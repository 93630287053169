/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 * @preferred
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { SharedModule } from 'ng/shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { MatchModule } from 'ng/modules/match/match.module';

import {
    VHMatchRuleModalComponent,
} from 'ng/modules/virtual-service/components/vh-match-rule-modal/vh-match-rule-modal.component';

import {
    DnsRecordsComponent,
    VHMatchModalComponent,
    VHMatchRuleGridComponent,
    VirtualHostGridComponent,
} from './components';

import { VirtualServiceStateService } from './services';

import {
    dnsQueryCollectionProvider,
    metricDataCollectionProvider,
} from './ajs-upgraded-providers';

const virtualServiceComponents = [
    DnsRecordsComponent,
    VirtualHostGridComponent,
    VHMatchModalComponent,
    VHMatchRuleModalComponent,
    VHMatchRuleGridComponent,
];

@NgModule({
    declarations: [
        ...virtualServiceComponents,
    ],
    providers: [
        VirtualServiceStateService,
        metricDataCollectionProvider,
        dnsQueryCollectionProvider,
    ],
    imports: [
        AviFormsModule,
        BrowserAnimationsModule,
        CommonModule,
        DataGridModule,
        MatchModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VirtualServiceModule {}
