/**
 * @module VerticalNavModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { SharedModule } from 'ng/shared/shared.module';
import { CoreModule } from 'ng/modules/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrIconModule,
    ClrLayoutModule,
    ClrNavigationModule,
    ClrVerticalNavModule,
} from '@clr/angular';

import {
    IsActiveStatePipe,
    IsHiddenStatePipe,
    IsNavGroupPipe,
    IsNavLinkPipe,
    VerticalNavComponent,
} from './components/vertical-nav';

const verticalNavPipes = [
    IsActiveStatePipe,
    IsHiddenStatePipe,
    IsNavGroupPipe,
    IsNavLinkPipe,
];

const verticalNavComponents = [
    VerticalNavComponent,
];

@NgModule({
    declarations: [
        ...verticalNavPipes,
        ...verticalNavComponents,
    ],
    imports: [
        AviFormsModule,
        BrowserAnimationsModule,
        CommonModule,
        ClrIconModule,
        ClrLayoutModule,
        ClrNavigationModule,
        ClrVerticalNavModule,
        SharedModule,
        CoreModule,
        UIRouterModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VerticalNavModule {}
