/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component to display header with text and an edit button.
 * @example
 *      <avi-page-header
 *          [disableClick]="flag"
 *          (onButtonClick)="functionToBeCalled()"
 *          headerText="headerLabel"
 *          buttonLabelText= "buttonLabel"
 *          buttonToolTip= "buttonToolTip"
 *      >
 *          //The content in the tag is projected on the left (for stuff like status icons).
 *          <div class="cportal-page__status-icon-and-text">
 *              <avi-tooltip-icon-with-content-wrapper
 *                  shape="circle"
 *                  size="28"
 *                  status="{{ aviCloudConnectedStatus }}"
 *                  solid
 *                  [hidden]="busy"
 *                  class="cportal-page__pulse-status"
 *              >
 *                   {{ portalUrl }}
 *               </avi-tooltip-icon-with-content-wrapper>
 *               <div
 *                   class="cportal-page__pulse-spinner"
 *                   *ngIf="busy"
 *               >
 *                   <clr-spinner [clrMedium]="true"></clr-spinner>
 *               </div>
 *          </div>
 *      </avi-page-header>
 * @author tmaridi
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-page-header.l10n';

import './avi-page-header.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'avi-page-header',
    templateUrl: './avi-page-header.component.html',
})
export class AviPageHeaderComponent {
    /**
     * Text to be shown in the Header H2 Tag.
     */
    @Input()
    public headerText: string;

    /**
     * If true, shows the Edit button.
     */
    @Input()
    public showEditButton = false;

    /**
     * Text to be shown on the Edit button.
     */
    @Input()
    public buttonLabelText: string;

    /**
     * Text to be shown on the tooltip of the button.
     */
    @Input()
    public buttonToolTip: string;

    /**
     * If true, will disable the Edit button.
     */
    @Input()
    public disableClick = false;

    /**
     * Called when the user clicks the Edit button.
     */
    @Output()
    public onButtonClick = new EventEmitter<void>();

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.buttonLabelText =
            l10nService.getMessage(l10nKeys.buttonLabelText);
    }

    /**
     * Handler for submitting or saving the modal.
     */
    public onClick(): void {
        this.onButtonClick.emit();
    }
}
