/**
 * @module NetworkServiceModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { INetworkService } from 'generated-types';
import {
    IItemArgs,
    Item,
} from '../../data-model/factories/item.factory';

/**
 * @author Akul Aggarwal
 * @description Item for network_service
 */
export class NetworkService extends Item<INetworkService> {
    constructor(args = {} as any as IItemArgs<INetworkService>) {
        super({
            objectName: 'networkservice',
            ...args,
        });
    }
}

export const NETWORK_SERVICE_TOKEN = 'NetworkService';
