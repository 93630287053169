/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-tenant-settings';
const prefix = `${moduleName}.${componentName}`;

export const ipRouteDomainLabel = `${prefix}.ipRouteDomainLabel`;
export const perTenantIpRouteDomainLabel = `${prefix}.perTenantIpRouteDomainLabel`;
export const shareIpRouteAcrossTenantsLabel = `${prefix}.shareIpRouteAcrossTenantsLabel`;
export const serviceEnginesAreManagedWithinTheLabel =
    `${prefix}.serviceEnginesAreManagedWithinTheLabel`;
export const notSharedAcrossTenantsLabel = `${prefix}.notSharedAcrossTenantsLabel`;
export const sharedAcrossTenantsLabel = `${prefix}.sharedAcrossTenantsLabel`;
export const tenantAccessToServiceEngineLabel = `${prefix}.tenantAccessToServiceEngineLabel`;
export const readAccessLabel = `${prefix}.readAccessLabel`;
export const noAccessLabel = `${prefix}.noAccessLabel`;

export const ENGLISH = {
    [ipRouteDomainLabel]: 'IP Route Domain',
    [perTenantIpRouteDomainLabel]: 'Per tenant IP route domain',
    [shareIpRouteAcrossTenantsLabel]: 'Share IP route domain across tenants',
    [serviceEnginesAreManagedWithinTheLabel]: 'Service Engines are managed within the',
    [notSharedAcrossTenantsLabel]: 'Tenant (Not shared across tenants)',
    [sharedAcrossTenantsLabel]: 'Provider (Shared across tenants)',
    [tenantAccessToServiceEngineLabel]: 'Tenant Access to Service Engine',
    [readAccessLabel]: 'Read Access',
    [noAccessLabel]: 'No Access',
};
