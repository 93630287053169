/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-type-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const l4LogsLabel = `${prefix}.l4LogsLabel`;
export const l7LogsLabel = `${prefix}.l7LogsLabel`;

export const ENGLISH = {
    [l4LogsLabel]: 'L4 Logs',
    [l7LogsLabel]: 'L7 Logs',
};
