/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-signpost-custom-groups';
const prefix = `${moduleName}.${componentName}`;

export const logsNumberLabel = `${prefix}.logsNumberLabel`;
export const logsPercentageLabel = `${prefix}.logsPercentageLabel`;

export const ENGLISH = {
    [logsNumberLabel]: '# Logs',
    [logsPercentageLabel]: '% of Logs',
};
