/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { GSLBService } from './gslb-service.item.factory';
import { GSLB } from './gslb.item.factory';

interface IGslbServiceFqdnArgs {
    gslb?: GSLB;
    gslbService?: GSLBService;
}

const allDataSources = {
    list: {
        source: 'GSLBServiceFQDNListCollDataSource',
        transformer: 'GSLBServiceFQDNListDataTransformer',
        transport: 'IdentityDataTransport',
        fields: ['config'],
    },
    collection: {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'dns_client.avg_udp_queries',
            'dns_client.avg_errored_queries',
            'dns_client.pct_errored_queries',
        ],
        dependsOn: 'config',
    },
};

/**
 * @description
 *
 *     Collection of GSLBServiceFQDN Items, list of which comes from GslbServiceConfig.domain_names.
 *     Needs Loaded GSLB Item to get a list of DNS VSes with names.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBServiceFQDNCollection extends Collection {
    public readonly gslb: GSLB;
    public readonly gslbService: GSLBService;

    constructor(args: IGslbServiceFqdnArgs = {}) {
        super(args);

        this.itemClass_ = this.getAjsDependency_('GSLBServiceFQDN');

        if (args.gslb && args.gslbService) {
            this.gslb = args.gslb;
            this.gslbService = args.gslbService;
        } else {
            console.error(`Can't create GSLBServiceFQDNCollection collection wo GSLB or
                GSLBService instance`);
        }
    }
}

Object.assign(GSLBServiceFQDNCollection.prototype, {
    objectName_: 'GSLBServiceFQDN',
    allDataSources_: allDataSources,
    isStatic_: false,
});

GSLBServiceFQDNCollection.ajsDependencies = [
    'GSLBServiceFQDN',
];
