/**
 * @module BotDetectionPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for bot-detection-policy related things.
 * @author Akul Aggarwal
 */

/**
 * Ajs Dependency token for BotDetectionPolicyCollection.
 */
export const BOT_DETECTION_POLICY_COLLECTION_TOKEN = 'BotDetectionPolicyCollection';

export const BOT_ALLOW_LIST_CONFIG_ITEM_TOKEN = 'BotAllowListConfigItem';

export const BOT_ALLOW_RULE_CONFIG_ITEM_TOKEN = 'BotAllowRuleConfigItem';

/**
 * Ajs Dependency token for BotDetectionPolicyItem.
 */
export const BOT_DETECTION_POLICY_ITEM_TOKEN = 'BotDetectionPolicyItem';

/**
 * Ajs Dependency token for BotIPReputationTypeMappingCollection.
 */
export const BOT_IP_REPUTATION_TYPE_MAPPING_COLLECTION_TOKEN =
    'BotIPReputationTypeMappingCollection';

/**
 * Ajs Dependency token for BotIPReputationTypeMappingItem.
 */
export const BOT_IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN = 'BotIpReputationTypeMappingItem';

/**
 * Ajs Dependency token for BotMappingCollection.
 */
export const BOT_MAPPING_COLLECTION_TOKEN = 'BotMappingCollection';

/**
 * Ajs Dependency token for BotMappingItem.
 */
export const BOT_MAPPING_ITEM_TOKEN = 'BotMappingItem';

export const BOT_MAPPING_RULE_ITEM_TOKEN = 'BotMappingRuleConfigItem';

/**
 * Ajs Dependency token for IpReputationTypeMappingConfigItem.
 */
export const IP_REPUTATION_TYPE_MAPPING_ITEM_TOKEN = 'IPReputationTypeMappingConfigItem';

/**
 * Ajs Dependency token for botManagementPolicyListPage.
 */
export const BOT_MANAGEMENT_POLICY_LIST_PAGE_OPTIONS_TOKEN = 'botManagementPolicyListPage';
