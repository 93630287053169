/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './EventGridConfig.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * This factory is for sharing a single code base for creating event grid configs
 */
angular.module('aviApp').factory('eventGridConfig', ['$filter', 'Event', 'l10nService',
function($filter, Event, l10nService) {
    return function(collection, options) {
        const ddosDictionary = {
            DOS_ATTACK: true,
            SE_SYN_SEEN_TABLE_HIGH: true,
            SE_FLOW_TABLE_HIGH: true,
            SE_DOS_ATTACK: true,
        };

        l10nService.registerSourceBundles(dictionary);

        const fields = [
            {
                title: l10nService.getMessage(l10nKeys.columnTitleTimestamp),
                name: 'report_timestamp',
                sortBy: 'report_timestamp',
                transform(row) {
                    return $filter('i18nDate')(row.data.config.report_timestamp);
                },
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleResourceName),
                name: 'data.config.obj_name',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleResourceType),
                name: 'data.config.obj_type',
                sortBy: 'obj_type',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleEventCode),
                name: 'data.config.event_id',
                sortBy: 'event_id',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleUser),
                name: 'user', // used as id to hide this column for certain pages
                template: require('./display-event-user.partial.html'),
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleDescription),
                name: 'data.config.event_description',
            }];

        const defaultConfig = {
            fields,
            expandedContainerTemplate: require('./event-details-expander.partial.html'),
            expanderDisabled(row) {
                return row.data.config.ignore_event_details_display;
            },
            layout: {
                hideDisplaying: true,
                hideSearch: true,
                hideEditColumns: true,
            },
            rowClass(row) {
                return ddosDictionary[row.data.config.event_id] && 'dos-attack';
            },
        };

        if (collection) { //collection grid
            defaultConfig.collection = collection;
        } else { //regular grid
            defaultConfig.rowId = function(row) {
                return Event.getIdFromData(row.data);
            };
        }

        return Object.assign(
            defaultConfig,
            options,
        );
    };
}]);
