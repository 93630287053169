/**
 * Module for HealthMonitor
 * @module HealthmonitorModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    HealthMonitorCollection,
    HealthMonitorHttpConfigItem,
    HealthMonitorItem,
} from '.';

import {
    HEALTH_MONITOR_COLLECTION_TOKEN,
    HEALTH_MONITOR_HTTP_CONFIG_TOKEN,
    HEALTH_MONITOR_ITEM_TOKEN,
} from './healthmonitor.tokens';

const healthmonitorModule = angular.module('avi/healthmonitor');

const factories = [
    {
        factory: HealthMonitorItem,
        name: HEALTH_MONITOR_ITEM_TOKEN,
    },
    {
        factory: HealthMonitorCollection,
        name: HEALTH_MONITOR_COLLECTION_TOKEN,
    },
    {
        factory: HealthMonitorHttpConfigItem,
        name: HEALTH_MONITOR_HTTP_CONFIG_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        healthmonitorModule,
        'factory',
        name,
        factory,
    );
});
