/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { UserProfileModalComponent } from 'ng/modules/accounts';
import { AviPermissionResource } from 'generated-types';
import { USER_PROFILE_ITEM_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

/**
 * Type of UserProfileCollection.
 */
export type TUserProfileCollection = typeof UserProfileCollection;

/**
 * @description UserProfile Collection Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class UserProfileCollection extends Collection {
    public static ajsDependencies = [
        USER_PROFILE_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'useraccountprofile',
            windowElement: UserProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_USER,
            objectType: 'UserAccountProfile',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(USER_PROFILE_ITEM_TOKEN);
    }
}
