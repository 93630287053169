/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './virtualservice-unit-info.less';
import * as l10n from './virtualservice-unit-info.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Delay between component hover event and popup being rendered.
 * Should match value used by {@link popupHelper} used by {@link unitInfo}.
 * @type {number}
 */
const popupShowDelay = 500;

/**
 * Delay between component mouseout event and popup being hidden.
 * Should match value used by {@link popupHelper} used by {@link unitInfo}.
 * @type {number}
 */
const popupHideDelay = 250;

class VirtualserviceUnitInfoController {
    constructor($timeout, Auth, AviModal, AviMessage, l10nService) {
        this.$timeout_ = $timeout;
        this.Auth = Auth;
        this.AviModal_ = AviModal;
        this.AviMessage_ = AviMessage;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.l10nService_ = l10nService;
    }

    $onInit() {
        this.showPopup = false;

        /**
         * Timeout promise for popup show & hide.
         * @type {null|angular.$q.promise}
         * @protected
         */
        this.timer_ = null;

        this.hasFloatingIp = false;

        this.reload_ = _.throttle(() => this.item.load(), 9999, { trailing: false });
        this.handleItemLoadSuccess_ = this.handleItemLoadSuccess_.bind(this);

        this.item.on('itemLoadSuccess', this.handleItemLoadSuccess_);

        /**
         * vsvip object.
         */
        this.vsVip = this.item.getVSVip();
    }

    /**
     * Handles itemLoadSuccess event.
     */
    handleItemLoadSuccess_() {
        this.checkFloatingIp_();
    }

    /**
     * Checks for the presence of floating IPs and sets the $ctrl.hasFloatingIp. Displayed in
     * the unit info if present.
     */
    checkFloatingIp_() {
        const runtime = this.item.getRuntimeData();

        if (runtime && 'vip_summary' in runtime) {
            this.hasFloatingIp = _.some(
                runtime['vip_summary'],
                ({ floating_ip: floatingIp }) => floatingIp,
            );
        } else {
            this.hasFloatingIp = false;
        }
    }

    /**
     * Handles the user action of hovering over the Virtual Service name. Displays the popup
     * on hover, and hides it when the mouse leaves the info box.
     * @param  {Boolean=} enter - If true, shows the popup, else hides it.
     */
    handleHover(enter = false) {
        const { $timeout_: $timeout } = this;

        $timeout.cancel(this.timer_);

        let timeout;

        if (enter) {
            timeout = $timeout(
                () => {
                    this.showPopup = true;
                    this.reload_();
                },
                popupShowDelay,
            );
        } else {
            timeout = $timeout(
                () => {
                    this.showPopup = false;
                },
                popupHideDelay,
            );
        }

        this.timer_ = timeout;
    }

    /**
     * Returns the status message to let the user know if scaling or migrating is currently in
     * progress.
     * @return {string|undefined}
     */
    getScaleStatus() {
        const { l10nService_: l10nService } = this;

        const statusHash = {
            SCALE_OUT: l10nService.getMessage(l10nKeys.scalingOutLabel),
            SCALE_IN: l10nService.getMessage(l10nKeys.scalingInLabel),
            MIGRATE: l10nService.getMessage(l10nKeys.migratingLabel),
        };

        return statusHash[this.item.getRuntimeStatus()];
    }

    /**
     * Opens the scale out/in/migrate modal.
     * @param  {string} direction - operation to be performed.
     */
    openScaleModal(direction) {
        const directionHash = {
            scalein: 'virtualservice-scale-in',
            scaleout: 'virtualservice-scale-out',
            migrate: 'virtualservice-migrate',
        };

        const modalComponent = directionHash[direction];

        this.AviModal_.open(modalComponent, {
            onCancel: () => this.AviModal_.destroy(modalComponent),
            onComplete: ['vipId', vipId => {
                this.AviModal_.destroy(modalComponent);
                this.item.load().then(() => this.checkScaleStatus(vipId));
            }],
            virtualservice: this.item,
        });
    }

    /**
     * Checks the Virtual Service runtime to determine if scaling or migrating is occuring. If
     * so, compiles the scale-progress modal.
     * @param  {string=} vipId - vip_id of the Vip being scaled/migrated.
     */
    checkScaleStatus(vipId) {
        const status = this.item.getRuntimeStatus();
        const statusHash = {
            SCALE_OUT: 'scaleout',
            SCALE_IN: 'scalein',
            MIGRATE: 'migrate',
        };

        const activeVipSummary = this.item.getActiveVipSummary();

        vipId = vipId || activeVipSummary && activeVipSummary.vip_id;

        if (status && vipId) {
            this.AviMessage_.open('virtualservice-scale-progress', {
                direction: statusHash[status],
                slug: this.item.id,
                vipId,
                onComplete: () => {
                    this.AviMessage_.destroy('virtualservice-scale-progress');
                    this.item.load();
                },
            });
        }
    }

    /**
     * Returns the name of the SE.
     * @param  {ServiceEngine.data.config} se
     * @return {string}
     */
    getServiceEngineName(se) {
        return se.url.name() || se.url.slug();
    }

    $onDestroy() {
        this.item.unbind('itemLoadSuccess', this.handleItemLoadSuccess_);
        this.AviMessage_.destroy('virtualservice-scale-progress');
    }
}

VirtualserviceUnitInfoController.$inject = [
    '$timeout',
    'Auth',
    'AviModal',
    'AviMessage',
    'l10nService',
];

/**
 * @ngdoc component
 * @name virtualserviceUnitInfo
 * @description
 *     Displays the information about the VirtualService. Includes buttons to initiate scale out,
 *     scale in, and migrate.
 * @param {VirtualService} item
 */
angular.module('aviApp').component('virtualserviceUnitInfo', {
    bindings: {
        item: '<',
    },
    controller: VirtualserviceUnitInfoController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-unit-info/' +
        'virtualservice-unit-info.html',
});
