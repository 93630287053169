/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { SensitiveFieldRule } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';

import { StringMatchConfigItem }
    from 'ajs/modules/match/factories/string-match.config-item.factory';

import {
    ISensitiveFieldRule,
    LogAction,
} from 'generated-types';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import * as l10n from './analytics-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSensitiveFieldRulePartial = Omit<ISensitiveFieldRule, 'match'>;

interface ISensitiveFieldRuleConfig extends TSensitiveFieldRulePartial {
    match?: StringMatchConfigItem,
}

/**
 * @description Sensitive Log Profile Message Item.
 *
 * @author sghare
 */
export class SensitiveFieldRuleConfigItem extends
    withFullModalMixin(MessageItem)<ISensitiveFieldRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: SensitiveFieldRule,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter function for the config to override the message default.
     */
    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<ISensitiveFieldRuleConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};

        return {
            ...defaultConfig,
            match: null,
        };
    }

    /**
     * Getter for SensitiveFieldRule match.
     */
    public get match(): StringMatchConfigItem {
        return this.config.match;
    }

    /**
     * Getter for SensitiveFieldRule action.
     */
    public get action(): LogAction {
        return this.config.action;
    }

    /**
     * Looks up description field for allow rule's selected action.
     */
    public get sensitiveFieldRuleActionText(): string {
        const schemaService = this.getAjsDependency_('schemaService');

        return schemaService.getEnumValueDescription('LogAction', this.config.action);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            AnalyticsProfileSensitiveInformationRulesModalComponent,
        } = await import(
            /* webpackChunkName: "analytics-profile-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/analytics-profile-modal/analytics-profile-client-log/analytics-profile-sensitive-information-rules-modal/analytics-profile-sensitive-information-rules-modal.component'
        );

        return AnalyticsProfileSensitiveInformationRulesModalComponent as Type<Component>;
    }

    /**
     * Getter for field name.
     */
    public get fieldName(): string {
        return this.fieldName_;
    }

    /**
     * Getter for SensitiveFieldRule header label.
     */
    public get headerLabel(): string | undefined {
        switch (this.fieldName) {
            case 'header_field_rules':
                return this.l10nService.getMessage(l10nKeys.headerFieldRulesTitle);
            case 'waf_field_rules':
                return this.l10nService.getMessage(l10nKeys.wafFieldRulesTitle);
            case 'uri_query_field_rules':
                return this.l10nService.getMessage(l10nKeys.uriQueryFieldRulesTitle);
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.headerLabel;
    }
}
