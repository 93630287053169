/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    ElementRef,
    Inject,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    each,
    isUndefined,
} from 'underscore';

import { SchemaService } from 'ajs/modules/core/services';

import { configFieldInputValidator }
    from 'ng/modules/avi-forms/validators/config-field-input-validation';

interface IFieldInputAttributes {
    max?: number;
    min?: number;
    specialValues?: number[] | undefined,
    step?: number;
}

/**
 * @description
 *      This directive sets min, max, and step attributes to the input field.
 *      Invalidates the form when the value is out of the boundaries (min-max).
 * @author Aravindh Nagarajan
 */
@Directive({
    providers: [
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: ConfigFieldInputValidationDirective,
        },
    ],
    selector: '[configFieldInputValidation]',
})
export class ConfigFieldInputValidationDirective implements OnInit, Validator {
    /**
     * ObjectType of the field.
     */
    @Input() public objectType: string;

    /**
     * Field name from Schema.
     */
    @Input() public fieldName: string;

    /**
     * Min value of the field - Used to validate formControl value.
     */
    private minValue: number | undefined;

    /**
     * max value of the field - Used to validate formControl value.
     */
    private maxValue: number | undefined;

    /**
     * Step value of the field - Used to validate formControl value.
     */
    private stepValue: number | undefined;

    /**
     * Special Values of the field - Used to validate formControl value.
     */
    private specialValues: number[] | undefined;

    public constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        const fieldAttributes = this.getFieldInputAttributes();

        const {
            min,
            max,
            step,
            specialValues,
        } = fieldAttributes;

        this.minValue = min;
        this.maxValue = max;
        this.specialValues = specialValues;
        this.stepValue = step;

        this.setAttributesInHostElement(fieldAttributes);
    }

    /**
     * @override
     * Checks whether the input value is within min-max boundary.
     *
     * Returns null if its a valid value, returns validationError if
     * input is invalid.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) ?
            configFieldInputValidator(
                this.minValue,
                this.maxValue,
                this.stepValue,
                this.specialValues,
            )(control) :
            null;
    }

    /**
     * Returns input field attributes (min, max and step) from SchemaService.
     */
    private getFieldInputAttributes(): IFieldInputAttributes {
        const {
            fieldName,
            objectType,
        } = this;

        return this.schemaService.getFieldInputAttributes(objectType, fieldName);
    }

    /**
     * Sets input field attributes (min, max and step) in host element.
     */
    private setAttributesInHostElement(fieldAttributes: IFieldInputAttributes): void {
        const {
            elementRef,
            renderer,
        } = this;

        const attributes: Array<[string, number]> = Object.entries(fieldAttributes);

        each(attributes, ([attr, value]) => {
            renderer.setAttribute(elementRef.nativeElement, attr, `${value}`);
        });
    }
}
