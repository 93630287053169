/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'core';
const componentName = 'central-license-alerts';
const prefix = `${moduleName}.${componentName}`;

export const controllerNotRegisteredMessage = `${prefix}.controllerNotRegisteredMessage`;
export const registerControllerButtonText = `${prefix}.registerControllerButtonText`;
export const retryNowButtonText = `${prefix}.retryNowButtonText`;
export const basicLicenseDeprecationMessage = `${prefix}.basicLicenseDeprecationMessage`;
export const learnMoreLink = `${prefix}.learnMoreLink`;

export const ENGLISH = {
    [controllerNotRegisteredMessage]: 'Register your Controller with Cloud Services to complete product activation.',
    [registerControllerButtonText]: 'Register Controller',
    [retryNowButtonText]: 'Retry Now',
    [basicLicenseDeprecationMessage]: 'Avi Basic Edition reached End of Availability on 30th January, 2024. Contact your VMware account team for upgrading to Avi Enterprise Edition.',
    [learnMoreLink]: 'Learn More',
};
