/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './tech-support-generation-modal.component.less';
import * as l10n from './tech-support-generation-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'tech-support-generation-modal';

/**
 * Informs if type selection is all that is needed, or needs additional instance selection.
 * @private
 */
const simpleTypeSelectionHash = {
    sys_details: true,
    clustering: true,
    metricslogs: true,
    debuglogs: true,
    placement: true,
    portal: true,
    upgrade: true,
    serviceengine: false,
    virtualservice: false,
    gslb: false,
    pool: false,
};

/**
 * @alias module:component/techSupportGenerationModal
 * @private
 */
class TechSupportGenerationModalController {
    constructor(
        dropDownUtils,
        TechSupportCaptureDataTransformer,
        techSupportTypesHash,
        CloudCollection,
        GSLBCollection,
        PoolCollection,
        ServiceEngineCollection,
        VirtualServiceCollection,
        CaseCollection,
        l10nService,
    ) {
        const collectionParams = { isStatic: true };

        this.techSupportTypesHash = techSupportTypesHash;
        this.cloudCollection = new CloudCollection(collectionParams);
        this.gslbCollection = new GSLBCollection(collectionParams);
        this.poolCollection = new PoolCollection(collectionParams);
        this.serviceEngineCollection = new ServiceEngineCollection(collectionParams);
        this.virtualServiceCollection = new VirtualServiceCollection(collectionParams);
        this.caseCollection = new CaseCollection(collectionParams);

        this.typeSelectionList = _.map(techSupportTypesHash, value => {
            const label = TechSupportCaptureDataTransformer.getTechSupportReadableTypeProp(value);

            return dropDownUtils.createOption(value, label);
        });

        /**
         * Selected cloud instance to filter item instances if 'non-simple' types from
         * simpleTypeSelectionHash are selected.
         * @type {string}
         */
        this.cloudRef;

        /**
         * Selected item instance for 'non-simple' types from simpleTypeSelectionHash.
         * @type {string}
         */
        this.instanceRef;

        /**
         * Flag for case attach. This is a UI property only.
         * @type {boolean}
         */
        this.attachCase = false;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    $onInit() {
        this.techSupportType = this.typeSelectionList[0].value;
    }

    /**
     * Fires when user changes/selects tech support type. Clears prior selections.
     */
    onTypeChange() {
        delete this.instanceRef;
        delete this.cloudRef;

        this.poolCollection.reset();
        this.virtualServiceCollection.reset();
        this.serviceEngineCollection.reset();
    }

    /**
     * Fire when the attach case checkbox is changed.
     */
    handleAttachCaseChange() {
        delete this.caseRef;
        this.caseCollection.reset();
    }

    /**
     * Get attach to case checkbox error message.
     * @return {string}
     */
    getAttachCaseLabelErrorMessage() {
        let errorMessage = '';
        const { l10nService_: l10nService } = this;

        if (!this.controllerRegistered) {
            errorMessage = l10nService.getMessage(l10nKeys.controllerRegistrationError);
        } else if (!this.aviCloudConnected) {
            errorMessage = l10nService.getMessage(l10nKeys.pulseConnectionError);
        }

        return errorMessage;
    }

    /**
     * Fires when user changes/selects cloud instance to filter by.
     */
    onCloudChange() {
        delete this.instanceRef;

        let collection;

        switch (this.techSupportType) {
            case 'pool':
                collection = this.poolCollection;
                break;

            case 'serviceengine':
                collection = this.serviceEngineCollection;
                break;

            case 'virtualservice':
                collection = this.virtualServiceCollection;
                break;
        }

        const
            cloudId = this.cloudRef ? this.cloudRef.slug() : undefined,
            params = { 'cloud_ref.uuid': cloudId };

        collection.setParams(params);
    }

    /**
     * Closes modal and triggers callback to trigger new tech support generation.
     */
    onClickGenerateTechSupport() {
        const params = {
            type: this.techSupportType,
        };

        if (this.caseRef) {
            params.caseNumber = this.caseRef.slug();
        }

        if (this.instanceListVisible()) {
            params.id = this.instanceRef.slug();
        }

        this.onGenerate(params);
        this.closeModal();
    }

    /**
     * Based on type of tech support selected, decides whether to disable button.
     * @return {boolean}
     */
    generationButtonDisabled() {
        return this.instanceListVisible() &&
            !this.instanceRef;
    }

    /**
     * Determines whether to show instance list based on tech support type selected.
     * @return {boolean}
     */
    instanceListVisible() {
        return !simpleTypeSelectionHash[this.techSupportType];
    }

    /**
     * Determines whether to show Cloud selection for filtering of instances of
     * gslb/pool/serviceengine/virtualservice types.
     * @return {boolean}
     */
    cloudSelectionVisible() {
        return this.techSupportType !== 'gslb' &&
            this.instanceListVisible();
    }

    /**
     * @override
     */
    $onDestroy() {
        this.cloudCollection.destroy();
        this.gslbCollection.destroy();
        this.poolCollection.destroy();
        this.serviceEngineCollection.destroy();
        this.virtualServiceCollection.destroy();
        this.caseCollection.destroy();
    }
}

TechSupportGenerationModalController.$inject = [
    'dropDownUtils',
    'TechSupportCaptureDataTransformer',
    'techSupportTypesHash',
    'CloudCollection',
    'GSLBCollection',
    'PoolCollection',
    'ServiceEngineCollection',
    'VirtualServiceCollection',
    'CaseCollection',
    'l10nService',
];

/**
 * @ngdoc component
 * @name techSupportGenerationModal
 * @module component/techSupportGenerationModal
 * @author Akul Aggarwal, Ashish Verma
 * @param {Function} onGenerate - method fired upon user selecting to generate new tech support
 * @param {Function} closeModal - method fired upon user selecting to close modal
 * @param {boolean} controllerRegistered - boolean flag. false if controller is not registered.
 * @param {boolean} aviCloudConnected - boolean flag. false if avi cloud is not connected
 * @description
 *
 *     Modal to select techsupport type to generate.
 *
 */
angular.module('aviApp').component('techSupportGenerationModal', {
    bindings: {
        onGenerate: '&',
        closeModal: '&',
        controllerRegistered: '<',
        aviCloudConnected: '<',
    },
    controller: TechSupportGenerationModalController,
    templateUrl: 'src/components/administration/tech-support/' +
        `${componentName}/${componentName}.component.html`,
});
