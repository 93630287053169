/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'cloud-setter';
const prefix = `${moduleName}.${componentName}`;

export const cloudTitleLabel = `${prefix}.cloudTitleLabel`;
export const selectCloudHeader = `${prefix}.selectCloudHeader`;
export const nextBtnLabel = `${prefix}.nextBtnLabel`;

export const ENGLISH = {
    [cloudTitleLabel]: 'Clouds',
    [selectCloudHeader]: 'Select Cloud',
    [nextBtnLabel]: 'Next',
};
