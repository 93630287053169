/**
 * @module PoolModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IpAddrConfigItem } from 'ajs/modules/data-model/factories/ip-addr.config-item.factory';
import { IServer } from 'generated-types';
import { Server } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { isUndefined } from 'underscore';

import {
    Component,
    Type,
} from '@angular/core';

type TServerConfigPartial = Omit<IServer, 'ip'>;
interface IServerConfig extends TServerConfigPartial {
    ip?: IpAddrConfigItem,
    uuid?: string;
    default_server_port?: number;
}

/**
 * @description
 *
 *   IServer config item.
 *
 * @author Rachit Aggarwal
 */
export class ServerConfigItem extends withFullModalMixin(MessageItem)<IServerConfig> {
    public static ajsDependencies = ['l10nService', 'ConfiguredNetwork', 'stringService'];

    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: Server,
            ...args,
            windowElement: 'lazy-load',
        };

        super(extendedArgs);
    }

    /**
     * Returns Ip Address value.
     */
    public get ipAddr(): string {
        return this.config.ip.address;
    }

    /**
     * Sets enabled property value.
     */
    public set enabled(enabled: boolean) {
        this.config.enabled = enabled;
    }

    /**
     * Returns enabled property value.
     */
    public get enabled(): boolean {
        return this.config.enabled;
    }

    /**
     * Sets default values for auto populate servers.
     */
    public setAutoPopulatedServerDefaults(): void {
        this.config.hostname = this.config.ip.config.addr;
        this.config.ratio = 1;
    }

    /**
     * @override
     * Method used to import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            PoolServerModalComponent,
        } = await import(
            /* webpackChunkName: "pool-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/pool-modal/pool-servers/pool-server-modal/pool-server-modal.component'
        );

        return PoolServerModalComponent as Type<Component>;
    }

    /**
     * Fetches the network details and updates ref.
     */
    public fetchNetwork(cloudRef: string): void {
        const stringService = this.getAjsDependency_('stringService');
        const ConfiguredNetwork = this.getAjsDependency_('ConfiguredNetwork');
        const nwRef = this.config.nw_ref;

        if (!isUndefined(nwRef) && !stringService.name(nwRef)) {
            const network = new ConfiguredNetwork({
                id: stringService.slug(nwRef),
                params: {
                    cloud_uuid: stringService.slug(cloudRef),
                },
            });

            network.load()
                .then(() => this.config.nw_ref = network.getRef())
                .finally(() => {
                    network.destroy();
                });
        }
    }
}
