/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * @description
 *
 *     HTTP Interceptor to encode QueryParams in HttpRequest.
 *
 * @author Aravindh Nagrajan
 */
@Injectable()
export class EncodeQueryParamsInterceptor implements HttpInterceptor {
    /** @override */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { params } = req;

        const encodedQueryParamHash = params.keys().reduce((queryParamHash, key) => {
            let value: string | string[] = params.get(key);

            if (Array.isArray(value)) {
                value = value.map(v => encodeURIComponent(v));
            } else {
                value = encodeURIComponent(value);
            }

            queryParamHash[encodeURIComponent(key)] = value;

            return queryParamHash;
        }, {});

        const clonedRequest = req.clone({
            params: new HttpParams({
                fromObject: encodedQueryParamHash,
            }),
        });

        return next.handle(clonedRequest);
    }
}
