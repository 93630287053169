/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-management-custom-params-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const columnTitleSensitive = `${prefix}.columnTitleSensitive`;
export const columnTitleDynamic = `${prefix}.columnTitleDynamic`;
export const removeActionLabel = `${prefix}.removeActionLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const customScriptParamsLabel = `${prefix}.editButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleName]: 'Name',
    [columnTitleSensitive]: 'Sensitive',
    [columnTitleDynamic]: 'Dynamic',
    [removeActionLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
    [customScriptParamsLabel]: 'Custom Script Params ({0})',
};
