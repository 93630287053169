/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/* eslint-disable no-underscore-dangle */

import {
    MessageItem,
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    IIpamDnsOpenstackProfile,
    IIpamDnsProviderProfile,
    IpamDnsType,
    IProxyConfiguration,
} from 'generated-types';

import {
    uniq,
    values,
} from 'underscore';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { StringService } from 'ajs/modules/core/services/string-service';
import { IpamDnsProviderProfile } from 'object-types';
import { IpamDnsProfileModalComponent }
    from 'ng/modules/ipam/components/ipam-dns-profile-modal/ipam-dns-profile-modal.component';
import { L10nService } from '@vmw/ngx-vip';
import { IpamDnsAzureProfileConfigItem } from './ipam-dns-azure-profile.config-item.factory';
import { IpamDnsAwsProfileConfigItem } from './ipam-dns-aws-profile.config-item.factory';
import { IpamDnsInternalProfileConfigItem } from './ipam-dns-internal-profile.config-item.factory';
import { IpamDnsInfobloxProfileConfigItem } from './ipam-dns-infoblox-profile.config-item.factory';
import { IpamDnsCustomProfileConfigItem } from './ipam-dns-custom-profile.config-item.factory';

import * as l10n from '../ipam.l10n';

type TChildMessageFields = 'internal_profile' |
'infoblox_profile' |
'aws_profile' |
'azure_profile' |
'custom_profile' |
'proxy_configuration' |
'openstack_profile';

type TIpamProfilePartial = Omit<IIpamDnsProviderProfile, TChildMessageFields>;

interface IIpamProfile extends TIpamProfilePartial {
    aws_profile?: IpamDnsAwsProfileConfigItem;
    azure_profile?: IpamDnsAzureProfileConfigItem;
    custom_profile?: IpamDnsCustomProfileConfigItem;
    infoblox_profile?: IpamDnsInfobloxProfileConfigItem;
    internal_profile?: IpamDnsInternalProfileConfigItem;
    openstack_profile?: MessageItem<IIpamDnsOpenstackProfile>;
    proxy_configuration?: MessageItem<IProxyConfiguration>
}

const domainListFetchUrl = '/api/ipamdnsproviderprofiledomainlist';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc IPAMProfile Item Class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamProfileItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        '$http',
        '$q',
        'secretStubStr',
        'stringService',
        'l10nService',
    ];

    /**
     * Hash mapping the IPAM/DNS profile type to the configuration property.
     */
    private static typeToConfig = {
        [IpamDnsType.IPAMDNS_TYPE_INTERNAL]: 'internal_profile',
        [IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS]: 'internal_profile',
        [IpamDnsType.IPAMDNS_TYPE_INFOBLOX]: 'infoblox_profile',
        [IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS]: 'infoblox_profile',
        [IpamDnsType.IPAMDNS_TYPE_AWS]: 'aws_profile',
        [IpamDnsType.IPAMDNS_TYPE_AWS_DNS]: 'aws_profile',
        [IpamDnsType.IPAMDNS_TYPE_OPENSTACK]: 'openstack_profile',
        [IpamDnsType.IPAMDNS_TYPE_CUSTOM]: 'custom_profile',
        [IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS]: 'custom_profile',
        [IpamDnsType.IPAMDNS_TYPE_AZURE_DNS]: 'azure_profile',
    };

    /**
     * List of profile property config names.
     */
    private static profileConfigPropNames = IpamProfileItem.getProfileConfigPropNames();

    /** Overriding getConfig()'s return type */
    public getConfig: () => IIpamProfile;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            permissionName: AviPermissionResource.PERMISSION_IPAMDNSPROVIDERPROFILE,
            objectType: IpamDnsProviderProfile,
            objectName: 'ipamdnsproviderprofile',
            windowElement: IpamDnsProfileModalComponent,
            restrictEditOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Gets list of domains for a specific cloud. Since the domains don't come prepended
     * with a '.', it is done here manually.
     * Used in VsVip modal component -> to load domains.
     * @param cloudRef - Cloud URL string.
     */
    public static getIpamDomains(cloudRef: string): ng.IPromise<any> {
        const $http: ng.IHttpService = this.getAjsDependency_('$http');
        const stringService: StringService = this.getAjsDependency_('stringService');

        const api = `${domainListFetchUrl}/?cloud_uuid=${stringService.slug(cloudRef)}`;

        return $http.get(api)
            .then(({ data }: any) => data.domains.map((domainName: string) => `.${domainName}`));
    }

    /**
     * Returns IpamDnsType enum types hash.
     * Used in IPAM Page component.
     */
    public static get typeHash(): Record<string, IpamDnsType> {
        return {
            [IpamDnsType.IPAMDNS_TYPE_OCI]: IpamDnsType.IPAMDNS_TYPE_OCI,
            [IpamDnsType.IPAMDNS_TYPE_INTERNAL]: IpamDnsType.IPAMDNS_TYPE_INTERNAL,
            [IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS]: IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
            [IpamDnsType.IPAMDNS_TYPE_INFOBLOX]: IpamDnsType.IPAMDNS_TYPE_INFOBLOX,
            [IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS]: IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS,
            [IpamDnsType.IPAMDNS_TYPE_AWS]: IpamDnsType.IPAMDNS_TYPE_AWS,
            [IpamDnsType.IPAMDNS_TYPE_AWS_DNS]: IpamDnsType.IPAMDNS_TYPE_AWS_DNS,
            [IpamDnsType.IPAMDNS_TYPE_OPENSTACK]: IpamDnsType.IPAMDNS_TYPE_OPENSTACK,
            [IpamDnsType.IPAMDNS_TYPE_CUSTOM]: IpamDnsType.IPAMDNS_TYPE_CUSTOM,
            [IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS]: IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS,
            [IpamDnsType.IPAMDNS_TYPE_AZURE_DNS]: IpamDnsType.IPAMDNS_TYPE_AZURE_DNS,
        };
    }

    /**
     * Get list of profile property config names.
     */
    private static getProfileConfigPropNames(): string[] {
        const propNameValues = values(IpamProfileItem.typeToConfig);

        return uniq(propNameValues);
    }

    /**
     * Returns the IPAMDNS profile type.
     */
    public get type(): IpamDnsType {
        return this.config.type;
    }

    /**
     * Returns true if the match string is contained within the type. For example,
     * this.typeContains('IPAMDNS_TYPE_INTERNAL') would check for both 'IPAMDNS_TYPE_INTERNAL'
     * and 'IPAMDNS_TYPE_INTERNAL_DNS'.
     * @param matchString - string to check against the type.
     */
    public typeContains(matchString: string): boolean {
        return this.type.includes(matchString);
    }

    /**
     * Returns the profile configuration according to the IPAMDNS profile type.
     */
    public get currentProfile(): any {
        const profileField = IpamProfileItem.typeToConfig[this.type];

        return this.config[profileField] || null;
    }

    /**
     * @override
     */
    public dataToSave(): IIpamDnsProviderProfile {
        const config = super.dataToSave();
        const { type } = config;

        if (type !== IpamDnsType.IPAMDNS_TYPE_INFOBLOX &&
            type !== IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS) {
            delete config.infoblox_profile;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_INTERNAL &&
            type !== IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS) {
            delete config.internal_profile;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_AWS &&
                type !== IpamDnsType.IPAMDNS_TYPE_AWS_DNS) {
            delete config.aws_profile;
            delete config.proxy_configuration;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_OPENSTACK) {
            delete config.openstack_profile;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_CUSTOM &&
                type !== IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS) {
            delete config.custom_profile;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_AZURE &&
                type !== IpamDnsType.IPAMDNS_TYPE_AZURE_DNS) {
            delete config.azure_profile;
        }

        if (type !== IpamDnsType.IPAMDNS_TYPE_GCP) {
            delete config.gcp_profile;
        }

        switch (config.type) {
            case IpamDnsType.IPAMDNS_TYPE_AWS: {
                delete config.aws_profile.usable_domains;

                break;
            }

            case IpamDnsType.IPAMDNS_TYPE_AWS_DNS: {
                delete config.aws_profile.zones;

                break;
            }

            case IpamDnsType.IPAMDNS_TYPE_INFOBLOX: {
                const infoblox = config.infoblox_profile;

                delete infoblox.usable_domains;
                break;
            }

            case IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS:
                delete config.infoblox_profile.usable_alloc_subnets;
                break;

            case IpamDnsType.IPAMDNS_TYPE_AZURE_DNS: {
                const { azure_profile: azureProfile } = config;

                delete azureProfile.usable_network_uuids;
                break;
            }
        }

        return config;
    }

    /**
     * Called on IPAM Profile type change. Resets config fields.
     */
    public onTypeChange(): void {
        this.resetProfiles();
    }

    /**
     * Checks if current IPAM Profile of specified type.
     * @param type - IPAM/DNS type (enum IpamDnsType).
     */
    public isType(type: IpamDnsType): boolean {
        return this.type === type;
    }

    /**
     * Returns this.data.config.proxy_configuartion.
     */
    public get proxyConfiguration(): MessageItem<IProxyConfiguration> {
        return this.getConfig().proxy_configuration;
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'openstack_profile',
            'custom_profile',
            'proxy_configuration',
        ];
    }

    /**
     * @override
     */
    protected getSecretStubPayload_(
        payload: Record<string, string>,
        key = 'uuid',
    ): Record<string, any> {
        const secretStubStr = this.getAjsDependency_('secretStubStr');

        if (this.id && (!payload.password || payload.password === secretStubStr)) {
            payload[key] = this.id;
            delete payload.username;
            delete payload.password;
            delete payload.proxy_pass;
        }

        return payload;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.ipamDnsProfileModalBreadcrumbTitle);
    }

    /**
     * Reset profiles. Called on IPAM type change.
     */
    private resetProfiles(): void {
        const config = this.getConfig();
        const defaultConfigHash = this.getDefaultConfig_();
        const { profileConfigPropNames } = IpamProfileItem;

        profileConfigPropNames.forEach(propName => {
            const defaultConfig = defaultConfigHash[propName] || {};
            let profile = defaultConfig;

            switch (propName) {
                case 'internal_profile':
                case 'infoblox_profile':
                case 'aws_profile':
                case 'openstack_profile':
                case 'azure_profile':
                case 'custom_profile':
                    profile = this.createChildByField_(propName);

                    break;
            }

            config[propName] = profile;
        });
    }
}
