/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './EndToEndTimeMetric.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ndgoc service
 * @name EndToEndTimeMetric
 * @description
 *
 *     Has a few series for VS, Pool and Server EndToEndTiming charts and infoBlocks. List of the
 *     series depends on VS type and item type. Other then that is an ordinary CollMetric with
 *     totalSeries.
 */
angular.module('aviApp').service('EndToEndTimeMetric', [
'CollMetric', 'l10nService',
function(CollMetric, l10nService) {
    l10nService.registerSourceBundles(dictionary);

    const
        clientRTTSeries = 'l4_client.avg_total_rtt',
        serverRTTSeries = 'l4_server.avg_total_rtt',
        appRespTimeSeries = 'l7_server.avg_application_response_time',
        dataTransTimeSeries = 'l7_client.avg_client_data_transfer_time';

    const allTimingTiles = [{
        id: 'clientRttIcon',
        sName: clientRTTSeries,
        icon: 'client',
        iconTitle: l10nKeys.clientTitle,
    }, {
        id: 'clientRtt',
        sName: clientRTTSeries,
        title: l10nKeys.clientRttTitle,
    }, {
        id: 'serverRttIcon',
        sName: serverRTTSeries,
        icon: 'lb',
        iconTitle: 'LB',
    }, {
        id: 'serverRtt',
        sName: serverRTTSeries,
        title: l10nKeys.serverRttTitle,
    }, {
        id: 'appRespTimeIcon',
        sName: appRespTimeSeries,
        icon: 'server',
        iconTitle: l10nKeys.serverTitle,
        highlighted: true,
    }, {
        id: 'appRespTime',
        sName: appRespTimeSeries,
        title: l10nKeys.appResponseTitle,
        highlighted: true,
    }, {
        id: 'appIcon',
        sName: appRespTimeSeries,
        icon: 'app',
        iconTitle: l10nKeys.appTitle,
        highlighted: true,
    }, {
        id: 'dataTransfer',
        sName: dataTransTimeSeries,
        title: l10nKeys.dataTransferTitle,
    }];

    /**
     * @class
     * @extends CollMetric
     **/
    return class EndToEndTimeMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;

            super(args);

            const
                sList = [],
                { item } = this;

            let vsAppType;

            switch (item.getItemType()) {
                case 'virtualservice':
                    vsAppType = item.appType();

                    //  e2e is not applicable for DNS VS with UDP service only
                    sList.push(
                        clientRTTSeries,
                        serverRTTSeries,
                    );

                    if (vsAppType !== 'l4' && vsAppType !== 'ssl' && vsAppType !== 'dns') {
                        sList.push(
                            appRespTimeSeries,
                            dataTransTimeSeries,
                        );
                    }

                    break;

                case 'pool':
                case 'server':
                    sList.push(serverRTTSeries);

                    vsAppType = args.vsAppType;

                    if (vsAppType !== 'l4' && vsAppType !== 'ssl' && vsAppType !== 'dns') {
                        sList.push(appRespTimeSeries);
                    }

                    break;
            }

            /**
             * @type {Array<string>}
             * @protected
             */
            this.sList_ = sList;

            this.addSeries(this.sList_);
        }

        /** @override */
        getTimingTilesConfig() {
            const
                { item } = this,
                tiles = angular.copy(allTimingTiles),
                config = {
                    header: l10nService.getMessage(l10nKeys.endToEndTimingHeader),
                    tiles: [],
                };

            tiles.forEach(tileConfig => {
                const { sName } = tileConfig;

                if (this.hasSeries(sName)) {
                    if (!('icon' in tileConfig)) {
                        tileConfig.series = this.getSeries(sName);
                    }

                    delete tileConfig.sName;

                    const title = tileConfig.title ? 'title' : 'iconTitle';

                    tileConfig[title] = l10nService.getMessage(tileConfig[title]);

                    config.tiles.push(tileConfig);
                }
            });

            // non http
            if (!this.hasSeries(appRespTimeSeries)) {
                config.tiles.push({
                    id: 'totalIcon',
                    icon: 'server',
                    iconTitle: l10nService.getMessage(l10nKeys.serverTitle),
                });
            }

            if (item.getItemType() === 'virtualservice') {
                config.tiles.push({
                    id: 'total',
                    title: l10nService.getMessage(l10nKeys.totalTimeTitle),
                    series: this.getMainSeries(),
                });
            }

            return config;
        }

        /**
         * Need to check the response for missing series and add the missing series
         * before processing it. As missing series will break the analytics graph.
         * This check is applicable for the first iteration only.
         * @override
         */
        processResponse(rsp) {
            // Check to skip the addition of missing series if api doesn't return data.
            if (!this.hasData() && rsp.series.length > 0) {
                rsp = this.addMissingSeries_(rsp);
            }

            super.processResponse(rsp);
        }

        /**
         * Add the missing series data from response for this Metric.
         * @param {Object} rsp - Series data.
         * @protected
         **/
        addMissingSeries_(rsp) {
            const { sList_: requiredSeriesNames } = this;
            const { series: seriesData } = rsp;
            const availableSeries = new Set();
            const seriesHeaderTemplate = {
                name: '',
                units: 'MILLISECONDS',
                metrics_min_scale: 10,
            };

            // Collecting the avilable series in the data
            seriesData.forEach(data => {
                availableSeries.add(data.header.name);
            });

            // Adding the missing series
            requiredSeriesNames.forEach(seriesName => {
                if (!availableSeries.has(seriesName)) {
                    const header = Object.assign({
                        name: seriesName,
                    }, seriesHeaderTemplate);

                    seriesData.push({
                        header,
                    });
                }
            });

            rsp.series = seriesData;

            return rsp;
        }
    };
}]);
