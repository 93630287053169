/**
 * Module containing tooltip directives and components.
 * @preferred
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import {
    AviHoverOverlayComponent,
    AviTooltipContentComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    AviTooltipTextComponent,
    SignpostComponent,
} from './components';

import {
    AviTitleDirective,
    AviTooltipDirective,
} from './directives';

const components = [
    AviHoverOverlayComponent,
    AviTooltipContentComponent,
    AviTooltipIconComponent,
    AviTooltipIconWithContentWrapperComponent,
    AviTooltipTextComponent,
    SignpostComponent,
];

const directives = [
    AviTitleDirective,
    AviTooltipDirective,
];

@NgModule({
    declarations: [
        ...components,
        ...directives,
    ],
    exports: [
        AviHoverOverlayComponent,
        AviTitleDirective,
        AviTooltipContentComponent,
        AviTooltipDirective,
        AviTooltipIconComponent,
        AviTooltipIconWithContentWrapperComponent,
        SignpostComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        OverlayModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TooltipModule {}
