/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @name eventsPageDirective
 * @memberOf module:avi/events
 * @property {boolean} transclude
 * @property {module:avi/events.EventsListController} controller
 */
angular.module('avi/events').directive('eventsPage', function() {
    return {
        restrict: 'AE',
        transclude: true,
        scope: true,
        controller: 'EventsListController',
        templateUrl: 'src/views/application/events-list.html',
    };
});
