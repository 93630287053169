/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ping-access-agent-modal';
const prefix = `${moduleName}.${componentName}`;

export const newPingAccessAgentHeader = `${prefix}.newPingAccessAgentHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const propertiesFileDataLabel = `${prefix}.propertiesFileDataLabel`;
export const propertiesFileDataPlaceholder = `${prefix}.propertiesFileDataPlaceholder`;

export const ENGLISH = {
    [newPingAccessAgentHeader]: 'New PingAccess Agent',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [propertiesFileDataLabel]: 'Import or Paste PingAccess Agent Properties',
    [propertiesFileDataPlaceholder]: 'Paste PingAccess Agent Properties',
};
