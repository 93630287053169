/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './upload-status.less';
import * as l10n from './uploadStatus.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * @ngdoc component
 * @name  uploadStatus
 * @param  {upload} Instance of Upload factory.
 * @description Used to display the status of the upload in progress.
 */

class UploadStatusController {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /**
         * Returns a formatted string of loaded bytes to total bytes. ex. '10KB of 50KB'.
         * @return {string} [description]
         */
        this.getProgressBytes = function() {
            const { uploadStatus } = this.upload;

            return l10nService.getMessage(l10nKeys.progressBytesPercentage,
                [Math.formatBytes(uploadStatus.loaded), Math.formatBytes(uploadStatus.total)]);
        };
    }
}

UploadStatusController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('uploadStatus', {
    bindings: {
        upload: '<',
    },
    controller: UploadStatusController,
    templateUrl: 'src/components/common/upload-status/upload-status.html',
});
