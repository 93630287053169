/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { AviPermissionResource, IUserAccountProfile } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { UserProfileModalComponent } from 'ng/modules/accounts';

import * as l10n from 'ajs/modules/accounts/accounts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IUserAccountProfileData {
    config: IUserAccountProfile
}

/**
 * @description UserProfile Item Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class UserProfileItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IUserAccountProfileData;
    public getConfig: () => IUserAccountProfile;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'useraccountprofile',
            windowElement: UserProfileModalComponent,
            objectType: 'UserAccountProfile',
            permissionName: AviPermissionResource.PERMISSION_USER,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.userProfileModalBreadcrumbTitle);
    }
}
