/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { RemoteAuthConfiguration } from 'object-types';

import * as l10n from './system-settings-auth-card.l10n';
import './system-settings-auth-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Authentication Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-auth-card',
    templateUrl: './system-settings-auth-card.component.html',
})
export class SystemSettingsAuthCardComponent {
    /**
     * Authprofile name(s) to be displayed in authentication card.
     */
    @Input()
    public authProfiles: string[] = [];

    /**
     * Authmapping profile name(s) to be displayed in authentication card.
     */
    @Input()
    public authMappingProfiles: string[] = [];

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Getter flag for remote authentication.
     */
    public remoteAuthentication: boolean;

    /**
     * Object type for template usage.
     */
    public readonly objectType = RemoteAuthConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting remoteAuthentication on initialization.
     */
    public ngOnInit(): void {
        this.setRemoteAuthentication();
    }

    /**
     * @override
     * Setting remoteAuthentication on changes detected.
     */
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        const { authProfiles } = simpleChanges;

        if (authProfiles && !authProfiles.isFirstChange()) {
            this.setRemoteAuthentication();
        }
    }

    /**
     * Track by index method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Setter for remote authentication flag.
     */
    private setRemoteAuthentication(): void {
        this.remoteAuthentication = Boolean(this.authProfiles?.length);
    }
}
