/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IPolicyActionInfo } from '../../policies.types';

import { PolicyMatchOrActionWrapperConfigItem } from '.';

export const POLICY_ACTION_CONFIG_ITEM_TOKEN = 'PolicyActionConfigItem';

/**
 * @alias PolicyActionConfigItem
 * @description
 *
 *     Abstract Policy Action ConfigItem class. Maps protobuf messages that contain action messages.
 *     Eg. L4RuleAction that contains L4RuleActionSelectPool, DnsRuleAction that contains
 *     DnsRuleActionAllowDrop, DnsRuleActionResponse, etc. This class doesn't check if an action
 *     is a RepeatedMessageItem when it comes to operations of adding/deleting since a specific type
 *     of action only has one occurence.
 *
 * @author Zhiqian Liu
 */
export abstract class PolicyActionConfigItem<T = any>
    extends PolicyMatchOrActionWrapperConfigItem<T> {
    /**
     * Getter for supported action field names.
     */
    public get supportedActions(): string[] {
        return this.fields;
    }

    /**
     * Return the number of action configured.
     */
    public get actionCount(): number {
        return this.entryCount;
    }

    /**
     * Return true if the action exists in this.config.
     * Defined as an arrow function because member function "this" is not preserved when being used
     * as a callback in Underscore or Array methods.
     * @param actionFieldName - Name of the action field name.
     */
    public hasActionByField = (actionFieldName: string): boolean => {
        return this.hasEntryByField(actionFieldName);
    };

    /**
     * Adds an empty action ConfigItem.
     * @param actionFieldName - Name of the field of the action to add.
     */
    public addAction(actionFieldName: string): void {
        this.addEntry(actionFieldName);
    }

    /**
     * Remove the action from the configuration.
     * @param actionFieldName - Name of the field of the action to remove.
     */
    public removeAction(actionFieldName: string): void {
        this.removeEntry(actionFieldName);
    }

    /**
     * Get list of configured actions with label/value pairs.
     */
    public getConfiguredActionInfoList(): IPolicyActionInfo[] {
        const configuredActions = this.getConfiguredActionFields();

        return configuredActions.map(actionFieldName => {
            return {
                actionLabel: this.getActionLabel(actionFieldName),
                actionValue: this.getActionValue(actionFieldName),
            };
        });
    }

    /**
     * Return value for an action field.
     * @param field Any one of the supportedActions.
     */
    public getActionValue(field: string): string {
        const {
            config: actionConfig,
        } = this;

        return actionConfig[field].actionValue;
    }

    /**
     * Return label for an action field.
     * @param field Any one of the supportedActions.
     */
    public getActionLabel(field: string): string {
        const {
            config: actionConfig,
        } = this;

        return actionConfig[field].actionLabel;
    }

    /**
     * Pick only action fields that are configured in the rule.
     */
    private getConfiguredActionFields(): string[] {
        return this.supportedActions.filter(
            (field: string) => this.hasActionByField(field),
        ) || [];
    }
}
