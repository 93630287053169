/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { DnsServiceApplicationProfile } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    IDnsServiceApplicationProfile,
    IDnsZone,
} from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

type TDnsServiceApplicationProfilePartial = Omit<IDnsServiceApplicationProfile, 'dns_zones'>;

interface IDnsServiceApplicationProfileConfig extends TDnsServiceApplicationProfilePartial {
    dns_zones: RepeatedMessageItem<MessageItem<IDnsZone>>,
}

/**
 * @desc DnsServiceApplicationProfile MessageItem class.
 * @author Nisar Nadaf
 */
export class DnsServiceApplicationProfileConfigItem
    extends MessageItem<IDnsServiceApplicationProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: DnsServiceApplicationProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Add new dns zone messageItem.
     */
    public addDnsZone(): void {
        this.config.dns_zones.add();
    }

    /**
     * Remove given messageItem from dns_zones repeatedmessageItems.
     */
    public removeDnsZone(dnsZone: MessageItem<IDnsZone>): void {
        this.config.dns_zones.removeByMessageItem(dnsZone);
    }
}
