/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'usable-subnet-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Usable Subnet: {0}',
    [modalTitleNew]: 'Create Usable Subnet: {0}',
};
