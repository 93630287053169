/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'data-script';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const eventTypesLabel = `${prefix}.eventTypesLabel`;

export const ENGLISH = {
    [headerLabel]: 'DataScripts',
    [eventTypesLabel]: 'Event Types',
};
