/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';

import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';

import {
    Observable,
    of,
} from 'rxjs';

import {
    catchError,
    map,
    mergeMap,
} from 'rxjs/operators';

import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import * as ControllerSiteActions from './controller-site.actions';

import {
    controllerSiteInitialState,
    IControllerSiteApiResponse,
} from './controller-site.state';

const CONTROLLER_SITE_URL = '/api/controllersite';

@Injectable()
export class ControllerSiteEffects {
    public loadControlllerSite$ = createEffect(() => this.actions$.pipe(
        ofType(ControllerSiteActions.loadControllerSite),
        mergeMap(action => this.getControllerSite(action.hostName)
            .pipe(
                map(
                    (controllerSiteData: IControllerSiteApiResponse) => {
                        const { results } = controllerSiteData;
                        let controllerSiteState = controllerSiteInitialState;

                        if (results?.length) {
                            controllerSiteState = results[0];
                        }

                        const title = this.titleService.getTitle().split('(')[0]?.trim();
                        const siteName = controllerSiteState.name;
                        const newTitle = siteName ? `${title} (${siteName})` : `${title}`;

                        this.titleService.setTitle(newTitle);

                        return ControllerSiteActions
                            .setControllerSite({ controllerSiteState });
                    },
                ),
                catchError(error => {
                    this.devLoggerService.error(error.data);

                    return of(ControllerSiteActions
                        .setControllerSite({ controllerSiteState: controllerSiteInitialState }));
                }),
            )),
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly devLoggerService: DevLoggerService,
        private titleService: Title,
        private readonly http: HttpClient,
    ) {
    }

    /**
     * Fetch ControllerSite configuration.
     */
    private getControllerSite(host: string): Observable<IControllerSiteApiResponse> {
        return this.http.get<IControllerSiteApiResponse>(
            CONTROLLER_SITE_URL,
            { params: { address: host } },
        );
    }
}
