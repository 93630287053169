/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
    Inject,
    Input,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';

import {
    carriageReturn,
    carriageReturnLineFeedWEscape,
    lineFeed,
} from 'ng/utils/regex.utils';

import * as l10n from './newline-converted-textarea.l10n';
import './newline-converted-textarea.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *   Allows user to enter text in text-area, and see the computed value in
 *   adjacent box which will be sent through API.
 *   Primary use is to deal with a browser's or OS's manipulation
 *   of stored strings.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'newline-converted-textarea',
    templateUrl: './newline-converted-textarea.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NewlineConvertedTextareaComponent),
        },
    ],
})
export class NewlineConvertedTextareaComponent implements ControlValueAccessor {
    /**
     * Object type used in template.
     */
    @Input()
    public objectType: string;

    /**
     * Field name for which text-area is shown.
     */
    @Input()
    public fieldName: string;

    /**
     * Placeholder text for the user input text area.
     */
    @Input()
    public placeholder: string;

    /**
     * Used to toggle between wordwrap and non-wordwrap version in read-only view.
     */
    public showPureModel = true;

    /**
     * Holds text area model value.
     */
    public viewValue: string;

    /**
     * Used to show wordwrap and non-wordwrap model version of computed value in read-only view
     * which will be sent through API.
     */
    public readOnlyOutput = '';

    /**
     * Used to check if text is copied to clipboard.
     */
    public isTextCopied = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    public modelValue: string;

    constructor(
        l10nService: L10nService,
        @Inject('clipboardService')
        private readonly clipboardService: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for user input in text area.
     */
    public handleUserInputChange(): void {
        let value = this.viewValue;

        if (value) {
            const pattern = new RegExp(carriageReturnLineFeedWEscape, 'g');
            const replaceVal = '\r\n';

            value = this.viewValue.replace(pattern, replaceVal);
        }

        this.modelValue = value;

        this.updateReadOnlyOutput();
        this.onChange(this.modelValue);
        this.onTouched();
    }

    /**
     * Toggles wordwrap:on/off.
     */
    public toggleReadOnlyView(): void {
        this.showPureModel = !this.showPureModel;
        this.updateReadOnlyOutput();
    }

    /**
     * Function to copy data to clipboard.
     */
    public copyToClipboard(target: EventTarget, copyReadOnlyOutput = false): void {
        // readOnlyOutput will have string html as well so need to
        // parse html before copying the text.
        const data = copyReadOnlyOutput ? this.parseHtml(this.readOnlyOutput) : this.viewValue;

        this.clipboardService.copy(data, target);
        this.isTextCopied = true;
    }

    /**
     * Handler for mouseout event on copy to clipboard icon.
     */
    public onMouseOutHandler(): void {
        this.isTextCopied = false;
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: string) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: string): void {
        this.viewValue = value;
        this.modelValue = value;

        this.updateReadOnlyOutput();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: string): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /**
     * Method to parse html string.
     */
    private parseHtml(htmlString: string): string {
        const parser = new DOMParser();
        const html = parser.parseFromString(htmlString, 'text/html');
        const { documentElement: { textContent } } = html;

        return textContent;
    }

    /**
     * Updates value to be shown on right read-only window.
     * Is same as actual model value to be shown, but allows toggle between wordwrap:on/off.
     */
    private updateReadOnlyOutput(): void {
        let result = '';

        if (!this.modelValue) {
            this.readOnlyOutput = '';

            return;
        }

        if (this.showPureModel) {
            result = this.modelValue;
        } else {
            const pattern = /\r\n/g;
            const replaceVal = '\r\n<br/>';

            result = this.modelValue.replace(pattern, replaceVal);
        }

        const patternCr = new RegExp(carriageReturn, 'g');
        const patternLf = new RegExp(lineFeed, 'g');
        const replaceValCr = '<span class="newline-converted-textarea__emphasized">\\r</span>';
        const replaceValLf = '<span class="newline-converted-textarea__emphasized">\\n</span>';

        if (result) {
            result = result.replace(patternCr, replaceValCr);
            result = result.replace(patternLf, replaceValLf);
        }

        this.readOnlyOutput = result || '';
    }
}
