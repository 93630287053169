/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'tcp-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleTcp = `${prefix}.sectionTitleTcp`;
export const clientRequestHeaderInputLabel = `${prefix}.clientRequestHeaderInputLabel`;
export const clientRequestHeaderInputPlaceholder = `${prefix}.clientRequestHeaderInputPlaceholder`;
export const tcpHalfOpenInputLabel = `${prefix}.tcpHalfOpenInputLabel`;
export const serverResponseDataInputLabel = `${prefix}.serverResponseDataInputLabel`;
export const serverResponseDataInputPlaceholder = `${prefix}.serverResponseDataInputPlaceholder`;
export const maintenanceResponseDataInputLabel = `${prefix}.maintenanceResponseDataInputLabel`;
export const maintenanceResponseDataInputPlaceholder = `${prefix}.maintenanceResponseDataInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleTcp]: 'TCP',
    [clientRequestHeaderInputLabel]: 'Client Request Header',
    [clientRequestHeaderInputPlaceholder]: 'Enter Client Request Header',
    [tcpHalfOpenInputLabel]: 'Half-Open (Close connection before completion)',
    [serverResponseDataInputLabel]: 'Server Response Data',
    [serverResponseDataInputPlaceholder]: 'Enter Server Response Data',
    [maintenanceResponseDataInputLabel]: 'Maintenance Server Response Data',
    [maintenanceResponseDataInputPlaceholder]: 'Enter Response Data',
};
