/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description Controls opening and closing the search bar dropdown.
 * @author Alex Klyuev
 */
@Injectable()
export class VsLogsSearchBarDropdownService implements OnDestroy {
    /**
     * Subject to control dropdown. True indicates open and false indicates close.
     */
    public readonly dropdownControl$ = new Subject<boolean>();

    /**
     * Subject to emit submission events.
     */
    public readonly submit$ = new Subject<void>();

    /**
     * Subject to emit that the dropdown stage has changed.
     */
    public readonly dropdownStageChange$ = new Subject<void>();

    /** @override */
    public ngOnDestroy(): void {
        this.dropdownControl$.complete();
        this.submit$.complete();
    }

    public openDropdown(): void {
        this.dropdownControl$.next(true);
    }

    public closeDropdown(): void {
        this.dropdownControl$.next(false);
    }

    public submit(): void {
        this.submit$.next();
    }

    /**
     * Emit a submit event upon a user click outside of the dropdown.
     */
    public handleDropdownBackdropClick(): void {
        this.submit();
    }

    /**
     * Reopen the dropdown and emit a dropdown stage change event.
     */
    public changeDropdownStage(): void {
        this.closeDropdown();
        this.openDropdown();
        this.dropdownStageChange$.next();
    }
}
