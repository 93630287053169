/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';
import { IIpAddrPrefix } from 'generated-types';

import {
    IpAddrConfigItem,
    MessageItem,
} from '.';

interface IIpAddrPrefixConfig extends IIpAddrPrefix {
    ip_addr: IpAddrConfigItem;
    flatProps: IIpAddrPrefix;
}

/**
 * @description IpAddrPrefix MessageItem.
 *
 * @author Alex Tseung, Aravindh Nagarajan
 */
export class IpAddrPrefixConfigItem extends MessageItem<IIpAddrPrefixConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddrPrefix',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IIpAddrPrefixConfig> {
        return {
            ip_addr: null,
        };
    }

    /**
     * Returns true if the fields are populated.
     */
    public isValid(): boolean {
        return Boolean(this.address) && !isUndefined(this.mask);
    }

    /**
     * Returns a string representation of the subnet address and mask. If the mask property
     * exists in this.data.config, we output a slash.
     */
    public get subnet(): string | undefined {
        const { address, mask } = this;
        let suffix;

        if (mask === 0 || mask) {
            suffix = `/${mask}`;
        } else {
            suffix = '';
        }

        return address && address + suffix || undefined;
    }

    /**
     * Sets the address and mask properties in the config. If the subnetString contains a slash,
     * we populate mask with either an empty string or the mask number. Otherwise, we delete the
     * mask property. The presence of the mask property helps us determine whehter or not we
     * should show a slash in the output string.
     */
    public set subnet(subnetString: string) {
        let address;
        let mask = NaN;

        if (typeof subnetString === 'string') {
            const subnetParts = subnetString.split('/');

            if (subnetParts.length === 2 && subnetParts[1]) {
                [address] = subnetParts;
                mask = Number(subnetParts[1]);
            }
        }

        this.address = address;
        this.mask = mask;

        if (this.config.flatProps) {
            this.updateFlatProps();
        }
    }

    /**
     * Sets the address property in the IpAddrConfigItem config.
     */
    public set address(address: string) {
        const { ip_addr: ipAddr } = this.config;

        ipAddr.address = address;
    }

    /**
     * Returns the address of the IpAddrConfigItem config.
     */
    public get address(): string {
        const { ip_addr: ipAddr } = this.config;

        return ipAddr.address;
    }

    /**
     * Sets the mask property in the config.
     */
    public set mask(mask: number) {
        this.config.mask = mask;
    }

    /**
     * Returns the mask property from the config.
     */
    public get mask(): number {
        return this.config.mask;
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const { ip_addr: ipAddr } = this.config;

        return `${ipAddr}/${this.mask}`;
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        this.config.flatProps = {};
        this.updateFlatProps();
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        const { ip_addr: ipAddr } = this.config;
        const { flatProps } = this.config;

        if (flatProps) {
            ipAddr.updateConfig(flatProps.ip_addr);

            this.mask = flatProps.mask;
        }

        delete this.config.flatProps;
    }

    /**
     * Sets flatProps on this.config.
     * flatProps is used to allow passing a flat object as the ngModel (legacy).
     * We need to keep flatProps updated so that the real config doesn't get
     * overwritten with stale flatProps data in modifyConfigDataBeforeSave.
     */
    private updateFlatProps(): void {
        const { ip_addr: ipAddr } = this.config;
        const { flatProps } = this.config;

        flatProps.mask = this.mask;
        flatProps.ip_addr = ipAddr.config;
    }
}
