/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module PoolModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Nitesh Kesarkar
 */

/**
 * Ajs dependency token for PoolGroupDeploymentPolicy.
 */
export const POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN = 'PoolGroupDeploymentPolicy';

/**
 * Ajs dependency token for PoolGroupDeploymentPolicyCollection.
 */
export const POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN = 'PoolGroupDeploymentPolicyCollection';

/**
 * Ajs dependency token for PoolGroupMemberConfigItem.
 */
export const POOL_GROUP_MEMBER_CONFIG_ITEM_TOKEN = 'PoolGroupMemberConfigItem';

/**
 * Ajs dependency token for PoolGroupCollection.
 */
export const POOL_GROUP_COLLECTION_TOKEN = 'PoolGroupCollection';

/**
 * Ajs dependency token for PoolCollection.
 */
export const POOL_COLLECTION_TOKEN = 'PoolCollection';

/**
 * Ajs dependency token for PoolGroup.
 */
export const POOL_GROUP_ITEM_TOKEN = 'PoolGroup';

/**
 * Ajs dependency token for Pool.
 */
export const POOL_ITEM_TOKEN = 'Pool';

/**
 * Ajs dependency token for FailAction.
 */
export const FAIL_ACTION_CONFIG_ITEM_TOKEN = 'FailActionConfigItem';

/**
 * Ajs dependency token for IFailActionHttpRedirectConfigItem.
 */
export const FAIL_ACTION_HTTP_REDIRECT_CONFIG_ITEM_TOKEN = 'FailActionHTTPRedirectConfigItem';

/**
 * Ajs dependency token for IFailActionHttpLocalResponseConfigItem.
 */
export const FAIL_ACTION_HTTP_LOCAL_RESPONSE_CONFIG_ITEM_TOKEN =
'FailActionHTTPLocalResponseConfigItem';

/**
 * Ajs dependency token for Server.
 */
export const SERVER_CONFIG_ITEM_TOKEN = 'ServerConfigItem';

/**
 * Ajs dependency token for HTTPServerReselect.
 */
export const HTTP_SERVER_RESELECT_CONFIG_ITEM_TOKEN = 'HTTPServerReselectConfigItem';

/**
 * Ajs dependency token for HTTPReselectRespCode.
 */
export const HTTP_RESELECT_RESP_CODE_CONFIG_ITEM_TOKEN = 'HTTPReselectRespCodeConfigItem';

/**
 * Ajs dependency token for HTTPReselectRespCode.
 */
export const HTTP_STATUS_RANGE_CONFIG_ITEM_TOKEN = 'HTTPStatusRangeConfigItem';
