/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name triCheckboxSet
 * @description
 *     Used to manage a set of checkboxes. The parent checkbox is used to represent the state of the
 *     checkbox children. If all children are checked, the parent shows checked. If all children are
 *     unchecked, the parent shows unchecked. If some children are checked and others unchecked, the
 *     parent shows an indeterminate state.
 *
 *     If an ngModel is specified on the parent, it should be synced with the state of the children.
 *     This component will update the ngModel based on either clicks on the parent checkbox or
 *     clicks on the children checkbox. The ngModel value will be true for checked and indeterminate
 *     states, and false for the unchecked state.
 *
 *     Does not support checkbox children that dynamically become hidden.
 * @param {string} label - Label to be used for the parent checkbox.
 * @param {string} aviHelp - Help text to be displayed on the help icon for the parent checkbox.
 * @param {Object=} ngModel - Optional ngModel controller.
 *
 * @example
 * <tri-checkbox-set
 *     label="Tri-Checkbox"
 *     ng-model="value">
 *     <checkbox ng-model="first"></checkbox>
 *     <checkbox ng-model="second"></checkbox>
 *     <checkbox ng-model="third"></checkbox>
 * </tri-checkbox-set>
 */
class TriCheckboxSetController {
    constructor() {
        this.checkboxes = {};
    }

    /**
     * Called by the child checkbox directive. Adds its scope to the this.checkboxes hash.
     * @param {string} attr - ngModel of the child checkbox to be used as a key.
     * @param {Object} scope - scope of the child checkbox to access the ngModel value.
     * @public
     */
    addCheckbox(attr, scope) {
        this.checkboxes[attr] = scope;
    }

    /**
     * Called by the checkbox children when their value has been modified. Used to update the
     * parent ngModel.
     * @public
     */
    onChange() {
        if (this.ngModel) {
            // ngModel should be true if any checkbox children are checked.
            const value = this._hasAnyChecked();

            this.ngModel.$setViewValue(value);
            this.ngModel.$render();
        }
    }

    /**
     * Handles clicking of the <span> element that looks like a checkbox. If checked, goes to
     * unchecked. If partial or unchecked, goes to checked.
     */
    handleClick() {
        const checked = this._hasAllChecked();

        _.each(this.checkboxes, checkbox => checkbox.ngModel = !checked);

        if (this.ngModel) {
            this.ngModel.$setViewValue(!checked);
            this.ngModel.$render();
        }
    }

    /**
     * Returns true if all checkbox children are set to true. False otherwise.
     * @return {boolean}
     */
    _hasAllChecked() {
        return _.all(this.checkboxes, checkbox => checkbox.ngModel === true);
    }

    /**
     * Returns true if any checkbox children are set to true. False otherwise.
     * @return {boolean}
     */
    _hasAnyChecked() {
        return _.any(this.checkboxes, checkbox => checkbox.ngModel === true);
    }

    /**
     * Returns true if the checkbox should look checked.
     * @return {boolean}
     */
    isChecked() {
        return this._hasAllChecked();
    }

    /**
     * Returns true if the checkbox should look partially-checked.
     * @return {boolean}
     */
    isIndeterminate() {
        return !this._hasAllChecked() && this._hasAnyChecked();
    }

    /**
     * Returns a state used for the triCheckbox component.
     * @return {number}
     */
    getState() {
        if (this._hasAllChecked()) {
            return 1;
        }

        if (this.isIndeterminate()) {
            return 2;
        }

        return 0;
    }
}

angular.module('aviApp').component('triCheckboxSet', {
    controller: TriCheckboxSetController,
    require: {
        ngModel: '?ngModel',
    },
    bindings: {
        label: '@',
        aviHelp: '<',
    },
    transclude: true,
    templateUrl: 'src/components/common/tri-checkbox-set/tri-checkbox-set.html',
});
