/**
 * @module Upgrade
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * Enum of image types.
 */
export enum ImageCategory {
    IMAGE_CATEGORY_CONTROLLER = 'Controller',
    IMAGE_CATEGORY_SE = 'Service Engine',
    IMAGE_CATEGORY_HYBRID = 'Controller & Service Engine',
}

/**
 * Hash for image category enums.
 * For .js file use ONLY.
 */
export const imageCategoryHash = {
    IMAGE_CATEGORY_CONTROLLER: 'Controller',
    IMAGE_CATEGORY_SE: 'Service Engine',
    IMAGE_CATEGORY_HYBRID: 'Controller & Service Engine',
};

/**
 * Hash for image type enums.
 * For .js file use ONLY.
 */
export const imageTypeHash = {
    IMAGE_TYPE_SYSTEM: 'IMAGE_TYPE_SYSTEM',
    IMAGE_TYPE_PATCH: 'IMAGE_TYPE_PATCH',
};

export const upgradeTypeHash = {
    UPGRADE_TYPE_SYSTEM_UPGRADE: 'UPGRADE_TYPE_SYSTEM_UPGRADE',
    UPGRADE_TYPE_SYSTEM_ROLLBACK: 'UPGRADE_TYPE_SYSTEM_ROLLBACK',
    UPGRADE_TYPE_SEG_UPGRADE: 'UPGRADE_TYPE_SEG_UPGRADE',
    UPGRADE_TYPE_SEG_ROLLBACK: 'UPGRADE_TYPE_SEG_ROLLBACK',
};

export const rollbackTypeHash = {
    ROLLBACK_TYPE_IMAGE: 'ROLLBACK_TYPE_IMAGE',
    ROLLBACK_TYPE_PATCH: 'ROLLBACK_TYPE_PATCH',
};

/**
 * Node types for upgrade as defined in protobuf. Needed in both ts and js.
 * Defined as enum upgrade.type.ts for use in ts files.
 * Defined here for use in js files.
 * @type {module:avi/ts.UpgradeNodeType}
 */
export const UpgradeNodeType = {
    NODE_CONTROLLER_CLUSTER: 'NODE_CONTROLLER_CLUSTER',
    NODE_SE_GROUP: 'NODE_SE_GROUP',
    NODE_SE_TYPE: 'NODE_SE_TYPE',
};

export const upgradeStateGroupTypes = {
    UPGRADE_STATE_GROUP_INIT: 'UPGRADE_FSM_UI_INIT',
    UPGRADE_STATE_GROUP_IN_PROGRESS: 'UPGRADE_FSM_UI_IN_PROGRESS',
    UPGRADE_STATE_GROUP_COMPLETED: 'UPGRADE_FSM_UI_COMPLETED',
    UPGRADE_STATE_GROUP_ERROR: 'UPGRADE_FSM_UI_ERROR',
};
