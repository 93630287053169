/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

const moduleName = 'ajs-legacy';
const componentName = 'match';
const prefix = `${moduleName}.${componentName}`;

export const clientIpLabel = `${prefix}.clientIpLabel`;

export const ENGLISH = {
    [clientIpLabel]: 'Client IP',
};
