/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'radius-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleRadius = `${prefix}.sectionTitleRadius`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const sharedSecretInputLabel = `${prefix}.sharedSecretInputLabel`;
export const sharedSecretInputPlaceholder = `${prefix}.sharedSecretInputPlaceholder`;
export const monitorPortInputLabel = `${prefix}.monitorPortInputLabel`;
export const monitorPortInputPlaceholder = `${prefix}.monitorPortInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleRadius]: 'Radius',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
    [sharedSecretInputLabel]: 'Shared Secret',
    [sharedSecretInputPlaceholder]: 'Enter Shared Secret',
    [monitorPortInputLabel]: 'Monitor Port',
    [monitorPortInputPlaceholder]: 'Enter Monitor Port',
};
