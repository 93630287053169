/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name NetworkSecurityPolicyDosCollection
 * @description
 *     Collection of rules of blocked addresses.
 */
angular.module('aviApp').factory('NetworkSecurityPolicyDosCollection', ['Collection',
'NetworkSecurityPolicyDos',
(Collection, NetworkSecurityPolicyDos) => {
    const NetworkSecurityPolicyDosCollection = class extends Collection {
        constructor(args) {
            super(args);

            /**
             * Hash used to determine if an address has been blocked.
             * @type {Object}
             */
            this.blockedAddressesHash = {};
        }

        isCreatable() {
            return false;
        }
    };

    angular.extend(NetworkSecurityPolicyDosCollection.prototype, {
        objectName_: 'networksecuritypolicydos',
        itemClass_: NetworkSecurityPolicyDos,
        allDataSources_: {
            list: {
                source: 'ListCollDataSource',
                transformer: 'NetworkSecurityPolicyDosDataTransformer',
                transport: 'ListDataTransport',
                fields: ['config'],
            },
        },
        isStatic_: false,
    });

    return NetworkSecurityPolicyDosCollection;
}]);
