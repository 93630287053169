/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'vh-match-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Rule',
    [modalTitleNew]: 'New Rule',
};
