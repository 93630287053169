/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';

import { ObservableList } from 'ng/shared/utils/observable-list.utils';

/**
 * Interface to represent props required to render app level alert.
 */
export interface IAviAppLevelAlertsProps {
    id: string;
    component?: Type<Component>;
    componentProps?: Record<string, any>;
}

/**
 * @description Service for adding and removing app level alerts.
 * @author Nisar Nadaf
 */
@Injectable({
    providedIn: 'root',
})
export class AppLevelAlertsService extends ObservableList<IAviAppLevelAlertsProps, string> {
    protected getID(item: IAviAppLevelAlertsProps): string {
        return item.id;
    }
}
