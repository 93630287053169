/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import { Injector } from '@angular/core';

import {
    ErrorPageBodyCollection,
    ErrorPageProfileCollection,
    TErrorPageBodyCollection,
    TErrorPageProfileCollection,
} from 'ajs/modules/error-page/factories';

import {
    ERROR_PAGE_BODY_COLLECTION_TOKEN,
    ERROR_PAGE_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/error-page/error-page.tokens';

const $injector = '$injector';

export const errorPageBodyCollectionProvider = {
    deps: [$injector],
    provide: ErrorPageBodyCollection,
    useFactory(injector: Injector): TErrorPageBodyCollection {
        return injector.get(ERROR_PAGE_BODY_COLLECTION_TOKEN);
    },
};

export const errorPageProfileCollectionProvider = {
    deps: [$injector],
    provide: ErrorPageProfileCollection,
    useFactory(injector: Injector): TErrorPageProfileCollection {
        return injector.get(ERROR_PAGE_PROFILE_COLLECTION_TOKEN);
    },
};
