/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import { Component } from '@angular/core';

/**
 * @description Component for Access Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-config-container',
    templateUrl: './system-settings-access-config-container.component.html',
})
export class SystemSettingsAccessConfigContainerComponent {

}
