/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import inSubnet from 'insubnet';

export function parseSubnetBaseIp(value: string): string {
    let ip: number;
    let prefix: number;
    let res: string;
    let baseIP: number;

    const parts = value.split('/');

    if (parts.length === 2 && inSubnet.isIP(parts[0]) && !Number.isNaN(+parts[1])) {
        ip = inSubnet.toInt(parts[0]);
        prefix = +parts[1];
        /**
         * Applies bitwise operations and converts network bitcount to netmask.
         * Gets the parsed IP using int method of insubnet library.
         */

        /* tslint:disable:no-bitwise */
        baseIP = inSubnet.toInt(ip & 0xFFFFFFFF << 32 - prefix & 0xFFFFFFFF, 0);
        res = `${baseIP}/${prefix}`;
    }

    return res || null;
}
