/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import {
    VirtualServiceStateService,
} from 'ng/modules/virtual-service/services/virtual-service-state.service';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import { VsLogsStore } from '../../../services/vs-logs.store';
import { VsAppProfileType } from '../../../vs-logs.types';

import * as l10n from './vs-log-cinematic-top-info.component.l10n';
import './vs-log-cinematic-top-info.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component used for showing the top info section of the log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-top-info',
    templateUrl: 'vs-log-cinematic-top-info.component.html',
})
export class VsLogCinematicTopInfoComponent {
    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public readonly vsAppProfileType$ = this.vsLogsStore.vsAppProfileType$;

    public readonly VsAppProfileType = VsAppProfileType;

    public readonly clientRtt$ = this.vsLogCinematicStore.clientRtt$;

    public readonly serverRtt$ = this.vsLogCinematicStore.serverRtt$;

    public readonly responseCode$ = this.vsLogCinematicStore.responseCode$;

    public readonly serverResponseCode$ = this.vsLogCinematicStore.serverResponseCode$;

    public readonly appResponseTime$ = this.vsLogCinematicStore.appResponseTime$;

    public readonly dataTransferTime$ = this.vsLogCinematicStore.dataTransferTime$;

    public readonly totalTime$ = this.vsLogCinematicStore.totalTime$;

    public readonly clientIps$ = this.vsLogCinematicStore.clientIps$;

    public readonly clientSrcPort$ = this.vsLogCinematicStore.clientSrcPort$;

    public readonly clientDestPort$ = this.vsLogCinematicStore.clientDestPort$;

    public readonly vsIps$ = this.vsLogCinematicStore.vsIps$;

    public readonly serverIps$ = this.vsLogCinematicStore.serverIps$;

    public readonly serverSrcPort$ = this.vsLogCinematicStore.serverSrcPort$;

    public readonly serverDestPort$ = this.vsLogCinematicStore.serverDestPort$;

    public readonly sourceIps$ = this.vsLogCinematicStore.sourceIps$;

    public readonly serverConnectionSrcIps$ = this.vsLogCinematicStore.serverConnectionSrcIps$;

    public readonly serviceEngine$ = this.vsLogCinematicStore.serviceEngine$;

    public readonly vcpuId$ = this.vsLogCinematicStore.vcpuId$;

    public readonly pool$ = this.vsLogCinematicStore.pool$;

    public readonly poolName$ = this.vsLogCinematicStore.poolName$;

    public readonly serverName$ = this.vsLogCinematicStore.serverName$;

    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any;

    constructor(
        l10nService: L10nService,
        vsStateService: VirtualServiceStateService,
        private readonly vsLogsStore: VsLogsStore,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.vs = vsStateService.virtualService;
    }
}
