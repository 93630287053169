/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function clusterNodeDataTransformerFactory(RevisedDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @author Ashish Verma
     * @desc RevisedDataTransformer of {@link module:avi/app.ClusterNode ClusterNode}.
     */
    class ClusterNodeDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            let results = [],
                clusterIp;

            if (resp.data && typeof resp.data === 'object') {
                if ('nodes' in resp.data) {
                    clusterIp = resp.data.virtual_ip && resp.data.virtual_ip.addr;

                    results = _.map(resp.data['nodes'], function(nodeConfigData) {
                        return {
                            config: {
                                name: nodeConfigData.name,
                                ip: nodeConfigData.ip.addr,
                                cluster_ip: clusterIp,
                                public_ip_or_name: nodeConfigData.public_ip_or_name,
                            },
                        };
                    });
                } else if ('node_states' in resp.data) {
                    const clusterState = resp.data.cluster_state?.state;

                    results = _.map(resp.data['node_states'], function(nodeRuntimeData) {
                        return {
                            config: {
                                name: nodeRuntimeData.name,
                            },
                            runtime: {
                                role: nodeRuntimeData.role,
                                state: nodeRuntimeData.state,
                                cluster_state: clusterState,
                                cluster_vip_status: resp.data?.cluster_vip_runtime_status
                                    ?.cluster_vip_status,
                            },
                        };
                    });
                }

                resp.data.results = results;
                resp.data.count = results.length;
            }

            return resp;
        }
    }

    return ClusterNodeDataTransformer;
}

clusterNodeDataTransformerFactory.$inject = [
    'RevisedDataTransformer',
];

angular.module('avi/app')
    .factory(
        'ClusterNodeDataTransformer',
        clusterNodeDataTransformerFactory,
    );
