/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { JwtMatchConfigItem }
    from 'ajs/modules/match/factories/jwt-match.config-item.factory';
import { JwtClaimMatchConfigItem }
    from 'ajs/modules/match/factories/jwt-claim-match.config-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './jwt-match.l10n';
import './jwt-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Match component for JwtMatch.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'jwt-match',
    templateUrl: './jwt-match.component.html',
})
export class JwtMatchComponent implements OnInit {
    /**
     * JwtMatchConfigItem instance.
     */
    @Input()
    public editable: JwtMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex: number;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type used in template.
     */
    public matchObjectType: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.matchObjectType = this.editable.messageType;
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Adds new claim entry.
     */
    public addClaimName(): void {
        this.editable.matches.add();
    }

    /**
     * Removes claim entry.
     */
    public deleteClaim(claim: JwtClaimMatchConfigItem): void {
        this.editable.matches.removeByMessageItem(claim);
    }
}
