/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './client-ip-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class ClientIpMatchComponent {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

ClientIpMatchComponent.$inject = [
    'l10nService',
];

/**
 * @ngdoc component
 * @name  clientIpMatch
 * @module match/clientIpMatch
 * @description Component for configuring a ClientIpMatch Config Item.
 * @param {ClientIpMatchConfigItem} clientIpMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('clientIpMatch', {
    bindings: {
        clientIpMatch: '<',
        preventEdit: '<',
    },
    controller: ClientIpMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/' +
        'client-ip-match/client-ip-match.component.html',
});
