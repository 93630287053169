/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import moment from 'moment';
import { IScheduledScaling } from 'generated-types';
import { ScheduledScaling } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    generateCronExpression,
    parseCronExpression,
} from './scheduled-scaling.utils';

import * as l10n from '../auto-scale.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const pickerDateFormat = 'YYYY-MM-DD';
const isoDateFormat = 'YYYYMMDDTHHmmss';

/**
 * @description Scheduled Scaling config Item.
 *
 * @author Harmeet Kaur
 */

export interface IExtendedScheduledScaling extends Omit<IScheduledScaling, 'cron_expression'> {
    cron_expression?: string,
    recurrence?: string,
    desiredTime?: string,
    useSchedulePicker?: boolean,
    occursOn?: number[],
}

export type TScheduleFrequencyConfig = Pick<IExtendedScheduledScaling,
'recurrence' | 'desiredTime' | 'cron_expression' | 'occursOn' | 'useSchedulePicker'>;

export class ScheduledScalingConfigItem
    extends withFullModalMixin(MessageItem)<IExtendedScheduledScaling> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Override return type of getConfig()
     */
    public getConfig: () => IExtendedScheduledScaling;

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: ScheduledScaling,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            ScheduledScalingModalComponent,
        } = await import(
            /* webpackChunkName: "auto-scale-modal" */ /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/autoscale-policy-modal/scheduled-scaling-modal/scheduled-scaling-modal.component'
        );

        return ScheduledScalingModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.scheduledScalingModalBreadcrumbTitle);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IScheduledScaling> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType('scheduledscaling') || {};

        return {
            ...defaultConfig,
            recurrence: '',
            cron_expression: '',
            occursOn: [],
            desiredTime: '',
            useSchedulePicker: true,
        };
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        this.changeStartEndDateFormat(pickerDateFormat);

        const config = this.getConfig();

        parseCronExpression(config);
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const config = this.getConfig();

        this.changeStartEndDateFormat(isoDateFormat);

        generateCronExpression(config);

        delete config.useSchedulePicker;
        delete config.recurrence;
        delete config.desiredTime;
        delete config.occursOn;
    }

    /**
     * Changes the format of start and end date of schedule
     */
    private changeStartEndDateFormat(format: string): void {
        const config = this.getConfig();

        config.start_date = this.transformDate(config.start_date, format);
        config.end_date = this.transformDate(config.end_date, format);

        if (format === isoDateFormat) {
            config.start_date += 'Z';
            config.end_date += 'Z';
        }
    }

    /**
     * Transforms the date based on given format
     */
    private transformDate(date: string, format: string): string {
        if (!date || !date.length) {
            return '';
        }

        const momentizedDate = moment(date);

        return momentizedDate.format(format);
    }
}
