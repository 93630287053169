/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-dns-services';
const prefix = `${moduleName}.${componentName}`;

export const dnsServicesHeader = `${prefix}.dnsServicesHeader`;
export const dnsServicesLabel = `${prefix}.dnsServicesLabel`;

export const ENGLISH = {
    [dnsServicesHeader]: 'DNS Services',
    [dnsServicesLabel]: 'Virtual Services',
};
