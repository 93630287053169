/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    IPortMatch,
    MatchOperation,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { PortMatch } from 'object-types';
import { PortRangeConfigItem } from 'ajs/modules/policies';
import { TEnumValueLabelsHash } from 'ajs/modules/core/services/schema-service';

interface IExtendedPortMatch extends IPortMatch {
    port_ranges?: RepeatedMessageItem<PortRangeConfigItem>;
}

/**
 * @description  PortMatch ConfigItem class.
 * @author Sarthak Kapoor
 */
export class PortMatchConfigItem extends MessageItem<IExtendedPortMatch> {
    public static ajsDependencies = [
        'schemaService',
    ];

    /**
     * Used for parsing match criteria string for expander info.
     */
    private matchOperationLabelsHash: TEnumValueLabelsHash;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: PortMatch,
            ...args,
        };

        super(extendedArgs);

        const schemaService = this.getAjsDependency_('schemaService');

        this.matchOperationLabelsHash = schemaService.getEnumValueLabelsHash('MatchOperation');
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IPortMatch> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(this.objectType) || {};

        return {
            ...defaultConfig,
            match_criteria: MatchOperation.IS_IN,
        };
    }

    /**
     * Returns the label for this.config.match_criteria.
     * Commonly used as part of expander info.
     */
    protected get matchCriteriaLabel(): string {
        const { match_criteria: matchCriteria } = this.config;

        const parsedMatchCriteria = this.matchOperationLabelsHash[matchCriteria]
            .split(' ')[0]
            .toLowerCase();

        return parsedMatchCriteria;
    }

    /**
     * Value of match to represent the data. Commonly used as part of expander info.
     */
    public get matchValue(): string {
        return this.config.ports.join(', ');
    }

    /**
     * Returns a string used for expander info.
     */
    public toString(): string {
        return `${this.matchCriteriaLabel} ${this.matchValue}`;
    }

    /**
     * Return true if ports have been configured.
     */
    public hasPorts(): boolean {
        return Boolean(this.config.ports?.length);
    }

    /** @override */
    public canFlatten(): boolean {
        return this.hasPorts();
    }
}
