/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'ip-reputation-type-match';
const prefix = `${moduleName}.${componentName}`;

export const ipReputationTypeLabel = `${prefix}.ipReputationTypeLabel`;
export const ipReputationTypePlaceholder = `${prefix}.ipReputationTypePlaceholder`;

export const ENGLISH = {
    [ipReputationTypeLabel]: 'IP Reputation Type',
    [ipReputationTypePlaceholder]: 'Select IP Reputation Type',
};
