/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'protocol-parser-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const parserCodeInputLabel = `${prefix}.parserCodeInputLabel`;
export const fileUploadInputPlaceholder = `${prefix}.fileUploadInputPlaceholder`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Protocol Parser Script',
    [modalTitleNew]: 'New Protocol Parser Script',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
    [parserCodeInputLabel]: 'Import or Paste Protocol Parser Script',
    [fileUploadInputPlaceholder]: 'Paste Protocol Parser Script',
};
