/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    GslbGeoDbProfileCollection,
    TGslbGeoDbProfileCollection,
} from 'ajs/modules/gslb/factories/gslb-geodb-profile/gslb-geodb-profile.collection.factory';

import {
    GslbGeoDbProfileItem,
} from 'ajs/modules/gslb/factories/gslb-geodb-profile/gslb-geodb-profile.item.factory';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { GSLB_GEODB_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/gslb/gslb.tokens';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-geo-profile-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description GeoProfile list page.
 * @author sghare
 */
@Component({
    selector: 'gslb-geo-profile-list',
    templateUrl: './gslb-geo-profile-list.component.html',
})
export class GslbGeoProfileListComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display GeoDbProfile collection.
     */
    public geoProfileGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the GeoDbProfile items.
     */
    private readonly gslbGeoDbProfileCollection: GslbGeoDbProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(GSLB_GEODB_PROFILE_COLLECTION_TOKEN)
        GslbGeoDbProfileCollection: TGslbGeoDbProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.gslbGeoDbProfileCollection = new GslbGeoDbProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.gslbGeoDbProfileCollection;

        this.geoProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.gslbGeoDbProfileCollection,
            getRowId: (index: number): number => index,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: GslbGeoDbProfileItem): string => row.getName(),
                },
                {
                    id: 'entries',
                    label: this.l10nService.getMessage(this.l10nKeys.entriesLabel),
                    transform: (row: GslbGeoDbProfileItem): string =>
                        this.getGeoDbEntriesDescription(row),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.gslbGeoDbProfileCollection.destroy();
    }

    /**
     * Returns Description of GeoDbEntry which includes filename and priority.
     */
    private getGeoDbEntriesDescription(row: GslbGeoDbProfileItem): string {
        const { geoDbEntries: { config: entries } } = row;
        const entryDescriptions = entries.map(entry => {
            const { priority, file } = entry.config;
            const { filename } = file.config;

            return `${filename}(${priority})`;
        });

        return entryDescriptions.join();
    }
}
