/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

import { TECH_SUPPORT_ITEM_TOKEN } from '../tokens/support.token';
import { FileServiceCollection } from '../../core/factories/file-service';

const allDataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'TechSupportDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * @description Collection for Tech-Support component.
 * @author Shahab Hashmi
 */
export class TechSupportCollection extends FileServiceCollection {
    /**
     * ajs dependency for TechSupportItem
     */
    public static ajsDependencies = [TECH_SUPPORT_ITEM_TOKEN];

    constructor(args = {}) {
        const extendedArgs = {
            allDataSources,
            isStatic: true,
            params: {
                uri: 'controller://tech_support',
            },
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(TECH_SUPPORT_ITEM_TOKEN);
    }
}
