/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NatModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    NatPolicyCollection,
} from 'ajs/modules/policies/factories/nat-policy/nat-policy-collection.factory';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './nat-policy-list-page.l10n';
import './nat-policy-list-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TNatPolicyCollection = typeof NatPolicyCollection;

/**
 * @description NAT policy list page.
 *
 * @author Anjali Tanpure
 */
@Component({
    selector: 'nat-policy-list-page',
    templateUrl: './nat-policy-list-page.component.html',
})
export class NatPolicyListPageComponent implements OnInit, OnDestroy {
    /**
     * Expanded content template for datagrid row.
     */
    @ViewChild('expandedContentTemplateRef')
    public readonly expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Configuration object to display role grid.
     */
    public natPolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * NatPolicyCollection instance.
     */
    private readonly natPolicyCollection: NatPolicyCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(NatPolicyCollection)
        NatPolicyCollection: TNatPolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.natPolicyCollection = new NatPolicyCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.natPolicyCollection;

        this.natPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.natPolicyCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    sortBy: 'name',
                    transform: (row: IAviCollectionDataGridRow): string => row.getName(),
                },
                {
                    id: 'rules',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleRulesCount),
                    transform: (row: IAviCollectionDataGridRow) =>
                        (row.getConfig().rules ? row.getConfig().rules.count : 0),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow): string => row.id,
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.natPolicyGridConfig = {
            ...this.natPolicyGridConfig,
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.natPolicyCollection.destroy();
    }
}
