/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import { isEqual } from 'underscore';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Performs deep check of current and previous values.
 */
const checkIfDistinctFromPast =
(prev: any, curr: any): boolean => isEqual(prev, curr);

/**
 * Only allows emission of values if selected properties have changed.
 * Needed to avoid firing of subscription callbacks upon change of non-relevant state vals.
 */
export const isDistinct = (): any => {
    return distinctUntilChanged(checkIfDistinctFromPast);
};
