/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { ILabelData } from '../label-color-optional';
import './label-count-row.component.less';

/**
 * Data format needed to create this component.
 */
export interface ILabelRowData extends ILabelData {
    count: number | string;
}

/**
 * @desc Reusable component for showing 1 combination of label and count, and optional color.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'label-count-row',
    templateUrl: './label-count-row.component.html',
})
export class LabelCountRowComponent implements OnInit {
    @Input()
    public readonly labelRowData: ILabelRowData;

    public labelData: ILabelData;

    /** @override */
    public ngOnInit(): void {
        this.createLabelData();
    }

    /**
     * Creates object needed by child component.
     */
    private createLabelData = (): void => {
        const {
            color,
            hasLeftColor,
            label,
        } = this.labelRowData;

        this.labelData = {
            color,
            hasLeftColor,
            label,
        };
    };
}
