/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'upload-geo-files-page';
const prefix = `${moduleName}.${componentName}`;

export const uploadGeoFileMessage = `${prefix}.uploadGeoFileMessage`;
export const selectFileBtnLabel = `${prefix}.selectFileBtnLabel`;
export const selectFormatInputPlaceholder = `${prefix}.selectFormatInputPlaceholder`;
export const uploadFileBtnLabel = `${prefix}.uploadFileBtnLabel`;
export const cancelUploadBtnLabel = `${prefix}.cancelUploadBtnLabel`;

export const ENGLISH = {
    [uploadGeoFileMessage]: 'Please select the Geo file to upload.',
    [selectFileBtnLabel]: 'Select File',
    [selectFormatInputPlaceholder]: 'Select Format',
    [uploadFileBtnLabel]: 'Upload File',
    [cancelUploadBtnLabel]: 'Cancel Upload',
};
