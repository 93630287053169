/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'service-engine';
const componentName = 'traffic-capture-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const exportModalName = `${prefix}.exportModalName`;

export const ENGLISH = {
    [exportModalName]: 'traffic capture',
};
