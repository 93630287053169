/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'add-pool-servers';
const prefix = `${moduleName}.${componentName}`;

export const serverIpAddressLabel = `${prefix}.serverIpAddressLabel`;
export const addServerBtnLabel = `${prefix}.addServerBtnLabel`;
export const manyIpAddressMessage = `${prefix}.manyIpAddressMessage`;
export const reachTheLimitMessage = `${prefix}.reachTheLimitMessage`;

export const ENGLISH = {
    [serverIpAddressLabel]: 'Server IP Address',
    [addServerBtnLabel]: 'Add Server',
    [manyIpAddressMessage]: 'Too many ({0}) IP addresses. Can add up to {1} addresses. Up to {2} in total.',
    [reachTheLimitMessage]: 'Reached the limit ({0}) of Servers in a Pool',
};
