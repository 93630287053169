/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'avi-enum-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const defaultPlaceholderText = `${prefix}.defaultPlaceholderText`;

export const ENGLISH = {
    [defaultPlaceholderText]: 'Select',
};
