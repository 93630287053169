/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Pipe, PipeTransform } from '@angular/core';

import {
    diff as deepDiff,
    DiffDeleted,
    DiffEdit,
    DiffNew,
} from 'deep-diff';
import { each, omit } from 'underscore';

import {
    IAviDiffGridRow,
    IAviDiffGridSourcePair,
    TAviDiffGridSourceObject,
} from './avi-diff-grid.types';

/**
 * Sort object properties by their keys and return the new object.
 */
const getOrderedHash = (
    obj: Record<string, string>,
): Record<string, string> => Object.keys(obj).sort().reduce((result, key) => {
    result[key] = obj[key];

    return result;
}, {});

/**
 * @description
 *     Pipe for transforming an AviDiffGridSourcePair to diff grid rows.
 *     An instance of AviDiffGridSourcePair contains two objects to be used as diff source, and
 *     it usually needs another layer of processing to be built, which is done by a custom diff
 *     source pair pipe.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'aviDiffGridRows',
})
export class AviDiffGridRowsPipe implements PipeTransform {
    /**
     * Suffix appended to each value in the hashes to help perform diffs on values with duplicated
     * keys.
     * To be overwritten when this.tranform is called.
     */
    public diffPostfix = '';

    /**
     * Transform function for the pipe that takes a diff source pair, compares the two source
     * objects inside it, and produces a list diff grid rows that map to properties from the objects
     * side by side with difference information.
     */
    public transform(
        diffSourcePair: IAviDiffGridSourcePair,
    ): IAviDiffGridRow[] {
        const {
            leftSource,
            rightSource,
            diffPostfix,
            performNoDiff,
        } = diffSourcePair;

        this.diffPostfix = diffPostfix || '';

        // object properties sorting by keys to have line to line matches (if there is any)
        const lhsHash = leftSource ? getOrderedHash(leftSource) : {};
        const rhsHash = rightSource ? getOrderedHash(rightSource) : {};

        const diffs = deepDiff(lhsHash, rhsHash);
        const diffDetected = Boolean(diffs);

        /**
         * Set that keeps the keys of the fields that contains diffs for both source objects.
         * To be filled only when noDiff is false.
         */
        const diffKeySet = new Set<string>();

        const rows: IAviDiffGridRow[] = [];

        // add diff rows
        if (!performNoDiff && diffDetected) {
            diffs.forEach(diff => {
                const { kind, path } = diff;

                // the first element in the path is the key of the field where diff exists
                const [key] = path;

                diffKeySet.add(key);

                const keyWithoutDiffPostfix = this.removeDiffPostfix(key);

                switch (kind) {
                    case 'N': {
                        // add type assertion here since diff is of three-combination type
                        // according to its "kind" property
                        const diffNew = diff as DiffNew<TAviDiffGridSourceObject>;

                        rows.push({
                            left: '',
                            right: `${keyWithoutDiffPostfix}: ${diffNew.rhs}`,
                            type: 'new',
                        });

                        break;
                    }

                    case 'E': {
                        // add type assertion here since diff is of three-combination type
                        // according to its "kind" property
                        const diffEdit = diff as DiffEdit<TAviDiffGridSourceObject>;

                        rows.push({
                            left: `${keyWithoutDiffPostfix}: ${diffEdit.lhs}`,
                            right: `${keyWithoutDiffPostfix}: ${diffEdit.rhs}`,
                            type: 'edit',
                        });

                        break;
                    }

                    case 'D': {
                        // add type assertion here since diff is of three-combination type
                        // according to its "kind" property
                        const diffDeleted = diff as DiffDeleted<TAviDiffGridSourceObject>;

                        rows.push({
                            left: `${keyWithoutDiffPostfix}: ${diffDeleted.lhs}`,
                            right: '',
                            type: 'deleted',
                        });

                        break;
                    }
                }
            });
        }

        const diffKeys = Array.from(diffKeySet);
        const diffKeyCount = diffKeys.length;
        const lhsToBeProcessedHash = omit(lhsHash, diffKeys);
        let lhsProcessedCount = 0;

        /**
         * Process no-diff.
         * 1. Left and right hand sides have the same entries of non-diff rows to be
         *    processed after diff is performed.
         * 2. Left and right hand sides can have different numbers of rows to be processed
         *    if no diff is required to be performed.
         */
        each(
            // need type assertion here since "underscore.omit" changes the key sets.
            lhsToBeProcessedHash as TAviDiffGridSourceObject,
            (lhsValue, key) => {
                const keyWithoutDiffPostfix = this.removeDiffPostfix(key);
                const lhsContent = `${keyWithoutDiffPostfix}: ${lhsValue}`;

                rows.push({
                    left: lhsContent,
                    right: '', // to be filled by the rhs process below
                    type: 'no-diff',
                });

                lhsProcessedCount++;
            },
        );

        const rhsToBeProcessedHash = omit(rhsHash, diffKeys);
        let rhsProcessedCount = 0;

        each(
            // need type assertion here since "underscore.omit" changes the key sets.
            rhsToBeProcessedHash as TAviDiffGridSourceObject,
            (rhsValue, key) => {
                const keyWithoutDiffPostfix = this.removeDiffPostfix(key);
                const rhsContent = `${keyWithoutDiffPostfix}: ${rhsValue}`;

                if (rhsProcessedCount < lhsProcessedCount) {
                    // fill the rhs content that's left from the lhs process
                    // need diffKeyCount as an offset to avoid changing the diff rows
                    rows[diffKeyCount + rhsProcessedCount].right = rhsContent;
                } else {
                    // add an extra row with rhs content only
                    rows.push({
                        left: '',
                        right: rhsContent,
                        type: 'no-diff',
                    });
                }

                rhsProcessedCount++;
            },
        );

        return rows;
    }

    /**
     * Append a postfix to duplicate field keys in order to perform diff checking.
     * Since we are using comparison feature mostly for objects, we do not have properties with the
     * same names within one object. But this is not the case under some situations
     * (ex. HTTP headers, and they usually comes as a concatenated string that makes key duplication
     * possible.) To make it work a postfix is added to the duplicated names for the sake of data
     * processing, to hide it from user on layout rendering.
     */
    private removeDiffPostfix(name: string): string {
        const pos = name.lastIndexOf(this.diffPostfix);

        if (pos !== -1) {
            name = name.slice(0, pos);
        }

        return name;
    }
}
