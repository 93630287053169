/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-search-bar';
const prefix = `${moduleName}.${componentName}`;

export const filterPlaceholder = `${prefix}.filterPlaceholder`;

export const ENGLISH = {
    [filterPlaceholder]: 'Filter by Name, Path, or more',
};
