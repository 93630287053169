/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { IService } from 'generated-types';
import {
    IVsLogsParams,
    IVsLogsStateParams,
    TVsLogsConstantParams,
    VsAppProfileType,
    VsLogsType,
} from '../vs-logs.types';
import { LOGS_API_URL } from '../services/vs-logs-api-request.service';
import { TVsConfig } from '../services/vs-logs.store';
import { convertQueryStringToApiString } from './vs-logs-filters.utils';

/**
 * Get relevant properties from a virtual service item.
 * TODO: change "any" to the VirtualService class once it's been converted to Typescript.
 */
export const getVsConfig = (virtualService: any): TVsConfig => {
    const vsAppProfileType = virtualService.appType();

    // type is set to 1 for L7 and 0 for L4 and DNS
    const vsLogsType = vsAppProfileType === VsAppProfileType.L7 ?
        VsLogsType.APPLICATION :
        VsLogsType.CONNECTION;

    const hasOverrideL4AppProfile = virtualService.getConfig().services.some(
        (service: IService) => service.override_application_profile_ref,
    );

    let nonSignificantLogsEnabled = true;
    const { analytics_policy: analyticsPolicy } = virtualService.getConfig();

    if (analyticsPolicy && analyticsPolicy.full_client_logs) {
        nonSignificantLogsEnabled = analyticsPolicy.full_client_logs.enabled;
    }

    const vsConfig: TVsConfig = {
        vsName: virtualService.getName(),
        vsUuid: virtualService.getConfig().uuid,
        vsAppProfileType,
        vsLogsType,
        hasOverrideL4AppProfile,
        nonSignificantLogsEnabled,
        hasWafPolicy: virtualService.hasWAFPolicy(),
    };

    return vsConfig;
};

/**
 * Convert state params to full set of API params needed for request.
 */
export const convertStateToApiParams = (
    stateParams: IVsLogsStateParams,
    constantParams: TVsLogsConstantParams,
): IVsLogsParams => {
    const { filters, ...remParams } = stateParams;

    const filterApiStrings: string[] = [];

    if (filters) {
        filters.forEach(filter => {
            filterApiStrings.push(convertQueryStringToApiString(filter, remParams.type));
        });
    }

    const fullParams: IVsLogsParams = {
        ...constantParams,
        ...remParams,
        filter: filterApiStrings,
    };

    return fullParams;
};

/**
 * Build a url from paramaters object.
 */
export const buildUrlFromParams = (params: IVsLogsParams): string => {
    let url = `${LOGS_API_URL}?`;

    for (const param in params) {
        if (Object.prototype.hasOwnProperty.call(params, param)) {
            const value = params[param];

            if (Array.isArray(value)) {
                url += value
                    .map(val => `${param}=${val}&`)
                    .join('');
            } else {
                url += `${param}=${value}&`;
            }
        }
    }

    return url;
};
