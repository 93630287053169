/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ip-reputation-db';
const componentName = 'ip-reputation-search-record-card';
const prefix = `${moduleName}.${componentName}`;

export const header = `${prefix}.header`;
export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const invalidInputText = `${prefix}.invalidInputText`;
export const matchFoundMessage = `${prefix}.matchFoundMessage`;
export const searchRecordMessage = `${prefix}.searchRecordMessage`;
export const noRecordFoundMessage = `${prefix}.noRecordFoundMessage`;
export const threatTypeLabel = `${prefix}.threatTypeLabel`;

export const ENGLISH = {
    [header]: 'Search Records',
    [searchInputPlaceholder]: 'Search by IP Address',
    [invalidInputText]: 'Not a valid IPv4 Address',
    [matchFoundMessage]: 'Found 1 match in <b>{0}</b>',
    [searchRecordMessage]: 'Searching records in <b>{0}</b>',
    [noRecordFoundMessage]: 'No Records Found for {0}',
    [threatTypeLabel]: 'Threat Types ({0})',
};
