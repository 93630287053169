/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './file-upload.less';
import { arrayBufferDecoder } from '../../../../angular/shared/utils';
import * as l10n from './file-upload.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** @type {string} */
const componentName = 'file-upload';

/**
 * File size base unit: 1 MB.
 * @type {number}
 */
const FILE_SIZE_1MB = 1024 * 1024;

/** @alias fileUpload */
class FileUploadController {
    constructor($elem, $window, $attrs, l10nService) {
        this.$elem = $elem;
        this.$window = $window;
        this.$attrs = $attrs;
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.isBusy = false;
        this.uploadedFile = null;

        /**
         * File size limit. Unlimited by default.
         * @type {number}
         * @protected
         */
        this.maxFileSize_ = this.maxFileSize ? this.maxFileSize * FILE_SIZE_1MB : NaN;

        const { $elem } = this;

        $elem.addClass(componentName);

        if (this.type === 'button') {
            $elem.addClass(`${componentName}--type--button`);
        }

        const { $attrs } = this;

        // static attribute
        this.isPrimary = 'primary' in $attrs;
        this.isDisabled = 'disabled' in $attrs;

        // set decodeFile; true by default
        this.decodeFile = _.isUndefined(this.decodeFile) || Boolean(this.decodeFile);

        // ng-disabled is supported
        $attrs.$observe('disabled',
            disabled => this.isDisabled = !!disabled || disabled === '');

        const fileInput = this.$elem.find(`.${componentName}__input-file`);

        fileInput.on('change', this.onFileLoadStart_.bind(this));

        const fileReader = new this.$window.FileReader();

        fileReader.addEventListener('load', this.onFileLoadComplete_.bind(this));
        fileReader.addEventListener('error', this.onFileLoadFailed_.bind(this));
        this.fileReader = fileReader;
    }

    /**
     * Commits $viewValue changes through ngModelCtrl on file read success event.
     * @param {Event} event
     * @protected
     */
    onFileLoadComplete_(event) {
        this.isBusy = false;

        let fileContent;

        if (this.decodeFile) {
            const { result: arrBuffer } = event.target;
            const { ngModelCtrl } = this;
            const { $modelValue } = ngModelCtrl;

            fileContent = arrayBufferDecoder(arrBuffer, !!this.base64);

            // dirty hack to make ngModelCtrl fire ngChange event on the same file re-upload
            // may backfire, feel free to modify ;)
            if (!ngModelCtrl.$isEmpty($modelValue) && $modelValue === fileContent) {
                ngModelCtrl.$$lastCommittedViewValue = undefined;
                ngModelCtrl.$modelValue = undefined;
            }
        }

        this.updateViewValue_(this.uploadedFile, fileContent);

        this.uploadedFile = null;
    }

    /**
     * Resets $viewValue on file read failure.
     * @protected
     */
    onFileLoadFailed_() {
        this.isBusy = false;
        this.uploadedFile = null;
        this.updateViewValue_();
    }

    /**
     * Passes selected file to fileReader instance.
     * @param {Event} event
     * @protected
     */
    onFileLoadStart_(event) {
        const { target } = event;
        const { files } = target;

        if (!files.length) {
            return;
        }

        const [file] = files;

        if (this.maxFileSize_ && file.size > this.maxFileSize_) {
            alert(`File size should be under ${this.maxFileSize_} Bytes`);

            return;
        }

        // Return only when keeping file ref. No further decoding actions are needed.
        if (!this.decodeFile) {
            this.updateViewValue_(file, undefined);

            return;
        }

        this.uploadedFile = file;

        const blob = file.slice();

        this.isBusy = true;

        this.fileReader.readAsArrayBuffer(blob);

        target.value = null;
    }

    /**
     * Updates $viewValue and commits changes through ngModelCtrl.
     * <file, fileContent> - decodeFile is truthy and file has been selected.
     * <file, undefined> - decodeFile is falsy and file has been selected.
     * <undefined, undefined> - called when file upload failed.
     * @param {File=} file - File object representing the original file.
     * @param {string=} fileContent - File string that is decoded from the original file.
     * @protected
     */
    updateViewValue_(file, fileContent) {
        if (file) {
            const { name, type } = file;

            this.fileName = name;
            this.contentType = type;

            const viewValue = this.decodeFile ? fileContent : file;

            this.ngModelCtrl.$setViewValue(viewValue);
        } else {
            this.fileName = undefined;
            this.contentType = undefined;
            this.ngModelCtrl.$setViewValue(undefined);
        }
    }

    /**
     * Returns button class names based on internal component state.
     * @return {string}
     */
    getButtonClassNames() {
        const classNames = [];

        if (this.isBusy || this.isDisabled) {
            classNames.push(`${componentName}__button--disabled`);
        }

        if (this.isPrimary) {
            classNames.push(`${componentName}__button--primary`);
        }

        return classNames.join(' ');
    }

    /**
     * Returns file filed label.
     * @return {string}
     */
    getFileInputLabel() {
        const { ngModelCtrl } = this;
        const { $modelValue } = ngModelCtrl;
        const { l10nService_: l10nService, l10nKeys } = this;

        if (ngModelCtrl.$isEmpty($modelValue)) {
            return l10nService.getMessage(l10nKeys.chooseFileInputPlaceholder);
        }

        return this.fileName || l10nService.getMessage(l10nKeys.fileAttachedLabel);
    }
}

FileUploadController.$inject = [
    '$element',
    '$window',
    '$attrs',
    'l10nService',
];

/**
 * @deprecated - see FileUploadComponent in angular/SharedModule
 * @ngdoc component
 * @name fileUpload
 * @param {string=} fileName - Will be set by component upon file upload event.
 * @param {string=} contentType - Will be set by component upon file upload event.
 * @param {boolean=} base64 - Pass truthy value for binary files. Apply only when decodeFile is
 *     passed as true.
 * @param {string=} type - Pass "button" to render button only,
 *     Pass "link" to render a link with text.
 * @param {string=} buttonLabel - Text to be used as button label
 * @param {boolean} primary - If attribute present will render green button.
 * @param {boolean=} [decodeFile=true] - True for file to be decoded to string. False to keep the
 *     File object pointing to its original source without decoding. Set to true by default.
 * @param {number=} maxFileSize - Max size of file being uploaded with unit of MB. Unlimited by
 *     default.
 * @desc
 *
 *     Component for input[type=file] with customizable layout and ng-model support.
 *
 *     Plain text and binary files are supported. Binary files are base64 encoded.
 *
 *     Supports three layouts: with and wo file name field next to the button
 *     and just a link without input and button.
 *
 *     All ng-model controller features are supported along with "disabled" attribute.
 *
 * @author Alex Malitsky, Zhiqian Liu
 */
//TODO support file extension filtering
angular.module('aviApp').component('fileUpload', {
    bindings: {
        contentType: '=?',
        base64: '<?',
        fileName: '=?',
        buttonLabel: '@?',
        type: '@?',
        decodeFile: '<?',
        maxFileSize: '@?',
    },
    controller: FileUploadController,
    templateUrl: `src/components/forms/inputs/${componentName}/${componentName}.component.html`,
    require: {
        ngModelCtrl: 'ngModel',
    },
});
