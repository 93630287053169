/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'client-ip-address-controller';
const prefix = `${moduleName}.${componentName}`;

export const clientIpAddressHeader = `${prefix}.clientIpAddressHeader`;
export const isRadioLabel = `${prefix}.isRadioLabel`;
export const isNotRadioLabel = `${prefix}.isNotRadioLabel`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const removeMatchTitle = `${prefix}.removeMatchTitle`;
export const sourceIpAddressHeader = `${prefix}.sourceIpAddressHeader`;

export const ENGLISH = {
    [clientIpAddressHeader]: 'Client IP Address',
    [isRadioLabel]: 'Is',
    [isNotRadioLabel]: 'Is not',
    [ipAddressInputLabel]: 'IP Address',
    [removeMatchTitle]: 'Remove Match',
    [sourceIpAddressHeader]: 'Source IP Address',
};
