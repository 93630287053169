/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-summary';
const prefix = `${moduleName}.${componentName}`;

export const userIdLabel = `${prefix}.userIdLabel`;
export const locationLabel = `${prefix}.locationLabel`;
export const operatingSystemLabel = `${prefix}.operatingSystemLabel`;
export const deviceLabel = `${prefix}.deviceLabel`;
export const browserLabel = `${prefix}.browserLabel`;
export const startTimeLabel = `${prefix}.startTimeLabel`;
export const endTimeLabel = `${prefix}.endTimeLabel`;
export const rulesAndPoliciesLabel = `${prefix}.rulesAndPoliciesLabel`;
export const networkSecurityPolicyRuleLabel = `${prefix}.networkSecurityPolicyRuleLabel`;
export const httpSecurityPolicyRuleLabel = `${prefix}.httpSecurityPolicyRuleLabel`;
export const httpRequestPolicyRuleLabel = `${prefix}.httpRequestPolicyRuleLabel`;
export const httpResponsePolicyRuleLabel = `${prefix}.httpResponsePolicyRuleLabel`;
export const requestInfoLabel = `${prefix}.requestInfoLabel`;
export const requestIdLabel = `${prefix}.requestIdLabel`;
export const uriLabel = `${prefix}.uriLabel`;
export const userAgentLabel = `${prefix}.userAgentLabel`;
export const responseInfoLabel = `${prefix}.responseInfoLabel`;
export const grpcStatusLabel = `${prefix}.grpcStatusLabel`;
export const dnsQueryTypeLabel = `${prefix}.dnsQueryTypeLabel`;
export const gslbServiceLabel = `${prefix}.gslbServiceLabel`;
export const gslbPoolLabel = `${prefix}.gslbPoolLabel`;
export const recursionAvailableLabel = `${prefix}.recursionAvailableLabel`;

export const ENGLISH = {
    [userIdLabel]: 'User ID',
    [locationLabel]: 'Location',
    [operatingSystemLabel]: 'Operating System',
    [deviceLabel]: 'Device',
    [browserLabel]: 'Browser',
    [startTimeLabel]: 'Start Time',
    [endTimeLabel]: 'End Time',
    [rulesAndPoliciesLabel]: 'Rules and Policies',
    [networkSecurityPolicyRuleLabel]: 'Network Security Policy Rule',
    [httpSecurityPolicyRuleLabel]: 'HTTP Security Policy Rule',
    [httpRequestPolicyRuleLabel]: 'HTTP Request Policy Rule',
    [httpResponsePolicyRuleLabel]: 'HTTP Response Policy Rule',
    [requestInfoLabel]: 'Request Information',
    [requestIdLabel]: 'Request ID',
    [uriLabel]: 'URI',
    [userAgentLabel]: 'User Agent',
    [responseInfoLabel]: 'Response Information',
    [grpcStatusLabel]: 'GRPC Status',
    [dnsQueryTypeLabel]: 'DNS Query Type',
    [gslbServiceLabel]: 'GSLB Service',
    [gslbPoolLabel]: 'GSLB Pool',
    [recursionAvailableLabel]: 'Recursion Available',
};
