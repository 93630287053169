/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './license-add-panel.component.less';

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';

import { IHttpResponse } from 'angular';
import { ClrFormLayout } from '@clr/angular';
import { LicensingService } from 'ajs/modules/licensing';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-add-panel.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IAddLicenseKeyErrorResponse {
    result: string;
}

enum LicenseAddType {
    LICENSE_KEY = 'LICENSE_KEY',
    UPLOAD_KEY = 'UPLOAD_KEY',
}

enum AlertType {
    SUCCESS = 'success',
    ERROR = 'danger'
}

const enum LoadingState {
    DEFAULT = 'default',
    LOADING = 'loading',
}

interface ILicenseAddAlert {
    type?: AlertType,
    message?: string,
}

/**
 * @description
 *     Panel to provide add license functionality.
 * @author Ram Pal, Rajawant Prajapati
 */
@Component({
    selector: 'license-add-panel',
    templateUrl: './license-add-panel.component.html',
})
export class LicenseAddPanelComponent {
    /**
     * This event gets fired when license file upload or
     * adding new license by serial key request is completed.
     * Used to emit license add request's success/error message.
     */
    @Output()
    public onLicenseAddRequestComplete = new EventEmitter<ILicenseAddAlert>();

    /**
     * Property holding LicenseAddType.
     */
    public readonly LicenseAddType = LicenseAddType;

    /**
     * Used to check which license type (LICENSE_KEY/UPLOAD_KEY) is selected.
     * Default selected type is LicenseAddType.LICENSE_KEY
     */
    public selectedLicenseAddType = LicenseAddType.LICENSE_KEY;

    /**
     * New license key model.
     */
    public newLicenseKey: string;

    /**
     * Filename of the selected file containing license details.
     */
    public selectedLicenseFileName: string;

    /**
     * Content of the selected file containing license details.
     */
    public licenseFileContent: string;

    /**
     * Used to show success/error message alert.
     */
    public readonly licenseAddAlert: ILicenseAddAlert = {};

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for license key clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Loading state of the apply key/upload button. If set to 'loading', shows a spinner.
     */
    public loadingState: string = LoadingState.DEFAULT;

    /**
     * Success Message to be displayed when licence is added successfully.
     */
    private licenseAddSuccessMessage: string;

    /**
     * Model to hold error message that we get on adding license.
     */
    private errorMessage: string;

    constructor(
        private readonly licensingService: LicensingService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.licenseAddSuccessMessage = l10nService.getMessage(l10nKeys.licenseAddSuccessMessage);
    }

    /**
     * Function to upload license file content.
     */
    public uploadLicenseFile(): void {
        this.loadingState = LoadingState.LOADING;

        this.resetAlert();

        this.licensingService.uploadLicenseFile(this.licenseFileContent)
            .then(() => {
                this.resetInputs();
            })
            .catch(({ data: { result } }: IHttpResponse<IAddLicenseKeyErrorResponse>) => {
                this.errorMessage = result;
            })
            .finally(() => {
                this.loadingState = LoadingState.DEFAULT;
                this.setAlertTypeAndMessage();
                this.onLicenseAddRequestComplete.emit(this.licenseAddAlert);
            });
    }

    /**
     * Function to add new license key.
     */
    public applyNewLicenseKey(): void {
        this.loadingState = LoadingState.LOADING;

        this.resetAlert();

        this.licensingService.applyLicenseKey(this.newLicenseKey)
            .then(() => {
                this.resetInputs();
            })
            .catch(({ data: { result } }: IHttpResponse<IAddLicenseKeyErrorResponse>) => {
                this.errorMessage = result;
            })
            .finally(() => {
                this.loadingState = LoadingState.DEFAULT;
                this.setAlertTypeAndMessage();
                this.onLicenseAddRequestComplete.emit(this.licenseAddAlert);
            });
    }

    /**
     * Function to set success/error alert type and message.
     */
    public setAlertTypeAndMessage(): void {
        if (this.errorMessage) {
            this.licenseAddAlert.type = AlertType.ERROR;
            this.licenseAddAlert.message = this.errorMessage;
        } else {
            this.licenseAddAlert.type = AlertType.SUCCESS;
            this.licenseAddAlert.message = this.licenseAddSuccessMessage;
        }
    }

    /**
     * Used to show apply key button with loading state.
     */
    public get busy(): boolean {
        return this.loadingState === LoadingState.LOADING;
    }

    /**
     * Function to clear success/error message.
     */
    public resetAlert(): void {
        this.licenseAddAlert.message = undefined;
        this.errorMessage = undefined;
    }

    /**
     * Function to clear selected license file/ license key on successfully license add.
     */
    public resetInputs(): void {
        this.newLicenseKey = undefined;
        this.licenseFileContent = undefined;
        this.selectedLicenseFileName = undefined;
    }
}
