/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'http-method-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const httpMethodLabel = `${prefix}.httpMethodLabel`;

export const ENGLISH = {
    [httpMethodLabel]: 'HTTP Method',
};
