/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import { duration } from 'moment';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './vs-logs-timeframes.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface ILogTimeframeValue {
    label: string;
    range: number;
    step: number;
    hiddenInLayout?: boolean;
}

/**
 * @description
 *  Map of VS timeframe options to label, range, and step properties.
 *  Angular version of ajs 'log-timeframes.factory.ts'; can eventually
 *  be used for Timeframe Selector component once it's upgraded.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Injectable()
export class VsLogsTimeframesService {
    private readonly timeframeMap = {
        '15m': {
            label: this.l10nService.getMessage(l10nKeys.past15minutesLabel),
            range: duration(15, 'm').asSeconds(),
            step: 5,
        },
        '1h': {
            label: this.l10nService.getMessage(l10nKeys.pastHourLabel),
            range: duration(1, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        '3h': {
            label: this.l10nService.getMessage(l10nKeys.past3hoursLabel),
            range: duration(3, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        '6h': {
            label: this.l10nService.getMessage(l10nKeys.past6hoursLabel),
            range: duration(6, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        '1d': {
            label: this.l10nService.getMessage(l10nKeys.pastDayLabel),
            range: duration(1, 'd').asSeconds(),
            step: duration(5, 'm').asSeconds(),
        },
        '1w': {
            label: this.l10nService.getMessage(l10nKeys.pastWeekLabel),
            range: duration(1, 'w').asSeconds(),
            step: duration(1, 'h').asSeconds(),
        },
        '1m': {
            label: this.l10nService.getMessage(l10nKeys.pastMonthLabel),
            range: duration(1, 'M').asSeconds(),
            step: duration(1, 'days').asSeconds(),
        },
        custom: {
            label: this.l10nService.getMessage(globalL10nKeys.customLabel),
            range: 'custom',
            hiddenInLayout: true,
            step: 300,
        },
    };

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns timeframe object with useful properties for a given timeframe.
     */
    public getTimeframeProps(timeframeKey: string): ILogTimeframeValue {
        return this.timeframeMap[timeframeKey];
    }
}
