/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'icap-logs';
const prefix = `${moduleName}.${componentName}`;

export const gridColumnHeaderActionLabel = `${prefix}.gridColumnHeaderActionLabel`;
export const gridColumnHeaderTypeLabel = `${prefix}.gridColumnHeaderTypeLabel`;
export const gridColumnHeaderReasonLabel = `${prefix}.gridColumnHeaderReasonLabel`;
export const gridColumnHeaderViolationsLabel = `${prefix}.gridColumnHeaderViolationsLabel`;
export const gridColumnHeaderScoreLabel = `${prefix}.gridColumnHeaderScoreLabel`;

export const ENGLISH = {
    [gridColumnHeaderActionLabel]: 'Request Action',
    [gridColumnHeaderTypeLabel]: 'Type',
    [gridColumnHeaderReasonLabel]: 'Reason',
    [gridColumnHeaderViolationsLabel]: 'Violations',
    [gridColumnHeaderScoreLabel]: 'Score',
};
