/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'auth-match-group-membership';
const prefix = `${moduleName}.${componentName}`;

export const ldapGroupLabel = `${prefix}.ldapGroupLabel`;
export const memberLabel = `${prefix}.memberLabel`;
export const notMemberLabel = `${prefix}.notMemberLabel`;
export const anyLabel = `${prefix}.anyLabel`;

export const ENGLISH = {
    [ldapGroupLabel]: 'LDAP Group',
    [memberLabel]: 'Member',
    [notMemberLabel]: 'Not a Member',
    [anyLabel]: 'Any',
};
