/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-infoblox-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const credentialSectionHeader = `${prefix}.credentialSectionHeader`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const networkViewInputLabel = `${prefix}.networkViewInputLabel`;
export const dnsViewInputLabel = `${prefix}.dnsViewInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const valueNotSetPlaceholder = `${prefix}.valueNotSetPlaceholder`;
export const infobloxHeader = `${prefix}.infobloxHeader`;
export const settingsSectionHeader = `${prefix}.settingsSectionHeader`;
export const wapiVersionInputLabel = `${prefix}.wapiVersionInputLabel`;
export const wapiVersionInputPlaceholder = `${prefix}.wapiVersionInputPlaceholder`;
export const extensibleAttributesGridHeader = `${prefix}.extensibleAttributesGridHeader`;

export const ENGLISH = {
    [credentialSectionHeader]: 'Credentials',
    [ipAddressInputLabel]: 'IP Address or FQDN',
    [networkViewInputLabel]: 'Network View',
    [dnsViewInputLabel]: 'DNS View',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [valueNotSetPlaceholder]: 'Not Set',
    [infobloxHeader]: 'Infoblox',
    [settingsSectionHeader]: 'Settings',
    [wapiVersionInputLabel]: 'WAPI Version',
    [wapiVersionInputPlaceholder]: 'Enter WAPI Version',
    [extensibleAttributesGridHeader]: 'Extensible Attributes ({0})',
};
