/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive, ElementRef, Injector, Input,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * Upgraded version of 'avi-icon' component, to make work in angular.
 */
@Directive({
    selector: 'avi-icon',
})
export class AviIconDirective extends UpgradeComponent {
    @Input() public type?: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('aviIcon', elementRef, injector);
    }
}
