/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NatModule
 */

import { L10nService } from '@vmw/ngx-vip';
import { each } from 'underscore';
import {
    Component,
    Type,
} from '@angular/core';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { NatMatchTargetConfigItem } from './nat-match-target.config-item.factory';
import { NatPolicyActionConfigItem } from './nat-policy-action.config-item.factory';
import * as l10n from '../../policies.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface INatRuleConfig {
    name: string,
    match: NatMatchTargetConfigItem;
    action: NatPolicyActionConfigItem;
    enable: boolean;
}

// Interface for UI only field for Nat action IP Range and adress.
export interface INatActionIpAddrRange {
    addr: string;
    range: string;
}

/**
 * @desc NatRule MessageItem class.
 * @author Aravindh Nagarajan, Guru Prasad H K
 */
export class NatRuleConfigItem extends withFullModalMixin(MessageItem)<INatRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NatRule',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<INatRuleConfig> {
        const type = this.objectType.toLowerCase();
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            enable: true,
            match: null,
        };
    }

    /**
     * Getter for the number of matches in NAT rule.
     */
    public get matchCount(): number {
        return this.config.match ? this.config.match.matchCount : 0;
    }

    /**
     * Getter for enable value of rule.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Getter for match configItem of a rule.
     */
    public get match(): NatMatchTargetConfigItem {
        return this.config.match || null;
    }

    /**
     * Getter for action configItem of a rule.
     */
    public get action(): NatPolicyActionConfigItem {
        return this.config.action || null;
    }

    /**
     * Returns an array of all addresses in this.config.
     */
    public get addresses(): INatActionIpAddrRange[] {
        const { nat_info: natInfo } = this.config.action.config;
        const servers: INatActionIpAddrRange[] = [];

        each(natInfo.config, ({ config: { nat_ip, nat_ip_range } }) => {
            if (nat_ip || nat_ip_range) {
                servers.push({
                    addr: nat_ip?.config?.addr,
                    range: nat_ip_range?.toString(),
                });
            }
        });

        return servers;
    }

    /**
     * Remove all IP Addresses from config.
     */
    public removeAllIpAddresses(): void {
        const {
            nat_info: natInfo,
        } = this.config.action.config;

        natInfo.removeAll();
    }

    /**
     * @override
     * Import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        /* eslint-disable-next-line max-len */
        const {
            NatRuleModalComponent,
        } = await import(
            /* webpackChunkName: "nat-policy-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/nat-policy-modal/nat-rule-modal/nat-rule-modal.component'
        );

        return NatRuleModalComponent as Type<Component>;
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.natRuleBreadcrumbTitle);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['match', 'action'];
    }
}
