/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    OnDestroy,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { Subscription } from 'rxjs';
import { isUndefined } from 'underscore';
import { attachComponentBindings } from 'ng/shared/utils';
import { AviAppLevelAlertComponent } from 'ng/shared/components/avi-app-level-alert';

import {
    AppLevelAlertsService,
    IAviAppLevelAlertsProps,
} from '../../services/app-level-alerts.service';

/**
 * Interface for representing info of app level alert.
 */
interface IAviAppLevelAlert {
    id: string;
    componentRef: ComponentRef<Component>;
}

/**
 * @description Component used to display app level alerts. All rendered alerts will be contained
 * within the view of this component.
 * @author Nisar Nadaf
 */
@Component({
    selector: 'avi-app-level-alerts-portal',
    template: `
        <ng-template
            class="clr-wrapper"
            #alertsContainer
        ></ng-template>`,
})
export class AviAppLevelAlertsPortalComponent implements AfterViewInit, OnDestroy {
    /**
     * Template ref of container element to render alerts.
     */
    @ViewChild('alertsContainer', {
        read: ViewContainerRef,
    })
    private readonly alertsContainerRef: ViewContainerRef;

    /**
     * Contains a list of app level alerts.
     */
    public alerts: IAviAppLevelAlert[] = [];

    /**
     * Subscription to the AppLevelAlertsService, which provides this component with a stack of
     * app level alerts to be rendered.
     */
    private subscription: Subscription;

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly appLevelAlertsService: AppLevelAlertsService,
    ) {}

    /** @override */
    public ngAfterViewInit(): void {
        this.subscription = this.appLevelAlertsService.items$
            .subscribe((alerts: IAviAppLevelAlertsProps[]): void => {
                this.destroyRemovedAlerts(alerts);
                this.setAlerts(alerts);
                this.renderNewAlerts();
            });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Destroys any alerts that no longer exist in the alerts stack from the
     * AppLevelAlertsService.
     */
    private destroyRemovedAlerts(incomingAlertsProps: IAviAppLevelAlertsProps[]): void {
        const incomingAlertsHash = incomingAlertsProps.reduce((hash, alert) => {
            hash[alert.id] = true;

            return hash;
        }, {});

        const removedAlerts = this.alerts.filter(alert => {
            return !(alert.id in incomingAlertsHash);
        });

        removedAlerts.forEach((alert): void => {
            const { hostView } = alert.componentRef;
            const index = this.alertsContainerRef.indexOf(hostView);

            this.alertsContainerRef.remove(index);
        });
    }

    /**
     * Creates a AlertStack that matches the alertPropsStack coming from the
     * AppLevelAlertsService.
     */
    private setAlerts(incomingAlertsProps: IAviAppLevelAlertsProps[] = []): void {
        const existingAlertRefs = this.alerts.reduce((hash, alert) => {
            hash[alert.id] = alert.componentRef;

            return hash;
        }, {});

        this.alerts = incomingAlertsProps.map((prop: IAviAppLevelAlert) => {
            const { id } = prop;
            const componentRef = id in existingAlertRefs ?
                existingAlertRefs[id] :
                this.createComponentRef(prop);

            return {
                componentRef,
                id,
            };
        });
    }

    /**
     * Renders any alert in the alertsStack that doesn't already exist in the
     * viewContainerRef.
     */
    private renderNewAlerts(): void {
        this.alerts.forEach((alert, stackIndex): void => {
            const { hostView } = alert.componentRef;
            const index = this.alertsContainerRef.indexOf(hostView);

            if (index < 0) {
                this.alertsContainerRef.insert(hostView, stackIndex);
            }
        });
    }

    /**
     * Creates an instance of the app level alert component.
     */
    private createComponentRef(appLevelAlertsProps: IAviAppLevelAlertsProps)
        : ComponentRef<Component> {
        const { component, componentProps = {}, id } = appLevelAlertsProps;

        // Set default alert component if no component is passed in service props.
        const alertComponent = isUndefined(component) ?
            AviAppLevelAlertComponent as Type<Component> : component;

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(alertComponent);

        const componentRef = componentFactory.create(this.alertsContainerRef.injector);

        const defaultComponentProps = {
            onClose: () => this.appLevelAlertsService.remove(id),
        };

        const extendedComponentProps: {} = {
            ...defaultComponentProps,
            ...componentProps,
        };

        attachComponentBindings(componentRef, extendedComponentProps);

        return componentRef;
    }
}
