/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('NetworkResolutionFactory', [
'Base', '$q', 'ConfiguredNetwork', 'Pool',
function(Base, $q, ConfiguredNetwork, Pool) {
    /*--- Dependencies ---*/
    const base = new Base();
    /*--- ---*/

    /**
     * NetworkResolutionFactory factory.
     */
    const NetworkResolutionFactory = function() {
        /*---  Properties for resolution controllers to be set to ---*/
        // Network object
        this.network = {};
        // Pool object
        this.pool = {};
        // List of all subnets for user to select.
        this.subnetsList = [];
        // VS runtime object to get placement resolution info.
        this.vs_runtime = {};
        // Network runtime object used to get subnet runtime.
        this.networkruntime = {};
        // Subnet runtime object.
        this.subnet_runtime = {};
        // Subnet selected by user.
        this.selected_subnet = null;
        // IP address added by user to be added to subnet.
        this.static_ipaddr_tmp = [];
        // List of network's configured subnets.
        this.configured_subnets = [];
        // List of placement networks from pool.
        this.placement_networks = [];
        // Error message if something fails.
        this.error = null;
    };

    /**
     * Loads network.
     * @param  {string} slug - String of slug from network_ref.
     * @return {promise} Promise returned from network.load();
     */
    NetworkResolutionFactory.prototype.loadNetwork = function(slug) {
        const self = this;

        this.error = null;
        this.network = new ConfiguredNetwork({ id: slug });

        return this.network.load().then(function() {
            self.network.beforeEdit();
        }).catch(function(rsp) {
            self.error = rsp.data.error;
        });
    };

    /**
     * Load pool.
     * @param  {string} uuid - String of uuid from vs_placement_resolution_info.
     * @return {promise} Promise returned from pool.load();
     */
    NetworkResolutionFactory.prototype.loadPool = function(vsRuntime) {
        const
            self = this,
            uuid = vsRuntime.vs_placement_resolution_info.pool_uuid;

        this.error = null;

        if (_.isUndefined(uuid)) {
            return $q.when(true);
        }

        this.pool = new Pool({ id: uuid });

        return this.pool.load().then(function() {
            self.pool.beforeEdit();
        }).catch(function(rsp) {
            self.error = rsp.data.error;
        });
    };

    /**
     * Sets pool object. Need to add network_ref and subnet to the pool's placement_networks list
     * if the placement network doesn't already contains the subnet.
     * @param  {string} networkRef - Reference URL of the network.
     * @param  {string} subnet - String of subnet selected by user.
     * @param  {object} pool - Pool being edited.
     * @return {promise} Promise returned from pool.save();
     */
    NetworkResolutionFactory.prototype.setPool = function(networkRef, subnet, pool) {
        const self = this;
        let poolNetwork;

        // If no pool, return
        if (!pool || !pool.data || !pool.data.config) {
            return;
        }

        // If pool doesn't contain placement_networks list, create an empty one. Else search
        // existing list for one with the same subnet.
        if (!pool.data.config.placement_networks) {
            this.placement_networks = [];
        } else {
            this.placement_networks = angular.copy(pool.data.config.placement_networks);
            poolNetwork = this.getSelectedSubnet(self.placement_networks, subnet);
        }

        // If no network found, add new one to the placement_networks list. If found, just overwrite
        // the network_ref.
        if (!poolNetwork) {
            this.placement_networks.push({
                network_ref: networkRef,
                subnet,
            });
        } else {
            poolNetwork.network_ref = networkRef;
        }
    };

    /**
     * Saves item.
     * @return {promise} Promise returned from item.save();
     */
    NetworkResolutionFactory.prototype.saveItem = function(item) {
        const self = this;

        this.error = null;

        if (!item || !item.data || !item.data.config) {
            return $q.when(true);
        }

        return item.save().catch(function(rsp) {
            self.error = rsp.data.error;
        });
    };

    /**
     * Creates list of subnet IP addresses from configuredSubnets and discoveredSubnets lists.
     * @param {Object[]} configuredSubnets - List of configured subnets containing a subnet address.
     * @param {Object[]} discoveredSubnets - List of discovered subnets containing a subnet address.
     */
    NetworkResolutionFactory.prototype.getSubnets = function(configuredSubnets, discoveredSubnets) {
        const subnetsHash = {};

        configuredSubnets.forEach(function(subnet) {
            subnetsHash[subnet.subnet] = true;
        });
        discoveredSubnets.forEach(function(subnet) {
            subnetsHash[subnet.subnet] = true;
        });
        this.subnetsList = Object.keys(subnetsHash);
    };

    /**
     * Creates list of subnet IP addresses from a network. Used for multiple network resolution.
     * @param  {object} network - Contains list of subnets.
     */
    NetworkResolutionFactory.prototype.getSubnetsMultipleNetworks = function(network) {
        const subnetsList = [];

        network.subnet.forEach(function(subnet) {
            subnetsList.push(`${subnet.ip_addr.addr}/${subnet.mask}`);
        });
        this.subnetsList = subnetsList;
    };

    /**
     * Sets selected_subnet based on addManualSubnet boolean. If true, user is adding a manual
     * subnet so clear out selected_subnet. If false, selected_subnet should be set to first string
     * in subnetsLits.
     * @param  {boolean} addManualSubnet - True if user is adding a manual subnet. False if user is
     *     choosing from existing subnet(s).
     * @param  {string[]} subnetsList - List of subnet addresses.
     */
    NetworkResolutionFactory.prototype.setSelectedSubnet = function(addManualSubnet, subnetsList) {
        this.selected_subnet = addManualSubnet ? null : subnetsList[0];
    };

    /**
     * Makes request to Virtual Service runtime API.
     * @param  {string} slug - Virtual Service slug.
     * @return {promise} Http request promise.
     */
    NetworkResolutionFactory.prototype.getRuntime = function(slug) {
        const self = this;

        this.error = null;

        return base.request('GET', `/api/virtualservice/${slug}/runtime?include_name`)
            .then(function(rsp) {
                self.vs_runtime = rsp.data;
            }).catch(function(rsp) {
                self.error = rsp.data.error;
            });
    };

    /**
     * Makes request to networkruntime API.
     * @param  {string} uuid - Network uuid.
     * @return {promise} Http request promise.
     */
    NetworkResolutionFactory.prototype.getNetworkRuntime = function(uuid) {
        const self = this;

        this.error = null;

        return base.request('GET', `/api/networkruntime/${uuid}?include_name`)
            .then(function(rsp) {
                self.networkruntime = rsp.data;
            }).catch(function(rsp) {
                self.error = rsp.data.error;
            });
    };

    /**
     * Looks through subnet_runtime in networkruntime object for one matching selected subnet.
     * @param {Object} networkruntime - Networkruntime object containing subnet_runtime objects.
     * @param {string} subnet - String of subnet selected by user.
     */
    NetworkResolutionFactory.prototype.setSubnetRuntime = function(networkruntime, subnet) {
        const subnetRuntime = _.find(networkruntime.subnet_runtime, function(s) {
            return `${s.prefix.ip_addr.addr}/${s.prefix.mask}` === subnet;
        });

        this.subnet_runtime = subnetRuntime;
    };

    /**
     * Gets the subnet object by looking through the list of subnets and returning the
     * subnet with the same subnet string or prefix IP address and the mask as the one selected.
     * @param  {Object[]} subnetsList - List containing subnets with IP address properties.
     * @param  {string} selectedSubnet - Subnet to search through list for match.
     * @return {object} Subnet object containing subnet and subnet IP address properties.
     */
    NetworkResolutionFactory.prototype.getSelectedSubnet = function(subnetsList, selectedSubnet) {
        if (!selectedSubnet || !subnetsList) {
            return;
        }

        const splitSubnet = selectedSubnet.split('/');

        return _.find(subnetsList, function(subnet) {
            return subnet.subnet === selectedSubnet ||
                (subnet.prefix ?
                    subnet.prefix.ip_addr.addr === splitSubnet[0] &&
                subnet.prefix.mask === +splitSubnet[1] : false);
        });
    };

    /**
     * Sets NetworkResolutionFactory.static_ipaddr_tmp to the IP addresses listed in the selected
     * subnet. Only configured subnets will have a list of pool addresses.
     * @param {Object[]} configuredSubnets - List of configured subnets.
     * @param {string} subnet - String of subnet selected by user.
     */
    NetworkResolutionFactory.prototype.setStaticIpaddrTmp = function(configuredSubnets, subnet) {
        const configuredSubnet = this.getSelectedSubnet(configuredSubnets, subnet);

        if (_.isUndefined(configuredSubnet) || configuredSubnet.static_ipaddr_tmp.length === 0) {
            this.static_ipaddr_tmp = [];
        } else {
            this.static_ipaddr_tmp = angular.copy(configuredSubnet.static_ipaddr_tmp);
        }
    };

    /**
     * Searches through configured_subnets list for a subnet matching the user-selected subnet. If
     * found, update that subnet with the pool IP address. Otherwise, the subnet was either
     * discovered or newly added by the user, so a new subnet object needs to be added to the
     * configured_subnets list.
     * @param {Object[]} configuredSubnets - List of configured subnets.
     * @param {string} subnet - String of subnet selected by user.
     * @param {string} ip - String of pool IP address added by user.
     */
    NetworkResolutionFactory.prototype.setConfiguredSubnets = function(subnets, subnet, ip) {
        const configuredSubnet = this.getSelectedSubnet(subnets, subnet);

        if (ip) {
            if (_.isUndefined(configuredSubnet)) {
                subnets.push({
                    subnet,
                    static_ipaddr_tmp: [ip],
                });
            } else {
                configuredSubnet.static_ipaddr_tmp.push(ip);
            }
        }

        this.configured_subnets = subnets;
    };

    return NetworkResolutionFactory;
}]);
