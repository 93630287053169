/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('GraphSync', ['Base', function(Base) {
    // Inherit from base to allow event listeners.
    const graphSync = new Base();

    graphSync.sync = _.throttle(function(mouse) {
        this.mouseOnGraph = mouse;
        this.trigger('GraphSync mouseOnGraph', this.mouseOnGraph);
    }, 16);

    graphSync.hide = function() {
        this.mouseOnGraph = false;
        this.trigger('GraphSync hide', this.mouseOnGraph);
    };

    graphSync.toggle = function() {
        this.stuck = !this.stuck;
        this.trigger('GraphSync toggle', this.mouseOnGraph);
    };

    /**
     * Returns unix timestamp when mouse is hovering over chart, false otherwise.
     * @returns {boolean|number}
     * @public
     */
    graphSync.mouseIsOnGraph = function() {
        return this.mouseOnGraph;
    };

    return graphSync;
}]);
