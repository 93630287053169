/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module PoolModule */

import {
    copy,
    IPromise,
} from 'angular';

import {
    Component,
    Type,
} from '@angular/core';

import {
    isArray,
    isNaN,
    isUndefined,
    reduce,
    sortBy,
} from 'underscore';

import {
    AviPermissionResource,
    IPoolGroup,
    IPoolGroupMember,
} from 'generated-types';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { RoleFilterMatchLabelConfigItem }
    from 'ajs/modules/data-model/factories/role-filter-match-label.config-item.factory';

import { PoolGroup as PoolGroupObjectType } from 'object-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { StringService } from 'ajs/modules/core/services/string-service';
import * as globalL10n from 'global-l10n';
import { L10nService } from '@vmw/ngx-vip';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { Pool } from './pool.item.factory';
import { FailActionConfigItem } from './fail-action.config-item.factory';
import { PoolGroupMemberConfigItem } from './pool-group-member.config-item.factory';
import { POOL_ITEM_TOKEN } from '../pool.tokens';
import { PoolCollection } from './pool.collection.factory';

const { ...globalL10nKeys } = globalL10n;

interface IExtendedPoolGroupMember extends IPoolGroupMember {
    pool: Pool;
}

interface IPriorityHashMembers {
    priority: string,
    members: IPoolGroupMember[],
}

type TPoolGroupConfigPartial = Omit<IPoolGroup, 'fail_action' | 'markers' | 'members'>;

interface IPoolGroupConfig extends TPoolGroupConfigPartial {
    fail_action: FailActionConfigItem,
    markers: RepeatedMessageItem<RoleFilterMatchLabelConfigItem>,
    members: RepeatedMessageItem<PoolGroupMemberConfigItem>,
}

interface IPoolGroupData {
    config: IPoolGroupConfig;
}

/**
 * @description PoolGroup collection item.
 *
 * @author Nisar Nadaf, sghare
 */
export class PoolGroup extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'Collection',
        'poolFailActionService',
        'stringService',
        'l10nService',
        POOL_ITEM_TOKEN,
    ];

    /**
     * Function to get PoolGroup config.
     */
    public getConfig: () => IPoolGroupConfig;

    /**
     * PoolGroup data.
     */
    public data: IPoolGroupData;

    // TODO: change any to PoolFailActionService class once it's been converted to ts
    private poolFailActionService: any;

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'poolgroup',
            objectType: PoolGroupObjectType,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUP,
            ...args,
        };

        super(extendedArgs);

        this.poolFailActionService = this.getAjsDependency_('poolFailActionService');
        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * Sort function used in grid config to sort by priority_label.
     */
    public static gridSortByPriority(a: IPoolGroupMember, b: IPoolGroupMember): number {
        const priorityA = isNaN(+a.priority_label) ? 0 : +a.priority_label;
        const priorityB = isNaN(+b.priority_label) ? 0 : +b.priority_label;

        return priorityB - priorityA;
    }

    /**
     * Matches pools to the pool_refs within members to be used in unit cards.
     */
    public static matchPoolsToMembers(
        members: IExtendedPoolGroupMember[],
        pools: Pool[],
    ): IExtendedPoolGroupMember[] {
        const stringService: StringService = this.getAjsDependency_('stringService');
        const poolHash = pools.reduce((acc: Record<string, Pool>, pool: Pool) => {
            acc[pool.id] = pool;

            return acc;
        }, {});

        if (isArray(members) && members.length) {
            members.forEach((member: IExtendedPoolGroupMember) =>
                member.pool = poolHash[stringService.slug(member.pool_ref)]);
        }

        return members;
    }

    /**
     * Sort pool members based on priority_label, then by ratio. Used by poolGroupListExpander.
     */
    public static sortMembersByPriorityLabel(
        members: RepeatedMessageItem<PoolGroupMemberConfigItem>,
    ): PoolGroupMemberConfigItem[] {
        if (isUndefined(members.config) || !members.count) {
            return [];
        }

        const membersCopy = copy(members.config);

        return membersCopy.sort((a: PoolGroupMemberConfigItem, b: PoolGroupMemberConfigItem) => {
            const priorityA = +a.config.priority_label || 0;
            const priorityB = +b.config.priority_label || 0;

            return priorityA === priorityB ?
                b.config.ratio - a.config.ratio : priorityB - priorityA;
        });
    }

    /**
     * Converts a list of Pool Group members into a list of Pools sorted by priority then ratio.
     */
    public static membersToSortedPools(members: IPoolGroupMember[]): IPriorityHashMembers[] {
        const priorityHash = PoolGroup.getPriorityToMembersHash(members);

        return PoolGroup.priorityHashToSortedPools(priorityHash);
    }

    /**
     * Sort function that sorts two objects with priority.
     */
    private static sortByPriority(a: IPriorityHashMembers, b: IPriorityHashMembers): number {
        const priorityA = isNaN(+a.priority) ? 0 : +a.priority;
        const priorityB = isNaN(+b.priority) ? 0 : +b.priority;

        return priorityB - priorityA;
    }

    /**
     * Returns a hash of priority values to Pool Group members.
     */
    private static getPriorityToMembersHash(
        members: IPoolGroupMember[] = [],
    ): Record<string, IPoolGroupMember[]> {
        return members.reduce((
            acc: Record<string, IPoolGroupMember[]>,
            member: IPoolGroupMember,
        ) => {
            const priority = member.priority_label || '--';

            if (isUndefined(acc[priority])) {
                acc[priority] = [];
            }

            acc[priority].push(member);

            return acc;
        }, {});
    }

    /**
     * Returns an array of objects containing a priority property and list of Pools with that
     * priority. The list of pools are sorted by ratio.
     */
    private static priorityHashToSortedPools(
        priorityHash: Record<string,
        IPoolGroupMember[]>,
    ): IPriorityHashMembers[] {
        return reduce(priorityHash,
            (acc: IPriorityHashMembers[], members: IPoolGroupMember[], priority: string) => {
                acc.push({
                    priority,
                    members: sortBy(members, 'ratio'),
                });

                return acc;
            }, []).sort(PoolGroup.sortByPriority);
    }

    /**
     * Returns the cloud_ref configured on the PoolGroup.
     */
    public getCloudRef(): string {
        const { cloud_ref: cloudRef } = this.getConfig();

        return cloudRef;
    }

    /**
     * Making an API call to figure what VRF context is used by the first pool in the list.
     * All pools of the poolGroup are using the same VRF context and poolGroup's config
     * doesn't have vrf_context_uuid property.
     */
    public getVRFContextRef(): IPromise<string> {
        const Collection = this.getAjsDependency_('Collection');
        const poolItemClass: Pool = this.getAjsDependency_(POOL_ITEM_TOKEN);
        const stringService: StringService = this.getAjsDependency_('stringService');
        const { members } = this.getConfig();

        if (!members || !members.count) {
            return Promise.reject(
                new Error('PoolGroup has no member to figure out VRF Context ref'),
            );
        }

        const { config: [poolConfig] } = members;
        const { config: { pool_ref: poolRef } } = poolConfig;

        // can't use PoolCollection cause that one is inventory (no "fields" param support)
        // we could load single pool as well but such approach has the exact same problem
        const poolCollection = new Collection({
            objectName: 'pool',
            itemClass: poolItemClass,
            limit: 1,
            params: {
                fields: 'vrf_ref,tenant_ref',
                uuid: stringService.slug(poolRef),
            },
        });

        const promise = poolCollection.load()
            .then(() => {
                const [pool] = poolCollection.items;

                return pool.config.vrf_ref;
            })
            .finally(() => poolCollection.destroy());

        return promise;
    }

    /**
     * Updates fail action configuration based on the type passed.
     */
    public onFailActionTypeChange(): void {
        this.config.fail_action.onTypeChange();
    }

    /**
     * Convenience method to get members.
     */
    public get members(): RepeatedMessageItem<PoolGroupMemberConfigItem> {
        return this.config.members;
    }

    /**
     * Opens modal to add PoolGroup Member.
     */
    public addPoolGroupMember(poolCollection: PoolCollection): void {
        this.addChildMessageItem({
            field: 'members',
            modalBindings: {
                poolCollection,
            },
        });
    }

    /**
     * Opens modal to edit PoolGroup Member.
     */
    public editPoolGroupMember(
        poolGroupMember: PoolGroupMemberConfigItem,
        poolCollection: PoolCollection,
    ): void {
        this.editChildMessageItem({
            field: 'members',
            messageItem: poolGroupMember,
            modalBindings: {
                editMode: true,
                poolCollection,
            },
        });
    }

    /**
     * Removes PoolGroup Member.
     */
    public removePoolGroupMember(poolGroupMember: PoolGroupMemberConfigItem): void {
        this.members.removeByMessageItem(poolGroupMember);
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            PoolGroupModalComponent,
        } = await import(
            /* webpackChunkName: "pool-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/pool-group-modal/pool-group-modal.component'
        );

        return PoolGroupModalComponent as Type<Component>;
    }

    /** @override */
    protected beforeEdit(): void {
        const config = this.getConfig();

        config.fail_action = this.poolFailActionService.beforeEdit(config.fail_action);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.poolGroupLabel);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'fail_action',
        ];
    }
}
