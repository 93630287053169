/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { CustomIpamDnsProfileModalComponent } from 'ng/modules/ipam';
import { CUSTOM_IPAM_PROFILE_ITEM_TOKEN } from '../ipam.tokens';

/**
 * @desc CustomIpamDnsProfile collection class
 *
 * @author Aravindh Nagarajan
 */
export class CustomIpamDnsCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'customipamdnsprofile',
            objectType: 'CustomIpamDnsProfile',
            windowElement: CustomIpamDnsProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CUSTOMIPAMDNSPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(CUSTOM_IPAM_PROFILE_ITEM_TOKEN);
    }
}

CustomIpamDnsCollection.ajsDependencies = [
    CUSTOM_IPAM_PROFILE_ITEM_TOKEN,
];
