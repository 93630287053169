/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IIpAddr,
    IpAddrType,
} from 'generated-types';

import {
    hostname,
    ip,
    ipv6,
} from 'ng/utils/regex.utils';

import {
    MessageItem,
} from '.';

/**
 * @description IpAddr MessageItem.
 *
 * @author Alex Tseung, Aravindh Nagarajan
 */
export class IpAddrConfigItem extends MessageItem<IIpAddr> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddr',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    public canFlatten(): boolean {
        return this.isValid();
    }

    /**
     * Returns true if this MessageItem has a valid configuration.
     */
    public isValid(): boolean {
        return Boolean(this.address && this.type);
    }

    /**
     * Sets the addr property in the config.
     */
    public set address(address: string) {
        this.config.addr = address;

        if (ip.test(address)) {
            this.type = IpAddrType.V4;
        } else if (hostname.test(address)) {
            this.type = IpAddrType.DNS;
        } else if (ipv6.test(address)) {
            this.type = IpAddrType.V6;
        } else {
            this.type = undefined;
        }
    }

    /**
     * Returns the set address.
     */
    public get address(): string | undefined {
        return this.config?.addr;
    }

    /**
     * Sets the type property in the config.
     */
    public set type(type: IpAddrType | undefined) {
        this.config.type = type;
    }

    /**
     * Returns the set type.
     */
    public get type(): IpAddrType | undefined {
        return this.config.type;
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        return this.address;
    }
}
