/**
 * @module accountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AviPermissionResource,
    IPermission,
    IRole,
} from 'generated-types';

import {
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { Role } from 'object-types';
import { RoleModalComponent } from 'ng/modules/accounts/components/role-modal';
import { ROLE_SERVICE_TOKEN } from '../../accounts.tokens';
import { RoleService } from '../../services/role-service/role.service';
import { IRoleNode } from '../../services/role-service/role.types';
import { RoleFilterConfigItem } from './role-filter.config-item.factory';

/**
 * Interface to add UI only field in IRole.
 */
interface IExtendedRole extends IRole {
    transformedPrivileges?: IRoleNode[],
}

type TRolePartial = Omit<IExtendedRole, 'filters'>;

interface IRoleConfig extends TRolePartial {
    filters: RepeatedMessageItem<RoleFilterConfigItem>,
}

interface IRoleData {
    config: IRoleConfig;
}

/**
 * @description Role Item.
 * @author Nisar Nadaf
 */
export class RoleItem extends withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        ROLE_SERVICE_TOKEN,
    ];

    public data: IRoleData;

    private readonly roleService: RoleService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'role',
            objectType: Role,
            windowElement: RoleModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ROLE,
            ...args,
        };

        super(extendedArgs);

        this.roleService = this.getAjsDependency_(ROLE_SERVICE_TOKEN);
    }

    /**
     * @override
     */
    public beforeEdit(): IExtendedRole {
        const config = this.getConfig();

        config.transformedPrivileges = this.transformData(config.privileges);

        return config;
    }

    /**
     * Transforms permissions tree structure into server privileges data structure and
     * appends the privileges which are not exposed to UI but added through CLI to
     * the config privileges list in order to avoid resetting them.
     * @override
     */
    public dataToSave(): IRole {
        const config = super.dataToSave();
        const {
            transformedPrivileges: latestPrivileges,
        } = config;

        const latestPrivilegesHash = this.roleService.flattenRoles(latestPrivileges);

        config.privileges = Object.values(latestPrivilegesHash);

        delete config.transformedPrivileges;

        return config;
    }

    /**
     * Returns transformed Privileges from config.
     */
    public getPrivileges(): IRoleNode[] {
        let config = this.getConfig();

        if (!config.transformedPrivileges) {
            config = this.beforeEdit();
        }

        return config.transformedPrivileges;
    }

    /**
     * Returns label filters list.
     */
    public get filters(): RepeatedMessageItem<RoleFilterConfigItem> {
        return this.getConfig().filters;
    }

    /**
     * Method to add Label Filter.
     */
    public addLabelFilter(): void {
        this.addChildMessageItem({
            field: 'filters',
        });
    }

    /**
     * Method to remove Role Label Filter.
     */
    public removeRoleFilter(
        labelFilter: RoleFilterConfigItem,
    ): void {
        this.filters.removeByMessageItem(labelFilter);
    }

    /**
     * Method to edit Role Label Filter.
     */
    public editRoleFilter(labelFilter: RoleFilterConfigItem): void {
        this.editChildMessageItem({
            field: 'filters',
            messageItem: labelFilter,
        });
    }

    /**
     * Transforms default privileges data structure into tree structure.
     */
    private transformData(privileges: RepeatedMessageItem<MessageItem<IPermission>>): IRoleNode[] {
        const privilegeHashMap = this.roleService
            .createPrivilegeHashMap(privileges.flattenConfig());

        return this.roleService.processRoles(
            this.roleService.getRoleCategories(),
            privilegeHashMap,
            undefined,
        );
    }
}
