/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'dos-rate-limiter-vs';
const prefix = `${moduleName}.${componentName}`;

export const rateLimitNumberOfNewTcpConnectionsHeader = `${prefix}.rateLimitNumberOfNewTcpConnectionsHeader`;
export const thresholdInputLabel = `${prefix}.thresholdInputLabel`;
export const thresholdInputPlaceholder = `${prefix}.thresholdInputPlaceholder`;
export const timePeriodInputLabel = `${prefix}.timePeriodInputLabel`;
export const timePeriodInputPlaceholder = `${prefix}.timePeriodInputPlaceholder`;
export const secInnerText = `${prefix}.secInnerText`;
export const actionInputLabel = `${prefix}.actionInputLabel`;
export const rateLimitNumberOfNewHttpRequestHeader = `${prefix}.rateLimitNumberOfNewHttpRequestHeader`;
export const statusCodeInputLabel = `${prefix}.statusCodeInputLabel`;
export const fileInputLabel = `${prefix}.fileInputLabel`;
export const protocolInputLabel = `${prefix}.protocolInputLabel`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const keepQueryInputLabel = `${prefix}.keepQueryInputLabel`;
export const hostInputLabel = `${prefix}.hostInputLabel`;
export const pathInputLabel = `${prefix}.pathInputLabel`;

export const ENGLISH = {
    [rateLimitNumberOfNewTcpConnectionsHeader]: 'Rate Limit Number of New TCP Connections',
    [thresholdInputLabel]: 'Threshold',
    [thresholdInputPlaceholder]: 'Infinite',
    [timePeriodInputLabel]: 'Time Period',
    [timePeriodInputPlaceholder]: 'Infinite Time',
    [secInnerText]: 'sec',
    [actionInputLabel]: 'Action',
    [rateLimitNumberOfNewHttpRequestHeader]: 'Rate Limit Number of New HTTP Requests',
    [statusCodeInputLabel]: 'Status Code:',
    [fileInputLabel]: 'File:',
    [protocolInputLabel]: 'Protocol:',
    [portInputLabel]: 'Port:',
    [keepQueryInputLabel]: 'Keep query',
    [hostInputLabel]: 'Host:',
    [pathInputLabel]: 'Path:',
};
