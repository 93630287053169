/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { VsLogsSearchBarStore } from '../vs-logs-search-bar.store';
import {
    VsLogsSearchBarDropdownService,
} from '../../../../services/vs-logs-search-bar-dropdown.service';
import './vs-logs-filter-input.component.less';

/**
 * @description
 *      VS Logs input component to manage input size and styling
 *      and set search bar store state.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-filter-input',
    templateUrl: './vs-logs-filter-input.component.html',
})
export class VsLogsFilterInputComponent implements AfterViewInit, OnDestroy {
    /**
     * Flag to indicate whether input is an editable input,
     * which gives it some unique properties like resizing with input size.
     */
    @Input()
    public isEditable = false;

    /**
     * Placeholder for the input.
     */
    @Input()
    public placeholder = '';

    /**
     * Event emitters for input actions.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    @Output()
    public onBackspace = new EventEmitter<void>();

    /**
     * Ref for search input element.
     */
    @ViewChild('searchInput')
    public searchInputRef: ElementRef<HTMLInputElement>;

    /**
     * Flag to track whether this input is currently focused on.
     */
    public isActive = false;

    /**
     * Value of the filter in the input.
     */
    public filter = '';

    /**
     * Subscribe to the filter value from the store.
     */
    private inputFilterValueSubscription: Subscription;

    /**
     * Subscribe to submit events. Submit the current filter in the input if it is active.
     */
    private readonly submitSubscription = this.vsLogsSearchBarDropdownService.submit$.subscribe(
        () => {
            if (this.isActive) {
                this.submit();
            }
        },
    );

    /**
     * Subscribe to dropdown stage change events. Recofus the input if it is active.
     */
    private readonly dropdownStageChangeSubscription =
    this.vsLogsSearchBarDropdownService.dropdownStageChange$.subscribe(() => {
        if (this.isActive) {
            this.refocus();
        }
    });

    constructor(
        public readonly vsLogsSearchBarStore: VsLogsSearchBarStore,
        public readonly vsLogsSearchBarDropdownService: VsLogsSearchBarDropdownService,
    ) {}

    /**
     * @override
     * Initialize input size and focus into it if needed.
     */
    public ngAfterViewInit(): void {
        this.inputFilterValueSubscription = this.vsLogsSearchBarStore.inputFilterValue$
            .subscribe(filter => {
                this.filter = filter;
                this.updateInputSize();
            });

        if (this.isEditable) {
            this.searchInputRef.nativeElement.focus();
        } else {
            this.vsLogsSearchBarStore.primaryFilterInput = this.searchInputRef;
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.inputFilterValueSubscription.unsubscribe();
        this.submitSubscription.unsubscribe();
        this.dropdownStageChangeSubscription.unsubscribe();
    }

    /**
     * Set this input to active and open the dropdown upon focus.
     */
    public handleFocus(): void {
        this.isActive = true;
        this.vsLogsSearchBarDropdownService.openDropdown();
    }

    /**
     * Handle ngModel, input size, and update store.
     */
    public handleFilterChange(filter: string): void {
        // ngModel sets empty input to undefined; change to empty string for below
        if (!filter) {
            filter = '';
        }

        this.vsLogsSearchBarStore.setInputFilterValue(filter);

        if (this.isEditable) {
            this.updateInputSize();
        }
    }

    /**
     * Submit the current filter value and remove the focus.
     */
    public submit(): void {
        this.isActive = false;
        this.searchInputRef.nativeElement.blur();
        this.onSubmit.emit();
    }

    /**
     * Change the size of the input to match the width of the current text
     * when the user is editing a filter.
     */
    private updateInputSize(): void {
        // Set a minimum size at 4
        const inputSize = Math.max(4, this.filter.length + 3);

        this.searchInputRef.nativeElement.setAttribute('size', inputSize.toString());
    }

    /**
     * Refocus the input.
     */
    private refocus(): void {
        this.searchInputRef.nativeElement.focus();
    }
}
