/**
 * @module avi/dataModel
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isNaN, isUndefined } from 'underscore';

import {
    IEditableChildren,
    IGenericMessageItemConfig,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import { TModalBindings } from 'ajs/modules/data-model/data-model.types';
import { Constructor } from '../../../declarations/globals.d';

interface IEditChildArgs {
    field: string,
    config?: Record<string, any>, // initial config used when creating new messageItem.
    messageItem?: MessageItem,
    modalBindings?: TModalBindings,
    windowElement?: string,
}

/**
 * Mixin that adds methods for editing child message items, or message items within the config.
 * There are two ways of editing:
 *     - After editing, simply replace the old message item instance with the edited message item
 *       instance.
 *     - After editing, replace the old message item instance with the edited message item
 *       instance then make a save request.
 *
 * @example
 * This mixin should be applied to the parent ObjectTypeItem or MessageItem in the parent's
 * definition:
 * ```
 * class WafPSMLocationConfigItem extends withEditChildMessageItemMixin(MessageItem) {}
 * ```
 *
 * @example
 * To edit or add a child message item, create a method on the parent message item that calls
 * this.editChildMessageItem or this.addChildMessageItem. This is done so that the component doesn't
 * need to have knowledge of the 'field', as a separation of concerns.
 * ```
 * export class WafPSMLocationConfigItem extends withEditChildMessageItemMixin(MessageItem) {
 *     public editPSMRule(rule: WafPSMRuleConfigItem): void {
 *          this.editChildMessageItem({
 *              field: 'rules',
 *              messageItem: rule,
 *          });
 *     }
 * }
 * ```
 *
 * @example
 * Since this is a mixin, it can be applied to an ObjectTypeItem or MessageItem alongside other
 * mixins. For example, for a parent ObjectTypeItem that uses the withFullModalMixin, we can do
 * ```
 * class SystemConfig extends withFullModalMixin(withEditChildMessageItemMixin(ObjectTypeItem)) {}
 * ```
 *
 * @author alextsg
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function withEditChildMessageItemMixin<
    S extends IGenericMessageItemConfig,
    T extends Constructor<IEditableChildren<S>>
>(
    BaseClass: T,
) {
    return class EditableChildMessageItem extends BaseClass {
        /**
         * Called to make a backend save request. Can be overridden if the save request involves a
         * unique API or payload.
         */
        protected saveChildMessageItem(
            editChildArgs: IEditChildArgs,
        ): ng.IPromise<ng.IHttpResponse<any>> {
            return this.save();
        }

        /**
         * Call to add a new message item.
         */
        protected addChildMessageItem(
            editChildArgs: IEditChildArgs,
            saveOnSubmit = false,
        ): Promise<MessageItem> {
            const { field, config } = editChildArgs;

            const newMessageItem = this.createChildByField(field, undefined, true) as MessageItem;

            // so that default config wont be lost.
            if (config) {
                newMessageItem.mergeConfig(config);
            }

            const extendedEditChildArgs = {
                messageItem: newMessageItem,
                ...editChildArgs,
            };

            return this.editChildMessageItem(extendedEditChildArgs, saveOnSubmit);
        }

        /**
         * Call to edit an existing message item. If saveOnSubmit is true, we execute a backend save
         * request after adding the child message item to the config.
         */
        protected editChildMessageItem(
            editChildArgs: IEditChildArgs,
            saveOnSubmit = false,
        ): Promise<MessageItem> {
            const {
                messageItem,
                field,
                modalBindings,
                windowElement,
            } = editChildArgs;

            const messageItemToEdit = messageItem || this.config[field];
            const terminate$ = new Subject();
            let index: number;

            // calculate array index using originalMessageItem
            // index will be NaN if its new messageItem.
            if (this.config[field] instanceof RepeatedMessageItem) {
                index = this.config[field].getArrayIndexWithMessageItem(messageItemToEdit);
            }

            return new Promise(resolve => {
                messageItemToEdit.edit(windowElement, modalBindings)
                    .pipe(takeUntil(terminate$))
                    .subscribe(
                        (editedMessageItem: MessageItem) => {
                            const backup =
                                this.addChildToConfig(editedMessageItem, field, index);

                            const promise = saveOnSubmit ?
                                this.saveChildMessageItem(editChildArgs) :
                                Promise.resolve() as ng.IPromise<any>;

                            editedMessageItem.setErrors(null);
                            editedMessageItem.setBusy(true);

                            promise
                                .then(() => {
                                    // Save request succeeded. Modal can be dismissed and
                                    // terminate$.next is called to end the subscription.
                                    editedMessageItem.dismiss();
                                    terminate$.next();
                                    resolve(editedMessageItem);
                                })
                                .catch((errors: any) => {
                                    // If a save request error occurs, sets the error on the child
                                    // message item and does not dismiss the modal so that the user
                                    // can see the error and make changes.
                                    editedMessageItem.setErrors(errors?.data ?? errors);
                                    this.restoreBackup(backup, editedMessageItem, field);
                                })
                                .finally(() => {
                                    editedMessageItem.setBusy(false);
                                });
                        },
                        // Called when the modal is closed by the user.
                        () => {
                            messageItemToEdit.dismiss();
                        },
                    );
            });
        }

        /**
         * Called to add a message item to a field in the config. If the field contains a repeated
         * message item, calls this.addRepeatedChildToConfig to add the new message item, otherwise
         * just sets the new message item on the field.
         * @returns The existing messageItem overwritten by the newly edited messageItem. Possibly
         * undefined if there was no existing messageItem.
         */
        private addChildToConfig(
            messageItem: MessageItem,
            field: string,
            index?: number,
        ): MessageItem | undefined {
            let existingMessageItem;

            if (this.config[field] instanceof RepeatedMessageItem) {
                existingMessageItem = this.addRepeatedChildToConfig(
                    messageItem, field, index,
                );
            } else {
                existingMessageItem = this.config[field];

                this.config[field as keyof S] =
                    messageItem as (IGenericMessageItemConfig & S)[keyof S];
            }

            return existingMessageItem;
        }

        /**
         * Called by this.addChildToConfig to add a message item to a repeated message item.
         *
         * If index parameter(array index) is not NaN,
         * replaces an existing message item with the same index,
         * otherwise adds the message item to the end of the list with a new index.
         *
         * @returns The existing messageItem overwritten by the newly edited messageItem. Possibly
         * undefined if a new messageItem was simply added.
         */
        private addRepeatedChildToConfig(
            messageItem: MessageItem,
            field: string,
            index?: number,
        ): MessageItem | undefined {
            const repeatedMessageItem = this.config[field];
            let existingMessageItem;

            // New messageItem.
            if (isUndefined(index) || isNaN(index)) {
                if (messageItem.hasIndexField()) {
                    messageItem.setIndex(repeatedMessageItem.getNextIndex());
                }

                repeatedMessageItem.add(messageItem);
            } else {
                existingMessageItem = repeatedMessageItem.config[index];

                repeatedMessageItem.config[index] = messageItem;
            }

            return existingMessageItem;
        }

        /**
         * Called when a saveOnSubmit fails. The edited messageItem that was added to the parent
         * needs to be reverted back to the original messageItem.
         */
        private restoreBackup(
            backupMessageItem: MessageItem | undefined,
            editedMessageItem: MessageItem,
            field: string,
        ): void {
            const fieldValue = this.config[field];

            if (fieldValue instanceof RepeatedMessageItem) {
                const repeatedMessageItem = this.config[field];

                // If backupMessageItem exists, that means an existing messageItem was replaced.
                if (backupMessageItem) {
                    const index = backupMessageItem.getIndex();
                    const arrayIndex = repeatedMessageItem.getArrayIndexWithIndexField(index);

                    repeatedMessageItem.config[arrayIndex] = backupMessageItem;
                // If backupMessageItem does not exist, that means the editedMessageItem was simply
                // added, so we can just remove it.
                } else {
                    repeatedMessageItem.removeByMessageItem(editedMessageItem);
                }
            } else {
                this.config[field as keyof S] =
                    backupMessageItem as (IGenericMessageItemConfig & S)[keyof S];
            }
        }
    };
}
