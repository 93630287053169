/***************************************************************************
 * ========================================================================
 * Copyright 2024 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @constructor
 * @memberof module:avi/dataModel
 * @extends MetricDataListCollDataSource
 * @author Zhiqian Liu
 * @description
 *     Updated Metric Data Source for Dns Query List.
 */
angular.module('aviApp').factory('DnsQueryMetricDataListCollDataSource', [
'MetricDataListCollDataSource',
MetricDataListCollDataSource => {
    class DnsQueryMetricDataListCollDataSource extends MetricDataListCollDataSource {
        /**
         * For DNS Query Collection, the results are filered to exclude entries with tcp/udp IDs, so
         * the real result length isn't the original length of the returned data. When the true
         * "count" is not returned, to avoid a wrong estimate calculation for this.itemsTotal_ by
         * checking respLength < limit, we need to pass the original repsonse length as the
         * "respLength" to calcFakeItemsTotal_.
         * @param {{data: DnsQueryListDataTransformerResponse}} resp - Backend response transformed
         *     by DNSQueryListDataTransformer.
         * @param {ListDataTransportRequestParams} requestParams - Result of
         *     ListCollDataSource#getRequestParams_ call which was used as a payload for the network
         *     call which is about to be processed.
         * @protected
         * @override
         */
        processConfigResponse_(resp, requestParams) {
            super.processConfigResponse_(resp, requestParams);

            // our best guess on total count
            if (this.hasPagination && !this.hasTotalNumberOfItems) {
                const { limit_: limit, offset_: offset } = requestParams;
                const { originalResponseLength } = resp.data;

                // the original repsonse length is passed for the "actualCountFound" checking in
                // calcFakeItemsTotal_
                this.itemsTotal_ = this.calcFakeItemsTotal_(offset, limit, originalResponseLength);
            } else if ('count' in resp.data) {
                this.itemsTotal_ = resp.data['count'];
            }
        }
    }

    return DnsQueryMetricDataListCollDataSource;
}]);
