/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { WEBHOOK_ITEM_TOKEN } from 'ajs/modules/auto-scale/auto-scale.tokens';

/**
 * @description Webhook Collection.
 *
 * @author Nitesh Kesarkar
 */
export class WebhookCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'webhook',
            permissionName: AviPermissionResource.PERMISSION_POOL,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(WEBHOOK_ITEM_TOKEN);
    }
}

WebhookCollection.ajsDependencies = [
    WEBHOOK_ITEM_TOKEN,
];
