/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { IP_ADDRESS_GROUP_ITEM_TOKEN } from '../../groups.tokens';

/**
 * Type of IpAddrGroupCollection.
 */
export type TIpAddrGroupCollection = typeof IpAddrGroupCollection;

/**
 * @description IP address group collection.
 *
 * @author Nisar Nadaf
 */
export class IpAddrGroupCollection extends Collection {
    public static ajsDependencies = [
        IP_ADDRESS_GROUP_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'ipaddrgroup',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_IPADDRGROUP,
            sortBy: 'name',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(IP_ADDRESS_GROUP_ITEM_TOKEN);
    }
}
