/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'mapping-rule-group-match';
const prefix = `${moduleName}.${componentName}`;

export const groupMatchLabel = `${prefix}.groupMatchLabel`;
export const memberLabel = `${prefix}.memberLabel`;
export const notMemberLabel = `${prefix}.notMemberLabel`;
export const anyLabel = `${prefix}.anyLabel`;
export const groupLabel = `${prefix}.groupLabel`;

export const ENGLISH = {
    [groupMatchLabel]: 'LDAP Group Match',
    [memberLabel]: 'Member',
    [notMemberLabel]: 'Not a Member',
    [anyLabel]: 'Any',
    [groupLabel]: 'Group',
};
