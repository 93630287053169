/**
 * @module BotModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { MatchModule } from 'ng/modules/match/match.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    BotAllowRuleModalComponent,
    BotDetectionResultMatchGridComponent,
    BotIpReputationTypeMappingModalComponent,
    BotManagementPolicyListComponent,
    BotManagementPolicyModalComponent,
    BotMappingModalComponent,
    BotMappingPageComponent,
    BotMappingRuleModalComponent,
    IpReputationTypeMappingModalComponent,
} from '.';
import {
    botDetectionPolicyCollectionProvider,
    botIpReputationTypeMappingCollectionProvider,
    botMappingCollectionProvider,
    stringGroupCollectionProvider,
} from './ajs-upgraded-providers';

const botDetectionResultMatchComponents = [
    BotAllowRuleModalComponent,
    BotDetectionResultMatchGridComponent,
    BotIpReputationTypeMappingModalComponent,
    BotManagementPolicyListComponent,
    BotManagementPolicyModalComponent,
    BotMappingRuleModalComponent,
    IpReputationTypeMappingModalComponent,
    BotMappingModalComponent,
    BotMappingPageComponent,
];

/**
 *  Angular Module for Bot.
 *  @author Sarthak Kapoor, Nitesh Kesarkar
 */
@NgModule({
    declarations: [
        ...botDetectionResultMatchComponents,
    ],
    imports: [
        AviFormsModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrFormsModule,
        MatchModule,
    ],
    providers: [
        botIpReputationTypeMappingCollectionProvider,
        botMappingCollectionProvider,
        stringGroupCollectionProvider,
        botDetectionPolicyCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class BotModule {}
