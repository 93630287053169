/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    AlertSyslogConfigItem,
    AlertSyslogServerConfigItem,
} from 'ajs/modules/alert/factories';

import { L10nService } from '@vmw/ngx-vip';
import { AlertSyslogConfig } from 'object-types';
import * as l10n from './alert-syslog-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * AlertSyslogConfigModalComponent modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'alert-syslog-modal',
    templateUrl: './alert-syslog-modal.component.html',
})
export class AlertSyslogConfigModalComponent implements OnInit {
    /**
     * AlertSyslogConfigItem instance.
     */
    @Input()
    public editable: AlertSyslogConfigItem;

    /**
     * Object type for alert syslog config.
     */
    public readonly objectType = AlertSyslogConfig;

    /**
     * Layout for AlertSyslog modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Syslog servers grid config.
     */
    public syslogServersGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        const {
            l10nService,
        } = this;

        this.syslogServersGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.addressLabel),
                    id: 'ip-addr',
                    transform({ config }) {
                        return config.syslog_server || '';
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.portLabel),
                    id: 'syslog-server-port',
                    transform({ config }) {
                        return config.syslog_server_port || undefined;
                    },
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                onClick: (syslogServers: AlertSyslogServerConfigItem[]) => {
                    syslogServers.forEach((syslogServer: AlertSyslogServerConfigItem) => {
                        this.deleteSyslogServer(syslogServer);
                    });
                },
            }],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (syslogServer: AlertSyslogServerConfigItem) => {
                        this.editSyslogServer(syslogServer);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (syslogServer: AlertSyslogServerConfigItem) => {
                        this.deleteSyslogServer(syslogServer);
                    },
                },
            ],
        };
    }

    /**
     * Opens AlertSyslogServer modal.
     */
    public createSyslogServers(): void {
        this.editable.addSyslogServer();
    }

    /**
     * Handler for Syslog Server edit.
     */
    private editSyslogServer(syslogServer: AlertSyslogServerConfigItem): void {
        this.editable.editSyslogServer(syslogServer);
    }

    /**
     * Handler for Syslog Server delete.
     */
    private deleteSyslogServer(syslogServer: AlertSyslogServerConfigItem): void {
        const { syslogServers } = this.editable;

        syslogServers.removeByMessageItem(syslogServer);
    }
}
