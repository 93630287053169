/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4RuleAction } from 'generated-types';
import {
    PolicyActionConfigItem,
} from 'ajs/modules/policies/factories/config-items/policy-action.config-item.factory';
import { L4RuleActionSelectPoolConfigItem } from '.';

interface IL4RuleActionConfig extends Omit<IL4RuleAction, 'select_pool'> {
    select_pool?: L4RuleActionSelectPoolConfigItem;
}

/**
 * @desc L4RuleAction MessageItem class.
 * @author Zhiqian Liu
 */
export class L4RuleActionConfigItem extends PolicyActionConfigItem<IL4RuleActionConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'L4RuleAction',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected requiredFields(): string[] {
        return ['select_pool'];
    }
}
