/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'server-analytics-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleHealthMonitor = `${prefix}.columnTitleHealthMonitor`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const columnTitleCurrentResponseTime = `${prefix}.columnTitleCurrentResponseTime`;
export const columnTitleAverageResponseTime = `${prefix}.columnTitleAverageResponseTime`;
export const columnTitleSuccess = `${prefix}.columnTitleSuccess`;
export const msInnerText = `${prefix}.msInnerText`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const requestStringLabel = `${prefix}.requestStringLabel`;
export const responseCodeLabel = `${prefix}.responseCodeLabel`;
export const responseStringLabel = `${prefix}.responseStringLabel`;
export const lastSeenBadResponseStringLabel = `${prefix}.lastSeenBadResponseStringLabel`;
export const reasonLabel = `${prefix}.reasonLabel`;
export const sslErrorLabel = `${prefix}.sslErrorLabel`;
export const analyticsLinkLabel = `${prefix}.analyticsLinkLabel`;
export const cpuStatsHeader = `${prefix}.cpuStatsHeader`;
export const serverLabel = `${prefix}.serverLabel`;
export const poolAvgLabel = `${prefix}.poolAvgLabel`;
export const hostLabel = `${prefix}.hostLabel`;
export const memoryStatsHeader = `${prefix}.memoryStatsHeader`;
export const poolLabel = `${prefix}.poolLabel`;
export const selectServerInputPlaceholder = `${prefix}.selectServerInputPlaceholder`;
export const serverMetricsHeader = `${prefix}.serverMetricsHeader`;

export const ENGLISH = {
    [columnTitleHealthMonitor]: 'Health Monitor',
    [columnTitleType]: 'Type',
    [columnTitleStatus]: 'Status',
    [columnTitleCurrentResponseTime]: 'Current Response Time',
    [columnTitleAverageResponseTime]: 'Average Response Time',
    [columnTitleSuccess]: 'Success',
    [msInnerText]: '{0}ms',
    [emptyDataLabel]: 'N/A',
    [requestStringLabel]: 'Request String:',
    [responseCodeLabel]: '<b>Response Code:</b>&nbsp {0}',
    [responseStringLabel]: 'Response String:',
    [lastSeenBadResponseStringLabel]: 'Last Seen Bad Response String:',
    [reasonLabel]: '<b>Reason:</b>&nbsp {0}',
    [sslErrorLabel]: '<b>SSL Error:</b>&nbsp {0}',
    [analyticsLinkLabel]: 'Analytics',
    [cpuStatsHeader]: 'CPU Stats',
    [serverLabel]: 'Server',
    [poolAvgLabel]: 'Pool Avg',
    [hostLabel]: 'Host',
    [memoryStatsHeader]: 'Memory Stats',
    [poolLabel]: 'Pool',
    [selectServerInputPlaceholder]: 'Select Server',
    [serverMetricsHeader]: 'Server Metrics',
};
