/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module IpamModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { ICustomIpamSubnet } from 'generated-types';
import { IpAddrPrefixConfigItem }
    from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';

import { MessageItem }
    from 'ajs/modules/data-model/factories/message-item.factory';

import { StringService } from 'ajs/modules/core/services/string-service';
import { SUBNET_LIST_NETWORK_TOKEN }
    from 'ajs/modules/network/factories/subnet-list-network.item.factory';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { UsableSubnetModalComponent }
    from 'ng/modules/ipam/components/ipam-dns-custom-profile-config';

import { CustomIpamSubnet } from 'object-types';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../ipam.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCustomIpamSubnetPartial = Omit<ICustomIpamSubnet,
'subnet' | 'subnet6'>;

interface ICustomIpamSubnetConfig extends TCustomIpamSubnetPartial {
    subnet?: IpAddrPrefixConfigItem;
    subnet6?: IpAddrPrefixConfigItem;
}

/**
 * @description UsableSubnet Item class.
 *
 * @author Hitesh Mandav
 */
export class CustomIpamSubnetConfigItem extends
    withFullModalMixin(MessageItem)<ICustomIpamSubnetConfig> {
    public static ajsDependencies = [
        'l10nService',
        'stringService',
        'devLoggerService',
        SUBNET_LIST_NETWORK_TOKEN,
    ];

    private readonly l10nService: L10nService;

    private readonly stringService: StringService;

    private readonly devLoggerService: DevLoggerService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: CustomIpamSubnet,
            objectType: CustomIpamSubnet,
            windowElement: UsableSubnetModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.stringService = this.getAjsDependency_('stringService');
        this.devLoggerService = this.getAjsDependency_('devLoggerService');
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.usableSubnetModalBreadcrumbTitle);
    }

    /**
     * Set new child by field for subnet and subnet6
     * and sets network ID to network ref value to be used in modal
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        if (!this.config.subnet) {
            this.safeSetNewChildByField('subnet');
        }

        if (!this.config.subnet6) {
            this.safeSetNewChildByField('subnet6');
        }
    }

    /**
     * Set the network id value back to uuid from network ref before saving
     * the data.
     * @override
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        if (!this.config.subnet.isValid()) {
            delete this.config.subnet;
        }

        if (!this.config.subnet6.isValid()) {
            delete this.config.subnet6;
        }
    }
}
