/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const componentName = 'c-grid-table-header-checkbox';

const template =
        `<checkbox
            class="sel-grid-select-all"
            ng-model="$ctrl.allRowsSelected"
            ng-disabled="$ctrl.isDisabled"
            ng-change="$ctrl.onAllSelectedChange({value: $ctrl.allRowsSelected})">
        </checkbox>
        <i class="icon icon-down-open-big clickable"
           ng-hide="$ctrl.isDisabled"
           ng-click="$ctrl.showOptions($event)"></i>`;

const tooltipTemplate =
        `<a
            href=""
            ng-hide="$ctrl.allRowsSelected"
            ng-click="$ctrl.selectAllRows()">
            Select All
        </a>
        <a
            href=""
            ng-hide="$ctrl.noRowsSelected"
            ng-click="$ctrl.deselectAllRows()">
            Clear All
        </a>`;

class GridTableHeaderCheckboxController {
    constructor($scope, $compile, popoverFactory) {
        this.$scope = $scope;
        this.$compile_ = $compile;
        this.PopoverFactory_ = popoverFactory;
    }

    $onInit() {
        this.popover = new this.PopoverFactory_({
            position: 'bottom',
            className: `${componentName}__popover`,
            repositioning: true,
            removeAfterHide: true,
            html: this.$compile_(tooltipTemplate)(this.$scope),
            hide: {
                outClick: true,
                innerClick: true,
                onEscape: true,
                onWinResize: true,
            },
        });
    }

    /**
     * Renders popover with available options.
     * @param {Object} e - Mouse click event object.
     */
    showOptions(e) {
        this.popover.show(e.target);
    }

    /**
     * Calls passed function to switch on "all selected" state.
     * Doesn't bother to change allRowsSelected value cause it will come updated from the
     * {@link grid}.
     */
    selectAllRows() {
        this.onAllSelectedChange({ value: true });
    }

    /**
     * Calls passed function to switch on "no selected" state.
     * Doesn't bother to change allRowsSelected value cause it will come updated from the
     * {@link grid}.
     */
    deselectAllRows() {
        this.onAllSelectedChange({ value: false });
    }

    $onDestroy() {
        this.popover.remove();
    }
}

GridTableHeaderCheckboxController.$inject = [
    '$scope',
    '$compile',
    'popoverFactory',
];

/**
 * @ngdoc component
 * @name gridTableHeaderCheckbox
 * @memberOf module:avi/component-kit/grid
 * @param {boolean} allRowsSelected - True is passed if all table rows are selected.
 * @param {boolean} noRowsSelected - True is passed if not a single table row is selected.
 * @param {Function} onAllSelectedChange - To be called with desired allRowsSelected value
 *     as 'value' property.
 * @param {boolean} isDisabled
 * @desc
 *
 *    Checkbox to toggle all rows selected state with ability to clear selection.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridTableHeaderCheckbox', {
    bindings: {
        allRowsSelected: '<',
        noRowsSelected: '<',
        onAllSelectedChange: '&',
        isDisabled: '<',
    },
    controller: GridTableHeaderCheckboxController,
    template,
});
