/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    Input,
} from '@angular/core';
import './avi-state-page.component.less';

const componentSelector = 'avi-state-page';

/**
 *
 * Generic container component to be used in pages with the capability to add a right side rail for
 * additional info.
 * Meant for leaf-page node only, parent handles header.
 *
 * @author Akul Aggarwal, Zhiqian Liu
 */
@Component({
    selector: componentSelector,
    templateUrl: './avi-state-page.component.html',
})
export class AviStatePageComponent {
    /**
     * Decide whether the page comes with a right side bar.
     */
    @Input()
    public hasSidebar?: boolean;

    /**
     * Toggle for deciding if sidebar is to show.
     */
    public isSideBarCollapsed = false;

    /**
     * Sets class for component template element.
     * @param elementRef - wrapper around a native element inside of a View
     */
    public constructor(public elementRef: ElementRef) {
        elementRef.nativeElement.classList.add(componentSelector);
    }
}
