/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'avi-forms';
const componentName = 'avi-search';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
};
