/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'upgrade';
const componentName = 'upgrade-transcript-modal';
const prefix = `${moduleName}.${componentName}`;

export const upgradeLabel = `${prefix}.upgradeLabel`;
export const patchLabel = `${prefix}.patchLabel`;
export const rollbackLabel = `${prefix}.rollbackLabel`;
export const rollbackPatchLabel = `${prefix}.rollbackPatchLabel`;
export const headerLabel = `${prefix}.headerLabel`;
export const controllerLabel = `${prefix}.controllerLabel`;
export const successfulOperationMessage = `${prefix}.successfulOperationMessage`;
export const failedOperationMessage = `${prefix}.failedOperationMessage`;
export const nodeNameLabel = `${prefix}.nodeNameLabel`;
export const seGroupLabel = `${prefix}.seGroupLabel`;
export const transcriptLabel = `${prefix}.transcriptLabel`;
export const upgradeProgressBarDisplayValueLabel =
    `${prefix}.upgradeProgressBarDisplayValueLabel`;
export const upgradeProgressBarLabel = `${prefix}.upgradeProgressBarLabel`;
export const completedOnLabel = `${prefix}.completedOnLabel`;

export const ENGLISH = {
    [upgradeLabel]: 'Upgrade',
    [patchLabel]: 'Patch',
    [rollbackLabel]: 'Upgrade (Rollback)',
    [rollbackPatchLabel]: 'Patch (Rollback)',
    [headerLabel]: '{0} Transcript',
    [controllerLabel]: 'Controller',
    [upgradeProgressBarLabel]: '{0} Updating to {1}',
    [successfulOperationMessage]: 'completed successfully on {0}',
    [failedOperationMessage]: 'failed on {0}',
    [nodeNameLabel]: 'Source',
    [seGroupLabel]: 'Service Engine Group',
    [transcriptLabel]: 'Transcript',
    [upgradeProgressBarDisplayValueLabel]: 'Completed',
    [completedOnLabel]: 'Completed On',
};
