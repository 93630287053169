/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-access-client-management-access-card';
const prefix = `${moduleName}.${componentName}`;

export const clientManagementAccessHeader = `${prefix}.clientManagementAccessHeader`;
export const allowSshClientsLabel = `${prefix}.allowSshClientsLabel`;
export const cliShellClientsLabel = `${prefix}.cliShellClientsLabel`;
export const allowExternalHttpsClientsLabel = `${prefix}.allowExternalHttpsClientsLabel`;
export const allowExternalSnmpClientsLabel = `${prefix}.allowExternalSnmpClientsLabel`;

export const ENGLISH = {
    [clientManagementAccessHeader]: 'Client Management Access',
    [allowSshClientsLabel]: 'Allow SSH Clients',
    [cliShellClientsLabel]: 'CLI Shell Clients',
    [allowExternalHttpsClientsLabel]: 'Allow External HTTPS Clients',
    [allowExternalSnmpClientsLabel]: 'Allow External SNMP Clients',
};
