/**
 * Module containing loaders, spinners, or progress related components.
 * @module LoaderModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClrProgressBarModule } from '@clr/angular';

import {
    AviProgressBarComponent,
} from './components';

const loaderComponents = [
    AviProgressBarComponent,
];

@NgModule({
    declarations: [
        ...loaderComponents,
    ],
    imports: [
        CommonModule,
        ClrProgressBarModule,
    ],
    exports: [
        AviProgressBarComponent,
    ],
})
export class LoaderModule {}
