/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const controllerSiteFactory = Item => {
    class ControllerSite extends Item {
        /**
         * Return data#config#address of this controller site.
         * @return {string}
         */
        getAddress() {
            return this.getConfig().address;
        }
    }

    Object.assign(ControllerSite.prototype, {
        objectName: 'controllersite',
    });

    return ControllerSite;
};

controllerSiteFactory.$inject = [
    'Item',
];

/**
 * @ngdoc factory
 * @name  ControllerSite
 * @description  ControllerSite item.
 * @author Zhiqian Liu
 */
angular.module('aviApp').factory('ControllerSite', controllerSiteFactory);
