/**
 * @module VerticalNavModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Inject,
    Pipe,
    PipeTransform,
} from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';

/**
 * @description Pipe to check if a navigation state should be hidden.
 * @author alextsg
 */
@Pipe({
    name: 'isHiddenState',
})
export class IsHiddenStatePipe implements PipeTransform {
    constructor(
        @Inject('systemInfoService')
        private readonly systemInfoService: any,
    ) {}

    /**
     * Returns true for states that should be hidden unless the controller has been configured
     * in a specific way. Ex. the GSLB tab should not be shown unless the user has GSLB configured.
     */
    public transform(state: IAppState): boolean {
        const { fullName: stateName } = state;

        switch (stateName) {
            case 'authenticated.profile.profiles.gslb-healthmonitor-list':
            case 'authenticated.application.gslbservice-list':
                return !this.systemInfoService.haveGSLBConfig();

            case 'authenticated.infrastructure.gslb.upload-geo-files':
            case 'authenticated.infrastructure.gslb.geo-profile':
            case 'authenticated.infrastructure.gslb.federation-checkpoints':
                return !this.systemInfoService.localSiteIsGSLBLeader;

            default:
                return false;
        }
    }
}
