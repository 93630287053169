/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'geo-db';

export const addGeoDbFileModalBreadcrumbTitle = `${prefix}.addGeoDbFileModalBreadcrumbTitle`;
export const addGeoDbMappingModalBreadcrumbTitle = `${prefix}.addGeoDbMappingModalBreadcrumbTitle`;
export const addGeoDbMappingElementModalBreadcrumbTitle = `${prefix}.addGeoDbMappingElementModalBreadcrumbTitle`;

export const ENGLISH = {
    [addGeoDbFileModalBreadcrumbTitle]: 'GeoDB File',
    [addGeoDbMappingModalBreadcrumbTitle]: 'GeoDB Mapping',
    [addGeoDbMappingElementModalBreadcrumbTitle]: 'GeoDB Mapping Element',
};
