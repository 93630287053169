/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/log-paginate.less';

angular.module('avi/logs').directive('logPagination', [
'$timeout', 'logApiMaxEntriesLimit',
function($timeout, logApiMaxEntriesLimit) {
    function logPaginationLink(scope) {
        scope.countPagesQ = function() {
            if (scope.itemsQ && scope.itemsOnPage) {
                scope.pagesQ = Math.ceil(scope.itemsQ / scope.itemsOnPage);

                if (scope.pagesQ * scope.itemsOnPage > logApiMaxEntriesLimit) {
                    //last page not available when API limit will be hit
                    scope.pagesQ--;
                }
            } else {
                scope.pagesQ = 1;
            }

            scope.currPage = Math.min(scope.pagesQ, scope.currPage);
        };

        scope.$watch('itemsQ', scope.countPagesQ);

        scope.internalQuery = function() {
            //since there is a angular bug with expression inside max attribute of input field type
            // number
            scope.currPage = Math.min(scope.pagesQ, scope.currPage);

            //need timeout to have appropriate page & page_size value at parent scope
            $timeout(() => scope.query());
        };
    }

    return {
        restrict: 'E',
        scope: {
            nextPage: '&', //function to be called onClick
            prevPage: '&', // same
            query: '&', //function to be called onEnter
            itemsQ: '=', //number of log entries, read only
            currPage: '=',
            itemsOnPage: '=',
        },
        link: logPaginationLink,
        templateUrl: 'src/views/components/log-pagination.html',
    };
}]);
