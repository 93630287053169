/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'scripts';
const componentName = 'data-script-config';
const prefix = `${moduleName}.${componentName}`;

export const fileUploadInputPlaceholder = `${prefix}.fileUploadInputPlaceholder`;
export const fileUploadInputLabel = `${prefix}.fileUploadInputLabel`;

export const ENGLISH = {
    [fileUploadInputPlaceholder]: 'Enter your DataScript Here',
    [fileUploadInputLabel]: '{0} Event Script',
};
