/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType,
} from '@ngrx/effects';
import { Observable } from 'rxjs';
import {
    map,
    mergeMap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IControllerInitialDataApiResponse } from
    'ng/root-store/controller-initial-data-store/controller-initial-data.state';
import * as ControllerInitialDataActions from './controller-initial-data.actions';

@Injectable()
export class ControllerInitialDataEffects {
    public loadInitialData$ = createEffect(() => this.actions$.pipe(
        ofType(ControllerInitialDataActions.loadControllerInitialData),
        mergeMap(() => this.getInitialData()
            .pipe(
                map(
                    (controllerInitialData: IControllerInitialDataApiResponse) =>
                        ControllerInitialDataActions
                            .setControllerInitialData({ controllerInitialData }),
                ),
            )),
    ));

    private readonly initialDataUrl =
    '/api/initial-data?include_name&treat_expired_session_as_unauthenticated=true';

    constructor(
        private readonly http: HttpClient, // todo switch to AlbHttpClient when works
        private readonly actions$: Actions,
    ) {}

    /**
     * Calls initial-data api using HTTP get.
     */
    private getInitialData(): Observable<IControllerInitialDataApiResponse> {
        return this.http.get<IControllerInitialDataApiResponse>(this.initialDataUrl);
    }
}
