/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AviAlertService,
    DialogService,
    DIALOG_SERVICE_TOKEN,
    FullModalService,
    NotificationService,
} from 'ng/modules/core';

import { AppLevelAlertsService } from 'ng/modules/core/services/app-level-alerts.service';

import {
    CentralLicenseAlertsService,
} from 'ng/modules/core/services/central-license-alerts/central-license-alerts.service';

import {
    APP_LEVEL_ALERTS_SERVICE_TOKEN,
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
} from '../../../downgrade-services.tokens';

export const USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN = 'userSessionEventListenersService';

/**
 * @desc Service that sets Angular service listeners on the AJS $rootScope to perform a clean-up on
 *     user logout or setContext changes.
 * @author alextsg
 */
export class UserSessionEventListenersService {
    constructor(
        private readonly $rootScope: ng.IRootScopeService,
        private readonly fullModalService: FullModalService,
        private readonly dialogService: DialogService,
        private readonly notificationService: NotificationService,
        private readonly aviAlertService: AviAlertService,
        private readonly appLevelAlertsService: AppLevelAlertsService,
        private readonly centralLicenseAlertsService: CentralLicenseAlertsService,
    ) {}

    /**
     * Sets listeners on $rootScope to remove FullModal modals.
     */
    public setListeners(): void {
        this.$rootScope.$on('userLoggedOut', this.removeData);
        this.$rootScope.$on('setContext', this.reset);
    }

    /**
     * Resets Angular services.
     */
    private reset = (): void => {
        this.fullModalService.removeAllModals();
        this.dialogService.removeAll();
        this.notificationService.removeAll();
        this.aviAlertService.removeAll();
        this.appLevelAlertsService.removeAll();
    };

    /**
     * Called on user logged out. Clears localstorage data and resets angular services.
     */
    private removeData = (): void => {
        this.centralLicenseAlertsService.clearAlertsFromLocalStorage();
        this.reset();
    };
}

UserSessionEventListenersService.$inject = [
    '$rootScope',
    'fullModalService',
    DIALOG_SERVICE_TOKEN,
    'notificationService',
    'aviAlertService',
    APP_LEVEL_ALERTS_SERVICE_TOKEN,
    CENTRAL_LICENSE_ALERT_SERVICE_TOKEN,
];
