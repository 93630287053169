/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
    VRFContext,
    VRFContextCollection,
} from 'ajs/modules/vrf-context';
import { CloudCollection } from 'ajs/modules/cloud';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services/string-service';
import { ICloudVrfContext } from 'ajs/modules/pool';
import * as globalL10n from 'global-l10n';
import * as l10n from './cloud-vrf-context-dialog.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVRFContextCollection = typeof VRFContextCollection;
type TCloudCollection = typeof CloudCollection;

/**
 * @description Component for Cloud and Vrf Context dialog.
 * @author Rachit Aggarwal, Harmeet Kaur
 */
@Component({
    selector: 'cloud-vrf-context-dialog',
    templateUrl: './cloud-vrf-context-dialog.component.html',
})
export class CloudVrfContextDialogComponent implements OnDestroy {
    /**
     * Cloud and Vrf Context config.
     */
    @Input()
    public config: ICloudVrfContext;

    /**
     * Flag to remove VRF selection.
     */
    @Input()
    public disallowVrfContextSelection = false;

    /**
     * Field name for Cloud Ref.
     */
    @Input()
    public cloudRefFieldName: string;

    /**
     * Field name for Vrf Ref.
     */
    @Input()
    public vrfRefFieldName: string;

    /**
     * Message Type.
     */
    @Input()
    public messageType: string;

    /**
     * Observable called on submit.
     */
    @Input()
    public submit$: Observable<void>;

    /**
     * EventEmitter passed in by the parent. Called when cancelling this dialog.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * EventEmitter automatically passed in by the CredentialsVerification component. Used to close
     * this dialog.
     */
    @Output()
    public closeDialog = new EventEmitter();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * VRFContextCollection instance.
     */
    public vrfContextCollection: VRFContextCollection;

    /**
     * CloudCollection instance.
     */
    public cloudCollection: CloudCollection;

    /**
     * Errors to be displayed if an error occurs.
     */
    public errors: string | null;

    /**
     * Busy flag for rendering a spinner in the Connect button.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(VRFContextCollection)
        VrfContextCollection: TVRFContextCollection,
        @Inject('CloudCollection')
        CloudCollection: TCloudCollection,
        private readonly stringService: StringService,
    ) {
        this.vrfContextCollection = new VrfContextCollection({
            params: {
                fields: 'name,tenant_ref',
            },
        });
        this.cloudCollection = new CloudCollection({
            isStatic: true,
            params: {
                fields: 'name,tenant_ref',
            },
        });

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (!this.disallowVrfContextSelection) {
            this.setVrfContextCollectionParams();
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.vrfContextCollection.destroy();
        this.cloudCollection.destroy();
    }

    /**
     * Handles the cancel operation. Emits the onCancel EventEmitter that's passed in by the parent,
     * and the closeDialog EventEmitter that's passed in automatically by the
     * CredentialsVerification component to close this dialog.
     */
    public handleCancel(): void {
        this.onCancel.emit();
        this.closeDialog.emit();
    }

    /**
     * Called when the Set button is clicked. Subscribes to the submit$ observable, and closes
     * this dialog if successful. If not, shows the error message.
     */
    public handleSubmit(): void {
        this.busy = true;
        this.errors = null;

        const subscription = this.submit$
            .subscribe(
                () => this.closeDialog.emit(),
                errors => this.errors = errors,
            ).add(
                () => {
                    this.busy = false;
                    subscription.unsubscribe();
                },
            );
    }

    /**
     * Getter for credentials verification dialog header.
     */
    public get credentialsDialogHeader(): string {
        return this.disallowVrfContextSelection ?
            l10nKeys.setCloudHeader :
            l10nKeys.setCloudVrfContextHeader;
    }

    /**
     * Getter for credentials verification selection alert.
     */
    public get credentialsDialogAlert(): string {
        return this.disallowVrfContextSelection ?
            l10nKeys.cloudMessage :
            l10nKeys.cloudVrfContextMessage;
    }

    /**
     * Handler for Cloud dropdown select operation.
     */
    public async onCloudSelect(): Promise<void> {
        if (this.disallowVrfContextSelection) {
            return;
        }

        this.setVrfContextCollectionParams();
        this.config.vrf_ref = undefined;
        this.busy = true;
        await this.vrfContextCollection.load();

        if (this.vrfContextCollection.getNumberOfItems() === 1) {
            const vrfContextItems = this.vrfContextCollection.itemList as VRFContext[];

            this.config.vrf_ref = vrfContextItems[0].config.url;
        }

        this.busy = false;
    }

    /**
     * Sets params for VRF context collection dropdown.
     */
    private setVrfContextCollectionParams(): void {
        if (this.config.cloud_ref) {
            this.vrfContextCollection.setParams({
                'cloud_ref.uuid': this.stringService.slug(this.config.cloud_ref),
            });
        }
    }
}
