/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    forwardRef,
    Input,
    OnInit,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services';

import {
    isNull,
    isUndefined,
} from 'underscore';

import {
    aviRepeatedStringsRangeValidator,
    aviRepeatedStringsUniquenessValidator,
} from 'ng/modules/avi-forms/validators';

import {
    flattenArray,
    parseNumbersToRange,
    parseRangeToNumbers,
    parseStringsToNumbers,
} from './avi-repeated-numbers.utils';

import * as l10n from './avi-repeated-numbers.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Default maxLimit value.
const DEFAULT_MAX_REPEATED_NUMBERS_LIMIT = Infinity;

// Default minLimit value.
const DEFAULT_MIN_REPEATED_NUMBERS_LIMIT = 0;

/**
 * @description
 *  Wrapper Component to take array of numbers as input and convert it to array of string.
 *  Passes this array of string as input to Avi Repeated Strings component.
 *  On every update, checks if the input from user is a set of consecutive numbers.
 *  Converts the set of consecutive numbers to range for display purpose.
 *  Ex. 1,2,3,4,5 will be parsed to 1-5.
 *  Unsorted set will also be converted to Range. Ex. 5,2,4,3,1 will be parsed to 1-5.
 *
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'avi-repeated-numbers',
    templateUrl: './avi-repeated-numbers.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedNumbersComponent),
        },
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviRepeatedNumbersComponent),
        },
    ],
})
export class AviRepeatedNumbersComponent implements OnInit, ControlValueAccessor, Validator {
    /**
     * Configurable Placeholder.
     */
    @Input()
    public placeholder = '';

    /**
     * ObjectType of the field (Optional).
     */
    @Input()
    public objectType?: string;

    /**
     * Name of the field (Optional).
     */
    @Input()
    public fieldName?: string;

    /**
     * When true, wont invalidate if duplicate values are added.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Max number of values that can be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If maxLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Will be passed accordingly to Avi Reapeated Strings Component.
     *
     * Default is Infinity.
     */
    @Input()
    public maxLimit ?= DEFAULT_MAX_REPEATED_NUMBERS_LIMIT;

    /**
     * Min number of values that should be added (Optional).
     * It is set by objectType & fieldName.
     *
     * If minLimit is passed, it takes precedence over
     * schema bindings.
     *
     * Will be passed accordingly to Avi Reapeated Strings Component.
     *
     * Default is 0.
     */
    @Input()
    public minLimit ?= DEFAULT_MIN_REPEATED_NUMBERS_LIMIT;

    /**
     * The ngModel value set from the template invoking this component.
     */
    public modelValue: number[];

    /**
     *  Value to be passed as ngModel to AviRepeatedStrings Component.
     */
    public repeatedStringsModel: string[];

    constructor(
        private readonly schemaService: SchemaService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * If objectType & fieldName is passed by user,
     * try to set maxLimit.
     * @override
     */
    public ngOnInit(): void {
        const {
            objectType,
            fieldName,
            l10nService,
            maxLimit,
            minLimit,
            schemaService,
        } = this;

        // If maxLimit/minLimit is not set by user
        // try to get it from schema bindings.
        if (objectType && fieldName) {
            if (maxLimit === DEFAULT_MAX_REPEATED_NUMBERS_LIMIT) {
                try {
                    this.maxLimit = schemaService.getFieldMaxElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }

            if (minLimit === DEFAULT_MIN_REPEATED_NUMBERS_LIMIT) {
                try {
                    this.minLimit = schemaService.getFieldMinElements(objectType, fieldName);
                } catch (e) { /** empty catch block */ }
            }
        }

        if (!this.placeholder) {
            this.placeholder = l10nService.getMessage(l10nKeys.placeholderLabel);
        }
    }

    /**
     * Change Handler Method
     */
    public handleUpdate(): void {
        this.parseModelValues();
        this.onChange(this.modelValue);
        this.onTouched();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
    */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: number[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: number[]): void {
        this.modelValue = value;
        this.updateStringModel();
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * To invalidate the field incase of duplicate or out-of-range values.
     * @override
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        if (isUndefined(control.value) || isNull(control.value)) {
            return null;
        }

        const range: [number, number] = [this.minLimit, this.maxLimit];

        return aviRepeatedStringsRangeValidator(range)(control) ||
            !this.allowDuplicates && aviRepeatedStringsUniquenessValidator()(control) || null;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: number[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /*************************************************************************/

    /**
     * Method to convert an array of numbers to array of strings.
     * Also checks for ranges.
     */
    private updateStringModel(): void {
        if (!this.modelValue) {
            return;
        }

        this.repeatedStringsModel = [...this.parseInitialInputModel()];
    }

    /**
     * Invokes methods to parse for ranges and update own model accordingly.
     */
    private parseModelValues(): void {
        this.updateRepeatedStringsModelValue();
        this.updateModelValue();
    }

    /**
     * Checks for the user input,
     * Updates repeatedString Model passed to Avi Repeated Strings component.
     * Calls method to parse more than 2 consecutive numbers to a range.
     */
    private updateRepeatedStringsModelValue(): void {
        if (!this.repeatedStringsModel) {
            return;
        }

        const updatedStringModel = this.repeatedStringsModel.map(
            (modelValue: string) => {
                if (modelValue.includes(',') && modelValue.split(',').length > 2) {
                    return parseNumbersToRange(modelValue);
                } else {
                    return modelValue;
                }
            },
        );

        this.repeatedStringsModel = [...updatedStringModel];
    }

    /**
     * Updates the model value after invoking methods to parse,
     * Array of string(repeatedStringsModel) returned from Avi Repeated Strings component,
     * and converts to array of numbers.
     */
    private updateModelValue(): void {
        this.modelValue = [];

        if (!this.repeatedStringsModel) {
            // Adding this for empty validation in case user deletes all the input.
            this.modelValue = undefined;

            return;
        }

        this.repeatedStringsModel.forEach(
            (value: string) => {
                if (value.includes('-')) {
                    this.modelValue.push(...parseRangeToNumbers(value));
                } else if (value.includes(',')) {
                    this.modelValue.push(
                        ...parseStringsToNumbers(value),
                    );
                } else {
                    this.modelValue.push(+value);
                }
            },
        );
    }

    /**
     * Parses the input modelValue received from Component and checks for ranges.
     * Passes the same to Avi Repeated Strings Component.
     */
    private parseInitialInputModel(): string[] {
        const modelValue = [...this.modelValue];

        /**
         * Constant for minimum numbers that should be consecutive to be parsed as range.
         */
        const minimumRangeLimit = 3;

        let checkedModelValues = [];
        const updatedModel = [];

        for (let i = 0; i < modelValue.length; i++) {
            checkedModelValues.push(modelValue[i]);

            if (!(modelValue[i + 1] - modelValue[i] === 1)) {
                if (checkedModelValues.length >= minimumRangeLimit) {
                    updatedModel.push(checkedModelValues);
                } else {
                    updatedModel.push(...checkedModelValues);
                }

                checkedModelValues = [];
            }
        }

        return [...flattenArray(updatedModel)];
    }
}
