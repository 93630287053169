/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  loadItem
 * @returns {Function}
 * @description
 *     Returns a function that will load instance of Item with specified Id. Id might consist of
 *     few properties, in which case object is expected to be passed as Id and will be forwarded to
 *     ItemClass constructor.
 */
angular.module('avi/router').factory('loadItem', function() {
    /**
     * @param {string|object} args - Args or item id to be passed to the constructor.
     * @param {Object} ItemClass - Class of item to be created.
     * @return {ng.$q.promise} - To be resolved with loaded item.
     */
    return (args, ItemClass) => {
        const
            item = new ItemClass(angular.isString(args) ? { id: args } : args),
            promise = item.load(undefined, true);

        return promise.then(() => item);
    };
});
