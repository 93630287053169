/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import './avi-checkbox-container-group.component.less';

/**
 * @description Component to style clr-checkbox-container elements grouped together.
 * @author alextsg
 */
@Component({
    selector: 'avi-checkbox-container-group',
    templateUrl: './avi-checkbox-container-group.component.html',
})
export class AviCheckboxContainerGroupComponent {
    /**
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * If true, removes the margin-top from the top-level div
     */
    public noMarginTop = false;
}
