/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { Case } from 'ajs/modules/cportal';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-preview.l10n';
import './case-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Component to show the case status and the case created date in full modal preview.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'case-preview',
    templateUrl: './case-preview.component.html',
})
export class CasePreviewComponent {
    /**
     * Case Item currently opened in case modal.
     */
    @Input()
    private readonly editable: Case;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the case status.
     */
    public get status(): string {
        return this.editable.getStatus();
    }

    /**
     * Returns case created date.
     */
    public get caseCreatedDate(): string {
        const { caseCreatedDate } = this.editable;

        return caseCreatedDate;
    }

    /**
     * Returns true if case is closed.
     */
    public get isCaseClosed(): boolean {
        return this.editable.isClosed();
    }
}
