/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './item-alert-bell.less';

/**
 * @ngdoc component
 * @name itemAlertBell
 * @param {Item} item
 * @param {string=} withPopover - Pass false to switch off the popover functionality.
 * @description
 *
 *     Shows the bell icon colored by the highest Item's alert severity. Shows popover with
 *     details on mouseover event.
 */
const componentName = 'item-alert-bell';

let
    $compile,
    Timeframe = null,
    PopoverFactory = null;

class ItemAlertBellController {
    constructor($scope, _$compile_, $element, _Timeframe_, _PopoverFactory_) {
        $compile = _$compile_;
        Timeframe = _Timeframe_;
        PopoverFactory = _PopoverFactory_;

        this.$elem_ = $element;
        this.$scope_ = $scope;

        this.loadAlerts_ = _.throttle(this.loadAlerts_, 9999, { trailing: false });
    }

    /**
     * @type {string}
     * @public
     */
    get alertLevelClass() {
        const
            alertData = this.item.getAlertsData(),
            highestAlertLevel = alertData && alertData.highestAlertLevel || '';

        return highestAlertLevel ? `${componentName}__icon_level_${highestAlertLevel}` : '';
    }

    $onInit() {
        this.$elem_.addClass(componentName);
        this.alertsCollection = this.item.alertsPrioritized;

        if (this.withPopover !== 'false') {
            this.$elem_.on('mouseover', this.onHover_.bind(this));
        }
    }

    /**
     * Compiles the popover template and creates an instance to be shown.
     * @private
     */
    createPopoverInstance_() {
        const $popoverContent = $('<item-alert-popover/>')
            .attr({ item: '$ctrl.item' });

        const popoverConfig = {
            className: `with-${componentName}-popover`,
            margin: 10,
            width: 300,
            height: 100,
            removeAfterHide: true,
            repositioning: true,
            hide: {
                onEscape: true,
                parentMouseOut: true,
            },
            html: $compile($popoverContent)(this.$scope_),
        };

        this.popover_ = new PopoverFactory(popoverConfig);
    }

    /**
     * Event listener for mouse over event.
     * @private
     */
    onHover_() {
        if (!this.popover_) {
            this.createPopoverInstance_();
        }

        this.popover_.show(this.$elem_);
        this.loadAlerts_();
    }

    /**
     * Loads a list of events according to the selected Timeframe.
     * @private
     */
    loadAlerts_() {
        const { step, limit } = Timeframe.selected();

        this.alertsCollection.loadDuration(step, limit);
    }

    $onDestroy() {
        if (this.popover_) {
            this.popover_.remove();
        }
    }
}

ItemAlertBellController.$inject = [
    '$scope',
    '$compile',
    '$element',
    'Timeframe',
    'popoverFactory',
];

angular.module('aviApp').component('itemAlertBell', {
    controller: ItemAlertBellController,
    bindings: {
        item: '<',
        withPopover: '@',
    },
    template:
            `<i class="icon-bell ${componentName}__icon" ng-class="$ctrl.alertLevelClass"></i>`,
});
