/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { contains } from 'underscore';
import { Auth } from 'ajs/modules/core/services/auth';
import { appStates } from 'ajs/js/constants/app-config/app-state.constants';

const {
    LOGIN_STATE,
    PLEASE_RELOAD_STATE,
} = appStates;

/**
 * Map of URL with array of HTTP error response codes to ignore.
 */
const errorExceptionsMap: Map<string, number[]> = new Map([
    ['/api/mesos-verify-login', [401]],
]);

/**
 * 401 errorCode is allowed for following states.
 */
const respCode401allowedStates: Set<string> = new Set([
    LOGIN_STATE,
    PLEASE_RELOAD_STATE,
]);

/**
 * @description Interceptor that handles HTTP failures.
 *
 * @author Aravindh Nagrajan
 */
@Injectable()
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
    constructor(
        private readonly authService: Auth,
        private readonly $state: StateService,
    ) {}

    /** @override */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const { url } = req;

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                const { status: errorCode } = error;

                const skip = errorExceptionsMap.has(url) &&
                    contains(errorExceptionsMap.get(url), errorCode);

                if (!skip) {
                    this.handleErrorCode(errorCode);
                }

                return throwError(error);
            }),
        );
    }

    /**
     * Handles HTTP Exceptions.
     */
    private handleErrorCode(errorCode: number): void {
        const {
            $state,
            authService,
        } = this;

        switch (errorCode) {
            case 401: {
                const { name: stateName } = $state.$current;

                if (!respCode401allowedStates.has(stateName) &&
                    authService.isLoggedIn()) {
                    authService.logout(true, true);
                }

                break;
            }

            case 503:
            case 520: {
                const targetState = String(errorCode);

                const logoutPromise =
                    authService.isLoggedIn() ? authService.logout() : Promise.resolve();

                logoutPromise.finally(() => {
                    $state.go(targetState);
                });

                break;
            }
        }
    }
}
