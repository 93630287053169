/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  LegacyPolicyRuleConfigItem
 * @description
 *     LegacyPolicyRuleConfigItem class, class of Policy/Profile rule.
 *     Will be deprecated soon after policy refactoring is done.
 *     For the new class please refer to PolicyRuleExtendableConfigItem.
 */
const legacyPolicyRuleConfigItemFactory = (ConfigItem, stringService) => {
    return class LegacyPolicyRuleConfigItem extends ConfigItem {
        constructor(args = {}) {
            super(args);

            const argsConfig = args.data && args.data.config;

            /**
             * The default Rule contains the default values of actions due to the way the
             * protobuf is set up. We don't want the rule to have actions by default, so we
             * delete them here.
             */
            const defaultRule = angular.copy(this._defaultConfig);

            if (defaultRule) {
                delete defaultRule.match;
                delete defaultRule.action;
            }

            this.data.config = angular.extend({}, defaultRule, argsConfig);

            /**
             * Hash mapping the match/action property to the ConfigItem class.
             */
            this.dataSettingHashes_ = {};

            if (this.matchSettingsHash && !_.isEmpty(this.matchSettingsHash)) {
                this.dataSettingHashes_['match'] = this.matchSettingsHash;
            }

            if (this.actionSettingsHash && !_.isEmpty(this.actionSettingsHash)) {
                this.dataSettingHashes_['action'] = this.actionSettingsHash;
            }

            this.transformAfterLoad_();
        }

        /**
         * Getter function for the 'index' property. Used in the policyGridPromptIndex component
         * as that component does not expect 'index' to be nested within data#config as part of
         * a ConfigItem class.
         * TODO: remove once other policies are refactored into ConfigItem instances.
         * @readonly
         * @return {number}
         */
        get index() {
            return this.getIndex();
        }

        /**
         * Getter function for the 'name' property. Used in the policyGridPromptIndex component
         * as that component does not expect 'name' to be nested within data#config as part of a
         * ConfigItem class.
         * TODO: remove once other policies are refactored into ConfigItem instances.
         * @readonly
         * @return {string}
         */
        get name() {
            return this.getName();
        }

        /**
         * Returns the name of the Rule.
         * @return {string}
         */
        getName() {
            return this.getConfig().name;
        }

        /**
         * Sets the name property on the rule.
         * @param {string} name - String to set as the name.
         */
        setName(name) {
            const config = this.getConfig();

            config.name = name;
        }

        /**
         * Returns the index of the rule. This is the index property set on a rule, not the
         * index of the rule in the list.
         * @return {number}
         */
        getIndex() {
            return this.getConfig().index;
        }

        /**
         * Sets the index property on the rule.
         * @param {number} index - Number to set as the index.
         */
        setIndex(index) {
            const config = this.getConfig();

            config.index = index;
        }

        /**
         * Sets the enabled property on the rule.
         * @param {boolean=} enabled
         */
        setEnable(enabled = true) {
            const config = this.getConfig();

            config.enabled = enabled;
        }

        /**
         * Returns true if the match property exists in data#config.
         * @param {string} matchPropertyName - Match property.
         * @return {boolean}
         */
        hasMatch(matchPropertyName) {
            return this.hasDataInDataObject_('match', matchPropertyName);
        }

        /**
         * Returns true if the rule contains any matches.
        * @return {boolean}
         */
        hasMatches() {
            return this.hasData_('match');
        }

        /**
         * Returns the list of matches under the given property name.
         * If no argument is passed, a list containing all matches in a rule is returned.
         * @param {string=} matchPropertyName
         * @return {Array<MatchConfigItems>}
         */
        getMatches(matchPropertyName) {
            return this.getData_('match', matchPropertyName);
        }

        /**
         * Adds a MatchConfigItem instance to a match list in data#config#match.
         * @param {string} matchPropertyName - Match list property for the match to be added.
         */
        addMatch(matchPropertyName) {
            this.addDataToDataList_('match', matchPropertyName);
        }

        /**
         * Removes a match of index from a match list.
         * @param {string} matchPropertyName - Match list property to operate on.
         * @param {number=} index - Index of match to be removed, the whole list will be
         *      removed if no index is passed.
         */
        removeMatch(matchListPropertyName, index) {
            this.removeDataFromDataList_('match', matchListPropertyName, index);
        }

        /**
         * Returns true if the action property exists in data#config#action.
         * @param {string} actionPropertyName - Action property.
         * @return {boolean}
         */
        hasAction(actionPropertyName) {
            return this.hasDataInDataObject_('action', actionPropertyName);
        }

        /**
         * Returns true if the rule contains any actions.
         * @return {boolean}
         */
        hasActions() {
            return this.hasData_('action');
        }

        /**
         * Returns the list of actions under the given property name.
         * Returns a list containing all actions in a rule if no argument is passed.
         * @param {string|undefined} actionPropertyName
         * @return {Array<ActionConfigItems>}
         */
        getActions(actionPropertyName) {
            return this.getData_('action', actionPropertyName);
        }

        /**
         * Adds a ActionConfigItem instance to a action list in data#config#action.
         * @param {string} actionPropertyName - Action list property for the action to be added.
         */
        addAction(actionPropertyName) {
            this.addDataToDataList_('action', actionPropertyName);
        }

        /**
         * Removes a actionwe of index from a action list.
         * @param {string} actionListPropertyName - Action list property to operate on.
         * @param {number=} index - Index of action to be removed, the whole list will be
         *      removed if no index is passed.
         */
        removeAction(actionListPropertyName, index) {
            this.removeDataFromDataList_('action', actionListPropertyName, index);
        }

        /**
         * Returns true if the rule contains a particular type of data.
         * @param {string} key - Key of the wrapper object.
         * @return {boolean}
         * @protected
         */
        hasData_(key) {
            const { [key]: dataObject } = this.getConfig();

            return angular.isObject(dataObject) && !_.isEmpty(dataObject);
        }

        /**
         * Returns true if a data / data list of a particular property name exists in its
         * wrapper data object.
         * @param {string} dataObjectKey - Key of the wrapper object.
         * @param {string} dataListName - Name of the data list property.
         * @return {boolean}
         * @protected
         */
        hasDataInDataObject_(dataObjectKey, dataListName) {
            const { [dataObjectKey]: dataObject } = this.getConfig();

            return angular.isObject(dataObject) && dataListName in dataObject;
        }

        /**
         * Returns the list of data under the given property name in its wrapper object.
         * If no argument is passed, a list containing all data in the wrapper object.
         * @param {string} dataObjectKey
         * @param {string=} dataListName
         * @return {Array<ConfigItems>}
         * @protected
         */
        getData_(dataObjectKey, dataListName) {
            const { [dataObjectKey]: dataObject } = this.getConfig();
            const resultList = [];

            if (this.hasData_(dataObjectKey)) {
                if (dataListName) {
                    if (this.hasDataInDataObject_(dataObjectKey, dataListName)) {
                        resultList.push(...dataObject[dataListName]);
                    }
                } else {
                    _.each(dataObject, dataList => {
                        resultList.push(...dataList);
                    });
                }
            }

            return resultList;
        }

        /**
         * Adds a ConfigItem instance to a data list.
         * @param {string} dataObjectKey - Name of wrapper object of data list.
         * @param {string} dataListName - Name of data list property for the data to be added.
         * @protected
         */
        addDataToDataList_(dataObjectName, dataListName) {
            const { [dataObjectName]: dataSettingsHash } = this.dataSettingHashes_;
            const { [dataListName]: dataSettings } = dataSettingsHash;

            if (!dataSettings) {
                return;
            }

            const DataConfigItemClass = dataSettings.className;
            const { [dataObjectName]: dataObj } = this.getConfig();
            const dataConfig = new DataConfigItemClass();

            dataConfig.beforeEdit();

            if (!(dataListName in dataObj)) {
                dataObj[dataListName] = [];
            }

            dataObj[dataListName].push(dataConfig);
        }

        /**
         * Removes data of index from a data list.
         * @param {string} dataObjectKey - Name of data object that contains the data list.
         * @param {string} dataListName - Name of data list property to operate on.
         * @param {number=} index - Index of data to be removed, the whole list will be
         *      removed if no index is passed.
         * @protected
         */
        removeDataFromDataList_(dataObjectKey, dataListName, index) {
            if (this.hasDataInDataObject_(dataObjectKey, dataListName)) {
                const config = this.getConfig();
                const { [dataListName]: dataList } = config[dataObjectKey];

                if (angular.isUndefined(index)) {
                    delete config[dataObjectKey][dataListName];
                } else {
                    dataList[index].destroy();
                    dataList.splice(index, 1);

                    if (dataList.length === 0) {
                        delete config[dataObjectKey][dataListName];
                    }
                }
            }
        }

        /**
         * Returns true if a specified searchTerm is found in the rule's name, match or action
         * configuration.
         * @param {string} searchTerm - Search string to match against.
         * @return {boolean}
         */
        searchBy(searchTerm) {
            const
                config = this.getConfig(),
                matches = this.getMatches(),
                actions = this.getActions();

            let foundTerm = false;

            if (stringService.contains(config.name, searchTerm)) {
                foundTerm = true;
            }

            for (let i = 0; i < matches.length && !foundTerm; i++) {
                if (matches[i].hasTerm(searchTerm)) {
                    foundTerm = true;
                }
            }

            for (let i = 0; i < actions.length && !foundTerm; i++) {
                if (actions[i].hasTerm(searchTerm)) {
                    foundTerm = true;
                }
            }

            return foundTerm;
        }

        /**
         * Saves data in config object.
         * @param {Object} config - Config object from which the data are lying.
         * @param {string} key - Property name of data to be saved.
         * @return {Object}
         * @protected
         */
        saveConfigItemData_(config, key) {
            if (!this.hasData_(key)) {
                delete config[key];
            }

            const dataSettingsHash = this.dataSettingHashes_[key];

            _.each(config[key], (dataList, name, hash) => {
                const { [name]: dataSettings } = dataSettingsHash;
                let data;

                if (dataSettings.isRepeated) {
                    data = [];
                    _.each(dataList, dataConfig => {
                        data.push(dataConfig.dataToSave());
                    });
                } else {
                    const dataConfig = dataList[0];

                    data = dataConfig.dataToSave();
                }

                if (dataSettings.isNested) {
                    hash[name] = data;
                } else {
                    delete config[key];
                    config[key] = data;
                }
            });

            return config;
        }

        /** @override */
        dataToSave() {
            let config = super.dataToSave();

            config = this.saveConfigItemData_(config, 'match');
            config = this.saveConfigItemData_(config, 'action');

            return config;
        }

        /**
         * Performed when data loading is completed and this instance is ready.
         * @protected
         */
        transformAfterLoad_() {
            this.initConfigItems_('match');
            this.initConfigItems_('action');
        }

        /**
         * Part of intialization, convert plain data returned by backend to
         * corresponding UI ConfigItem list.
         * @param {string} key - Wrapper property name, wrapping data to be converted.
         * @protected
         */
        initConfigItems_(key) {
            const dataSettingsHash = this.dataSettingHashes_[key];
            const rawConfig = angular.copy(this.getConfig());
            const config = this.getConfig();

            config[key] = {};

            // handle plain data (un-nested data)
            _.each(dataSettingsHash, (dataSettings, name) => {
                if (name in rawConfig && !dataSettings.isNested) {
                    const { [name]: rawData } = rawConfig;

                    config[key][name] = this.createDataList_(rawData, dataSettings);
                }
            });

            // handle nested data
            _.each(rawConfig[key], (rawData, name) => {
                const { [name]: dataSettings } = dataSettingsHash;

                if (dataSettings) {
                    config[key][name] = this.createDataList_(rawData, dataSettings);
                }
            });
        }

        /**
         * Creates data list from raw data returned from backend.
         * @param {Object} rawData
         * @param {Object} dataSettingsHash - Object containing data settings:
         *      className, isRepeated, isNested.
         * @return {ConfigItem[]}
         * @protected
         */
        createDataList_(rawData, dataSettings) {
            const DataConfigItemClass = dataSettings.className;
            const dataList = [];
            let dataConfigItem;

            if (Array.isArray(rawData) && dataSettings.isRepeated) {
                _.each(rawData, dataConfig => {
                    dataConfigItem = new DataConfigItemClass({
                        data: {
                            config: dataConfig,
                        },
                    });
                    dataList.push(dataConfigItem);
                });
            } else {
                dataConfigItem = new DataConfigItemClass({
                    data: {
                        config: rawData,
                    },
                });
                dataList.push(dataConfigItem);
            }

            return dataList;
        }
    };
};

legacyPolicyRuleConfigItemFactory.$inject = [
    'ConfigItem',
    'stringService',
];

angular.module('aviApp').factory('LegacyPolicyRuleConfigItem', legacyPolicyRuleConfigItemFactory);
