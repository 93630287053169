/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  virtualserviceScaleProgress
 * @description
 *     Displays the progress of scaling or migrating for the Virtual Service in a popup modal. When
 *     the process is done, the modal will fade and disappear. Throws an error if a failure during
 *     scaling/migrating occurs.
 * @param {string} direction - scalein, scaleout, or migrate
 * @param {string} slug - Virtual Service uuid.
 * @param {string} vipId - ID of the VIP address.
 * @param {Function} onComplete - Called after process is complete.
 * @param {Function} dismiss - Called when the close button on the modal is clicked.
 */
const
    pendingStatusRequestFrequencyInSec = 10,
    maxNumberOfTries = 100;

class VirtualserviceScaleProgressController {
    constructor(Base, AsyncFactory, aviAlertService, $q) {
        this.AsyncFactory_ = AsyncFactory;
        this.aviAlertService_ = aviAlertService;
        this.base_ = new Base();
        this.$q_ = $q;
    }

    $onInit() {
        this.numberOfTries_ = 0;
        this.serviceEngineAddress = undefined;

        this.asyncScaleProgress_ = new this.AsyncFactory_(() => {
            this.numberOfTries_++;

            return this.getVMWareRuntime();
        });

        this.asyncScaleProgress_.start(pendingStatusRequestFrequencyInSec * 1000);
    }

    /**
     * Gets the scale progress and checks for completion. If scaling has failed, will throw an
     * error using AviAlertService.
     * @return {ng.$q.promise}
     */
    getVMWareRuntime() {
        return this.base_.request('GET', `/api/virtualservice/${this.slug}/runtime`)
            .then(({ data }) => {
                this.vipSummary = _.findWhere(data.vip_summary, { vip_id: this.vipId });

                if (!this.serviceEngineAddress && this.vipSummary.scale_status) {
                    this.serviceEngineAddress = this.vipSummary.scale_status.scale_se;
                }

                if (angular.isUndefined(this.vipSummary[`${this.direction}_in_progress`])) {
                    this.asyncScaleProgress_.stop();

                    const msg =
                            `${this.getDirectionLabel()} ${this.serviceEngineAddress} failed!`;

                    this.checkLastScaleStatus_(this.vipSummary.last_scale_status, msg);
                    this.complete();
                } else if (this.numberOfTries_ >= maxNumberOfTries) {
                    const msg = `${this.getDirectionLabel()} ${this.serviceEngineAddress} ` +
                            'takes too long.';

                    return this.$q_.reject({ data: msg });
                }
            }).catch(({ data }) => {
                this.asyncScaleProgress_.stop();
                this.aviAlertService_.throw(data);
                this.complete();
            });
    }

    /**
     * Checks the last_scale_status object to determine if scaling succeeded or failed.
     * @param  {Object} lastScaleStatus - last_scale_status object.
     * @param  {string} failMessage - Message to be displayed on scale failure.
     */
    checkLastScaleStatus_(lastScaleStatus, failMessage) {
        if (lastScaleStatus && lastScaleStatus.state &&
                lastScaleStatus.state.indexOf('_SUCCESS') < 0) {
            if (angular.isArray(lastScaleStatus.reason)) {
                this.aviAlertService_.throw(lastScaleStatus.reason[0]);
            } else {
                this.aviAlertService_.throw(failMessage);
            }
        }
    }

    /**
     * Checks to make sure that this.onComplete is a function before calling it.
     */
    complete() {
        if (angular.isFunction(this.onComplete)) {
            this.onComplete();
        }
    }

    /**
     * Used by the template to get the label based on the direction of scaling.
     * @return {String}
     */
    getDirectionLabel() {
        const labelHash = {
            scaleout: 'Scale out to',
            scalein: 'Scale in from',
            migrate: 'Migrate to',
        };

        return labelHash[this.direction];
    }

    $onDestroy() {
        this.asyncScaleProgress_.stop();
        this.base_.destroy();
    }
}

VirtualserviceScaleProgressController.$inject = [
    'Base',
    'AsyncFactory',
    'aviAlertService',
    '$q',
];

angular.module('aviApp').component('virtualserviceScaleProgress', {
    bindings: {
        direction: '@',
        slug: '@',
        vipId: '@',
        onComplete: '&',
        closeModal: '&',
    },
    controller: VirtualserviceScaleProgressController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-scale/' +
            'virtualservice-scale-progress/virtualservice-scale-progress.html',
});
