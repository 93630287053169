/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name PoolServersHsScatterMetric
 * @description
 *
 *     ScatterPlot of health score and number of connections for Servers of the Pool.
 *
 *     Number of connections is being loaded by collection API, HS comes from inventory API
 *     at postprocessing.
 *
 *     Item is Pool only.
 *
 */
//TODO change series list on the fly based on server list returned by inventory
//TODO pass * as obj_id so that newly created servers also show up
angular.module('aviApp').factory('PoolServersHsScatterMetric', [
'$q', '$injector', 'CollMetric', 'Timeframe',
function($q, $injector, CollMetric, Timeframe) {
    let Server;

    /** @class */
    return class PoolServersHsScatterMetric extends CollMetric {
        constructor(args = {}) {
            args.stackedSeries = false;
            args.seriesConstructorName = 'poolServerHSScatterSeries';
            args.aggregation = 'METRICS_DIMENSION_AGG_AVG';
            args.seriesId = args.vsLayer === 7 ? 'l7_server.avg_complete_responses' :
                'l4_server.avg_new_established_conns';

            super(args);

            this.type = 'scatter';

            this.maxHS = 100;//needed for chart
            this.maxConns = 100;

            this.lastUpdate = undefined;//watchable value for chart, date in seconds

            this.servers = [];//keeps inventory results for servers we've got from metrics

            if (!Server) {
                Server = $injector.get('Server');
            }

            this.updateSeriesListBasedOnPoolConfig_();
        }

        /**
         * ObjId (server id) is the short series id.
         * @override
         */
        seriesIdToConfig_(objId) {
            return {
                objId,
                seriesId: this.seriesId_,
            };
        }

        /**
         * Updates series list based on Pool configuration.
         * @protected
         */
        updateSeriesListBasedOnPoolConfig_() {
            const { servers } = this.item.getConfig();

            if (servers) {
                const defaultPort = this.item.getDefaultServerPort();

                this.updateSeriesList(
                    servers.flattenConfig().map(server =>
                        Server.getServerUuid(server, defaultPort)),
                );
            } else {
                this.updateSeriesList([]);
            }
        }

        /**
         * Returns URL for Pool servers inventory call.
         * @returns {string}
         * @private
         */
        getInventoryUrl_() {
            const
                { id: itemId } = this.item,
                { step, limit } = Timeframe.selected();

            return `/api/pool-inventory/${itemId}/server?all_se=true&include=health_score,` +
                `runtime&step=${step}&limit=${limit}`;
        }

        /**
         * Gets HS info for Pool's servers.
         * @override
         */
        postProcessing() {
            this.cancelRequests('healthscore');

            // hs data is supplementary, don't need to make a call if no metric data is present
            const haveMetricData = _.some(this.series, series => series.hasData());

            if (!haveMetricData) {
                this.servers.length = 0;

                return;
            }

            this.request('get', this.getInventoryUrl_(), undefined, null, 'healthscore')
                .then(
                    this.processHealthScoreData_.bind(this),
                )
                .catch(
                    () => this.servers.length = 0,
                );
        }

        /**
         * Need to update servers list with received HS and operation status data.
         * @param {Object} data - Pool servers inventory API response.
         * @protected
         */
        processHealthScoreData_({ data }) {
            const
                serverIds = [],
                defaultPort = this.item.getDefaultServerPort(),
                { servers } = this;

            servers.length = 0;

            if (Array.isArray(data.results)) {
                data.results.forEach(server => {
                    servers.push(server);

                    const serverId = Server.getServerUuid(server.config, defaultPort);

                    serverIds.push(serverId);

                    let series;

                    if (series = this.getSeries(serverId)) {
                        series.storeRuntimeInfo({
                            healthScore: +server['health_score']['health_score'],
                            operStatus: server['runtime']['oper_status']['state'],
                        });
                    }
                });
            }

            //adds new series and drops ones which are gone
            this.updateSeriesList(serverIds);

            this.setMaxValues_();

            //TODO check with previous values we had and skip update when same
            this.lastUpdate = +moment.utc();
        }

        /**
         * Sets maxHS and maxConns values over the instance.
         * @private
         */
        setMaxValues_() {
            let
                maxHS = 0,
                maxConns = 0;

            this.series.forEach(series => {
                if (series.hasData()) {
                    const
                        value = series.getValue(),
                        hsValue = series.getHealthScoreValue();

                    maxConns = Math.max(maxConns, _.isNaN(+value) ? 0 : value);
                    maxHS = Math.max(maxHS, _.isNaN(+hsValue) ? 0 : hsValue);
                }
            });

            this.maxHS = maxHS;
            this.maxConns = maxConns;
        }

        /**
         * Returns the list of servers with healthscore and operational status.
         * @returns {Server#data[]}
         */
        getServersInventoryList() {
            return this.servers.concat();
        }
    };
}]);
