/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const tabSectionGeneralLabel = `${prefix}.tabSectionGeneralLabel`;
export const ignorePeerChainCheckboxLabel = `${prefix}.ignorePeerChainCheckboxLabel`;
export const enableCrlCheckCheckboxLabel = `${prefix}.enableCrlCheckCheckboxLabel`;
export const isFederatedCheckboxLabel = `${prefix}.isFederatedCheckboxLabel`;
export const leafValidationCheckboxLabel = `${prefix}.leafValidationCheckboxLabel`;
export const certificateAuthorityHeader = `${prefix}.certificateAuthorityHeader`;
export const certificateAuthoritiesLabel = `${prefix}.certificateAuthoritiesLabel`;
export const certificateRevocationListHeader = `${prefix}.certificateRevocationListHeader`;
export const certificateRevocationListLabel = `${prefix}.certificateRevocationListLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit PKI Profile',
    [modalTitleNew]: 'New PKI Profile',
    [nameInputLabel]: 'Name',
    [tabSectionGeneralLabel]: 'General',
    [ignorePeerChainCheckboxLabel]: 'Ignore Peer Chain',
    [enableCrlCheckCheckboxLabel]: 'Enable CRL Check',
    [isFederatedCheckboxLabel]: 'Is Federated',
    [leafValidationCheckboxLabel]: 'Leaf Certificate CRL validation only',
    [certificateAuthorityHeader]: 'Certificate Authority (CA)',
    [certificateAuthoritiesLabel]: 'Certificate Authorities ({0})',
    [certificateRevocationListHeader]: 'Certificate Revocation List (CRL)',
    [certificateRevocationListLabel]: 'Certificate Revocation List ({0})',
};
