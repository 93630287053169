/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    TWafPolicyCollection,
    WafPolicyCollection,
} from 'ajs/modules/waf/factories/waf-policy/waf-policy.collection.factory';

import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Waf Policy list page.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'waf-policy-list-page',
    templateUrl: './waf-policy-list-page.component.html',
})
export class WafPolicyListPageComponent implements OnInit, OnDestroy {
    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Waf Policy Collection Object.
     */
    public readonly wafPolicyCollection: WafPolicyCollection;

    /**
     * Waf Policy grid config object.
     */
    public wafPolicyGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(WafPolicyCollection)
        WafPolicyCollection: TWafPolicyCollection,
    ) {
        this.wafPolicyCollection = new WafPolicyCollection();
    }

    /**
     * @override
     * Setting wafPolicyGridConfig for Waf Policy List.
     */
    public ngOnInit(): void {
        const { objectName } = this.wafPolicyCollection;

        this.wafPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.wafPolicyCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: IAviCollectionDataGridRow) => row.getName(),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow) => row.id,
        };
    }

    /**
     * @override
     * Destroying wafPolicy Collection.
     */
    public ngOnDestroy(): void {
        this.wafPolicyCollection.destroy();
    }
}
