/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IHttpPromise } from 'angular';

import {
    GCPNetworkConfigMode,
    IGCPConfiguration,
    IGCPEncryptionKeys,
} from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    IHttpWrapperRequestConfig,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { GCPConfiguration } from 'object-types';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { STRING_SERVICE_TOKEN } from 'ajs/modules/core/core.tokens';
import { isEmpty } from 'underscore';
import { GCPNetworkConfigConfigItem } from './gcp-network-config.config-item.factory';
import { GCPVipAllocationConfigItem } from './gcp-vip-allocation.config-item.factory';

const REGIONS_API = '/api/gcp-get-regions';
const NETWORKS_API = '/api/gcp-get-networks';
const AVAILABILITY_ZONES_API = '/api/gcp-get-zones';
const SUBNETS_API = '/api/gcp-get-subnets';

const NETWORKS_API_REQUEST_ID = 'NETWORKS_API';

type TGcpConfigurationPartial = Omit<
IGCPConfiguration, 'encryption_keys' | 'network_config' | 'vip_allocation_strategy'
>;

interface IGcpConfig extends TGcpConfigurationPartial {
    encryption_keys: MessageItem<IGCPEncryptionKeys>;
    network_config: GCPNetworkConfigConfigItem;
    vip_allocation_strategy: GCPVipAllocationConfigItem;
}

/**
 * Type of payload data sent as request params to backend.
 */
interface IPayload {
    cloud_credentials_uuid?: string;
    nic_id?: number;
    project: string;
    region?: string;
    network?: string;
}

/**
 * Type of response returned by getProjectIdAndNetworkName method.
 */
interface IProjectIdAndNetwork {
    projectId: string;
    networkName: string;
}

/**
 * Type of response returned by getInbandManagementProjectIdAndNetworkName method.
 */
interface IInbandProjectIdAndNetwork {
    inbandProjectId: string;
    inbandNetworkName: string;
}

/**
 * Type of response returned by getOneArmProjectIdAndNetworkName method.
 */
interface IOneArmProjectIdAndNetwork {
    oneArmProjectId: string;
    oneArmNetworkName: string;
}

/**
 * Type of response returned by getFrontendProjectIdAndNetworkName method.
 */
interface IFrontendProjectIdAndNetwork {
    frontendProjectId: string;
    frontendNetworkName: string;
}

/**
 * Type of response returned by getBackendProjectIdAndNetworkName method.
 */
interface IBackendProjectIdAndNetwork {
    backendProjectId: string;
    backendNetworkName: string;
}

/**
 * Type of response returned by getOneArmManagementProjectIdAndNetworkName method.
 */
interface IOneArmManagementProjectIdAndNetwork {
    oneArmManagementProjectId: string;
    oneArmManagementNetworkName: string;
}

/**
 * Type of response returned by getTwoArmManagementProjectIdAndNetworkName method.
 */
interface ITwoArmManagementProjectIdAndNetwork {
    twoArmManagementProjectId: string;
    twoArmManagementNetworkName: string;
}

/**
 * Type of promise returned by fetchSeRegions and fetchNetworks method.
 */
export interface IGcpGenericResponse {
    name?: string;
    uri?: string;
}

/**
 * Hash of GCPNetworkConfigMode enum from 'generated-types'.
 * Also has other additional properties like 'FRONT_END', 'BACK_END',
 * 'ONE_ARM_MANAGEMENT' and 'TWO_ARM_MANAGEMENT'.
 */
export const GCPNetworkConfigModeHash = {
    INBAND_MANAGEMENT: GCPNetworkConfigMode.INBAND_MANAGEMENT,
    ONE_ARM_MODE: GCPNetworkConfigMode.ONE_ARM_MODE,
    TWO_ARM_MODE: GCPNetworkConfigMode.TWO_ARM_MODE,
    ONE_ARM_MANAGEMENT: 'ONE_ARM_MANAGEMENT',
    FRONT_END: 'FRONT_END',
    BACK_END: 'BACK_END',
    TWO_ARM_MANAGEMENT: 'TWO_ARM_MANAGEMENT',
};

/**
 * @description
 *
 *   GCP Configuration MessageItem.
 *
 * @author Sarthak kapoor
 */

/**
 * Type for Credentials Config passed to gcp-edit-credentials-dialog component.
 */
export type TGcpCredentials = Pick<IGCPConfiguration, 'cloud_credentials_ref' | 'se_project_id'>;

export class GCPConfigurationConfigItem extends MessageItem<IGcpConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        STRING_SERVICE_TOKEN,
    ];

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * StringService instance to work with string values.
     */
    private stringService: StringService;

    /**
     * Object for storing in progress API Call Ids.
     */
    private apiCallsInProgress: Record<string, boolean>;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: GCPConfiguration,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_(STRING_SERVICE_TOKEN);

        this.apiCallsInProgress = {};
    }
    /**
     * Sets the gcp login credentials on gcp config object.
     */
    public setGcpCredentials(gcpCredentials: TGcpCredentials): void {
        const { config } = this;

        const {
            cloud_credentials_ref: cloudCredentialsRef,
            se_project_id: seProjectId,
        } = gcpCredentials;

        config.se_project_id = seProjectId;

        if (cloudCredentialsRef) {
            config.cloud_credentials_ref = cloudCredentialsRef;
        } else {
            delete config.cloud_credentials_ref;
        }
    }

    /**
     * On Cloud Credentials Ref change,removes/resets the fields depndent on it.
     */
    public resetCredentialsBasedFields(): void {
        const { config } = this;

        delete config.region_name;
        delete config.zones;
    }

    /**
     * On Service Engine region change, removes/resets the avaiability zones.
     */
    public resetAvailabilityZones(): void {
        const { config } = this;

        delete config.zones;
    }

    /**
     * Invokes fetchSeRegions & fetchNetworks method.
     */
    public async fetchSeRegionsAndNetworks(
        gcpCredentials: TGcpCredentials,
    ): Promise<IGcpGenericResponse[][]> {
        this.busy = true;
        this.errors = null;

        const seRegionsAndNetworkList = await Promise.all([
            this.fetchSeRegions(gcpCredentials),
            this.fetchNetworks(gcpCredentials),
        ]).catch(error => {
            this.errors = error.data;

            return Promise.reject(error.data);
        }).finally(() => this.busy = false);

        const [seRegions, networks] = seRegionsAndNetworkList;

        return [seRegions.data, networks.data];
    }

    /**
     * Fetches list of availability zones for selected SE Project ID and Region.
     */
    public async fetchAvailabilityZones(): Promise<IGcpGenericResponse[]> {
        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(AVAILABILITY_ZONES_API);

            const response = await this.httpWrapper.request(requestConfig);

            return response.data || [];
        } catch (error) {
            this.errors = error.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches network list when user enters VPC Project IDs
     * for diferent Network Management arm modes.
     */
    public async fetchNetworksOnIdChange(
        type: GCPNetworkConfigMode | string,
    ): Promise<IGcpGenericResponse[]> {
        if (this.apiCallsInProgress[NETWORKS_API_REQUEST_ID]) {
            this.httpWrapper.cancelRequest(NETWORKS_API_REQUEST_ID);
        }

        this.busy = true;
        this.errors = null;

        try {
            this.apiCallsInProgress[NETWORKS_API_REQUEST_ID] = true;

            const networkListResponse = await this.fetchNetworks(undefined, type);

            return networkListResponse.data || [];
        } catch (error) {
            this.errors = error.data;
        } finally {
            delete this.apiCallsInProgress[NETWORKS_API_REQUEST_ID];

            this.busy = false;
        }
    }

    /**
     * Fetches list of Subnet Names.
     */
    public async fetchSubnetNames(
        type: GCPNetworkConfigMode | string,
    ): Promise<IGcpGenericResponse[]> {
        const credentials: TGcpCredentials = {};

        const { config } = this;

        credentials.cloud_credentials_ref = config.cloud_credentials_ref;

        const {
            projectId,
            networkName,
        } = this.getProjectIdAndNetworkName(type, true);

        credentials.se_project_id = projectId;

        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(SUBNETS_API, credentials);

            requestConfig.params.network = networkName;

            const response = await this.httpWrapper.request(requestConfig);

            return response.data || [];
        } catch (error) {
            this.errors = error.data;
        } finally {
            this.busy = false;
        }
    }

    /** @override */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.network_config) {
            this.safeSetNewChildByField('network_config');
        }

        if (!config.encryption_keys) {
            this.safeSetNewChildByField('encryption_keys');
        }

        /**
         * We generally get this as part of default config but can be skipped while
         * configuring from backend as the field is not mandatory.
         */
        if (!config.vip_allocation_strategy) {
            this.safeSetNewChildByField('vip_allocation_strategy');
        }
    }

    /**
     * @override
     * Using this hook to copy se_project_id to different project id keys in network config object
     * depending upon the mode selected.
     * Copying the se_project_id to gcs_project_id as all these fields are non-mandatory.
     * Removing encryption keys from the config in case user has not entered any values for it.
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        switch (config.network_config?.config?.config) {
            case GCPNetworkConfigMode.INBAND_MANAGEMENT: {
                this.modifyInbandNetworkConfigBeforeSave();

                break;
            }

            case GCPNetworkConfigMode.ONE_ARM_MODE: {
                this.modifyOneArmNetworkConfigBeforeSave();

                break;
            }

            case GCPNetworkConfigMode.TWO_ARM_MODE: {
                this.modifyTwoArmNetworkConfigBeforeSave();

                break;
            }
        }

        if (config.se_project_id && !config.gcs_project_id) {
            config.gcs_project_id = config.se_project_id;
        }

        if (isEmpty(config.encryption_keys?.config)) {
            delete config.encryption_keys;
        }
    }

    /**
     * Modifies inband config object before modal save.
     */
    private modifyInbandNetworkConfigBeforeSave(): void {
        const { config } = this;
        const { config: inbandConfig } = config.network_config.config.inband;

        if (inbandConfig.vpc_network_name && !inbandConfig.vpc_project_id) {
            inbandConfig.vpc_project_id = config.se_project_id;
        }
    }

    /**
     * Modifies oneArm config object before modal save.
     */
    private modifyOneArmNetworkConfigBeforeSave(): void {
        const { config } = this;
        const { config: oneArmConfig } = config.network_config.config.one_arm;

        if (oneArmConfig?.data_vpc_network_name && !oneArmConfig?.data_vpc_project_id) {
            oneArmConfig.data_vpc_project_id = config.se_project_id;
        }

        if (oneArmConfig?.management_vpc_network_name && !oneArmConfig?.management_vpc_project_id) {
            oneArmConfig.management_vpc_project_id = config.se_project_id;
        }
    }

    /**
     * Modifies twoArm config object before modal save.
     */
    private modifyTwoArmNetworkConfigBeforeSave(): void {
        const { config } = this;
        const { config: twoArmConfig } = config.network_config.config.two_arm;

        if (
            twoArmConfig?.frontend_data_vpc_network_name &&
            !twoArmConfig?.frontend_data_vpc_project_id
        ) {
            twoArmConfig.frontend_data_vpc_project_id = config.se_project_id;
        }

        if (
            twoArmConfig?.backend_data_vpc_network_name &&
            !twoArmConfig?.backend_data_vpc_project_id
        ) {
            twoArmConfig.backend_data_vpc_project_id = config.se_project_id;
        }

        if (
            twoArmConfig?.management_vpc_network_name &&
            !twoArmConfig?.management_vpc_project_id
        ) {
            twoArmConfig.management_vpc_project_id = config.se_project_id;
        }
    }

    /**
     * Fetches list of Service Engine Regions to be displayed as a dropdown.
     */
    private fetchSeRegions(
        gcpCredentials: TGcpCredentials,
    ): IHttpPromise<IGcpGenericResponse[]> {
        const requestConfig = this.getRequestConfig(REGIONS_API, gcpCredentials);

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Fetches list of networks to be displayed as a dropdown.
     */
    private fetchNetworks(
        gcpCredentials?: TGcpCredentials,
        type?: GCPNetworkConfigMode | string,
    ): IHttpPromise<IGcpGenericResponse[]> {
        let credentials: TGcpCredentials = {};

        if (!gcpCredentials) {
            const { config } = this;

            credentials.cloud_credentials_ref = config.cloud_credentials_ref;

            const { projectId } = this.getProjectIdAndNetworkName(type);

            credentials.se_project_id = projectId;
        } else {
            credentials = { ...gcpCredentials };
        }

        const requestConfig = this.getRequestConfig(NETWORKS_API, credentials);

        requestConfig.requestId = NETWORKS_API_REQUEST_ID;

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Returns the project ID to be used depending on the Network Mode type.
     */
    private getProjectIdAndNetworkName(
        type: GCPNetworkConfigMode | string,
        isNetworkSelected = false,
    ): IProjectIdAndNetwork {
        const { config } = this;
        let projectId: string;
        let networkName: string;

        switch (type) {
            case GCPNetworkConfigModeHash.INBAND_MANAGEMENT: {
                const {
                    inbandNetworkName,
                    inbandProjectId,
                } = this.getInbandManagementProjectIdAndNetworkName(isNetworkSelected);

                projectId = inbandProjectId;
                networkName = inbandNetworkName;

                break;
            }

            case GCPNetworkConfigModeHash.ONE_ARM_MODE: {
                const {
                    oneArmProjectId,
                    oneArmNetworkName,
                } = this.getOneArmProjectIdAndNetworkName(isNetworkSelected);

                projectId = oneArmProjectId;
                networkName = oneArmNetworkName;

                break;
            }

            case GCPNetworkConfigModeHash.FRONT_END: {
                const {
                    frontendProjectId,
                    frontendNetworkName,
                } = this.getFrontendProjectIdAndNetworkName(isNetworkSelected);

                projectId = frontendProjectId;
                networkName = frontendNetworkName;

                break;
            }

            case GCPNetworkConfigModeHash.BACK_END: {
                const {
                    backendProjectId,
                    backendNetworkName,
                } = this.getBackendProjectIdAndNetworkName(isNetworkSelected);

                projectId = backendProjectId;
                networkName = backendNetworkName;

                break;
            }

            case GCPNetworkConfigModeHash.ONE_ARM_MANAGEMENT: {
                const {
                    oneArmManagementProjectId,
                    oneArmManagementNetworkName,
                } = this.getOneArmManagementProjectIdAndNetworkName(isNetworkSelected);

                projectId = oneArmManagementProjectId;
                networkName = oneArmManagementNetworkName;

                break;
            }

            case GCPNetworkConfigModeHash.TWO_ARM_MANAGEMENT: {
                const {
                    twoArmManagementProjectId,
                    twoArmManagementNetworkName,
                } = this.getTwoArmManagementProjectIdAndNetworkName(isNetworkSelected);

                projectId = twoArmManagementProjectId;
                networkName = twoArmManagementNetworkName;

                break;
            }

            default:
                projectId = config.se_project_id;

                break;
        }

        return {
            projectId,
            networkName,
        };
    }

    /**
     * Return Inband Management Config Mode Project Id and Network Name.
     */
    private getInbandManagementProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): IInbandProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { inband } = config.network_config.config;
        const {
            vpc_project_id: vpcProjectId,
            vpc_network_name: vpcNetworkName,
        } = inband.config;
        const inbandProjectId = vpcProjectId || seProjectId;
        let inbandNetworkName: string;

        if (isNetworkSelected) {
            inbandNetworkName = vpcNetworkName;
        }

        return {
            inbandProjectId,
            inbandNetworkName,
        };
    }

    /**
     * Returns One Arm Config Mode Project ID and Network Name.
     */
    private getOneArmProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): IOneArmProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { one_arm: oneArm } = config.network_config.config;
        const {
            data_vpc_project_id: dataVpcProjectId,
            data_vpc_network_name: dataVpcNetworkName,
        } = oneArm.config;
        let oneArmNetworkName: string;

        const oneArmProjectId = dataVpcProjectId || seProjectId;

        if (isNetworkSelected) {
            oneArmNetworkName = dataVpcNetworkName;
        }

        return {
            oneArmProjectId,
            oneArmNetworkName,
        };
    }

    /**
     * Returns Two Arm Config Mode Frontend Project ID and Network Name.
     */
    private getFrontendProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): IFrontendProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { two_arm: twoArm } = config.network_config.config;
        const {
            frontend_data_vpc_network_name: feDataVpcNetworkName,
            frontend_data_vpc_project_id: feDataVpcProjectId,
        } = twoArm.config;
        let frontendNetworkName: string;

        const frontendProjectId = feDataVpcProjectId || seProjectId;

        if (isNetworkSelected) {
            frontendNetworkName = feDataVpcNetworkName;
        }

        return {
            frontendProjectId,
            frontendNetworkName,
        };
    }

    /**
     * Returns Two Arm Config Mode Backend Project ID and Network Name.
     */
    private getBackendProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): IBackendProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { two_arm: twoArm } = config.network_config.config;
        const {
            backend_data_vpc_network_name: beDataVpcNetworkName,
            backend_data_vpc_project_id: beDataVpcProjectId,
        } = twoArm.config;
        let backendNetworkName: string;

        const backendProjectId = beDataVpcProjectId || seProjectId;

        if (isNetworkSelected) {
            backendNetworkName = beDataVpcNetworkName;
        }

        return {
            backendProjectId,
            backendNetworkName,
        };
    }

    /**
     * Returns One Arm Config Mode Management Project ID and Network Name.
     */
    private getOneArmManagementProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): IOneArmManagementProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { one_arm: oneArm } = config.network_config.config;
        const {
            management_vpc_project_id: managementVpcProjectId,
            management_vpc_network_name: managementVpcNetworkName,
        } = oneArm.config;
        let oneArmManagementNetworkName: string;

        const oneArmManagementProjectId = managementVpcProjectId || seProjectId;

        if (isNetworkSelected) {
            oneArmManagementNetworkName = managementVpcNetworkName;
        }

        return {
            oneArmManagementProjectId,
            oneArmManagementNetworkName,
        };
    }

    /**
     * Returns Two Arm Config Mode Management Project ID and Network Name.
     */
    private getTwoArmManagementProjectIdAndNetworkName(
        isNetworkSelected = false,
    ): ITwoArmManagementProjectIdAndNetwork {
        const { config } = this;
        const { se_project_id: seProjectId } = config;
        const { two_arm: twoArm } = config.network_config.config;
        const {
            management_vpc_network_name: managementVpcNetworkName,
            management_vpc_project_id: managementVpcProjectId,
        } = twoArm.config;
        let twoArmManagementNetworkName: string;

        const twoArmManagementProjectId = managementVpcProjectId || seProjectId;

        if (isNetworkSelected) {
            twoArmManagementNetworkName = managementVpcNetworkName;
        }

        return {
            twoArmManagementProjectId,
            twoArmManagementNetworkName,
        };
    }

    /**
     * Returns requestConfig for fetching data from backend depending upon url type passed.
     */
    private getRequestConfig(
        requestUrl: string,
        gcpCredentials?: TGcpCredentials,
    ): IHttpWrapperRequestConfig {
        const { config } = this;

        const cloudCredentialsRef = gcpCredentials ? gcpCredentials.cloud_credentials_ref :
            config.cloud_credentials_ref;

        const seProjectId = gcpCredentials ? gcpCredentials.se_project_id : config.se_project_id;

        const payload: IPayload = {
            project: seProjectId,
        };

        if (cloudCredentialsRef) {
            payload.cloud_credentials_uuid = this.stringService.slug(cloudCredentialsRef);
        }

        switch (requestUrl) {
            case NETWORKS_API:
                payload.nic_id = 0;
                break;

            case AVAILABILITY_ZONES_API:
                payload.region = config.region_name;
                break;

            case SUBNETS_API:
                payload.region = config.region_name;
                break;
        }

        return {
            url: requestUrl,
            method: HttpMethod.GET,
            params: payload,
        };
    }
}

