/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module IpamModule
 */

import {
    ICustomIpamSubnet,
    ICustomParams,
    IIpamDnsCustomProfile,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { IpamDnsCustomProfile } from 'object-types';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { CustomIpamSubnetConfigItem }
    from 'ajs/modules/ipam/factories/custom-ipam-subnet.config-item.factory';

type TIpamDnsCustomProfilePartial = Omit<IIpamDnsCustomProfile,
'dynamic_params' | 'usable_alloc_subnets'>;

interface IIpamDnsCustomProfileConfig extends TIpamDnsCustomProfilePartial {
    dynamic_params?: RepeatedMessageItem<MessageItem<ICustomParams>>;
    usable_alloc_subnets?: RepeatedMessageItem<CustomIpamSubnetConfigItem>;
}

/**
 * Type of Promise that will be returned by testLogin testLoginCustomIpamProfile method.
 */
interface ITestLoginResponse {
    loginError?: string;
    connectionStatus: boolean;
}

/**
 * Constant for Usable alloc subnets field.
 */
const USABLE_ALLOC_SUBNETS_FIELD = 'usable_alloc_subnets';

/**
 * @description IpamDnsCustomProfile ConfigItem class.
 *
 * @author Aravindh Nagarajan, Hitesh Mandav
 */
export class IpamDnsCustomProfileConfigItem extends withEditChildMessageItemMixin(MessageItem)
    <IIpamDnsCustomProfileConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'stringService',
    ];

    private readonly httpWrapper: HttpWrapper;

    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IpamDnsCustomProfile,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_('stringService');
    }

    /**
     * Get usable_domains count.
     */
    public get usableDomainsCount(): number {
        return Array.isArray(this.config.usable_domains) ? this.config.usable_domains.length : 0;
    }

    /**
     * Get usable_alloc_subnets.
     */
    public get usableAllocSubnets(): RepeatedMessageItem<CustomIpamSubnetConfigItem> {
        const { usable_alloc_subnets: usableAllocSubnets } = this.config;

        return usableAllocSubnets;
    }

    /**
     * Add usable_alloc_subnet entry to Custom profiles.
     */
    public addUsableSubnet(availableSubnets: ICustomIpamSubnet[]): void {
        this.addChildMessageItem({
            field: USABLE_ALLOC_SUBNETS_FIELD,
            modalBindings: {
                availableSubnets,
            },
        });
    }

    /**
     * Edit Script Param Message Item.
     */
    public editUsableSubnet(
        usableSubnet: CustomIpamSubnetConfigItem,
        availableSubnets: ICustomIpamSubnet[],
    ): void {
        this.editChildMessageItem({
            field: USABLE_ALLOC_SUBNETS_FIELD,
            messageItem: usableSubnet,
            modalBindings: {
                editMode: true,
                availableSubnets,
            },
        });
    }

    /**
     * Remove current item at index from IpamDnsCustomProfile::usable_alloc_subnets.
     */
    public removeUsableSubnet(usableSubnet: CustomIpamSubnetConfigItem): void {
        this.config[USABLE_ALLOC_SUBNETS_FIELD].removeByMessageItem(usableSubnet);
    }

    /**
     * Clear usable subnets of IPAM infoblox profile.
     */
    public clearUsableSubnetList(): void {
        this.config[USABLE_ALLOC_SUBNETS_FIELD].removeAll();
    }

    /**
     * Test login for custom IPAM Profile
     */
    public async testLoginCustomIpamProfile(): Promise<ITestLoginResponse> {
        const customIpamProfileId =
        this.stringService.slug(this.config.custom_ipam_dns_profile_ref);

        const url = 'api/ipamdnsproviderprofilelogin';
        const queryParam = `type=IPAMDNS_TYPE_CUSTOM&customdnsprovider_uuid=${customIpamProfileId}`;
        const requestConfig = {
            url: `${url}?${queryParam}`,
            method: HttpMethod.GET,
        };

        try {
            await this.httpWrapper.request(requestConfig);

            return {
                connectionStatus: true,
            };
        } catch (err) {
            return {
                loginError: err.data.error,
                connectionStatus: false,
            };
        }
    }

    /**
     * Make request for the network list with uuid.
     * @param ipamProfileId - IPAM Profile UUID used to fetch the domain list.
     */
    public getAvailableSubnetsWithId(ipamProfileId: string): Promise<ICustomIpamSubnet[]> {
        const api = '/api/ipamdnsproviderprofilenetworklist';

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            // eslint-disable-next-line max-len
            url: `${api}?ipamdnsprovider_uuid=${ipamProfileId}&provider=true&type=IPAMDNS_TYPE_CUSTOM`,
        })
            .then(({ data }) => data.alloc_networks_and_subnets)
            .finally(() => this.busy = false);
    }
}
