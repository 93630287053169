/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function vsWafTopHitsDataTransportFactory(ListDataTransport) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransport
     * @author Alex Malitsky, Ashish Verma
     * @desc One API call for each groupby param.
    */
    class VsWafTopHitsDataTransport extends ListDataTransport {
        /**
         * One call per each group (differs by groupby param value only).
         * @override
         */
        getQueryParams_(params) {
            params = angular.copy(params);

            const { groupBy_: groupBys } = params;

            delete params['groupBy_'];

            return groupBys.map(groupBy => {
                params.groupby = groupBy;

                return super.getQueryParams_(params);
            });
        }
    }

    return VsWafTopHitsDataTransport;
}

vsWafTopHitsDataTransportFactory.$inject = [
    'ListDataTransport',
];

angular.module('avi/app').factory(
    'VsWafTopHitsDataTransport',
    vsWafTopHitsDataTransportFactory,
);
