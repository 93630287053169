/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'request-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const redirectActionLabel = `${prefix}.redirectActionLabel`;
export const modifyHeaderActionLabel = `${prefix}.modifyHeaderActionLabel`;
export const rewriteUrlActionLabel = `${prefix}.rewriteUrlActionLabel`;
export const contentSwitchActionLabel = `${prefix}.contentSwitchActionLabel`;
export const ipReputationDbHeader = `${prefix}.ipReputationDbHeader`;
export const addHttpRequestRuleHeader = `${prefix}.addHttpRequestRuleHeader`;
export const sourceIpMatchLabel = `${prefix}.sourceIpMatchLabel`;
export const customStringLabel = `${prefix}.customStringLabel`;
export const matchCaseLabel = `${prefix}.matchCaseLabel`;
export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [redirectActionLabel]: 'Redirect',
    [modifyHeaderActionLabel]: 'Modify Header',
    [rewriteUrlActionLabel]: 'Rewrite URL',
    [contentSwitchActionLabel]: 'Content Switch',
    [ipReputationDbHeader]: 'IP Reputation DB',
    [addHttpRequestRuleHeader]: 'Add HTTP Request Rule',
    [customStringLabel]: 'String group or custom string',
    [matchCaseLabel]: 'Match Case',
    [sourceIpMatchLabel]: 'Source IP',
};
