/**
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IRoleFilterMatchLabel } from 'generated-types';

import { MessageItem } from '.';

export const ROLE_FILTER_MATCH_LABEL_CONFIG_ITEM_TOKEN = 'RoleFilterMatchLabelConfigItem';

export interface IRoleFilterMatchLabelConfig extends IRoleFilterMatchLabel {
    flatProps: IRoleFilterMatchLabel;
}

/**
 * @description
 *
 *     RoleFilterMatchLabelConfig MessageItem.
 *     This message item holds Role-Based Access Control (RBAC) info.
 *
 * @author Zhiqian Liu
 */
export class RoleFilterMatchLabelConfigItem extends MessageItem<IRoleFilterMatchLabelConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'RoleFilterMatchLabel',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        this.config.flatProps = {};
        this.updateFlatProps();
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        delete this.config.flatProps;
    }

    /**
     * Sets flatProps on this.config.
     * flatProps is used to allow passing a flat object as the ngModel (legacy).
     * We need to keep flatProps updated so that the real config doesn't get
     * overwritten with stale flatProps data in modifyConfigDataBeforeSave.
     */
    private updateFlatProps(): void {
        const { key, values } = this.config;
        const { flatProps } = this.config;

        flatProps.key = key;
        flatProps.values = values;
    }
}
