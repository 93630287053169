/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { IHttpResponse } from 'angular';
import { AsyncFactory } from 'ajs/modules/core/factories/async-factory/async.factory';

import {
    HttpMethod,
    HttpWrapper,
    IHttpWrapperRequestConfig,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import {
    concatMap,
    exhaustMap,
    switchMap,
    take,
} from 'rxjs/operators';

import {
    IDebugServiceEngine,
    IServiceEngine,
} from 'generated-types';

import { isEqual } from 'underscore';

import {
    ICaptureFiles,
    IStartCaptureConfig,
    IStopCaptureConfig,
    ITrafficCaptureState,
} from '../types/service-engine.types';

export const defaultState: ITrafficCaptureState = {
    serviceEngine: null,
    debugServiceEngine: null,
    captureFiles: [],
    error: null,
    captureInProgress: false,
    stopCaptureInProgress: false,
};

type TAsyncFactory = typeof AsyncFactory;

const FILE_SERVICE_URL = '/api/fileservice?uri=controller://se-pcap';

const ALLOWED_CAPTURE_FILE_EXTENSION = 'tar';

const FETCH_CURRENT_TRAFFIC_CAPTURE = 'fetch-current-traffic-capture';
const DELETE_TRAFFIC_CAPTURE_FILE = 'delete-traffic-capture-file';
const START_TRAFFIC_CAPTURE = 'start-traffic-capture';
const STOP_TRAFFIC_CAPTURE = 'stop-traffic-capture';
const FETCH_TRAFFIC_CAPTURE_FILES = 'fetch-traffic-capture-files';
const SERVICE_TIMEOUT_ERROR = 'Service Time-out';
const SERVICE_TIMEOUT_STATUS_CODE = 504;

/**
 * @description SE Traffic capture store to handle traffic capture state.
 *
 * @author Suraj Kumar
 */

@Injectable()
export class ServiceEngineTrafficCaptureStore extends ComponentStore<ITrafficCaptureState> {
    /**
     * DebugServiceEngine NgRx/selector for selecting debugServiceEngine from the state.
     */
    public debugServiceEngine$ = this.select(state => state.debugServiceEngine);

    /**
     * ServiceEngine NgRx/selector for selecting serviceEngine from the state.
     */
    public serviceEngine$ = this.select(state => state.serviceEngine);

    /**
     * CaptureFiles NgRx/selector for selecting captureFiles from the state.
     */
    public captureFiles$ = this.select(state => state.captureFiles);

    /**
     * Error NgRx/selector for selecting error from the state.
     */
    public error$ = this.select(state => state.error);

    /**
     * CaptureInProgress NgRx/selector for selecting captureInProgress from the state.
     */
    public captureInProgress$ = this.select(state => state.captureInProgress);

    /**
     * StopCaptureInProgress NgRx/selector for selecting stopCaptureInProgress from the state.
     */
    public stopCaptureInProgress$ = this.select(state => state.stopCaptureInProgress);

    /**
     * NgRx/Effect for fetching DebugServiceEngine and each new call of getCurrentCapture(config)
     * pushed that config into config$ stream.
     */
    public getCurrentCapture = this.effect((config$: Observable<void>) => {
        return config$.pipe(
            switchMap(async config => {
                const requestConfig = {
                    url: this.url,
                    method: HttpMethod.GET,
                    requestId: FETCH_CURRENT_TRAFFIC_CAPTURE,
                };

                try {
                    this.setError(undefined);

                    const { data } = await this.sendRequest(requestConfig);

                    this.setCaptureInProgress(Boolean(data?.flags?.length));
                    this.setDebugServiceEngine(data);
                } catch ({ data }) {
                    this.setError(data?.error);
                }
            }),
        );
    });

    /**
     * NgRx/Effect for deleting capture file and each new call of deleteFile(config)
     * pushed that config into config$ stream.
     */
    public deleteFile = this.effect((fileName$: Observable<string>) => {
        return fileName$.pipe(
            concatMap(async fileName => {
                const requestConfig = {
                    url: `${FILE_SERVICE_URL}/${fileName}`,
                    method: HttpMethod.DELETE,
                    requestId: DELETE_TRAFFIC_CAPTURE_FILE,
                };

                try {
                    await this.sendRequest(requestConfig);

                    this.getAllCaptureFiles();
                } catch ({ data }) {
                    this.setError(data);
                }
            }),
        );
    });

    /**
     * NgRx/Effect for starting start capture and each new call of startCapture(config)
     * pushed that config into config$ stream.
     */
    public startCapture = this.effect((config$: Observable<IStartCaptureConfig>) => {
        return config$.pipe(
            switchMap(async startCaptureConfig => {
                const requestConfig = {
                    url: this.url,
                    method: HttpMethod.PUT,
                    data: startCaptureConfig.config,
                    requestId: START_TRAFFIC_CAPTURE,
                };

                try {
                    await this.sendRequest(requestConfig);
                    startCaptureConfig.whenSaved();
                    this.getCurrentCapture();
                } catch ({ data }) {
                    startCaptureConfig.whenError(data);
                }
            }),
        );
    });

    /**
     * NgRx/Effect for starting stop capture and each new call of stopCapture(config)
     * pushed that config into config$ stream.
     */
    public stopCapture = this.effect((config$: Observable<IStopCaptureConfig>) => {
        return config$.pipe(
            exhaustMap(async stopCaptureConfig => {
                const stopConfig = JSON.parse(JSON.stringify(stopCaptureConfig?.config));

                delete stopConfig.flags;

                const requestConfig = {
                    url: this.url,
                    method: HttpMethod.PUT,
                    data: stopConfig,
                    requestId: STOP_TRAFFIC_CAPTURE,
                };

                try {
                    this.setError(undefined);

                    this.setStopCaptureInProgress(true);

                    const { data } = await this.sendRequest(requestConfig);

                    stopCaptureConfig.whenStopped();
                    this.setCaptureInProgress(false);
                    this.setDebugServiceEngine(data);
                } catch (error) {
                    /**
                     * We get 504 and request fails when capture file size is bigger but it would
                     * have stopped the traffic capture. We get error because creation of tar is
                     * under process and is taking more then our default time out so we get that.
                     */
                    if (error.status === SERVICE_TIMEOUT_STATUS_CODE &&
                        error.data?.error === SERVICE_TIMEOUT_ERROR) {
                        this.setCaptureInProgress(false);
                        stopCaptureConfig.whenStopped();
                    } else {
                        this.setError(error.data?.error);
                    }
                } finally {
                    this.setStopCaptureInProgress(false);
                }
            }),
        );
    });

    /**
     * NgRx/Effect for getting all capture files stop capture and each new call of
     * getAllCaptureFiles(config) pushed that config into config$ stream.
     */
    public getAllCaptureFiles = this.effect((config$: Observable<void>) => {
        return config$.pipe(
            switchMap(async config => {
                const requestConfig = {
                    url: `${FILE_SERVICE_URL}`,
                    method: HttpMethod.GET,
                    requestId: FETCH_TRAFFIC_CAPTURE_FILES,
                };

                try {
                    const { data } = await this.sendRequest(requestConfig);

                    if (data?.results) {
                        const newCaptureFiles: ICaptureFiles[] = [];

                        data.results.forEach((captureFileObj: ICaptureFiles) => {
                            const { url } = captureFileObj;
                            const ext = url.substring(url.lastIndexOf('.') + 1);

                            if (ext === ALLOWED_CAPTURE_FILE_EXTENSION) {
                                captureFileObj.size = Math.round(captureFileObj.size / 1024);
                                newCaptureFiles.push(captureFileObj);
                            }
                        });

                        this.captureFiles$.pipe(take(1)).subscribe(captureFiles => {
                            const currentCaptureFiles = captureFiles;

                            if (!isEqual(currentCaptureFiles, newCaptureFiles)) {
                                this.setCaptureFiles(newCaptureFiles);
                            }
                        });
                    }
                } catch ({ data }) {
                    this.setError(data?.error);
                }
            }),
        );
    });

    /**
     * Updater function to change state.serviceEngine.
     */
    public setServiceEngine = this.updater((state, serviceEngine: IServiceEngine) => {
        return {
            ...state,
            serviceEngine,
        };
    });

    /**
     * Updater function to change state.error.
     */
    public setError = this.updater((state, error: string) => {
        return {
            ...state,
            error,
        };
    });

    /**
     * NgRx/updater for updating store state.
     */
    public dataHandler = this.updater((state, data: ITrafficCaptureState) => ({
        ...state,
        ...data,
    }));

    /**
     * Updater function to change state.debugServiceEngine.
     */
    private setDebugServiceEngine = this.updater(
        (state, debugServiceEngine: IDebugServiceEngine) => {
            return {
                ...state,
                debugServiceEngine,
            };
        },
    );

    /**
     * Updater function to change state.captureFiles.
     */
    private setCaptureFiles = this.updater((state, captureFiles: ICaptureFiles[]) => {
        return {
            ...state,
            captureFiles,
        };
    });

    /**
     * Updater function to change state.captureInProgress.
     */
    private setCaptureInProgress = this.updater((state, captureInProgress: boolean) => {
        return {
            ...state,
            captureInProgress,
        };
    });

    /**
     * Updater function to change state.stopCaptureInProgress.
     */
    private setStopCaptureInProgress = this.updater((state, stopCaptureInProgress: boolean) => {
        return {
            ...state,
            stopCaptureInProgress,
        };
    });

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * Handle URL for sending requests.
     */
    private url: string;

    constructor(
        @Inject('AsyncFactory')
        private readonly AsyncFactory: TAsyncFactory,
        // eslint-disable-next-line @typescript-eslint/indent
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        super(defaultState);
        this.httpWrapper = new HttpWrapper();

        this.serviceEngine$.subscribe(serviceEngine => {
            if (serviceEngine) {
                this.url = `/api/debugserviceengine/${serviceEngine?.uuid}#${serviceEngine?.name}`;
            }
        });
    }

    /**
     * Cancels all the outgoing requests on user going out of the trafic capture tab
     */
    public cancelAllRequests(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /**
     *  Method for sending http Requests.
     */
    private sendRequest<T = any>(
        requestConfig: IHttpWrapperRequestConfig,
    ): Promise<IHttpResponse<T>> {
        return this.httpWrapper.request(requestConfig);
    }
}
