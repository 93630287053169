/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'cloud-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleHost = `${prefix}.columnTitleHost`;
export const columnTitleHostState = `${prefix}.columnTitleHostState`;
export const columnTitleServiceEngineState = `${prefix}.columnTitleServiceEngineState`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleStatus = `${prefix}.columnTitleStatus`;
export const convertToCloudNone = `${prefix}.convertToCloudNone`;
export const convertToNoOrchestratorTooltip = `${prefix}.convertToNoOrchestratorTooltip`;
export const downloadSeImageTooltip = `${prefix}.downloadSeImageTooltip`;
export const installLbaasPluginTooltip = `${prefix}.installLbaasPluginTooltip`;
export const generateTokenTooltip = `${prefix}.generateTokenTooltip`;
export const rediscoverTooltip = `${prefix}.rediscoverTooltip`;
export const creatingSeImageForDownloadMessage = `${prefix}.creatingSeImageForDownloadMessage`;
export const vcenterHostnameHeader = `${prefix}.vcenterHostnameHeader`;
export const dataCenterHeader = `${prefix}.dataCenterHeader`;
export const usernameHeader = `${prefix}.usernameHeader`;
export const accessPermissionHeader = `${prefix}.accessPermissionHeader`;
export const ipAddressManagementHeader = `${prefix}.ipAddressManagementHeader`;
export const staticIpAddressLabel = `${prefix}.staticIpAddressLabel`;
export const managementNetworkHeader = `${prefix}.managementNetworkHeader`;
export const subnetLabel = `${prefix}.subnetLabel`;
export const defaultGatewayHeader = `${prefix}.defaultGatewayHeader`;
export const adminTenantHeader = `${prefix}.adminTenantHeader`;
export const domainHeader = `${prefix}.domainHeader`;
export const deploymentHeader = `${prefix}.deploymentHeader`;
export const regionHeader = `${prefix}.regionHeader`;
export const managementNetworksHeader = `${prefix}.managementNetworksHeader`;
export const availabilityZoneLabel = `${prefix}.availabilityZoneLabel`;
export const networkLabel = `${prefix}.networkLabel`;
export const keystoneAuthUrlHeader = `${prefix}.keystoneAuthUrlHeader`;
export const authenticationHeader = `${prefix}.authenticationHeader`;
export const hypervisorTypeHeader = `${prefix}.hypervisorTypeHeader`;
export const projectIdHeader = `${prefix}.projectIdHeader`;
export const vpcNetworkNameHeader = `${prefix}.vpcNetworkNameHeader`;
export const zonesHeader = `${prefix}.zonesHeader`;
export const vipStaticRoutesHeader = `${prefix}.vipStaticRoutesHeader`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const routePlacementPreferenceHeader = `${prefix}.routePlacementPreferenceHeader`;
export const staticRoutesLabel = `${prefix}.staticRoutesLabel`;
export const interfaceRoutesLabel = `${prefix}.interfaceRoutesLabel`;
export const statusLabel = `${prefix}.statusLabel`;
export const reasonLabel = `${prefix}.reasonLabel`;
export const convertCloudTypeTooltip = `${prefix}.convertCloudTypeTooltip`;

export const ENGLISH = {
    [columnTitleHost]: 'Host',
    [columnTitleHostState]: 'Host State',
    [columnTitleServiceEngineState]: 'Service Engine State',
    [columnTitleName]: 'Name',
    [columnTitleType]: 'Type',
    [columnTitleStatus]: 'Status',
    [convertToCloudNone]:
        `Converting Cloud {0} to no access will limit your ability to modify Service Engine
        properties requiring an administrator to manually perform changes through the orchestrator.`,
    [convertToNoOrchestratorTooltip]: 'Convert to No Orchestrator',
    [downloadSeImageTooltip]: 'Download Service Engine Image',
    [installLbaasPluginTooltip]: 'Install LBaaS Plugin',
    [generateTokenTooltip]: 'Generate Token',
    [rediscoverTooltip]: 'Rediscover',
    [creatingSeImageForDownloadMessage]: 'Creating Service Engine Image for Download.',
    [vcenterHostnameHeader]: 'vCenter Hostname',
    [dataCenterHeader]: 'Data Center',
    [usernameHeader]: 'Username',
    [accessPermissionHeader]: 'Access Permission',
    [ipAddressManagementHeader]: 'IP Address Management',
    [staticIpAddressLabel]: 'Static IP Address',
    [managementNetworkHeader]: 'Management Network',
    [subnetLabel]: 'Subnet:',
    [defaultGatewayHeader]: 'Default Gateway:',
    [adminTenantHeader]: 'Admin Tenant',
    [domainHeader]: 'Domain',
    [deploymentHeader]: 'Deployment',
    [regionHeader]: 'Region',
    [managementNetworksHeader]: 'Management Networks',
    [availabilityZoneLabel]: '<b>Availability Zone:</b> {0}',
    [networkLabel]: '<b>Network:</b> {0}',
    [keystoneAuthUrlHeader]: 'Keystone Auth URL',
    [authenticationHeader]: 'Authentication',
    [hypervisorTypeHeader]: 'Hypervisor Type',
    [projectIdHeader]: 'Project Id',
    [vpcNetworkNameHeader]: 'VPC Network Name',
    [zonesHeader]: 'Zones',
    [vipStaticRoutesHeader]: 'VIP Static Routes',
    [enabledLabel]: 'Enabled',
    [disabledLabel]: 'Disabled',
    [routePlacementPreferenceHeader]: 'Route Placement Preference',
    [staticRoutesLabel]: 'Static Routes',
    [interfaceRoutesLabel]: 'Interface Routes',
    [statusLabel]: '<strong>Status:</strong> {0}',
    [reasonLabel]: '<strong>Reason:</strong> {0}',
    [convertCloudTypeTooltip]: 'Convert Cloud Type',
};
