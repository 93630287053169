/**
 * @module LoginModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
} from '@angular/core';

import { InitialDataService } from 'ajs/modules/core/services/initial-data';

import './login-banner.component.less';

/**
 * @description
 *      Login-banner component
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'login-banner',
    templateUrl: './login-banner.component.html',
})
export class LoginBannerComponent {
    /**
     * Banner from initData
     */
    public loginBanner = '';

    public constructor(@Inject(InitialDataService) initialDataService: InitialDataService) {
        this.loginBanner = initialDataService.controllerBanner;
    }

    /**
     * Returns true if banner is configured.
     */
    public hasBanner(): boolean {
        return Boolean(this.loginBanner);
    }
}
