/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { AuthMappingRule } from 'object-types';
import { isUndefined } from 'underscore';
import {
    AuthRuleAssignment,
    IAuthMappingRule,
} from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { AuthMappingRuleModalComponent }
    from 'ng/modules/auth-settings/components/auth-mapping-rule-modal';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { AuthMatchAttributeConfigItem } from './auth-match-attribute.config-item.factory';
import { AuthMatchGroupMembershipConfigItem }
    from './auth-match-group-membership.config-item.factory';
import * as l10n from '../auth-settings.l10n';

type TAuthMappingRulePartial = Omit<IAuthMappingRule, 'attribute_match' | 'group_match'>;

interface IAuthMappingRuleConfig extends TAuthMappingRulePartial {
    attribute_match?: AuthMatchAttributeConfigItem;
    group_match?: AuthMatchGroupMembershipConfigItem;
}

export const enum AuthMappingRuleCustomMappingFields {
    ASSIGN_TENANT = 'assign_tenant',
    ASSIGN_ROLE = 'assign_role',
    ASSIGN_USERPROFILE = 'assign_userprofile',
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description AuthMappingRule ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class AuthMappingRuleConfigItem extends
    withFullModalMixin(MessageItem)<IAuthMappingRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthMappingRule,
            windowElement: AuthMappingRuleModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /**
     * Getter for group_match.
     */
    public get groupMatch(): AuthMatchGroupMembershipConfigItem {
        return this.config.group_match;
    }

    /**
     * Getter for attribute_match.
     */
    public get attributeMatch(): AuthMatchAttributeConfigItem {
        return this.config.attribute_match;
    }

    /**
     * Getter for is_superuser prop.
     */
    public get isSuperUser(): boolean {
        return Boolean(this.config.is_superuser);
    }

    /**
     * Returns true if rule has any mapping configured.
     */
    public hasAnyCustomMapping(): boolean {
        return Boolean(this.config.assign_role || this.config.assign_tenant ||
            this.config.assign_userprofile);
    }

    /**
     * Clears mapping.
     */
    public clearCustomMapping(): void {
        this.clearTenantMapping();
        this.clearRoleMapping();
        this.clearUserProfileMapping();
    }

    /**
     * Clears mapping based on fieldname.
     */
    public clearMappingByFieldName(fieldName: string): void {
        switch (fieldName) {
            case AuthMappingRuleCustomMappingFields.ASSIGN_TENANT: {
                this.clearTenantMapping();

                break;
            }

            case AuthMappingRuleCustomMappingFields.ASSIGN_ROLE: {
                this.clearRoleMapping();

                break;
            }

            case AuthMappingRuleCustomMappingFields.ASSIGN_USERPROFILE: {
                this.clearUserProfileMapping();

                break;
            }
        }
    }

    /**
     * Sets mapping based on fieldname.
     */
    public setMappingByFieldName(fieldName: string): void {
        this.config[fieldName] = AuthRuleAssignment.ASSIGN_FROM_SELECT_LIST;
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        if (!this.groupMatch.hasCriteria) {
            this.groupMatch.config.criteria = null;
        }

        if (!this.attributeMatch.hasCriteria) {
            this.attributeMatch.config.criteria = null;
        }

        if (isUndefined(this.config.is_superuser)) {
            this.config.is_superuser = false;
        }
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        if (!this.groupMatch.hasCriteria) {
            this.config.group_match = undefined;
        }

        if (!this.attributeMatch.hasCriteria) {
            this.config.attribute_match = undefined;
        }
    }

    /**
     * Resets userprofile mapping.
     */
    public resetUserProfileMapping(): void {
        this.config.userprofile_ref = undefined;
        this.config.userprofile_attribute_name = undefined;
    }

    /**
     * Resets tenant mapping.
     */
    public resetTenantMapping(): void {
        this.config.tenant_refs = undefined;
        this.config.tenant_attribute_name = undefined;
        this.config.default_tenant_ref = undefined;
    }

    /**
     * Resets role mapping.
     */
    public resetRoleMapping(): void {
        this.config.role_refs = undefined;
        this.config.role_attribute_name = undefined;
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'attribute_match',
            'group_match',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authMappingRuleModalBreadcrumbTitle);
    }

    /**
     * Clears role mapping.
     */
    private clearRoleMapping(): void {
        this.config.assign_role = undefined;

        this.resetRoleMapping();
    }

    /**
     * Clears tenant mapping.
     */
    private clearTenantMapping(): void {
        this.config.assign_tenant = undefined;

        this.resetTenantMapping();
    }

    /**
     * Clears userprofile mapping.
     */
    private clearUserProfileMapping(): void {
        this.config.assign_userprofile = undefined;

        this.resetUserProfileMapping();
    }
}
