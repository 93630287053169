/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HealthMonitorType,
    IHealthMonitor,
    IHealthMonitorAuthInfo,
    IHealthMonitorDNS,
    IHealthMonitorExternal,
    IHealthMonitorHttp,
    IHealthMonitorRadius,
    IHealthMonitorSIP,
    IHealthMonitorSSLAttributes,
    IHealthMonitorTcp,
    IHealthMonitorUdp,
    IRoleFilterMatchLabel,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { HealthMonitorHttpConfigItem } from './factories/health-monitor-http.config.item.factory';

/**
 * Health Monitor HTTP Partial type.
 */
type THealthMonitorHttpPartial = Omit<IHealthMonitorHttp, 'ssl_attributes'>;

/**
 * Interface for Health Monitor HTTP Config.
 */
export interface IHealthMonitorHttpConfig extends THealthMonitorHttpPartial {
    ssl_attributes?: MessageItem<IHealthMonitorSSLAttributes>
}

/**
 * Child message fields for which we need to override types.
 */
type TChildMessageFields = 'authentication' |
'dns_monitor' |
'external_monitor' |
'http_monitor' |
'https_monitor' |
'markers' |
'radius_monitor' |
'sip_monitor' |
'tcp_monitor' |
'udp_monitor';

/**
 * Health Monitor Partial type.
 */
type THealthMonitorPartial = Omit<IHealthMonitor, TChildMessageFields>;

/**
 * Interface for Health Monitor Config.
 */
export interface IHealthMonitorConfig extends THealthMonitorPartial {
    authentication: MessageItem<IHealthMonitorAuthInfo>;
    dns_monitor: MessageItem<IHealthMonitorDNS>;
    external_monitor: MessageItem<IHealthMonitorExternal>;
    http_monitor: HealthMonitorHttpConfigItem;
    https_monitor: HealthMonitorHttpConfigItem;
    markers: RepeatedMessageItem<MessageItem<IRoleFilterMatchLabel>>;
    radius_monitor: MessageItem<IHealthMonitorRadius>;
    sip_monitor: MessageItem<IHealthMonitorSIP>;
    tcp_monitor: MessageItem<IHealthMonitorTcp>;
    udp_monitor: MessageItem<IHealthMonitorUdp>;
}

/**
 * Interface for Health Monitor data.
 */
export interface IHealthMonitorData {
    config: IHealthMonitorConfig;
}

/**
 * Child field name constants.
 */
export const AUTHENTICATION = 'authentication';

/**
 * Set of Health Monitor Types for which we need to show Authentication field.
 */
export const HmTypesWithAuthenticationField = new Set([
    HealthMonitorType.HEALTH_MONITOR_HTTP,
    HealthMonitorType.HEALTH_MONITOR_HTTPS,
    HealthMonitorType.HEALTH_MONITOR_EXTERNAL,
]);
