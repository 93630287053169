/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AnalyticsModule */

import { Injector } from '@angular/core';

import {
    AnalyticsProfileCollection,
} from 'ajs/modules/analytics/factories/analytics-profile.collection.factory';

import { ANALYTICS_PROFILE_COLLECTION_TOKEN } from 'ajs/modules/analytics/analytics.tokens';

const $injector = '$injector';

export const analyticsProfileCollectionProvider = {
    deps: [$injector],
    provide: AnalyticsProfileCollection,
    useFactory(injector: Injector): typeof AnalyticsProfileCollection {
        return injector.get(ANALYTICS_PROFILE_COLLECTION_TOKEN);
    },
};
