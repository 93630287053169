/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { IGeoLocation } from 'generated-types';
import { isUndefined } from 'angular';

/**
 * @description
 *     Pipe for converting a GeoLocation object to a string showing the location of the request
 *     along with the coordinates, ex. 'USA/California/Santa Clara (127°N, 58°E)'
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'geoLocationString',
})
export class GeoLocationStringPipe implements PipeTransform {
    // eslint-disable-next-line class-methods-use-this
    public transform(location: IGeoLocation): string | null {
        if (isUndefined(location)) {
            return null;
        }

        let locationString = '';

        if (!isUndefined(location.name)) {
            locationString += `${location.name} `;
        }

        if (!isUndefined(location.latitude) &&
            !isUndefined(location.longitude)) {
            const latitude = `${Math.abs(location.latitude)}\xB0${
                location.latitude < 1 ? 'S' : 'N'}`;
            const longitude = `${Math.abs(location.longitude)}\xB0${
                location.longitude < 1 ? 'W' : 'E'}`;

            locationString += `(${latitude}, ${longitude})`;
        }

        return locationString;
    }
}
