/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-security-waf';
const prefix = `${moduleName}.${componentName}`;

export const allowListLabel = `${prefix}.allowListLabel`;
export const hitLabel = `${prefix}.hitLabel`;
export const hitsLabel = `${prefix}.hitsLabel`;
export const timingLabel = `${prefix}.timingLabel`;
export const requestHeaderLabel = `${prefix}.requestHeaderLabel`;
export const requestBodyLabel = `${prefix}.requestBodyLabel`;
export const responseHeaderLabel = `${prefix}.responseHeaderLabel`;
export const responseBodyLabel = `${prefix}.responseBodyLabel`;
export const resourcesLabel = `${prefix}.resourcesLabel`;
export const wafMemoryUsedLabel = `${prefix}.wafMemoryUsedLabel`;
export const actionLabel = `${prefix}.actionLabel`;
export const groupLabel = `${prefix}.groupLabel`;
export const groupsLabel = `${prefix}.groupsLabel`;
export const locationLabel = `${prefix}.locationLabel`;
export const locationsLabel = `${prefix}.locationsLabel`;
export const ruleLabel = `${prefix}.ruleLabel`;
export const rulesLabel = `${prefix}.rulesLabel`;
export const positiveSecurityLabel = `${prefix}.positiveSecurityLabel`;
export const argumentLabel = `${prefix}.argumentLabel`;
export const applicationRulesLabel = `${prefix}.applicationRulesLabel`;
export const signatureLabel = `${prefix}.signatureLabel`;
export const phaseLabel = `${prefix}.phaseLabel`;
export const tagsLabel = `${prefix}.tagsLabel`;

export const ENGLISH = {
    [allowListLabel]: 'Allow List ({0} {1})',
    [hitLabel]: 'Hit',
    [hitsLabel]: 'Hits',
    [timingLabel]: 'Timing',
    [requestHeaderLabel]: 'Request Header',
    [requestBodyLabel]: 'Request Body',
    [responseHeaderLabel]: 'Response Header',
    [responseBodyLabel]: 'Response Body',
    [resourcesLabel]: 'Resources',
    [wafMemoryUsedLabel]: 'WAF Memory Used',
    [actionLabel]: 'Action',
    [groupLabel]: '{0} Group',
    [groupsLabel]: '{0} Groups',
    [locationLabel]: '{0} Location',
    [locationsLabel]: '{0} Locations',
    [ruleLabel]: '{0} Argument Rule',
    [rulesLabel]: '{0} Argument Rules',
    [positiveSecurityLabel]: 'Positive Security ({0}, {1}, {2})',
    [argumentLabel]: 'Argument',
    [applicationRulesLabel]: 'Application Rules ({0}, {1})',
    [signatureLabel]: 'Signature ({0}, {1})',
    [phaseLabel]: 'Phase',
    [tagsLabel]: 'Tags',
};
