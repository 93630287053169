/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import {
    ErrorPageConfigItem,
    ErrorPageProfile,
} from 'ajs/modules/error-page';

import { StringService } from 'ajs/modules/core/services/string-service';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './error-page-profile-grid.l10n';

import './error-page-profile-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Grid component for Error Pages List.
 * @author Ratan Kumar
 */
@Component({
    selector: 'error-page-profile-grid',
    templateUrl: './error-page-profile-grid.component.html',
})
export class ErrorPageProfileGridComponent implements AfterViewInit {
    /**
     * Error Page Profile Item.
     */
    @Input()
    public editable: ErrorPageProfile;

    /**
     * Fires on add Error page list.
     */
    @Output()
    public onAddErrorPage = new EventEmitter<void>();

    /**
     * Fires on edit Error page list.
     */
    @Output()
    public onEditErrorPage = new EventEmitter<ErrorPageConfigItem>();

    /**
     * Fires on delete/deleteAll Error page list.
     */
    @Output()
    public onDeleteErrorPage = new EventEmitter<ErrorPageConfigItem>();

    /**
     * TemplateRef for Expand Error Status Codes column.
     */
    @ViewChild('expandedErrorStatusCodeTemplateRef')
    public expandedErrorStatusCodeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for enabled status for column.
     */
    @ViewChild('enabledTemplateRef')
    public enabledTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Error pages grid config.
     */
    public errorPageListGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const {
            stringService,
            l10nService,
        } = this;

        this.errorPageListGridConfig = {
            getRowId(index: number, row: ErrorPageConfigItem): number {
                return row.pageIndex;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.indexLabel),
                    id: 'index',
                    transform({ config }) {
                        return config.index || 0;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.errorPageLabel),
                    id: 'error_redirect',
                    transform({ config }) {
                        return config.error_redirect ?
                            l10nService.getMessage(l10nKeys.redirectLabel) :
                            l10nService.getMessage(l10nKeys.htmlBodyLabel);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.errorPageOrUrlLabel),
                    id: 'error_redirect',
                    transform(row: ErrorPageConfigItem) {
                        const {
                            error_page_body_ref: errorPageBodyRef,
                            error_redirect: errorRedirect,
                        } = row.config;

                        return errorPageBodyRef ?
                            stringService.name(errorPageBodyRef) :
                            errorRedirect;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.enableLabel),
                    id: 'enable',
                    templateRef: this.enabledTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    onClick: (errorPageList: ErrorPageConfigItem[]) => {
                        errorPageList.forEach((errorPage: ErrorPageConfigItem) => {
                            this.deleteErrorPage(errorPage);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: l10nService.getMessage(l10nKeys.removeLabel),
                    shape: 'trash',
                    onClick: (errorPage: ErrorPageConfigItem) => {
                        this.deleteErrorPage(errorPage);
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (errorPage: ErrorPageConfigItem) => {
                        this.editErrorPage(errorPage);
                    },
                },
            ],
            expandedContentTemplateRef: this.expandedErrorStatusCodeTemplateRef,
        };
    }

    /**
     * Handler for Add Error Page button.
     */
    public addErrorPage(): void {
        this.onAddErrorPage.emit();
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Handler for Error Page edit.
     */
    private editErrorPage(errorPage: ErrorPageConfigItem): void {
        this.onEditErrorPage.emit(errorPage);
    }

    /**
     * Deletes Error Page from ErrorPageProfile.
     */
    private deleteErrorPage(errorPage: ErrorPageConfigItem): void {
        this.onDeleteErrorPage.emit(errorPage);
    }
}
