/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'backup-page';
const prefix = `${moduleName}.${componentName}`;

export const configurationBackupLabel = `${prefix}.configurationBackupLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleLocalFile = `${prefix}.columnTitleLocalFile`;
export const emptyDataObject = `${prefix}.emptyDataObject`;
export const columnTitleRemoteFile = `${prefix}.columnTitleRemoteFile`;
export const backupsGridPlaceholderLabel = `${prefix}.backupsGridPlaceholderLabel`;

export const ENGLISH = {
    [configurationBackupLabel]: 'Configuration Backup',
    [editButtonLabel]: 'Edit',
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleLocalFile]: 'Local File',
    [emptyDataObject]: 'N/A',
    [columnTitleRemoteFile]: 'Remote File',
    [backupsGridPlaceholderLabel]: 'No backups found.',
};
