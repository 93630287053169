/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VSModule
 */

import { L10nService } from '@vmw/ngx-vip';
import * as Regex from 'ng/utils/regex.utils';
import { VHMatchConfigItem } from 'ajs/modules/vs/factories/vh-match.config-item.factory';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import template from './vs-enhanced-vh-child-hosts.component.html';
import './vs-enhanced-vh-child-hosts.component.less';
import * as l10n from './vs-enhanced-vh-child-hosts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TRegex = typeof Regex;

class VSEnhancedVHChildHostsController {
    /**
     * VirtualService item. TODO: change "any" to the VirtualService class once it's been converted
     * to TS.
     */
    public vs: any;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        public regex: TRegex,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Edit Handler for VH Match.
     */
    public editVHMatch(vhMatch: VHMatchConfigItem): void {
        this.vs.editVHMatch(vhMatch);
    }

    /**
     * Add Handler for VH Match.
     */
    public addVHMatch(): void {
        this.vs.addVHMatch();
    }

    /**
     * Delete Handler for VH Match.
     */
    public deleteVHMatch(vhMatch: VHMatchConfigItem): void {
        this.vs.deleteVHMatch(vhMatch);
    }

    /**
     * Called to add a PathMatch to a VH Match.
     */
    public addPathMatch(matchIndex: number): void {
        this.vs.addEnhancedVHPathMatch(matchIndex);
    }

    /**
     * Returns the list of VH Match.
     */
    public get vhMatches(): RepeatedMessageItem<VHMatchConfigItem> {
        return this.vs.getConfig().vh_matches;
    }
}

VSEnhancedVHChildHostsController.$inject = [
    'Regex',
    'l10nService',
];

/**
 * @description Component for configuring VH Enhanced Child VS settings. Contains a repeated list of
 *     host and path match pairs.
 * @author alextsg
 */
export const vsEnhancedVHChildHostsOptions = {
    bindings: {
        vs: '<',
    },
    controller: VSEnhancedVHChildHostsController,
    template,
};
