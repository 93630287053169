/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cportal';
const componentName = 'system-proxy';
const prefix = `${moduleName}.${componentName}`;

export const serverHostLabel = `${prefix}.serverHostLabel`;
export const serverHostPlaceholder = `${prefix}.serverHostPlaceholder`;
export const portLabel = `${prefix}.portLabel`;
export const portPlaceholder = `${prefix}.portPlaceholder`;
export const userNameLabel = `${prefix}.userNameLabel`;
export const usernamePlaceholder = `${prefix}.usernamePlaceholder`;
export const passwordLabel = `${prefix}.passwordLabel`;
export const passwordPlaceholder = `${prefix}.passwordPlaceholder`;

export const ENGLISH = {
    [serverHostLabel]: 'Server Host',
    [serverHostPlaceholder]: 'Enter Server Host',
    [portLabel]: 'Port',
    [portPlaceholder]: 'Enter Port Number',
    [userNameLabel]: 'Username',
    [usernamePlaceholder]: 'Enter Username',
    [passwordLabel]: 'Password',
    [passwordPlaceholder]: 'Enter Password',
};
