/**
 * @module NotificationsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';

interface IControllerFaultDetails {
    description: string;
    name: string;
    status: string;
    valid_until: string;
}

interface IControllerFault {
    type: string;
    faults: IControllerFaultDetails[];
    // Name actually does not exist in ControllerFault item config.
    // but setting this property to make it compatible with IItemGenericConfig
    // since a controller fault is not a real Item.
    name?: string;
}

interface IControllerFaultData {
    config: IControllerFault;
}

/**
 * @description Controller Faults Item.
 * @author Alex Tseung, Alex Malitsky, Aravindh Nagarajan
 */
export class ControllerFault extends Item<IControllerFault> {
    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected getIdFromData_(data: IControllerFaultData): string {
        const { config } = data;

        return config?.type || '';
    }
}

Object.assign(ControllerFault.prototype, {
    objectName: 'controller-inventory',
});
