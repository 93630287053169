/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'login';
const componentName = 'admin-user-setup';
const prefix = `${moduleName}.${componentName}`;

export const passwordFieldPlaceholder = `${prefix}.passwordFieldPlaceholder`;
export const confirmPasswordFieldPlaceholder = `${prefix}.confirmPasswordFieldPlaceholder`;
export const emailFieldPlaceholder = `${prefix}.emailFieldPlaceholder`;
export const createAccountButtonLabel = `${prefix}.createAccountButtonLabel`;
export const samePasswordUsernameErrorMessage = `${prefix}.samePasswordUsernameErrorMessage`;

export const ENGLISH = {
    [passwordFieldPlaceholder]: 'Password',
    [confirmPasswordFieldPlaceholder]: 'Confirm Password',
    [emailFieldPlaceholder]: 'Email Address (Optional)',
    [createAccountButtonLabel]: 'Create Account',
    [samePasswordUsernameErrorMessage]: 'Password should not be the same as username',
};
