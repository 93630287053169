/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-service';
const prefix = `${moduleName}.${componentName}`;

export const welcomeLabel = `${prefix}.welcomeLabel`;

export const ENGLISH = {
    [welcomeLabel]: 'Welcome',
};
