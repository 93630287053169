/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    ErrorPageBodyCollection,
} from 'ajs/modules/error-page';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid';

import * as l10n from './error-page-body.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TErrorPageBodyCollection = typeof ErrorPageBodyCollection;

/**
 * @description Error Page Body list page.
 *
 * @author tmaridi
 */
@Component({
    selector: 'error-page-body',
    templateUrl: './error-page-body.component.html',
})
export class ErrorPageBodyComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the error page body collection.
     */
    public errorPageBodyCollection: ErrorPageBodyCollection;

    /**
     * Configuration object to display error page body collection.
     */
    public errorPageBodyGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(ErrorPageBodyCollection)
        private readonly ErrorPageBodyCollection: TErrorPageBodyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.errorPageBodyCollection = new ErrorPageBodyCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.errorPageBodyCollection;

        this.errorPageBodyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.errorPageBodyCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                transform: row => row.getName(),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
            },
            getRowId: (index, row: any) => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.errorPageBodyCollection.destroy();
    }
}
