/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { GEO_DB_COLLECTION_TOKEN } from 'ajs/modules/geo-db';
import * as globalL10n from 'global-l10n';
import * as l10n from './SecurityPolicy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

angular.module('aviApp').directive('securityPolicy', [
'Regex',
'RangeParser',
'$timeout',
'$templateCache',
'defaultValues',
'HTTPRedirectAction',
'PolicyGridConfig',
'AviConfirmService',
'schemaService',
'IPReputationDBCollection',
'dropDownUtils',
'l10nService',
GEO_DB_COLLECTION_TOKEN,
function(
    Regex,
    RangeParser,
    $timeout,
    $templateCache,
    defaultValues,
    HTTPRedirectAction,
    PolicyGridConfig,
    AviConfirmService,
    schemaService,
    IPReputationDBCollection,
    dropDownUtils,
    l10nService,
    GeoDbCollection,
) {
    l10nService.registerSourceBundles(dictionary);

    const USER_DEFINED_BOT = 'USER_DEFINED_BOT';
    const BOT_DETECTION_RESULT = 'bot_detection_result';

    function link(scope, elm) {
        /**
         * Get keys from source bundles for template usage
         */
        scope.l10nKeys = l10nKeys;
        scope.globalL10nKeys = globalL10nKeys;

        scope.Regex = Regex;
        scope.RangeParser = RangeParser;

        scope.httpLocalResponseStatusCodeEnumValues = schemaService
            .getEnumValues('HTTPLocalResponseStatusCode');
        scope.httpProtocolEnumKeys = schemaService
            .getEnumKeys('HTTPProtocol');
        scope.httpVersionEnumValues = schemaService
            .getEnumValues('HTTPVersion');

        scope.rule2EditMode = function(rule) {
            // Convert matches
            rule.matchEdit = [];
            _.each(rule.match, function(match, id) {
                if (match instanceof Array) {
                    _.each(match, function(m) {
                        if (scope.matches[id].init) {
                            scope.matches[id].init(m);
                        }

                        rule.matchEdit.push({
                            id,
                            value: m,
                        });
                    });
                } else {
                    if (scope.matches[id].init) {
                        scope.matches[id].init(match);
                    }

                    rule.matchEdit.push({
                        id,
                        value: match,
                    });
                }
            });

            rule.save = function() {
                scope.saveRule();
            };

            if (rule.action && rule.action.rate_profile && rule.action.rate_profile.action &&
                rule.action.rate_profile.action.redirect) {
                HTTPRedirectAction.beforeEdit(rule.action.rate_profile.action.redirect);
            }
        };

        scope.rule2ReadMode = function(rule) {
            // Convert matches back
            rule.match = {};
            _.map(rule.matchEdit, function(match) {
                if (scope.matches[match.id].onSubmit) {
                    scope.matches[match.id].onSubmit(match.value);
                }

                if (match.id == 'hdrs') {
                    if (!rule.match[match.id] || !(rule.match[match.id] instanceof Array)) {
                        rule.match[match.id] = [];
                    }

                    rule.match[match.id].push(match.value);
                } else {
                    rule.match[match.id] = match.value;
                }
            });
            delete rule.matchEdit;
            delete rule.save;
        };

        /**
         * @type {module:avi/vs.IPReputationDBCollection}
         */
        scope.ipReputationDBCollection = new IPReputationDBCollection();

        /**
         * Options for IPReputationType enum dropdown.
         * Used when IPReputationType match is selected.
         * @type {DropDownOption[]}
         */
        scope.ipReputationTypeOptions =
            dropDownUtils.getEnumDropdownOptions('IPReputationType');

        /**
         * @type {module:avi/vs.GeoDbCollection}
         */
        scope.geoDbCollection = new GeoDbCollection();

        /**
         * Creates a new rule.
         * @param {Object=} toPosition - Determines where to place the new rule.
         * @param {string} toPosition.position - 'above' or 'below' a specified index.
         * @param {number} toPosition.index - Existing index used as reference for position.
         */
        scope.addRule = function(toPosition) {
            const { config } = scope.httpSecurityPolicy;

            scope.current = {
                enable: true,
                name: `Rule ${config && config.rules ? config.rules.length + 1 : 1}`,
                action: {
                    status_code: 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200',
                },
                matchEdit: [],
                _toPosition: toPosition,
            };
            scope.rule2EditMode(scope.current);
        };

        /**
         * Duplicates an existing rule and allows for editing.
         * @param {Object} rule - Rule config.
         * @param {Object} toData - Contains position and index properties.
         * @param {string} toData.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} toData.index - New index to be moved to.
         */
        scope.duplicateRule = function(rule, toPosition) {
            scope.current = angular.extend(angular.copy(rule), {
                name: l10nService.getMessage(l10nKeys.ruleNameDuplicatedMessage, [rule.name]),
                _toPosition: toPosition,
            });

            scope.current.index = undefined;
            scope.rule2EditMode(scope.current);
        };

        scope.actionChanged = function() {
            const { action } = scope.current;

            delete action.status_code;
            delete action.https_port;
            delete action.file;
            delete action.rate_profile;

            switch (action.action) {
                case 'HTTP_SECURITY_ACTION_SEND_RESPONSE':
                    action.status_code = 'HTTP_LOCAL_RESPONSE_STATUS_CODE_200';
                    break;

                case 'HTTP_SECURITY_ACTION_REDIRECT_TO_HTTPS':
                    action.https_port = 443;
                    break;

                case 'HTTP_SECURITY_ACTION_RATE_LIMIT':
                    action.rate_profile =
                        defaultValues.getDefaultItemConfig('httpsecurityrule.action.rate_profile');
                    break;
            }
        };

        /**
         * Called when Rate Limiter Action dropdown value has been changed.
         */
        scope.setRateLimiterAction = function() {
            const
                { action } = scope.current.action.rate_profile,
                redirect = defaultValues.getDefaultItemConfig(
                    'httpsecurityrule.action.rate_profile.action.redirect',
                );

            if (action.type === 'RL_ACTION_REDIRECT') {
                action.redirect = angular.copy(redirect);
                action.redirect.protocol = 'HTTP';
                action.redirect.port = 80;
            }
        };

        /**
         * Gets the RateLimiterActionType enums from Schema with the L4 enums filtered out.
         * @return {String[]} Array of RateLimiterActionType enums.
         */
        scope.getRateLimiterActions = function() {
            const actions = schemaService.getEnumKeys('RateLimiterActionType');

            return actions.filter(function(type) {
                return type !== 'RL_ACTION_DROP_CONN' && type !== 'RL_ACTION_RESET_CONN';
            });
        };

        scope.editRule = function(rule) {
            scope.current = angular.copy(rule);
            scope.rule2EditMode(scope.current);
        };

        scope.saveRule = function() {
            if (!scope.current) {
                return;
            }

            if (checkForEmptyBotManagementRule(scope.current.matchEdit)) {
                scope.error = l10nService.getMessage(l10nKeys.invalidBotManagemntValues);

                return;
            }

            let { config } = scope.httpSecurityPolicy;

            if (!config) {
                config = {
                    rules: [],
                };

                scope.httpSecurityPolicy.config = config;
            }

            if (!Array.isArray(config.rules)) {
                config.rules = [];
            }

            // Make sure there is no rule with the same name
            scope.error = null;

            if (_.any(config.rules, function(rule) {
                return rule.name == scope.current.name && rule.index != scope.current.index;
            })) {
                scope.error = l10nService.getMessage(l10nKeys.ruleNameAlreadyInUseMessage);

                return;
            }

            const { action } = scope.current;

            if (action.action === 'HTTP_SECURITY_ACTION_SEND_RESPONSE') {
                delete action.https_port;
                delete action.rate_profile;
            } else if (action.action === 'HTTP_SECURITY_ACTION_REDIRECT_TO_HTTPS') {
                delete action.status_code;
                delete action.rate_profile;
            } else if (action.action === 'HTTP_SECURITY_ACTION_RATE_LIMIT') {
                delete action.https_port;
                delete action.status_code;

                if (action.rate_profile.action.type !== 'RL_ACTION_REDIRECT') {
                    delete action.rate_profile.action.redirect;
                }

                if (action.rate_profile.action.type !== 'RL_ACTION_LOCAL_RSP') {
                    delete action.rate_profile.action.status_code;
                    delete action.rate_profile.action.file;

                    if (action.rate_profile.action.type === 'RL_ACTION_REDIRECT') {
                        HTTPRedirectAction.beforeSave(action.rate_profile.action.redirect);
                    }
                }
            }

            scope.rule2ReadMode(scope.current);

            if (!_.isUndefined(scope.current.index)) {
                angular.copy(scope.current, _.find(config.rules, function(item) {
                    return item.index == scope.current.index;
                }));
            } else {
                scope.current.index = _.max(config.rules, function(i) {
                    return i.index;
                }).index + 1 || 1;
                config.rules.push(scope.current);
            }

            if (angular.isObject(scope.current._toPosition)) {
                scope.httpSecurityPolicy.moveRule(scope.current, scope.current._toPosition);
            }

            scope.current = null;
        };

        /**
         * Appends a match to the current rule
         * @param type - Match type
         */
        scope.addMatch = function(type, event) {
            if (!scope.current || !scope.matches[type]) {
                return;
            }

            scope.current.matchEdit.push({
                id: type,
                index: scope.current.matchEdit.length,
                value: angular.copy(scope.matches[type].default),
            });

            // Scroll down
            const curOffsetTop = $('.new-match-list').offset().top;

            $timeout(function() {
                if (!$('.new-match-list').length) {
                    return;
                }

                const scrollable = $(elm).closest('.scrollable');

                scrollable.animate({
                    scrollTop: $(scrollable).scrollTop() +
                        ($('.new-match-list').offset().top - curOffsetTop),
                });
            });
        };

        /**
         * Deletes the match from the current rule
         * @param {Object} matchOrMatchValue - the type of the match
         */
        scope.deleteMatch = function(matchOrMatchValue) {
            const index = _.findIndex(scope.current.matchEdit,
                item => item === matchOrMatchValue || item.value === matchOrMatchValue);

            if (index !== -1) {
                scope.current.matchEdit.splice(index, 1);
            }
        };

        // Used to filter out the rules that exist in detail.rules
        scope.matchNotUsed = function(match) {
            if (match == 'hdrs') {
                return true;
            }

            if (scope.current && scope.current.matchEdit) {
                const found = _.find(scope.current.matchEdit, function(item) {
                    return item.id == match;
                });

                if (found) {
                    return false;
                }
            }

            return true;
        };

        scope.object2Array = function(obj) {
            const arr = [];

            _.each(obj, function(item, key) {
                arr.push({
                    id: key,
                    data: item,
                });
            });

            return arr;
        };

        scope.removeHeaderMatch = function(match, index) {
            match.value.splice(index, 1);

            if (!match.value.length) {
                scope.deleteMatch(match.id);
            }
        };

        scope.matches = {
            client_ip: {
                name: l10nService.getMessage(globalL10nKeys.clientIpLabel),
                default: {
                    match_criteria: 'IS_IN',
                    _tmp: [{
                        type: 'address',
                        data: '',
                    }],
                    addrs: [],
                    ranges: [],
                },
                stringify(m) {
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, function(item) {
                            val.push(item.addr);
                        });
                    }

                    if (m.ranges) {
                        _.each(m.ranges, function(item) {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, function(item) {
                            val.push(`${item.ip_addr.addr}/${item.mask}`);
                        });
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });
                    }

                    return `${
                        schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                    } (${val.join(', ')})`;
                },
            },
            vs_port: {
                name: l10nService.getMessage(l10nKeys.servicePortMatchLabel),
                default: {
                    match_criteria: 'IS_IN',
                    ports: [],
                },
                stringify(m) {
                    return m.ports.join(', ');
                },
            },
            protocol: {
                name: l10nService.getMessage(l10nKeys.protocolTypeMatchLabel),
                default: {
                    match_criteria: 'IS_IN',
                    protocols: 'HTTP',
                },
                stringify(m) {
                    return m.protocols;
                },
            },
            method: {
                name: l10nService.getMessage(globalL10nKeys.httpMethodLabel),
                default: {
                    match_criteria: 'IS_IN',
                    methods: [],
                },
                stringify(m) {
                    return `${
                        schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                    } (${
                        _.map(m.methods, function(item) {
                            return item.replace(/^HTTP_METHOD_/g, '');
                        }).join(', ')})`;
                },
            },
            version: {
                name: l10nService.getMessage(globalL10nKeys.httpVersionLabel),
                default: {
                    match_criteria: 'IS_IN',
                    versions: [], // {version: 'ZERO_NINE'}
                    _tmp: {},
                },
                init(m) {
                    m._tmp = {};
                    _.each(m.versions, function(v) {
                        m._tmp[v] = true;
                    });
                },
                onSubmit(m) {
                    m.versions = [];
                    _.each(m._tmp, function(value, version) {
                        if (value) {
                            m.versions.push(
                                version,
                            );
                        }
                    });
                },
                stringify(m) {
                    return _.map(m.versions, item => {
                        return schemaService.getEnumValue('HTTPVersion', item).label;
                    }).join(', ');
                },
            },
            path: {
                name: l10nService.getMessage(globalL10nKeys.pathLabel),
                default: {
                    match_criteria: 'CONTAINS',
                    match_decoded_string: true,
                    match_str: [],
                    _tmp: [{
                        type: 'custom',
                        data: '',
                    }],
                },
                stringify(m) {
                    const val = [];

                    Array.prototype.push.apply(val, m.match_str);

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `${
                        schemaService.getEnumValue('StringOperation', m.match_criteria).label
                    } (${val.join(', ')})`;
                },
            },
            query: {
                name: l10nService.getMessage(globalL10nKeys.queryLabel),
                default: {
                    match_criteria: 'QUERY_MATCH_CONTAINS',
                    match_decoded_string: true,
                    match_str: [],
                    _tmp: [{
                        type: 'custom',
                        data: '',
                    }],
                },
                stringify(m) {
                    const val = [];

                    _.each(m.match_str, function(str) {
                        val.push(`"${str}"`);
                    });

                    _.each(m.string_group_refs, function(item) {
                        val.push(`group ${item.name()}` || item.slug());
                    });

                    return `contains ${val.join(' or ')}`;
                },
            },
            hdrs: {
                name: l10nService.getMessage(globalL10nKeys.headersLabel),
                default: {
                    match_criteria: 'HDR_EXISTS',
                    hdr: '',
                    value: [],
                },
                stringify(hdrs) {
                    return _.map(hdrs, function(hdr) {
                        const { label } = schemaService
                            .getEnumValue('HdrMatchOperation', hdr.match_criteria);

                        return `${hdr.hdr} ${label || hdr.match_criteria
                        }${hdr.match_criteria == 'HDR_CONTAINS' ?
                            ` ${hdr.value.join(' or ')}` : ''}`;
                    }).join(', ');
                },
            },
            cookie: {
                name: l10nService.getMessage(globalL10nKeys.cookieLabel),
                default: {
                    match_criteria: 'HDR_EXISTS',
                    name: '',
                    value: '',
                },
                stringify(m) {
                    const { label } = schemaService
                        .getEnumValue('HdrMatchOperation', m.match_criteria);

                    return `${m.name} ${label.toLowerCase()} ${m.value}`;
                },
            },
            host_hdr: {
                name: l10nService.getMessage(globalL10nKeys.hostHeaderLabel),
                default: {
                    match_criteria: 'HDR_EQUALS',
                    value: [''],
                },
                init(m) {
                    if (m.match_criteria != 'HDR_EXISTS' &&
                        m.match_criteria != 'HDR_DOES_NOT_EXIST' &&
                        (!m.value || !m.value.length)) {
                        m.value = [''];
                    }
                },
                onSubmit(m) {
                    if (m.match_criteria == 'HDR_EXISTS' ||
                        m.match_criteria == 'HDR_DOES_NOT_EXIST') {
                        delete m.value;
                    }
                },
                stringify(m) {
                    return `${
                        schemaService.getEnumValue('HdrMatchOperation', m.match_criteria).label
                    } '${m.value.join('\' or \'')}'`;
                },
            },
            ip_reputation_type: {
                name: l10nService.getMessage(globalL10nKeys.ipReputationLabel),
                default: {
                    match_operation: 'IS_IN',
                    reputation_types: [],
                },
            },
            bot_detection_result: {
                name: l10nService.getMessage(l10nKeys.botManagementLabel),
                default: {
                    match_operation: 'IS_IN',
                    classifications: [],
                },
            },
            source_ip: {
                name: l10nService.getMessage(l10nKeys.sourceIpMatchLabel),
                default: {
                    match_criteria: 'IS_IN',
                    _tmp: [{
                        type: 'address',
                        data: '',
                    }],
                    addrs: [],
                    ranges: [],
                },
                stringify(m) {
                    const val = [];

                    if (m.addrs) {
                        _.each(m.addrs, function(item) {
                            val.push(item.addr);
                        });
                    }

                    if (m.ranges) {
                        _.each(m.ranges, function(item) {
                            val.push(`${item.begin.addr}-${item.end.addr}`);
                        });
                    }

                    if (m.prefixes) {
                        _.each(m.prefixes, function(item) {
                            val.push(`${item.ip_addr.addr}/${item.mask}`);
                        });
                    }

                    if (m.group_refs) {
                        _.each(m.group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });
                    }

                    return `${
                        schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                    } (${val.join(', ')})`;
                },
            },
        };

        scope.onLogTypeChange = function(switchedTo) {
            const currentRule = scope.current;

            switch (switchedTo) {
                case 'with-headers':
                    currentRule.log = true;
                    currentRule.all_headers = true;
                    break;

                case 'on':
                    currentRule.log = true;
                    currentRule.all_headers = false;
                    break;

                default:
                    currentRule.log = false;
                    currentRule.all_headers = false;
            }
        };

        scope.matchKeys = Object.keys(scope.matches);

        scope.httpSecurityPolicyGridConfig = new PolicyGridConfig({
            collection: scope.httpSecurityPolicy,
            controls: {
                create: {
                    title: l10nService.getMessage(globalL10nKeys.createRuleLabel),
                    do: () => {
                        const { rows } = scope.httpSecurityPolicy;
                        const index = angular.isArray(rows) ?
                            rows.length && rows[rows.length - 1].index || 0 :
                            0;

                        scope.addRule({
                            index,
                            position: 'below',
                        });
                    },
                },
            },
            actions: {
                createAt: ({ index }, position) => scope.addRule({
                    index,
                    position,
                }),
            },
            singleactions: [{
                title: l10nService.getMessage(globalL10nKeys.deleteLabel),
                class: 'icon icon-trash',
                do: rule => scope.httpSecurityPolicy.delete(rule),
            }, {
                title: l10nService.getMessage(globalL10nKeys.menuLabel),
                template: require(
                    '../../components/applications/virtualservice/policy/' +
                    'policy-grid/policy-grid-menu.tooltip.partial.html',
                ),
                edit: rule => scope.editRule(rule),
                move: rule => {
                    const rules = scope.httpSecurityPolicy.rows;

                    AviConfirmService
                        .prompt('policy-grid-prompt-index', {
                            rule,
                            rules,
                        })
                        .then(data => scope.httpSecurityPolicy.moveRule(rule, data));
                },
                duplicate: rule => {
                    AviConfirmService
                        .prompt(
                            'policy-grid-prompt-index', { rules: scope.httpSecurityPolicy.rows },
                        )
                        .then(data => scope.duplicateRule(rule, data));
                },
            }],
        });

        /**
         * Checks if user has not selected any value from Classifications dropdown.
         * This method is added as there is no validation for new Angular grid in ajs components.
         * @returns {Boolean}
         * Returns true if user clicks on add row but does not select any type from dropdown.
         * TODO: This can be removed once VS Create modal is migrated to new Angular.
         */
        function checkIfTypeIsEmpty(classifications) {
            return Boolean(classifications.find(
                classification => classification.type === '',
            ));
        }

        /**
         * Checks if user has selected User Defined Bot and not entered any value.
         * This method is added as there is no validation for new Angular grid in ajs components.
         * @returns {Boolean}
         * Returns true if user selects User Defined Bot from Dropdown but
         * does not provide any value for this type of bot.
         * TODO: This can be removed once VS Create modal is migrated to new Angular.
         */
        function checkIfUserDefinedBotIsEmpty(classifications) {
            const userDefinedBotMatches = classifications.filter(
                classification => classification.type === USER_DEFINED_BOT,
            );

            return userDefinedBotMatches.length && Boolean(userDefinedBotMatches.find(
                userDefinedBotMatch => !userDefinedBotMatch.user_defined_type,
            ));
        }

        /**
         * This method is added as there is no validation for new Angular grid in ajs components.
         * TODO: This can be removed once VS Create modal is migrated to new Angular.
         * @returns {boolean} True if user tries to save without adding any entry in the grid or
         * if user tries to save with empty row or
         * selects user defined bot from dropdown but does not enter the value for user defined bot.
         */
        function checkForEmptyBotManagementRule(matchEdit) {
            const botManagementMatch = matchEdit.find(
                match => match.id === BOT_DETECTION_RESULT,
            );

            if (botManagementMatch) {
                return (
                    !botManagementMatch.value.classifications.length ||
                    checkIfTypeIsEmpty(botManagementMatch.value.classifications) ||
                    checkIfUserDefinedBotIsEmpty(botManagementMatch.value.classifications)
                );
            } else {
                return false;
            }
        }

        /**
         * Listens for $destroy event and destroy ipReputation and geoDb collection instance.
         */
        scope.$on('$destroy', () => {
            scope.ipReputationDBCollection.destroy();
            scope.geoDbCollection.destroy();
        });
    }

    return {
        scope: {
            httpSecurityPolicy: '=',
            httpPolicySet: '<',
            current: '=',
            services: '=',
            readonly: '@',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/security-policy.html',
        link,
    };
}]);
