/**
 * @module WelcomeModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IEmailConfiguration } from 'generated-types';
import * as l10n from './welcome-email-smtp-server-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *     Component for SMTP Email Server configuration.
 *     Used in Welcome Modal -> Email panel.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'welcome-email-smtp-server-settings',
    templateUrl: './welcome-email-smtp-server-settings.component.html',
})
export class WelcomeEmailSmtpServerSettingsComponent {
    /**
     * EmailConfig object.
     */
    @Input()
    public emailConfig: IEmailConfiguration;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
