/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('logBarChartTooltip', ['$timeout', function($timeout) {
    return function() {
        const self = this;
        const avgWidth = 170; //for initial positioning

        let
            tooltip,
            carat,
            hideTimeout;

        const hide = function() {
            clearHideTimeout();

            if (tooltip) {
                tooltip.hide();
            }

            if (carat) {
                carat.hide();
            }
        };

        const countLeftPos = function(center, width) {
            width = width || tooltip.outerWidth();

            let left = center - width / 2;

            left = Math.min($(window).width() - width - 15, Math.max(15, left));

            //console.log('tooltipLeftPos, center:%i, width:%i, left:%i', center, width, left);
            return Math.round(left);
        };

        const clearHideTimeout = function() {
            if (hideTimeout) {
                $timeout.cancel(hideTimeout);
                hideTimeout = false;
            }
        };

        this.show = function(pos, html) {
            clearHideTimeout();

            if (!tooltip) {
                tooltip = $('<div/>')
                    .addClass('graph-tooltip logBarChart')
                    .on('mouseleave', function() {
                        //self.onMouseLeave();
                        self.hide();//pass 50ms?
                    })
                    .appendTo('body');

                carat = $('<div/>')
                    .addClass('logBarchartCarat')
                    .appendTo('body');
            }

            tooltip
                .html(html)
                .css({
                    bottom: pos.bottom,
                    left: countLeftPos(pos.center, avgWidth),
                });

            carat.css({
                bottom: pos.bottom,
                left: pos.center - 10,
            });

            if (!self.isVisible()) {
                tooltip.show();
                carat.show();
            }

            tooltip.css({ left: countLeftPos(pos.center) });
        };

        /* if passed element is a child of tooltip or carat or tooltip or carat itself. */
        this.has = function(elem) {
            return tooltip && carat &&
                (elem === carat[0] || elem === tooltip[0] || tooltip.has(elem).length ||
                carat.has(elem).length);
        };

        this.isVisible = function() {
            return tooltip.is(':visible');
        };

        this.hide = function() {
            clearHideTimeout();
            hideTimeout = $timeout(hide, 99);
        };

        //this.onMouseLeave = function(){};

        this.remove = function() {
            clearHideTimeout();

            if (tooltip) {
                tooltip.remove();
            }

            if (carat) {
                carat.remove();
            }

            tooltip = false;
            carat = false;
        };
    };
}]);
