/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'dialog';
const componentName = 'json-viewer';
const prefix = `${moduleName}.${componentName}`;

export const downloadConfigTitle = `${prefix}.downloadConfigTitle`;
export const dialogTitle = `${prefix}.dialogTitle`;
export const copyConfigToClipboardTitle = `${prefix}.copyConfigToClipboardTitle`;
export const configPreviewedNotSavedAlertMessage = `${prefix}.configPreviewedNotSavedAlertMessage`;
export const showDefaultsLabel = `${prefix}.showDefaultsLabel`;

export const ENGLISH = {
    [dialogTitle]: 'JSON Viewer: {0}',
    [downloadConfigTitle]: 'Download config as JSON',
    [copyConfigToClipboardTitle]: 'Copy config to clipboard',
    [configPreviewedNotSavedAlertMessage]: 'Changes being previewed have not been saved. Save configuration for changes to take effect.',
    [showDefaultsLabel]: 'Show Defaults',
};
