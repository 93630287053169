/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { L10nService } from '@vmw/ngx-vip';
import {
    AviDataGridFieldVisibility,
    IAviDataGridConfig,
    IAviDataGridConfigField,
    IAviDataGridRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import {
    IAviDynamicDataGridSortingInfo,
    TAviDataGridPaginationInfo,
} from 'ng/modules/data-grid/components/avi-dynamic-data-grid/avi-dynamic-data-grid.types';
import {
    RecommendationDialogService,
} from 'ng/modules/logs/services/recommendation-dialog.service';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { FormatBytesPipe } from 'ng/shared/pipes/format-bytes.pipe';
import { AviDatePipe } from 'ng/modules/core/pipes/avi-date.pipe';
import {
    IApplicationLog,
    IConnectionLog,
    WafStatusCode,
} from 'generated-types';
import * as globalL10n from 'global-l10n';
import { VsLogListStore } from './vs-log-list.store';
import { VsLogsStore } from '../../services/vs-logs.store';
import { VsLogsReloadService } from '../../services/vs-logs-reload.service';
import { VsLogsDownloadService } from '../../services/vs-logs-download.service';
import {
    FilterOperatorType,
    TLogEntrySignatureParams,
    TVsLog,
    TVsLogListStateParams,
    VsAppProfileType,
} from '../../vs-logs.types';
import { VsLogCinematicService } from '../../services/vs-log-cinematic.service';
import * as l10n from './vs-log-list.l10n';
import './vs-log-list.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Logs list component.
 * @author Akul Aggarwal, Alex Klyuev, Zhiqian Liu
 */
@Component({
    selector: 'vs-log-list',
    templateUrl: './vs-log-list.component.html',
    providers: [VsLogListStore],
})
export class VsLogListComponent implements OnDestroy, AfterViewInit {
    // ---------------------- TemplateRefs for List Fields ----------------------

    @ViewChild('wafFieldTemplateRef') public wafFieldTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('clientIpTemplateRef') public clientIpTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('clientPortTemplateRef') public clientPortTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('serverIpTemplateRef') public serverIpTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('serverPortTemplateRef') public serverPortTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('uriTemplateRef') public uriTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('protocolTemplateRef') public protocolTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('dnsRequestTypeTemplateRef')
    public dnsRequestTypeTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('domainNameTemplateRef') public domainNameTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('requestTemplateRef') public requestTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('responseTemplateRef') public responseTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('lengthTemplateRef') public lengthTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('durationTemplateRef') public durationTemplateRef: TemplateRef<HTMLElement>;
    @ViewChild('ednsSubnetTemplateRef') public ednsSubnetTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly FilterOperatorType = FilterOperatorType;

    public vsLogsListGridConfig: IAviDataGridConfig;

    /**
     * Subscription to properties that affect the data grid.
     */
    private dataGridPropsSubscription: Subscription;

    /**
     * Subscribe to changes in the logs API parameters. Get new logs data upon change.
     */
    private readonly logListApiParamsSubscription: Subscription =
    this.vsLogsStore.vsLogListApiParams$.subscribe((apiParams: TVsLogListStateParams) => {
        this.vsLogListStore.getVsLogsList(apiParams);
    });

    constructor(
        private readonly aviDatePipe: AviDatePipe,
        public readonly formatBytes: FormatBytesPipe,
        @Inject('$filter')
        public readonly timeFormatterService: any,
        private readonly l10nService: L10nService,
        private readonly VsLogCinematicService: VsLogCinematicService,
        private readonly recommendationDialogService: RecommendationDialogService,
        public readonly vsLogListStore: VsLogListStore,
        public readonly vsLogsStore: VsLogsStore,
        public readonly schemaService: SchemaService,
        public readonly stringService: StringService,
        vsLogsReloadService: VsLogsReloadService,
        vsLogsDownloadService: VsLogsDownloadService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        // Subscribe to Reload and Download services for associated actions.
        vsLogsReloadService.reload$.subscribe(() => vsLogListStore.reloadList());
        vsLogsDownloadService.downloadCurrentPage$.subscribe(
            () => vsLogListStore.downloadCurrentPage(),
        );
        vsLogsDownloadService.downloadSelectedLogs$.subscribe(
            () => vsLogListStore.downloadSelectedLogs(),
        );
    }

    /**
     * @override
     * Complete data grid config once templateRefs have initialized.
     */
    public ngAfterViewInit(): void {
        this.dataGridPropsSubscription = combineLatest([
            this.vsLogsStore.vsAppProfileType$,
            this.vsLogsStore.hasWafPolicy$,
        ]).subscribe(([vsAppProfileType, hasWafPolicy]: [VsAppProfileType, boolean]) => {
            this.initDataGrid(hasWafPolicy);
            this.setDataGridFields(vsAppProfileType, hasWafPolicy);
        });
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.logListApiParamsSubscription.unsubscribe();
        this.dataGridPropsSubscription.unsubscribe();

        if (this.recommendationDialogService.isOpen()) {
            this.recommendationDialogService.closeDialog();
        }
    }

    /**
     * Handle the user changing the table size or page.
     */
    public handlePaginationChange(pageInfo: TAviDataGridPaginationInfo): void {
        this.vsLogsStore.setTablePaginationProps(pageInfo.current, pageInfo.size);
    }

    /**
     * Update the store with log_id's that the user has selected.
     */
    public handleSelectionChange(rows: IAviDataGridRow[]): void {
        const logIds: number[] = [];

        rows.forEach(row => logIds.push(row.log_id));

        this.vsLogsStore.setSelectedLogs(logIds);
    }

    /**
     * Read sorting information upon grid state changes and perform sorting calls accordingly.
     * The list are returned sorted descendingly by default when no custom sortings are applied.
     */
    public handleSortingChange(sortingInfo: IAviDynamicDataGridSortingInfo): void {
        const { sortBy, reverse: descending } = sortingInfo;
        const secondarySortBy = 'report_timestamp';
        let sortByTerm = `${descending ? '-' : ''}${sortBy}`;

        // set 'report_timestamp' as a secondary sorting term, which means that when the list is
        // sorted by terms other than timestamp, entries sharing the same values of the
        // sorted property are then arranged by the timestamp, descendingly
        if (sortBy !== secondarySortBy) {
            sortByTerm += `,-${secondarySortBy}`;
        }

        this.vsLogsStore.setTableSortingProps(sortByTerm);
    }

    /**
     * Get edns subnet value from a log.
     */
    // eslint-disable-next-line class-methods-use-this
    public getEdnsSubnet(
        log: IConnectionLog,
    ): { value: number | string, type: string } | undefined {
        if (
            log.dns_request &&
            log.dns_request.opt_record &&
            log.dns_request.opt_record.options
        ) {
            const { options } = log.dns_request.opt_record;

            const { subnet_ip6: subnetIp6, subnet_ip: subnetIp } = options[0];

            if (subnetIp6) {
                return {
                    value: subnetIp6,
                    type: 'dns_request.opt_record.options.subnet_ip6',
                };
            }

            if (subnetIp) {
                return {
                    value: subnetIp,
                    type: 'dns_request.opt_record.options.subnet_ip',
                };
            }
        }
    }

    /**
     * Open a cinematic view for a single log entry.
     */
    private openCinematicView(logEntryParams: TLogEntrySignatureParams): void {
        this.VsLogCinematicService.openCinematic(logEntryParams);
    }

    /**
     * Initialize data grid.
     */
    private initDataGrid(hasWafPolicy: boolean): void {
        const singleactions = [
            {
                label: this.l10nService.getMessage(globalL10nKeys.downloadLabel),
                shape: 'download',
                onClick: (log: TVsLog) => {
                    const { log_id: logId, service_engine: seId } = log;

                    this.vsLogsStore.downloadLogEntry({
                        logId,
                        seId,
                    });
                },
            },
            {
                label: this.l10nService.getMessage(l10nKeys.detailIconLabel),
                shape: 'eye',
                onClick: (log: TVsLog) => {
                    const { log_id: logId, service_engine: seId } = log;

                    this.openCinematicView({
                        logId,
                        seId,
                    });
                },
            },
        ];

        // add recommendation only for WAF VS
        if (hasWafPolicy) {
            let vsUuid: string;

            this.vsLogsStore.vsUuid$
                .pipe(take(1))
                .subscribe(id => vsUuid = id);

            const recommendationAction = {
                label: this.l10nService.getMessage(l10nKeys.recommendationLabel),
                shape: 'recommendation',
                onClick: (log: IApplicationLog) => {
                    const {
                        report_timestamp: reportTimestamp,
                        request_id: requestId,
                    } = log;

                    this.recommendationDialogService.openDialog(vsUuid, reportTimestamp, requestId);
                },
                disabled: (log: IApplicationLog) => {
                    const { waf_log: wafLog } = log;

                    if (wafLog) {
                        const { status } = wafLog;

                        return status !== WafStatusCode.FLAGGED &&
                            status !== WafStatusCode.REJECTED;
                    }

                    return true;
                },
            };

            singleactions.unshift(recommendationAction);
        }

        this.vsLogsListGridConfig = {
            getRowId: (index: number, log: TVsLog): number => log.log_id,
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
            singleactions,
        };
    }

    /**
     * Set list grid fields.
     */
    private setDataGridFields(vsAppProfileType: VsAppProfileType, hasWafPolicy: boolean): void {
        // Set individual AviDataGrid fields to simplify L4, L7, and DNS grid combinations
        const timestampField: IAviDataGridConfigField = {
            id: 'timestamp',
            sortBy: 'report_timestamp',
            label: this.l10nService.getMessage(l10nKeys.timestampLabel),
            transform: (log: TVsLog): string => (
                log.report_timestamp ?
                    this.aviDatePipe.transform(log.report_timestamp) :
                    '-'
            ),
        };

        const wafField: IAviDataGridConfigField = {
            id: 'waf',
            label: this.l10nService.getMessage(l10nKeys.wafLabel),
            templateRef: this.wafFieldTemplateRef,
        };

        const clientIpField: IAviDataGridConfigField = {
            id: 'client-ip',
            sortBy: 'client_ip',
            label: this.l10nService.getMessage(globalL10nKeys.clientIpLabel),
            templateRef: this.clientIpTemplateRef,
        };

        const clientPortField: IAviDataGridConfigField = {
            id: 'client-port',
            sortBy: 'client_src_port',
            label: this.l10nService.getMessage(l10nKeys.clientPortLabel),
            templateRef: this.clientPortTemplateRef,
        };

        const serverIpField: IAviDataGridConfigField = {
            id: 'server-ip',
            sortBy: 'server_ip',
            label: this.l10nService.getMessage(globalL10nKeys.serverIpLabel),
            templateRef: this.serverIpTemplateRef,
        };

        const serverPortField: IAviDataGridConfigField = {
            id: 'server-port',
            sortBy: 'server_dest_port',
            label: this.l10nService.getMessage(l10nKeys.serverPortLabel),
            templateRef: this.serverPortTemplateRef,
        };

        const uriField: IAviDataGridConfigField = {
            id: 'uri',
            sortBy: 'uri_path',
            label: this.l10nService.getMessage(l10nKeys.uriLabel),
            templateRef: this.uriTemplateRef,
        };

        const protocolField: IAviDataGridConfigField = {
            id: 'protocol',
            label: this.l10nService.getMessage(l10nKeys.protocolLabel),
            templateRef: this.protocolTemplateRef,
        };

        const dnsRequestTypeField: IAviDataGridConfigField = {
            id: 'dns-request-type',
            label: this.l10nService.getMessage(l10nKeys.dnsRequestTypeLabel),
            templateRef: this.dnsRequestTypeTemplateRef,
        };

        const domainNameField: IAviDataGridConfigField = {
            id: 'domain-name',
            label: this.l10nService.getMessage(l10nKeys.domainNameLabel),
            templateRef: this.domainNameTemplateRef,
        };

        const requestField: IAviDataGridConfigField = {
            id: 'request',
            sortBy: 'method',
            label: this.l10nService.getMessage(globalL10nKeys.requestLabel),
            templateRef: this.requestTemplateRef,
        };

        const responseField: IAviDataGridConfigField = {
            id: 'response',
            sortBy: 'response_code',
            label: this.l10nService.getMessage(globalL10nKeys.responseLabel),
            templateRef: this.responseTemplateRef,
        };

        const lengthField: IAviDataGridConfigField = {
            id: 'length',
            sortBy: 'response_length',
            label: this.l10nService.getMessage(l10nKeys.lengthLabel),
            templateRef: this.lengthTemplateRef,
        };

        const durationField: IAviDataGridConfigField = {
            id: 'duration',
            sortBy: 'total_time',
            label: this.l10nService.getMessage(l10nKeys.durationLabel),
            templateRef: this.durationTemplateRef,
        };

        const ednsSubnetField: IAviDataGridConfigField = {
            id: 'edns-subnet',
            label: this.l10nService.getMessage(l10nKeys.ednsSubnetLabel),
            visibility: AviDataGridFieldVisibility.OPTIONAL,
            templateRef: this.ednsSubnetTemplateRef,
        };

        // Set data grid fields based on VS App Profile Type.
        switch (vsAppProfileType) {
            case VsAppProfileType.L7:
                this.vsLogsListGridConfig.fields = [
                    timestampField,
                    clientIpField,
                    uriField,
                    requestField,
                    responseField,
                    lengthField,
                    durationField,
                ];

                if (hasWafPolicy) {
                    this.vsLogsListGridConfig.fields.splice(1, 0, wafField);
                }

                break;

            case VsAppProfileType.L4:
                this.vsLogsListGridConfig.fields = [
                    timestampField,
                    clientIpField,
                    clientPortField,
                    serverIpField,
                    serverPortField,
                    lengthField,
                    durationField,
                ];
                break;

            case VsAppProfileType.DNS:
                this.vsLogsListGridConfig.fields = [
                    timestampField,
                    clientIpField,
                    protocolField,
                    dnsRequestTypeField,
                    domainNameField,
                    responseField,
                    ednsSubnetField,
                ];
                break;
        }
    }
}
