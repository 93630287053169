/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains token used when registering as a downgraded service in AJS
 *
 * @author vgohil
 */

/**
 * Ajs dependency token for HSMGroup Download Certificate service.
 */
export const HSM_GROUP_DOWNLOAD_CERTIFICATE_MODAL_SERVICE_TOKEN =
    'hsmGroupDownloadCertificateModalService';

/**
 * Ajs dependency token for App Level Alerts service.
 */
export const APP_LEVEL_ALERTS_SERVICE_TOKEN = 'appLevelAlertsService';

/**
 * Ajs dependency token for Central License Alerts service.
 */
export const CENTRAL_LICENSE_ALERT_SERVICE_TOKEN = 'centralLicenseAlertsService';

/**
 * Ajs dependency token for Verify Cloud Connector User Host Modal service.
 */
export const VERIFY_CLOUD_CONNECTOR_USER_HOST_MODAL_SERVICE_TOKEN =
    'VerifyCloudConnectorUserHostModalService';

/*
 * Ajs dependency token for Recommendation Dialog service.
 */
export const RECOMMENDATION_DIALOG_SERVICE_TOKEN = 'recommendationDialogService';

/**
 * Ajs dependency token for the Virtual Service State service.
 */
export const VIRTUAL_SERVICE_STATE_SERVICE_TOKEN = 'virtualServiceStateService';
