/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & collection.
 *
 * @author Ratan Kumar
 */

/**
 * Dependency token for Analytics ProfileMessage Item.
 */
export const ANALYTICS_PROFILE_ITEM_TOKEN = 'AnalyticsProfileItem';

/**
 * Dependency token for Analytics Profile Collection.
 */
export const ANALYTICS_PROFILE_COLLECTION_TOKEN = 'AnalyticsProfileCollection';

/**
 * Dependency token for SensitiveLogProfileConfigItem.
 */
export const SENSITIVE_LOG_PROFILE_CONFIG_ITEM_TOKEN = 'SensitiveLogProfileConfigItem';

/**
 * Dependency token for SensitiveFieldRuleConfigItem.
 */
export const SENSITIVE_FIELD_RULE_CONFIG_ITEM_TOKEN = 'SensitiveFieldRuleConfigItem';
