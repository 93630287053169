/**
 * Module for all things gslb related.
 * @preferred
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { CoreModule } from 'ng/modules/core/core.module';

import {
    GslbCheckpointsModalComponent,
    GslbFederationCheckpointsListComponent,
    GslbGeoDbProfileEntriesGridComponent,
    GslbGeoDbProfileModalComponent,
    GslbGeoProfileListComponent,
} from '.';

import {
    federationCheckpointItemProvider,
    gslbGeoDbProfileCollectionProvider,
} from './ajs-upgraded-providers';

const gslbComponents = [
    GslbCheckpointsModalComponent,
    GslbFederationCheckpointsListComponent,
    GslbGeoDbProfileEntriesGridComponent,
    GslbGeoDbProfileModalComponent,
    GslbGeoProfileListComponent,
];

@NgModule({
    declarations: [
        ...gslbComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        CoreModule,
        DataGridModule,
        SharedModule,
    ],
    providers: [
        gslbGeoDbProfileCollectionProvider,
        federationCheckpointItemProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class GslbModule {}
