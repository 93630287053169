/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'dos-rate-limiter-service';
const prefix = `${moduleName}.${componentName}`;

export const rateLimitConnectionsFromClientOption = `${prefix}.rateLimitConnectionsFromClientOption`;
export const rateLimitRequestsFromClientToAllUrlsOption = `${prefix}.rateLimitRequestsFromClientToAllUrlsOption`;
export const rateLimitRequestsFromAllClientsToUrlOption = `${prefix}.rateLimitRequestsFromAllClientsToUrlOption`;
export const rateLimitRequestsFromClientsToUrlOption = `${prefix}.rateLimitRequestsFromClientsToUrlOption`;
export const rateLimitFailedRequestsFromClientsToAllUrlsOption = `${prefix}.rateLimitFailedRequestsFromClientsToAllUrlsOption`;
export const rateLimitFailedRequestsFromAllClientsToUrlOption = `${prefix}.rateLimitFailedRequestsFromAllClientsToUrlOptions`;
export const rateLimitHttpRequestsToAllUrlsOfVsOption = `${prefix}.rateLimitHttpRequestsToAllUrlsOfVsOption`;
export const rateLimitFailedRequestsFormClientToUrlOption = `${prefix}.rateLimitFailedRequestsFormClientToUrlOption`;
export const rateLimitScansFromClientToAllUrlsOption = `${prefix}.rateLimitScansFromClientToAllUrlsOption`;
export const rateLimitScansFromAllClientsToAllUrlsOption = `${prefix}.rateLimitScansFromAllClientsToAllUrlsOption`;
export const rateLimitHttpHeaderOrCookieOption = `${prefix}.rateLimitHttpHeaderOrCookieOption`;
export const reportOnlyOption = `${prefix}.reportOnlyOption`;
export const dropSynPacketsOption = `${prefix}.dropSynPacketsOption`;
export const sendTcpRstOption = `${prefix}.sendTcpRstOption`;
export const closeTcpConnectionOption = `${prefix}.closeTcpConnectionOption`;
export const sendHttpLocalResponseOption = `${prefix}.sendHttpLocalResponseOption`;
export const sendHttpRedirectOption = `${prefix}.sendHttpRedirectOption`;

export const ENGLISH = {
    [rateLimitConnectionsFromClientOption]: 'Rate Limit Connections from a Client',
    [rateLimitRequestsFromClientToAllUrlsOption]: 'Rate Limit Requests from a Client to all URLs',
    [rateLimitRequestsFromAllClientsToUrlOption]: 'Rate Limit Requests from all Clients to a URL',
    [rateLimitRequestsFromClientsToUrlOption]: 'Rate Limit Requests from a Client to a URL',
    [rateLimitFailedRequestsFromClientsToAllUrlsOption]: 'Rate Limit Failed Requests from a Client to all URLs',
    [rateLimitFailedRequestsFromAllClientsToUrlOption]: 'Rate Limit Failed Requests from all Clients to a URL',
    [rateLimitHttpRequestsToAllUrlsOfVsOption]: 'Rate Limit all HTTP requests that map to any custom string to all URLs of the Virtual Service.',
    [rateLimitFailedRequestsFormClientToUrlOption]: 'Rate Limit Failed Requests from a Client to a URL',
    [rateLimitScansFromClientToAllUrlsOption]: 'Rate Limit Scans from a Client to all URLs',
    [rateLimitScansFromAllClientsToAllUrlsOption]: 'Rate Limit Scans from all Clients to all URLs',
    [rateLimitHttpHeaderOrCookieOption]: 'Rate Limit HTTP Header or Cookie',
    [reportOnlyOption]: 'Report Only',
    [dropSynPacketsOption]: 'Drop SYN Packets',
    [sendTcpRstOption]: 'Send TCP RST',
    [closeTcpConnectionOption]: 'Close TCP Connection',
    [sendHttpLocalResponseOption]: 'Send HTTP Local Response',
    [sendHttpRedirectOption]: 'Send HTTP Redirect',
};
