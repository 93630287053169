/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-certificate-authority-modal';
const prefix = `${moduleName}.${componentName}`;

export const generalHeader = `${prefix}.generalHeader`;
export const modalTitleAddCa = `${prefix}.modalTitleAddCa`;
export const uploadCaFileInputLabel = `${prefix}.uploadCaFileInputLabel`;

export const ENGLISH = {
    [generalHeader]: 'General',
    [modalTitleAddCa]: 'Add CA',
    [uploadCaFileInputLabel]: 'Upload Certificate Authority File (CA)',
};
