/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-duration-detail';
const prefix = `${moduleName}.${componentName}`;

export const startTimeLabel = `${prefix}.startTimeLabel`;
export const clientRttLabel = `${prefix}.clientRttLabel`;
export const serverRttLabel = `${prefix}.serverRttLabel`;
export const wafLabel = `${prefix}.wafLabel`;
export const appResponseLabel = `${prefix}.appResponseLabel`;
export const dataTransferLabel = `${prefix}.dataTransferLabel`;

export const ENGLISH = {
    [startTimeLabel]: 'Start Time',
    [clientRttLabel]: 'Client RTT',
    [serverRttLabel]: 'Server RTT',
    [wafLabel]: 'WAF',
    [appResponseLabel]: 'App Response',
    [dataTransferLabel]: 'Data Transfer',
};
