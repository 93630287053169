/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'infoblox-usable-subnets-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const columnTitleIpv4 = `${prefix}.columnTitleIpv4`;
export const columnTitleIpv6 = `${prefix}.columnTitleIpv6`;
export const ipv4SelectPlaceholder = `${prefix}.ipv4SelectPlaceholder`;
export const ipv6SelectPlaceholder = `${prefix}.ipv6SelectPlaceholder`;
export const usableSubnetsGridHeader = `${prefix}.usableSubnetsGridHeader`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [columnTitleIpv4]: 'IPv4',
    [columnTitleIpv6]: 'IPv6',
    [ipv4SelectPlaceholder]: 'Select IPv4 Subnet',
    [ipv6SelectPlaceholder]: 'Select IPv6 Subnet',
    [usableSubnetsGridHeader]: 'Usable Subnets ({0})',
};
