/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function alertConfigCollectionFactory(Collection, AlertConfig) {
    /**
     * @constructor
     * @memberOf module:avi/app
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/app.AlertConfig AlertConfigs}.
     */
    class AlertConfigCollection extends Collection {}

    const serverDefaultsOverride = {
        alert_filter: [],
    };

    Object.assign(AlertConfigCollection.prototype, {
        objectName_: 'alertconfig',
        itemClass_: AlertConfig,
        windowElement_: 'adm-alertconfig-create',
        serverDefaultsOverride_: serverDefaultsOverride,
    });

    return AlertConfigCollection;
}

alertConfigCollectionFactory.$inject = [
    'Collection',
    'AlertConfig',
];

angular.module('avi/app')
    .factory('AlertConfigCollection', alertConfigCollectionFactory);
