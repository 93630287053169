/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { TEnumValueLabelsHash } from 'ajs/modules/core/services/schema-service';
import {
    IL4RuleProtocolMatch,
    MatchOperation,
} from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IPolicyMatchInfo } from 'ajs/modules/policies/policies.types';

/**
 * @desc L4RuleProtocolMatch MessageItem class.
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
export class L4RuleProtocolMatchConfigItem extends MessageItem<IL4RuleProtocolMatch>
    implements IPolicyMatchInfo {
    private readonly matchValueLabelsHash: TEnumValueLabelsHash;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'L4RuleProtocolMatch',
            ...args,
        };

        super(extendedArgs);

        const schemaService = this.getAjsDependency_('schemaService');

        /**
         * Labels representing match values.
         */
        this.matchValueLabelsHash = schemaService.getEnumValueLabelsHash('Protocol');
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IL4RuleProtocolMatch> {
        return {
            match_criteria: MatchOperation.IS_IN,
        };
    }

    /**
     * Label of match. Commonly used as part of expander info.
     */
    public get matchLabel(): string {
        return 'Protocol';
    }

    /**
     * Value of match to represent the data. Commonly used as part of expander info.
     */
    public get matchValue(): string {
        const { protocol: protocolEnumValue } = this.config;
        const value = this.matchValueLabelsHash[protocolEnumValue];

        return value;
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        return `${this.matchLabel}: ${this.matchValue}`;
    }

    /**
     * Return true if the protocol has been configured.
     */
    public hasProtocol(): boolean {
        return Boolean(this.config.protocol);
    }

    /** @override */
    public canFlatten(): boolean {
        return this.hasProtocol();
    }
}

L4RuleProtocolMatchConfigItem.ajsDependencies = [
    'schemaService',
];
