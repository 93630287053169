/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { MgmtIpAccessControl } from 'object-types';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { IIpAddrMatch } from 'generated-types';
import * as globalL10n from 'global-l10n';

import * as l10n from './system-settings-access-client-management-access-card.l10n';
import './system-settings-access-client-management-access-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Presentational Component for Client Management Access in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-client-management-access-card',
    templateUrl: './system-settings-access-client-management-access-card.component.html',
})
export class SystemSettingsAccessClientManagementAccessCardComponent implements OnInit, OnChanges {
    /**
     * Allowed SSH Clients
     */
    @Input()
    public allowedSshClientsAccess: IIpAddrMatch;

    /**
     * CLI Shell clients allowed.
     */
    @Input()
    public cliShellClientsAccess: IIpAddrMatch;

    /**
     * Allowed external HTTPS Clients.
     */
    @Input()
    public allowedExternalHttpsClientsAccess: IIpAddrMatch;

    /**
     * Allowed external SNMP Clients.
     */
    @Input()
    public allowedExternalSnmpClientsAccess: IIpAddrMatch;

    /**
     * SSH client values that are allowed.
     */
    public allowedSshClientsValues: string[];

    /**
     * Cli shel client values that are allowed.
     */
    public cliShellClientsValues: string[];

    /**
     * External https clients allowed.
     */
    public allowedExternalHttpsClientsValues: string[];

    /**
     * External snmp clients allowed.
     */
    public allowedExternalSnmpClientsValues: string[];

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * ObjectType for management ip access control.
     */
    public readonly objectType = MgmtIpAccessControl;

    /**
     * Placeholder value for fields
     */
    private readonly anyValue: string;

    constructor(
        l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.anyValue = l10nService.getMessage(this.globalL10nKeys.anyLabel);
    }

    /**
     * @override
     * update state by calling its setter function.
     */
    public ngOnInit(): void {
        this.setAllowedSshClientsValues();
        this.setCliShellClientsValues();
        this.setAllowedExternalHttpsClientsValues();
        this.setAllowedExternalSnmpClientsValues();
    }

    /**
     * @override
     * update state by calling its setter function.
     */
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        const {
            allowedSshClientsAccess,
            cliShellClientsAccess,
            allowedExternalHttpsClientsAccess,
            allowedExternalSnmpClientsAccess,
        } = simpleChanges;

        if (allowedSshClientsAccess && !allowedSshClientsAccess.isFirstChange()) {
            this.setAllowedSshClientsValues();
        }

        if (cliShellClientsAccess && !cliShellClientsAccess.isFirstChange()) {
            this.setCliShellClientsValues();
        }

        if (
            allowedExternalHttpsClientsAccess &&
            !allowedExternalHttpsClientsAccess.isFirstChange()
        ) {
            this.setAllowedExternalHttpsClientsValues();
        }

        if (
            allowedExternalSnmpClientsAccess &&
            !allowedExternalSnmpClientsAccess.isFirstChange()
        ) {
            this.setAllowedExternalSnmpClientsValues();
        }
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Setter for Allowed SSH Clients Values.
     */
    private setAllowedSshClientsValues(): void {
        this.allowedSshClientsValues = this.getAllowedClients(this.allowedSshClientsAccess);
    }

    /**
     * Setter for CLI Shell clients values.
     */
    private setCliShellClientsValues(): void {
        this.cliShellClientsValues = this.getAllowedClients(this.cliShellClientsAccess);
    }

    /**
     * Setter for  external HTTPS clients values.
     */
    private setAllowedExternalHttpsClientsValues(): void {
        this.allowedExternalHttpsClientsValues = this.getAllowedClients(
            this.allowedExternalHttpsClientsAccess,
        );
    }

    /**
     * Setter for external SNMP clients values.
     */
    private setAllowedExternalSnmpClientsValues(): void {
        this.allowedExternalSnmpClientsValues = this.getAllowedClients(
            this.allowedExternalSnmpClientsAccess,
        );
    }

    /**
     * Gets all types allowed clients, like client network refs, client addrs,
     * client prefixes and client ranges.
     */
    private getAllowedClients(allowedClientsAccess: IIpAddrMatch): string[] {
        if (!allowedClientsAccess) {
            return [this.anyValue];
        }

        const {
            group_refs: groupRefs = [],
            addrs = [],
            prefixes = [],
            ranges = [],
        } = allowedClientsAccess;

        const allowedClientsNames = groupRefs.map(ref => this.stringService.name(ref));
        const allowedClientAddrs = addrs.map(addr => addr.addr);

        const allowedClientPrefixes = prefixes.map(
            prefix => [prefix.ip_addr.addr, prefix.mask].join('/'),
        );

        const allowedClientRanges = ranges.map(
            range => [range.begin.addr, range.end.addr].join('-'),
        );

        const allowedClients = [
            ...allowedClientsNames,
            ...allowedClientAddrs,
            ...allowedClientPrefixes,
            ...allowedClientRanges,
        ];

        return allowedClients.length ? allowedClients : [this.anyValue];
    }
}
