/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { StringMatchConfigItem }
    from 'ajs/modules/match/factories/string-match.config-item.factory';

/**
 * @description Match component for StringMatch.
 *
 * @author sghare
 */
@Component({
    selector: 'string-match',
    templateUrl: './string-match.component.html',
})
export class StringMatchComponent {
    /**
     * StringMatchConfigItem instance.
     */
    @Input()
    public editable: StringMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Field name passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * If true, shows the remove icon.
     */
    @Input()
    public showRemoveIcon = true;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
