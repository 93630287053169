/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { GslbGeoDbProfileItem, IGslbGeoDbProfileStagingFile } from 'ajs/modules/gslb';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { IEnumValue, SchemaService } from 'ajs/modules/core/services/schema-service';
import * as globalL10n from 'global-l10n';
import * as l10n from './gslb-geodb-profile-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for Geo Profile Modal.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'geo-profile-modal',
    templateUrl: './gslb-geodb-profile-modal.component.html',
})
export class GslbGeoDbProfileModalComponent {
    /**
     * GslbGeoDbProfileItem ObjectTypeItem Instance
     */
    @Input()
    public editable: GslbGeoDbProfileItem;

    /**
     * GslbGeoDbProfileItem Object Type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Layout for GslbGeoDbProfile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Enum List of Fileformats for GslbGeoDbProfile.
     */
    public readonly gslbGeoDbFileFormatEnumList: IEnumValue[];

    /**
     * Dropdown Options for staging filenames fetched.
     */
    public filenameDropdownOptions: IAviDropdownOption[] = [];

    /**
     * Dropdown Options for File Formats of GslbGeoDb files.
     */
    public formatDropdownOptions: IAviDropdownOption[];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.gslbGeoDbFileFormatEnumList = schemaService.getEnumValues('GslbGeoDbFileFormat');
    }

    /**
     * @override
     * Format dropdown options and Filename dropdown options are set.
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setFormatDropdownOptions();
        this.setFilenameDropdownOptions();
    }

    /**
     * GslbGeoDbProfile Staging files are requested
     * from API call and dropdown options are set.
     */
    private async setFilenameDropdownOptions(): Promise<void> {
        const stagingFiles: IGslbGeoDbProfileStagingFile[] =
            await this.editable.getStagingFiles() || [];

        this.filenameDropdownOptions = stagingFiles.map(
            ({ filename }) => createDropdownOption(filename),
        );
    }

    /**
     * GslbGeoDbProfile File format options are set.
     */
    private setFormatDropdownOptions(): void {
        this.formatDropdownOptions = this.gslbGeoDbFileFormatEnumList.map(
            ({ value, label }) => createDropdownOption(value, label),
        );
    }
}
