/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    HealthMonitorAuthInfo,
    HealthMonitorHttp,
} from 'object-types';

import {
    HealthMonitorAuthType,
    IHealthMonitorAuthInfo,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';

import {
    HealthMonitorHttpConfigItem,
    HealthMonitorItem,
} from 'ajs/modules/healthmonitor/factories';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as l10n from './health-monitor-authentication.l10n';
import './health-monitor-authentication.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor HTTP/HTTPS authentication component.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'health-monitor-authentication',
    templateUrl: './health-monitor-authentication.component.html',
})
export class HealthMonitorAuthenticationComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Shows/Hides authentication type dropdown.
     */
    @Input()
    public showAuthTypeDropdown = true;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitorAuthInfo,
        HealthMonitorHttp,
    };

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Delete authentication values when authentication type is cleared and reset
     * exact_http_request.
     */
    public handleAuthenticationTypeClear(): void {
        this.editable.clearHttpAuthentication();
        this.editable.resetExactHttpRequest();
    }

    /**
     * If authentication type is NTLM then reset exact_http_request.
     */
    public handleAuthenticationTypeChange(): void {
        const { healthMonitorTypeConfig: { config } } = this.editable;
        const { auth_type: authType } = config;

        if (!authType) {
            // When authentication type is cleared we need to delete authentication values and reset
            // exact_http_request
            this.handleAuthenticationTypeClear();
        }

        if (authType === HealthMonitorAuthType.AUTH_NTLM) {
            this.editable.resetExactHttpRequest();
        }
    }

    /**
     * Getter for authentication message item.
     */
    public get authentication(): MessageItem<IHealthMonitorAuthInfo> {
        const { authentication } = this.editable;

        return authentication;
    }

    /**
     * Getter for sub config of HTTP/HTTPS Health Monitor Types.
     */
    public get healthMonitorTypeConfig(): HealthMonitorHttpConfigItem {
        const { healthMonitorTypeConfig } = this.editable;

        return healthMonitorTypeConfig;
    }
}
