/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'gslb-pool-priority-field';
const prefix = `${moduleName}.${componentName}`;

export const priorityLabel = `${prefix}.priorityLabel`;

export const ENGLISH = {
    [priorityLabel]: 'Priority',
};
