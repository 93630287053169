/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './virtualservice-faults.less';
import * as l10n from './virtualserviceFaults.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceFaults
 * @param {VirtualServiceFaults} faults
 * @param {function} onCloseMessage - Closes message.
 * @description
 *     Displays a list of Virtual Service faults. Lists are separated by faults of a generic data
 *     structure and faults of a nested structure like server faults, which are nested by pools.
 */
class Controller {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Used to toggle displaying the list of faults.
     */
    showList = false;

    $onInit() {
        this.count = this.getCount();
        this.genericFaults = this.getGenericFaults();
        this.serverFaults = this.getServerFaults();
    }

    /**
     * Returns the count of total faults. If faults are of type 'server_fault', faults are
     * nested further.
     * @return {number}
     */
    getCount() {
        let counter = 0;

        this.faults.forEach(fault => {
            if (fault.id === 'server_faults') {
                fault.faults.forEach(innerFault => {
                    counter += innerFault.servers.length;
                });
            } else {
                counter += 1;
            }
        });

        return counter;
    }

    /**
     * Returns a list of generic faults.
     * @return {Object[]}
     */
    getGenericFaults() {
        return this.faults.filter(fault => fault.id !== 'server_faults');
    }

    /**
     * Returns a list of server faults.
     * @return {Object[]}
     */
    getServerFaults() {
        return _.find(this.faults, fault => fault.id === 'server_faults');
    }

    /**
     * Toggles the view between showing and hiding the list of faults.
     */
    toggleList() {
        this.showList = !this.showList;
    }
}

Controller.$inject = [
    'l10nService',
];

angular.module('aviApp').component('virtualserviceFaults', {
    bindings: {
        faults: '<',
        onCloseMessage: '&',
    },
    controller: Controller,
    templateUrl: 'src/components/applications/virtualservice/' +
            'virtualservice-faults/virtualservice-faults.html',
});
