/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as serversListTemplate
    from '../../views/components/application/servers-list.partial.html';

/**
 * @ngdoc directive
 * @name serversList
 * @restrict E
 * @param {Server[]} servers
 * @param {Pool} pool
 * @description
 * Outputs a list of servers for the Tree view on the App dashboard. Have two different view types
 * here - one for the number less then 31 (up to three lists placed horizontally one after
 * another) and more - when we show only circles colored by HS value, trying to keep them in a
 * square shape (i.e. having a size of 5x5).
 */
angular.module('aviApp').directive('serversList', function() {
    function link(scope) {
        scope.$watchCollection('servers', function(servers) {
            let grSize,
                i;

            scope.threshold = 30;
            scope.srvArr = _.values(servers);
            scope.servers_displ = [];

            if (scope.srvArr.length) {
                //use different layout depending on servers quantity
                if (scope.srvArr.length <= scope.threshold) {
                    grSize = Math.ceil(scope.threshold / 3);
                } else { //square of circles (minimized)
                    grSize = Math.ceil(Math.sqrt(scope.srvArr.length + 1));
                    //number of circles in a row
                }

                for (i = 0; i < scope.srvArr.length; i += grSize) {
                    scope.servers_displ.push(scope.srvArr.slice(i, i + grSize));
                }
            }
        });
    }

    return {
        restrict: 'E',
        scope: {
            servers: '=',
            pool: '=',
        },
        link,
        template: serversListTemplate,
    };
});
