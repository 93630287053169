/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-app-level-alert';
const prefix = `${moduleName}.${componentName}`;

export const defaultActionBtnLabel = `${prefix}.defaultActionBtnLabel`;

export const ENGLISH = {
    [defaultActionBtnLabel]: 'Click',
};
