/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SMTPType } from 'generated-types';
import { EmailConfiguration } from 'object-types';
import * as globalL10n from 'global-l10n';

import { AviModalService } from 'ajs/modules/core/services/avi-modal/avi-modal.service';

import * as l10n from './system-settings-email-smtp-source-card.l10n';

import './system-settings-email-smtp-source-card.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Email/SMTP Source Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-email-smtp-source-card',
    templateUrl: './system-settings-email-smtp-source-card.component.html',
})
export class SystemSettingsEmailSmtpSourceCardComponent {
    /**
     * SMTP Type in email configuration.
     */
    @Input()
    public smtpType: string;

    /**
     * SMTP Source Email in email configuration.
     */
    @Input()
    public smtpFromEmail = '';

    /**
     * SMTP Server Name in email configuration.
     */
    @Input()
    public smtpServer = '';

    /**
     * SMTP Port from email configuration.
     */
    @Input()
    public smtpPort: number;

    /**
     * SMTP Server Username from email configuration,
     * to be displayed if smtpType is SMTP Server.
     */
    @Input()
    public smtpServerUsername = '';

    /**
     * Object Type for aviLabelWithTooltip
     */
    public readonly objectType = EmailConfiguration;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Map holding SMTP type to it's l10nKey val.
     */
    private readonly smtpSourceValueHashMap = {
        [SMTPType.SMTP_ANONYMOUS_SERVER]: l10nKeys.anonymousSmtpServerValue,
        [SMTPType.SMTP_LOCAL_HOST]: l10nKeys.localHostSmtpServerValue,
        [SMTPType.SMTP_SERVER]: l10nKeys.smtpServerValue,
        [SMTPType.SMTP_NONE]: l10nKeys.noSmtpServerValue,
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly aviModal: AviModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for SMPT Source Type.
     */
    public get smtpSourceType(): string {
        const smtpSourceType = this.l10nService.getMessage(
            this.smtpSourceValueHashMap[this.smtpType],
        );

        return smtpSourceType || '';
    }

    /**
     * Getter for SMTP server port value.
     */
    public get smtpServerPortValue(): string {
        return this.smtpPort ? this.smtpPort.toString() : '';
    }

    /**
     * Getter Flag for Custom SMTP Server.
     */
    public get isSmtpServer(): boolean {
        return this.smtpType === SMTPType.SMTP_SERVER;
    }

    /**
     * Getter Flag for Anonymous SMTP Server.
     */
    public get isAnonymousSmtpServer(): boolean {
        return this.smtpType === SMTPType.SMTP_ANONYMOUS_SERVER;
    }

    /**
     * Getter Flag for Local SMTP Server.
     */
    public get isLocalSmtpServer(): boolean {
        return this.smtpType === SMTPType.SMTP_LOCAL_HOST;
    }

    /**
     * Getter Flag for No SMTP Server.
     */
    public get isNoneSmtpServer(): boolean {
        return this.smtpType === SMTPType.SMTP_NONE;
    }

    /**
     * Opens Send Test Email modal.
     */
    public openSendTestEmail(): void {
        this.aviModal.open(
            'adm-email-validate',
            { type: 'smtp' },
        );
    }
}
