/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import './expander-action-row.less';

/**
 * @ngdoc component
 * @name ExpanderActionRowComponent
 * @memberOf module:avi/shared
 * @param message - Message to be shown.
 * @description Component for displaying a message. Buttons can be passed in as transcluded content
 *     if action can be taken, for example as a confirmation.
 * @author alextsg
 */
@Component({
    selector: 'expander-action-row',
    templateUrl: './expander-action-row.component.html',
})
export class ExpanderActionRowComponent {
    @Input() public message: string;
}
