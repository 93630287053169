/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Pipe, PipeTransform } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './boolean-label-pipe.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IBooleanLabelPairs {
    enabled: string[];
    allowed: string[];
    switch: string[];
    yes: string[];
    active: string[];
}

@Pipe({ name: 'booleanLabel' })
export class BooleanLabelPipe implements PipeTransform {
    constructor(public readonly l10nService: L10nService) {
        this.l10nService = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Pipe that transforms boolean values into text labels. Default is "True" and "False".
     */
    public transform(boolVal: boolean, type?: string, lowerCase?: boolean): string {
        let output: string;
        const pairs: IBooleanLabelPairs = {
            enabled: [
                this.l10nService.getMessage(l10nKeys.enabledLabel),
                this.l10nService.getMessage(l10nKeys.notEnabledLabel),
            ],
            allowed: [
                this.l10nService.getMessage(l10nKeys.allowedLabel),
                this.l10nService.getMessage(l10nKeys.disallowedLabel),
            ],
            switch: [
                this.l10nService.getMessage(l10nKeys.disableLabel),
                this.l10nService.getMessage(l10nKeys.enableLabel),
            ],
            yes: [
                this.l10nService.getMessage(l10nKeys.yesLabel),
                this.l10nService.getMessage(l10nKeys.noLabel),
            ],
            active: [
                this.l10nService.getMessage(l10nKeys.activeLabel),
                this.l10nService.getMessage(l10nKeys.suspendedLabel),
            ],
        };

        if (type && type in pairs) {
            const pair = pairs[type];

            output = boolVal ? pair[0] : pair[1];
        } else {
            output = boolVal ?
                this.l10nService.getMessage(l10nKeys.trueLabel) :
                this.l10nService.getMessage(l10nKeys.falseLabel);
        }

        if (lowerCase) {
            output = output.toLowerCase();
        }

        return output;
    }
}

BooleanLabelPipe.$inject = ['l10nService'];
