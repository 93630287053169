/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module IpamModule */

import { Injector } from '@angular/core';
import {
    CustomIpamDnsCollection,
    IpamProfileCollection,
} from 'ajs/modules/ipam/factories';

const $injector = '$injector';

export const customIpamDnsProfileCollectionProvider = {
    deps: [$injector],
    provide: CustomIpamDnsCollection,
    useFactory(injector: Injector): typeof CustomIpamDnsCollection {
        return injector.get('CustomIpamDnsCollection');
    },
};

export const ipamProfileCollectionProvider = {
    deps: [$injector],
    provide: IpamProfileCollection,
    useFactory(injector: Injector): typeof IpamProfileCollection {
        return injector.get('IpamProfileCollection');
    },
};
