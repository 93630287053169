/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnDestroy,
    Type,
} from '@angular/core';

import {
    Observable,
    Subject,
} from 'rxjs';

import { Auth } from 'ajs/modules/core/services/auth';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { L10nService } from '@vmw/ngx-vip';
import { DialogService } from 'ng/modules/core';
import { AppAboutModalComponent } from '../../app-about-modal';
import './user-card-menu.component.less';
import * as l10n from './user-card-menu.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * About modal id.
 */
const APP_ABOUT_DIALOG_ID = 'app-about-modal';

/**
 * @desc Component for user menu dropdown.
 *
 * @author Rohit Gaikwad
 */
@Component({
    selector: 'user-card-menu',
    templateUrl: './user-card-menu.component.html',
})
export class UserCardMenuComponent implements OnDestroy {
    /**
     * User's last login information.
     */
    @Input()
    public lastLoginTimestamp: string;

    /**
     * Logged-in user's name.
     */
    @Input()
    public userName: string;

    /**
     * User dropdown/tooltip position strategy.
     */
    public menuPositions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
        },
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Subject to control user menu.
     */
    private userMenuControlSubject = new Subject<boolean>();

    constructor(
        l10nService: L10nService,
        private readonly auth: Auth,
        private readonly dialogService: DialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns an observable to allow subscriptions to show and hide menu.
     */
    public get userMenuControl$(): Observable<boolean> {
        return this.userMenuControlSubject.asObservable();
    }

    /**
     * Hides the user dropdown menu/tooltip when my account or about modal is opened.
     */
    public handleTooltipClose(): void {
        this.userMenuControlSubject.next(false);
    }

    /**
     * Opens the About modal popup.
     */
    public handleOpenAbout(): void {
        this.handleTooltipClose();

        this.dialogService.add({
            id: APP_ABOUT_DIALOG_ID,
            component: AppAboutModalComponent as Type<Component>,
            componentProps: {
                onClose: () => {
                    this.dialogService.remove(APP_ABOUT_DIALOG_ID);
                },
            },
        });
    }

    /**
     * Logs out of the application.
     */
    public handleLogout(): void {
        this.auth.logout(true);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.handleTooltipClose();
    }
}
