/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'http-health-monitor-config';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleHttp = `${prefix}.sectionTitleHttp`;
export const sectionTitleHttps = `${prefix}.sectionTitleHttps`;
export const clientRequestHeaderInputLabel = `${prefix}.clientRequestHeaderInputLabel`;
export const clientRequestHeaderInputPlaceholder = `${prefix}.clientRequestHeaderInputPlaceholder`;
export const clientRequestBodyInputLabel = `${prefix}.clientRequestBodyInputLabel`;
export const clientRequestBodyInputPlaceholder = `${prefix}.clientRequestBodyInputPlaceholder`;
export const useExactRequestInputLabel = `${prefix}.useExactRequestInputLabel`;
export const serverResponseDataInputLabel = `${prefix}.serverResponseDataInputLabel`;
export const serverResponseDataInputPlaceholder = `${prefix}.serverResponseDataInputPlaceholder`;
export const responseCodeInputLabel = `${prefix}.responseCodeInputLabel`;
export const responseCodeInputPlaceholder = `${prefix}.responseCodeInputPlaceholder`;
export const enableSslAttributesInputLabel = `${prefix}.enableSslAttributesInputLabel`;
export const serverNameInputLabel = `${prefix}.serverNameInputLabel`;
export const sslProfileInputLabel = `${prefix}.sslProfileInputLabel`;
export const sslProfileInputPlaceholder = `${prefix}.sslProfileInputPlaceholder`;
export const pkiProfileInputLabel = `${prefix}.pkiProfileInputLabel`;
export const pkiProfileInputPlaceholder = `${prefix}.pkiProfileInputPlaceholder`;
export const sslKeyCertificateInputLabel = `${prefix}.sslKeyCertificateInputLabel`;
export const sslKeyCertificateInputPlaceholder = `${prefix}.sslKeyCertificateInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleHttp]: 'HTTP',
    [sectionTitleHttps]: 'HTTPS',
    [clientRequestHeaderInputLabel]: 'Client Request Header',
    [clientRequestHeaderInputPlaceholder]: 'Enter Client Request Header',
    [clientRequestBodyInputLabel]: 'Client Request Body',
    [clientRequestBodyInputPlaceholder]: 'Enter Client Request Body',
    [useExactRequestInputLabel]: 'Use Exact Request',
    [serverResponseDataInputLabel]: 'Server Response Data',
    [serverResponseDataInputPlaceholder]: 'Enter Server Response Data',
    [responseCodeInputLabel]: 'Response Code',
    [responseCodeInputPlaceholder]: 'Select Response Code',
    [enableSslAttributesInputLabel]: 'Enable SSL Attributes',
    [serverNameInputLabel]: 'TLS SNI Server Name',
    [sslProfileInputLabel]: 'SSL Profile',
    [sslProfileInputPlaceholder]: 'Select SSL Profile',
    [pkiProfileInputLabel]: 'PKI Profile',
    [pkiProfileInputPlaceholder]: 'Select PKI Profile',
    [sslKeyCertificateInputLabel]: 'SSL Key and Certificate',
    [sslKeyCertificateInputPlaceholder]: 'Select SSL Key and Certificate',
};
