/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    Output,
} from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

/**
 * Upgraded version of 'license-selector' component.
 */
@Directive({
    selector: 'license-selector',
})
export class LicenseSelectorDirective extends UpgradeComponent {
    /**
     * Two-way bound property to be set.
     */
    @Input() public licenseType: string;

    /**
     * Type of the cloud.
     */
    @Input() public cloudType: string;

    /**
     * Informs if being used for SEG. Only ServiceEngineGroup is supported and default value is
     * Cloud.
     */
    @Input() public objectType?: string;

    /**
     * Change EventEmitter for the licenseType input. Needed since this is a two-way binding on the
     * licenseSelector component.
     */
    @Output() public licenseTypeChange: EventEmitter<string>;

    constructor(elementRef: ElementRef, injector: Injector) {
        super('licenseSelector', elementRef, injector);
    }
}
