/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { findLastIndex } from 'underscore';

export interface IFullModalLayout {
    component: Type<Component>;
    componentProps?: Record<string, any>;
    previewComponent?: Type<Component>;
    previewComponentProps?: Record<string, any>;
    getDescription(): string;
    getName(): string;
}

// Token used when registering as a downgraded service in AJS.
export const FULL_MODAL_SERVICE_TOKEN = 'fullModalService';

/**
 * @ngdoc service
 * @name  FullModalService
 * @description Service for adding and removing full modals.
 * @author alextsg
 */
@Injectable()
export class FullModalService {
    public modalLayoutStack: IFullModalLayout[] = [];
    private subject = new Subject<IFullModalLayout[]>();

    /**
     * Returns the modalLayoutStack, a stack of all modal layouts that the user is working with.
     */
    public get modalLayoutStack$(): Observable<IFullModalLayout[]> {
        return this.subject.asObservable();
    }

    /**
     * Adds a modal layout to the end of the modalLayoutStack.
     */
    public addModal(modal: IFullModalLayout): void {
        this.modalLayoutStack.push(modal);
        this.subject.next(this.modalLayoutStack);
    }

    /**
     * Checks whether a modal is opened by modal component.
     */
    public isOpen(component: Type<Component>): boolean {
        return this.modalLayoutStack.some(
            (modalLayout: IFullModalLayout) => modalLayout.component === component,
        );
    }

    /**
     * Removes a modal by component class. Looks through the modalLayoutStack for the last component
     * class that matches, and removes it from the stack.
     */
    public removeModalByComponent(component: Type<Component>): void {
        const modalLayoutToRemoveIndex = findLastIndex(
            this.modalLayoutStack,
            (modalLayout: IFullModalLayout) => modalLayout.component === component,
        );

        this.modalLayoutStack.splice(modalLayoutToRemoveIndex, 1);
        this.subject.next(this.modalLayoutStack);
    }

    /**
     * Removes the last modal layout from the modalLayoutStack.
     */
    public removeLastModal(): void {
        this.modalLayoutStack.pop();
        this.subject.next(this.modalLayoutStack);
    }

    /**
     * Removes all modal layouts from the modalLayoutStack.
     */
    public removeAllModals = (): void => {
        this.modalLayoutStack.length = 0;
        this.subject.next(this.modalLayoutStack);
    };
}
