/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'response-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const locationHeaderMatchLabel = `${prefix}.locationHeaderMatchLabel`;
export const httpStatusMatchLabel = `${prefix}.httpStatusMatchLabel`;
export const responseHeaderMatchLabel = `${prefix}.responseHeaderMatchLabel`;
export const modifyHeaderActionLabel = `${prefix}.modifyHeaderActionLabel`;
export const rewriteLocationHeaderActionLabel = `${prefix}.rewriteLocationHeaderActionLabel`;
export const addHttpResponseRuleHeader = `${prefix}.addHttpResponseRuleHeader`;
export const sourceIpMatchLabel = `${prefix}.sourceIpMatchLabel`;
export const matchCaseLabel = `${prefix}.matchCaseLabel`;
export const customStringLabel = `${prefix}.customStringLabel`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [locationHeaderMatchLabel]: 'Location Header',
    [httpStatusMatchLabel]: 'HTTP Status',
    [responseHeaderMatchLabel]: 'Response Header',
    [modifyHeaderActionLabel]: 'Modify Header',
    [rewriteLocationHeaderActionLabel]: 'Rewrite Location Header',
    [addHttpResponseRuleHeader]: 'Add HTTP Response Rule',
    [sourceIpMatchLabel]: 'Source IP',
    [matchCaseLabel]: 'Match Case',
    [customStringLabel]: 'String group or custom string',
};
