/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'dns-policy-match';
const prefix = `${moduleName}.${componentName}`;

export const geographicalLocationHeader = `${prefix}.geographicalLocationHeader`;
export const isLabel = `${prefix}.isLabel`;
export const isNotLabel = `${prefix}.isNotLabel`;
export const useEndsClientIpLabel = `${prefix}.useEndsClientIpLabel`;
export const geolocationNameLabel = `${prefix}.geolocationNameLabel`;
export const geolocationTagLabel = `${prefix}.geolocationTagLabel`;
export const clientIpAddressHeader = `${prefix}.clientIpAddressHeader`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const transportProtocolHeader = `${prefix}.transportProtocolHeader`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const selectProtocolPlaceholder = `${prefix}.selectProtocolPlaceholder`;
export const dnsOverUdpOptionLabel = `${prefix}.dnsOverUdpOptionLabel`;
export const dnsOverTcpOptionLabel = `${prefix}.dnsOverTcpOptionLabel`;
export const queryNameHeader = `${prefix}.queryNameHeader`;
export const criteriaLabel = `${prefix}.criteriaLabel`;
export const selectCriteriaPlaceholder = `${prefix}.selectCriteriaPlaceholder`;
export const domainNamesLabel = `${prefix}.domainNamesLabel`;
export const stringGroupsLabel = `${prefix}.stringGroupsLabel`;
export const selectStringGroupsPlaceholder = `${prefix}.selectStringGroupsPlaceholder`;
export const queryTypeHeader = `${prefix}.queryTypeHeader`;
export const dnsQueryTypesLabel = `${prefix}.dnsQueryTypesLabel`;
export const dnsQueryTypesPlaceholder = `${prefix}.dnsQueryTypesPlaceholder`;

export const ENGLISH = {
    [geographicalLocationHeader]: 'Geographical Location',
    [isLabel]: 'is',
    [isNotLabel]: 'is not',
    [useEndsClientIpLabel]: 'Use EDNS Client IP',
    [geolocationNameLabel]: 'Geolocation Name',
    [geolocationTagLabel]: 'Geolocation Tag',
    [clientIpAddressHeader]: 'Client IP Address',
    [ipAddressLabel]: 'IP Address',
    [transportProtocolHeader]: 'Transport Protocol',
    [protocolLabel]: 'Protocol',
    [selectProtocolPlaceholder]: 'Select Protocol',
    [dnsOverUdpOptionLabel]: 'DNS over UDP',
    [dnsOverTcpOptionLabel]: 'DNS over TCP',
    [queryNameHeader]: 'Query Name',
    [criteriaLabel]: 'Criteria',
    [selectCriteriaPlaceholder]: 'Select Criteria',
    [domainNamesLabel]: 'Domain Names',
    [stringGroupsLabel]: 'String Groups',
    [selectStringGroupsPlaceholder]: 'Select String Groups',
    [queryTypeHeader]: 'Query Type',
    [dnsQueryTypesLabel]: 'DNS Query Types',
    [dnsQueryTypesPlaceholder]: 'Add DNS Query Type',
};
