/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'CportalModule';

export const albServiceConfigBreadcrumbTitle = `${prefix}.albServiceConfigBreadcrumbTitle`;

export const ENGLISH = {
    [albServiceConfigBreadcrumbTitle]: 'ALB Services Config',
};
