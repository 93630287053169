/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { StringService } from 'ajs/modules/core/services/string-service';
import { L10nService } from '@vmw/ngx-vip';

import {
    IExtendedVipRuntimeDetail,
    OperStateType,
} from 'ajs/modules/vs/vs.types';

import './vip-list-popover.component.less';
import * as l10n from './vip-list-popover.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * class name prefix for Vip state icon
 */
const VIP_STATE_ICON_CLASSNAME_PREFIX = 'vip-list-popover__state-icon--';

/**
 * @description
 *
 *   Renders the list of vip IP addresses filtered by state passed via operStateType binding.
 *   For VIPs with operStateType as Down also renders the reason string.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'vip-list-popover',
    templateUrl: './vip-list-popover.component.html',
})
export class VipListPopoverComponent implements OnInit {
    @Input()
    public operStateType: OperStateType;

    @Input()
    public vips: IExtendedVipRuntimeDetail[];

    @Output()
    public onClose = new EventEmitter<void>();

    public readonly operStateTypeEnum = OperStateType;

    public headerTitle: string;

    public iconShape: string;

    public iconDirection: string;

    public vipStateIconClass: string;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.headerTitle = this.getHeaderTitle();
        this.iconShape = this.getIconShape(this.operStateType);
        this.iconDirection = this.getIconDirection(this.operStateType);
        this.vipStateIconClass = this.getVipStateIconClass();
    }

    /**
     * Returns operational state for the vip.
     */
    public getVipOperState(vip: IExtendedVipRuntimeDetail): string {
        return this.stringService.enumeration(vip.oper_status?.state || '', 'OPER_');
    }

    /**
     * Returns address string to be displayed in the template.
     */
    public getVipAddresses(vip: IExtendedVipRuntimeDetail): string {
        let vipAddresses = '';

        if (vip.ip_address?.addr) {
            vipAddresses = `${vip.ip_address.addr}`;
        }

        if (vip.ip6_address?.addr) {
            if (!vipAddresses) {
                vipAddresses = `${vip.ip6_address.addr}`;
            } else {
                vipAddresses += `, ${vip.ip6_address.addr}`;
            }
        }

        if (vip.floating_ip) {
            vipAddresses += ` (${vip.floating_ip.addr})`;
        }

        if (vip.floating_ip6) {
            vipAddresses += ` (${vip.floating_ip6.addr})`;
        }

        return vipAddresses;
    }

    /**
     * Emits event to close the popover
     */
    public close(): void {
        this.onClose.emit();
    }

    /**
     * trackBy Function for Vips.
     */
    public trackById(i: number, { vip_id }: IExtendedVipRuntimeDetail): string {
        return vip_id;
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Returns class for circle arrow icon based on vip state.
     */
    private getVipStateIconClass(): string {
        return VIP_STATE_ICON_CLASSNAME_PREFIX + this.operStateType;
    }

    /**
     * Returns icon direction based on operStateType.
     */
    private getIconDirection(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
                return 'up';

            case OperStateType.OPER_DOWN:
                return 'down';

            default:
                return '';
        }
    }

    /**
     * Returns header title based on vip state and address length.
     */
    private getHeaderTitle(): string {
        return this.l10nService
            .getMessage(
                l10nKeys.headerLabel,
                [this.stringService.capitalize(this.operStateType || ''), this.vips?.length],
            );
    }

    /**
     * Returns icon shape based on operStateType.
     */
    private getIconShape(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
            case OperStateType.OPER_DOWN:
                return 'circle-arrow';

            case OperStateType.OPER_PENDING:
                return 'minus-circle';

            default:
                return '';
        }
    }
}
