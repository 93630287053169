/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IStringMatch,
    StringOperation,
} from 'generated-types';

import { StringMatch } from 'object-types';
import { PathOrQueryMatchBaseConfigItem } from './path-or-query-match-base.config-item';

/**
 * @description  StringMatch ConfigItem class.
 * @author Akul Aggarwal
 */
export class StringMatchConfigItem extends PathOrQueryMatchBaseConfigItem<IStringMatch> {
    public static ajsDependencies = ['schemaService'];

    constructor(args = {}) {
        super({
            objectType: StringMatch,
            ...args,
        });

        const schemaService = this.getAjsDependency_('schemaService');

        this.operationLabels = schemaService.getEnumValueLabelsHash('StringOperation');
    }

    /**
     * Getter function for the config to override the message default.
     */
    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IStringMatch> {
        return {
            match_criteria: StringOperation.BEGINS_WITH,
        };
    }
}
