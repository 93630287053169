/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Pool's Server Health Monitor object
 * @typedef {Object} ServerHSmonitor
 * @property {number} healthscore - HealthScore value for the VS.
 * @property {string} name
 * @property {string} type
 * @property {boolean} state - True if up and false if down.
 * @property {number} resptime - Recent response time of HS monitor.
 * @property {number} avgRespTime - Average response time (ms).
 * @property {string} respCode - Empty string or response code number.
 * @property {number|undefined} success - Percent of successful monitor executions.
 */

/**
 * @ngdoc service
 * @name ServerHealthMonitor
 * @description Health Monitor {@link Item} of the {@link Pool Pool's} {@link Server}.
 */
angular.module('aviApp').factory('ServerHealthMonitor', [
    '$q', 'Item',
    function($q, Item) {
        class ServerHealthMonitor extends Item {
            /** @override */
            isEditable() {
                return false;
            }

            /** @override */
            loadConfig() {
                return $q.reject(`${this.objectName} can't be loaded from API. Can be loaded` +
                    ' through corresponding Collection only.');
            }

            /** @override */
            getIdFromData_(data) {
                return data && data.config && data.config.type && data.config.name ?
                    `${data.config.type.toLowerCase()}-${data.config.name.toLowerCase()}` : '';
            }
        }

        ServerHealthMonitor.prototype.objectName = 'server-health-monitor';//not for API calls

        return ServerHealthMonitor;
    }]);
