/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'service-match';
const prefix = `${moduleName}.${componentName}`;

export const portMatchInputPlaceholder = `${prefix}.portMatchInputPlaceholder`;
export const sourcePortLabel = `${prefix}.sourcePortLabel`;
export const destinationPortLabel = `${prefix}.destinationPortLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;

export const ENGLISH = {
    [portMatchInputPlaceholder]: 'Enter Port',
    [sourcePortLabel]: 'Source Port',
    [destinationPortLabel]: 'Destination Port',
    [protocolLabel]: 'Protocol',
};
