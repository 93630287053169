/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'dns-policy-action-gslb-site-selection';
const prefix = `${moduleName}.${componentName}`;

export const gslbSiteNameLabel = `${prefix}.gslbSiteNameLabel`;
export const gslbSiteNamePlaceholder = `${prefix}.gslbSiteNamePlaceholder`;
export const sitePreferredLabel = `${prefix}.sitePreferredLabel`;
export const fallbackSiteNameLabel = `${prefix}.fallbackSiteNameLabel`;
export const fallbackSiteNamePlaceholder = `${prefix}.fallbackSiteNamePlaceholder`;

export const ENGLISH = {
    [gslbSiteNameLabel]: 'GSLB Site Name',
    [gslbSiteNamePlaceholder]: 'Select a GSLB Site Name',
    [sitePreferredLabel]: 'Site Preferred',
    [fallbackSiteNameLabel]: 'Fallback GSLB Site Names',
    [fallbackSiteNamePlaceholder]: 'Choose Fallback GSLB Site Names',
};
