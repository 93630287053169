/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'client-log-filters';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const clientIpMatchLabel = `${prefix}.clientIpMatchLabel`;
export const pathMatchLabel = `${prefix}.pathMatchLabel`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const enableButtonLabel = `${prefix}.enableButtonLabel`;
export const disableButtonLabel = `${prefix}.disableButtonLabel`;
export const editActionTitle = `${prefix}.editActionTitle`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const clientIpLabel = `${prefix}.clientIpLabel`;
export const addClientLogFilterHeader = `${prefix}.addClientLogFilterHeader`;
export const filterNameInputLabel = `${prefix}.filterNameInputLabel`;
export const durationInputLabel = `${prefix}.durationInputLabel`;
export const minInnerText = `${prefix}.minInnerText`;
export const logAllHeadersCheckboxLabel = `${prefix}.logAllHeadersCheckboxLabel`;
export const matchingFilterHeader = `${prefix}.matchingFilterHeader`;
export const clientIpAddressHeader = `${prefix}.clientIpAddressHeader`;
export const isInRadioInputLabel = `${prefix}.isInRadioInputLabel`;
export const isNotInRadioInputLabel = `${prefix}.isNotInRadioInputLabel`;
export const removeMatchButtonLabel = `${prefix}.removeMatchButtonLabel`;
export const pathHeader = `${prefix}.pathHeader`;
export const matchInputLabel = `${prefix}.matchInputLabel`;
export const anyMatchInputPlaceholder = `${prefix}.anyMatchInputPlaceholder`;
export const clientLogSettingsHeader = `${prefix}.clientLogSettingsHeader`;
export const significantLogThrottleInputLabel = `${prefix}.significantLogThrottleInputLabel`;
export const userDefinedFiltersLogThrottleInputLabel = `${prefix}.userDefinedFiltersLogThrottleInputLabel`;
export const nonSignificantLogsCheckboxLabel = `${prefix}.nonSignificantLogsCheckboxLabel`;
export const nonSignificantLogThrottleInputLabel = `${prefix}.nonSignificantLogThrottleInputLabel`;
export const nonSignificantLogDurationInputLabel = `${prefix}.nonSignificantLogDurationInputLabel`;
export const clientLogFiltersHeader = `${prefix}.clientLogFiltersHeader`;
export const addClientLogFilterButtonLabel = `${prefix}.addClientLogFilterButtonLabel`;

export const ENGLISH = {
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [clientIpMatchLabel]: 'Client IP',
    [pathMatchLabel]: 'Path',
    [columnTitleEnabled]: 'Enabled',
    [columnTitleName]: 'Name',
    [removeButtonLabel]: 'Remove',
    [enableButtonLabel]: 'Enable',
    [disableButtonLabel]: 'Disable',
    [editActionTitle]: 'Edit',
    [matchingRulesHeader]: 'Matching Rules',
    [clientIpLabel]: 'Client IP',
    [addClientLogFilterHeader]: 'Add Client Log Filter',
    [filterNameInputLabel]: 'Filter Name',
    [durationInputLabel]: 'Duration',
    [minInnerText]: 'min',
    [logAllHeadersCheckboxLabel]: 'Log all headers',
    [matchingFilterHeader]: 'Matching Filter',
    [clientIpAddressHeader]: 'Client IP Address',
    [isInRadioInputLabel]: 'Is',
    [isNotInRadioInputLabel]: 'Is not',
    [removeMatchButtonLabel]: 'Remove Match',
    [pathHeader]: 'Path',
    [matchInputLabel]: 'Match',
    [anyMatchInputPlaceholder]: 'Any',
    [clientLogSettingsHeader]: 'Client Log Settings',
    [significantLogThrottleInputLabel]: 'Significant log throttle',
    [userDefinedFiltersLogThrottleInputLabel]: 'User defined filters log throttle',
    [nonSignificantLogsCheckboxLabel]: 'Non-significant logs',
    [nonSignificantLogThrottleInputLabel]: 'Non-significant log throttle',
    [nonSignificantLogDurationInputLabel]: 'Non-significant log duration',
    [clientLogFiltersHeader]: 'Client Log Filters',
    [addClientLogFilterButtonLabel]: 'Add Client Log Filter',
};
