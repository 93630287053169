/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'avi-page-header';
const prefix = `${moduleName}.${componentName}`;

export const buttonLabelText = `${prefix}.buttonLabelText`;

export const ENGLISH = {
    [buttonLabelText]: 'edit',
};
