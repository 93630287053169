/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2022 VMware, Inc.  All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    ControlScriptCollection,
    TControlScriptCollection,
} from 'ajs/modules/scripts/factories/control-script/control-script.collection.factory';

import { ControlScript }
    from 'ajs/modules/scripts/factories/control-script/control-script.item.factory';

import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './control-script.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description ControlScript list page.
 * @author sghare
 */
@Component({
    selector: 'control-script',
    templateUrl: './control-script.component.html',
})
export class ControlScriptComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display ControlScript collection.
     */
    public controlScriptGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the ControlScript items.
     */
    private controlScriptCollection: ControlScriptCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('ControlScriptCollection')
        private readonly ControlScriptCollection: TControlScriptCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.controlScriptCollection = new ControlScriptCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.controlScriptCollection;

        this.controlScriptGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.controlScriptCollection,
            getRowId: (index: number): number => index,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: ControlScript): string => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(
                    globalL10nKeys.noItemsFoundLabel,
                ),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.controlScriptCollection.destroy();
    }
}
