/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'user-tenant-mapping';
const prefix = `${moduleName}.${componentName}`;

export const userTenantLabel = `${prefix}.userTenantLabel`;
export const tenantsLabel = `${prefix}.tenantsLabel`;
export const tenantsPlaceholderLabel = `${prefix}.tenantsPlaceholderLabel`;
export const tenantAttributeNamePlaceholderLabel = `${prefix}.tenantAttributeNamePlaceholderLabel`;
export const tenantAttributeNameLabel = `${prefix}.tenantAttributeNameLabel`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;
export const defaultTenantLabel = `${prefix}.defaultTenantLabel`;
export const defaultTenantPlaceholder = `${prefix}.defaultTenantPlaceholder`;

export const ENGLISH = {
    [userTenantLabel]: 'User Tenant',
    [tenantsLabel]: 'Tenants',
    [tenantsPlaceholderLabel]: 'Select Tenants',
    [tenantAttributeNameLabel]: 'Tenant Attribute Name',
    [tenantAttributeNamePlaceholderLabel]: 'Enter Tenant Attribute Name',
    [typeDropdownPlaceholder]: 'Select Type',
    [defaultTenantLabel]: 'Default Tenant',
    [defaultTenantPlaceholder]: 'Select Default Tenant',
};
