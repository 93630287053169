/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    AuthMappingRule,
    AuthMatchAttribute,
} from 'object-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { AuthMatchOperation } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { AuthMatchAttributeConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-match-attribute.config-item.factory';
import * as l10n from './mapping-rule-attribute-match.l10n';
import './mapping-rule-attribute-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description AuthMappingRule -> AttributeMatch Configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'mapping-rule-attribute-match',
    templateUrl: './mapping-rule-attribute-match.component.html',
})
export class MappingRuleAttributeMatchComponent {
    /**
     * Binding for attributeMatch messageItem.
     */
    @Input()
    public attributeMatch: AuthMatchAttributeConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectTypes used in template.
     */
    public readonly objectTypes = {
        [AuthMappingRule]: AuthMappingRule,
        [AuthMatchAttribute]: AuthMatchAttribute,
    };

    /**
     * AuthMatchOperationEnum hash.
     */
    public readonly authMatchOperationEnumHash = {
        [AuthMatchOperation.AUTH_MATCH_CONTAINS]: AuthMatchOperation.AUTH_MATCH_CONTAINS,
        [AuthMatchOperation.AUTH_MATCH_DOES_NOT_CONTAIN]:
            AuthMatchOperation.AUTH_MATCH_DOES_NOT_CONTAIN,
        [AuthMatchOperation.AUTH_MATCH_REGEX]: AuthMatchOperation.AUTH_MATCH_REGEX,
    };

    /**
     * Hash of AuthMatchOperation value to description
     * Used to display tooltip.
     */
    private readonly authMatchOperationEnumValueDescriptionHash =
    {} as any as Record<AuthMatchOperation, string>;

    constructor(
        schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        const authMatchOperationValues = schemaService.getEnumValues('AuthMatchOperation');

        this.authMatchOperationEnumValueDescriptionHash =
            authMatchOperationValues.reduce((hash, { value, description = '' }) => {
                hash[value] = description;

                return hash;
            }, {} as any as Record<AuthMatchOperation, string>);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns tooltip for radio buttons.
     */
    public getTooltip(authMatchOperationValue: AuthMatchOperation): string {
        return this.authMatchOperationEnumValueDescriptionHash[authMatchOperationValue];
    }

    /**
     * Handler for `Any` Criteria selection.
     * Clears attribute name and values.
     */
    public onClearCriteria(): void {
        this.attributeMatch.config.name = undefined;
        this.attributeMatch.config.values = [];
    }
}
