/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './hm-select.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

class GslbHealthMonitorProxy {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Adds empty Health Monitor Proxy object to specified site.
     */
    addHealthMonitorProxy() {
        const { site } = this;

        site.hm_proxies = site.hm_proxies || [];
        site.hm_proxies.push({
            site_uuid: '',
        });
    }

    /**
     * Removes Health Monitor Proxy object at index specified.
     * @param {number} index
     */
    removeHealthMonitorProxy(index) {
        const { site } = this;

        if (angular.isArray(site.hm_proxies)) {
            site.hm_proxies.splice(index, 1);
        }
    }
}

GslbHealthMonitorProxy.$inject = [
    'l10nService',
];

/**
 * @ngdoc component
 * @nme gslbHmSelect
 * @description
 *      GSLB Site Health Monitor Proxy list create component.
 * @param {GslbSiteConfig} site - GSLB site where HM proxy will be added.
 * @param {GslbSiteConfig[]} sites - Available active GSLB sites.
 */
angular.module('aviApp').component('gslbHmSelect', {
    bindings: {
        site: '<',
        sites: '<',
    },
    controller: GslbHealthMonitorProxy,
    templateUrl: 'src/components/infrastructure/gslb/hm-select/hm-select.html',
});
