/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    PoolCollection,
    PoolGroupCollection,
} from 'ajs/modules/pool';

import {
    DataScriptSet,
    RateLimiterConfigItem,
    TDataScriptSet,
} from 'ajs/modules/scripts';

import {
    dnsEventTypes,
    httpEventTypes,
    l4EventTypes,
    sslEventTypes,
} from 'ajs/modules/scripts/constants';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from 'ng/shared/components';

import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
} from 'ajs/modules/security';

import {
    IVSDataScript,
    SSLCertificateType,
    VSDataScriptEvent,
} from 'generated-types';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { IPReputationDBCollection } from 'ajs/modules/ip-reputation-db';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { GeoDbCollection } from 'ajs/modules/geo-db';

import * as l10n from './data-script-set-modal.l10n';
import './data-script-set-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TPoolCollection = typeof PoolCollection;
type TPoolGroupCollection = typeof PoolGroupCollection;
type TIPReputationDBCollection = typeof IPReputationDBCollection;
type TGeoDbCollection = typeof GeoDbCollection;
type TSslProfileCollection = typeof SSLProfileCollection;
type TCertificateCollection = typeof CertificateCollection;
type TPkiProfileCollection = typeof PKIProfileCollection;

/**
 * @description Modal component for DataScriptSet.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'data-script-set-modal',
    templateUrl: './data-script-set-modal.component.html',
})
export class DataScriptSetModalComponent implements OnInit, OnDestroy {
    /**
     * DataScriptSet instance which is being edited.
     */
    @Input()
    public editable: DataScriptSet;

    /**
     * DataScriptSet objectType
     */
    public objectType: string;

    /**
     * Layout for modal.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * PoolCollection instance.
     */
    public readonly poolCollection: PoolCollection;

    /**
     * PoolGroupCollection instance.
     */
    public readonly poolGroupCollection: PoolGroupCollection;

    /**
     * IPReputationDBCollection instance.
     */
    public readonly ipReputationDBCollection: IPReputationDBCollection;

    // TODO: Update following with correct types once they are converted to TS.
    /**
     * IPAddrGroupCollection instance.
     */
    public readonly ipAddrGroupCollection: any;

    /**
     * StringGroupCollection instance.
     */
    public readonly stringGroupCollection: any;

    /**
     * ProtocolParserCollection instance.
     */
    public readonly protocolParserCollection: any;

    /**
     * SSLProfileCollection instance.
     */
    public readonly sslProfileCollection: SSLProfileCollection;

    /**
     * CertificateCollection instance.
     */
    public readonly certificateCollection: CertificateCollection;

    /**
     * PKIProfileCollection instance.
     */
    public readonly pkiProfileCollection: PKIProfileCollection;

    /**
     * List of all events available to add.
     */
    public eventsTypes: IAviDropdownButtonAction[];

    /**
     * Set of added L4 events.
     */
    public addedL4Events: Set<VSDataScriptEvent> = new Set();

    /**
     * Set of added DNS events.
     */
    public addedDnsEvents: Set<VSDataScriptEvent> = new Set();

    /**
     * Set of added SSL events.
     */
    public addedSslEvents: Set<VSDataScriptEvent> = new Set();

    /**
     * Set of added HTTP events.
     */
    public addedHttpEvents: Set<VSDataScriptEvent> = new Set();

    /**
     * Position of add button's dropdown.
     */
    public buttonDropdownPosition = AviDropdownButtonPosition.TOP_LEFT;

    /**
     * GeoDbCollection instance.
     */
    public readonly geoDbCollection: GeoDbCollection;

    /**
     * Actions for the Certificate collection dropdown menu.
     */
    public certificateDropdownActions: IAviDropdownButtonAction[] = [];

    constructor(
        private readonly l10nService: L10nService,
        @Inject(PoolCollection)
        PoolCollection: TPoolCollection,
        @Inject(PoolGroupCollection)
        PoolGroupCollection: TPoolGroupCollection,
        @Inject('IpAddrGroupCollection')
        IpAddrGroupCollection: any,
        @Inject('StringGroupCollection')
        StringGroupCollection: any,
        @Inject('ProtocolParserCollection')
        ProtocolParserCollection: any,
        @Inject(IPReputationDBCollection)
        IPReputationDBCollection: TIPReputationDBCollection,
        @Inject(GeoDbCollection)
        GeoDbCollection: TGeoDbCollection,
        @Inject(DataScriptSet)
        private readonly DataScriptSet: TDataScriptSet,
        @Inject(SSLProfileCollection)
        SSLProfileCollection: TSslProfileCollection,
        @Inject(CertificateCollection)
        CertificateCollection: TCertificateCollection,
        @Inject(PKIProfileCollection)
        PKIProfileCollection: TPkiProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.poolCollection = new PoolCollection({ isStatic: true });
        this.poolGroupCollection = new PoolGroupCollection({ isStatic: true });
        this.ipAddrGroupCollection = new IpAddrGroupCollection();
        this.stringGroupCollection = new StringGroupCollection();
        this.protocolParserCollection = new ProtocolParserCollection();
        this.ipReputationDBCollection = new IPReputationDBCollection();
        this.geoDbCollection = new GeoDbCollection();
        this.sslProfileCollection = new SSLProfileCollection();
        this.certificateCollection = new CertificateCollection();
        this.pkiProfileCollection = new PKIProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { editable, editable: { config } } = this;

        if (editable.id) {
            config.datascript.forEach((datascript: IVSDataScript) => {
                if (datascript.script) {
                    const { evt } = datascript;

                    this.addEventsByType(evt);
                }
            });
        }

        this.objectType = editable.messageType;
        this.createEventDropdownOptions();
        this.setCertificateDropdownActions();
    }

    /**
     * Add selected event and update the respective event set by event type.
     */
    public handleAddEventsByType(dataScriptEvent: VSDataScriptEvent): void {
        this.addEventsByType(dataScriptEvent);
        this.createEventDropdownOptions();
    }

    /**
     * Remove already added event and update the respective event set by event type.
     * Update the script value for removed event.
     */
    public handleEventRemove(dataScriptEvent: VSDataScriptEvent): void {
        const { editable } = this;

        switch (true) {
            case l4EventTypes.has(dataScriptEvent):
                this.addedL4Events.delete(dataScriptEvent);
                break;
            case dnsEventTypes.has(dataScriptEvent):
                this.addedDnsEvents.delete(dataScriptEvent);
                break;
            case sslEventTypes.has(dataScriptEvent):
                this.addedSslEvents.delete(dataScriptEvent);
                break;
            case httpEventTypes.has(dataScriptEvent):
                this.addedHttpEvents.delete(dataScriptEvent);
        }

        this.createEventDropdownOptions();
        editable.resetDataScript(dataScriptEvent);
    }

    /**
     * Returns human readable string from VSDataScriptEvent enum.
     */
    public getDSEventLabel(dataScriptEvent: VSDataScriptEvent): string {
        return this.DataScriptSet.getDSEventLabel(dataScriptEvent);
    }

    /**
     * Adds a new rateLimiter.
     */
    public handleRateLimiterAdd(): void {
        this.editable.rateLimiters.add();
    }

    /**
     * Deletes a rateLimiter.
     */
    public handleRateLimiterDelete(rateLimiter?: RateLimiterConfigItem): void {
        const { rateLimiters } = this.editable;

        rateLimiters.removeByMessageItem(rateLimiter);
    }

    /** @override */
    public ngOnDestroy(): void {
        [
            this.poolCollection,
            this.poolGroupCollection,
            this.stringGroupCollection,
            this.protocolParserCollection,
            this.ipAddrGroupCollection,
            this.ipReputationDBCollection,
            this.geoDbCollection,
            this.sslProfileCollection,
            this.certificateCollection,
            this.pkiProfileCollection,
        ].forEach((collection: Collection) => collection.destroy());
    }

    /**
     * Returns true if events are added.
     * This validation is to make sure that user at least adds a single event.
     */
    public isEventSelected(): boolean {
        return Boolean(this.addedL4Events.size || this.addedSslEvents.size ||
            this.addedHttpEvents.size || this.addedDnsEvents.size);
    }

    /**
     * Creates the list of events available to add.
     */
    private createEventDropdownOptions(): void {
        const { editable: { config } } = this;

        this.eventsTypes = [];
        config.datascript.forEach((dataScript: IVSDataScript) => {
            if (!this.isEventAdded(dataScript.evt)) {
                const label = this.getDSEventLabel(dataScript.evt);

                this.eventsTypes.push({
                    label,
                    onClick: () => this.handleAddEventsByType(dataScript.evt as VSDataScriptEvent),
                });
            }
        });
    }

    /**
     * Add selected event and update the respective event set by event type.
     */
    private addEventsByType(dataScriptEvent: VSDataScriptEvent): void {
        switch (true) {
            case l4EventTypes.has(dataScriptEvent):
                this.addedL4Events.add(dataScriptEvent);
                break;
            case dnsEventTypes.has(dataScriptEvent):
                this.addedDnsEvents.add(dataScriptEvent);
                break;
            case sslEventTypes.has(dataScriptEvent):
                this.addedSslEvents.add(dataScriptEvent);
                break;
            case httpEventTypes.has(dataScriptEvent):
                this.addedHttpEvents.add(dataScriptEvent);
        }
    }

    /**
     * Returns true if event is already added to respective event set.
     */
    private isEventAdded(dataScriptEvent: VSDataScriptEvent): boolean {
        const {
            addedDnsEvents,
            addedL4Events,
            addedHttpEvents,
            addedSslEvents,
        } = this;

        return addedL4Events.has(dataScriptEvent) || addedDnsEvents.has(dataScriptEvent) ||
            addedSslEvents.has(dataScriptEvent) || addedHttpEvents.has(dataScriptEvent);
    }

    /**
     * Sets actions for SSL/TLS Certificate collection dropdown.
     */
    private setCertificateDropdownActions(): void {
        this.certificateDropdownActions = [
            {
                label: this.l10nService.getMessage(l10nKeys.btnLabelRootIntermediateCaCert),
                onClick: (): void => {
                    this.certificateCollection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_CA,
                    });

                    this.certificateCollection.create();
                },
                disabled: (): boolean => !this.certificateCollection.isCreatable(),
            }, {
                label: this.l10nService.getMessage(l10nKeys.btnLabelApplicationCert),
                onClick: (): void => {
                    this.certificateCollection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_VIRTUALSERVICE,
                    });

                    this.certificateCollection.create();
                },
                disabled: (): boolean => !this.certificateCollection.isCreatable(),
            }, {
                label: this.l10nService.getMessage(l10nKeys.btnLabelControllerCert),
                onClick: (): void => {
                    this.certificateCollection.setDefaultItemConfigProps({
                        type: SSLCertificateType.SSL_CERTIFICATE_TYPE_SYSTEM,
                    });

                    this.certificateCollection.create();
                },
                disabled: (): boolean => !this.certificateCollection.isCreatable(),
            },
        ];
    }
}
