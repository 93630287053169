/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'pool-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleHealth = `${prefix}.columnTitleHealth`;
export const columnTitleVirtualService = `${prefix}.columnTitleVirtualService`;
export const columnTitleServersUpTotal = `${prefix}.columnTitleServersUpTotal`;
export const columnTitlePoolGroups = `${prefix}.columnTitlePoolGroups`;
export const columnTitleCloud = `${prefix}.columnTitleCloud`;
export const columnTitleVrfContext = `${prefix}.columnTitleVrfContext`;
export const columnTitleVirtualServices = `${prefix}.columnTitleVirtualServices`;
export const gridTitleAlerts = `${prefix}.gridTitleAlerts`;
export const gridTitleEnable = `${prefix}.gridTitleEnable`;
export const gridTitleDisable = `${prefix}.gridTitleDisable`;
export const emptyPoolsLabel = `${prefix}.emptyPoolsLabel`;
export const noPoolsMatchSearchCriteriaLabel = `${prefix}.noPoolsMatchSearchCriteriaLabel`;
export const createPoolBtnLabel = `${prefix}.createPoolBtnLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleHealth]: 'Health',
    [columnTitleVirtualService]: 'Virtual Service',
    [columnTitleServersUpTotal]: 'Servers (Up/Total)',
    [columnTitlePoolGroups]: 'Pool Groups',
    [columnTitleCloud]: 'Cloud',
    [columnTitleVrfContext]: 'VRF Context',
    [columnTitleVirtualServices]: '# Virtual Services',
    [gridTitleAlerts]: 'Alerts',
    [gridTitleEnable]: 'Enable',
    [gridTitleDisable]: 'Disable',
    [emptyPoolsLabel]: 'No Pools found.',
    [noPoolsMatchSearchCriteriaLabel]: 'No Pools match your search criteria.',
    [createPoolBtnLabel]: 'Create Pool',
};
