/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    Type,
    ViewChild,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { optionsPositionsPriority } from 'ng/shared/components/avi-dropdown';
import { DialogService } from 'ng/modules/core/services/dialog.service';
import { VsLogsStore } from '../../../services/vs-logs.store';
import { VsLogsSearchBarStore } from './vs-logs-search-bar.store';
import {
    VsLogsSearchBarDropdownService,
} from '../../../services/vs-logs-search-bar-dropdown.service';
import {
    SaveSearchType,
    VsLogsSaveSearchModalComponent,
} from './vs-logs-save-search-modal/vs-logs-save-search-modal.component';

import * as l10n from './vs-logs-search-bar.l10n';
import './vs-logs-search-bar.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const VS_LOGS_SAVE_SEARCH_MODAL_ID = 'VS_LOGS_SAVE_SEARCH_MODAL';

/**
 * @description VS Logs search bar component.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-search-bar',
    templateUrl: './vs-logs-search-bar.component.html',
    providers: [VsLogsSearchBarStore],
})
export class VsLogsSearchBarComponent implements AfterViewInit {
    /**
     * Element ref for the search bar container.
     */
    @ViewChild('searchBarContainer')
    public searchBarContainerRef: ElementRef;

    /**
     * Element ref for the search icon.
     */
    @ViewChild('searchIcon')
    public searchIconRef: ElementRef;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Save enum as class property for access in view.
     */
    public readonly SaveSearchTypeEnum = SaveSearchType;

    /**
     * Position definition of the dropdown relative to the search bar.
     */
    public readonly dropdownPosition: ConnectedPosition[] = optionsPositionsPriority;

    /**
     * Calculated width of the dropdown.
     */
    public dropdownWidth: number;

    constructor(
        l10nService: L10nService,
        public readonly vsLogsStore: VsLogsStore,
        public readonly vsLogsSearchBarStore: VsLogsSearchBarStore,
        public readonly vsLogsSearchBarDropdownService: VsLogsSearchBarDropdownService,
        private readonly dialogService: DialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.dropdownWidth = this.searchBarContainerRef.nativeElement.offsetWidth -
            this.searchIconRef.nativeElement.offsetWidth;
    }

    /**
     * Callback to ngFor-track by.
     */
    // eslint-disable-next-line class-methods-use-this
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Open the save search modal.
     */
    public openSaveSearchModal(type: SaveSearchType): void {
        this.dialogService.add({
            id: VS_LOGS_SAVE_SEARCH_MODAL_ID,
            component: VsLogsSaveSearchModalComponent as Type<Component>,
            componentProps: {
                saveSearchType: type,
                onClose: () => this.dialogService.remove(VS_LOGS_SAVE_SEARCH_MODAL_ID),
            },
        });
    }
}
