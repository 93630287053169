/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { HSMSafenetLunaServerConfigItem } from 'ajs/modules/security';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-safenet-luna-server-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Thales Luna HSM Server modal component.
 * @author vgohil
 */

@Component({
    selector: 'hsm-group-safenet-luna-server-modal',
    templateUrl: './hsm-group-safenet-luna-server-modal.component.html',
})
export class HsmGroupSafenetLunaServerModalComponent implements OnInit {
    /**
     * Thales Luna HSM Server item instance.
     */
    @Input()
    public editable: HSMSafenetLunaServerConfigItem;

    /**
     * boolean flag to check if edit or add mode - HSMSafenetLunaServerConfigItem doesn't have id.
     */
    @Input()
    public editMode = false;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<HSMSafenetLunaServerConfigItem>();

    /**
     * Thales Luna HSM Server object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles to use in template.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Thales Luna HSM Server modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
