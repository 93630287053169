/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './paa-log.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.paaLogComponentBindings
 * @see {@link  module:avi/logs.paaLogComponent}
 */
class PaaLogController {
    /**
     * Updates the log search param to payload.
     * @param {string} str - log search param.
     */
    onSearchUpdate(str) {
        this.updateSearch({ str });
    }
}

/**
 * @name paaLogComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.paaLogComponentBindings} bindings
 * @property {module:avi/logs.PaaLogController} controller
 * @description PingAccess Agent Log component.
 * @author chitra
 */
angular.module('avi/logs').component('paaLog', {
    /**
     * @mixin paaLogComponentBindings
     * @memberOf module:avi/logs
     * @property {Object} log - PingAccess Agent Log info
     * @property {Function} updateSearch - Callback method to be called for log filter click.
     */
    bindings: {
        log: '<',
        updateSearch: '&',
    },
    controller: PaaLogController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/paa-log/' +
        'paa-log.component.html',
});
