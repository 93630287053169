/**
 * @module NotificationModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    OnInit,
    Output,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import * as moment from 'moment';
import {
    Auth,
    InitialDataService,
} from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';
import './utc-mismatch-notification.less';
import * as l10n from './utc-mismatch-notification.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      UtcMismatchNotificationComponent is used to show the notification message when
 *      there is a time mismatch in client and server clocks.
 *      This component will be wrapped into a popup component.
 * @example
 *      <utc-mismatch-notification (onClose)='closePopover()'></utc-mismatch-notification>
 * @author chitra
 */
@Component({
    selector: 'utc-mismatch-notification',
    templateUrl: './utc-mismatch-notification.html',
})
export class UtcMismatchNotificationComponent implements OnInit {
    @Output() public onClose = new EventEmitter();

    public serverTime: string;
    public timeDiff: number;
    public timeDiffInHrMin: string;
    public localClockIsBehind: boolean;
    public userHasAccessToSysConfig: boolean;
    public checkNtpSettingsMessageList: string[] | undefined;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        private readonly authService: Auth,
        private readonly $state: StateService,
        private readonly initialDataService: InitialDataService,
        l10nService : L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.checkNtpSettingsMessageList =
            l10nService.getSplitedMessage(this.l10nKeys.checkNtpSettingsMessage);
    }

    /**
     * Calculates and formats the time mismatch between client and controller's clocks.
     */
    public ngOnInit(): void {
        const { controllerTimeDifference } = this.initialDataService;
        const clientUtcTime = moment.utc();
        const diffInSec = moment.duration(Math.abs(controllerTimeDifference), 's');

        // add/subtract matching the way diff was calculated
        this.serverTime = clientUtcTime.subtract(controllerTimeDifference, 's').format('L LTS');
        this.timeDiff = Math.abs(controllerTimeDifference); // no point to have sign there
        this.timeDiffInHrMin = `${diffInSec.hours()}hr:${diffInSec.minutes()}min`;
        this.localClockIsBehind = controllerTimeDifference < 0;
        this.userHasAccessToSysConfig = this.authService.isAllowed('systemconfiguration');
    }

    /**
     * Close handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Redirects to system configuration page.
     */
    public redirectToSystemConfig(): void {
        this.$state.go('authenticated.administration.system-settings');
    }
}
