/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    findIndex,
    identity,
    isEmpty,
    isUndefined,
    max,
    pick,
} from 'underscore';

import {
    extend,
    IHttpResponse,
} from 'angular';

import {
    IBaseRequestPromise,
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { L10nService } from '@vmw/ngx-vip';
import { WafPolicy } from 'object-types';
import { IWafPolicy } from 'generated-types';

import * as globalL10n from 'global-l10n';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { WafCrs } from '../waf-crs.item.factory';
import { WAF_CRS_COLLECTION_TOKEN } from '../waf-crs.collection.factory';
import * as l10n from '../../waf.l10n';
import { WafRuleConfigItem } from '../waf-rule.config-item.factory';
import { WafRuleGroupConfigItem } from '../waf-rule-group.config-item.factory';
import { WafRuleGroupOverridesConfigItem } from '../waf-rule-group-overrides.config-item.factory';
import { WafPolicyAllowlistConfigItem } from '../waf-policy-allowlist.config-item.factory';

import {
    WafApplicationSignaturesConfigItem,
} from '../waf-application-signatures.config-item.factory';

import { WafPolicyAllowlistRuleConfigItem } from '../waf-policy-allowlist-rule.config-item.factory';

import {
    WafPositiveSecurityModelConfigItem,
} from '../waf-positive-security-model.config-item.factory';

import { WafRuleOverridesConfigItem } from '../waf-rule-overrides.config-item.factory';
import { WafExcludeListEntryConfigItem } from '../waf-exclude-list-entry.config-item.factory';

export const PRE_CRS_GROUPS_FIELD = 'pre_crs_groups';
export const CRS_GROUPS_FIELD = 'crs_groups';
export const POST_CRS_GROUPS_FIELD = 'post_crs_groups';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Waf policy feilds to be omited.
 */
type TWafPolicyOmitFields = 'crs_overrides' |
'pre_crs_groups' |
'crs_groups' |
'post_crs_groups' |
'allowlist' |
'application_signatures' |
'positive_security_model';

/**
 * Custom Waf Policy Type.
 */
type TWafPolicyPartial = Omit<IWafPolicy, TWafPolicyOmitFields>;

/**
 * Interface for IWafPolicyConfig
 */
export interface IWafPolicyConfig extends TWafPolicyPartial {
    crs_overrides?: RepeatedMessageItem<WafRuleGroupOverridesConfigItem>;
    pre_crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    post_crs_groups?: RepeatedMessageItem<WafRuleGroupConfigItem>;
    allowlist?: MessageItem<WafPolicyAllowlistConfigItem>;
    application_signatures?: MessageItem<WafApplicationSignaturesConfigItem>;
    positive_security_model?: MessageItem<WafPositiveSecurityModelConfigItem>;

}

interface IWafPolicySaveRequestPayload {
    model_name: string;
    data: IWafPolicyConfig;
}

/**
 * @description Waf Policy Item class
 *
 * @author Hitesh Mandav
 */
export class WafPolicyItem extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static groupsProperties = [
        PRE_CRS_GROUPS_FIELD,
        CRS_GROUPS_FIELD,
        POST_CRS_GROUPS_FIELD,
    ];

    public static ajsDependencies = [
        'WafRuleGroupConfigItem',
        '$q',
        'WafPolicyPsmGroupCollection',
        WAF_CRS_COLLECTION_TOKEN,
        'l10nService',
    ];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    /**
     * CRS Map for groupname to ruleId.
     */
    private crsGroupNameToRuleIdsMap: Map<string, Set<string>>;

    /**
     * RS Map for ruleId to groupname.
     */
    private crsRuleIDToGroupName: Map<string, string>;

    /**
     * onject to map rule id to waf rule config item.
     */
    private ruleIdToRuleHash: Record<string, WafRuleConfigItem>;

    /**
     * object to  map group name to waf rule group config item.
     */
    private groupNameToGroupHash: Record<string, WafRuleGroupConfigItem>;

    /**
     * Map for CRS group overide name to group override config.
     */
    private crsGroupOverridesMap: Map<string, WafRuleGroupOverridesConfigItem>;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpolicy',
            windowElement: 'lazy-load',
            params: {
                include_name: true,
                join: [
                    'wafpolicypsmgroup:positive_security_model.group_refs',
                    'waf_crs_ref',
                ],
            },
            permissionName: 'PERMISSION_WAFPOLICY',
            objectType: WafPolicy,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        this.crsGroupNameToRuleIdsMap = new Map();
        this.crsRuleIDToGroupName = new Map();

        if (this.getConfig()) {
            const config = this.getConfig();
            const { waf_crs_ref_data: wafCrsRefData } = config;

            if (wafCrsRefData) {
                if (!(wafCrsRefData instanceof WafCrs)) {
                    config.waf_crs_ref_data = this.createWafCrs(wafCrsRefData);
                }

                this.setCrsLookupMaps();
            }

            this.ruleIdToRuleHash = this.getRuleIdToRuleHash();
            this.groupNameToGroupHash = this.getGroupNameToGroupHash();
            this.setCrsGroupOverridesMap();
        }
    }

    /**
     * @override
     */
    public urlToSave(): string {
        return '/api/macro';
    }

    /**
     * After save, PSM groups_refs_data objects are returned outside of the WafPolicy data as
     * part of the rsp.data array. We need to merge them back into config.
     * @override
     */
    public transformDataAfterSave(rsp: IHttpResponse<any>): IWafPolicyConfig {
        const { data: responses } = rsp;

        // If response contains array of objects, the last object is the one that we need
        if (!Array.isArray(responses)) {
            return responses;
        }

        const { length: responseLength } = responses;
        const wafPolicyData = responses[responseLength - 1];

        // No groups_refs_data.
        if (responseLength === 1) {
            return wafPolicyData;
        }

        const { positive_security_model: psm = {} } = wafPolicyData;
        const { group_refs: psmGroupRefs = [] } = psm;
        const groupRefDataHash = responses.reduce((acc, groupRefData, index) => {
            if (index === responseLength - 1) {
                return acc;
            }

            // _last_modified is returned in the rsp. If we send _last_modified within the
            // groupRefData, the backend will complain.
            /* eslint no-underscore-dangle: 0 */
            delete groupRefData._last_modified;
            acc[groupRefData.url] = { ...groupRefData };

            return acc;
        }, {});

        if (psmGroupRefs.length) {
            psm.group_refs_data =
                psmGroupRefs.map((groupRef: string) => groupRefDataHash[groupRef]);
        }

        return wafPolicyData;
    }

    /**
     * @override
     */
    public transformAfterLoad(): void {
        const config = this.getConfig();
        const { waf_crs_ref_data: wafCrsRefData } = config;

        if (wafCrsRefData) {
            if (!(wafCrsRefData instanceof WafCrs)) {
                config.waf_crs_ref_data = this.createWafCrs(wafCrsRefData);
            }

            this.setCrsLookupMaps();
        }

        this.ruleIdToRuleHash = this.getRuleIdToRuleHash();
        this.groupNameToGroupHash = this.getGroupNameToGroupHash();
        this.setCrsGroupOverridesMap();
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();
        const {
            allowlist,
            positive_security_model: psm,
        } = config;

        if (isEmpty(allowlist)) {
            this.setNewChildByField('allowlist');
        }

        if (isEmpty(psm)) {
            this.setNewChildByField('positive_security_model');
        }
    }

    /**
     * @override
     */
    public dataToSave(): IWafPolicyConfig {
        const config = super.dataToSave();

        // Delete WAF CRS data, used only as a reference for the user to see the originally
        // configured data.
        delete config.waf_crs_ref_data;

        return config;
    }

    /**
     * Returns true if any PSM Groups are learning groups.
     */
    public hasLearningGroup(): boolean {
        return this.config.positive_security_model?.hasLearningGroup();
    }

    /**
     * Returns a WafRuleGroupConfig ConfigItem given the group name.
     */
    public getGroupByGroupName(groupName: string): WafRuleGroupConfigItem {
        return this.groupNameToGroupHash[groupName];
    }

    /**
     * Returns a WafRuleConfig ConfigItem given a rule ID.
     */
    public getRuleByRuleId(ruleId: string): WafRuleConfigItem {
        return this.ruleIdToRuleHash[ruleId];
    }

    /**
     * Returns the name of a rule given a rule ID. If rule is not found, returns the rule
     * ID.
     */
    public getRuleNameByRuleId(ruleId: string, fullName: boolean): string {
        const ruleConfigItem = this.getRuleByRuleId(ruleId);

        return ruleConfigItem ? ruleConfigItem.getRuleName(fullName) : '';
    }

    /**
     * Returns the max index of all the groups within config[groupsProperty].
     */
    public getMaxGroupIndex(groupsProperty: string): number {
        const config = this.getConfig();
        const maxIndexGroup = max(config[groupsProperty].config, group => group.getIndex());

        return !isEmpty(maxIndexGroup) && maxIndexGroup.getIndex() + 1 || 0;
    }

    /**
     * Returns a new WafRuleGroupConfig instance.
     */
    public addNewGroup(groupsProperty: string, groupToCreateAbove: WafRuleGroupConfigItem): void {
        const WafRuleGroupConfigItem = this.getAjsDependency_('WafRuleGroupConfigItem');
        const { [groupsProperty]: repeatedGroup } = this.getConfig();
        const maxIndexGroup = max(repeatedGroup.config, group => group.getIndex());
        const newIndex = !isEmpty(maxIndexGroup) ? maxIndexGroup.getIndex() + 1 : 0;
        const newGroup = this.createChildByField_(
            groupsProperty,
            { index: newIndex },
            true,
            false,
            { newGroup: true },
        );

        repeatedGroup.add(newGroup);

        if (groupToCreateAbove instanceof WafRuleGroupConfigItem) {
            const targetIndex = groupToCreateAbove.getIndex();
            const targetArrayIndex = this.getArrayIndexFromGroupIndex(
                groupsProperty,
                targetIndex,
            );

            const currentArrayIndex = repeatedGroup.config.length - 1;

            this.moveGroup(groupsProperty, currentArrayIndex, targetArrayIndex);
        }
    }

    /**
     * Removes a group.
     */
    public removeGroup(groupsProperty: string, group: WafRuleGroupConfigItem): void {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        repeatedGroup.removeByMessageItem(group);
    }

    /**
     * Returns the array position index from the group.index property.
     */
    public getArrayIndexFromGroupIndex(groupsProperty: string, groupIndex: number): number {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        return findIndex(repeatedGroup.config, group => group.getIndex() === groupIndex);
    }

    /**
     * Moves group to a new index. All groups in-between need to have their indices shifted.
     */
    public moveGroup(groupsProperty: string, oldIndex: number, newIndex: number): void {
        let newIndexCounter = newIndex;
        /**
         * newIndex moves towards the direction of oldIndex
         */
        const increment = oldIndex < newIndex ? -1 : 1;

        while (oldIndex !== newIndexCounter) {
            this.swapGroup(groupsProperty, oldIndex, newIndexCounter);
            newIndexCounter += increment;
        }
    }

    /**
     * Given two indices of groups, swaps positions in the rules array along with the index
     * property in the rule.
     */
    public swapGroup(groupsProperty: string, oldIndex: number, newIndex: number): void {
        const { [groupsProperty]: repeatedGroup } = this.getConfig();

        const oldGroup = repeatedGroup.at(oldIndex);
        const newGroup = repeatedGroup.at(newIndex);

        repeatedGroup.config[oldIndex] = newGroup;
        repeatedGroup.config[newIndex] = oldGroup;

        /**
         * Actual 'index' property of the rule.
         */
        const oldIndexValue = oldGroup.getIndex();
        const newIndexValue = newGroup.getIndex();

        oldGroup.setIndex(newIndexValue);
        newGroup.setIndex(oldIndexValue);
    }

    /**
     * Adds a group to the WafPolicy. If the group is a new group and does not contain an
     * index, add it to the end of the list. Otherwise, replace an existing group with the
     * group as it has been edited.
     */
    public saveGroup(newGroup: WafRuleGroupConfigItem): void {
        const fieldName = newGroup.getFieldName();
        const { [fieldName]: repeatedGroup } = this.getConfig();

        if (isUndefined(newGroup.getIndex())) {
            const maxIndexGroup = max(repeatedGroup.config, group => group.getIndex());
            const newIndex = !isEmpty(maxIndexGroup) ? maxIndexGroup.getIndex() + 1 : 0;

            newGroup.setIndex(newIndex);
            repeatedGroup.add(newGroup);
        } else {
            const newIndex = newGroup.getIndex();
            const oldIndex = findIndex(repeatedGroup.config, group => {
                return group.getIndex() === newIndex;
            });

            // Index exists, so rule is being edited.
            if (oldIndex !== -1) {
                repeatedGroup.config[oldIndex] = newGroup;
            } else {
                repeatedGroup.add(newGroup);
            }
        }
    }

    /**
     * Returns the ConfigItem based on the type and identifier.
     */
    public getConfigItemById(type: string, id: string): WafRuleConfigItem | WafRuleGroupConfigItem {
        return type === 'rule' ? this.getRuleByRuleId(id) : this.getGroupByGroupName(id);
    }

    /**
     * Returns true if the group or rule already contains the exception.
     */
    public hasMatchingException(type: string, id: string, exception: any): boolean {
        const configItem = this.getConfigItemById(type, id);

        return configItem?.hasMatchingException(exception) || false;
    }

    /**
     * Adds an exception to the group or rule.
     */
    public addException(type: string, id: string, exception: WafExcludeListEntryConfigItem): void {
        // If CRS group or rule.
        if (this.crsGroupNameToRuleIdsMap.has(id) || this.crsRuleIDToGroupName.has(id)) {
            const isGroup = this.crsGroupNameToRuleIdsMap.has(id);
            const groupName = isGroup ? id : this.crsRuleIDToGroupName.get(id);
            let hasExistingGroupOverride = false;
            let hasExistingRuleOverride = false;
            let overrideExcludeList: RepeatedMessageItem<WafExcludeListEntryConfigItem>;
            let groupOverride = this.getCrsGroupOverrideByName(groupName);
            let ruleOverride = groupOverride?.getRuleOverrideByID(id);

            // If exception is for a CRS rule. Based on what the exception is getting added to,
            // makes sure that the proper parent messageItem has been created.
            if (!isGroup) {
                if (ruleOverride) {
                    // Configuring existing ruleOverride.
                    hasExistingRuleOverride = true;
                    hasExistingGroupOverride = true;
                } else if (groupOverride) {
                    // Need to add new ruleOverride on an existing groupOverride.
                    hasExistingGroupOverride = true;
                    ruleOverride = groupOverride.createRuleOverride({ rule_id: id });
                } else {
                    // Need to add new ruleOverride along with a new groupOverride.
                    groupOverride = this.createCrsGroupOverride({ name: groupName });
                    ruleOverride = groupOverride.createRuleOverride({ rule_id: id });
                }

                overrideExcludeList = ruleOverride.excludeList;
            } else {
                if (groupOverride) {
                    // Configuring existing groupOverride.
                    hasExistingGroupOverride = true;
                } else {
                    // Add new groupOverride.
                    groupOverride = this.createCrsGroupOverride({ name: groupName });
                }

                overrideExcludeList = groupOverride.excludeList;
            }

            overrideExcludeList.add(exception);

            if (!isGroup && !hasExistingRuleOverride) {
                groupOverride.ruleOverrides.add(ruleOverride);
                groupOverride.addRuleOverridesMap(ruleOverride.getId(), ruleOverride);
            }

            if (!hasExistingGroupOverride) {
                this.config.crs_overrides.add(groupOverride);
                this.crsGroupOverridesMap.set(groupOverride.getName(), groupOverride);
            }
            // If Pre-CRS or Post-CRS group/rule.
        } else {
            const configItem = this.getConfigItemById(type, id);

            configItem.addExcludeListEntry(pick(exception, identity));
        }
    }

    /**
     * Returns true if policy mode delegation is allowed.
     */
    public modeDelegationIsAllowed(): boolean {
        return Boolean(this.getConfig().allow_mode_delegation);
    }

    /**
     * Returns policy mode.
     */
    public getMode(): string {
        return this.getConfig().mode;
    }

    /**
     * Updates the WafPolicy with a set of CRS rules.
     */
    public updateCrsRules(wafCrsUuid: string, commit = false): IBaseRequestPromise<any> {
        const $q = this.getAjsDependency_('$q');
        const wafPolicyUuid = this.id;
        const api = `/api/wafpolicy/${wafPolicyUuid}/update-crs-rules`;
        const payload = {
            waf_crs_ref: wafCrsUuid,
            commit,
        };

        this.busy = true;
        this.errors = null;

        return this.request('PUT', api, payload)
            .catch(({ data }) => {
                this.errors = data.error;

                return $q.reject(data.error);
            })
            .finally(() => this.busy = false);
    }

    /**
     * Getter for the allowlist property.
     */
    public get allowlist(): WafPolicyAllowlistConfigItem {
        return this.getConfig().allowlist;
    }

    /**
     * Getter for Application signature object.
     */
    public get applicationSignatures(): WafApplicationSignaturesConfigItem {
        return this.getConfig().application_signatures;
    }

    /**
     * Returns the number of configured allowlist rules.
     */
    public getAllowlistRulesCount(): number {
        return this.allowlist.getRulesCount();
    }

    /**
     * Returns a new WafPolicyAllowlistRule instance.
     */
    public createNewAllowlistRule(): WafPolicyAllowlistRuleConfigItem {
        return this.allowlist.createNewRule();
    }

    /**
     * Adds an allowlist rule to the config.
     */
    public addAllowlistRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.allowlist.addRule(rule);
    }

    /**
     * Getter for the positive_security_model config object.
     */
    public get psm(): WafPositiveSecurityModelConfigItem {
        return this.getConfig().positive_security_model;
    }

    /**
     * Getter for the 'enable_app_learning' flag.
     */
    public get appLearning(): boolean {
        return this.getConfig().enable_app_learning;
    }

    /**
     * Setter for the 'enable_app_learning` flag.
     */
    public set appLearning(enableAppLearning: boolean) {
        this.getConfig().enable_app_learning = enableAppLearning;
    }

    /**
     * Setter for the 'allow_mode_delegation` flag.
     */
    public set allowModeDelegation(allowModeDelegationValue: boolean) {
        this.getConfig().allow_mode_delegation = allowModeDelegationValue;
    }

    /**
     * Resets the mode for all rules of all CRS groups.
     */
    public resetModeOverrideForCrsRules(): void {
        this.crsOverrides.config.forEach((groupOverride: WafRuleGroupOverridesConfigItem) => {
            groupOverride.ruleOverrides.config
                .forEach((ruleOverride: WafRuleOverridesConfigItem) => {
                    ruleOverride.mode = undefined;
                });
        });
    }

    /**
     * Getter function for an enum to be used for the 'min_confidence' value.
     */
    public get minConfidence(): string {
        return this.getConfig().min_confidence;
    }

    /**
     * Setter function to set the 'min_confidence' enum value.
     */
    public set minConfidence(enumValue: string) {
        this.getConfig().min_confidence = enumValue;
    }

    /**
     * Sets the learning_params object to its default values in this.data.config.config. (Not a
     * typo)
     */
    public setDefaultAppLearningParams(): void {
        this.safeSetNewChildByField('learning_params');
    }

    /**
     * Returns learning data.
     */
    public fetchLearningData(vsId: string): IBaseRequestPromise<any> {
        const requestConfig = {
            method: 'GET',
            url: '/api/analytics/learning/virtualservice',
            params: {
                app_id: `${vsId}:${this.id}`,
                stats: true,
                confidence: 'certain',
            },
        };

        return this.request(requestConfig);
    }

    /**
     * Adds a new Pre-CRS WAF rule group.
     * @param groupToCreateAbove - if provided, then the rule group will be created above the
     * provided group.
     */
    public addPreCrsWafRuleGroup(
        modalBindings: Record<string, any>,
        groupToCreateAbove?: WafRuleGroupConfigItem,
    ): void {
        this.addWafRuleGroup(PRE_CRS_GROUPS_FIELD, groupToCreateAbove, modalBindings);
    }

    /**
     * Adds a new Post-CRS WAF rule group.
     * @param groupToCreateAbove - if provided, then the rule group will be created above the
     * provided group.
     */
    public addPostCrsWafRuleGroup(
        modalBindings: Record<string, any>,
        groupToCreateAbove?: WafRuleGroupConfigItem,
    ): void {
        this.addWafRuleGroup(POST_CRS_GROUPS_FIELD, groupToCreateAbove, modalBindings);
    }

    /**
     * Adds a new rule group. Only applies to Pre-CRS and Post-CRS as CRS Groups are not allowed to
     * be added.
     */
    public addWafRuleGroup(
        field: string,
        groupToCreateAbove: WafRuleGroupConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const repeatedGroup = this.config[field];
        const WafRuleGroupConfigItem = this.getAjsDependency_('WafRuleGroupConfigItem');
        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.createPreCrsGroupModalHeader :
            l10nKeys.createPostCrsGroupModalHeader;

        let setGroupPosition;

        if (groupToCreateAbove instanceof WafRuleGroupConfigItem) {
            const targetIndex = groupToCreateAbove.getIndex();
            const targetArrayIndex = this.getArrayIndexFromGroupIndex(
                field,
                targetIndex,
            );

            setGroupPosition = () => {
                const currentArrayIndex = repeatedGroup.count - 1;

                this.moveGroup(field, currentArrayIndex, targetArrayIndex);
            };
        }

        this.addChildMessageItem({
            field,
            modalBindings: {
                modalHeaderKey,
                setGroupPosition,
                ...modalBindings,
            },
        });
    }

    /**
     * Edits an existing rule group. Only applies to Pre-CRS and Post-CRS as CRS Groups are not
     * directly editable.
     */
    public editWafRuleGroup(
        wafRuleGroup: WafRuleGroupConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        const field = wafRuleGroup.getFieldName();

        if (field !== PRE_CRS_GROUPS_FIELD && field !== POST_CRS_GROUPS_FIELD) {
            return;
        }

        const modalHeaderKey = field === PRE_CRS_GROUPS_FIELD ?
            l10nKeys.editPreCrsGroupModalHeader :
            l10nKeys.editPostCrsGroupModalHeader;

        this.editChildMessageItem({
            field,
            messageItem: wafRuleGroup,
            modalBindings: {
                modalHeaderKey,
                ...modalBindings,
            },
        });
    }

    /**
     * Creates a map of CRS group override names to the group override.
     */
    public createCrsGroupOverridesMap(): Map<string, WafRuleGroupOverridesConfigItem> {
        const map = new Map();
        const { crs_overrides: overrides } = this.getConfig();

        overrides.config.forEach((override: WafRuleGroupOverridesConfigItem) => {
            map.set(override.getName(), override);
        });

        return map;
    }

    /**
     * Returns the CRS group overrides RepeatedMessageItem.
     */
    public get crsOverrides(): RepeatedMessageItem<WafRuleGroupOverridesConfigItem> {
        return this.config.crs_overrides;
    }

    /**
     * Returns the configured CRS group override by the original group's name.
     */
    public getCrsGroupOverrideByName(groupName: string)
        : WafRuleGroupOverridesConfigItem | undefined {
        return this.crsGroupOverridesMap.get(groupName);
    }

    /**
     * Creates a new WafRuleGroupOverridesConfigItem instance.
     */
    public createCrsGroupOverride(
        config: Record<string, any> = null,
    ): WafRuleGroupOverridesConfigItem {
        return this.createChildByField(
            'crs_overrides',
            config,
            true,
        ) as WafRuleGroupOverridesConfigItem;
    }

    /**
     * Called to add a new group override.
     */
    public addCrsGroupOverride(
        group: WafRuleGroupConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        const { name, enable } = group.getConfig();
        const newConfig = {
            name,
            enable,
        };

        this.editCrsGroupOverride(
            this.createCrsGroupOverride(newConfig),
            group,
            modalBindings,
        );
    }

    /**
     * Called to edit an existing group override.
     */
    public editCrsGroupOverride(
        groupOverride: WafRuleGroupOverridesConfigItem,
        originalGroup: WafRuleGroupConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        const { enable } = originalGroup.config;

        // If the override's enable flag is undefined, set it to the original rule's enable value.
        if (isUndefined(groupOverride.enable)) {
            groupOverride.enable = enable;
        }

        this.editChildMessageItem({
            field: 'crs_overrides',
            messageItem: groupOverride,
            modalBindings: {
                modalHeaderKey: l10nKeys.editCrsGroupModalHeader,
                ...modalBindings,
            },
        }).then((groupOverride: WafRuleGroupOverridesConfigItem) => {
            this.crsGroupOverridesMap.set(groupOverride.getName(), groupOverride);
        });
    }

    /**
     * Called to remove an existing group override.
     */
    public removeCrsGroupOverride(groupOverride: WafRuleGroupOverridesConfigItem): void {
        this.crsOverrides.removeByMessageItem(groupOverride);
        this.crsGroupOverridesMap.delete(groupOverride.getName());
    }

    /**
     * Called to add a new CRS rule override. If a group override doesn't already exist for the
     * group, it needs to be created first.
     */
    public addCrsRuleOverride(
        group: WafRuleGroupConfigItem,
        rule: WafRuleConfigItem,
        modalBindings: Record<string, any>,
    ): void {
        const groupOverride = this.getCrsGroupOverrideByName(group.getName());

        if (groupOverride) {
            groupOverride.addRuleOverride(rule, modalBindings);
        } else {
            const { name } = group.config;
            const newGroupOverride: WafRuleGroupOverridesConfigItem =
                this.createChildByField(
                    'crs_overrides',
                    { name },
                    true,
                ) as WafRuleGroupOverridesConfigItem;

            newGroupOverride.addRuleOverride(rule, modalBindings).then(() => {
                this.crsOverrides.add(newGroupOverride);
                this.crsGroupOverridesMap.set(newGroupOverride.getName(), newGroupOverride);
            });
        }
    }

    /**
     * Called to edit an allowlist rule.
     */
    public editAllowlistRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.config.allowlist.editRule(rule);
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-policy-modal.component'
        );

        return WafPolicyModalComponent as Type<Component>;
    }

    /**
     * Set overrides map used for lookup.
     */
    public setCrsGroupOverridesMap(): void {
        this.crsGroupOverridesMap = this.createCrsGroupOverridesMap();
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['application_signatures'];
    }

    /**
     * @override
     */
    protected createSaveRequestPayload_(dataToSave: IWafPolicyConfig)
        : IWafPolicySaveRequestPayload {
        return {
            model_name: 'wafpolicy',
            data: dataToSave,
        };
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WafProfile.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(globalL10nKeys.wafPolicyLabel);
    }

    /**
     * Creates a new WafCrs instance.
     */
    private createWafCrs(config: WafCrs): WafCrs {
        const WafCrsCollection = this.getAjsDependency_(WAF_CRS_COLLECTION_TOKEN);
        const wafCrsCollection = new WafCrsCollection();
        const wafCrsMessageItem = wafCrsCollection.createNewItem({
            data: {
                config,
            },
        }, true);

        wafCrsCollection.destroy();

        return wafCrsMessageItem;
    }

    /**
     * Creates CRS lookup maps to track configured CRS groups and rules.
     */
    private setCrsLookupMaps(): void {
        this.crsGroupNameToRuleIdsMap = new Map();
        this.crsRuleIDToGroupName = new Map();

        const { waf_crs_ref_data: wafCrs } = this.getConfig();

        wafCrs.config.groups.config.forEach((group: WafRuleGroupConfigItem) => {
            const ruleIds = new Set<string>();
            const groupName = group.getName();

            group.config.rules.config.forEach((rule: WafRuleConfigItem) => {
                const ruleID = rule.getId();

                ruleIds.add(ruleID);
                this.crsRuleIDToGroupName.set(ruleID, groupName);
            });

            this.crsGroupNameToRuleIdsMap.set(groupName, ruleIds);
        });
    }

    /**
     * Returns a hash of all rule IDs to Rules within the Waf Policy. Used for lookup.
     */
    private getRuleIdToRuleHash(): Record<string, WafRuleConfigItem> {
        const output = {};
        const config = this.getConfig();
        const { waf_crs_ref_data: wafCrs } = config;

        WafPolicyItem.groupsProperties.forEach(groupProperty => {
            const repeatedGroupConfigItems = config[groupProperty];

            if (!repeatedGroupConfigItems || repeatedGroupConfigItems.isEmpty()) {
                return;
            }

            repeatedGroupConfigItems.config.forEach((groupConfigItem: WafRuleGroupConfigItem) => {
                extend(output, groupConfigItem.getRuleIdHash());
            });
        });

        if (wafCrs) {
            wafCrs.config.groups.config.forEach((group: WafRuleGroupConfigItem) => {
                Object.assign(output, group.getRuleIdHash());
            });
        }

        return output;
    }

    /**
     * Returns a hash of all group names to Groups within the Waf Policy. Used for lookup.
     */
    private getGroupNameToGroupHash(): Record<string, WafRuleGroupConfigItem> {
        const output = {};
        const config = this.getConfig();
        const { waf_crs_ref_data: wafCrs } = config;

        WafPolicyItem.groupsProperties.forEach(groupProperty => {
            const repeatedGroupConfigItems = config[groupProperty];

            if (!repeatedGroupConfigItems || repeatedGroupConfigItems.isEmpty()) {
                return;
            }

            repeatedGroupConfigItems.config.forEach((groupConfigItem: WafRuleGroupConfigItem) => {
                output[groupConfigItem.getName()] = groupConfigItem;
            });
        });

        if (wafCrs) {
            wafCrs.config.groups.config.forEach((group: WafRuleGroupConfigItem) => {
                output[group.getName()] = group;
            });
        }

        return output;
    }
}
