/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'header';
const componentName = 'user-card-menu';
const prefix = `${moduleName}.${componentName}`;

export const signOutLabel = `${prefix}.signOutLabel`;
export const aboutLabel = `${prefix}.aboutLabel`;

export const ENGLISH = {
    [signOutLabel]: 'Sign Out',
    [aboutLabel]: 'About NSX ALB (Avi)',
};
