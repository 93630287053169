/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './upload-geo-files-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  uploadGeoFilesPage
 * @description  Page for Uploading Geo Files.
 */
class UploadGeoFilesController {
    constructor(CollateUpload, l10nService) {
        this.upload = new CollateUpload(
            {
                uri: 'controller://gslb',
                chunkIndexStartsWith: 1,
            },
        );
        this.file = null;
        this.uploadStarted = false;

        /** @type {?string} */
        this.fileFormat = null;

        this.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Calls send method from upload instance to upload a file.
     */
    uploadFile() {
        this.uploadStarted = true;

        let destination = '/api/fileservice/gslb';

        if (this.fileFormat) {
            destination += `?geodbformat=${this.fileFormat}`;
        }

        this.upload.send(this.file, this.file.name, destination);
    }
}

UploadGeoFilesController.$inject = [
    'CollateUpload',
    'l10nService',
];

angular.module('aviApp').component('uploadGeoFilesPage', {
    controller: UploadGeoFilesController,
    templateUrl: 'src/components/pages/infrastructure/gslb/upload-geo-files-page/' +
        'upload-geo-files-page.html',
});
