/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    HealthMonitorCollection,
} from 'ajs/modules/healthmonitor/factories/health-monitor.collection.factory';

import { HEALTH_MONITOR_COLLECTION_TOKEN } from 'ajs/modules/healthmonitor/healthmonitor.tokens';

const $injector = '$injector';

type THealthMonitorCollection = typeof HealthMonitorCollection;

export const healthMonitorCollectionProvider = {
    deps: [$injector],
    provide: HealthMonitorCollection,
    useFactory(injector: Injector): THealthMonitorCollection {
        return injector.get(HEALTH_MONITOR_COLLECTION_TOKEN);
    },
};
