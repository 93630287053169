/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-client-management-access-config-container';
const prefix = `${moduleName}.${componentName}`;

export const clientManagementAccessLabel = `${prefix}.clientManagementAccessLabel`;
export const allowedSshClientsLabel = `${prefix}.allowedSshClientsLabel`;
export const allowedCliShellClientsLabel = `${prefix}.allowedCliShellClientsLabel`;
export const allowedExternalHttpsClientsLabel = `${prefix}.allowedExternalHttpsClientsLabel`;
export const allowedExternalSnmpClientsLabel = `${prefix}.allowedExternalSnmpClientsLabel`;

export const ENGLISH = {
    [clientManagementAccessLabel]: 'Client Management Access',
    [allowedSshClientsLabel]: 'Allowed SSH Clients',
    [allowedCliShellClientsLabel]: 'Allowed CLI Shell Clients',
    [allowedExternalHttpsClientsLabel]: 'Allowed External HTTPS Clients',
    [allowedExternalSnmpClientsLabel]: 'Allowed External SNMP Clients',
};
