/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import { L10nService } from '@vmw/ngx-vip';
import {
    AlertRuleOperator,
    AlertSource,
    AviPermissionResource,
    IAlertRule,
    IAlertRuleEvent,
} from 'generated-types';

import { AlertConfig } from 'object-types';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import * as l10n from 'ajs/modules/alert/alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const AlertOperatorNot = 'OPERATOR_NOT';

export const ValueNone = 'none';

/**
 * @description
 *
 *   AlertConfig item.
 *
 * @author Dhanashree Sathelkar
 */
export class AlertConfigItem extends Item {
    public static ajsDependencies = [
        'l10nService',
        'defaultValues',
    ];

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'alertconfig',
            objectType: AlertConfig,
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            // TODO: replace with Create modal once component is migrated AV-156964
            windowElement: 'adm-alertconfig-create',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertActionBreadcrumbTitle);
    }

    /**
     * Getter for Alert Config Rule
     */
    private get alertRule(): MessageItem<IAlertRule> {
        return this.data.config.alert_rule;
    }

    /** @override */
    public beforeEdit(): void {
        const { config } = this.data;
        const defaultValues = this.getAjsDependency_('defaultValues');

        if (!config.source) {
            config.source = AlertSource.EVENT_LOGS;
        }

        if (!this.alertRule) {
            config.alert_rule = {
                operator: AlertRuleOperator.OPERATOR_AND,
            };
        }

        if (config.alert_rule.sys_event_rule) {
            const alert = config.alert_rule;

            if (Array.isArray(alert.sys_event_rule) && alert.sys_event_rule.length === 2) {
                if (alert.sys_event_rule[1].not_cond === true) {
                    alert.operator = AlertOperatorNot;
                }
            }
        } else {
            config.alert_rule.sys_event_rule = [
                defaultValues.getDefaultItemConfigByType('alertruleevent'),
            ];
        }

        if (!config.alert_rule.metrics_rule) {
            config.alert_rule.metrics_rule = [
                defaultValues.getDefaultItemConfigByType('alertrulemetric'),
            ];
        }

        if (!config.object_type) {
            config.object_type = ValueNone;
        }
    }

    /** @override */
    public dataToSave(): any {
        const config = super.dataToSave();

        if (config.source === AlertSource.EVENT_LOGS) {
            const alert = config.alert_rule;

            if (alert.operator === AlertOperatorNot) {
                alert.operator = AlertRuleOperator.OPERATOR_AND;
                alert.sys_event_rule[1].not_cond = true;
            } else {
                alert.sys_event_rule.forEach((rule: IAlertRuleEvent) => {
                    rule.not_cond = false;
                });
            }

            alert.sys_event_rule = alert.sys_event_rule.filter((rule: IAlertRuleEvent) => {
                return Boolean(rule.event_id);
            });
        } else {
            delete config.alert_rule.sys_event_rule;
        }

        if (config.source !== AlertSource.METRICS) {
            delete config.alert_rule.metrics_rule;
        }

        if (config.object_type === ValueNone) {
            delete config.object_type;
        }

        return config;
    }
}
