/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').filter('stateContext', function() {
    return function(state) {
    // ignore leaf states (summary, config, performance)
    // and get to abstract state (application, vs, pool)
        const parts = state.name.split('.');

        return parts[parts.length - 2];
    };
})

    .filter('currentStateIncludes', ['$state', function($state) {
        return function(string) {
            return _.contains($state.current.name.split('.'), string);
        };
    }])

/**
 * @ngdoc filter
 * @name leafStateName
 * @param {ng.$state|string} str - State object or just a state name.
 */
    .filter('leafStateName', function() {
        return function(arg) {
            let res = '',
                str,
                pointPos;

            if (typeof arg === 'string') {
                str = arg;
            } else if (typeof arg === 'object' && typeof arg.name === 'string') {
                str = arg.name;
            }

            if (str) {
                pointPos = str.lastIndexOf('.');

                if (pointPos > -1) {
                    res = str.slice(pointPos + 1);
                }
            }

            return res;
        };
    });
