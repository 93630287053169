/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    AUTO_SCALE_POLICY_COLLECTION_TOKEN,
    AUTO_SCALE_POLICY_ITEM_TOKEN,
    SCHEDULED_SCALING_ITEM_TOKEN,
    WEBHOOK_COLLECTION_TOKEN,
    WEBHOOK_ITEM_TOKEN,
} from 'ajs/modules/auto-scale/auto-scale.tokens';

import {
    AutoScalePolicy,
    AutoScalePolicyCollection,
    ScheduledScalingConfigItem,
} from 'ajs/modules/auto-scale/factories';

import {
    WebhookCollection,
    WebhookItem,
} from 'ajs/modules/auto-scale/factories/webhook';

const autoScaleModule = angular.module('avi/auto-scale');

const factories = [
    {
        factory: AutoScalePolicyCollection,
        name: AUTO_SCALE_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: AutoScalePolicy,
        name: AUTO_SCALE_POLICY_ITEM_TOKEN,
    },
    {
        factory: WebhookCollection,
        name: WEBHOOK_COLLECTION_TOKEN,
    },
    {
        factory: WebhookItem,
        name: WEBHOOK_ITEM_TOKEN,
    },
    {
        factory: ScheduledScalingConfigItem,
        name: SCHEDULED_SCALING_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(autoScaleModule, 'factory', name, factory);
});
