/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'data-grid';
const componentName = 'avi-diff-grid';
const prefix = `${moduleName}.${componentName}`;

export const defaultEmptyListPlaceholderMessage = `${prefix}.defaultEmptyListPlaceholderMessage`;

export const ENGLISH = {
    [defaultEmptyListPlaceholderMessage]: 'No diff data.',
};
