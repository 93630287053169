/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import {
    IBotClassification,
    IBotMappingRule,
} from 'generated-types';
import { BotMappingRule } from 'object-types';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { BotMappingRuleModalComponent } from 'ng/modules/bot';
import { L10nService } from '@vmw/ngx-vip';
import {
    BotMappingRuleMatchTargetConfigItem,
    botMappingRulesSupportedMatches,
} from '../../policies/factories/config-items';
import * as l10n from './bot-mapping-rule.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBotMappingRulePartial = Omit<IBotMappingRule, 'match' | 'classification'>;

interface IBotMappingRuleConfig extends TBotMappingRulePartial {
    match: BotMappingRuleMatchTargetConfigItem,
    classification: MessageItem<IBotClassification>,
}

/**
 * config-item for BotMappingRule, which has matches
 * @author Akul Aggarwal
 */
export class BotMappingRuleConfigItem extends
    withFullModalMixin(MessageItem)<IBotMappingRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
        'schemaService',
    ];

    private readonly l10nService: L10nService;

    /**
     * List of all supported matches.
     */
    private readonly botMappingRulesSupportedMatches = botMappingRulesSupportedMatches;

    constructor(args = {}) {
        super({
            objectType: BotMappingRule,
            windowElement: BotMappingRuleModalComponent,
            ...args,
        });

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get match config-item.
     */
    public get matchConfigItem(): BotMappingRuleMatchTargetConfigItem {
        return this.config.match;
    }

    /**
     * Returns list of types of matches configured.
     */
    private get configuredMatchList(): string[] {
        return this.botMappingRulesSupportedMatches.filter(this.config.match.hasMatchByField);
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.configuredMatchList.length;
    }

    /**
     * Returns 'action' text (for match & action pair) in human readable format.
     */
    public get classificationTypeText(): string {
        const schemaService = this.getAjsDependency_('schemaService');

        if (!this.config.classification.config.type) {
            return '';
        }

        return schemaService.getEnumValueDescription(
            'BotClassificationTypes',
            this.config.classification.config.type,
        );
    }

    /** @override */
    public canFlatten(): boolean {
        return this.matchConfigItem.canFlatten();
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IBotMappingRuleConfig> {
        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');
        const type = BotMappingRule.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            classification: null,
            match: null,
        };
    }

    /**
     * Needed to clear out flat props before save, otherwise gets overwritten.
     */
    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { client_ip: clientIp } = this.config.match.config;

        if (clientIp) {
            clientIp.clearFlatProps();
        }
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.botMappingRuleLabel);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'match',
            'classification',
        ];
    }
}
