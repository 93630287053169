/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import { findWhere } from 'underscore';

import {
    ISystemConfiguration,
    ITenant,
} from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import {
    ADMIN_TENANT_NAME,
    ALL_TENANTS,
    STORAGE_USERNAME_BEFORE_LOGOUT,
    STORAGE_USER_PROFILE_LOCAL_STORAGE,
    USER_PROFILE_URL,
} from 'ajs/modules/core/core.constants';

import { IAppVersion } from 'ajs/modules/core/core.types';

export interface IActiveUserData {
    default_tenant_ref: string;
    email: string;
    full_name: string;
    is_superuser: boolean;
    local: boolean;
    name: string;
    ui_property: string;
    username: string;
    uuid: string;
}

export interface IActiveUserProfile {
    version: IAppVersion;
    user_initialized: boolean;
    user: IActiveUserData;
    tenants: ITenant[];
    system_config: ISystemConfiguration;
    session_cookie_name: string;
    controller: {
        api_idle_timeout: number;
    }
}

/**
 * Ajs Dependency token for ActiveUserProfileService.
 */
export const ACTIVE_USER_PROFILE_SERVICE_TOKEN = 'activeUserProfileService';

/**
 * @desc Service to maintain current user profile.
 *
 * @author Aravindh Nagarajan
 */
export class ActiveUserProfileService {
    /**
     * HttpWrapper instance.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * Keeps user profile
     * available after user login
     */
    private profile: IActiveUserProfile | null = null;

    constructor(HttpWrapper: THttpWrapper) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Checks whether user profile is present on local storage.
     */
    public haveStoredProfile(): boolean {
        return Boolean(this.getStoredProfile());
    }

    /**
     * Remove user profile from local storage.
     */
    public removeStoredProfile(): void {
        localStorage.removeItem(STORAGE_USER_PROFILE_LOCAL_STORAGE);
    }

    /**
     * Returns profile data from localStorage.
     */
    public getStoredProfile(): IActiveUserProfile | null {
        const profile = localStorage.getItem(STORAGE_USER_PROFILE_LOCAL_STORAGE);

        if (profile) {
            return JSON.parse(profile);
        }

        return null;
    }

    /**
     * Saves user profile to the local storage as STORAGE_USER_PROFILE.
     * @param profile - Object with key-values to be updated.
     */
    public saveUserProfile(profile: IActiveUserProfile): void {
        localStorage.setItem(STORAGE_USER_PROFILE_LOCAL_STORAGE, JSON.stringify(profile));
    }

    /**
     * Returns true if current user has access to the tenant name passed.
     */
    public hasAccessToTenant(tenantName: string): boolean {
        if (tenantName === ALL_TENANTS) {
            return this.isSuperUser();
        }

        const tenants = this.getTenants();

        return Boolean(findWhere(tenants, { name: tenantName }));
    }

    /**
     * Checks if user profile had been set.
     */
    public isUserProfileSet(): boolean {
        return Boolean(this.userProfile);
    }

    /**
     * Returns profile data.
     */
    public get userProfile(): IActiveUserProfile {
        return this.profile;
    }

    /**
     * Setter for user profile.
     */
    public set userProfile(profile: IActiveUserProfile) {
        this.profile = profile;
    }

    /**
     * Return list of tenant for the active user.
     */
    public getTenants(): ITenant[] {
        const { userProfile } = this;

        return userProfile?.tenants || [];
    }

    /**
     * Returns default tenant ref.
     */
    public get defaultTenantRef(): IActiveUserData['default_tenant_ref'] {
        const { userData } = this;
        const { default_tenant_ref: defaultTenantRef = '' } = userData;

        return defaultTenantRef;
    }

    /**
     * Returns current user information. Undefined when not applicable.
     */
    public get userData(): IActiveUserData {
        return this.userProfile.user;
    }

    /**
     * True if current user is admin.
     */
    public isAdminUser(): boolean {
        return this.username === ADMIN_TENANT_NAME;
    }

    /**
     * Returns whether current user is a super user
     */
    public isSuperUser(): boolean {
        return this.userData.is_superuser;
    }

    /**
     * Returns the name of current user.
     * Empty string when not applicable.
     */
    public get username(): string {
        return this.isUserProfileSet() ? this.userData.username : '';
    }

    /**
     * Returns "message of the day" text when applicable.
     */
    public get messageOfTheDay(): string {
        const { system_config: systemConfig } = this.userProfile;
        const { linux_configuration: linuxConfig } = systemConfig;

        if (linuxConfig) {
            return linuxConfig.motd || '';
        }

        return '';
    }

    /**
     * Loads and updates current user's data.
     */
    public loadUserProfile(): IPromise<void> {
        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url: USER_PROFILE_URL,
        }).then(({ data }: IHttpResponse<IActiveUserProfile>) => {
            this.updateUserProfile(data);
        });
    }

    /**
     * Returns tenant reference based on tenant name.
     * For All Tenants(*), TenantRef and tenantName are same.
     */
    public getTenantRefByName(tenantName: string): string {
        if (tenantName === '*') {
            return tenantName;
        }

        const tenants = this.getTenants();
        const tenant = findWhere(tenants, { name: tenantName });

        return tenant ? tenant.url : '';
    }

    /**
     * Stores active user's username in session storage.
     */
    public storeActiveUsername(): void {
        const { username } = this;

        this.storeUsername(username);
    }

    /**
     * Stores username in session storage.
     */
    public storeUsername(username: string): void {
        sessionStorage.setItem(STORAGE_USERNAME_BEFORE_LOGOUT, username);
    }

    /**
     * Returns username from sessionStorage.
     */
    public getStoredUsername(): string {
        return sessionStorage.getItem(STORAGE_USERNAME_BEFORE_LOGOUT);
    }

    /**
     * Removes username from sessionStorage.
     */
    public removeStoredUsername(): void {
        sessionStorage.removeItem(STORAGE_USERNAME_BEFORE_LOGOUT);
    }

    /**
     * Updates user profile on this as well as local storage copy.
     * @param profile - Object with key-values to be updated.
     */
    private updateUserProfile({ tenants, user }: IActiveUserProfile): void {
        const profile = this.userProfile;

        profile.tenants = tenants;
        profile.user = user;

        this.saveUserProfile(profile);
    }
}

ActiveUserProfileService.$inject = [
    HTTP_WRAPPER_TOKEN,
];
