/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const systemSettingsTabHeader = `${prefix}.systemSettingsTabHeader`;
export const systemSettingsTabDescription = `${prefix}.systemSettingsTabDescription`;
export const emailSettingsTabHeader = `${prefix}.emailSettingsTabHeader`;
export const tenantSettingsTabHeader = `${prefix}.tenantSettingsTabHeader`;
export const setupCloudAfterCheckboxLabel = `${prefix}.setupCloudAfterCheckboxLabel`;
export const nextButtonLabel = `${prefix}.nextButtonLabel`;

export const ENGLISH = {
    [modalTitle]: 'Welcome admin',
    [systemSettingsTabHeader]: 'System Settings',
    [systemSettingsTabDescription]: 'Let\'s get started with some basic questions',
    [emailSettingsTabHeader]: 'Email/SMTP',
    [tenantSettingsTabHeader]: 'Multi-Tenant',
    [setupCloudAfterCheckboxLabel]: 'Setup Cloud After',
    [nextButtonLabel]: 'Next',
};
