/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { RateLimiterConfigItem } from 'ajs/modules/scripts';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './ds-rate-limiters-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description RateLimiterConfigItem Grid component with inline-editing.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ds-rate-limiters-grid',
    templateUrl: './ds-rate-limiters-grid.component.html',
})
export class DsRateLimiterGridComponent {
    /**
     * List of RateLimiterConfigItems.
     */
    @Input()
    public rateLimiters: RateLimiterConfigItem[];

    /**
     * Fires on new rate_limiter addition.
     */
    @Output()
    public onAddRateLimiter: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on delete/removeAll of rate_limiter.
     */
    @Output()
    public onDeleteRateLimiter: EventEmitter<RateLimiterConfigItem> = new EventEmitter();

    /**
     * TemplateRef for RateLimiter#name input element.
     */
    @ViewChild('rateLimiterNameTemplateRef')
    public rateLimiterNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for RateLimiter#count input element.
     */
    @ViewChild('rateLimiterCountTemplateRef')
    public rateLimiterCountTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for RateLimiter#burst_sz input element.
     */
    @ViewChild('rateLimiterBurstSizeTemplateRef')
    public rateLimiterBurstSizeTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for RateLimiter#period input element.
     */
    @ViewChild('rateLimiterPeriodTemplateRef')
    public rateLimiterPeriodTemplateRef: TemplateRef<HTMLElement>;

    /**
     * DS Rate-limiters string grid config.
     */
    public dsRateLimitersGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.dsRateLimitersGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.nameLabel),
                    id: 'name',
                    templateRef: this.rateLimiterNameTemplateRef,
                }, {
                    label: this.l10nService.getMessage(l10nKeys.countLabel),
                    id: 'count',
                    templateRef: this.rateLimiterCountTemplateRef,
                }, {
                    label: this.l10nService.getMessage(l10nKeys.burstSizeLabel),
                    id: 'burst_sz',
                    templateRef: this.rateLimiterBurstSizeTemplateRef,
                }, {
                    label: this.l10nService.getMessage(l10nKeys.periodLabel),
                    id: 'period',
                    templateRef: this.rateLimiterPeriodTemplateRef,
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                onClick: (rateLimiters: RateLimiterConfigItem[]) => {
                    this.deleteRateLimiters(rateLimiters);
                },
            }],
            singleactions: [{
                label: this.l10nService.getMessage(l10nKeys.actionTitleRemove),
                shape: 'trash',
                onClick: (rateLimiter: RateLimiterConfigItem): void => {
                    this.deleteRateLimiter(rateLimiter);
                },
            }],
        };
    }

    /**
     * Handler for RateLimiter add operation.
     */
    public addRateLimiter(): void {
        this.onAddRateLimiter.emit();
    }

    /**
     * Handler for RateLimiter delete operation.
     */
    private deleteRateLimiter(rateLimiter: RateLimiterConfigItem): void {
        this.onDeleteRateLimiter.emit(rateLimiter);
    }

    /**
     * Handler for RateLimiter multiple-delete operation.
     */
    private deleteRateLimiters(rateLimiters: RateLimiterConfigItem[]): void {
        rateLimiters.forEach((rateLimiter: RateLimiterConfigItem) => {
            this.deleteRateLimiter(rateLimiter);
        });
    }
}
