/**
 * Module for Analytics related components.
 * @preferred
 * @module AnalyticsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    timingTileComponentOptions,
    timingTilesComponentOptions,
} from './components';

import {
    AnalyticsProfileCollection,
    AnalyticsProfileItem,
} from './factories';

import {
    ANALYTICS_PROFILE_COLLECTION_TOKEN,
    ANALYTICS_PROFILE_ITEM_TOKEN,
    SENSITIVE_FIELD_RULE_CONFIG_ITEM_TOKEN,
    SENSITIVE_LOG_PROFILE_CONFIG_ITEM_TOKEN,
} from './analytics.tokens';

import { SensitiveLogProfileConfigItem }
    from './factories/sensitive-log-profile.config-item.factory';

import { SensitiveFieldRuleConfigItem } from './factories/sensitive-field-rule.config-item.factory';

const analyticsModule = angular.module('avi/analytics');

const components = [{
    name: 'timingTiles',
    options: timingTilesComponentOptions,
}, {
    name: 'timingTile',
    options: timingTileComponentOptions,
}];

const factories = [
    {
        factory: AnalyticsProfileItem,
        name: ANALYTICS_PROFILE_ITEM_TOKEN,
    },
    {
        factory: AnalyticsProfileCollection,
        name: ANALYTICS_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: SensitiveLogProfileConfigItem,
        name: SENSITIVE_LOG_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: SensitiveFieldRuleConfigItem,
        name: SENSITIVE_FIELD_RULE_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        analyticsModule,
        'factory',
        name,
        factory,
    );
});

components.forEach(({ name, options }) => analyticsModule.component(name, options));
