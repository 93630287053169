/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'icap-profile';

export const icapProfileModalBreadcrumbTitle = `${prefix}.icapProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [icapProfileModalBreadcrumbTitle]: 'ICAP Profile',
};
