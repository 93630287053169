/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'user-account-profile-mapping';
const prefix = `${moduleName}.${componentName}`;

export const userAccountProfileLabel = `${prefix}.userAccountProfileLabel`;
export const userProfileAttributeNameLabel = `${prefix}.userProfileAttributeNameLabel`;
export const userAccountProfilePlaceholderLabel = `${prefix}.userAccountProfilePlaceholderLabel`;
export const userProfileAttributeNamePlaceholderLabel = `${prefix}.userProfileAttributeNamePlaceholderLabel`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;

export const ENGLISH = {
    [userAccountProfileLabel]: 'User Account Profile',
    [userProfileAttributeNameLabel]: 'User Profile Attribute Name',
    [userAccountProfilePlaceholderLabel]: 'Select User Account Profile',
    [userProfileAttributeNamePlaceholderLabel]: 'Enter User Profile Attribute Name',
    [typeDropdownPlaceholder]: 'Select Type',
};
