/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module SecurityModule */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { LdapAuthSettings } from 'object-types';

import {
    ILdapAuthSettings,
    ILdapDirectorySettings,
    ILdapUserBindSettings,
} from 'generated-types';

type TAuthProfileLdapPartial = Omit<ILdapAuthSettings, 'settings' | 'user_bind'>;

interface IAuthProfileLdapConfig extends TAuthProfileLdapPartial {
    settings?: MessageItem<ILdapDirectorySettings>,
    user_bind?: MessageItem<ILdapUserBindSettings>,
}

/**
 * @description LDAP Message Item.
 * @author Suraj Kumar
 */
export class LdapAuthSettingsConfigItem extends MessageItem<IAuthProfileLdapConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: LdapAuthSettings,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Delete unnecessary field from configuration.
     * @override
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        if (config.bind_as_administrator) {
            delete config.user_bind;
        } else {
            delete config.settings;
        }
    }

    /**
     * Method for creating LDAP nested field depending on bindAsAdmin type, if true will configure
     * nested setting property and in case of false we will configure nested user_bind property.
     * Create respective property if doesn't exist in the configuration.
     */
    public handleLdapBindTypeChange(): void {
        const { config } = this;

        if (config.bind_as_administrator) {
            this.safeSetNewChildByField('settings');
            delete config.user_bind;
        } else {
            this.safeSetNewChildByField('user_bind');
            delete config.settings;
        }
    }

    /** @override */
    protected requiredFields(): string[] {
        const requiredField = this.config.bind_as_administrator ? 'settings' : 'user_bind';

        return [requiredField];
    }
}
