/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'system';

export const emailConfigurationModalBreadcrumbTitle =
    `${prefix}.emailConfigurationModalBreadcrumbTitle`;
export const schedulerModalBreadcrumbTitle = `${prefix}.schedulerModalBreadcrumbTitle`;
export const systemSettingsBreadCrumbTitle = `${prefix}.systemSettingsBreadCrumbTitle`;
export const licensingBreadCrumbTitle = `${prefix}.licensingBreadCrumbTitle`;
export const reloginMessageInfo = `${prefix}.reloginMessageInfo`;
export const httpsMessage = `${prefix}.httpsMessage`;
export const httpMessage = `${prefix}.httpMessage`;

export const ENGLISH = {
    [emailConfigurationModalBreadcrumbTitle]: 'Email/SMTP',
    [schedulerModalBreadcrumbTitle]: 'Configuration Backup',
    [systemSettingsBreadCrumbTitle]: 'System Settings',
    [licensingBreadCrumbTitle]: 'Licensing',
    [reloginMessageInfo]: '{0} port changed. Please re-login at the new port number',
    [httpsMessage]: 'Https',
    [httpMessage]: 'Http',
};
