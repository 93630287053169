/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module RightRailModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import './collapsed-sidebar-icon-list.component.less';

/**
 * @description
 *
 *     Component for styling a list of icons in the collapsed log sidebar.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'collapsed-sidebar-icon-list',
    templateUrl: './collapsed-sidebar-icon-list.component.html',
})
export class CollapsedSidebarIconListComponent {
    /**
     * List of icon names for generating the icon list.
     */
    @Input()
    public iconNames: string[];

    /**
     * Callback to ngFor-track by.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
