/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AuthProfileType,
    AviPermissionResource,
    ISSOPolicy,
    SSOPolicyType,
} from 'generated-types';

import { SSOPolicy } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { SsoPolicyModalComponent }
    from 'ng/modules/sso-policy/components/sso-policy-modal/sso-policy-modal.component';
import { AuthenticationPolicyConfigItem } from './authentication-policy.config-item.factory';
import { AuthorizationPolicyConfigItem } from './authorization-policy.config-item.factory';
import * as l10n from '../sso-policy.l10n';

type TSsoPolicyPartial = Omit<ISSOPolicy, 'authentication_policy' | 'authorization_policy'>;

interface ISsoPolicyConfig extends TSsoPolicyPartial {
    authentication_policy?: AuthenticationPolicyConfigItem;
    authorization_policy?: AuthorizationPolicyConfigItem;
}

export enum SSOPolicyTypes {
    PINGACCESS = 'SSO_TYPE_PINGACCESS',
    SAML = 'SSO_TYPE_SAML',
    JWT = 'SSO_TYPE_JWT',
    LDAP = 'SSO_TYPE_LDAP',
    OAUTH = 'SSO_TYPE_OAUTH',
}

/**
 * Hash of auth profile type by SSO policy type.
 */
export enum AuthProfileTypeBySsoPolicy {
    SSO_TYPE_SAML = AuthProfileType.AUTH_PROFILE_SAML,
    SSO_TYPE_PINGACCESS = AuthProfileType.AUTH_PROFILE_PINGACCESS,
    SSO_TYPE_JWT = AuthProfileType.AUTH_PROFILE_JWT,
    SSO_TYPE_LDAP = AuthProfileType.AUTH_PROFILE_LDAP,
    SSO_TYPE_OAUTH = AuthProfileType.AUTH_PROFILE_OAUTH,
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Ssopolicy objectTypeItem.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export class SsoPolicyItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Returns policy type.
     */
    public get type(): SSOPolicyType | undefined {
        return this.getConfig().type;
    }

    /**
     * Sets type of the policy.
     */
    public set type(type: SSOPolicyType) {
        this.getConfig().type = type;
    }

    /**
     * Getter for authenticationPolicy.
     */
    public get authenticationPolicy(): (AuthenticationPolicyConfigItem | null) {
        return this.getConfig().authentication_policy || null;
    }

    /**
     * Getter for authorizationPolicy.
     */
    public get authorizationPolicy(): (AuthorizationPolicyConfigItem | null) {
        return this.getConfig().authorization_policy || null;
    }

    /** Overriding getConfig()'s return type */
    public getConfig: () => ISsoPolicyConfig;

    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'ssopolicy',
            objectType: SSOPolicy,
            permissionName: AviPermissionResource.PERMISSION_SSOPOLICY,
            windowElement: SsoPolicyModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns default_auth_profile_ref of the nested authentication policy.
     */
    public getDefaultAuthProfileRef(): string {
        const { authentication_policy: authenticationPolicy } = this.getConfig();

        return authenticationPolicy.config.default_auth_profile_ref || '';
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'authentication_policy',
            'authorization_policy',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.ssoPolicyModalBreadcrumbTitle);
    }
}
