/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('operStatus', function() {
    return {
        scope: false,
        link(scope, elm, attr) {
            elm.wrap('<div class="input-append"></div>').after(`<span class="oper_status">${
                attr.operStatus}</span>`);
            elm.parent().find('.oper_status').addClass(attr.operStatus.toLowerCase());
        },
    };
});
