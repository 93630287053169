/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'log-list-controller';
const prefix = `${moduleName}.${componentName}`;

export const exportBtnLabel = `${prefix}.exportBtnLabel`;
export const totalLogsPluralLabel = `${prefix}.totalLogsPluralLabel`;
export const logThrottlingIsOnLabel = `${prefix}.logThrottlingIsOnLabel`;
export const zoomToSelectedLink = `${prefix}.zoomToSelectedLink`;
export const nonSignificantLogsLabel = `${prefix}.nonSignificantLogsLabel`;
export const significantLogsLabel = `${prefix}.significantLogsLabel`;
export const reloadMoreLogsMessage = `${prefix}.reloadMoreLogsMessage`;
export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleClientIp = `${prefix}.columnTitleClientIp`;
export const columnTitleClientApp = `${prefix}.columnTitleClientApp`;
export const columnTitlePort = `${prefix}.columnTitlePort`;
export const columnTitleServerIp = `${prefix}.columnTitleServerIp`;
export const columnTitleViolationResolutions = `${prefix}.columnTitleViolationResolutions`;
export const columnTitleViolationThreatNames = `${prefix}.columnTitleViolationThreatNames`;
export const columnTitleBytes = `${prefix}.columnTitleBytes`;
export const durationLinkLabel = `${prefix}.durationLinkLabel`;
export const timeLineLinkLabel = `${prefix}.timeLineLinkLabel`;
export const tooManyLogsToShowMessage = `${prefix}.tooManyLogsToShowMessage`;
export const columnTitleRequest = `${prefix}.columnTitleRequest`;
export const columnTitleResponse = `${prefix}.columnTitleResponse`;
export const columnTitleLength = `${prefix}.columnTitleLength`;
export const columnTitleProtocol = `${prefix}.columnTitleProtocol`;
export const columnTitleDnsRequestType = `${prefix}.columnTitleDnsRequestType`;
export const columnTitleDomainName = `${prefix}.columnTitleDomainName`;
export const poolNotAssignedMessage = `${prefix}.poolNotAssignedMessage`;
export const noLogsAvailableMessage = `${prefix}.noLogsAvailableMessage`;
export const summariesOptionLabel = `${prefix}.summariesOptionLabel`;
export const savedSearchesOptionLabel = `${prefix}.savedSearchesOptionLabel`;
export const logAnalyticsLabel = `${prefix}.logAnalyticsLabel`;
export const wafAnalyticsHeader = `${prefix}.wafAnalyticsHeader`;
export const columnTitleWafTags = `${prefix}.columnTitleWafTags`;
export const columnTitleWafRules = `${prefix}.columnTitleWafRules`;
export const columnTitleWafGroups = `${prefix}.columnTitleWafGroups`;
export const columnTitleWafLatency = `${prefix}.columnTitleWafLatency`;
export const clientAnalyticsHeader = `${prefix}.clientAnalyticsHeader`;
export const columnTitleBrowser = `${prefix}.columnTitleBrowser`;
export const columnTitleClientOs = `${prefix}.columnTitleClientOs`;
export const columnTitleDevice = `${prefix}.columnTitleDevice`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const columnTitleLocation = `${prefix}.columnTitleLocation`;
export const columnTitleEndToEnd = `${prefix}.columnTitleEndToEnd`;
export const columnTitleUserId = `${prefix}.columnTitleUserId`;
export const loadBalancerAnalyticsHeader = `${prefix}.loadBalancerAnalyticsHeader`;
export const columnTitlePolicies = `${prefix}.columnTitlePolicies`;
export const columnTitleVsIpAddress = `${prefix}.columnTitleVsIpAddress`;
export const columnTitleServerIpAddress = `${prefix}.columnTitleServerIpAddress`;
export const columnTitleServiceEngine = `${prefix}.columnTitleServiceEngine`;
export const requestAnalyticsHeader = `${prefix}.requestAnalyticsHeader`;
export const columnTitleReferer = `${prefix}.columnTitleReferer`;
export const columnTitleUrlPath = `${prefix}.columnTitleUrlPath`;
export const columnTitleHostHeader = `${prefix}.columnTitleHostHeader`;
export const columnTitleHttpVersion = `${prefix}.columnTitleHttpVersion`;
export const columnTitleRequestLength = `${prefix}.columnTitleRequestLength`;
export const columnTitleRequestType = `${prefix}.columnTitleRequestType`;
export const columnTitleResponseCode = `${prefix}.columnTitleResponseCode`;
export const columnTitleResponseContentType = `${prefix}.columnTitleResponseContentType`;
export const columnTitleResponseLength = `${prefix}.columnTitleResponseLength`;
export const icapAnalyticsHeader = `${prefix}.icapAnalyticsHeader`;
export const columnTitleAction = `${prefix}.columnTitleAction`;
export const columnTitleLatency = `${prefix}.columnTitleLatency`;
export const columnTitleIcapResponseCode = `${prefix}.columnTitleIcapResponseCode`;
export const columnTitleHttpResponseCode = `${prefix}.columnTitleHttpResponseCode`;
export const significanceHeader = `${prefix}.significanceHeader`;
export const columnTitleSignificance = `${prefix}.columnTitleSignificance`;
export const columnTitleEteTcp = `${prefix}.columnTitleEteTcp`;
export const columnTitleEteUdp = `${prefix}.columnTitleEteUdp`;
export const columnTitleFqdnDomainName = `${prefix}.columnTitleFqdnDomainName`;
export const columnTitleClientIpAddress = `${prefix}.columnTitleClientIpAddress`;
export const columnTitleQueryType = `${prefix}.columnTitleQueryType`;
export const columnTitleRecordSource = `${prefix}.columnTitleRecordSource`;
export const columnTitleGslbServiceName = `${prefix}.columnTitleGslbServiceName`;
export const enableNonSignificantLogsMessage = `${prefix}.enableNonSignificantLogsMessage`;
export const botColumnHeader = `${prefix}.botColumnHeader`;
export const columnBotClassification = `${prefix}.columnBotClassification`;
export const l4LogsLabel = `${prefix}.l4LogsLabel`;
export const l7LogsLabel = `${prefix}.l7LogsLabel`;
export const recommendationsTooltip = `${prefix}.recommendationsTooltip`;
export const techPreviewBannerHeader = `${prefix}.techPreviewBannerHeader`;
export const techPreviewBannerDialog = `${prefix}.techPreviewBannerDialog`;
export const enableTechPreviewLabel = `${prefix}.enableTechPreviewLabel`;

export const ENGLISH = {
    [exportBtnLabel]: 'Export',
    [totalLogsPluralLabel]: '{0, plural, one{Total <strong class="sel-total-logs">#</strong> Log} other{Total <strong class="sel-total-logs">#</strong> Logs}}',
    [logThrottlingIsOnLabel]: '(Log Throttling is ON)',
    [zoomToSelectedLink]: 'Zoom to selected',
    [nonSignificantLogsLabel]: 'Non-Significant Logs',
    [significantLogsLabel]: 'Significant Logs',
    [reloadMoreLogsMessage]: 'More logs available. Please ##0reload list##1',
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleClientIp]: 'Client IP',
    [columnTitleClientApp]: 'Client App',
    [columnTitlePort]: 'Port',
    [columnTitleServerIp]: 'Server IP',
    [columnTitleViolationResolutions]: 'Violation Resolutions',
    [columnTitleViolationThreatNames]: 'Violation Threat Names',
    [columnTitleBytes]: 'Bytes',
    [durationLinkLabel]: 'Duration',
    [timeLineLinkLabel]: 'Timeline',
    [tooManyLogsToShowMessage]: 'Too many logs to show: either select a smaller time window or apply more filters.',
    [columnTitleRequest]: 'Request',
    [columnTitleResponse]: 'Response',
    [columnTitleLength]: 'Length',
    [columnTitleProtocol]: 'Protocol',
    [columnTitleDnsRequestType]: 'DNS Request Type',
    [columnTitleDomainName]: 'Domain Name',
    [poolNotAssignedMessage]: 'This pool is not assigned to any Virtual Service.',
    [noLogsAvailableMessage]: 'No logs available for this timeframe and filters.',
    [summariesOptionLabel]: 'Summaries',
    [savedSearchesOptionLabel]: 'Saved Searches',
    [logAnalyticsLabel]: 'Log Analytics',
    [wafAnalyticsHeader]: 'WAF Analytics',
    [columnTitleWafTags]: 'WAF Tags',
    [columnTitleWafRules]: 'WAF Rules',
    [columnTitleWafGroups]: 'WAF Groups',
    [columnTitleWafLatency]: 'WAF Latency',
    [clientAnalyticsHeader]: 'Client Analytics',
    [columnTitleBrowser]: 'Browser',
    [columnTitleClientOs]: 'Client OS',
    [columnTitleDevice]: 'Device',
    [columnTitleIpAddress]: 'IP Address',
    [columnTitleLocation]: 'Location',
    [columnTitleEndToEnd]: 'End to End',
    [columnTitleUserId]: 'User ID',
    [loadBalancerAnalyticsHeader]: 'Load Balancer Analytics',
    [columnTitlePolicies]: 'Policies',
    [columnTitleVsIpAddress]: 'VS IP Address',
    [columnTitleServerIpAddress]: 'Server IP Address',
    [columnTitleServiceEngine]: 'Service Engine',
    [requestAnalyticsHeader]: 'Request Analytics',
    [columnTitleReferer]: 'Referer',
    [columnTitleUrlPath]: 'URL Path',
    [columnTitleHostHeader]: 'Host Header',
    [columnTitleHttpVersion]: 'HTTP Version',
    [columnTitleRequestLength]: 'Request Length',
    [columnTitleRequestType]: 'Request Type',
    [columnTitleResponseCode]: 'Response Code',
    [columnTitleResponseContentType]: 'Response Content Type',
    [columnTitleResponseLength]: 'Response Length',
    [icapAnalyticsHeader]: 'ICAP Analytics',
    [columnTitleAction]: 'Action',
    [columnTitleLatency]: 'Latency',
    [columnTitleIcapResponseCode]: 'ICAP Response Code',
    [columnTitleHttpResponseCode]: 'HTTP Response Code',
    [significanceHeader]: 'Significance',
    [columnTitleSignificance]: 'Significance',
    [columnTitleEteTcp]: 'End to End (TCP)',
    [columnTitleEteUdp]: 'End to End (UDP)',
    [columnTitleFqdnDomainName]: 'FQDN/Domain Name',
    [columnTitleClientIpAddress]: 'Client IP Address',
    [columnTitleQueryType]: 'Query Type',
    [columnTitleRecordSource]: 'Record Source',
    [columnTitleGslbServiceName]: 'GSLB Service Name',
    [enableNonSignificantLogsMessage]: 'Please enable Non-Significant logging in Virtual Service Settings.',
    [botColumnHeader]: 'Bot Analytics',
    [columnBotClassification]: 'Classifications',
    [l4LogsLabel]: 'L4 Logs',
    [l7LogsLabel]: 'L7 Logs',
    [recommendationsTooltip]: 'Recommendations',
    [techPreviewBannerHeader]: 'Tech Preview',
    [techPreviewBannerDialog]: 'The logs view is changing! Check out the new design and features in Tech Preview.',
    [enableTechPreviewLabel]: 'Enable Tech Preview',
};
