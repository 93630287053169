/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'create-vlan-interface-controller';
const prefix = `${moduleName}.${componentName}`;

export const ipAddressMaskInputLabel = `${prefix}.ipAddressMaskInputLabel`;
export const removeIpPrefixBtnLabel = `${prefix}.removeIpPrefixBtnLabel`;
export const addIpAddressLinkLabel = `${prefix}.addIpAddressLinkLabel`;
export const parentInterfaceInputLabel = `${prefix}.parentInterfaceInputLabel`;
export const parentInterfaceInputPlaceholder = `${prefix}.parentInterfaceInputPlaceholder`;
export const vrfInputPlaceholder = `${prefix}.vrfInputPlaceholder`;
export const vipAddressInputLabel = `${prefix}.vipAddressInputLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const createVlanInterfaceLabel = `${prefix}.createVlanInterfaceLabel`;
export const editVlanInterfaceLabel = `${prefix}.editVlanInterfaceLabel`;
export const duplicateVlanErrorMessage = `${prefix}.duplicateVlanErrorMessage`;

export const ENGLISH = {
    [ipAddressMaskInputLabel]: 'IP Address/mask',
    [removeIpPrefixBtnLabel]: 'Remove IP Prefix',
    [addIpAddressLinkLabel]: '+ Add IP Address',
    [parentInterfaceInputLabel]: 'Parent Interface',
    [parentInterfaceInputPlaceholder]: 'Select Parent Interface',
    [vrfInputPlaceholder]: 'Select VRF',
    [vipAddressInputLabel]: 'VIP Address',
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Select Parent Interface',
    [createVlanInterfaceLabel]: 'Create VLAN Interface: {0}',
    [editVlanInterfaceLabel]: 'Edit VLAN Interface: {0}',
    [duplicateVlanErrorMessage]: 'A VLAN with this name already exists.',
};
