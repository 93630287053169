/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-protocol-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const protocolMatchLabel = `${prefix}.protocolMatchLabel`;

export const ENGLISH = {
    [protocolMatchLabel]: 'Protocol',
};
