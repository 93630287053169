/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @description
 *
 *      Comparator for natural sorting.
 *      A negative number if the reference string occurs before the compare string;
 *      positive if the reference string occurs after the compare string;
 *      0 if they are equivalent.
 */
export function naturalSort(a = '', b = ''): number {
    return `${a}`.localeCompare(`${b}`, undefined, { numeric: true });
}

