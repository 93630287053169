/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-aws-usable-networks-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const columnTitleAvailabilityZone = `${prefix}.columnTitleAvailabilityZone`;
export const columnTitleUsableNetwork = `${prefix}.columnTitleUsableNetwork`;
export const availabilityZoneSelectPlaceholder = `${prefix}.availabilityZoneSelectPlaceholder`;
export const usableNetworkSelectPlaceholder = `${prefix}.usableNetworkSelectPlaceholder`;
export const usableNetworksLabel = `${prefix}.usableNetworksLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [columnTitleAvailabilityZone]: 'Availability Zone',
    [columnTitleUsableNetwork]: 'Usable Network',
    [availabilityZoneSelectPlaceholder]: 'Select Availability Zone',
    [usableNetworkSelectPlaceholder]: 'Select Usable Network',
    [usableNetworksLabel]: 'Usable Networks ({0})',
};
