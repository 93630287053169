/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import {
    HostHdrMatchComponent,
    IMatchOption,
    IpAddrMatchComponent,
    PathOrQueryMatchComponent,
} from 'ng/modules/match/components';

import {
    AuthenticationAction,
    AuthenticationMatch,
    AuthenticationRule,
} from 'object-types';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { AuthenticationRuleConfigItem }
    from 'ajs/modules/sso-policy/factories/authentication-rule.config-item.factory';
import * as l10n from './authentication-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for AuthenticationRule Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'authentication-rule-modal',
    templateUrl: './authentication-rule-modal.component.html',
})
export class AuthenticationRuleModalComponent implements OnInit {
    /**
     * AuthenticationRuleConfigItem instance.
     */
    @Input()
    public editable: AuthenticationRuleConfigItem;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<AuthenticationRuleConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectTypes.
     */
    public readonly objectTypes = {
        [AuthenticationRule]: AuthenticationRule,
        [AuthenticationAction]: AuthenticationAction,
    };

    /**
     * List of match options the user is able to add.
     */
    public matchOptions: IMatchOption[];

    /**
     * AuthenticationAction dropdown options.
     */
    public authenticationActionDropdownOptions: IAviDropdownOption[] = [];

    constructor(
        schemaService: SchemaService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const authenticationActions =
            schemaService.getEnumValues('AuthenticationActionEnum');

        this.authenticationActionDropdownOptions =
            createOptionsFromEnumProps(authenticationActions);
    }

    /** @override */
    public ngOnInit(): void {
        this.matchOptions = [
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.clientIpAddressLabel),
                objectType: AuthenticationMatch,
                fieldName: 'client_ip',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.pathLabel),
                objectType: AuthenticationMatch,
                fieldName: 'path',
            },
            {
                component: HostHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.hostHeaderLabel),
                objectType: AuthenticationMatch,
                fieldName: 'host_hdr',
            },
        ];
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
