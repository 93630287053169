/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { DataScriptSetModalComponent } from 'ng/modules/scripts';

export type TDataScriptSetCollection = typeof DataScriptSetCollection;

/**
 * @description DataScriptSet collection
 *
 * @author Aravindh Nagarajan
 */
export class DataScriptSetCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'vsdatascriptset',
            windowElement: DataScriptSetModalComponent,
            permissionName: AviPermissionResource.PERMISSION_VSDATASCRIPTSET,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('DataScriptSet');
    }
}

DataScriptSetCollection.ajsDependencies = [
    'DataScriptSet',
];
