/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author vgohil, Sarthak Kapoor
 */

/**
 * Ajs dependency token for LicenseBasedFeatures service.
 */
export const LICENSE_BASED_FEATURES_SERVICE_TOKEN = 'licenseBasedFeaturesService';

/**
 * Ajs dependency token for file service item.
 */
export const FILE_SERVICE_ITEM_TOKEN = 'FileServiceItem';

/**
 * Ajs dependency token for file service collection.
 */
export const FILE_SERVICE_COLLECTION_TOKEN = 'FileServiceCollection';

/**
 * Ajs dependency token for secretStubStr.
 */
export const SECRET_STUB_STRING = 'secretStubStr';

/**
 * Ajs dependency token for String service.
 */
export const STRING_SERVICE_TOKEN = 'stringService';
