/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { RoleCollection } from 'ajs/modules/accounts/factories/role/role.collection.factory';
import { RoleService } from 'ajs/modules/accounts/services/role-service/role.service';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './role-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const { ...globalL10nKeys } = globalL10n;

type TRoleCollection = typeof RoleCollection;

/**
 * @description Role list page.
 *
 * @author Anjali Tanpure
 */
@Component({
    selector: 'role-list-page',
    templateUrl: './role-list-page.component.html',
})
export class RoleListPageComponent implements OnInit, OnDestroy {
    /**
     * Configuration object to display role grid.
     */
    public roleGridConfig: IAviCollectionDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * RoleCollection instance.
     */
    private readonly roleCollection: RoleCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly roleService: RoleService,
        @Inject(RoleCollection)
        RoleCollection: TRoleCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.roleCollection = new RoleCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.roleCollection;

        this.roleGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.roleCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    sortBy: 'name',
                    transform: (row: IAviCollectionDataGridRow): string => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow): string => row.id,
        };

        /**
         * To get role categories for each column like assorted, read, write, no access.
         * Also, fetching permissions and labels to create a grid.
         */
        const roleCategories = this.roleService.getRoleCategories();

        roleCategories.forEach((role, index) => {
            this.roleGridConfig.fields.push({
                id: role.permission,
                label: this.l10nService.getMessage(role.name),
                transform: (index =>
                    this.roleService.getRowTransformFunctionBasedOnColumnIndex(index))(index),
            });
        });
    }

    /** @override */
    public ngOnDestroy(): void {
        this.roleCollection.destroy();
    }
}
