/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './dns-policy-action-gslb-site-selection.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name dnsPolicyActionGslbSiteSelection
 * @param {Object} action
 * @description
 *
 *     Two dropdowns to pick main and fallback GSLB Site names.
 *     GSLB site names come from
 */
class DnsPolicyActionGslbSiteSelectionController {
    constructor(GSLB, systemInfoService, l10nService) {
        this.GSLB_ = GSLB;
        this.systemInfoService_ = systemInfoService;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.gslbSites = [];
    }

    $onInit() {
        const { gslbId } = this.systemInfoService_;

        this.gslb = new this.GSLB_({ id: gslbId });

        if (gslbId) {
            this.gslb.load()
                .then(this.onGslbLoad_.bind(this));
        }
    }

    /**
     * Populates a list of GSLB Site names for dropdowns.
     * @protected
     */
    onGslbLoad_() {
        const { gslbSites } = this;

        gslbSites.length = 0;

        this.gslb.getAllSites().forEach(
            siteSlug => gslbSites.push(siteSlug.name()),
        );
    }

    $onDestroy() {
        this.gslb.destroy();
    }
}

DnsPolicyActionGslbSiteSelectionController.$inject = [
        'GSLB',
        'systemInfoService',
        'l10nService',
];

angular.module('aviApp').component('dnsPolicyActionGslbSiteSelection', {
    bindings: {
        action: '<',
    },
    controller: DnsPolicyActionGslbSiteSelectionController,
    templateUrl: 'src/components/applications/virtualservice/' +
            'virtualservice-dns-policy/dns-policy-rule-modal/dns-policy-action/' +
            'gslb-site-selection/dns-policy-action-gslb-site-selection.html',
});
