/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './virtualservice-security-ddos-full-list.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosFullList
 * @param {Object} gridConfig - Collection Grid config object for DDoS collections.
 * @param {string} title - Title of the modal.
 * @param {function} closeModal - Closes modal.
 * @description
 *     Displays the full list of DDoS Attacks/Client IPs/URLs/ASNs/Blocked IPs.
 */

class VirtualserviceSecurityDdosFullListController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

VirtualserviceSecurityDdosFullListController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('virtualserviceSecurityDdosFullList', {
    bindings: {
        gridConfig: '<',
        title: '@',
        closeModal: '&',
    },
    controller: VirtualserviceSecurityDdosFullListController,
    templateUrl: 'src/components/modals/applications/virtualservice/' +
            'virtualservice-security-ddos-full-list/' +
            'virtualservice-security-ddos-full-list.html',
});
