/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, OnDestroy } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION } from 'ng/shared/components/avi-dropdown-button';
import { VsLogsStore } from '../../services/vs-logs.store';
import { VsLogsReloadService } from '../../services/vs-logs-reload.service';
import { VsLogsDownloadService } from '../../services/vs-logs-download.service';
import * as l10n from './vs-logs-actions.l10n';
import './vs-logs-actions.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component housing Refresh and Export actions for the VS Logs Page.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-actions',
    templateUrl: './vs-logs-actions.component.html',
})
export class VsLogsActionsComponent implements OnDestroy {
    public readonly l10nKeys = l10nKeys;

    /**
     * Position definition of the export options panel relative to the export button.
     */
    public readonly exportOptionsPanelPosition: [ConnectedPosition] = [
        BOTTOM_RIGHT_ALIGNED_CONNECTED_POSITION,
    ];

    /**
     * Subject to control closing the export options panel. Subscribed to by avi-tooltip.
     */
    public readonly exportOptionsPanelCloseControl$ = new Subject<boolean>();

    constructor(
        public readonly vsLogsStore: VsLogsStore,
        private readonly vsLogsReloadService: VsLogsReloadService,
        private readonly vsLogsDownloadService: VsLogsDownloadService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.exportOptionsPanelCloseControl$.complete();
    }

    /**
     * Refresh the logs page while preserving exisiting configs/filters, in case there are new logs.
     */
    public refreshPage(): void {
        this.vsLogsReloadService.reload();
    }

    /**
     * Download all logs on the current page of the table.
     */
    public downloadCurrentPage(): void {
        this.vsLogsDownloadService.downloadCurrentPage();
        this.closeExportOptionsPanel();
    }

    /**
     * Download all logs for the given set of parameters.
     */
    public downloadAllLogs(): void {
        this.vsLogsDownloadService.downloadAllLogs();
        this.closeExportOptionsPanel();
    }

    /**
     * Download any logs that have been selected by the user.
     */
    public downloadSelectedLogs(): void {
        this.vsLogsDownloadService.downloadSelectedLogs();
        this.closeExportOptionsPanel();
    }

    /**
     * Close the Export dropdown panel.
     */
    private closeExportOptionsPanel(): void {
        this.exportOptionsPanelCloseControl$.next(false);
    }
}
