/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

// Default values for the Logs Page.
export const defaultShowSignificantLogs = true;
export const defaultShowNonSignificantLogs = false;
export const defaultPageNumber = 1;
export const defaultPageSize = 10;
