/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { IcapProfile } from 'object-types';
import { ICAP_PROFILE_ITEM_TOKEN } from '../icap-profile.tokens';

/**
 * @description ICAP-Profile collection
 *
 * @author Aravindh Nagarajan
 */
export class IcapProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'icapprofile',
            objectType: IcapProfile,
            permissionName: AviPermissionResource.PERMISSION_ICAP,
            windowElement: 'icap-profile-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ICAP_PROFILE_ITEM_TOKEN);
    }
}

IcapProfileCollection.ajsDependencies = [
    ICAP_PROFILE_ITEM_TOKEN,
];
