/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const moduleName = 'auth-settings';
const componentName = 'auth-rule-custom-mapping';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const noEntriesLabel = `${prefix}.noEntriesLabel`;
export const noAvailableMappingLabel = `${prefix}.noAvailableMappingLabel`;
export const userAccountProfileLabel = `${prefix}.userAccountProfileLabel`;
export const userRoleLabel = `${prefix}.userRoleLabel`;
export const userTenantLabel = `${prefix}.userTenantLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [noEntriesLabel]: 'No entries have been added',
    [noAvailableMappingLabel]: 'No available mapping to add',
    [userTenantLabel]: 'User Tenant',
    [userAccountProfileLabel]: 'User Account Profile',
    [userRoleLabel]: 'User Role',
};
