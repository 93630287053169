/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

const wafApplicationSignatureProviderToken = 'WafApplicationSignatureProvider';

/**
 * @description
 *
 *   WafApplicationSignatureProvider Collection.
 *
 * @author Rachit Aggarwal
 */
export class WafApplicationSignatureProviderCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            permission: 'PERMISSION_WAFPOLICY',
            objectName: 'wafapplicationsignatureprovider',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(wafApplicationSignatureProviderToken);
    }
}

WafApplicationSignatureProviderCollection.ajsDependencies = [
    wafApplicationSignatureProviderToken,
];
