/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
// @ts-expect-error
import * as d3 from 'd3';
import { throttle } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import {
    IAviBarGraphColors,
    IAviBarGraphDataPoint,
    IAviBarGraphDataPointValue,
    IAviBarGraphDataPointValueInput,
    IAviBarGraphOverlayTimeframe,
    TTimestampFormatFromApi,
    TTimestampFormatFromApiWithMicroS,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';
import { attachComponentBindings } from 'ng/shared/utils';
import {
    ILabelData,
    ILabelRowData,
    LabelCountLegendComponent,
} from 'ng/modules/diagram/components';
import {
    calculateStep,
    convertTimeToTTimestampFormatFromApi,
} from './avi-bar-graph.utils';
import * as l10n from './avi-bar-graph.l10n';
import './avi-bar-graph.component.less';

/** Height of container(s) holding bar-graph. */
const barGraphContainerHeightPx = 90;

/** Elements inside parent element must be pushed down to avoid being cut off. */
const pixelsFromTopOfParent = 10;

/** Elements inside parent element must be pushed up to avoid being cut off. */
const pixelsFromBottomOfParent = 20;

const maxHeightOfBars = 55;

/** Height of graph container */
const actualGraphHeightPx = maxHeightOfBars + pixelsFromTopOfParent + pixelsFromBottomOfParent;

/** Elements inside parent element must be pushed left to avoid being cut off. */
const pixelsFromLeftOfParent = 30;

/** Desired space between individual vertical bars representing data. */
const pixelsBetweenBars = 1;

const overlayBorderWidth = 3;

/** Number of pixels to left/right of border which can be selected for border-drag. */
const overlayBorderPlusMinus = overlayBorderWidth + 3;

const overlayBorderFillColorHex = '#77AECC';

/** Constants representing elements d3 will work with. */
const RECT_ELEMENT = 'rect';
const G_ELEMENT = 'g';
const SVG_ELEMENT = 'svg';

const COMPONENT_NAME = 'avi-bar-graph';

/** Class name constants representing classes d3 will work with.  */
const AVI_BAR_GRAPH_VIEWPORT_CLASS = `${COMPONENT_NAME}__viewport`;
const AVI_BAR_GRAPH_SVG_CONTAINER_CLASS = `${COMPONENT_NAME}__svg-container`;
const AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS = `${COMPONENT_NAME}__svg-pseudo-container`;
const AVI_BAR_GRAPH_RECT_CLASS = `${COMPONENT_NAME}__rect`;
const AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS = `${COMPONENT_NAME}__rect-svg-pseudo`;
const AVI_BAR_GRAPH_RECT_ACTUAL_CLASS = `${COMPONENT_NAME}__rect-actual`;
const AVI_BAR_GRAPH_X_AXIS_CLASS = `${COMPONENT_NAME}__x-axis`;
const AVI_BAR_GRAPH_Y_AXIS_CLASS = `${COMPONENT_NAME}__y-axis`;
const AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS = `${COMPONENT_NAME}__overlay-selection`;
const AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS = `${COMPONENT_NAME}__overlay-boundary-vertical`;
const AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS = `${COMPONENT_NAME}__overlay-drag-start`;
const AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS = `${COMPONENT_NAME}__overlay-drag-end`;
const AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS = `${COMPONENT_NAME}__overlay-boundary-horizontal`;
const AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS = `${COMPONENT_NAME}__overlay-boundary-zoom-icon`;
const AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID = `${COMPONENT_NAME}__overlay-gradient`;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** Standard date parser returns milliseconds unix time from time-format. */
function getUnixTimeInMilliseconds(
    input: TTimestampFormatFromApi | TTimestampFormatFromApiWithMicroS,
): number {
    return Date.parse(input);
}

/**
 * @description
 * Reusable component: creates bar graph for values spanning time.
 * Expects IAviBarGraphDataPoint[] as input to paint bars.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'avi-bar-graph',
    templateUrl: './avi-bar-graph.component.html',
})
export class AviBarGraphComponent implements OnInit, OnChanges, OnDestroy {
    /** Informs if waiting for data. */
    @Input()
    public isLoading: boolean;

    /** Informs if error present. */
    @Input()
    public hasError: boolean;

    /** Actual data used by d3 to draw graph. */
    @Input()
    public dataset: IAviBarGraphDataPoint[];

    /** Timestamp of duration's end => very right side of graph, where it ends. */
    @Input()
    public graphEndTimestamp: TTimestampFormatFromApi;

    /** Timestamp of duration's start => very left side of graph, where it starts. */
    @Input()
    public graphStartTimestamp: TTimestampFormatFromApi;

    @Input()
    public colors: IAviBarGraphColors;

    /** Decides if tooltip functionality will be present. */
    @Input()
    public allowHoverTooltip = true;

    /**  */
    @Input()
    public tooltipTotalLabelAddendum: string;

    /** Emitter for when user finishes creating/modifying an overlay. */
    @Output()
    public completeOverlayCreationOrModification = new EventEmitter<IAviBarGraphOverlayTimeframe>();

    /** Emitter for when user clicks zoom button in overlay. */
    @Output()
    public clickOverlayZoom = new EventEmitter();

    // TODO 158220 maybe delete if can manipulate in AviBarGraphTooltip component
    /** Ref to manipulate tooltip container. */
    @ViewChild('aviBarGraphTooltipContainerElementRef', {
        read: ElementRef,
    })
    public aviBarGraphTooltipContainerElementRef: ElementRef;

    /** Ref to pass in tooltip component. */
    @ViewChild('aviBarGraphTooltipRef', {
        read: ViewContainerRef,
        static: true,
    })
    public aviBarGraphTooltipRef: ViewContainerRef;

    public l10nKeys = l10nKeys;

    public myInjector: Injector;

    // TODO 158220 delete if works properly in AviBarGraphTooltip
    public left: number;

    /** Offset of tooltip from parent's (AviBarGraph component's) left. */
    public tooltipOffsetX: number;

    /** Width of tooltip. */
    public tooltipWidth: number;

    /** Holds value of graph container width, before it changes. */
    public currentGraphContainerWidth: number;

    public labelList: ILabelData[];

    /** Label displayed in summary/final line of tooltip. */
    public totalLogsLabel: string;

    /** Value holding d3 svg graph container, including axes. */
    private svg: d3.svg;

    /** Value holding d3 svg graph container, minus axes. */
    private svgGraph: d3.svg;

    /** Value holding d3 RECT_ELEMENT bars element selection. */
    private bars: d3.svg;

    /** Added to component state to allow for unsubscribing. */
    private graphResizeObserver: ResizeObserver;

    /**
     * Boolean for start-to-end of user clicking, dragging, and releasing.
     */
    private overlayConstructionInProgress = false;

    /**
     * Boolean for start of overlay creation from user click on bar, to creation end.
     */
    private singleBarOverlayConstructionInProgress = false;

    /**
     * When drawing/manipulating graph, serves as the vertical border which has been set,
     * and is not currently moving - whether user drags left or drags right.
     * When moving entire overlay, serves as original point user dragged from.
     */
    private overlayStaticVerticalBorderPixels: DragEvent['x'];

    /**
     * Leftmost point of user click+drag.
     */
    private overlayStartPixels: DragEvent['x'];

    /**
     * Rightmost point of user click+drag.
     */
    private overlayEndPixels: DragEvent['x'];

    /**
     * Start time for overlay.
     */
    private overlayStartTime: TTimestampFormatFromApiWithMicroS;

    /**
     * End time for overlay.
     */
    private overlayEndTime: TTimestampFormatFromApiWithMicroS;

    constructor(
        private readonly injector: Injector,
        // TODO 158220 delete if intermediate comp takes care of positioning
        private renderer: Renderer2,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.labelList = Object.keys(this.colors).map(key => {
            const {
                color,
                label,
            } = this.colors[key];

            return {
                hasLeftColor: true,
                color,
                label,
            };
        });

        const totalLabel = this.l10nService.getMessage(l10nKeys.totalLabel);
        const { tooltipTotalLabelAddendum } = this;

        this.totalLogsLabel =
            tooltipTotalLabelAddendum ? `${totalLabel} ${tooltipTotalLabelAddendum}` : totalLabel;
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.graphResizeObserver) {
            this.graphResizeObserver.disconnect();
        }
    }

    /**
     * Paints d3 graph upon changes on input variables.
     * @override
     */
    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataset?.isFirstChange()) {
            return;
        }

        // TODO delete AV-150203
        // this.addStartEndTimeframeData();

        this.refreshGraph();

        if (!this.graphResizeObserver) {
            this.setGraphResizeObserver();
        }
    }

    /**
     * Sets listener:subscriber to listen to and respond to parent element size change.
     * This is run only once.
     * It must wait till d3 selection of svg is made before running.
     */
    private setGraphResizeObserver = (): void => {
        this.graphResizeObserver = new ResizeObserver(this.handleGraphResize);

        this.graphResizeObserver
            .observe(document.querySelector(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`));
    };

    // TODO 150203 add to testing file (or automation tests), and delete from here
    //  Following tests whether extreme left/right of graph accurately show
    //  data points right at startTime and endTime of graphs;
    //  this helps validate that graph is positioning bars correctly on x-axis.
    private addStartEndTimeframeData(): void {
        // fixme: comment-out if want to test with 0 results in graph
        // if (this.dataset.length) return;

        // fixme change 'value' prop as needed for useful bar height
        const totalValueSummation = 4;

        const values = [
            {
                color: this.colors.significant.color,
                count: Math.round(totalValueSummation * 0.4),
                type: 'significant',
            }, {
                color: this.colors.nonSignificant.color,
                count: totalValueSummation - Math.round(totalValueSummation * 0.4),
                type: 'non-significant',
            },
        ];

        const singleBarMs = 1000 * Number(calculateStep(
            this.getDurationInMilliSeconds(this.graphEndTimestamp, this.graphStartTimestamp) / 1000,
        ));

        const graphEndUnix = getUnixTimeInMilliseconds(this.graphEndTimestamp);

        const pseudoLastBarStartMs = graphEndUnix - singleBarMs;

        const pseudoLastBarStartStandard =
            convertTimeToTTimestampFormatFromApi(pseudoLastBarStartMs);

        this.dataset.push({
            end: this.graphEndTimestamp,
            start: pseudoLastBarStartStandard,
            totalValueSummation,
            values,
        });

        const graphStartUnix = getUnixTimeInMilliseconds(this.graphStartTimestamp);

        const pseudoFirstBarEndMs = graphStartUnix + singleBarMs;

        const pseudoFirstBarEndStandard =
            convertTimeToTTimestampFormatFromApi(pseudoFirstBarEndMs);

        this.dataset.push({
            end: pseudoFirstBarEndStandard,
            start: this.graphStartTimestamp,
            totalValueSummation,
            values,
        });
    }

    /**
     * Handles horizontal resizing of graph as needed.
     * Modifies size and position of bars, and size and spacing of x-axis.
     * Modifies overlay position/width, if overlay exists.
     * Throttle used to limit excessive firing, low enough number used to avoid jumpy effect.
     */
    // eslint-disable-next-line @typescript-eslint/member-ordering
    private handleGraphResize = throttle(() => {
        if (this.dataset.length) {
            this.setHorizontalPropsForBars();
        }

        this.svg.select(`.${AVI_BAR_GRAPH_X_AXIS_CLASS}`)
            .call(this.scaleXAxis());

        if (this.overlayExists()) {
            const oldGraphWidth = this.currentGraphContainerWidth - pixelsFromLeftOfParent;
            const newGraphWidth = this.getGraphContainerWidth() - pixelsFromLeftOfParent;

            if (newGraphWidth !== oldGraphWidth) {
                const oldXLeft = this.overlayStartPixels;
                const oldXRight = this.overlayEndPixels;
                const graphWidthsFraction = newGraphWidth / oldGraphWidth;
                const newXLeft = graphWidthsFraction * oldXLeft + pixelsFromLeftOfParent;
                const newXRight = graphWidthsFraction * oldXRight + pixelsFromLeftOfParent;

                this.handleOverlayCreationStart(newXLeft, true);
                this.handleOverlayCreationInProcess(newXRight, true);
                this.handleOverlayCreationEnd(newXRight, true);
            }
        }

        this.currentGraphContainerWidth = this.getGraphContainerWidth();
    }, 30);

    /**
     * Runs after initial data retrieval, as well as any time data changes.
     * Holds seperated out methods which take care of each disparate part
     * of graph creation: axes, bars, removal, etc.
     *
     * d3 treats creation of axes, scaling, and creation of bars as separate,
     * thus these actions have been separated out in distinct methods.
     * They are ultimately bound together to the same d3 svg element selection.
     */
    private refreshGraph(): void {
        this.removeSvg();
        this.createSvg();

        if (this.dataset.length) {
            this.createGraph();
        }

        this.createYAxis();
        this.createXAxis();
        this.setOverlayColorGradient();
        this.enableOverlayCreation();
        this.enableSingleBarOverlayCreation();
        this.enableOverlayDeletionUponEmptySpaceClick();

        if (this.allowHoverTooltip) {
            this.enableTooltipCreation();
        }
    }

    /**
     * Deletes 'svg' from page.
     */
    private removeSvg = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();
        d3.select(SVG_ELEMENT).remove();
    };

    /**
     * Creates 'svg' element.
     */
    private createSvg = (): void => {
        d3
            .select(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`)
            .append(SVG_ELEMENT);

        this.svg = d3
            .select(SVG_ELEMENT)
            .attr('height', barGraphContainerHeightPx)
            .attr('width', '100%')
            .classed(AVI_BAR_GRAPH_SVG_CONTAINER_CLASS, true);

        // Separate svg container needed to represent area holding only bars, exclusive of axes.
        this.svgGraph = this.svg
            .append(SVG_ELEMENT)
            .classed(AVI_BAR_GRAPH_SVG_PSEUDO_CONTAINER_CLASS, true)
            .attr('height', barGraphContainerHeightPx)
            .attr('x', pixelsFromLeftOfParent)
            .attr('width', '100%');
    };

    /**
     * Allows drag and select of custom time range.
     */
    private enableOverlayCreation = (): void => {
        const dragOverlayInstruction = d3.drag()
            .on('start', (dragEvent: DragEvent) => this.handleOverlayCreationStart(dragEvent.x))
            // todo debounce/throttle
            .on('drag', (dragEvent: DragEvent) => this.handleOverlayCreationInProcess(dragEvent.x))
            .on('end', (dragEvent: DragEvent) => this.handleOverlayCreationEnd(dragEvent.x));

        d3.select(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`).call(dragOverlayInstruction);
    };

    /**
     * Triggers upon user clicking anywhere in graph area.
     */
    private handleOverlayCreationStart = (x: DragEvent['x'], graphResized = false): void => {
        const shiftedX = x - pixelsFromLeftOfParent;

        this.overlayStaticVerticalBorderPixels = shiftedX;

        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            return;
        }

        if (!graphResized && this.hasLeftBoundaryBeenClicked()) {
            this.overlayStaticVerticalBorderPixels = this.overlayEndPixels;
        } else if (!graphResized && this.hasRightBoundaryBeenClicked()) {
            this.overlayStaticVerticalBorderPixels = this.overlayStartPixels;
        }

        this.overlayConstructionInProgress = true;
    };

    /**
     * Triggers upon user dragging after click in graph area.
     */
    private handleOverlayCreationInProcess = (x: MouseEvent['x'], graphResized = false): void => {
        const shiftedX = x - pixelsFromLeftOfParent;

        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            this.handleOverlayDrag(shiftedX);

            return;
        }

        if (this.isOverlayBoundaryOutOfBounds(shiftedX)) {
            return;
        }

        this.resetOverlay();

        /** Draw initial vertical overlay boundary. */
        if (d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS}`).empty()) {
            this.drawVerticalOverlayBoundary(
                this.overlayStaticVerticalBorderPixels,
                AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS,
            );
        }

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).remove();
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`).remove();

        this.drawVerticalOverlayBoundary(shiftedX, AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
        this.drawOverlay(shiftedX);
        this.drawHorizontalOverlayBoundaries(shiftedX);

        this.aviBarGraphTooltipRef.clear();

        if (!graphResized) {
            this.createOverlayTooltip(shiftedX);
        }
    };

    /**
     * Triggers upon user letting go of mouse-click upon drag in graph area.
     */
    private handleOverlayCreationEnd = (x: DragEvent['x'], graphResized = false): void => {
        let shiftedX = x - pixelsFromLeftOfParent;

        this.aviBarGraphTooltipRef.clear();

        /**
         * Terminate if user clicked, but didn't move. Because:
         * D3's 'drag' functionality has 3 parts: 'start', 'drag', and 'end'
         * 'start' and 'end' still fire if user clicked, but let go without dragging.
         */
        if (shiftedX === this.overlayStaticVerticalBorderPixels) {
            this.overlayStaticVerticalBorderPixels = undefined;
            this.overlayConstructionInProgress = false;
            d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();

            return;
        }

        const containerWidth = this.getGraphContainerWidth();

        /**
         * Though overlay cannot go beyond left/right of graph, the cursor can end beyond them.
         * This resets the end point(s), disallowing either one of overlay boundary left/right
         * from being set to beyond limits of graph.
         */
        if (x > containerWidth - 2) {
            shiftedX = containerWidth - pixelsFromLeftOfParent - 3;
        } else if (shiftedX < 0) {
            shiftedX = 0;
        }

        /**
         * Functionality for when overlay is being dragged.
         * Conditionals in place if user drags overlay to beyond edges of graph: prevents
         * storage of overlay numbers being stored beyond edges.
         */
        if (!graphResized && this.hasExistingOverlayBeenClicked()) {
            const xDifferencePixels = shiftedX - this.overlayStaticVerticalBorderPixels;

            let overlayEndPixelsPlusDiff = this.overlayEndPixels + xDifferencePixels;
            const didCursorPositionEndBeyondRightGraphBoundary =
                overlayEndPixelsPlusDiff > containerWidth - pixelsFromLeftOfParent - 2;

            let overlayStartPixelsPlusDiff = this.overlayStartPixels + xDifferencePixels;
            const didCursorPositionEndBeyondLeftGraphBoundary = overlayStartPixelsPlusDiff < 0;

            if (didCursorPositionEndBeyondLeftGraphBoundary) {
                overlayEndPixelsPlusDiff = this.getOverlayWidthPx();
            }

            const rightEdgeOfGraph = containerWidth - pixelsFromLeftOfParent - 3;
            const leftEdgeOfGraph = 0;

            if (didCursorPositionEndBeyondRightGraphBoundary) {
                overlayStartPixelsPlusDiff = rightEdgeOfGraph - this.getOverlayWidthPx();
            }

            this.setOverlayBoundaryNumbers(
                didCursorPositionEndBeyondLeftGraphBoundary ?
                    leftEdgeOfGraph : overlayStartPixelsPlusDiff,
                didCursorPositionEndBeyondRightGraphBoundary ?
                    rightEdgeOfGraph : overlayEndPixelsPlusDiff,
            );

            this.handlePostOverlayCreation();

            return;
        }

        const draggedLeftToRight = this.isDragFromLeftToRight(shiftedX);

        const { overlayStaticVerticalBorderPixels } = this;

        this.setOverlayBoundaryNumbers(
            draggedLeftToRight ? overlayStaticVerticalBorderPixels : shiftedX,
            draggedLeftToRight ? shiftedX : overlayStaticVerticalBorderPixels,
        );

        this.handlePostOverlayCreation();
    };

    /**
     * Handles user click of overlay.
     */
    private handleOverlayClick = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
            .on('click', (): void => {
                this.clickOverlayZoom.emit();
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
            .on('click', (): void => {
                this.clickOverlayZoom.emit();
                d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
                    .remove();
            });
    };

    /**
     * Handles remaining things once overlay is created.
     */
    private handlePostOverlayCreation = (graphResized = false): void => {
        this.overlayStaticVerticalBorderPixels = undefined;
        this.overlayConstructionInProgress = false;

        this.createZoomIconForOverlay();

        if (!graphResized) {
            this.emitOverlaySettings();
        }

        this.changeOverlayHoverCursor();
        this.handleOverlayClick();
    };

    /**
     * Emits to parent when overlay is created/altered.
     */
    private emitOverlaySettings = (): void => {
        const duration = this.getOverlayDuration() / 1000;

        this.completeOverlayCreationOrModification.emit({
            end: this.overlayEndTime,
            duration,
        });
    };

    /**
     * Creates zoom-in icon upon overlay creation/modification.
     */
    private createZoomIconForOverlay = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();

        if (this.getOverlayDuration() / 1000 < 120) {
            return;
        }

        const zoomIcon = document.createElement('cds-icon');

        zoomIcon.shape = 'zoom-in';

        const pxFromBottom = maxHeightOfBars + 11;
        const pxFromLeft = pixelsFromLeftOfParent - 6 + this.overlayStartPixels +
            (this.overlayEndPixels - this.overlayStartPixels) / 2;

        d3.select(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`)
            .insert(() => zoomIcon)
            .classed(AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS, true)
            .style('position', 'relative')
            .style('bottom', `${pxFromBottom}px`)
            .style('left', `${pxFromLeft}px`);
    };

    /**
     * Sets pixels from left of graph and time for overlay start and end.
     */
    private setOverlayBoundaryNumbers = (startPixels: number, endPixels: number): void => {
        this.overlayStartPixels = startPixels;
        this.overlayEndPixels = endPixels;
        this.overlayStartTime = this.getTimeFromPixels(this.overlayStartPixels);
        this.overlayEndTime = this.getTimeFromPixels(this.overlayEndPixels);
    };

    /**
     * Informs when left/right boundary is beyond x-axis of graph.
     */
    private isOverlayBoundaryOutOfBounds = (boundary: DragEvent['x']): boolean => {
        const graphRightExtreme = this.getGraphContainerWidth() -
            pixelsFromLeftOfParent - overlayBorderWidth;

        return boundary < 0 || boundary > graphRightExtreme;
    };

    /**
     * Sets cursor to desired upon mouse hover.
     */
    private changeOverlayHoverCursor = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'ew-resize');
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'move');
            });

        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`)
            .on('mouseover', function() {
                d3.select(this)
                    .style('cursor', 'pointer');
            });
    };

    /**
     * Creates overlay upon click-selection of single data-bar.
     */
    private enableSingleBarOverlayCreation = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS}`)
            .on('click', (ev: d3.onClick) => {
                this.singleBarOverlayConstructionInProgress = true;
                this.resetOverlay();

                const { __data__: data } = ev.currentTarget;
                const x1 = this.getXPositionFromTimestamp(data.start);
                const x2 = this.getXPositionFromTimestamp(data.end);

                this.overlayStaticVerticalBorderPixels = x1;
                this.drawVerticalOverlayBoundary(x1, AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS);
                this.drawVerticalOverlayBoundary(x2, AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
                this.drawOverlay(x2);
                this.drawHorizontalOverlayBoundaries(x2);

                this.setOverlayBoundaryNumbers(x1, x2);
                this.handlePostOverlayCreation();
            });
    };

    /**
     * Deletes overlay if user clicks space outside overlay and between bars.
     */
    private enableOverlayDeletionUponEmptySpaceClick = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`)
            .on('click', () => {
                if (this.singleBarOverlayConstructionInProgress) {
                    this.singleBarOverlayConstructionInProgress = false;

                    return;
                }

                if (this.overlayExists()) {
                    d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`)
                        .remove();

                    d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`)
                        .remove();

                    d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS}`)
                        .remove();

                    this.completeOverlayCreationOrModification.emit({
                        end: this.graphEndTimestamp,
                        duration: this.getGraphDurationInSeconds(),
                    });
                }
            });
    };

    /**
     * Aggregates data point information in given timeframe.
     */
    private getAviBarGraphDataPointFromTimeframe =
    (startPx: number, endPx: number): IAviBarGraphDataPoint => {
        const start = this.getTimeFromPixels(startPx);
        const end = this.getTimeFromPixels(endPx);
        const initialValue: IAviBarGraphDataPoint = {
            end,
            start,
            totalValueSummation: 0,
            values: [],
        };

        return this.dataset.reduce((previousDataPoint, currentDataPoint, i, set) => {
            const {
                start: currentDataPtStart,
                end: currentDataPtEnd,
            } = currentDataPoint;

            const overlayEndMs = getUnixTimeInMilliseconds(end);
            const overlayStartMs = getUnixTimeInMilliseconds(start);
            const currentDataPtEndMs = getUnixTimeInMilliseconds(currentDataPtEnd);
            const currentDataPtStartMs = getUnixTimeInMilliseconds(currentDataPtStart);
            const fallsOutsideOverlay =
                overlayEndMs <= currentDataPtEndMs || currentDataPtStartMs <= overlayStartMs;

            if (fallsOutsideOverlay) {
                return previousDataPoint;
            }

            const {
                values: previousValues,
                totalValueSummation: previousTotal,
            } = previousDataPoint;
            const {
                values: currentValues,
                totalValueSummation: currentTotal,
            } = currentDataPoint;
            const previousValueTypes = previousValues.map(({ type }) => type);
            // TODO Delete when library "@types/node" is upgraded to v17
            // @ts-expect-error
            const values: IAviBarGraphDataPointValueInput[] = structuredClone(previousValues);

            currentValues.forEach(currentVal => {
                const {
                    type: currentType,
                    count,
                } = currentVal;
                const currentTypeIndex = previousValueTypes.indexOf(currentType);

                if (currentTypeIndex === -1) {
                    values.push(currentVal);
                } else {
                    values[currentTypeIndex].count += count;
                }
            });

            return {
                end,
                start,
                totalValueSummation: previousTotal + currentTotal,
                values,
            };
        }, initialValue);
    };

    /**
     * Creates tooltip over overlay.
     */
    private createOverlayTooltip = (x: number): void => {
        const { overlayStaticVerticalBorderPixels } = this;
        const draggedLeftToRight = this.isDragFromLeftToRight(x);
        const leftPx = draggedLeftToRight ? overlayStaticVerticalBorderPixels : x;
        const rightPx = draggedLeftToRight ? x : overlayStaticVerticalBorderPixels;

        this.createTooltip(
            this.getAviBarGraphDataPointFromTimeframe(leftPx, rightPx),
            x,
        );
    };

    /**
     * Handles tooltip creation upon user hover over data bars.
     */
    private enableTooltipCreation = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const barGraphInstanceContext = this;

        d3.selectAll(`.${AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS}`)
            .on('mouseover', function(d: MouseEvent) {
                barGraphInstanceContext.createTooltip(d3.select(this).data()[0], d.offsetX);
            })
            .on('mouseout', function() {
                barGraphInstanceContext.aviBarGraphTooltipRef.clear();
            });
    };

    /**
     * Create hover tooltip to display desired data info.
     */
    private createTooltip = (data: IAviBarGraphDataPoint, x: number): void => {
        const componentRef = this.createTooltipComponentRef();
        const { aviBarGraphTooltipRef } = this;

        const {
            end,
            start,
            values,
        } = data;

        const labelList = this.transformDataToTooltipInput(values);

        /** Done to remove summation/total count from data. */
        const summarySectionLabel = labelList.shift();

        attachComponentBindings(
            componentRef,
            {
                title: this.getTooltipTitle(start, end),
                labelList,
                summarySectionLabel,
            },
        );

        aviBarGraphTooltipRef.insert(componentRef.hostView);

        // TODO 158220 delete
        this.left = x - 60;
        this.tooltipOffsetX = x;

        setTimeout(() => {
            this.tooltipWidth =
                this.aviBarGraphTooltipContainerElementRef.nativeElement.offsetWidth;

            // TODO delete 158220
            // console.log(this.tooltipWidth);
        }, 0);

        // TODO 158220 Delete if keeping avi-gar-graph-tooltip.component
        // this.renderer.setStyle(
        //     aviBarGraphTooltipContainerElementRef.nativeElement,
        //     'left', `${d.offsetX - 60}px`,
        // );
    };

    /**
     * Returns tooltipref element.
     */
    private createTooltipComponentRef = (): ComponentRef<Component> => {
        const { aviBarGraphTooltipRef } = this;

        aviBarGraphTooltipRef.clear();

        const componentFactory = this.componentFactoryResolver
            .resolveComponentFactory(LabelCountLegendComponent as Type<Component>);

        return componentFactory.create(aviBarGraphTooltipRef.injector);
    };

    /**
     * Transforms data to format needed by tooltip.
     */
    private transformDataToTooltipInput =
    (values: IAviBarGraphDataPointValueInput[]): ILabelRowData[] => {
        return values.map((val: IAviBarGraphDataPointValueInput) => {
            const {
                color,
                count,
                type: label,
            } = val;

            return {
                color,
                count,
                hasLeftColor: label !== this.totalLogsLabel,
                label,
            };
        });
    };

    /**
     * Transorms start & end times into title to display in tooltip.
     */
    private getTooltipTitle =
    (start: TTimestampFormatFromApi, end: TTimestampFormatFromApi): string => {
        const { timeFormat } = d3;
        const isMoreThan24Hours =
            this.getDurationInMilliSeconds(end, start) > 86400000;

        const formatTime = isMoreThan24Hours ?
            timeFormat('%m/%d %I:%M:%S %p') : timeFormat('%I:%M:%S %p');
        const titleStart = formatTime(getUnixTimeInMilliseconds(start));
        const titleEnd = formatTime(getUnixTimeInMilliseconds(end));

        return `${titleStart} - ${titleEnd}`;
    };

    /**
     * Determines if overlay exists or is in process of being made.
     */
    private overlayExists = (): boolean => {
        return !d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).empty();
    };

    /**
     * Determines if left boundary has been clicked.
     */
    private hasLeftBoundaryBeenClicked = (): boolean => {
        const {
            overlayStaticVerticalBorderPixels,
            overlayStartPixels,
        } = this;

        return overlayStaticVerticalBorderPixels >
            overlayStartPixels - overlayBorderPlusMinus &&
            overlayStaticVerticalBorderPixels <
            overlayStartPixels + overlayBorderPlusMinus;
    };

    /**
     * Determines if right boundary has been clicked.
     */
    private hasRightBoundaryBeenClicked = (): boolean => {
        const {
            overlayStaticVerticalBorderPixels,
            overlayEndPixels,
        } = this;

        return overlayStaticVerticalBorderPixels >
            overlayEndPixels - overlayBorderPlusMinus &&
            overlayStaticVerticalBorderPixels < overlayEndPixels + overlayBorderPlusMinus;
    };

    /**
     * Draws custom overlay.
     * @param {x} DragEvent['x'] - Represents:
     *  when overlay is being created or a boundary is being dragged, the current cursor position;
     *  when overlay is being moved, old left boundary + number of pixels being moved
     */
    private drawOverlay = (x: DragEvent['x'], isOverlayBeingDragged = false): void => {
        const widthPixels = isOverlayBeingDragged ?
            this.overlayEndPixels - this.overlayStartPixels :
            this.getOverlayWidthPxDuringConstruction(x);

        /**
         * Represents:
         *  when overlay created left to right, left boundary;
         *  when overlay created right to left, current cursor position;
         *  when overlay is being moved, old left boundary + number of pixels being moved
         */
        let startingPositionPixels: number;

        if (isOverlayBeingDragged || !this.isDragFromLeftToRight(x)) {
            startingPositionPixels = x;
        } else {
            startingPositionPixels = this.overlayStaticVerticalBorderPixels;
        }

        this.svgGraph
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS, true)
            .attr('x', startingPositionPixels + 2)
            .attr('y', pixelsFromTopOfParent)
            .attr('width', widthPixels)
            .attr('height', maxHeightOfBars)
            .attr('fill', `url(#${AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID})`);
    };

    /**
     * Draws left/right boundary for custom overlay.
     */
    private drawVerticalOverlayBoundary = (xPosition: number, borderUniqueClass: string): void => {
        this.svgGraph
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_VERTICAL_CLASS, true)
            .classed(borderUniqueClass, true)
            .attr('x', xPosition)
            .attr('y', pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', overlayBorderWidth)
            .attr('height', maxHeightOfBars + overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);
    };

    /**
     * Determines if user click occurred inside existing overlay.
     */
    private hasExistingOverlayBeenClicked = (): boolean => {
        if (!this.overlayExists() || this.overlayConstructionInProgress) {
            return false;
        }

        const { overlayStaticVerticalBorderPixels: x } = this;

        const isRightOfBorderPlusMargin = x > this.overlayStartPixels + overlayBorderPlusMinus;
        const isLeftOfBorderPlusMargin = x < this.overlayEndPixels - overlayBorderPlusMinus;

        if (isRightOfBorderPlusMargin && isLeftOfBorderPlusMargin) {
            return true;
        }

        return false;
    };

    /**
     * Resets all overlay properties and deletes existing overlay.
     */
    private resetOverlay = (): void => {
        this.overlayStartPixels = undefined;
        this.overlayEndPixels = undefined;
        this.overlayStartTime = undefined;
        this.overlayEndTime = undefined;
        this.removeOverlayGraphics();
    };

    /**
     * Removes existing overlay from page. Does not delete properties.
     */
    private removeOverlayGraphics = (): void => {
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_SELECTION_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS}`).remove();
        d3.selectAll(`.${AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS}`).remove();
        d3.select(`.${AVI_BAR_GRAPH_OVERLAY_ZOOM_ICON_CLASS}`).remove();
    };

    /**
     * Drags overlay.
     */
    private handleOverlayDrag = (currentX: DragEvent['x']): void => {
        const xDifferencePixels = currentX - this.overlayStaticVerticalBorderPixels;

        const newLeftBoundaryPixels = this.overlayStartPixels + xDifferencePixels;
        const newRightBoundaryPixels = this.overlayEndPixels + xDifferencePixels;

        if (this.isOverlayBoundaryOutOfBounds(newLeftBoundaryPixels) ||
            this.isOverlayBoundaryOutOfBounds(newRightBoundaryPixels)) {
            return;
        }

        this.removeOverlayGraphics();

        this.drawVerticalOverlayBoundary(newLeftBoundaryPixels,
            AVI_BAR_GRAPH_OVERLAY_DRAG_START_CLASS);
        this.drawVerticalOverlayBoundary(newRightBoundaryPixels,
            AVI_BAR_GRAPH_OVERLAY_DRAG_END_CLASS);
        this.drawOverlay(newLeftBoundaryPixels, true);
        this.drawHorizontalOverlayBoundaries(newLeftBoundaryPixels, true);
    };

    /**
     * Sets gradient for overlay.
     */
    private setOverlayColorGradient = (): void => {
        const gradientElement = this.svgGraph
            .append('linearGradient')
            .attr('id', AVI_BAR_GRAPH_OVERLAY_GRADIENT_ID)
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 1);

        gradientElement
            .append('stop')
            .attr('offset', '0%')
            .attr('stop-color', '#67b0cb') // aviBlue3
            .attr('stop-opacity', 0.3)
            .attr('offset', 0);

        gradientElement
            .append('stop')
            .attr('offset', '100%')
            .attr('stop-color', 'white')
            .attr('stop-opacity', 0)
            .attr('offset', 0.6);
    };

    /**
     * Draws top and bottom boundaries for custom overlay.
     */
    private drawHorizontalOverlayBoundaries =
    (x: DragEvent['x'], isOverlayBeingDragged = false): void => {
        const widthPixels = isOverlayBeingDragged ?
            this.overlayEndPixels - this.overlayStartPixels :
            this.getOverlayWidthPxDuringConstruction(x);

        let startingPositionPixels: number;

        if (isOverlayBeingDragged || !this.isDragFromLeftToRight(x)) {
            startingPositionPixels = x;
        } else {
            startingPositionPixels = this.overlayStaticVerticalBorderPixels;
        }

        // Top bar
        this.svgGraph
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS, true)
            .attr('x', startingPositionPixels)
            .attr('y', pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', widthPixels)
            .attr('height', overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);

        // Bottom bar
        this.svgGraph
            .append(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_OVERLAY_HORIZONTAL_CLASS, true)
            .attr('x', startingPositionPixels)
            .attr('y', maxHeightOfBars + pixelsFromTopOfParent - overlayBorderWidth)
            .attr('width', widthPixels)
            .attr('height', overlayBorderWidth)
            .attr('fill', overlayBorderFillColorHex);
    };

    /**
     * Returns width of overlay during construction of overlay.
     */
    private getOverlayWidthPxDuringConstruction = (currentX: DragEvent['x']): number => {
        return Math.abs(currentX - this.overlayStaticVerticalBorderPixels);
    };

    /**
     * Returns width of overlay after it is created.
     */
    private getOverlayWidthPx = (): number => {
        if (!this.overlayStartPixels) {
            return 0;
        }

        return this.overlayEndPixels - this.overlayStartPixels;
    };

    /**
     * Returns duration of overlay in milliseconds.
     */
    private getOverlayDuration = (): number => {
        if (!this.overlayStartTime) {
            return 0;
        }

        return getUnixTimeInMilliseconds(this.overlayEndTime) -
            getUnixTimeInMilliseconds(this.overlayStartTime);
    };

    /**
     * Determines if user went left->right or right->left.
     */
    private isDragFromLeftToRight = (currentX: DragEvent['x']): boolean => {
        return currentX > this.overlayStaticVerticalBorderPixels;
    };

    /**
     * Converts pixels from left extreme of graph to time, in a readable format.
     */
    private getTimeFromPixels =
    (pixelsFromLeft: number): TTimestampFormatFromApiWithMicroS => {
        const {
            graphEndTimestamp,
            graphStartTimestamp,
        } = this;

        const ratioFromLeft =
            pixelsFromLeft / (this.getGraphContainerWidth() - pixelsFromLeftOfParent);
        const milliSecondsFromStart =
            ratioFromLeft * this.getDurationInMilliSeconds(graphEndTimestamp, graphStartTimestamp);
        const unixTime = milliSecondsFromStart + getUnixTimeInMilliseconds(graphStartTimestamp);

        return convertTimeToTTimestampFormatFromApi(unixTime);
    };

    /**
     * Main functionality creating actual bars in graph from data.
     */
    private createGraph = (): void => {
        this.bars = this.svgGraph.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
            .data(this.dataset)
            .join(SVG_ELEMENT) // extra full size pseudo-element
            .classed(AVI_BAR_GRAPH_RECT_SVG_PSEUDO_CLASS, true);

        this.addBars();
        this.setHorizontalPropsForBars();
        this.changeGraphCursor();
    };

    /**
     * Sets cursor to desired upon mouse hover over graph area.
     */
    private changeGraphCursor = (): void => {
        d3.select(`.${AVI_BAR_GRAPH_SVG_CONTAINER_CLASS}`)
            .on('mouseover', function(): void {
                d3.select(this).style('cursor', 'crosshair');
            });
    };

    /**
     * Adds actual bars from data.
     */
    private addBars = (): void => {
        this.bars.selectAll(`.${AVI_BAR_GRAPH_RECT_CLASS}`)
            .data((datum: IAviBarGraphDataPoint): IAviBarGraphDataPointValue[] => {
                const {
                    end,
                    start,
                    totalValueSummation,
                } = datum;

                const { totalLogsLabel } = this;

                let lastCount = 0;

                // Add pseudo-bar to represent full column
                // yPosition for this will be incorrect, and handled separately
                datum.values.unshift({
                    color: '#fafafa',
                    count: totalValueSummation,
                    type: totalLogsLabel,
                });

                // Return actual properties needed to create bars
                return datum.values
                    .map((value: IAviBarGraphDataPointValueInput): IAviBarGraphDataPointValue => {
                        const {
                            color,
                            type,
                        } = value;

                        let { count } = value;

                        if (type === totalLogsLabel) {
                            count = 0;
                        }

                        const currentCount = lastCount;

                        lastCount += count;

                        return {
                            color,
                            count,
                            end,
                            start,
                            type,
                            yPosition: currentCount + count,
                        };
                    });
            })
            .join(RECT_ELEMENT)
            .classed(AVI_BAR_GRAPH_RECT_CLASS, true)
            .classed(AVI_BAR_GRAPH_RECT_ACTUAL_CLASS, true)
            // Represents top of the bar in pixels
            .attr('y', (value: IAviBarGraphDataPointValue): number => {
                // sets to match max y-value out of all columns for pseudo-bar
                if (value.type === this.totalLogsLabel) {
                    return pixelsFromTopOfParent;
                }

                return actualGraphHeightPx - pixelsFromBottomOfParent -
                this.createBarYScale()(value.yPosition);
            })
            .attr('height', (value: IAviBarGraphDataPointValue): number => {
                // sets to match max count out of all data for pseudo-bar
                if (value.type === this.totalLogsLabel) {
                    return maxHeightOfBars;
                }

                return this.createBarYScale()(value.count);
            })
            .attr('fill', (value: IAviBarGraphDataPointValue):
            IAviBarGraphDataPointValue['color'] => value.color);
    };

    /**
     * Sets width and x-position for bars.
     */
    private setHorizontalPropsForBars = (): void => {
        this.bars.selectAll(`.${AVI_BAR_GRAPH_RECT_ACTUAL_CLASS}`)
            .attr('width', (datum: IAviBarGraphDataPoint): number => this.getBarWidth(datum))
            .attr('transform', (datum: IAviBarGraphDataPoint): string => {
                const xPosition = this.getXPositionFromTimestamp(datum.start);
                const translate = [xPosition, 0];

                return `translate(${translate})`;
            });
    };

    /**
     * Returns width of single bar given data point.
     */
    private getBarWidth = (datum: IAviBarGraphDataPoint): number => {
        const graphContainerWidth = this.getGraphContainerWidth();

        if (graphContainerWidth <= pixelsBetweenBars) {
            return 0;
        }

        const {
            end,
            start,
        } = datum;

        const barWidth = this.getDurationInMilliSeconds(end, start);
        const graphDuration = this.getGraphDuration();

        const oneDivisionWidth = barWidth / graphDuration * this.getGraphContainerWidth();

        return oneDivisionWidth < pixelsBetweenBars ?
            oneDivisionWidth : oneDivisionWidth - pixelsBetweenBars;
    };

    /**
     * Returns yScale needed for bars in graph.
     * Specifically for bars in graph, separate from scaling needed for y-axis.
     */
    private createBarYScale = (): d3.scaleLinear =>
        d3.scaleLinear()
            .domain([0, d3.max(
                this.dataset,
                (datum: IAviBarGraphDataPoint) => datum.totalValueSummation,
            )])
            // Denotes min to max range of any given datum's value
            .range([
                0,
                maxHeightOfBars,
            ]);

    /**
     * Creates y-axis for bar graph.
     */
    private createYAxis = (): void => {
        const maxVal = d3.max(
            this.dataset,
            (datum: IAviBarGraphDataPoint) => datum.totalValueSummation,
        );

        const yAxisScale = d3.scaleLinear()
            .domain([0, maxVal])
            // go backwards => bottom to top (negative)
            .range([actualGraphHeightPx - pixelsFromBottomOfParent, pixelsFromTopOfParent]);

        const yAxis = d3.axisLeft()
            .scale(yAxisScale)
            .tickSize(0)
            .tickFormat(d3.format('.0f'));

        yAxis.ticks(maxVal > 3 ? 4 : maxVal);

        this.svg
            .append(G_ELEMENT)
            .classed(AVI_BAR_GRAPH_Y_AXIS_CLASS, true)
            .call(yAxis)
            .attr('transform', `translate(${pixelsFromLeftOfParent}, 0)`);
    };

    /**
     * Creates x-axis for bar graph.
     */
    private createXAxis = (): void => {
        const topTranslation = actualGraphHeightPx - pixelsFromBottomOfParent;

        this.svg
            .append(G_ELEMENT)
            .classed(AVI_BAR_GRAPH_X_AXIS_CLASS, true)
            .attr('transform', `translate(0, ${topTranslation})`)
            .call(this.scaleXAxis());
    };

    /**
     * Returns real-time width of graph container element.
     */
    private getGraphContainerWidth = (): number =>
        /* eslint-disable no-extra-parens */
        (document.querySelector(`.${AVI_BAR_GRAPH_VIEWPORT_CLASS}`) as HTMLElement)
            .offsetWidth;

    /**
     * Returns starting horizontal position for provided timestamp.
     * Calculates ratio: (bar-start-time - graph-start-time)/(graph duration),
     * multiplies by graph width, and sets the bar at this position relative to svg container.
     */
    private getXPositionFromTimestamp = (timestamp: TTimestampFormatFromApi): number => {
        const milliSecondsFromStart =
            this.getDurationInMilliSeconds(timestamp, this.graphStartTimestamp);
        const xRatioFromStart = milliSecondsFromStart / this.getGraphDuration();

        return xRatioFromStart * (this.getGraphContainerWidth() - pixelsFromLeftOfParent);
    };

    /**
     * Returns duration of any end+start timestamps in milliseconds.
     */
    private getDurationInMilliSeconds =
    (end: TTimestampFormatFromApi, start: TTimestampFormatFromApi): number => {
        return getUnixTimeInMilliseconds(end) - getUnixTimeInMilliseconds(start);
    };

    /**
     * Returns duration of graph in milliseconds.
     */
    private getGraphDuration = (): number => {
        return this.getDurationInMilliSeconds(this.graphEndTimestamp, this.graphStartTimestamp);
    };

    /**
     * Returns duration of graph in seconds.
     */
    private getGraphDurationInSeconds = (): number => {
        return this.getGraphDuration() / 1000;
    };

    /**
     * Scales x-axis.
     * Called upon change in parent container width (window size changes etc).
     * Note: '.domain' can alternatively be provided unix time in milliseconds.
     */
    private scaleXAxis = (): void => {
        const {
            graphEndTimestamp,
            graphStartTimestamp,
        } = this;

        const xAxisScale = d3.scaleTime()
            .domain([graphStartTimestamp, graphEndTimestamp])
            // offset to left to avoid last label spillage to right
            .range([pixelsFromLeftOfParent, this.getGraphContainerWidth() - 5]);

        return d3.axisBottom()
            .scale(xAxisScale)
            .tickPadding(5)
            .tickSize(0);
    };
}
