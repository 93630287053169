/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/*
 * Sparkline is used for simple charts that don't need anomalies, overlays, titles, or fancy
 * tooltips
 * Its behavior is the same as that of Performance chart, just without extra functionality
 * It does have some extra logic to display a single value based on the state
 * If hovering over a graph, the single value will be the value at that point
 * If not hovering over the graph, will displaying either latest value or average value, based on
 * user settings
 * Defaults to latest value if real time, otherwise uses averages

 * Scope
 *   mouseUpdatesCharts -- if true, hovering over the chart will make hover behavior happen
 */
//TODO destroy @am
angular.module('aviApp').directive('sparkline', ['myAccount', 'ChartService', 'Tooltip',
'$timeout', 'GraphSync', 'seriesDataPointService',
function(myAccount, ChartService, Tooltip, $timeout, GraphSync, seriesDataPointService) {
    function sparklineLink(scope, elm, attr) {
        const chartElm = $(elm.find('.sparkline-graph'));
        const settings = {
            padding: {
                top: 5,
                bottom: 5,
                left: 0,
                right: 0,
            },
            noAxes: true,
        };

        settings.height = scope.height || null;
        settings.width = scope.width || null;
        settings.yMax = scope.yMax || null;

        function makeChart() {
            return new ChartService(chartElm, settings);
        }

        function resetSettings() {
            scope.watchers = [];
            scope.tooltips = {};
            scope.displayData = null;
            scope.legend = {
                entries: [],
                display: false,
            };
            scope.settings = settings;
        }

        resetSettings();

        // ------------- Scope Methods ------------  //
        if (scope.mouseUpdatesCharts && scope.mouseUpdatesCharts !== 'false') {
            scope.mouseMoveHandler = _.throttle(function(e) {
                if (scope.chart && scope.chart.x && !GraphSync.stuck) {
                    const close = scope.chart.x.invert(e.pageX - scope.settings.padding.left -
                        elm.offset().left);

                    GraphSync.sync(scope.chart.findByX(scope.series, close).x);
                    updateTooltipPositions();
                }
            }, 16);
        }

        function handleNewCollection() {
            destroyTooltips();
            scope.chart.graphNewSingleArea(scope.series)
                .then(function() {
                    if (!scope.chart) {
                        return;
                    }

                    refreshTooltips();
                });
        }

        function destroyChart() {
            chartElm.empty();

            if (scope.chart) {
                scope.chart.destroy();
                scope.chart = null;
            }

            destroyTooltips();
            resetSettings();
        }

        function updateGraph() {
            scope.chart.updateSingleArea(scope.series);
            refreshTooltips();
        }

        // -------------- Listeners and Watchers ---------------------//

        // ------ Destroying the Chart ------------//
        scope.$on('$destroy', function() {
            destroyChart();
        });

        // -------- Redrawing the Chart  ------------ //
        const recreateTheChart = function() {
            destroyChart();

            if (scope.series && scope.series.hasData()) {
                scope.chart = makeChart();
                handleNewCollection();
            }
        };

        function recreateChartAsync() {
            $timeout(function() {
                recreateTheChart();
            }, 50);
        }

        recreateChartAsync();

        // ---------------- Tooltip Code --------------//
        function destroyTooltips() {
            _.each(scope.tooltips, function(tooltip) {
                tooltip.destroy();
            });
            scope.tooltips = {};
        }

        function makeTooltips() {
            destroyTooltips();
            scope.tooltips.main = new Tooltip({
                chart: scope.chart,
                data: scope.series,
                settings: {},
                type: scope.small ? 'line' : null,
            });
        }

        function updateTooltipPositions() {
            const { series } = scope;

            _.each(scope.tooltips, tooltip => {
                if (GraphSync.mouseOnGraph &&
                    series.getDataPoint(GraphSync.mouseOnGraph) &&
                    _.isUndefined(attr.hideTooltip)) {
                    tooltip.updatePosition(
                        series.getDataPoint(GraphSync.mouseOnGraph),
                        '',
                        GraphSync.stuck,
                    );
                } else {
                    tooltip.hide();
                }
            });
        }

        /**
         * Removes mouseOnGraph from GraphSync and updates tooltip when mouse is off the graph.
         */
        scope.mouseOutHandler = function() {
            if (!GraphSync.stuck) {
                GraphSync.hide();
            }

            updateTooltipPositions();
        };

        const refreshTooltips = function() {
            makeTooltips();
            updateTooltipPositions();
        };

        scope.$watch(
            () => `${GraphSync.mouseOnGraph}${GraphSync.stuck}`,
            () => {
                if (!scope.series || !scope.series.hasData()) {
                    return;
                }

                updateTooltipPositions();
            },
        );

        // Main Watcher -- also registers a watcher to check if it's been updated
        // Chart has been changed to different time frame (series.values.length will be zero)
        // Chart needs to be created (scope.chart undefined, series.values has length)
        // Chart needs to be updated (scope.chart defined, series.vaules has length)
        scope.$watch(scope => {
            const { series } = scope;
            const firstDataPoint = series && series.getFirstPoint() || null;
            const latestDataPoint = series && series.getLatestPoint(true) || null;

            const firstDataPointHash =
                seriesDataPointService.getDataPointHashString(firstDataPoint);

            const latestDataPointHash =
                seriesDataPointService.getDataPointHashString(latestDataPoint);

            return `${firstDataPointHash}|${latestDataPointHash}`;
        }, () => {
            const { series } = scope;

            if (!series) {
                return;
            }

            const { chart } = scope;

            if (chart && !series.hasData()) {
                destroyChart();
            } else if (chart) {
                updateGraph();
            } else if (series.hasData()) {
                recreateChartAsync();
            }
        });

        scope.$on('repaint', recreateChartAsync);
    }

    return {
        restrict: 'AE',
        replace: true,
        scope: {
            yMax: '=',
            padding: '=',
            mouseUpdatesCharts: '=',
            series: '=',
            height: '=',
            width: '=',
            small: '=',
        },
        template: require('../../views/components/sparkline.partial.html'),
        link: sparklineLink,
    };
}]);
