/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

// @ts-expect-error
import { timeParse } from 'd3';
import { IVsLogsUpdatableParams } from 'ng/modules/vs-logs';
import { TTimestampFormatFromApi } from
    'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';

/**
 * Represents desired target number of divisions on x-axis.
 *
 * Graph api accepts 3 request parameters which work together:
 * end: timestamp of very end of graph, typically the timestamp when api request is made;
 * duration: total time in seconds from beginning to end of graph;
 * step: how long in seconds each response entry should represent;
 *
 * end and duration together tell api the desired time range for the query.
 * The UI wants a max of 120 divisions on the x-axis, so step is then calculated
 * by dividing duration by desiredXAxisSplits.
 */
const desiredXAxisSplits = 120;

/**
 * Calculates 'step' api request param for given duration.
 * See description of const 'desiredXAxisSplits' above for more detail.
 */
export function calculateStep(
    duration: IVsLogsUpdatableParams['duration'],
    xSplits: number = desiredXAxisSplits,
): IVsLogsUpdatableParams['step'] {
    return Math.ceil(duration / xSplits).toString();
}

/**
 * Converts input to readable/avi format time.
 */
export function convertTimeToTTimestampFormatFromApi(time: number): TTimestampFormatFromApi {
    return timeParse('%Q')(Math.floor(time));
}
