/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    ElementRef,
    Input,
    Renderer2,
} from '@angular/core';

import './vs-log-cinematic-sub-section-wrapper.component.less';

const componentName = 'vs-log-cinematic-sub-section-wrapper';

/**
 * @description
 *
 *  Wrapper component to be used by sub-sections in VS log cinematic for header setting and
 *  container styling.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-wrapper',
    templateUrl: './vs-log-cinematic-sub-section-wrapper.component.html',
})
export class VsLogCinematicSubSectionWrapperComponent {
    /**
     * Text as the sub-section header.
     */
    @Input()
    public header: string;

    constructor(
        renderer: Renderer2,
        elementRef: ElementRef,
    ) {
        const hostElement = elementRef.nativeElement;

        // adding host class here rather than setting it in .less is to make sure :not(:first-child)
        // work for a list of sub section wrapper components
        renderer.addClass(hostElement, componentName);
    }
}
