/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4Rule } from 'generated-types';
import { L4Rule } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    L4RuleActionConfigItem,
    L4RuleActionSelectPoolConfigItem,
    L4RuleMatchTargetConfigItem,
} from 'ajs/modules/l4-policy/factories';

import { withFullModalMixin } from 'ajs/utils/mixins';

import {
    L4RuleModalComponent,
} from 'ng/modules/l4-policy/components/l4-rule-modal/l4-rule-modal.component';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../l4-policy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IL4RuleConfig extends Omit<IL4Rule, 'match' | 'action'> {
    match: L4RuleMatchTargetConfigItem,
    action: L4RuleActionConfigItem,
}

/**
 * @desc L4Rule MessageItem class.
 * @author Zhiqian Liu
 */
export class L4RuleConfigItem extends withFullModalMixin(MessageItem)<IL4RuleConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    public get enabled(): boolean {
        return this.config.enable;
    }

    public set enabled(isEnabled: boolean) {
        this.config.enable = isEnabled;
    }

    public get selectPoolAction(): L4RuleActionSelectPoolConfigItem {
        return this.config.action.config.select_pool;
    }

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: L4Rule,
            windowElement: L4RuleModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * We clear out flatProps so that the repeatedMessageItems don't get overwritten.
     * This is needed for new match adder component saving IP address match through its
     * repeatedMessageItems and getting around with the legacy _flatProps cache approach.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { match } = this.config;

        if (match) {
            const { client_ip: clientIp } = match.config;

            if (clientIp) {
                clientIp.clearFlatProps();
            }
        }
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected requiredFields(): string[] {
        return ['match', 'action'];
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IL4RuleConfig> {
        const { objectType: type } = this;
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            match: null,
        };
    }

    /**
     * @override
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.l4RuleModalBreadcrumbTitle);
    }
}
