/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('alertsPage', function() {
    return {
        restrict: 'AE',
        transclude: true,
        scope: true,
        controller: 'AlertsController',
        templateUrl: 'src/views/application/alerts.html',
    };
});
