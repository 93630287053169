/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IALBServicesFileUpload,
    Syserr,
} from 'generated-types';

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { StringService } from 'ajs/modules/core/services/string-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './case-attachments-grid.l10n';
import './case-attachments-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Component to show already attached files to the Case with file upload status.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'case-attachments-grid',
    templateUrl: './case-attachments-grid.component.html',
})
export class CaseAttachmentsGridComponent implements AfterViewInit, OnInit {
    /**
     * List of Case Attachments.
     * Used to show the data in the Case Attachments DataGrid.
     */
    @Input()
    public caseAttachments: IALBServicesFileUpload[] = [];

    /**
     * TemplateRef for status field.
     */
    @ViewChild('statusFieldTemplateRef')
    public statusFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Case attachment upload status.
     */
    public readonly uploadStatusHash = {
        SYSERR_ALBSVC_FILE_UPLOAD_SUCCESS: Syserr.SYSERR_ALBSVC_FILE_UPLOAD_SUCCESS,
        SYSERR_ALBSVC_FILE_UPLOAD_FAILED: Syserr.SYSERR_ALBSVC_FILE_UPLOAD_FAILED,
    };

    /**
     * DataGrid config for Case Attachments.
     */
    public caseAttachmentsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        // Need this in ngOnInit to hide checkboxes.
        this.caseAttachmentsGridConfig = {
            fields: [], // templates will be configured in ngAfterViewInit.
            getRowId: (index: number): number => index,
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.caseAttachmentsGridConfig.fields = [
            {
                label: l10nService.getMessage(l10nKeys.nameColumnLabel),
                id: 'file-path',
                transform: ({ file_path: filePath }: IALBServicesFileUpload) => {
                    return this.stringService.slug(filePath);
                },
            },
            {
                label: l10nService.getMessage(l10nKeys.statusColumnLabel),
                id: 'status',
                templateRef: this.statusFieldTemplateRef,
            },
        ];
    }

    /**
     * Returns the count of case attachments.
     */
    public get caseAttachmentsCount(): number {
        return this.caseAttachments.length;
    }

    /**
     * Returns file upload error message.
     * Used to show error message in the tooltip.
     */
    public getFileUploadErrorMessage({ error }: IALBServicesFileUpload): string {
        return error;
    }
}
