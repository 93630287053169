/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    ServerAutoScalePolicyListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/auto-scale/components/server-auto-scale-policy-list-page/server-auto-scale-policy-list-page.component';

import {
    LaunchConfigListPageComponent,
} from 'ng/modules/auto-scale/components/launch-config-list-page/launch-config-list-page.component';

import {
    PoolGroupDeploymentPolicyListPageComponent,
    // eslint-disable-next-line max-len
} from 'ng/modules/auto-scale/components/pool-group-deployment-policy-list-page/pool-group-deployment-policy-list-page.component';

import {
    WebhookListPageComponent,
} from 'ng/modules/auto-scale/components/webhook-list-page/webhook-list-page.component';

import {
    AutoScaleLaunchConfigCollectionProvider,
    AutoScalePolicyCollectionProvider,
    PoolGroupDeploymentPolicyCollectionProvider,
    WebhookCollectionProvider,
} from 'ng/modules/auto-scale/ajs-upgraded-providers';

const autoScaleComponents = [
    WebhookListPageComponent,
    ServerAutoScalePolicyListPageComponent,
    LaunchConfigListPageComponent,
    PoolGroupDeploymentPolicyListPageComponent,
];

/**
 * Angular Module for Auto Scale related components.
 * @author sghare, Nitesh kesarkar
 */
@NgModule({
    declarations: [
        ...autoScaleComponents,
    ],
    imports: [
        DataGridModule,
        SharedModule,
        BrowserAnimationsModule,
    ],
    providers: [
        WebhookCollectionProvider,
        AutoScalePolicyCollectionProvider,
        AutoScaleLaunchConfigCollectionProvider,
        PoolGroupDeploymentPolicyCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})

export class AutoScaleModule {}
