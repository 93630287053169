/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module RightRailModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import './right-rail-expander-text-child.component.less';

/**
 * @description
 *
 *     Component for a right rail tree node that only contains pure texts.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'right-rail-expander-text-child',
    templateUrl: './right-rail-expander-text-child.component.html',
})
export class RightRailExpanderTextChildComponent {
    /**
     * Text showed as the content.
     */
    @Input()
    public text: string;

    /**
     * Flag indicating if current node is active or not.
     */
    @Input()
    public isActive = false;
}
