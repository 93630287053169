/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './item-alert-popover.less';
import * as l10n from './item-alert-popover.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name itemAlertPopover
 * @param {Pool|VirtualService|ServiceEngine} item
 * @description
 *
 *     Item alert popover with header showing total number of alerts per severity level, main
 *     section with a list of relevant alerts and button to go to alerts list page.
 */
const componentName = 'item-alert-popover';

let $state;

class ItemAlertPopoverController {
    constructor($element, _$state_, itemAlertDataTransform, l10nService) {
        $state = _$state_;

        this.alertLevels = itemAlertDataTransform.alertLevels;
        this.$elem_ = $element;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        this.alertsPageLabels = [
            l10nService.getMessage(l10n.viewAllAlertsListLabel),
            l10nService.getMessage(l10n.goToAlertsPageListLabel),
        ];
    }

    $onInit() {
        this.alertsCollection = this.item.alertsPrioritized;
        this.$elem_.addClass(componentName);
    }

    /**
     * Returns a text label for "go to alerts page" button.
     * @type {string}
     * @public
     */
    get alertsPageLinkLabel() {
        const alertsData = this.item.getAlertsData();

        if (alertsData && alertsData.highestAlertLevel) {
            return this.alertsPageLabels[0];
        }

        return this.alertsPageLabels[1];
    }

    /**
     * Return true to show "go to alerts page" button.
     * @type {boolean}
     * @public
     **/
    get hasAlertsPageLink() {
        return !$state.includes('**.alerts');
    }

    /**
     * Click event handler to take use to alert list page.
     * @public
     */
    goToAlertsListPage() {
        this.item.goToItemPage('alerts');
    }
}

ItemAlertPopoverController.$inject = [
    '$element',
    '$state',
    'itemAlertDataTransform',
    'l10nService',
];

angular.module('aviApp').component('itemAlertPopover', {
    controller: ItemAlertPopoverController,
    bindings: {
        item: '<',
    },
    templateUrl: 'src/components/common/item-alert-popover/item-alert-popover.html',
});
