/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IJWTClaimMatch, JWTClaimType } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { JWTClaimMatch } from 'object-types';
import { StringMatchConfigItem } from './string-match.config-item.factory';

type TJwClaimMatchPartial = Omit<IJWTClaimMatch, 'string_match'>;

interface IJwtClaimMatchConfig extends TJwClaimMatchPartial {
    string_match?: StringMatchConfigItem;
}

/**
 * @description JWTClaimMatch ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class JwtClaimMatchConfigItem extends MessageItem<IJwtClaimMatchConfig> {
    public static ajsDependencies = [
        'schemaService',
    ];

    private readonly schemaService: SchemaService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: JWTClaimMatch,
            ...args,
        };

        super(extendedArgs);

        this.schemaService = this.getAjsDependency_('schemaService');
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const type = this.schemaService.getEnumValueLabel(
            'JWTClaimType',
            this.config.type,
        );

        const claimValue = this.config.validate ? `(${this.claimValue})` : '';

        return `${this.config.name} - ${type} ${claimValue}`;
    }

    /**
     * Returns claim value based on claim type.
     */
    private get claimValue(): string | number | boolean {
        const { type } = this.config;

        switch (type) {
            case JWTClaimType.JWT_CLAIM_TYPE_BOOL:
                return this.config.bool_match;

            case JWTClaimType.JWT_CLAIM_TYPE_STRING: {
                const {
                    stringGroupNames = [],
                    matchStr = [],
                } = this.config.string_match;

                return [
                    ...stringGroupNames,
                    ...matchStr,
                ].join(', ');
            }

            case JWTClaimType.JWT_CLAIM_TYPE_INT:
                return this.config.int_match;
        }
    }
}
