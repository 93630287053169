/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ISaasLicensingStatus,
    LicenseTierType,
} from 'generated-types';

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './licensing-page-header.l10n';
import './licensing-page-header.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * License tier comparison datasheet path.
 */
const LICENSE_TIER_COMPARISON_DOC_SRC = 'src/assets/docs/license-tiers-comparison.pdf';

/**
 * Set of ENTERPRISE_WITH_CLOUD_SERVICES and Enterprise license tier type used
 * to show promotional message.
 */
export const CLOUD_SERVICES_ENTERPRISE_LICENSE_SET = new Set([
    LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES,
    LicenseTierType.ENTERPRISE,
]);

/**
 * License status alert type enum.
 */
enum AlertType {
    success = 'success',
    danger = 'danger',
}

/**
 * @description
 *      Component to trigger the license tier change events
 *      and to open license tier comparison document tab.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'licensing-page-header',
    templateUrl: './licensing-page-header.component.html',
})
export class LicensingPageHeaderComponent {
    /**
     * Default license tier.
     */
    @Input()
    public defaultLicenseTier: LicenseTierType;

    /**
     * ENTERPRISE_WITH_CLOUD_SERVICES license status object.
     */
    @Input()
    public enterpriseWithCloudServicesLicenseStatus: ISaasLicensingStatus;

    /**
     * Emit license tier change event.
     */
    @Output()
    public onLicenseTierChangeRequest = new EventEmitter<void>();

    /**
     * Used to display message on alert to unlock more features.
     */
    public licenseInfoAlertMessage: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.licenseInfoAlertMessage = l10nService.getMessage(l10nKeys.unlockMoreFeaturesMessage);
    }

    /**
     * Returns true if ENTERPRISE_WITH_CLOUD_SERVICES tier is selected
     */
    public get isEnterpriseWithCloudServicesTier(): boolean {
        return this.defaultLicenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * Returns alert Type based on ENTERPRISE_WITH_CLOUD_SERVICES license status.
     */
    public get licenseStatusAlertType(): string {
        return this.enterpriseWithCloudServicesLicenseStatus?.enabled &&
            !this.enterpriseWithCloudServicesLicenseStatus?.expired ?
            AlertType.success : AlertType.danger;
    }

    /**
     * Function to emit license tier change event.
     */
    public onCogClick(): void {
        this.onLicenseTierChangeRequest.emit();
    }

    /**
     * Opens new tab with license tier comparison pdf.
     */
    public openTierComparisonDocTab(): void {
        window.open(LICENSE_TIER_COMPARISON_DOC_SRC, '_blank');
    }

    /**
     * Returns true when license tier type is neither of
     * Enterprise or ENTERPRISE_WITH_CLOUD_SERVICES type.
     * When true, Show unlock more feature promotional message.
     */
    public get showLicenseInfoAlertMessage(): boolean {
        return !CLOUD_SERVICES_ENTERPRISE_LICENSE_SET.has(this.defaultLicenseTier);
    }
}
