/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'bot-class-matcher-readonly';
const prefix = `${moduleName}.${componentName}`;

export const clientClassLabel = `${prefix}.clientClassLabel`;

export const ENGLISH = {
    [clientClassLabel]: 'Client Class',
};
