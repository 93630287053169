/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name encodeURIQueryPartService
 * @author Alex Malitsky
 * @params {string} url - Url to get query part encoded.
 * @returns {string} Url with encoded URL query part.
 * @description
 *
 *     Backend requires an encoded URL query part. We encode both keys and values because
 *     otherwise we get a mix of UTF and ASCI symbols in URL which freaks backend out.
 *
 */
angular.module('aviApp').factory('encodeURIQueryPartService', ['$window', function($window) {
    const { encodeURIComponent } = $window;

    /**
     * Gets query part of URL only to go through all k&v pairs and encode param names and values.
     * @param {string} queryStr
     * @returns {string}
     * @inner
     */
    function encodeQueryParams(queryStr) {
        return queryStr.split('&').map(paramStr => {
            const eqPos = paramStr.indexOf('=');

            let res;

            if (eqPos !== -1) {
                res = `${encodeURIComponent(paramStr.slice(0, eqPos))}=${
                    encodeURIComponent(paramStr.slice(eqPos + 1))}`;
            } else {
                res = encodeURIComponent(paramStr);
            }

            return res;
        }).join('&');
    }

    return function(url) {
        const qPos = url.indexOf('?');

        let
            res,
            poundPos;

        if (qPos !== -1 && qPos !== url.length - 1) { //when ? is the last symbol no need to bother
            poundPos = url.indexOf('#', qPos + 1);

            res = url.slice(0, qPos + 1) + encodeQueryParams(
                url.slice(qPos + 1, poundPos === -1 ? undefined : poundPos),
            );

            if (poundPos !== -1) {
                res += url.slice(poundPos);
            }
        } else {
            res = url;
        }

        return res;
    };
}]);
