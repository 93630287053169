/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { CLOUD_ITEM_TOKEN } from '../cloud.tokens';

export class CloudCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            allDataSources: {
                inventory: {
                    source: 'InventoryCollDataSource',
                    transformer: 'InventoryDataTransformer',
                    transport: 'ListDataTransport',
                    fields: ['config', 'status'],
                },
            },
            defaultDataSources: 'inventory',
            isStatic: false,
            objectName: 'cloud-inventory',
            permissionName: AviPermissionResource.PERMISSION_CLOUD,
            windowElement: 'lazy-load',
            restrictCreateOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(CLOUD_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            rancher_configuration: undefined,
            docker_configuration: undefined,
        };
    }
}

CloudCollection.ajsDependencies = [
    CLOUD_ITEM_TOKEN,
];
