/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module NatModule */

import { Injector } from '@angular/core';

import {
    NatPolicyCollection,
} from 'ajs/modules/policies/factories/nat-policy/nat-policy-collection.factory';

import { NAT_POLICY_COLLECTION_TOKEN } from 'ajs/modules/policies/policies.tokens';

const $injector = '$injector';

export const natPolicyCollectionProvider = {
    deps: [$injector],
    provide: NatPolicyCollection,
    useFactory(injector: Injector): typeof NatPolicyCollection {
        return injector.get(NAT_POLICY_COLLECTION_TOKEN);
    },
};
