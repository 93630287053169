/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IPromise } from 'angular';
import { findWhere } from 'underscore';

import {
    Component,
    Type,
} from '@angular/core';

import {
    AviPermissionResource,
    IALBServicesCase,
    IALBServicesFileUpload,
} from 'generated-types';

import {
    CaseModalComponent,
    CasePreviewComponent,
    IFileServiceAttachment,
    ITagRow,
} from 'ng/modules/cportal';

import { IFullModalLayout } from 'ng/modules/core';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';

import { CaseStatus } from 'ajs/modules/core/constants';
import { CportalService } from '../services';

import {
    IALBServicesCaseCommentsResponse,
    ICaseItem,
    IExtendedALBServicesCase,
} from './case.item.types';

/**
 * Ajs dependency token Case Item.
 */
export const CASE_ITEM_TOKEN = 'Case';

/**
 * @description Case item
 *
 * @author Ram Pal, Rajawant Prajapati
 */
export class Case extends withFullModalMixin(ObjectTypeItem) {
    /**
     * Function to get Case config.
     */
    public getConfig: () => IExtendedALBServicesCase;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'albservices/case',
            objectType: 'Case',
            windowElement: CaseModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public getIdFromData_(data: ICaseItem): string {
        return data?.config?.id || '';
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        // Initialise the tagRows array used to hold values of newly added tags
        config.tagRows = [];

        const { id } = config;

        if (id) {
            const { contact_info: { email } } = config;

            // We are getting contact_info object while case update.
            // mapping desired value.
            config.email = email;

            this.createTagRowsFromCustomTag();
        }
    }

    /**
     * Get all case comments
     */
    public loadCaseComments(): IPromise<IALBServicesCaseCommentsResponse> {
        const url = `api/${this.objectName}/${this.id}/comment`;

        return CportalService.handleHttpResponse(this.request('GET', url));
    }

    /**
     * Get Status of the case. Returns closed or active.
     */
    public getStatus(): string {
        const { status } = this.getConfig();

        // Required since status is not present when case modal is opened in create mode
        // to create new case.
        if (!status) {
            return '';
        }

        return status === 'Closed' ? CaseStatus.CLOSED : CaseStatus.ACTIVE;
    }

    /**
     * Returns true if case is closed.
     */
    public isClosed(): boolean {
        return this.getStatus() === CaseStatus.CLOSED;
    }

    /**
     * Get type of case
     * Value can be one of [Bug, Configuration Help, Feature Request, Issue, Question]
     */
    public getType(): string | undefined {
        const { type } = this.getConfig();

        return type;
    }

    /**
     * Returns case number and subject of the case.
     * Empty string when not ready.
     * @override
     */
    public getName(): string {
        const config = this.getConfig();
        let name = '';

        if (config) {
            const { case_number: caseNumber, subject = '' } = config;

            if (caseNumber) {
                name = `${caseNumber} | ${subject}`;
            } else {
                name = subject;
            }
        }

        return name;
    }

    /**
     * Update custom_tag field value.
     */
    public updateCustomTag(): void {
        const config = this.getConfig();
        const { tagRows } = config;

        // Selected Custom/System tag type and value as type=value pair in string format.
        config.custom_tag = tagRows
            .map(({ type, value }: ITagRow) => `${type}=${value}`)
            .join();
    }

    /**
     * Add new tag row.
     */
    public addTagRow(): void {
        const config = this.getConfig();

        const newTag = {
            type: '',
            value: '',
        };

        config.tagRows.push(newTag);
    }

    /**
     * Remove tag option for provided index
     */
    public removeTagRow(index: number): void {
        const config = this.getConfig();

        config.tagRows.splice(index, 1);
    }

    /**
     * @override
     */
    public dataToSave(): IALBServicesCase {
        const config = super.dataToSave();
        const { custom_tag: customTags, filesToAttach = [] } = config;

        if (!customTags) {
            delete config.custom_tag;
        }

        if (filesToAttach.length) {
            // Property to hold file URIs to be sent in request payload.
            config.file_uris = [];

            filesToAttach.forEach(({ url }: IFileServiceAttachment) => {
                // Extract file URI from the URL.
                // "url":
                // "https://ctrl.dev/api/fileservice?uri=controller://tech_support/test.tar.gz",
                // extract file uri value 'controller://tech_support/test.tar.gz'.
                const fileUri = url.split('uri=')[1];

                config.file_uris.push(fileUri);
            });
        }

        // Deleting UI only fields.
        delete config.tagRows;
        delete config.filesToAttach;

        return config;
    }

    /**
     * Setter for email in Case modal.
     */
    public set email(email: string) {
        const config = this.getConfig();

        config.email = email;
    }

    /**
     * Setter for severity value in severity dropdown in Case modal.
     */
    public set severity(severity: string) {
        const config = this.getConfig();

        config.severity = severity;
    }

    /**
     * Getter for Case Attachments.
     */
    public get caseAttachments(): IALBServicesFileUpload[] {
        return this.getConfig().case_file_uploads?.file_attachments || [];
    }

    /**
     * Getter for case created date.
     */
    public get caseCreatedDate(): string {
        const { created_date: caseCreatedDate } = this.getConfig();

        return caseCreatedDate;
    }

    /**
     * Set filesToAttach with selected attachments in attachment selection dialog.
     */
    public set filesToAttach(filesToAttach: IFileServiceAttachment[]) {
        const config = this.getConfig();

        config.filesToAttach = filesToAttach;
    }

    /**
     * Getter for selected attachments in attachment selection dialog.
     * Used to get the files to be attached to the case.
     */
    public get filesToAttach(): IFileServiceAttachment[] {
        const { filesToAttach = [] } = this.getConfig();

        return filesToAttach;
    }

    /**
     * Returns true if the attachment is already added to filesToAttach.
     */
    public isAttachmentAdded(url: string): boolean {
        return Boolean(findWhere(this.filesToAttach, { url }));
    }

    /**
     * Function to remove the attachment from filesToAttach.
     */
    public removeAttachment(attachment: IFileServiceAttachment): void {
        const { filesToAttach } = this;
        const config = this.getConfig();

        const index = filesToAttach.indexOf(attachment);

        config.filesToAttach = [
            ...filesToAttach.slice(0, index),
            ...filesToAttach.slice(index + 1),
        ];
    }

    /**
     * @override
     */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: CasePreviewComponent as Type<Component>,
        };
    }

    /**
     * For existing case get tagRows from
     * customTags string.
     */
    private createTagRowsFromCustomTag(): void {
        const config = this.getConfig();
        const { custom_tag: customTags } = config;

        config.tagRows = [];

        if (customTags) {
            customTags
                .split(',')
                .forEach((tag: string) => {
                    const [type, value] = tag.split('=');
                    const tagObject = {
                        type,
                        value,
                    };

                    config.tagRows.push(tagObject);
                });
        }
    }
}
