/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './tech-support.component.less';
import { portalStatus } from 'ajs/modules/cportal/constants/cportal.constants';
import * as l10n from './tech-support.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const componentName = 'tech-support';

/**
 * @alias module:component/techSupport
 * @private
 */
class TechSupportController {
    constructor(
        $interval,
        $window,
        Auth,
        AviModal,
        CRUDGridConfig,
        TechSupportCollection,
        TechSupportCapture,
        l10nService,
    ) {
        this.$interval = $interval;
        this.$window = $window;
        this.Auth_ = Auth;
        this.AviModal = AviModal;
        this.CRUDGridConfig = CRUDGridConfig;
        this.TechSupportCollection = TechSupportCollection;
        this.TechSupportCapture = TechSupportCapture;

        /**
         * Avi cloud connected status.
         * @type {boolean}
         */
        this.aviCloudConnected = false;

        /**
         * Controller registration status.
         * @type {boolean}
         */
        this.controllerRegistered = false;

        /**
         * Capture status vals to be used to determine view.
         * @type {{statusPending: boolean, generationApiFailed: boolean, showLastTried: boolean}}
         */
        this.generationStatus = {
            showLastTried: false,
            statusPending: true,
            generationApiFailed: false,
        };

        /**
         * Techsupport grid requires `PERMISSION_TECHSUPPORT` and `PERMISSION_CONTROLLER`.
         * This value will be set to true if user does not have `PERMISSION_CONTROLLER`.
         * @type {boolean}
         */
        this.hideTechSupportGrid = false;

        /**
        * Get keys from source bundles for template usage.
        */
        this.l10nKeys = l10nKeys;
        this.l10nService_ = l10nService;
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.initTechSupportCapture_();
        this.setRegistrationStatusAndInitTechSupportCollectionGrid_();
    }

    /**
     * Returns true if user has `PERMISSION_CONTROLLER` access.
     * @return {boolean}
     * @protected
     */
    canShowTechSupportGrid_() {
        return this.Auth_.isAllowed('PERMISSION_CONTROLLER');
    }

    /**
     * Get registration status of controller to show or hide Suport Case column in
     * tech support collection grid.
     * @protected
     */
    setRegistrationStatusAndInitTechSupportCollectionGrid_() {
        const albServicesStatus = this.cportalService_.getRegistrationStatusDetails();

        this.updateStatus_(albServicesStatus);

        if (this.canShowTechSupportGrid_()) {
            this.initTechSupportCollectionGridConfig_();
        } else {
            this.hideTechSupportGrid = true;
        }
    }

    /**
     * Update's Controller registration and Avi cloud connectivity status
     * @param {Object} data - Response object of registration status.
     * @protected
     */
    updateStatus_(data) {
        const { ALBSERVICES_REGISTERED, ALBSERVICES_CONNECTED } = portalStatus;

        this.controllerRegistered =
            data.registration_status === ALBSERVICES_REGISTERED;
        this.aviCloudConnected =
            data.connectivity_status === ALBSERVICES_CONNECTED;
    }

    /**
     * Creates instance of last tech support generation status data object.
     * @protected
     */
    initTechSupportCapture_() {
        this.techSupportCapture = new this.TechSupportCapture();

        this.techSupportCapture.on(
            this.TechSupportCapture.HANDLE_CAPTURE_STATUS_UPDATE_EVENT,
            this.handleCaptureStatusUpdate_,
        );
    }

    /**
      * Handles any successful response from techSupportCapture's update.
      * @protected
     */
    handleCaptureStatusUpdate_ = () => {
        const
            { generationStatus, techSupportCapture } = this,
            { generationApiFailed, showLastTried } = generationStatus;

        const
            inProgress =
                techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'inProgress',
            wasSuccessful =
                techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'success';

        if (inProgress || generationApiFailed) {
            generationStatus.showLastTried = true;
        } else if (wasSuccessful && showLastTried && !this.hideTechSupportGrid) {
            this.techSupportCollection.load();
        }

        generationStatus.statusPending = false;
    };

    /**
     * Initializes collection and creates config for tech support table.
     * @protected
     */
    initTechSupportCollectionGridConfig_() {
        const { l10nService_: l10nService } = this;

        this.techSupportCollection = new this.TechSupportCollection();

        const fields = [{
            title: l10nService.getMessage(l10nKeys.columnTitleCreationDate),
            name: 'modified',
            template: '{{ row.getConfig().created }}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleType),
            name: 'type',
            template: '{{ row.getConfig().type || "-" }}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleResourceName),
            name: 'resourceName',
            template: '{{ row.getConfig().resourceName || "-" }}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleDuration),
            name: 'duration',
            template: '{{ row.getConfig().duration || "-"}}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleSize),
            name: 'size',
            template: '{{ row.getConfig().size }}',
        }, {
            title: l10nService.getMessage(l10nKeys.columnTitleResults),
            name: 'results',
            template: require('./tech-support-results.partial.html'),
        }];

        if (this.aviCloudConnected && this.controllerRegistered) {
            fields.push({
                title: l10nService.getMessage(l10nKeys.columnTitleSupportCase),
                name: 'supportCase',
                transform: row => {
                    let caseDetail = '';
                    const { caseDetails } = row.getConfig();

                    if (caseDetails && 'case_id' in caseDetails) {
                        caseDetail = caseDetails.case_number;
                    }

                    return caseDetail;
                },
            });
        }

        const singleactions = [{
            title: l10nService.getMessage(l10nKeys.deleteBtnLabel),
            class: 'icon icon-trash',
            do: row => row.drop(),
        }, {
            title: l10nService.getMessage(l10nKeys.downloadBtnLabel),
            class: 'icon-download-1',
            //Following only true for read/write, page is inaccessible for noaccess anyway
            bypassPermissionsCheck: true,
            do: row => this.$window.location.assign(row.getConfig().url),
        }];

        const config = {
            collection: this.techSupportCollection,
            id: componentName,
            props: {
                l10nKeys,
            },
            expandedContainerTemplate: require('./tech-support-expander.partial.html'),
            fields,
            layout: {
                hideEditColumns: true,
            },
            singleactions,
            permission: 'PERMISSION_TECHSUPPORT',
        };

        this.techSupportGridConfig = this.CRUDGridConfig(config);
    }

    /**
     * Triggered upon user selection of Tech Support type/etc in modal.
     * @param {TechSupportApiStatus.level} type - type of tech support desired, e.g. VS,SE, etc
     * @param {string=} id - UUID for instance of type vs, se, pool, or gslb
     * @param {string=} caseNumber - case number which we are going to attach the tech support
     */
    triggerTechSupportGeneration(type, id, caseNumber) {
        this.generationStatus.statusPending = true;
        this.generationStatus.generationApiFailed = false;

        this.TechSupportCapture.generateNewTechSupport(type, id, caseNumber)
            .catch(this.handleGenerateApiError_)
            .finally(this.handleGenerateApiResponse_);
    }

    /**
     * Opens techSupportTypeSelection component for type selection before generation.
     */
    openGenerateTypeSelection() {
        this.AviModal.open(
            'tech-support-generation-modal',
            {
                // Uses $injector.annotate, to make args works, same as Angular does generally
                onGenerate: [
                    'type',
                    'id',
                    'caseNumber',
                    this.triggerTechSupportGeneration.bind(this),
                ],
                controllerRegistered: this.controllerRegistered,
                aviCloudConnected: this.aviCloudConnected,
            },
        );
    }

    /**
     * @param {string} err
     * Note: This shouldn't trigger because button is disabled upon clicking first time,
     * but in case generation can't be triggered even on first attempt.
     * @protected
     */
    handleGenerateApiError_ = err => {
        this.generationStatus.generationApiFailed = true;
        console.error('generation error: ', err);
    };

    /**
     * Handles common operations needed for either success or failure.
     * @protected
     */
    handleGenerateApiResponse_ = () => {
        this.techSupportCapture.load();
    };

    /**
     * Handles click of closing error or success message for tech support status section.
     */
    onClickTechSupportStatusClose() {
        this.generationStatus.showLastTried = false;
    }

    /**
     * Shows generate button in tech support status section.
     * @return {boolean}
     */
    generationAllowed() {
        return !this.lastGenerationSuccessful() &&
            !this.lastGenerationFailed() &&
            !this.isInProgress();
    }

    /**
     * Shows in progress dialog in tech support status section.
     * @return {boolean}
     */
    isInProgress() {
        return this.techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'inProgress';
    }

    /**
     * Shows successful completion dialog in tech support status section.
     * @return {boolean}
     */
    lastGenerationSuccessful() {
        const { techSupportCapture, generationStatus } = this;

        return generationStatus.showLastTried &&
            techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'success';
    }

    /**
     * Shows error state termination dialog in tech support status section.
     * @return {boolean}
     */
    lastGenerationFailed() {
        const { techSupportCapture, generationStatus } = this;

        return generationStatus.showLastTried &&
            techSupportCapture.getTechSupportCaptureVal('captureStatus') === 'error';
    }

    /**
     * Returns the status message to be displayed when generating tech support.
     * @returns {string}
     */
    getTechSupportStatusLabel() {
        const { l10nService_: l10nService, techSupportCapture } = this;
        const resource = techSupportCapture.getTechSupportCaptureVal('resourceName');
        const type = techSupportCapture.getTechSupportCaptureVal('type');

        if (type && resource) {
            return l10nService.getMessage(
                l10nKeys.generatingTechSupportStatusWithResourceMessage,
                [type, resource],
            );
        } else if (type) {
            return l10nService.getMessage(
                l10nKeys.generatingTechSupportStatusWithTypeMessage,
                [type],
            );
        } else {
            return l10nService.getMessage(l10nKeys.generatingTechSupportStatusMessage);
        }
    }

    $onDestroy() {
        if (this.techSupportCollection) {
            this.techSupportCollection.destroy();
        }

        if (this.techSupportCapture) {
            this.techSupportCapture.destroy();
        }
    }
}

TechSupportController.$inject = [
    '$interval',
    '$window',
    'Auth',
    'AviModal',
    'CRUDGridConfig',
    'TechSupportCollection',
    'TechSupportCapture',
    'l10nService',
];

/**
 * @ngdoc component
 * @name techSupport
 * @module component/techSupport
 * @property {cportalService} cportalService_ CportalService instance
 * @author Akul Aggarwal
 * @description
 *
 *     Component to generate new tech support items and show/download existing ones.
 *
 */
angular.module('aviApp').component('techSupport', {
    controller: TechSupportController,
    bindings: {
        cportalService_: '<loadedPulseRegistrationStatus',
    },
    templateUrl: `src/components/administration/${componentName}/${componentName}.component.html`,
});
