/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    each,
    some,
    isEmpty,
    isFunction,
} from 'underscore';

const mixin = {
    /**
     * True when all rows are selected.
     * In the case that checkboxDisable property changes for a given row, this property may
     * no longer be correct. Changes to checkboxDisable are not being watched.
     * @type {boolean}
     */
    allRowsSelected: false,

    /**
     * True when no rows are selected.
     * @type {boolean}
     */
    noRowsSelected: true,

    /**
     * Hash of selected rows, all rows are expected to be present. Default value only,
     * has to be overwritten in constructor otherwise will be polluted and shared between
     * instances.
     * In the case that checkboxDisable property changes for a given row, this hash may
     * no longer be correct or complete. Changes to checkboxDisable are not being watched.
     * @type {{string: boolean}}
     * @readonly
     */
    selectedRowsHash: {},

    /**
     * Returns true for currently selected rows.
     * @param {string} rowId
     * @returns {boolean}
     */
    isRowSelected(rowId) {
        return this.selectedRowsHash[rowId];
    },

    /**
     * Adds selected flag for new rows and removes entries for rows not present.
     * Updates boolean flags of selectedRowsHash as well.
     * @param {boolean=} fromList
     * @protected
     */
    updateSelectedHash_(fromList = true) {
        const {
            filteredRows,
            selectedRowsHash,
            config,
        } = this;

        if (fromList) {
            const filteredRowsHash = {};

            filteredRows.forEach(row => {
                const rowId = this.rowId(row);

                if (!(rowId in filteredRowsHash)) {
                    filteredRowsHash[rowId] = row;

                    if (!(rowId in selectedRowsHash)) {
                        selectedRowsHash[rowId] =
                            this.allRowsSelected && this.checkIsRowSelectable_(row);
                    }
                } else {
                    console.error(
                        'Regular grid keeps rows with duplicated ids. ' +
                            'rowId for the row: %s, Rows: %O, rowId property: %O, config: %O',
                        rowId,
                        filteredRows,
                        config.rowId,
                        config,
                    );
                }
            });

            const selectedRowIdsToRemove = [];

            each(selectedRowsHash, (boolVal, rowId) => {
                if (!(rowId in filteredRowsHash)) {
                    selectedRowIdsToRemove.push(rowId);
                }
            });

            //removing hash entries not present in the list anymore
            selectedRowIdsToRemove.forEach(rowId => delete selectedRowsHash[rowId]);

            this.allRowsSelected = this.allRowsSelectedCheck_();
            this.noRowsSelected = this.noRowsAreSelected_();
        } else if (this.allRowsSelected) {
            // Must check filteredRows to make sure row is not disabled.
            filteredRows.forEach(row => {
                const id = this.rowId(row);

                selectedRowsHash[id] = this.checkIsRowSelectable_(row);
            });
        } else if (this.noRowsSelected) {
            each(selectedRowsHash, (value, key) => {
                selectedRowsHash[key] = false;
            });
        }
    },

    /**
     * Returns true when each and every enabled filteredRow is selected.
     * @returns {boolean}
     * @protected
     */
    allRowsSelectedCheck_() {
        const { selectedRowsHash } = this;

        return !isEmpty(selectedRowsHash) &&
            this.atLeastOneRowIsSelectable() &&
            this.everySelectableRowIsSelected_();
    },

    /**
     * Returns true when no rows are selected.
     * @returns {boolean}
     * @protected
     */
    noRowsAreSelected_() {
        const { selectedRowsHash } = this;

        return isEmpty(selectedRowsHash) || !some(selectedRowsHash, angular.identity);
    },

    /**
     * Checks if at least one row in grid is selectable (enabled).
     * Nothing to do with whether is actually checked/selected.
     * @return {boolean}
     */
    atLeastOneRowIsSelectable() {
        return this.filteredRows.some(row => this.checkIsRowSelectable_(row));
    },

    /**
     * Checks if given row is selectable (enabled), assuming disabling is possible at all.
     * Nothing to do with whether is actually checked/selected.
     * @param {*} row - single row of data in grid
     * @return {boolean}
     * @protected
     */
    checkIsRowSelectable_(row) {
        const { checkboxDisable } = this.config;

        if (isFunction(checkboxDisable)) {
            try {
                return !checkboxDisable(row);
            } catch (e) {
                console.error('callback checkboxDisable failed: ', e);
            }
        }

        return true;
    },

    /**
     * Checks if every selectable (enabled) row is also selected.
     * @return {boolean}
     * @protected
     */
    everySelectableRowIsSelected_() {
        return this.filteredRows.every(row => {
            if (this.checkIsRowSelectable_(row)) {
                const id = this.rowId(row);

                return this.selectedRowsHash[id];
            }

            return true;
        });
    },

    /**
     * Event handler for row checkbox click.
     */
    onRowCheckboxClick() {
        this.updateSelectedHash_();
    },

    /**
     * Event handler for grid header checkbox click.
     * @param {boolean} allRowsSelected
     */
    onAllSelectedChange(allRowsSelected) {
        this.allRowsSelected = allRowsSelected;
        this.noRowsSelected = !allRowsSelected;
        this.updateSelectedHash_(false);
    },

    /**
     * Clears rows selection. Used by grid table header checkbox or after multiple action
     * execution.
     */
    clearSelection() {
        this.onAllSelectedChange(false);
    },

    /**
     * Returns selected rows
     * @returns {Object[]}
     */
    getSelectedRows() {
        const { filteredRows, selectedRowsHash } = this;

        return filteredRows.filter(row => selectedRowsHash[this.rowId(row)]);
    },
};

function gridCtrlRowSelectionMixin(classMixin) {
    return GridCtrl => classMixin(GridCtrl, angular.copy(mixin));
}

gridCtrlRowSelectionMixin.$inject = ['classMixin'];

/**
 * @ngdoc service
 * @name gridCtrlRowSelectionMixin
 * @mixin
 * @desc
 *
 *     Row selection mixin for Grid controller.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid')
    .factory('gridCtrlRowSelectionMixin', gridCtrlRowSelectionMixin);
