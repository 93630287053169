/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'end-to-end-time-metric';
const prefix = `${moduleName}.${componentName}`;

export const clientTitle = `${prefix}.clientTitle`;
export const clientRttTitle = `${prefix}.clientRttTitle`;
export const serverRttTitle = `${prefix}.serverRttTitle`;
export const serverTitle = `${prefix}.serverTitle`;
export const appResponseTitle = `${prefix}.appResponseTitle`;
export const appTitle = `${prefix}.appTitle`;
export const dataTransferTitle = `${prefix}.dataTransferTitle`;
export const endToEndTimingHeader = `${prefix}.endToEndTimingHeader`;
export const totalTimeTitle = `${prefix}.totalTimeTitle`;

export const ENGLISH = {
    [clientTitle]: 'Client',
    [clientRttTitle]: 'Client RTT',
    [serverRttTitle]: 'Server RTT',
    [serverTitle]: 'Server',
    [appResponseTitle]: 'App Response',
    [appTitle]: 'App',
    [dataTransferTitle]: 'Data Transfer',
    [endToEndTimingHeader]: 'End to End Timing',
    [totalTimeTitle]: 'Total Time',
};
