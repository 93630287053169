/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AuthMatchOperation } from 'generated-types';
import { AuthMatchAttributeConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-match-attribute.config-item.factory';
import * as l10n from './auth-match-attribute-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for auth-match-attribute.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-match-attribute-readonly',
    templateUrl: './auth-match-attribute-readonly.component.html',
})
export class AuthMatchAttributeReadonlyComponent {
    /**
     * Binding for AuthMatchAttribute.
     */
    @Input()
    public editable: AuthMatchAttributeConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Map of AuthMatchOperation and its corresponding label.
     */
    private readonly matchCriteriaMap: Map<AuthMatchOperation, string>;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);

        this.matchCriteriaMap = new Map<AuthMatchOperation, string>([
            [AuthMatchOperation.AUTH_MATCH_CONTAINS,
                this.l10nService.getMessage(l10nKeys.memberLabel)],
            [AuthMatchOperation.AUTH_MATCH_DOES_NOT_CONTAIN,
                this.l10nService.getMessage(l10nKeys.notMemberLabel)],
        ]);
    }

    /**
     * Getter for criteria.
     */
    public get matchCriteria(): string {
        const { criteria } = this.editable.config;

        return criteria ? this.matchCriteriaMap.get(criteria) :
            this.l10nService.getMessage(l10nKeys.anyLabel);
    }

    /**
     * Getter for authMatchAttribute#name.
     */
    public get attributeName(): string {
        return this.editable.config.name || '';
    }

    /**
     * Getter for authMatchAttribute#values.
     */
    public get attributeValues(): string[] {
        const { values = [] } = this.editable.config;

        return values;
    }

    /**
     * Returns true if match has attributes.
     */
    public get hasAttributes(): boolean {
        return Boolean(this.attributeValues?.length);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
