/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const techSupportStatusHash = {
    SYSERR_TECH_SUPPORT_COLLECTION_ABORTED: 'SYSERR_TECH_SUPPORT_COLLECTION_ABORTED',
    SYSERR_TECH_SUPPORT_INVALID_FILENAME: 'SYSERR_TECH_SUPPORT_INVALID_FILENAME',
    SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS:
        'SYSERR_TECH_SUPPORT_COLLECTION_STATUS_IN_PROGRESS',
    SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS: 'SYSERR_TECH_SUPPORT_COLLECTION_IN_PROGRESS',
    SYSERR_TECH_SUPPORT_COLLECTION_STARTED: 'SYSERR_TECH_SUPPORT_COLLECTION_STARTED',
    SYSERR_TECH_SUPPORT_COLLECTION_ONGOING: 'SYSERR_TECH_SUPPORT_COLLECTION_ONGOING',
    SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS: 'SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS',
    SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS:
        'SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS',
    SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE: 'SYSERR_TECH_SUPPORT_COLLECTION_NOT_DONE',
    SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND:
        'SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND',
};

/**
 * Remaining ones can simply have first letter capitalized to make readable.
 * @typedef {Object} TechSupportTypes
 */
const techSupportReadableTypesHash = {
    sys_details: 'System Details',
    metricslogs: 'Metrics Logs',
    debuglogs: 'Debug Logs',
    serviceengine: 'Service Engine',
    virtualservice: 'Virtual Service',
    gslb: 'GSLB',
};

const techSupportTypesHash = {
    CLUSTERING: 'clustering',
    DEBUG_LOGS: 'debuglogs',
    GSLB: 'gslb',
    METRICS_LOGS: 'metricslogs',
    PLACEMENT: 'placement',
    POOL: 'pool',
    PORTAL: 'portal',
    SERVICE_ENGINE: 'serviceengine',
    UPGRADE: 'upgrade',
    VIRTUAL_SERVICE: 'virtualservice',
};

angular.module('aviApp')
    .constant('techSupportStatus', techSupportStatusHash)
    .constant('techSupportReadableTypes', techSupportReadableTypesHash)
    .constant('techSupportTypesHash', techSupportTypesHash);
