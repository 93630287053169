/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsModule
 */

/**
 * Ajs dependency token for VsVipCollection.
 */
export const VS_VIP_COLLECTION_TOKEN = 'VsVipCollection';

/**
 * Ajs dependency token for VHMatchConfigItem.
 */
export const VH_MATCH_CONFIG_ITEM_TOKEN = 'VHMatchConfigItem';

/**
 * Ajs dependency token for VHMatchRuleConfigItem.
 */
export const VH_MATCH_RULE_CONFIG_ITEM_TOKEN = 'VHMatchRuleConfigItem';
