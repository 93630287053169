/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { BACKUP_CONFIGURATION_ITEM_TOKEN } from '../system.tokens';

/**
 * @desc Collection for BackupConfiguration items.
 * @author Zhiqian Liu
 */
export class BackupConfigurationCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'backupconfiguration',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(BACKUP_CONFIGURATION_ITEM_TOKEN);
    }
}

BackupConfigurationCollection.ajsDependencies = [
    BACKUP_CONFIGURATION_ITEM_TOKEN,
];
