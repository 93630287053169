/**
 * @module IcapProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import {
    IcapProfileListPageComponent,
    ICAPProfileModalComponent,
} from '.';

import { icapProfileCollectionProvider } from './ajs-upgraded-providers';

const icapProfileComponents = [
    ICAPProfileModalComponent,
    IcapProfileListPageComponent,
];

/**
 * @description
 *
 *      Angular Module for ICAP-Profile related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...icapProfileComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
        DataGridModule,
    ],
    providers: [
        icapProfileCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class IcapProfileModule {}
