/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    ViewChild,
} from '@angular/core';
import './avi-card.component.less';

@Component({
    selector: 'avi-card',
    templateUrl: './avi-card.component.html',
})
export class AviCardComponent implements AfterViewInit {
    /**
     * If true, loader will be displayed.
     */
    @Input()
    public loading = false;

    /**
     * Indicate whether to add a body padding by default.
     */
    @Input()
    public noBodyPadding = false;

    /**
     * Reference to the div containing an optional transcluded avi-card_footer element.
     */
    @ViewChild('cardFooterContainerContent') private cardFooterContainer: ElementRef;

    /**
     * Reference to the div containing an optional transcluded avi-card_header element.
     */
    @ViewChild('cardHeaderContainerContent') private cardHeaderContainerContent: ElementRef;

    public hasTranscludedCardHeader = false;

    /**
     * Returns true if the avi-card_footer transcluded element exists.
     */
    public get hasTranscludedCardFooterContainer(): boolean {
        return this.cardFooterContainer?.nativeElement.children.length > 0;
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.hasTranscludedCardHeader =
            this.cardHeaderContainerContent?.nativeElement.children.length > 0;
    }
}
