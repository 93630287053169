/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Component, Input } from '@angular/core';
import { IFullModalLayout } from 'ng/modules/core';
import './full-modal-breadcrumbs.component.less';

/**
 * @description Component for displaying a list of breadcrumbs.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-breadcrumbs',
    templateUrl: './full-modal-breadcrumbs.component.html',
})
export class FullModalBreadcrumbsComponent {
    /**
     * Stack of modals that the user is configuring.
     */
    @Input() public modalLayoutStack: IFullModalLayout[] = [];

    /**
     * TrackBy function for rendering the list of breadcrumbs.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
