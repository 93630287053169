/**
 * Module containing rbac related components and services.
 * @preferred
 * @module RbacModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClrInputModule } from '@clr/angular';
import { AviFormsModule } from '../avi-forms/avi-forms.module';

import {
    RbacKeyUniquenessValidationDirective,
    RbacLabelGridAjsWrapperComponent,
    RbacLabelGridComponent,
    RoleFilterMatchLabel,
} from '.';

const rbacComponents = [
    RbacLabelGridComponent,
    RbacLabelGridAjsWrapperComponent,
    RoleFilterMatchLabel,
];

const rbacDirectives = [
    RbacKeyUniquenessValidationDirective,
];

@NgModule({
    declarations: [
        ...rbacComponents,
        ...rbacDirectives,
    ],
    exports: [
        RoleFilterMatchLabel,
        RbacLabelGridComponent,
        RbacLabelGridAjsWrapperComponent,
    ],
    imports: [
        CommonModule,
        ClrInputModule,
        BrowserAnimationsModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        AviFormsModule,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class RbacModule { }
