/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'dns-records';
const prefix = `${moduleName}.${componentName}`;

export const totalValuesHeader = `${prefix}.totalValuesHeader`;
export const columnTitleFqdn = `${prefix}.columnTitleFqdn`;
export const columnTitleUdpQueries = `${prefix}.columnTitleUdpQueries`;
export const columnTitleErrors = `${prefix}.columnTitleErrors`;
export const columnTitleSource = `${prefix}.columnTitleSource`;
export const columnTitleService = `${prefix}.columnTitleService`;

export const ENGLISH = {
    [totalValuesHeader]: 'Total Values',
    [columnTitleFqdn]: 'FQDN',
    [columnTitleUdpQueries]: 'UDP Queries (/sec)',
    [columnTitleErrors]: 'Errors (/sec)',
    [columnTitleSource]: 'Source',
    [columnTitleService]: 'Service',
};
