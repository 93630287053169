/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-signpost-custom-waf-latency';
const prefix = `${moduleName}.${componentName}`;

export const latencyLabel = `${prefix}.latencyLabel`;
export const logsNumberLabel = `${prefix}.logsNumberLabel`;

export const ENGLISH = {
    [latencyLabel]: 'Latency (usec)',
    [logsNumberLabel]: '# Logs',
};
