/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './system-settings-access-card.l10n';
import './system-settings-access-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Access Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-card',
    templateUrl: './system-settings-access-card.component.html',
})
export class SystemSettingsAccessCardComponent {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
