/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic';
const prefix = `${moduleName}.${componentName}`;

export const logLabel = `${prefix}.logLabel`;
export const significantLabel = `${prefix}.significantLabel`;
export const nonSignificantLabel = `${prefix}.nonSignificantLabel`;
export const significanceLabel = `${prefix}.significanceLabel`;
export const sslLabel = `${prefix}.sslLabel`;
export const applicationLabel = `${prefix}.applicationLabel`;
export const protocolLabel = `${prefix}.protocolLabel`;
export const securityLabel = `${prefix}.securityLabel`;
export const datascriptLabel = `${prefix}.datascriptLabel`;
export const outOfBandLabel = `${prefix}.outOfBandLabel`;
export const clientConnectionLabel = `${prefix}.clientConnectionLabel`;
export const serverConnectionLabel = `${prefix}.serverConnectionLabel`;
export const defaultErrorMsgLabel = `${prefix}.defaultErrorMsgLabel`;

export const ENGLISH = {
    [significantLabel]: 'Significant',
    [nonSignificantLabel]: 'Non-Significant',
    [significanceLabel]: 'Significance',
    [sslLabel]: 'SSL',
    [applicationLabel]: 'Application',
    [protocolLabel]: 'Protocol',
    [securityLabel]: 'Security',
    [datascriptLabel]: 'Datascript',
    [outOfBandLabel]: 'Out of Band',
    [clientConnectionLabel]: 'Client Connection',
    [serverConnectionLabel]: 'Server Connection',
    [defaultErrorMsgLabel]: 'An error occurred while loading the graph, please try again.',
};
