/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IEmailConfiguration,
    SMTPType,
} from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/utils/mixins';
import * as l10n from '../system.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export class EmailConfigurationConfigItem extends
    withFullModalMixin(MessageItem)<IEmailConfiguration> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    protected readonly objectType = 'EmailConfiguration';

    private l10nService: L10nService;

    constructor(args = {}) {
        super(args);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.emailConfigurationModalBreadcrumbTitle);
    }

    /**
     * Returns the configured SMTP type.
     */
    public get smtpType(): SMTPType {
        return this.config.smtp_type;
    }

    /**
     * Returns true if smtyType is SMTP_LOCAL_HOST or SMTP_ANONYMOUS_SERVER;
     */
    public emailSmtpTypeIsServerOrAnonymous(): boolean {
        return this.smtpType === SMTPType.SMTP_LOCAL_HOST ||
            this.smtpType === SMTPType.SMTP_ANONYMOUS_SERVER;
    }

    /**
     * Returns true if desired to show from_email field.
     */
    public hasFromEmail(): boolean {
        return this.emailSmtpTypeIsServerOrAnonymous() && Boolean(this.config.from_email);
    }
}
