/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ssl-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleAcceptedVersions = `${prefix}.columnTitleAcceptedVersions`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleAcceptedVersions]: 'Accepted Versions',
    [headerLabel]: 'SSL Profile',
};
