/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'full-modal-config';
const prefix = `${moduleName}.${componentName}`;

export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const viewAsJson = `${prefix}.viewAsJson`;

export const ENGLISH = {
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
    [viewAsJson]: 'View as JSON',
};
