/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'backup-overview-card';
const prefix = `${moduleName}.${componentName}`;

export const configurationBackupLabel = `${prefix}.configurationBackupLabel`;
export const enabledDataLabel = `${prefix}.enabledDataLabel`;
export const deactivatedDataLabel = `${prefix}.deactivatedDataLabel`;
export const frequencyLabel = `${prefix}.frequencyLabel`;
export const frequencyStringLabel = `${prefix}.frequencyStringLabel`;
export const maximumBackupsStoredLabel = `${prefix}.maxBackupsStoredLabel`;

export const ENGLISH = {
    [configurationBackupLabel]: 'Configuration Backup',
    [enabledDataLabel]: 'Enabled',
    [deactivatedDataLabel]: 'Deactivated',
    [frequencyLabel]: 'Frequency',
    [frequencyStringLabel]: 'Every {0} {1}(s)',
    [maximumBackupsStoredLabel]: 'Maximum Backups Stored',
};
