/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DataModelModule
 */

import { ListCollDataSource } from './list-coll-data-source.factory';

export class LimitedListCollDataSource extends ListCollDataSource {}

Object.assign(LimitedListCollDataSource.prototype, {
    hasSearch: false,
    hasSorting: false,
    hasPagination: false,
});
