/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name DebouncePromiseFactory
 * @author Alex Malitsky
 * @description
 *
 *     Constructor function to return instance with two methods: `method` to use a debounced path
 *     and `immediate` to cancel postponed method call (if any) and run another one immediately.
 *     Each `method` or `immediate` call returns a promise to be resolved with returned value of
 *     a passed function wrapped in a promise if it has been executed or rejected when another
 *     call has came within a provided time interval.
 *
 */
//TODO throttle support
angular.module('aviApp').factory('DebouncePromiseFactory', ['$q', function($q) {
    /**
     * @param {Function} method - Supposed to return promise, otherwise we can just use underscore.
     * @param {number=} duration - Debounce interval. Defaults to zero.
     * @constructor
     */
    return function(method, duration) {
        let timeoutId = 0,
            deferred;

        if (typeof duration !== 'number' || !_.isFinite(+duration) || duration < 0) {
            duration = 0;
        }

        if (!method || typeof method !== 'function') {
            throw new Error('Function to be debounced was not passed');
        }

        function clear() {
            clearTimeout(timeoutId);

            if (deferred) {
                deferred.reject('Following method call came within defined debounced interval');
            }
        }

        function execute(args, immediateRun) {
            clear();

            deferred = $q.defer();

            if (!immediateRun) {
                timeoutId = setTimeout(function() {
                    $q.when(method(...args)).then(
                        deferred.resolve.bind(deferred),
                        deferred.reject.bind(deferred),
                    );
                }, duration);
            } else {
                $q.when(method(...args)).then(
                    deferred.resolve.bind(deferred),
                    deferred.reject.bind(deferred),
                );
            }

            return deferred.promise;
        }

        /**
         * Runs method immediately cancelling the prevoisly postponed method call (if any).
         * @returns {ng.$q.promise}
         */
        this.immediate = function() {
            return execute(arguments, true);
        };

        /**
         * Runs method in normal (debounced way) cancelling the previously postponed method call
         * (if any).
         * @returns {ng.$q.promise}
         */
        this.method = function() {
            return execute(arguments);
        };
    };
}]);
