/** @module BotModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { BotAllowRuleConfigItem } from 'ajs/modules/bot-detection-policy';

import {
    HostHdrMatchComponent,
    HttpMethodMatchComponent,
    IMatchOption,
    IpAddrMatchComponent,
    PathOrQueryMatchComponent,
} from 'ng/modules/match';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import { MatchTarget } from 'object-types';
import * as globalL10n from 'global-l10n';
import * as l10n from './bot-allow-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Bot Allow List modal.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-allow-rule-modal',
    templateUrl: './bot-allow-rule-modal.component.html',
})
export class BotAllowRuleModalComponent implements OnInit {
    @Input()
    public editable: BotAllowRuleConfigItem;

    /**
     * Informs if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Called when the user wants to save the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    /**
     * Options for bot-action dropdown
     */
    public botActionOptions: IAviDropdownOption[];

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Is 'BotAllowRule'.
     */
    public objectType: string;

    /**
     * Options list for all possible bot-allow-rule options
     */
    public matchOptions: IMatchOption[];

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.setMatchAndActionOptions();
    }

    /**
     * Triggered upon user attempting to save settings.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Triggered upon user exiting modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Sets options for Matches and bot-action.
     */
    private setMatchAndActionOptions(): void {
        this.matchOptions = [
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(globalL10nKeys.clientIpAddressLabel),
                objectType: MatchTarget,
                fieldName: 'client_ip',
            },
            {
                component: HttpMethodMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(globalL10nKeys.httpMethodLabel),
                objectType: MatchTarget,
                fieldName: 'method',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                componentBindings: {
                    disallowStringGroups: true,
                },
                label: this.l10nService.getMessage(globalL10nKeys.pathLabel),
                objectType: MatchTarget,
                fieldName: 'path',
            },
            {
                component: HostHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(globalL10nKeys.hostHeaderLabel),
                objectType: MatchTarget,
                fieldName: 'host_hdr',
            },
        ];

        const botActionTypes = this.schemaService.getEnumValues('BotAction');

        this.botActionOptions = createOptionsFromEnumProps(botActionTypes);
    }
}
