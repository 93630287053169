/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('healthscoreSparkline', ['ChartService', function(ChartService) {
    return {
        restrict: 'EA',
        scope: {
            data: '=',
        },
        template: '<div class = "healthscore-sparkline"></div>',
        replace: true,
        link(scope, elm, attr) {
            const repaint = function() {
                if (!scope.data) { return; }

                scope.chart.graphSingleLine(scope.data);
            };

            scope.chart = new ChartService(elm, {
                height: 100,
                width: 300,
                padding: {
                    left: 5,
                    right: 5,
                    top: 2,
                    bottom: 2,
                },
            });

            // register listeners
            scope.$watch('data.__hidden.toggle', repaint);
            scope.$on('repaint', repaint);
        },
    };
}]);
