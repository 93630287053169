/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import { DOCUMENT } from '@angular/common';
import { HSMSafenetClientInfoConfigItem } from 'ajs/modules/security';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './hsm-group-download-certificate-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Download Certificate modal.
 * @author vgohil
 */
@Component({
    selector: 'hsm-group-download-certificate-modal',
    templateUrl: './hsm-group-download-certificate-modal.component.html',
})
export class HsmGroupDownloadCertificateModalComponent {
    /**
     * HSM Group name value.
     */
    @Input()
    public name: string;

    /**
     * Client Info list.
     */
    @Input()
    public clientInfo: RepeatedMessageItem<HSMSafenetClientInfoConfigItem>;

    /**
     * Method to close the modal.
     */
    @Output()
    public onCloseModal = new EventEmitter();

    /**
     * ClientInfo#Certificate grid config.
     */
    public clientInfoCertificateGridConfig: IAviDataGridConfig;

    /**
     * Layout for Download Certificate modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        @Inject(DOCUMENT)
        document: Document,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.clientInfoCertificateGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleIpAddress),
                    id: 'client_ip',
                    transform(safenetClientInfo: HSMSafenetClientInfoConfigItem) {
                        return safenetClientInfo.config.client_ip;
                    },
                },
            ],
            layout: {
                hideCheckboxes: true,
            },
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.downloadButtonLabel),
                shape: 'download',
                onClick: (safenetClientInfo: HSMSafenetClientInfoConfigItem) => {
                    document.location.href = `data:application/octet-stream,${
                        encodeURIComponent(safenetClientInfo.config.client_cert)}`;
                },
            }],
        };
    }

    /**
     * Method to close Download Certificate modal.
     */
    public dismissModal(): void {
        this.onCloseModal.emit();
    }
}
