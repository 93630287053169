/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'cloud-create-controller';
const prefix = `${moduleName}.${componentName}`;

export const seInProviderContextTooltip = `${prefix}.seInProviderContextTooltip`;
export const cloudSelectionTitle = `${prefix}.cloudSelectionTitle`;
export const selectCloudTitle = `${prefix}.selectCloudTitle`;
export const infrastructureTitle = `${prefix}.infrastructureTitle`;
export const dataCenterTitle = `${prefix}.dataCenterTitle`;
export const networkTitle = `${prefix}.networkTitle`;
export const roleMappingTitle = `${prefix}.roleMappingTitle`;
export const vpcNetworkEncryptionTitle = `${prefix}.vpcNetworkEncryptionTitle`;
export const dhcpSettingsTitle = `${prefix}.dhcpSettingsTitle`;
export const linuxServerConfigurationTitle = `${prefix}.linuxServerConfigurationTitle`;
export const credentialsTitle = `${prefix}.credentialsTitle`;
export const locationNetworkTitle = `${prefix}.locationNetworkTitle`;
export const generalTitle = `${prefix}.generalTitle`;
export const separateManagementInterfaceToolTip = `${prefix}.separateManagementInterfaceToolTip`;
export const vcenterVsphereLoginLabel = `${prefix}.vcenterVsphereLoginLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const vcenterAddressInputLabel = `${prefix}.vcenterAddressInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const accessPermissionInputLabel = `${prefix}.accessPermissionInputLabel`;
export const readButtonLabel = `${prefix}.readButtonLabel`;
export const writeButtonLabel = `${prefix}.writeButtonLabel`;
export const cloudNameInputPlaceholder = `${prefix}.cloudNameInputPlaceholder`;
export const licenseModelLabel = `${prefix}.licenseModelLabel`;
export const ipamProfileInputLabel = `${prefix}.ipamProfileInputLabel`;
export const selectIpamProfilePlaceholder = `${prefix}.selectIpamProfilePlaceholder`;
export const dnsProfileInputLabel = `${prefix}.dnsProfileInputLabel`;
export const selectDnsProfilePlaceholder = `${prefix}.selectDnsProfilePlaceholder`;
export const createIpamDnsProfileButtonLabel = `${prefix}.createIpamDnsProfileButtonLabel`;
export const selectDataCenterInputLabel = `${prefix}.selectDataCenterInputLabel`;
export const dataCenterInputLabel = `${prefix}.dataCenterInputLabel`;
export const selectDataCenterInputPlaceholder = `${prefix}.selectDataCenterInputPlaceholder`;
export const systemIpAddressManagementSettingLabel = `${prefix}.systemIpAddressManagementSettingLabel`;
export const defaultNetworkIpAddressManagementLabel = `${prefix}.defaultNetworkIpAddressManagementLabel`;
export const dhcpEnabledCheckboxLabel = `${prefix}.dhcpEnabledCheckboxLabel`;
export const ipv6AutoConfigurationCheckboxLabel = `${prefix}.ipv6AutoConfigurationCheckboxLabel`;
export const virtualServicePlacementSettingsLabel = `${prefix}.virtualServicePlacementSettingsLabel`;
export const preferStaticRoutesVsDirectlyConnectedNetworkLabel = `${prefix}.preferStaticRoutesVsDirectlyConnectedNetworkLabel`;
export const useStaticRoutesForNetworkResolutionOfVipLabel = `${prefix}.useStaticRoutesForNetworkResolutionOfVipLabel`;
export const selectManagementNetworkLabel = `${prefix}.selectManagementNetworkLabel`;
export const managementNetworkInputLabel = `${prefix}.managementNetworkInputLabel`;
export const selectManagementNetworkInputPlaceholder = `${prefix}.selectManagementNetworkInputPlaceholder`;
export const serviceEngineLabel = `${prefix}.serviceEngineLabel`;
export const ipAddressManagementNetworkLabel = `${prefix}.ipAddressManagementNetworkLabel`;
export const ipSubnetInputLabel = `${prefix}.ipSubnetInputLabel`;
export const defaultGatewayLabel = `${prefix}.defaultGatewayLabel`;
export const addStaticIpAddressPoolInputLabel = `${prefix}.addStaticIpAddressPoolInputLabel`;
export const stateBasedDnsRegistrationCheckboxLabel = `${prefix}.stateBasedDnsRegistrationCheckboxLabel`;
export const templateServiceEngineGroupInputLabel = `${prefix}.templateServiceEngineGroupInputLabel`;
export const templateSegGroupDropdownPlaceholder = `${prefix}.templateSegGroupDropdownPlaceholder`;
export const createServiceEngineGroupLabel = `${prefix}.createServiceEngineGroupLabel`;
export const nextBtnLabel = `${prefix}.nextBtnLabel`;
export const editClouldHeader = `${prefix}.editClouldHeader`;
export const newClouldHeader = `${prefix}.newClouldHeader`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;
export const completeBtnLabel = `${prefix}.completeBtnLabel`;
export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const retryBtnLabel = `${prefix}.retryBtnLabel`;
export const previousBtnLabel = `${prefix}.previousBtnLabel`;
export const useIp4DhcpCheckboxLabel = `${prefix}.useIp4DhcpCheckboxLabel`;
export const useIp6DhcpCheckboxLabel = `${prefix}.useIp6DhcpCheckboxLabel`;
export const enableVipStaticRoutesCheckboxLabel = `${prefix}.enableVipStaticRoutesCheckboxLabel`;
export const preferStaticRoutesCheckboxLabel = `${prefix}.preferStaticRoutesCheckboxLabel`;
export const licenseModelHeader = `${prefix}.licenseModelHeader`;
export const ipamProfileInputPlaceholder = `${prefix}.ipamProfileInputPlaceholder`;
export const serviceEngineHeader = `${prefix}.serviceEngineHeader`;
export const openStackLoginHeader = `${prefix}.openStackLoginHeader`;
export const keystoneAuthUrlLabel = `${prefix}.keystoneAuthUrlLabel`;
export const allowSelfsignedCertificatesCheckboxLabel = `${prefix}.allowSelfsignedCertificatesCheckboxLabel`;
export const useKeystoneAuthCheckboxLabel = `${prefix}.useKeystoneAuthCheckboxLabel`;
export const useInternalUrlEndpointsCheckboxLabel = `${prefix}.useInternalUrlEndpointsCheckboxLabel`;
export const dnsProviderInputLabel = `${prefix}.dnsProviderInputLabel`;
export const dnsProviderInputPlaceholder = `${prefix}.dnsProviderInputPlaceholder`;
export const managementNetworkHeader = `${prefix}.managementNetworkHeader`;
export const adminTenantInputLabel = `${prefix}.adminTenantInputLabel`;
export const adminTenantInputPlaceholder = `${prefix}.adminTenantInputPlaceholder`;
export const regionNameInputLabel = `${prefix}.regionNameInputLabel`;
export const managementNetworkInputPlaceholder = `${prefix}.managementNetworkInputPlaceholder`;
export const freeUnusedFloatingIpAddressCheckboxLabel = `${prefix}.freeUnusedFloatingIpAddressCheckboxLabel`;
export const mapAviAdminToCloudAdminCheckboxLabel = `${prefix}.mapAviAdminToCloudAdminCheckboxLabel`;
export const serviceEngineOwnershipLabel = `${prefix}.serviceEngineOwnershipLabel`;
export const seWithinTenantContextRadioLabel = `${prefix}.seWithinTenantContextRadioLabel`;
export const seWithinAdminContextRadioLabel = `${prefix}.seWithinAdminContextRadioLabel`;
export const serviceEngineImageFormatLabel = `${prefix}.serviceEngineImageFormatLabel`;
export const importAllOpenStackKeystoneTenantsCheckboxLabel = `${prefix}.importAllOpenStackKeystoneTenantsCheckboxLabel`;
export const virtualServicePlacementSettingsHeader = `${prefix}.virtualServicePlacementSettingsHeader`;
export const preferStaticRoutesMessageCheckboxLabel = `${prefix}.preferStaticRoutesMessageCheckboxLabel`;
export const useStaticRoutesMessageCheckboxLabel = `${prefix}.useStaticRoutesMessageCheckboxLabel`;
export const advancedHeader = `${prefix}.advancedHeader`;
export const useAdminUrlCheckboxLabel = `${prefix}.useAdminUrlCheckboxLabel`;
export const antiAffinityCheckboxLael = `${prefix}.antiAffinityCheckboxLael`;
export const externalNetworksCheckboxLabel = `${prefix}.externalNetworksCheckboxLabel`;
export const configDriveRadioLabel = `${prefix}.configDriveRadioLabel`;
export const metadataSvcRadioLabel = `${prefix}.metadataSvcRadioLabel`;
export const thirdPartyIntegrationSettingsHeader = `${prefix}.thirdPartyIntegrationSettingsHeader`;
export const integrationWithContrailVncCheckboxLabel = `${prefix}.integrationWithContrailVncCheckboxLabel`;
export const apiEndpointUrlInputLabel = `${prefix}.apiEndpointUrlInputLabel`;
export const organizationInputLabel = `${prefix}.organizationInputLabel`;
export const vsdHostInputLabel = `${prefix}.vsdHostInputLabel`;
export const servicePortInputLabel = `${prefix}.servicePortInputLabel`;
export const sshUserInputLabel = `${prefix}.sshUserInputLabel`;
export const createSshUserLabel = `${prefix}.createSshUserLabel`;
export const sshUserInputPlaceholder = `${prefix}.sshUserInputPlaceholder`;
export const autoscalePollingIntervalInputLabel = `${prefix}.autoscalePollingIntervalInputLabel`;
export const secInnerText = `${prefix}.secInnerText`;
export const useStaticRouteForVipPlacementCheckboxLabel = `${prefix}.useStaticRouteForVipPlacementCheckboxLabel`;
export const useInbandManagementCheckboxLabel = `${prefix}.useInbandManagementCheckboxLabel`;
export const directoryPathForSeUsageLabel = `${prefix}.directoryPathForSeUsageLabel`;
export const autoSelectPathRadioLabel = `${prefix}.autoSelectPathRadioLabel`;
export const singlePathForAllLogsRadioLabel = `${prefix}.singlePathForAllLogsRadioLabel`;
export const separatePathsForSystemAndClientLogsRadioLabel = `${prefix}.separatePathsForSystemAndClientLogsRadioLabel`;
export const pathForSystemClientLogsInputLabel = `${prefix}.pathForSystemClientLogsInputLabel`;
export const pathForSystemLogsInputLabel = `${prefix}.pathForSystemLogsInputLabel`;
export const pathForSystemLogsInputPlaceholder = `${prefix}.pathForSystemLogsInputPlaceholder`;
export const sizeInputLabel = `${prefix}.sizeInputLabel`;
export const gbInnerText = `${prefix}.gbInnerText`;
export const pathForClientLogsInputLabel = `${prefix}.pathForClientLogsInputLabel`;
export const serverListHeader = `${prefix}.serverListHeader`;
export const hostIpAddressInputLabel = `${prefix}.hostIpAddressInputLabel`;
export const seGroupInputLabel = `${prefix}.seGroupInputLabel`;
export const seGroupInputPlaceholder = `${prefix}.seGroupInputPlaceholder`;
export const cleanupExistingSeConfigBtnLabel = `${prefix}.cleanupExistingSeConfigBtnLabel`;
export const removeHostBtnLabel = `${prefix}.removeHostBtnLabel`;
export const verifyHostBtnLabel = `${prefix}.verifyHostBtnLabel`;
export const yesRadioLabel = `${prefix}.yesRadioLabel`;
export const noRadioLabel = `${prefix}.noRadioLabel`;
export const inbandManagementLabel = `${prefix}.inbandManagementLabel`;
export const numberOfCoresLabel = `${prefix}.numberOfCoresLabel`;
export const allRadioLabel = `${prefix}.allRadioLabel`;
export const customRadioLabel = `${prefix}.customRadioLabel`;
export const memoryGbLabel = `${prefix}.memoryGbLabel`;
export const useCustomSystemClientLogPathsCheckboxLabel = `${prefix}.useCustomSystemClientLogPathsCheckboxLabel`;
export const addNewServerLinkLabel = `${prefix}.addNewServerLinkLabel`;
export const dnsProfileInputPlaceholder = `${prefix}.dnsProfileInputPlaceholder`;
export const awsRegionInputLabel = `${prefix}.awsRegionInputLabel`;
export const awsRegionInputPlaceholder = `${prefix}.awsRegionInputPlaceholder`;
export const accessAwsThroughProxyCheckboxLabel = `${prefix}.accessAwsThroughProxyCheckboxLabel`;
export const proxyHostInputLabel = `${prefix}.proxyHostInputLabel`;
export const proxyPortInputLabel = `${prefix}.proxyPortInputLabel`;
export const proxyPortInputPlaceholder = `${prefix}.proxyPortInputPlaceholder`;
export const proxyUsernameInputLabel = `${prefix}.proxyUsernameInputLabel`;
export const proxyUsernameInputPlaceholder = `${prefix}.proxyUsernameInputPlaceholder`;
export const proxyPasswordInputLabel = `${prefix}.proxyPasswordInputLabel`;
export const proxyPasswordInputPlaceholder = `${prefix}.proxyPasswordInputPlaceholder`;
export const awsUserCredentialsHeader = `${prefix}.awsUserCredentialsHeader`;
export const useAccessSecretKeyRadioLabel = `${prefix}.useAccessSecretKeyRadioLabel`;
export const useAwsIdentityAccessManagementRadioLabel = `${prefix}.useAwsIdentityAccessManagementRadioLabel`;
export const accessKeyIdInputLabel = `${prefix}.accessKeyIdInputLabel`;
export const secretAccessKeyInputLabel = `${prefix}.secretAccessKeyInputLabel`;
export const useCrossAccountAssumeRoleCheckboxLabel = `${prefix}.useCrossAccountAssumeRoleCheckboxLabel`;
export const iamAssumeRoleInputLabel = `${prefix}.iamAssumeRoleInputLabel`;
export const assumeRoleInputLabel = `${prefix}.assumeRoleInputLabel`;
export const registerVirtualServiceNamesLabel = `${prefix}.registerVirtualServiceNamesLabel`;
export const emptyRadioLabel = `${prefix}.emptyRadioLabel`;
export const amazonRoute53RadioLabel = `${prefix}.amazonRoute53RadioLabel`;
export const awsVpcAndAvailabilityZonesHeader = `${prefix}.awsVpcAndAvailabilityZonesHeader`;
export const vpcNameInputPlaceholder = `${prefix}.vpcNameInputPlaceholder`;
export const freeUnusedElasticIpAddressCheckboxLabel = `${prefix}.freeUnusedElasticIpAddressCheckboxLabel`;
export const selectSeManagementNetworkLabel = `${prefix}.selectSeManagementNetworkLabel`;
export const availabilityZoneInputLabel = `${prefix}.availabilityZoneInputLabel`;
export const availabilityZoneInputPlaceholder = `${prefix}.availabilityZoneInputPlaceholder`;
export const seManagementNetworkInputLabel = `${prefix}.seManagementNetworkInputLabel`;
export const seManagementNetworkInputPlaceholder = `${prefix}.seManagementNetworkInputPlaceholder`;
export const removeAvailabilityZoneBtnLabel = `${prefix}.removeAvailabilityZoneBtnLabel`;
export const addAvailabilityZoneLinkLabel = `${prefix}.addAvailabilityZoneLinkLabel`;
export const enableSimpleQueueServiceCheckboxLabel = `${prefix}.enableSimpleQueueServiceCheckboxLabel`;
export const encryptionHeader = `${prefix}.encryptionHeader`;
export const otherSettingsHeader = `${prefix}.otherSettingsHeader`;
export const asgPollingIntervalInputLabel = `${prefix}.asgPollingIntervalInputLabel`;
export const generalHeader = `${prefix}.generalHeader`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const ipamProviderInputLabel = `${prefix}.ipamProviderInputLabel`;
export const ipamProviderInputPlaceholder = `${prefix}.ipamProviderInputPlaceholder`;
export const otherRadioLabel = `${prefix}.otherRadioLabel`;
export const googleCloudUserCredentialsHeader = `${prefix}.googleCloudUserCredentialsHeader`;
export const serviceEngineProjectIdInputLabel = `${prefix}.serviceEngineProjectIdInputLabel`;
export const serviceEngineProjectIdInputPlaceholder = `${prefix}.serviceEngineProjectIdInputPlaceholder`;
export const controllerRnningInGcpMessage = `${prefix}.controllerRnningInGcpMessage`;
export const useControllerVirtualMachineServiceAccountCheckboxLabel = `${prefix}.useControllerVirtualMachineServiceAccountCheckboxLabel`;
export const googleCloudCredentialsInputLabel = `${prefix}.googleCloudCredentialsInputLabel`;
export const googleCloudCredentialsInputPlaceholder = `${prefix}.googleCloudCredentialsInputPlaceholder`;
export const createCredentialsBtnLabel = `${prefix}.createCredentialsBtnLabel`;
export const regionZonesHeader = `${prefix}.regionZonesHeader`;
export const serviceEngineRegionInputLabel = `${prefix}.serviceEngineRegionInputLabel`;
export const serviceEngineRegionInputPlaceholder = `${prefix}.serviceEngineRegionInputPlaceholder`;
export const zonesInputLabel = `${prefix}.zonesInputLabel`;
export const zonesInputPlaceholder = `${prefix}.zonesInputPlaceholder`;
export const networkHeader = `${prefix}.networkHeader`;
export const networkModeLabel = `${prefix}.networkModeLabel`;
export const dedicatedManagementLabel = `${prefix}.dedicatedManagementLabel`;
export const twoArmModeLabel = `${prefix}.twoArmModeLabel`;
export const vpcProjectIdLabel = `${prefix}.vpcProjectIdLabel`;
export const vpcNetworkNameInputLabel = `${prefix}.vpcNetworkNameInputLabel`;
export const vpcSubnetNameInputLabel = `${prefix}.vpcSubnetNameInputLabel`;
export const firewallHeader = `${prefix}.firewallHeader`;
export const firewallTargetTagsInputLabel = `${prefix}.firewallTargetTagsInputLabel`;
export const firewallTargetTagsInputPlaceholder = `${prefix}.firewallTargetTagsInputPlaceholder`;
export const storageHeader = `${prefix}.storageHeader`;
export const cloudStorageProjectIdInputLabel = `${prefix}.cloudStorageProjectIdInputLabel`;
export const cloudStorageBucketNameInputLabel = `${prefix}.cloudStorageBucketNameInputLabel`;
export const cloudStorageBucketNameInputPlaceholder = `${prefix}.cloudStorageBucketNameInputPlaceholder`;
export const encryptionKeysHeader = `${prefix}.encryptionKeysHeader`;
export const seImageEncryptionKeyIdInputLabel = `${prefix}.seImageEncryptionKeyIdInputLabel`;
export const seDiskEncryptionKeyIdInputLabel = `${prefix}.seDiskEncryptionKeyIdInputLabel`;
export const gcsBucketEncryptionKeyIdInputLabel = `${prefix}.gcsBucketEncryptionKeyIdInputLabel`;
export const gcsObjectsEncryptionKeyIdInputLabel = `${prefix}.gcsObjectsEncryptionKeyIdInputLabel`;
export const customTagsHeader = `${prefix}.customTagsHeader`;
export const vipAllocationHeader = `${prefix}.vipAllocationHeader`;
export const routesRadioLabel = `${prefix}.routesRadioLabel`;
export const enableSubnetRoutesCheckboxLabel = `${prefix}.enableSubnetRoutesCheckboxLabel`;
export const gcpCloudRouterNamesInputLabel = `${prefix}.gcpCloudRouterNamesInputLabel`;
export const gcpCloudRouterNamesInputPlaceholder = `${prefix}.gcpCloudRouterNamesInputPlaceholder`;
export const azureUserCredentialsHeader = `${prefix}.azureUserCredentialsHeader`;
export const subscriptionIdInputLabel = `${prefix}.subscriptionIdInputLabel`;
export const useAzureMsiAuthenticationCheckboxLabel = `${prefix}.useAzureMsiAuthenticationCheckboxLabel`;
export const azureCredentialsInputLabel = `${prefix}.azureCredentialsInputLabel`;
export const azureCredentialsInputPlaceholder = `${prefix}.azureCredentialsInputPlaceholder`;
export const locationAndNetworkHeader = `${prefix}.locationAndNetworkHeader`;
export const locationInputLabel = `${prefix}.locationInputLabel`;
export const locationInputPlaceholder = `${prefix}.locationInputPlaceholder`;
export const resourceGroupInputLabel = `${prefix}.resourceGroupInputLabel`;
export const resourceGroupInputPlaceholder = `${prefix}.resourceGroupInputPlaceholder`;
export const vnetInputPlaceholder = `${prefix}.vnetInputPlaceholder`;
export const serviceEngineNetworkInputLabel = `${prefix}.serviceEngineNetworkInputLabel`;
export const serviceEngineNetworkInputPlaceholder = `${prefix}.serviceEngineNetworkInputPlaceholder`;
export const useDedicatedManagementInterfaceCheckboxLabel = `${prefix}.useDedicatedManagementInterfaceCheckboxLabel`;
export const subnetForManagementInterfaceInputLabel = `${prefix}.subnetForManagementInterfaceInputLabel`;
export const subnetForManagementInterfaceInputPlaceholder = `${prefix}.subnetForManagementInterfaceInputPlaceholder`;
export const diskEncryptionHeader = `${prefix}.diskEncryptionHeader`;
export const diskEncryptionSetInputLabel = `${prefix}.diskEncryptionSetInputLabel`;
export const diskEncryptionSetInputPlaceholder = `${prefix}.diskEncryptionSetInputPlaceholder`;
export const dnsResolverLabel = `${prefix}.dnsResolverLabel`;

export const ENGLISH = {
    [seInProviderContextTooltip]: 'Provider mode is turned on in the Tenant Settings. Hence, all SEs can only be created in the provider tenant.',
    [cloudSelectionTitle]: 'Cloud Selection',
    [selectCloudTitle]: 'Select Cloud',
    [infrastructureTitle]: 'Infrastructure',
    [dataCenterTitle]: 'Data Center',
    [networkTitle]: 'Network',
    [roleMappingTitle]: 'Role Mapping',
    [vpcNetworkEncryptionTitle]: 'VPC/Network/Encryption',
    [dhcpSettingsTitle]: 'DHCP Settings',
    [linuxServerConfigurationTitle]: 'Linux Server Configuration',
    [credentialsTitle]: 'Credentials',
    [locationNetworkTitle]: 'Location/Network',
    [generalTitle]: 'General',
    [separateManagementInterfaceToolTip]: 'Use this option to use a separate NIC for control-plane communication between Service Engines and Controllers.When this option is not selected, a single NIC is used for both data and control plane traffic.',
    [vcenterVsphereLoginLabel]: 'vCenter / vSphere Login',
    [nameInputLabel]: 'Name',
    [cloudNameInputPlaceholder]: 'Name',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Username',
    [vcenterAddressInputLabel]: 'vCenter Address',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Password',
    [accessPermissionInputLabel]: 'Access Permission',
    [readButtonLabel]: 'Read',
    [writeButtonLabel]: 'Write',
    [licenseModelLabel]: 'License Model',
    [ipamProfileInputLabel]: 'IPAM Profile',
    [selectIpamProfilePlaceholder]: 'Select IPAM Profile',
    [dnsProfileInputLabel]: 'DNS Profile',
    [selectDnsProfilePlaceholder]: 'Select DNS Profile',
    [createIpamDnsProfileButtonLabel]: 'Create IPAM/DNS Profile',
    [selectDataCenterInputLabel]: 'Select a Data Center',
    [dataCenterInputLabel]: 'Data Center',
    [selectDataCenterInputPlaceholder]: 'Select a Data Center',
    [systemIpAddressManagementSettingLabel]: 'System IP Address Management Setting',
    [defaultNetworkIpAddressManagementLabel]: 'Default Network IP Address Management',
    [dhcpEnabledCheckboxLabel]: 'DHCP Enabled',
    [ipv6AutoConfigurationCheckboxLabel]: 'IPv6 Auto Configuration',
    [virtualServicePlacementSettingsLabel]: 'Virtual Service Placement Settings',
    [preferStaticRoutesVsDirectlyConnectedNetworkLabel]: 'Prefer Static Routes vs Directly Connected Network',
    [useStaticRoutesForNetworkResolutionOfVipLabel]: 'Use Static Routes for Network Resolution of VIP',
    [selectManagementNetworkLabel]: 'Select Management Network',
    [managementNetworkInputLabel]: 'Management Network',
    [selectManagementNetworkInputPlaceholder]: 'Select Management Network',
    [serviceEngineLabel]: 'Service Engine',
    [ipAddressManagementNetworkLabel]: 'IP Address Management for Management Network',
    [ipSubnetInputLabel]: 'IP Subnet',
    [defaultGatewayLabel]: 'Default Gateway',
    [addStaticIpAddressPoolInputLabel]: 'Add Static IP Address Pool',
    [stateBasedDnsRegistrationCheckboxLabel]: 'State Based DNS Registration',
    [templateServiceEngineGroupInputLabel]: 'Template Service Engine Group',
    [templateSegGroupDropdownPlaceholder]: 'None',
    [createServiceEngineGroupLabel]: 'Create Service Engine Group',
    [nextBtnLabel]: 'Next',
    [editClouldHeader]: 'Edit Cloud: {0}',
    [newClouldHeader]: 'New Cloud: {0}',
    [saveBtnLabel]: 'Save',
    [completeBtnLabel]: 'Complete',
    [cancelBtnLabel]: 'Cancel',
    [retryBtnLabel]: 'Retry',
    [previousBtnLabel]: 'Previous',
    [useIp4DhcpCheckboxLabel]: 'Use IPv4 DHCP',
    [useIp6DhcpCheckboxLabel]: 'Use IPv6 DHCP',
    [enableVipStaticRoutesCheckboxLabel]: 'Enable VIP Static Routes',
    [preferStaticRoutesCheckboxLabel]: 'Prefer Static Routes',
    [licenseModelHeader]: 'License Model',
    [ipamProfileInputPlaceholder]: 'Select IPAM Profile',
    [serviceEngineHeader]: 'Service Engine',
    [openStackLoginHeader]: 'OpenStack Login',
    [keystoneAuthUrlLabel]: 'Keystone Auth URL',
    [allowSelfsignedCertificatesCheckboxLabel]: 'Allow Self-signed Certificates',
    [useKeystoneAuthCheckboxLabel]: 'Use Keystone Auth',
    [useInternalUrlEndpointsCheckboxLabel]: 'Use InternalURL Endpoints',
    [dnsProviderInputLabel]: 'DNS Provider',
    [dnsProviderInputPlaceholder]: 'Select DNS Provider',
    [managementNetworkHeader]: 'Management Network',
    [adminTenantInputLabel]: 'Admin Tenant (Project)',
    [adminTenantInputPlaceholder]: 'Admin Tenant',
    [regionNameInputLabel]: 'Region Name',
    [managementNetworkInputPlaceholder]: 'Network Name',
    [freeUnusedFloatingIpAddressCheckboxLabel]: 'Free Unused Floating IP Address',
    [mapAviAdminToCloudAdminCheckboxLabel]: 'Map Avi-Admin to Cloud-Admin',
    [serviceEngineOwnershipLabel]: 'Service Engine Ownership',
    [seWithinTenantContextRadioLabel]: 'Service Engine within Tenant Context',
    [seWithinAdminContextRadioLabel]: 'Service Engine within Admin Context',
    [serviceEngineImageFormatLabel]: 'Service Engine Image Format',
    [importAllOpenStackKeystoneTenantsCheckboxLabel]: 'Import All OpenStack Keystone Tenants',
    [virtualServicePlacementSettingsHeader]: 'Virtual Service Placement Settings',
    [preferStaticRoutesMessageCheckboxLabel]: 'Prefer Static Routes vs Directly Connected Network for Virtual Service Placement',
    [useStaticRoutesMessageCheckboxLabel]: 'Use Static Routes for Network Resolution of VIP for Virtual Service Placement',
    [advancedHeader]: 'Advanced',
    [useAdminUrlCheckboxLabel]: 'Use Admin URL',
    [antiAffinityCheckboxLael]: 'Anti Affinity',
    [externalNetworksCheckboxLabel]: 'External Networks',
    [configDriveRadioLabel]: 'Config Drive',
    [metadataSvcRadioLabel]: 'Metadata SVC',
    [thirdPartyIntegrationSettingsHeader]: '3rd Party Integration Settings',
    [integrationWithContrailVncCheckboxLabel]: 'Integration with Contrail VNC',
    [apiEndpointUrlInputLabel]: 'API Endpoint URL',
    [organizationInputLabel]: 'Organization',
    [vsdHostInputLabel]: 'VSD Host',
    [servicePortInputLabel]: 'Service Port',
    [sshUserInputLabel]: 'SSH User',
    [createSshUserLabel]: 'Create SSH User',
    [sshUserInputPlaceholder]: 'Select SSH User',
    [autoscalePollingIntervalInputLabel]: 'Autoscale Polling Interval',
    [secInnerText]: 'sec',
    [useStaticRouteForVipPlacementCheckboxLabel]: 'Use Static Route for VIP Placement',
    [useInbandManagementCheckboxLabel]: 'Use Inband Management',
    [directoryPathForSeUsageLabel]: 'Directory Path for Service Engine Usage',
    [autoSelectPathRadioLabel]: 'Auto-select path',
    [singlePathForAllLogsRadioLabel]: 'Single path for all logs',
    [separatePathsForSystemAndClientLogsRadioLabel]: 'Separate paths for System and Client logs',
    [pathForSystemClientLogsInputLabel]: 'Path for System and Client Logs',
    [pathForSystemLogsInputLabel]: 'Path for System Logs',
    [pathForSystemLogsInputPlaceholder]: 'Path',
    [sizeInputLabel]: 'Size',
    [gbInnerText]: 'GB',
    [pathForClientLogsInputLabel]: 'Path for Client Logs',
    [serverListHeader]: 'Server List',
    [hostIpAddressInputLabel]: 'Host IP Address',
    [seGroupInputLabel]: 'Service Engine Group',
    [seGroupInputPlaceholder]: 'Default-Group',
    [cleanupExistingSeConfigBtnLabel]: 'Cleanup existing Service Engine config',
    [verifyHostBtnLabel]: 'Verify Host',
    [removeHostBtnLabel]: 'Remove Host',
    [yesRadioLabel]: 'Yes',
    [noRadioLabel]: 'No',
    [inbandManagementLabel]: 'Inband Management',
    [numberOfCoresLabel]: 'Number of Cores',
    [allRadioLabel]: 'All',
    [customRadioLabel]: 'Custom',
    [memoryGbLabel]: 'Memory (GB)',
    [useCustomSystemClientLogPathsCheckboxLabel]: 'Use custom System and Client Log paths',
    [addNewServerLinkLabel]: '+ Add new server',
    [dnsProfileInputPlaceholder]: 'Select DNS Profile',
    [awsRegionInputLabel]: 'AWS Region',
    [awsRegionInputPlaceholder]: 'Region Name',
    [accessAwsThroughProxyCheckboxLabel]: 'Access AWS through Proxy',
    [proxyHostInputLabel]: 'Proxy Host',
    [proxyPortInputLabel]: 'Proxy Port',
    [proxyPortInputPlaceholder]: 'Port',
    [proxyUsernameInputLabel]: 'Proxy Username',
    [proxyUsernameInputPlaceholder]: 'Username',
    [proxyPasswordInputLabel]: 'Proxy Password',
    [proxyPasswordInputPlaceholder]: 'Password',
    [awsUserCredentialsHeader]: 'AWS User Credentials',
    [useAccessSecretKeyRadioLabel]: 'Use Access/Secret Key',
    [useAwsIdentityAccessManagementRadioLabel]: 'Use AWS Identity and Access Management (IAM) Roles of Controller',
    [accessKeyIdInputLabel]: 'Access Key ID',
    [secretAccessKeyInputLabel]: 'Secret Access Key',
    [useCrossAccountAssumeRoleCheckboxLabel]: 'Use Cross-Account AssumeRole',
    [iamAssumeRoleInputLabel]: 'IAM AssumeRole',
    [assumeRoleInputLabel]: 'AssumeRole',
    [registerVirtualServiceNamesLabel]: 'Register Virtual Service Names',
    [emptyRadioLabel]: 'None',
    [amazonRoute53RadioLabel]: 'Amazon Route 53',
    [awsVpcAndAvailabilityZonesHeader]: 'AWS VPC and Availability Zones',
    [vpcNameInputPlaceholder]: 'VPC Name',
    [freeUnusedElasticIpAddressCheckboxLabel]: 'Free Unused Elastic IP Address',
    [selectSeManagementNetworkLabel]: 'Select Service Engine Management Network for required Availability Zones',
    [availabilityZoneInputLabel]: 'Availability Zone',
    [availabilityZoneInputPlaceholder]: 'Availability Zone name',
    [seManagementNetworkInputLabel]: 'Service Engine Management Network',
    [seManagementNetworkInputPlaceholder]: 'Management Network',
    [removeAvailabilityZoneBtnLabel]: 'Remove Availability Zone',
    [addAvailabilityZoneLinkLabel]: ' + Add Availability Zone',
    [enableSimpleQueueServiceCheckboxLabel]: 'Enable Simple Queue Service (SQS) for Autoscale Groups Monitoring',
    [encryptionHeader]: 'Encryption',
    [otherSettingsHeader]: 'Other Settings',
    [asgPollingIntervalInputLabel]: 'ASG Polling Interval',
    [generalHeader]: 'General',
    [typeInputLabel]: 'Type',
    [ipamProviderInputLabel]: 'IPAM Provider',
    [ipamProviderInputPlaceholder]: 'Select IPAM Provider',
    [otherRadioLabel]: 'Other',
    [googleCloudUserCredentialsHeader]: 'Google Cloud User Credentials',
    [serviceEngineProjectIdInputLabel]: 'Service Engine Project ID',
    [serviceEngineProjectIdInputPlaceholder]: 'Service Engine Project Id',
    [controllerRnningInGcpMessage]: 'This can be used only if the controller is running in Google Cloud Platform and a Service Account is attached to the Controller Virtual Machine. This Service Account should have all the required permissions to create resources in Google Cloud Platform required by AVI.',
    [useControllerVirtualMachineServiceAccountCheckboxLabel]: 'Use Controller Virtual Machine Service Account',
    [googleCloudCredentialsInputLabel]: 'Google Cloud Credentials',
    [googleCloudCredentialsInputPlaceholder]: 'Select Credentials',
    [createCredentialsBtnLabel]: 'Create Credentials',
    [regionZonesHeader]: 'Region & Zones',
    [serviceEngineRegionInputLabel]: 'Service Engine Region',
    [serviceEngineRegionInputPlaceholder]: 'Region Name',
    [zonesInputLabel]: 'Zones',
    [zonesInputPlaceholder]: 'Availability Zone name',
    [networkHeader]: 'Network',
    [networkModeLabel]: 'Network Mode',
    [dedicatedManagementLabel]: 'Dedicated Management',
    [twoArmModeLabel]: 'Two-arm Mode',
    [vpcProjectIdLabel]: 'VPC Project ID',
    [vpcNetworkNameInputLabel]: 'VPC Network Name',
    [vpcSubnetNameInputLabel]: 'VPC Subnet Name',
    [firewallHeader]: 'Firewall',
    [firewallTargetTagsInputLabel]: 'Firewall Target Tags',
    [firewallTargetTagsInputPlaceholder]: 'Firewall Target Tag',
    [storageHeader]: 'Storage',
    [cloudStorageProjectIdInputLabel]: 'Cloud Storage Project ID',
    [cloudStorageBucketNameInputLabel]: 'Cloud Storage Bucket Name',
    [cloudStorageBucketNameInputPlaceholder]: 'Cloud Bucket Name',
    [encryptionKeysHeader]: 'Encryption Keys',
    [seImageEncryptionKeyIdInputLabel]: 'Service Engine Image Encryption Key ID',
    [seDiskEncryptionKeyIdInputLabel]: 'Service Engine Disk Encryption Key ID',
    [gcsBucketEncryptionKeyIdInputLabel]: 'GCS Bucket Encryption Key ID',
    [gcsObjectsEncryptionKeyIdInputLabel]: 'GCS Objects Encryption Key ID',
    [customTagsHeader]: 'Custom Tags',
    [vipAllocationHeader]: 'VIP Allocation',
    [routesRadioLabel]: 'Routes',
    [enableSubnetRoutesCheckboxLabel]: 'Enable Subnet Routes',
    [gcpCloudRouterNamesInputLabel]: 'GCP Cloud Router Names ({0})',
    [gcpCloudRouterNamesInputPlaceholder]: 'Router Name',
    [azureUserCredentialsHeader]: 'Azure User Credentials',
    [subscriptionIdInputLabel]: 'Subscription ID',
    [useAzureMsiAuthenticationCheckboxLabel]: 'Use Azure Managed Service Identity (MSI) Authentication',
    [azureCredentialsInputLabel]: 'Azure Credentials',
    [azureCredentialsInputPlaceholder]: 'Select Credentials',
    [locationAndNetworkHeader]: 'Location and Network',
    [locationInputLabel]: 'Location',
    [locationInputPlaceholder]: 'Select Location',
    [resourceGroupInputLabel]: 'Resource Group',
    [resourceGroupInputPlaceholder]: 'Select Resource Group',
    [vnetInputPlaceholder]: 'Select VNet',
    [serviceEngineNetworkInputLabel]: 'Service Engine Network',
    [serviceEngineNetworkInputPlaceholder]: 'Select Network',
    [useDedicatedManagementInterfaceCheckboxLabel]: 'Use Dedicated Management Interface',
    [subnetForManagementInterfaceInputLabel]: 'Subnet for Management Interface',
    [subnetForManagementInterfaceInputPlaceholder]: 'Select Subnet',
    [diskEncryptionHeader]: 'Disk Encryption',
    [diskEncryptionSetInputLabel]: 'Disk Encryption Set',
    [diskEncryptionSetInputPlaceholder]: 'Select Disk Encryption Set',
    [dnsResolverLabel]: 'DNS Resolver',
};
