/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './path-match.component.less';

/**
 * @ngdoc component
 * @name pathMatch
 * @description
 *     Component for displaying the path match
 * @param {Object} match - Match object.
 * @param {Function=} onDelete - Method to delete the match.
 * @param {boolean=} allowDelete - Showing remove button or not.
 * @param {boolean=} allowCustomString - Allow custom string in input field or not, true by default.
 */
class PathMatchController {
    constructor(dropDownUtils) {
        this.allowDelete = true;
        this.allowCustomString = true;
        this.stringOperationOptions = dropDownUtils.getEnumDropdownOptions('StringOperation');
    }

    /**
     * Returns a boolean value for allowing custom strings in the String Group dropdown. If
     * this.preventRegexCriteriaCustomMatch is true, returns true for non RegExp match criteria.
     */
    allowCustom() {
        const {
            match,
            allowCustomString,
            preventRegexCriteriaCustomMatch,
        } = this;

        if (!match || !allowCustomString) {
            return false;
        }

        if (preventRegexCriteriaCustomMatch) {
            const { match_criteria: criteria } = match;

            return criteria !== 'REGEX_DOES_NOT_MATCH' && criteria !== 'REGEX_MATCH';
        }

        return true;
    }
}

PathMatchController.$inject = [
    'dropDownUtils',
];

/**
 * @ngdoc component
 * @name pathMatch
 * @description
 *     Component for displaying the path match under tabs for VS policies.
 * @param {Object} match - PathMatch object.
 * @param {boolean=} allowDelete - Showing delete button or not.
 * @param {boolean=} allowCustomString - Allow custom string or not, true by default.
 * @param {Function=} onDelete - Method to delete the match.
 * @param {string[]} [unsupportedStringOperations=] - List of string operations options to hide. If
 *     not specified, displays all. Values taken from the StringOperation enum.
 * @param {boolean=} hideEditMatchCase - If true, does not render the Match Case checkbox.
 * @param {boolean=} preventRegexCriteriaCustomMatch - If true, don't allow custom strings for
 *     'REGEX_DOES_NOT_MATCH' and 'REGEX_MATCH' match criteria.
 */
angular.module('aviApp').component('pathMatch', {
    bindings: {
        match: '<',
        allowDelete: '<?',
        allowCustomString: '<?',
        onDelete: '&?',
        unsupportedStringOperations: '<?',
        hideEditMatchCase: '<?',
        preventRegexCriteriaCustomMatch: '<?',
    },
    controller: PathMatchController,
    templateUrl: 'src/components/applications/virtualservice/policy/path-match.component.html',
});
