/**
 * @module GslbModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { GslbGeoDbProfileModalComponent } from 'ng/modules/gslb';
import { AviPermissionResource } from 'generated-types';
import { GSLB_GEODB_PROFILE_ITEM_TOKEN } from 'ajs/modules/gslb/gslb.tokens';
import { GslbGeoDbProfile } from 'object-types';

export type TGslbGeoDbProfileCollection = typeof GslbGeoDbProfileCollection;

/**
 * @description GeoProfile Collection Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class GslbGeoDbProfileCollection extends Collection {
    public static ajsDependencies = [
        GSLB_GEODB_PROFILE_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'gslbgeodbprofile',
            windowElement: GslbGeoDbProfileModalComponent,
            objectType: GslbGeoDbProfile,
            permissionName: AviPermissionResource.PERMISSION_GSLBGEODBPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(GSLB_GEODB_PROFILE_ITEM_TOKEN);
    }

    /**
     * Only 1 row is allowed to create.
     */
    /** @override */
    public isCreatable(): boolean {
        return this.items.length < 1 && super.isCreatable();
    }
}
