/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('input', [
'secretStubStr', function(secretStubStr) {
    function link(scope, element, attrs, ngModel) {
        if (_.isUndefined(ngModel) || ngModel === null) {
            return;
        }

        /**
         * Handles password input focus event.
         */
        function handlePasswordFocus() {
            // 1) attributes of 'readonly' and 'ng-readonly="<aTruthyExpression>"' will both result
            // in 'readonly="readonly"' being added to <input> element after ng compilation
            // 2) 'disabled' and 'ng-disabled="<aTruthyExpression>"' dont't need to be handled since
            // focus event won't be triggered with them being set
            if (!attrs.readonly) {
                if (ngModel.$modelValue === secretStubStr) {
                    ngModel.$setViewValue(undefined);
                    ngModel.$render();
                }
            }
        }

        if (attrs.type === 'password') {
            element.on('focus', handlePasswordFocus);
        }

        /* We get modelValue as 'null' for numeric fields and "" for text/textarea fields.
        Since api's do not support null values,
        we do not include null and empty string fields in the request payload */

        ngModel.$viewChangeListeners.push(() => {
            if (ngModel.$modelValue === '' || ngModel.$modelValue === null) {
                ngModel.$setViewValue(undefined);
            }
        });

        scope.$on('$destroy', function() {
            element.off('focus', handlePasswordFocus);
        });
    }

    return {
        require: '?ngModel',
        restrict: 'E',
        link,
    };
}]);
