/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editErrorPageHeader = `${prefix}.editErrorPageHeader`;
export const newErrorPageHeader = `${prefix}.newErrorPageHeader`;
export const errorPageBodyFieldLabel = `${prefix}.errorPageBodyFieldLabel`;
export const indexInputLabel = `${prefix}.indexInputLabel`;
export const minutesInnerText = `${prefix}.minutesInnerText`;
export const enabledInputLabel = `${prefix}.enabledInputLabel`;
export const htmlBodyInputLabel = `${prefix}.htmlBodyInputLabel`;
export const redirectToUrlInputLabel = `${prefix}.redirectToUrlInputLabel`;
export const pageBodyInputPlaceholder = `${prefix}.pageBodyInputPlaceholder`;
export const redirectUrlInputPlaceholder = `${prefix}.redirectUrlInputPlaceholder`;
export const urlInputLabel = `${prefix}.urlInputLabel`;
export const statusCodesLabel = `${prefix}.statusCodesLabel`;
export const addPageButtonLabel = `${prefix}.addPageButtonLabel`;
export const generalLabel = `${prefix}.generalLabel`;
export const statusCodesPlaceholder = `${prefix}.statusCodesPlaceholder`;

export const ENGLISH = {
    [editErrorPageHeader]: 'Edit Error Page: {0}',
    [newErrorPageHeader]: 'Add Error Page: {0}',
    [errorPageBodyFieldLabel]: 'Error Page',
    [enabledInputLabel]: 'Enable Error Page',
    [htmlBodyInputLabel]: 'HTML Body',
    [redirectToUrlInputLabel]: 'Redirect to URL',
    [pageBodyInputPlaceholder]: 'Select Error Page Body',
    [redirectUrlInputPlaceholder]: 'Redirect URL',
    [statusCodesLabel]: 'Status Codes',
    [urlInputLabel]: 'URL',
    [generalLabel]: 'General',
    [statusCodesPlaceholder]: 'Enter Status Codes',
};
