/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { createAction, props } from '@ngrx/store';
import { ICloud } from 'generated-types';

const setCloud = createAction(
    '[InfraCloud] Set cloud',
    props<{ cloud: ICloud }>(),
);

export const InfraCloudActions = {
    setCloud,
};
