/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'avi-forms';
const componentName = 'avi-repeated-dropdowns-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const dropdownPlaceholder = `${prefix}.dropdownPlaceholder`;
export const columnTitleString = `${prefix}.columnTitleString`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [dropdownPlaceholder]: 'Select',
    [columnTitleString]: 'Value(s)',
    [removeButtonLabel]: 'Remove',
};
