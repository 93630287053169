/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AlertModule
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { ALERT_CONFIG_ITEM_TOKEN } from '../../alert.tokens';

/**
 * @description
 *
 *   AlertConfig collection
 *
 * @author Dhanashree Sathelkar
 */
export class AlertConfigCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'alertconfig',
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            // TODO: replace with Create modal once component is migrated AV-156964
            windowElement: 'adm-alertconfig-create',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(ALERT_CONFIG_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            alert_filter: [],
        };
    }
}

AlertConfigCollection.ajsDependencies = [
    ALERT_CONFIG_ITEM_TOKEN,
];
