/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component that is used to open editable item in a full modal.
 * @example
 *     <item-full-modal
 *         modalTitle="Modal Title"
 *         [item]="editable"
 *         [form]="form"
 *         class="some-class-name"
 *     >
 *         <form
 *             clrForm
 *             [clrLayout]="verticalLayout"
 *             #form="ngForm"
 *         >
 *           ...
 *         </form>
 *     </item-full-modal>
 * @author vgohil
 */

import {
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    QueryList,
    Type,
} from '@angular/core';

import {
    AviDismissChangesConfirmationComponent,
    AviDismissChangesWarningType,
} from 'ng/modules/dialog';

import { IHttpResponse } from 'angular';
import { NgForm } from '@angular/forms';
import { FullModalTabSectionComponent } from 'ng/shared/components';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { DialogService } from 'ng/modules/core/services/dialog.service';

import {
    IObjectGraphApiResponse,
    IReferencedObject,
    ReferencedObjectsGraphService,
} from 'ng/modules/full-modal/services/referenced-objects-graph';

import { ReferencedObjectsDialogComponent }
    // eslint-disable-next-line max-len
    from 'ng/modules/full-modal/components/referenced-objects-dialog/referenced-objects-dialog.component';

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

const { ...globalL10nKeys } = globalL10n;

@Component({
    selector: 'item-full-modal',
    templateUrl: './item-full-modal.component.html',
})
export class ItemFullModalComponent {
    /**
     * Dismiss changes confirmation dialog id.
     */
    public static aviDismissChangesConfirmationId = 'avi-dismiss-changes-id';

    /**
     * Referenced Objects dialog id.
     */
    public static referencedObjectsDialogId = 'referenced-objects-dialog-id';

    /**
     * Optional label for submit button.
     */
    @Input()
    public submitButtonText ?= this.l10nService.getMessage(globalL10nKeys.saveLabel);

    /**
     * Reference to form element.
     */
    @Input()
    public form: NgForm;

    /**
     * Title of the modal, typically `${objectType}`.
     */
    @Input()
    public modalTitle: string;

    /**
     * Editable item of type Item.
     */
    @Input()
    public item: Item;

    /**
     * Optional flag to disable tabs in item-full-modal.
     */
    @Input()
    public disableTabs ?= false;

    /**
     * Optional class name for item full-modal.
     */
    @Input()
    public modalClassName ?= '';

    /**
     * If true, check if object is being used in other objects.
     */
    @Input()
    public checkReferencedObjects ?= true;

    /**
     * Optional function for submitting the form.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Gets the list of projected FullModalTabSection components.
     */
    @ContentChildren(FullModalTabSectionComponent, { descendants: true })
    public tabSections: QueryList<FullModalTabSectionComponent>;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * True, if the Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly dialogService: DialogService,
        private readonly l10nService: L10nService,
        private readonly referencedObjectsGraphService: ReferencedObjectsGraphService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        this.isEditing = Boolean(itemId);
    }

    /**
     * Called when the user tries to cancel or exit out of the modal. If the data has been modified,
     * we show a 'Confirm discard' confirmation in case the user wants to save changes. Otherwise,
     * the cancel handler is called.
     */
    public handleCancelAttempt(): void {
        const { aviDismissChangesConfirmationId } = ItemFullModalComponent;

        if (this.item.modified()) {
            this.dialogService.add({
                id: aviDismissChangesConfirmationId,
                component: AviDismissChangesConfirmationComponent as Type<Component>,
                componentProps: {
                    warningType: AviDismissChangesWarningType.UNSAVED_CHANGES,
                    onConfirm: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                        this.handleCancel();
                    },
                    onClose: () => {
                        this.dialogService.remove(aviDismissChangesConfirmationId);
                    },
                },
            });
        } else {
            this.handleCancel();
        }
    }
    /**
     * Handler for onSubmit event.
     * If checkReferencedObjects is set to true, it will check if the item is being used
     * by any other item.
     */
    public handleSubmit(): void {
        if (this.checkForReferencedObjects) {
            const { objectName, id } = this.item;

            this.referencedObjectsGraphService
                .fetchObjectGraphs(objectName, id)
                .then(({ data }: IHttpResponse<IObjectGraphApiResponse>) => {
                    const { count, results } = data;

                    if (!count) {
                        this.submit();
                    } else {
                        this.openReferencedObjectsDialog(results, count);
                    }
                })
                .catch((errorObject: any) => {
                    const { data: error } = errorObject;

                    return Promise.reject(error);
                });
        } else {
            this.submit();
        }
    }

    /**
     * If true, fetch referenced objects and show referenced objects dialog.
     */
    public get checkForReferencedObjects(): boolean {
        return this.isEditing && this.item.modified() && this.checkReferencedObjects;
    }

    /**
     * Handler for cancelling or exiting out of the modal.
     */
    public handleCancel(): void {
        this.item.dismiss(true);
    }

    /**
     * Method to open referenced objects dialog.
     */
    private openReferencedObjectsDialog(
        referencedObjects: IReferencedObject[],
        referencedObjectsCount: number,
    ): void {
        const { referencedObjectsDialogId } = ItemFullModalComponent;

        this.dialogService.add({
            id: referencedObjectsDialogId,
            component: ReferencedObjectsDialogComponent as Type<Component>,
            componentProps: {
                objectItemName: this.item.getName(),
                referencedObjects,
                referencedObjectsCount,
                onSubmit: () => {
                    this.dialogService.remove(referencedObjectsDialogId);
                    this.submit();
                },
                onCancel: () => {
                    this.dialogService.remove(referencedObjectsDialogId);
                },
            },
        });
    }

    /**
     * Method to emit onSubmit event or submit modal form.
     */
    private submit(): void {
        this.onSubmit.observers.length > 0 ? this.onSubmit.emit(this.item) : this.item.submit();
    }
}
