/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Inject,
    Injectable,
    OnDestroy,
} from '@angular/core';

import {
    HttpMethod,
    HttpWrapper,
    THttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';
import {
    IVsLogListResponseData,
    IVsLogsGraphResponseData,
    IVsLogsParams,
    IVsLogsStateParams,
    TVsLogEntryResponseData,
    TVsLogsApiResponseData,
    TVsLogsConstantParams,
} from '../vs-logs.types';
import {
    buildUrlFromParams,
    convertStateToApiParams,
} from '../utils/vs-logs.utils';

const VS_LOGS_CONSTANT_PARAMS: TVsLogsConstantParams = {
    js_compat: true,
};

export const LOGS_API_URL = '/api/analytics/logs';

/**
 * Initial and max timeouts for logs API requests.
 */
const INITIAL_TIMEOUT = 2;
const MAX_TIMEOUT = 32;

/**
 * Max amount of API requests we make; if all logs are not returned by then,
 * show an error to the user.
 */
const MAX_REQUEST_COUNT = 20;

/**
 * @description Service used for API requests for the various VS Logs Components.
 * @author Alex Klyuev, Zhiqian Liu
 */
@Injectable()
export class VsLogsApiRequestService implements OnDestroy {
    private readonly httpWrapper: HttpWrapper;

    constructor(@Inject(HttpWrapper) HttpWrapperClass: THttpWrapper) {
        this.httpWrapper = new HttpWrapperClass();
    }

    /**
     * Download logs. Requesting API with download=true will automatically
     * download a CSV of the data.
     */
    public static downloadLogs(stateParams: IVsLogsStateParams): void {
        const params = convertStateToApiParams(stateParams, VS_LOGS_CONSTANT_PARAMS);

        params.download = true;

        if (params.end) {
            const endStringified = JSON.stringify(params.end);

            params.end = endStringified.slice(1, endStringified.length - 1);
        }

        // remove undefined parameters
        Object.entries(params).forEach(([param, value]) => {
            if (value === undefined) {
                delete params[param];
            }
        });

        const url = buildUrlFromParams(params);

        window.location.assign(url);
    }

    /**
     * Cancel pending requests if page is left.
     * @override
     */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelAllRequests();
    }

    /**
     * Get logs from API.
     * Request ID is used by the httpWrapper service to cancel previous requests
     * if a new one is made or if the page is left.
     */
    public requestLogs<
        T extends IVsLogListResponseData | IVsLogsGraphResponseData | TVsLogEntryResponseData
    >(
        stateParams: IVsLogsStateParams,
        requestId: string,
    ): Promise<T> {
        const params = convertStateToApiParams(stateParams, VS_LOGS_CONSTANT_PARAMS);

        return this.requestWithTimeoutIncrease<T>(params, requestId);
    }

    /**
     * Cancel request of a given requestId.
     */
    public cancelRequest(requestId: string): void {
        if (this.httpWrapper.requestsHash[requestId]) {
            this.httpWrapper.cancelRequest(requestId);
        }
    }

    /**
     * Method to make an API request to the logs endpoint and return data.
     */
    private async fetchApiData<T extends TVsLogsApiResponseData>(
        params: IVsLogsParams,
        requestId: string,
    ): Promise<T> {
        const { data } = await this.httpWrapper.request({
            url: LOGS_API_URL,
            method: HttpMethod.GET,
            params,
            requestId,
        });

        return data;
    }

    /**
     * Make logs requests with exponentially increasing timeout (up to 32),
     * until all data is returned or 20 requests have been made (whichever is sooner).
     */
    private async requestWithTimeoutIncrease<T extends TVsLogsApiResponseData>(
        params: IVsLogsParams,
        requestId: string,
        requestCount = 1,
    ): Promise<T> {
        // if all data isn't returned after 20 timeouts, handle as though API error
        if (requestCount === MAX_REQUEST_COUNT) {
            throw new Error('API timeout');
        }

        if (!params.timeout) {
            params.timeout = INITIAL_TIMEOUT;
        }

        const data = await this.fetchApiData<T>(params, requestId);

        if (data.percent_remaining === 0) {
            return data;
        } else {
            if (params.timeout < MAX_TIMEOUT) {
                params.timeout *= 2;
            }

            requestCount++;

            return this.requestWithTimeoutIncrease(params, requestId, requestCount);
        }
    }
}
