/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-detection-result-match-grid';
const prefix = `${moduleName}.${componentName}`;

export const classificationsLabelWithCount = `${prefix}.classificationLabelWithCount`;
export const addButtonLabel = `${prefix}.addButtonLabel`;
export const classificationLabel = `${prefix}.classificationLabel`;
export const classificationPlaceHolder = `${prefix}.classificationPlaceHolder`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [classificationsLabelWithCount]: 'Classifications ({0})',
    [classificationLabel]: 'Classification',
    [classificationPlaceHolder]: 'Select Classification',
};
