/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-top-hits-logs-list-modal';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleTimestamp = `${prefix}.columnTitleTimestamp`;
export const columnTitleClientIp = `${prefix}.columnTitleClientIp`;
export const columnTitlePath = `${prefix}.columnTitlePath`;
export const columnTitleRequest = `${prefix}.columnTitleRequest`;
export const columnTitleResponse = `${prefix}.columnTitleResponse`;
export const columnTitleLength = `${prefix}.columnTitleLength`;
export const columnTitleDuration = `${prefix}.columnTitleDuration`;
export const columnTitleTotalTime = `${prefix}.columnTitleTotalTime`;
export const modalHeader = `${prefix}.modalHeader`;
export const openLogsPageButtonLabel = `${prefix}.openLogsPageButtonLabel`;

export const ENGLISH = {
    [columnTitleTimestamp]: 'Timestamp',
    [columnTitleClientIp]: 'Client IP',
    [columnTitlePath]: 'Path',
    [columnTitleRequest]: 'Request',
    [columnTitleResponse]: 'Response',
    [columnTitleLength]: 'Length',
    [columnTitleDuration]: 'Duration',
    [columnTitleTotalTime]: 'Total Time',
    [modalHeader]: 'Displaying {0} logs',
    [openLogsPageButtonLabel]: 'Open in Logs',
};
