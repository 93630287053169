/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component, Input } from '@angular/core';
import { TooltipPosition } from '../../directives/avi-tooltip/avi-tooltip.constants';
import './avi-tooltip-content.component.less';

const componentName = 'avi-tooltip-content';
const caretClassNameHash = {
    [TooltipPosition.TOP_POSITION]: `${componentName}--top`,
    [TooltipPosition.TOP_RIGHT_POSITION]: `${componentName}--top-right`,
    [TooltipPosition.TOP_LEFT_POSITION]: `${componentName}--top-left`,
    [TooltipPosition.BOTTOM_LEFT_POSITION]: `${componentName}--bottom-left`,
    [TooltipPosition.BOTTOM_RIGHT_POSITION]: `${componentName}--bottom-right`,
    [TooltipPosition.BOTTOM_POSITION]: `${componentName}--bottom`,
};

/**
 * @description Component for tooltip content, displayed when an element is hovered/clicked.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-content',
    template: `
        <div
            class="avi-tooltip-content"
            [ngClass]="caretClassName"
        >
            <ng-content></ng-content>
        </div>
    `,
})
export class AviTooltipContentComponent {
    /**
     * TooltipPosition binding, used to determine the styling of the caret.
     */
    @Input('tooltipPosition')
    public set tooltipPosition(position: TooltipPosition) {
        this.caretClassName = caretClassNameHash[position];
    }

    /**
     * Class applied to add styling for a caret.
     */
    public caretClassName = caretClassNameHash[TooltipPosition.TOP_RIGHT_POSITION];
}
