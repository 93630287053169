/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createReducer,
    on,
} from '@ngrx/store';
import {
    setControllerInitialData,
} from './controller-initial-data.actions';
import {
    controllerInitialDataState,
    IControllerInitialDataState,
} from './controller-initial-data.state';

export const controllerInitialDataReducer = createReducer(
    controllerInitialDataState,
    on(
        setControllerInitialData,
        (state: IControllerInitialDataState, action): IControllerInitialDataState => {
            const {
                version: {
                    build = 0,
                    Date: date = '',
                    min_version: minVersion = '',
                    patch = '',
                    Product: product = '',
                    ProductName: productName = '',
                    Tag: tag = '',
                    Version: version = '',
                } = {},
                ...baseData
            } = action.controllerInitialData;

            return {
                build,
                date,
                min_version: minVersion,
                patch,
                product,
                product_name: productName,
                tag,
                version,
                ...baseData,
            };
        },
    ),
);
