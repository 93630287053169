/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../waf-log-entry.less';
import '../waf-logs-list.less';

/**
 * @constructor
 * @memberOf module:avi/logs
 * @mixes module:avi/logs.wafAllowlistLogsListBindings
 * @see {@link  module:avi/logs.wafAllowlistLogsListComponent}
 */
class WafAllowlistLogsController {
    constructor() {
        /**
         * Expanded state. Used for expanding/collapsing all.
         */
        this.expanded = false;
    }

    /**
     * Click handler for expanding/collapsing all.
     */
    toggleExpand() {
        this.expanded = !this.expanded;
    }
}

/**
 * @name wafAllowlistLogsListComponent
 * @memberOf module:avi/logs
 * @property {module:avi/logs.wafAllowlistLogsListBindings} bindings
 * @description Component for a list of WAF Allowlist Logs.
 * @author alextg
 */
angular.module('avi/logs').component('wafAllowlistLogsList', {
    /**
     * @mixin wafAllowlistLogsListBindings
     * @memberOf module:avi/logs
     * @property {Object[]} logs - Array of allowlist log entries.
     */
    bindings: {
        logs: '<',
    },
    controller: WafAllowlistLogsController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-logs/waf-logs/' +
        'waf-allowlist-logs-list/waf-allowlist-logs-list.component.html',
});
