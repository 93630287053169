/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    HardwareSecurityModuleConfigItem,
    HSMGroup,
    HSMSafenetClientInfoConfigItem,
    HSMSafenetLunaServerConfigItem,
} from 'ajs/modules/security';

import {
    IAviDropdownOption,
} from 'ng/shared/components';

import { TStringRow } from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { HSMType } from 'generated-types';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { ClrFormLayout } from '@clr/angular';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';
import './hsm-group-modal.component.less';
import * as l10n from './hsm-group-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for Create/Edit HSM Group.
 * @author vgohil
 */
@Component({
    selector: 'hsm-group-modal',
    templateUrl: './hsm-group-modal.component.html',
})
export class HSMGroupModalComponent implements OnInit {
    /**
     * HSMGroup ObjectTypeItem.
     */
    @Input()
    public editable: HSMGroup;

    /**
     * HSMGroup object type.
     */
    public objectType: string;

    /**
     * HSM message item.
     */
    public hsmConfigItem: HardwareSecurityModuleConfigItem;

    /**
     * Layout for HSM Group modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * HSM type dropdown options.
     */
    public readonly hsmTypeOptions: IAviDropdownOption[] = [];

    /**
     * Hash of HSM type enum.
     */
    public readonly HSMType = {
        [HSMType.HSM_TYPE_AWS_CLOUDHSM]: HSMType.HSM_TYPE_AWS_CLOUDHSM,
        [HSMType.HSM_TYPE_SAFENET_LUNA]: HSMType.HSM_TYPE_SAFENET_LUNA,
    };

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        const hsmTypes = schemaService.getEnumValues('HSMType');

        this.hsmTypeOptions = createOptionsFromEnumProps(hsmTypes);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.hsmConfigItem = this.editable.config.hsm;
        this.handleTypeChange(true);
    }

    /**
     * Calls onTypeChange on HSMGroup and loads Thales Luna HSM nodes when applicable.
     */
    public handleTypeChange(initial = false): void {
        const { editable: hsmGroup } = this;

        if (!hsmGroup.id && hsmGroup.getType() === HSMType.HSM_TYPE_SAFENET_LUNA) {
            hsmGroup.populateSLunaNodesList();
        }

        if (!initial) {
            hsmGroup.onTypeChange();
        }
    }

    /**
     * Returns true if current type matches a specific type.
     */
    public isHsmType(type: HSMType): boolean {
        return this.editable.getType() === type;
    }

    /**
     * Opens child modal to add new Thales Luna HSM Server Message Item.
     */
    public onAddSafenetLunaServer(): void {
        this.hsmConfigItem.safenetLuna.addServer();
    }

    /**
     * Handles remove Thales Luna HSM Server Message Item.
     */
    public onRemoveSafenetLunaServer(safenetLunaServer: HSMSafenetLunaServerConfigItem): void {
        this.hsmConfigItem.safenetLuna.removeServer(safenetLunaServer);
    }

    /**
     * Handles edit Thales Luna HSM Server Item.
     */
    public onEditSafenetLunaServer(safenetLunaServer: HSMSafenetLunaServerConfigItem): void {
        this.hsmConfigItem.safenetLuna.editServer(safenetLunaServer);
    }

    /**
     * Handles the change of use_dedicated_network checkbox.
     */
    public onUseDedicatedNetworkChange(): void {
        this.editable.populateSLunaNodesList();
    }

    /**
     * Handles add new Thales Luna HSM ClientInfo Message Item.
     */
    public onAddClientInfo(): void {
        this.hsmConfigItem.safenetLuna.addClientInfo();
    }

    /**
     * Handles remove Thales Luna HSM ClientInfo Message Item.
     */
    public onRemoveClientInfo(clientInfo: HSMSafenetClientInfoConfigItem): void {
        this.hsmConfigItem.safenetLuna.removeClientInfo(clientInfo);
    }

    /**
     * Handles add new cloudhsm HSM IP Address.
     */
    public onAddHsmIp(): void {
        this.hsmConfigItem.cloudHsm.addHsmIpString();
    }

    /**
     * Handles remove cloudhsm HSM IP Addresses.
     */
    public onRemoveHsmIps(hsmIpStrings: TStringRow[]): void {
        this.hsmConfigItem.cloudHsm.removeHsmIpStrings(hsmIpStrings);
    }
}
