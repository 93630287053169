/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './ServerInfoController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc controller
 * @name ServerInfoController
 */
class ServerInfoController {
    constructor($scope, l10nService) {
        $scope.l10nKeys = l10nKeys;
        l10nService.registerSourceBundles(dictionary);
    }
}

ServerInfoController.$inject = [
    '$scope',
    'l10nService',
];

angular.module('aviApp').controller('ServerInfoController', ServerInfoController);
