/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name Anomaly
 * @description
 *
 *     Extends {@link Item}. Differs by tuple, id and collection metrics API methods since this
 *     update channel is used only by {@link AnomalyCollection} and significantly differs from
 *     other Item types.
 *
 */
angular.module('aviApp').factory('Anomaly', ['UpdatableItem',
function(UpdatableItem) {
    class Anomaly extends UpdatableItem {
        /** @override */
        getIdFromData_(data) {
            return [
                data.timestamp,
                data.metricId,
                data.entity_uuid,
                data.obj_id,
                data.obj_id_type,
            ].join('-');
        }

        /**
         * Anomaly Item can request only one timeseries, which id is stored in {@link
         * Anomaly#data#metricId}. And these timeseries differ for each particular Item within one
         * Collection.
         * @override
         */
        getCollMetricsRequests() {
            return super.getCollMetricsRequests([this.data.metricId]);
        }

        /**
         * Metric data is retrieved through getMetric method.
         * @override
         **/
        saveMetricData_() {}

        /** @override */
        getConfig() {
            return this.data || null;
        }

        /**
         * Since we use anomalies mostly for the grid view we keep only one metric with special
         * id regardless of actual series it is using.
         * @override
         **/
        getMetric(mName) {
            if (mName === 'anomaly_metric') {
                return _.sample(this.collMetricsHash) || null;
            }

            return super.getMetric(mName);
        }

        /** @override */
        getMetricsTuple() {
            const { data } = this;

            if (data) {
                const {
                    entity_uuid: entityUuid,
                    server,
                    pool_uuid: poolUuid,
                    obj_id_type: objIdType,
                } = data;

                switch (objIdType) {
                    case 'SERVER':
                        return {
                            entity_uuid: '*',
                            aggregate_entity: true,
                            pool_uuid: poolUuid,
                            obj_id: server,
                        };

                    case 'POOL':
                        return {
                            entity_uuid: '*',
                            aggregate_entity: true,
                            pool_uuid: poolUuid,
                        };

                    default:
                        return {
                            entity_uuid: entityUuid,
                        };
                }
            }

            return null;
        }
    }

    Anomaly.prototype.objectName = 'Anomaly';

    return Anomaly;
}]);
