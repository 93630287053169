/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'cloud';

export const dnsResolverModalBreadcrumbTitle = `${prefix}.dnsResolverModalBreadcrumbTitle`;
export const cloudModalBreadcrumbTitle = `${prefix}.cloudModalBreadcrumbTitle`;
export const linuxHostServerBreadcrumbTitle = `${prefix}.linuxHostServerBreadcrumbTitle`;

export const ENGLISH = {
    [dnsResolverModalBreadcrumbTitle]: 'DNS Resolver',
    [cloudModalBreadcrumbTitle]: 'Cloud',
    [linuxHostServerBreadcrumbTitle]: 'Server',
};
