/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'geoloc-select';
const prefix = `${moduleName}.${componentName}`;

export const geoLocationSourceInputLabel = `${prefix}.geoLocationSourceInputLabel`;
export const selectGeoLocationSourceInputPlaceholder = `${prefix}.selectGeoLocationSourceInputPlaceholder`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const tagInputLabel = `${prefix}.tagInputLabel`;
export const latitudeInputLabel = `${prefix}.latitudeInputLabel`;
export const longitudeInputLabel = `${prefix}.longitudeInputLabel`;

export const ENGLISH = {
    [geoLocationSourceInputLabel]: 'Geo Location Source',
    [selectGeoLocationSourceInputPlaceholder]: 'Select Geo Location Source',
    [nameInputLabel]: 'Name',
    [tagInputLabel]: 'Tag',
    [latitudeInputLabel]: 'Latitude',
    [longitudeInputLabel]: 'Longitude',
};
