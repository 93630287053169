/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'accounts';

export const tenantModalBreadcrumbTitle = `${prefix}.tenantModalBreadcrumbTitle`;
export const userProfileModalBreadcrumbTitle = `${prefix}.userProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [tenantModalBreadcrumbTitle]: 'Tenant',
    [userProfileModalBreadcrumbTitle]: 'User Profile',
};
