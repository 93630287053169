/**
 * @module Logs
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import {
    IAction,
    IApplyLogRecommendations,
    IGetLogRecommendations,
    IRecommendation,
    IRecommendationRequest,
    IRecommendationResponse,
    RecommendationStatus,
} from 'generated-types';
import * as globalL10n from 'global-l10n';

import { ClrLoadingState } from '@cds/core/button';
import { IHttpResponse } from 'angular';
import { L10nService } from '@vmw/ngx-vip';
import { RecommendationDialogService } from '../../services/recommendation-dialog.service';
import './recommendation-dialog.component.less';
import * as l10n from './recommendation-dialog.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component used for showing WAF logs recommendation.
 * @author vgohil
 */
@Component({
    selector: 'recommendation-dialog',
    templateUrl: 'recommendation-dialog.component.html',
})
export class RecommendationDialogComponent implements OnInit {
    /**
     * Method to close the dialog.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    /**
     * Object to make recommendation request.
     */
    @Input()
    private readonly recommendationRequest: IRecommendationRequest;

    /**
     * Current virtualservice uuid value.
     */
    @Input()
    private readonly vsUuid: string;

    /**
     * Flag use to show spinner while fetching recommendation
     * And, to enable/disable the Accept Recommendation button.
     */
    public busy = true;

    /**
     * Hold the error to be displayed if an error occurs after API call.
     */
    public error: string | undefined;

    /**
     * Hold the list of recommendations from API response.
     */
    public recommendationList: IRecommendation[];

    /**
     * Hold the status of recommendations from API response.
     */
    public recommendationStatus: RecommendationStatus;

    /**
     * Loading state for Apply Recommendation button. Set to 'loading' when submitting the request.
     */
    public loadingState: ClrLoadingState = ClrLoadingState.default;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly recommendationDialogService: RecommendationDialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const { recommendationDialogService, recommendationRequest, vsUuid } = this;
        const recommendationRequests: IGetLogRecommendations = {
            requests: [recommendationRequest],
        };

        this.busy = true;
        this.error = undefined;
        recommendationDialogService.fetchRecommendation(recommendationRequests, vsUuid)
            .then(({ data }: IHttpResponse<IRecommendationResponse>) => {
                this.recommendationList = data.recommendations;
                this.recommendationStatus = data.status;
            })
            .catch(({ data }) => this.error = data.error)
            .finally(() => this.busy = false);
    }

    /**
     * Called when user confirms accepting the recommendation.
     */
    public handleSubmit(): void {
        const { recommendationDialogService, recommendationList, vsUuid } = this;
        const actionsList: IAction[] = [];

        recommendationList.forEach((item: IRecommendation) => {
            actionsList.push(...item.actions);
        });

        const applyRecommendations: IApplyLogRecommendations = {
            actions: actionsList,
        };

        this.error = undefined;
        this.loadingState = ClrLoadingState.loading;
        recommendationDialogService.applyRecommendation(applyRecommendations, vsUuid)
            .then(() => this.handleCancel())
            .catch(({ data }) => this.error = data.error)
            .finally(() => this.loadingState = ClrLoadingState.default);
    }

    /**
     * Called when user decides not to accept the recommendation.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Method to check if recommendation status is GENERATED.
     */
    public isRecommendationGenerated(): boolean {
        return !this.busy &&
            this.recommendationStatus === RecommendationStatus.RECOMMENDATION_GENERATED;
    }

    /**
     * Method to check if recommendation status is ALREADY_APPLIED.
     */
    public isRecommendationAlreadyApplied(): boolean {
        return !this.busy &&
            this.recommendationStatus === RecommendationStatus.RECOMMENDATION_ALREADY_APPLIED;
    }

    /**
     * Method to check if recommendation status is FAILED.
     */
    public isRecommendationFailed(): boolean {
        return !this.busy &&
            this.recommendationStatus === RecommendationStatus.RECOMMENDATION_FAILED;
    }

    /**
     * Method to check if recommendation status is LOG_NOT_AVAILABLE.
     */
    public isRecommendationLogNotAvailable(): boolean {
        return !this.busy &&
            this.recommendationStatus === RecommendationStatus.RECOMMENDATION_LOG_NOT_AVAILABLE;
    }
}
