/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function vmNicIPCollectionFactory(
    Collection,
    VMNicIP,
) {
    const dataSources = {
        list: {
            source: 'ListCollDataSource',
            transformer: 'VMNicIPDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
    };

    const fields = [
        'name',
        'vs_lb_weight',
        'guest_nic.mac_addr',
        'guest_nic.network_name',
        'guest_nic.guest_ip',
        'powerstate',
        'guest_nic.network_uuid',
    ].join();

    /**
     * @constructor
     * @extends module:avi/dataModel.Collection
     * @memberOf module:avi/network
     * @author Alex Malitsky
     * @desc
     *
     *     List of VM NIC IP objects.
     *
     *     Used by "select server by network" feature of Pool and Basic VS modals.
     *
     * @see {@link module:avi/network.VMNicIP}
     * @see {@link module:avi/network.VMNicIPDataTransformer}
     */
    class VMNicIPCollection extends Collection {
        constructor(args = {}) {
            const { params = {}, ...restArgs } = args;
            const extendedArgs = {
                ...restArgs,
                params: {
                    fields,
                    ...params,
                },
            };

            super(extendedArgs);
        }

        /** @override */
        isCreatable() {
            return false;
        }
    }

    Object.assign(VMNicIPCollection.prototype, {
        objectName_: 'serversbynetwork',
        itemClass_: VMNicIP,
        sortBy_: 'name',
        allDataSources_: dataSources,
    });

    return VMNicIPCollection;
}

vmNicIPCollectionFactory.$inject = [
    'Collection',
    'VMNicIP',
];

angular.module('avi/network')
    .factory('VMNicIPCollection', vmNicIPCollectionFactory);
