/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bot-detection-policy-logs-expander.l10n';
import template from './bot-detection-policy-logs-expander.component.html';
import './bot-detection-policy-log-expander.component.less';

export const BOT_DETECTION_LOG_LIST_EXPANDER_TOKEN = 'botDetectionLogListExpander';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component when Bot Detection Logs are drilled down to next level.
 * @author Sarthak Kapoor
 */
class BotDetectionLogListExpanderComponent {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: { [str: string]: string }) => void;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }
}

BotDetectionLogListExpanderComponent.$inject = [
    'l10nService',
];

export const botDetectionLogListExpanderComponentOptions = {
    bindings: {
        botLog: '<',
        updateSearch: '&',
    },
    controller: BotDetectionLogListExpanderComponent,
    template,
};
