/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'launch-config';
const componentName = 'launch-config-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameLabel = `${prefix}.nameLabel`;
export const namePlaceholder = `${prefix}.namePlaceholder`;
export const useExternalAsgLabel = `${prefix}.useExternalAsgLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Launch Config',
    [modalTitleNew]: 'New Launch Config',
    [sectionTitleGeneral]: 'General',
    [nameLabel]: 'Name',
    [namePlaceholder]: 'Enter Name',
    [useExternalAsgLabel]: 'Use External ASG',
};
