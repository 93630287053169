/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'string-or-group-list';
const prefix = `${moduleName}.${componentName}`;

export const addStringGroupLinkLabel = `${prefix}.addStringGroupLinkLabel`;
export const addStringGroupCustomStringLinkLabel = `${prefix}.addStringGroupCustomStringLinkLabel`;
export const orLabel = `${prefix}.orLabel`;
export const enterStringInputPlaceholder = `${prefix}.enterStringInputPlaceholder`;
export const enterCustomStringInputPlaceholder = `${prefix}.enterCustomStringInputPlaceholder`;
export const selectStringGroupInputPlaceholder = `${prefix}.selectStringGroupInputPlaceholder`;
export const removeItemTitle = `${prefix}.removeItemTitle`;
export const matchDecodedStringLabel = `${prefix}.matchDecodedStringLabel`;

export const ENGLISH = {
    [addStringGroupLinkLabel]: '+ Add string group',
    [addStringGroupCustomStringLinkLabel]: '+ Add string group or custom string',
    [orLabel]: 'or',
    [enterStringInputPlaceholder]: 'Enter string or select string group',
    [enterCustomStringInputPlaceholder]: 'Enter String',
    [selectStringGroupInputPlaceholder]: 'Select String Group',
    [removeItemTitle]: 'Remove Item',
    [matchDecodedStringLabel]: 'Match Decoded String',
};
