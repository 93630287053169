/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name LogRecordCollDataSource
 * @description
 *
 *     Differs from regular ListCollDataSource by bunch of convenience methods around
 *     this.filters_ and setting timeline properties on owner/collection.
 *
 *     Selected timeframe defines duration param.
 */
angular.module('aviApp').factory('LogRecordCollDataSource', [
'EventListCollDataSource', 'Timeframe',
function(EventListCollDataSource, Timeframe) {
    return class LogRecordCollDataSource extends EventListCollDataSource {
        constructor(args) {
            angular.extend(args, {
                defaultFields: [{
                    id: 'config',
                    preserved: true,
                    subscribers: ['__default_field'],
                }],
                defaultParams: {
                    type: 1,
                    timeout: 2,
                },
            });

            super(args);

            this.setDurationParam_();

            this.onTimeframeChange_ = this.onTimeframeChange_.bind(this);

            Timeframe.on('change', this.onTimeframeChange_);

            /**
             * Multiple filters (one value per every property name) can be applied to API calls.
             * @type {{string: *}}
             * @private
             */
            this.filters_ = {};
        }

        /**
         * Generates `filter` param value based on active filters.
         * @override
         **/
        getRequestParams_() {
            const params = super.getRequestParams_();

            params['virtualservice'] = this.owner_.vsId;

            const filters = [];

            _.each(this.filters_, (value, filterType) => {
                filters.push(`eq(${filterType},"${value}")`);
            });

            if (filters.length) {
                if ('filter' in params) {
                    params['filter'].push(...filters);
                } else {
                    params['filter'] = filters;
                }
            }

            return params;
        }

        /** @override */
        processConfigResponse_(...args) {
            super.processConfigResponse_(...args);
            this.timelineCalc_(args[0]);
        }

        /**
         * Passes received timeline params to the collection.
         * @param {Object} data
         * @private
         */
        timelineCalc_({ data }) {
            const { timeline } = this.owner_;

            timeline.maxDuration = data.maxDuration;
            timeline.maxTimestamp = moment.utc(data.maxTimestamp);
            timeline.minTimestamp = moment.utc(data.minTimestamp);
        }

        /**
         * Sets duration param on Timeframe change event.
         * @protected
         */
        setDurationParam_() {
            const { range } = Timeframe.selected();

            this.params_['duration'] = range;
        }

        /**
         * Updates this.filters_ property with received hash. This is normally a union operation
         * but if ALL passed filters are active on the time of the call it will implement
         * deselect operation.
         * @param {{string: *}} filtersHash
         * @param {boolean=} patch
         * @public
         */
        setFilters(filtersHash, patch = true) {
            if (!patch) {
                this.removeFilters_();
            }

            if (!filtersHash || _.isEmpty(filtersHash)) {
                return;
            }

            const
                { filters_ } = this,
                deselection = patch && this.isFilterActive(filtersHash);

            _.each(filtersHash, (value, type) => {
                if (!deselection && !angular.isUndefined(value)) {
                    filters_[type] = value;
                } else {
                    delete filters_[type];
                }
            });
        }

        /**
         * Removes all user defined filters.
         * @private
         */
        removeFilters_() {
            this.filters_ = {};
        }

        /**
         * Checks whether all passed filters are present (active) on an instance.
         * @param {{string: *}} filtersHash
         * @returns {boolean}
         * @public
         */
        isFilterActive(filtersHash) {
            if (!filtersHash || _.isEmpty(filtersHash)) {
                return _.isEmpty(this.filters_);
            }

            const { filters_ } = this;

            return _.every(filtersHash,
                (value, type) => filters_[type] === value);
        }

        /**
         * Returns a copy of filters hash.
         * @returns {{string: *}}
         */
        getActiveFiltersHash() {
            return angular.copy(this.filters_);
        }

        /**
         * Timeframe change event listener.
         * @protected
         */
        onTimeframeChange_() {
            this.reset();
            this.setDurationParam_();
        }

        /** @override */
        reset() {
            super.reset();
            this.removeFilters_();
        }

        /** @override */
        destroy(force) {
            const gotDestroyed = super.destroy(force);

            if (gotDestroyed) {
                Timeframe.unbind('change', this.onTimeframeChange_);
            }

            return gotDestroyed;
        }
    };
}]);
