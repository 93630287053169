/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import { StringOperation } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IMatchOption } from 'ng/modules/match/components/match-adder/match-adder.component';
import { HdrMatchComponent } from 'ng/modules/match/components/hdr-match/hdr-match.component';
import { PortMatchComponent } from 'ng/modules/match/components/port-match/port-match.component';

import {
    MatchTarget,
    VHMatchRule,
} from 'object-types';

import {
    VHMatchRuleConfigItem,
} from 'ajs/modules/vs/factories/vh-match-rule.config-item.factory';

import {
    PathOrQueryMatchComponent,
} from 'ng/modules/match/components/path-or-query-match/path-or-query-match.component';

import {
    HttpMethodMatchComponent,
} from 'ng/modules/match/components/http-method-match/http-method-match.component';

import {
    ProtocolTypeMatchComponent,
} from 'ng/modules/match/components/protocol-type-match/protocol-type-match.component';

import {
    HttpVersionMatchComponent,
} from 'ng/modules/match/components/http-version-match/http-version-match.component';

import * as globalL10n from 'global-l10n';
import * as l10n from './vh-match-rule-modal.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Virtual Hosting Match Rule.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'vh-match-rule-modal',
    templateUrl: './vh-match-rule-modal.component.html',
})
export class VHMatchRuleModalComponent implements OnInit {
    @Input()
    public editable: VHMatchRuleConfigItem;

    /**
     * Indicate if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Existing rule names used in current match config.
     * Pass rule names to the directive which detects duplicate string values.
     */
    @Input()
    public ruleNames: string[] = [];

    /**
     * Current rule index in current match config
     * Pass rule index to the directive which detects duplicate string values.
     */
    @Input()
    public ruleIndex: number;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<VHMatchRuleConfigItem>();

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Options list for all possible VHMatch options.
     */
    public matchOptions: IMatchOption[] = [];

    public readonly objectType = VHMatchRule;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.setVHMatchOptions();
    }

    /**
     * Triggers on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Triggers on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Configures options for match-adder component.
     */
    private setVHMatchOptions(): void {
        const { l10nService } = this;
        const unsupportedPathMatchStringOperations = [
            StringOperation.DOES_NOT_BEGIN_WITH,
            StringOperation.CONTAINS,
            StringOperation.DOES_NOT_CONTAIN,
            StringOperation.ENDS_WITH,
            StringOperation.DOES_NOT_END_WITH,
            StringOperation.DOES_NOT_EQUAL,
            StringOperation.REGEX_DOES_NOT_MATCH,
        ];

        this.matchOptions = [
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.pathMatchLabel),
                objectType: MatchTarget,
                componentBindings: {
                    hiddenValues: unsupportedPathMatchStringOperations,
                    defaultStringOperation: StringOperation.BEGINS_WITH,
                },
                fieldName: 'path',
            },
            {
                component: HdrMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.headersLabel),
                objectType: MatchTarget,
                fieldName: 'hdrs',
            },
            {
                component: PortMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.portLabel),
                objectType: MatchTarget,
                fieldName: 'vs_port',
            },
            {
                component: HttpMethodMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.httpMethodLabel),
                objectType: MatchTarget,
                fieldName: 'method',
            },
            {
                component: ProtocolTypeMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.protocolLabel),
                objectType: MatchTarget,
                fieldName: 'protocol',
            },
            {
                component: HttpVersionMatchComponent as Type<Component>,
                label: l10nService.getMessage(globalL10nKeys.httpVersionLabel),
                objectType: MatchTarget,
                fieldName: 'version',
            },
        ];
    }
}
