/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { ElementRef, Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { pipe } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { VsLogsStore } from '../../../services/vs-logs.store';
import { VsLogsEffectsService } from '../../../services/vs-logs-effects.service';
import {
    VsLogsSearchBarDropdownService,
} from '../../../services/vs-logs-search-bar-dropdown.service';
import {
    IVsLogsFullGroupbyParams,
    IVsLogsGroupbyResponseData,
    IVsLogsGroupbyResponseResultData,
    TVsLogsPageStateParams,
} from '../../../vs-logs.types';

const VS_FILTER_GROUPBY_REQUEST_ID = 'VS_FILTER_GROUPBY_REQUEST';

const defaultGroupbyParams = {
    orderby: '-count',
};

/**
 * Enum for the 3 types of options the dropdown can provide.
 * Corresponds to the keys of TFilterObj.
 */
export enum DropdownOptionType {
    PROPERTY,
    OPERATOR,
    VALUE,
}

/**
 * @param fullString - full string that is currently being edited in the input.
 * @param property - filter property, e.g. 'method'.
 * @param operator - filter operator, e.g. '='.
 * @param groupbyValues - groupby data for the current filter property.
 */
interface IInputFilterProps {
    fullString: string;
    property: string;
    operator: string;
    groupbyValues: IVsLogsGroupbyResponseResultData[];
}

/**
 * @param inputFilter
 *  Several filters can exist in the search bar but only one is edited at a time.
 *  These are the props of the current filter being edited in the input.
 * @param dropdownOptionType - current type of options that the dropdown is suggesting.
 * @param isLoading - flag to indicate a request for the search bar is in progress.
 * @param isFilterBeingEdited - flag to indicate whether a filter is being edited.
 * @param hasError - whether there is an error loading groupby values.
 */
interface IStateTypes {
    inputFilter: IInputFilterProps
    dropdownOptionType: DropdownOptionType;
    isLoading: boolean;
    isFilterBeingEdited: boolean;
    hasError: boolean;
}

const initialState: IStateTypes = {
    inputFilter: {
        fullString: '',
        property: '',
        operator: '',
        groupbyValues: [],
    },
    dropdownOptionType: DropdownOptionType.PROPERTY,
    isLoading: false,
    isFilterBeingEdited: false,
    hasError: false,
};

/**
 * @description
 *      The VsLogsStore is the source of truth for filters.
 *      The search bar component reads filter state from the VsLogsStore
 *      and updates it according to search bar interaction.
 *
 *      This store manages state for any filter being typed or edited by the user
 *      and the dropdown.
 *
 * @author Alex Klyuev
 */
@Injectable()
export class VsLogsSearchBarStore extends ComponentStore<IStateTypes> {
    /**
     * Reference to the primary filter input.
     */
    public primaryFilterInput: ElementRef<HTMLInputElement>;

    // ********************************** Selectors **********************************

    public readonly inputFilterValue$ = this.select(state => state.inputFilter.fullString);

    public readonly inputFilterProperty$ = this.select(state => state.inputFilter.property);

    public readonly inputFilterOperator$ = this.select(state => state.inputFilter.operator);

    public readonly dropdownOptionType$ = this.select(state => state.dropdownOptionType);

    public readonly groupbyValues$ = this.select(state => state.inputFilter.groupbyValues);

    public readonly isLoading$ = this.select(state => state.isLoading);

    public readonly isFilterBeingEdited$ = this.select(state => state.isFilterBeingEdited);

    public readonly hasError$ = this.select(state => state.hasError);

    // ********************************** Effects **********************************

    /**
     * Add a filter to the central VS Logs store from the search bar.
     */
    public readonly addFilter = this.effect(
        VsLogsEffectsService.createSelectorTapEffect(
            this.inputFilterValue$,
            inputFilterValue => {
                // ignore if input is empty
                if (inputFilterValue) {
                    this.vsLogsStore.addFilterQueryStrings(inputFilterValue);
                }

                this.resetSearchBar();
            },
        ),
    );

    /**
     * Edit an existing filter in the central VS Logs store.
     */
    public readonly editFilter = this.effect<number>(
        index$ => index$.pipe(
            withLatestFrom(this.inputFilterValue$),
            tap(([index, inputFilterValue]: [number, string]) => {
                // if new filter is empty, remove it altogether
                if (!inputFilterValue) {
                    this.vsLogsStore.removeFilter(index);
                } else {
                    this.vsLogsStore.editFilterQueryString(index, inputFilterValue);
                }

                this.setIsFilterBeingEdited(false);
                this.editIndex = null;
                this.resetSearchBar();
            }),
        ),
    );

    /**
     * Handle backspace event.
     * Remove last added filter if main search input is empty.
     * (Does not apply for editable filters).
     */
    public readonly handleBackspace = this.effect<void>(pipe(
        withLatestFrom(this.inputFilterValue$, this.vsLogsStore.filters$),
        tap((valuesContainer: [void, string, string[]]) => {
            const inputFilterValue = valuesContainer[1];
            const filters = valuesContainer[2];

            if (filters.length !== 0 && !inputFilterValue) {
                this.vsLogsStore.removeFilter(filters.length - 1);
            }
        }),
    ));

    /**
     * Effects to increment or decrement the dropdown stage.
     */
    public readonly incrementDropdownStage = this.effect(
        VsLogsEffectsService.createSelectorTapEffect(
            this.dropdownOptionType$,
            type => this.updateDropdownStage(type + 1),
        ),
    );

    public readonly decrementDropdownStage = this.effect(
        VsLogsEffectsService.createSelectorTapEffect(
            this.dropdownOptionType$,
            type => this.updateDropdownStage(type - 1),
        ),
    );

    /**
     * Make a groupby request based on current input filter property.
     */
    public readonly requestGroupbyValues: () => void;

    // ********************************** Updaters **********************************

    public readonly setInputFilterValue = this.updater((state, inputFilterValue: string) => ({
        ...state,
        inputFilter: {
            ...state.inputFilter,
            fullString: inputFilterValue,
        },
    }));

    public readonly setInputFilterProperty = this.updater((state, property: string) => ({
        ...state,
        inputFilter: {
            ...state.inputFilter,
            property,
        },
    }));

    public readonly setInputFilterOperator = this.updater((state, operator: string) => ({
        ...state,
        inputFilter: {
            ...state.inputFilter,
            operator,
        },
    }));

    public readonly setIsFilterBeingEdited = this.updater(
        (state, isFilterBeingEdited: boolean) => ({
            ...state,
            isFilterBeingEdited,
        }),
    );

    private readonly setDropdownOptionType = this.updater(
        (state, dropdownOptionType: DropdownOptionType) => ({
            ...state,
            dropdownOptionType,
        }),
    );

    private readonly setGroupbyValues = this.updater(
        (state, groupbyValues: IVsLogsGroupbyResponseResultData[]) => ({
            ...state,
            inputFilter: {
                ...state.inputFilter,
                groupbyValues,
            },
        }),
    );

    private readonly setIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    private readonly setHasError = this.updater((state, hasError: boolean) => ({
        ...state,
        hasError,
    }));

    /**
     * Index of filter if filter is being edited.
     */
    private editIndex: number;

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        private readonly vsLogsSearchBarDropdownService: VsLogsSearchBarDropdownService,
        vsLogsEffectsService: VsLogsEffectsService,
    ) {
        super(initialState);

        // Initialize request effect
        const requestGroupbyValuesRequestEffect = this.effect<TVsLogsPageStateParams>(
            vsLogsEffectsService.createVsLogsRequestEffect(
                VS_FILTER_GROUPBY_REQUEST_ID,
                this.startLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiComplete,
            ),
        );

        this.requestGroupbyValues = this.effect(
            groupbyParams$ => groupbyParams$.pipe(
                withLatestFrom(
                    this.vsLogsStore.vsLogsPageParams$,
                    this.inputFilterProperty$,
                    this.isFilterBeingEdited$,
                ),
                tap((valuesContainer: [void, TVsLogsPageStateParams, string, boolean]) => {
                    let groupbyParams = valuesContainer[1];
                    const inputFilterProperty = valuesContainer[2];
                    const isFilterBeingEdited = valuesContainer[3];

                    // If filter is being edited, ignore that filter when making
                    // the groupby request
                    if (isFilterBeingEdited) {
                        // Make new objects to avoid modifying state
                        groupbyParams = { ...groupbyParams };
                        groupbyParams.filters = [...groupbyParams.filters];
                        groupbyParams.filters.splice(this.editIndex, 1);
                    }

                    const fullParams: IVsLogsFullGroupbyParams = {
                        ...defaultGroupbyParams,
                        ...groupbyParams,
                        groupby: inputFilterProperty,
                    };

                    requestGroupbyValuesRequestEffect(fullParams);
                }),
            ),
        );
    }

    // ********************************** Methods **********************************

    public resetInputFilterProperty(): void {
        this.setInputFilterProperty('');
    }

    public resetInputFilterOperator(): void {
        this.setInputFilterOperator('');
    }

    public resetGroupbyValues(): void {
        this.setGroupbyValues([]);
    }

    /**
     * Focus the primary filter input.
     */
    public focusPrimaryInput(): void {
        this.primaryFilterInput.nativeElement.focus();
    }

    /**
     * Set params for filters that are being edited.
     */
    public setEdit(index: number): void {
        this.setIsFilterBeingEdited(true);
        this.editIndex = index;
    }

    /**
     * Update the dropdown stage.
     */
    private updateDropdownStage(type: DropdownOptionType): void {
        this.setDropdownOptionType(type);
        this.vsLogsSearchBarDropdownService.changeDropdownStage();
    }

    /**
     * Close the dropdown and reset the search bar state.
     */
    private resetSearchBar(): void {
        this.vsLogsSearchBarDropdownService.closeDropdown();
        this.setState(initialState);
    }

    /**
     * Set isLoading to true upon API request.
     */
    private startLoading = (): void => {
        this.setHasError(false);
        this.setIsLoading(true);
    };

    /**
     * Set the groupby data to state upon successful API request.
     */
    private handleApiData = (data: IVsLogsGroupbyResponseData): void => {
        this.setGroupbyValues(data.results);
    };

    /**
     * Set the error state and an empty list if an error occurs during groupby request.
     */
    private handleApiError = (): void => {
        this.setGroupbyValues([]);
        this.setHasError(true);
        this.setIsLoading(false);
    };

    /**
     * Set isLoading to false upon API request complete.
     */
    private handleApiComplete = (): void => {
        this.setIsLoading(false);
    };
}
