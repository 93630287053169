/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';
import { IDnsCnameRdata, IDnsInfo } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import * as l10n from '../dns.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TDnsInfoPartial = Omit<IDnsInfo, 'cname'>;

interface IDnsInfoConfig extends TDnsInfoPartial {
    cname?: MessageItem<IDnsCnameRdata>
}

/**
 * Ajs dependency token for DnsInfoConfigItem.
 */
export const DNS_INFO_CONFIG_ITEM_TOKEN = 'DnsInfoConfigItem';

/**
 * @description DnsInfoConfig ConfigItem class.
 * @author Ram Pal, alextsg
 */
export class DnsInfoConfigItem extends withFullModalMixin(MessageItem)<IDnsInfoConfig> {
    public static ajsDependencies = [
        'DNS',
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'DnsInfo',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Sets the fqdn field of the DnsInfoConfig object.
     */
    public setFqdn(fqdn: string): void {
        this.config.fqdn = fqdn;
    }

    /**
     * Returns DnsInfoConfig#fqdn.
     * @return {string}
     */
    public getFqdn(): string {
        return this.config.fqdn;
    }

    /**
     * Getter for DnsInfoConfig#fqdn.
     */
    public get fqdn(): string {
        return this.getFqdn() || '';
    }

    /**
     * Setter for DnsInfoConfig#fqdn.
     */
    public set fqdn(fqdn: string) {
        this.setFqdn(fqdn);
    }

    /**
     * Looks up a domain to get the list of IP addresses it resolves to.
     */
    public domainLookup(domain: string): Promise<unknown> {
        const DNS = this.getAjsDependency_('DNS');

        return DNS.singleLookup(domain);
    }

    /**
     * @override
     * If the fqdn string is not present, the entire DnsInfoConfig object should not be part
     * of the save request payload, so return undefined.
     */
    public canFlatten(): boolean {
        return Boolean(this.config.fqdn);
    }

    /**
     * @override
     * Import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { DnsInfoModalComponent } = await import(
            /* webpackChunkName: "vs-vip-modal" */
            'ng/lazy-loaded-components/modals/vs-vip-modal/dns-info-modal/dns-info-modal.component'
        );

        return DnsInfoModalComponent as Type<Component>;
    }

    /**
     * @override
     */
    protected getModalBreadcrumbDescription(): string {
        return this.config.fqdn || '';
    }

    /**
     * @override
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.dnsModalBreadcrumbTitle);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'cname',
        ];
    }

    /** @override */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        if (isEmpty(this.config.cname) || !this.config.cname.config.cname) {
            delete this.config.cname;
        }
    }
}
