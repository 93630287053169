/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/pages/administration/traffic-capture.less';
import * as l10n from './VSTrafficCaptureController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('VSTrafficCaptureController', [
'AsyncFactory',
'VSTrafficCapture',
'AviModal',
'Auth',
'VSTrafficCaptureConfig',
'l10nService',
function(
    AsyncFactory,
    VSTrafficCapture,
    AviModal,
    Auth,
    VSTrafficCaptureConfig,
    l10nService,
) {
    const vm = this;

    vm.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    const asyncHash = {
        pollTrafficCapture: null,
        pollPreparingCapture: null,
    };

    this.$onInit = function() {
        this.capturingTraffic = false;
        this.preparingCapture = false;

        this.currentCaptureConfig = {};
        this.progress = null;
        this.downloadableCaptures = [];

        this.errors = null;

        asyncHash.pollPreparingCapture = new AsyncFactory(function() {
            vm.preparingCapture = true;
            vm.errors = null;

            return VSTrafficCapture.getCurrentCapture()
                .then(function(rsp) {
                    if (Array.isArray(rsp.data.results) && !rsp.data.results.length) {
                        asyncHash.pollPreparingCapture.stop();
                        vm.preparingCapture = false;
                        reset();
                    }
                }).catch(function(rsp) {
                    vm.errors = rsp.data.error;
                    asyncHash.pollPreparingCapture.stop();
                    vm.preparingCapture = false;
                    reset();
                });
        });

        getDownloadableCaptures();
        getCurrentCapture();
    };

    /**
     * Public properties.
     */
    this.gridConfig = {
        id: 'downloadable-captures-list',
        fields: [{
            name: 'modified',
            title: l10nService.getMessage(l10nKeys.columnTitleDate),
            sortBy: 'modified',
            template: '<span>{{ row.modified | aviDate }}</span>',
        }, {
            name: 'name',
            title: l10nService.getMessage(l10nKeys.columnTitleVirtualServiceName),
        }, {
            name: 'size',
            title: l10nService.getMessage(l10nKeys.columnTitleSize),
            transform(row) {
                return Math.formatBytes(row.size);
            },
        }],
        rowId(row) {
            return row.url;
        },
        searchFields: ['modified', 'name'],
        layout: {
            hideSearch: false,
            hideDisplaying: false,
        },
        multipleactions: [{
            title: l10nService.getMessage(l10nKeys.actionBtnDelete),
            do(rows) {
                VSTrafficCapture.deleteRows(rows)
                    .then(function() {
                        getDownloadableCaptures();
                    });

                return true;
            },
        }],
        singleactions: [{
            title: l10nService.getMessage(l10nKeys.actionBtnDownload),
            class: 'icon-download-1',
            do(row) {
                VSTrafficCapture.download(row.url);
            },
        }],
        checkboxDisable() {
            return !Auth.isPrivilegeAllowed('PERMISSION_TRAFFIC_CAPTURE', 'WRITE_ACCESS');
        },
    };

    /**
     * Opens modal when Edit button is clicked.
     * @public
     */
    this.editSettings = function() {
        AviModal.open('virtualservice-traffic-capture-config', {
            onSuccess: getCurrentCapture,
            captureConfig: new VSTrafficCaptureConfig(),
        });
    };

    /**
     * Switch to stop current ongoing capture. Can only be switched from 'on' to 'off'.
     * @public
     */
    this.toggleSwitch = function() {
        if (vm.currentCaptureConfig && vm.currentCaptureConfig.uuid) {
            VSTrafficCapture.stopCapture(vm.currentCaptureConfig);

            if (asyncHash.pollTrafficCapture && asyncHash.pollTrafficCapture.isActive()) {
                asyncHash.pollTrafficCapture.stop();
            }

            asyncHash.pollPreparingCapture.start(5000);
        }
    };

    /**
     * @inner
     * Gets list of downloadable captures.
     */
    function getDownloadableCaptures() {
        VSTrafficCapture.getDownloadableCaptures()
            .then(function(rsp) {
                vm.downloadableCaptures = rsp.data.results;
            });
    }

    /**
     * @inner
     * Checks if there is an ongoing traffic capture in progress. If there is, call pollProgress
     * to create a polling function for its progress.
     */
    function getCurrentCapture() {
        VSTrafficCapture.getCurrentCapture()
            .then(function(rsp) {
                [vm.currentCaptureConfig] = rsp.data.results;

                if (vm.currentCaptureConfig && vm.currentCaptureConfig.capture) {
                    pollProgress(vm.currentCaptureConfig.uuid);
                }
            });
    }

    /**
     * @inner
     * @param {string} uuid - Virtual Service uuid.
     * Polls for traffic capture progress.
     * Don't stop polling if there is an error, as the error indicates something that the user can
     * fix while the capture is running.
     * When complete, stops polling for progress and starts polling to check if the capture file
     * is ready for download.
     */
    function pollProgress(uuid) {
        vm.capturingTraffic = true;
        vm.errors = null;

        asyncHash.pollTrafficCapture = new AsyncFactory(function() {
            return VSTrafficCapture.getProgress(uuid)
                .then(function(rsp) {
                    vm.progress = rsp.data;
                    vm.errors = null;

                    if (VSTrafficCapture.isComplete(vm.progress)) {
                        vm.capturingTraffic = false;
                        asyncHash.pollTrafficCapture.stop();
                        asyncHash.pollPreparingCapture.start(5000);
                    }
                }).catch(function(rsp) {
                    vm.errors = rsp.data.error;
                });
        });

        asyncHash.pollTrafficCapture.start(5000);
    }

    /**
     * @inner
     * Cancels all async polling.
     */
    function cancelPolling() {
        _.each(asyncHash, function(asyncInstance, key) {
            if (asyncInstance && asyncInstance.isActive()) {
                asyncInstance.stop();
            }
        });
    }

    /**
     * @inner
     * Clears current capture configuration and progress, then checks for downloadable captures.
     */
    function reset() {
        vm.currentCaptureConfig = {};
        vm.progress = null;

        getDownloadableCaptures();
    }

    /**
     * Cancels requests on $destroy event.
     */
    this.$onDestroy = function() {
        VSTrafficCapture.cancelRequests();
        cancelPolling();
    };
}]);
