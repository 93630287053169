/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { isString, isUndefined } from 'underscore';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { IFileServiceAttachment } from 'ng/modules/cportal/components/add-attachments-grid';
import { Observable } from 'rxjs';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { CrashReportsStore } from './crash-reports-page.store';
import './crash-reports-page.component.less';
import * as l10n from './crash-reports-page.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description component for crash-reports-page.
 * @author Suraj Kumar
 */
@Component({
    selector: 'crash-reports-page',
    templateUrl: './crash-reports-page.component.html',
    providers: [CrashReportsStore],
})
export class CrashReportsPageComponent {
    /*
    * Datagrid template for expanded ContentTemplate.
    */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Crash Report Page FileNames grid config.
     */
    public crashReportsGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly crashReportsStore: CrashReportsStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.fetchCrashReportsList();
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.crashReportsGridConfig = {
            getRowId: (index: number): number => index,
            expandedContentTemplateRef: this.expandedContentTemplateRef,
            expanderDisabled(row) {
                const { stack_trace: trace } = row;

                return isUndefined(trace) || trace === '';
            },
            fields: [{
                id: 'name',
                label: l10nService.getMessage(l10nKeys.columnTitleFilename),
                transform: row => this.getNameFromUrl(row.url),
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.downloadBtnLabel),
                shape: 'download',
                onClick: row => window.open(row.url, '_blank'),
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                onClick: rows => this.deleteRows(rows),
            }],
            searchKeys: ['name'],
        };
    }

    /**
     * Getter method for getting observable of rows from store.
     */
    public get rows$(): Observable<IFileServiceAttachment[]> {
        return this.crashReportsStore.rows$;
    }

    /**
     * Getter method for getting isLoading state from store.
     */
    public get isLoading(): boolean {
        return this.crashReportsStore.isLoading;
    }

    /**
     * Fetches name string from the url.
     */
    private getNameFromUrl(url: string): string {
        return isString(url) ? url.slice(url.lastIndexOf('/') + 1) : '';
    }

    /**
     * Deletes entries from the crashReportPage grid.
     */
    private deleteRows(rows: IFileServiceAttachment[]): void {
        const fileNames = rows.map((row: IFileServiceAttachment) => this.getNameFromUrl(row.url));

        this.crashReportsStore.deleteEntries(fileNames);
    }

    /**
     * Fetches list of entries for the crashReportPage grid.
     */
    private fetchCrashReportsList(): void {
        this.crashReportsStore.fetchList();
    }
}
