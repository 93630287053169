/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { ITenantConfiguration } from 'generated-types';
import { TenantConfiguration } from 'object-types';

import './tenancy-mode-config.component.less';
import * as l10n from './tenancy-mode-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for Tenancy Mode config in System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'tenancy-mode-config',
    templateUrl: './tenancy-mode-config.component.html',
})
export class TenancyModeConfigComponent implements OnInit {
    /**
     * Tenant Config Instance.
     */
    @Input()
    public tenantConfig: ITenantConfiguration;

    /**
     * Boolean flag to check if SE is in provider context.
     */
    public isSeInProviderContext: boolean;

    /**
     * Object type for tempate usage.
     */
    public readonly objectType = TenantConfiguration;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setter for isSeInProviderContext is called.
     */
    public ngOnInit(): void {
        this.setIsSeInProviderContext();
    }

    /**
     * Sets boolean flag isSeInProviderContext.
     */
    public setIsSeInProviderContext(): void {
        this.isSeInProviderContext = this.tenantConfig.se_in_provider_context;
    }
}
