/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Angular Build, Upgrade/downgrade stuff
 * @module Angular-Core
 * @preferred
 */

import { Injector } from '@angular/core';
import { IpamProfileCollection } from 'ajs/modules/ipam';
import {
    Auth,
    AviConfirmService,
    AviModalService,
    DefaultValues,
    InitialDataService,
    MyAccount,
    SchemaService,
    StateManager,
    StringService,
} from 'ajs/modules/core/services';

import {
    HttpWrapper,
    Upload,
} from 'ajs/modules/core/factories';

import { VCenterServerCollection } from 'ajs/modules/cloud';
import { PoolGroupCollection } from 'ajs/modules/pool';
import {
    WafApplicationSignatureProvider,
    WafApplicationSignatureProviderCollection,
    WafPolicyPsmGroupCollection,
    WafPolicyPsmGroupMetric,
} from 'ajs/modules/waf';

import {
    PlacementNetworkConfigItem,
    PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN,
    PoolGroupDeploymentPolicy,
    PoolGroupDeploymentPolicyCollection,
} from 'ajs/modules/pool/factories';

import {
    POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN,
    POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
} from 'ajs/modules/pool/pool.tokens';

import { LicensingService } from 'ajs/modules/licensing';
import {
    BackupConfiguration,
    BackupConfigurationCollection,
    SystemConfig,
} from 'ajs/modules/system';

import { Cloud } from 'ajs/modules/cloud/factories/cloud.item.factory';
import { CLOUD_ITEM_TOKEN } from 'ajs/modules/cloud/cloud.tokens';
import { MacroStackFactory } from 'ajs/modules/data-model/factories/macro-stack.factory';
import { CportalService } from 'ajs/modules/cportal/services';
import { FaultService } from 'ajs/modules/notifications';
import { InfraCloudState } from 'ajs/modules/vrf-context/services/infra-cloud-state.service';
import { VRFContextCollection } from
    'ajs/modules/vrf-context/factories/vrf-context.collection.factory';
import {
    INFRA_CLOUD_STATE_SERVICE_TOKEN,
    VRF_CONTEXT_COLLECTION_TOKEN,
} from 'ajs/modules/vrf-context/vrf-context.tokens';
import { SubnetListNetwork, SubnetListNetworkCollection } from 'ajs/modules/network';
import {
    StateParams,
    TransitionService,
} from '@uirouter/core';

import {
    GeoDbCollection,
    GEO_DB_COLLECTION_TOKEN,
} from 'ajs/modules/geo-db/factories/geo-db.collection.factory';

import {
    ErrorPageBodyCollection,
    TErrorPageBodyCollection,
} from 'ajs/modules/error-page/factories/error-page-body.collection.factory';

import {
    IPAM_PROFILE_COLLECTION_TOKEN,
    IPAM_PROFILE_ITEM_TOKEN,
} from 'ajs/modules/ipam/ipam.tokens';

import { IpamProfileItem } from 'ajs/modules/ipam/factories/ipam-profile.item.factory';

import {
    VRFContext,
    VRF_CONTEXT_TOKEN,
} from 'ajs/modules/vrf-context';

import { AutoScalePolicyCollection } from 'ajs/modules/auto-scale';
import {
    CertificateCollection,
    PKIProfileCollection,
    SSLProfileCollection,
} from 'ajs/modules/security';

import { PersistenceProfileCollection } from 'ajs/modules/persistence-profile';
import { PASSWORD_GENERATOR_SERVICE_TOKEN } from 'ajs/modules/accounts/accounts.tokens';
import { SECRET_STUB_STRING } from 'ajs/modules/core/core.tokens';

import {
    PasswordGeneratorService,
} from 'ajs/modules/accounts/services/password-generator-service/password-generator.service';

import { ERROR_PAGE_BODY_COLLECTION_TOKEN } from 'ajs/modules/error-page/error-page.tokens';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

const $injector = '$injector';

/**
 * Used to upgrade providers from AngularJS to be used in Angular.
 * @param serviceName Service to be injected from AngularJS.
 */
export function matchTargetConfigItemFactory(injector: Injector): any {
    return injector.get('MatchTargetConfigItem');
}

export const matchTargetConfigItemProvider = {
    deps: [$injector],
    provide: 'MatchTargetConfigItem',
    useFactory: matchTargetConfigItemFactory,
};

export const schemaServiceProvider = {
    deps: [$injector],
    provide: SchemaService,
    useFactory(injector: Injector): SchemaService {
        return injector.get('schemaService');
    },
};

export const alertConfigCollectionProvider = {
    deps: [$injector],
    provide: 'AlertConfigCollection',
    useFactory(injector: Injector): any {
        return injector.get('AlertConfigCollection');
    },
};

export const aviModalProvider = {
    deps: [$injector],
    provide: AviModalService,
    useFactory(injector: Injector): AviModalService {
        return injector.get('AviModal');
    },
};

export const aviConfirmServiceProvider = {
    deps: [$injector],
    provide: AviConfirmService,
    useFactory(injector: Injector): AviConfirmService {
        return injector.get('AviConfirmService');
    },
};

export const wafPolicyPsmGroupCollectionProvider = {
    deps: [$injector],
    provide: WafPolicyPsmGroupCollection,
    useFactory(injector: Injector): typeof WafPolicyPsmGroupCollection {
        return injector.get('WafPolicyPsmGroupCollection');
    },
};

export const wafApplicationSignatureProviderCollectionProvider = {
    deps: [$injector],
    provide: WafApplicationSignatureProviderCollection,
    useFactory(injector: Injector): typeof WafApplicationSignatureProviderCollection {
        return injector.get('WafApplicationSignatureProviderCollection');
    },
};

export const wafApplicationSignatureProviderProvider = {
    deps: [$injector],
    provide: WafApplicationSignatureProvider,
    useFactory(injector: Injector): typeof WafApplicationSignatureProvider {
        return injector.get('WafApplicationSignatureProvider');
    },
};

export const PoolGroupDeploymentPolicyCollectionProvider = {
    deps: [$injector],
    provide: PoolGroupDeploymentPolicyCollection,
    useFactory(injector: Injector): typeof PoolGroupDeploymentPolicyCollection {
        return injector.get(POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN);
    },
};

export const PoolGroupDeploymentPolicyProvider = {
    deps: [$injector],
    provide: PoolGroupDeploymentPolicy,
    useFactory(injector: Injector): typeof PoolGroupDeploymentPolicy {
        return injector.get(POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN);
    },
};

export const PlacementNetworkConfigItemProvider = {
    deps: [$injector],
    provide: PlacementNetworkConfigItem,
    useFactory(injector: Injector): typeof PlacementNetworkConfigItem {
        return injector.get(PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN);
    },
};

export const alertMetricsCollectionProvider = {
    deps: [$injector],
    provide: 'alertMetricsCollection',
    useFactory(injector: Injector): any {
        return injector.get('AlertMetricsCollection');
    },
};

export const wafPolicyPsmGroupMetricProvider = {
    deps: [$injector],
    provide: WafPolicyPsmGroupMetric,
    useFactory(injector: Injector): typeof WafPolicyPsmGroupMetric {
        return injector.get('WafPolicyPsmGroupMetric');
    },
};

export function timeframeFactory(injector: Injector): any {
    return injector.get('Timeframe');
}

export const timeframeProvider = {
    deps: [$injector],
    provide: 'Timeframe',
    useFactory: timeframeFactory,
};

export function appStateTreeFactory(injector: Injector): any {
    return injector.get('appStateTree');
}

export const appStateTreeProvider = {
    deps: [$injector],
    provide: 'appStateTree',
    useFactory: appStateTreeFactory,
};

export function $stateFactory(injector: Injector): any {
    return injector.get('$state');
}

export const $stateProvider = {
    deps: [$injector],
    provide: '$state',
    useFactory: $stateFactory,
};

export const $stateParamsProvider = {
    deps: [$injector],
    provide: '$stateParams',
    useFactory(injector: Injector): StateParams {
        return injector.get('$stateParams');
    },
};

export const $transitionsProvider = {
    deps: [$injector],
    provide: '$transitions',
    useFactory(injector: Injector): TransitionService {
        return injector.get('$transitions');
    },
};

export function upgradeNodeCollectionFactory(injector: Injector): any {
    return injector.get('UpgradeNodeCollection');
}

export const UpgradeNodeCollectionProvider = {
    deps: [$injector],
    provide: 'UpgradeNodeCollection',
    useFactory: upgradeNodeCollectionFactory,
};

export function upgradeServiceFactory(injector: Injector): any {
    return injector.get('upgradeService');
}

export const UpgradeServiceProvider = {
    deps: [$injector],
    provide: 'upgradeService',
    useFactory: upgradeServiceFactory,
};

export function licensingServiceFactory(injector: Injector): LicensingService {
    return injector.get('licensingService');
}

export const licensingServiceProvider = {
    deps: [$injector],
    provide: LicensingService,
    useFactory: licensingServiceFactory,
};

export const cloudConnectorUserCollectionProvider = {
    deps: [$injector],
    provide: 'CloudConnectorUserCollection',
    useFactory(injector: Injector): any {
        return injector.get('CloudConnectorUserCollection');
    },
};

export const cloudCollectionProvider = {
    deps: [$injector],
    provide: 'CloudCollection',
    useFactory(injector: Injector): any {
        return injector.get('CloudCollection');
    },
};

export const myAccountProvider = {
    deps: [$injector],
    provide: MyAccount,
    useFactory(injector: Injector): MyAccount {
        return injector.get('myAccount');
    },
};

export const ipamProfileCollectionProvider = {
    deps: [$injector],
    provide: IpamProfileCollection,
    useFactory(injector: Injector): typeof IpamProfileCollection {
        return injector.get(IPAM_PROFILE_COLLECTION_TOKEN);
    },
};

export const vCenterServerCollectionProvider = {
    deps: [$injector],
    provide: VCenterServerCollection,
    useFactory(injector: Injector): typeof VCenterServerCollection {
        return injector.get('VCenterServerCollection');
    },
};

export const authServiceProvider = {
    deps: [$injector],
    provide: Auth,
    useFactory(injector: Injector): Auth {
        return injector.get('Auth');
    },
};

export const poolGroupCollectionProvider = {
    deps: [$injector],
    provide: PoolGroupCollection,
    useFactory(injector: Injector): typeof PoolGroupCollection {
        return injector.get('PoolGroupCollection');
    },
};

export const stringServiceProvider = {
    deps: [$injector],
    provide: StringService,
    useFactory(injector: Injector): StringService {
        return injector.get('stringService');
    },
};

function initialDataService(injector: Injector): InitialDataService {
    return injector.get('initialDataService');
}

export const initialDataServiceProvider = {
    deps: [$injector],
    provide: InitialDataService,
    useFactory: initialDataService,
};

export const stateManagerServiceProvider = {
    deps: [$injector],
    provide: StateManager,
    useFactory($injector: Injector): StateManager {
        return $injector.get('stateManagerService');
    },
};

export const uploadProvider = {
    deps: [$injector],
    provide: Upload,
    useFactory(i: Injector): typeof Upload {
        return i.get('Upload');
    },
};

export const macroStackProvider = {
    deps: [$injector],
    provide: MacroStackFactory,
    useFactory(injector: Injector): typeof MacroStackFactory {
        return injector.get('MacroStackFactory');
    },
};

export const systemConfigProvider = {
    deps: [$injector],
    provide: SystemConfig,
    useFactory(injector: Injector): typeof SystemConfig {
        return injector.get('SystemConfig');
    },
};

export const backupConfigItemProvider = {
    deps: [$injector],
    provide: BackupConfiguration,
    useFactory(injector: Injector): typeof BackupConfiguration {
        return injector.get('BackupConfiguration');
    },
};

export const backupConfigCollectionProvider = {
    deps: [$injector],
    provide: BackupConfigurationCollection,
    useFactory(injector: Injector): typeof BackupConfigurationCollection {
        return injector.get('BackupConfigurationCollection');
    },
};

export const faultServiceProvider = {
    deps: [$injector],
    provide: FaultService,
    useFactory(injector: Injector): typeof FaultService {
        return injector.get('FaultService');
    },
};

export const defaultValuesProvider = {
    deps: [$injector],
    provide: DefaultValues,
    useFactory(injector: Injector): typeof DefaultValues {
        return injector.get('defaultValues');
    },
};

export const cloudItemProvider = {
    deps: [$injector],
    provide: Cloud,
    useFactory(injector: Injector): typeof Cloud {
        return injector.get(CLOUD_ITEM_TOKEN);
    },
};

export const systemInfoServiceProvider = {
    deps: [$injector],
    provide: 'systemInfoService',
    useFactory(injector: Injector): any {
        return injector.get('systemInfoService');
    },
};

export const cportalServiceProvider = {
    deps: [$injector],
    provide: CportalService,
    useFactory(injector: Injector): CportalService {
        return injector.get('CportalService');
    },
};

export const infraCloudStateServiceProvider = {
    deps: [$injector],
    provide: InfraCloudState,
    useFactory(injector: Injector): InfraCloudState {
        return injector.get(INFRA_CLOUD_STATE_SERVICE_TOKEN);
    },
};

export const subnetlistNetworkCollectionProvider = {
    deps: [$injector],
    provide: SubnetListNetworkCollection,
    useFactory(injector: Injector): typeof SubnetListNetworkCollection {
        return injector.get('SubnetListNetworkCollection');
    },
};

export const HttpWrapperProvider = {
    deps: [$injector],
    provide: HttpWrapper,
    useFactory(injector: Injector): typeof HttpWrapper {
        return injector.get('HttpWrapper');
    },
};

export const clipboardServiceProvider = {
    deps: [$injector],
    provide: 'clipboardService',
    useFactory(injector: Injector): any {
        return injector.get('clipboardService');
    },
};

export const vrfContextCollectionProvider = {
    deps: [$injector],
    provide: VRFContextCollection,
    useFactory(injector: Injector): typeof VRFContextCollection {
        return injector.get(VRF_CONTEXT_COLLECTION_TOKEN);
    },
};

export const geoDbCollectionProvider = {
    deps: [$injector],
    provide: GeoDbCollection,
    useFactory(injector: Injector): typeof GeoDbCollection {
        return injector.get(GEO_DB_COLLECTION_TOKEN);
    },
};

export const healthMonitorCollectionProvider = {
    deps: [$injector],
    provide: 'HealthMonitorCollection',
    useFactory(injector: Injector): any {
        return injector.get('HealthMonitorCollection');
    },
};

export const autoScaleLaunchConfigCollectionProvider = {
    deps: [$injector],
    provide: 'AutoScaleLaunchConfigCollection',
    useFactory(injector: Injector): any {
        return injector.get('AutoScaleLaunchConfigCollection');
    },
};

export const analyticsProfileCollectionProvider = {
    deps: [$injector],
    provide: 'AnalyticsProfileCollection',
    useFactory(injector: Injector): any {
        return injector.get('AnalyticsProfileCollection');
    },
};

export const autoScalePolicyCollectionProvider = {
    deps: [$injector],
    provide: AutoScalePolicyCollection,
    useFactory(injector: Injector): any {
        return injector.get('AutoScalePolicyCollection');
    },
};

export const persistenceProfileCollectionProvider = {
    deps: [$injector],
    provide: PersistenceProfileCollection,
    useFactory(injector: Injector): any {
        return injector.get('PersistenceProfileCollection');
    },
};

export const clusterNodeCollectionProvider = {
    deps: [$injector],
    provide: 'ClusterNodeCollection',
    useFactory(injector: Injector): any {
        return injector.get('ClusterNodeCollection');
    },
};

export const errorPageBodyCollectionProvider = {
    deps: [$injector],
    provide: ErrorPageBodyCollection,
    useFactory(injector: Injector): TErrorPageBodyCollection {
        return injector.get(ERROR_PAGE_BODY_COLLECTION_TOKEN);
    },
};

export const subnetListNetworkItemProvider = {
    deps: [$injector],
    provide: SubnetListNetwork,
    useFactory(injector: Injector): SubnetListNetwork {
        return injector.get('SubnetListNetwork');
    },
};

export const ipamProfileItemProvider = {
    deps: [$injector],
    provide: IpamProfileItem,
    useFactory(injector: Injector): typeof IpamProfileItem {
        return injector.get(IPAM_PROFILE_ITEM_TOKEN);
    },
};

export const vrfContextItemProvider = {
    deps: [$injector],
    provide: VRFContext,
    useFactory(injector: Injector): typeof VRFContext {
        return injector.get(VRF_CONTEXT_TOKEN);
    },
};

export const sslProfileCollectionProvider = {
    deps: [$injector],
    provide: SSLProfileCollection,
    useFactory(injector: Injector): typeof SSLProfileCollection {
        return injector.get('SSLProfileCollection');
    },
};

export const pkiProfileCollectionProvider = {
    deps: [$injector],
    provide: PKIProfileCollection,
    useFactory(injector: Injector): typeof PKIProfileCollection {
        return injector.get('PKIProfileCollection');
    },
};

export const certificateCollectionProvider = {
    deps: [$injector],
    provide: CertificateCollection,
    useFactory(injector: Injector): typeof CertificateCollection {
        return injector.get('CertificateCollection');
    },
};

export const dnsProvider = {
    deps: [$injector],
    provide: 'DNS',
    useFactory(injector: Injector): any {
        return injector.get('DNS');
    },
};

export const VMNicIPCollectionProvider = {
    deps: [$injector],
    provide: 'VMNicIPCollection',
    useFactory(injector: Injector): any {
        return injector.get('VMNicIPCollection');
    },
};

export const passwordGeneratorServiceProvider = {
    deps: [$injector],
    provide: PasswordGeneratorService,
    useFactory(injector: Injector): typeof PasswordGeneratorService {
        return injector.get(PASSWORD_GENERATOR_SERVICE_TOKEN);
    },
};

export const secretStubStrProvider = {
    deps: [$injector],
    provide: 'secretStubStr',
    useFactory(injector: Injector): string {
        return injector.get(SECRET_STUB_STRING);
    },
};

export const seGroupFlavorCollectionProvider = {
    deps: [$injector],
    provide: 'SEGroupFlavorCollection',
    useFactory(injector: Injector): any {
        return injector.get('SEGroupFlavorCollection');
    },
};

export const baseCollectionProvider = {
    deps: [$injector],
    provide: 'Collection',
    useFactory(injector: Injector): typeof Collection {
        return injector.get('Collection');
    },
};
