/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'health-monitor-authentication';
const prefix = `${moduleName}.${componentName}`;

export const authenticationHeader = `${prefix}.authenticationHeader`;
export const authenticationTypeInputLabel = `${prefix}.authenticationTypeInputLabel`;
export const authenticationTypeInputPlaceholder = `${prefix}.authenticationTypeInputPlaceholder`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;

export const ENGLISH = {
    [authenticationHeader]: 'Authentication',
    [authenticationTypeInputLabel]: 'Authentication Type',
    [authenticationTypeInputPlaceholder]: 'New Technology LAN Manager (NTLM)',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
};
