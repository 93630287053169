/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './StringOrGroupList.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name stringOrGroupList
 * @restrict E
 * @param {string[]} strings - Custom strings
 * @param {groups[]} groups - List of string group refs
 * @param {boolean=} ngDisabled
 * @param {boolean=} ngRequired
 * @param {boolean=} allowClear
 * @param {boolean} [allowCustom=true] - When not set or true is passed will change text
 *     labels and allow custom string value to be added. Value is watched and on change
 *     will flush the lists of groups and strings.
 * @param {boolean} [disallowStringGroups=false] - When true, disallow selecting string groups.
 *     If false, allow only custom strings.
 * @desc
 *
 *     Repeated list of collection-dropdowns for string group refs and custom strings.
 *
 * @author Alex Malitsky
 */
//TODO rebuild with parser and formatter
angular.module('aviApp').directive('stringOrGroupList', [
'StringGroupCollection', 'l10nService',
function(StringGroupCollection, l10nService) {
    const defaultRow = {
        type: 'group',
        data: '',
    };

    l10nService.registerSourceBundles(dictionary);

    const stringOrGroupListLink = function(scope, elm, attr) {
        scope.isRequired = !_.isUndefined(attr['required']);

        scope.stringGroupCollection = new StringGroupCollection();
        scope.all = {};

        scope.l10nKeys = l10nKeys;

        let combineAllowed = true;

        /**
         * allowCustom proxy variable. Need it to run $watch before template ng-ifs.
         * @type {boolean}
         */
        scope.customAllowed = _.isUndefined(scope.allowCustom) || !!scope.allowCustom;

        // Workaround to access scope properties within an ng-if since ng-if creates a child scope.
        scope.$ctrl = scope;

        const combineSourceData = () => {
            if (!combineAllowed) {
                return;
            }

            const strings = _.map(scope.strings,
                string => ({
                    type: 'custom',
                    data: string,
                }));

            const groups = _.map(scope.groups,
                ref => ({
                    type: 'group',
                    data: ref,
                }));

            const { all } = scope;

            all.items = strings.concat(groups);

            // By default there should be at least 1 item
            if (!all.items.length) {
                all.items.push(angular.copy(defaultRow));
            }
        };

        combineSourceData();

        scope.splitStringsAndGroups = function() {
            combineAllowed = false;

            const { items } = scope.all;

            //filtering out `undefined` as well
            scope.strings = _.pluck(
                _.filter(items, ({ type, data }) => type === 'custom' && data),
                'data',
            );

            scope.groups = _.pluck(
                _.filter(items, ({ type }) => type === 'group'),
                'data',
            );

            setTimeout(() => combineAllowed = true);
        };

        scope.$on('$destroy',
            () => scope.stringGroupCollection.destroy());

        // when we switch between allowCustom values we want to wipe out all the values
        // cause custom values might not be supported anymore. Also no point to use list of
        // regexps for reqular string operators.
        scope.$watch('allowCustom', (allowCustom, prevValue) => {
            //angularJS init
            if (allowCustom === prevValue) {
                return;
            }

            const {
                strings,
                groups,
            } = scope;

            let updated = false;

            if (Array.isArray(strings) && strings.length) {
                strings.length = 0;
                updated = true;
            }

            if (Array.isArray(groups) && groups.length) {
                groups.length = 0;
                updated = true;
            }

            if (updated) {
                combineSourceData();
            }

            //to avoid race condition with colleciton-grid loading custom string
            scope.customAllowed = _.isUndefined(allowCustom) || !!allowCustom;
        });

        /**
         * Watcher for the groups binding.
         */
        scope.$watch('groups', () => {
            combineSourceData();
        });

        /**
         * Adds a row to the list.
         */
        scope.addRow = function() {
            scope.all.items.push(angular.copy(defaultRow));
            scope.splitStringsAndGroups();
        };

        /**
         * Removes a row.
         * @param {number} $index
         */
        scope.removeRow = function($index) {
            scope.all.items.splice($index, 1);
            scope.splitStringsAndGroups();
        };
    };

    return {
        scope: {
            strings: '=',
            groups: '=',
            matchDecodedString: '=',
            showMatchDecoded: '=',
            ngRequired: '=',
            ngDisabled: '=',
            allowClear: '<',
            allowCustom: '<?',
            disallowStringGroups: '<?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/string-or-group-list.html',
        link: stringOrGroupListLink,
    };
}]);
