/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    AuthMappingRule,
    AuthMatchGroupMembership,
} from 'object-types';

import { SchemaService } from 'ajs/modules/core/services';
import { AuthMatchOperation } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { AuthMatchGroupMembershipConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-match-group-membership.config-item.factory';
import * as l10n from './mapping-rule-group-match.l10n';
import './mapping-rule-group-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description AuthMappingRule -> GroupMatch Configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'mapping-rule-group-match',
    templateUrl: './mapping-rule-group-match.component.html',
})
export class MappingRuleGroupMatchComponent {
    /**
     * GroupMatch messageItem instance.
     */
    @Input()
    public groupMatch: AuthMatchGroupMembershipConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectTypes used in template.
     */
    public readonly objectTypes = {
        [AuthMappingRule]: AuthMappingRule,
        [AuthMatchGroupMembership]: AuthMatchGroupMembership,
    };

    /**
     * AuthMatchOperationEnum hash.
     */
    public readonly authMatchOperationEnumHash = {
        [AuthMatchOperation.AUTH_MATCH_CONTAINS]: AuthMatchOperation.AUTH_MATCH_CONTAINS,
        [AuthMatchOperation.AUTH_MATCH_DOES_NOT_CONTAIN]:
            AuthMatchOperation.AUTH_MATCH_DOES_NOT_CONTAIN,
    };

    /**
     * Hash of AuthMatchOperation value to description
     * Used to display tooltip.
     */
    private readonly authMatchOperationEnumValueDescriptionHash =
    {} as any as Record<AuthMatchOperation, string>;

    constructor(
        schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        const authMatchOperationValues = schemaService.getEnumValues('AuthMatchOperation');

        this.authMatchOperationEnumValueDescriptionHash =
            authMatchOperationValues.reduce((acc, { value, description = '' }) => {
                if (value !== AuthMatchOperation.AUTH_MATCH_REGEX) {
                    acc[value] = description;
                }

                return acc;
            }, {} as any as Record<AuthMatchOperation, string>);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns tooltip for radio buttons.
     */
    public getTooltip(authMatchOperationValue: AuthMatchOperation): string {
        return this.authMatchOperationEnumValueDescriptionHash[authMatchOperationValue];
    }

    /**
     * Handler for `Any` Criteria selection.
     * Clears groups.
     */
    public onClearCriteria(): void {
        this.groupMatch.config.groups = [];
    }
}
