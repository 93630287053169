/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'names-helper';
const prefix = `${moduleName}.${componentName}`;

export const newConnectionsTitle = `${prefix}.newConnectionsTitle`;
export const newRequestsTitle = `${prefix}.newRequestsTitle`;
export const averageSatisfactoryResponsesTitle = `${prefix}.averageSatisfactoryResponsesTitle`;
export const averageToleratedResponsesTitle = `${prefix}.averageToleratedResponsesTitle`;
export const averageFrustratedResponsesTitle = `${prefix}.averageFrustratedResponsesTitle`;
export const connectionApdexTitle = `${prefix}.connectionApdexTitle`;
export const responseApdexTitle = `${prefix}.responseApdexTitle`;
export const clientLatencyTitle = `${prefix}.clientLatencyTitle`;
export const performanceTitle = `${prefix}.performanceTitle`;
export const requestsTitle = `${prefix}.requestsTitle`;
export const apdexRttTitle = `${prefix}.apdexRttTitle`;
export const totalTitle = `${prefix}.totalTitle`;
export const resourcePenaltyTitle = `${prefix}.resourcePenaltyTitle`;
export const connsTitle = `${prefix}.connsTitle`;
export const openConnsTitle = `${prefix}.openConnsTitle`;
export const droppedConnectionsTitle = `${prefix}.droppedConnectionsTitle`;
export const lossyConnectionsTitle = `${prefix}.lossyConnectionsTitle`;
export const server4xxTitle = `${prefix}.server4xxTitle`;
export const server5xxTitle = `${prefix}.server5xxTitle`;
export const pageLoadApdexTitle = `${prefix}.pageLoadApdexTitle`;
export const requestErrorsTitle = `${prefix}.requestErrorsTitle`;
export const requestErrorsPercentageTitle = `${prefix}.requestErrorsPercentageTitle`;
export const averageUptimeTitle = `${prefix}.averageUptimeTitle`;
export const getTitle = `${prefix}.getTitle`;
export const postTitle = `${prefix}.postTitle`;
export const othersTitle = `${prefix}.othersTitle`;
export const oneXxInfoTitle = `${prefix}.oneXxInfoTitle`;
export const twoXxOkTitle = `${prefix}.twoXxOkTitle`;
export const threeXxRedirectTitle = `${prefix}.threeXxRedirectTitle`;
export const throughputTitle = `${prefix}.throughputTitle`;
export const satisfactoryResponsesTitle = `${prefix}.satisfactoryResponsesTitle`;
export const toleratedResponsesTitle = `${prefix}.toleratedResponsesTitle`;
export const frustratedResponsesTitle = `${prefix}.frustratedResponsesTitle`;
export const badConnectionsTitle = `${prefix}.badConnectionsTitle`;
export const satisfiedResponsesTitle = `${prefix}.satisfiedResponsesTitle`;
export const postRequestsTitle = `${prefix}.postRequestsTitle`;
export const getRequestsTitle = `${prefix}.getRequestsTitle`;
export const otherRequestsTitle = `${prefix}.otherRequestsTitle`;
export const dataTransferTitle = `${prefix}.dataTransferTitle`;
export const appResponseTitle = `${prefix}.appResponseTitle`;
export const clientRttTitle = `${prefix}.clientRttTitle`;
export const serverRttTitle = `${prefix}.serverRttTitle`;
export const fourXxErrorsTitle = `${prefix}.fourXxErrorsTitle`;
export const fiveXxErrorsTitle = `${prefix}.fiveXxErrorsTitle`;
export const aviGenerated4xxTitle = `${prefix}.aviGenerated4xxTitle`;
export const aviGenerated5xxTitle = `${prefix}.aviGenerated5xxTitle`;
export const cpuUsageTitle = `${prefix}.cpuUsageTitle`;
export const connectionMemoryUsageTitle = `${prefix}.connectionMemoryUsageTitle`;
export const memoryUsageTitle = `${prefix}.memoryUsageTitle`;
export const bufferUsageTitle = `${prefix}.bufferUsageTitle`;
export const sslCacheUsageTitle = `${prefix}.sslCacheUsageTitle`;
export const disk1UsageTitle = `${prefix}.disk1UsageTitle`;
export const persistentTableUsageTitle = `${prefix}.persistentTableUsageTitle`;
export const maxUsageTitle = `${prefix}.maxUsageTitle`;
export const droppedConnsPercentageTitle = `${prefix}.droppedConnsPercentageTitle`;
export const synCacheUsageTitle = `${prefix}.synCacheUsageTitle`;
export const rxPacketsDropTitle = `${prefix}.rxPacketsDropTitle`;
export const rxBytesDropTitle = `${prefix}.rxBytesDropTitle`;
export const txThroughputTitle = `${prefix}.txThroughputTitle`;
export const vsesTimeSeriesTitle = `${prefix}.vsesTimeSeriesTitle`;
export const dynamicMemoryUsageTitle = `${prefix}.dynamicMemoryUsageTitle`;
export const serviceEngineTitle = `${prefix}.serviceEngineTitle`;
export const poolTitle = `${prefix}.poolTitle`;
export const virtualServiceTitle = `${prefix}.virtualServiceTitle`;
export const anomalyPenaltyTitle = `${prefix}.anomalyPenaltyTitle`;
export const securityPenaltyTitle = `${prefix}.securityPenaltyTitle`;
export const performanceScoreTitle = `${prefix}.performanceScoreTitle`;
export const healthScoreTitle = `${prefix}.healthScoreTitle`;
export const clientTxnLatencyTitle = `${prefix}.clientTxnLatencyTitle`;
export const rxPacketsTitle = `${prefix}.rxPacketsTitle`;
export const txPacketsTitle = `${prefix}.txPacketsTitle`;
export const numberServiceEnginesTitle = `${prefix}.numberServiceEnginesTitle`;
export const anomalyScoreTitle = `${prefix}.anomalyScoreTitle`;
export const totalRequestsTitle = `${prefix}.totalRequestsTitle`;
export const netUsageTitle = `${prefix}.netUsageTitle`;
export const netReceivedTitle = `${prefix}.netReceivedTitle`;
export const connectionErrorsTitle = `${prefix}.connectionErrorsTitle`;
export const memoryTitle = `${prefix}.memoryTitle`;
export const diskBandwidthTitle = `${prefix}.diskBandwidthTitle`;
export const transactionsTitle = `${prefix}.transactionsTitle`;
export const newSslSessionsTitle = `${prefix}.newSslSessionsTitle`;
export const reusedSslSessionsTitle = `${prefix}.reusedSslSessionsTitle`;
export const sslProtocolErrorsTitle = `${prefix}.sslProtocolErrorsTitle`;
export const sslNetworkErrorsTitle = `${prefix}.sslNetworkErrorsTitle`;
export const sslTimeoutsTitle = `${prefix}.sslTimeoutsTitle`;
export const failedTransactionsTitle = `${prefix}.failedTransactionsTitle`;
export const l4AttacksTitle = `${prefix}.l4AttacksTitle`;
export const l7AttacksTitle = `${prefix}.l7AttacksTitle`;
export const attackDurationTitle = `${prefix}.attackDurationTitle`;
export const blockedConnectionsTitle = `${prefix}.blockedConnectionsTitle`;
export const numberOfAttacksTitle = `${prefix}.numberOfAttacksTitle`;
export const blockedTitle = `${prefix}.blockedTitle`;
export const attackLevelTitle = `${prefix}.attackLevelTitle`;
export const dosAttacksTitle = `${prefix}.dosAttacksTitle`;
export const packetsDosAttacksTitle = `${prefix}.packetsDosAttacksTitle`;
export const applicationDosAttacksTitle = `${prefix}.applicationDosAttacksTitle`;
export const dosRxBytesTitle = `${prefix}.dosRxBytesTitle`;
export const policyDropsTitle = `${prefix}.policyDropsTitle`;
export const connectionDosAttacksTitle = `${prefix}.connectionDosAttacksTitle`;
export const dosBandwidthTitle = `${prefix}.dosBandwidthTitle`;
export const lbFailCountTitle = `${prefix}.lbFailCountTitle`;
export const estimatedCapacityTitle = `${prefix}.estimatedCapacityTitle`;
export const availableCapacityTitle = `${prefix}.availableCapacityTitle`;
export const dnsQueriesTitle = `${prefix}.dnsQueriesTitle`;
export const otherTitle = `${prefix}.otherTitle`;
export const errorsPercentageTitle = `${prefix}.errorsPercentageTitle`;
export const errorsSecTitle = `${prefix}.errorsSecTitle`;
export const tcpQueriesTitle = `${prefix}.tcpQueriesTitle`;
export const udpQueriesTitle = `${prefix}.udpQueriesTitle`;
export const updPassthroughResponseTimeTitle = `${prefix}.updPassthroughResponseTimeTitle`;
export const gslbPoolMemberNotAvailableTitle = `${prefix}.gslbPoolMemberNotAvailableTitle`;
export const invalidQueriesTitle = `${prefix}.invalidQueriesTitle`;
export const invalidDomainsTitle = `${prefix}.invalidDomainsTitle`;
export const unsupportedRequestsTitle = `${prefix}.unsupportedRequestsTitle`;
export const tcpPassthroughErrorsTitle = `${prefix}.tcpPassthroughErrorsTitle`;
export const udpPassthroughErrorsTitle = `${prefix}.udpPassthroughErrorsTitle`;
export const backendServersTitle = `${prefix}.backendServersTitle`;
export const seCoresAvailableTitle = `${prefix}.seCoresAvailableTitle`;
export const throughputAcrossSesTitle = `${prefix}.throughputAcrossSesTitle`;
export const throughputAcrossVirtualServicesTitle = `${prefix}.throughputAcrossVirtualServicesTitle`;
export const maxSeHostsTitle = `${prefix}.maxSeHostsTitle`;
export const averageSeHostsTitle = `${prefix}.averageSeHostsTitle`;
export const maxSeSocketsTitle = `${prefix}.maxSeSocketsTitle`;
export const averageSeSocketsTitle = `${prefix}.averageSeSocketsTitle`;
export const wafAttacksTitle = `${prefix}.wafAttacksTitle`;
export const wafAttacksPercentageTitle = `${prefix}.wafAttacksPercentageTitle`;
export const http2RequestsTitle = `${prefix}.http2RequestsTitle`;
export const endToEndTimingTitle = `${prefix}.endToEndTimingTitle`;
export const virtualServiceThroughputTitle = `${prefix}.virtualServiceThroughputTitle`;
export const interfaceThroughputTitle = `${prefix}.interfaceThroughputTitle`;
export const certificateTitle = `${prefix}.certificateTitle`;
export const tlsVersionTitle = `${prefix}.tlsVersionTitle`;
export const keyExchangeTitle = `${prefix}.keyExchangeTitle`;
export const attackCountTitle = `${prefix}.attackCountTitle`;
export const attackRateTitle = `${prefix}.attackRateTitle`;
export const rxMinusculesPacketsTitle = `${prefix}.rxMinusculesPacketsTitle`;
export const txMinusculesPacketsTitle = `${prefix}.txMinusculesPacketsTitle`;
export const dnsQueryTypesTitle = `${prefix}.dnsQueryTypesTitle`;
export const tcpVsUdpTitle = `${prefix}.tcpVsUdpTitle`;
export const localVsPassthroughQueriesTitle = `${prefix}.localVsPassthroughQueriesTitle`;
export const pfsVsNonPfsTrafficTitle = `${prefix}.pfsVsNonPfsTrafficTitle`;
export const httpVsHttps2RequestsTitle = `${prefix}.httpVsHttps2RequestsTitle`;
export const icapRequestsTotalTitle = `${prefix}.icapRequestsTotalTitle`;
export const icapRequestsModifiedTitle = `${prefix}.icapRequestsModifiedTitle`;
export const icapRequestsPassedTitle = `${prefix}.icapRequestsPassedTitle`;
export const icapRequestsInternalErrorTitle = `${prefix}.icapRequestsInternalErrorTitle`;
export const icapRequestsServerErrorTitle = `${prefix}.icapRequestsServerErrorTitle`;
export const timeoutSeriesTitle = `${prefix}.timeoutSeriesTitle`;
export const botTitle = `${prefix}.botTitle`;

export const ENGLISH = {
    [newConnectionsTitle]: 'New Connections',
    [newRequestsTitle]: 'New Requests',
    [averageSatisfactoryResponsesTitle]: 'Average Satisfactory Responses',
    [averageToleratedResponsesTitle]: 'Average Tolerated Responses',
    [averageFrustratedResponsesTitle]: 'Average Frustrated Responses',
    [connectionApdexTitle]: 'Connection Apdex',
    [responseApdexTitle]: 'Response Apdex',
    [clientLatencyTitle]: 'Client Latency',
    [performanceTitle]: 'Performance',
    [requestsTitle]: 'Requests',
    [apdexRttTitle]: 'Apdex RTT',
    [totalTitle]: 'Total',
    [resourcePenaltyTitle]: 'Resource Penalty',
    [connsTitle]: 'Conns',
    [openConnsTitle]: 'Open Conns',
    [droppedConnectionsTitle]: 'Dropped Connections',
    [lossyConnectionsTitle]: 'Lossy Connections',
    [server4xxTitle]: 'Server 4xx',
    [server5xxTitle]: 'Server 5xx',
    [pageLoadApdexTitle]: 'PageLoad Apdex',
    [requestErrorsTitle]: 'Request Errors',
    [requestErrorsPercentageTitle]: 'Request Errors, %',
    [averageUptimeTitle]: 'Average Uptime',
    [getTitle]: 'GET',
    [postTitle]: 'POST',
    [othersTitle]: 'Others',
    [oneXxInfoTitle]: '1xx Info',
    [twoXxOkTitle]: '2xx OK',
    [threeXxRedirectTitle]: '3xx Redirect',
    [throughputTitle]: 'Throughput',
    [satisfactoryResponsesTitle]: 'Satisfactory Responses',
    [toleratedResponsesTitle]: 'Tolerated Responses',
    [frustratedResponsesTitle]: 'Frustrated Responses',
    [badConnectionsTitle]: 'Bad Connections',
    [satisfiedResponsesTitle]: 'Satisfied Responses',
    [postRequestsTitle]: 'Post Requests',
    [getRequestsTitle]: 'Get Requests',
    [otherRequestsTitle]: 'Other Requests',
    [dataTransferTitle]: 'Data Transfer',
    [appResponseTitle]: 'App Response',
    [clientRttTitle]: 'Client RTT',
    [serverRttTitle]: 'Server RTT',
    [fourXxErrorsTitle]: '4xx Errors',
    [fiveXxErrorsTitle]: '5xx Errors',
    [aviGenerated4xxTitle]: 'Avi Generated 4xx',
    [aviGenerated5xxTitle]: 'Avi Generated 5xx',
    [cpuUsageTitle]: 'CPU Usage',
    [connectionMemoryUsageTitle]: 'Connection Memory Usage',
    [memoryUsageTitle]: 'Memory Usage',
    [bufferUsageTitle]: 'Buffer Usage',
    [sslCacheUsageTitle]: 'SSL Cache Usage',
    [disk1UsageTitle]: 'Disk1 Usage',
    [persistentTableUsageTitle]: 'Persistent Table Usage',
    [maxUsageTitle]: 'Max Usage',
    [droppedConnsPercentageTitle]: 'Dropped Conns, %',
    [synCacheUsageTitle]: 'Syn Cache Usage',
    [rxPacketsDropTitle]: 'RX Packets Drop',
    [rxBytesDropTitle]: 'RX Bytes Drop',
    [txThroughputTitle]: 'TX Throughput',
    [vsesTimeSeriesTitle]: '# VSes Time Series',
    [dynamicMemoryUsageTitle]: 'Dynamic Memory Usage',
    [serviceEngineTitle]: 'Service Engine',
    [poolTitle]: 'Pool',
    [virtualServiceTitle]: 'Virtual Service',
    [anomalyPenaltyTitle]: 'Anomaly Penalty',
    [securityPenaltyTitle]: 'Security Penalty',
    [performanceScoreTitle]: 'Performance Score',
    [healthScoreTitle]: 'Health Score',
    [clientTxnLatencyTitle]: 'Client TXN Latency',
    [rxPacketsTitle]: 'RX Packets',
    [txPacketsTitle]: 'TX Packets',
    [numberServiceEnginesTitle]: '# Service Engines',
    [anomalyScoreTitle]: 'Anomaly Score',
    [totalRequestsTitle]: 'Total Requests',
    [netUsageTitle]: 'Net Usage',
    [netReceivedTitle]: 'Net Received',
    [connectionErrorsTitle]: 'Connection Errors',
    [memoryTitle]: 'Memory',
    [diskBandwidthTitle]: 'Disk Bandwidth',
    [transactionsTitle]: 'Transactions',
    [newSslSessionsTitle]: 'New SSL Sessions',
    [reusedSslSessionsTitle]: 'Reused SSL Sessions',
    [sslProtocolErrorsTitle]: 'SSL Protocol Errors',
    [sslNetworkErrorsTitle]: 'SSL Network Errors',
    [sslTimeoutsTitle]: 'SSL Timeouts',
    [failedTransactionsTitle]: 'Failed Transactions',
    [l4AttacksTitle]: 'L4 Attacks',
    [l7AttacksTitle]: 'L7 Attacks',
    [attackDurationTitle]: 'Attack Duration',
    [blockedConnectionsTitle]: 'Blocked Connections',
    [numberOfAttacksTitle]: 'Number of Attacks',
    [blockedTitle]: 'Blocked',
    [attackLevelTitle]: 'Attack Level',
    [dosAttacksTitle]: 'DoS Attacks',
    [packetsDosAttacksTitle]: 'Packets DoS Attacks',
    [applicationDosAttacksTitle]: 'Application DoS Attacks',
    [dosRxBytesTitle]: 'DoS RX Bytes',
    [policyDropsTitle]: 'Policy Drops',
    [connectionDosAttacksTitle]: 'Connection DoS Attacks',
    [dosBandwidthTitle]: 'DoS Bandwidth',
    [lbFailCountTitle]: 'LB Fail Count',
    [estimatedCapacityTitle]: 'Estimated Capacity',
    [availableCapacityTitle]: 'Available Capacity',
    [dnsQueriesTitle]: 'DNS Queries',
    [otherTitle]: 'Other',
    [errorsPercentageTitle]: 'Errors (%)',
    [errorsSecTitle]: 'Errors (/sec)',
    [tcpQueriesTitle]: 'TCP Queries',
    [udpQueriesTitle]: 'UDP Queries',
    [updPassthroughResponseTimeTitle]: 'UDP Passthrough Response Time',
    [gslbPoolMemberNotAvailableTitle]: 'GSLB Pool Member Not Available',
    [invalidQueriesTitle]: 'Invalid Queries',
    [invalidDomainsTitle]: 'Invalid Domains',
    [unsupportedRequestsTitle]: 'Unsupported Requests',
    [tcpPassthroughErrorsTitle]: 'TCP Passthrough Errors',
    [udpPassthroughErrorsTitle]: 'UDP Passthrough Errors',
    [backendServersTitle]: 'Backend Servers',
    [seCoresAvailableTitle]: 'Service Engine Cores Available',
    [throughputAcrossSesTitle]: 'Throughput across Service Engines',
    [throughputAcrossVirtualServicesTitle]: 'Throughput across Virtual Services',
    [maxSeHostsTitle]: 'Max Service Engine Hosts',
    [averageSeHostsTitle]: 'Average Service Engine Hosts',
    [maxSeSocketsTitle]: 'Max Service Engine Sockets',
    [averageSeSocketsTitle]: 'Average Service Engine Sockets',
    [wafAttacksTitle]: 'WAF Attacks',
    [wafAttacksPercentageTitle]: 'WAF Attacks, %',
    [http2RequestsTitle]: 'HTTP2 Requests',
    [endToEndTimingTitle]: 'End to End Timing',
    [virtualServiceThroughputTitle]: 'Virtual Service Throughput',
    [interfaceThroughputTitle]: 'Interface Throughput',
    [certificateTitle]: 'Certificate',
    [tlsVersionTitle]: 'TLS Version',
    [keyExchangeTitle]: 'Key Exchange',
    [attackCountTitle]: 'Attack Count',
    [attackRateTitle]: 'Attack Rate',
    [rxMinusculesPacketsTitle]: 'Rx Packets',
    [txMinusculesPacketsTitle]: 'Tx Packets',
    [dnsQueryTypesTitle]: 'DNS Query Types',
    [tcpVsUdpTitle]: 'TCP vs UDP',
    [localVsPassthroughQueriesTitle]: 'Local vs Passthrough Queries',
    [pfsVsNonPfsTrafficTitle]: 'PFS vs Non-PFS Traffic',
    [httpVsHttps2RequestsTitle]: 'HTTP vs HTTP2 Requests',
    [icapRequestsTotalTitle]: 'ICAP Requests',
    [icapRequestsModifiedTitle]: 'Modified',
    [icapRequestsPassedTitle]: 'Passed',
    [icapRequestsInternalErrorTitle]: 'Internal Errors',
    [icapRequestsServerErrorTitle]: 'Server Errors',
    [timeoutSeriesTitle]: 'Timeouts',
    [botTitle]: 'Bot',
};
