/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name HSGraphEdge
 * @author Alex Malitsky
 * @description
 *
 *     Item class for HealthScore graph edge.
 */
angular.module('aviApp').factory('HSGraphEdge', ['Item',
function(Item) {
    class HSGraphEdge extends Item {
        /** @override */
        getIdFromData_(...data) {
            return this.constructor.getIdFromData(...data);
        }

        /**
         * Static getIdFromData.
         * @static
         */
        static getIdFromData({ config }) {
            const { source, target } = config;

            return `${source}|${target}`;
        }

        /** @override */
        getName() {
            return this.id;
        }
    }

    HSGraphEdge.prototype.objectName = 'HSGraphNode';

    return HSGraphEdge;
}]);
