/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    TenantCollection,
    UserProfileCollection,
} from 'ajs/modules/accounts/factories';

import {
    TENANT_COLLECTION_TOKEN,
    USER_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/accounts/accounts.tokens';

import {
    AuthProfileCollection,
    AUTH_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/security/factories';

import { StringService }
    from 'ajs/modules/core/services/string-service/string.service';

import { AuthMappingProfileCollection }
    from 'ajs/modules/auth-settings/factories/auth-mapping-profile.collection.factory';

import { AUTH_MAPPING_PROFILE_COLLECTION_TOKEN }
    from 'ajs/modules/auth-settings/auth-settings.tokens';

const $injector = '$injector';

export const stringServiceProvider = {
    deps: [$injector],
    provide: StringService,
    useFactory(injector: Injector): StringService {
        return injector.get('stringService');
    },
};

export const systemConfigServiceProvider = {
    deps: [$injector],
    provide: 'systemConfigService',
    useFactory(injector: Injector): any {
        return injector.get('systemConfigService');
    },
};

export const roleCollectionProvider = {
    deps: [$injector],
    provide: 'RoleCollection',
    useFactory(injector: Injector): any {
        return injector.get('RoleCollection');
    },
};

export const tenantCollectionProvider = {
    deps: [$injector],
    provide: TenantCollection,
    useFactory(injector: Injector): TenantCollection {
        return injector.get(TENANT_COLLECTION_TOKEN);
    },
};

export const userProfileCollectionProvider = {
    deps: [$injector],
    provide: UserProfileCollection,
    useFactory(injector: Injector): UserProfileCollection {
        return injector.get(USER_PROFILE_COLLECTION_TOKEN);
    },
};

export const authProfileCollectionProvider = {
    deps: [$injector],
    provide: AuthProfileCollection,
    useFactory(injector: Injector): AuthProfileCollection {
        return injector.get(AUTH_PROFILE_COLLECTION_TOKEN);
    },
};

export const authMappingProfileCollectionProvider = {
    deps: [$injector],
    provide: AuthMappingProfileCollection,
    useFactory(injector: Injector): AuthProfileCollection {
        return injector.get(AUTH_MAPPING_PROFILE_COLLECTION_TOKEN);
    },
};
