export const ActionGroupConfig = "ActionGroupConfig";
export const ConfigPbAttributes = "ConfigPbAttributes";
export const ALBServicesConfig = "ALBServicesConfig";
export const OperationsConfig = "OperationsConfig";
export const InventoryConfig = "InventoryConfig";
export const CaseConfig = "CaseConfig";
export const WafCrsConfig = "WafCrsConfig";
export const UserAgentDBConfig = "UserAgentDBConfig";
export const AppSignatureConfig = "AppSignatureConfig";
export const IpReputationConfig = "IpReputationConfig";
export const ProxyConfiguration = "ProxyConfiguration";
export const ALBServicesUser = "ALBServicesUser";
export const ALBServicesAccount = "ALBServicesAccount";
export const ALBServicesAccountUser = "ALBServicesAccountUser";
export const Alert = "Alert";
export const ApplicationLog = "ApplicationLog";
export const ClientFingerprints = "ClientFingerprints";
export const TlsClientInfo = "TlsClientInfo";
export const OauthLog = "OauthLog";
export const OauthSubRequestLog = "OauthSubRequestLog";
export const SubRequestLog = "SubRequestLog";
export const AuthzRuleMatch = "AuthzRuleMatch";
export const AuthnRuleMatch = "AuthnRuleMatch";
export const BotManagementLog = "BotManagementLog";
export const BotClassification = "BotClassification";
export const BotEvaluationResult = "BotEvaluationResult";
export const BotIdentification = "BotIdentification";
export const OutOfBandRequestLog = "OutOfBandRequestLog";
export const DSRequestLog = "DSRequestLog";
export const NtlmLog = "NtlmLog";
export const JwtLog = "JwtLog";
export const SamlLog = "SamlLog";
export const SamlAttribute = "SamlAttribute";
export const SamlAuthzRuleMatch = "SamlAuthzRuleMatch";
export const SamlAuthnRuleMatch = "SamlAuthnRuleMatch";
export const IcapLog = "IcapLog";
export const IcapRequestLog = "IcapRequestLog";
export const IcapNSXDefenderLog = "IcapNSXDefenderLog";
export const IcapOPSWATLog = "IcapOPSWATLog";
export const ConnErrorInfo = "ConnErrorInfo";
export const EventLog = "EventLog";
export const AlertConfig = "AlertConfig";
export const AlertEmailConfig = "AlertEmailConfig";
export const AlertRuleEvent = "AlertRuleEvent";
export const EventDetailsFilter = "EventDetailsFilter";
export const AlertRuleMetric = "AlertRuleMetric";
export const AlertMetricThreshold = "AlertMetricThreshold";
export const AlertScriptConfig = "AlertScriptConfig";
export const AlertSyslogConfig = "AlertSyslogConfig";
export const AlertSyslogServer = "AlertSyslogServer";
export const AnalyticsProfile = "AnalyticsProfile";
export const TimeTrackerProperties = "TimeTrackerProperties";
export const LatencyAuditProperties = "LatencyAuditProperties";
export const RoleFilterMatchLabel = "RoleFilterMatchLabel";
export const SensitiveLogProfile = "SensitiveLogProfile";
export const SensitiveFieldRule = "SensitiveFieldRule";
export const StringMatch = "StringMatch";
export const HTTPStatusRange = "HTTPStatusRange";
export const ClientLogStreamingConfig = "ClientLogStreamingConfig";
export const StreamingSyslogConfig = "StreamingSyslogConfig";
export const ClientLogConfiguration = "ClientLogConfiguration";
export const ApplicationPersistenceProfile = "ApplicationPersistenceProfile";
export const HttpCookiePersistenceProfile = "HttpCookiePersistenceProfile";
export const AppCookiePersistenceProfile = "AppCookiePersistenceProfile";
export const HdrPersistenceProfile = "HdrPersistenceProfile";
export const IPPersistenceProfile = "IPPersistenceProfile";
export const ApplicationProfile = "ApplicationProfile";
export const L4SSLApplicationProfile = "L4SSLApplicationProfile";
export const SipServiceApplicationProfile = "SipServiceApplicationProfile";
export const DnsServiceApplicationProfile = "DnsServiceApplicationProfile";
export const DnsZone = "DnsZone";
export const TCPApplicationProfile = "TCPApplicationProfile";
export const FTPProfile = "FTPProfile";
export const HTTPApplicationProfile = "HTTPApplicationProfile";
export const TrueClientIPConfig = "TrueClientIPConfig";
export const HTTP2ApplicationProfile = "HTTP2ApplicationProfile";
export const SSLClientCertificateAction = "SSLClientCertificateAction";
export const SSLClientRequestHeader = "SSLClientRequestHeader";
export const HttpCacheConfig = "HttpCacheConfig";
export const PathMatch = "PathMatch";
export const CompressionProfile = "CompressionProfile";
export const CompressionFilter = "CompressionFilter";
export const IpAddrPrefix = "IpAddrPrefix";
export const IpAddrRange = "IpAddrRange";
export const IpAddr = "IpAddr";
export const AuthorizationPolicy = "AuthorizationPolicy";
export const AuthorizationRule = "AuthorizationRule";
export const AuthorizationAction = "AuthorizationAction";
export const AuthorizationMatch = "AuthorizationMatch";
export const JWTMatch = "JWTMatch";
export const JWTClaimMatch = "JWTClaimMatch";
export const MethodMatch = "MethodMatch";
export const HostHdrMatch = "HostHdrMatch";
export const AuthAttributeMatch = "AuthAttributeMatch";
export const AuthProfile = "AuthProfile";
export const OAuthProfile = "OAuthProfile";
export const SamlSettings = "SamlSettings";
export const SamlServiceProviderSettings = "SamlServiceProviderSettings";
export const SamlServiceProviderNode = "SamlServiceProviderNode";
export const SamlIdentityProviderSettings = "SamlIdentityProviderSettings";
export const TacacsPlusAuthSettings = "TacacsPlusAuthSettings";
export const AuthTacacsPlusAttributeValuePair = "AuthTacacsPlusAttributeValuePair";
export const AuthProfileHTTPClientParams = "AuthProfileHTTPClientParams";
export const LdapAuthSettings = "LdapAuthSettings";
export const LdapUserBindSettings = "LdapUserBindSettings";
export const LdapDirectorySettings = "LdapDirectorySettings";
export const AuthMappingProfile = "AuthMappingProfile";
export const AuthMappingRule = "AuthMappingRule";
export const AuthMatchAttribute = "AuthMatchAttribute";
export const AuthMatchGroupMembership = "AuthMatchGroupMembership";
export const AutoScaleLaunchConfig = "AutoScaleLaunchConfig";
export const AutoScaleMesosSettings = "AutoScaleMesosSettings";
export const AutoScaleOpenStackSettings = "AutoScaleOpenStackSettings";
export const Backup = "Backup";
export const BackupConfiguration = "BackupConfiguration";
export const BotDetectionPolicy = "BotDetectionPolicy";
export const BotConfigUserAgent = "BotConfigUserAgent";
export const BotConfigIPLocation = "BotConfigIPLocation";
export const BotConfigIPReputation = "BotConfigIPReputation";
export const BotAllowList = "BotAllowList";
export const BotAllowRule = "BotAllowRule";
export const MatchTarget = "MatchTarget";
export const TlsFingerprintMatch = "TlsFingerprintMatch";
export const IpAddrMatch = "IpAddrMatch";
export const BotDetectionMatch = "BotDetectionMatch";
export const GeoMatch = "GeoMatch";
export const IPReputationTypeMatch = "IPReputationTypeMatch";
export const CookieMatch = "CookieMatch";
export const HdrMatch = "HdrMatch";
export const QueryMatch = "QueryMatch";
export const HTTPVersionMatch = "HTTPVersionMatch";
export const ProtocolMatch = "ProtocolMatch";
export const PortMatch = "PortMatch";
export const BotIPReputationTypeMapping = "BotIPReputationTypeMapping";
export const IPReputationTypeMapping = "IPReputationTypeMapping";
export const BotMapping = "BotMapping";
export const BotMappingRule = "BotMappingRule";
export const BotMappingRuleMatchTarget = "BotMappingRuleMatchTarget";
export const BotTypeMatcher = "BotTypeMatcher";
export const BotClassMatcher = "BotClassMatcher";
export const CaptureFileSize = "CaptureFileSize";
export const CertificateManagementProfile = "CertificateManagementProfile";
export const CustomParams = "CustomParams";
export const ClientLogStreamingFormat = "ClientLogStreamingFormat";
export const Cloud = "Cloud";
export const NTPConfiguration = "NTPConfiguration";
export const NTPServer = "NTPServer";
export const NTPAuthenticationKey = "NTPAuthenticationKey";
export const DnsResolver = "DnsResolver";
export const CustomTag = "CustomTag";
export const NsxtConfiguration = "NsxtConfiguration";
export const DataNetworkConfig = "DataNetworkConfig";
export const NsxtTier1SegmentConfig = "NsxtTier1SegmentConfig";
export const NsxtTier1SegmentAutomaticMode = "NsxtTier1SegmentAutomaticMode";
export const NsxtTier1SegmentManualMode = "NsxtTier1SegmentManualMode";
export const ManagementNetworkConfig = "ManagementNetworkConfig";
export const Tier1LogicalRouterInfo = "Tier1LogicalRouterInfo";
export const GCPConfiguration = "GCPConfiguration";
export const GCPNetworkConfig = "GCPNetworkConfig";
export const GCPTwoArmMode = "GCPTwoArmMode";
export const GCPOneArmMode = "GCPOneArmMode";
export const GCPInBandManagement = "GCPInBandManagement";
export const AzureConfiguration = "AzureConfiguration";
export const AzureNetworkInfo = "AzureNetworkInfo";
export const DockerConfiguration = "DockerConfiguration";
export const MesosAttribute = "MesosAttribute";
export const DockerRegistry = "DockerRegistry";
export const OshiftDockerRegistryMetaData = "OshiftDockerRegistryMetaData";
export const LinuxServerConfiguration = "LinuxServerConfiguration";
export const LinuxServerHost = "LinuxServerHost";
export const HostAttributes = "HostAttributes";
export const vCloudAirConfiguration = "vCloudAirConfiguration";
export const AwsConfiguration = "AwsConfiguration";
export const AwsEncryption = "AwsEncryption";
export const AwsZoneConfig = "AwsZoneConfig";
export const OpenStackConfiguration = "OpenStackConfiguration";
export const OpenStackVipNetwork = "OpenStackVipNetwork";
export const OpenStackRoleMapping = "OpenStackRoleMapping";
export const vCenterConfiguration = "vCenterConfiguration";
export const ContentLibConfig = "ContentLibConfig";
export const CloudConnectorUser = "CloudConnectorUser";
export const VCenterCredentials = "VCenterCredentials";
export const NsxtCredentials = "NsxtCredentials";
export const GCPCredentials = "GCPCredentials";
export const OCICredentials = "OCICredentials";
export const AzureServicePrincipalCredentials = "AzureServicePrincipalCredentials";
export const AzureUserPassCredentials = "AzureUserPassCredentials";
export const Cluster = "Cluster";
export const ClusterNode = "ClusterNode";
export const ControllerInterface = "ControllerInterface";
export const ConnectionLog = "ConnectionLog";
export const DnsRequest = "DnsRequest";
export const GeoLocation = "GeoLocation";
export const DnsResponse = "DnsResponse";
export const DnsResourceRecord = "DnsResourceRecord";
export const ControllerLicense = "ControllerLicense";
export const CumulativeLicense = "CumulativeLicense";
export const SingleLicense = "SingleLicense";
export const ControllerProperties = "ControllerProperties";
export const FalsePositiveLearningConfig = "FalsePositiveLearningConfig";
export const UserAgentCacheConfig = "UserAgentCacheConfig";
export const ControllerSite = "ControllerSite";
export const CustomIpamDnsProfile = "CustomIpamDnsProfile";
export const DebugServiceEngine = "DebugServiceEngine";
export const DebugTraceMemory = "DebugTraceMemory";
export const DebugTraceShmMallocTypes = "DebugTraceShmMallocTypes";
export const DebugTraceMallocTypes = "DebugTraceMallocTypes";
export const DebugSeAgent = "DebugSeAgent";
export const CaptureFilters = "CaptureFilters";
export const DebugIpAddr = "DebugIpAddr";
export const DebugVirtualServiceCapture = "DebugVirtualServiceCapture";
export const DebugSeFault = "DebugSeFault";
export const SeFault = "SeFault";
export const DebugSeCpuShares = "DebugSeCpuShares";
export const DebugSeDataplane = "DebugSeDataplane";
export const DebugVirtualService = "DebugVirtualService";
export const DebugVirtualServiceObjSync = "DebugVirtualServiceObjSync";
export const DosRateLimitProfile = "DosRateLimitProfile";
export const DosThresholdProfile = "DosThresholdProfile";
export const DosThreshold = "DosThreshold";
export const DnsPolicy = "DnsPolicy";
export const DnsRule = "DnsRule";
export const DnsRuleMatchTarget = "DnsRuleMatchTarget";
export const DnsClientPortMatch = "DnsClientPortMatch";
export const PortMatchGeneric = "PortMatchGeneric";
export const DnsClientIpMatch = "DnsClientIpMatch";
export const DnsGeoLocationMatch = "DnsGeoLocationMatch";
export const DnsQueryTypeMatch = "DnsQueryTypeMatch";
export const DnsQueryNameMatch = "DnsQueryNameMatch";
export const DnsTransportProtocolMatch = "DnsTransportProtocolMatch";
export const DnsRuleAction = "DnsRuleAction";
export const DnsRuleActionGsGroupSelection = "DnsRuleActionGsGroupSelection";
export const DnsRateLimiter = "DnsRateLimiter";
export const DnsRuleRLAction = "DnsRuleRLAction";
export const RateLimiter = "RateLimiter";
export const DnsRuleActionPoolSwitching = "DnsRuleActionPoolSwitching";
export const DnsRuleActionGslbSiteSelection = "DnsRuleActionGslbSiteSelection";
export const DnsRuleActionResponse = "DnsRuleActionResponse";
export const DnsRuleDnsRrSet = "DnsRuleDnsRrSet";
export const DnsRrSet = "DnsRrSet";
export const DnsAAAARdata = "DnsAAAARdata";
export const DnsNsRdata = "DnsNsRdata";
export const DnsCnameRdata = "DnsCnameRdata";
export const DnsARdata = "DnsARdata";
export const DnsRuleActionAllowDrop = "DnsRuleActionAllowDrop";
export const ErrorPageBody = "ErrorPageBody";
export const ErrorPageProfile = "ErrorPageProfile";
export const ErrorPage = "ErrorPage";
export const HTTPStatusMatch = "HTTPStatusMatch";
export const FederationCheckpoint = "FederationCheckpoint";
export const GatewayMonitor = "GatewayMonitor";
export const GCPEncryptionKeys = "GCPEncryptionKeys";
export const GCPVIPAllocation = "GCPVIPAllocation";
export const GCPVIPILB = "GCPVIPILB";
export const GCPVIPRoutes = "GCPVIPRoutes";
export const GeoDB = "GeoDB";
export const GeoDBMapping = "GeoDBMapping";
export const GeoDBMappingElement = "GeoDBMappingElement";
export const GeoDBFile = "GeoDBFile";
export const Gslb = "Gslb";
export const ReplicationPolicy = "ReplicationPolicy";
export const GslbClientIpAddrGroup = "GslbClientIpAddrGroup";
export const GslbThirdPartySite = "GslbThirdPartySite";
export const GslbHealthMonitorProxy = "GslbHealthMonitorProxy";
export const GslbGeoLocation = "GslbGeoLocation";
export const GslbSite = "GslbSite";
export const GslbSiteDnsVs = "GslbSiteDnsVs";
export const DNSConfig = "DNSConfig";
export const GslbGeoDbProfile = "GslbGeoDbProfile";
export const GslbGeoDbEntry = "GslbGeoDbEntry";
export const GslbGeoDbFile = "GslbGeoDbFile";
export const GslbPoolMember = "GslbPoolMember";
export const GslbService = "GslbService";
export const GslbServiceDownResponse = "GslbServiceDownResponse";
export const GslbPool = "GslbPool";
export const HardwareSecurityModuleGroup = "HardwareSecurityModuleGroup";
export const HardwareSecurityModule = "HardwareSecurityModule";
export const HSMAwsCloudHsm = "HSMAwsCloudHsm";
export const HSMSafenetLuna = "HSMSafenetLuna";
export const HSMSafenetClientInfo = "HSMSafenetClientInfo";
export const HSMSafenetLunaServer = "HSMSafenetLunaServer";
export const HSMThalesNetHsm = "HSMThalesNetHsm";
export const HSMThalesRFS = "HSMThalesRFS";
export const HealthMonitor = "HealthMonitor";
export const HealthMonitorSctp = "HealthMonitorSctp";
export const HealthMonitorLdap = "HealthMonitorLdap";
export const HealthMonitorSSLAttributes = "HealthMonitorSSLAttributes";
export const HealthMonitorFtp = "HealthMonitorFtp";
export const HealthMonitorImap = "HealthMonitorImap";
export const HealthMonitorPop3 = "HealthMonitorPop3";
export const HealthMonitorSmtp = "HealthMonitorSmtp";
export const HealthMonitorAuthInfo = "HealthMonitorAuthInfo";
export const HealthMonitorRadius = "HealthMonitorRadius";
export const HealthMonitorSIP = "HealthMonitorSIP";
export const HealthMonitorDNS = "HealthMonitorDNS";
export const HealthMonitorUdp = "HealthMonitorUdp";
export const HealthMonitorExternal = "HealthMonitorExternal";
export const HealthMonitorHttp = "HealthMonitorHttp";
export const HealthMonitorTcp = "HealthMonitorTcp";
export const HttpCookiePersistenceKey = "HttpCookiePersistenceKey";
export const HTTPPolicySet = "HTTPPolicySet";
export const HTTPRequestPolicy = "HTTPRequestPolicy";
export const HTTPRequestRule = "HTTPRequestRule";
export const HTTPSwitchingAction = "HTTPSwitchingAction";
export const PoolServer = "PoolServer";
export const HTTPLocalFile = "HTTPLocalFile";
export const HTTPHdrAction = "HTTPHdrAction";
export const HTTPCookieData = "HTTPCookieData";
export const HTTPHdrData = "HTTPHdrData";
export const HTTPHdrValue = "HTTPHdrValue";
export const HTTPRedirectAction = "HTTPRedirectAction";
export const URIParam = "URIParam";
export const URIParamToken = "URIParamToken";
export const HTTPResponsePolicy = "HTTPResponsePolicy";
export const HTTPSecurityPolicy = "HTTPSecurityPolicy";
export const HTTPSecurityRule = "HTTPSecurityRule";
export const HTTPSecurityAction = "HTTPSecurityAction";
export const HTTPSecurityActionRateProfile = "HTTPSecurityActionRateProfile";
export const RateLimiterAction = "RateLimiterAction";
export const IcapProfile = "IcapProfile";
export const IcapNsxDefenderConfig = "IcapNsxDefenderConfig";
export const Image = "Image";
export const ImageEventMap = "ImageEventMap";
export const ImageEvent = "ImageEvent";
export const ImageUploadOpsStatus = "ImageUploadOpsStatus";
export const TimeStamp = "TimeStamp";
export const ImageCloudData = "ImageCloudData";
export const ImageCloudSpecificData = "ImageCloudSpecificData";
export const IpAddrGroup = "IpAddrGroup";
export const IpamDnsProviderProfile = "IpamDnsProviderProfile";
export const IpamDnsAzureProfile = "IpamDnsAzureProfile";
export const IpamDnsCustomProfile = "IpamDnsCustomProfile";
export const CustomIpamSubnet = "CustomIpamSubnet";
export const IpamDnsGCPProfile = "IpamDnsGCPProfile";
export const IpamDnsInternalProfile = "IpamDnsInternalProfile";
export const IpamUsableNetwork = "IpamUsableNetwork";
export const KeyValueTuple = "KeyValueTuple";
export const DnsServiceDomain = "DnsServiceDomain";
export const IpamDnsOpenstackProfile = "IpamDnsOpenstackProfile";
export const IpamDnsAwsProfile = "IpamDnsAwsProfile";
export const AwsZoneNetwork = "AwsZoneNetwork";
export const IpamDnsInfobloxProfile = "IpamDnsInfobloxProfile";
export const InfobloxSubnet = "InfobloxSubnet";
export const IPReputationDB = "IPReputationDB";
export const IPReputationServiceStatus = "IPReputationServiceStatus";
export const JWTServerProfile = "JWTServerProfile";
export const ControllerInternalAuth = "ControllerInternalAuth";
export const JWSKey = "JWSKey";
export const L4ConnectionPolicy = "L4ConnectionPolicy";
export const L4Rule = "L4Rule";
export const L4RuleAction = "L4RuleAction";
export const L4RuleActionSelectPool = "L4RuleActionSelectPool";
export const L4RuleMatchTarget = "L4RuleMatchTarget";
export const L4RuleProtocolMatch = "L4RuleProtocolMatch";
export const L4RulePortMatch = "L4RulePortMatch";
export const PortRange = "PortRange";
export const L4PolicySet = "L4PolicySet";
export const NatPolicy = "NatPolicy";
export const NatRule = "NatRule";
export const NatPolicyAction = "NatPolicyAction";
export const NatAddrInfo = "NatAddrInfo";
export const NatMatchTarget = "NatMatchTarget";
export const ServiceMatch = "ServiceMatch";
export const Network = "Network";
export const KeyValue = "KeyValue";
export const Subnet = "Subnet";
export const StaticIpRange = "StaticIpRange";
export const NetworkProfile = "NetworkProfile";
export const NetworkProfileUnion = "NetworkProfileUnion";
export const SCTPFastPathProfile = "SCTPFastPathProfile";
export const SCTPProxyProfile = "SCTPProxyProfile";
export const UDPProxyProfile = "UDPProxyProfile";
export const UDPFastPathProfile = "UDPFastPathProfile";
export const DsrProfile = "DsrProfile";
export const TCPFastPathProfile = "TCPFastPathProfile";
export const TCPProxyProfile = "TCPProxyProfile";
export const NetworkSecurityPolicy = "NetworkSecurityPolicy";
export const NetworkSecurityRule = "NetworkSecurityRule";
export const NetworkSecurityPolicyActionRLParam = "NetworkSecurityPolicyActionRLParam";
export const NetworkSecurityMatchTarget = "NetworkSecurityMatchTarget";
export const MicroServiceMatch = "MicroServiceMatch";
export const OrgServiceUnits = "OrgServiceUnits";
export const OsLbProvPluginForm = "OsLbProvPluginForm";
export const PingAccessAgent = "PingAccessAgent";
export const PKIProfile = "PKIProfile";
export const CRL = "CRL";
export const Pool = "Pool";
export const SpGslbServiceInfo = "SpGslbServiceInfo";
export const HorizonProfile = "HorizonProfile";
export const HTTP2PoolProperties = "HTTP2PoolProperties";
export const ConnPoolProperties = "ConnPoolProperties";
export const PoolAnalyticsPolicy = "PoolAnalyticsPolicy";
export const HTTPServerReselect = "HTTPServerReselect";
export const HTTPReselectRespCode = "HTTPReselectRespCode";
export const FailAction = "FailAction";
export const FailActionHTTPLocalResponse = "FailActionHTTPLocalResponse";
export const FailActionHTTPRedirect = "FailActionHTTPRedirect";
export const PlacementNetwork = "PlacementNetwork";
export const NetworkFilter = "NetworkFilter";
export const PoolGroup = "PoolGroup";
export const PoolGroupMember = "PoolGroupMember";
export const PoolGroupDeploymentPolicy = "PoolGroupDeploymentPolicy";
export const PGDeploymentRule = "PGDeploymentRule";
export const PortalFeatureOptIn = "PortalFeatureOptIn";
export const ProtocolParser = "ProtocolParser";
export const RateLimiterProfile = "RateLimiterProfile";
export const RateLimiterProperties = "RateLimiterProperties";
export const RateProfile = "RateProfile";
export const Role = "Role";
export const RoleFilter = "RoleFilter";
export const Permission = "Permission";
export const SubResource = "SubResource";
export const SaasLicensingInfo = "SaasLicensingInfo";
export const Scheduler = "Scheduler";
export const SecurityPolicy = "SecurityPolicy";
export const SeGroupOptions = "SeGroupOptions";
export const Server = "Server";
export const DiscoveredNetwork = "DiscoveredNetwork";
export const ServerAutoScalePolicy = "ServerAutoScalePolicy";
export const ScheduledScaling = "ScheduledScaling";
export const ServiceEngine = "ServiceEngine";
export const vNIC = "vNIC";
export const MemberInterface = "MemberInterface";
export const VlanInterface = "VlanInterface";
export const vNICNetwork = "vNICNetwork";
export const SeResources = "SeResources";
export const ServiceEngineGroup = "ServiceEngineGroup";
export const SETimeTrackerProperties = "SETimeTrackerProperties";
export const KniPortRange = "KniPortRange";
export const ObjSyncConfig = "ObjSyncConfig";
export const GCPSeGroupConfig = "GCPSeGroupConfig";
export const SeGroupAnalyticsPolicy = "SeGroupAnalyticsPolicy";
export const MetricsEventThreshold = "MetricsEventThreshold";
export const CloudFlavor = "CloudFlavor";
export const CloudMeta = "CloudMeta";
export const PlacementScopeConfig = "PlacementScopeConfig";
export const ClusterHAConfig = "ClusterHAConfig";
export const NsxtClusters = "NsxtClusters";
export const NsxtDatastores = "NsxtDatastores";
export const NsxtHosts = "NsxtHosts";
export const VipAutoscaleGroup = "VipAutoscaleGroup";
export const VipAutoscaleConfiguration = "VipAutoscaleConfiguration";
export const VipAutoscaleZones = "VipAutoscaleZones";
export const IptableRuleSet = "IptableRuleSet";
export const IptableRule = "IptableRule";
export const MetricsRealTimeUpdate = "MetricsRealTimeUpdate";
export const VcenterDatastore = "VcenterDatastore";
export const SnmpTrapProfile = "SnmpTrapProfile";
export const SnmpTrapServer = "SnmpTrapServer";
export const SnmpV3UserParams = "SnmpV3UserParams";
export const SSLCertificate = "SSLCertificate";
export const SSLKeyParams = "SSLKeyParams";
export const SSLKeyECParams = "SSLKeyECParams";
export const SSLKeyRSAParams = "SSLKeyRSAParams";
export const SSLCertificateDescription = "SSLCertificateDescription";
export const SSLCertificateRequest = "SSLCertificateRequest";
export const SSLKeyAndCertificate = "SSLKeyAndCertificate";
export const OCSPResponseInfo = "OCSPResponseInfo";
export const OCSPConfig = "OCSPConfig";
export const CertificateAuthority = "CertificateAuthority";
export const SSLKeyAndCertificateImport = "SSLKeyAndCertificateImport";
export const SSLProfile = "SSLProfile";
export const SSLRating = "SSLRating";
export const Tag = "Tag";
export const SSLVersion = "SSLVersion";
export const SSOPolicy = "SSOPolicy";
export const AuthenticationPolicy = "AuthenticationPolicy";
export const AuthenticationRule = "AuthenticationRule";
export const AuthenticationAction = "AuthenticationAction";
export const AuthenticationMatch = "AuthenticationMatch";
export const StaticRoute = "StaticRoute";
export const StringGroup = "StringGroup";
export const SysTestEmailParams = "SysTestEmailParams";
export const SystemConfiguration = "SystemConfiguration";
export const ControllerAnalyticsPolicy = "ControllerAnalyticsPolicy";
export const SecureChannelConfiguration = "SecureChannelConfiguration";
export const MgmtIpAccessControl = "MgmtIpAccessControl";
export const LinuxConfiguration = "LinuxConfiguration";
export const SnmpConfiguration = "SnmpConfiguration";
export const SnmpV3Configuration = "SnmpV3Configuration";
export const AdminAuthConfiguration = "AdminAuthConfiguration";
export const RemoteAuthConfiguration = "RemoteAuthConfiguration";
export const EmailConfiguration = "EmailConfiguration";
export const TenantConfiguration = "TenantConfiguration";
export const PortalConfiguration = "PortalConfiguration";
export const DNSConfiguration = "DNSConfiguration";
export const Tenant = "Tenant";
export const TrafficCloneProfile = "TrafficCloneProfile";
export const CloneServer = "CloneServer";
export const UpgradeOpsState = "UpgradeOpsState";
export const UpgradeParams = "UpgradeParams";
export const UserAccountProfile = "UserAccountProfile";
export const VcenterInventoryDiagRsp = "VcenterInventoryDiagRsp";
export const VcenterNetworkDiag = "VcenterNetworkDiag";
export const VcenterMgmtNwDiag = "VcenterMgmtNwDiag";
export const VcenterHostDiag = "VcenterHostDiag";
export const VcenterClusters = "VcenterClusters";
export const VcenterHosts = "VcenterHosts";
export const VCenterServer = "VCenterServer";
export const VIMgrClusterRuntime = "VIMgrClusterRuntime";
export const VIMgrHostRuntime = "VIMgrHostRuntime";
export const CdpLldpInfo = "CdpLldpInfo";
export const VipAutoscalePolicy = "VipAutoscalePolicy";
export const VirtualService = "VirtualService";
export const OAuthVSConfig = "OAuthVSConfig";
export const OAuthSettings = "OAuthSettings";
export const OAuthResourceServer = "OAuthResourceServer";
export const JWTValidationParams = "JWTValidationParams";
export const OpaqueTokenValidationParams = "OpaqueTokenValidationParams";
export const OAuthAppSettings = "OAuthAppSettings";
export const OIDCConfig = "OIDCConfig";
export const LDAPVSConfig = "LDAPVSConfig";
export const VHMatch = "VHMatch";
export const VHMatchRule = "VHMatchRule";
export const JWTValidationVsConfig = "JWTValidationVsConfig";
export const SAMLSPConfig = "SAMLSPConfig";
export const SSLProfileSelector = "SSLProfileSelector";
export const L4Policies = "L4Policies";
export const Vip = "Vip";
export const VipPlacementNetwork = "VipPlacementNetwork";
export const IPNetworkSubnet = "IPNetworkSubnet";
export const SidebandProfile = "SidebandProfile";
export const DnsInfo = "DnsInfo";
export const DnsRecord = "DnsRecord";
export const DnsSrvRdata = "DnsSrvRdata";
export const ServicePoolSelector = "ServicePoolSelector";
export const DnsPolicies = "DnsPolicies";
export const VSDataScripts = "VSDataScripts";
export const AnalyticsPolicy = "AnalyticsPolicy";
export const LearningLogPolicy = "LearningLogPolicy";
export const ClientInsightsSampling = "ClientInsightsSampling";
export const ClientLogFilter = "ClientLogFilter";
export const FullClientLogs = "FullClientLogs";
export const PerformanceLimits = "PerformanceLimits";
export const HTTPPolicies = "HTTPPolicies";
export const Service = "Service";
export const VrfContext = "VrfContext";
export const BfdProfile = "BfdProfile";
export const DebugVrfContext = "DebugVrfContext";
export const DebugVrf = "DebugVrf";
export const InternalGatewayMonitor = "InternalGatewayMonitor";
export const BgpProfile = "BgpProfile";
export const BgpRoutingOptions = "BgpRoutingOptions";
export const IpCommunity = "IpCommunity";
export const BgpPeer = "BgpPeer";
export const VSDataScriptSet = "VSDataScriptSet";
export const VSDataScript = "VSDataScript";
export const VssPlacement = "VssPlacement";
export const VsVip = "VsVip";
export const Selector = "Selector";
export const WafApplicationSignatures = "WafApplicationSignatures";
export const WafRule = "WafRule";
export const WafExcludeListEntry = "WafExcludeListEntry";
export const WafExclusionType = "WafExclusionType";
export const WafRuleOverrides = "WafRuleOverrides";
export const WafApplicationSignatureProvider = "WafApplicationSignatureProvider";
export const WebApplicationSignatureServiceStatus = "WebApplicationSignatureServiceStatus";
export const WafApplicationSignatureAppVersion = "WafApplicationSignatureAppVersion";
export const WafCRS = "WafCRS";
export const WafRuleGroup = "WafRuleGroup";
export const WafLog = "WafLog";
export const OmittedWafLogStats = "OmittedWafLogStats";
export const WafAllowlistLog = "WafAllowlistLog";
export const WafRuleLog = "WafRuleLog";
export const WafRuleMatchData = "WafRuleMatchData";
export const WafPSMLog = "WafPSMLog";
export const WafPolicy = "WafPolicy";
export const WafPolicyRequiredDataFile = "WafPolicyRequiredDataFile";
export const WafRuleGroupOverrides = "WafRuleGroupOverrides";
export const WafPolicyAllowlist = "WafPolicyAllowlist";
export const WafPolicyAllowlistRule = "WafPolicyAllowlistRule";
export const AppLearningConfidenceOverride = "AppLearningConfidenceOverride";
export const AppLearningParams = "AppLearningParams";
export const WafPositiveSecurityModel = "WafPositiveSecurityModel";
export const WafPolicyPSMGroup = "WafPolicyPSMGroup";
export const WafPSMLocation = "WafPSMLocation";
export const WafPSMRule = "WafPSMRule";
export const WafPSMMatchElement = "WafPSMMatchElement";
export const WafPSMLocationMatch = "WafPSMLocationMatch";
export const WafProfile = "WafProfile";
export const WafDataFile = "WafDataFile";
export const WafConfig = "WafConfig";
export const WafContentTypeMapping = "WafContentTypeMapping";
export const Webhook = "Webhook";
