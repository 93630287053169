/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import type {
    Certificate,
} from 'ajs/modules/security/factories/certificates/certificate.item.factory';

import { TOP_RIGHT_CONNECTED_POSITION }
    from 'ng/modules/tooltip/directives/avi-tooltip/avi-tooltip.constants';

import { ConnectedPosition } from '@angular/cdk/overlay';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ssl-certificate-grid-name-column.l10n';
import './ssl-certificate-grid-name-column.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Ssl Certificate Grid Name Column.
 *
 * @author Manideep Kotha
 */

@Component({
    selector: 'ssl-certificate-grid-name-column',
    templateUrl: './ssl-certificate-grid-name-column.component.html',
})

export class SslCertificateGridNameColumnComponent implements OnInit {
    /**
     * Certificate Item.
     */
    @Input()
    public certificate: Certificate;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * User dropdown/tooltip position strategy.
     */
    public readonly tooltipPositions: [ConnectedPosition] = [TOP_RIGHT_CONNECTED_POSITION];

    /**
     * Name of the missing certificate issuer name.
     */
    public missingCertIssuerName: string;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.missingCertIssuerName = this.certificate.getMissingCertIssuerName();
    }
}
