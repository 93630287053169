/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import 'ajs/less/components/file.less';
import * as l10n from './file.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @restrict E
 * @name file
 * @param {string=} label - Value for file name.
 * @deprecated
 * @see fileUpload
 */
angular.module('aviApp').directive('file', [
'$timeout', 'l10nService',
function($timeout, l10nService) {
    l10nService.registerSourceBundles(dictionary);

    /** @alias file */
    function fileLink(scope, elm) {
        scope.l10nKeys = l10nKeys;

        elm.on('change', event => {
            $timeout(() => {
                [scope.file] = event.target.files;
                scope.label = scope.file.name;

                if (angular.isFunction(scope.onChange)) {
                    scope.onChange({ file: scope.file });
                }
            });
        });
    }

    return {
        scope: {
            label: '=?',
            file: '=',
            isRequired: '<?',
            onChange: '&?',
            buttonText: '@?',
        },
        restrict: 'E',
        templateUrl: 'src/views/components/file.html',
        link: fileLink,
    };
}]);
