/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import cron from 'cron-validate';

export const CRON_EXPRESSION_VALIDATOR_KEY = 'cronExpressionValidation';

export const cronValidatePreset = {
    preset: 'default',
    override: {
        useYears: false, // 6-part expressions not allowed
        useSeconds: false, // 7-part expressions not allowed
        useNthWeekdayOfMonth: false, // '2nd Tuesday of month' type scenarios not supported yet
        useAliases: true, // terms such as TUE, JAN etc allowed
        useBlankDay: true, // '?' allowed
    },
};

/**
 * Returns validatorFunction for cronExpressionValidation directive.
 *
 * @author Harmeet Kaur
 */
export function cronExpressionValidator(): ValidatorFn {
    /**
     * Returns null if it's a valid value.
     * Returns validationError if input is invalid.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        const errorObject = {
            [CRON_EXPRESSION_VALIDATOR_KEY]: value,
        };

        return isValidCronExpression(value) ? null : errorObject;
    };
}

/**
 * Validates the given cron expression string.
 * Returns true for valid and false for invalid expression.
 *
 * Filter out below non-supported scenarios:
 *  1. Only 5-part expressions allowed
 *  2. 'daysOfWeek' field cannot have # (handled in preset).
 *  3. 'months' and 'daysOfMonth' fields value should be greater than 0.
 *  4. Ranges, lists and combination of both allowed only with numbers, not names.
 *  http://crontab.org/ : Refer for all the allowed and not allowed formats.
 */
export function isValidCronExpression(expression: string): boolean {
    const cronObj = cron(expression, cronValidatePreset);

    // Check against the preset values.
    if (cronObj.isError()) {
        return false;
    }

    const {
        daysOfMonth,
        months,
        daysOfWeek,
    } = cronObj.getValue();

    // Check if aliases are being used while providing ranges or lists
    if (checkAliasesInRangeList(months) || checkAliasesInRangeList(daysOfWeek)) {
        return false;
    }

    /**
     * This condition is missed by the cron-validate library.
     * We can override the lower limit for these fields in the preset,
     * but in that case the library does not support wildcards,
     * hence it seems a better option to check this value manually.
     */
    if (!checkLowerLimit(daysOfMonth, 1) || !checkLowerLimit(months, 1)) {
        return false;
    }

    return true;
}

/**
 * Returns true if a list/range of values has aliases present in it.
 * Only numeric values can have list/range.
 */
function checkAliasesInRangeList(value: string): boolean {
    // Check if either range or list is present.
    if (value.includes('-') || value.includes(',')) {
        // Check if any alphabets are present in the range/list.
        const matchedValue = value.match('[a-zA-Z]+');

        if (matchedValue !== null) {
            return true;
        }
    }

    return false;
}

/**
 * Returns true if given field has value(s) above provided lower limit.
 */
function checkLowerLimit(fieldValue: string, lowerLimit: number): boolean {
    let lowerValue = fieldValue;

    if (fieldValue.includes('-')) {
        lowerValue = fieldValue.split('-')[0];
    } else if (fieldValue.includes(',')) {
        lowerValue = fieldValue.split(',').sort()[0];
    }

    const parsedLowerValue = parseInt(lowerValue, 10);

    return Number.isNaN(parsedLowerValue) ?
        true :
        parsedLowerValue >= lowerLimit;
}
