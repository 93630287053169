/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'auth-attribute-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const authAttributeMatchLabel = `${prefix}.authAttributeMatchLabel`;

export const ENGLISH = {
    [authAttributeMatchLabel]: 'Attribute',
};
