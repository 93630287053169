/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';

import {
    IIpAddr,
    IIpAddrMatch,
    IIpAddrPrefix,
    IIpAddrRange,
    IMgmtIpAccessControl,
    IpAddrType,
    MatchOperation,
} from 'generated-types';

import {
    ip,
    ipAddrRange,
    ipv6,
    ipv6Prefix,
    ipv6Range,
    subnet,
} from 'ng/utils/regex.utils';

import {
    IpAddrGroupCollection,
} from 'ajs/modules/groups/factories/ip-addr-group/ip-addr-group.collection.factory';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import * as l10n from './system-settings-client-management-access-config-container.l10n';
import './system-settings-client-management-access-config-container.component.less';

import {
    AllowedClientTypesEnumValues,
    IAllowedClientsConfig,
    IAllowedValue,
} from './system-settings-client-management-access-config-container.types';

type TIpAddrGroupCollection = typeof IpAddrGroupCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Component for Client Management Access Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-client-management-access-config-container',
    templateUrl: './system-settings-client-management-access-config-container.component.html',
})
export class SystemSettingsClientManagementAccessConfigContainerComponent {
    /**
     * Management IP Access control of controller.
     */
    @Input()
    public mgmtIPAccessControl: IMgmtIpAccessControl;

    /**
     * Ssh access config used in template.
     */
    public allowedSshClientsConfig: IAllowedClientsConfig;

    /**
     * Cli shell access config used in template.
     */
    public allowedCliShellClientsConfig: IAllowedClientsConfig;

    /**
     * External HTTPs access config used in template.
     */
    public allowedExternalHttpsClientsConfig: IAllowedClientsConfig;

    /**
     * External SNMP access config used in template.
     */
    public allowedExternalSnmpClientsConfig: IAllowedClientsConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Collection object to store the IpAddrGroup collection.
     */
    public readonly ipAddrGroupCollection: IpAddrGroupCollection;

    /**
     * Allowed client types hashmap
     */
    public readonly allowedClientTypesEnumValues = {
        [AllowedClientTypesEnumValues.ssh]: AllowedClientTypesEnumValues.ssh,
        [AllowedClientTypesEnumValues.cli]: AllowedClientTypesEnumValues.cli,
        [AllowedClientTypesEnumValues.https]: AllowedClientTypesEnumValues.https,
        [AllowedClientTypesEnumValues.snmp]: AllowedClientTypesEnumValues.snmp,
    };

    /**
     * Reference for classname to access its static properties.
     */
    private readonly staticProp = SystemSettingsClientManagementAccessConfigContainerComponent;

    constructor(
        l10nService: L10nService,
        @Inject(IpAddrGroupCollection)
        IpAddrGroupCollection: TIpAddrGroupCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.ipAddrGroupCollection = new IpAddrGroupCollection();
    }

    /**
     * A helper method, to categorize a string into ipv4, ipv6 or neither of two types.
     */
    private static getIpVersion(ipAddr: string): IpAddrType {
        let version: IpAddrType;

        if (ip.test(ipAddr)) {
            version = IpAddrType.V4;
        } else if (ipv6.test(ipAddr)) {
            version = IpAddrType.V6;
        }

        return version;
    }

    /**
     * A helper method, to check if a string is in any (IPv4 or IPv6) IP format.
     */
    private static isAnyIpAddress(addr: string): boolean {
        return ip.test(addr) || ipv6.test(addr);
    }

    /**
     * A helper method, to check if a string is in any (IPv4 or IPv6) subnet format.
     */
    private static isSubnet(addr: string): boolean {
        return subnet.test(addr) || ipv6Prefix.test(addr);
    }

    /**
     * A helper method, to check if a string is in any (IPv4 or IPv6) IP range format.
     */
    private static isIpRange(addr: string): boolean {
        return ipAddrRange.test(addr) || ipv6Range.test(addr);
    }

    /**
     * @override
     * Sets allowed clients config.
     */
    public ngOnInit(): void {
        this.allowedSshClientsConfig = this.getAllowedClients(this.mgmtIPAccessControl.ssh_access);
        this.allowedCliShellClientsConfig = this.getAllowedClients(
            this.mgmtIPAccessControl.shell_server_access,
        );
        this.allowedExternalHttpsClientsConfig = this.getAllowedClients(
            this.mgmtIPAccessControl.api_access,
        );
        this.allowedExternalSnmpClientsConfig = this.getAllowedClients(
            this.mgmtIPAccessControl.snmp_access,
        );
    }

    /**
     * Sets management ip access control on change detected in values.
     */
    public setMgmtIpAccessControl(): void {
        this.mgmtIPAccessControl.ssh_access = this.getAccessConfig(this.allowedSshClientsConfig);
        this.mgmtIPAccessControl.shell_server_access = this.getAccessConfig(
            this.allowedCliShellClientsConfig,
        );
        this.mgmtIPAccessControl.api_access = this.getAccessConfig(
            this.allowedExternalHttpsClientsConfig,
        );
        this.mgmtIPAccessControl.snmp_access = this.getAccessConfig(
            this.allowedExternalSnmpClientsConfig,
        );
    }

    /**
     * Gets access config matched with IIpAddrMatch.
     */
    private getAccessConfig(allowedClientsConfig: IAllowedClientsConfig): IIpAddrMatch {
        if (allowedClientsConfig.areAnyValuesAllowed) {
            return;
        }

        const emptyAccess: IIpAddrMatch = {
            match_criteria: MatchOperation.IS_IN,
            addrs: [],
            group_refs: [],
            prefixes: [],
            ranges: [],
        };

        const accessConfig = allowedClientsConfig.allowedValues.reduce(
            (access: IIpAddrMatch, value: IAllowedValue): IIpAddrMatch => {
                if (value.value) {
                    const isAnyIpAddress = this.staticProp.isAnyIpAddress(value.value);
                    const isSubnet = this.staticProp.isSubnet(value.value);
                    const isIpRange = this.staticProp.isIpRange(value.value);

                    switch (true) {
                        case isAnyIpAddress: {
                            access.addrs.push({
                                addr: value.value,
                                type: this.staticProp.getIpVersion(value.value),
                            });
                            break;
                        }

                        case isSubnet: {
                            const [ipAddr, mask] = value.value.split('/');

                            access.prefixes.push({
                                ip_addr: {
                                    addr: ipAddr,
                                    type: this.staticProp.getIpVersion(ipAddr),
                                },
                                mask: Number(mask),
                            });
                            break;
                        }

                        case isIpRange: {
                            const [ipAddrBegin, ipAddrEnd] = value.value.split('-');

                            access.ranges.push({
                                begin: {
                                    addr: ipAddrBegin,
                                    type: this.staticProp.getIpVersion(ipAddrBegin),
                                },
                                end: {
                                    addr: ipAddrEnd,
                                    type: this.staticProp.getIpVersion(ipAddrEnd),
                                },
                            });
                            break;
                        }

                        case value.isGroupRef: {
                            access.group_refs.push(
                                value.value,
                            );
                            break;
                        }
                    }
                }

                return access;
            }, emptyAccess,
        );

        return accessConfig;
    }

    /**
     * Gets all types allowed clients, like client network refs, client addrs,
     * client prefixes and client ranges.
     */
    private getAllowedClients(allowedClientsAccess: IIpAddrMatch): IAllowedClientsConfig {
        if (!allowedClientsAccess) {
            const defaultClientManagementAccess: IAllowedClientsConfig = {
                areAnyValuesAllowed: true,
                allowedValues: [],
            };

            return defaultClientManagementAccess;
        }

        const {
            group_refs: groupRefs = [],
            addrs = [],
            prefixes = [],
            ranges = [],
        } = allowedClientsAccess;

        const allowedClientsGroupRefs = groupRefs.map((ref: string): IAllowedValue => ({
            value: ref,
            isGroupRef: true,
        }));

        const allowedClientAddrs = addrs.map((addr: IIpAddr): IAllowedValue => ({
            value: addr.addr,
            isGroupRef: false,
        }));

        const allowedClientPrefixes = prefixes.map((prefix: IIpAddrPrefix): IAllowedValue => ({
            value: [prefix.ip_addr.addr, prefix.mask].join('/'),
            isGroupRef: false,
        }));

        const allowedClientRanges = ranges.map((range: IIpAddrRange): IAllowedValue => ({
            value: [range.begin.addr, range.end.addr].join('-'),
            isGroupRef: false,
        }));

        const areAnyValuesAllowed = !(allowedClientsGroupRefs.length + allowedClientAddrs.length +
            allowedClientPrefixes.length + allowedClientRanges.length);

        const allowedClients: IAllowedClientsConfig = {
            areAnyValuesAllowed,
            allowedValues: [
                ...allowedClientAddrs,
                ...allowedClientPrefixes,
                ...allowedClientRanges,
                ...allowedClientsGroupRefs,
            ],
        };

        return allowedClients;
    }
}
