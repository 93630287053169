/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'authentication-config';
const prefix = `${moduleName}.${componentName}`;

export const remoteAuthType = `${prefix}.remoteAuthType`;
export const localAuthType = `${prefix}.localAuthType`;
export const enableLocalUserLoginLabel = `${prefix}.enableLocalUserLoginLabel`;
export const mappingRulesGridHeader = `${prefix}.mappingRulesGridHeader`;

export const ENGLISH = {
    [remoteAuthType]: 'Remote',
    [localAuthType]: 'Local',
    [enableLocalUserLoginLabel]: 'Enable Local User Login',
    [mappingRulesGridHeader]: 'Auth Profiles & Mapping Profiles ({0})',
};
