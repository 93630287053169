/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IRoleNode } from 'ajs/modules/accounts/services/role-service/role.types';
import './role-access-expander.component.less';

import * as l10n from './role-access-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Role Access Expander.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'role-access-expander',
    templateUrl: './role-access-expander.component.html',
})
export class RoleAccessExpanderComponent implements OnInit {
    /**
     * Privileges which need to be shown in accordian.
     */
    @Input()
    public privileges: IRoleNode[];

    /**
     * Stores filtered list of privileges.
     */
    public filteredPrivileges: IRoleNode[];

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.filteredPrivileges = this.privileges;
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Handler for search Input change. Called when the user types in the search input.
     */
    public handleSearch(searchTerm: string): void {
        if (!searchTerm) {
            this.filteredPrivileges = this.privileges;

            return;
        }

        this.filteredPrivileges = this.privileges
            .reduce((filteredList: IRoleNode[], privilege: IRoleNode) => {
                // If parent's name matches then add parent along with all childrens.
                if (this.isPrivilegeContainsSearchTerm(privilege, searchTerm)) {
                    filteredList.push(privilege);
                } else {
                // If any child element matches then add parent along with matching child element.
                    const childPrivileges = privilege.children.filter((child: IRoleNode) =>
                        this.isPrivilegeContainsSearchTerm(child, searchTerm));

                    if (childPrivileges?.length) {
                        filteredList.push({
                            ...privilege,
                            children: childPrivileges,
                        });
                    }
                }

                return filteredList;
            }, []);
    }

    /**
     * Checks if privilege name contains searchTerm.
     */
    private isPrivilegeContainsSearchTerm(privilege: IRoleNode, searchTerm: string): boolean {
        return privilege.name.toLowerCase().includes(searchTerm.toLowerCase());
    }
}
