/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'newline-converted-textarea';
const prefix = `${moduleName}.${componentName}`;

export const userInputLabel = `${prefix}.userInputLabel`;
export const convertedValuePreviewLabel = `${prefix}.convertedValuePreviewLabel`;
export const wordwrapTooltipText = `${prefix}.wordwrapTooltipText`;
export const singleLineTooltipText = `${prefix}.singleLineTooltipText`;
export const copyToClipboardTooltipText = `${prefix}.copyToClipboardTooltipText`;
export const copiedTooltipText = `${prefix}.copiedTooltipText`;

export const ENGLISH = {
    [userInputLabel]: 'User Input',
    [convertedValuePreviewLabel]: 'Converted Value Preview',
    [wordwrapTooltipText]: 'Wordwrap',
    [singleLineTooltipText]: 'Single Line',
    [copyToClipboardTooltipText]: 'Copy to clipboard',
    [copiedTooltipText]: 'Copied',
};
