/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-create-root';
const prefix = `${moduleName}.${componentName}`;

export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const validateButtonLabel = `${prefix}.validateButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const certificateFileInputPlaceholder = `${prefix}.certificateFileInputPlaceholder`;
export const certificateFileInputLabel = `${prefix}.certificateFileInputLabel`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const sectionTitleCertificate = `${prefix}.sectionTitleCertificate`;
export const sectionTitleOcspSampling = `${prefix}.sectionTitleOcspSampling`;

export const ENGLISH = {
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [validateButtonLabel]: 'Validate',
    [saveButtonLabel]: 'Save',
    [modalTitleEdit]: 'Edit Certificate (SSL/TLS)',
    [modalTitleNew]: 'New Certificate (SSL/TLS)',
    [certificateFileInputPlaceholder]: 'Paste Certificate',
    [certificateFileInputLabel]: 'Upload or Paste Certificate File',
    [sectionTitleGeneral]: 'General',
    [sectionTitleCertificate]: 'Certificate',
    [sectionTitleOcspSampling]: 'OCSP',
};
