/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { take, withLatestFrom } from 'rxjs/operators';
import { VsLogsStore } from '../../../../services/vs-logs.store';
import { findSavedSearch } from '../../../../utils/vs-logs-filters.utils';

import * as l10n from './vs-logs-save-search-modal.l10n';
import './vs-logs-save-search-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Types corresponding to whether modal is for creating or editing.
 */
export enum SaveSearchType {
    CREATE,
    EDIT,
}

/**
 * @description Modal to save filter searches.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vs-logs-save-search-modal',
    templateUrl: './vs-logs-save-search-modal.component.html',
})
export class VsLogsSaveSearchModalComponent implements OnInit {
    /**
     * Event emitter to close the modal.
     */
    @Output()
    public readonly onClose = new EventEmitter<void>();

    @Input()
    public readonly saveSearchType: SaveSearchType;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * NgModel value of the search name the user is typing.
     */
    public searchName = '';

    /**
     * Indicate that there is an error.
     */
    public hasError = false;

    /**
     * Name of search to update if it is an edit.
     */
    public searchNameToUpdate?: string;

    constructor(
        l10nService: L10nService,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.saveSearchType === SaveSearchType.EDIT) {
            this.initEdit();
        }
    }

    /**
     * Set isError to false.
     */
    public resetError(): void {
        this.hasError = false;
    }

    /**
     * Check if name has already been taken.
     */
    public isNameFree(): boolean {
        let free: boolean;

        this.vsLogsStore.savedSearches$.pipe(take(1)).subscribe(savedSearches => {
            free = savedSearches[this.searchName] === undefined;
        });

        return free;
    }

    /**
     * Save the search.
     */
    public saveSearch(): void {
        // ignore empty inputs
        if (!this.searchName) {
            return;
        }

        switch (this.saveSearchType) {
            case SaveSearchType.CREATE:
                if (this.isNameFree()) {
                    this.vsLogsStore.addSavedSearch(this.searchName);
                    this.closeSaveSearchModal();
                } else {
                    this.hasError = true;
                }

                break;

            case SaveSearchType.EDIT:
                if (this.isNameFree() || this.searchName === this.searchNameToUpdate) {
                    this.vsLogsStore.editSavedSearch(this.searchName, this.searchNameToUpdate);
                    this.closeSaveSearchModal();
                } else {
                    this.hasError = true;
                }

                break;
        }
    }

    /**
     * Emit the close modal event.
     */
    public closeSaveSearchModal(): void {
        this.onClose.emit();
    }

    /**
     * Stop propogation of modal clicks to know when backdrop is clicked.
     */
    // eslint-disable-next-line class-methods-use-this
    public handleModalClick(e: MouseEvent): void {
        e.stopPropagation();
    }

    /**
     * For modals that are editing an existing search,
     * set the search name and search name to update from the store.
     */
    private initEdit(): void {
        this.vsLogsStore.filters$.pipe(
            take(1),
            withLatestFrom(this.vsLogsStore.savedSearches$),
        ).subscribe(([filters, savedSearches]) => {
            const name = findSavedSearch(filters, savedSearches);

            this.searchName = name;
            this.searchNameToUpdate = name;
        });
    }
}
