/**
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './license-summary-card.component.less';

import {
    Component,
    Inject,
    Input,
} from '@angular/core';

import {
    IOrgServiceUnits,
    ISaasLicensingInfo,
    LicenseTierType,
} from 'generated-types';

import { SaasLicensingInfo } from 'object-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './license-summary-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Card to display license summary data.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'license-summary-card',
    templateUrl: './license-summary-card.component.html',
})
export class LicenseSummaryCardComponent {
    /**
     * Default license tier for template usage.
     */
    @Input()
    public licenseTier: LicenseTierType;

    /**
     * Used service cores from totalLicensedServiceCores.
     */
    @Input()
    public usedServiceCores: number;

    /**
     * Total licensed Service Cores.
     */
    @Input()
    public licensedServiceCores: number;

    /**
     * True when application fetching is license data.
     */
    @Input()
    public isBusy: boolean;

    /**
     * Holds the customer portal information.
     */
    @Input()
    public saasConfig: ISaasLicensingInfo;

    /**
     * Holds Org service units data.
     */
    @Input()
    public orgServiceUnits: IOrgServiceUnits;

    /**
     * Object type.
     */
    public readonly saasObjectType = SaasLicensingInfo;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Getter for license tier as title: capitalize the enum value.
     */
    public get licenseTierTitle(): string {
        return this.schemaService.getEnumValue('LicenseTierType', this.licenseTier).label;
    }

    public constructor(
        @Inject(SchemaService) private schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if ENTERPRISE_WITH_CLOUD_SERVICES tier is selected
     */
    public isEnterpriseWithCloudServicesTier(): boolean {
        return this.licenseTier === LicenseTierType.ENTERPRISE_WITH_CLOUD_SERVICES;
    }

    /**
     * Getter for available_service_units.
     */
    public get availableServiceUnits(): number | undefined {
        return this.orgServiceUnits?.available_service_units;
    }

    /**
     * Getter for used_service_units.
     */
    public get usedServiceUnits(): number | undefined {
        return this.orgServiceUnits?.used_service_units;
    }

    /**
     * Getter for org_id.
     */
    public get orgId(): string {
        return this.orgServiceUnits?.org_id;
    }

    /**
     * Used to hide Available Service Units section
     * if both usedServiceCores and licensedServiceCores are 0.
     */
    public get showAvailableServiceUnitsSection(): boolean {
        return Boolean(this.usedServiceUnits || this.availableServiceUnits);
    }
}
