/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'scripts';

export const controlScriptModalBreadcrumbTitle = `${prefix}.controlScriptModalBreadcrumbTitle`;
export const protocolParserScriptModalBreadcrumbTitle = `${prefix}.protocolParserScriptModalBreadcrumbTitle`;

export const ENGLISH = {
    [controlScriptModalBreadcrumbTitle]: 'Control Script',
    [protocolParserScriptModalBreadcrumbTitle]: 'Protocol Parser Script',
};
