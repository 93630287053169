/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import { Component, Input } from '@angular/core';
import './full-modal-breadcrumb.component.less';

/**
 * @description Component to display a single breadcrumb containing an icon, name, and description.
 * @author alextsg
 */
@Component({
    selector: 'full-modal-breadcrumb',
    templateUrl: './full-modal-breadcrumb.component.html',
})
export class FullModalBreadcrumbComponent {
    /**
     * True if the modal for this breadcrumb is currently being configured.
     */
    @Input() public isActive = false;

    /**
     * Bolded text positioned on top of the description.
     */
    @Input() public name: string;

    /**
     * Text positioned below the name.
     */
    @Input() public description: string;
}
