/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import angular from 'angular';
import { initAjsDependency } from 'ajs/utils/ajsDependency';

import {
    PlacementScopeConfigConfigItem,
    SEGroup,
    SEGroupCollection,
    VcenterClustersConfigItem,
    VcenterHostsConfigItem,
    VipAutoscaleConfigurationConfigItem,
    VipAutoscaleGroupConfigItem,
} from '.';

import {
    PLACEMENT_SCOPE_CONFIG_CONFIG_ITEM_TOKEN,
    SE_GROUP_COLLECTION_TOKEN,
    SE_GROUP_ITEM_TOKEN,
    VCENTER_CLUSTERS_CONFIG_ITEM_TOKEN,
    VCENTER_HOSTS_CONFIG_ITEM_TOKEN,
    VIP_AUTOSCALE_CONFIGURATION_CONFIG_ITEM_TOKEN,
    VIP_AUTOSCALE_GROUP_CONFIG_ITEM_TOKEN,
} from './se-group.tokens';

const serviceEngineModule = angular.module('avi/service-engine-group');

const factories = [
    {
        dependency: SEGroup,
        name: SE_GROUP_ITEM_TOKEN,
    },
    {
        dependency: SEGroupCollection,
        name: SE_GROUP_COLLECTION_TOKEN,
    },
    {
        dependency: PlacementScopeConfigConfigItem,
        name: PLACEMENT_SCOPE_CONFIG_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VipAutoscaleGroupConfigItem,
        name: VIP_AUTOSCALE_GROUP_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VipAutoscaleConfigurationConfigItem,
        name: VIP_AUTOSCALE_CONFIGURATION_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VcenterClustersConfigItem,
        name: VCENTER_CLUSTERS_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: VcenterHostsConfigItem,
        name: VCENTER_HOSTS_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(serviceEngineModule, 'factory', name, dependency);
});
