/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './item-detail-page-header.less';

/**
 * @ngdoc component
 * @name  itemDetailPageHeader
 * @param {VirtualService|GslbService} item
 * @param {string} itemType - For now we need itemType to be set to use the right template for
 *     this component. For now only "vs" or "gslbservice" are supported.
 * @description
 *
 *     Item detail page header component. Consists of subheader that includes the unit info and
 *     healthscore along with nav bar for navigating child pages.
 *
 *     Tricky (workaround) part is the flexible width based on page content (sidebar).
 */
class ItemDetailPageHeaderController {
    constructor($scope, $elem, $state, $transitions, myAccount) {
        this.$scope_ = $scope;
        this.$elem_ = $elem;
        this.$state_ = $state;
        this.$transitions_ = $transitions;
        this.myAccount_ = myAccount;
    }

    /**
     * Returns true if current details child page is using sidebar.
     * @return {boolean}
     * @private
     */
    hasSidebar_() {
        return this.$state_.current.data && !!this.$state_.current.data.hasSidebar || false;
    }
}

ItemDetailPageHeaderController.$inject = [
    '$scope',
    '$element',
    '$state',
    '$transitions',
    'myAccount',
];

const templateUrl = function({ itemType }) {
    const
        folder = 'src/components/common/item-detail-page-header/',
        suffix = '-detail-page-header.html';

    if (!itemType) {
        console.error('Can\'t use itemDetailPageHeader wo setting the item type');
    }

    return folder + itemType + suffix;
};

templateUrl.$inject = ['$attrs'];

angular.module('aviApp').component('itemDetailPageHeader', {
    bindings: {
        item: '<',
        itemType: '@',
    },
    controller: ItemDetailPageHeaderController,
    templateUrl,
});
