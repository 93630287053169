/**
 * @module PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import { IPolicyRuleConfig, PolicyRuleExtendableConfigItem } from 'ajs/modules/policies';

@Component({
    selector: 'policy-rule-list',
    templateUrl: './policy-rule-list.component.html',
})
export class PolicyRuleListComponent {
    /**
     * List of instances of policy rule config items.
     */
    @Input()
    public rules: RepeatedMessageItem<PolicyRuleExtendableConfigItem<IPolicyRuleConfig>>;

    /**
     * Fires on edit.
     */
    @Output()
    public onEdit:
    EventEmitter<PolicyRuleExtendableConfigItem<IPolicyRuleConfig>> = new EventEmitter();

    /**
     * Click handler for editing a policy rule.
     */
    public handleEdit(rule: PolicyRuleExtendableConfigItem<IPolicyRuleConfig>): void {
        this.onEdit.emit(rule);
    }

    /**
     * Click handler for deleting a policy rule.
     */
    public deleteRule(rule: PolicyRuleExtendableConfigItem<IPolicyRuleConfig>): void {
        this.rules.removeByMessageItem(rule);
    }

    /**
     * Handler for the drag-and-drop event.
     * Moves dragged rule to a new position in the list.
     */
    public handleDrop(dragEvent: CdkDragDrop<void>): void {
        const { previousIndex, currentIndex } = dragEvent;
        const rule = this.rules.at(previousIndex);

        this.rules.moveItem(rule, currentIndex);
    }

    /**
     * Function for trackBy.
     */
    public trackByRuleName(
        index: number,
        rule: PolicyRuleExtendableConfigItem<IPolicyRuleConfig>,
    ): string {
        return rule.getName();
    }
}
