/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Item,
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import {
    compact,
    isString,
    isUndefined,
    pick,
} from 'underscore';

import { Component, Type } from '@angular/core';
import { IHttpResponse, IPromise } from 'angular';
import { L10nService } from '@vmw/ngx-vip';

import {
    IAdminAuthConfiguration,
    IDNSConfiguration,
    IEmailConfiguration,
    IIpAddrMatch,
    ILinuxConfiguration,
    IMgmtIpAccessControl,
    INTPConfiguration,
    IPortalConfiguration,
    ISecureChannelConfiguration,
    ISnmpConfiguration,
    ISystemConfiguration,
    ITenantConfiguration,
    LicenseTierType,
    MatchOperation,
    SMTPType,
} from 'generated-types';

import {
    AppLevelAlertsService,
    IFullModalLayout,
} from 'ng/modules/core';

import {
    LicenseTierSelectCardComponent,
    LicenseTierSwitchModalComponent,
} from 'ng/modules/licensing/components/license-tier-switch-modal/';

import {
    SystemSettingsPreviewComponent,
// eslint-disable-next-line max-len
} from 'ng/modules/system/components/system-settings-modal/system-settings-preview/system-settings-preview.component';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';

import { EmailConfigurationConfigItem } from './email-configuration.config-item.factory';
import * as l10n from '../system.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TSystemConfigurationPartial = Omit<ISystemConfiguration, 'email_configuration'>;

export interface ISystemConfigurationConfig extends TSystemConfigurationPartial {
    email_configuration?: EmailConfigurationConfigItem;
}

interface ISystemConfigData {
    uuid: string;
    config: ISystemConfigurationConfig;
}

export interface ISystemConfigurationComplianceMode {
    fips_mode?: boolean;
    common_criteria_mode?: boolean;
    force?: boolean;
}

/**
 * Interface for DNS Vs References.
 */
export interface IDnsVsRefObj {
    vsRef: string;
}

/**
 * @description
 *      Item Class for the systemconfiguration object.
 *
 *      Has verbose transformations on_load/before-save and few convenience/legacy save methods.
 *
 *      We have only one systemconfiguration which is provided by systemConfigService,
 *      hence this factory is rarely used directly.
 *
 * @author Alex Malitsky, Aravindh Nagarajan, Zhiqian Liu
 * @alias SystemConfig
 */
export class SystemConfig extends
    withFullModalMixin(withEditChildMessageItemMixin(ObjectTypeItem)) {
    public getConfig: () => ISystemConfigurationConfig;
    public data: ISystemConfigData;
    public backup: ISystemConfigurationConfig;

    /**
     * l10nService for translation.
     */
    private readonly l10nService: L10nService;

    private readonly appLevelAlertsService: AppLevelAlertsService;

    /**
     * Get keys from source bundles for template usage.
     */
    private readonly l10nKeys: typeof l10nKeys;

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            id: 'default', // only one in a system
            objectName: 'systemconfiguration',
            objectType: 'SystemConfiguration',
            whitelistedFields: [
                'email_configuration',
            ],
            params: {
                include_name: true,
                join: 'admin_auth_configuration.remote_auth_configurations.auth_profile_ref',
            },
        };

        super(extendedArgs);

        this.l10nKeys = l10nKeys;
        this.l10nService = this.getAjsDependency_('l10nService');
        this.appLevelAlertsService = this.getAjsDependency_('appLevelAlertsService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for the default license tier.
     */
    public get defaultLicenseTier(): LicenseTierType {
        return this.getConfig().default_license_tier;
    }

    /**
     * Setter for the default license tier.
     */
    public set defaultLicenseTier(tier: LicenseTierType) {
        this.getConfig().default_license_tier = tier;
    }

    /**
     * Returns Item.id from Item's data object.
     */
    // eslint-disable-next-line no-underscore-dangle
    public getIdFromData_(data: ISystemConfigData): string {
        return data.uuid;
    }

    /**
     * Returns true if system config is loaded.
     */
    public isReady(): boolean {
        return !!this.getConfig();
    }

    /**
     * Loads system_configuration and puts the response into Item#data.config.
     * @override
     */
    public async loadConfig(): Promise<ng.IHttpResponse<ISystemConfiguration>> {
        const params = this.getLoadParams();

        this.cancelRequests('config');

        const url = `/api/${this.objectName}/?${params.join('&')}`;

        const response: ng.IHttpResponse<ISystemConfiguration> =
            await this.request('get', url, undefined, null, 'config');

        this.onConfigLoad_(response);

        return response;
    }

    /**
     * Returns an API URI to save systemConfig.
     * @override
     */
    public urlToSave(): string {
        return `/api/${this.objectName}/?include_name`;
    }

    /** Empty systemConfig data */
    public emptyData(): void {
        this.data.config = null;
    }

    /**
     * Convenience method over Item.patch.
     */
    public updateTenantSettings(
        tenantConfig: ITenantConfiguration,
    ): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        return this.patch({
            replace: {
                global_tenant_config: tenantConfig,
            },
        });
    }

    /**
     * Convenience method over Item.patch.
     */
    public setWelcomeWorkflowComplete(): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        return this.patch({
            replace: {
                welcome_workflow_complete: true,
            },
        });
    }

    /**
     * Convenience method over Item.patch.
     */
    public updateAdminAuthSettings(
        adminAuthConfig: IAdminAuthConfiguration,
    ): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        return this.patch({
            replace: {
                admin_auth_configuration: adminAuthConfig,
            },
        });
    }

    /**
     * Close license tier switch modal.
     */
    public closeLicenseTierModal(): void {
        this.closeModal(LicenseTierSelectCardComponent as Type<Component>);
    }

    /**
     * Save default_license_field by doing a patch.
     */
    public saveDefaultLicenseTierType(): ng.IPromise<ng.IHttpResponse<ISystemConfiguration>> {
        const { default_license_tier: licenseTierToSave } = this.getConfig();

        return this.patch({
            replace: {
                default_license_tier: licenseTierToSave,
            },
        });
    }

    /** @override */
    public dataToSave(): ISystemConfiguration {
        /**
         * If IpAddrMatches have been added, set the match_criteria to 'IS_IN'.
         */
        function updateMatchCriteria(ipAddrMatch: IIpAddrMatch): IIpAddrMatch {
            if (ipAddrMatch &&
                (ipAddrMatch.addrs && ipAddrMatch.addrs.length > 0 ||
                ipAddrMatch.ranges && ipAddrMatch.ranges.length > 0 ||
                ipAddrMatch.prefixes && ipAddrMatch.prefixes.length > 0 ||
                ipAddrMatch.group_refs && ipAddrMatch.group_refs.length > 0)) {
                ipAddrMatch.match_criteria = MatchOperation.IS_IN;

                return ipAddrMatch;
            } else {
                return undefined;
            }
        }

        const config = super.dataToSave();

        const {
            portal_configuration: portalConfig,
            email_configuration: emailConfig,
            mgmt_ip_access_control: mgmtIPAccessControl,
        } = config;

        if (portalConfig) {
            // If http_port or https_port had been configured, but user is now trying to remove
            // them to reset back to default, we need to set them to 80 and 443 respectively
            // so that they are not simply undefined. We use _isUndefined for oldHttpPort
            // since it can be undefined, while newHttpPort can be undefined or null (null
            // when user deletes an existing value).
            if (this.backup) {
                const { portal_configuration: prevPortalConfig } = this.backup;

                ['http_port', 'https_port'].forEach((fieldName: string) => {
                    if (!portalConfig[fieldName] && prevPortalConfig[fieldName]) {
                        portalConfig[fieldName] = fieldName === 'https_port' ? 443 : 80;
                    }
                });
            }

            if (!portalConfig.enable_https) {
                portalConfig.redirect_to_https = false;
            }

            this.checkPortalPortChange();
        }

        if (mgmtIPAccessControl) {
            ['ssh_access', 'api_access', 'snmp_access', 'shell_server_access']
                .forEach(fieldName => {
                    if (fieldName in mgmtIPAccessControl) {
                        mgmtIPAccessControl[fieldName] =
                            updateMatchCriteria(mgmtIPAccessControl[fieldName]);
                    }
                });
        }

        // drop all properties not applicable for selected SMTP type
        if (emailConfig) {
            const mailServerConfigFields = [
                'mail_server_name',
                'mail_server_port',
                'from_email',
                'disable_tls',
            ];

            const fieldsPerType = {
                SMTP_NONE: [] as string[],
                SMTP_LOCAL_HOST: [
                    'from_email',
                ],
                SMTP_SERVER: [
                    'auth_username',
                    'auth_password',
                    ...mailServerConfigFields,
                ],
                SMTP_ANONYMOUS_SERVER: [
                    ...mailServerConfigFields,
                ],
            };

            if (!emailConfig.smtp_type) {
                emailConfig.smtp_type = SMTPType.SMTP_NONE;
            }

            const { smtp_type: smtpType } = emailConfig;

            config.email_configuration = pick(emailConfig, 'smtp_type', ...fieldsPerType[smtpType]);
        }

        if ('dns_virtualservice_refs' in config) {
            config.dns_virtualservice_refs = compact(config.dns_virtualservice_refs);

            if (!config.dns_virtualservice_refs.length) {
                config.dns_virtualservice_refs = undefined;
            }
        }

        const { snmp_configuration: snmp } = config;

        if (snmp && snmp.version) {
            switch (snmp.version) {
                case 'SNMP_VER2':
                    delete snmp.snmp_v3_config;
                    break;

                case 'SNMP_VER3':
                    delete snmp.community;
                    break;
            }
        } else {
            delete config.snmp_configuration;
        }

        return config;
    }

    /**
     * Dedicated method to execute same data transformations on load and on save as well.
     */
    // eslint-disable-next-line no-underscore-dangle
    public transformAfterLoad_(config: ISystemConfigurationConfig): ISystemConfigurationConfig {
        let { ntp_configuration: ntpConfig } = config;

        if (!ntpConfig) {
            config.ntp_configuration = {};
            ntpConfig = config.ntp_configuration;
        }

        [
            'ntp_servers',
            'ntp_server_list',
            'ntp_authentication_keys',
        ].forEach(fieldName => {
            if (!(fieldName in ntpConfig)) {
                ntpConfig[fieldName] = [];
            }
        });

        if (!('dns_virtualservice_refs' in config)) {
            config.dns_virtualservice_refs = [];
        }

        if (!('snmp_configuration' in config)) {
            config.snmp_configuration = {};
        }

        if (!('mgmt_ip_access_control' in config)) {
            config.mgmt_ip_access_control = {};
        }

        if (!('linux_configuration' in config)) {
            config.linux_configuration = {};
        }

        if (!('dns_configuration' in config)) {
            config.dns_configuration = {
                server_list: [],
            };
        } else if (!config.dns_configuration.search_domain) {
            delete config.dns_configuration.search_domain;
        }

        return config;
    }

    /** @override */
    public transformAfterLoad(): void {
        this.transformAfterLoad_(this.getConfig());
    }

    /** @override */
    public transformDataAfterSave(
        { data }: ng.IHttpResponse<ISystemConfigurationConfig>,
    ): ISystemConfigurationConfig {
        this.data.config = this.transformAfterLoad_(data);

        return this.getConfig();
    }

    /**
     * Previously we operated a copy of SystemConfiguration config wo Item and when done
     * saved it directly with the custom API PUT request.
     */
    public legacySave(
        config: ISystemConfigurationConfig,
    ): Promise<ISystemConfiguration> {
        const { config: prevConfig } = this.data;

        this.data.config = config;

        return this.save()
            .catch((rsp: any) => {
                this.data.config = prevConfig;

                return Promise.reject(rsp);
            }) as Promise<ISystemConfiguration>;
    }

    /**
     * Returns NTP configuration.
     */
    public getNTPConfig(): INTPConfiguration | null {
        const config = this.getConfig();

        return config?.ntp_configuration || null;
    }

    /**
     * Returns DNS configuration.
     */
    public getDNSConfig(): IDNSConfiguration | null {
        const config = this.getConfig();

        return config?.dns_configuration || null;
    }

    /**
     * Returns Email/SMTP configuration.
     */
    public getEmailConfig(): IEmailConfiguration | null {
        const config = this.getConfig();

        return config?.email_configuration.config || null;
    }

    public getDefaultEmailConfig(): IEmailConfiguration {
        const { email_configuration: defaultEmailConfig } = this.getDefaultConfig();

        return defaultEmailConfig;
    }

    /**
     * Returns Tenant configuration.
     */
    public getTenantConfig(): ITenantConfiguration | null {
        const config = this.getConfig();

        return config?.global_tenant_config || null;
    }

    /**
     * Returns Admin Auth configuration.
     */
    public getAdminAuthConfig(): IAdminAuthConfiguration | null {
        const config = this.getConfig();

        return config?.admin_auth_configuration || null;
    }

    /**
     * Returns Snmp configuration.
     */
    public getSnmpConfig(): ISnmpConfiguration {
        const { snmp_configuration: snmpConfig } = this.getConfig();

        return snmpConfig;
    }

    /**
     * @override
     * Overriden because SystemConfig can open both avi-modal and full-modal for partial configs.
     */
    public openModal(
        windowElement: TWindowElement = this.windowElement,
        params: IItemParams,
    ): Promise<void> {
        if (isUndefined(windowElement)) {
            windowElement = this.windowElement as string;
        }

        // open avi-modal
        if (isString(windowElement)) {
            return Item.prototype.openModal.call(this, windowElement, params);
        }

        // open full-modal
        return Promise.resolve(super.openModal(windowElement, params));
    }

    /**
     * @override
     */
    public closeModal(windowElement?: string | Type<Component>): Promise<void> {
        if (isUndefined(windowElement)) {
            windowElement = this.windowElement as string;
        }

        // close avi-modal
        if (isString(windowElement)) {
            return Item.prototype.closeModal.call(this, windowElement);
        }

        // close full-modal
        return Promise.resolve(super.closeModal(windowElement));
    }

    /**
     * Called to edit email configuration settings.
     */
    public editEmailSmtp(): void {
        this.editChildMessageItem({ field: 'email_configuration' }, true);
    }

    /**
     * Returns the email configuration SMTP type.
     */
    public get emailSmtpType(): SMTPType | undefined {
        return this.getEmailConfig().smtp_type;
    }

    /**
     * Makes a request to enable FIPS mode.
     */
    public enableFipsMode(
        config: ISystemConfigurationComplianceMode,
    ): IPromise<IHttpResponse<void>> {
        return this.request('POST', 'api/systemconfiguration/compliancemode', config);
    }

    /**
     * List of dns virtual services refs are restrutured to IDnsVsRefObj,
     * to serve the collection dropdown consumption in data grid.
     */
    public getDnsVirtualServiceRefs(): IDnsVsRefObj[] {
        const {
            dns_virtualservice_refs: vsRefs = [],
        } = this.getConfig();

        return vsRefs.map(vsRef => ({ vsRef }));
    }

    /**
     * Returns management ip access control of controller.
     */
    public getMgmtIPAccessControl(): IMgmtIpAccessControl {
        const { mgmt_ip_access_control: mgmtIPAccessControl } = this.getConfig();

        return mgmtIPAccessControl;
    }

    /**
     * Remove dns service from the existing list of dns vs.
     */
    public removeDnsService(dnsVsRefObj: IDnsVsRefObj): void {
        const {
            dns_virtualservice_refs: vsRefs = [],
        } = this.getConfig();

        const index = vsRefs.findIndex(ref => ref === dnsVsRefObj.vsRef);

        vsRefs.splice(index, 1);
    }

    /**
     * Add dns vs service to the existing list of dns vs.
     */
    public addDnsVs(): void {
        const {
            dns_virtualservice_refs: dnsVsRefs = [],
        } = this.getConfig();

        dnsVsRefs.push(undefined);
    }

    /**
     * Update dns vs service from the existing list of dns vs.
     */
    public updateDnsVs(vsRef: string, index: number): void {
        const {
            dns_virtualservice_refs: dnsVsRefs,
        } = this.getConfig();

        dnsVsRefs[index] = vsRef;
    }

    /**
     * Getter method for portal configuration.
     */
    public getPortalConfig(): IPortalConfiguration {
        const {
            portal_configuration: portalConfig,
        } = this.getConfig();

        return portalConfig;
    }

    /**
     * Getter method for Secure channel configuration.
     */
    public getSecureChannelConfig(): ISecureChannelConfiguration {
        const {
            secure_channel_configuration: secureChannelConfig,
        } = this.getConfig();

        return secureChannelConfig;
    }

    /**
     * Gets HMACs in system configuration.
     */
    public getSshHmacs(): string[] {
        const {
            ssh_hmacs: sshHmacs,
        } = this.getConfig();

        return sshHmacs;
    }

    /**
     * Gets ciphers in system configuration.
     */
    public getSshCiphers(): string[] {
        const {
            ssh_ciphers: sshCiphers,
        } = this.getConfig();

        return sshCiphers;
    }

    /**
     * Adds a new empty object into NTP auth grid, when 'Add' button is clicked
     */
    public addEmptyNtpAuthenticationKey(): void {
        const { ntp_authentication_keys: ntpAuthKeys } = this.getNTPConfig();

        ntpAuthKeys.push({});
    }

    /**
     * Adds a new empty object into NTP server grid, when 'Add' button is clicked
     */
    public addEmptyNtpServer(): void {
        const { ntp_servers: ntpServers } = this.getNTPConfig();

        ntpServers.push({});
    }

    /**
     * Returns linux configuration of controller.
     */
    public getLinuxConfiguration(): ILinuxConfiguration {
        const {
            linux_configuration: linuxConfig,
        } = this.getConfig();

        return linuxConfig;
    }

    /**
     * Override to suppress error alert window.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    protected patchErrorHandler_(errRsp: any): ng.IPromise<void> {
        this.errors = errRsp.data;
        this.emitOnSaveEvents_('save-fail', this.errors);

        return this.$q.reject(errRsp.data);
    }

    /**
     * @override
     * Overriden for editing default_licenese_tier or system settings modal
     * for SystemConfig. Default modal is system settings modal.
     */
    protected async getFullModalProps(
        params: IItemParams,
        windowElement?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const emptyValue = '-';

        const modalParams = await super.getFullModalProps(params, windowElement);

        if (windowElement === LicenseTierSwitchModalComponent) {
            return Promise.resolve({
                ...modalParams,
                getDescription: () => this.getLicenseTierModalBreadCrumbDescription(params),
                getName: () => this.l10nService.getMessage(
                    this.l10nKeys.licensingBreadCrumbTitle,
                ),
            });
        }

        return Promise.resolve({
            ...modalParams,
            previewComponent: SystemSettingsPreviewComponent as Type<Component>,
            previewComponentProps: {
                config: params.editable.getConfig(),
            },
            getDescription: () => emptyValue,
            getName: () => this.l10nService.getMessage(
                this.l10nKeys.systemSettingsBreadCrumbTitle,
            ),
        });
    }

    /**
     * Gets description for full modal config for licensing.
     */
    private getLicenseTierModalBreadCrumbDescription(params: IItemParams): string {
        const schemaService = this.getAjsDependency_('schemaService');
        const {
            defaultLicenseTier: selectedLicenseTier,
        } = params.editable as SystemConfig;

        const { label: licenseTierLabel } = schemaService.getEnumValue(
            'LicenseTierType',
            selectedLicenseTier,
        );

        return licenseTierLabel;
    }

    /**
     * Show app-level alert to relogin if user logged in port changed.
     */
    private showReLoginAppLevelInfoAlert(isHttpsConnection: boolean): void {
        const connection = this.l10nService.getMessage(
            isHttpsConnection ? l10nKeys.httpsMessage : l10nKeys.httpMessage,
        );

        this.appLevelAlertsService.removeAll();
        this.appLevelAlertsService.add({
            id: 're-login',
            componentProps: {
                messages: [
                    this.l10nService.getMessage(l10nKeys.reloginMessageInfo, [connection]),
                ],
                status: 'info',
            },
        });
    }

    /**
     * Check if user logged in to the port on which port change happened.
     */
    private checkPortalPortChange(): void {
        if (this.backup && this.isReady()) {
            const { portal_configuration: prevPortalConfig } = this.backup;
            const { portal_configuration: portalConfig } = this.getConfig();

            if (prevPortalConfig && portalConfig) {
                const {
                    https_port: httpsPort = 443,
                    http_port: httpPort = 80,
                } = portalConfig;

                const {
                    https_port: prevHTTPSPort = 443,
                    http_port: prevHTTPPort = 80,
                } = prevPortalConfig;

                const isHttpPortChanged = Number(httpPort) !== Number(prevHTTPPort);
                const isHttpsPortChanged = Number(httpsPort) !== Number(prevHTTPSPort);
                const isCurrentUrlHttps = window.location.href.includes('https');

                if (
                    isHttpsPortChanged && isCurrentUrlHttps ||
                    isHttpPortChanged && !isCurrentUrlHttps
                ) {
                    this.showReLoginAppLevelInfoAlert(isCurrentUrlHttps);
                }
            }
        }
    }
}

SystemConfig.ajsDependencies = [
    'appLevelAlertsService',
    'schemaService',
    'l10nService',
];
