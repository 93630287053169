/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'scatter-plot-card';
const prefix = `${moduleName}.${componentName}`;

export const serverPlural = `${prefix}.serverPlural`;

export const ENGLISH = {
    [serverPlural]: '{0, plural, one{<span class="sel-server-count">#</span> Server} other{<span class="sel-server-count">#</span> Servers}}',
};
