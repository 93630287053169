/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-graph';
const prefix = `${moduleName}.${componentName}`;

export const significantLabel = `${prefix}.significantLabel`;
export const nonSignificantLabel = `${prefix}.nonSignificantLabel`;

export const ENGLISH = {
    [significantLabel]: 'Significant',
    [nonSignificantLabel]: 'Non-significant',
};
