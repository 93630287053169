/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'my-acount-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const sectionTitleDisplay = `${prefix}.sectionTitleDisplay`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const userNameInputLabel = `${prefix}.userNameInputLabel`;
export const oldPasswordInputLabel = `${prefix}.oldPasswordInputLabel`;
export const oldPasswordInputPlaceholder = `${prefix}.oldPasswordInputPlaceholder`;
export const newPasswordInputLabel = `${prefix}.newPasswordInputLabel`;
export const newPasswordInputPlaceholder = `${prefix}.newPasswordInputPlaceholder`;
export const confirmNewPasswordInputLabel = `${prefix}.confirmNewPasswordInputLabel`;
export const emailInputLabel = `${prefix}.emailInputLabel`;
export const emailInputPlaceholder = `${prefix}.emailInputPlaceholder`;
export const timeZoneLabel = `${prefix}.timeZoneLabel`;
export const utcTimeLabel = `${prefix}.utcTimeLabel`;
export const localTimeLabel = `${prefix}.localTimeLabel`;
export const languageLabel = `${prefix}.languageLabel`;
export const changePasswordHeader = `${prefix}.changePasswordHeader`;
export const passwordMismatchError = `${prefix}.passwordMismatchError`;
export const sectionTitleControllerSettings = `${prefix}.sectionTitleControllerSettings`;
export const sessionTimeoutLabel = `${prefix}.sessionTimeoutLabel`;
export const minutesHelperText = `${prefix}.minutesInnerText`;
export const dnsRefreshPeriodInputLabel = `${prefix}.dnsRefreshPeriodInputLabel`;
export const sessionTimeoutInputPlaceholder = `${prefix}.sessionTimeoutInputPlaceholder`;
export const dnsRefreshPeriodInputPlaceholder = `${prefix}.dnsRefreshPeriodInputPlaceholder`;
export const newPasswordErrorText = `${prefix}.newPasswordErrorText`;
export const techPreviewLabel = `${prefix}.techPreviewLabel`;
export const techPreviewHoverInfo = `${prefix}.techPreviewHoverInfo`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit My Account',
    [sectionTitleGeneral]: 'General',
    [sectionTitleDisplay]: 'Display',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [userNameInputLabel]: 'Username',
    [oldPasswordInputLabel]: 'Old Password',
    [oldPasswordInputPlaceholder]: 'Enter Old Password',
    [newPasswordInputLabel]: 'New Password',
    [newPasswordInputPlaceholder]: 'Enter New Password',
    [confirmNewPasswordInputLabel]: 'Confirm New Password',
    [emailInputLabel]: 'Email',
    [emailInputPlaceholder]: 'Enter Email',
    [timeZoneLabel]: 'Time Zone',
    [utcTimeLabel]: 'UTC Time',
    [localTimeLabel]: 'Local Time',
    [dnsRefreshPeriodInputLabel]: 'DNS Refresh Period',
    [languageLabel]: 'Language',
    [changePasswordHeader]: 'Change Password',
    [passwordMismatchError]: 'Password does not match',
    [sectionTitleControllerSettings]: 'Controller',
    [sessionTimeoutLabel]: 'Session Timeout',
    [minutesHelperText]: 'Minutes',
    [sessionTimeoutInputPlaceholder]: 'Enter Session Timeout',
    [dnsRefreshPeriodInputPlaceholder]: 'Enter DNS Refresh Period',
    [newPasswordErrorText]: `Password must be {0} characters minimum, non-sequential and contains at least
        three of the following criteria (uppercase, lowercase, numeric or special characters)`,
    [techPreviewLabel]: 'Enable Tech Preview for Virtual Service Logs',
    [techPreviewHoverInfo]: 'The new logs view is in Tech Preview, and will replace the existing legacy view in the upcoming release.',
};
