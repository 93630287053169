/** @module PoolModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import {
    POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
} from '../pool.tokens';

/**
 * @description Pool Group Deployment Policy collection.
 * @author Nisar Nadaf
 */
export class PoolGroupDeploymentPolicyCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'poolgroupdeploymentpolicy',
            objectType: 'PoolGroupDeploymentPolicy',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_POOLGROUPDEPLOYMENTPOLICY,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN);
    }
}

PoolGroupDeploymentPolicyCollection.ajsDependencies = [
    POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
];
