/**
 * @module PoolModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IHTTPStatusRange } from 'generated-types';
import { HTTPStatusRange } from 'object-types';

/**
 * @description
 *
 *   HttpStatusRange config item.
 *
 * @author Rachit Aggarwal
 */
export class HTTPStatusRangeConfigItem extends MessageItem<IHTTPStatusRange> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HTTPStatusRange,
            ...args,
        };

        super(extendedArgs);
    }
}
