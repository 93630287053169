/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'underscore';
import { SchemaService } from 'ajs/modules/core/services';
import { TVsLog } from '../vs-logs.types';

export type TVsLogSignificanceReason = {
    label: string;
    description?: string;
};

/**
 * @description
 *     Pipe for transforming "significance" field of a log entry to a list of descriptive objects.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'logSignificanceReasons',
})
export class LogSignificanceReasonsPipe implements PipeTransform {
    constructor(
        private readonly schemaService: SchemaService,
    ) {}

    /** @override */
    public transform(significanceStr: TVsLog['significance']): TVsLogSignificanceReason[] {
        if (!significanceStr) {
            return [];
        }

        const significantReasons = significanceStr.split(', ');
        const enums = this.schemaService.getEnumValues('AviDefinedFilters');

        const results = significantReasons.map(label => {
            const enumValue = find(enums, elem => elem.label === label);
            const description = enumValue && enumValue.description;

            return {
                label,
                description,
            };
        });

        return results;
    }
}
