/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'network-subnet-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeaderCreate = `${prefix}.modalHeaderCreate`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const subnetPrefixInputLabel = `${prefix}.subnetPrefixInputLabel`;
export const subnetPrefixInputPlaceholder = `${prefix}.subnetPrefixInputPlaceholder`;
export const staticIpForVipSeInputLabel = `${prefix}.staticIpForVipSeInputLabel`;
export const staticIpRangesTitle = `${prefix}.staticIpRangesTitle`;
export const ipAddressRangeColumnTitle = `${prefix}.ipAddressRangeColumnTitle`;
export const ipAddressRangeInputPlaceholder = `${prefix}.ipAddressRangeInputPlaceholder`;
export const useForColumnTitle = `${prefix}.useForColumnTitle`;

export const ENGLISH = {
    [modalHeaderCreate]: 'Add Subnet',
    [modalHeaderEdit]: 'Edit Subnet',
    [subnetPrefixInputLabel]: 'Subnet Prefix',
    [subnetPrefixInputPlaceholder]: 'Enter Subnet Prefix',
    [staticIpForVipSeInputLabel]: 'Use Static IP Address for VIPs and SE',
    [staticIpRangesTitle]: 'Static IP Ranges ({0})',
    [ipAddressRangeColumnTitle]: 'IP Address Range',
    [ipAddressRangeInputPlaceholder]: 'Enter IP Address Range',
    [useForColumnTitle]: 'Use For',
};
