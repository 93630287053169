/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-policy-set';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleNumberRules = `${prefix}.columnTitleNumberRules`;

export const ENGLISH = {
    [headerLabel]: 'L4 Policy Set',
    [columnTitleNumberRules]: '# Rules in L4 Connection Policy',
};
