/**
 * @module GroupsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import {
    ipAddrGroupCollectionProvider,
    StringGroupItemProvider,
} from './ajs-upgraded-providers';

import {
    IpAddrGroupListPageComponent,
    StringGroupListComponent,
    StringGroupModalComponent,
} from '.';

const groupsComponents = [
    IpAddrGroupListPageComponent,
    StringGroupListComponent,
    StringGroupModalComponent,
];

/**
 * @description
 *
 *      Angular Module for String Group/IP related components.
 * @author Nisar Nadaf, Suraj Kumar
 */
@NgModule({
    declarations: [
        ...groupsComponents,
    ],
    imports: [
        DataGridModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrFormsModule,
        AviFormsModule,
        CommonModule,
        VIPModule,
    ],
    providers: [
        StringGroupItemProvider,
        ipAddrGroupCollectionProvider,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class GroupsModule {}
