/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/** @module serviceEngineModule */

import { Collection } from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    ICloudFlavor,
    IVcenterDatastore,
} from 'generated-types';

import { SE_GROUP_ITEM_TOKEN } from '../se-group.tokens';

const dataSources = {
    inventory: {
        source: 'InventoryCollDataSource',
        transformer: 'SeGroupInventoryDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
            'virtualservices',
            'serviceengines',
            'upgradestatus',
        ],
    },
};

/**
 * @description Service Engine Group collection.
 * @author Sarthak Kapoor, vgohil
 */
export class SEGroupCollection extends Collection {
    public static ajsDependencies = [
        SE_GROUP_ITEM_TOKEN,
    ];

    public readonly showTenantName: boolean;

    constructor(args: Record<string, any> = {}) {
        const extendedArgs = {
            objectName: 'serviceenginegroup-inventory',
            isStatic: false,
            windowElement: 'lazy-load',
            defaultDataSources: 'inventory',
            allDataSources: dataSources,
            restrictCreateOnEssentialLicense: false,
            permissionName: AviPermissionResource.PERMISSION_SERVICEENGINEGROUP,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(SE_GROUP_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            vcenter_datastores: [] as IVcenterDatastore[],
            instance_flavor_info: undefined as ICloudFlavor,
        };

        /**
         * Displays the tenant name in parentheses next to the SE Group name. False by
         * default, set to true in SE Create/Edit, when we want to show the tenant names in
         * the SE Group dropdown.
         * @type {boolean}
         */
        this.showTenantName = args.showTenantName || false;
    }
}
