/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-certificate-revocation-list-modal';
const prefix = `${moduleName}.${componentName}`;

export const crlModalTitle = `${prefix}.crlModalTitle`;
export const generalHeader = `${prefix}.generalHeader`;
export const addByServerUrlHeader = `${prefix}.addByServerUrlHeader`;
export const serverUrlInputLabel = `${prefix}.serverUrlInputLabel`;
export const serverUrlInputPlaceholderText = `${prefix}.serverUrlInputPlaceholderText`;
export const serverUrlButtonLabel = `${prefix}.serverUrlButtonLabel`;
export const refreshTimeInputLabel = `${prefix}.refreshTimeInputLabel`;
export const refreshTimeInputPlaceholderText = `${prefix}.refreshTimeInputPlaceholderText`;
export const refreshTimeMinutesText = `${prefix}.refreshTimeMinutesText`;
export const addByFileHeader = `${prefix}.addByFileHeader`;
export const uploadCrlInputLabel = `${prefix}.uploadCrlInputLabel`;

export const ENGLISH = {
    [generalHeader]: 'General',
    [crlModalTitle]: 'Add CRL',
    [addByServerUrlHeader]: 'Add by Server URL',
    [serverUrlInputLabel]: 'Server URL',
    [serverUrlInputPlaceholderText]: 'Enter Server URL',
    [serverUrlButtonLabel]: 'Lookup',
    [refreshTimeInputLabel]: 'Refresh Time',
    [refreshTimeInputPlaceholderText]: 'Enter Refresh Time',
    [refreshTimeMinutesText]: 'Minutes',
    [addByFileHeader]: 'Add by File',
    [uploadCrlInputLabel]: 'Upload Certificate Revocation List File (CRL)',
};
