/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name Server
 * @description
 *
 *     Server object is made of Pool by taking config object from PoolConfig.servers.
 *     Since server is effectively a fraction of pool config, we need to have pool id or pool
 *     instance.
 *
 */
//TODO get rid of Pool item on Pool creation by adding tenant_ref to server's config object

/**
 * @typedef {Object} ServerConfig
 */
angular.module('aviApp').factory('Server', [
'$q', 'UpdatableItem', 'AsyncFactory', 'Timeframe', 'ServerHealthMonitorCollection',
function($q, UpdatableItem, AsyncFactory, Timeframe, ServerHealthMonitorCollection) {
    class Server extends UpdatableItem {
        constructor(args = {}) {
            const {
                data,
                pool,
            } = args;

            let
                poolId,
                config;

            if (data) {
                config = data.config;
            }

            if (args.serverId && args.poolId) {
                poolId = args.poolId;
                args.id = args.serverId;
            } else if (config && config['uuid'] &&
                (poolId = config.poolId || args.poolId ||
                    data['pool_ref'] && data['pool_ref'].slug())) {
                args.id = config['uuid'] || args.serverId;
            } else {
                console.warn(
                    'Server Item: Trying to create instance of Server without server or pool id',
                    args,
                );
            }

            super(args);

            if (pool) {
                this.pool = pool;
                this.poolId = pool.id;
            } else if (poolId) {
                this.poolId = poolId;
            }

            this.onConfigLoad_ = this.onConfigLoad_.bind(this);
        }

        /** @override */
        destroy() {
            const gotDestroyed = super.destroy();

            if (gotDestroyed) {
                this.pool = null;

                this.destroyHMcollection_();
            }

            return gotDestroyed;
        }
    }

    //FIXME since now we support collection of servers across multiple Pools poolId should also
    // become a part of id
    Server.prototype.getIdFromData_ = function(data) {
        return data && data.config && data.config.uuid || '';
    };

    Server.prototype.objectName = 'pool';

    /**
     * Reference to the pool this server belongs to.
     * @type {Pool|null}
     * @protected
     */
    Server.prototype.pool = null;

    /** @override */
    Server.prototype.getMetricsTuple = function() {
        return {
            entity_uuid: '*',
            aggregate_entity: true,
            pool_uuid: this.poolId,
            obj_id: this.id,
        };
    };

    /**
     * This function emulates a call to the server and giving the promise
     * It is making multiple calls periodically to continuously update the object
     * that was delivered into resolve
     *
     * @param aFields - Array of fields that has to be loaded
     * @return {*} - Promise
     */
    Server.prototype.loadRequest = function(aFields) {
        return this.loadConfig().then(function() {
            return $q.all([
                this.loadMetrics(aFields, { server: this.id }),
                this.loadHealthMonitors(aFields, 30000),
                this.loadEventsAndAlerts(aFields),
            ]);
        }.bind(this));
    };

    /**
     * TODO If pool reference is set, should we just load pool instead?
     * @override
     **/
    Server.prototype.loadConfig = function() {
        const
            params = this.getLoadParams(),
            headers = this.getLoadHeaders_();

        params.push(
            `uuid=${this.poolId}`,
            'fields=servers,default_server_port',
        );

        const
            paramStr = params.join('&'),
            url = `/api/pool?${paramStr}`;

        this.cancelRequests('config');

        return this.request('get', url, undefined, headers, 'config')
            .then(this.onConfigLoad_);
    };

    /** @override */
    Server.prototype.onConfigLoad_ = function(rsp) {
        const
            [poolConfig] = rsp.data['results'],
            {
                default_server_port: defaultPoolServerPort,
                servers,
            } = poolConfig;

        const server = _.find(servers, server =>
            Server.getServerUuid(server, defaultPoolServerPort) === this.id);

        if (server) {
            server.uuid = this.id;
            server.poolId = this.poolId;
            server['default_server_port'] = defaultPoolServerPort;
            this.data.config = server;
        } else {
            console.error(
                `Can't find server "${this.id}" in pool config "${poolConfig['uuid']}"`, poolConfig,
            );

            this.data.config = null;
        }

        return rsp;
    };

    /**
     * Destroys health monitor collection.
     * @protected
     */
    Server.prototype.destroyHMcollection_ = function() {
        if (this.healthMonitors) {
            this.healthMonitors.destroy();
            this.healthMonitors = null;
        }
    };

    /**
     * Starts an {@link AsyncFactory} for health monitors of a {@link Server}
     * @param {Array} aFields
     * @param {number=} interval
     * @returns {ng.$q.promise}
     */
    Server.prototype.loadHealthMonitors = function(aFields, interval) {
        const
            deferred = $q.defer(),
            { hash } = this.async;

        if (hash.health_monitors) {
            hash.health_monitors.stop();
        }

        if (!_.contains(aFields, 'health_monitors')) {
            this.destroyHMcollection_();
            deferred.resolve('No subscription for server health monitors.');
        } else if (!this.hasConfig()) {
            this.destroyHMcollection_();
            deferred.reject(
                'Can\'t start HM updates without poolId, server IP or server port.',
            );
        } else {
            if (!this.healthMonitors) {
                const config = this.getConfig();

                this.healthMonitors = new ServerHealthMonitorCollection({
                    isStatic: true,
                    poolId: this.poolId,
                    serverIp: config['ip']['addr'],
                    serverPort: config['port'] || config['default_server_port'],
                });
            } else {
                this.healthMonitors.reset();
            }

            hash.health_monitors = new AsyncFactory(() => {
                return this.healthMonitors.load()
                    .then(
                        rsp => deferred.resolve(rsp),
                        err => deferred.reject(err),
                    );
            }, {
                maxSkipped: 2,
                callback: () => this.healthMonitors.cancelRequests(),
            });

            hash.health_monitors.start(interval || Timeframe.selected().interval * 1000);
        }

        return deferred.promise;
    };

    /**
     * @static
     * Creates pseudo uuid for the server, combining the server IP address with the port number.
     * Its not just for UI. Its being passed as a parameter to get server metrics.
     * @param  {Server#data#config} server - Server config data.
     * @param  {number=} port - Port number.
     * @return {string} server UUID.
     */
    Server.getServerUuid = function(server, port) {
        const serverPort = server.port || port || server.default_server_port || 80;

        // Add hostname for unresolved servers (0.0.0.0)
        const key = server.ip.addr === '0.0.0.0' ? server.hostname : server.ip.addr;

        return `${key}:${serverPort}`;
    };

    /**
     * Need to override Item.isDroppable because servers do not have tenant_refs, so we use the
     * tenant_ref of the passed-in Pool object by calling this.pool.isDroppable.
     * @return {Boolean} True if server is droppable, false otherwise.
     * @override
     */
    Server.prototype.isDroppable = function() {
        return this.pool && this.pool.isEditable() || false;
    };

    /**
     * There is no way to edit Pool's Server directly, edit Pool instead.
     * @override
     */
    Server.prototype.isEditable = function() {
        return false;
    };

    /**
     * Server as part of Pool's config, don't have API to drop a particular server.
     * @override
     **/
    Server.prototype.drop = function() {
        return $q.reject({ error: 'Can\'t delete Pool\'s Server directly' });
    };

    /**
     * Server as part of Pool's config, don't have API to edit a particular server.
     * @override
     **/
    Server.prototype.edit = function() {
        return $q.reject({ error: 'Can\'t edit Pool\'s Server directly' });
    };

    /**
     * Server as part of Pool's config, don't have API to save a particular server.
     * @override
     */
    Server.prototype.save = function() {
        return $q.reject({ error: 'Can\'t save Pool\'s Server directly' });
    };

    /** @override */
    Server.prototype.getItemType = function() {
        return 'server';
    };

    /**
     * Returns vm ref property of a server config.
     * @returns {string}
     * @public
     */
    Server.prototype.getVMRef = function() {
        return this.getConfig()['vm_ref'] || '';
    };

    /** @override */
    Server.prototype.hasCustomTimeFrameSettings = function() {
        const { pool, collection } = this;

        return !collection && pool && pool.hasCustomTimeFrameSettings() || false;
    };

    /** @override */
    Server.prototype.getCustomTimeFrameSettings = function(tfLabel) {
        if (this.hasCustomTimeFrameSettings()) {
            return this.pool.getCustomTimeFrameSettings(tfLabel);
        }

        return null;
    };

    /**
     * Consistent way to get VM id from of Server configuration.
     * @param {ServerConfig} serverConfig
     * @returns {string}
     * @static
     * @public
     */
    Server.getServerVMId = function(serverConfig) {
        const {
            external_uuid: externalUuid,
            vm_ref: vmRef,
        } = serverConfig;

        return (vmRef ? vmRef.slug() : externalUuid) || '';
    };

    return Server;
}]);
