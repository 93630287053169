/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notification';
const componentName = 'cloud-status';
const prefix = `${moduleName}.${componentName}`;

export const clickHereForDetails = `${prefix}.clickHereForDetails`;

export const ENGLISH = {
    [clickHereForDetails]: 'Click here for ##0details##1.',
};
