/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ACTIVE_USER_PROFILE_SERVICE_TOKEN } from 'ajs/modules/core/services/active-user-profile';

function eventListDataTransformerFactory(ListDataTransformer, activeUserProfileService) {
    class EventListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp, request) {
            const tenantHash = {};

            resp = super.processResponse(resp, request);

            EventListDataTransformer.setIsAccurate_(resp.data);

            if ('results' in resp.data && Array.isArray(resp.data.results) &&
                resp.data.results.length) {
                activeUserProfileService.getTenants().forEach(tenant => {
                    if (tenant && tenant.tenant_ref) {
                        tenantHash[tenant.tenant_ref.slug()] = tenant.tenant_ref;
                    }
                });

                resp.data.results.forEach(function(event) {
                    event.config.tenant_ref = tenantHash[event.config.tenant] || '';
                });
            }

            return resp;
        }

        /**
         * Set the isAccurate flag to true if percent_remaining = 0, else
         * set it to false.
         * percent_remaining flag > 0, indicates that,
         * event list needs to be reloaded to fetch more records from api
         * @param {Object} data - event response data from api
         * @protected
         */
        static setIsAccurate_(data) {
            data.isAccurate = data.percent_remaining === 0;
        }
    }

    return EventListDataTransformer;
}

eventListDataTransformerFactory.$inject = [
    'ListDataTransformer',
    ACTIVE_USER_PROFILE_SERVICE_TOKEN,
];

/**
 * @ngdoc factory
 * @name EventListDataTransformer
 * @description
 *  Class used for processing the event list response
 */
angular.module('aviApp').factory('EventListDataTransformer', eventListDataTransformerFactory);
