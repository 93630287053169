/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { isEmpty } from 'underscore';
import { getDisplayLabel, getValue } from '../avi-dropdown.utils';
import { DropdownModelValue, IAviDropdownOption } from '../avi-dropdown.types';
import './avi-dropdown-selected-values-container.component.less';

/**
 * Component for displaying either a single selected value or multiple selected values in the
 * AviDropdownComponent.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-selected-values-container',
    templateUrl: './avi-dropdown-selected-values-container.component.html',
})
export class AviDropdownSelectedValuesContainerComponent {
    /**
     * List of selected options.
     */
    @Input() public selectedOptions: IAviDropdownOption[] = [];

    /**
     * True if multiple selected values should be shown.
     */
    @Input() public multiple = false;

    /**
     * Disable any modifications to the fields.
     */
    @Input() public disabled = false;

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * Emitted to remove a selected option. Only applicable for multiple selection.
     */
    @Output() public onRemoveValue = new EventEmitter<DropdownModelValue>();

    /**
     * Returns the text to be shown.
     */
    public getDisplayLabel(dropdownOption: IAviDropdownOption): string {
        if (isEmpty(dropdownOption)) {
            return '';
        }

        return getDisplayLabel(dropdownOption);
    }

    /**
     * Gettter for checking presence of dropdown prepend.
     */
    public get hasPrepend(): boolean {
        return Boolean(this.prepend);
    }

    /**
     * Click handler to remove a selected dropdown value.
     */
    public handleRemove($event: MouseEvent, selectedOption: IAviDropdownOption): void {
        if (this.disabled) {
            return;
        }

        this.onRemoveValue.emit(getValue(selectedOption));
        $event.stopPropagation();
    }

    /**
     * Returns the dropdown value from the dropdownOption object. Used as the trackBy function.
     */
    public trackByValue(
        index: number,
        dropdownOption: IAviDropdownOption,
    ): IAviDropdownOption['value'] {
        return getValue(dropdownOption);
    }
}
