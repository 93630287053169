/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function serviceEngineCollectionFactory(Collection, ServiceEngine) {
    /**
     * @constructor
     * @memberOf module:avi/serviceengine
     * @extends module:avi/dataModel.Collection
     * @author Ram Pal
     * @desc Collection of {@link module:avi/serviceengine.ServiceEngine ServiceEngines}.
     */
    class ServiceEngineCollection extends Collection {
        /** @override */
        isCreatable() {
            return false;
        }
    }

    const allDataSources = {
        inventory: {
            source: 'InventoryCollDataSource',
            transformer: 'InventoryDataTransformer',
            transport: 'ListDataTransport',
            fields: [
                'config',
                'health',
                'runtime',
                'alert',
                'faults',
            ],
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: [
                'se_if.avg_bandwidth',
                'se_stats.avg_cpu_usage',
                'se_stats.max_num_vs',
                'se_stats.avg_mem_usage',
                'se_stats.avg_disk1_usage',
                'se_stats.avg_packet_buffer_usage',
                'se_stats.avg_persistent_table_usage',
                'se_stats.avg_ssl_session_cache_usage',
                'se_stats.pct_syn_cache_usage',
                'se_stats.avg_connections',
                'se_stats.pct_connections_dropped',
                'se_if.avg_rx_bytes',
                'se_if.avg_rx_pkts',
                'se_stats.avg_rx_bytes_dropped',
                'se_stats.avg_rx_pkts_dropped',
                'se_stats.avg_tx_bandwidth',
                'se_if.avg_tx_bytes',
                'se_if.avg_tx_pkts',
                'se_stats.avg_connection_mem_usage',
                'se_stats.avg_dynamic_mem_usage',
            ],
            dependsOn: 'config',
        },
    };

    Object.assign(ServiceEngineCollection.prototype, {
        objectName_: 'serviceengine-inventory',
        itemClass_: ServiceEngine,
        windowElement_: 'infra-se-edit',
        isStatic_: false,
        allDataSources_: allDataSources,
        defaultDataSources_: 'inventory',
    });

    return ServiceEngineCollection;
}

serviceEngineCollectionFactory.$inject = [
    'Collection',
    'ServiceEngine',
];

angular.module('avi/serviceengine')
    .factory('ServiceEngineCollection', serviceEngineCollectionFactory);
