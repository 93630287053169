/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('ControllerFaultDataTransformer', [
'RevisedDataTransformer', RevisedDataTransformer => {
    return class ControllerFaultDataTransformer extends RevisedDataTransformer {
        /**
         * Prepares request parameters before passing them to DataTransport.
         * @param {ListDataTransportRequestParams} requestParams - Takes care of special
         * properties such as `fields_`, `offset_`, `limit_`.
         * @returns {ListDataTransportRequestParams}
         * @override
         * @public
         */
        getRequestConfig(requestParams) {
            const params = super.getRequestConfig(requestParams);

            if ('fields_' in params && Array.isArray(params['fields_'])) {
                params['include'] = params['fields_'].join(',');
            }

            delete params['fields_'];

            return params;
        }

        /**
         * API is returning a hash of faults instead of the list.
         * @override
         **/
        processResponse(rsp) {
            let results = [];

            if ('results' in rsp.data) {
                results = _.map(rsp.data.results, (row, type) => {
                    const config = {
                        type,
                        faults: row,
                    };

                    return { config };
                });
            }

            rsp.data.results = results;
            rsp.data.count = results.length;

            return rsp;
        }
    };
}]);
