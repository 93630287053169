/**
 * Module for Templates>Security>GeoDB.
 * @preferred
 * @module GeoDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    GeoDb,
    GeoDbCollection,
    GeoDbFileConfigItem,
    GeoDbMappingConfigItem,
    GeoDbMappingElementConfigItem,
    GEO_DB_COLLECTION_TOKEN,
    GEO_DB_FILE_CONFIG_ITEM_TOKEN,
    GEO_DB_ITEM_TOKEN,
    GEO_DB_MAPPING_CONFIG_ITEM_TOKEN,
    GEO_DB_MAPPING_ELEMENT_CONFIG_ITEM_TOKEN,
} from '.';

const geoDbModule = angular.module('avi/geo-db');

const factories = [
    {
        factory: GeoDbCollection,
        name: GEO_DB_COLLECTION_TOKEN,
    },
    {
        factory: GeoDb,
        name: GEO_DB_ITEM_TOKEN,
    },
    {
        factory: GeoDbFileConfigItem,
        name: GEO_DB_FILE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: GeoDbMappingConfigItem,
        name: GEO_DB_MAPPING_CONFIG_ITEM_TOKEN,
    },
    {
        factory: GeoDbMappingElementConfigItem,
        name: GEO_DB_MAPPING_ELEMENT_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(geoDbModule, 'factory', name, factory);
});
