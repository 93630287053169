/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @typedef {string} FormattedSize
 * Formatted size for B, KB, MB, or GB with 1 decimal place rounding.
 */

/**
 * @param {number} bytes
 * @return {FormattedSize}
 */
Math.formatBytes = function(bytes) {
    let v = bytes;

    if (v === 0) { return 0; }

    if (!Math.floor(v / 1024)) {
        return `${v} B`;
    }

    v /= 1024;

    if (!Math.floor(v / 1024)) {
        return `${v.toFixed(1)} KB`;
    }

    v /= 1024;

    if (!Math.floor(v / 1024)) {
        return `${v.toFixed(1)} MB`;
    }

    v /= 1024;

    return `${v.toFixed(1)} GB`;
};

Math.num2abbrStr = function(num, prec) {
    const regExp = /\.0+$/;

    let
        str,
        postfix = '';

    if (num > 9999) {
        if (num < 1000000) {
            postfix = 'K';
            str = (num / 1000).toFixed(prec);
        } else {
            postfix = 'M';
            str = (num / 1000000).toFixed(prec);
        }
    } else {
        str = num.toFixed(prec);
    }

    return str.replace(regExp, '') + postfix;
};

/**
 * Syntax sugar for the toFixed method returning not a string but number with three digits after
 * decimal point. Mostly used for SVG attributes.
 * @param {number} number - Number to be rounded.
 * @returns {number}
 * @static
 */
Math.toFixed3 = function(number) {
    return Math.toFixedN(number, 3);
};

/**
 * Rounds value to desired number of digits after decimal point.
 * @param {number} number
 * @param {number} N - How many digits after decimal point to we want to have.
 * @returns {number}
 * @static
 */
Math.toFixedN = function(number, N) {
    return +number.toFixed(N);
};

/**
 * Returns the value itself if it is within boundaries or the closest boundary value when out.
 * @param {number} val
 * @param {number} min
 * @param {number} max
 * @returns {number}
 * @static
 */
Math.clipValue = function(val, min, max) {
    if (max < min) {
        console.error(`clipValue: max=${Math.toFixed3(max)} < min=${Math.toFixed3(min)}`);
        max = min;
    }

    return Math.min(max, Math.max(min, val));
};
