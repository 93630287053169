/**
 * @module NotificationModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { attachComponentBindings } from 'ng/shared/utils';
import { IAviNotificationProps, NotificationService } from 'ng/modules/core';
import './avi-notifications-portal.component.less';

interface IAviNotification {
    id: string;
    componentRef: ComponentRef<Component>;
}

/**
 * Component used to display notifications. All rendered notifications will be contained within the
 * view of this component.
 * @author alextsg
 */
@Component({
    selector: 'avi-notifications-portal',
    template: '<ng-template #notificationsContainer></ng-template>',
})
export class AviNotificationsPortalComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild('notificationsContainer', {
        read: ViewContainerRef,
    }) private notificationsContainerRef: ViewContainerRef;

    /**
     * Contains a list of notifications.
     */
    public notifications: IAviNotification[] = [];

    /**
     * Subscription to the NotificationService, which provides this component with a stack of
     * notifications to be rendered.
     */
    private subscription: Subscription;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private notificationService: NotificationService,
        private elementRef: ElementRef,
    ) {}

    /**
     * @override
     */
    public ngOnInit(): void {
        this.elementRef.nativeElement.classList.add('avi-notifications-portal');
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        this.subscription = this.notificationService.items$
            .subscribe((notifications: IAviNotificationProps[]): void => {
                this.destroyRemovedNotifications(notifications);
                this.setNotifications(notifications);
                this.renderNewNotifications();
            });
    }

    /**
     * @override
     */
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Destroys any notifications that no longer exist in the notifications stack from the
     * NotificationsService.
     */
    private destroyRemovedNotifications(incomingNotificationProps: IAviNotificationProps[]): void {
        const incomingHash = incomingNotificationProps.reduce((hash, notification) => {
            hash[notification.id] = true;

            return hash;
        }, {});

        const removedNotifications = this.notifications.filter(notification => {
            return !(notification.id in incomingHash);
        });

        removedNotifications.forEach((notification): void => {
            const { hostView } = notification.componentRef;
            const index = this.notificationsContainerRef.indexOf(hostView);

            this.notificationsContainerRef.remove(index);
        });
    }

    /**
     * Creates a notificationsStack that matches the notificationPropsStack coming from the
     * NotificationsService.
     */
    private setNotifications(incomingNotificationProps: IAviNotificationProps[] = []): void {
        const existingNotificationRefs = this.notifications.reduce((hash, notification) => {
            hash[notification.id] = notification.componentRef;

            return hash;
        }, {});

        // Notification-props come in as a list where new notifications are added to the end. We
        // want to render new notifications on top, so we're reversing the list.
        const reversedNotificationProps = [...incomingNotificationProps].reverse();

        this.notifications = reversedNotificationProps.map((props): IAviNotification => {
            const { id } = props;
            const componentRef = id in existingNotificationRefs ?
                existingNotificationRefs[id] :
                this.createComponentRef(props);

            return {
                componentRef,
                id,
            };
        });
    }

    /**
     * Renders any notification in the notificationsStack that doesn't already exist in the
     * viewContainerRef.
     */
    private renderNewNotifications(): void {
        this.notifications.forEach((notification, stackIndex): void => {
            const { hostView } = notification.componentRef;
            const index = this.notificationsContainerRef.indexOf(hostView);

            if (index < 0) {
                this.notificationsContainerRef.insert(hostView, stackIndex);
            }
        });
    }

    /**
     * Creates an instance of the notification component.
     */
    private createComponentRef(notificationProps: IAviNotificationProps): ComponentRef<Component> {
        const { component, componentProps = {}, id } = notificationProps;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = componentFactory.create(this.notificationsContainerRef.parentInjector);
        const defaultComponentProps = {
            onClose: () => this.notificationService.remove(id),
        };
        const extendedComponentProps: {} = {
            ...defaultComponentProps,
            ...componentProps,
        };

        attachComponentBindings(componentRef, extendedComponentProps);

        return componentRef;
    }
}
