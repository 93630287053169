/**
 * @module PersistenceProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    PersistenceProfile,
    PersistenceProfileCollection,
    PERSISTENCE_PROFILE_COLLECTION_TOKEN,
    PERSISTENCE_PROFILE_ITEM_TOKEN,
} from '.';

const persistenceProfileModule = angular.module('avi/persistence-profile');

const factories = [
    {
        factory: PersistenceProfileCollection,
        name: PERSISTENCE_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: PersistenceProfile,
        name: PERSISTENCE_PROFILE_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(persistenceProfileModule, 'factory', name, factory);
});
