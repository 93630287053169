/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { createDropdownOption, createOptionsFromEnumProps } from 'ng/shared/utils';
import {
    HSMType,
    SSLCertificateType,
    SSLKeyAlgorithm,
} from 'generated-types';

import {
    SchemaService,
    StringService,
} from 'ajs/modules/core/services';

import {
    IAviDropdownOption,
    UploadType,
} from 'ng/shared/components';

import {
    Certificate,
    CertificateCreateType,
    CertificateManagementCollection,
    TCertificateManagementCollection,
} from 'ajs/modules/security';

import * as globalL10n from 'global-l10n';
import * as l10n from './ssl-certificate-create-application-modal.l10n';

import {
    ISigningRequestData,
} from './ssl-certificate-signing-request/ssl-certificate-signing-request.component';

import './ssl-certificate-create-application-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     This is a modal component for creating an Application or a Controller(System) certificate.
 *     These two kinds of certificates are very similar and are therefore coupled together, as
 *     opposed to a Root certificate that has its own modal.
 *     Certificates of type 'Self-signed' and 'Import' can only be created. 'CSR', or Certificate
 *     Signing Requests, can be edited, but only to attach a signed certificate - all other input
 *     fields are disabled on 'Edit'.
 *     User workflows:
 *         Self-signed: Create and save certificate.
 *         CSR: Create and save certificate -> Edit to add signed certificate.
 *         Import: Upload/copy paste certificate -> Validate -> Save certificate.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-certificate-create-application-modal',
    templateUrl: './ssl-certificate-create-application-modal.component.html',
})
export class SslCertificateCreateApplicationModalComponent implements OnInit, AfterViewInit {
    /**
     * Certificate ObjectTypeItem.
     */
    @Input()
    public editable: Certificate;

    /**
     * Stores certificate creation type.
     * User can select between Self-signed, CSR, and Import to create certificate.
     * (editable.config.type contains type of certificate created i.e. Root/Intermediate CA,
     * Controller or application certificate.)
     */
    public certificateCreateType: CertificateCreateType;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Layout for clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     *  Stores validation state.
     */
    public validated: boolean;

    /**
     *  True if HSM group has encrypted private key.
     */
    public showHSMEncryptedPrivateKey = false;

    /**
     * SSLKeyAndCertificate object type.
     */
    public objectType: string;

    /**
     * CertificateCreate type dropdown options.
     */
    public certificateCreateTypeOptions: IAviDropdownOption[];

    /**
     * Variable to access CertificateCreateType enum in template.
     */
    public certificateCreateTypeEnum = CertificateCreateType;

    /**
     * SSLKeyAlgorithm dropdown options.
     */
    public sslKeyAlgorithmOptions: IAviDropdownOption[];

    /**
     * SSLRSAKeySize dropdown options.
     */
    public sslRsaKeySizeOptions: IAviDropdownOption[];

    /**
     * SSLRSAKeyECCurve dropdown options.
     */
    public sslKeyECCurveOptions: IAviDropdownOption[];

    // TODO: Define type once hsmGroupCollection.js converted to ts.
    /**
     * HsmGroupCollection instance - for hsmGroup avi-collection-dropdown.
     */
    public readonly hsmGroupCollection: any;

    /**
     * certificateManagementCollection instance - for certificateManagement avi-collection-dropdown.
     */
    public readonly certificateManagementCollection: CertificateManagementCollection;

    /**
     * Hash of SSLKeyAlgorithm type enum.
     */
    public readonly sslKeyAlgorithmTypeHash = {
        SSL_KEY_ALGORITHM_EC: SSLKeyAlgorithm.SSL_KEY_ALGORITHM_EC,
        SSL_KEY_ALGORITHM_RSA: SSLKeyAlgorithm.SSL_KEY_ALGORITHM_RSA,
    };

    /**
     * Hash of SSLCertificateType enum.
     */
    public readonly SSLCertificateTypeHash = {
        SSL_CERTIFICATE_TYPE_VIRTUALSERVICE: SSLCertificateType.SSL_CERTIFICATE_TYPE_VIRTUALSERVICE,
    };

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        schemaService: SchemaService,
        @Inject('HSMGroupCollection')
        HSMGroupCollection: any,
        @Inject(CertificateManagementCollection)
        CertificateManagementCollection: TCertificateManagementCollection,
    ) {
        this.hsmGroupCollection = new HSMGroupCollection();
        this.certificateManagementCollection = new CertificateManagementCollection();

        const sslKeyAlgorithmEnumValues = schemaService.getEnumValues('SSLKeyAlgorithm');
        const sslRSAKeySizeEnumValues = schemaService.getEnumValues('SSLRSAKeySize');
        const sslKeyECCurveEnumValues = schemaService.getEnumValues('SSLKeyECCurve');

        this.sslKeyAlgorithmOptions = createOptionsFromEnumProps(sslKeyAlgorithmEnumValues);
        this.sslRsaKeySizeOptions = createOptionsFromEnumProps(sslRSAKeySizeEnumValues);
        this.sslKeyECCurveOptions = createOptionsFromEnumProps(sslKeyECCurveEnumValues);

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.certificateCreateType = this.editable.certificateCreateType;

        if (!this.editable.id) {
            this.onCertCreationTypeChange(this.certificateCreateType);
        }

        this.certificateCreateTypeOptions = [
            createDropdownOption(CertificateCreateType.SELF_SIGNED,
                this.l10nService.getMessage(l10nKeys.certificateCreateTypeSelfSignedLabel)),
            createDropdownOption(CertificateCreateType.CA_SIGNED,
                this.l10nService.getMessage(l10nKeys.certificateCreateTypeCsrLabel)),
            createDropdownOption(CertificateCreateType.IMPORT,
                this.l10nService.getMessage(l10nKeys.certificateCreateTypeImport)),
        ];
    }

    /**
     * @override
     * Set Validated flag after all ngmodel values are set.
     * (Because file-upload-textarea will emit onUploadTypeChange event after
     * ngmodel is set which will reset validation flag.)
     */
    public ngAfterViewInit(): void {
        this.validated = Boolean(this.editable.id);
    }

    /**
     * Called when user selects between "Self-signed", "CSR", and "Import" types.
     */
    public onCertCreationTypeChange(type: CertificateCreateType): void {
        this.editable.changeCertCreateType(type);
        this.editable.clearCertificate();
    }

    /**
     * Called by HSMGroup CollectionDropdown to show/hide the Encrypted Private Key field.
     */
    public handleHSMGroupChange(selected: string): void {
        if (selected) {
            const hsmObjId = this.stringService.slug(selected);
            const hsmItem = this.hsmGroupCollection.getItemById(hsmObjId);

            this.showHSMEncryptedPrivateKey =
                hsmItem.data.config.hsm.type === HSMType.HSM_TYPE_THALES_NETHSM;
        } else {
            this.showHSMEncryptedPrivateKey = false;
            delete this.editable.data.config.enckey_base64;
            delete this.editable.data.config.enckey_name;
        }
    }

    /**
     * Called by sslCertificateSigningRequest to populate certificate properties.
     */
    public handleCertificateSigningRequestChange(signingRequestData : ISigningRequestData): void {
        const config = this.editable.getConfig();
        const { base64, certificate } = signingRequestData;

        config.certificate_base64 = base64;
        config.certificate.config.certificate = certificate;
    }

    /**
     * Sends certificate validate request and sets the Certificate config.
     */
    public validateCert(): void {
        this.editable.validateCertificate()
            .then(() => {
                this.validated = true;
            });
    }

    /**
     * Returns true if key or certificate has value.
     */
    public get hasCertOrKey(): boolean {
        const { certificate, key } = this.editable.getConfig();

        return Boolean(certificate.config.certificate || key);
    }

    /**
     * Resets certificate validation state.
     */
    public resetValidation(): void {
        this.validated = false;
    }

    /**
     * Sets certificate_base64 flag based on uploadType
     */
    public setCertificateBase64(uploadType: UploadType): void {
        this.editable.config.certificate_base64 = uploadType === UploadType.FILE;
        this.resetValidation();
    }

    /**
     * Sets key_base64 flag based on uploadType
     */
    public setKeyBase64(uploadType: UploadType): void {
        this.editable.config.key_base64 = uploadType === UploadType.FILE;
    }

    /**
     * Destroy collection instances.
     * @override
     */
    public ngOnDestroy(): void {
        this.hsmGroupCollection.destroy();
        this.certificateManagementCollection.destroy();
    }
}
