/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

const prefix = 'traffic-clone-profile';

export const trafficCloneProfileModalBreadcrumbTitle = `${prefix}.trafficCloneProfileModalBreadcrumbTitle`;
export const cloneServerModalBreadcrumbTitle = `${prefix}.cloneServerModalBreadcrumbTitle`;

export const ENGLISH = {
    [trafficCloneProfileModalBreadcrumbTitle]: 'Traffic Clone Profile',
    [cloneServerModalBreadcrumbTitle]: 'Clone Server',
};
