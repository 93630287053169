/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name Series
 * @description
 *
 *     Series class. Keeps series values, anomalies, dominant factors (when applicable),
 *     header and statistics.
 *
 *     Usually used witin {@link Metric} instance.
 */

/**
 * @typedef {Object} SeriesData
 * @property {SeriesHeader} header
 * @property {Object[]} data - Original data points received from the backend.
 */

/**
 * @typedef {Object} SeriesDataPoint
 * @property {number} timestamp - UNIX timestamp (in ms).
 * @property {number|string} value - Series value.
 * @property {boolean|undefined} noData - This flag is set to true for dummy values when back end
 *     has no data.
 */

/**
 * @typedef {Object} SeriesHeader
 * @property {string} name
 * @property {number} metrics_min_scale
 * @property {string} units - MetricUnits protobuf enum.
 * @property {string} entity_uuid - Actual item (VS/Pool/SE) Id this series is about.
 * @property {boolean} metrics_sum_agg_invalid - When true can't calculate sum of values over time.
 * @property {string} metric_description - For series of a string type this can have a comma
 *     separated list of all possible values. Oreder does matter. When those are not known in
 *     advance this string can have no value.
 */
//TODO add UI properties

/**
 * @typedef {Object} SeriesStats - Dummy values don't participate in calculations of those.
 * @property {number} max
 * @property {number} sum - Summed values (when applicable).
 * @property {number} avg - Average value.
 * @property {number} maxTimestamp
 * @property {SeriesDataPoint} current - Deprecated. Reference to the most recent data point.
 */

/**
 * @typedef {Object} SeriesConfig
 * @property {string} seriesId - Actual series id, such as se_if.avg_bandwidth.
 * @property {string} id - Series id within Metric.seriesHash_.
 * @property {string|undefined} title - Human readable series name.
 * @property {string|undefined} itemRef
 * @property {string|undefined} itemId
 * @property {string|undefined} itemType
 * @property {string|undefined} objId
 * @property {string|undefined} colorClassName
 * @property {string|undefined} aggregation - Will become aggregation_dimension param on API call.
 */

//TODO support time difference between controller and UI
//TODO remove/split params_
angular.module('avi/metrics').factory('Series', [
'Base', 'NamesHelper',
function(Base, NamesHelper) {
    /** @class */
    class Series extends Base {
        constructor(args = {}) {
            super(args);

            /**
             * Series name or back-end "metric_id". Used for the reponse filtering.
             * @type {string}
             * @public
             **/
            this.seriesId = args.seriesId || args.id;//actual series name/id

            if (!this.seriesId) {
                console.warn('Can not create a series wo series id', this);
            }

            /**
             * Ref of {@link Item} this series is about.
             * @type {string}
             * @public
             */
            this.itemRef = args.itemRef || '';

            /**
             * ID of {@link Item} this series is about. When set is used for API response filtering.
             * @type {string}
             * @public
             */
            this.itemId = args.itemId || this.itemRef.slug() || '';

            /**
             * Type of {@link Item} this series is about.
             * @type {string}
             * @public
             */
            // TODO figure out from ref
            this.itemType = args.itemType || '';

            /**
             * Some series are fetched with not just itemId but objId/serverId as well. When set
             * is used for response filtering.
             * @type {?string}
             */
            this.objId = args.objId || '';

            /**
             * Dimension id when applicable.
             * @type {string}
             */
            this.dimensionId = args.dimensionId || '';

            /**
             * List of dimensions the Series keeps data about.
             * @type {String[]}
             */
            this.dimensions = Array.isArray(args.dimensions) ? args.dimensions.concat() : [];

            /**
             * Series are usually stored in Metric.seriesHash_, hence need to have a unique id
             * even when seriesId, itemId and objId are the same for different series within one
             * Metric instance. Rear case but still supported.
             * @type {string}
             * @public
             */
            this.id = args.id || this.getId();

            /**
             * Human readable series name.
             * @type {string}
             * @protected
             */
            this.title = args.title || NamesHelper.getTitle(this.getSeriesId(), true);

            /**
             * Chart color class name.
             * @type {string}
             * @private
             */
            this.colorClassName_ = args.colorClassName ||
                NamesHelper.getColorClass(this.getSeriesId());

            /**
             * Ordered by the timestamp list of data points. Length of the list is defined by
             * limit parameter.
             * @type {SeriesDataPoint[]}
             */
            this.values = [];

            /**
             * Hash of data points by UNIX timestamps.
             * @type {{number: SeriesDataPoint}}
             */
            this.valuesHash = {};

            /**
             * Ordered by timestamp list of anomalies.
             * @type {SeriesDataPoint[]}
             */
            this.anomalies = [];

            /**
             * Hash of data points by timestamps.
             * @type {{number: SeriesDataPoint}}
             * @protected
             */
            this.anomaliesHash_ = {};

            /**
             * Total number of anomalies for the Series timeframe.
             * @type {number}
             */
            this.anomaliesQ = 0;

            /** @type {SeriesStats} */
            this.statistics = {};

            /**
             * Series header copied from the backend response.
             * @type {SeriesHeader}
             */
            this.header = {};

            /**
             * @type {Object}
             * @property {number} step
             * @property {number} limit
             * @protected
             */
            this.params_ = {};

            /**
             * Dominant contributors times.
             * @type {string[]}
             */
            this.dominators = [];

            /**
             * dimension_aggregation parameter value. Set for aggregated series {@link AggSeries}
             * only.
             * @type {string}
             */
            this.aggregation = args.aggregation || '';

            /**
             * Sometimes having aggregation param doesn't mean Series gonna be aggregated. For
             * these cases we can pass such argument as an override.
             * @type {boolean}
             * @protected
             */
            this.isAggregated_ = false;

            if (this.aggregation) {
                this.isAggregated_ = angular.isUndefined(args.isAggregated) || !!args.isAggregated;
            }

            // can create series with data
            if (angular.isObject(args.data)) {
                const {
                    step,
                    limit,
                    seriesData: data,
                    anomalyData,
                } = args.data;

                this.process(data, anomalyData, step, limit);
            }
        }

        /**
         * Returns series id. Usually set through constructor params by Metric using this Series.
         * @returns {string}
         * @public
         */
        getId() {
            if (this.id) {
                return this.id;
            }

            const {
                seriesId,
                itemId,
                objId,
                dimensionId,
            } = this;

            let id = `${itemId}:${seriesId}`;

            if (objId) {
                id += `:${objId}`;
            }

            if (dimensionId) {
                id += `:${dimensionId}`;
            }

            return id;
        }

        /**
         * Returns series data.
         * @returns {Object}
         * @public
         */
        getData() {
            const {
                values,
                header,
                valuesHash,
                statistics,
            } = this;

            return {
                values,
                header,
                valuesHash,
                statistics,
            };
        }

        /**
         * Returns data point by UNIX timestamp.
         * @param {number} timestamp
         * @returns {SeriesDataPoint|null}
         */
        getDataPoint(timestamp) {
            return this.valuesHash[timestamp] || null;
        }

        /**
         * Returns true when passed header object is considered to be of this series.
         * @param {SeriesHeader} header
         * @returns {boolean}
         * @private
         */
        // TODO add pool_uuid support, now this is a workaround
        checkHeaderForMatch_(header) {
            const
                { objId, itemId, dimensionId } = this,
                {
                    obj_id: hObjId,
                    entity_ref: hEntityRef,
                    entity_uuid: hEntityId,
                    pool_ref: hPoolRef,
                    dimension_data: dimensionData,
                } = header,
                hPoolId = hPoolRef ? hPoolRef.slug() : '',
                hItemId = hEntityId || (hEntityRef ? hEntityRef.slug() : '');

            let hDimensionId = '';

            if (dimensionData) {
                hDimensionId = dimensionData[0]['dimension_id'];
            }

            return this.seriesId === header['name'] &&
                (!itemId || itemId === hItemId || itemId === hPoolId) &&
                (!objId || objId === hObjId) &&
                (!dimensionId || dimensionId === hDimensionId);
        }

        /**
         * Finds series in an array of series provided by the back-end and pre filtered by
         * {@link Metric.filterResponse}.
         * @param {SeriesData|SeriesData[]} rsp - Array of series or a single one.
         * @returns {SeriesData|undefined} - Series back-end object having header object and
         *     values array.
         */
        findSeriesInResponse_(rsp) {
            let sRsp = null;

            if (Array.isArray(rsp)) {
                const filteredSeries =
                    _.filter(rsp, ({ header }) => this.checkHeaderForMatch_(header));

                if (filteredSeries) {
                    [sRsp] = filteredSeries;

                    if (filteredSeries.length > 1) {
                        console.error(
                            'Series filter has returned multiple series',
                            this.seriesId,
                            rsp,
                            filteredSeries,
                        );
                    }
                }
            } else if (Series.isSeriesDataObj(rsp) && this.checkHeaderForMatch_(rsp['header'])) {
                sRsp = rsp;
            }

            // for incremental updates of non-static-series-list Metrics some series don't get
            // updates and it's fine
            if (rsp && !sRsp && !this.hasData() && process.env.NODE_ENV !== 'production') {
                console.warn('Series didn\'t find its response', this.getId());
            }

            return sRsp;
        }

        /**
         * Finds an anomaly response for the series out of the list received from the back-end and
         * pre-filtered by {@link Metric.filterResponse}.
         * @param {SeriesData|SeriesData[]} rsp - Array of anomalies.
         * @returns {SeriesData|undefined}
         */
        //TODO use one method for both?
        findAnomaliesInResponse_(rsp) {
            let aRsp;

            if (Array.isArray(rsp)) {
                aRsp = _.find(rsp,
                    ({ header }) => this.checkHeaderForMatch_(header));
            } else if (Series.isSeriesDataObj(rsp) && this.checkHeaderForMatch_(rsp['header'])) {
                aRsp = rsp;
            }

            return aRsp || null;
        }

        /**
         * Processes the data we've got from the backend. Takes care of incremental updates and
         * consistency of data points (number of points and it's order).
         * @param {SeriesData|SeriesData[]} rsp - Set of responses for all {@link Metric} Series.
         * @param {SeriesData|SeriesData[]} anomalies - Set of responses for all {@link Metric}
         *     anomalies.
         * @param {number} step -  Difference in seconds between two data points.
         * @param {number} limit - How many points do we need for the series.
         */
        process(rsp, anomalies, step, limit) {
            const { params_: params } = this;

            let gotUpdated = false;

            if (step !== params.step || limit !== params.limit) {
                this.emptyData_();
                params.step = step;
                params.limit = limit;
                gotUpdated = true;
            }

            const series = this.findSeriesInResponse_(rsp);

            if (series) {
                if (!this.hasData()) {
                    this.setHeader_(series.header);
                }

                gotUpdated = this.updateValues_(series.data);

                if (gotUpdated) {
                    this.updateHeader_();

                    if (Array.isArray(series.dominators)) {
                        this.addDominators(series.dominators);
                    }
                }
            } else if (this.hasData()) {
                //no updates came from the backend, let's generate some fake points
                gotUpdated = this.updateValues_([]);
            }

            const anomaliesGotUpdated = this.updateAnomalies_(
                this.findAnomaliesInResponse_(anomalies),
            );

            if (gotUpdated) {
                if (!this.isAggregated()) {
                    this.setValuesStatistics_();
                }

                this.trigger(Series.VALUES_UPDATE_EVENT, this);
            }

            if (anomaliesGotUpdated) {
                this.trigger(Series.ANOMALIES_UPDATE_EVENT, this);
            }

            return gotUpdated || anomaliesGotUpdated;
        }

        /**
         * Adds dominant contributors to dominators array.
         * @param {string[]} dominators - ISO8601 values.
         */
        addDominators(dominators = []) {
            this.dominators = this.dominators.concat(dominators);

            if (this.dominators.length) {
                const { timestamp: minTS } = this.getFirstPoint();
                const { timestamp: maxTS } = this.getLatestPoint();
                // prevent duplicate values
                const tsHash = {};
                let hasHash = false;
                let dts = 0;

                // remove all dominant contributors not in range of data timestamps
                this.dominators = this.dominators.filter(isoTime => {
                    dts = new Date(isoTime).getTime();
                    hasHash = dts in tsHash;
                    tsHash[dts] = undefined;

                    return !hasHash && dts >= minTS && dts <= maxTS;
                });
            }
        }

        /**
         * Copies series header received from the back-end.
         * @param {SeriesHeader} header
         * @private
         */
        setHeader_(header) {
            this.header = angular.copy(header);

            this.setItemRef_();

            if (this.isStringType()) {
                this.header.stringValuesList = [''];

                if (this.header['metric_description']) {
                    this.header.stringValuesList.push(
                        ...this.header['metric_description'].split(', '),
                    );
                }
            }
        }

        /**
         * Updates series header metadata about an Item this data is about.
         * @private
         */
        setItemRef_() {
            const { header } = this;

            if ('entity_ref' in header) {
                this.itemRef = header['entity_ref'];
                this.itemId = this.itemRef.slug();
            } else if ('entity_uuid' in header) {
                this.itemRef = header['entity_uuid'];
                this.itemId = header['entity_uuid'];
            } else {
                this.itemRef = '';
                this.itemId = '';
            }

            if ('obj_id_type' in header) {
                this.itemType = header['obj_id_type'];
            } else {
                this.itemType = '';
            }
        }

        /**
         * Returns item ref when set.
         * @returns {string}
         * @public
         */
        getItemRef() {
            return this.itemRef;
        }

        /**
         * Returns item id when set.
         * @returns {string}
         * @public
         */
        getItemId() {
            return this.itemId;
        }

        /**
         * Updates (when applicable) series header after getting and setting new values.
         * @private
         */
        updateHeader_() {
            if (this.isStringType()) {
                const { header } = this;
                let stringValues;

                header.stringValuesList.length = 1;

                if (stringValues = header['metric_description']) {
                    header.stringValuesList.push(...stringValues.split(', '));
                } else {
                    const stringValuesHash = {};

                    this.values.forEach(({ y }) => stringValuesHash[y] = true);
                    header.stringValuesList.push(...Object.keys(stringValuesHash));
                }
            }
        }

        /**
         * Returns a dummy data point to keep a persistent values array even when back end has
         * no data for some reason.
         * @param {number} timestamp - UNIX timestamp (in ms).
         * @returns {SeriesDataPoint}
         * @private
         */
        getNoDataDataPoint_(timestamp) {
            return {
                timestamp,
                value: this.isStringType() ? '' : 0,
                noData: true,
            };
        }

        /**
         * Merges server response into existent list of points. Makes appropriate shift
         * (drops some old point(s), appends new one(s)) based on the current time.
         * @param {Object[]} newValuesList
         * @returns {boolean} True when values list actually got updated.
         * @private
         */
        updateValues_(newValuesList = []) {
            const { valuesHash: prevValuesHash, values } = this;
            const { step, limit } = this.params_;
            const mergedValuesList = [];
            const stepMs = step * 1000;

            newValuesList = newValuesList
                .filter(({ is_null: isNull }) => !isNull)
                .map(Series.dataPointTransform_);

            const newValuesHash = Series.getValuesHash_(newValuesList);

            /** @type {boolean} */
            let gotUpdated = false;

            const { start, end } = Series.getProcessedTimeFrame_(step, limit);
            let startTs = Number(start);
            let endTs = Number(end);

            /** @type {boolean} */
            const noMostRecentPointData = !(endTs in newValuesHash);

            // Since we need to preserve the length of values list these are options when
            // most recent point didn't come from the backend:
            // - if we had full list of points before the update we can effectively do nothing
            //   and wait for the next update
            // - otherwise we need to add a "noData" point for the most recent timestamp
            //   and have a dip on the chart.
            if (noMostRecentPointData &&
                (startTs - stepMs in prevValuesHash || startTs - stepMs in newValuesHash)
            ) {
                // shifting time frame into the past by one step
                startTs -= stepMs;
                endTs -= stepMs;
            }

            for (let ts = startTs; ts <= endTs; ts += stepMs) {
                let dataPoint;

                if (ts in newValuesHash) {
                    const newDataPoint = newValuesHash[ts];
                    const hadThisPoint = ts in prevValuesHash;
                    const pointIsUpdated = !hadThisPoint ||
                        prevValuesHash[ts].noData === true ||
                        prevValuesHash[ts].value !== newDataPoint.value;

                    dataPoint = pointIsUpdated ? { ...newDataPoint } : prevValuesHash[ts];
                    gotUpdated = gotUpdated || pointIsUpdated;
                } else if (ts in prevValuesHash) {
                    dataPoint = prevValuesHash[ts];
                } else {
                    gotUpdated = true;
                    dataPoint = this.getNoDataDataPoint_(ts);
                }

                mergedValuesList.push(dataPoint);
            }

            if (gotUpdated) {
                values.length = 0;
                values.push(...mergedValuesList);
                this.valuesHash = Series.getValuesHash_(values);
            }

            return gotUpdated;
        }

        /**
         * Merged list of anomalies received from the back end into existent list.
         * @param {?SeriesData} anomSeriesData
         * @returns {boolean} - True when list got updated.
         * @private
         */
        //TODO if we always use same step and limit for values and anomalies, should we merge
        //anomalies Q into values?
        updateAnomalies_(anomSeriesData) {
            const
                {
                    anomaliesHash_: prevAnomaliesHash,
                    anomalies,
                } = this,
                mergedAnomaliesList = [];

            const dataPoints = anomSeriesData ? anomSeriesData.data : [];

            const newAnomaliesList = dataPoints
                .filter(({ value }) => value)
                .map(Series.anomalyDataPointTransform_);

            const newAnomaliesHash = Series.getValuesHash_(newAnomaliesList);

            let
                gotUpdated = false,
                anomaliesQ = 0;

            const
                { step, limit } = this.params_,
                { start, end } = Series.getProcessedTimeFrame_(step, limit),
                endTs = +end;

            for (let ts = +start; ts <= endTs; ts += step * 1000) {
                let dataPoint;

                if (ts in newAnomaliesHash) {
                    gotUpdated = gotUpdated || !(ts in prevAnomaliesHash);
                    dataPoint = newAnomaliesHash[ts];
                } else if (ts in prevAnomaliesHash) {
                    dataPoint = prevAnomaliesHash[ts];
                }

                if (dataPoint) {
                    mergedAnomaliesList.push(dataPoint);
                    anomaliesQ += dataPoint.value;
                }
            }

            //if we've dropped at least one point because of time frame shift
            gotUpdated = gotUpdated || mergedAnomaliesList.length !== anomalies.length;

            if (gotUpdated) {
                anomalies.length = 0;
                anomalies.push(...mergedAnomaliesList);
                this.anomaliesQ = anomaliesQ;
                this.anomaliesHash_ = Series.getValuesHash_(anomalies);
            }

            return gotUpdated;
        }

        /**
         * Set statistics object of Series instance. Called after processing values has been
         * done and those were actually updated.
         * @private
         */
        setValuesStatistics_() {
            const stat = {};

            let
                max = -Infinity,
                maxTimestamp = null,
                sum = 0,
                countReal = 0;

            if (!this.isStringType()) {
                this.values.forEach(({ timestamp, value, noData }) => {
                    if (!noData) {
                        if (value > max) {
                            max = value;
                            maxTimestamp = timestamp;
                        }

                        sum += value;
                        countReal++;
                    }
                });

                const { metrics_sum_agg_invalid: sumAggInvalid } = this.getHeader();

                //need fallback values for situations when all values were faked
                stat.max = _.isFinite(max) ? max : 0;
                stat.avg = countReal ? sum / countReal : 0;
                stat.sum = sumAggInvalid ? NaN : sum;
                stat.maxTimestamp = maxTimestamp || this.getLatestPointTime();

                if (!_.isNaN(+stat.sum) && this.getUnits().lastIndexOf('PER_SECOND') !== -1) {
                    stat.sum *= this.params_.step;
                }
            }

            //DEPRECATED, use getLatestPoint instead
            stat.current = this.getLatestPoint();

            this.statistics = stat;
        }

        /**
         * Returns first dataPoint this series has.
         * @returns {SeriesDataPoint|null}
         * @public
         */
        getFirstPoint() {
            if (this.hasData()) {
                return this.values[0];
            }

            return null;
        }

        /**
         * Returns the latest data point or null if there are none.
         * @returns {SeriesDataPoint | null}
         * @param {boolean=} realPoint - When true passed method gonna ignore fake points.
         * @public
         */
        getLatestPoint(realPoint = false) {
            if (this.hasData()) {
                const { values } = this;

                if (!realPoint) {
                    return values.slice(-1)[0];
                } else {
                    const index = _.findLastIndex(values, ({ noData }) => !noData);

                    return index !== -1 ? values[index] : null;
                }
            }

            return null;
        }

        /**
         * Looks for the latest point's date and returns UNIX timestamp if found or undefined if
         * not.
         * @param {boolean} realPoint - When set to true only real (non faked) points will be
         *     considered.
         * @returns {number|undefined}
         * @public
         */
        getLatestPointTime(realPoint = false) {
            const dataPoint = this.getLatestPoint(realPoint);

            return dataPoint ? dataPoint.timestamp : undefined;
        }

        /**
         * Returns a series value according to the desired value type and timestamp (for exact type
         * only).
         * @param {SeriesDisplayValueType} type.
         * @param {Moment|number=} timestamp
         * @returns {number|string|undefined}
         * @public
         */
        getValue(type, timestamp) {
            const { statistics, valuesHash } = this;

            if (type && this.hasData()) {
                if (type in statistics) {
                    return type !== 'current' ? statistics[type] : this.getLatestPoint().value;
                } else if (type === 'exact' &&
                    (timestamp = +timestamp) && timestamp in valuesHash) {
                    return valuesHash[timestamp].value;
                }
            }
        }

        /**
         * Returns a series value type provided by the back-end.
         * @returns {string}
         * @public
         */
        getUnits() {
            return this.header.units || '';
        }

        /**
         * Some metrics provide string values, this is the method to figure whether we are
         * working with string value type series.
         * @returns {boolean}
         * @public
         */
        isStringType() {
            return this.getUnits() === 'STRING';
        }

        /**
         * To check whether series has data.
         * @returns {boolean}
         * @public
         */
        hasData() {
            return !!this.values.length;
        }

        /**
         * @returns {SeriesHeader}
         * @public
         */
        getHeader() {
            return this.header || null;
        }

        /**
         *
         * @returns {SeriesStats}
         * @public
         */
        getStats() {
            return this.statistics;
        }

        /**
         * Empties all data present in instance.
         * @private
         */
        emptyData_() {
            this.values.length = 0;
            this.valuesHash = {};
            this.anomalies.length = 0;
            this.anomaliesQ = 0;
            this.anomaliesHash_ = {};
            this.dominators.length = 0;

            if (!this.isAggregated()) {
                this.setValuesStatistics_();
            }
        }

        /** @public */
        emptyData() {
            this.emptyData_();
        }

        /**
         * Returns human readable series label.
         * @returns {string}
         * @public
         */
        getTitle() {
            return this.title || this.hasData() && this.getHeader()['title'] || this.getSeriesId();
        }

        /**
         * Returns true for aggregated series.
         * @returns {boolean}
         * @public
         */
        isAggregated() {
            return this.isAggregated_;
        }

        /**
         * Every series has a series id, which is called as metric_id while making API call. Not
         * to be confused with Series.id which is "full" id containing seriesId as well as other
         * ids.
         * @returns {string}
         * @public
         */
        getSeriesId() {
            return this.seriesId;
        }

        /**
         * Returns objId this series is using.
         * @returns {string}
         * @public
         */
        getObjectId() {
            return this.objId;
        }

        /**
         * Returns dimensionId this Series has.
         * @returns {string}
         * @public
         */
        getDimensionId() {
            return this.dimensionId;
        }

        /**
         * Returns color class name for charting directives.
         * @returns {string}
         * @public
         */
        getColorClassName() {
            return this.colorClassName_;
        }

        /**
         *
         * @returns {number}
         */
        getAnomaliesQ() {
            return this.anomaliesQ;
        }

        /**
         * Returns Series.params_.
         * @returns {Object}
         * @public
         */
        getParams() {
            return angular.copy(this.params_);
        }

        /**
         * Custom type checked for series object provided by the back end.
         * @param {Object} sData
         * @returns {boolean}
         * @static
         */
        static isSeriesDataObj(sData) {
            return angular.isObject(sData) && 'header' in sData && 'data' in sData;
        }

        /**
         * Data transformer for series data point object.
         * @param {Object} dataPoint - Received from the back end.
         * @returns {SeriesDataPoint}
         * @private
         * @static
         */
        static dataPointTransform_(dataPoint) {
            const res = {
                timestamp: +moment(dataPoint['timestamp']),
                value: 'value_str' in dataPoint ? dataPoint['value_str'] : +dataPoint['value'],
            };

            return res;
        }

        /**
         * Data transformer for anomalies data point object.
         * @param {Object} dataPoint - Received from the back end.
         * @returns {SeriesDataPoint}
         * @private
         * @static
         */
        static anomalyDataPointTransform_(dataPoint) {
            return {
                timestamp: +moment(dataPoint['timestamp']),
                value: +dataPoint['value'],
            };
        }

        /**
         * Returns an object with two moment objects defining start and end of a processed
         * period. Basically we take current time (floored by step) and add step multiplied by
         * limit.
         * @param {number} step - Period of time used for data aggregation (i.e. one point
         *     every 30 s).
         * @param {number} limit - How many data points are we expected to render.
         * @returns {{start: moment, end: moment}}
         * @static
         * @private
         */
        static getProcessedTimeFrame_(step, limit) {
            let end = +moment().unix();

            end = moment.unix(end - end % step);//in seconds

            //start is the end of the first period, that is why we have "limit - 1" there
            const start = end.clone().subtract(step * (limit - 1), 's');

            return {
                start,
                end,
            };
        }

        /**
         * Reduces values into a hash by the UNIX timestamp.
         * @param {SeriesDataPoint[]} values
         * @returns {{number: SeriesDataPoint}}
         * @private
         * @static
         */
        static getValuesHash_(values) {
            const hash = {};

            values.forEach(dataPoint => hash[dataPoint.timestamp] = dataPoint);

            return hash;
        }
    }

    /**
     * Values update event name.
     * @type {string}
     */
    Series.VALUES_UPDATE_EVENT = 'valuesUpdate';

    /**
     * Anomalies update event name.
     * @type {string}
     */
    Series.ANOMALIES_UPDATE_EVENT = 'anomaliesUpdate';

    return Series;
}]);
