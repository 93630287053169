/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHdrMatch } from 'generated-types';
import { HdrMatch } from 'object-types';
import { GenericHdrMatchBaseConfigItem } from './generic-hdr-match-base.config-item';

/**
 * @description  HdrMatch ConfigItem class.
 * @author alextsg, Abhinesh Gour
 */
export class HdrMatchConfigItem extends GenericHdrMatchBaseConfigItem<IHdrMatch> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HdrMatch,
            ...args,
        };

        super(extendedArgs);
    }
}
