/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { CompressionProfile } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    CompressionType,
    ICompressionProfile,
} from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { CompressionFilterConfigItem } from '.';

type TCompressionProfilePartial = Omit<ICompressionProfile, 'filter'>;

interface ICompressionProfileConfig extends TCompressionProfilePartial {
    filter: RepeatedMessageItem<CompressionFilterConfigItem>,
}

/**
 * @desc CompressionProfile MessageItem class.
 * @author Nisar Nadaf
 */
export class CompressionProfileConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<ICompressionProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: CompressionProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Method to add Compression Filter.
     */
    public addCompressionFilter(): void {
        this.addChildMessageItem({
            field: 'filter',
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Method to edit Compression Filter.
     */
    public editCompressionFilter(filter: CompressionFilterConfigItem): void {
        this.editChildMessageItem({
            field: 'filter',
            messageItem: filter,
            modalBindings: {
                isEditing: true,
            },
        });
    }

    /**
     * Sets compression type and reset fields accordingly.
     */
    public setType(type: CompressionType): void {
        this.config.type = type;

        if (type === CompressionType.AUTO_COMPRESSION) {
            delete this.config.filter;
        } else {
            this.safeSetNewChildByField('filter');
        }
    }
}
