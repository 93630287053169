/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { aviMediumDateFormatWithSec } from 'ng/modules/core/pipes/avi-date.pipe';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import { VsLogsStore } from '../../../services';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import { VsAppProfileType } from '../../../vs-logs.types';
import * as l10n from './vs-log-cinematic-section-summary.l10n';

import './vs-log-cinematic-section-summary.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description
 *     Component for displaying Summary section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-summary',
    templateUrl: './vs-log-cinematic-section-summary.component.html',
})
export class VsLogCinematicSectionSummaryComponent {
    public readonly vsAppProfileType$ = this.vsLogsStore.vsAppProfileType$;

    public readonly VsAppProfileType = VsAppProfileType;

    /**
     * Object that includes L7 client information.
     */
    public readonly l7ClientInfo$ = this.vsLogCinematicStore.l7ClientInfo$;

    /**
     * Object that includes L4 client information.
     */
    public readonly l4ClientInfo$ = this.vsLogCinematicStore.l4ClientInfo$;

    /**
     * Object that includes policy rule information.
     */
    public readonly policyRules$ = this.vsLogCinematicStore.policyRules$;

    /**
     * Object that includes HTTP request information.
     */
    public readonly requestInfo$ = this.vsLogCinematicStore.requestInfo$;

    public readonly requestDetails$ = this.vsLogCinematicStore.requestDetails$;

    /**
     * Object that includes HTTP response information.
     */
    public readonly responseInfo$ = this.vsLogCinematicStore.responseInfo$;

    public readonly dnsClientInfo$ = this.vsLogCinematicStore.dnsClientInfo$;

    public readonly dnsLoadBalancerInfo$ = this.vsLogCinematicStore.dnsLoadBalancerInfo$;

    /**
     * Format string that specifies date transformation to seconds.
     */
    public readonly timeFormat = aviMediumDateFormatWithSec;

    /**
     * Get global keys from source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash from enum values to the corresponding translated labels.
     */
    public readonly enumLabelHash = {
        ...this.schemaService.getEnumValueLabelsHash('DnsRecordType'),
        ...this.schemaService.getEnumValueLabelsHash('Protocol'),
    };

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly schemaService: SchemaService,
        private readonly vsLogsStore: VsLogsStore,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
