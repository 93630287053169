/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name DnsPolicyRulePoolSwitchingActionConfig
 * @description DnsPolicyRulePoolSwitchingActionConfig,
 *      maps to DnsRuleActionPoolSwitching in protobof.
 */
const dnsPolicyRulePoolSwitchingActionConfigFactory = (ActionConfigItem, stringService) => {
    class DnsPolicyRulePoolSwitchingActionConfig extends ActionConfigItem {
        constructor(args) {
            super(args);

            this.poolOrPoolGroup = 'pool';
        }

        handlePoolOrPoolGroupChange() {
            const config = this.getConfig();

            delete config.pool_ref;
            delete config.pool_group_ref;
        }

        /** @override */
        toString() {
            const {
                pool_ref: pool,
                pool_group_ref: poolGroup,
            } = this.getConfig();

            const poolGroupName = poolGroup ? stringService.name(poolGroup) : '';
            const poolOrPoolGroupName = pool ? stringService.name(pool) : poolGroupName;

            return `Passthrough is served by ${this.poolOrPoolGroup}: ${poolOrPoolGroupName}`;
        }
    }

    angular.extend(DnsPolicyRulePoolSwitchingActionConfig.prototype, {
        type: 'DnsRuleActionPoolSwitching',
        name: 'Pool Switching',
        defaultPath: 'dnsrule.action.pool_switching',
    });

    return DnsPolicyRulePoolSwitchingActionConfig;
};

dnsPolicyRulePoolSwitchingActionConfigFactory.$inject = [
    'ActionConfigItem',
    'stringService',
];

angular.module('aviApp').factory(
    'DnsPolicyRulePoolSwitchingActionConfig',
    dnsPolicyRulePoolSwitchingActionConfigFactory,
);
