/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Inject, Injectable } from '@angular/core';

import {
    includes,
    isEmpty,
    isObject,
    uniq,
} from 'underscore';

import {
    getUiOnlyPermissionMapping,
    IUIPermissionHash,
} from 'ng/shared/common/ui-only-permissions';

import {
    IAppState,
    IAppStatePermissionTree,
} from 'ajs/js/services/appStates.types';

type childPermissions = string | string[] | object;

const READ_ACCESS = 'READ_ACCESS';

/**
 * @ngdoc service
 * @name StatePermissionTreeService
 * @module services/StatePermissionTreeService
 * @desc Service used to generate and get statePermissionTree.
 * @author Aravindh Nagarajan
 */
@Injectable()
export class StatePermissionTreeService {
    private statePermissionTree: IAppStatePermissionTree;

    public constructor(@Inject('appStateTree') private appStateTree_: IAppState[]) {
        /**
         * Map of custom permissions, that are used in UI, that depend on
         * server-defined permissions.
         *
         * Mapping: <CUSTOM_PERMISSION>: [<LIST_OF_SERVER_PERMISSIONS>]
         */
        this.statePermissionTree = null;
    }

    /**
     * Returns statePermissionTree.
     * Generates the same if its not generated already.
     */
    public getStatePermissionTree(): IAppStatePermissionTree {
        if (!this.statePermissionTree) {
            this.generatePermissionTree();
        }

        return this.statePermissionTree;
    }

    /**
     * Generates statePermissionTree from appStates.
     */
    private generatePermissionTree(): void {
        const {
            appStateTree_: appStates,
        } = this;

        const statePermissionTree: IAppStatePermissionTree = {} as any;

        appStates.forEach((state: IAppState) => {
            const rootPermission = state.data && state.data.permission;

            const parentChildPermissionMap =
                this.getChildPermissions(state.children, rootPermission, true);

            Object.assign(statePermissionTree, parentChildPermissionMap);
        });

        this.addUIOnlyPermissions(statePermissionTree);

        this.statePermissionTree = statePermissionTree;
    }

    /**
     * Adds a permission to the list.
     * Only if the permission is not present already.
     */
    private addPermission(permissionList: Array<string | object>, permission: string): void {
        if (permission && !includes(permissionList, permission)) {
            permissionList.push(permission);
        }
    }

    /**
     * Recursively collects permissions from all child states.
     */
    private getChildPermissions(
        states: IAppState[] = [],
        parentPermission: string,
        isRoot: boolean,
    ): childPermissions {
        const permissionMap = {};
        const permissionList: Array<string | object> = [];
        const childPermissionsMap = {};

        states.forEach((state: IAppState) => {
            const currentStatePermission: string = state.data && state.data.permission;

            if (isRoot) {
                // No need to look for children of this states
                // as the resulting tree is only two level deep.
                const childPermissions =
                    this.getChildPermissions(state.children, currentStatePermission, false);

                if (Array.isArray(childPermissions)) {
                    permissionList.push(...childPermissions);
                } else if (isObject(childPermissions)) {
                    Object.assign(childPermissionsMap, childPermissions);
                } else if (childPermissions) {
                    this.addPermission(permissionList, childPermissions as string);
                }
            } else if (currentStatePermission) {
                this.addPermission(permissionList, currentStatePermission);
            }
        });

        if (isRoot && !isEmpty(childPermissionsMap)) {
            permissionList.push(childPermissionsMap);
        }

        if (parentPermission && !isEmpty(permissionList)) {
            permissionMap[parentPermission] = uniq(permissionList);

            return permissionMap;
        } else if (!isEmpty(permissionList)) {
            return uniq(permissionList);
        } else if (parentPermission) {
            return parentPermission;
        }
    }

    /**
     * UI only permissions (for Main menu, Help..) will not be present in appState.
     * This method adds them to generated statePermissionTree Object.
     */
    private addUIOnlyPermissions(statePermissionTree: IAppStatePermissionTree): void {
        // Set READ_ACCESS to uiOnlyPermissions as default.
        const otherPermissions: IUIPermissionHash = getUiOnlyPermissionMapping(READ_ACCESS);

        Object.assign(statePermissionTree, otherPermissions);
    }
}
