/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import {
    any,
    isUndefined,
} from 'underscore';
import {
    IWafRule,
    WafMode,
} from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { sha1 } from 'object-hash';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { WafExcludeListEntryConfigItem } from './waf-exclude-list-entry.config-item.factory';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TWafRuleConfigPartial = Omit<IWafRule, 'exclude_list'>;

interface IWafRuleConfig extends TWafRuleConfigPartial {
    exclude_list?: RepeatedMessageItem<WafExcludeListEntryConfigItem>;
}

export const WAF_RULE_CONFIG_ITEM_TOKEN = 'WafRuleConfigItem';

export class WafRuleConfigItem extends
    withFullModalMixin(
        withEditChildMessageItemMixin<IWafRuleConfig, typeof MessageItem>(MessageItem),
    )<IWafRuleConfig> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    private readonly ruleId: string;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafRule',
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        /**
         * 'rule_id' property is not a real id, may have duplicates, hence we have to
         * introduce another id for easier handling in UI. We can't use 'index' as id
         * because user may re-order rules and this would change ids which are supposed
         * to be immutable.
         */
        this.ruleId = sha1(Math.random());

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns true if this rule is enabled.
     */
    public isEnabled(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable flag of this rule.
     */
    public setEnabledState(enabled = false): void {
        this.config.enable = enabled;
    }

    /**
     * Returns the name of this rule. Name is either configured as a field or parsed from
     * the rule.
     * @param {boolean=} full - Usually in UI we want to show rule id and name next to
     *     each other.
     * @return {string}
     */
    public getRuleName(full = false): string {
        const { name = this.getRuleField('msg') } = this.config;

        if (!full) {
            return name;
        }

        return name ? `${this.getId()} | ${name}` : this.getId();
    }

    /**
     * Returns the ID of this rule. ID is either configured as a field or parsed from the
     * rule. Might have duplicates within a group, not populated by UI for the new rules.
     * @return {string}
     */
    public getId(): string {
        const { rule_id: ruleId } = this.config;

        return ruleId || this.getRuleField('id');
    }

    /**
     * Returns a unique identifier for each rule within a group. This id is randomly
     * generated by constructor mostly for [ng-repeat] use.
     * @return {string}
     * @public
     */
    public get uniqueId(): string {
        return this.ruleId;
    }

    /**
     * Adds a WafExcludeListEntryConfigItem MessageItem to config.exclude_list.
     */
    public addExcludeListEntry(exception: any): void {
        this.config.exclude_list.add(exception);
    }

    /**
     * Returns true if entries exist on config.exclude_list.
     */
    public hasExcludeListEntries(): boolean {
        return !this.config.exclude_list.isEmpty();
    }

    /**
     * Returns true if rule defines its own mode, overriding policy mode.
     * Ignores "allow override" setting of the policy.
     */
    public hasCustomMode(): boolean {
        return Boolean(this.config.mode);
    }

    /**
     * Returns true if any entry has an invalid configuration.
     */
    public hasInvalidExcludeListEntries(): boolean {
        return any(this.config.exclude_list.config, entry => !entry.isValid());
    }

    /**
     * Returns true if the rule contains the exception.
     */
    public hasMatchingException(exception: any): boolean {
        const { exclude_list: excludeList } = this.config;

        return any(excludeList.config, entry => entry.hasMatchingException(exception));
    }

    /**
     * Sets the mode.
     * @param wafMode - Mode enum to set. Undefined or not set means that the rule follows the
     *     policy's mode.
     */
    public set mode(wafMode: WafMode | undefined) {
        this.config.mode = wafMode;
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addExcludeListEntryMessageItem(modalBindings?: Record<string, any>): void {
        this.addChildMessageItem({
            field: 'exclude_list',
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.addExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editExcludeListEntryMessageItem(
        entry: WafExcludeListEntryConfigItem,
        modalBindings?: Record<string, any>,
    ): void {
        this.editChildMessageItem({
            field: 'exclude_list',
            messageItem: entry,
            modalBindings: {
                modalHeader: this.l10nService.getMessage(l10nKeys.editExceptionModalHeader),
                ...modalBindings,
            },
        });
    }

    /**
     * Removes a WafExcludeListEntryConfigItem instance.
     */
    public removeExcludeListEntry(entry: WafExcludeListEntryConfigItem): void {
        this.excludeList.removeByMessageItem(entry);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafRuleBreadcrumbTitle);
    }

    /**
     * Returns the excludeList repeatedMessageItem.
     */
    public get excludeList(): RepeatedMessageItem<WafExcludeListEntryConfigItem> {
        return this.config.exclude_list;
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafRuleModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-rule-modal/waf-rule-modal.component'
        );

        return WafRuleModalComponent as Type<Component>;
    }

    /**
     * Parses the rule string for a particular field.
     * @param {string} field - Name of the field to parse for.
     * @return {string}
     */
    private getRuleField(field: string): string {
        const { rule } = this.config;

        if (isUndefined(rule)) {
            return;
        }

        // The field of the rule is contained within a huge string, ie:
        // "....ection',    id:981261,    rev:'1'....". We have to parse this string to get
        // the specified field.
        const regExp = new RegExp(`${field}:('[^,]+?')`);
        const matches = regExp.exec(rule);

        return Array.isArray(matches) && matches[1].replace(/'/g, '') || '';
    }
}
