/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'shared';
const componentName = 'copyright';
const prefix = `${moduleName}.${componentName}`;

export const message = `${prefix}.message`;

export const ENGLISH = {
    [message]: 'VMware, Inc. 3401 Hillview Avenue Palo Alto CA 94304 USA Tel 877-486-9273' +
    ' Fax 650-427-5001 http://www.vmware.com. Copyright © 2021 VMware, Inc. All rights reserved.' +
    ' This product is protected by U.S. and international copyright and intellectual property laws.' +
    ' VMware products are covered by one or more patents listed at http://vmware.com/go/patents.' +
    ' VMware is a registered trademark or trademark of VMware, Inc.' +
    ' and its subsidiaries in the United States and other jurisdictions.' +
    ' All other marks and names mentioned herein may be trademarks of their respective companies.',
};
