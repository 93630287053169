/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Overlay -- makes the little dots on the charts (e.g. the anomaly dots that you can
 * click on) The anomaly dots end up coming from the performance charts (because they're
 * associated with individual data series) but the system events and alerts come from
 * EventsAndAlerts and are * the same for all the graphs.
 *
 * These overlays are displayed using straight jquery, rather than using any sort of angular tools,
 * we basically figure out the position using padding and the chart scales (yay d3!), and then
 * use position absolute to place them appropriately.
 *
 * The CSS for all these dots is in the performance chart less file.
 */
angular.module('aviApp').factory('Overlay', [
'$interpolate', '$rootScope', 'myAccount',
function($interpolate, $rootScope, myAccount) {
    const Overlay = {
        events: [],
        eventsContainer: [],
        anomalies: [],
        // Work around for zoom event not triggering in PerformanceChart
        onZoom: angular.noop,
        hide() {
            let { performanceControls } = myAccount.uiProperty;

            if (!performanceControls) {
                performanceControls = {
                    ConfigEvents: true,
                    SystemEvents: false,
                    Alerts: false,
                };
            }

            return performanceControls;
        },
    };

    Overlay.clearAll = function() {
        const removeClass = function(d) {
            d.removeClass('clicked');
        };

        _.each(Overlay.anomalies, removeClass);
        _.each(Overlay.events, removeClass);

        Overlay.currentlySelected = null;
    };

    function removeEl(el) {
        el.remove();
    }

    Overlay.removeAnomalies = function() {
        Overlay.unbindAnomalies();
        _.each(Overlay.anomalies, removeEl);
        Overlay.anomalies = [];
    };

    const unbind = function(array) {
        _.each(array, function(dot) {
            dot.off();
        });
    };

    Overlay.unbindAnomalies = function() {
        unbind(Overlay.anomalies);
    };

    Overlay.unbindEvents = function() {
        unbind(Overlay.events);
    };

    Overlay.unbindAll = function() {
        const all = (Overlay.events || []).concat(Overlay.anomalies || []);

        unbind(all);
    };

    Overlay.removeAll = function() {
        Overlay.unbindAll();
        _.each(Overlay.anomalies, removeEl);
        _.each(Overlay.eventsContainer, removeEl);

        Overlay.anomalies = [];
        Overlay.events = [];
        Overlay.eventsContainer = [];
    };

    const removePunctuationFromId = function(id) {
        const reg = /[\w\d]/;

        return _.reduce(id.split(''), function(str, chr) {
            return chr.match(reg) ? str + chr : str;
        }, '');
    };

    Overlay.createAnomalies = function(settings) {
        const {
            elm,
            chart,
            padding,
            config,
        } = settings;

        const positionAnomaly = function(anomaly, series) {
            const d = series.getDataPoint(anomaly.timestamp);

            if (!d) {
                return;
            }

            const anomalyElem = $(
                '<div class="graph-shape shape anomaly clickable graph-overlay sel-anamoly">' +
                '<i class = "icon-flash"></i></div>',
            );

            const seriesId = series.getSeriesId();

            anomalyElem
                .attr('id', `anomaly${seriesId}${d.timestamp}`)
                .on('click', () => {
                    config.selectedDot.type = 'anomalies';
                    config.selectedDot.dot = d;
                    config.selectedDot.series_name = seriesId;
                    Overlay.clearAll();

                    Overlay.currentlySelected = d.id;

                    anomalyElem.addClass('clicked');
                    $rootScope.$apply();
                });

            Overlay.anomalies.push(anomalyElem);
            elm.append(anomalyElem);

            anomalyElem
                .css('left', chart.x(d.timestamp) + padding.left - 7.5)
                .css('top', chart.y(d.value + (d.y0 || 0)) + padding.top - 15);
        };

        if (!this.hide().Anomalies) {
            const card = config.getCard();
            let allSeries;

            if (card.errorSeries) {
                allSeries = card.series.concat(card.errorSeries);
            } else {
                allSeries = card.series;
            }

            const { metric } = card;

            const positionAllAnomalies = function() {
                Overlay.clearAll();
                Overlay.removeAnomalies();
                allSeries.forEach(seriesId => {
                    if (settings.display.hiddenSeries.indexOf(seriesId) === -1) {
                        const series = metric.getSeries(seriesId);

                        if (series && series.anomalies) {
                            series.anomalies.forEach(anomaly =>
                                positionAnomaly(anomaly, series));
                        }
                    }
                });
            };

            chart.onZoomEnd(function() {
                positionAllAnomalies();
                Overlay.onZoom();
            });

            positionAllAnomalies();
        }

        if (Overlay.currentlySelected) {
            $(`#${Overlay.currentlySelected}`).addClass('clicked');
        }
    };

    Overlay.createEvents = function(settings) {
        const {
            elm,
            item,
            chart,
            padding,
            config,
        } = settings;

        this.unbindEvents();

        function makeContainer(start, end) {
            const
                startInt = +moment.utc(start),
                endInt = +moment.utc(end),
                id = startInt;

            let domElem = $(`#${id}`);

            if (domElem.length === 0) {
                const container = `<div class="dotWrapper" id="${id
                }"><div class="alignHeight"></div><div class="dotContainer"></div></div>`;

                domElem = $(container).appendTo(elm);

                const endPoint = chart.x(endInt);

                domElem.css('left', endPoint + padding.left - 7.5);
                domElem.css('top', chart.y(0) + padding.top - 45);
                Overlay.eventsContainer.push(domElem);
            }

            return domElem;
        }

        const makeDots = function(oEvents, sType) {
            const dotHtml = '<div class = "graph-shape shape clickable ' +
                'sel-{{classType}} {{classType}}" id =' +
                ' "{{id}}"><i class = "{{ iconType }}"></i></div>';

            const
                dotTemplate = $interpolate(dotHtml),
                dotType = sType.replace('Events', '');

            const icons = {
                config: 'icon-cog',
                system: 'icon-system',
                alerts: 'icon-bell',
            };

            _.each(oEvents, function(d) {
                d.iconType = icons[dotType];
                d.classType = dotType;
                d.id = removePunctuationFromId(dotType + d.timestamp);

                const dot = $(dotTemplate(d));

                dot.on('click', function() {
                    config.selectedDot.type = sType;
                    config.selectedDot.dot = d;
                    config.selectedDot.series_name = undefined;
                    Overlay.clearAll();

                    Overlay.currentlySelected = d.id;

                    dot.addClass('clicked');
                    $rootScope.$apply();
                });

                const container = makeContainer(d.start, d.end);

                container.find('.dotContainer').append(dot);
                Overlay.events.push(dot);
            });
        };

        if (!this.hide().ConfigEvents && item.data.configEvents && item.data.configEvents.series) {
            makeDots(item.data.configEvents.series, 'configEvents');
        }

        if (!this.hide().SystemEvents && item.data.systemEvents && item.data.systemEvents.series) {
            makeDots(item.data.systemEvents.series, 'systemEvents');
        }

        if (!this.hide().Alerts && item.data.alerts && item.data.alerts.series) {
            makeDots(item.data.alerts.series, 'alerts');
        }

        if (Overlay.currentlySelected) {
            $(`#${Overlay.currentlySelected}`).addClass('clicked');
        }
    };

    return Overlay;
}]);
