/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './pool-health-monitors-selector.component.less';
import * as l10n from './pool-health-monitors-selector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const componentName = 'pool-health-monitors-selector';

/**
 * @constructor
 * @memberOf module:avi/pool
 * @mixes module:avi/pool.poolHealthMonitorsSelectorBindings
 * @see
 *  {@link module:avi/pool.poolHealthMonitorsSelectorComponent poolHealthMonitorsSelectorComponent}
 */
class PoolHealthMonitorsSelectorController {
    constructor(
        HealthMonitorCollection,
        schemaService,
        l10nService,
    ) {
        const healthmonitorTypesEnum = schemaService.getEnumValues('HealthMonitorType');

        const applicableMonitorTypes = _.pluck(healthmonitorTypesEnum, 'value').join();

        this.healthMonitorCollection = new HealthMonitorCollection({
            params: {
                type: applicableMonitorTypes,
                exclude: undefined,
                fields: 'name,tenant_ref',
            },
        });

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    $onInit() {
        this.updateSelectedMonitorFilter();
    }

    /**
     * Update healthMonitorCollection to filter out selected monitors.
     */
    updateSelectedMonitorFilter() {
        const { monitors: monitorList } = this;
        let params;

        const selectedHealthMonitorIds = monitorList.reduce((selectedMonitors, monitor) => {
            if (monitor) {
                selectedMonitors.push(monitor.slug());
            }

            return selectedMonitors;
        }, []);

        if (selectedHealthMonitorIds.length) {
            params = {
                'uuid.in': selectedHealthMonitorIds.join(),
                exclude: 'uuid.in',
            };
        } else {
            params = {
                'uuid.in': undefined,
                exclude: undefined,
            };
        }

        this.healthMonitorCollection.setParams(params);
    }

    /**
     * Removes a row from health monitor list.
     * @param {number} index Index of the row to be deleted.
     */
    removeHealthMonitor(index) {
        const { monitors } = this;

        monitors.splice(index, 1);
    }

    /**
     * Adds a row to health monitor list.
     */
    addHealthMonitor() {
        const { monitors } = this;

        monitors.push('');
    }

    /** @override */
    $onDestroy() {
        this.healthMonitorCollection.destroy();
    }
}

PoolHealthMonitorsSelectorController.$inject = [
    'HealthMonitorCollection',
    'schemaService',
    'l10nService',
];

/**
 * @name poolHealthMonitorsSelectorComponent
 * @memberOf module:avi/pool
 * @description
 *      Repeated Healthmonitor collection-dropdown component
 *      Used in Pool create/edit modal.
 * @property {module:avi/pool.PoolHealthMonitorsSelectorController} controller
 * @property {module:avi/pool.poolHealthMonitorsSelectorBindings} bindings
 * @author Aravindh Nagarajan
 */
angular.module('avi/pool').component('poolHealthMonitorsSelector', {
    /**
     * @mixin poolHealthMonitorsSelectorBindings
     * @memberof module:avi/pool
     * @property {string[]} monitors Array of healthmonitor refs.
     */
    bindings: {
        monitors: '<',
    },
    controller: PoolHealthMonitorsSelectorController,
    templateUrl:
        `src/components/applications/pool/${componentName}/${componentName}.component.html`,
});
