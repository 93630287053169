/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * @description
 *
 *     Collection which is supposed to have at most one item. Used to load it or create a new
 *     one using default configuration.
 *
 * @author Alex Malitsky, Ram Pal
 */
export class GSLBCollection extends Collection {
    constructor(args = {}) {
        super(args);

        this.itemClass_ = this.getAjsDependency_('GSLB');

        this.serverDefaultsOverride_ = {
            name: 'Default',
            dns_configs: [{ domain_name: '' }],
        };
    }
}

Object.assign(GSLBCollection.prototype, {
    objectName_: 'gslb',
    defaultViewportSize_: 1,
});

GSLBCollection.ajsDependencies = [
    'GSLB',
];
