/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing cinematic components
 * @preferred
 * @module CinematicModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'ng/shared/shared.module';

import {
    AviCinematicsPortalComponent,
    CinematicComponent,
    CinematicHeaderComponent,
    CinematicHeaderTabsComponent,
    CinematicTabSectionComponent,
} from './components';

import {
    CinematicService,
} from './services';

const cinematicComponents = [
    AviCinematicsPortalComponent,
    CinematicComponent,
    CinematicHeaderComponent,
    CinematicHeaderTabsComponent,
    CinematicTabSectionComponent,
];

const cinematicServices = [
    CinematicService,
];

@NgModule({
    declarations: [
        ...cinematicComponents,
    ],
    exports: [
        AviCinematicsPortalComponent,
        CinematicComponent,
        CinematicHeaderComponent,
        CinematicHeaderTabsComponent,
        CinematicTabSectionComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
    ],
    providers: [
        ...cinematicServices,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class CinematicModule {}
