/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
} from '@angular/core';
import './avi-dropdown-single-selected-value.component.less';

/**
 * Component for a selected value in the AviDropdownComponent.
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-single-selected-value',
    templateUrl: './avi-dropdown-single-selected-value.component.html',
})
export class AviDropdownSingleSelectedValueComponent {}
