/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import {
    GeoDb,
    GeoDbFileConfigItem,
    GeoDbMappingConfigItem,
} from 'ajs/modules/geo-db';
import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import * as l10n from './geo-db-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Geo DB create/edit modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'geo-db-modal',
    templateUrl: './geo-db-modal.component.html',
})
export class GeoDbModalComponent {
    /**
     * GeoDB ObjectTypeItem.
     */
    @Input()
    public editable: GeoDb;

    /**
     * Layout for Geo Db modal clrForm.
     */
    public verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    public constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Opens modal to add new Geo Db File.
     */
    public onAddGeoDbFile(): void {
        this.editable.addGeoDbFile();
    }

    /**
     * Handles Geo Db File remove operation.
     */
    public onRemoveGeoDbFile(geoDbFileItem: GeoDbFileConfigItem): void {
        const { files } = this.editable;

        files.removeByMessageItem(geoDbFileItem);
    }

    /**
     * Handles Geo Db File edit operation.
     */
    public onEditGeoDbFile(geoDbFileItem: GeoDbFileConfigItem): void {
        this.editable.editGeoDbFile(geoDbFileItem);
    }

    /**
     * Opens modal to add new Geo Db Mapping.
     */
    public onAddGeoDbMapping(): void {
        this.editable.addGeoDbMapping();
    }

    /**
     * Handles Geo Db Mapping remove operation.
     */
    public onRemoveGeoDbMapping(geoDbMappingItem: GeoDbMappingConfigItem): void {
        this.editable.removeGeoDbMapping(geoDbMappingItem);
    }

    /**
     * Handles Geo Db Mapping edit operation.
     */
    public onEditGeoDbMapping(geoDbMappingItem: GeoDbMappingConfigItem): void {
        this.editable.editGeoDbMapping(geoDbMappingItem);
    }
}
