/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'bot-detection-policy-logs-expander';
const prefix = `${moduleName}.${componentName}`;

export const gridRowExpandedConfidenceLabel = `${prefix}.gridRowExpandedConfidenceLabel`;
export const gridRowExpandedNotesLabel = `${prefix}.gridRowExpandedNotesLabel`;

export const ENGLISH = {
    [gridRowExpandedConfidenceLabel]: 'Confidence',
    [gridRowExpandedNotesLabel]: 'Notes',
};
