/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-sub-section-paa';
const prefix = `${moduleName}.${componentName}`;

export const paaLogLabel = `${prefix}.paaLogLabel`;
export const clientRequestBodySentLabel = `${prefix}.clientRequestBodySentLabel`;
export const cacheHitLabel = `${prefix}.cacheHitLabel`;
export const paaHeadersLabel = `${prefix}.paaHeadersLabel`;

export const ENGLISH = {
    [paaLogLabel]: 'PAA Log',
    [clientRequestBodySentLabel]: 'Client Request Body Sent',
    [cacheHitLabel]: 'Cache Hit',
    [paaHeadersLabel]: 'PAA Headers',
};
