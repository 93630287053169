/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-security-ddos-full-list';
const prefix = `${moduleName}.${componentName}`;

export const noAttacksToDisplayMessage = `${prefix}.noAttacksToDisplayMessage`;
export const doneButtonLabel = `${prefix}.doneButtonLabel`;

export const ENGLISH = {
    [noAttacksToDisplayMessage]: 'No attacks to display.',
    [doneButtonLabel]: 'Done',
};
