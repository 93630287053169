/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { ControlScriptsModalComponent } from 'ng/modules/scripts';
import { AviPermissionResource } from 'generated-types';

export type TControlScriptCollection = typeof ControlScriptCollection;
export const CONTROL_SCRIPT_COLLECTION_TOKEN = 'ControlScriptCollection';

/**
 * @description
 *
 *   Control Script collection
 *
 * @author Rachit Aggarwal
 */
export class ControlScriptCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'alertscriptconfig',
            objectType: 'Alertscriptconfig',
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            windowElement: ControlScriptsModalComponent,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('ControlScript');
    }
}

ControlScriptCollection.ajsDependencies = [
    'ControlScript',
];
