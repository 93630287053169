/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'role-access-expander';
const prefix = `${moduleName}.${componentName}`;

export const assortedLabel = `${prefix}.assortedLabel`;
export const writeLabel = `${prefix}.writeLabel`;
export const readLabel = `${prefix}.readLabel`;
export const noAccessLabel = `${prefix}.noAccessLabel`;

export const ENGLISH = {
    [assortedLabel]: 'Assorted',
    [writeLabel]: 'Write',
    [readLabel]: 'Read',
    [noAccessLabel]: 'No Access',
};
