/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ordered-grid-controls';
const prefix = `${moduleName}.${componentName}`;

export const createTitle = `${prefix}.createTitle`;
export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;

export const ENGLISH = {
    [createTitle]: 'Create',
    [searchInputPlaceholder]: 'Search',
};
