/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const rangeParserProvider = {
    deps: [$injector],
    provide: 'RangeParser',
    useFactory(injector: Injector): any {
        return injector.get('RangeParser');
    },
};

export const filterProvider = {
    deps: [$injector],
    provide: '$filter',
    useFactory(injector: Injector): any {
        return injector.get('$filter');
    },
};

export const asyncFactoryProvider = {
    deps: [$injector],
    provide: 'AsyncFactory',
    useFactory(injector: Injector): any {
        return injector.get('AsyncFactory');
    },
};
