/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth, SchemaService } from 'ajs/modules/core/services';

/**
 * @description
 *
 *     HTTP Interceptor to set following headers in HttpRequest.
 *        - X-Avi-UserAgent
 *        - X-Avi-Version
 *        - X-Avi-Tenant
 *
 * @author Aravindh Nagrajan
 */
@Injectable()
export class SetRequestHeadersInterceptor implements HttpInterceptor {
    /**
     * ALB App version.
     */
    public readonly appVersion: string;

    /**
     * UserAgent value for requests originated from UI.
     */
    public readonly userAgent = 'UI';

    constructor(
        private readonly authService: Auth,
        schemaService: SchemaService,
    ) {
        this.appVersion = schemaService.getAppVersion();
    }

    /** @override */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = new HttpHeaders({
            'X-Avi-UserAgent': this.userAgent,
            'X-Avi-Version': this.appVersion,
        });

        if (this.authService.isLoggedIn()) {
            headers = headers.set('X-Avi-Tenant', this.authService.getTenantName());
        }

        const clonedRequest = req.clone({ headers });

        return next.handle(clonedRequest);
    }
}
