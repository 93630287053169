/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { AuthAttributeMatchConfigItem }
    from 'ajs/modules/match/factories/auth-attribute-match.config-item.factory';
import * as l10n from './auth-attribute-match.l10n';
import './auth-attribute-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Match component for AuthAttributeMatch.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'auth-attribute-match',
    templateUrl: './auth-attribute-match.component.html',
})
export class AuthAttributeMatchComponent implements OnInit {
    /**
     * AuthAttributeMatchConfigItem instance.
     */
    @Input()
    public editable: AuthAttributeMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex: number;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type used in template.
     */
    public matchObjectType: string;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.matchObjectType = this.editable.messageType;
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
