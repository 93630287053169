/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
    IRowReorder,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { IRemoteAuthConfiguration } from 'generated-types';
import { AuthProfileCollection }
    from 'ajs/modules/security/factories/auth-profile/auth-profile.collection.factory';
import { AuthMappingProfileCollection }
    from 'ajs/modules/auth-settings/factories/auth-mapping-profile.collection.factory';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './remote-auth-configurations-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAuthProfileCollection = typeof AuthProfileCollection;
type TAuthMappingProfileCollection = typeof AuthMappingProfileCollection;

/**
 * @desc Grid component to configure RemoteAuthConfigurations.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'remote-auth-configurations-grid',
    templateUrl: './remote-auth-configurations-grid.component.html',
})
export class RemoteAuthConfigurationsGridComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Binding for AdminAuthConfiguration#RemoteAuthConfiguration;
     */
    @Input()
    public remoteAuthConfig: IRemoteAuthConfiguration[];

    /**
     * Fires on drag-drop.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<IRowReorder>();

    /**
     * TemplateRef for authProfile collection dropdown.
     */
    @ViewChild('authProfileTemplateRef')
    public authProfileTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for authmappingprofile collection dropdown.
     */
    @ViewChild('authMappingProfileTemplateRef')
    public authMappingProfileTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Fires on add remote auth config.
     */
    @Output()
    public onAddRemoteAuthConfig = new EventEmitter<void>();

    /**
     * Fires on delete remote auth config.
     */
    @Output()
    public onDeleteRemoteAuthConfig = new EventEmitter<IRemoteAuthConfiguration>();

    /**
     * RemoteAuthConfigG Grid config.
     */
    public remoteAuthConfigGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Prefix for network dropdown id & name.
     */
    public readonly authProfileRowPrefix = 'auth_profile_';

    /**
     * Prefix for network dropdown id & name.
     */
    public readonly authMappingProfileRowPrefix = 'auth_mapping_profile_';

    /**
     * AuthProfileCollection instance.
     */
    public readonly authProfileCollection: AuthProfileCollection;

    /**
     * AuthMappingProfileCollection instance.
     */
    public readonly authMappingProfileCollection: AuthMappingProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(AuthProfileCollection)
        AuthProfileCollection: TAuthProfileCollection,
        @Inject(AuthMappingProfileCollection)
        AuthMappingProfileCollection: TAuthMappingProfileCollection,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.authProfileCollection = new AuthProfileCollection();

        this.authMappingProfileCollection = new AuthMappingProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        if (this.remoteAuthConfig) {
            this.updateSelectedAuthProfileFilter();
        }
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.remoteAuthConfigGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleAuthProfile),
                id: 'auth-profile',
                templateRef: this.authProfileTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleMappingProfile),
                id: 'mapping-profile',
                templateRef: this.authMappingProfileTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (remoteAuthConfigs: IRemoteAuthConfiguration[]) => {
                    remoteAuthConfigs.forEach((remoteAuthConfig: IRemoteAuthConfiguration) => {
                        this.removeRemoteAuthConfig(remoteAuthConfig);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (remoteAuthConfig: IRemoteAuthConfiguration) => {
                    this.removeRemoteAuthConfig(remoteAuthConfig);
                },
            }],
            layout: {
                withReordering: true,
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.authMappingProfileCollection.destroy();
        this.authProfileCollection.destroy();
    }

    /**
     * Emits add remoteAuthConfig event.
     */
    public addRemoteAuthConfig(): void {
        this.onAddRemoteAuthConfig.emit();
    }

    /**
     * Emits delete remoteAuthConfig event.
     */
    public removeRemoteAuthConfig(remoteAuthConfig: IRemoteAuthConfiguration): void {
        this.onDeleteRemoteAuthConfig.emit(remoteAuthConfig);

        this.updateSelectedAuthProfileFilter();
    }

    /**
     * Emits row reorder event.
     */
    public handleRowReorder(reorderInfo: IRowReorder): void {
        this.onRowOrderChange.emit(reorderInfo);
    }

    /**
     * Update authProfileCollection to filter out already selected authProfiles.
     */
    public updateSelectedAuthProfileFilter(): void {
        const { remoteAuthConfig } = this;

        const authProfileIdList = remoteAuthConfig
            .filter((remoteAuthConfig: IRemoteAuthConfiguration) => {
                return remoteAuthConfig.auth_profile_ref;
            })
            .map((remoteAuthConfig: IRemoteAuthConfiguration) => {
                return this.stringService.slug(remoteAuthConfig.auth_profile_ref);
            })
            .join();

        this.authProfileCollection.setParams({
            'uuid.in': authProfileIdList,
            exclude: 'uuid.in',
        });
    }
}
