/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './modal-container-breadcrumbs.less';

/**
 * @ngdoc component
 * @name  modalContainerBreadcrumbs
 * @description Component for displaying breadcrumbs in a modal container.
 * @module common/modalContainerBreadcrumbs
 * @param {string[]} breadcrumbs - Array containing strings to display as breadcrumbs.
 * @author alextsg
 */
angular.module('avi/component-kit').component('modalContainerBreadcrumbs', {
    bindings: {
        breadcrumbs: '<',
    },
    templateUrl: 'src/components/modals/modal-container/modal-container-breadcrumbs/' +
        'modal-container-breadcrumbs.component.html',
});
