/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const
    gridSelector = '.c-grid',
    gridRowSelector = `${gridSelector}__table-body-row`,
    draggedRowClassName = `${gridRowSelector.slice(1)}--dragged`;

//TODO instead of jQuery way and bunch of selectors we should require not yet existing row
// or grid controller and ask em to set mouse move and mouse up events (once)

class GridDragAndDropHandleController {
    constructor($elem, $document) {
        this.$elem = $elem;
        this.$document = $document;
        this.$body = $($document.get(0).body);

        /**
         * Set to true on mouse click and to false on button release.
         * @type {boolean}
         */
        this.inProgress = false;

        /**
         * On first mouse click we set certain event handlers on $body and set this flag to
         * true to remove then on destroy event.
         * @type {boolean}
         */
        this.handlersSet = false;

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onDocumentMouseUp = this.onDocumentMouseUp.bind(this);
        this.onDocumentMouseMove = _.throttle(this.onDocumentMouseMove.bind(this), 39);
    }

    $onInit() {
        const { $elem } = this;

        $elem.on('mousedown', this.onMouseDown);

        /**
         * Grid element this component belongs to.
         * @type {jQuery}
         */
        this.$grid = $elem.closest(gridSelector);

        /**
         * Grid row this component belongs to.
         * @type {jQuery}
         */
        this.$row = $elem.closest(gridRowSelector);
    }

    $onDestroy() {
        this.inProgress = false;

        if (this.handlersSet) {
            this.$body.css('cursor', '');

            this.$document
                .off('mousemove', this.onDocumentMouseMove)
                .off('mouseup', this.onDocumentMouseUp);
        }
    }

    /**
     * Sets active dragging flag and document events handlers (for the first call only),
     * changes cursor and sets extra class on the row.
     * @param {Object} event
     */
    onMouseDown(event) {
        event.preventDefault();

        this.inProgress = true;

        const {
            $body,
            $document,
            $row,
        } = this;

        $row.addClass(draggedRowClassName);

        $body.css('cursor', 'ns-resize');

        if (!this.handlersSet) {
            this.handlersSet = true;

            $document
                .on('mousemove', this.onDocumentMouseMove)
                .on('mouseup', this.onDocumentMouseUp);
        }
    }

    /**
     * Figures out what row is hovered and if not the one we belong to gonna swap that one
     * with our row by calling onRowMove event handler.
     * @param {Object} event
     */
    onDocumentMouseMove(event) {
        if (!this.inProgress) {
            return;
        }

        const
            { $row, $grid } = this,
            $rows = $grid.find(gridRowSelector),
            $hoveredRow = $rows.has(event.target);

        if ($hoveredRow.length && !$hoveredRow.is($row)) {
            const
                originalRowIndex = $rows.index($row),
                hoveredRowIndex = $rows.index($hoveredRow);

            this.onRowMove({
                from: originalRowIndex,
                to: hoveredRowIndex,
            });
        }
    }

    /**
     * Resets cursor, stops progress and removes draggable row class.
     **/
    onDocumentMouseUp() {
        if (!this.inProgress) {
            return;
        }

        this.inProgress = false;

        const {
            $row,
            $body,
        } = this;

        $row.removeClass(draggedRowClassName);
        $body.css('cursor', '');
    }
}

GridDragAndDropHandleController.$inject = [
    '$element',
    '$document',
];

/**
 * @ngdoc component
 * @name gridDragAndDropHandle
 * @param {Function} onRowMove - Event handler for row release action. To be called with
 *     "from" and "to" arguments.
 * @desc
 *
 *     Notifies grid of desire to flip rows on dragging. Works of $document mouse events.
 *     Used as a single row action.
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid').component('gridDragAndDropHandle', {
    bindings: {
        onRowMove: '&',
    },
    controller: GridDragAndDropHandleController,
    template: '<a><i class="icon-ellipsis-vert"></i></a>',
});
