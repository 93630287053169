/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'virtual-service';
const componentName = 'virtual-host-grid';
const prefix = `${moduleName}.${componentName}`;

export const hostsLabel = `${prefix}.hostsLabel`;
export const virtualHostingMatchLabel = `${prefix}.virtualHostingMatchLabel`;

export const ENGLISH = {
    [hostsLabel]: 'Hosts ({0})',
    [virtualHostingMatchLabel]: 'Virtual Hosting Match',
};
