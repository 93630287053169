/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { TrafficCloneProfile } from 'object-types';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    AviPermissionResource,
    ICloud,
    ITrafficCloneProfile,
} from 'generated-types';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import * as l10n from '../traffic-clone-profile.l10n';
import { CloneServerConfigItem } from './clone-server-config.item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const CLONE_SERVERS = 'clone_servers';

/**
 * @description
 *
 *   Traffic Clone Profile Item
 *
 * @author Harmeet Kaur
 */

type TTrafficCloneProfilePartial = Omit<ITrafficCloneProfile, 'clone_servers'>;

interface IExtendedTrafficCloneProfile extends TTrafficCloneProfilePartial {
    clone_servers?: RepeatedMessageItem<CloneServerConfigItem>
    cloud_ref_data?: ICloud;
}

export class TrafficCloneProfileItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
        'Cloud',
    ];

    public getConfig: () => IExtendedTrafficCloneProfile;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'trafficcloneprofile',
            objectType: TrafficCloneProfile,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_TRAFFICCLONEPROFILE,
            params: {
                include_name: true,
                join: 'cloud_ref',
            },
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            TrafficCloneProfileModalComponent,
        } = await import(
            /* webpackChunkName: "traffic-clone-profile-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/traffic-clone-profile-modal/traffic-clone-profile-modal.component'
        );

        return TrafficCloneProfileModalComponent as Type<Component>;
    }

    /** @override */
    public dataToSave(): ITrafficCloneProfile {
        const config = super.dataToSave();

        delete config.cloud_ref_data;

        return config;
    }

    /**
     * Getter for config.clone_servers
     */
    public get cloneServers(): RepeatedMessageItem<CloneServerConfigItem> {
        const { clone_servers: cloneServers } = this.getConfig();

        return cloneServers;
    }

    /**
     * Returns the count of clone servers present in config.
     */
    public get cloneServersCount(): number {
        return this.cloneServers.count;
    }

    /**
     * Adds a CloneServer object to config.clone_servers.
     */
    public addCloneServer(): void {
        this.addChildMessageItem({
            field: CLONE_SERVERS,
            modalBindings: {
                cloudRef: this.getCloudRef(),
                showNetworkDropdown: this.allowNetworkConfiguration(),
            },
        });
    }

    /**
     * Method to edit Clone Server item.
     */
    public editCloneServer(cloneServer: CloneServerConfigItem): void {
        this.editChildMessageItem({
            field: CLONE_SERVERS,
            messageItem: cloneServer,
            modalBindings: {
                cloudRef: this.getCloudRef(),
                showNetworkDropdown: this.allowNetworkConfiguration(),
            },
        });
    }

    /**
     * Returns cloud ref this traffic clone profile is set up with.
     */
    public getCloudRef(): string {
        return this.getConfig().cloud_ref || '';
    }

    /**
     * Sets cloud ref to the string passed
     */
    public setCloudRef(cloudRef: string, cloudConfig: ICloud): void {
        const config = this.getConfig();

        config.cloud_ref = cloudRef;
        config.cloud_ref_data = cloudConfig;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.trafficCloneProfileModalBreadcrumbTitle);
    }

    /**
     * Returns true if the network should be shown as a dropdown, in cases of write access
     * clouds like vCenter, OpenStack, and AWS. Otherwise, returns false to show an input
     * for the subnet.
     */
    private allowNetworkConfiguration(): boolean {
        const { cloud_ref_data: cloudConfig } = this.getConfig();

        const Cloud = this.getAjsDependency_('Cloud');

        return Cloud.hasSubnetList(cloudConfig.vtype);
    }
}
