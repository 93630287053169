/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    VrfContextPageComponent,
} from './components';

const vrfContextComponents = [
    VrfContextPageComponent,
];

/**
 * @description
 *
 *      Angular Module for VRF-Context related components.
 *
 * @author Aravindh Nagarajan, Alex Klyuev
 */
@NgModule({
    declarations: [
        ...vrfContextComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        CommonModule,
        DataGridModule,
        SharedModule,
        DataGridModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class VrfContextModule {}
