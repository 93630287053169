/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name AnomalyCollection
 * @description
 *
 *     Anomaly Collection is different from regular Collections in a sense that its objectName
 *     depends on Item it belongs to and a fact that collection metrics API returns only one
 *     dataField 'anomaly_metric' which is not a real metric name. Each Anomaly Item of this
 *     Collection pulls only one arbitrary metric which it was caused by
 *     {@link Anomaly#data#metricId}.
 *
 */
angular.module('aviApp').factory('AnomalyCollection', [
'$q', 'Collection', 'Anomaly', 'backendDateStringFormat',
function($q, Collection, Anomaly, backendDateStringFormat) {
    class AnomalyCollection extends Collection {
        constructor(args) {
            ['itemId', 'itemObjectName']
                .forEach(requiredPropertyName => {
                    if (!(requiredPropertyName in args)) {
                        throw Error(
                            'AnomalyCollection.constructor failed: ' +
                            `missing "${requiredPropertyName}"`,
                        );
                    }
                });

            super(args);

            this.itemObjectName = args.itemObjectName;
            this.itemId = args.itemId;
            this.itemPoolId = args.itemPoolId;

            this.objectName_ = [
                this.objectName_,
                this.itemObjectName,
                this.itemPoolId || this.itemId,
            ].join('/');

            if (this.itemPoolId) {
                this.setParams({ server: this.itemId });
            }

            /**
             * Metric name to become the first item in a list of anomalies. `Main` anomaly,
             * used by the collGrid when the user clicks an anomaly icon on any metrics chart
             * layer.
             * @type {string}
             */
            this.highlightedMetric = '';
        }

        /**
         * Loads collection within specified timeframe.
         * @param tStart {moment}
         * @param tStop {moment}
         * @return {ng.$q.promise}
         * @public
         */
        loadRange(tStart, tStop) {
            if (!tStart || !tStop) {
                const errMsg = 'AnomalyCollection.loadRange failed: begin or end is not passed';

                console.error(errMsg);

                return $q.reject(errMsg);
            } else {
                this.setParams({
                    start: moment.utc(tStart).format(backendDateStringFormat),
                    stop: moment.utc(tStop).format(backendDateStringFormat),
                });

                return this.load(undefined, true);
            }
        }

        /** @override */
        reset() {
            this.highlightedMetric = '';
            super.reset();
        }
    }

    const { prototype } = AnomalyCollection;

    prototype.objectName_ = 'analytics/anomaly';
    prototype.itemClass_ = Anomaly;
    prototype.defaultDataSources_ = 'list';
    prototype.allDataSources_ = {
        list: {
            source: 'AnomalyListCollDataSource',
            transformer: 'AnomalyListDataTransformer',
            transport: 'ListDataTransport',
            fields: ['config'],
        },
        'collection-metrics': {
            source: 'CollMetricsCollDataSource',
            transformer: 'CollMetricsDataTransformer',
            transport: 'CollMetricsDataTransport',
            fields: ['anomaly_metric'],
            dependsOn: 'config',
        },
    };

    return AnomalyCollection;
}]);
