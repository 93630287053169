/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import './app-about-modal.component.less';
import * as l10n from './app-about-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *   Modal Component for displaying current application version info.
 *   [version, build, build date, patch etc]
 *
 * @author Rajawant Prajapati, Akul Aggarwal
 */
@Component({
    selector: 'app-about-modal',
    templateUrl: './app-about-modal.component.html',
})
export class AppAboutModalComponent {
    /**
     * Called on modal close.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for Close button click.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }
}
