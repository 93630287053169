/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing Service Engine components.
 * @preferred
 * @module ServiceEngineModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { CommonModule } from '@angular/common';

import {
    ClrIconModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { DataGridModule } from '../data-grid/data-grid.module';

import {
    BgpPeersPageComponent,
    BgpPeersStateService,
    ServiceEngineTrafficCaptureStore,
    TrafficCaptureModalComponent,
    TrafficCaptureModalService,
    TrafficCapturePageComponent,
    TrafficCapturePageComponentCard,
    TrafficCapturePreviewComponent,
} from '.';

import {
    asyncFactoryProvider,
    filterProvider,
    rangeParserProvider,
} from './ajs-upgraded-providers';

const trafficCaptureComponents = [
    TrafficCaptureModalComponent,
    TrafficCapturePageComponent,
    TrafficCapturePageComponentCard,
    TrafficCapturePreviewComponent,
];

/**
 * @description
 *
 *      Angular Module for Service Engine related components.
 *
 * @author Suraj, Rajawant Prajapati
 */
@NgModule({
    declarations: [
        ...trafficCaptureComponents,
        BgpPeersPageComponent,
    ],
    exports: [
        TrafficCapturePageComponent,
    ],
    imports: [
        AviFormsModule,
        BrowserAnimationsModule,
        DataGridModule,
        SharedModule,
        FormsModule,
        ClrIconModule,
        CommonModule,
        ClrSpinnerModule,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
    providers: [
        asyncFactoryProvider,
        BgpPeersStateService,
        filterProvider,
        rangeParserProvider,
        ServiceEngineTrafficCaptureStore,
        TrafficCaptureModalService,
    ],
})
export class ServiceEngineModule {}
