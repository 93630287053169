/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './host-hdr-match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @alias module:match/hostHdrMatch
 * @private
 */
class HostHdrMatchComponent {
    constructor(
        dropDownUtils,
        Regex,
        l10nService,
    ) {
        this.criteria = dropDownUtils.getEnumDropdownOptions('HdrMatchOperation');
        this.Regex = Regex;

        /**
         * Get keys from source bundles for template usage
         */
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }
}

HostHdrMatchComponent.$inject = [
    'dropDownUtils',
    'Regex',
    'l10nService',
];

/**
 * @ngdoc component
 * @name  hostHdrMatch
 * @module match/hostHdrMatch
 * @description Component for configuring a HostHdrMatch Config Item.
 * @param {HostHdrMatchConfigItem} hostHdrMatch
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('hostHdrMatch', {
    bindings: {
        hostHdrMatch: '<',
        preventEdit: '<',
    },
    controller: HostHdrMatchComponent,
    templateUrl: 'src/components/forms/match-config/matches/' +
        'host-hdr-match/host-hdr-match.component.html',
});
