/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module TooltipModule
 */

import { Component } from '@angular/core';

import './avi-hover-overlay.component.less';

/**
 * @description
 *
 *     Container component for displaying hover overlay pop-up contents. Positions used by this
 *     component are more corner side-aligned rather than corner point-connected.
 *     This component works with avi-tooltip directive as its #avi-tooltip-content template ref.
 *
 * @example
 *
 * example.component.ts:
 *     import {
 *         hoverOverlayPositionMap,
 *         HOVER_OVERLAY_BOTTOM_LEFT_ALIGNED_POSITION,
 *         HOVER_OVERLAY_BOTTOM_RIGHT_ALIGNED_POSITION,
 *         HoverOverlayPosition,
 *     } from 'ng/modules/tooltip/components/avi-hover-overlay/avi-hover-overlay.constants';
 *
 *     // overlay position priorities.
 *     public hoverOverlayPositionPriorities: ConnectedPosition[] = [
 *         HOVER_OVERLAY_BOTTOM_LEFT_ALIGNED_POSITION,
 *         HOVER_OVERLAY_BOTTOM_RIGHT_ALIGNED_POSITION,
 *     ];
 *
 *
 * example.component.html:
 *     <cds-button
 *         avi-tooltip
 *         [positionsPriority]="hoverOverlayPositionPriorities"
 *     >
 *         test button
 *         <ng-template #aviTooltipContent>
 *             <avi-hover-overlay>
 *                 <div>
 *                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 *                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 *                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
 *                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
 *                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
 *                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
 *                      culpa qui officia deserunt mollit anim id est laborum.
 *                  </div>
 *             </avi-hover-overlay>
 *         </ng-template>
 *     </cds-button>
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-hover-overlay',
    templateUrl: './avi-hover-overlay.component.html',
})
export class AviHoverOverlayComponent {}
