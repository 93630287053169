/** @module SharedModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import {
    Auth,
    StringService,
} from 'ajs/modules/core/services';

import { L10nService } from '@vmw/ngx-vip';
import { IItemGenericConfig } from 'ajs/modules/data-model/factories/item.factory';
import { IGenericMessageItemConfig } from 'ajs/modules/data-model/factories/message-item.factory';
import { AviDatePipe } from 'ng/modules/core/pipes/avi-date.pipe';

import * as l10n from './item-preview-defaults.l10n';
import './item-preview-defaults.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/** Pair of field and val to display. */
interface IItemPreviewDefaultProp {
    label: string;
    val: string;
}

/**
 * Default preview showing tenantRef and lastModified fields for all item modals.
 * Used in item-preview-wrapper.component.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'item-preview-defaults',
    templateUrl: './item-preview-defaults.component.html',
})
export class ItemPreviewDefaultsComponent implements OnInit {
    @Input()
    public readonly config: IItemGenericConfig | IGenericMessageItemConfig;

    /**
     * Flag that tells if editable is an Item or a Message item.
     */
    @Input()
    public readonly isItem: boolean;

    /**
     * List to populate with default label:val pairs.
     */
    public previewFields: IItemPreviewDefaultProp[];

    constructor(
        private readonly aviDatePipe: AviDatePipe,
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        private readonly authService: Auth,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isItem) {
            this.setPreviewFields();
        }
    }

    /**
     * ngFor trackBy fn for displaying passed in props/vals
     */
    public trackByVal(index: number, { label }: IItemPreviewDefaultProp): string {
        return label;
    }

    /**
     * Converts date to human readable standard format.
     */
    private transformLastModified(): string {
        const { _last_modified: lastModified } = this.config;

        return this.aviDatePipe.transform(lastModified.slice(0, -3));
    }

    /**
     * Extracts tenant from tenantRef if available in config else fallback to authservice.
     */
    private transformTenantRef(): string {
        const tenantRef = this.config.tenant_ref || this.authService.getCurrentTenantRef();

        return this.stringService.name(tenantRef);
    }

    /**
     * Sets fields to pass into generic component.
     */
    private setPreviewFields(): void {
        this.previewFields = [
            {
                val: this.transformTenantRef(),
                label: this.l10nService.getMessage(l10nKeys.tenantLabel),
            },
        ];

        // Show Last Modified field if value exists
        const { _last_modified: lastModified } = this.config;

        if (lastModified) {
            this.previewFields.push({
                val: this.transformLastModified(),
                label: this.l10nService.getMessage(l10nKeys.lastModifiedLabel),
            });
        }
    }
}
