/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Sarthak Kapoor, vgohil
 */

/**
 * Ajs dependency token for SEGroup Collection.
 */
export const SE_GROUP_COLLECTION_TOKEN = 'SEGroupCollection';

/**
 * Ajs dependency token for SEGroup Item with UpgradeStatusMixin.
 */
export const SE_GROUP_ITEM_TOKEN = 'SEGroup';

/**
 * Ajs dependency token for PlacementScopeConfig Config Item.
 */
export const PLACEMENT_SCOPE_CONFIG_CONFIG_ITEM_TOKEN = 'PlacementScopeConfigConfigItem';

/**
 * Ajs dependency token for VipAutoscaleGroup Config Item.
 */
export const VIP_AUTOSCALE_GROUP_CONFIG_ITEM_TOKEN = 'VipAutoscaleGroupConfigItem';

/**
 * Ajs dependency token for VipAutoscaleConfiguration Config Item.
 */
export const VIP_AUTOSCALE_CONFIGURATION_CONFIG_ITEM_TOKEN = 'VipAutoscaleConfigurationConfigItem';

/**
 * Ajs dependency token for VcenterClusters Config Item.
 */
export const VCENTER_CLUSTERS_CONFIG_ITEM_TOKEN = 'VcenterClustersConfigItem';

/**
 * Ajs dependency token for VcenterHosts Config Item.
 */
export const VCENTER_HOSTS_CONFIG_ITEM_TOKEN = 'VcenterHostsConfigItem';
