/** @module GeoDBModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { Component, Type } from '@angular/core';
import { GeoDbModalComponent } from 'ng/modules/security';
import { GEO_DB_ITEM_TOKEN } from './geo-db.item.factory';

/**
 * @description Collection for Geo DB
 *
 * @author Sarthak Kapoor
 */

export class GeoDbCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'geodb',
            objectType: GEO_DB_ITEM_TOKEN,
            permissionName: AviPermissionResource.PERMISSION_GEODB,
            windowElement: GeoDbModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(GEO_DB_ITEM_TOKEN);
    }
}

GeoDbCollection.ajsDependencies = [
    GEO_DB_ITEM_TOKEN,
];

export type TGeoDbCollection = typeof GeoDbCollection;
export const GEO_DB_COLLECTION_TOKEN = 'GeoDbCollection';
