/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';

import { IAppState } from 'ng/root-store/root.state';
import { IControllerSiteState } from './controller-site.state';

export const controllerSiteStateFeatureKey = 'controllerSiteState';

const selectControllerSiteState =
    createFeatureSelector<IAppState, IControllerSiteState>(controllerSiteStateFeatureKey);

const selectName = createSelector(
    selectControllerSiteState,
    (controllerSiteState: IControllerSiteState) => controllerSiteState.name,
);

const selectTenantRef = createSelector(
    selectControllerSiteState,
    (controllerSiteState: IControllerSiteState) => controllerSiteState.tenant_ref,
);
const selectUrl = createSelector(
    selectControllerSiteState,
    (controllerSiteState: IControllerSiteState) => controllerSiteState.url,
);
const selectUuid = createSelector(
    selectControllerSiteState,
    (controllerSiteState: IControllerSiteState) => controllerSiteState.uuid,
);

export const ControllerSiteSelectors = {
    selectName,
    selectTenantRef,
    selectUrl,
    selectUuid,
};
