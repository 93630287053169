/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *     Parser for checkboxes that should have a custom values for checked and unchecked states.
 * @example
 *     <clr-checkbox-container>
 *         <clr-checkbox-wrapper>
 *             <input
 *                 type="checkbox"
 *                 clrCheckbox
 *                 name="match_case"
 *                 [(ngModel)]="editable.config.match_case"
 *                 id="match_case"
 *                 trueFalseValue
 *                 trueValue="SENSITIVE"
 *                 falseValue="INSENSITIVE"
 *             />
 *             <label
 *                 aviLabelWithTooltip
 *                 objectType="PathMatch"
 *                 fieldName="match_case"
 *                 for="match_case"
 *             >
 *                 Enable Match Case
 *             </label>
 *         </clr-checkbox-wrapper>
 *      </clr-checkbox-container>
 * @author alextsg
 */

import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    Renderer2,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Directive({
    selector: 'input[type=checkbox][trueFalseValue]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TrueFalseValueDirective),
            multi: true,
        },
    ],
})
export class TrueFalseValueDirective implements ControlValueAccessor {
    /**
     * Value to be set when the checkbox is selected.
     */
    @Input()
    public trueValue: any = true;

    /**
     * Value to be set when the checkbox is unselected.
     */
    @Input()
    public falseValue: any = false;

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    @HostListener('input', ['$event'])
    private onInputChange(event: InputEvent): void {
        // It seems there's no event type for checkbox selection.
        const { checked } = event.target as any;

        this.onChange(checked ? this.trueValue : this.falseValue);
    }

    /**
     * Sets the checked status based on the model value.
     */
    public writeValue(value: any): void {
        const status = value === this.trueValue;

        this.renderer.setProperty(this.elementRef.nativeElement, 'checked', status);
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange = (value: any): void => {};

    /**
     *  Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch: () => void = () => {};
}
