/**
 * @module LogsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { duration } from 'moment';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './log-timeframes.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface ILogTimeframeValue {
    index: number;
    label: string;
    range: number | string;
    step: number;
    limit: number;
    interval: number;
    hiddenInLayout?: boolean;
}

enum LogTimeframeKeys {
    '15m' = '15m',
    '1h' = '1h',
    '3h' = '3h',
    '6h' = '6h',
    '1d' = '1d',
    '1w' = '1w',
    '1m' = '1m',
    'custom' = 'custom',
}

type TLogTimeframes = {
    [key in LogTimeframeKeys]: ILogTimeframeValue;
};

/**
 * Returns list of log timeframe values.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export function logTimeframesFactory(l10nService: L10nService): TLogTimeframes {
    l10nService.registerSourceBundles(dictionary);

    return {
        '15m': {
            index: 0,
            label: l10nKeys.past15minutesOptionLabel,
            range: duration(15, 'm').asSeconds(),
            step: 5,
            limit: 360,
            interval: 5,
        },
        '1h': {
            index: 1,
            label: l10nKeys.pastHourOptionLabel,
            range: duration(1, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
            limit: 12,
            interval: 30,
        },
        '3h': {
            index: 2,
            label: l10nKeys.past3hoursOptionLabel,
            range: duration(3, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
            limit: 36,
            interval: 30,
        },
        '6h': {
            index: 3,
            label: l10nKeys.past6hoursOptionLabel,
            range: duration(6, 'h').asSeconds(),
            step: duration(5, 'm').asSeconds(),
            limit: 72,
            interval: 30,
        },
        '1d': {
            index: 4,
            label: l10nKeys.pastDayOptionLabel,
            range: duration(1, 'd').asSeconds(),
            step: duration(5, 'm').asSeconds(),
            limit: 288,
            interval: 30,
        },
        '1w': {
            index: 5,
            label: l10nKeys.pastWeekOptionLabel,
            range: duration(1, 'w').asSeconds(),
            step: duration(1, 'h').asSeconds(),
            limit: 168,
            interval: 30,
        },
        '1m': {
            index: 6,
            label: l10nKeys.pastMonthOptionLabel,
            range: duration(1, 'M').asSeconds(),
            step: duration(1, 'days').asSeconds(),
            limit: 30,
            interval: 30,
        },
        custom: {
            index: 10,
            label: l10nKeys.customOptionLabel,
            range: 'custom',
            hiddenInLayout: true,
            step: 300,
            limit: 72,
            interval: 30,
        },
    };
}

logTimeframesFactory.$inject = [
    'l10nService',
];
