/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HttpBackend,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AlbInterceptorHandler } from './alb-interceptor-handler';

/**
 * @description
 *      InterceptingHandler - Transforms an `HttpRequest` into a stream of `HttpEvent`s,
 *      one of which will likely be a `HttpResponse`.
 *
 *      Its responsibility is to send requests to the first interceptor in the chain,
 *      which can pass it to the second interceptor, and so on, eventually
 *      reaching HttpBackend.
 *
 * @author Aravindh Nagarajan
 *
 */
export class AlbInterceptingHandler implements HttpHandler {
    /**
     * AlbInterceptorHandler with chain of interceptors
     * (HttpXsrfInterceptor, EncodeQueryParamsInterceptor, ...).
     */
    private chain: AlbInterceptorHandler;

    constructor(
        private readonly backend: HttpBackend,
        private readonly interceptors: HttpInterceptor[],
    ) {
        this.buildChain();
    }

    /** @override */
    public handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.chain.handle(req);
    }

    /**
     * Builds interceptors chain.
     */
    private buildChain(): void {
        this.chain = this.interceptors.reduceRight(
            (next: AlbInterceptorHandler, interceptor: HttpInterceptor) =>
                new AlbInterceptorHandler(next, interceptor),
            this.backend as unknown as AlbInterceptorHandler,
        );
    }
}
