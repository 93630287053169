/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'health-monitor';

export const healthMonitorBreadcrumbTitle = `${prefix}.healthMonitorBreadcrumbTitle`;

export const ENGLISH = {
    [healthMonitorBreadcrumbTitle]: 'Health Monitor',
};
