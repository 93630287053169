/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Observable, Subject } from 'rxjs';

/**
 * Service for adding and removing items that allows for subscriptions. Can be extended, for example
 * by the NotificationService, to add and remove a specific set of notification props.
 * @typeParam T - Type of the item to be added to the list.
 * @typeParam U - Type of the unique ID.
 * @author alextsg
 */
export abstract class ObservableList<T, U> {
    /**
     * List of all items.
     */
    private readonly items: T[] = [];

    /**
     * Subject to allow for subscribing to changes in the list.
     */
    private readonly subject = new Subject<T[]>();

    /**
     * Observable that returns the list of all items.
     */
    public get items$(): Observable<T[]> {
        return this.subject.asObservable();
    }

    /**
     * Adds an item to the list of items. If the ID already exists, throws an error.
     */
    public add(item: T): void {
        const id = this.getID(item);

        if (this.has(id)) {
            throw new Error(`item with ID ${id} already exists`);
        }

        this.items.push(item);
        this.subject.next(this.items);
    }

    /**
     * Removes an item by ID. Looks through the list for the item that matches, and removes it from
     * the list.
     */
    public remove(id: U): void {
        const index = this.getIndex(id);

        if (index > -1) {
            this.items.splice(index, 1);
            this.subject.next(this.items);
        } else {
            throw new Error(`Item with ID ${id} not found`);
        }
    }

    /**
     * Removes all items.
     */
    public removeAll = (): void => {
        this.items.length = 0;
        this.subject.next(this.items);
    };

    /**
     * Returns true if the item with a specified ID exist.
     */
    public has(id: U): boolean {
        return this.getIndex(id) > -1;
    }

    /**
     * Returns true if the number of items is 0.
     */
    public isEmpty(): boolean {
        return !this.items.length;
    }

    /**
     * Returns the index of the item with the specified ID.
     */
    private getIndex(id: U): number {
        return this.items.findIndex((item: T) => this.getID(item) === id);
    }

    /**
     * Returns a unique identifier for the item.
     */
    protected abstract getID(item: T): U;
}
