/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { PkiProfileModalComponent } from 'ng/modules/security';
import { PKI_PROFILE_ITEM_TOKEN } from './pki-profile.item.factory';

/**
 * Ajs dependency token for PKIProfile collection.
 */
export const PKI_PROFILE_COLLECTION_TOKEN = 'PKIProfileCollection';

/**
 * @description PKIProfile collection class.
 *
 * @author Satish Pednekar
 */
export class PKIProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'pkiprofile',
            windowElement: PkiProfileModalComponent,
            permissionName: AviPermissionResource.PERMISSION_PKIPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PKI_PROFILE_ITEM_TOKEN);
    }
}

PKIProfileCollection.ajsDependencies = [
    PKI_PROFILE_ITEM_TOKEN,
];
