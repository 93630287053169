/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-actions';
const prefix = `${moduleName}.${componentName}`;

export const refreshLabel = `${prefix}.refreshLabel`;
export const exportLabel = `${prefix}.exportLabel`;
export const currentPageLabel = `${prefix}.currentPageLabel`;
export const allLogsLabel = `${prefix}.allLogsLabel`;
export const selectedLogsLabel = `${prefix}.selectedLogsLabel`;

export const ENGLISH = {
    [refreshLabel]: 'Refresh',
    [exportLabel]: 'Export',
    [currentPageLabel]: 'Current Page',
    [allLogsLabel]: 'All {0} Logs',
    [selectedLogsLabel]: '{0} Selected Logs',
};
