/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-azure-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const credentialSectionHeader = `${prefix}.credentialSectionHeader`;
export const valueNotSetPlaceholder = `${prefix}.valueNotSetPlaceholder`;
export const subscriptionIdInputLabel = `${prefix}.subscriptionIdInputLabel`;
export const tenantNameInputLabel = `${prefix}.tenantNameInputLabel`;
export const applicationIdInputLabel = `${prefix}.applicationIdInputLabel`;
export const authenticationTokenInputLabel = `${prefix}.authenticationTokenInputLabel`;
export const tenantIdInputLabel = `${prefix}.tenantIdInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const azureSectionHeader = `${prefix}.azureSectionHeader`;

export const ENGLISH = {
    [credentialSectionHeader]: 'Credentials',
    [valueNotSetPlaceholder]: 'Not Set',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [subscriptionIdInputLabel]: 'Subscription ID',
    [tenantNameInputLabel]: 'Tenant Name',
    [applicationIdInputLabel]: 'Application ID',
    [authenticationTokenInputLabel]: 'Authentication Key',
    [tenantIdInputLabel]: 'Tenant ID',
    [azureSectionHeader]: 'Azure',
};
