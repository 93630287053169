/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name poolServerHSScatterSeries
 * @description
 *
 *     Aggregated series for poolServer HS scatter plot. Keeps an aggregated series value (such
 *     as throughput), health score value and operation status of the corresponding pool Server.
 *
 *     Used by {@link PoolServersHsScatterMetric}.
 *
 */
angular.module('aviApp').factory('poolServerHSScatterSeries', [
'AggSeries',
function(AggSeries) {
    /**
     * @class
     * @extends AggSeries
     */
    return class PoolServerHSScatterSeries extends AggSeries {
        constructor(args) {
            super(args);

            this.healthScore = NaN;
            this.operStatus = '';
        }

        /**
         * Saves healthscore API response into the Series. To be used by Metric only.
         * @param {Object} runtimeData
         */
        storeRuntimeInfo(runtimeData) {
            const { healthScore, operStatus } = runtimeData;

            this.healthScore = healthScore;
            this.operStatus = operStatus;
        }

        /**
         * @returns {number}
         * @public
         */
        getHealthScoreValue() {
            return this.healthScore;
        }

        /**
         *
         * @returns {string}
         * @public
         */
        getOperStatus() {
            return this.operStatus;
        }
    };
}]);
