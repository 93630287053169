/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').factory('MetricDataListDataTransformer', [
'ListDataTransformer',
ListDataTransformer => {
    return class MetricDataListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(rsp) {
            const
                { data } = rsp,
                results = [];

            if (data && 'series' in data && !_.isEmpty(data.series)) {
                const entityUuidHash = data.series.reduce((acc, series) => {
                    const
                        { name, entity_uuid: entityUuid, entity_ref: entityRef } = series.header,
                        itemId = entityUuid || entityRef;

                    acc[itemId] = acc[itemId] || {};
                    acc[itemId][name] = series;

                    return acc;
                }, {});

                results.push(
                    ..._.map(entityUuidHash, (metricIdHash, id) => {
                        return {
                            id,
                            name: id,
                            metrics: metricIdHash,
                        };
                    }),
                );
            }

            data.results = results;
            data.count = results.length;

            return rsp;
        }
    };
}]);
