/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { isUndefined } from 'underscore';
import { StringService } from 'ajs/modules/core/services/string-service';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { DropdownModelValue } from '../../avi-dropdown';
import './avi-collection-dropdown-selected-values-container.component.less';

/**
 * @desc Component for displaying either a single selected value or multiple selected values
 *      in the AviCollectionDropdownComponent.
 *
 * @author alextsg
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-collection-dropdown-selected-values-container',
    templateUrl: './avi-collection-dropdown-selected-values-container.component.html',
})
export class AviCollectionDropdownSelectedValuesContainerComponent {
    /**
     * Selected value.
     */
    @Input()
    public value: DropdownModelValue;

    /**
     * True if the value is an array.
     */
    @Input()
    public multiple = false;

    /**
     * Holds value of prepend for dropdown.
     */
    @Input()
    public prepend?: string;

    /**
     * Map of selected ref and its item instance.
     */
    @Input()
    public selectedItemsMap: Map<DropdownModelValue, Item>;

    /**
     * Called to remove a value.
     */
    @Output()
    public onRemoveValue = new EventEmitter<DropdownModelValue>();

    constructor(private readonly stringService: StringService) {}

    /**
     * Returns name of the selected option from its Item instance.
     * If name is not present, falls back on the slug of the
     * ref, and if that's not available, returns the ref itself.
     */
    public getName(ref?: DropdownModelValue): string {
        if (isUndefined(ref)) {
            return '';
        }

        let selectedOptionLabel = '';

        if (this.selectedItemsMap && this.selectedItemsMap.has(ref)) {
            const item = this.selectedItemsMap.get(ref);

            selectedOptionLabel = item.getName();
        }

        // If item is undefined or getName returns falsy value
        const fallback = this.stringService.name(ref as string) ||
            this.stringService.slug(ref as string) || ref as string;

        return selectedOptionLabel || fallback;
    }

    /**
     * Click handler to remove a value.
     */
    public handleRemove($event: MouseEvent, ref: DropdownModelValue): void {
        this.onRemoveValue.emit(ref);
        $event.stopPropagation();
    }

    /**
     * Gettter for checking presence of dropdown prepend.
     */
    public get hasPrepend(): boolean {
        return Boolean(this.prepend);
    }

    /**
     * Trackby function for multiple values.
     */
    public trackByValue(index: number, value: DropdownModelValue): DropdownModelValue {
        return value;
    }
}
