/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-ssl';
const prefix = `${moduleName}.${componentName}`;

export const sslLabel = `${prefix}.sslLabel`;
export const sslVersionLabel = `${prefix}.sslVersionLabel`;
export const sniHostnameLabel = `${prefix}.sniHostnameLabel`;
export const perfectForwardSecracyLabel = `${prefix}.perfectForwardSecracyLabel`;
export const oscpHandlingLabel = `${prefix}.oscpHandlingLabel`;
export const fullTlsFingerprintLabel = `${prefix}.fullTlsFingerprintLabel`;
export const filteredTlsFingerprintLabel = `${prefix}.filteredTlsFingerprintLabel`;

export const ENGLISH = {
    [sslLabel]: 'SSL',
    [sslVersionLabel]: 'Version',
    [sniHostnameLabel]: 'SNI Hostname',
    [perfectForwardSecracyLabel]: 'Perfect Forward Secracy',
    [oscpHandlingLabel]: 'OSCP Handling',
    [fullTlsFingerprintLabel]: 'Full TLS Fingerprint',
    [filteredTlsFingerprintLabel]: 'Filtered TLS Fingerprint',
};
