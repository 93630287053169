/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    Output,
} from '@angular/core';

import { AuthRuleAssignment } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { AuthMappingRuleConfigItem }
    from 'ajs/modules/auth-settings/factories/auth-mapping-rule.config-item.factory';
import { UserProfileCollection }
    from 'ajs/modules/accounts/factories/user-profile/user-profile.collection.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './user-account-profile-mapping.l10n';
import './user-account-profile-mapping.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ENGLISH, ...globalL10nKeys } = globalL10n;

type TUserProfileCollection = typeof UserProfileCollection;

/**
 * @description Component to configure UserAccountProfile Mapping.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'user-account-profile-mapping',
    templateUrl: './user-account-profile-mapping.component.html',
})
export class UserAccountProfileMappingComponent implements OnDestroy {
    /**
     * AuthMappingRule messageItem instance.
     */
    @Input()
    public editable: AuthMappingRuleConfigItem;

    /**
     * ObjectType used in template.
     */
    @Input()
    public readonly objectType: string;

    /**
     * Fieldname used in template.
     */
    @Input()
    public readonly fieldName: string;

    /**
     * Emits event when remove button is clicked.
     */
    @Output()
    public onRemoveMapping = new EventEmitter();

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * UserProfileCollection collection instance.
     */
    public readonly userProfileCollection: UserProfileCollection;

    /**
     * Following Assignment values are not allowed for userAccountProfile match.
     */
    public readonly hiddenAuthRuleAssignmentValues = [
        AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_REGEX,
        AuthRuleAssignment.ASSIGN_ALL,
        AuthRuleAssignment.ASSIGN_MATCHING_GROUP_REGEX,
    ];

    constructor(
        l10nService: L10nService,
        @Inject(UserProfileCollection)
        UserProfileCollection: TUserProfileCollection,
    ) {
        this.userProfileCollection = new UserProfileCollection();

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.userProfileCollection.destroy();
    }

    /**
     * If true, UserProfile collection dropdown will be displayed.
     */
    public get showUserProfileList(): boolean {
        const { assign_userprofile: userProfileAssignment } = this.editable.config;

        return userProfileAssignment === AuthRuleAssignment.ASSIGN_FROM_SELECT_LIST;
    }

    /**
     * If true, UserProfile attributeName input will be displayed.
     */
    public get showUserProfileAttributeName(): boolean {
        const { assign_userprofile: userProfileAssignment } = this.editable.config;

        return userProfileAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_VALUE ||
            userProfileAssignment === AuthRuleAssignment.ASSIGN_MATCHING_ATTRIBUTE_REGEX;
    }

    /**
     * Fires on AssignmentType change.
     */
    public onAssignmentTypeChange(): void {
        this.editable.resetUserProfileMapping();
    }

    /**
     * Emits Remove mapping event.
     */
    public removeMapping(): void {
        this.onRemoveMapping.emit();
    }
}
