/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RateProfile } from 'object-types';
import { IRateProfile } from 'generated-types';

import {
    RateLimiterConfigItem,
} from 'ajs/modules/scripts/factories/data-script-set/rate-limiter.config-item.factory';

import { RateLimiterActionConfigItem } from './rate-limiter-action.config-item.factory';

type TRateProfilePartial = Omit<IRateProfile, 'action' | 'rate_limiter'>;

export interface IRateProfileConfig extends TRateProfilePartial{
    action?: RateLimiterActionConfigItem;
    rate_limiter?: RateLimiterConfigItem;
}

/**
 * @description Rate Profile ConfigItem.
 * @author Nisar Nadaf
 */
export class RateProfileConfigItem extends MessageItem<IRateProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: RateProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'rate_limiter',
        ];
    }
}
