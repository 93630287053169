/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/percent-pile-chart.less';

/**
 * Created by amalitsky on 12/30/14.
 * Representation of percent value as a barchart.
 *
 * By default it expects value to lay between 0 and 1.
 * If not, use max and min. For example max='100' will make directive to count percent by
 * dividing value by 100. Min makes sense only when max is set. In this case it will count
 * percent by dividing value by (max-min).
 */

angular.module('aviApp').directive('percentPileChart', function() {
    const link = function(scope) {
        let min = 0,
            max = 0;

        scope.bars = new Array(10);

        if (scope.max && !_.isNaN(+scope.max)) {
            max = +scope.max;

            if (scope.min && !_.isNaN(+scope.min) && +scope.min < max) {
                min = +scope.min;
            }
        }

        scope.$watch('value', function(value) {
            if (max) {
                scope.percent = +value / (max - min);
            } else {
                scope.percent = +value;
            }

            if (_.isNaN(+scope.percent)) {
                scope.percent = 0;
            }
        });
    };

    return {
        restrict: 'A',
        scope: {
            value: '=percentPileChart',
            max: '@',
            min: '@',
            ngDisabled: '=',
        },
        link,
        template: '<div><div class="bar"' +
                    'ng-repeat="undef in bars track by $index" ' +
                    'ng-class="{\'full\':percent >= (10 - $index)/10}"></div></div>',
    };
});

//wrapper for pool's server page
angular.module('aviApp').directive('serverPercentPileChart', [
'chartValueController',
function(chartValueController) {
    return {
        restrict: 'E',
        controller: ['$scope', chartValueController],
        scope: {
            series: '<',
            label: '@',
            ngDisabled: '=',
        },
        template: '<div percent-pile-chart="value" max="100" ng-hide="ngDisabled"></div>' +
                  '<div percent-pile-chart="0" max="100" ng-show="ngDisabled"></div>' +
                  '<div class="legend">' +
                    '<span class="name">{{ label }}</span><br>' +
                    '<span class="val" ng-show="ngDisabled">N/A</span>' +
                    '<span class="val" ng-hide="ngDisabled">{{value |number:2}}{{ unit }}' +
                    '</span>' +
                  '</div>',
    };
}]);
