/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Ajs dependency token for Backup Configuration Item.
 */
export const BACKUP_CONFIGURATION_ITEM_TOKEN = 'BackupConfiguration';

/**
 * Ajs dependency token for Backup Configuration Collection.
 */
export const BACKUP_CONFIGURATION_COLLECTION_TOKEN = 'BackupConfigurationCollection';

/**
 * Ajs dependency token for Backup Item.
 */
export const BACKUP_ITEM_TOKEN = 'BackupItem';

/**
 * Ajs dependency token for Backup Collection.
 */
export const BACKUP_COLLECTION_TOKEN = 'BackupCollection';

/**
 * Ajs dependency token for Email Configuration Config Item.
 */
export const EMAIL_CONFIGURATION_CONFIG_ITEM_TOKEN = 'EmailConfigurationConfigItem';

/**
 * Ajs dependency token for File Object Collection.
 */
export const FILE_OBJECT_COLLECTION_TOKEN = 'FileObjectCollection';

/**
 * Ajs dependency token for Scheduler Item.
 */
export const SCHEDULER_ITEM_TOKEN = 'Scheduler';

/**
 * Ajs dependency token for System Configuration Item.
 */
export const SYSTEM_CONFIG_ITEM_TOKEN = 'SystemConfig';
