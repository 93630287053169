/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-azure-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setAzureCredentialsHeader = `${prefix}.setAzureCredentialsHeader`;
export const subscriptionIdInputLabel = `${prefix}.subscriptionIdInputLabel`;
export const useUsernamePasswordCredentialsOption = `${prefix}.useUsernamePasswordCredentialsOption`;
export const useApplicationIdCredentialsOption = `${prefix}.useApplicationIdCredentialsOption`;
export const tenantNameInputLabel = `${prefix}.tenantNameInputLabel`;
export const applicationIdInputLabel = `${prefix}.applicationIdInputLabel`;
export const authenticationTokenInputLabel = `${prefix}.authenticationTokenInputLabel`;
export const tenantIdInputLabel = `${prefix}.tenantIdInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const tenantNameInputPlaceholder = `${prefix}.tenantNameInputPlaceholder`;
export const applicationIdInputPlaceholder = `${prefix}.applicationIdInputPlaceholder`;
export const authenticationTokenInputPlaceholder = `${prefix}.authenticationTokenInputPlaceholder`;
export const tenantIdInputPlaceholder = `${prefix}.tenantIdInputPlaceholder`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const subscriptionIdInputPlaceholder = `${prefix}.subscriptionIdInputPlaceholder`;

export const ENGLISH = {
    [setAzureCredentialsHeader]: 'Set Azure Credentials',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [subscriptionIdInputLabel]: 'Subscription ID',
    [useUsernamePasswordCredentialsOption]: 'Use Username/Password credentials',
    [useApplicationIdCredentialsOption]: 'Use Application ID credentials',
    [tenantNameInputLabel]: 'Tenant Name',
    [applicationIdInputLabel]: 'Application ID',
    [authenticationTokenInputLabel]: 'Authentication Key',
    [tenantIdInputLabel]: 'Tenant ID',
    [usernameInputPlaceholder]: 'Enter Username',
    [passwordInputPlaceholder]: 'Enter Password',
    [subscriptionIdInputPlaceholder]: 'Enter Subscription ID',
    [tenantNameInputPlaceholder]: 'Enter Tenant Name',
    [applicationIdInputPlaceholder]: 'Enter Application ID',
    [authenticationTokenInputPlaceholder]: 'Enter Authentication Key',
    [tenantIdInputPlaceholder]: 'Enter Tenant ID',
};
