/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'sso-policy';
const componentName = 'authentication-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const modalTitle = `${prefix}.modalTitle`;
export const enableRuleLabel = `${prefix}.enableRuleLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeSelectPlaceholder = `${prefix}.typeSelectPlaceholder`;
export const sectionTitleMatch = `${prefix}.sectionTitleMatch`;
export const sectionTitleAction = `${prefix}.sectionTitleAction`;
export const clientIpAddressLabel = `${prefix}.clientIpAddressLabel`;
export const hostHeaderLabel = `${prefix}.hostHeaderLabel`;
export const pathLabel = `${prefix}.pathLabel`;

export const ENGLISH = {
    [sectionTitleGeneral]: 'General',
    [modalTitle]: 'Authentication Rule',
    [enableRuleLabel]: 'Enable Rule',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [typeInputLabel]: 'Type',
    [typeSelectPlaceholder]: 'Select Type',
    [sectionTitleMatch]: 'Match',
    [sectionTitleAction]: 'Action',
    [clientIpAddressLabel]: 'Client IP Address',
    [hostHeaderLabel]: 'Host Header',
    [pathLabel]: 'Path',
};
