/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './GslbPoolPriorityField.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name gslbPoolPriorityField
 * @author Alex Malitsky
 * @param {GslbPoolConfig#priority} value
 * @restrict A
 * @description
 *
 *    Simple input wrapper to avoid code duplication.
 *
 */

angular.module('aviApp').directive('gslbPoolPriorityField', ['Regex', 'l10nService',
function(Regex, l10nService) {
    return {
        templateUrl: 'src/views/partials/application/gslb-pool-priority-field.html',
        restrict: 'A',
        controller: ['$scope', scope => {
            scope.Regex = Regex;
            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);
        }],
        scope: {
            value: '=',
        },
    };
}]);
