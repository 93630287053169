/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe, PipeTransform } from '@angular/core';

type TClientEnvType = 'os' | 'device' | 'browser';

/**
 * @description
 *     Pipe for converting a text env of client environments to a CSS class for icons of
 *     device type, browser and OS.
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'logClientEnvToIconClass',
})
export class LogClientEnvToIconClassPipe implements PipeTransform {
    /** @override */
    // eslint-disable-next-line class-methods-use-this
    public transform(env: string, type: TClientEnvType): string {
        const nameStartsWith = (name: string, token: string): boolean => name.indexOf(token) === 0;

        if (!env) {
            return '';
        }

        switch (type) {
            case 'os':
                if (nameStartsWith(env, 'Mac') || nameStartsWith(env, 'iOS')) {
                    return 'apple';
                } else if (nameStartsWith(env, 'Windows')) {
                    return 'windows';
                } else if (nameStartsWith(env, 'Ubuntu')) {
                    return 'ubuntu';
                } else if (nameStartsWith(env, 'Android')) {
                    return 'android';
                } else {
                    return 'unknown';
                }

            case 'device':
                if (nameStartsWith(env, 'Computer')) {
                    return 'desktop';
                } else {
                    return 'tablet';
                }

            case 'browser':
                if (nameStartsWith(env, 'Chrom')) {
                    return 'chrome';
                } else if (nameStartsWith(env, 'Firefox')) {
                    return 'firefox';
                } else if (nameStartsWith(env, 'IE')) {
                    return 'ie';
                } else if (nameStartsWith(env, 'Safari') || nameStartsWith(env, 'AppleMail')) {
                    return 'apple';
                } else if (nameStartsWith(env, 'Opera')) {
                    return 'opera';
                } else if (nameStartsWith(env, 'Android')) {
                    return 'android';
                } else {
                    return 'globe';
                }

            default:
                return '';
        }
    }
}
