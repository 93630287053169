/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module TrafficCloneProfileModule
 */

const moduleName = 'traffic-clone-profile';
const componentName = 'traffic-clone-profile-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const placeholderMessage = `${prefix}.placeholderMessage`;

export const ENGLISH = {
    [headerLabel]: 'Traffic Clone Profile',
    [placeholderMessage]: 'No Items Found',
};
