/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const sectionTitleFiles = `${prefix}.sectionTitleFiles`;
export const sectionTitleMapping = `${prefix}.sectionTitleMapping`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const descriptionLabel = `${prefix}.descriptionLabel`;
export const isFederatedLabel = `${prefix}.isFederatedLabel`;
export const geoDbFilesLabel = `${prefix}.geoDbFilesLabel`;
export const geoDbMappingLabel = `${prefix}.geoDbMappingLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Geo DB',
    [sectionTitleGeneral]: 'General',
    [sectionTitleFiles]: 'Files',
    [sectionTitleMapping]: 'Mapping',
    [modalTitleNew]: 'Create Geo DB',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionLabel]: 'Description',
    [isFederatedLabel]: 'Is Federated',
    [geoDbFilesLabel]: 'GeoDB Files ({0})',
    [geoDbMappingLabel]: 'GeoDB Mapping ({0})',
};
