/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'missing-cert-issuer-icon';
const prefix = `${moduleName}.${componentName}`;

export const certificateMissingTooltipLabel = `${prefix}.certificateMissingTooltipLabel`;

export const ENGLISH = {
    [certificateMissingTooltipLabel]: 'Issuer Certificate Missing',
};
