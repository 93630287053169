/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    InfraCloudState,
    VRFContext,
} from 'ajs/modules/vrf-context';
import { Auth } from 'ajs/modules/core/services/auth/auth.service';
import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';
import {
    AviDataGridFieldVisibility,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './vrf-context-page.l10n';
import './vrf-context-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the VRF Context page.
 * @author Alex Klyuev
 */
@Component({
    selector: 'vrf-context-page',
    templateUrl: './vrf-context-page.component.html',
})
export class VrfContextPageComponent implements OnInit, AfterViewInit, OnDestroy {
    /**
     * Template Ref for BGP Peering column.
     */
    @ViewChild('bgpPeeringTemplateRef')
    public bgpPeeringTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection Data Grid config for VRF Context items.
     */
    public vrfContextGridConfig: IAviCollectionDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        private readonly infraCloudState: InfraCloudState,
        private readonly auth: Auth,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.infraCloudState.on(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }

    /** @override */
    public ngOnInit(): void {
        const vrfContextCollection = this.infraCloudState.getVrfContextCollection();
        const { objectName } = vrfContextCollection;

        this.vrfContextGridConfig = {
            id: `${objectName}-list-page`,
            collection: vrfContextCollection,
            fields: [],
            layout: {
                hideCreate: !this.areVrfsEditable(),
                hideEdit: !this.areVrfsEditable(),
                hideDelete: !this.areVrfsEditable(),
            },
            getRowId: (index, row: VRFContext): string => {
                return row.id;
            },
        };
    }

    /**
     * @override
     *
     * Template Refs must be set after the view is initialized.
     */
    public ngAfterViewInit(): void {
        this.vrfContextGridConfig.fields = [
            {
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: VRFContext): string => {
                    return row.getName();
                },
                visibility: AviDataGridFieldVisibility.MANDATORY,
            }, {
                id: 'bgp-peering',
                label: this.l10nService.getMessage(l10nKeys.bgpPeeringColumnLabel),
                templateRef: this.bgpPeeringTemplateRef,
            }, {
                id: 'static-route',
                label: this.l10nService.getMessage(l10nKeys.staticRouteColumnLabel),
                transform: (row: VRFContext): string => {
                    const { count } = row.staticRoutes;

                    if (!count) {
                        return '-';
                    }

                    return count.toString();
                },
                visibility: AviDataGridFieldVisibility.MANDATORY,
            }, {
                id: 'gateway-monitor',
                label: this.l10nService.getMessage(l10nKeys.gatewayMonitorColumnLabel),
                transform: (row: VRFContext): string => {
                    const { count } = row.gatewayMonitors;

                    if (!count) {
                        return '-';
                    }

                    return count.toString();
                },
                visibility: AviDataGridFieldVisibility.MANDATORY,
            },
        ];
    }

    /**
     * Handler for 'vrfContextCollectionChange' event. Resets the gridConfig.
     */
    public handleVrfCollectionChange = (): void => {
        const vrfContextCollection = this.infraCloudState.getVrfContextCollection();

        this.vrfContextGridConfig = {
            ...this.vrfContextGridConfig,
            collection: vrfContextCollection,
            layout: {
                hideCreate: !this.areVrfsEditable(),
                hideEdit: !this.areVrfsEditable(),
                hideDelete: !this.areVrfsEditable(),
            },
        };
    };

    /** @override */
    public ngOnDestroy(): void {
        this.infraCloudState.unbind(
            'vrfContextCollectionChange',
            this.handleVrfCollectionChange,
        );
    }

    /**
     * Return whether VRFs can be created / edited or not.
     */
    private areVrfsEditable(): boolean {
        // Whether VRF can be created / edited based on cloud type
        const allowCustomVrfContext = this.infraCloudState.allowCustomVrfContext();

        // Whether tenant has access to VRF
        const { hasTenantVrf } = this.auth;

        return allowCustomVrfContext && hasTenantVrf;
    }
}
