/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module HealthMonitorModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorExternal,
} from 'object-types';

import { IHealthMonitorExternal } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './external-health-monitor-config.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Config Component for External Health Monitor Type.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'external-health-monitor-config',
    templateUrl: './external-health-monitor-config.component.html',
})
export class ExternalHealthMonitorConfigComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorExternal,
    };

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for External Health Monitor message item.
     */
    public get externalMonitor(): MessageItem<IHealthMonitorExternal> {
        const { externalMonitor } = this.editable;

        return externalMonitor;
    }
}
