/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name radio
 * @deprecated Please use {@see radioButton} component.
 * @example
 *   <radio
 *      ng-model="variableName"
 *      ng-change="expression to be called"
 *      ng-click="expression to be called"
 *      ng-disabled="true|false">
 *   </radio>
 */
angular.module('aviApp').directive('radio', ['$compile', '$timeout',
function($compile, $timeout) {
    return {
        scope: {
            ngModel: '=',
            ngChange: '&',
            ngClick: '&',
            ngDisabled: '=',
            ngChecked: '=',
            value: '@',
            ngValue: '=',
        },
        restrict: 'E',
        template: '<i class="icon-check"></i>',
        link(scope, elm, attr) {
            elm.append($compile(
                `${'<input type="radio" ng-model="ngModel" ' +
                    'class="sel-avi-radio" ' +
                    'ng-change="change()" ' +
                    'ng-click="ngClick({$event: $event})" ' +
                    'ng-disabled="ngDisabled!=undefined && ngDisabled" '}${
                    scope.value !== undefined ? 'value="{{value}}"' : ''
                }${scope.ngValue !== undefined ? 'ng-value="ngValue"' : ''
                }>`,
            )(scope));

            scope.change = function() {
                $timeout(function() {
                    scope.ngChange();
                });
            };

            // Put the class when it's disabled
            scope.$watch('ngDisabled', function() {
                if (scope.ngDisabled) {
                    elm.addClass('disabled');
                } else {
                    elm.removeClass('disabled');
                }
            });

            // If ngModel attribute missing, then need to work with ngModel,
            // otherwise it's gonna generate errors
            scope.$watch('ngModel', function() {
                if (scope.value !== undefined && scope.value == scope.ngModel ||
                    scope.ngValue !== undefined && scope.ngValue == scope.ngModel) {
                    elm.addClass('checked');
                } else {
                    elm.removeClass('checked');
                }
            });

            // Catch space click on <raio> element in case if it was focused
            elm.on('keydown', function(event) {
                if (event.keyCode == 32) {
                    elm.find('input').trigger('click');
                }
            });
        },
    };
}]);
