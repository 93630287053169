/**
 * @module VsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IHttpResponse,
    IPromise,
} from 'angular';

import {
    Component,
    Type,
} from '@angular/core';

import { compact } from 'underscore';

import {
    AviPermissionResource,
    IIpAddr,
    IVip,
    IVipRuntimeDetail,
    IVsVip,
    OperationalState,
} from 'generated-types';

import {
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import { L10nService } from '@vmw/ngx-vip';
import { DnsInfoConfigItem } from 'ajs/modules/dns';
import { VipConfigItem } from './vip.config-item.factory';
import * as l10n from '../vs.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVsVipConfigPartial = Omit<IVsVip, 'dns_info' | 'vip'>;

interface IVsVipConfig extends TVsVipConfigPartial {
    dns_info?: RepeatedMessageItem<DnsInfoConfigItem>;
    vip?: RepeatedMessageItem<VipConfigItem>;
}

interface IVsVipData {
    config: IVsVipConfig;
}

interface INetworkSubnetListResponse {
    count: number;
}

interface INetworkSubnetListErrorResponse {
    data: string;
}

/**
 * @description
 *
 *   VsVip item
 *
 * @author Ram Pal, alextsg
 */
export class VsVip extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        '$q',
        'stringService',
        'l10nService',
    ];

    public data: IVsVipData;
    public getDefaultConfig_: () => object | null;
    public getRuntimeData: () => IVipRuntimeDetail | null;
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'vsvip',
            objectType: 'VsVip',
            permissionName: AviPermissionResource.PERMISSION_VIRTUALSERVICE,
            windowElement: 'lazy-load',
            whitelistedFields: [
               'dns_info',
               'vip',
            ],
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Import lazy-loaded modal component.
     */
    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { VsVipModalComponent } = await import(
            /* webpackChunkName: "vs-vip-modal" */
            'ng/lazy-loaded-components/modals/vs-vip-modal/vs-vip-modal.component'
        );

        return VsVipModalComponent as Type<Component>;
    }

    /**
     * Returns OperationalState for current Vip object.
     * @param index - Index of the Vip object from the vip list.
     */
    public getVipOperState(index: number): OperationalState {
        const runtimeList = this.getRuntimeData() as IVipRuntimeDetail[];

        return runtimeList[index].oper_status.state;
    }

    /**
     * Returns the Vip object from the vip list based on the index passed in. Defaults to 0.
     * @param index - Index of the Vip object from the vip list.
     */
    public getVip(index: number): VipConfigItem {
        const vipList = this.getVipList();

        return vipList.at(index);
    }

    /**
     * Returns the Vip repeated message item of the current VsVip.
     */
    public getVipList(): RepeatedMessageItem<VipConfigItem> {
        const { vip: vipList } = this.getConfig();

        return vipList;
    }

    /**
     * Sets the name of the VsVip object.
     * @param name - Name to set.
     */
    public setName(name: string): void {
        const config = this.getConfig();

        config.name = config.name || name;
    }

    /**
     * The cloud_ref for a VsVip object can be set only once.
     * @param cloudRef - Cloud ref url.
     */
    public setCloudRef(cloudRef: string): void {
        const config = this.getConfig();

        config.cloud_ref = cloudRef;
    }

    /**
     * The vrf_context_ref for a VsVip object can be set only once.
     * @param vrfContextRef - VRF Context ref url.
     */
    public setVrfContext(vrfContextRef: string): void {
        const config = this.getConfig();

        config.vrf_context_ref = vrfContextRef;
    }

    /**
     * Adds a Vip object to the VirtualService vip list.
     */
    public addVip(): void {
        const vipList = this.getVipList();
        const id = this.getNextVipId();

        vipList.add();

        const newVip = vipList.at(vipList.count - 1);

        newVip.id = id.toString();
    }

    /**
     * Removes a Vip object from the VirtualService vip list based on the vip_id.
     * @param index - vip_id of the Vip to be removed.
     */
    public removeVip(index: number): void {
        this.getVipList().remove(index);
    }

    /**
     * Returns an array of configured Vip addresses.
     */
    public getVipAddresses(): string[] {
        const vipList = this.getVipList();
        const vips: string[] = [];

        vipList.config.forEach((vip: VipConfigItem) => {
            const ipv4 = vip.getVipAddress();
            const ipv6 = vip.getIP6VipAddress();

            if (ipv4) {
                vips.push(ipv4);
            }

            if (ipv6) {
                vips.push(ipv6);
            }
        });

        return compact(vips);
    }

    /**
     * Returns a list of VIP ip address hashes.
     */
    public getVipAddressHashes(): Array<Record<string, string | IIpAddr>> {
        const vipList = this.getVipList();

        return vipList.config.map((vip: VipConfigItem) => {
            const hash: Record<string, string | IIpAddr> = vip.getVipAddressHash();

            hash.vip_id = vip.id;

            return hash;
        });
    }

    /**
     * Returns the DnsInfo object from the dns_info list based on the index passed in.
     * Defaults to 0.
     * @param index - Index of the dnsInfo object.
     */
    public getDnsInfo(index = 0): DnsInfoConfigItem {
        const { dns_info: dnsInfoList } = this.getConfig();

        return dnsInfoList.at(index);
    }

    /**
     * Adds a default DnsInfo object to the dns_info list.
     */
    public addDnsInfo(): void {
        const { dns_info: dnsInfoList } = this.getConfig();

        dnsInfoList.add();
    }

    /**
     * Removes a DnsInfo object from the dns_info list by DnsInfoConfigItem.
     */
    public removeDnsInfoByMessageItem(dnsInfo: DnsInfoConfigItem): void {
        const { dns_info: dnsInfoList } = this.getConfig();

        dnsInfoList.removeByMessageItem(dnsInfo);
    }

    /**
     * Sets the fqdn field. If no index is passed in, sets config.fqdn. Otherwise, sets
     * config.dns_info[index].fqdn.
     * @param fqdn - fqdn string.
     * @param index - Index of the dnsInfo object in the dns_info list.
     */
    public setFqdn(fqdn: string, index = 0): void {
        const dnsConfig = this.getDnsInfo(index);

        dnsConfig.setFqdn(fqdn);
    }

    /**
     * Returns an array of configured fqdns.
     */
    public getFqdns(): string[] {
        const { dns_info: dnsInfo } = this.getConfig();

        if (!dnsInfo.isEmpty()) {
            return compact(
                dnsInfo.config.map((dnsConfig: DnsInfoConfigItem) => dnsConfig.getFqdn()),
            );
        }

        return [];
    }

    /**
     * Sets the auto_allocate_floating_ip field on every Vip object. Also needs to verify
     * that the network_refs in every Vip object are fip_capable, by calling
     * this.getFipNetworksCount if auto_allocate_floating_ip is set to true.
     * If not, remove that network and subnet.
     * @param enabled - Boolean value to set auto_allocate_floating_ip to.
     */
    public setAutoAllocateFloatingIpForAllVips(enabled = false): Promise<void> {
        const $q = this.getAjsDependency_('$q');
        const vipList = this.getVipList();
        const promises: Array<IPromise<void>> = [];

        vipList.config.forEach((vip: VipConfigItem) => {
            const { networkRef } = vip;

            vip.setAutoAllocateFloatingIp(enabled);
            vip.clearFloatingIp();

            if (enabled && networkRef) {
                const promise = this.getFipNetworksCount(networkRef)
                    .then((count: number): void => {
                        if (!count) {
                            vip.clearVipNetwork();
                            vip.clearVipSubnet();
                            vip.setIpAddr(undefined);
                        }
                    });

                promises.push(promise);
            }
        });

        this.busy = true;

        return $q.all(promises)
            .finally(() => this.busy = false);
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        const config = this.getConfig();

        const { markers, bgp_peer_labels: bgpPeerLabels } = config;

        if (!bgpPeerLabels) {
            config.bgp_peer_labels = [];
        }

        if (!markers) {
            config.markers = [];
        }
    }

    /**
     * @override
     */
    public dataToSave(): IVsVip {
        const config: IVsVip = super.dataToSave();

        const {
            vip: vipList,
            markers,
        } = config;

        /**
         * Set all auto_allocate_floating_ip fields to match the first Vip object's.
         */
        if (vipList?.length) {
            const { auto_allocate_floating_ip: autoAllocateFloatingIP } = vipList[0];

            vipList.forEach((vip: IVip) => vip.auto_allocate_floating_ip = autoAllocateFloatingIP);
        }

        if (markers) {
            // filter out RBAC entries with an empty key
            config.markers = markers.filter(({ key }) => key);

            // delete empty RABC label list
            if (markers.length === 0) {
                delete config.markers;
            }
        }

        return config;
    }

    /**
     * Getter for cloud_ref.
     */
    public get cloudRef(): string {
        return this.config.cloud_ref;
    }

    /**
     * Getter for vrfContextRef.
     */
    public get vrfContextRef(): string {
        return this.config.vrf_context_ref;
    }

    /**
     * Getter for DnsInfo.
     */
    public get dnsInfoList(): RepeatedMessageItem<DnsInfoConfigItem> {
        return this.config.dns_info;
    }

    /**
     * Getter for ipam_selector labels.
     * ipam_selector is applicable only for AVI Ipam type.
     * As of now, UI configuration is not supported.
     * UI need to read the underlying structure (when present) and pass key&value pairs
     * to API request on api/networksubnetlist.
     * (used by network selection dropdowns - on vsvip settings).
     */
    public get ipamSelectorLabels(): string {
        const { ipam_selector: ipamSelector } = this.config;

        return ipamSelector?.labels ? JSON.stringify(ipamSelector.labels) : '';
    }

    /**
     * Opens VIP Create modal.
     */
    public createVip(vipCreateParams: Record<string, any> = {}): void {
        const {
            cloudRef,
            vrfContextRef,
        } = this;

        this.addChildMessageItem({
            field: 'vip',
            config: {
                vip_id: this.getNextVipId(),
            },
            modalBindings: {
                cloudRef,
                vrfContextRef,
                vipCreateParams,
            },
        });
    }

    /**
     * Opens VIP Edit modal.
     */
    public editVip(
        vipConfigItem: VipConfigItem,
        vipCreateParams: Record<string, any> = {},
    ): void {
        const { cloudRef, vrfContextRef } = this;

        this.addChildMessageItem({
            field: 'vip',
            messageItem: vipConfigItem,
            modalBindings: {
                cloudRef,
                vrfContextRef,
                vipCreateParams,
            },
        });
    }

    /**
     * Opens DnsInfo create modal.
     */
    public createDnsInfo(vsName = ''): void {
        this.addChildMessageItem({
            field: 'dns_info',
            modalBindings: {
                vsName,
                cloudRef: this.cloudRef,
            },
        });
    }

    /**
     * Opens DnsInfo edit modal.
     */
    public editDnsInfo(dnsInfoConfigItem: DnsInfoConfigItem): void {
        this.addChildMessageItem({
            field: 'dns_info',
            messageItem: dnsInfoConfigItem,
            modalBindings: {
                cloudRef: this.cloudRef,
                isEditing: true,
            },
        });
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.vsVipModalBreadcrumbTitle);
    }

    /**
     * Gets number of networks that are fip_capable.
     * @param networkRef - network_ref URL.
     */
    private getFipNetworksCount(networkRef: string): IPromise<number> {
        const { cloud_ref: cloudRef } = this.getConfig();
        const stringService = this.getAjsDependency_('stringService');
        const cloudUuid = stringService.slug(cloudRef);
        const networkUuid = stringService.slug(networkRef);

        const api = '/api/networksubnetlist/?auto_allocate_only=true&fip_capable=true&' +
            `cloud_uuid=${cloudUuid}&search=${networkUuid}`;

        return this.request<INetworkSubnetListResponse>('GET', api)
            .then(({ data }: IHttpResponse<INetworkSubnetListResponse>) => data.count)
            .catch(({ data }: INetworkSubnetListErrorResponse) => {
                this.errors = data;

                return Promise.reject(data);
            });
    }

    /**
     * Returns vip_id of new VIP.
     */
    private getNextVipId(): number {
        const vipList = this.getVipList();

        return vipList.config.reduce((acc: number, vip: VipConfigItem) => {
            return Math.max(acc, +vip.id || 0);
        }, 0) + 1;
    }
}
