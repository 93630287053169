/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-signpost';
const prefix = `${moduleName}.${componentName}`;

export const defaultErrorMsgLabel = `${prefix}.defaultErrorMsgLabel`;
export const reloadPageLabel = `${prefix}.reloadPageLabel`;
export const emptyDataMessage = `${prefix}.emptyDataMessage`;

export const ENGLISH = {
    [defaultErrorMsgLabel]: 'An error occurred while loading the signpost logs, please try again.',
    [reloadPageLabel]: 'Reload Page',
    [emptyDataMessage]: 'No Data Available',
};
