/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'modal-container';
const prefix = `${moduleName}.${componentName}`;

export const cancelBtnLabel = `${prefix}.cancelBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [cancelBtnLabel]: 'Cancel',
    [saveBtnLabel]: 'Save',
};
