/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    AzureCredentialsType,
    IAzureCredentialsConfig,
    IpamDnsAzureProfileConfigItem,
} from 'ajs/modules/ipam/factories/ipam-dns-azure-profile.config-item.factory';

import {
    AzureServicePrincipalCredentials,
    AzureUserPassCredentials,
    IpamDnsAzureProfile,
} from 'object-types';

import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { Observable } from 'rxjs';
import { IEditCredentialsConfig } from 'ng/modules/avi-forms/components/credentials-verification';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { IpamDnsAzureCredentialsDialogComponent } from './ipam-dns-azure-credentials-dialog';
import * as l10n from './ipam-dns-azure-profile-config.l10n';
import './ipam-dns-azure-profile-config.component.less';

const { ENGLISH, ...l10nKeys } = l10n;

/**
 * @description IpamDnsAzureProfile configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-azure-profile-config',
    templateUrl: './ipam-dns-azure-profile-config.component.html',
})
export class IpamDnsAzureProfileConfigComponent implements OnInit {
    /**
     * IpamDnsAzureProfile ConfigItem instance.
     */
    @Input()
    public editable: IpamDnsAzureProfileConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * True if ipam profile is being edited.
     */
    public readonly isEditing: boolean;

    /**
     * AzureCredentialsType enum values.
     * To be used in template.
     */
    public readonly AzureCredentialsTypeEnum = AzureCredentialsType;

    /**
     * Credentials config passed to the CredentialsVerification component.
     */
    public editAzureProfileCredentialsConfig: IEditCredentialsConfig;

    /**
     * True if credentials is set by the user.
     */
    public connected = false;

    /**
     * Model value for credentials type radio buttons.
     */
    public azureCredentialsType = AzureCredentialsType.USERPASS;

    /**
     * IpamDnsAzureProfile & child objectTypes.
     */
    public readonly objectTypes = {
        IpamDnsAzureProfile,
        AzureUserPassCredentials,
        AzureServicePrincipalCredentials,
    };

    constructor(
        l10nService: L10nService,
        @Inject(ITEM_ID_TOKEN)
        private readonly ipamProfileId: string,
    ) {
        l10nService.registerSourceBundles(ENGLISH);

        this.isEditing = Boolean(ipamProfileId);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.isEditing) {
            this.connected = true;

            this.setCredentialsType();
        }

        this.setEditAzureProfileCredentialsConfig();
    }

    /**
     * Creates the config object passed to the CredentialsVerification component for editing
     * credentials.
     */
    private setEditAzureProfileCredentialsConfig(): void {
        const {
            config,
            azureServicePrincipal,
            azureUserpass,
        } = this.editable;

        const editCredentialsConfig: IAzureCredentialsConfig = {
            subscription_id: config.subscription_id,
            azure_serviceprincipal: {
                application_id: azureServicePrincipal?.config.application_id,
                authentication_token: azureServicePrincipal?.config.authentication_token,
                tenant_id: azureServicePrincipal?.config.tenant_id,
            },
            azure_userpass: {
                password: azureUserpass?.config.password,
                tenant_name: azureUserpass?.config.tenant_name,
                username: azureUserpass?.config.username,
            },
            type: this.azureCredentialsType,
        };

        this.editAzureProfileCredentialsConfig = {
            editCredentialsDialog: IpamDnsAzureCredentialsDialogComponent as Type<Component>,
            editCredentialsDialogProps: {
                config: editCredentialsConfig,
                isEditing: this.isEditing,
                submit$: new Observable<void>(subscriber => {
                    this.editable.verifyAzureCredentials(editCredentialsConfig, this.ipamProfileId)
                        .then(() => {
                            subscriber.next();
                            subscriber.complete();

                            this.editable.setAzureLoginCredentials(editCredentialsConfig);
                            this.setEditAzureProfileCredentialsConfig();
                            this.connected = true;
                            this.setCredentialsType();
                        }).catch(error => subscriber.error(error));
                }),
                onCancel: () => {
                    this.editable.cancelAzureVerifyCredentials();

                    this.setEditAzureProfileCredentialsConfig();
                },
            },
        };
    }

    /**
     * Sets Azure Credentials type.
     */
    private setCredentialsType(): void {
        if (isEmpty(this.editable.azureUserpass.config)) {
            this.azureCredentialsType = AzureCredentialsType.SERVICE_PRINCIPAL;
        } else if (isEmpty(this.editable.azureServicePrincipal.config)) {
            this.azureCredentialsType = AzureCredentialsType.USERPASS;
        }
    }
}
