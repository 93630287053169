/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'pool-group-member';

export const poolGroupMembersModalBreadcrumbTitle = `${prefix}.poolGroupMembersModalBreadcrumbTitle`;

export const ENGLISH = {
    [poolGroupMembersModalBreadcrumbTitle]: 'Pool Group Member',
};
