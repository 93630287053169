/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SystemConfiguration } from 'object-types';

import * as l10n from './system-settings-dns-services-card.l10n';
import './system-settings-dns-services-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for DNS Services Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-dns-services-card',
    templateUrl: './system-settings-dns-services-card.component.html',
})
export class SystemSettingsDnsServicesCardComponent {
    /**
     * DNS Servers Configured for Controller.
     */
    @Input()
    public dnsServicesList: string[];

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type for template usage.
     */
    public readonly objectType = SystemConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Track by index method.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
