/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SecurityModule
 */

import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import type {
    Certificate,
} from 'ajs/modules/security/factories/certificates/certificate.item.factory';

import {
    SSLCertificateExpiryStatus,
    SSLCertificateStatus,
} from 'generated-types';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { IEnumValue } from 'ajs/modules/core/services/schema-service/schema.types';
import * as l10n from './ssl-certificate-grid-status-column.l10n';
import './ssl-certificate-grid-status-column.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const statusState = {
    GOOD: 'success',
    EXPIRING: 'danger',
    EXPIRED: 'danger',
    PENDING: 'warning',
};

const statusType = {
    SSL_CERTIFICATE_GOOD: statusState.GOOD,
    SSL_CERTIFICATE_EXPIRY_WARNING: statusState.EXPIRING,
    SSL_CERTIFICATE_EXPIRED: statusState.EXPIRED,
    SSL_CERTIFICATE_PENDING: statusState.PENDING,
    OCSP_UI_STATUS_OK: statusState.GOOD,
    OCSP_UI_STATUS_WARNING: statusState.EXPIRING,
    OCSP_UI_STATUS_ERROR: statusState.EXPIRED,
};

const OCSP_UI_STATUS_ERROR = 'OCSP_UI_STATUS_ERROR';

/**
 * @description Component for getting status of certificate.
 *
 * @author Manideep Kotha
 */

@Component({
    selector: 'ssl-certificate-grid-status-column',
    templateUrl: './ssl-certificate-grid-status-column.component.html',
})
export class SslCertificateGridStatusColumnComponent {
    /**
     * Status Tooltip Template.
     */
    @ViewChild('tooltip')
    public tooltip: TemplateRef<HTMLElement>;

    /**
     * Certificate Item.
     */
    @Input()
    public certificate: Certificate;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Label for the expiry status.
     */
    public expiryStatusLabel: string;

    /**
     * Label for the expiry status.
     */
    public expiryStatusClassLabel: string;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the description string for the certificate's expiry status.
     */
    public get getExpiryStatus(): string {
        const { status } = this.certificate.getConfig();

        const { description: statusDescription } = this.schemaService
            .getEnumValue('SSLCertificateStatus', status);

        switch (status) {
            case SSLCertificateStatus.SSL_CERTIFICATE_PENDING:
                return statusDescription;

            case SSLCertificateStatus.SSL_CERTIFICATE_FINISHED:
                return this.getExpiryStatusDescription();

            default:
                return null;
        }
    }

    /**
     * Returns ocsp status for a particular certificate.
     */
    private getOcspUiStatus(): IEnumValue {
        const { ocspErrorStatus } = this.certificate;
        const ocspUiStatusList = this.schemaService.getEnumValues('OCSPUiStatus');

        return ocspUiStatusList.find(({ ocsp_ui_status_options: ocspUiStatusOptions }) => {
            const { allowed_ocsp_error_status: allowedOcspErrorStatusList } = ocspUiStatusOptions;

            return allowedOcspErrorStatusList.includes(ocspErrorStatus);
        });
    }

    /**
     * Returns the class name for the certificate's expiry status.
     */
    public get getExpiryStatusClass(): string {
        const { status } = this.certificate.getConfig();
        const { enableOcspStapling, expiryStatus } = this.certificate;

        switch (status) {
            case SSLCertificateStatus.SSL_CERTIFICATE_PENDING:
                return statusType[status];

            case SSLCertificateStatus.SSL_CERTIFICATE_FINISHED:
                if (enableOcspStapling) {
                    const { value: ocspErrorStatusType } = this.getOcspUiStatus();

                    if (expiryStatus === SSLCertificateExpiryStatus.SSL_CERTIFICATE_EXPIRED) {
                        return statusType[expiryStatus];
                    }

                    if (ocspErrorStatusType === OCSP_UI_STATUS_ERROR) {
                        return statusType[ocspErrorStatusType];
                    }

                    if (
                        expiryStatus === SSLCertificateExpiryStatus.SSL_CERTIFICATE_EXPIRY_WARNING
                    ) {
                        return statusType[expiryStatus];
                    }

                    return statusType[ocspErrorStatusType];
                }

                return statusType[expiryStatus];

            default:
                return null;
        }
    }

    /**
     * Returns the description string for the certificate's expiry status.
     */
    private getExpiryStatusDescription(): string {
        const { value: ocspErrorStatusType } = this.getOcspUiStatus();

        const {
            enableOcspStapling,
            expiryStatus,
            ocspErrorStatus,
        } = this.certificate;

        const { description: sslExpiryDescription } = this.schemaService
            .getEnumValue('SSLCertificateExpiryStatus', expiryStatus);

        if (enableOcspStapling) {
            const { description: ocspErrorStatusDescription } = this.schemaService
                .getEnumValue('OCSPErrorStatus', ocspErrorStatus);

            if (expiryStatus === SSLCertificateExpiryStatus.SSL_CERTIFICATE_EXPIRED) {
                return sslExpiryDescription;
            }

            if (ocspErrorStatusType === OCSP_UI_STATUS_ERROR) {
                return ocspErrorStatusDescription;
            }

            if (expiryStatus === SSLCertificateExpiryStatus.SSL_CERTIFICATE_EXPIRY_WARNING) {
                return sslExpiryDescription;
            }

            return ocspErrorStatusDescription;
        }

        return sslExpiryDescription;
    }

    public get isExpiryStatusWarning(): boolean {
        const { certificate } = this.certificate.getConfig();
        const { data } = certificate;
        const { config } = data;
        const { expiry_status: expiryStatus } = config;

        return expiryStatus === SSLCertificateExpiryStatus.SSL_CERTIFICATE_EXPIRY_WARNING;
    }
}
