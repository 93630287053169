/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import { L10nService } from '@vmw/ngx-vip';
import { AviPermissionResource } from 'generated-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { ProtocolParserModalComponent } from 'ng/modules/scripts';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import * as l10n from '../scripts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Ajs dependency token for ProtocolParser item.
 */
export const PROTOCOL_PARSER_ITEM_TOKEN = 'ProtocolParser';

/**
 * @description ProtocolParser Item
 *
 * @author Aravindh Nagarajan, Rajawant Prajapati
 */
export class ProtocolParser extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'protocolparser',
            objectType: 'ProtocolParser',
            windowElement: ProtocolParserModalComponent,
            permissionName: AviPermissionResource.PERMISSION_PROTOCOLPARSER,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.protocolParserScriptModalBreadcrumbTitle);
    }
}
