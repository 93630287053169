/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function arrayOfObjectsListDataTransformerFactory(ListDataTransformer) {
    /**
     * @constructor
     * @memberof module:avi/app
     * @extends module:avi/dataModel.ListDataTransformer
     * @author Ashish Verma
     * @desc ListDataTransformer of {@link module:avi/app.ArrayOfObjects ArrayOfObjects}.
     */
    class ArrayOfObjectsListDataTransformer extends ListDataTransformer {
        /** @override */
        processResponse(resp) {
            let results = [];

            if (resp.data && Array.isArray(resp.data)) {
                results = _.map(resp.data, function(item) {
                    item.name = item[this.propertyToDisplay];
                    item.url = item.name;

                    return { config: item };
                }, this.owner_);
            }

            return {
                data: {
                    results,
                    count: results.length,
                },
            };
        }
    }

    return ArrayOfObjectsListDataTransformer;
}

arrayOfObjectsListDataTransformerFactory.$inject = [
    'ListDataTransformer',
];

angular.module('avi/app').factory(
    'ArrayOfObjectsListDataTransformer',
    arrayOfObjectsListDataTransformerFactory,
);
