/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    isNull,
    isUndefined,
} from 'underscore';

import { aviSliderRangeValidator } from 'ng/modules/avi-forms/validators';
import { SchemaService } from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-slider.l10n';
import './avi-slider.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

// Default minLimit value.
export const DEFAULT_MIN_SLIDER_LIMIT = 0;

// Default maxLimit value.
export const DEFAULT_MAX_SLIDER_LIMIT = 100;

// Default step value.
export const DEFAULT_STEP_VALUE = 1;

// Default name attribute value.
const DEFAULT_NAME_VALUE = 'avi-slider-input';

// CdsRange accepts status string to display component's validation status.
enum CdsRangeStatus {
    error = 'error',
    neutral = 'neutral'
}

/**
 * @description Slider component.
 *
 *      Component to wrap Clarity's range component and an input element.
 *
 * @author vgohil
 */
@Component({
    selector: 'avi-slider',
    templateUrl: './avi-slider.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviSliderComponent),
        },
        {
            multi: true,
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AviSliderComponent),
        },
    ],
})
export class AviSliderComponent implements ControlValueAccessor, Validator, OnInit {
    /**
     * Optional min value for range input.
     */
    @Input()
    public minLimit ?= DEFAULT_MIN_SLIDER_LIMIT;

    /**
     * Optional max value for range input.
     */
    @Input()
    public maxLimit ?= DEFAULT_MAX_SLIDER_LIMIT;

    /**
     * Optional step value for range input.
     */
    @Input()
    public stepValue ?= DEFAULT_STEP_VALUE;

    /**
     * Set the name attribute of slider input field.
     */
    @Input()
    public name = DEFAULT_NAME_VALUE;

    /**
     * Placeholder text for the slider input field.
     */
    @Input()
    public placeholder: string;

    /**
     * Optional helper text, to be displayed below the input field.
     */
    @Input()
    public helperText ?= '';

    /**
     * ObjectType of the field (Optional).
     */
    @Input()
    public objectType?: string;

    /**
     * Name of field in ObjectType (Optional).
     */
    @Input()
    public fieldName?: string;

    /**
     * Optional error text, to be displayed when ngModel becomes invalid.
     */
    @Input()
    public errorText ?= '';

    /**
     * Optional hide range input feild.
     */
    @Input()
    public hideInput ?= false;

    /**
     * Setter for required attribute.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Setter for disabled attribute.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * ElementRef of Clarity's CdsRange component.
     */
    @ViewChild('cdsRangeRef')
    private cdsRangeRef: ElementRef;

    /**
     * Hash of validation errors, coming from the control's errors object.
     * Updated on validate the model value changes.
     */
    public validationErrors: ValidationErrors;

    /**
     * Set to true to make this component required.
     */
    public required = false;

    /**
     * Set to true to make this component disabled.
     */
    public disabled = false;

    /**
     * The ngModel value.
     */
    private modelValue: number;

    constructor(
        private readonly schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.placeholder = l10nService.getMessage(l10nKeys.placeholderLabel);
    }

    /**
     * If objectType & fieldName passed by user, try to set max and min limits.
     * @override
     */
    public ngOnInit(): void {
        const {
            objectType,
            fieldName,
            minLimit,
            maxLimit,
            schemaService,
        } = this;

        // If min/max value not set by user, then try to get it from Schema bindings.
        if (objectType && fieldName) {
            if (minLimit === DEFAULT_MIN_SLIDER_LIMIT && maxLimit === DEFAULT_MAX_SLIDER_LIMIT) {
                try {
                    [this.minLimit, this.maxLimit] =
                        schemaService.getFieldRangeAsTuple(objectType, fieldName);
                } catch (e) { /** empty catch block. */ }
            }
        }
    }

    /**
     * Getter for model value.
     */
    public get value(): number {
        return this.modelValue;
    }

    /**
     * Setter for model value.
     */
    public set value(value: number) {
        this.modelValue = value;

        /**
         * To handle the change in CdsRange when user provides input.
         * Clarity range is not working via ngModel two way binding.
         */
        let trackWidth = 0; // set default/mininum to 0, as it works in percentage.
        const { minLimit, maxLimit, stepValue, cdsRangeRef } = this;

        if (value >= minLimit && value <= maxLimit) {
            const valueByStep = Math.round(value / stepValue) * stepValue;

            trackWidth = Math.round((valueByStep - minLimit) * 100 / (maxLimit - minLimit));
        } else if (value > maxLimit) { // set max to 100% as it works in percentage.
            trackWidth = 100;
        }

        cdsRangeRef.nativeElement.setAttribute('style', `--track-width:${trackWidth}%`);

        this.onChange(this.modelValue);
        this.onTouched();
    }

    /**
     * Getter for component status. To show red highlight on error.
     */
    public get status(): CdsRangeStatus {
        return this.invalid ? CdsRangeStatus.error : CdsRangeStatus.neutral;
    }

    /**
     * Returns true if the component is invalid.
     */
    public get invalid(): boolean {
        return Boolean(this.validationErrors);
    }

    /***********************************************************
     * Implementing Validator Interface
     */

    /** @override */
    public validate(control: AbstractControl): ValidationErrors | null {
        /**
         * Show and returns the required field validation if the component is required.
         * Otherwise returns null.
         */
        if (control.dirty && (isUndefined(control.value) || isNull(control.value))) {
            if (this.required && control.errors) {
                this.validationErrors = control.errors;

                return this.validationErrors;
            }

            return null;
        }

        const range: [number, number] = [this.minLimit, this.maxLimit];

        // Show the validation errors if it's occurred.
        this.validationErrors = aviSliderRangeValidator(range)(control);

        return this.validationErrors || null;
    }

    /***********************************************************/

    /***********************************************************
     * Implementing ControlValueAccessor Interface
     * /

    /**
     * Writes the model value.
     */
    public writeValue(value: number): void {
        this.modelValue = value;

        if (value !== null) {
            this.value = value;
        }
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: number) => {}): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /***********************************************************/

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onChange = (value: number): void => {};

    /**
     * Method to be overriden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /***********************************************************/
}
