/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subscription } from 'rxjs';
// @ts-expect-error
import * as d3 from 'd3';
import { DevLoggerService } from 'ng/modules/core';
import { L10nService } from '@vmw/ngx-vip';
import { isDistinct } from 'ng/shared/utils';
import {
    IAviBarGraphColors,
    IAviBarGraphDataPoint,
    IAviBarGraphDataPointValueInput,
    TTimestampFormatFromApi,
    TTimestampFormatFromApiWithMicroS,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.types';
import {
    IVsLogsGraphResponseData,
    IVsLogsGraphResponseResultData,
    TVsLogsGraphStateParams,
} from '../../vs-logs.types';
import { VsLogsStore } from '../../services/vs-logs.store';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';
import * as l10n from './vs-logs-graph.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IStateTypes {
    end: TTimestampFormatFromApiWithMicroS;
    start: TTimestampFormatFromApiWithMicroS;
    isLoading: boolean;
    hasError: boolean;
    vsLogsGraphResults: IVsLogsGraphResponseResultData[];
}

/**
 * Needed to handle cancellation of request if previous request hangs incomplete.
 */
const VS_LOGS_GRAPH_REQUEST_ID = 'VS_LOGS_GRAPH_REQUEST';

const initialState: IStateTypes = {
    end: String(),
    start: String(),
    hasError: false,
    isLoading: true,
    vsLogsGraphResults: [],
};

/**
 * Converts timestamp returned by api for individual data-points to standard time-format.
 */
function parseTime(input: TTimestampFormatFromApiWithMicroS | TTimestampFormatFromApiWithMicroS):
TTimestampFormatFromApi {
    switch (input.length) {
        case 32:
            return d3.timeParse('%Y-%m-%dT%H:%M:%S.%f%Z')(input);
        case 25:
            return d3.timeParse('%Y-%m-%dT%H:%M:%S%Z')(input);
    }

    return d3.timeParse('%Y-%m-%dT%H:%M:%S.%f%Z')(input);
}

/**
 * Only values which when altered, must prompt a new api request.
 */
export type TValuesAffectingGraph = Omit<IStateTypes, 'isLoading'>;

/**
 * @description State and Effect management for VsLogsGraphComponent.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Injectable()
export class VsLogsGraphStore extends ComponentStore<IStateTypes> {
    public readonly vsLogsGraphColors: IAviBarGraphColors = {
        nonSignificant: {
            color: '#62A420',
            label: '',
        },
        significant: {
            color: '#E62700',
            label: '',
        },
    };

    // ********************************** Selectors **********************************

    public readonly graphBarData$: Observable<IAviBarGraphDataPoint[]> =
    this.select(({ vsLogsGraphResults }) => {
        return vsLogsGraphResults.map(
            (result: IVsLogsGraphResponseResultData): IAviBarGraphDataPoint => {
                const values: IAviBarGraphDataPointValueInput[] = [];

                const {
                    adf,
                    end,
                    start,
                    nf,
                    udf,
                    value: totalValueSummation,
                } = result;

                const {
                    vsLogsGraphColors,
                } = this;

                if (adf) {
                    values.push({
                        color: vsLogsGraphColors.significant.color,
                        count: adf,
                        type: vsLogsGraphColors.significant.label,
                    });
                }

                if (nf || udf) {
                    values.push({
                        color: vsLogsGraphColors.nonSignificant.color,
                        count: (nf || 0) + (udf || 0),
                        type: vsLogsGraphColors.nonSignificant.label,
                    });
                }

                return {
                    end,
                    start,
                    totalValueSummation,
                    values,
                };
            },
        );
    })
        .pipe(
            isDistinct(),
        );

    public readonly end$: Observable<TTimestampFormatFromApi> =
    this.select(({ end }) => parseTime(end));

    public readonly start$: Observable<TTimestampFormatFromApi> =
    this.select(({ start }) => parseTime(start));

    public readonly isLoading$ =
    this.select(({ isLoading }) => isLoading);

    public readonly hasError$ =
    this.select(({ hasError }) => hasError);

    // ********************************** Effects **********************************

    /**
     * Request API for graph data and update the state accordingly.
     */
    public readonly getVsLogsGraphData: (apiParams: TVsLogsGraphStateParams) => Subscription;

    /**
     * Reload the graph to fetch new logs.
     */
    public readonly reloadGraph: () => void;

    // **************************** Updaters ******************************

    /**
     * Set the isLoading flag.
     */
    private readonly setIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    /**
     * Update the state with the graph data.
     */
    private readonly setGraphData = this.updater((state, data: IVsLogsGraphResponseData) => ({
        ...state,
        end: data.end,
        start: data.start,
        vsLogsGraphResults: data.results,
    }));

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        vsLogsEffectsService: VsLogsEffectsService,
        private readonly devLoggerService: DevLoggerService,
        l10nService: L10nService,
    ) {
        super(initialState);

        l10nService.registerSourceBundles(dictionary);

        // Initialize request and reload effects
        this.getVsLogsGraphData = this.effect<TVsLogsGraphStateParams>(
            vsLogsEffectsService.createVsLogsRequestEffect(
                VS_LOGS_GRAPH_REQUEST_ID,
                this.startLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiComplete,
            ),
        );

        this.reloadGraph = this.effect(
            VsLogsEffectsService.createReloadEffect<TVsLogsGraphStateParams>(
                vsLogsStore.vsLogsGraphApiParams$,
                this.getVsLogsGraphData,
            ),
        );

        const {
            nonSignificantLabel,
            significantLabel,
        } = l10nKeys;
        const {
            vsLogsGraphColors,
        } = this;

        vsLogsGraphColors.nonSignificant.label = l10nService.getMessage(nonSignificantLabel);
        vsLogsGraphColors.significant.label = l10nService.getMessage(significantLabel);
    }

    // ********************************** Methods **********************************

    /**
     * Set isLoading to true upon initiating an API request.
     */
    private startLoading = (): void => {
        this.setErrorState(false);
        this.setIsLoading(true);
    };

    /**
     * Handles response from API once retrieved.
     */
    private handleApiData = (data: IVsLogsGraphResponseData): void => {
        this.setGraphData(data);
    };

    /**
     * Handles instance of API returning error.
     */
    private handleApiError = (err: any): void => {
        this.devLoggerService.error(err);
        this.setErrorState(true);
        this.handleApiComplete();
    };

    /**
     * Toggle store errState var.
     */
    private setErrorState = (hasError: boolean): void => {
        this.vsLogsStore.patchState(() => ({ hasError }));
        this.patchState(() => ({ hasError }));
    };

    /**
     * Set isLoading to false upon API request completion.
     */
    private handleApiComplete = (): void => {
        this.setIsLoading(false);
    };
}
