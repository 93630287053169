/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { each } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { WafPolicyPSMGroup } from 'object-types';
import * as l10n from 'ajs/modules/waf/waf.l10n';

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins/with-full-modal.mixin';

import {
    Item,
    ObjectTypeItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    ICollMetricsRequest,
    IItemMetricTuple,
    TWindowElement,
} from 'ajs/modules/data-model/data-model.types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    AviPermissionResource,
    IWafPolicyPSMGroup,
} from 'generated-types';

import { WafPolicyPsmGroupModalPreviewComponent }
    // eslint-disable-next-line max-len
    from 'ng/lazy-loaded-components/modals/waf-policy-psm-group-modal/waf-policy-psm-group-modal-preview/';

import { IFullModalLayout } from 'ng/modules/core/services/full-modal/full-modal.service';
import { WafPSMLocationConfigItem } from './waf-psm-location.config-item.factory';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Custom Waf PSM Group Type.
 */
type TIWafPolicyPSMGroupPartial = Omit<IWafPolicyPSMGroup, 'locations'>;

/**
 * Interface for Waf PSM Group Type.
 */
interface IWafPolicyPsmGroupConfig extends TIWafPolicyPSMGroupPartial {
    locations: RepeatedMessageItem<WafPSMLocationConfigItem>;
}

/**
 * @description WAF Policy PSM Group Class.
 *
 * @author Alex Tseung, Nitesh Kesarkar
 */
export class WafPolicyPsmGroup extends withEditChildMessageItemMixin(
    withFullModalMixin(ObjectTypeItem),
) {
    public static ajsDependencies = [
        'l10nService',
        'GroupCollMetric',
    ];

    public data: {
        config: IWafPolicyPsmGroupConfig;
    };

    public getConfig: () => this['data']['config'];

    /**
     * l10n Service for internationalization.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafpolicypsmgroup',
            objectType: WafPolicyPSMGroup,
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_WAFPOLICYPSMGROUP,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled: boolean) {
        this.config.enable = enabled;
    }

    /**
     * Returns locations in the config item
     */
    public get locations(): RepeatedMessageItem<WafPSMLocationConfigItem> {
        return this.config.locations;
    }

    /**
     * Returns the no of locations in the config item
     */
    public get locationsCount(): number {
        return this.config.locations.count;
    }

    /**
     * Returns true if the PSM Group is a learning group.
     */
    public get isLearningGroup(): boolean {
        return this.config.is_learning_group;
    }

    /**
     * Sets the is_learning_group property.
     */
    public set isLearningGroup(learning: boolean) {
        this.config.is_learning_group = learning;
    }

    /**
     * We pass '*' as the entity_uuid because the metrics are based in the context of VSes, so
     * use '*' to gather all VS data. obj_id is the id of the PSM Group.
     * TODO: Use the inventory API to get a list of VSes for each PSM Group to replace '*'.
     * @override
     */
    public getMetricsTuple(): IItemMetricTuple {
        return {
            entity_uuid: '*',
            obj_id: this.id,
            dimension_filter_op: 'METRICS_FILTER_CONTAINS',
        };
    }

    /**
     * We are overriding this to pass in the dimension_aggregation to use AggSeries.
     * @override
     */
    public getCollMetricsRequests(fields: string[]): ICollMetricsRequest[] {
        const GroupCollMetric = this.getAjsDependency_('GroupCollMetric');
        const requests: ICollMetricsRequest[] = [];

        if (Array.isArray(fields)) {
            each(fields, fieldName => {
                if (this.dataFieldIsApplicable(fieldName)) {
                    if (!(fieldName in this.collMetricsHash)) {
                        this.collMetricsHash[fieldName] = new GroupCollMetric({
                            name: fieldName,
                            dimension_aggregation: 'METRICS_DIMENSION_AGG_SUM',
                            item: this,
                            subscribers: ['GroupCollMetricRequest'],
                            series: fieldName,
                        });
                    }

                    const request = this.collMetricsHash[fieldName].beforeCall();

                    if (request) {
                        requests.push(request.series);
                    }
                }
            });
        }

        return requests;
    }

    /**
     * Creates a new location and opens the modal to edit it.
     */
    public addLocation(preventEdit: boolean): void {
        this.addChildMessageItem({
            field: 'locations',
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Edits a WafPSMLocation config item.
     */
    public editLocation(
        location: WafPSMLocationConfigItem,
        preventEdit: boolean,
    ): void {
        this.editChildMessageItem({
            field: 'locations',
            messageItem: location,
            modalBindings: {
                preventEdit,
            },
        });
    }

    /**
     * Deletes a WafPSMLocation config item.
     */
    public deleteLocation(location: WafPSMLocationConfigItem): void {
        this.locations.removeByMessageItem(location);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyPsmGroupModalComponent } = await import(
            /* webpackChunkName: "waf-policy-psm-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-psm-group-modal/waf-policy-psm-group-modal.component'
        );

        return WafPolicyPsmGroupModalComponent as Type<Component>;
    }

    /**
     * @override
     */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: WafPolicyPsmGroupModalPreviewComponent as Type<Component>,
            previewComponentProps: {
                config: params.editable.getConfig(),
                editable: params.editable,
                isItem: params.editable instanceof Item,
            },
        };
    }

    /**
     * @override
     * Provides Modal Bread Crumb Title for WAF Policy PSG Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.wafPolicyPsgModalBreadcrumbTitle);
    }
}
