/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { TooltipPosition } from '../../directives';

/**
 * @desc Component to render text as a tooltip. A caret is also rendered pointing to the original
 *     element that this tooltip is attached to, which can change depending on the direction of the
 *     original element. This component is used by the aviTitleDirective, which updates the 'text'
 *     and 'tooltipPosition' bindings programmatically, so ChangeDetectionStrategy.OnPush cannot be
 *     used here.
 * @author alextsg
 */
@Component({
    selector: 'avi-tooltip-text',
    template: `
        <avi-tooltip-content [tooltipPosition]="tooltipPosition">
            {{ text }}
        </avi-tooltip-content>
    `,
})
export class AviTooltipTextComponent {
    /**
     * TooltipPosition binding, used to determine the styling of the caret.
     */
    @Input()
    public tooltipPosition = TooltipPosition.TOP_RIGHT_POSITION;

    /**
     * Text to be rendered inside the tooltip.
     */
    @Input()
    public text = '';
}
