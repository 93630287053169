/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-banners-config';
const prefix = `${moduleName}.${componentName}`;

export const bannersHeader = `${prefix}.bannersHeader`;
export const loginBannerLabel = `${prefix}.loginBannerLabel`;
export const loginBannerPlaceholder = `${prefix}.loginBannerPlaceholder`;
export const messageOfTheDayLabel = `${prefix}.messageOfTheDayLabel`;
export const messageOfTheDayPlaceholder = `${prefix}.messageOfTheDayPlaceholder`;

export const ENGLISH = {
    [bannersHeader]: 'Banners',
    [loginBannerLabel]: 'Login Banner',
    [loginBannerPlaceholder]: 'Enter Login Banner',
    [messageOfTheDayLabel]: 'Message of the Day',
    [messageOfTheDayPlaceholder]: 'Enter Message of the Day',
};
