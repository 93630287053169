/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IL4RuleActionSelectPool, L4RuleActionSelectPoolType } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IPolicyActionInfo } from 'ajs/modules/policies/policies.types';

export const L4_RULE_ACTION_SELECT_POOL_CONFIG_ITEM_TOKEN = 'L4RuleActionSelectPoolConfigItem';

/**
 * @desc L4RuleActionSelectPool MessageItem class.
 * @author Zhiqian Liu
 */
export class L4RuleActionSelectPoolConfigItem extends MessageItem<IL4RuleActionSelectPool>
    implements IPolicyActionInfo {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'L4RuleActionSelectPool',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IL4RuleActionSelectPool> {
        return {
            action_type: L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOL,
        };
    }

    /**
     * Getter for action type.
     */
    public get type(): L4RuleActionSelectPoolType {
        return this.config.action_type;
    }

    /**
     * Setter for action type.
     */
    public set type(actionType: L4RuleActionSelectPoolType) {
        this.config.action_type = actionType;
    }

    /**
     * Label of action. Commonly used as part of expander info.
     */
    public get actionLabel(): string {
        return 'Passthrough served by';
    }

    /**
     * Value of action to represent the data. Commonly used as part of expander info.
     */
    public get actionValue(): string {
        const { action_type: actionType } = this.config;

        let poolOrPoolGroup;
        let poolOrPoolGroupName = '';

        const {
            pool_ref: poolRef,
            pool_group_ref: poolGroupRef,
        } = this.config;

        const stringService = this.getAjsDependency_('stringService');

        if (actionType === L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOL) {
            poolOrPoolGroup = 'Pool';
            poolOrPoolGroupName = poolRef ? stringService.name(poolRef) : '';
        } else if (actionType === L4RuleActionSelectPoolType.L4_RULE_ACTION_SELECT_POOLGROUP) {
            poolOrPoolGroup = 'Pool Group';
            poolOrPoolGroupName = poolGroupRef ? stringService.name(poolGroupRef) : '';
        }

        return `${poolOrPoolGroup}: ${poolOrPoolGroupName}`;
    }

    /**
     * Called on change of action type. Delete selected pool / pool group.
     */
    public onActionTypeChange(): void {
        delete this.config.pool_ref;
        delete this.config.pool_group_ref;
    }

    /**
     * Return a string representation of the config data.
     */
    public toString(): string {
        return `${this.actionLabel}: ${this.actionValue}`;
    }
}

L4RuleActionSelectPoolConfigItem.ajsDependencies = [
    'stringService',
];
