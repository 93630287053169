/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IHttpResponse } from 'angular';
import { ISSLKeyAndCertificate } from 'generated-types';
import { SslCertificateExportModalService } from '../..';

import * as l10n from './ssl-certificate-export-modal.l10n';
import './ssl-certificate-export-modal.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for export certificate modal.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'ssl-certificate-export-modal',
    templateUrl: './ssl-certificate-export-modal.component.html',
})
export class SslCertificateExportModalComponent implements OnInit {
    /**
     * certificate uuid value.
     */
    @Input()
    public uuid: string;

    /**
     * certificate data object.
     */
    public data: ISSLKeyAndCertificate;

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Used to show error messages if any.
     */
    public errors: string;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        @Inject('clipboardService')
        private readonly clipboardService: any,
        private readonly certificateExportModalService: SslCertificateExportModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.fetchCertificateData(this.uuid);
    }

    /**
     * Function to copy certificate and key data to clipboard.
     */
    public copyToClipboard(data: string, target: EventTarget): void {
        this.clipboardService.copy(data, target);
    }

    /**
     * Function to close certificate export modal.
     */
    public dismissModal(): void {
        this.certificateExportModalService.closeModal();
    }

    /**
     * Function to get name of certificate.
     */
    public get name(): string {
        return this.data ? this.data.name : '';
    }

    /**
     * Function to fetch certificate data.
     */
    private fetchCertificateData(uuid: string): void {
        this.busy = true;
        this.certificateExportModalService.fetch(uuid)
            .then(({ data }: IHttpResponse<ISSLKeyAndCertificate>) => {
                this.data = data;
            }).catch(({ data }) => {
                this.errors = data;
            }).finally(() => this.busy = false);
    }
}
