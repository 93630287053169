/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { debounce } from 'underscore';
import { StringService } from 'ajs/modules/core/services/string-service';
import {
    anyIP,
    anySubnet,
    ipAddrRange,
} from 'ng/utils/regex.utils';
import { IpAddrMatchConfigItem } from 'ajs/modules/match';
import * as l10n from './ip-addr-match.l10n';
import './ip-addr-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Match component for the IpAddrMatch message. This component breaks down the message items of
 *     the IpAddrMatch, made up of IpAddrs, IpAddrPrefixes, and IpAddrRanges, along with the
 *     group_refs property for IpAddrGroup references and combines them all for configuration.
 * @author alextsg
 */
@Component({
    selector: 'ip-addr-match',
    templateUrl: './ip-addr-match.component.html',
})
export class IpAddrMatchComponent implements OnInit {
    /**
     * IpAddrMatch message item instance.
     */
    @Input()
    public editable: IpAddrMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    public readonly l10nKeys = l10nKeys;

    /**
     * String representation of all configured groups and addresses.
     */
    public addresses: string[] = [];

    constructor(
        l10nService: L10nService,
        private stringService: StringService,
        @Inject('RangeParser')
        private rangeParser: any,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.handleAddressChange = debounce(this.handleAddressChange, 250);
    }

    /** @override */
    public ngOnInit(): void {
        const { group_refs: groupRefs = [] } = this.editable.config;

        this.addresses = this.editable.addresses.concat(groupRefs);

        if (!this.addresses.length) {
            this.addItem();
        }
    }

    /**
     * Called to add an address entry.
     */
    public addItem(): void {
        this.addresses.push('');
    }

    /**
     * Called to remove an address entry.
     */
    public removeItem(index: number): void {
        this.addresses.splice(index, 1);
        this.handleAddressChange();
    }

    /**
     * Called when an address has changed. We need to translate the string representations of each
     * address type into message items to be added to the match.
     */
    public handleAddressChange(): void {
        this.editable.removeAll();

        this.addresses.forEach(address => {
            if (ipAddrRange.test(address)) {
                const range = this.rangeParser.ipRange2Json(address);

                this.editable.config.ranges.add(range);
            } else if (anyIP.test(address)) {
                const ipAddr = this.rangeParser.ipRange2Json(address);

                this.editable.config.addrs.add(ipAddr);
            } else if (anySubnet.test(address)) {
                const prefix = this.rangeParser.ipRange2Json(address);

                this.editable.config.prefixes.add(prefix);
            } else if (this.stringService.name(address)) {
                this.editable.addGroupRef(address);
            }
        });
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }

    /**
     * Trackby function for the *ngFor of IpAddrMatchEntryComponents.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
