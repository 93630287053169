/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import $ from 'jquery';
import { throttle } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import { StateParams, StateService } from '@uirouter/core';
import { FaultService } from 'ajs/modules/notifications';

import {
    AppStateHandler,
    MyAccount,
    UserSessionEventListenersService,
    USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
} from 'ajs/modules/core/services';

import * as globalL10n from 'global-l10n';
import * as schemaL10n from 'ajs/js/services/schema.l10n';
import * as l10n from './core.l10n';

interface IAviRootscopeService extends ng.IRootScopeService {
    $state?: StateService;
    $stateParams?: StateParams;
}

const { ENGLISH: globalDictionary } = globalL10n;
const resizeEvent = 'resize';
const repaintEvent = 'repaint';
const $repaintViewPortEvent = '$repaintViewport';

const { ENGLISH } = l10n;
const { ENGLISH: schemaDict } = schemaL10n;

/**
 * @description
 * Run block for ajs core module.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export function initCoreModule(
    $rootScope: IAviRootscopeService,
    $state: StateService,
    $stateParams: StateParams,
    $window: ng.IWindowService,
    activateAviModalJQueryPlugin: ($: JQueryStatic) => void,
    userSessionEventListenersService: UserSessionEventListenersService,
    appStateHandler: AppStateHandler,
    FaultService: FaultService,
    l10nService: L10nService,
    logErrorToController: OnErrorEventHandlerNonNull,
    myAccount: MyAccount,
): void {
    activateAviModalJQueryPlugin($);
    FaultService.setListeners();
    userSessionEventListenersService.setListeners();
    appStateHandler.init();
    l10nService.registerSourceBundles(ENGLISH);
    l10nService.registerSourceBundles(globalDictionary);
    l10nService.registerSourceBundles(schemaDict);

    /** @deprecated */
    $rootScope.$state = $state;

    /** @deprecated */
    $rootScope.$stateParams = $stateParams;

    // So that we repaint when we change the timeframe
    // (at some point switch to just repainting the axes)
    // TODO replace with events
    $rootScope.$watch(
        () => myAccount.uiProperty.useUTCTime,
        () => $rootScope.$broadcast(repaintEvent),
    );

    // add single window listener to
    $($window).on(
        resizeEvent,
        throttle(() => {
            $rootScope.$broadcast($repaintViewPortEvent);
            $rootScope.$broadcast(repaintEvent);
        }, 100),
    );

    $window.onerror = logErrorToController;
}

initCoreModule.$inject = [
    '$rootScope',
    '$state',
    '$stateParams',
    '$window',
    'activateAviModalJQueryPlugin',
    USER_SESSION_EVENT_LISTENERS_SERVICE_TOKEN,
    'appStateHandler',
    'FaultService',
    'l10nService',
    'logErrorToController',
    'myAccount',
];
