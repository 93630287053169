/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SupportModule
 */

/**
 * @description Contains Ajs dependency tokens for factories, services of Support Module.
 *
 * @author Shahab Hashmi
 */

/**
 * Ajs dependency token for TechSupportCollection.
 */
export const TECH_SUPPORT_COLLECTION_TOKEN = 'TechSupportCollection';

/**
 * Ajs dependency token for TechSupportItem.
 */
export const TECH_SUPPORT_ITEM_TOKEN = 'TechSupportItem';
