/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { UDPProxyProfile } from 'object-types';
import { IUDPProxyProfile } from 'generated-types';
import * as l10n from './udp-proxy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description UDP Proxy setting component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'udp-proxy',
    templateUrl: './udp-proxy.component.html',
})
export class UdpProxyComponent {
    /**
     * UDP Proxy profile data.
     */
    @Input()
    public editable: MessageItem<IUDPProxyProfile>;

    /**
     * Object type for UDP Proxy.
     */
    public readonly objectType = UDPProxyProfile;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }
}
