/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/plt-down-arrow.less';

angular.module('aviApp').directive('pltDownArrow', function() {
    return {
        restrict: 'AE',
        template: ['<div class = "plt-down-arrow">',
                        '<div class = "box"><div class = "down-arrow"></div></div>',
                    '<div>',
        ].join(''),
    };
});
