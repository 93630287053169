/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-out-of-band';
const prefix = `${moduleName}.${componentName}`;

export const outOfBandLabel = `${prefix}.outOfBandLabel`;
export const datascriptNameLabel = `${prefix}.datascriptNameLabel`;
export const vsDatascriptEventLabel = `${prefix}.vsDatascriptEventLabel`;
export const uriQueryLabel = `${prefix}.uriQueryLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const modifiedContentLengthLabel = `${prefix}.modifiedContentLengthLabel`;
export const dsHeaders = `${prefix}.dsHeadersLabel`;

export const ENGLISH = {
    [outOfBandLabel]: 'Out of Band',
    [datascriptNameLabel]: 'Datascript Name',
    [vsDatascriptEventLabel]: 'VS Datascript Event',
    [uriQueryLabel]: 'URI Query',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [modifiedContentLengthLabel]: 'Modified Content Length',
    [dsHeaders]: 'DS Headers',
};
