/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ServiceEngineGroupModule
 */

import { Injector } from '@angular/core';
import { SE_GROUP_COLLECTION_TOKEN } from 'ajs/modules/service-engine-group/se-group.tokens';

import { SEGroupCollection }
    from 'ajs/modules/service-engine-group/factories/se-group.collection.factory';

const $injector = '$injector';

export const seGroupCollectionProvider = {
    deps: [$injector],
    provide: SEGroupCollection,
    useFactory(injector: Injector): typeof SEGroupCollection {
        return injector.get(SE_GROUP_COLLECTION_TOKEN);
    },
};
