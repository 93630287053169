/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ICloneServer } from 'generated-types';
import { CloneServer } from 'object-types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';

import * as l10n from '../traffic-clone-profile.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Clone Server config Item.
 *
 * @author Harmeet Kaur
 */

type TCloneServerPartial = Omit<ICloneServer, 'ip_address' | 'subnet'>;

export interface IExtendedCloneServer extends TCloneServerPartial {
    ip_address?: IpAddrConfigItem;
    subnet?: IpAddrPrefixConfigItem;
}

export class CloneServerConfigItem extends withFullModalMixin(MessageItem)<IExtendedCloneServer> {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: CloneServer,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method used to import lazy loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            CloneServerModalComponent,
        } = await import(
            /* webpackChunkName: "traffic-clone-profile-modal" */
            /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/traffic-clone-profile-modal/clone-server-modal/clone-server-modal.component'
        );

        return CloneServerModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.cloneServerModalBreadcrumbTitle);
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IExtendedCloneServer> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType('cloneserver') || {};

        return defaultConfig;
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'ip_address',
            'subnet',
        ];
    }
}
