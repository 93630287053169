/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SnmpConfiguration } from 'object-types';

import * as l10n from './system-settings-access-snmp-card.l10n';
import './system-settings-access-snmp-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Presentational Component for Access Card - SNMP Version, in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-access-snmp-card',
    templateUrl: './system-settings-access-snmp-card.component.html',
})
export class SystemSettingsAccessSystemSnmpComponent {
    /**
     * SNMP version
     */
    @Input()
    public snmpVersion = '';

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * objecttype for template usage.
     */
    public readonly objectType = SnmpConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
