/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name orderedGridDragAndDropBody
 * @description
 *     Directive for handling the <tbody> drop zone for the orderedGridDragAndDrop <tr> element.
 *     The <tr> table row element is dragged and dropped onto a placeholder element, which is an
 *     allowed drop zone since it is within this directive. This directive is responsible for
 *     handling the events of a draggable <tr> element being dragged over and dropped upon it.
 * @param {Function} onChange - Function to be called after the <tr> is dropped on the placeholder.
 *     The old index and the new index are passed in.
 */
const cellClass = 'ordered-grid__cell--body';
const rowClass = 'ordered-grid__body__row';
const rowDataClass = `${rowClass}--data`;
const placeholderClass = `${rowClass}__drag-placeholder`;

const orderedGridDragAndDropBody = () => {
    const link = ($scope, $elem) => {
        const tbody = $elem[0];
        let placeholderElement = null;

        /**
         * Counter for dragenter/dragleave events. Ensures that they happen only on the
         * element and not on the children.
         */
        let counter = 0;

        /**
         * Returns the placeholder element to be placed before or after a table row.
         * @return {JQuery} Placeholder <tr> element.
         */
        const getPlaceholderElement = () => {
            const cellsSelector = `.${rowClass}:first-child .${cellClass}`;
            const children = $elem.find(cellsSelector);
            let output = `<div class="${rowClass} ${rowDataClass} ${placeholderClass}">`;

            for (let i = 0; i < children.length; i++) {
                output += `<div class="${children[i].className}"></div>`;
            }

            return $(`${output}</div>`);
        };

        /**
         * Returns the index of the placeholder <tr> element, which is used as the new index the
         * rule is moving to.
         * @return {number}
         */
        const getPlaceholderIndex = () => {
            const placeholderNode = $elem.find(`div.${placeholderClass}`)[0];

            return $elem.children(`div.${rowDataClass}`).index(placeholderNode);
        };

        /**
         * Removes the placeholder <tr> element from the table.
         */
        const removePlaceholderElements = () => {
            $elem.find(`div.${placeholderClass}`).remove();
        };

        const dragenter = event => {
            event.stopPropagation();
            event.preventDefault();

            if (counter === 0) {
                event.dataTransfer.dropEffect = 'move';
                placeholderElement = getPlaceholderElement();
            }

            counter++;
        };

        const dragover = event => {
            event.stopPropagation();
            event.preventDefault();

            const rowElement = $(event.target).closest(`div.${rowClass}`);

            if (!rowElement.length || rowElement.hasClass(placeholderClass)) {
                return;
            }

            const row = rowElement[0];
            const rect = row.getBoundingClientRect();
            const isFirstHalf = event.clientY < rect.top + rect.height / 2;
            let placeholder;
            let placeholderToRemove;

            if (isFirstHalf) {
                placeholder = $(row).prev();
                placeholderToRemove = $(row).after();
            } else {
                placeholder = $(row).after();
                placeholderToRemove = $(row).prev();
            }

            if (placeholder.hasClass(placeholderClass)) {
                return;
            }

            if (placeholderToRemove.hasClass(placeholderClass)) {
                placeholderToRemove.remove();
            }

            placeholderElement.insertBefore(isFirstHalf ? row : row.nextSibling);
        };

        const dragleave = event => {
            event.stopPropagation();
            event.preventDefault();

            if (counter === 1) {
                removePlaceholderElements();
            }

            counter--;
        };

        const drop = event => {
            event.stopPropagation();
            event.preventDefault();

            const oldIndex = +event.dataTransfer.getData('text/plain');
            let newIndex = getPlaceholderIndex();

            if (oldIndex < newIndex) {
                newIndex--;
            }

            if (oldIndex !== newIndex) {
                $scope.onChange({
                    oldIndex,
                    newIndex,
                });
                $scope.$apply();
            }

            counter = 0;
            removePlaceholderElements();
        };

        /**
         * Events on the element being dragged over.
         */
        tbody.addEventListener('dragenter', dragenter, false);
        tbody.addEventListener('dragover', dragover, false);
        tbody.addEventListener('dragleave', dragleave, false);
        tbody.addEventListener('drop', drop, false);

        $scope.$on('$destroy', () => {
            tbody.removeEventListener('dragenter', dragenter);
            tbody.removeEventListener('dragover', dragover);
            tbody.removeEventListener('dragleave', dragleave);
            tbody.removeEventListener('drop', drop);
        });
    };

    return {
        restrict: 'A',
        link,
        scope: {
            onChange: '&',
        },
    };
};

angular.module('aviApp').directive('orderedGridDragAndDropBody', orderedGridDragAndDropBody);
