/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name scrollIntoView
 * @description
 *      Scrolls element into view if attribute expression evaluates to true.
 * @example
 *      <div scroll-into-view="true"></div>
 *      <span scroll-into-view="foo == bar"></span>
 *      <span scroll-into-view="1 == 1"></span>
 */
angular.module('aviApp').directive('scrollIntoView', function() {
    return (scope, element, attr) => {
        setTimeout(() => {
            const val = scope.$eval(attr.scrollIntoView);

            if (val) {
                const el = element.get(0);

                if (angular.isFunction(el.scrollIntoView)) {
                    el.scrollIntoView();
                }
            }
        });
    };
});
