/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'analytics-profile';

export const analyticsProfileModalBreadcrumbTitle = `${prefix}.analyticsProfileModalBreadcrumbTitle`;
export const headerFieldRulesTitle = `${prefix}.headerFieldRulesTitle`;
export const wafFieldRulesTitle = `${prefix}.wafFieldRulesTitle`;
export const uriQueryFieldRulesTitle = `${prefix}.uriQueryFieldRulesTitle`;

export const ENGLISH = {
    [analyticsProfileModalBreadcrumbTitle]: 'Analytics Profile',
    [headerFieldRulesTitle]: 'Header Field Rule',
    [wafFieldRulesTitle]: 'WAF Field Rule',
    [uriQueryFieldRulesTitle]: 'URI Query Field Rule',
};
