/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-tenancy-mode-card';
const prefix = `${moduleName}.${componentName}`;

export const emailSmtpSourceHeader = `${prefix}.emailSmtpSourceHeader`;
export const smtpSourceLabel = `${prefix}.smtpSourceLabel`;
export const fromAddressLabel = `${prefix}.fromAddressLabel`;
export const smtpServerLabel = `${prefix}.smtpServerLabel`;
export const anonymousSmtpServerValue = `${prefix}.anonymousSmtpServerValue`;
export const localHostSmtpServerValue = `${prefix}.localHostSmtpServerValue`;
export const smtpServerValue = `${prefix}.smtpServerValue`;
export const noSmtpServerValue = `${prefix}.noSmtpServerValue`;
export const sendTestEmailBtnLabel = `${prefix}.sendTestEmailBtnLabel`;

export const ENGLISH = {
    [emailSmtpSourceHeader]: 'Email SMTP Source',
    [smtpSourceLabel]: 'SMTP Source',
    [fromAddressLabel]: 'From Address',
    [smtpServerLabel]: 'SMTP Server',
    [anonymousSmtpServerValue]: 'Anonymous SMTP Server',
    [localHostSmtpServerValue]: 'Local Host',
    [smtpServerValue]: 'SMTP Server',
    [noSmtpServerValue]: 'N/A',
    [sendTestEmailBtnLabel]: 'Send Test Email',
};
