/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { SignpostPosition } from './signpost.constants';

import './signpost.component.less';

const componentName = 'signpost';
const caretClassNameHash = {
    [SignpostPosition.LEFT_POSITION]: `${componentName}--left`,
    [SignpostPosition.RIGHT_POSITION]: `${componentName}--right`,
    [SignpostPosition.TOP_LEFT_POSITION]: `${componentName}--top-left`,
    [SignpostPosition.BOTTOM_LEFT_POSITION]: `${componentName}--bottom-left`,
    [SignpostPosition.TOP_RIGHT_POSITION]: `${componentName}--top-right`,
    [SignpostPosition.BOTTOM_RIGHT_POSITION]: `${componentName}--bottom-right`,
};

/**
 * @description
 *
 *     Container component for displaying signpost pop-up contents.
 *     This component works with avi-tooltip directive as its #avi-tooltip-content template ref.
 *
 * @example
 *
 * example.component.ts:
 *     import { ConnectedPosition } from '@angular/cdk/overlay';
 *     import {
 *         signpostPositionMap,
 *         SIGNPOST_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_RIGHT_CONNECTED_POSITION,
 *         SIGNPOST_TOP_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_TOP_RIGHT_CONNECTED_POSITION,
 *         SIGNPOST_BOTTOM_RIGHT_CONNECTED_POSITION,
 *         SignpostPosition,
 *     } from 'ng/modules/tooltip/components/signpost/signpost.constants';
 *
 *     // Signpost position priorities.
 *     public signpostPositionPriorities: ConnectedPosition[] = [
 *         SIGNPOST_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_RIGHT_CONNECTED_POSITION,
 *         SIGNPOST_TOP_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION,
 *         SIGNPOST_TOP_RIGHT_CONNECTED_POSITION,
 *         SIGNPOST_BOTTOM_RIGHT_CONNECTED_POSITION,
 *     ];
 *
 *     // Decide signpost position relative to its parent.
 *     public signpostPosition: SignpostPosition;
 *
 *     // Subject to control the hide/show signpost close button.
 *     private signpostCloseControlSubject = new Subject<boolean>();
 *
 *     // Return an observable to allow hide/show signpost
 *     // to close itself through subscription.
 *     public get signpostCloseButtonControl$(): Observable<boolean> {
 *         return this.signpostCloseControlSubject.asObservable();
 *     }
 *
 *     // Notify signpost to update postion when the connected position from avi-tooltip changes
 *     public handleSignpostConnectedPositionChange(
 *         signpostConnectedPosition: ConnectedPosition,
 *     ): void {
 *         this.signpostPosition = signpostPositionMap.get(signpostConnectedPosition);
 *     }
 *
 *     // Close the signpost.
 *     public closeSignpost(): void {
 *         this.signpostCloseControlSubject.next(false);
 *     }
 *
 * example.component.html:
 *     <cds-button
 *         avi-tooltip
 *         [positionsPriority]="signpostPositionPriorities"
 *         [showOnClick]="true"
 *         [tooltipControl$]="signpostCloseButtonControl$"
 *         (onPositionChange)="handleSignpostConnectedPositionChange($event)"
 *     >
 *         signpost trigger
 *         <ng-template #aviTooltipContent>
 *             <signpost
 *                 [signpostPostion]="signpostPosition"
 *                 (onCloseButtonClick)="closeSignpost()"
 *             >
 *                 <div>
 *                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
 *                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
 *                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
 *                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
 *                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
 *                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
 *                      culpa qui officia deserunt mollit anim id est laborum.
 *                  </div>
 *             </signpost>
 *         </ng-template>
 *     </cds-button>
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'signpost',
    templateUrl: './signpost.component.html',
})
export class SignpostComponent {
    /**
     * Position of the signpost to the original element.
     * For style use only. The true position is decided on avi-tooltip directive level.
     */
    @Input()
    public signpostPosition: SignpostPosition;

    /**
     * Emit when the close button is clicked.
     */
    @Output()
    public onCloseButtonClick = new EventEmitter<void>();

    /**
     * Perform when close button is clicked.
     */
    public handleClose(): void {
        this.onCloseButtonClick.emit();
    }

    /**
     * CSS class name to put the caret to a correct position as to the signpost position.
     */
    public get caretClassName(): string {
        return caretClassNameHash[this.signpostPosition];
    }
}
