/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/sparkline-card.less';

angular.module('aviApp').directive('sparklineCard', ['chartValueController',
function(chartValueController) {
    return {
        scope: true,
        restrict: 'E',
        template: require('../../views/components/sparkline-card.partial.html'),
        controller: ['$scope', chartValueController],
        link: function sparklineCardController(scope) {
            scope.padding = {
                top: 5,
                bottom: 5,
                left: 5,
                right: 5,
            };
        },
    };
}]);
