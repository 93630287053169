/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Injector,
    OnDestroy,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FullModalService, IFullModalLayout } from 'ng/modules/core';
import { attachComponentBindings } from '../../utils';
import { ITEM_ID_TOKEN } from '../../shared.constants';
import './full-modal.component.less';

interface IFullModalLayoutComponentRefs {
    configComponentRef: ComponentRef<Component>;
    previewComponentRef: ComponentRef<Component> | null;
}

/**
 * @description Displays a full modal that consists of a config component, breadcrumbs, and a
 *     preview component. This component subscribes to the FullModalService, which outputs a modal
 *     layout stack of modals to render.
 * @author alextsg
 */
@Component({
    selector: 'full-modal',
    templateUrl: './full-modal.component.html',
})
export class FullModalComponent implements AfterViewInit, OnDestroy {
    /**
     * ViewContainerRef for fullModalConfig
     */
    @ViewChild('fullModalConfig', {
        read: ViewContainerRef,
        static: true,
    })
    private fullModalConfigViewContainerRef: ViewContainerRef;

    /**
     * ViewContainerRef for fullModalConfig preview.
     */
    @ViewChild('fullModalPreviewConfig', {
        read: ViewContainerRef,
        static: true,
    })
    private fullModalPreviewContainerRef: ViewContainerRef;

    public modalLayoutStack: IFullModalLayout[] = [];

    /**
     * Subscription to the FullModalService's modalLayoutStack.
     */
    private subscription: Subscription;

    /**
     * A map using the FullModalLayout as the key and its configComponentRef as the value.
     * When the user is configuring an object in a modal, and opens up another modal of another
     * object then closes that, we don't want to recreate the original modal. We do a lookup of the
     * FullModalLayout reference to see if componentRefs already exist in order to re-use
     * already-created config components.
     */
    private componentRefMap = new Map<IFullModalLayout, IFullModalLayoutComponentRefs>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private fullModalService: FullModalService,
    ) {}

    /**
     * Renders a componentRef within a viewContainerRef.
     */
    private static renderComponentRef = (
        componentRef: ComponentRef<Component>,
        viewContainerRef: ViewContainerRef,
    ): void => {
        viewContainerRef.insert(componentRef.hostView);
    };

    /**
     * @override
     * Subscibes to the FullModal service, which returns a stack of FullModalLayouts to be rendered.
     */
    public ngAfterViewInit(): void {
        this.subscription = this.fullModalService.modalLayoutStack$.subscribe(this.updateModals);

        this.updateModals(this.fullModalService.modalLayoutStack);
    }

    /**
     * @override
     * Unsubscribes from the FullModal service.
     */
    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Returns true if no modals should be rendered.
     */
    public get isHidden(): boolean {
        return !this.currentModalLayout;
    }

    /**
     * Returns the current modal to be rendered.
     */
    public get currentModalLayout(): IFullModalLayout {
        return this.modalLayoutStack[this.modalLayoutStack.length - 1];
    }

    /**
     * Called when the FullModal service returns a new modalLayoutStack. Destroys componentRefs of
     * modalLayouts that no longer exist in the new modalLayoutStack, and adds new ones to the
     * componentRefMap.
     */
    private setIncomingModalLayoutStack(incomingModalLayoutStack: IFullModalLayout[] = []): void {
        this.modalLayoutStack = incomingModalLayoutStack;

        this.destroyComponentRefs(incomingModalLayoutStack);
        this.addToComponentRefMap(incomingModalLayoutStack);
    }

    /**
     * Renders a config componentRef (and preview componentRef if it exists) onto their respective
     * directives.
     */
    private renderComponents(): void {
        this.fullModalConfigViewContainerRef.detach();
        this.fullModalPreviewContainerRef.detach();

        if (this.currentModalLayout) {
            const {
                configComponentRef,
                previewComponentRef,
            } = this.componentRefMap.get(this.currentModalLayout);

            FullModalComponent.renderComponentRef(
                configComponentRef,
                this.fullModalConfigViewContainerRef,
            );

            if (previewComponentRef) {
                FullModalComponent.renderComponentRef(
                    previewComponentRef,
                    this.fullModalPreviewContainerRef,
                );
            }
        }
    }

    /**
     * Handles a new incoming stack of modalLayouts.
     */
    private updateModals = (incomingModalLayoutStack: IFullModalLayout[] = []): void => {
        this.setIncomingModalLayoutStack(incomingModalLayoutStack);
        this.renderComponents();
    };

    /**
     * Destroys componentRefs that no longer exist in the modalLayoutStack.
     */
    private destroyComponentRefs(incomingModalLayoutStack: IFullModalLayout[] = []): void {
        this.componentRefMap.forEach((componentRefs, modalLayout): void => {
            if (!incomingModalLayoutStack.includes(modalLayout)) {
                const {
                    configComponentRef,
                    previewComponentRef,
                } = componentRefs;

                configComponentRef.destroy();

                if (previewComponentRef) {
                    previewComponentRef.destroy();
                }

                this.componentRefMap.delete(modalLayout);
            }
        });
    }

    /**
     * Adds a modalLayout and its configComponentRef and previewComponentRef to the componentRefMap.
     */
    private addToComponentRefMap(incomingModalLayoutStack: IFullModalLayout[] = []): void {
        incomingModalLayoutStack.forEach((modalLayout: IFullModalLayout): void => {
            if (!this.componentRefMap.has(modalLayout)) {
                const componentRefs: IFullModalLayoutComponentRefs = {
                    configComponentRef: this.createModalComponentInstance(
                        modalLayout,
                        this.fullModalConfigViewContainerRef,
                    ),
                    previewComponentRef: null,
                };

                // Preview component's parent injector should be the modal component's injector,
                // so that we can list a service in the modal's provider
                // (mostly for inter-component communication),
                // and the preview component will be able to use the same instance.
                componentRefs.previewComponentRef = this.createPreviewComponentInstance(
                    modalLayout,
                    componentRefs.configComponentRef,
                );

                this.componentRefMap.set(modalLayout, componentRefs);
            }
        });
    }

    /**
     * Creates and returns an instance of the preview component if previewComponent props is
     * passed.
     * returns null otherwise.
     */
    private createPreviewComponentInstance(
        modalLayout: IFullModalLayout,
        modalComponentRef: ComponentRef<Component>,
    ): ComponentRef<Component> | null {
        const { previewComponent, previewComponentProps = {} } = modalLayout;

        if (!previewComponent) {
            return null;
        }

        const componentFactory =
            this.componentFactoryResolver.resolveComponentFactory(previewComponent);

        const componentRef = componentFactory.create(modalComponentRef.injector);

        attachComponentBindings(componentRef, previewComponentProps);

        return componentRef;
    }

    /**
     * Creates an instance of the config component. If editable.id exists as a
     * componentProp, set it as the itemId provider so that the modal component and its children
     * have access to it through injection.
     */
    private createModalComponentInstance(
        modalLayout: IFullModalLayout,
        viewContainerRef: ViewContainerRef,
    ): ComponentRef<Component> {
        const { component, componentProps = {} } = modalLayout;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const injector = Injector.create({
            parent: viewContainerRef.injector,
            providers: [
                {
                    provide: ITEM_ID_TOKEN,
                    useValue: componentProps.editable?.id,
                },
            ],
        });

        const componentRef = componentFactory.create(injector);

        attachComponentBindings(componentRef, componentProps);

        return componentRef;
    }
}
