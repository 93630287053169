/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot';
const componentName = 'bot-mapping-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const placeholderMessage = `${prefix}.placeholderMessage`;
export const ENGLISH = {
    [headerLabel]: 'Bot Mapping',
    [placeholderMessage]: 'No Items Found',
};
