/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { TCPFastPathProfileConfigItem } from 'ajs/modules/network';

import * as l10n from './tcp-fast-path.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description TCP fast path setting component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'tcp-fast-path',
    templateUrl: './tcp-fast-path.component.html',
})
export class TcpFastPathComponent {
    /**
     * TCP fast path profile data.
     */
    @Input()
    public editable: TCPFastPathProfileConfigItem;

    /**
     * Object type for tcp fast path.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
