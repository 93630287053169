/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { BotClassMatcherConfigItem } from 'ajs/modules/match';
import { BotClassMatcher } from 'object-types';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils';
import * as l10n from './bot-class-matcher.l10n';
import './bot-class-matcher.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description BotClassMatcherComponent
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-class-matcher',
    templateUrl: './bot-class-matcher.component.html',
})
export class BotClassMatcherComponent {
    /**
     * Bot Class message item instance
     */
    @Input()
    public editable: BotClassMatcherConfigItem;

    /**
     * Index of the match within the MatchAdder component
     */
    @Input()
    public matchIndex = 0;

    /**
     * Match label passed to the MatchWrapperComponent
     */
    @Input()
    public label: string;

    /**
     * fieldName passed to the MatchWrapperComponent
     */
    @Input()
    public fieldName: string;

    /**
     * objectType passed to the MatchWrapperComponent
     */
    @Input()
    public objectType: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Object Type to be used in template for tooltip.
     */
    public readonly botClassMatcherObjectType = BotClassMatcher;

    /**
     * client_classes type dropdown options
     */
    public readonly botClientClassOptions: IAviDropdownOption[] = [];

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const botClientClassTypes = schemaService.getEnumValues('BotClientClass');

        this.botClientClassOptions = createOptionsFromEnumProps(botClientClassTypes);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
