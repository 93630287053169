/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'server-maintenance-mode';
const prefix = `${moduleName}.${componentName}`;

export const sectionTitleServerMaintenanceMode = `${prefix}.sectionTitleServerMaintenanceMode`;
export const maintenanceResponseCodeInputLabel = `${prefix}.maintenanceResponseCodeInputLabel`;
export const maintenanceResponseCodeInputPlaceholder = `${prefix}.maintenanceResponseCodeInputPlaceholder`;
export const maintenanceResponseCodeInputHelperText = `${prefix}.maintenanceResponseCodeInputHelperText`;
export const maintenanceResponseDataInputLabel = `${prefix}.maintenanceResponseDataInputLabel`;
export const maintenanceResponseDataInputPlaceholder = `${prefix}.maintenanceResponseDataInputPlaceholder`;

export const ENGLISH = {
    [sectionTitleServerMaintenanceMode]: 'Server Maintenance Mode',
    [maintenanceResponseCodeInputLabel]: 'Maintenance Response Code',
    [maintenanceResponseCodeInputPlaceholder]: 'Enter Response Code',
    [maintenanceResponseCodeInputHelperText]: 'Accepts single value, comma separate, and ranges',
    [maintenanceResponseDataInputLabel]: 'Maintenance Server Response Data',
    [maintenanceResponseDataInputPlaceholder]: 'Enter Response Data',
};
