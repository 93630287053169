/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './hs-graph.less';
import {
    layout as d3Layout,
    svg as d3Svg,
    select as d3Select,
} from 'd3v3';

const d3 = {
    layout: d3Layout,
    svg: d3Svg,
    select: d3Select,
};

//TODO new lines for long labels
//TODO handle race conditions between different update types
let
    $compile,
    healthScoreClassNameFilter;

const
    initialSmallValue = 1e-6,
    defaultNodeRadius = 10,
    scoresNodeRadius = defaultNodeRadius * 2,
    rootNodeRadius = defaultNodeRadius * 3;

/**
 * @ngdoc component
 * @name hsGraph
 * @author Alex Malitsky
 * @param graph {HSGraph}
 * @param item {VirtualService|Pool|ServiceEngine}
 * @description
 *
 *     HealthScore Graph component - renders a health score svg tree graph.
 *
 *     Initially shows one level of children and has an ability to drill down once tree nodes are
 *     clicked/activated.
 *
 *     Root node element shows Item health score average value with corresponding background
 *     color, first level children (scores) are also having avg numbers of corresponding score
 *     values.
 *
 **/
class HSGraphController {
    constructor($scope, $elem, $filter, _$compile_) {
        $compile = _$compile_;
        healthScoreClassNameFilter = $filter('healthScoreClass');

        this.$elem_ = $elem;
        this.$scope_ = $scope;

        /**
         * HSGraphNode.id of active node.
         * @type {string}
         */
        this.activeNodeId = '';

        /**
         * @type {number}
         */
        this.animationDuration = 280;

        /**
         * Margins of the wrapper within the SVG.
         * @type {{left: number}}
         */
        this.margin = {
            left: 15,
        };

        /**
         * Max tree depth for specific styling of last column nodes.
         * @type {number}
         */
        this.maxDepth = 0;

        /**
         * Max number of name characters on one line. For longer names we should split words
         * by a new line symbol.
         * @type {number}
         */
        this.maxNameLength = 50;

        /**
         * @type {d3.tree.nodes}
         */
        this.nodes = [];

        /**
         * @type {d3.tree.links}
         */
        this.links = [];

        /**
         * From $onInit we call this.setViewportSize_ on timeout and keep reference to it for
         * $destroy method (in case of immediate destroy event).
         * @type {number|null}
         * @private
         */
        this.setViewportSizeTimeout_ = null;

        [
            'setViewportSize_',
            'onResize_',
            'onGraphUpdate_',
            'onActiveNodeChange_',
            'nodeClickHandler_',
            'getNodeLabel_',
            'nodeCircleClassName',
            'nodeGroupClassName',
            'onItemRuntimeDataUpdate_',
        ].forEach(methodName => this[methodName] = this[methodName].bind(this));
    }

    /**
     * Sets the clicked node active, making an API call and redraws the tree.
     * @param {HSGraphNode|string} node
     * @private
     */
    nodeClickHandler_(node) {
        this.graph.setActiveNode(angular.isString(node) ? node : node.id);
    }

    /**
     * Returns a class name for the node group element.
     * @param {HSGraphNode} node
     * @returns {string}
     */
    nodeGroupClassName(node) {
        const classNames = ['node'];

        classNames.push(node.isLeafNode() ? 'leaf' : 'parent');

        if (node.isRootNode()) {
            classNames.push('root');
        } else if (node.isRootChild()) {
            classNames.push('score');
        }

        if (node.id === this.activeNodeId) {
            classNames.push('active');
        }

        if (node.isContributor()) {
            classNames.push('contributor');
        }

        return classNames.join(' ');
    }

    /**
     * Returns the Node name.
     * @param {HSGraphNode} node
     * @returns {string}
     */
    getNodeLabel_(node) {
        const
            name = node.getName(),
            { maxNameLength } = this;

        if (name.length > maxNameLength) {
            return `${name.slice(0, maxNameLength - 2)}...`;
        } else {
            return name;
        }
    }

    /**
     * Returns a unique id of a Node on the chart.
     * @param {Item.id} id
     * @returns {Item.id}
     * @static
     */
    static getIdFromNode({ id }) {
        return id;
    }

    /**
     * Returns and sets the node radius.
     * @param {HSGraphNode} node
     * @returns {number}
     */
    static setNodeRadius(node) {
        const { leafnode } = node.getConfig();

        let radius = defaultNodeRadius;

        if (leafnode && !node.isRootNode()) {
            radius *= 0.75;
        } else {
            switch (node.depth) {
                case 0:
                    radius = rootNodeRadius;
                    break;

                case 1:
                    radius = scoresNodeRadius;
                    break;

                default:
                    radius *= 1.25;
            }
        }

        return node.radius = radius;
    }

    /**
     * Returns a radius value of the passed node.
     * @param {number} radius
     * @returns {number}
     */
    static getNodeRadius({ radius }) {
        return radius;
    }

    /**
     * Returns transform property based on node's coordinates.
     * @param {number} x
     * @param {number} y
     * @returns {string}
     * @static
     */
    static nodePosTransform({ x, y }) {
        return `translate(${y},${x})`;
    }

    /**
     * Returns positioning attributes for the label text element. We use different
     * positioning for different node types/depths.
     * @param {HSGraphNode} node
     * @returns {{x: number, y: number, dy: string, text-anchor: string}}
     * @private
     */
    getNodeLabelPosition_(node) {
        const
            margin = 5,
            { radius, depth } = node;

        let x = 0,
            textAnchor = 'start';

        let y = radius + 10;

        if (depth === this.maxDepth) {
            x = radius;
            textAnchor = 'end';
        } else if (node.isRootNode()) {
            x = (radius + margin) * -1;
        } else if (node.isLeafNode()) {
            y = 0;
            x = radius + margin;
        } else {
            textAnchor = 'middle';
        }

        return {
            x,
            y,
            dy: '.35em',
            'text-anchor': textAnchor,
        };
    }

    /**
     * Partial app wrapper around this.getNodeLabelPosition_ to return function called by
     * d3.each. Needed because of 'this' being redefined by d3.
     * @returns {Function}
     */
    setNodeLabelPosition() {
        const self = this;//since d3 gonna redefined this

        return function(node) {
            d3.select(this).attr(self.getNodeLabelPosition_(node));
        };
    }

    /**
     * In some node circles we use HTML templates to render metrics number. This method will
     * return compiled HTML template empowered by Angular when applicable.
     * @param {HSGraphNode} node
     * @returns {jQuery|undefined}
     * @private
     */
    getNodeInnerTemplate_(node) {
        if (node.isRootNode() || node.isRootChild()) {
            const template = $('<metrics-value/>')
                .attr({
                    metrics: `$ctrl.graph.metrics.metricsHash["${node.id}"]`,
                    'display-type': 'avg',
                    'dec-digits': 0,
                    'ng-click':
                        `$ctrl.nodeClickHandler_('${node.id}')`,
                });

            return $compile(template)(this.$scope_);
        }
    }

    /**
     * Partial app wrapper around this.getNodeInnerTemplate_ to be passed into d3.each.
     * Also appends received rendered template into the node (when applicable).
     * @returns {Function}
    */
    appendNodeInnerTemplate() {
        const self = this;//d3 thing

        return function(node) {
            const template = self.getNodeInnerTemplate_(node);

            if (template) {
                const
                    { radius } = node,
                    height = Math.toFixed3(radius * 2);

                const div = d3.select(this)
                    .append('svg:foreignObject')
                    .attr({
                        x: -radius,
                        y: -radius,
                        height,
                        width: height,
                    })
                    .append('xhtml:div');

                $(div[0])
                    .addClass('container')
                    .append(template);
            }
        };
    }

    /**
     * Returns a health score class name for the root node circle element.
     * @param {string} id - Root node's id.
     * @returns {string}
     * @private
     */
    rootNodeCircleClassName_({ id }) {
        const
            runtimeData = this.item.getRuntimeData(),
            operState = runtimeData && runtimeData['oper_status']['state'],
            healthScore = this.graph.metrics.metricsHash[id];

        return healthScoreClassNameFilter(
            healthScore && healthScore.getValue('avg'),
            operState,
        );
    }

    /**
     * Returns node circle class name.
     * @param {HSGraphNode} node
     * @returns {string|null} - null when no extra class name is needed.
     */
    nodeCircleClassName(node) {
        if (node.isRootNode()) {
            return this.rootNodeCircleClassName_(node);
        } else if (node.isRootChild()) {
            return 'scores';
        }

        return null;
    }

    /**
     * Returns a unique id of an Edge on the chart.
     * @param {Item.id} target
     * @returns {Item.id}
     * @static
     */
    static getIdFromEdge({ target }) {
        return target.id;
    }

    /**
     * Returns an edge class name.
     * @param {HSGraphEdge} edge
     * @returns string
     */
    static edgeClassName({ edge }) {
        const { contributor } = edge.getConfig();

        return `${contributor ? 'contributor ' : ''}link`;
    }

    /**
     * For links we initially provide path from the root/clicked node to itself. This method
     * is calculating and returning this path.
     * @param {number} x
     * @param {number} y
     * @returns {string}
    */
    getPathFromToPrevNodePos({ x0: x, y0: y }) {
        const source = {
            x,
            y,
        };

        return this.diagonal({
            source,
            target: source,
        });
    }

    /**
     * Since d3.tree layout use its own list of edges we need to append HSGraphEdge data to
     * every such element. Also id property is added.
     * @param {Object[]} links - List of d3.tree links.
     * @param {HSGraphNode} links[].source
     * @param {HSGraphNode} links[].target
     */
    appendConfigToLinks_(links) {
        const { graph } = this;

        links.forEach(link => {
            const { target, source } = link;

            link.id = graph.getEdgeIdFromData({
                config: {
                    source: source.id,
                    target: target.id,
                },
            });

            link.edge = graph.edgeById[link.id];
        });
    }

    /**
     * Sets this.nodes and this.links arrays with appropriate positions and some extra
     * properties.
     * @private
     */
    calcElemPositions_() {
        const
            { tree, graph } = this,
            root = graph.getRootNode();

        this.nodes = tree.nodes(root);
        this.links = tree.links(this.nodes);

        const { links, nodes } = this;

        this.maxDepth = 0;

        nodes.forEach(node => {
            HSGraphController.setNodeRadius(node);
            this.maxDepth = Math.max(this.maxDepth, node.depth);
        });

        this.appendConfigToLinks_(links);

        if (root.y < defaultNodeRadius * 2) {
            root.y = defaultNodeRadius * 2;
        }

        if (!('x0' in root)) {
            root.x0 = root.x;
            root.y0 = root.y;
        }
    }

    /**
     * Method (re-)rendering the chart, to be called on graph update when we've got nodes or
     * links list changed. Property changes are handled by this.onPropertiesUpdate_.
     * @private
     */
    render_() {
        const
            { animationDuration: duration, graph, viewport, tree } = this,
            root = graph.getRootNode(),
            activeNode = graph.getActiveNode() || root;

        this.calcElemPositions_();

        this.activeNodeId = activeNode.id;

        const
            { nodes, links } = this,
            [height, width] = tree.size(),
            initialNewNodesPosition = `translate(${activeNode.y0},${activeNode.x0})`,
            finalRemovedNodesPosition = `translate(${height / 2}, ${width})`;

        const nodeElems = viewport.selectAll('g.node')
            .data(nodes, HSGraphController.getIdFromNode);

        const nodeEnter = nodeElems.enter()
            .append('g')
            .attr({
                class: this.nodeGroupClassName,
                transform: initialNewNodesPosition,
            });

        nodeEnter.append('circle')
            .attr({
                class: this.nodeCircleClassName,
                r: initialSmallValue,
            })
            .on('click', this.nodeClickHandler_);

        //for the root node and "scores" we want to put metrics avg value into the node
        nodeEnter.each(this.appendNodeInnerTemplate());

        nodeEnter.append('text')
            .each(this.setNodeLabelPosition())
            .text(this.getNodeLabel_)
            .style('fill-opacity', initialSmallValue)
            .on('click', this.nodeClickHandler_);

        //update section
        nodeElems
            .transition().duration(duration)//seems to affect enter and exit selections as well
            .attr({
                transform: HSGraphController.nodePosTransform,
                class: this.nodeGroupClassName,
            });

        nodeElems.select('circle')
            .attr({
                class: this.nodeCircleClassName,
                r: HSGraphController.getNodeRadius,
            });

        nodeElems.select('text')
            .transition().duration(duration)
            .each(this.setNodeLabelPosition())
            .style('fill-opacity', 1);

        const nodeExit = nodeElems.exit();

        nodeExit.select('circle')
            .attr('r', initialSmallValue);

        nodeExit.select('text')
            .style('fill-opacity', initialSmallValue);

        nodeExit
            .transition().duration(duration)
            .attr('transform', finalRemovedNodesPosition)
            .remove();

        const initialNewLinkPath = this.getPathFromToPrevNodePos(activeNode);

        const edgeElems = viewport
            .selectAll('path.link')
            .data(links, HSGraphController.getIdFromEdge);

        edgeElems.enter()
            .insert('path', 'g')
            .attr({
                class: HSGraphController.edgeClassName,
                d: initialNewLinkPath,
            });

        edgeElems
            .transition().duration(duration)
            .attr({
                class: HSGraphController.edgeClassName,
                d: this.diagonal,
            });

        edgeElems.exit()
            .transition().duration(duration)
            .attr('d', ({ target }) => {
                return this.getPathFromToPrevNodePos({
                    y0: width,
                    x0: target.x,
                });
            })
            .style('opacity', initialSmallValue)
            .remove();

        this.setPreviousNodePos_();
    }

    /**
     * Renders chart on window resize event. No graph update, just new positions for the
     * same elements.
     * @private
     */
    renderOnResize_() {
        this.calcElemPositions_();

        const {
            animationDuration: duration,
            viewport,
            nodes,
            links,
        } = this;

        viewport.selectAll('g.node')
            .data(nodes, HSGraphController.getIdFromNode)
            .transition().duration(duration)
            .attr('transform', HSGraphController.nodePosTransform);

        viewport
            .selectAll('path.link')
            .data(links, HSGraphController.getIdFromEdge)
            .transition().duration(duration)
            .attr('d', this.diagonal);

        this.setPreviousNodePos_();
    }

    /**
     * Sets x0 and y0 of node to be used for next rendering.
     * @private
     */
    setPreviousNodePos_() {
        this.nodes.forEach(node => {
            node.x0 = node.x;
            node.y0 = node.y;
        });
    }

    /**
     * Event listener for graph elements properties update event.
     * Need to update text labels, edge vs node classes and node circle radius.
     * New or removed edges or nodes are not handled here.
     * @private
     */
    onPropertiesUpdate_() {
        const {
            animationDuration: duration,
            nodes,
            viewport,
        } = this;

        nodes.forEach(HSGraphController.setNodeRadius);

        const nodeElems = viewport.selectAll('g.node');

        nodeElems
            .attr('class', this.nodeGroupClassName)
            .select('circle')
            .transition().duration(duration)
            .attr({
                class: this.nodeCircleClassName,
                r: HSGraphController.getNodeRadius,
            });

        nodeElems.select('text')
            .text(this.getNodeLabel_)
            .transition().duration(duration)
            .each(this.setNodeLabelPosition());

        const edgeElems = viewport.selectAll('path.link');

        edgeElems.attr('class', HSGraphController.edgeClassName);
    }

    /**
     * Event handler for root Item runtimeData update event. Should re-render root node
     * background color.
     * @private
     */
    onItemRuntimeDataUpdate_() {
        this.viewport.select('g.node.root circle')
            .attr('class', this.nodeCircleClassName);
    }

    /**
     * Event listener for the window resize.
     * @private
     */
    setViewportSize_() {
        const { $elem_: $elem, svg, tree } = this;

        this.width = $elem.width();
        this.height = $elem.height();

        const { width, height } = this;

        svg.attr({
            width,
            height,
        });
        tree.size([height, width - defaultNodeRadius - rootNodeRadius]);
    }

    /**
     * Event handler for the viewport size update.
     * @private
     */
    onResize_() {
        this.setViewportSize_();
        this.renderOnResize_();
    }

    /**
     * Event handler for graph's data update event.
     * @param {number} updateType
     * @private
     */
    onGraphUpdate_(updateType) {
        if (updateType < 3) {
            this.render_();
        } else if (updateType === 3) {
            this.onPropertiesUpdate_();
        }
    }

    /**
     * Event handler for active node change event to higlight activated node.
     * @param activeNodeId
     * @private
     */
    onActiveNodeChange_(activeNodeId) {
        this.activeNodeId = activeNodeId || this.graph.getRootNode().id;

        this.viewport.selectAll('g.node')
            .attr('class', this.nodeGroupClassName);
    }

    /**
     * D3 Tree layout separation function.
     * @param {HSGraphNodeConfig.parentid} parent1
     * @param {HSGraphNodeConfig.parentid} parent2
     * @returns {number}
     * @static
     */
    static nodeSeparation({ parent: parent1 }, { parent: parent2 }) {
        return parent1 === parent2 ? 1 : 1.25;
    }

    /**
     * Function to sort sibling - children of one parent.
     * @param {HSGraphNode} node1
     * @param {HSGraphNode} node2
     * @returns {number}
     * @static
     */
    static nodeOrder(node1, node2) {
        const
            name1 = node1.getName().toUpperCase(),
            name2 = node2.getName().toUpperCase();

        if (name1 === name2) {
            return 0;
        } else {
            return name1 < name2 ? -1 : 1;
        }
    }

    $onInit() {
        this.svg = d3.select(this.$elem_.get(0)).append('svg');

        this.viewport = this.svg.append('g')
            .attr({
                class: 'wrapper',
                transform: `translate(${this.margin.left},0)`,
            });

        this.tree = d3.layout.tree()
            .children(node => node.getChildren())
            .sort(HSGraphController.nodeOrder)
            .separation(HSGraphController.nodeSeparation);

        this.diagonal = d3.svg.diagonal().projection(({ x, y }) => [y, x]);

        this.setViewportSize_();
        this.setViewportSizeTimeout_ = setTimeout(this.setViewportSize_, 9);

        const { graph, item, $scope_: $scope } = this;

        graph.on('graphUpdate', this.onGraphUpdate_);
        graph.on('activeNodeChanged', this.onActiveNodeChange_);

        item.on('metricsUpdate', this.onItemRuntimeDataUpdate_);

        $scope.$on('$repaintViewport', this.onResize_);
    }

    $onDestroy() {
        clearTimeout(this.setViewportSizeTimeout_);//in case we've left immediately

        this.activeNodeId = '';

        const { nodes, links, graph, item } = this;

        nodes.length = 0;
        links.length = 0;

        graph.unbind('graphUpdate', this.onGraphUpdate_);
        graph.unbind('activeNodeChanged', this.onActiveNodeChange_);

        item.unbind('metricsUpdate', this.onItemRuntimeDataUpdate_);
    }
}

HSGraphController.$inject = [
    '$scope',
    '$element',
    '$filter',
    '$compile',
];

angular.module('aviApp').component('hsGraph', {
    bindings: {
        graph: '<',
        item: '<',
    },
    controller: HSGraphController,
    template: '<div avi-loader ng-show="$ctrl.graph.isBusy()"></div>',
});
