/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  MetricDataListCollDataSource
 * @description
 *     Data Source for collections built from metrics.
 */
angular.module('aviApp').factory('MetricDataListCollDataSource', [
'InventoryCollDataSource', 'Timeframe', 'myAccount',
(InventoryCollDataSource, Timeframe, myAccount) => {
    class MetricDataListCollDataSource extends InventoryCollDataSource {
        constructor(args) {
            super(args);

            this.setAggregationParams_();

            this.onValuesToDisplayChange_ = this.onValuesToDisplayChange_.bind(this);
            myAccount.on('valuesToDisplayChange', this.onValuesToDisplayChange_);
        }

        /**
         * @override
         */
        getRequestParams_() {
            const params = {};

            params['objectName_'] = [
                'analytics/metrics',
                'virtualservice',
                this.params_.uuid,
            ];

            params['metric_id'] = this.params_.metric_id;

            if (this.params_.obj_id) {
                params['obj_id'] = this.params_.obj_id;
            }

            params['offset_'] = this.offset_;
            params['limit_'] = Math.ceil(this.limit_ * (1 + this.owner_.overLimitCoeff_ * 2));

            params['dimension_aggregation'] = this.params_.dimension_aggregation;

            if (this.params_.dimension_limit) {
                params['dimension_limit'] = this.params_.dimension_limit;
            }

            params['step'] = Timeframe.selected().step;
            params['limit'] = this.params_.limit || Timeframe.selected().limit;

            return params;
        }

        /** @override */
        onTimeframeChange_() {
            this.setStepAndLimitParams_();
            this.owner_.load(undefined, true);
        }

        /**
         * Called when valuesToDisplay of myAccount is changed.
         */
        onValuesToDisplayChange_() {
            this.setAggregationParams_();
            this.owner_.load(undefined, true);
        }

        /**
         * Sets params based on valuesToDisplay
         */
        setAggregationParams_() {
            const params = {
                dimension_aggregation: undefined,
                limit: undefined,
            };

            if (myAccount.uiProperty.valuesToDisplay === 'current') {
                params.limit = 1;
                params.dimension_aggregation = 'avg';
            } else {
                params.dimension_aggregation = myAccount.uiProperty.valuesToDisplay;
            }

            this.setParams(params);
        }

        /** @override */
        destroy(force) {
            myAccount.unbind('valuesToDisplayChange', this.onValuesToDisplayChange_);
            super.destroy(force);
        }
    }

    angular.extend(MetricDataListCollDataSource.prototype, {
        hasTotalNumberOfItems: false,
        hasSearch: false,
        hasSorting: false,
        hasPagination: true,
        defaultParams_: {},
    });

    return MetricDataListCollDataSource;
}]);
