/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';
import {
    RoleFilterConfigItem,
} from 'ajs/modules/accounts/factories/role/role-filter.config-item.factory';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './role-filter-grid.l10n';

import './role-filter-grid.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Role filter component for displaying label filters.
 *
 * @author Nisar Nadaf
 */

@Component({
    selector: 'role-filter-grid',
    templateUrl: './role-filter-grid.component.html',
})
export class RoleFilterGridComponent implements AfterViewInit, OnInit {
    /**
     * RoleFilter RepeatedMessageItem instance.
     */
    @Input()
    public roleFilters: RepeatedMessageItem<RoleFilterConfigItem>;

    /**
     * RoleFilter object type
     */
    @Input()
    public objectType: string;

    /**
     * RoleFilter FieldName.
     */
    @Input()
    public fieldName: string;

    /**
     * Fires on add Role Label Filter.
     */
    @Output()
    public onAddRoleFilter = new EventEmitter();

    /**
     * Fires on remove Role Label Filter.
     */
    @Output()
    public onRemoveRoleFilter = new EventEmitter<RoleFilterConfigItem>();

    /**
     * Fires on edit Role Label Filter.
     */
    @Output()
    public onEditRoleFilter = new EventEmitter<RoleFilterConfigItem>();

    /**
     * Template ref for role filter enabled grid cell.
     */
    @ViewChild('roleFilterEnabledTemplateRef')
    public roleFilterEnabledTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref for role filter value grid cell.
     */
    @ViewChild('roleFilterValueTemplateRef')
    public roleFilterValueTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Role Label Filter grid config.
     */
    public roleFilterGridConfig: IAviDataGridConfig;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Stores max count for values.
     */
    private valueMaxCount: number;

    constructor(
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.valueMaxCount = this.schemaService
            .getFieldMaxElements(this.objectType, this.fieldName);
    }

    /**
     * @override
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            l10nKeys,
            schemaService,
        } = this;

        this.roleFilterGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleCriteria),
                    id: 'criteria',
                    transform(roleFilter: RoleFilterConfigItem) {
                        return schemaService.getEnumValue('RoleFilterMatchOperation',
                            roleFilter.config.match_operation).label;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleKey),
                    id: 'key',
                    transform(roleFilter: RoleFilterConfigItem) {
                        return roleFilter.key;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleValue),
                    id: 'value',
                    templateRef: this.roleFilterValueTemplateRef,
                },
                {
                    label: l10nService.getMessage(l10nKeys.columnTitleEnabled),
                    id: 'enabled',
                    templateRef: this.roleFilterEnabledTemplateRef,
                },
            ],
            multipleactions: [{
                label: this.l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                onClick: (labelFilterList: RoleFilterConfigItem[]) => {
                    labelFilterList
                        .forEach((labelFilter: RoleFilterConfigItem) => {
                            this.removeRoleFilter(labelFilter);
                        });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (labelFilter: RoleFilterConfigItem) => {
                    this.editRoleFilter(labelFilter);
                },
            }, {
                label: this.l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (labelFilter: RoleFilterConfigItem) => {
                    this.removeRoleFilter(labelFilter);
                },
            }],
        };
    }

    /**
     * Handler for role filter add operation.
     */
    public addRoleFilter(): void {
        this.onAddRoleFilter.emit();
    }

    /**
     * Returns true if filter count reaches max allowed.
     */
    public get disableAddButton(): boolean {
        return this.roleFilters.count >= this.valueMaxCount;
    }

    /**
     * Returns tooltip text to show for value column.
     */
    public valuesTooltip(row: RoleFilterConfigItem): string[] {
        return row.values.slice(1);
    }

    /**
     * Handler for role filter remove operation.
     */
    private removeRoleFilter(roleFilterItem: RoleFilterConfigItem): void {
        this.onRemoveRoleFilter.emit(roleFilterItem);
    }

    /**
     * Handler for role filter edit operation.
     */
    private editRoleFilter(roleFilterItem: RoleFilterConfigItem): void {
        this.onEditRoleFilter.emit(roleFilterItem);
    }
}
