/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { JwtMatchConfigItem } from 'ajs/modules/match/factories/jwt-match.config-item.factory';
import * as l10n from './jwt-match-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for jwt-match.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'jwt-match-readonly',
    templateUrl: './jwt-match-readonly.component.html',
})
export class JwtMatchReadonlyComponent {
    /**
     * Binding for JwtMatchConfigItem.
     */
    @Input()
    public editable: JwtMatchConfigItem;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get token name.
     */
    public get tokenName(): string {
        return this.editable.config.token_name;
    }

    /**
     * Getter for jwtClaimMatches.
     */
    public get claims(): string[] {
        const { config: jwtClaimMatchConfigItems } = this.editable.matches;

        return jwtClaimMatchConfigItems.map(String);
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
