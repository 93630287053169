/**
 * @module NetworkModule
 */

/***************************************************************************
* ========================================================================
* Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
* ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { NetworkProfileUnion } from 'object-types';

import {
    INetworkProfileUnion,
    ISCTPFastPathProfile,
    ISCTPProxyProfile,
    ITCPProxyProfile,
    IUDPProxyProfile,
    ProtocolType,
} from 'generated-types';

import {
    TCPFastPathProfileConfigItem,
    UDPFastPathProfileConfigItem,
} from '..';

import {
    ProtocolTypes,
} from './network-profile.item.types';

/**
 * Interface for Network Profile Union Partial.
 */
type TNetworkProfileUnionPartial =
    Omit<INetworkProfileUnion,
    'tcp_fast_path_profile' |
    'tcp_proxy_profile' |
    'udp_fast_path_profile' |
    'udp_proxy_profile' |
    'sctp_proxy_profile' |
    'sctp_fast_path_profile'
    >;

/**
 * Interface for Extended Network Profile Union.
 */
export interface IExtendedNetworkProfileUnion extends TNetworkProfileUnionPartial {
    tcp_fast_path_profile?: TCPFastPathProfileConfigItem;
    tcp_proxy_profile?: MessageItem<ITCPProxyProfile>;
    type?: ProtocolType;
    udp_fast_path_profile?: UDPFastPathProfileConfigItem;
    udp_proxy_profile?: MessageItem<IUDPProxyProfile>;
    sctp_proxy_profile?: MessageItem<ISCTPProxyProfile>;
    sctp_fast_path_profile?: MessageItem<ISCTPFastPathProfile>;
}

/**
 * @description
 *      Message Item class for setting up Network Profile Union Type.
 * @author Ratan Kumar
 */
export class NetworkProfileUnionConfigItem extends MessageItem<IExtendedNetworkProfileUnion> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: NetworkProfileUnion,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Reset Network profile on type change.
     */
    public resetNetworkProfileType(selectedType: string): void {
        const propsToDrop = [];
        const type = ProtocolTypes[this.config.type];

        const {
            PROTOCOL_TYPE_TCP_FAST_PATH,
            PROTOCOL_TYPE_TCP_PROXY,
            PROTOCOL_TYPE_UDP_FAST_PATH,
            PROTOCOL_TYPE_UDP_PROXY,
            PROTOCOL_TYPE_SCTP_FAST_PATH,
            PROTOCOL_TYPE_SCTP_PROXY,
        } = ProtocolTypes;

        switch (type) {
            case PROTOCOL_TYPE_TCP_FAST_PATH:

                propsToDrop.push(
                    'tcp_proxy_profile',
                    'udp_fast_path_profile',
                    'udp_proxy_profile',
                    'sctp_fast_path_profile',
                    'sctp_proxy_profile',
                );

                break;

            case PROTOCOL_TYPE_TCP_PROXY:

                propsToDrop.push(
                    'tcp_fast_path_profile',
                    'udp_fast_path_profile',
                    'udp_proxy_profile',
                    'sctp_fast_path_profile',
                    'sctp_proxy_profile',
                );

                break;

            case PROTOCOL_TYPE_UDP_FAST_PATH:

                propsToDrop.push(
                    'tcp_proxy_profile',
                    'tcp_fast_path_profile',
                    'udp_proxy_profile',
                    'sctp_fast_path_profile',
                    'sctp_proxy_profile',
                );

                break;

            case PROTOCOL_TYPE_UDP_PROXY:

                propsToDrop.push(
                    'tcp_proxy_profile',
                    'tcp_fast_path_profile',
                    'udp_fast_path_profile',
                    'sctp_fast_path_profile',
                    'sctp_proxy_profile',
                );

                break;

            case PROTOCOL_TYPE_SCTP_FAST_PATH:
                propsToDrop.push(
                    'sctp_proxy_profile',
                    'tcp_fast_path_profile',
                    'tcp_proxy_profile',
                    'udp_fast_path_profile',
                    'udp_proxy_profile',
                );

                break;

            case PROTOCOL_TYPE_SCTP_PROXY:
                propsToDrop.push(
                    'sctp_fast_path_profile',
                    'tcp_fast_path_profile',
                    'tcp_proxy_profile',
                    'udp_fast_path_profile',
                    'udp_proxy_profile',
                );

                break;
        }

        propsToDrop.forEach(propName => delete this.config[propName]);

        this.safeSetNewChildByField(selectedType);
    }
}
