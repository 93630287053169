/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'modal-ip-addr-list-edit';
const prefix = `${moduleName}.${componentName}`;

export const addSubnetBtnLabel = `${prefix}.addSubnetBtnLabel`;
export const addModifyStaticIpSubnetHeader = `${prefix}.addModifyStaticIpSubnetHeader`;
export const ipSubnetInputLabel = `${prefix}.ipSubnetInputLabel`;
export const networkIpSubnetsHeader = `${prefix}.networkIpSubnetsHeader`;
export const columnTitleIpSubnet = `${prefix}.columnTitleIpSubnet`;
export const columnTitleType = `${prefix}.columnTitleType`;
export const columnTitleIpAddressPool = `${prefix}.columnTitleIpAddressPool`;
export const actionBtnDelete = `${prefix}.actionBtnDelete`;
export const actionBtnEdit = `${prefix}.actionBtnEdit`;
export const discoveredLabel = `${prefix}.discoveredLabel`;
export const configuredLabel = `${prefix}.configuredLabel`;

export const ENGLISH = {
    [addSubnetBtnLabel]: 'Add Subnet',
    [addModifyStaticIpSubnetHeader]: 'Add/Modify Static IP Subnet',
    [ipSubnetInputLabel]: 'IP Subnet',
    [networkIpSubnetsHeader]: 'Network IP Subnets',
    [columnTitleIpSubnet]: 'IP Subnet',
    [columnTitleType]: 'Type',
    [columnTitleIpAddressPool]: 'IP Address Pool',
    [actionBtnDelete]: 'Delete',
    [actionBtnEdit]: 'Edit',
    [discoveredLabel]: 'Discovered',
    [configuredLabel]: 'Configured',
};
