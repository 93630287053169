/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './event-chart.less';
import {
    select as d3Select,
} from 'd3';

const d3 = {
    select: d3Select,
};

/**
 * Event is displayed as icon inside chart.
 * @typedef {Object} ChartEvent
 * @property {string} name
 * @property {string} icon
 * @property {boolean} selected
 * @property {boolean} visible
 * @property {number} timestamp
 * @property {number} count
 * @property {number} value
 */

class EventChart {
    /**
     * Creates object that will represent event point on the chart.
     * @param {string} title - Human readable name for event type.
     * @param {string} name - Predefined names for each event type.
     * @param {string} icon - CSS class to use for event icon.
     * @returns {ChartEvent}
     */
    static createEventType(title, name, icon) {
        return {
            title,
            name,
            icon,
            selected: false,
            visible: true,
            timestamp: 0,
            count: 0,
            value: 0,
        };
    }

    constructor(MetricChart, $element, $scope, eventChartService) {
        this.MetricChart = MetricChart;
        this.$element = $element;
        this.$scope = $scope;
        this.eventChartService = eventChartService;

        /** @type {ng.element} */
        this.chartContainer = null;

        /** @type {Chart} */
        this.chart = null;

        /** @type {ng.element} */
        this.eventsContainer = null;

        /** @type {Metric} */
        this.lineMetric = null;

        /** @type {Metric[]} */
        this.stackMetrics = null;

        /** @type {string} */
        this.chartTitle = '';

        /** @type {?ChartSync} */
        this.sync = null;

        /** @type {string} */
        this.chartType = MetricChart.TYPE_LINE;

        /**
         * Hashmap to store events at each timestamp.
         * @type {Object<number, Object>}
         */
        this.eventsHash = {};

        /** @type {ChartEvent[]} */
        this.eventTypes = [
            EventChart.createEventType('Anomaly Event', 'anomalies', 'icon-flash'),
            EventChart.createEventType('System Event', 'system', 'icon-system'),
            EventChart.createEventType('Alert Event', 'alerts', 'icon-bell'),
            EventChart.createEventType('Config Event', 'config', 'icon-cog'),
        ];

        this.eventSeries = [{
            name: 'aggConfigEventSeries',
            event: this.eventTypes[3],
        }, {
            name: 'aggNonConfigEventSeries',
            event: this.eventTypes[1],
        }];

        /**
         * Average value to display inside the chart.
         * @type {number}
         */
        this.average = 0;

        /**
         * Display event icon toggle controls.
         * @type {boolean}
         */
        this.showControls = true;

        this.defaultPadding = {
            left: 70,
            bottom: 25,
            right: 25,
            top: 10,
        };

        this.padding = this.defaultPadding;

        this.lineMetricChange = this.lineMetricChange.bind(this);
        this.stackMetricsChange = this.stackMetricsChange.bind(this);
        this.drawAverage = this.drawAverage.bind(this);
        this.resize = this.resize.bind(this);
        this.renderEvents = this.renderEvents.bind(this);
        this.typeChange = this.typeChange.bind(this);
        this.update = this.update.bind(this);
    }

    $onInit() {
        const { MetricChart, $scope, $element, sync } = this;

        this.chartContainer = $element.find('.chart-container');
        this.eventsContainer = $element.find('.chart-events-container');

        const width = this.getWidth();
        const height = this.getHeight();
        const padding = angular.extend(this.defaultPadding, this.padding);

        this.eventsContainer.css({
            left: padding.left,
            top: padding.top,
        });

        this.chart = new MetricChart({
            width,
            height,
            padding,
            axis: {
                x: {
                    type: 'time',
                },
                y1: {
                    visible: true,
                },
            },
        });

        const { chart } = this;

        chart.render(this.chartContainer.get(0));
        chart.setType(this.chartType);
        chart.on(MetricChart.METRIC_STACK_RENDER, this.update);
        chart.on(MetricChart.METRIC_LINE_RENDER, this.update);

        if (sync) {
            sync.add(chart);
        }

        $scope.$watch(() => this.lineMetric, this.lineMetricChange);
        $scope.$watch(() => this.stackMetrics, this.stackMetricsChange);
        $scope.$watch(() => this.average, this.drawAverage);
        $scope.$watch(() => this.chartType, this.typeChange);
        $scope.$on('$repaintViewport', this.resize);

        this.lineMetricChange(this.lineMetric);
        this.stackMetricsChange(this.stackMetrics);
    }

    $onDestroy() {
        this.eventChartService.hideGrid();

        if (this.chart) {
            this.chart.destroy();

            if (this.sync) {
                this.sync.remove(this.chart);
            }
        }
    }

    /**
     * Handles Metric instance change.
     * @param {?Metric} newMetric
     * @param {?Metric=} oldMetric
     */
    lineMetricChange(newMetric, oldMetric) {
        if (newMetric && newMetric !== oldMetric) {
            this.chart.setLineMetric(newMetric);

            const { supplData } = newMetric;

            supplData.on('configEventsUpdate', this.renderEvents);
            supplData.on('nonConfigEventsUpdate', this.renderEvents);
            this.renderEvents();
        }
    }

    /**
     * Handles metrics array change for stack chart.
     * @param {Metric[]} newMetrics
     * @param {Metric[]} oldMetrics
     */
    stackMetricsChange(newMetrics, oldMetrics) {
        if (Array.isArray(newMetrics) && newMetrics !== oldMetrics) {
            this.chart.setStackMetric(newMetrics);
        }
    }

    getWidth() {
        return this.chartContainer.width();
    }

    getHeight() {
        return this.chartContainer.height();
    }

    /**
     * Updates chart based on current Metric instance.
     */
    update() {
        const { lineMetric, stackMetrics, chart } = this;
        const { lineSeries0, lineSeries1, stackSeries } = chart;

        if (lineMetric && this.isLineChart()) {
            if (Array.isArray(lineSeries0) && lineSeries0.length > 0) {
                this.leftAxisLabel = lineSeries0[0].unitsLong;
            }

            if (Array.isArray(lineSeries1) && lineSeries1.length > 0) {
                this.rightAxisLabel = lineSeries1[0].unitsLong;
            } else {
                this.rightAxisLabel = '';
            }
        } else if (stackMetrics && this.isStackChart()) {
            if (Array.isArray(stackSeries)) {
                this.leftAxisLabel = stackSeries[0].unitsLong;
            }

            this.rightAxisLabel = '';
        }
    }

    isStackChart() {
        const { chartType, MetricChart } = this;

        return chartType === MetricChart.TYPE_STACK;
    }

    isLineChart() {
        const { chartType, MetricChart } = this;

        return chartType === MetricChart.TYPE_LINE;
    }

    /**
     * Displays average line from metric instance.
     * @param {number} newAvg
     * @param {number} oldAvg
     */
    drawAverage(newAvg, oldAvg) {
        if (newAvg !== oldAvg) {
            this.chart.drawAverage(+newAvg);
        }
    }

    typeChange(type) {
        this.chart.setType(type);
    }

    /**
     * Renders event icons on chart.
     */
    renderEvents() {
        const { eventChartService, lineMetric, eventsHash: eHash } = this;

        if (!lineMetric) {
            return;
        }

        this.eventSeries.forEach(evt => {
            const series = lineMetric.supplData[evt.name];
            const { values } = series;

            if (angular.isArray(values)) {
                values.forEach(v => {
                    if (v.value > 0) {
                        const t = v.timestamp;
                        const { event } = evt;
                        const evtCopy = angular.copy(event);
                        const { name } = evtCopy;

                        eHash[t] = eHash[t] || {};

                        if (!(name in eHash[t])) {
                            eHash[t][name] = evtCopy;
                        }

                        const ext = eHash[t][name];

                        ext.timestamp = t;
                        ext.value = v.value;
                        ext.count = series.getValue('sum') || 0;
                    }
                });
            }
        });

        const [series] = lineMetric.getSeries();
        const p1 = series.getFirstPoint();
        const p2 = series.getLatestPoint();

        if (!p1 || !p2) {
            return;
        }

        const { timestamp: minTS } = p1;
        const { timestamp: maxTS } = p2;
        // create group of events for each timestamp
        const eventGroups = [];
        const eventsHashKeys = Object.keys(this.eventsHash);

        _.each(eventsHashKeys, ts => {
            if (ts >= minTS && ts <= maxTS) {
                const keys = Object.keys(this.eventsHash[ts]);

                if (keys.length) {
                    const events = keys.map(evtName => this.eventsHash[ts][evtName]);

                    eventGroups.push({
                        timestamp: +ts,
                        events,
                    });
                }
            }
        });

        const container = d3.select(this.eventsContainer.get(0));
        const iconGroups = container
            .selectAll('div')
            .data(eventGroups);

        iconGroups
            .enter()
            .append('div')
            .attr('class', 'event-icons');
        iconGroups
            .exit()
            .remove();

        const { xScale, y0Scale: yScale } = this.chart;

        const iconGroup = container.selectAll('div.event-icons');

        iconGroup
            .attr('style', d => `left: ${xScale(d.timestamp)}px; top: ${yScale(0)}px`)
            .attr('class', 'event-icons');

        const icons = iconGroup
            .selectAll('div')
            .data(d => d.events);

        icons
            .enter()
            .append('div')
            .attr('class', 'event-icon')
            .append('i');
        icons
            .exit()
            .remove();

        container
            .selectAll('div.event-icon')
            .on('click', null)
            .attr('class', d => `event-icon ${d.name}`)
            .attr('title', d => `${d.title}`)
            .classed('hidden', d => !d.visible || false)
            .classed('selected', d => d.selected)
            .on('click', function(d) {
                const sel = d3.select(this);

                d.selected = !d.selected;
                container
                    .selectAll('div.event-icon.selected')
                    .classed('selected', false);
                sel.classed('selected', d.selected);

                if (d.selected) {
                    eventChartService.showGrid();
                } else {
                    eventChartService.hideGrid();
                }

                eventChartService.update(d.name, d.timestamp);
            })
            .select('i')
            .attr('class', d => d.icon);
    }

    /**
     * Handles event icon checkbox state change.
     */
    iconDisplayHandler() {
        this.eventTypes.forEach(event => {
            this.eventsContainer
                .find(`.event-icon.${event.name}`)
                .toggleClass('hidden', !event.visible);
        });
    }

    resize() {
        setTimeout(() => {
            this.chart.resize(this.getWidth(), this.getHeight());
            this.renderEvents();
        });
    }
}

EventChart.$inject = [
    'MetricChart',
    '$element',
    '$scope',
    'eventChartService',
];

/**
 * @ngdoc component
 * @name eventChart
 * @description
 *      Line chart with alert, anomalies system and config events display selectors.
 * @param stackMetrics {Metric[]} - Metrics used to draw stacked line chart.
 * @param lineMetric {Metric} - Metric used for draw single lines.
 * @param chartType {string} - "stack" or "line".
 * @param chartTitle {string} - Header title for the chart.
 * @param sync {ChartSync=} - Optional ChartSync instance.
 * @param average {number} - Average value to display as horizontal line.
 * @param showControls {boolean} - Show or hide event icons toggle controls.
 */
angular.module('aviApp').component('eventChart', {
    bindings: {
        stackMetrics: '<?',
        lineMetric: '<?',
        chartType: '<?',
        chartTitle: '<?',
        sync: '<?',
        average: '<?',
        showControls: '@?',
        padding: '<?',
    },
    controller: EventChart,
    templateUrl: 'src/components/common/charts/event-chart/event-chart.html',
});
