/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    HSMGroupCollection,
} from 'ajs/modules/security/factories/hsm-group/hsm-group.collection.factory';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { HSM_GROUP_COLLECTION_TOKEN } from 'ajs/modules/security/security.tokens';

import {
    HsmGroupDownloadCertificateModalService,
} from 'ng/modules/security/services/hsm-group-download-certificate-modal.service';

import { HSMType } from 'generated-types';
import * as globalL10n from 'global-l10n';
import * as l10n from './hsm-group-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type THSMGroupCollection = typeof HSMGroupCollection;

/**
 * @description Hsm Group List Page.
 *
 * @author Manideep Kotha
 */

@Component({
    selector: 'hsm-group-list-page',
    templateUrl: './hsm-group-list.component.html',
})
export class HsmGroupListComponent {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display HSM Group collection.
     */
    public hsmGroupListGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the HSM Group collection.
     */
    private readonly hsmGroupCollection: HSMGroupCollection;

    constructor(
        private readonly l10nService: L10nService,
        public readonly schemaService: SchemaService,
        @Inject(HSM_GROUP_COLLECTION_TOKEN)
        private readonly HSMGroupCollection: THSMGroupCollection,
        // eslint-disable-next-line max-len
        private readonly hsmGroupDownloadCertificateModalService: HsmGroupDownloadCertificateModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.hsmGroupCollection = new HSMGroupCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.hsmGroupCollection;

        this.hsmGroupListGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.hsmGroupCollection,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                    sortBy: 'name',
                },
                {
                    id: 'type',
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: (row: IAviCollectionDataGridRow) => {
                        return this.schemaService.getEnumValueLabel(
                            'HSMType',
                            row.data.config.hsm.config.type,
                        );
                    },
                },
            ],
            singleactions: [{
                id: 'client-cert-download',
                label: this.l10nService.getMessage(
                    l10nKeys.actionBtnOpenWindowForClientCertDownload,
                ),
                // TODO below icon will be changed/updated based on Andrew(UX) feedback.
                shape: 'certificate',
                onClick: ({ data }: IAviCollectionDataGridRow) => {
                    const nodeInfo = data.config.hsm.safenetLuna.clientInfoList;

                    this.hsmGroupDownloadCertificateModalService
                        .openModal(data.config.name, nodeInfo);
                },
                hidden: row => row.data.config.hsm.config.type !== HSMType.HSM_TYPE_SAFENET_LUNA,
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },

            getRowId: (index: number, row: IAviCollectionDataGridRow): string => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.hsmGroupCollection.destroy();
    }
}
