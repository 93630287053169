/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { Injector } from '@angular/core';

import {
    LatestWafCrsVersionService,
} from 'ajs/modules/waf/services/latest-waf-crs-version.service';

import {
    WafCrsCollection,
    WAF_CRS_COLLECTION_TOKEN,
} from 'ajs/modules/waf/factories/waf-crs.collection.factory';

import {
    WafProfileCollection,
} from 'ajs/modules/waf/factories/waf-profile/waf-profile.collection.factory';

import {
    WafPolicyCollection,
} from 'ajs/modules/waf/factories/waf-policy/waf-policy.collection.factory';

import {
    LATEST_WAF_CRS_VERSION_SERVICE_TOKEN,
    WAF_POLICY_COLLECTION_TOKEN,
    WAF_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/waf/waf.tokens';

const $injector = '$injector';

export const wafProfileCollectionProvider = {
    deps: [$injector],
    provide: WafProfileCollection,
    useFactory(injector: Injector): typeof WafProfileCollection {
        return injector.get(WAF_PROFILE_COLLECTION_TOKEN);
    },
};

export const wafCrsCollectionProvider = {
    deps: [$injector],
    provide: WafCrsCollection,
    useFactory(injector: Injector): typeof WafCrsCollection {
        return injector.get(WAF_CRS_COLLECTION_TOKEN);
    },
};

export const latestWafCrsVersionServiceProvider = {
    deps: [$injector],
    provide: LatestWafCrsVersionService,
    useFactory(injector: Injector): LatestWafCrsVersionService {
        return injector.get(LATEST_WAF_CRS_VERSION_SERVICE_TOKEN);
    },
};

export const wafPolicyCollectionProvider = {
    deps: [$injector],
    provide: WafPolicyCollection,
    useFactory(injector: Injector): typeof WafPolicyCollection {
        return injector.get(WAF_POLICY_COLLECTION_TOKEN);
    },
};
