/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @type Function
 * @param {string} val - Subnet string.
 * @description
 *     Returns a subnet object given a subnet string with format '1.2.3.4/24'. If format doesn't
 *     match, returns undefined.
 */
//TODO convert to factory, add Regex checks and return undefined for faulty ones. Use getIpAddr.
const getSubnetObject = val => {
    let output = val;

    if (angular.isString(val) && val.split('/').length === 2) {
        const subnet = val.split('/');

        output = {
            ip_addr: {
                addr: subnet[0],
                type: 'V4',
            },
            mask: +subnet[1] || 0,
        };
    }

    return output;
};

angular.module('aviApp').constant('getSubnetObject', getSubnetObject);
