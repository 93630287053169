/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name KeystoneIsEnabledCollDataSource
 * @description
 *
 *     Regular CollDataSource, only difference is the fact there is no need to load it when
 *     collection has no entries.
 *
 **/
angular.module('aviApp').factory('KeystoneIsEnabledCollDataSource', [
'CollDataSource', function(CollDataSource) {
    return class KeystoneIsEnabledCollDataSource extends CollDataSource {
        /** @override */
        isInactive() {
            return !this.owner_.getNumberOfItems();
        }
    };
}]);
