/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    FilterOperatorType,
    IVsLogsCombinedRequestUpdatableParams,
    IVsLogsDefaultSignpostData,
} from 'ng/modules/vs-logs/vs-logs.types';

import {
    customTemplates,
    defaultSignpostParams,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import {
    VirtualServiceStateService,
} from 'ng/modules/virtual-service/services/virtual-service-state.service';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-custom-groups.component.less';
import * as l10n from './vs-logs-signpost-custom-groups.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Signpost component for policy and ssl section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-groups',
    templateUrl: './vs-logs-signpost-custom-groups.component.html',
})
export class VsLogsSignpostCustomGroupsComponent implements OnInit, AfterViewInit {
    /**
     * Selected signpost config key.
     */
    @Input()
    public configKey: string;

    /**
     * Datagrid template for column name.
     */
    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for column progress bar.
     */
    @ViewChild('progressBarFieldTemplateRef')
    public progressBarFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Reference to the virtual service item instance.
     */
    public readonly vs: any; // TODO AV-159345 update type.

    public groupsGridConfig: IAviDataGridConfig;

    public readonly FilterOperatorType = FilterOperatorType;

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        vsStateService: VirtualServiceStateService,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);

        this.vs = vsStateService.virtualService;
    }

    /** @override */
    public ngOnInit(): void {
        // TODO: AV-164376 figure out correct place for that.
        const fullSignpostParams = this.configKey === customTemplates.policy ?
            this.getPolicyRequestParams() : this.getSslRequestParams();

        this.vsLogsSignpostStore.getVsLogSignpostCombinedData(fullSignpostParams);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            configKey,
        } = this;

        this.groupsGridConfig = {
            getRowId: (index: number): number => index,
            isGridExpandable: true,
            layout: {
                expandGridByDefault: true,
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: templatesConfiguration[configKey].name,
                    id: 'name',
                    templateRef: this.nameFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(
                        l10nKeys.logsNumberLabel,
                    ),
                    id: 'logs',
                    transform: (row: IVsLogsDefaultSignpostData): number => row.count,
                },
                {
                    label: l10nService.getMessage(l10nKeys.logsPercentageLabel),
                    id: 'percentage',
                    transform: (row: IVsLogsDefaultSignpostData): string => `${row.percentage}%`,
                },
                {
                    label: '',
                    id: 'progress-bar',
                    templateRef: this.progressBarFieldTemplateRef,
                },
            ],
        };
    }

    public trackbyIndex(index: number): number {
        return index;
    }

    /**
     * Get request params for policy signpost.
     */
    private getPolicyRequestParams(): IVsLogsCombinedRequestUpdatableParams[] {
        const configKey = customTemplates.policy;
        const template = templatesConfiguration[configKey];
        const { query } = template;
        const { http_policies: httpPolicies } = this.vs.getConfig();
        const fullRequestsParams: IVsLogsCombinedRequestUpdatableParams[] = [];

        Object.entries(query).forEach(([key, value]) => {
            const groupby = value as string;
            const vsPolicy = httpPolicies[0] && httpPolicies[0].http_policy_set_ref_data &&
                httpPolicies[0].http_policy_set_ref_data[groupby.slice(0, -10)];

            if (vsPolicy && Array.isArray(vsPolicy.rules)) {
                fullRequestsParams.push({
                    requestId: key,
                    requestParams: {
                        ...defaultSignpostParams,
                        groupby,
                    },
                });
            }
        });

        return fullRequestsParams;
    }

    /**
     * Get request params for ssl signpost.
     */
    private getSslRequestParams(): IVsLogsCombinedRequestUpdatableParams[] {
        const configKey = customTemplates.ssl;
        const template = templatesConfiguration[configKey];
        const { query } = template;
        const fullRequestsParams: IVsLogsCombinedRequestUpdatableParams[] = [];

        Object.entries(query).forEach(([key, value]) => {
            const groupby = value as string;

            fullRequestsParams.push({
                requestId: key,
                requestParams: {
                    ...defaultSignpostParams,
                    groupby,
                },
            });
        });

        return fullRequestsParams;
    }
}
