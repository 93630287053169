/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'error-page';
const componentName = 'error-page-profile-grid';
const prefix = `${moduleName}.${componentName}`;

export const addLabel = `${prefix}.addLabel`;
export const statusCodeLabel = `${prefix}.statusCodeLabel`;
export const editLabel = `${prefix}.editLabel`;
export const indexLabel = `${prefix}.indexLabel`;
export const removeLabel = `${prefix}.removeLabel`;
export const enableLabel = `${prefix}.enableLabel`;
export const errorPageOrUrlLabel = `${prefix}.errorPageOrUrlLabel`;
export const errorPageLabel = `${prefix}.errorPageLabel`;
export const htmlBodyLabel = `${prefix}.htmlBodyLabel`;
export const redirectLabel = `${prefix}.redirectLabel`;

export const ENGLISH = {
    [addLabel]: 'Add',
    [statusCodeLabel]: 'Status Codes',
    [editLabel]: 'Edit',
    [indexLabel]: 'Index',
    [removeLabel]: 'Remove',
    [enableLabel]: 'Enable',
    [errorPageOrUrlLabel]: 'Error Page or URL',
    [errorPageLabel]: 'Error Page',
    [htmlBodyLabel]: 'HTML Body',
    [redirectLabel]: 'Redirect',
};
