/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/stacked-end-to-end.less';

angular.module('aviApp').directive('stackedEndToEnd', ['$timeout',
function($timeout) {
    return {
        scope: {
            data: '=',
            max: '=',
            small: '=',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/stacked-end-to-end.html',
        link(scope, elm) {
            elm.addClass('stacked-end-to-end');

            if (scope.small) { scope.barGap = 2; }

            scope.$watch('data.endToEnd', function() {
                if (!scope.data) { return; }

                scope.data.barSettings = scope.data.barSettings || {};
                main();
            });
            scope.$on('resizeStackedEndToEnd', function() {
                main();
            });

            scope.$on('assignTitles', function() {
                main();
            });

            scope.$on('$destroy', function() {
                if (scope.timer) { $timeout.cancel(scope.timer); }
            });

            scope.drilldown = {};

            scope.showDrilldownCheck = function(bar) {
                if (bar.metric === 'page_downloading_time') {
                    // tells parent scope to show or hide the drilldrown
                    scope.$emit('toggleDrilldown');
                }
            };

            function main() {
                const { data } = scope;

                if (!data || !data.endToEnd) { return; }

                const main = data.endToEnd;

                scope.data.max = scope.max || _.reduce(main, function(memo, d) {
                    if (memo > d.value) { return memo; } else { return d.value; }
                }, 0);

                const max = _.max(main, function(d) { return d.value; }).value;

                for (let i = 0; i < main.length; i++) {
                    const m = main[i];
                    const next = main[i + 1];

                    m.width = m.value / max * 100;
                    // just doing some scalin', don't even worry
                    if (next) {
                        m.availableTitleWidth =
                            (m.value - next.value) * (100 / max) * (100 / m.width);
                    } else m.availableTitleWidth = 100;

                    if (m.metric === 'page_downloading_time') scope.drilldown.width = m.width;
                }

                // using timeout so that we complete digest loop and are able to actually
                // use classes and widths and all that good stuff
                if (!scope.small) { scope.timer = $timeout(assignTitle); }
            }

            const removeOldTitles = function() {
                _.each(scope.data.endToEnd, function(m) {
                    m.viewableTitle = '';
                });
            };

            const assignTitle = function() {
                removeOldTitles();
                if (scope.data.barSettings && scope.data.barSettings.height === 5) return;
                _.each(scope.data.endToEnd, function(m) {
                    const el = elm.find(`.title-bar-${m.metric}`);

                    m.pixelWidth = el.width();

                    // empty string for resizing
                    const titles = ['', m.title];

                    if (m.value) { titles.push(`${m.title}: ${m.value.toFixed(1)}ms`); }

                    _.each(titles, function(t) {
                        let w = findTextWidth(t, elm);

                        if (m.metric === 'page_downloading_time') {
                            w += 20;
                        }

                        if (w < m.pixelWidth) m.viewableTitle = t;
                    });
                });
            };

            const findTextWidth = function(text, elm) {
                const el = $(
                    `<span style = "position: relative; left: -9999px; white-space: nowrap;">${
                        text}</span>`,
                );

                $(elm).append(el);

                const width = (el.width() || -12) + 12;

                el.remove();

                return width;
            };
        },
    };
}]);
