/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { VirtualServiceCollection } from 'ajs/modules/vs';

interface IGslbVsCollectionArgs {
    gslbSiteId?: string;
    gslbTenant?: string;
    limit?: number;
    params?: object;
}

/**
 * @description
 *
 *     Syntax sugar to set GSLB specific headers through constructor params.
 *     Loads VSes of local as well as remote controllers.
 *
 * @author Ram Pal
 */
export class GSLBVSCollection extends VirtualServiceCollection {
    constructor(args: IGslbVsCollectionArgs = {}) {
        const extendedArgs = {
            isStatic: true,
            objectName: 'virtualservice',
            ...args,
        };

        super(extendedArgs);

        const {
            gslbSiteId,
            gslbTenant,
        } = args;

        const headerParams = this.getParams('headers_') || {};

        if (gslbSiteId) {
            headerParams['X-Avi-Internal-GSLB'] = gslbSiteId;
        }

        if (gslbTenant) {
            headerParams['X-Avi-Tenant'] = gslbTenant;
        }

        this.setParams({ headers_: headerParams });
    }
}
