/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnDestroy,
    OnInit,
    Type,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    AviDropdownButtonPosition,
    IAviDropdownButtonAction,
} from 'ng/shared/components/avi-dropdown-button';

import { attachComponentBindings } from 'ng/shared/utils';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { PolicyMatchConfigItem } from 'ajs/modules/policies';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import * as l10n from './match-adder.l10n';
import './match-adder.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export interface IMatchOption {
    label: string;
    subLabel?: string;
    objectType?: string;
    fieldName?: string;
    component: Type<Component>;
    componentBindings?: Record<string, any>;
}

/**
 * @description
 *     This component allows for developers to pass in a list of IMatchOptions for rendering match
 *     components. Users are able to add matches from the dropdown button and remove them. Matches
 *     that have been added will be removed from the dropdown button options, unless they're
 *     repeated. Repeated matches can be added multiple times and its component will be rendered
 *     multiple times.
 *
 *     TODO: We can create wrappers around MatchAdderComponent for specific match messages like
 *     MatchTarget that has a predefined list of IMatchOptions.
 * @author alextsg
 */
@Component({
    selector: 'match-adder',
    templateUrl: './match-adder.component.html',
})
export class MatchAdderComponent implements OnInit, OnDestroy {
    /**
     * Match message item, extended from PolicyMatchConfigItem.
     */
    @Input()
    public match: PolicyMatchConfigItem;

    /**
     * List of match options that the user can add. These options will be used to create the
     * dropdown button options.
     */
    @Input()
    public matchOptions: IMatchOption[] = [];

    /**
     * Flag to disable the add button
     */
    @Input()
    public disableAddButton = false;

    /**
     * ViewContainerRef for rendering match components.
     */
    @ViewChild('matchListContainerRef', {
        read: ViewContainerRef,
        static: true,
    })
    public matchListContainerRef: ViewContainerRef;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * AviDropdownButton options for selecting a match to add.
     */
    public addMatchDropdownActions: IAviDropdownButtonAction[] = [];

    /**
     * Position of the add attachments actions menu tooltip.
     */
    public actionPosition = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * Set of selected match options from the matchOptions binding.
     */
    public selectedMatchOptions = new Set<IMatchOption>();

    /**
     * Set of added match componentRefs, used to set the matchIndex for match components.
     */
    public matchComponentRefs = new Set<ComponentRef<Component>>();

    constructor(
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        private readonly l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.updateSelectedMatchesAndOptions();
        this.renderSelectedMatches();
    }

    /**
     * Removes the rendered match component and deletes the associated messageItem from the match
     * messageItem.
     */
    public removeMatch(
        componentRef: ComponentRef<Component>,
        editable: MessageItem,
        matchOption: IMatchOption,
    ): void {
        const { fieldName } = matchOption;
        const index = this.matchListContainerRef.indexOf(componentRef.hostView);

        this.matchListContainerRef.remove(index);
        componentRef.destroy();
        this.matchComponentRefs.delete(componentRef);

        if (this.isRepeatedMatch(matchOption)) {
            this.match.config[fieldName].removeByMessageItem(editable);
        } else {
            delete this.match.config[fieldName];
        }

        this.updateSelectedMatchesAndOptions();
        this.updateMatchIndices();
    }

    /**
     * @override
     * Destroys all rendered match components.
     */
    public ngOnDestroy(): void {
        this.matchListContainerRef.clear();
    }

    /**
     * Sets the AviDropdownButton options. If no options are available to select, shows a disabled
     * "No available matches to add" option.
     */
    private setAddMatchDropdownActions(): void {
        this.addMatchDropdownActions = this.matchOptions
            .filter(option => this.showAddMatchDropdownAction(option))
            .map(option => ({
                label: option.label,
                onClick: () => this.addMatch(option),
            }));

        if (!this.addMatchDropdownActions.length) {
            this.addMatchDropdownActions = [{
                label: this.l10nService.getMessage(l10nKeys.noAvailableMatchesLabel),
                onClick: () => {},
                disabled: () => true,
            }];
        }
    }

    /**
     * Returns true if the dropdown option should be selectable.
     */
    private showAddMatchDropdownAction(matchOption: IMatchOption): boolean {
        return this.isRepeatedMatch(matchOption) || !this.selectedMatchOptions.has(matchOption);
    }

    /**
     * Adds currently selected match options to the selectedMatchOptions set.
     */
    private setSelectedMatches(): void {
        this.selectedMatchOptions.clear();

        this.matchOptions.forEach((matchOption: IMatchOption): void => {
            if (this.match.hasMatchByField(matchOption.fieldName)) {
                this.selectedMatchOptions.add(matchOption);
            }
        });
    }

    /**
     * Iterates through the selectedMatchOptions set to render selected match options.
     */
    private renderSelectedMatches(): void {
        this.selectedMatchOptions.forEach((matchOption: IMatchOption): void => {
            const { fieldName } = matchOption;

            if (this.isRepeatedMatch(matchOption)) {
                const repeatedMessageItem = this.match.config[fieldName];

                repeatedMessageItem.config.forEach((messageItem: MessageItem) => {
                    this.renderMatch(matchOption, messageItem);
                });
            } else {
                this.renderMatch(matchOption, this.match.config[fieldName]);
            }
        });
    }

    /**
     * Renders the match component. The repeatedMessageItem is passed here so that the match
     * component can create unique names for any ngModel bindings from the messageItem's index in
     * the repeatedMessageItem's config, otherwise all repeated match components will have the same
     * ngModel name.
     */
    private renderMatch(matchOption: IMatchOption, editable: MessageItem): void {
        const {
            component,
            componentBindings,
            label,
            subLabel,
            objectType,
            fieldName,
        } = matchOption;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            component as Type<Component>,
        );

        const componentRef = componentFactory.create(this.matchListContainerRef.injector);
        const matchObjectType = this.getMatchComponentObjectType(matchOption);
        const bindings = {
            editable,
            label,
            subLabel,
            objectType,
            fieldName,
            matchObjectType,
            matchIndex: this.matchListContainerRef.length,
            onRemoveMatch: () => this.removeMatch(componentRef, editable, matchOption),
            ...componentBindings,
        };

        attachComponentBindings(componentRef, bindings);
        this.matchListContainerRef.insert(componentRef.hostView);
        this.matchComponentRefs.add(componentRef);
    }

    /**
     * Adds a match and renders its associated component.
     */
    private addMatch(matchOption: IMatchOption): void {
        this.selectedMatchOptions.add(matchOption);

        let messageItem: MessageItem;

        const { fieldName } = matchOption;

        if (this.isRepeatedMatch(matchOption)) {
            const repeatedMessageItem = this.match.config[fieldName];

            repeatedMessageItem.add();

            messageItem = repeatedMessageItem.at(repeatedMessageItem.count - 1);
        } else {
            this.match.addMatch(fieldName);

            messageItem = this.match.config[fieldName];
        }

        this.renderMatch(matchOption, messageItem);
        this.updateSelectedMatchesAndOptions();
    }

    /**
     * Returns true if the match option belongs to a repeated match.
     */
    private isRepeatedMatch(matchOption: IMatchOption): boolean {
        return this.match.config[matchOption.fieldName] instanceof RepeatedMessageItem;
    }

    /**
     * Updates each match component's matchIndex binding. This is needed for components for repeated
     * matches that may have an [(ngModel)] binding in their components, since the name attribute
     * must be unique. Otherwise, repeated match components would likely have the same and break
     * functionality.
     */
    private updateMatchIndices(): void {
        Array.from(this.matchComponentRefs).forEach((componentRef, index) => {
            // eslint-disable-next-line no-extra-parens
            (componentRef.instance as Record<string, any>).matchIndex = index;
        });
    }

    /**
     * Calls setSelectedMatches and setAddMatchDropdownActions to update the state of the component.
     */
    private updateSelectedMatchesAndOptions(): void {
        this.setSelectedMatches();
        this.setAddMatchDropdownActions();
    }

    /**
     * Returns objectType for the match component.
     * @param matchOption the match option item for the given match component.
     */
    private getMatchComponentObjectType(matchOption: IMatchOption): string {
        const { fieldName, objectType } = matchOption;

        if (objectType) {
            const fields = this.schemaService.getMessageFields(objectType);

            return fields && fields[fieldName] ? fields[fieldName].objectType : '';
        }
    }
}
