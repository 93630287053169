/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import {
    forkJoin,
    from,
    Observable,
    of,
    pipe,
    Subscription,
    UnaryFunction,
} from 'rxjs';
import {
    catchError,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { tapResponse } from '@ngrx/component-store';
import {
    convertTimeToTTimestampFormatFromApi,
} from 'ng/modules/diagram/components/avi-bar-graph/avi-bar-graph.utils';
import {
    IVsLogsCombinedRequestParams,
    IVsLogsUpdatableParams,
    TVsLogsApiResponseData,
    TVsLogsCombinedApiResponseData,
} from '../vs-logs.types';
import { VsLogsApiRequestService } from './vs-logs-api-request.service';

/**
 * Effect creator takes in any Observable and a function or effect that performs an action on
 * latest value of the Observable.
 *
 * Returns a pipe to be passed into an Effect constructor.
 */
type TSelectorTapEffectCreator = <T>(
    selector$: Observable<T>,
    next: (value: T) => void | Subscription,
) => UnaryFunction<Observable<void>, Observable<[void, T]>>;

/**
 * Effect creator takes in an Observable of a component's API params and an effect to load data for
 * that component.
 *
 * Returns a pipe to be passed into an Effect constructor.
 */
type TReloadEffectCreator = <T extends Partial<IVsLogsUpdatableParams>>(
    apiParams$: Observable<T>,
    requestEffect: (apiParams: T) => Subscription,
) => UnaryFunction<Observable<void>, Observable<[void, T]>>;

/**
 * Request effect creator function takes in needed functionality
 * and returns a function as expected by the Component Store effect constructor.
 */
type TVsLogsRequestEffectCreator = (
    requestId: string,
    startLoading: () => void,
    handleApiData: (data: TVsLogsApiResponseData) => void,
    // TODO 149827: specify error type
    handleApiError: (err: any) => void,
    handleApiComplete: () => void,
) => (apiParams$: Observable<IVsLogsUpdatableParams>) => Observable<TVsLogsApiResponseData>;

/**
 * Request effect creator function takes in needed functionality for multiple requests
 * and returns a function as expected by the Component Store effect constructor.
 */
type IVsLogsCombinedRequestEffectCreator = (
    startLoading: () => void,
    handleApiData: (data: TVsLogsCombinedApiResponseData) => void,
    // TODO 149827: specify error type
    handleApiError: (err: any) => void,
    handleApiComplete: () => void,
) => (
    apiParams$: Observable<IVsLogsCombinedRequestParams[]>,
) => Observable<TVsLogsCombinedApiResponseData>;

/**
 * @description
 *  Provides constructors for effects that share functionality
 *  across different log component's stores.
 * @author Alex Klyuev, Suraj Kumar
 */
@Injectable()
export class VsLogsEffectsService {
    constructor(private readonly vsLogsApiRequestService: VsLogsApiRequestService) { }

    /**
     * Create effects that can grab the latest value from a specified selector / Observable
     * and perform some function on it.
     */
    public static createSelectorTapEffect: TSelectorTapEffectCreator = (selector$, next) => pipe(
        withLatestFrom(selector$),
        tap(valueContainer => {
            const value = valueContainer[1];

            next(value);
        }),
    );

    /**
     * Create effects that will reload the data of the current component with the current time.
     */
    public static createReloadEffect: TReloadEffectCreator = (apiParams$, requestEffect) => pipe(
        withLatestFrom(apiParams$),
        tap(apiParamsContainer => {
            const apiParams = apiParamsContainer[1];

            apiParams.end = convertTimeToTTimestampFormatFromApi(Date.now());

            requestEffect(apiParams);
        }),
    );

    /**
     * Create effects to be used for VS Logs Component Stores to request the API
     * and update their states accordingly.
     */
    public createVsLogsRequestEffect: TVsLogsRequestEffectCreator = (
        requestId,
        startLoading,
        handleApiData,
        handleApiError,
        handleApiComplete,
    ) => apiParams$ => apiParams$.pipe(
        // if a previous request hasn't completed yet, the new request will cancel it
        // switchMap handles this by only emitting the last Observable output
        switchMap(apiParams => {
            this.vsLogsApiRequestService.cancelRequest(requestId);
            startLoading();

            // emit the next Observable by making the request
            return from(this.vsLogsApiRequestService.requestLogs(
                apiParams,
                requestId,
            )).pipe(
                // handle API response
                tapResponse(
                    handleApiData,
                    handleApiError,
                    handleApiComplete,
                ),
            );
        }),
    );

    /**
     * Create effects to be used for VS Logs Component Stores to make combined request for
     * API and update their states accordingly.
     */
    public createVsLogsCombinedRequestEffect: IVsLogsCombinedRequestEffectCreator = (
        startLoading,
        handleApiData,
        handleApiError,
        handleApiComplete,
    ) => apiParams$ => apiParams$.pipe(
        // if a previous request hasn't completed yet, the new request will cancel it
        // switchMap handles this by only emitting the last Observable output
        switchMap(apiParams => {
            const requests = {};
            let isErrorPresent = false;

            startLoading();
            apiParams.forEach((param: IVsLogsCombinedRequestParams) => {
                this.vsLogsApiRequestService.cancelRequest(param.requestId);

                requests[param.requestId] = from(this.vsLogsApiRequestService.requestLogs(
                    param.requestParams,
                    param.requestId,
                )).pipe(
                    catchError(err => {
                        isErrorPresent = true;
                        // TODO AV-164997: Decide approach for handling combined requests error of
                        // signpost.

                        handleApiError(err);

                        return of(err);
                    }),
                );
            });

            const observable = forkJoin(requests);

            observable.subscribe({
                next: value => {
                    if (!isErrorPresent) {
                        handleApiData(value);
                    }
                },
                complete: () => handleApiComplete(),
            });

            return observable;
        }),
    );
}
