/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-mapping';
const prefix = `${moduleName}.${componentName}`;

export const botMappingBreadcrumbTitle = `${prefix}.botMappingBreadcrumbTitle`;

export const ENGLISH = {
    [botMappingBreadcrumbTitle]: 'Bot Mapping',
};
