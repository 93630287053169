/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IPReputationDB } from 'ajs/modules/ip-reputation-db';
import { IPReputationDBDisplayStatus } from '../ip-reputation-db-list-page-container';
import './ip-reputation-db-list-page.component.less';

/**
 * @description Component for the IP Reputation DB List Page.
 * @author alextsg
 */
@Component({
    selector: 'ip-reputation-db-list-page',
    templateUrl: './ip-reputation-db-list-page.component.html',
})
export class IPReputationDBListPageComponent {
    /**
     * IP Reputation DB item.
     */
    @Input()
    public ipReputationDB: IPReputationDB;

    /**
     * Status to be displayed.
     */
    @Input()
    public status: IPReputationDBDisplayStatus;

    /**
     * Called to edit Pulse settings.
     */
    @Output()
    public onEditPulseSettings = new EventEmitter();

    /**
     * Returns the error to be displayed in the tooltip, if any.
     */
    public getError(): string | undefined {
        return this.ipReputationDB?.serviceStatusError;
    }

    /**
     * Called when the user clicks the Edit Pulse Settings or Register Controller link. Passed to
     * the IPReputationDBListPageHeaderComponent.
     */
    public handleEditPulseSettings(): void {
        this.onEditPulseSettings.emit();
    }
}
