/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    forwardRef,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
    DropdownModelSingleValue,
    IAviDropdownOption,
} from 'ng/shared/components/avi-dropdown/avi-dropdown.types';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './avi-repeated-dropdowns-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export type TDropdownRow = {
    value: DropdownModelSingleValue;
};

/**
 * @description
 *    Component translates array of Dropdown Options into an array of DropdownRows
 *    and pass the data to AviDataGrid.
 *
 *  @example
 *     <avi-repeated-dropdowns-grid
 *         name="avirepeateddropdownsgrid"
 *         placeholder="Select Options"
 *         [options]="testDropdownOptions"
 *         [hideSearch]="false"
 *         [multiple]="true"
 *         [maxRows]="5"
 *         gridTitle="Dropdown Rows"
 *         [maxSelectableOptions]="5"
 *         [busy]="true"
 *         [hiddenValues]="['10', 'Hello']"
 *         [helperText]="Description for Dropdown"
 *         [readonly]="false"
 *         [allowDuplicates]="false"
 *     ></avi-repeated-dropdowns-grid>
 *
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'avi-repeated-dropdowns-grid',
    templateUrl: './avi-repeated-dropdowns-grid.component.html',
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedDropdownsGridComponent),
        },
    ],
})
export class AviRepeatedDropdownsGridComponent implements AfterViewInit, ControlValueAccessor {
    /**
     * Placeholder for Dropdown.
     */
    @Input()
    public placeholder = l10nKeys.dropdownPlaceholder;

    /**
     * AviDataGrid column label.
     */
    @Input()
    public columnLabel = l10nKeys.columnTitleString;

    /**
     * Options to be shown for selection.
     */
    @Input()
    public options: IAviDropdownOption[] = [];

    /**
     * Check for allowing duplicate values.
     */
    @Input()
    public multiple = false;

    /**
     * Option to Hide Search in dropdown.
     */
    @Input()
    public hideSearch = false;

    /**
     * Maximum number of selectable options, only applicable for multiple selection dropdown.
     */
    @Input()
    public maxSelectableOptions = Infinity;

    /**
     * True to show a spinner in the dropdown options.
     */
    @Input()
    public busy = false;

    /**
     * Optional Helper-text
     * To be displayed below input fields.
     */
    @Input()
    public helperText = '';

    /**
     * Grid Title to be passed to datagrid.
     */
    @Input()
    public gridTitle = '';

    /**
     * Flag to show a value as readonly. Differs from disabled in that the input field will be
     * displayed similarly to a label.
     */
    @Input()
    public readonly = false;

    /**
     * Maximum rows allowed in grid.
     */
    @Input()
    public maxRows = Infinity;

    /**
     * This will be added as prefix for each dropdown row.
     */
    @Input()
    public name = 'repeated-dropdown';

    /**
     * Check for allowing duplicate values.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Hidden Values are List of values that should be hidden from selection.
     * The hidden values are set to a variable to be used in getter.
     */
    @Input()
    public set hiddenValues(values: DropdownModelSingleValue[]) {
        this.hiddenValuesFromParent = values;
    }

    /**
     * Hidden Values are the total of Hidden Values specified in Parent
     * and the Dropdown Row Values that are selected so far in Grid
     * if Duplicates are not allowed.
     */
    public get hiddenValues(): DropdownModelSingleValue[] {
        const hiddenValues = this.allowDuplicates ?
            this.hiddenValuesFromParent :
            this.hiddenValuesFromParent.concat(this.allDropdownRowValues);

        return hiddenValues;
    }

    /**
     * Template ref for dropdown input row.
     */
    @ViewChild('dropdownTemplateRef')
    public dropdownTemplateRef: TemplateRef<HTMLElement>;

    /**
     * dropdownRows, it is passed to the AviDataGrid rows.
     */
    public dropdownRows: TDropdownRow[] = [];

    /**
     * Repeated Dropdowns Grid Config.
     */
    public repeatedDropdownsGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * To check for maximum allowed values in grid.
     */
    public get hasMaxRows(): boolean {
        return this.dropdownRows.length >= this.maxRows;
    }

    /**
     * Hidden Values Specified in parent.
     */
    private hiddenValuesFromParent: DropdownModelSingleValue[] = [];

    /**
     * Getter for all the Dropdown Row Values added into AviDataGrid.
     */
    private get allDropdownRowValues(): DropdownModelSingleValue[] {
        return this.dropdownRows.map(dropdownRow => dropdownRow.value);
    }

    /**
     * The ngModel value.
     */
    private modelValue: DropdownModelSingleValue[];

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting Repeated Dropdown Grid Config.
     */
    public ngAfterViewInit(): void {
        const {
            l10nService,
            columnLabel,
        } = this;

        this.repeatedDropdownsGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(columnLabel),
                id: 'repeated-string',
                templateRef: this.dropdownTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (dropdownRows: TDropdownRow[]) => {
                    dropdownRows.forEach((dropdownRow: TDropdownRow) => {
                        this.deleteDropdownRow(dropdownRow);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (dropdownRow: TDropdownRow) => {
                    this.deleteDropdownRow(dropdownRow);
                },
            }],
        };
    }

    /**
     * DropdownRow addition to AviDataGrid.
     */
    public addDropdown(): void {
        this.dropdownRows.push({ value: undefined });

        this.updateModelValue(this.dropdownRows);
    }

    /**
     * Modal Value Update on Modal Change.
     */
    public handleRowModelChange(): void {
        this.updateModelValue(this.dropdownRows);
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: DropdownModelSingleValue[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(values: DropdownModelSingleValue[]): void {
        this.modelValue = values;

        this.setRepeatedDropdowns(values);
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: DropdownModelSingleValue[]): void => { };

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => { };

    /*************************************************************************/

    /**
     * DropdownRow deletion from AviDataGrid.
     */
    private deleteDropdownRow(dropdownRow: TDropdownRow): void {
        const index = this.dropdownRows.indexOf(dropdownRow);

        this.dropdownRows.splice(index, 1);
        this.updateModelValue(this.dropdownRows);
    }

    /**
     * Set Dropdown Rows to be passed to AviDataGrid rows.
     */
    private setRepeatedDropdowns(values: DropdownModelSingleValue[]): void {
        values = values || [];

        this.dropdownRows = values.map((item: DropdownModelSingleValue): TDropdownRow => {
            return { value: item };
        });
    }

    /**
     * Updates modelValue and emits model change event.
     */
    private updateModelValue(dropdownRows: TDropdownRow[]): void {
        this.modelValue = dropdownRows.length ?
            dropdownRows.map(item => item.value) : undefined;

        this.onChange(this.modelValue);
        this.onTouched();
    }
}
