/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as dropdownTemplate from './dropdown-options.partial.html';
import * as dropdownPartial from './dropdown.partial.html';
import * as l10n from './dropdown.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name dropdown
 * @restrict E
 *
 * @description
 * Dropdown/select element.
 * Supports passing options by <option> DOM elements as well as a list of DropDownOptions or
 * simple type values. Also options can be passed through the controller by other directives.
 *
 * For ngModel only string and numeric types are supported.
 *
 * @param {*=} multiple - If attribute is present user can select multiple options from the list.
 * @param {DropDownOption[]} [options] - Optional array of options to create
 *     dropdown menu list elements. Used instead of <option> DOM elements.
 * @param {string[]} hiddenOptions - Array of strings to be omitted from the list of options.
 *     Filtering is made by option.value.
 * @param {Function=} ngChange - Function to be called on option selection.
 * @param {boolean=} allowClear - When set selection removal is allowed.
 * @param {Function=} onclear - Function to be called on selected value removal.
 * @param {boolean=} ngDisabled - Considered to be disabled if set.
 * @param {boolean=} ngRequired - Considered to be required if set.
 * @param {string=} placeholder - Placeholder for the input element when no value is set.
 * @param {boolean=} search - Search is available when attribute value evaluates to true.
 * @param {string=} searchFieldPlaceholder - Placeholder for search bar.
 * @param {string=} prepend - Additional text/label displayed before dropdown box.
 * @param {string=} defaultValue - List option shown by default when dropdown is initialized.
 * @param {string?} optionsPopoverClassName - Optional classname for options popover.
 * @author Alex Malitsky, Ashish Verma
 * @example <caption>Passing options by the list (recommended)</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple
 *     options="list">
 * </dropdown>
 *
 * @deprecated
 * @example <caption>Passing options with DOM option elements</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple>
 *         <option value="4">Test 4</option>
 *         <option value="5">Test 5</option>
 *         <option value="6">Test 6</option>
 * </dropdown>
 * @see {@link module:avi/component-kit.enumOptions}
 * @example <caption>Passing object-type and field-name to get options</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple
 *     enum-options
 *     object-type="objectType"
 *     field-name="fielName"
 * >
 * </dropdown>
 *
 * @example <caption>Passing enum type to get options</caption>
 * <dropdown
 *     ng-model="obj.value"
 *     multiple
 *     enum-options="enumType"
 * >
 * </dropdown>
*/
//TODO wrap options in a class or somehow else streamline simple form to object conversion

class DropdownController {
    constructor($scope, l10nService) {
        this.$scope_ = $scope;
        /**
         * Flag to check if options were provided by other directives
         * @type {boolean}
         * @protected
         */
        this.providedOptions_ = false;

        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Set options to the scope provided by other directives.
     * @param {DropDownOption[]}
     */
    set options(options) {
        this.providedOptions_ = true;
        this.$scope_.options = options.concat();
    }

    /**
     * Return true if options were already provided by other directives.
     * @returns {boolean}
     */
    get providedOptions() {
        return this.providedOptions_;
    }
}

DropdownController.$inject = [
    '$scope',
    'l10nService',
];

function dropdownDirectiveFactory(
    $timeout,
    $templateCache,
    $compile,
    popoverFactory,
    dropDownUtils,
    l10nService,
) {
    class Overlay extends popoverFactory {
        constructor(config, scope, container) {
            super(config);
            this.scope = scope;
            this.container = container;
            this.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            this.template = dropdownTemplate;
        }

        /** @override */
        show(elm) {
            this.config.html = $compile(this.template)(this.scope);
            super.show(elm);
            this.config.html.find('.search').trigger('focus'); //sets the focus on search field
        }

        /** @override */
        reposition() {
            this.popover.width(this.container.width());
            super.reposition();
        }
    }

    /**
     * Returns either property of passed option or if not set - option itself.
     * Need this since DropDownOption might actually be a simple type.
     * @param {DropDownOption} option
     * @param {string=} propName - Property name we are interested at, 'value' by default.
     * @returns {DropDownOption}
     * @inner
     */
    const propertyOrObj = function(option, propName = 'value') {
        if (angular.isObject(option)) {
            const { [propName]: value } = option;

            if (!angular.isUndefined(value)) {
                return value;
            }
        }

        return option;
    };

    // have to use "scope." to share methods between pre and post link functions
    // TODO switch to controller so that they can be put on this. instead

    // need to have prelink to register our formatter and parser first in case other
    // directives using ngModelCtrl are used on the same node
    function dropdownPreLink(scope, elm, attr, { ngModelCtrl }) {
        /**
         * Returns index of the passed option value in the list of options.
         * @param {string|number} value
         * @return {number} - -1 if not found
         * @inner
         */
        scope.getOptionIndex = value =>
            _.findIndex(scope.options, option => propertyOrObj(option) == value);

        /**
         * Finds the option object by option value. If not found creates an option
         * from the value. If found but of simple type wraps it in an object.
         * @param {string|number} optionValue
         * @returns {DropDownOption} - Object form only.
         * @inner
         */
        scope.createOptionObjFromValue = function(optionValue) {
            const index = scope.getOptionIndex(optionValue);

            if (index < 0) {
                return dropDownUtils.createOption(optionValue);
            }

            const option = scope.options[index];

            if (angular.isObject(option)) {
                return angular.copy(option);
            }

            return dropDownUtils.createOption(option);
        };

        /**
         * Translates an array of selected options into an array of their values.
         * viewValue > modelValue.
         * @param {DropDownOption[]} viewVal - Object form only.
         * @returns {string|string[]}
         * @inner
         */
        function parseItemsList(viewVal) {
            if (scope.isEmpty()) {
                return undefined;
            }

            if (scope.isMultiple()) { //array
                return _.pluck(viewVal, 'value');
            } else { //string
                return viewVal[0].value;
            }
        }

        /**
         * Translates a list or single value into a list of selected options.
         * modelValue > viewValue
         * @param {string|number|string[]|number[]} modelVal
         * @returns {DropDownOption[]} - Object form only.
         * @inner
         */
        function formatItemsList(modelVal) {
            const viewVal = [];

            if (scope.isMultiple()) {
                if (Array.isArray(modelVal)) {
                    viewVal.push(
                        ...modelVal.map(scope.createOptionObjFromValue),
                    );
                }
            } else if (modelVal !== '' && modelVal !== null &&
                !angular.isUndefined(modelVal)) {
                viewVal.push(scope.createOptionObjFromValue(modelVal));
            }

            return viewVal;
        }

        ngModelCtrl.$parsers.push(parseItemsList);
        ngModelCtrl.$formatters.push(formatItemsList);
    }

    function dropdownPostLink(scope, elm, attr, { ngModelCtrl }) {
        const elContainer = $(elm).children('.dropdown-container');
        const elScrollable = $(elm).children('.scrollable');
        const popoverClassName = `collDropdown ${scope.optionsPopoverClassName || ''}`;

        const overlayConfig = {
            className: popoverClassName,
            height: 30,
            width: elContainer.width(),
            repositioning: true,
            removeAfterHide: true,
        };

        const popupOverlay = new Overlay(overlayConfig, scope, elContainer);

        /**
         * Returns true if multiple attribute is present.
         * @returns {boolean}
         * @inner
         */
        function isMultiple() {
            return 'multiple' in attr;
        }

        /**
         * @type {Function}
         * @public
         */
        scope.isMultiple = isMultiple;

        // Need this in template
        scope.attr = attr;

        // Keeps panel expanded state. Used in hotkeys
        scope.expanded = false;

        //init
        $timeout(() => {
            //do it initially, otherwise selected option may not be shown properly
            //updates for the list of DOM notes are not watched
            readOptions();

            const { defaultValue } = scope;

            if (defaultValue && isEmpty()) {
                const { $viewValue: viewValue } = ngModelCtrl;

                ngModelCtrl.$setViewValue(
                    viewValue.concat(scope.createOptionObjFromValue(defaultValue)),
                );
            }
        });

        // we want to pick up labels for the selected values once the list of options updates
        scope.$watchCollection(
            'options',
            () => ngModelCtrl.$processModelValue(),
        );

        const { $viewChangeListeners: listeners } = ngModelCtrl;

        //removes regular ng-change behavior
        //TODO restore regular ngChange behavior
        listeners.length = 0;

        // since parser returns `undefined` for empty case input will be considered invalid,
        // fixing it here
        const emptyValidation = () => {
            if (isEmpty()) {
                $timeout(() => ngModelCtrl.$setValidity('parse', true));
            }
        };

        listeners.push(emptyValidation);

        scope.ngModelCtrl = ngModelCtrl;

        /**
         * Returns true if something selected.
         * @param {Object[]} viewValue - Object form only.
         * @returns {boolean}
         * @inner
         */
        ngModelCtrl.$isEmpty = viewValue => !viewValue.length;

        scope.isEmpty = () => ngModelCtrl.$isEmpty(ngModelCtrl.$viewValue);

        const { isEmpty } = scope;

        //need to allow `undefined` to be returned by parser so that our listeners do run
        ngModelCtrl.$overrideModelOptions({
            updateOn: 'default',
            allowInvalid: true,
        });

        /**
         * Returns true for the currently selected option. Doesn't work for multiple
         * selection since for that one we hide already selected options from the list.
         * @param {DropDownOption} option
         * @returns {boolean}
         * @public
         */
        scope.isSelected = option => {
            if (isEmpty() || isMultiple()) {
                return false;
            }

            const optionValue = propertyOrObj(option);

            return _.some(
                ngModelCtrl.$viewValue,
                ({ value }) => value == optionValue,
            );
        };

        /**
         * Returns option label for the passed option.
         * @param {DropDownOption} option
         * @returns {string|number}
         * @inner
         */
        const getOptionLabel = function(option) {
            if (angular.isObject(option)) {
                const { label } = option;

                if (!angular.isUndefined(label) && label !== '') {
                    return label;
                }

                return option.value;
            }

            return angular.isString(option) && option.name() || option;
        };

        /**
         * @type {Function}
         * @public
         */
        scope.getOptionLabel = getOptionLabel;

        /**
         * Checks whether user has reached limit of selected options for multiselect mode.
         * @returns {boolean} - False for regular dropdown.
         * @public
         */
        scope.multipleLimitReached = () => {
            const { max } = attr;

            return isMultiple() && max > 0 &&
                ngModelCtrl.$viewValue.length >= max || false;
        };

        const { multipleLimitReached } = scope;

        /**
         * Reads (and populates) scope.options list from the DOM tree.
         * @inner
         */
        function readOptions() {
            //passed to scope by ref to the list, nothing to do here
            if ('options' in attr) {
                return;
            }

            // provided through controller by other directives, hence already on scope
            if (scope.$ctrl.providedOptions) {
                return;
            }

            // reading from DOM
            scope.options = _.map(
                elm.find('option'),
                dropDownUtils.createOptionFromHtml,
            );
        }

        /**
         * Shows options panel
         * @public
         */
        scope.showOptions = function() {
            if (!scope.ngDisabled && !multipleLimitReached()) {
                readOptions();
                popupOverlay.show(elm);
                scope.expanded = true;
                scope.query = '';
                elScrollable.scrollTop(0);
            }
        };

        /**
         * Hides options panel
         * @public
         */
        scope.hideOptions = function() {
            popupOverlay.hide();
            scope.expanded = false;
        };

        /**
         * Adds/sets selected option to ngModel.
         * @param {DropDownOption} option
         * @public
         */
        scope.select = option => {
            const
                optionValue = propertyOrObj(option),
                { $viewValue: viewValue } = ngModelCtrl;

            //we need an object form here
            if (!angular.isObject(option)) {
                option = dropDownUtils.createOption(option);
            } else {
                option = angular.copy(option);
            }

            const newViewValue = [];

            if (isMultiple()) {
                // make sure there will be no duplicates
                // some instead of find (or contains) to support 0
                const alreadyPresent = _.some(
                    viewValue, ({ value }) => value == optionValue,
                );

                if (!alreadyPresent) {
                    newViewValue.push(...viewValue, option);
                } else {
                    return;
                }
            } else {
                newViewValue.push(option);
            }

            ngModelCtrl.$setViewValue(newViewValue);

            //FIXME need to go back to default ngChange behavior
            //not sure why option form is passed
            if (angular.isFunction(scope.ngChange)) {
                const selected = isMultiple() ? newViewValue.concat() : option;

                try {
                    scope.ngChange({
                        selected: angular.copy(selected),
                    });
                } catch (e) {
                    console.error(e);
                }
            }
        };

        /**
         * Returns the next option after selected.
         * @returns {DropDownOption|undefined}
         * @public
         */
        scope.getNextItem = function() {
            const { options } = scope;

            if (isMultiple() || isEmpty() || !options) {
                return;
            }

            let index = scope.getOptionIndex(ngModelCtrl.$viewValue[0].value);

            if (index < 0 || ++index === options.length) {
                return;
            }

            return options[index];
        };

        /**
         * Returns previous option after selected.
         * @returns {DropDownOption|undefined}
         * @public
         */
        scope.getPrevItem = function() {
            const { options } = scope;

            if (isMultiple() || isEmpty() || !options) {
                return;
            }

            const index = scope.getOptionIndex(ngModelCtrl.$viewValue[0].value);

            if (index <= 0) {
                return;
            }

            return options[index - 1];
        };

        /**
         * Removes selected option by index.
         * @param {number} index - Element's index
         * @public
         */
        scope.remove = function(index) {
            const viewValue = ngModelCtrl.$viewValue.concat();

            viewValue.splice(index, 1);
            ngModelCtrl.$setViewValue(viewValue);

            if (angular.isFunction(scope.onclear)) {
                try {
                    scope.onclear();
                } catch (e) {
                    console.error(e);
                }
            }
        };

        /**
         * Hides options that are already selected or were passed as hiddenOptions.
         * @param {DropDownOption} option
         * @returns {boolean} - True for option to display.
         * @public
         */
        scope.filterHideSelected = function(option) {
            const
                { hiddenOptions } = scope,
                haveHiddenOptions = Array.isArray(hiddenOptions) && hiddenOptions.length > 0;

            //shortcut
            if (isEmpty() && !haveHiddenOptions) {
                return true;
            }

            const optionValue = propertyOrObj(option);

            let show = true;

            if (isMultiple() && !isEmpty()) {
                //some instead of find (or contains) to support 0
                show = !_.some(ngModelCtrl.$viewValue, ({ value }) => value == optionValue);
            }

            if (show && haveHiddenOptions) {
                //some instead of find (or contains) to support 0
                show = !_.some(hiddenOptions, value => value == optionValue);
            }

            return show;
        };

        /**
         * Returns true if option label has a match with search string set by grid.
         * @param {DropDownOption} option
         * @returns {boolean} - True for matching option.
         * @public
         */
        scope.filterByKeyword = function(option) {
            if (!scope.query) {
                return true;
            }

            const
                keyword = scope.query.toLowerCase(),
                optionLabel = String(getOptionLabel(option)).toLowerCase();

            return optionLabel.indexOf(keyword) !== -1;
        };

        /** Hotkeys */
        elContainer.off('keydown')
            .on('keydown', event => {
                const
                    { keyCode } = event,
                    { expanded } = scope;

                switch (keyCode) {
                    case 40:
                        if (!expanded) {
                            scope.showOptions();
                        } else if (!isMultiple()) {
                            scope.select(scope.getNextItem());
                        }

                        break;

                    case 38:
                        if (expanded && !isMultiple()) {
                            scope.select(scope.getPrevItem());
                        }

                        break;

                    case 27:
                        scope.hideOptions();
                        event.preventDefault();
                        event.stopPropagation();
                        break;

                    case 13:
                        scope.hideOptions();
                        break;
                }
            });

        scope.$on('$destroy', () => popupOverlay.remove());
    }

    return {
        scope: {
            ngChange: '&?',
            onclear: '&?',
            ngDisabled: '=?',
            ngRequired: '=?',
            hiddenOptions: '=?', //array of values to exclude from the list of options
            allowClear: '<?',
            search: '@?',
            searchFieldPlaceholder: '@?',
            defaultValue: '@?',
            prepend: '@?',
            options: '<?',
            optionsPopoverClassName: '@?',
        },
        restrict: 'E',
        template: dropdownPartial,
        require: {
            ngModelCtrl: 'ngModel',
        },
        priority: 1, //so that ngModelCtr formatters and parsers are set before the rest
        transclude: true,
        controller: DropdownController,
        controllerAs: '$ctrl',
        compile: () => ({
            pre: dropdownPreLink,
            post: dropdownPostLink,
        }),
    };
}

dropdownDirectiveFactory.$inject = [
    '$timeout',
    '$templateCache',
    '$compile',
    'popoverFactory',
    'dropDownUtils',
    'l10nService',
];

angular.module('avi/component-kit')
    .directive(
        'dropdown',
        dropdownDirectiveFactory,
    );
