/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    select as d3Select,
    stack as d3Stack,
    area as d3Area,
    max as d3Max,
    curveMonotoneX as d3CurveMonotoneX,
} from 'd3';

const d3 = {
    select: d3Select,
    stack: d3Stack,
    area: d3Area,
    max: d3Max,
    curveMonotoneX: d3CurveMonotoneX,
};

angular.module('charts').factory('LineStack', ['chartUtils', function(chartUtils) {
    /**
     * Stacked line area chart.
     */
    class LineStack {
        /**
         * @param {Function} xScale
         * @param {Function} yScale
         */
        constructor(xScale, yScale) {
            this.xScale = xScale;
            this.yScale = yScale;

            this.element = d3
                .select(chartUtils.createElement())
                .attr('class', 'line-stack-container');

            this.stack = d3.stack();

            this.area = d3.area()
                .curve(d3.curveMonotoneX)
                .y0(d => this.yScale(d[0]))
                .y1(d => this.yScale(d[1]))
                .x(d => this.xScale(d.data.time));

            /** @type {Array<ChartSeries>} */
            this.series = null;

            /** @type {Array<string>} */
            this.colors = null;
        }

        /**
         * Update scales functions.
         * @param {Function} xScale
         * @param {Function} yScale
         */
        updateScale(xScale, yScale) {
            this.xScale = xScale;
            this.yScale = yScale;
        }

        /**
         * Updates stack chart elements based on series.
         * @param {Array<ChartSeries>} series
         * @param {Array<string>=} colors - Colors for each area.
         * @returns {Array} - D3 stack array based on series.
         */
        update(series, colors = []) {
            this.series = series;
            this.colors = colors;

            if (Array.isArray(series)) {
                const keys = chartUtils.getSeriesId(series);
                const data = chartUtils.seriesToStack(series);

                this.stack.keys(keys);

                const stackData = this.stack(data);

                const len = stackData.length;

                if (len > 0) {
                    const max = d3.max(stackData[len - 1], d => d[1]);

                    this.yScale.domain([0, max]);
                }

                const layer = this.element.selectAll('.stack-area').data(stackData);

                layer.enter().append('path');
                layer.exit().remove();

                this.element
                    .selectAll('path')
                    .attr('class', 'stack-area')
                    .attr('d', this.area)
                    .style('fill', (d, i) => colors[i]);

                return stackData;
            }
        }

        /**
         * Offsets element based on X and Y values.
         * @param {number} x
         * @param {number} y
         */
        position(x, y) {
            this.element.attr('transform', `translate(${x}, ${y})`);
        }

        /**
         * Renders stack chart to specified element.
         * @param {Element} parent
         */
        render(parent) {
            const el = this.element.node();

            parent.appendChild(el);

            return el;
        }

        resize() {
            this.update(this.series, this.colors);
        }
    }

    return LineStack;
}]);
