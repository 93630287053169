/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, OnInit } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import {
    IAviDiffGridConfig,
} from 'ng/modules/data-grid/components/avi-diff-grid/avi-diff-grid.types';

import * as l10n from './vs-log-cinematic-section-headers.l10n';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying the HTTP headers section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-headers',
    templateUrl: './vs-log-cinematic-section-headers.component.html',
})
export class VsLogCinematicSectionHeadersComponent implements OnInit {
    public readonly l10nKeys = l10nKeys;

    /**
     * Object that includes HTTP request headers strings.
     */
    public readonly httpRequestHeaders$ = this.vsLogCinematicStore.httpRequestHeaders$;

    /**
     * Object that includes HTTP response headers strings.
     */
    public readonly httpResponseHeaders$ = this.vsLogCinematicStore.httpResponseHeaders$;

    /**
     * Config for the request headers diff grid.
     */
    public requestDiffGridConfig: IAviDiffGridConfig;

    /**
     * Config for the response headers diff grid.
     */
    public responseDiffGridConfig: IAviDiffGridConfig;

    constructor(
        private readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.requestDiffGridConfig = {
            leftField: {
                label: this.l10nService.getMessage(l10nKeys.headersReceivedFromClientLabel),
            },
            rightField: {
                label: this.l10nService.getMessage(l10nKeys.headersSentToServerLabel),
            },
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.emptyListPlaceholderLabel),
            },
            isGridExpandable: true,
        };

        this.responseDiffGridConfig = {
            leftField: {
                label: this.l10nService.getMessage(l10nKeys.headersReceivedFromServerLabel),
            },
            rightField: {
                label: this.l10nService.getMessage(l10nKeys.headersSentToClientLabel),
            },
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.emptyListPlaceholderLabel),
            },
            isGridExpandable: true,
        };
    }
}
