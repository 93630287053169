/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing WAF related components and services.
 * @preferred
 * @module WafModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { VIPModule } from '@vmw/ngx-vip';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { MatchModule } from 'ng/modules/match/match.module';
import {
    ClrCheckboxModule,
    ClrInputModule,
    ClrRadioModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafExcludeListEntryModalComponent,
    WafLearningLinkComponent,
    WafPolicyListPageComponent,
    WafProfileListComponent,
    WafUserOverridesBadgeComponent,
} from '.';
import {
    wafApplicationSignatureProviderCollectionProvider,
    wafApplicationSignatureProviderProvider,
} from '../../ajs-upgraded-providers';

import {
    latestWafCrsVersionServiceProvider,
    wafCrsCollectionProvider,
    wafPolicyCollectionProvider,
    wafProfileCollectionProvider,
} from './ajs-upgraded-providers';

import { WafApplicationLearningService } from './services/waf-application-learning.service';
import { WafPolicyAllowlistRuleExpanderContentService }
    from './services/waf-policy-allowlist-rule-expander-content.service';

const wafComponents = [
    WafApplicationRulesListComponent,
    WafApplicationRulesListHeaderComponent,
    WafExcludeListEntryModalComponent,
    WafLearningLinkComponent,
    WafProfileListComponent,
    WafPolicyListPageComponent,
    WafUserOverridesBadgeComponent,
];

@NgModule({
    declarations: [
        ...wafComponents,
    ],
    exports: [
        WafLearningLinkComponent,
        WafExcludeListEntryModalComponent,
        WafUserOverridesBadgeComponent,
    ],
    imports: [
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        SharedModule,
        DialogModule,
        ClrCheckboxModule,
        ClrRadioModule,
        AviFormsModule,
        MatchModule,
        VIPModule,
        DataGridModule,
    ],
    providers: [
        latestWafCrsVersionServiceProvider,
        wafApplicationSignatureProviderProvider,
        wafApplicationSignatureProviderCollectionProvider,
        wafCrsCollectionProvider,
        WafPolicyAllowlistRuleExpanderContentService,
        wafPolicyCollectionProvider,
        wafProfileCollectionProvider,
        WafApplicationLearningService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class WafModule { }
