/**
 * @module Angular-Core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { times } from 'underscore';

/**
 * Makes a list regexp from regexp for a single string/match.
 */
const listOfRegExpStr = (regExpStr: string, separator = ',', spacesAllowed = true): string => {
    if (spacesAllowed) {
        separator = `\\s*${separator}\\s*`;
    }

    const res = `(?:${regExpStr}|(?:${regExpStr}${separator})+${regExpStr})`;

    return spacesAllowed ? `\\s*${res}\\s*` : res;
};

/**
 * Provides a regular expression in a string form for values range. Basically joins two
 * passed regExpStrs with a separator.
 */
const rangeOfRegExpStr = (regExpStr: string, separator = '\\-', spacesAllowed = true): string => {
    if (spacesAllowed) {
        separator = `\\s*${separator}\\s*`;
    }

    return regExpStr + separator + regExpStr;
};

/**
 *     Various regular expressions used by app. Initially generated from small chunks by few
 *     convenience methods.
 *
 *     For regular expression in a string form we need to escape the special "\" character.
 *
 *     WG suffix for the regexp means that it has some presumably meaningful character groups.
 *     Such groups have performance penalties so use wisely. Those are not supposed to be used
 *     for the templates.
 */

const alphaStr = 'a-zA-Z';
const alphaNumStr = `${alphaStr}\\d`;
const alphaNumWDashStr = `${alphaNumStr}\\-`;
const alphaNumWUnderscoreStr = `${alphaNumStr}\\_`;
const alphaNumWDashUnderscoreStr = `${alphaNumWDashStr}\\_`;

const anyStr = '.*';

const portStr = [
    '(?:[1-9]',
    '[1-9]\\d{1,3}',
    '[1-5]\\d{4}',
    '6[0-4]\\d{3}',
    '65[0-4]\\d{2}',
    '655[0-2]\\d',
    '6553[0-5])',
].join('|');

const ipAddrOctetStr = '(?:25[0-5]|2[0-4]\\d|[01]?\\d\\d?)';
const ipAddrStr = times(4, () => ipAddrOctetStr).join('\\.');
const ipAddrWGStr = times(4, () => `(${ipAddrOctetStr})`).join('\\.');
const ipAddrWithPortStr = `${ipAddrStr}:${portStr}`;
const ipAddrWithPortWGStr = `(${ipAddrStr}):(${portStr})`;
const ipAddrRangeStr = rangeOfRegExpStr(ipAddrStr);
const ipAddrRangeWGStr = rangeOfRegExpStr(`(${ipAddrStr})`);
const ipAddrOrRangeStr = `(?:${ipAddrStr}|${ipAddrRangeStr})`;
const listOfIpAddrOrRangesStr = listOfRegExpStr(ipAddrOrRangeStr);
const ipAddrOrIpAddrWithPortOrRangeStr = `(?:${ipAddrStr}|${ipAddrWithPortStr}|${ipAddrRangeStr})`;
const listOfIpAddrOrIpAddrWithPortOrRangeStr = listOfRegExpStr(ipAddrOrIpAddrWithPortOrRangeStr);

const prefixLengthStr = '(?:\\d|[1-2]\\d|3[0-2])';
const subnetStr = `${ipAddrStr}\\/${prefixLengthStr}`;
const subnetWGStr = `(${ipAddrStr})\\/(${prefixLengthStr})`;

const ipAddrOrRangeOrSubnetStr = `(?:${ipAddrStr}|${ipAddrRangeStr}|${subnetStr})`;

const hostNamePartStr = `(?:[${alphaNumStr}]|[${alphaNumStr}]` +
    `[${alphaNumWDashUnderscoreStr}]*[${alphaNumStr}])`;
const hostNameStr = `(?:${hostNamePartStr}\\.)*${hostNamePartStr}`;
const ipAddrOrRangeOrHostnameStr = `(?:${ipAddrOrRangeStr}|${hostNameStr})`;
const ipAddrOrHostnameStr = `(?:${ipAddrStr}|${hostNameStr})`;

const listOfIpAddrOrRangesOrSingleHostnameStr = `(?:${listOfIpAddrOrRangesStr}|${hostNameStr})`;
const listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostnameStr =
    `(?:${listOfIpAddrOrIpAddrWithPortOrRangeStr}|${hostNameStr})`;
const fqdnStr = `(?:${hostNamePartStr}\\.){2,}(?:${hostNamePartStr}){2,}`;
const ipAddrOrFqdnStr = `(?:${ipAddrStr}|${fqdnStr})`;

const httpStatusCodeStr = '[1-5]\\d{2}';
const httpStatusCodeRangeStr = rangeOfRegExpStr(httpStatusCodeStr);
const httpStatusCodeOrRangeStr = `(${httpStatusCodeStr}|${httpStatusCodeRangeStr})`;

const respCodeBlockStr = '[4-5]{1}[xX]{2}';
const httpStatusCodeOrRangeOrCodeBlockStr = `(${httpStatusCodeOrRangeStr}|${respCodeBlockStr})`;

const emailStr = `[\\w.!#$%&'*+/=?^\`{|}~-]+@[${alphaNumStr}]` +
    `(?:[${alphaNumWDashStr}]{0,61}[${alphaNumStr}])?` +
    `(?:\\.[${alphaNumStr}](?:[${alphaNumWDashStr}]{0,61}[${alphaNumStr}])?)*`;

const ipv6P1Str = '(?:[0-9A-Fa-f]{1,4})';
const ipv6P2Str = '(?:25[0-5]|2[0-4]d|1dd|[1-9]?d)';
const ipv6P3Str = `(?:(?:${ipv6P2Str})(?:.(?:${ipv6P2Str})){3}))`;
const ipv6Str =
    `s*(?:(?:(?:${ipv6P1Str}:){7}(?:${ipv6P1Str}|:))|(?:(?:${ipv6P1Str}:){6}(?::${ipv6P1Str}|` +
    `(?:${ipv6P3Str}|:))|(?:(?:${ipv6P1Str}:){5}(?:(?:(?::${ipv6P1Str}){1,2})|` +
    `:(?:${ipv6P3Str}|:))|(?:(?:${ipv6P1Str}:){4}(?:(?:(?::${ipv6P1Str}){1,3})|` +
    `(?:(?::${ipv6P1Str})?:(?:${ipv6P3Str})|:))|` +
    `(?:(?:${ipv6P1Str}:){3}(?:(?:(?::${ipv6P1Str}){1,4})|` +
    `(?:(?::${ipv6P1Str}){0,2}:(?:${ipv6P3Str})|:))|` +
    `(?:(?:${ipv6P1Str}:){2}(?:(?:(?::${ipv6P1Str}){1,5})|` +
    `(?:(?::${ipv6P1Str}){0,3}:(?:${ipv6P3Str})|:))|` +
    `(?:(?:${ipv6P1Str}:){1}(?:(?:(?::${ipv6P1Str}){1,6})|` +
    `(?:(?::${ipv6P1Str}){0,4}:(?:${ipv6P3Str})|:))|(?::(?:(?:(?::${ipv6P1Str}){1,7})|` +
    `(?:(?::${ipv6P1Str}){0,5}:(?:${ipv6P3Str})|:)))` +
    '(?:%.+)?s*';
const ipv6PrefixStr = `(${ipv6Str})(\\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))`;
const ipv6RangeStr = rangeOfRegExpStr(`(${ipv6Str})`);
const ipv6PortStr = `(\\[(${ipv6Str})\\]:(${portStr}))`;
const anyIPStr = `(${ipAddrStr}|${ipv6Str})`;
const anySubnetStr = `(${subnetStr}|${ipv6PrefixStr})`;
const anyIPRangeStr = `(${ipAddrRangeStr}|${ipv6RangeStr})`;
const anyIPRangeOrSubnetListStr = `(${ipAddrStr}|${anyIPRangeStr}|${subnetStr}|` +
    `${ipv6Str}|${ipv6PrefixStr})`;
const anyIPPortStr = `(${ipAddrWithPortStr}|${ipv6PortStr})`;
const anyIPIPv4RangeStr = `(${anyIPStr}|${ipAddrRangeStr})`;
const anyIPStrHostnameStr = `(?:${anyIPStr}|${hostNameStr})`;
const anyIPIPv4RangeHostnameStr = `(${anyIPStr}|${ipAddrRangeStr}|${hostNameStr})`;
const anyIPIPPortIPv4RangeStr = `(${anyIPStr}|${anyIPPortStr}|${ipAddrRangeStr})`;
const anyIPIPSubnetIPv4RangeStr = `(${anyIPStr}|${anySubnetStr}|${ipAddrRangeStr})`;
const anyIPIPSubnetIPRangeStr = `(${anyIPStr}|${anySubnetStr}|${anyIPRangeStr})`;
const anyIPIPPortIPv4RangeHostnameStr = `(${anyIPIPPortIPv4RangeStr}|${hostNameStr})`;
const anyIPIPPortHostnameStr = `(?:${anyIPStr}|${anyIPPortStr}|${hostNameStr})`;

const licenseSerialKeyPartStr = `(?:[${alphaNumStr}]){5}`;
const licenseSerialKeyStr = times(5, () => licenseSerialKeyPartStr).join('\\-');

const URITokenTypesStr = [
    'HOST',
    'PATH',
    'H',
    'P',
    'SG_MAP',
    'StringGroup_Map',
    'SG_RE',
    'StringGroup_Regex',
    'SG_RE_Q',
    'StringGroup_Regex_Query',
].join('|');

const wafExclusionMatchElementStr =
    '^(?:(?:ARGS(?:_GET|_NAMES|_POST)?:.+)|QUERY_STRING|REQUEST_' +
    '(?:BASENAME|BODY|URI|URI_RAW)|(?:REQUEST_(?:COOKIES|HEADERS)(?:_NAMES)?:.+)' +
    '|(?:RESPONSE_HEADERS:.+)|(?:FILES:.+)|(?:XML:.+))$';

/*
- Typed out input \r in browser is actually treated as \\r in browser.
- Typed out input \n in browser is actually treated as \\n in browser.
- Typed out input \r\n in browser is actually treated as \\r\\n in browser.
- Typed out new line in browser is treated as \n in browser.
*/
export const carriageReturnLineFeedWEscape = /\\r\\n|\\r|\r(?!\n)|\\n|(?!\r])\n/;

export const anyIP = new RegExp(`^${anyIPStr}$`);
export const anyIPHostname = new RegExp(`^${anyIPStrHostnameStr}$`);
export const anyIPIPPortHostname = new RegExp(`^${anyIPIPPortHostnameStr}$`);
export const anyIPIPPortIPv4RangeHostnameList = new RegExp(
    `^${listOfRegExpStr(anyIPIPPortIPv4RangeHostnameStr)}$`,
);
export const anyIPIPPortIPv4RangeList = new RegExp(`^${listOfRegExpStr(anyIPIPPortIPv4RangeStr)}$`);
export const anyIPIPSubnetIPv4Range = new RegExp(`^${anyIPIPSubnetIPv4RangeStr}$`);
export const anyIPIPSubnetIPv4RangeList = new RegExp(
    `^${listOfRegExpStr(anyIPIPSubnetIPv4RangeStr)}$`,
);
export const anyIPIPSubnetIPRangeList = new RegExp(
    `^${listOfRegExpStr(anyIPIPSubnetIPRangeStr)}$`,
);
export const anyIPIPv4Range = new RegExp(`^${anyIPIPv4RangeStr}$`);
export const anyIPIPv4RangeHostnameList = new RegExp(
    `^${listOfRegExpStr(anyIPIPv4RangeHostnameStr)}$`,
);
export const anyIPIPv4RangeList = new RegExp(`^${listOfRegExpStr(anyIPIPv4RangeStr)}$`);
export const anyIPList = new RegExp(`^${listOfRegExpStr(anyIPStr)}$`);
export const anyIPPort = new RegExp(`^${anyIPPortStr}$`);
export const anyIPRange = new RegExp(`^${anyIPRangeStr}$`);
export const anyIPRangeList = new RegExp(`^${listOfRegExpStr(anyIPRangeStr)}$`);
export const anyIPRangeOrSubnetList = new RegExp(`^${listOfRegExpStr(anyIPRangeOrSubnetListStr)}$`);
export const anyIPSubnetList = new RegExp(`^${listOfRegExpStr(anySubnetStr)}$`);
export const anySubnet = new RegExp(`^${anySubnetStr}$`);
export const carriageReturn = /\r/;
export const dnsName = new RegExp(
    `^(?:[${alphaNumStr}]{1,2}|[${alphaNumWUnderscoreStr}]` +
    `[${alphaNumWDashUnderscoreStr}.]{1,61}[${alphaNumStr}])$`,
);
export const email = new RegExp(`^${emailStr}$`);
export const emailList = new RegExp(`^${listOfRegExpStr(emailStr)}$`);
export const hostname = new RegExp(`^${hostNameStr}$`);
export const fqdn = new RegExp(`^${fqdnStr}$`);
export const ipAddrOrFqdn = new RegExp(`^${ipAddrOrFqdnStr}$`);
export const integer = /^-?\d+$/;
export const unsignedInteger = /^\d+$/;
export const ip = new RegExp(`^${ipAddrStr}$`);
export const ipAddrOrRange = new RegExp(`^${ipAddrOrRangeStr}$`);
export const ipAddrOrRangeOrHostname = new RegExp(`^${ipAddrOrRangeOrHostnameStr}$`);
export const ipAddrOrHostname = new RegExp(`^${ipAddrOrHostnameStr}$`);
export const ipAddrRange = new RegExp(`^${ipAddrRangeStr}$`);
export const ipAddrRangeWG = new RegExp(`^${ipAddrRangeWGStr}$`);
export const ipAddrWithPort = new RegExp(`^${ipAddrWithPortStr}$`);
export const ipAddrWithPortWG = new RegExp(`^${ipAddrWithPortWGStr}$`);
export const ipRangePrefix = new RegExp(`^\\s*${ipAddrOrRangeOrSubnetStr}\\s*$`);
export const ipv6 = new RegExp(`^${ipv6Str}$`);
export const ipv6Port = new RegExp(`^${ipv6PortStr}$`);
export const ipv6Prefix = new RegExp(`^${ipv6PrefixStr}$`);
export const ipv6PrefixList = new RegExp(`^${listOfRegExpStr(ipv6PrefixStr)}$`);
export const ipv6Range = new RegExp(`^${ipv6RangeStr}$`);
export const ipWG = new RegExp(`^${ipAddrWGStr}$`);
export const lineFeed = /\n/;
export const listOfHostnames = new RegExp(`^${listOfRegExpStr(hostNameStr)}$`);
export const listOfIpAddrOrIpAddrWithPortOrRange = new RegExp(
    `^${listOfIpAddrOrIpAddrWithPortOrRangeStr}$`,
);
export const listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostname = new RegExp(
    `^${listOfIpAddrOrIpAddrWithPortOrRangeOrSingleHostnameStr}$`,
);
export const listOfIpAddrOrRangesOrSingleHostname = new RegExp(
    `^${listOfIpAddrOrRangesOrSingleHostnameStr}$`,
);
export const listOfIps = new RegExp(`^${listOfRegExpStr(ipAddrStr)}$`);
export const listOfIpsPrefixes = new RegExp(`^${listOfRegExpStr(subnetStr)}$`);
export const listOfIpsRanges = new RegExp(`^${listOfIpAddrOrRangesStr}$`);
export const listOfIpsRangesPrefixes = new RegExp(`^${listOfRegExpStr(ipAddrOrRangeOrSubnetStr)}$`);
export const listOfStatusCodeOrRanges = new RegExp(
    `^${listOfRegExpStr(httpStatusCodeOrRangeStr)}$`,
);

// Checks the list of status code, rang with http status code block(4XX and 5XX).
export const httpStatusCodeOrRangeOrCodeBlock = new RegExp(
    `^${listOfRegExpStr(httpStatusCodeOrRangeOrCodeBlockStr)}$`,
);

export const mac = /^(?:[\dA-Fa-f]{2}[:-]){5}(?:[\dA-Fa-f]{2})$/;
export const mask = new RegExp(`^${subnetStr}$`);
export const objName = /^[^<>]*$/;
export const objNameExcludeSpaces = /^(?!.*[<>])(?=.*\S.*).*$/;
// mathiasbynens.be/demo/url-regex
export const poolURL = /^(?:-\.)?(?:[^\s/?.#-]+\.?)+(?:\/[^\s]*)?$/;
export const port = new RegExp(`^${portStr}$`);
export const prefixLength = new RegExp(`^${prefixLengthStr}$`);
export const ratio = /^(?:20|1\d|[1-9])$/;
// supposed to be used to create global regex
export const schemaObjectFieldSpecialValuesHash = /{?\s?([^\s']+?)\s?:\s?'(.+?)'\s?[,}]?\s?/;
export const statusCodeList = new RegExp(`^${listOfRegExpStr(httpStatusCodeStr)}$`);
export const statusCodeRangeList = new RegExp(`^${listOfRegExpStr(httpStatusCodeRangeStr)}$`);
export const subnetWG = new RegExp(`^${subnetWGStr}$`);
export const licenseSerialKey = new RegExp(`^${licenseSerialKeyStr}$`);

// old ambiguous names (to the right) are deprecated
// FIXME remove old names from code and remove em completely
export const listOfIpOrRanges = listOfIpsRanges;
export const subnet = mask;

// Checks the list of tokens like HOST[0:2],
export const hostTokens = new RegExp(
    `^(?:[^\\[\\]&/]*?|(?:(?:${URITokenTypesStr})\\[\\d*:?\\d*]))` +
    `(?:\\.(?:[^\\[\\]&/]*?|(?:(?:${URITokenTypesStr})\\[\\d*:?\\d*])))*?$`,
    'i',
);

export const pathTokens = new RegExp(
    `^(?:[^\\[\\]]*?|(?:(?:${URITokenTypesStr})\\[\\d*:?\\d*]))` +
    `(?:\\/([^\\[\\]]*?|(?:(?:${URITokenTypesStr})\\[\\d*:?\\d*])))*?$`,
    'i',
);

export const wafExclusionMatchElement = new RegExp(wafExclusionMatchElementStr, 'i');

// Checks O/5-14400 for TCP FastPath/TCP Proxy session_idle_timeout
export const tcpProfileSessionIdleTimeout = new RegExp(
    '^(0|[5-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8]' +
    '[0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|' +
    '1[0-3][0-9]{3}|14[0-3][0-9]{2}|14400)$',
);

export const tcpProfileSessionIpDscp = new RegExp('^([0-9]|[1-5][0-9]|6[0-3]|2147483647)$');

export const tcpProfileMaxSegmentSize = new RegExp(
    '^(0|51[2-9]|5[2-9][0-9]|[6-9][0-9][0-9]|[1-8][0-9][0-9][0-9]|9000)$',
);

export const anyString = new RegExp(`${anyStr}`, 's');
export const bgpPeerLabel = new RegExp(`^[${alphaNumWDashUnderscoreStr}]+$`);
export const communityStrings = new RegExp(`^(${portStr}:${portStr})$|[a-zA-Z]`);

export const seNamePrefix = new RegExp(`^[${alphaNumWUnderscoreStr}]+$`);
export const seNamePrefixWithDash = new RegExp(`^[${alphaNumWDashUnderscoreStr}]+$`);
export const seNamePrefixWithoutUnderscore = new RegExp(`^[${alphaNumStr}]+$`);

export const trueClientIpHeaders = new RegExp(`^(${anyIPStr}|X-Forwarded-For)$`);
