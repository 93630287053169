/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    AutoScaleLaunchConfigCollection,
} from 'ajs/modules/launch-config/factories/launch-config.collection.factory';

import {
    AutoScaleLaunchConfigItem,
} from 'ajs/modules/launch-config/factories/launch-config.item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './launch-config-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TAutoScaleLaunchConfigCollection = typeof AutoScaleLaunchConfigCollection;

/**
 * @description launch config list page.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'launch-config-list-page',
    templateUrl: './launch-config-list-page.component.html',
})
export class LaunchConfigListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display launchConfig grid.
     */
    public launchConfigGridConfig: IAviCollectionDataGridConfig;

    /**
     * launchConfig Collection instance.
     */
    public readonly launchConfigCollection: AutoScaleLaunchConfigCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(AutoScaleLaunchConfigCollection)
        AutoScaleLaunchConfigCollection: TAutoScaleLaunchConfigCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.launchConfigCollection = new AutoScaleLaunchConfigCollection();
    }

    /**
     * @override
     * Set grid config options.
     */
    public ngOnInit(): void {
        const { objectName } = this.launchConfigCollection;

        this.launchConfigGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.launchConfigCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: AutoScaleLaunchConfigItem) => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.launchConfigCollection.destroy();
    }
}
