/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModules
 */

import {
    Component,
    Input,
} from '@angular/core';
import './label-count-legend.component.less';
import {
    ILabelRowData,
} from '../label-count-row';

/**
 * @desc Reusable component acting as legend, for labeling data; mostly for charts/graphs.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'label-count-legend',
    templateUrl: './label-count-legend.component.html',
})
export class LabelCountLegendComponent {
    @Input()
    public readonly labelList: ILabelRowData[];

    /**
     * Optional title displayed.
     */
    @Input()
    public title?: string;

    /**
     * Same as labelList format, for bottom section.
     */
    @Input()
    public summarySectionLabel?: ILabelRowData;

    public trackByIndex(index: number): number {
        return index;
    }
}
