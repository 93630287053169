/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    Component,
    forwardRef,
    Input,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { L10nService } from '@vmw/ngx-vip';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import * as l10n from './avi-repeated-collection-or-custom-value.l10n';
import './avi-repeated-collection-or-custom-value.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Interface for a collection ref or custom value object, to be passed in a list as ngModel.
 */
export interface IRepeatedCollectionOrCustomStringModel {
    customValue?: string,
    collectionRef?: string,
}

/**
 * @description Component for entering repeated collection refs or custom values.
 *
 * @author Nisar Nadaf
 */
@Component({
    providers: [
        {
            multi: true,
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AviRepeatedCollectionOrCustomValueComponent),
        },
    ],
    selector: 'avi-repeated-collection-or-custom-value',
    templateUrl: './avi-repeated-collection-or-custom-value.component.html',
})
export class AviRepeatedCollectionOrCustomValueComponent implements ControlValueAccessor {
    /**
     * Collection instance.
     */
    @Input()
    public collection: Collection;

    /**
     * Collection-dropdown placeholder.
     */
    @Input()
    public dropdownPlaceholder: string;

    /**
     * Custom value input placeholder.
     */
    @Input()
    public inputPlaceholder: string;

    /**
     * Name to be passed for unique name attributes in case of repeated controls.
     */
    @Input()
    public name: string;

    /**
     * Label text to show above radio buttons.
     */
    @Input()
    public label: string;

    /**
     * Required - True if either dropdown-ref or custom-value is a required.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Set through 'disabled' binding. Disables dropdown and custom input field.
     */
    @Input('disabled')
    private set setDisabled(disabled: boolean | '') {
        this.disabled = disabled === '' || Boolean(disabled);
    }

    /**
     * Set through 'required' binding. Makes form field required.
     */
    public required = false;

    /**
     * Set through 'disabled' binding. Disables input and button.
     */
    public disabled = false;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Value being get/set as the ngModel value.
     */
    private modelValue: IRepeatedCollectionOrCustomStringModel[];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Updates custom value at given index in modelValue.
     */
    public updateCustomValue(customValues: string[], index: number): void {
        this.modelValue[index].customValue = customValues ? customValues[0] : undefined;
    }

    /**
     * Getter for the modelValue.
     */
    public get value(): IRepeatedCollectionOrCustomStringModel[] {
        return this.modelValue;
    }

    /**
     * Called to remove an entry.
     */
    public removeItem(index: number): void {
        this.modelValue.splice(index, 1);
        this.emitModalChange();
    }

    /**
     * Handler for adding new item in array.
     */
    public addItem(): void {
        if (!this.modelValue) {
            this.modelValue = [];
        }

        this.modelValue.push({});
        this.emitModalChange();
    }

    /***************************************************************************
     * IMPLEMENTING ControlValueAccessor INTERFACE
     */

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: (value: IRepeatedCollectionOrCustomStringModel[]) => {}): void {
        this.onChange = fn;
    }

    /**
     * Writes the modelValue.
     */
    public writeValue(value: IRepeatedCollectionOrCustomStringModel[]): void {
        this.modelValue = value;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onChange = (value: IRepeatedCollectionOrCustomStringModel[]): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouched = (): void => {};

    /**
     * Emits model change event.
     */
    private emitModalChange(): void {
        this.onChange(this.value);
        this.onTouched();
    }
}
