/** @module BotModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import { BotMappingItem }
    from 'ajs/modules/bot-detection-policy/factories/bot-mapping.item.factory';

import { ClrFormLayout } from '@clr/angular';

import { BotMappingRuleConfigItem }
    from 'ajs/modules/bot-detection-policy/factories/bot-mapping-rule.config-item.factory';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './bot-mapping-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Bot Mapping modal.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-mapping-modal',
    templateUrl: './bot-mapping-modal.component.html',
})
export class BotMappingModalComponent implements OnInit {
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Template ref action column cell.
     */
    @ViewChild('actionTemplateRef')
    public actionTemplateRef: TemplateRef<HTMLElement>;

    @Input()
    public editable: BotMappingItem;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Is 'BotMapping'.
     */
    public objectType: string;

    /**
     * avi-data-grid config for bot mapping grid
     */
    public botMappingGridConfig: IAviDataGridConfig;

    constructor(
        public l10nService: L10nService,
        public readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Needed because expandedContentTemplateRef avi-data-grid can only be set after init.
     * @override
     */
    public ngAfterViewInit(): void {
        this.setBotMappingGrid();
    }

    /**
     * Variable modal header, depends on creating new, or editing.
     */
    public get modalHeader(): string {
        return this.l10nService.getMessage(
            this.editable.id ? l10nKeys.modalTitleEdit : l10nKeys.modalTitleCreate,
        );
    }

    /**
     * Handles user clicking 'Add'.
     */
    public createBotMappingRule(): void {
        this.editable.addRule();
    }

    /**
     * Deletes bot mapping rule.
     */
    private removeMappingRule(mapping: BotMappingRuleConfigItem): void {
        this.editable.botMappingRules.removeByMessageItem(mapping);
    }

    /**
     * Sets properties needed for bot mapping rules grid.
     */
    private setBotMappingGrid(): void {
        this.botMappingGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform: (mappingRule: BotMappingRuleConfigItem) => {
                        return mappingRule.config.name;
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.numberOfMatchesLabel),
                    id: 'match',
                    transform: (mappingRule: BotMappingRuleConfigItem) => {
                        return String(mappingRule.matchCount);
                    },
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.actionLabel),
                    id: 'action',
                    templateRef: this.actionTemplateRef,
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (mappings: BotMappingRuleConfigItem[]) => {
                        mappings.forEach(mapping => {
                            this.removeMappingRule(mapping);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (mapping: BotMappingRuleConfigItem) => {
                        this.editable.editRule(mapping);
                    },
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (mapping: BotMappingRuleConfigItem) => {
                        this.removeMappingRule(mapping);
                    },
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
