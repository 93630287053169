/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @desc Parses IpAddrPrefix message-type to string and vice versa.
 *
 * @author Aravindh Nagarajan
 */

import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Provider,
    Renderer2,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import { IIpAddrPrefix } from 'generated-types';

import {
    formatIpAddrPrefix,
    parseIpPrefixString,
} from 'ng/shared/utils/ip-prefix-parser.utils';

const PARSE_IP_PREFIX_DIRECTIVE_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ParseIpPrefixDirective),
};

@Directive({
    selector: '[parseIpPrefix]',
    providers: [
        PARSE_IP_PREFIX_DIRECTIVE_ACCESSOR,
    ],
})
export class ParseIpPrefixDirective implements ControlValueAccessor {
    public constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
    ) {}

    /**
     * Listener for the input change event.
     * parses Input value and calls onChange to proceed with the new model value.
     */
    @HostListener('input', ['$event.target.value'])
    private onInputChange(value: string): void {
        const modelValue: IIpAddrPrefix = this.parse(value);

        this.onChange(modelValue);
    }

    /**
     * Writes the input display value with formatted ipAddrPrefix string.
     */
    public writeValue(value: IIpAddrPrefix): void {
        const { nativeElement: hostElement } = this.elementRef;
        const formattedIp = this.format(value);

        this.renderer.setProperty(hostElement, 'value', formattedIp);
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange: (value: string | IIpAddrPrefix) => void = (value: string) => {};

    /**
     *  Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch: () => void = () => {};

    /**
     * Formats IpAddrPrefix object into IP-subnet string.
     */
    private format(val: IIpAddrPrefix): string {
        return formatIpAddrPrefix(val);
    }

    /**
     * Parses IP-subnet string into an IPAddrPrefix message-type object with
     * addr and type properties.
     */
    private parse(val: string): IIpAddrPrefix {
        return parseIpPrefixString(val);
    }
}
