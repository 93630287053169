/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-mapping-profile-page';
const prefix = `${moduleName}.${componentName}`;

export const placeholderMessage = `${prefix}.placeholderMessage`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [placeholderMessage]: 'No Items Found',
    [headerLabel]: 'Auth Mapping Profile',
};
