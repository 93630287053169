/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { RepeatedMessageItem }
    from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IAviDataGridConfig,
    IRowReorder,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import { AuthenticationRuleConfigItem }
    from 'ajs/modules/sso-policy/factories/authentication-rule.config-item.factory';
import * as l10n from './authentication-rule-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Authentication Rules Grid component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'authentication-rule-grid',
    templateUrl: './authentication-rule-grid.component.html',
})
export class AuthenticationRuleGridComponent implements AfterViewInit {
    /**
     * AuthenticationRule RepeatedMessageItem instance.
     */
    @Input()
    public authRules: RepeatedMessageItem<AuthenticationRuleConfigItem>;

    /**
     * Fires on new authentication rule.
     */
    @Output()
    public onAddAuthRule: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit authentication rule.
     */
    @Output()
    public onEditAuthRule = new EventEmitter<AuthenticationRuleConfigItem>();

    /**
     * Fires on delete/deleteAll authentication rule.
     */
    @Output()
    public onDeleteAuthRule = new EventEmitter<AuthenticationRuleConfigItem>();

    /**
     * Fires on drag-drop of a rule.
     */
    @Output()
    public onRowOrderChange = new EventEmitter<IRowReorder>();

    /**
     * Match Expander templateRef.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * AuthenticationRules Grid config.
     */
    public authenticationRuleGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.authenticationRuleGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleName),
                id: 'name',
                transform(rule: AuthenticationRuleConfigItem) {
                    return rule.config.name;
                },
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (rules: AuthenticationRuleConfigItem[]) => {
                    rules.forEach((rule: AuthenticationRuleConfigItem) => {
                        this.deleteAuthRule(rule);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.editButtonLabel),
                shape: 'pencil',
                onClick: (rule: AuthenticationRuleConfigItem) => this.editAuthRule(rule),
            }, {
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (rule: AuthenticationRuleConfigItem) => this.deleteAuthRule(rule),
            }],
            layout: {
                withReordering: true,
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /**
     * Emits Add new rule event.
     */
    public addAuthRule(): void {
        this.onAddAuthRule.emit();
    }

    /**
     * Emits rule reorder event.
     */
    public handleRowReorder(reorderInfo: IRowReorder): void {
        this.onRowOrderChange.emit(reorderInfo);
    }

    /**
     * Emits Edit rule event.
     */
    private deleteAuthRule(rule: AuthenticationRuleConfigItem): void {
        this.onDeleteAuthRule.emit(rule);
    }

    /**
     * Emits Delete rule event.
     */
    private editAuthRule(rule: AuthenticationRuleConfigItem): void {
        this.onEditAuthRule.emit(rule);
    }
}
