/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-unit-info';
const prefix = `${moduleName}.${componentName}`;

export const viewProgressLabel = `${prefix}.viewProgressLabel`;
export const currentlyLabel = `${prefix}.currentlyLabel`;
export const scalingOutLabel = `${prefix}.scalingOutLabel`;
export const scalingInLabel = `${prefix}.scalingInLabel`;
export const migratingLabel = `${prefix}.migratingLabel`;
export const scaleOutButtonLabel = `${prefix}.scaleOutButtonLabel`;
export const scaleInButtonLabel = `${prefix}.scaleInButtonLabel`;
export const migrateButtonLabel = `${prefix}.migrateButtonLabel`;
export const servicePortPluralLabel = `${prefix}.servicePortPluralLabel`;
export const appDomainNameLabel = `${prefix}.appDomainNameLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const floatingIpLabel = `${prefix}.floatingIpLabel`;
export const vipStatusLabel = `${prefix}.vipStatusLabel`;
export const serviceEngineLabel = `${prefix}.serviceEngineLabel`;
export const availabilityZoneLabel = `${prefix}.availabilityZoneLabel`;
export const noneLabel = `${prefix}.noneLabel`;
export const trafficLabel = `${prefix}.trafficLabel`;
export const enabledLabel = `${prefix}.enabledLabel`;
export const disabledLabel = `${prefix}.disabledLabel`;
export const parentLabel = `${prefix}.parentLabel`;
export const applicationProfileLabel = `${prefix}.applicationProfileLabel`;
export const networkProfileLabel = `${prefix}.networkProfileLabel`;
export const wafPolicyLabel = `${prefix}.wafPolicyLabel`;
export const cloudLabel = `${prefix}.cloudLabel`;
export const vrfLabel = `${prefix}.vrfLabel`;
export const tenantLabel = `${prefix}.tenantLabel`;
export const sslCertificatesLabel = `${prefix}.sslCertificatesLabel`;
export const rulesLabel = `${prefix}.rulesLabel`;
export const networkSecurityPolicyLabel = `${prefix}.networkSecurityPolicyLabel`;
export const httpSecurityPolicyLabel = `${prefix}.httpSecurityPolicyLabel`;
export const httpRequestPolicyLabel = `${prefix}.httpRequestPolicyLabel`;
export const httpResponsePolicyLabel = `${prefix}.httpResponsePolicyLabel`;
export const dataScriptsLabel = `${prefix}.dataScriptsLabel`;
export const nonSignificantLogsLabel = `${prefix}.nonSignificantLogsLabel`;
export const clientLogFiltersLabel = `${prefix}.clientLogFiltersLabel`;
export const realTimeMetricsLabel = `${prefix}.realTimeMetricsLabel`;
export const clientInsightsLabel = `${prefix}.clientInsightsLabel`;
export const clientInsightsActiveLabel = `${prefix}.clientInsightsActiveLabel`;
export const clientInsightsPassiveLabel = `${prefix}.clientInsightsPassiveLabel`;
export const virtualServiceHeader = `${prefix}.virtualServiceHeader`;

export const ENGLISH = {
    [viewProgressLabel]: 'Click to view progress',
    [currentlyLabel]: 'Currently {0}',
    [scalingOutLabel]: 'scaling out',
    [scalingInLabel]: 'scaling in',
    [migratingLabel]: 'migrating',
    [scaleOutButtonLabel]: 'Scale Out',
    [scaleInButtonLabel]: 'Scale In',
    [migrateButtonLabel]: 'Migrate',
    [servicePortPluralLabel]: '{0, plural, one{Service Port} other{Service Ports}}',
    [appDomainNameLabel]: 'App Domain Name',
    [ipAddressLabel]: 'Address',
    [floatingIpLabel]: 'Floating IP',
    [vipStatusLabel]: 'VIP Status',
    [serviceEngineLabel]: 'Service Engine',
    [availabilityZoneLabel]: 'Availability Zone: {0}',
    [noneLabel]: 'None',
    [trafficLabel]: 'Traffic',
    [enabledLabel]: 'Enabled',
    [disabledLabel]: 'Disabled',
    [parentLabel]: 'Parent',
    [applicationProfileLabel]: 'Application Profile',
    [networkProfileLabel]: 'TCP/UDP Profile',
    [wafPolicyLabel]: 'WAF Policy',
    [cloudLabel]: 'Cloud',
    [vrfLabel]: 'VRF',
    [tenantLabel]: 'Tenant',
    [sslCertificatesLabel]: 'SSL Certificates',
    [rulesLabel]: 'Rules',
    [networkSecurityPolicyLabel]: 'Network Security Policy:',
    [httpSecurityPolicyLabel]: 'HTTP Security Policy:',
    [httpRequestPolicyLabel]: 'HTTP Request Policy:',
    [httpResponsePolicyLabel]: 'HTTP Response Policy:',
    [dataScriptsLabel]: 'DataScripts:',
    [nonSignificantLogsLabel]: 'Non-Significant Logs',
    [clientLogFiltersLabel]: 'Client Log Filters',
    [realTimeMetricsLabel]: 'Real Time Metrics',
    [clientInsightsLabel]: 'Client Insights',
    [clientInsightsActiveLabel]: 'Active',
    [clientInsightsPassiveLabel]: 'Passive',
    [virtualServiceHeader]: 'Virtual Service: <span class="sel-subnav-name unit-info--virtualservice__name">{0}</span>',
};
