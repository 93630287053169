/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import { VcenterHosts } from 'object-types';
import { IVcenterHosts } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description Vcenter Hosts Message Item.
 * @author vgohil
 */
export class VcenterHostsConfigItem extends MessageItem<IVcenterHosts> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: VcenterHosts,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns the configured host refs.
     */
    public get hostRefs(): string[] {
        return this.config.host_refs;
    }

    /**
     * Returns the current include mode.
     */
    public get includeMode(): boolean {
        return this.config.include;
    }

    /**
     * Method to reset the Vcenter hosts info.
     */
    public resetHostsInfo(): void {
        const defaultConfig = this.getDefaultConfig();

        this.updateConfig(defaultConfig);
    }
}
