/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name  MetricsDimensionAsnameDataTransformer
 * @description
 *
 *    Merges two lists into one, flattens data out and adds config.id.
 */
angular.module('avi/vs/security').factory('MetricsDimensionAsnameDataTransformer', [
'RevisedDataTransformer',
function(RevisedDataTransformer) {
    return class MetricsDimensionAsnameDataTransformer extends RevisedDataTransformer {
        /** @override */
        processResponse(resp) {
            const
                { data } = resp,
                results = [];

            if ('ipdata' in data) {
                _.forEach(data['ipdata'], ({ asdata }, ip) => {
                    results.push({
                        config: {
                            id: ip,
                        },
                        asname: asdata.asname,
                    });
                });
            }

            if ('asdata' in data) {
                _.forEach(data['asdata'], (name, asn) => {
                    results.push({
                        config: {
                            id: asn,
                        },
                        asname: name,
                    });
                });
            }

            data.results = results;
            data.count = results.length;

            return resp;
        }
    };
}]);
