/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { isUndefined } from 'underscore';
import './expander.less';

/**
 * @description Component for an expander.
 * @example
 *     <expander>
 *         <opener>
 *             <div>EXPAND ME</div>
 *         </opener>
 *         <content>
 *             <div>EXPANDED</div>
 *         </content>
 *     </expander>
 * @author alextsg
 */
@Component({
    selector: 'expander',
    templateUrl: './expander.component.html',
})
export class ExpanderComponent {
    /**
     * Text to be displayed. Optional, as a transcluded element can be used instead.
     */
    @Input() public label?: string;

    /**
     * Class to be added to the opener element.
     */
    @Input() public openerClassName?: string;

    /**
     * True to hide the expander caret.
     */
    @Input() public hideCaret?: boolean = false;

    /**
     * Allows the parent to set the expanded state.
     */
    @Input('expanded') public set expandOverride(expand: boolean) {
        if (!isUndefined(expand) && expand !== this.expanded) {
            this.expanded = expand;
        }
    }

    /**
     * Called when the expand state has changed.
     */
    @Output() public onToggleExpand = new EventEmitter();

    public isExpanded = false;

    /**
     * Getter for the isExpanded property.
     */
    public get expanded(): boolean {
        return this.isExpanded;
    }

    /**
     * Setter for the isExpanded property. Also emits the onToggleExpand EventEmitter.
     */
    public set expanded(expand: boolean) {
        this.isExpanded = expand;
        this.onToggleExpand.emit(this.expanded);
    }

    /**
     * Click handler for clicking the expander.
     */
    public handleClick(): void {
        this.expanded = !this.expanded;
    }

    /**
     * Returns the name of the icon class to use.
     */
    public getCaretIconClass(): string {
        return this.expanded ? 'sl-icon-arrow-up' : 'sl-icon-arrow-down';
    }
}
