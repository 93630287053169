/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';

import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import { L4ConnectionInfoType } from '../vs-log-cinematic.types';
import { L4ConnectionType } from '../vs-log-cinematic.component';
import * as l10n from './vs-log-cinematic-section-connection.l10n';

import './vs-log-cinematic-section-connection.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for client/server connection logs in VS logs c-view.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'vs-log-cinematic-section-connection',
    templateUrl: 'vs-log-cinematic-section-connection.component.html',
})
export class VsLogCinematicSectionConnection {
    /**
     * Host type for connection logs.
     */
    @Input()
    public l4ConnectionType: L4ConnectionType;

    /**
     * TeamplteRef for packets, transfers and connection issues grid rows.
     */
    @ViewChild('rowFieldTemplateRef')
    private readonly rowFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Hashmap for host type and connections logs type.
     */
    public readonly hostTypeConnectionLog = {
        [L4ConnectionType.SERVER]: this.vsLogCinematicStore.l4ServerConnectionInfo$,
        [L4ConnectionType.CLIENT]: this.vsLogCinematicStore.l4ClientConnectionInfo$,
    };

    /**
     * Log types for a specific host (client or server).
     */
    public readonly l4ConnectionInfoType = {
        [L4ConnectionInfoType.CONNECTION_ISSUES]: L4ConnectionInfoType.CONNECTION_ISSUES,
        [L4ConnectionInfoType.PACKETS]: L4ConnectionInfoType.PACKETS,
        [L4ConnectionInfoType.TRANSFERS]: L4ConnectionInfoType.TRANSFERS,
    };

    public packetsGridConfig: IAviDataGridConfig;

    public transfersGridConfig: IAviDataGridConfig;

    public connectionIssuesGridConfig: IAviDataGridConfig;

    constructor(
        private readonly l10nService: L10nService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngAfterViewInit(): void {
        this.packetsGridConfig = this.setGridConfig(
            this.l4ConnectionInfoType.PACKETS,
            l10nKeys.packetsLabel,
        );

        this.transfersGridConfig = this.setGridConfig(
            this.l4ConnectionInfoType.TRANSFERS,
            l10nKeys.transfersLabel,
        );

        this.connectionIssuesGridConfig = this.setGridConfig(
            this.l4ConnectionInfoType.CONNECTION_ISSUES,
            l10nKeys.connectionIssuesLabel,
        );
    }

    /**
     * Set packets, transfers and connection issues grid configs.
     */
    public setGridConfig(id: string, label: string): IAviDataGridConfig {
        const gridConfig: IAviDataGridConfig = {
            getRowId: (index: number): number => index,
            layout: {
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: this.l10nService.getMessage(label),
                    id,
                    templateRef: this.rowFieldTemplateRef,
                },
            ],
        };

        return gridConfig;
    }
}
