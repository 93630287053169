/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as globalL10n from 'global-l10n';
import * as l10n from './GslbServiceEditController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @ngdoc controller
 * @name GSLBServiceEditController
 * @author Alex Malitsky
 * @description
 *
 *     Modal has two main modes - basic create and advanced create/edit. Same template is used.
 *     Basic mode was added later.
 *
 *     Advanced mode has two pages/views/layouts - one for the GslbService itself and
 *     another for nested GslbPools.
 *
 *     Basic create mode uses one page for both. There are two views for basic: active-active and
 *     active-standby. Active-active means one GslbPool and any number of members within it,
 *     active-standby means many GslbPools (up to priority max value) and each one has only one
 *     member.
 *
 *     Both basic edit modes are working with one GslbPool members list
 *     but for active-standby transition (into many pools with one member per each) is made
 *     on submit button click. Modal layout is also almost same for them, only differences are LB
 *     algorithm input for a-a and priority inputs for each member in case of a-s.
 *
 */
angular.module('aviApp').controller('GSLBServiceEditController', [
'$scope',
'$element',
'$window',
'HealthMonitorCollection',
'PersistenceProfileCollection',
'PKIProfileCollection',
'schemaService',
'dropDownUtils',
'l10nService',
function(
    $scope,
    $element,
    $window,
    HealthMonitorCollection,
    PersistenceProfileCollection,
    PKIProfileCollection,
    schemaService,
    dropDownUtils,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    $scope.l10nKeys = l10nKeys;
    $scope.globalL10nKeys = globalL10nKeys;

    l10nService.registerSourceBundles(dictionary);

    /**
     * @type {GslbServiceConfig}
     * @inner
     */
    let config;

    /**
     * @type {GSLBService}
     * @inner
     */
    let gslbService;

    $scope.gslbServiceHMScopeEnumValues = schemaService
        .getEnumValues('GslbServiceHealthMonitorScope');

    /**
     * For basic create there are two views/modes - `active-active` or `active-standby`. Undefined
     * for advanced create or edit.
     * @type {string|undefined}
     */
    $scope.basicEditMode = undefined;

    /**
     * Index of currently editable GslbPool within GslbServiceConfig#groups array.
     * @type {number|undefined}
     * @public
     */
    $scope.poolIndex = undefined;

    /**
     * Currently editable GslbPoolConfig or null.
     * @type {GslbPoolConfig|null}
     * @public
     */
    $scope.pool = null;

    const params = { is_federated: true };

    //dropdown only
    $scope.healthMonitorCollection = new HealthMonitorCollection({
        params,
        defaults: params,
    });

    $scope.pkiProfileCollection = new PKIProfileCollection({
        params,
        defaults: params,
    });

    const persistenceProfileParams = {
        ...params,
        persistence_type: 'PERSISTENCE_TYPE_GSLB_SITE',
    };

    $scope.appPersistenceProfCollection = new PersistenceProfileCollection({
        params: persistenceProfileParams,
        defaults: persistenceProfileParams,
    });

    $scope.poolsGridConfig = {
        id: 'gslb-service-modal-pools',
        rowId: 'name',
        defaultSorting: '-priority',
        fields: [{
            name: 'name',
            title: l10nService.getMessage(globalL10nKeys.nameLabel),
            sortBy: 'name',
        }, {
            name: 'priority',
            title: l10nService.getMessage(globalL10nKeys.priorityLabel),
            label: schemaService.getFieldDescription('GslbPool', 'priority'),
            sortBy: 'priority',
        }, {
            name: 'algorithm',
            title: l10nService.getMessage(l10nKeys.columnTitleAlgorithm),
            template: '{{row.algorithm | enum: \'GSLB_ALGORITHM_\'}}',
        }, {
            name: 'description',
            title: l10nService.getMessage(globalL10nKeys.descriptionLabel),
        }],
        singleactions: [{
            title: l10nService.getMessage(globalL10nKeys.editLabel),
            class: 'icon-pencil',
            do(poolConfig) {
                editPool(gslbService.editPool(poolConfig));
            },
        }, {
            title: l10nService.getMessage(globalL10nKeys.deleteLabel),
            class: 'icon-trash',
            hidden() {
                const config = gslbService.getConfig();

                return config ? config.groups.length < 2 : true;
            },
            do(poolConfig) {
                gslbService.dropPool(poolConfig);
            },
        }],
    };

    /**
     * GSLB domain name which is used by default for the new domain names.
     * @type {string}
     * @private
     */
    let defaultGSLBDomainName = '';

    $scope.init = function() {
        gslbService = $scope.editable;

        const gslb = gslbService.getGSLB();

        if (gslb) {
            $scope.gslbDomainNames = gslb.getDNSDomainNames();
            defaultGSLBDomainName = gslb.getDefaultDNSDomainName();
        }

        $scope.config = gslbService.getConfig();

        config = $scope.config;

        if ($scope.basicEditMode) {
            $scope.setBasicEditMode($scope.basicEditMode, true);
        }

        //TODO: Once other fallback algorithms are supported, need to get them from schema.
        const allowedFallbackAlgorithms = [
            'GSLB_ALGORITHM_ROUND_ROBIN',
            'GSLB_ALGORITHM_CONSISTENT_HASH',
        ];

        $scope.fallbackAlgorithms = dropDownUtils.getEnumDropdownOptions('GslbAlgorithm')
            .filter(({ value }) => allowedFallbackAlgorithms.includes(value));
    };

    /**
     * Adds GslbPool to the modal layout and scope. Doesn't update {GslbServiceConfig}.
     * @param {GslbPoolConfig=} newGslbPool - Overrides default values.
     * @public
     */
    $scope.addPool = function(newGslbPool) {
        editPool(gslbService.createPool(newGslbPool));
    };

    /**
     * Adds domain name UI object to the GslbService#domain_names list. Button click
     * handler.
     * @public
     */
    $scope.addDomainName = function() {
        config['domain_names'].push(
            gslbService.domainNameBeforeEdit(defaultGSLBDomainName),
        );

        setTimeout(() => {
            $element.find('div.domain-name-wrapper input').last().trigger('focus');
        }, 5);
    };

    /**
     * Sets $scope properties and therefore switches active modal layout to GslbPool edit.
     * Doesn't update {GslbServiceConfig}.
     * @param {{config: GslbPoolConfig, id: number}} poolEditable
     * @inner
     */
    function editPool(poolEditable) {
        $scope.poolIndex = poolEditable.id;
        $scope.pool = poolEditable.config;

        if (!$scope.basicEditMode) {
            setTimeout(function() {
                $element.find('input[type=text][ng-model=pool\\.name]').trigger('focus');
            }, 5);
        }
    }

    /**
     * Returns a modal window title/header. Shouldn't use one time binding in a template.
     * @returns {string}
     * @public
     */
    $scope.getModalTitle = function() {
        let title;

        if ($scope.basicEditMode) {
            title = l10nService.getMessage(l10nKeys.newGslbServiceTitle);
        } else if (!angular.isUndefined($scope.poolIndex)) {
            title = `<i class="icon-arrow-left"></i>${
                $scope.poolIndex === config['groups'].length ?
                    l10nService.getMessage(l10nKeys.newGslbPoolTitle) :
                    l10nService.getMessage(l10nKeys.editGslbPoolTitle)}`;
        } else {
            title = `${'url' in $scope.config ?
                l10nService.getMessage(l10nKeys.editGslbServiceTitle) :
                l10nService.getMessage(l10nKeys.newGslbServiceTitle)}`;
        }

        return title;
    };

    /**
     * GslbPool view back button (part of the Modal header) click event handler. Only for advanced
     * edit mode.
     * @public
     */
    $scope.backToGslbServiceSettings = function() {
        if (!$scope.basicEditMode && $scope.pool) {
            $scope.pool = null;
            $scope.poolIndex = undefined;
        }
    };

    /**
     * Submit button or enter button click event handler. Behaviour depends on the current view
     * and basic or advanced mode.
     * @public
     */
    $scope.submit = function() {
        if (!$scope.basicEditMode) {
            if ($scope.pool) {
                if ($scope.forms['poolForm'].$valid &&
                    gslbService.checkEditablePool($scope.pool, $scope.poolIndex)) {
                    gslbService.appendPool($scope.pool, $scope.poolIndex);
                    $scope.backToGslbServiceSettings();
                }
            } else if ($scope.forms['modalForm'].$valid && config['groups'].length) {
                gslbService.submit();
            }
        } else if ($scope.forms['modalForm'].$valid && $scope.forms['poolForm'].$valid) {
            // every try we start from scratch. Actual group data will be populated below
            $scope.pool['name'] = `${config['name']}-pool`;
            config['groups'].length = 0;

            if ($scope.basicEditMode === 'active-active' &&
                gslbService.checkEditablePool($scope.pool, $scope.poolIndex)) {
                gslbService.appendPool($scope.pool, $scope.poolIndex);
                gslbService.submit();
            } else if ($scope.basicEditMode === 'active-standby' &&
                gslbService.checkEditableActiveStandbyPool($scope.pool)) {
                gslbService.appendActiveStandbyPool($scope.pool);
                gslbService.submit();
            }
        }
    };

    /**
     * Checks form state and disables submit button when needed. Depends on basic/advanced mode
     * and page layout (for advanced).
     * @returns {boolean} - True (button will be disabled) when form has an invalid state.
     * @public
     */
    $scope.isSubmitButtonDisabled = function() {
        let disabled;

        if (!$scope.basicEditMode) {
            disabled = $scope.pool ? $scope.forms['poolForm'].$invalid ||
                !$scope.editable.checkEditablePool($scope.pool, $scope.poolIndex) :
                $scope.forms['modalForm'].$invalid || $scope.config['groups'].length < 1;
        } else {
            disabled = $scope.forms['poolForm'].$invalid || $scope.forms['modalForm'].$invalid;

            if (!disabled) {
                $scope.pool['name'] = `${config['name']}-pool`;

                if ($scope.basicEditMode === 'active-active') {
                    disabled = !gslbService.checkEditablePool($scope.pool, $scope.poolIndex);
                } else if ($scope.basicEditMode === 'active-standby') {
                    disabled = !gslbService.checkEditableActiveStandbyPool($scope.pool);
                }
            }
        }

        return disabled;
    };

    /**
     * Event handler for the GslbPoolConfig#algorithm selection dropdown.
     * @public
     */
    $scope.onAlgorithmChange = function() {
        $scope.pool['consistent_hash_mask'] = undefined;

        if ($scope.pool['algorithm'] !== 'GSLB_ALGORITHM_PREFERENCE_ORDER') {
            $scope.resetPreferenceOrderForMembers();
        }

        if ($scope.pool['algorithm'] === 'GSLB_ALGORITHM_GEO') {
            $scope.pool['fallback_algorithm'] = 'GSLB_ALGORITHM_ROUND_ROBIN';
        } else {
            $scope.pool['fallback_algorithm'] = undefined;
        }
    };

    /**
     * Resets preference order for all pool members if the algorithm
     * is not 'GSLB_ALGORITHM_PREFERENCE_ORDER'.
     * @private
     */
    $scope.resetPreferenceOrderForMembers = function() {
        const defaultPreferenceOrder = gslbService.getDefaultPoolMemberConfig()['preference_order'];

        $scope.pool['members'].forEach(member => {
            member['preference_order'] = defaultPreferenceOrder;
        });
    };

    /**
     * Event handler for the GslbPoolConfig fallback algorithm selection dropdown.
     * @public
     */
    $scope.onFallbackAlgorithmChange = function() {
        $scope.pool['consistent_hash_mask'] = undefined;
    };

    /**
     * Initialization for basic create mode. Executes on modal opening or basic mode a-a/a-s type
     * switch.
     * @param {string} type - `active-active` or `active-standby`.
     * @param {boolean=} noConfirmation - Will ask user for confirmation if some GslbPool settings
     *     have been provided and `true` value was not passed.
     * @public
     */
    $scope.setBasicEditMode = function(type, noConfirmation) {
        const isPoolConfigEmpty = () => {
            return !_.any($scope.pool['members'], member => {
                return member['ip'] && member['ip'].addr || member['cluster_uuid'] ||
                    member.priority_;
            });
        };

        if (noConfirmation || isPoolConfigEmpty() ||
            $window.confirm('Type switch will wipe current GslbPool configuration')) {
            $scope.basicEditMode = type;
            config['groups'].length = 0;
            $scope.addPool({ priority: 9 });
        }

        if (type === 'active-standby') {
            delete config.pool_algorithm;
        }
    };

    $scope.$on('$destroy', () => {
        $scope.healthMonitorCollection.destroy();
    });
}]);
