/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'file-upload-textarea';
const prefix = `${moduleName}.${componentName}`;

export const enterTextRadioLabel = `${prefix}.enterTextRadioLabel`;
export const uploadFileRadioLabel = `${prefix}.uploadFileRadioLabel`;

export const ENGLISH = {
    [enterTextRadioLabel]: 'Enter Text',
    [uploadFileRadioLabel]: 'Upload File',
};
