/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { AviPermissionResource } from 'generated-types';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { WafPolicyPsmGroup } from './waf-policy-psm-group.item.factory';
import { WAF_POLICY_PSM_GROUP_ITEM_TOKEN } from '../waf.tokens';

const allDataSources = {
    'collection-metrics': {
        source: 'CollMetricsCollDataSource',
        transformer: 'CollMetricsDataTransformer',
        transport: 'CollMetricsDataTransport',
        fields: [
            'waf_psmgroup.sum_flagged',
            'waf_psmgroup.sum_evaluated',
        ],
        dependsOn: 'config',
    },
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * Type for WafPolicyPsmGroupCollection collection.
 */
export type TWafPolicyPsmGroupCollection = typeof WafPolicyPsmGroupCollection;

/**
 * @description WAF Policy PSM Group Collection Class.
 *
 * @author Alex Tseung, Nitesh Kesarkar
 */
export class WafPolicyPsmGroupCollection extends Collection {
    public static ajsDependencies = [
        WAF_POLICY_PSM_GROUP_ITEM_TOKEN,
    ];

    public createNewItem: (args?: any, isLone?: boolean) => WafPolicyPsmGroup;

    constructor(args = {}) {
        const extendedArgs = {
            allDataSources,
            objectName: 'wafpolicypsmgroup',
            permissionName: AviPermissionResource.PERMISSION_WAFPOLICYPSMGROUP,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(WAF_POLICY_PSM_GROUP_ITEM_TOKEN);
    }
}
