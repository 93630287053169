/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name modalWizardNav
 * @description
 *     Navigation component for the modalContainer component.
 * @param {Object[]} tabs - List of tabs to be shown.
 * @param {Function} onSelectTab - Function to be called when a tab is clicked.
 * @param {Function} isSelected - Function called to know if a tab is currently selected.
 * @param {boolean} ngDisabled - True to disable the wizard navigation.
 */
class ModalWizardNavController {
    handleSelectTab(tab) {
        this.onSelectTab({ tab });
    }
}

angular.module('aviApp').component('modalWizardNav', {
    controller: ModalWizardNavController,
    bindings: {
        tabs: '<',
        onSelectTab: '&',
        isSelected: '&',
        ngDisabled: '<',
    },
    templateUrl: 'src/components/modals/modal-container/modal-wizard/modal-wizard-nav/' +
            'modal-wizard-nav.html',
});
