/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'security-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const protocolTypeMatchLabel = `${prefix}.protocolTypeMatchLabel`;
export const ipReputationDbHeader = `${prefix}.ipReputationDbHeader`;
export const geoDbInputPlaceholder = `${prefix}.geoDbInputPlaceholder`;
export const addHttpSecurityRuleHeader = `${prefix}.addHttpSecurityRuleHeader`;
export const actionInputPlaceholder = `${prefix}.actionInputPlaceholder`;
export const sendResponseSettingsHeader = `${prefix}.sendResponseSettingsHeader`;
export const statusCodeInputLabel = `${prefix}.statusCodeInputLabel`;
export const redirectToHttpsSettingsHeader = `${prefix}.redirectToHttpsSettingsHeader`;
export const rateLimitSettingsHeader = `${prefix}.rateLimitSettingsHeader`;
export const countInputLabel = `${prefix}.countInputLabel`;
export const periodInputLabel = `${prefix}.periodInputLabel`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const rateLimiterActionInputLabel = `${prefix}.rateLimiterActionInputLabel`;
export const rateLimiterActionInputPlaceholder = `${prefix}.rateLimiterActionInputPlaceholder`;
export const reportOnlyLabel = `${prefix}.reportOnlyLabel`;
export const localResponseSettingsHeader = `${prefix}.localResponseSettingsHeader`;
export const rateLimiterActionStatusCodeInputPlaceholder = `${prefix}.rateLimiterActionStatusCodeInputPlaceholder`;
export const fileUploadInputLabel = `${prefix}.fileUploadInputLabel`;
export const botManagementLabel = `${prefix}.botManagementLabel`;
export const invalidBotManagemntValues = `${prefix}.invalidBotManagemntValues`;
export const sourceIpMatchLabel = `${prefix}.sourceIpMatchLabel`;
export const matchCaseLabel = `${prefix}.matchCaseLabel`;
export const customStringLabel = `${prefix}.customStringLabel`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [servicePortMatchLabel]: 'Service Port',
    [protocolTypeMatchLabel]: 'Protocol Type',
    [matchCaseLabel]: 'Match Case',
    [customStringLabel]: 'String group or custom string',
    [ipReputationDbHeader]: 'IP Reputation DB',
    [geoDbInputPlaceholder]: 'Select Geo DB',
    [addHttpSecurityRuleHeader]: 'Add HTTP Security Rule',
    [actionInputPlaceholder]: 'Select Action',
    [sendResponseSettingsHeader]: 'Send Response Settings',
    [statusCodeInputLabel]: 'Status Code:',
    [redirectToHttpsSettingsHeader]: 'Redirect to HTTPS Settings',
    [rateLimitSettingsHeader]: 'Rate Limit Settings',
    [countInputLabel]: 'Count',
    [periodInputLabel]: 'Period',
    [secondsInnerText]: 'seconds',
    [rateLimiterActionInputLabel]: 'Rate Limiter Action',
    [rateLimiterActionInputPlaceholder]: 'Select Rate Limiter Action',
    [reportOnlyLabel]: 'Report Only',
    [localResponseSettingsHeader]: 'Local Response Settings',
    [rateLimiterActionStatusCodeInputPlaceholder]: 'Select Rate Limiter Action',
    [fileUploadInputLabel]: 'File',
    [botManagementLabel]: 'Bot Management',
    [invalidBotManagemntValues]: 'Invalid Values For Bot Management Rule',
    [sourceIpMatchLabel]: 'Source IP',
};
