/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CloudModule
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';

/**
 * @description Item for OpenStack Network
 *
 * @author Sarthak Kapoor
 */

export class OpenstackNetworkItem extends Item {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'openstack-get-tenant-networks',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    public isEditable(): boolean {
        return false;
    }
}
