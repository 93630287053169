/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'waf';

export const wafExcludeListEntryBreadcrumbTitle = `${prefix}.wafExcludeListEntryBreadcrumbTitle`;
export const addExceptionModalHeader = `${prefix}.addExceptionModalHeader`;
export const editExceptionModalHeader = `${prefix}.editExceptionModalHeader`;
export const createPreCrsGroupModalHeader = `${prefix}.createPreCrsGroupModalHeader`;
export const editPreCrsGroupModalHeader = `${prefix}.editPreCrsGroupModalHeader`;
export const createPostCrsGroupModalHeader = `${prefix}.createPostCrsGroupModalHeader`;
export const editPostCrsGroupModalHeader = `${prefix}.editPostCrsGroupModalHeader`;
export const editCrsGroupModalHeader = `${prefix}.editCrsGroupModalHeader`;
export const createPreCrsRuleModalHeader = `${prefix}.createPreCrsRuleModalHeader`;
export const editPreCrsRuleModalHeader = `${prefix}.editPreCrsRuleModalHeader`;
export const createPostCrsRuleModalHeader = `${prefix}.createPostCrsRuleModalHeader`;
export const editPostCrsRuleModalHeader = `${prefix}.editPostCrsRuleModalHeader`;
export const editCrsRuleModalHeader = `${prefix}.editCrsRuleModalHeader`;
export const wafGroupBreadcrumbTitle = `${prefix}.wafGroupBreadcrumbTitle`;
export const wafRuleBreadcrumbTitle = `${prefix}.wafRuleBreadcrumbTitle`;
export const wafDataFileModalBreadcrumbTitle = `${prefix}.wafDataFileModalBreadcrumbTitle`;
export const wafPolicyPsgModalBreadcrumbTitle = `${prefix}.wafPolicyPsgModalBreadcrumbTitle`;
export const wafPsmLocationModalBreadcrumbTitle = `${prefix}.wafPsmLocationModalBreadcrumbTitle`;
export const wafPsmRuleModalBreadcrumbTitle = `${prefix}.wafPsmRuleModalBreadcrumbTitle`;
export const wafPsmMatchElementModalBreadcrumbTitle = `${prefix}.wafPsmMatchElementModalBreadcrumbTitle`;
export const allowListRuleModalBreadcrumbTitle = `${prefix}.allowListRuleModalBreadcrumbTitle`;

export const ENGLISH = {
    [addExceptionModalHeader]: 'Add Exception',
    [editExceptionModalHeader]: 'Edit Exception',
    [wafExcludeListEntryBreadcrumbTitle]: 'Exception',
    [createPreCrsGroupModalHeader]: 'Create Pre-CRS Group',
    [editPreCrsGroupModalHeader]: 'Edit Pre-CRS Group',
    [createPostCrsGroupModalHeader]: 'Create Post-CRS Group',
    [editPostCrsGroupModalHeader]: 'Edit Post-CRS Group',
    [editCrsGroupModalHeader]: 'Edit CRS Group',
    [createPreCrsRuleModalHeader]: 'Create Pre-CRS Rule',
    [editPreCrsRuleModalHeader]: 'Edit Pre-CRS Rule',
    [createPostCrsRuleModalHeader]: 'Create Post-CRS Rule',
    [editPostCrsRuleModalHeader]: 'Edit Post-CRS Rule',
    [editCrsRuleModalHeader]: 'Edit CRS Rule',
    [wafGroupBreadcrumbTitle]: 'CRS Group',
    [wafRuleBreadcrumbTitle]: 'CRS Rule',
    [wafDataFileModalBreadcrumbTitle]: 'File',
    [wafPolicyPsgModalBreadcrumbTitle]: 'Positive Security',
    [wafPsmLocationModalBreadcrumbTitle]: 'Location',
    [wafPsmRuleModalBreadcrumbTitle]: 'Argument Rule',
    [wafPsmMatchElementModalBreadcrumbTitle]: 'Match Element',
    [allowListRuleModalBreadcrumbTitle]: 'Allow List Rule',
};
