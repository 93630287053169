/**
 * @module LoginModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import './avi-progress-bar.component.less';

/**
 * @desc Progress bar component, basically a wrapper around the clarity progress bar with some
 *     additional props for labels.
 * @author alextsg
 */
@Component({
    selector: 'avi-progress-bar',
    templateUrl: './avi-progress-bar.component.html',
})
export class AviProgressBarComponent {
    /**
     * Completion percentage (out of 100).
     */
    @Input()
    public percentage = 0;

    /**
     * Text shown above the progress bar.
     */
    @Input()
    public header = '';

    /**
     * Text shown next to the progress percentage.
     */
    @Input()
    public message = '';

    /**
     * If true, makes the height of the progress bar 4px. Ideal for the application transition
     * progress bar or progress bars in cards.
     */
    @Input()
    public small = false;

    /**
     * If true, hides the percentage.
     */
    @Input()
    public hidePercentage = false;
}
