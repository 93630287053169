/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular, { copy } from 'angular';
import { isUndefined } from 'underscore';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';
import { Constructor } from '../../../declarations/globals.d';
import { withMessageMapMixin } from '../mixins';

export interface IMessageBaseArgs<T> {
    /**
     * Type of ConfigItem corresponding to Schema.
     */
    objectType?: string;

    /**
     * Name of the field the ConfigItem is being set to.
     */
    fieldName?: string;

    /**
     * Default configuration used on creation of the ConfigItem.
     */
    defaultConfig?: Partial<T> | T;

    /**
     * Config data to be set on data.config. Object form only.
     */
    config?: T;

    /**
     * If true, skips data transformation.
     */
    isClone?: boolean;

    /**
     * If this is defined, convert only these fields to ConfigItems and leave other message fields
     * as is. Used as a migration step so that not all child config messages need to be converted to
     * ConfigItems.
     */
    whitelistedFields?: string[];
}

export interface IMessageItemData<T> {
    defaultConfig_: T | Partial<T>;
    config: T;
}

/**
 * @description
 *     Base ConfigItem class. Intended to mirror protobuf objects (ex. Vip, DnsInfo) used in
 *     configuration. This is a base class that MessageItem and RepeatedMessageItem extend from.
 *     It's an abtrasct class that isn't intended to be used directly.
 *     You can think of this class as a leaf node that does not contain nested MessageItems in its
 *     config.
 * @author alextsg
 */
export class MessageBase<T> extends withMessageMapMixin(AjsDependency) {
    /**
     * Data object consisting of the defaultConfig and the config.
     */
    public data: IMessageItemData<T>;

    /**
     * The message name in the protobuf.
     */
    protected readonly objectType: string;

    /**
     * The field name that this MessageBase is set to.
     */
    protected fieldName_: string;

    /** @constructor */
    constructor(args: IMessageBaseArgs<T> = {}) {
        // @ts-expect-error
        super(args);

        const {
            objectType,
            fieldName = '',
            defaultConfig,
            config,
            isClone = false,
        } = args;

        this.objectType = objectType || this.objectType;

        if (isUndefined(this.objectType)) {
            throw new Error(`${this} is missing an objectType`);
        }

        this.fieldName_ = fieldName;

        const parsedDefaultConfig: T | Partial <T> = defaultConfig ||
            this.defaultConfigOverride_ ||
            this.defaultValuesConfig ||
            this.emptyConfig;

        this.data = {
            defaultConfig_: parsedDefaultConfig,
            config: this.emptyConfig,
        };

        this.updateConfig(config || parsedDefaultConfig, isClone);
    }

    /**
     * Getter function for an empty config.
     */
    public get emptyConfig(): T { // eslint-disable-line class-methods-use-this
        return {} as unknown as T;
    }

    /**
     * Getter function for the config to override the message default.
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<T> {
        return null;
    }

    /**
     * Getter function for the default configuration from defaultValues.
     */
    public get defaultValuesConfig(): Partial<T> | T {
        const defaultValues = this.getAjsDependency_('defaultValues');

        return defaultValues.getDefaultItemConfigByType(this.objectType.toLowerCase());
    }

    /**
     * Getter for objectType.
     */
    public get messageType(): string {
        return this.objectType;
    }

    /**
     * Getter function for the config data.
     */
    public get config(): T {
        return this.data.config;
    }

    /**
     * Returns the config object.
     */
    public getConfig(): T {
        return this.config;
    }

    /**
     * Creates a new ConfigItem with the same config data. Used when making a save request.
     */
    public clone(): this {
        // eslint-disable-next-line no-extra-parens
        return new (this.constructor as Constructor<this>)(this.getCloneArgs());
    }

    /**
     * Overwrite to modify config data before it gets sent in the save request. Receives flat
     * config data (without any ConfigItem instances) as an input and should also return flat
     * config data.
     */
    public dataToSave(data: T): T { // eslint-disable-line class-methods-use-this
        return data;
    }

    /**
     * Updates the ConfigItem's config with new config data, then calls
     * modifyConfigDataAfterLoad to allow modifying config data as ConfigItem instances.
     */
    public updateConfig(newConfig: T | Partial<T>, skipDataTransformation = false): void {}

    /**
     * Returns the flat config data (without any ConfigItem instances).
     */
    public flattenConfig(bypassCheck: boolean): T {
        return angular.copy(this.config);
    }

    /**
     * Returns the fieldName_ value.
     */
    public getFieldName(): string {
        return this.fieldName_;
    }

    /**
     * Returns arguments used in cloning the MessageBase.
     */
    protected getCloneArgs(): IMessageBaseArgs<T> {
        const config = angular.copy(this.flattenConfig(true));

        return {
            objectType: this.objectType,
            fieldName: this.fieldName_,
            config,
            isClone: true,
        };
    }

    /**
     * Returns a copy of the default config.
     */
    protected getDefaultConfig(): this['data']['defaultConfig_'] {
        return copy(this.defaultConfig_);
    }

    /**
     * Getter function for the defaultConfig.
     */
    // eslint-disable-next-line no-underscore-dangle
    protected get defaultConfig_(): Partial<T> | T {
        // eslint-disable-next-line no-underscore-dangle
        return this.data.defaultConfig_;
    }

    /**
     * Overwrite to modify config data before it gets set in ConfigItems. Receives flat config
     * data (without any ConfigItem instances) as an input and should also return flat config
     * data.
     */
    protected dataAfterLoad(data: T): T { // eslint-disable-line class-methods-use-this
        return data;
    }
}

MessageBase.ajsDependencies = [
    '$injector',
    'defaultValues',
    'schemaService',
];
