/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-geo-profile-list';

const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const entriesLabel = `${prefix}.entriesLabel`;

export const ENGLISH = {
    [headerLabel]: 'Geo Profile',
    [entriesLabel]: 'Entries (priority)',
};
