/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'ip-addr-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const clientIpAddressLabel = `${prefix}.clientIpAddressLabel`;

export const ENGLISH = {
    [clientIpAddressLabel]: 'Client IP Address',
};
