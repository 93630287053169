/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'geo-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const editGeoProfileHeader = `${prefix}.editGeoProfileHeader`;
export const newGeoProfileHeader = `${prefix}.newGeoProfileHeader`;
export const entriesHeader = `${prefix}.entriesHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const filenameInputLabel = `${prefix}.filenameInputLabel`;
export const filenameInputPlaceholder = `${prefix}.filenameInputPlaceholder`;
export const priorityInputLabel = `${prefix}.priorityInputLabel`;
export const formatInputLabel = `${prefix}.formatInputLabel`;
export const formatInputPlaceholder = `${prefix}.formatInputPlaceholder`;
export const removeFileTitle = `${prefix}.removeFileTitle`;
export const addEntryBtnLabel = `${prefix}.addEntryBtnLabel`;
export const saveBtnLabel = `${prefix}.saveBtnLabel`;

export const ENGLISH = {
    [editGeoProfileHeader]: 'Edit Geo Profile: {0}',
    [newGeoProfileHeader]: 'New Geo Profile: {0}',
    [entriesHeader]: 'Entries',
    [nameInputLabel]: 'Name',
    [filenameInputLabel]: 'Filename',
    [filenameInputPlaceholder]: 'Select File',
    [priorityInputLabel]: 'Priority',
    [formatInputLabel]: 'Format',
    [formatInputPlaceholder]: 'Select Format',
    [removeFileTitle]: 'Remove File',
    [addEntryBtnLabel]: '+ Add Entry',
    [saveBtnLabel]: 'Save',
};
