/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IIpAddr,
    IpAddrType,
} from 'generated-types';

import {
    ip as ipRegex,
    ipv6 as ipv6Regex,
} from './regex.utils';

/**
 * Returns IP address string as IpAddr messageType object.
 */
export function getIpAddr(addr: string, type = IpAddrType.V4): IIpAddr {
    return {
        addr,
        type,
    };
}

/**
 * Parses and returns IP address as IIpAddr type.
 * @throws if IP string is invalid
 */
export function parseIp(ip: string): IIpAddr {
    if (ipRegex.test(ip)) {
        return getIpAddr(ip);
    }

    if (ipv6Regex.test(ip)) {
        return getIpAddr(ip, IpAddrType.V6);
    }

    throw new Error('Invalid IP');
}

/**
 * Parses IPv4/IPv6 string and returns it as IpAddr messageType object.
 */
export function parseIpString(ipString: string): IIpAddr {
    try {
        ipString = ipString.trim();

        return parseIp(ipString);
    } catch (e) {
        return null;
    }
}

/**
 * Parses comma separated IPv4/IPv6 address string and
 * returns it as IpAddr messageType object.
 *
 * Returns null if any of the address is invalid
 */
export function parseIpList(ipStringList: string): IIpAddr[] | null {
    try {
        const ipList = ipStringList.split(',')
            .map(ip => ip.trim())
            .filter(ip => ip);

        return ipList.map(parseIp);
    } catch (e) {
        return null;
    }
}

/**
 * Returns IP address string from IPAddr object.
 */
export function formatIpAddr(ipAddrObject: IIpAddr): string {
    return ipAddrObject ? ipAddrObject.addr : '';
}

/**
 *  Returns comma separated IP address string from list of IPAddr objects.
 */
export function formatIpAddrList(ipAddrList: IIpAddr[] | null): string {
    const ipAddresses = ipAddrList ? ipAddrList.map(formatIpAddr) : [];

    return ipAddresses.join();
}

/**
 *  Parses IPv4/IPv6 string with server:port and return server, port.
 */
export function parseServerWithPort(serverWithPort: string): [string, string] {
    let server;
    let port;

    if (serverWithPort.startsWith('[')) {
        [server, port] = serverWithPort.split(']');
        server = server.substring(1);
        port = port.substring(1);
    } else {
        [server, port] = serverWithPort.split(':');
    }

    return [server, port];
}
