/** @module L4PolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';

import { L4RuleMatchTarget } from 'object-types';

import {
    IMatchOption,
    IpAddrMatchComponent,
    ProtocolTypeMatchComponent,
} from 'ng/modules/match/components';

import {
    L4RuleActionSelectPoolConfigItem,
    L4RuleConfigItem,
} from 'ajs/modules/l4-policy/factories';

import { L10nService } from '@vmw/ngx-vip';

import {
    L4RulePortMatchComponent,
} from '../matches/l4-rule-port-match/l4-rule-port-match.component';

import * as l10n from './l4-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal for L4 Policy Rules.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'l4-rule-modal',
    templateUrl: './l4-rule-modal.component.html',
})
export class L4RuleModalComponent implements OnInit {
    @Input()
    public editable: L4RuleConfigItem;

    /**
     * Indicate if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Called to save rule data from the modal.
     */
    @Output()
    public onSubmit = new EventEmitter();

    /**
     * Called when the user wants to cancel editing and dismiss the modal.
     */
    @Output()
    public onCancel = new EventEmitter();

    public readonly l10nKeys = l10nKeys;

    /**
     * Message type string of L4RuleConfigItem.
     */
    public objectType: string;

    /**
     * List of all possible matches to be configured for the rule.
     */
    public matchOptions: IMatchOption[];

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Return the L4RuleActionSelectPool action message item.
     */
    public get l4RuleSelectPoolAction(): L4RuleActionSelectPoolConfigItem {
        return this.editable.config.action.config.select_pool;
    }

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.setMatchOptions();
    }

    /**
     * Triggered upon user attempting to save settings.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Triggered upon user exiting modal.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Set match options for use by match-adder.
     */
    private setMatchOptions(): void {
        this.matchOptions = [
            {
                component: IpAddrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.clientIpLabel),
                objectType: L4RuleMatchTarget,
                fieldName: 'client_ip',
            },
            {
                component: ProtocolTypeMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.protocolLabel),
                objectType: L4RuleMatchTarget,
                fieldName: 'protocol',
            },
            {
                component: L4RulePortMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.servicePortsLabel),
                objectType: L4RuleMatchTarget,
                fieldName: 'port',
            },
        ];
    }
}
