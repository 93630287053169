/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    DnsHealthMonitorConfigComponent,
    ExternalHealthMonitorConfigComponent,
    HealthMonitorAuthenticationComponent,
    HealthMonitorListPageComponent,
    HealthMonitorModalComponent,
    HttpHealthMonitorConfigComponent,
    NewlineConvertedTextareaComponent,
    RadiusHealthMonitorConfigComponent,
    SctpHealthMonitorConfigComponent,
    ServerMaintenanceModeComponent,
    SipHealthMonitorConfigComponent,
    TcpHealthMonitorConfigComponent,
    UdpHealthMonitorConfigComponent,
} from './components';

import { RbacModule } from '../rbac/rbac.module';
import { healthMonitorCollectionProvider } from './ajs-upgraded-providers';

const healthMonitorComponents = [
    DnsHealthMonitorConfigComponent,
    ExternalHealthMonitorConfigComponent,
    HealthMonitorModalComponent,
    HealthMonitorListPageComponent,
    RadiusHealthMonitorConfigComponent,
    SipHealthMonitorConfigComponent,
    HealthMonitorAuthenticationComponent,
    NewlineConvertedTextareaComponent,
    HttpHealthMonitorConfigComponent,
    ServerMaintenanceModeComponent,
    TcpHealthMonitorConfigComponent,
    UdpHealthMonitorConfigComponent,
    SctpHealthMonitorConfigComponent,
];

/**
 * @description
 *      Angular Module for Health Monitor related Components.
 *
 * @author Rajawant Prajapati
 */
@NgModule({
    declarations: [
        ...healthMonitorComponents,
    ],
    imports: [
        CommonModule,
        SharedModule,
        AviFormsModule,
        DataGridModule,
        RbacModule,
    ],
    providers: [
        healthMonitorCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class HealthMonitorModule { }
