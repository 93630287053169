/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import {
    severityHash,
    typeHash,
} from 'ajs/modules/core/constants/case.constants';

import { AviPermissionResource } from 'generated-types';
import { CaseModalComponent } from 'ng/modules/cportal';
import { CASE_ITEM_TOKEN } from './case.item.factory';

const { SEVERITY_4 } = severityHash;
const { BUG } = typeHash;

const allDataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: ['config'],
    },
};

/**
 * Ajs dependency token for Case Collection.
 */
export const CASE_COLLECTION_TOKEN = 'CaseCollection';

/**
 * @description Collection of Case items
 *
 * @author Ram Pal, Rajawant Prajapati
 */
export class CaseCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'albservices/case',
            defaultDataSources: 'list',
            objectType: 'Case',
            windowElement: CaseModalComponent,
            allDataSources,
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(CASE_ITEM_TOKEN);

        const { controllerVersion } = this.getAjsDependency_('initialDataService');

        this.serverDefaultsOverride_ = {
            version: controllerVersion,
            severity: SEVERITY_4,
            type: BUG,
        };
    }
}

CaseCollection.ajsDependencies = [
    CASE_ITEM_TOKEN,
    'initialDataService',
];
