/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module containing Application profile related components.
 * @module ApplicationProfileModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { VIPModule } from '@vmw/ngx-vip';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    ApplicationProfileListPageComponent,
} from './components';

import { applicationProfileCollectionProvider } from './ajs-upgraded-providers';

/**
 * @description Angular Module for Application Profile related components.
 *
 * @author Nisar Nadaf
 */
@NgModule({
    declarations: [
        ApplicationProfileListPageComponent,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        SharedModule,
        VIPModule,
        DataGridModule,
    ],
    providers: [
        applicationProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ApplicationProfileModule { }
