/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

/**
 * @ngdoc directive
 * @name UniqueValueInputValidationDirective
 * @description Directive for validating duplicate string values.
 *
 * @example
 *      <input
 *          name="dummy_field"
 *          [(ngModel)]="config.dummy_field"
 *          [uniqueValue]="enteredList"
 *          [currentIndex]="someNumber"
 *      />
 *
 * @author Sarthak Kapoor
 */

import {
    Directive,
    Input,
} from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { isUndefined } from 'underscore';
import {
    uniqueValueInputValidator,
} from '../../validators/unique-value-input-validation/unique-value-input-validation.validator';

@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: UniqueValueInputValidationDirective,
    }],
    selector: '[uniqueValue][formControlName],[uniqueValue][formControl],[uniqueValue][ngModel]',
})
export class UniqueValueInputValidationDirective implements Validator {
    /**
     * List of string values to check for duplicacy.
     */
    @Input('uniqueValue')
    public uniqueValueInputList: string[];

    /**
     * Holds the current index for this directive will mostly be applied
     * on a repeated message item.
     */
    @Input()
    public currentIndex: number;

    /**
     * @override
     * Checks if the model value is defined, since we don't want to invalidate a form with
     * empty input fields that are optional.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        const {
            currentIndex,
            uniqueValueInputList,
        } = this;

        return !isUndefined(control.value) ?
            uniqueValueInputValidator(uniqueValueInputList, currentIndex)(control) : null;
    }
}
