/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    SimpleChanges,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    DNSConfiguration,
    NTPConfiguration,
} from 'object-types';

import * as l10n from './system-settings-dns-ntp-card.l10n';
import './system-settings-dns-ntp-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for DNS/NTP Card in System Settings Page.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-dns-ntp-card',
    templateUrl: './system-settings-dns-ntp-card.component.html',
})
export class SystemSettingsDnsNtpCardComponent {
    /**
     * DNS Servers Configured for Controller.
     */
    @Input()
    public dnsResolversList: string[];

    /**
     * NTP Authentication Keys Count.
     */
    @Input()
    public ntpAuthenticationKeysCount: number;

    /**
     * NTP Servers list.
     */
    @Input()
    public ntpServersList: string[];

    /**
     * boolean flag for empty ntp servers list.
     */
    public isNtpServersListEmpty: boolean;

    /**
     * DnsResolvers for template usage.
     */
    public dnsResolvers: string;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * DNS object type for template usage.
     */
    public readonly dnsObjectType = DNSConfiguration;

    /**
     * NTP object type for temaplate usage.
     */
    public readonly ntpObjectType = NTPConfiguration;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnInit(): void {
        this.setIsNtpServersListEmpty();
        this.setDnsResolvers();
    }

    /**
     * @override
     * update state data using its setter.
     */
    public ngOnChanges(simpleChanges: SimpleChanges): void {
        const {
            dnsResolversList,
            ntpServersList,
        } = simpleChanges;

        if (ntpServersList && !ntpServersList.isFirstChange()) {
            this.setIsNtpServersListEmpty();
        }

        if (dnsResolversList && !dnsResolversList.isFirstChange()) {
            this.setDnsResolvers();
        }
    }

    /**
     * Track by index method for *ngFor template usage.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Sets boolean flag for empty ntp servers list.
     */
    private setIsNtpServersListEmpty(): void {
        this.isNtpServersListEmpty = !this.ntpServersList?.length;
    }

    /**
     * Sets DnsResolvers for template usage.
     */
    private setDnsResolvers(): void {
        this.dnsResolvers = this.dnsResolversList.join(', ');
    }
}
