/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'http-status-match';
const prefix = `${moduleName}.${componentName}`;

export const isInputLabel = `${prefix}.isInputLabel`;
export const notInputLabel = `${prefix}.notInputLabel`;
export const statusCodeInputLabel = `${prefix}.statusCodeInputLabel`;
export const statusCodeRangesInputLabel = `${prefix}.statusCodeRangesInputLabel`;

export const ENGLISH = {
    [isInputLabel]: 'Is',
    [notInputLabel]: 'Not',
    [statusCodeInputLabel]: 'Status Codes',
    [statusCodeRangesInputLabel]: 'Status Code Ranges',
};
