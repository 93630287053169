/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/healthscore.less';
import * as l10n from './aviHealthscorePopup.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc directive
 * @name aviHealthscorePopup
 * @param {Item} item
 * @param {*=} getRuntimeParams - Optional params to get the right runtime object of an Item.
 * @param {boolean} showHealthScore - If true, healthscore details can be displayed.
 * @param {boolean} showOperState - If true, oper state and reason can be displayed.
 * @author Aravindh Nagarajan
 * @restrict AE
 */
angular.module('aviApp').directive('aviHealthscorePopup', [
'VirtualService', 'Timeframe', 'getRuntimeDataFingerprint', 'l10nService',
function(VirtualService, Timeframe, getRuntimeDataFingerprint, l10nService) {
    function aviHealthscorePopupLink(scope) {
        scope.Timeframe = Timeframe;
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        /** Checks if there is resolution for an error if error exists of course. */
        scope.resolveAvailable = function({ reason_code_string: reasonCodeString }) {
            return reasonCodeString && _.contains([
                'SYSERR_RM_STATIC_NO_POOL',
                'SYSERR_RM_STATIC_POOL_EXHAUSTED',
                'SYSERR_RM_SE_MGMT_NO_STATIC_IPS_CONFIGURED',
                'SYSERR_RM_SE_MGMT_STATIC_IPS_EXHAUSTED',
                'SYSERR_RM_SRVR_MULT_NETWORKS',
                'SYSERR_RM_SRVR_NO_NETWORK',
                'SYSERR_RM_VIP_MULT_NETWORKS',
                'SYSERR_RM_VIP_NO_NETWORK',
            ], reasonCodeString);
        };

        /**
         * Opens resolve issue dialog for VS.
         * @param {ng.$q.event} $event
         **/
        scope.resolveIssue = function($event) {
            $event.stopPropagation();

            const vs = new VirtualService({ id: scope.item.id });

            vs.load()
                .then(() => vs.checkForIssues())
                .finally(() => vs.destroy());
        };

        scope.$watch(() => {
            const { item } = scope;

            return item && getRuntimeDataFingerprint(item.getRuntimeData(scope.getRuntimeParams));
        }, () => {
            scope.runtimeData = scope.item &&
                scope.item.getRuntimeData(scope.getRuntimeParams) || {};
        });
    }

    return {
        restrict: 'AE',
        scope: {
            item: '=',
            getRuntimeParams: '<',
            showHealthScore: '<',
            showOperState: '<',
        },
        template: require('../../views/components/healthscore-popup.partial.html'),
        link: aviHealthscorePopupLink,
    };
}]);
