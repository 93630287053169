/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module FullModalModule
 */

import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { IReferencedObject }
    from 'ng/modules/full-modal/services/referenced-objects-graph/referenced-objects-graph.types';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as l10n from './referenced-objects-dialog.l10n';
import './referenced-objects-dialog.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const BACKDROP_CLICK_EVENT = 'backdrop-click';

/**
 * @description Referenced objects dialog component.
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'referenced-objects-dialog',
    templateUrl: './referenced-objects-dialog.component.html',
})
export class ReferencedObjectsDialogComponent implements OnInit {
    /**
     * Holds object item name.
     */
    @Input()
    public objectItemName: string;

    /**
     * Holds referenced objects list.
     */
    @Input()
    public referencedObjects: IReferencedObject[];

    /**
     * Holds referenced objects count.
     */
    @Input()
    public referencedObjectsCount: number;

    /**
     * Called when the user cancels the dialog.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Called when the user clicks the submit button.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get global keys from source bundles for template usage
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Grid config to show the list of referenced objects.
     */
    public referencedObjectsGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Listens for keydown events. This is set here to stop propagating the escape key event to
     * other event listeners like FullModalConfig.
     */
    @HostListener('keydown.escape', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        event.stopPropagation();
        this.handleCancel();
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.referencedObjectsGridConfig = {
            fields: [
                {
                    id: 'object-name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: ({ name }: IReferencedObject) => name,
                },
                {
                    id: 'object-type',
                    label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                    transform: ({ type }: IReferencedObject) => type,
                },
            ],
            getRowId: (index: number): number => index,
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Called when the cds-modal detects a close event. Closing by clicking the backdrop is
     * disabled.
     */
    public handleClose($event: Event): void {
        const { detail } = $event as CustomEvent;

        if (detail !== BACKDROP_CLICK_EVENT) {
            this.handleCancel();
        }
    }

    /**
     * Getter for warning message.
     */
    public get warningMessage(): string {
        return this.l10nService.getMessage(
            l10nKeys.warningMessage,
            [this.objectItemName, this.referencedObjectsCount],
        );
    }

    /**
     * Getter for grid title.
     */
    public get gridTitle(): string {
        return this.l10nService.getMessage(
            l10nKeys.referenceObjectsGridHeaderLabel,
            [this.referencedObjectsCount],
        );
    }

    /**
     * Called to emit the onCancel event.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Called to emit the onSubmit event. Called when the submit button is clicked.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }
}
