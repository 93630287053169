/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { RoleService } from 'ajs/modules/accounts/services/role-service/role.service';
import { IRoleNode } from 'ajs/modules/accounts/services/role-service/role.types';
import { Privilege } from 'generated-types';

import {
    dynamicPermissionLevels,
    permissionDependencyHash,
} from './role-permission-setter.constant';

import './role-permission-setter.component.less';

import * as l10n from './role-permission-setter.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Enum for possible Permission for a role.
 */
enum Permission {
    CAN_READ = 'canRead',
    CAN_WRITE = 'canWrite',
}

/**
 * @description Component for Role Permission Setter.
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'role-permission-setter',
    templateUrl: './role-permission-setter.component.html',
})
export class RolePermissionSetterComponent {
    /**
     * Role node on which permission need to be configured.
     */
    @Input()
    public role: IRoleNode;

    /**
     * If True, Shows Assorted icon.
     */
    @Input()
    public showAssortedIcon = false;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of Privilege enum.
     */
    public readonly PrivilegeEnumHash = {
        NO_ACCESS: Privilege.NO_ACCESS,
        READ_ACCESS: Privilege.READ_ACCESS,
        WRITE_ACCESS: Privilege.WRITE_ACCESS,
    };

    /**
     * Enum variable to use in template.
     */
    public permission = Permission;

    constructor(
        private readonly l10nService: L10nService,
        private readonly roleService: RoleService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public updateRolePermission(
        event: Event,
        privilege: Privilege,
        isButtonDisabled?: boolean,
    ): void {
        event.stopPropagation();

        // cds-icon-button fires click event even when its disabled.
        if (isButtonDisabled) {
            return;
        }

        this.role.type = this.role.type === privilege ? undefined : privilege;
        this.role.assorted = false;

        // Update parent node status based on child node update.
        if (this.role.parentNode) {
            this.roleService.setRoleNodeType(this.role.parentNode);
        } else {
            const { type, children } = this.role;

            if (Array.isArray(children)) {
                children.forEach(child => child.type = type);
            }
        }
    }

    /**
     * Checks and sets permission type of the role node based on permissions defined in
     * {@link permissionDependencyHash} and {@link dynamicPermissionLevels}.
     */
    public isRoleDisabled(accessProp: Permission) : boolean {
        if (!this.role || !this.role.children) {
            return false;
        }

        if (this.role.children.length) {
            return !this.role.children.some(childRole => childRole[accessProp]);
        }

        const { permission } = this.role;
        const setters = permissionDependencyHash[permission];

        /**
         * For given permission, Check if that permission dependant on other permissions
         * and then add hint for that role.
         */
        if (setters) {
            const permissionNodeMap = this.roleService.currentPermissionNodeMap;
            let disable = false;

            setters.forEach((setter: string) => {
                const setterNode = permissionNodeMap[setter];

                if (setterNode.type === Privilege.NO_ACCESS) {
                    const permissionLevel = dynamicPermissionLevels[setter];

                    if (this.setRoleAccessLevel(this.role, accessProp, permissionLevel)) {
                        this.role.hint = this.l10nService.getMessage(
                            l10nKeys.permissionHintLabel,
                            [setterNode.name],
                        );
                        disable = true;
                        // If privilage is disabled then update assorted flag and type value.
                        this.roleService.setRoleNodeType(this.role.parentNode);
                    }
                }
            });

            if (disable) {
                return true;
            }
        }

        this.role[accessProp] = true;
        this.role.hint = '';

        return false;
    }

    /**
     * Sets specified role node access properties based on specified access level object.
     */
    private setRoleAccessLevel(
        roleNode: IRoleNode,
        accessType: string,
        permissionLevel: Record<string, boolean>,
    ): boolean {
        const accessValue = permissionLevel[accessType];

        if (!accessValue) {
            roleNode[accessType] = accessValue;

            if (!roleNode.canRead && roleNode.type === Privilege.READ_ACCESS ||
                !roleNode.canWrite && roleNode.type === Privilege.WRITE_ACCESS) {
                roleNode.type = Privilege.NO_ACCESS;
                roleNode.parentNode.type = '';
            }

            return true;
        }

        return false;
    }
}
