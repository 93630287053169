/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-port-match';
const prefix = `${moduleName}.${componentName}`;

export const portsLabel = `${prefix}.portsLabel`;
export const portsInputPlaceholder = `${prefix}.portsInputPlaceholder`;
export const portsHelperText = `${prefix}.portsHelperText`;

export const ENGLISH = {
    [portsLabel]: 'Ports',
    [portsInputPlaceholder]: 'Enter Ports',
    [portsHelperText]: 'Accepts single value, comma separate, and ranges',
};
