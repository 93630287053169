/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {
    ConnectedOverlayPositionChange,
    FlexibleConnectedPositionStrategy,
    Overlay,
    OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
    defaultPositionsPriority,
    positionMap,
    TooltipPosition,
} from '../avi-tooltip/avi-tooltip.constants';
import { AviTooltipTextComponent } from '../../components/avi-tooltip-text';

/**
 * @description Directive for displaying text tooltip over an origin element.
 * @author alextsg
 * @example
 *     <span aviTitle="I am a tooltip.">
 *         Origin element
 *     </span>
 */
@Directive({
    selector: '[aviTitle]',
})
export class AviTitleDirective implements OnInit, OnDestroy {
    /**
     * Text to display in the tooltip.
     */
    @Input('aviTitle')
    public text = '';

    /**
     * OverlayRef instance from Overlay.create.
     */
    private overlayRef: OverlayRef;

    /**
     * Subscription to the overlay position change event.
     */
    private overlayPositionSubscription: Subscription;

    /**
     * Position of the tooltip. Passed to AviTooltipTextComponent to render the caret.
     */
    private tooltipPosition: TooltipPosition = TooltipPosition.TOP_RIGHT_POSITION;

    /**
     * Instance of the AviTooltipTextComponent. Created when it's attached to the overlay.
     */
    private tooltipTextComponentRef: ComponentRef<AviTooltipTextComponent>;

    constructor(
        private overlay: Overlay,
        private elementRef: ElementRef,
    ) {}

    /**
     * Listener for the mouseenter event and attaches the tooltip.
     */
    @HostListener('mouseenter')
    public show(): void {
        const componentPortal = new ComponentPortal(AviTooltipTextComponent);
        const componentRef = this.overlayRef.attach(componentPortal);
        const { instance } = componentRef;

        instance.text = this.text;
        instance.tooltipPosition = this.tooltipPosition;
        this.tooltipTextComponentRef = componentRef;
    }

    /**
     * Listener for the mouseleave event. Detaches the tooltip.
     */
    @HostListener('mouseleave')
    public hide(): void {
        this.overlayRef.detach();
        this.tooltipTextComponentRef = undefined;
    }

    /**
     * @override
     * Sets the overlayRef and subscribes to position changes.
     */
    public ngOnInit(): void {
        const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay.position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions(defaultPositionsPriority)
            .withGrowAfterOpen(true);

        this.overlayRef = this.overlay.create({ positionStrategy });

        this.overlayPositionSubscription = positionStrategy.positionChanges
            .subscribe(this.handlePositionChange);
    }

    /**
     * @override
     * Detaches and destroys the tooltip. Unsubscribes from position changes observable.
     */
    public ngOnDestroy(): void {
        this.overlayRef.dispose();
        this.overlayPositionSubscription.unsubscribe();
    }

    /**
     * Handler for a position change. If a change happens, emits the onPositionChange EventEmitter.
     */
    private handlePositionChange = (positionChange: ConnectedOverlayPositionChange): void => {
        this.tooltipPosition = positionMap.get(positionChange.connectionPair);

        if (this.tooltipTextComponentRef) {
            this.tooltipTextComponentRef.instance.tooltipPosition = this.tooltipPosition;
            this.tooltipTextComponentRef.changeDetectorRef.detectChanges();
        }
    };
}
