/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export enum Permission {
    APPLICATION = 'APPLICATION',
    TEMPLATES = 'TEMPLATES',
    GROUPS = 'GROUPS',
    SECURITY = 'SECURITY',
    POLICIES = 'POLICIES',
    WAF = 'WAF',
    ERRORPAGE = 'ERRORPAGE',
    OPERATIONS = 'OPERATIONS',
    INFRASTRUCTURE = 'INFRASTRUCTURE',
    ADMINISTRATION = 'ADMINISTRATION',
    ACCOUNT = 'ACCOUNT',
    GSLB = 'GSLB'
}

export interface IBasicRoleNode {
    name: string;
    permission: string;
    children?: IBasicRoleNode[];
}

export interface IRoleNode {
    name: string;
    permission: string;
    type: string;
    assorted: boolean;
    children: IRoleNode[];
    parentNode: IRoleNode;
    canWrite: boolean;
    canRead: boolean;
    hint: string;
}
