/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name defaultChartConfig
 * @description
 *      Defines basic configuration for Chart component.
 */
angular.module('charts').constant('defaultChartConfig', {
    width: 0,
    height: 0,
    padding: {
        left: 0,
        right: 0,
        top: 1,
        bottom: 1,
    },
    axis: {
        x: {
            type: 'linear',
            visible: true,
            domain: [],
        },
        y0: {
            type: 'linear',
            visible: true,
            domain: [],
        },
        y1: {
            visible: false,
        },
    },
});
