/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'icap-opswat-logs';
const prefix = `${moduleName}.${componentName}`;

export const opswatHeaderLabel = `${prefix}.opswatHeaderLabel`;
export const threatIdLabel = `${prefix}.threatIdLabel`;
export const opswatViolationsSectionTitle = `${prefix}.icapOpswatViolationsSectionTitle`;
export const fileNameLabel = `${prefix}.fileNameLabel`;
export const threatNameLabel = `${prefix}.threatNameLabel`;
export const violationResolutionLabel = `${prefix}.violationResolutionLabel`;

export const ENGLISH = {
    [opswatHeaderLabel]: 'OPSWAT',
    [threatIdLabel]: 'Threat ID',
    [opswatViolationsSectionTitle]: 'ICAP Violations ({0})',
    [fileNameLabel]: 'File Name',
    [threatNameLabel]: 'Threat Name',
    [violationResolutionLabel]: 'Violation Resolution',
};
