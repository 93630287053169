/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CinematicModule
 */

import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
} from '@angular/core';
import { each } from 'underscore';
import { Subscription } from 'rxjs';
import { CinematicTabSectionComponent } from './cinematic-tab-section';
import './cinematic.component.less';

const ESCAPE_KEY_CODE = 27;

export interface ICinematicViewTab {
    title: string;
    id: string;
    tabComponent: CinematicTabSectionComponent;
}

/**
 * @description
 *     Component for displaying a configured cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'cinematic',
    templateUrl: './cinematic.component.html',
})
export class CinematicComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Title of the view, typically `${objectType}: ${name}`.
     * Optional with customized header.
     */
    @Input()
    public viewTitle?: string;

    /**
     * True if the spinner should be shown in the footer, disabling the action buttons.
     */
    @Input()
    public busy = false;

    /**
     * Optional flag to deactivate tabs in the cinematic view.
     */
    @Input()
    public deactivateTabs ?= false;

    /**
     * Optional class name for the cinematic view.
     */
    @Input()
    public viewClassName ?= '';

    /**
     * Flag to indicate whether onClose event is emitted when escape key is pressed.
     */
    @Input()
    public closeOnEscapeKeypress ?= true;

    /**
     * Called when the user wants to close the view.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Gets the list of projected CinematicTabSection components.
     */
    @ContentChildren(CinematicTabSectionComponent, { descendants: true })
    private tabSections: QueryList<CinematicTabSectionComponent>;

    /**
     * List of tabs to be rendered by the CinematicHeaderTabsComponent.
     */
    public tabs: ICinematicViewTab[] = [];

    /**
     * Tab ID of the active tab.
     */
    public activeTabId: ICinematicViewTab['id'];

    /**
     * Observer to track intersections of tab sections. This is used to change the active navigation
     * tab as the user scrolls through the view.
     */
    private tabObserver: IntersectionObserver;

    /**
     * Used to keep track of the intersection ratios of each tab section. We want the tab section
     * with the highest intersection ratio to be set as the active navigation tab.
     */
    private tabsIntersectionRatioHash: Record<string, number> = {};

    /**
     * Subscription to changes in the contentChildren, the list of CinematicTabSection
     * components.
     */
    private tabChangesSubscription: Subscription;

    constructor(private elementRef: ElementRef) {}

    /**
     * Getter for querylist of cinematic view tab sections.
     * Return content children in cinematic component.
     */
    private get tabSectionsList(): QueryList<CinematicTabSectionComponent> {
        return this.tabSections;
    }

    /**
     * Returns the native HTML element given a tab component.
     */
    public static getTabNativeElement(tab: CinematicTabSectionComponent): HTMLElement {
        return tab.elementRef.nativeElement;
    }

    /**
     * Listens for keydown events.
     * With Cinematic View, we might have a stack of views where only the last view is attached to
     * the DOM, so we check the isConnected property of the nativeElement to know if this view is
     * the one attached.
     * If the Escape key is pressed, cancel the current view on demand.
     */
    @HostListener('document:keydown', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        if (!this.elementRef.nativeElement.isConnected) {
            return;
        }

        if (event.which === ESCAPE_KEY_CODE) {
            this.handleClose();
        }
    }

    /** @override */
    public ngOnInit(): void {
        // Puts focus on the view, so that if a button was clicked to open this view, pressing
        // the enter key does not trigger that button.
        this.elementRef.nativeElement.querySelector('.cinematic').focus();
    }

    /**
     * @override
     * Creates a new IntersectionObserver which tracks tabs in the viewport to set the active tab.
     * Also checks for changes in the tabs, as some tabs may be rendered/un-rendered based on view
     * configuration.
     */
    public ngAfterViewInit(): void {
        const { nativeElement } = this.elementRef;

        this.tabObserver = new IntersectionObserver(this.registerIntersectionObserver, {
            root: nativeElement.querySelector('.cinematic__body'),
            threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
        });

        this.observeTabs(this.tabSectionsList);

        this.tabChangesSubscription = this.tabSectionsList.changes.subscribe(this.observeTabs);
    }

    /** @override */
    public ngOnDestroy(): void {
        if (this.tabObserver) {
            this.tabObserver.disconnect();
        }

        if (this.tabChangesSubscription) {
            this.tabChangesSubscription.unsubscribe();
        }
    }

    /**
     * Handler for exiting out of the view.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Called when selecting a tab. Scrolls to the DOM element with the tab's ID.
     */
    public handleSelectTab(tab: ICinematicViewTab): void {
        const tabElementRef = CinematicComponent.getTabNativeElement(tab.tabComponent);

        tabElementRef.scrollIntoView({ behavior: 'smooth' });
    }

    /**
     * Returns the ID of the tab that should be marked as active. If no tabs have an intersection
     * ratio greater than 0, returns undefined.
     */
    private getActiveTabId(): string | undefined {
        let highestIntersectionRatio = 0;
        let activeId: string;

        each(this.tabsIntersectionRatioHash, (ratio: number, id: string) => {
            if (ratio > highestIntersectionRatio) {
                highestIntersectionRatio = ratio;
                activeId = id;
            }
        });

        return activeId;
    }

    /**
     * Method passed to the IntersectionObserver as the callback.
     */
    private registerIntersectionObserver = (entries: IntersectionObserverEntry[]): void => {
        entries.forEach(entry => {
            const id = entry.target.getAttribute('id');

            this.tabsIntersectionRatioHash[id] = entry.intersectionRatio;
        });

        const activeId = this.getActiveTabId();

        if (activeId) {
            this.activeTabId = activeId;
        }
    };

    /**
     * Handler called when the number of tabs has changed. Recreates the list of tabs passed to the
     * CinematicHeaderTabs component and resets the observer that tracks tab scrolling.
     */
    private observeTabs = (
        tabSectionsList: QueryList<CinematicTabSectionComponent>,
    ): void => {
        this.tabObserver.disconnect();

        const tabs: ICinematicViewTab[] = [];

        tabSectionsList.forEach(tab => {
            const { tabId, tabTitle } = tab;
            const tabElement = CinematicComponent.getTabNativeElement(tab);

            this.tabObserver.observe(tabElement);

            tabs.push({
                title: tabTitle,
                id: tabId,
                tabComponent: tab,
            });
        });

        this.tabs = tabs;
    };
}
