/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Logs module
 * @module LogsModule
 * @preferred
 */

import angular from 'angular';

import {
    botDetectionLogListExpanderComponentOptions,
    botDetectionPolicyLogsComponentOptions,
    botE2eClassificationComponentOptions,
    BOT_DETECTION_LOG_LIST_EXPANDER_TOKEN,
    BOT_DETECTION_POLICY_LOG_TOKEN,
    BOT_E2E_CLASSIFICATION_TOKEN,
    icapLogHeadersComponentOptions,
    icapLogListExpanderComponentOptions,
    icapLogsComponentOptions,
    IcapNsxDefenderLogsComponentOptions,
    IcapOpswatLogsComponentOptions,
    ICAP_NSX_DEFENDER_LOGS_COMPONENT_TOKEN,
    ICAP_OPSWAT_LOGS_COMPONENT_TOKEN,
    l4DatascriptLogComponentOptions,
    logTimeframesFactory,
    outOfBandLogListExpanderComponentOptions,
    outOfBandLogsComponentOptions,
    OUT_OF_BAND_COMPONENT_TOKEN,
    OUT_OF_BAND_EXPANDER_COMPONENT_TOKEN,
} from '.';

import {
    L4_DATASCRIPT_LOG_COMPONENT_TOKEN,
} from './logs.tokens';

const logsModule = angular.module('avi/logs');

const factories = [{
    factory: logTimeframesFactory,
    name: 'logTimeframes',
}];

const components = [
    {
        name: 'icapLogs',
        options: icapLogsComponentOptions,
    },
    {
        name: 'icapLogListExpander',
        options: icapLogListExpanderComponentOptions,
    },
    {
        name: 'icapLogHeaders',
        options: icapLogHeadersComponentOptions,
    },
    {
        name: ICAP_OPSWAT_LOGS_COMPONENT_TOKEN,
        options: IcapOpswatLogsComponentOptions,
    },
    {
        name: ICAP_NSX_DEFENDER_LOGS_COMPONENT_TOKEN,
        options: IcapNsxDefenderLogsComponentOptions,
    },
    {
        name: OUT_OF_BAND_COMPONENT_TOKEN,
        options: outOfBandLogsComponentOptions,
    },
    {
        name: OUT_OF_BAND_EXPANDER_COMPONENT_TOKEN,
        options: outOfBandLogListExpanderComponentOptions,
    },
    {
        name: BOT_DETECTION_POLICY_LOG_TOKEN,
        options: botDetectionPolicyLogsComponentOptions,
    },
    {
        name: BOT_DETECTION_LOG_LIST_EXPANDER_TOKEN,
        options: botDetectionLogListExpanderComponentOptions,
    },
    {
        name: BOT_E2E_CLASSIFICATION_TOKEN,
        options: botE2eClassificationComponentOptions,
    },
    {
        name: L4_DATASCRIPT_LOG_COMPONENT_TOKEN,
        options: l4DatascriptLogComponentOptions,
    },
];

factories.forEach(({ name, factory }) => logsModule.factory(name, factory));

components.forEach(({ name, options }) => logsModule.component(name, options));
