/**
 * @module DialogModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-dismiss-changes-confirmation.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const enum AviDismissChangesWarningType {
    RESET_DEFAULTS,
    UNSAVED_CHANGES,
}

/**
 * @description Component to be used when user takes an action which would dismiss/reset settings.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'avi-dismiss-changes-confirmation',
    templateUrl: './avi-dismiss-changes-confirmation.component.html',
})
export class AviDismissChangesConfirmationComponent implements OnInit {
    /**
     * User of this component must decide warning type to use.
     */
    @Input()
    private readonly warningType: AviDismissChangesWarningType;

    /**
     * Event to be fired upon user Canceling.
     */
    @Output()
    private readonly onClose = new EventEmitter();

    /**
     * Event to be fired upon user Confirming continue.
     */
    @Output()
    private readonly onConfirm = new EventEmitter();

    /**
     * Warning to show to user.
     */
    public warning: string;

    /**
     * Header to display for popup.
     */
    public header: string;

    public readonly l10nKeys = l10nKeys;

    constructor(public readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handles user clicking -> Cancel.
     */
    public handleCancel(): void {
        this.onClose.emit();
    }

    /**
     * Handles user clicking -> Yes, Continue.
     */
    public handleContinue(): void {
        this.onConfirm.emit();
    }

    /** @override */
    public ngOnInit(): void {
        this.setWarningAndHeader();
    }

    /**
     * This component's user decides which type of warning to show, this method handles decision.
     */
    private setWarningAndHeader(): void {
        const { l10nService } = this;

        switch (this.warningType) {
            case AviDismissChangesWarningType.RESET_DEFAULTS:
                this.header = l10nService.getMessage(l10nKeys.resetToDefaultsHeader);
                this.warning = l10nService.getMessage(l10nKeys.resetToDefaultsWarning);
                break;

            case AviDismissChangesWarningType.UNSAVED_CHANGES:
                this.header = l10nService.getMessage(l10nKeys.unsavedChangesHeader);
                this.warning = l10nService.getMessage(l10nKeys.unsavedChangesWarning);
        }
    }
}
