/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './chart.less';
import {
    min as d3Min,
    max as d3Max,
    pointer as d3Pointer,
    select as d3Select,
    bisector as d3Bisector,
    scaleLinear as d3ScaleLinear,
    scaleTime as d3ScaleTime,
    scaleOrdinal as d3ScaleOrdinal,
} from 'd3';

const d3 = {
    min: d3Min,
    max: d3Max,
    pointer: d3Pointer,
    select: d3Select,
    bisector: d3Bisector,
    scaleLinear: d3ScaleLinear,
    scaleTime: d3ScaleTime,
    scaleOrdinal: d3ScaleOrdinal,
};

const chartFactory = (
    EventEmitter2,
    defaultChartConfig,
    Axis,
    Guideline,
    Lines,
    LinesStack,
    Tooltip,
    Average,
    Dominators,
) => {
    /**
     * Single chart point with x and y position - [x, y].
     * @typedef {number[]|string[]} ChartPoint
     */

    /**
     * Represents single series for line chart.
     * @typedef {Object} ChartSeries
     * @property {ChartPoint[]} data
     * @property {string?} id
     * @property  {string?} name
     * @property {string?} class
     * @property {string[]?} domain
     * @property {string[]?} dominators
     * @property {string?} unitsShort
     * @property {string?} unitsLong
     */

    /**
     * Configurable chart component.
     */
    class Chart extends EventEmitter2 {
        /**
         * Creates linear Y-scale function.
         * @param {number[]} range - Range values.
         * @returns {Function}
         */
        static createLinearScale(...range) {
            return d3.scaleLinear().range(range);
        }

        /**
         * Creates time scale function.
         * @param {number[]} range - Range values.
         * @returns {Function}
         */
        static createTimeScale(...range) {
            return d3.scaleTime().range(range);
        }

        /**
         * Creates ordinal Y-scale function.
         * @param {number?} min - Min range value.
         * @param {number?} max - Max range value.
         * @param {string[]?} domain - Domain for ordinal scale.
         * @returns {Function}
         */
        static createOrdinalScale(min = 0, max = 0, domain = []) {
            return d3.scaleOrdinal().domain(domain).range([min, max]);
        }

        /**
         * Creates D3 scale function based on type.
         * @param {string} type
         * @param {number} min
         * @param {number} max
         * @return {Function}
         */
        static createScale(type, min, max) {
            switch (type) {
                case Chart.SCALE_ORDINAL:
                    return Chart.createOrdinalScale(min, max);
                case Chart.SCALE_TIME:
                    return Chart.createTimeScale(min, max);
                default:
                    return Chart.createLinearScale(min, max);
            }
        }

        /**
         * Line chart constructor.
         * @param {Object?} config - Chart configuration. See defaultChartConfig for sample
         *      configuration.
         * @param {Tooltip?} tooltip - Tooltip instance to use with this Chart.
         */
        constructor(config = {}, tooltip = new Tooltip()) {
            super();

            this.config = angular.merge({}, defaultChartConfig, config);

            // populate chart config as properties
            this.parseConfig(this.config);

            /** @type {Function} */
            this.bisectX = d3.bisector(d => d[0]).left;

            /** @type {Function} */
            this.xScale = this.createXScale(this.xScaleType);

            /**
             * Left Y-axis D3 Scale function.
             * @type {Function}
             */
            this.y0Scale = this.createYScale(this.y0ScaleType);

            /**
             * Right Y-axis D3 Scale function.
             * @type {Function}
             */
            this.y1Scale = this.createYScale(this.y0ScaleType);

            /** @type {Lines} */
            this.lines = new Lines(this.xScale, this.y0Scale, this.y1Scale);

            /** @type {LineStack} */
            this.lineStack = new LinesStack(this.xScale, this.y0Scale);

            /**
             * Left axis series.
             * @type {ChartSeries[]}
             */
            this.series0 = [];

            /**
             * Right axis series.
             * @type {ChartSeries[]}
             */
            this.series1 = [];

            /**
             * Array of point sets of points from multiple series.
             * @type {ChartPoint[][]}
             */
            this.guidelinePoints = [];

            /** @type {?d3.Selection} */
            this.svg = null;
            /** @type {?d3.Selection} */
            this.eventRect = null;

            /** @type {Guideline} */
            this.guideline = new Guideline(this.height, this.xScale, this.y0Scale);

            /** @type {boolean} */
            this.paused = false;

            /** @type {number} */
            this.lastXValue = 0;

            /** @type {Axis} */
            this.xAxis = new Axis(this.xScale, Axis.BOTTOM);

            /** @type {Axis} */
            this.y0Axis = new Axis(this.y0Scale, Axis.LEFT);

            /** @type {Axis} */
            this.y1Axis = new Axis(this.y1Scale, Axis.RIGHT);

            /** @type {Tooltip} */
            this.tooltip = tooltip;

            /** @type {?Average} */
            this.average = null;

            /** @type {?Dominators} */
            this.dominators = null;

            /** @type {?d3.Selection} */
            this.linesContainer = null;

            /** @type {?d3.Selection} */
            this.stackContainer = null;

            // event handler bindings
            this.onMouseMove = this.onMouseMove.bind(this);
            this.onMouseOver = this.onMouseOver.bind(this);
            this.onMouseOut = this.onMouseOut.bind(this);
        }

        /**
         * Creates additional properties from config object.
         * @param {Object} config
         */
        parseConfig(config) {
            const { axis, padding } = config;

            this.width = config.width;
            this.height = config.height;

            this.paddingLeft = padding.left;
            this.paddingRight = padding.right;
            this.paddingTop = padding.top;
            this.paddingBottom = padding.bottom;

            this.xDomain = axis.x.domain;
            this.xScaleType = axis.x.type;

            this.y0Domain = axis.y0.domain;
            this.y0ScaleType = axis.y0.type;

            this.y1Domain = axis.y1.domain;
            this.y1ScaleType = axis.y1.type;
        }

        /**
         * Sets D3 scale function for Y-values.
         * @param {string?} scaleType
         */
        setY0Scale(scaleType = Chart.SCALE_LINEAR) {
            this.y0ScaleType = scaleType;
            this.y0Scale = this.createYScale(scaleType);
        }

        /**
         * Generates scaling function for Y-values.
         * @param {string?} type
         * @return {Function}
         */
        createYScale(type = Chart.SCALE_LINEAR) {
            const yRange = this.getYScaleRange();

            return Chart.createScale(type, yRange[0], yRange[1]);
        }

        /**
         * Generates scaling function for X-values.
         * @param {string?} type
         * @return {Function}
         */
        createXScale(type = Chart.SCALE_LINEAR) {
            const xRange = this.getXScaleRange();

            return Chart.createScale(type, xRange[0], xRange[1]);
        }

        /**
         * Returns range array for D3 Y-scale function.
         * @return {number[]}
         */
        getYScaleRange() {
            return [this.height - this.paddingTop - this.paddingBottom, 0];
        }

        /**
         * Returns range array for D3 X-scale function.
         * @return {number[]}
         */
        getXScaleRange() {
            return [0, this.width - this.paddingLeft - this.paddingRight];
        }

        /**
         * Updates domain function for left axis.
         * @param {number[]|string[]} domain
         */
        setY0Domain(domain) {
            this.y0Domain = domain;
        }

        /**
         * Updates domain function for right axis.
         * @param {number[]|string[]} domain
         */
        setY1Domain(domain) {
            this.y1Domain = domain;
        }

        /**
         * Returns domain for scale function based on provided domain or series.
         * @param {(number[]|string[])?} domain
         * @param {ChartSeries[]?} series
         * @returns {Array}
         */
        getYScaleDomain(domain, series) {
            let yDomain = [];

            if (Array.isArray(domain)) {
                yDomain = [...domain];
            }

            if (yDomain.length < 2) {
                yDomain = [
                    d3.min(series, ({ data }) => d3.min(data, d => d[1])),
                    d3.max(series, ({ data }) => d3.max(data, d => d[1])),
                ];
            }

            return yDomain;
        }

        /**
         * Updates D3 scale functions with new data.
         * @param {ChartSeries[]} series0
         * @param {ChartSeries[]?} series1
         */
        updateScale(series0, series1) {
            if (Array.isArray(series0) && series0.length) {
                let xDomain = [...this.xDomain];

                if (xDomain.length < 2) {
                    let series = series0;

                    if (Array.isArray(series1)) {
                        series = series0.concat(series1);
                    }

                    xDomain = [
                        d3.min(series, ({ data }) => d3.min(data, d => d[0])),
                        d3.max(series, ({ data }) => d3.max(data, d => d[0])),
                    ];
                }

                this.xScale.domain(xDomain);

                const yDomain = this.getYScaleDomain(this.y0Domain, series0);

                this.y0Scale.domain(yDomain);
            }

            if (Array.isArray(series1) && series1.length) {
                const yDomain = this.getYScaleDomain(this.y1Domain, series1);

                this.y1Scale.domain(yDomain);
            }
        }

        /**
         * Sets lines container visibility.
         * @param {boolean} visible
         */
        setLinesVisibility(visible = true) {
            this.linesContainer.classed('hidden', !visible);
        }

        /**
         * Sets stack container visibility.
         * @param {boolean} visible
         */
        setStackVisibility(visible = true) {
            this.stackContainer.classed('hidden', !visible);
        }

        /**
         * Sets right axis visibility.
         * @param {boolean} visible
         */
        setRightAxisVisibility(visible = true) {
            this.svg.selectAll('.axis-right').classed('hidden', !visible);
        }

        /**
         * Updates line chart with new data.
         * @param {ChartSeries[]} series0
         * @param {ChartSeries[]?} series1
         * @param {string[]?} colors
         */
        updateLines(series0, series1, colors = []) {
            const { xScale, y0Scale, y1Scale, svg } = this;

            if (svg) {
                this.setStackVisibility(false);
                this.setLinesVisibility(true);

                this.series0 = series0;
                this.series1 = series1;

                this.updateScale(series0, series1);

                const hasSeries1 = Array.isArray(series1) && series1.length > 0;

                this.guidelinePoints = [];
                this.guidelinePoints[0] = series0.map(s => {
                    if (s && Array.isArray(s.data)) {
                        return s.data.concat();
                    }

                    return [];
                });

                if (hasSeries1) {
                    this.guidelinePoints[1] = series1.map(s => {
                        if (s && Array.isArray(s.data)) {
                            return s.data.concat();
                        }

                        return [];
                    });
                }

                this.guideline.updateScales(xScale, y0Scale, y1Scale);

                const cs = colors.length > 1 ? colors : [];

                this.guideline.colors = cs;
                this.tooltip.colors = cs;
                this.updateGuideline(this.lastXValue);

                this.lines.updateScales(xScale, y0Scale, y1Scale);
                this.lines.update(series0, series1, colors);

                this.updateAxis();
                this.setRightAxisVisibility(hasSeries1);
            }
        }

        /**
         * Updates and displays stack line graph.
         * @param {ChartSeries[]} series
         * @param {string[]?} colors - Colors for each series.
         */
        updateStack(series, colors = []) {
            const { svg, lineStack, xScale, y0Scale } = this;

            if (svg) {
                this.setStackVisibility(true);
                this.setLinesVisibility(false);

                this.series0 = series;
                this.series1 = [];

                this.updateScale(series);

                lineStack.updateScale(xScale, y0Scale);

                const stackData = lineStack.update(series, colors);

                this.guidelinePoints = [];
                this.guidelinePoints[0] = stackData.map(set => set.map(d => [d.data.time, d[1]]));

                this.guideline.updateScales(xScale, y0Scale);
                this.guideline.colors = [colors];
                this.tooltip.colors = colors;
                this.updateGuideline(this.lastXValue);

                this.updateAxis();
                this.setRightAxisVisibility(false);
            }
        }

        /**
         * Updates axis elements.
         */
        updateAxis() {
            const {
                y0Scale,
                y1Scale,
                xScale,
                paddingBottom,
                paddingRight,
                paddingLeft,
                paddingTop,
            } = this;

            this.xAxis.updateScale(xScale);
            this.xAxis.position(paddingLeft, this.height - paddingBottom);

            this.y0Axis.updateScale(y0Scale);
            this.y0Axis.position(paddingLeft, paddingTop);

            this.y1Axis.updateScale(y1Scale);
            this.y1Axis.position(this.width - paddingRight, paddingTop);
        }

        /**
         * Displays horizontal line on the chart.
         * @param {number|string} value - Y-value to display line for.
         */
        drawAverage(value) {
            if (!this.average) {
                this.average = new Average();
                this.average.render(this.svg.node());
            }

            this.average.setValue(value);
            this.positionAverage();
        }

        /**
         * Renders dominating contributors to chart.
         * @param {string[]} data - ISO8601 timestamps for dominating contributors.
         * @param {number} step - Length in milliseconds for each dominating contributor.
         * @param {string} color
         */
        drawDominators(data, step, color) {
            if (!this.dominators) {
                this.dominators = new Dominators(this.xScale, this.y0Scale);

                const cont = this.svg.select('g.dominators-container');

                this.dominators.render(cont.node());
            }

            const points = this.series0[0].data;

            this.dominators.draw(data, points, step, color);
        }

        /**
         * Positions optional horizontal average line.
         */
        positionAverage() {
            if (this.average) {
                const x = this.paddingLeft;
                const y = this.y0Scale(this.average.value) + this.paddingTop;
                const width = this.width - this.paddingRight - x;

                this.average.position(x, y, width);
            }
        }

        /**
         * Resumes chart events.
         * @param {boolean?} emitEvent - Dispatch "resumed" event.
         */
        resume(emitEvent = true) {
            if (this.paused) {
                this.paused = false;
                this.addEvents();

                if (emitEvent) {
                    this.emit(Chart.RESUMED);
                }
            }
        }

        /**
         * Displays guideline on the chart.
         */
        showGuideline() {
            this.guideline.show();
            this.tooltip.show();
        }

        /**
         * Hides guideline on the chart.
         */
        hideGuideline() {
            this.guideline.hide();
            this.tooltip.hide();
        }

        /**
         * Pauses chart events.
         * @param {boolean?} showGuideline - Show guideline while paused.
         * @param {boolean?} emitEvent - Dispatch "paused" event.
         */
        pause(showGuideline = true, emitEvent = true) {
            this.paused = true;
            this.removeEvents();

            if (showGuideline) {
                this.showGuideline();
            }

            if (emitEvent) {
                this.emit(Chart.PAUSED);
            }
        }

        /**
         * Creates and renders new line chart.
         * @param {Element} container - HTML element were to render line chart.
         * @returns {d3.Selection} - SVG containing chart.
         */
        render(container) {
            if (!(container instanceof Element)) {
                throw new Error('Invalid container element for Chart.render');
            }

            this.destroy();
            this.svg = d3
                .select(container)
                .append('svg')
                .attr('class', 'chart');

            const x = this.paddingLeft;
            const y = this.paddingTop;

            this.svg
                .append('g')
                .attr('class', 'dominators-container');

            this.linesContainer = this.svg
                .append('g')
                .attr('class', 'lines-container hidden');
            this.lines.render(this.linesContainer.node());
            this.lines.position(x, y);

            this.stackContainer = this.svg
                .append('g')
                .attr('class', 'stack-container hidden');
            this.lineStack.render(this.stackContainer.node());
            this.lineStack.position(x, y);

            this.eventRect = this.svg.append('rect')
                .attr('class', 'event-rect')
                .attr('transform', `translate(${x}, ${y})`);

            const { axis } = this.config;

            if (axis.x.visible) {
                const bottomAxis = this.svg.append('g');

                bottomAxis.attr('class', 'axis-bottom');
                this.xAxis.render(bottomAxis.node());
            }

            if (axis.y0.visible) {
                const leftAxis = this.svg.append('g');

                leftAxis.attr('class', 'axis-left');
                this.y0Axis.render(leftAxis.node());
                this.y0Axis.setTicks(5);
            }

            if (axis.y1.visible) {
                const rightAxis = this.svg.append('g');

                rightAxis.attr('class', 'axis-right');
                this.y1Axis.render(rightAxis.node());
                this.y1Axis.setTicks(5);
            }

            this.guideline.render(this.svg.node());
            this.guideline.position(x, y);

            this.tooltip.render(container);

            this.resizeSvg();
            this.addEvents();

            this.svg.on('click', () => {
                if (this.paused) {
                    this.resume();
                } else {
                    this.pause();
                }
            });

            return this.svg;
        }

        /**
         * Updates SVG elements sizes and positions.
         */
        resizeSvg() {
            const {
                svg,
                guideline,
                dominators,
                eventRect,
                width,
                height,
                paddingLeft,
                paddingRight,
                paddingTop,
                paddingBottom,
                xScale,
                y0Scale,
            } = this;

            if (svg) {
                svg.attr('width', width).attr('height', height);
            }

            const offsetWidth = width - paddingLeft - paddingRight;
            const offsetHeight = height - paddingBottom - paddingTop;

            if (guideline) {
                guideline.setHeight(offsetHeight);
            }

            if (eventRect) {
                eventRect
                    .attr('width', offsetWidth)
                    .attr('height', offsetHeight);
            }

            setTimeout(() => {
                if (dominators) {
                    dominators.updateScale(xScale, y0Scale);
                    dominators.resize();
                }

                this.updateAxis();
                this.positionAverage();

                this.lines.resize();
                this.lineStack.resize();
            });
        }

        /**
         * Handles chart mouse move event.
         */
        onMouseMove(event) {
            const mx = d3.pointer(event, this.eventRect.node())[0];
            const xValue = Math.round(+this.xScale.invert(mx));

            this.updateGuideline(xValue);
        }

        /**
         * Handles chart mouse over event.
         */
        onMouseOver() {
            this.showGuideline();
        }

        /**
         * Handles chart mouse out event.
         */
        onMouseOut() {
            this.xAxis.hideSync();
            this.hideGuideline();
            this.emit(Chart.MOUSE_EXIT);
        }

        /**
         * Adds mouse events.
         */
        addEvents() {
            this.eventRect.on('mousemove', this.onMouseMove);
            this.eventRect.on('mouseover', this.onMouseOver);
            this.eventRect.on('mouseout', this.onMouseOut);
        }

        /**
         * Removes mouse events.
         */
        removeEvents() {
            this.eventRect.on('mousemove', null);
            this.eventRect.on('mouseover', null);
            this.eventRect.on('mouseout', null);
        }

        /**
         * Resumes mouse event inputs.
         */
        resumeSync() {
            this.resume(false);
        }

        /**
         * Pauses mouse event inputs.
         */
        pauseSync() {
            this.pause(true, false);
        }

        /**
         * Hides guideline.
         */
        hideSync() {
            this.hideGuideline();
        }

        /**
         * Offsets guideline horizontal position based on provided value.
         * @param {number} value
         */
        syncValue(value) {
            this.showGuideline();
            this.updateGuideline(value, false);
        }

        /**
         * Repositions guideline based on specified X-value.
         * @param {number} xValue
         * @param {boolean?} emitUpdate - False if update event should not be dispatched.
         */
        updateGuideline(xValue, emitUpdate = true) {
            this.lastXValue = xValue;

            const { guidelinePoints } = this;
            const [series0] = guidelinePoints;

            if (Array.isArray(series0) && series0.length > 0) {
                const [points] = series0;
                const index = this.bisectX(points, xValue);

                [xValue] = points[index];
                this.guideline.update(xValue, guidelinePoints);

                if (emitUpdate) {
                    this.emit(Chart.GUIDE_UPDATE, xValue);
                }

                this.xAxis.syncValue(xValue);
            }

            this.updateTooltip(xValue);
        }

        /**
         * Updates tooltip display and position.
         * @param {number} xValue - X-axis value where tooltip should display.
         */
        updateTooltip(xValue) {
            const { series0, series1 } = this;

            if (series0.length > 0) {
                const x = this.paddingLeft + this.xScale(xValue);
                const y = this.paddingTop;

                this.tooltip.position(x, y);
                this.tooltip.update(xValue, series0, series1);
            }
        }

        /**
         * Resizes chart based on width and height specified.
         * @param {number} width
         * @param {number} height
         */
        resize(width, height) {
            const { config } = this;

            this.width = width;
            this.height = height;

            config.width = width;
            config.height = height;

            this.xScale.range(this.getXScaleRange());
            this.y0Scale.range(this.getYScaleRange());

            this.resizeSvg();
        }

        /**
         * Removes all chart elements to be garbage collected.
         */
        destroy() {
            if (this.svg) {
                this.removeEvents();
                this.svg.remove();

                this.svg = null;
                this.eventRect = null;
            }
        }
    }

    Chart.GUIDE_UPDATE = 'guideUpdate';
    Chart.MOUSE_EXIT = 'mouseExit';
    Chart.PAUSED = 'paused';
    Chart.RESUMED = 'resumed';
    Chart.SCALE_TIME = 'time';
    Chart.SCALE_LINEAR = 'linear';
    Chart.SCALE_ORDINAL = 'ordinal';

    return Chart;
};

chartFactory.$inject = [
    'EventEmitter2',
    'defaultChartConfig',
    'Axis',
    'Guideline',
    'Lines',
    'LineStack',
    'ChartTooltip',
    'Average',
    'Dominators',
];

angular.module('charts').factory('Chart', chartFactory);
