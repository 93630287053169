/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import {
    PoolGroupDeploymentPolicyCollection,
} from 'ajs/modules/pool/factories/pool-group-deployment-policy.collection.factory';

import {
    PoolGroupDeploymentPolicy,
} from 'ajs/modules/pool/factories/pool-group-deployment-policy.item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './pool-group-deployment-policy-list-page.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TPoolGroupDeploymentPolicyCollection = typeof PoolGroupDeploymentPolicyCollection;

/**
 * @description pool group deployment policy list page.
 * @author Nitesh Kesakar
 */
@Component({
    selector: 'pool-group-deployment-policy-list-page',
    templateUrl: './pool-group-deployment-policy-list-page.component.html',
})
export class PoolGroupDeploymentPolicyListPageComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Configuration object to display poolGroupDeploymentPolicy grid.
     */
    public poolGroupDeploymentPolicyGridConfig: IAviCollectionDataGridConfig;

    /**
     * poolGroupDeploymentPolicy Collection instance.
     */
    public readonly poolGroupDeploymentPolicyCollection: PoolGroupDeploymentPolicyCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(PoolGroupDeploymentPolicyCollection)
        PoolGroupDeploymentPolicyCollection: TPoolGroupDeploymentPolicyCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.poolGroupDeploymentPolicyCollection = new PoolGroupDeploymentPolicyCollection();
    }

    /**
     * @override
     * Set grid config options.
     */
    public ngOnInit(): void {
        const { objectName } = this.poolGroupDeploymentPolicyCollection;

        this.poolGroupDeploymentPolicyGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.poolGroupDeploymentPolicyCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: (row: PoolGroupDeploymentPolicy) => row.getName(),
            }, {
                id: 'target-test-traffic-ratio',
                label: this.l10nService.getMessage(l10nKeys.columnTitleTargetTrafficRatio),
                transform: (row: PoolGroupDeploymentPolicy) => row.config.target_test_traffic_ratio,
            }, {
                id: 'evaluation-duration',
                label: this.l10nService.getMessage(l10nKeys.columnTitleDuration),
                transform: (row: PoolGroupDeploymentPolicy) => row.config.evaluation_duration,
            }, {
                id: 'test-traffic-ratio-rampup',
                label: this.l10nService.getMessage(l10nKeys.columnTitleRampupRatio),
                transform: (row: PoolGroupDeploymentPolicy) => row.config.test_traffic_ratio_rampup,
            }, {
                id: 'rules',
                label: this.l10nService.getMessage(l10nKeys.columnTitleRules),
                transform: (row: PoolGroupDeploymentPolicy) => `${row.rulesCount}`,
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: PoolGroupDeploymentPolicy) => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.poolGroupDeploymentPolicyCollection.destroy();
    }
}
