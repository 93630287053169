/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'se-engine-group';

export const vCenterModalBreadcrumbTitle = `${prefix}.vCenterModalBreadcrumbTitle`;

export const ENGLISH = {
    [vCenterModalBreadcrumbTitle]: 'vCenter',
};
