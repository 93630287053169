/**
 * Module containing data models.
 * @preferred
 * @module DataModelModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    Base,
    CollDataSource,
    Collection,
    DataSource,
    InventoryCollDataSource,
    InventoryDataTransformer,
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    IpAddrRangeConfigItem,
    Item,
    LimitedListCollDataSource,
    ListCollDataSource,
    ListCollDataSourceWithNameContainsSearchParam,
    ListDataTransformer,
    MacroStackFactory,
    MessageBase,
    MessageItem,
    ObjectTypeItem,
    RepeatedMessageItem,
    RevisedDataTransformer,
    RoleFilterMatchLabelConfigItem,
    ROLE_FILTER_MATCH_LABEL_CONFIG_ITEM_TOKEN,
    UpdatableBase,
    UpdatableItem,
} from './factories';
import { MessageMapService } from './services';
import {
    withEditChildMessageItemMixin,
    withEditMixin,
    withMessageMapMixin,
} from './mixins';
import { LIST_COLL_DATA_SOURCE_WITH_NAME_CONTAINS_SEARCH_PARAM } from './data-model.tokens';

const dataModelModule = angular.module('avi/dataModel');

const factories = [
    {
        itemClass: Collection,
        name: 'Collection',
    },
    {
        itemClass: Item,
        name: 'Item',
    },
    {
        itemClass: UpdatableBase,
        name: 'UpdatableBase',
    },
    {
        itemClass: Base,
        name: 'Base',
    },
    {
        itemClass: MacroStackFactory,
        name: 'MacroStackFactory',
    },
    {
        itemClass: MessageBase,
        name: 'MessageBase',
    },
    {
        itemClass: RepeatedMessageItem,
        name: 'RepeatedMessageItem',
    },
    {
        itemClass: MessageItem,
        name: 'MessageItem',
    },
    {
        itemClass: ObjectTypeItem,
        name: 'ObjectTypeItem',
    },
    {
        itemClass: UpdatableItem,
        name: 'UpdatableItem',
    },
    {
        itemClass: ListDataTransformer,
        name: 'ListDataTransformer',
    },
    {
        itemClass: InventoryCollDataSource,
        name: 'InventoryCollDataSource',
    },
    {
        itemClass: InventoryDataTransformer,
        name: 'InventoryDataTransformer',
    },
    {
        itemClass: RevisedDataTransformer,
        name: 'RevisedDataTransformer',
    },
    {
        itemClass: LimitedListCollDataSource,
        name: 'LimitedListCollDataSource',
    },
    {
        itemClass: ListCollDataSource,
        name: 'ListCollDataSource',
    },
    {
        itemClass: CollDataSource,
        name: 'CollDataSource',
    },
    {
        itemClass: DataSource,
        name: 'DataSource',
    },
    {
        itemClass: IpAddrConfigItem,
        name: 'IpAddrConfigItem',
    },
    {
        itemClass: IpAddrPrefixConfigItem,
        name: 'IpAddrPrefixConfigItem',
    },
    {
        itemClass: IpAddrRangeConfigItem,
        name: 'IpAddrRangeConfigItem',
    },
    {
        itemClass: RoleFilterMatchLabelConfigItem,
        name: ROLE_FILTER_MATCH_LABEL_CONFIG_ITEM_TOKEN,
    },
    {
        itemClass: ListCollDataSourceWithNameContainsSearchParam,
        name: LIST_COLL_DATA_SOURCE_WITH_NAME_CONTAINS_SEARCH_PARAM,
    },
];

const services = [
    {
        name: 'messageMapService',
        service: MessageMapService,
    },
];

const mixins = [
    {
        mixin: withMessageMapMixin,
        name: 'withMessageMapMixin',
    },
    {
        mixin: withEditMixin,
        name: 'withEditMixin',
    },
    {
        mixin: withEditChildMessageItemMixin,
        name: 'withEditChildMessageItemMixin',
    },
];

factories.forEach(({ name, itemClass }) => {
    initAjsDependency(dataModelModule, 'factory', name, itemClass);
});

services.forEach(({ name, service }) => dataModelModule.service(name, service));

mixins.forEach(({ name, mixin }) => {
    initAjsDependency(dataModelModule, 'factory', name, mixin);
});
