/**
 * Module containing dataGrid related components
 * @preferred
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
    ClrCheckboxModule,
    ClrDatagridModule,
    ClrDropdownModule,
} from '@clr/angular';

import { VIPModule } from '@vmw/ngx-vip';
import { DialogModule } from '../dialog/dialog.module';
import { SharedModule } from '../../shared/shared.module';

import {
    AviCollectionDataGridComponent,
    AviDataGridBaseComponent,
    AviDataGridColumnSelectionComponent,
    AviDataGridComponent,
    AviDiffGridComponent,
    AviDynamicDataGridComponent,
    MoveDataGridRowComponent,
    MoveDataGridRowService,
} from '.';

import { AviDiffGridRowsPipe } from './components/avi-diff-grid/avi-diff-grid-rows.pipe';

const dataGridComponents = [
    AviDataGridColumnSelectionComponent,
    AviDataGridComponent,
    AviDataGridBaseComponent,
    AviDiffGridComponent,
    AviDynamicDataGridComponent,
    AviCollectionDataGridComponent,
    MoveDataGridRowComponent,
];

const dataGridPipes = [
    AviDiffGridRowsPipe,
];

@NgModule({
    declarations: [
        ...dataGridComponents,
        ...dataGridPipes,
    ],
    exports: [
        AviCollectionDataGridComponent,
        AviDataGridComponent,
        AviDiffGridRowsPipe,
        AviDynamicDataGridComponent,
        AviDiffGridComponent,
    ],
    imports: [
        ClrDatagridModule,
        ClrCheckboxModule,
        CommonModule,
        DragDropModule,
        FormsModule,
        SharedModule,
        DialogModule,
        VIPModule,
        ClrDropdownModule,
    ],
    providers: [
        MoveDataGridRowService,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
})
export class DataGridModule {}
