/**
 * @module TrafficCloneProfileModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { trafficCloneProfileCollectionProvider } from './ajs-upgraded-providers';

import { TrafficCloneProfilePageComponent } from '.';

const trafficCloneProfileComponents = [
    TrafficCloneProfilePageComponent,
];

/**
 * @description
 *
 *      Angular Module for Traffic Clone Profile related components.
 *
 * @author Harmeet Kaur
 */

@NgModule({
    declarations: [
        ...trafficCloneProfileComponents,
    ],
    imports: [
        AviFormsModule,
        SharedModule,
        BrowserAnimationsModule,
        DataGridModule,
    ],
    providers: [
        trafficCloneProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class TrafficCloneProfileModule {}
