/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './AlertConfigController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('AlertConfigController', AlertConfigController);

AlertConfigController.$inject = [
    '$scope',
    'CRUDGridConfig',
    'AlertConfigCollection',
    'stringService',
    'l10nService',
];

function AlertConfigController(
    $scope,
    CRUDGridConfig,
    AlertConfigCollection,
    stringService,
    l10nService,
) {
    /**
    * Get keys from source bundles for template usage
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.gridConfig = new CRUDGridConfig();
    $scope.collection = new AlertConfigCollection();

    $scope.gridConfig.collection = $scope.collection;
    $scope.gridConfig.fields = [{
        name: 'data.config.name',
        title: l10nService.getMessage(l10nKeys.columnTitleName),
        sortBy: 'name',
    }, {
        name: 'action',
        title: l10nService.getMessage(l10nKeys.columnTitleAlertAction),
        transform(row) {
            return row.getConfig().action_group_ref ?
                row.getConfig().action_group_ref.name() :
                l10nService.getMessage(l10nKeys.emptyDataLabel);
        },
    }, {
        name: 'category',
        title: l10nService.getMessage(l10nKeys.columnTitleType),
        transform(row) {
            return row.getConfig().category.enumeration();
        },
    }, {
        name: 'group',
        title: l10nService.getMessage(l10nKeys.columnTitleAlertGroup),
        transform(row) {
            return row.getConfig().source.enumeration();
        },
    }, {
        name: 'data.config.enabled',
        title: l10nService.getMessage(l10nKeys.columnTitleEnabled),
        sortBy: 'enabled',
        transform(row) {
            return row.getConfig().enabled.toString() === 'true' ?
                l10nService.getMessage(l10nKeys.trueText) :
                l10nService.getMessage(l10nKeys.falseText);
        },
    }, {
        name: 'obj',
        title: l10nService.getMessage(l10nKeys.columnTitleObject),
        template: '{{ ::config.templateFunctions.getObjectName(row.getConfig()) }}',
    }];

    const { objectName } = $scope.gridConfig.collection;

    $scope.gridConfig.id = `${objectName}-list-page`;

    $scope.gridConfig.expandedContainerTemplate =
        '<alert-config-grid-expander config="row.getConfig()"></alert-config-grid-expander>';

    $scope.gridConfig.expanderFunctions = {
        getEvents,
        getMetrics,
    };

    $scope.gridConfig.templateFunctions = {
        getObjectName,
    };

    /**
     * Template function to get the names of the events to be displayed in the grid.
     * @param  {object} rule - alert_rule object.
     * @return {string}
     */
    function getEvents(rule) {
        let separator = ', ';

        return _.map(rule.sys_event_rule, function(sysRule) {
            if (sysRule.not_cond === true) {
                separator = l10nService.getMessage(l10nKeys.notSeparator);
            }

            return sysRule.event_id.enumeration();
        }).join(separator);
    }

    /**
     * Template function to get the names of the metrics to be displayed in the grid.
     * @param  {object} rule - alert_rule object.
     * @return {string}
     */
    function getMetrics(rule) {
        return _.pluck(rule.metrics_rule, 'metric_id').join(', ');
    }

    /**
     * Template function to get the name of the object to be displayed in the grid.
     * @param  {AlertConfig} alert
     * @return {string} Name of object.
     */
    function getObjectName(config) {
        let output = 'N/A';

        if (config.obj_ref) {
            output = config.obj_ref.name();
        } else if (config.source === 'EVENT_LOGS') {
            output = l10nService.getMessage(l10nKeys.allInstancesObjectName);
        }

        return output;
    }

    $scope.$on('$destroy', function() {
        $scope.collection.destroy();
    });
}
