/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrCheckboxModule,
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrSpinnerModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { VIPModule } from '@vmw/ngx-vip';
import { AviFormsModule } from '../avi-forms/avi-forms.module';
import {
    customIpamDnsProfileCollectionProvider,
    ipamProfileCollectionProvider,
} from './ajs-upgraded-providers';

import {
    CustomIpamDnsProfileListPageComponent,
    CustomIpamDnsProfileModalComponent,
    CustomUsableSubnetsGridComponent,
    InfobloxUsableDomainsGridComponent,
    InfobloxUsableSubnetsGridComponent,
    InternalDnsServiceDomainsGrid,
    InternalIpamUsableNetworksGridComponent,
    IpamDnsAwsCredentialsDialogComponent,
    IpamDnsAwsProfileConfigComponent,
    IpamDnsAwsUsableNetworksGridComponent,
    IpamDnsAzureCredentialsDialogComponent,
    IpamDnsAzureProfileConfigComponent,
    IpamDnsCustomProfileConfigComponent,
    IpamDnsInfobloxCredentialsDialogComponent,
    IpamDnsInfobloxProfileConfigComponent,
    IpamDnsInternalProfileConfigComponent,
    IpamDnsOpenstackProfileConfigComponent,
    IpamDnsProfileListExpanderComponent,
    IpamDnsProfileListPageComponent,
    IpamDnsProfileModalComponent,
    UsableSubnetModalComponent,
} from '.';

const ipamProfileComponents = [
    CustomIpamDnsProfileModalComponent,
    UsableSubnetModalComponent,
    InfobloxUsableDomainsGridComponent,
    InfobloxUsableSubnetsGridComponent,
    InternalDnsServiceDomainsGrid,
    InternalIpamUsableNetworksGridComponent,
    IpamDnsAwsCredentialsDialogComponent,
    IpamDnsAwsProfileConfigComponent,
    IpamDnsAwsUsableNetworksGridComponent,
    IpamDnsAzureCredentialsDialogComponent,
    IpamDnsAzureProfileConfigComponent,
    IpamDnsCustomProfileConfigComponent,
    IpamDnsInfobloxCredentialsDialogComponent,
    IpamDnsInfobloxProfileConfigComponent,
    IpamDnsInternalProfileConfigComponent,
    IpamDnsOpenstackProfileConfigComponent,
    IpamDnsProfileModalComponent,
    IpamDnsProfileListPageComponent,
    IpamDnsProfileListExpanderComponent,
    CustomIpamDnsProfileListPageComponent,
    CustomUsableSubnetsGridComponent,
];

/**
 * @description
 *
 *      Angular Module for IPAM related components.
 *
 * @author Aravindh Nagarajan
 */
@NgModule({
    declarations: [
        ...ipamProfileComponents,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrCheckboxModule,
        ClrFormsModule,
        ClrInputModule,
        ClrRadioModule,
        CommonModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        ClrCheckboxModule,
        ClrRadioModule,
        ClrSpinnerModule,
        VIPModule,
    ],
    providers: [
        customIpamDnsProfileCollectionProvider,
        ipamProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class IpamModule {}
