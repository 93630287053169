/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @overview aviApp Angular directive to check privilege access using Auth service.
 */
angular.module('aviApp').directive('permissionAllowed', ['Auth', function(Auth) {
    function link(scope, element, attr, controller) {
        controller.init();
    }

    /**
     * Directive controller.
     * @param {angular.$element} $element - jqLite object wraped directive element.
     * @param {angular.$attrs} $attrs - Directive attributes.
     * @param {angular.$scope} $scope
     * @constructor
     */
    const Controller = function($element, $attrs, $scope) {
        /**
         * @type {angular.$element}
         * @private
         */
        this.$element_ = $element;

        /**
         * @type {angular.$attrs}
         * @private
         */
        this.$attrs_ = $attrs;

        this.$scope = $scope;

        /**
         * @type {string}
         * @private
         */
        this.privilegeResource_ = '';

        /**
         * @type {string}
         * @private
         */
        this.privilegeType_ = '';

        this.handlePermission_ = this.handlePermission_.bind(this);
    };

    Controller.$inject = ['$element', '$attrs', '$scope'];

    /**
     * Initializes the controller.
     */
    Controller.prototype.init = function() {
        const privilege = this.$attrs_['permissionAllowed'];

        if (angular.isUndefined(privilege) || privilege === '') {
            return true;
        }

        const privilegeParts = privilege.split(':');

        [this.privilegeResource_, this.privilegeType_] = privilegeParts;
        this.$scope.$watch(function() {
            return Auth.isPrivilegeAllowed(this.privilegeResource_, this.privilegeType_);
        }.bind(this), this.handlePermission_);
    };

    /**
     * Handles access privilege.
     * @param {boolean} allowed - Hide or show element based on permission.
     * @private
     */
    Controller.prototype.handlePermission_ = function(allowed) {
        if (allowed) {
            this.$element_.removeClass('hidden');
        } else {
            this.$element_.addClass('hidden');
        }
    };

    return {
        restrict: 'A',
        link,
        controller: Controller,
    };
}]);
