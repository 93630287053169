/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'role-filter';

export const addRoleFilterModalBreadcrumbTitle = `${prefix}.addRoleFilterModalBreadcrumbTitle`;

export const ENGLISH = {
    [addRoleFilterModalBreadcrumbTitle]: 'Label Filter',
};
