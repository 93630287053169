/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'safenet-luna-server-modal';
const prefix = `${moduleName}.${componentName}`;

export const addModalTitle = `${prefix}.addModalTitle`;
export const editModalTitle = `${prefix}.editModalTitle`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const hsmAddressInputLabel = `${prefix}.hsmAddressInputLabel`;
export const hsmAddressPlaceholder = `${prefix}.hsmAddressPlaceholder`;
export const serialNumberInputLabel = `${prefix}.serialNumberInputLabel`;
export const serialNumberPlaceholder = `${prefix}.serialNumberPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordPlaceholder = `${prefix}.passwordPlaceholder`;
export const serverCertificateInputLabel = `${prefix}.serverCertificateInputLabel`;
export const serverCertificatePlaceholder = `${prefix}.serverCertificatePlaceholder`;

export const ENGLISH = {
    [addModalTitle]: 'Add Thales Luna HSM Server',
    [editModalTitle]: 'Edit Thales Luna HSM Server',
    [generalSectionTitle]: 'General',
    [hsmAddressInputLabel]: 'HSM Address',
    [hsmAddressPlaceholder]: 'Enter HSM Address',
    [serialNumberInputLabel]: 'Serial Number',
    [serialNumberPlaceholder]: 'Enter Serial Number',
    [passwordInputLabel]: 'Password',
    [passwordPlaceholder]: 'Enter Password',
    [serverCertificateInputLabel]: 'Server Certificate',
    [serverCertificatePlaceholder]: 'Enter Server Certificate',
};
