/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './policy-grid-config.10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc factory
 * @name PolicyGridConfig
 * @description
 *     Class used as the blueprint for an orderedGrid config. An orderedGrid config should contain
 *     a collection, fields, singleactions, and multipleactions.
 * @example
 *     scope.policyPolicyGridConfig = new PolicyGridConfig({
 *         collection: scope.httpPolicyClass,
 *         fields: [{
 *             title: 'Index',
 *             name: 'index',
 *             template: '<span>{{ row.index }}</span>'
 *         }, {
 *             title: 'Enable',
 *             name: 'enable',
 *             template: `<checkbox ng-model="row.enable"></checkbox>`
 *         }, {
 *            title: 'Name',
 *            name: 'name',
 *            template: '<b>{{ row.name }}</b>'
 *        }],
 *        singleactions: [{
 *            title: 'Move rule',
 *            template: require('../move.tooltip.partial.html'),
 *            moveRuleUp: (rule, event) => {
 *                event.stopPropagation();
 *                scope.httpRequestClass.moveRule(rule, 'up');
 *            },
 *            moveRuleDown: (rule, event) => {
 *                event.stopPropagation();
 *                scope.httpRequestClass.moveRule(rule, 'down');
 *            }
 *        }, {
 *            title: 'Menu',
 *            template: require('../menu.tooltip.partial.html'),
 *            edit: rule => scope.editRule(rule),
 *            create: index => scope.addRule(index),
 *            remove: rule => scope.removeRule(rule)
 *        }]
 *    });
 */

const policyGridConfigFactory = (PolicyGridService, stringService, l10nService) => {
    return class PolicyGridConfig {
        constructor(args = {}) {
            l10nService.registerSourceBundles(dictionary);
            this.collection = args.collection;
            this.getRowId = args.getRowId || this._defaultGetRowId;
            this.fields = args.fields || this._getDefaultFields();
            this.singleactions = args.singleactions || this._getDefaultSingleactions();
            this.multipleactions = args.multipleactions || this._getDefaultMultipleactions();
            this.filterSets = args.filterSets || [];
            this.actions = angular.extend({}, this._getDefaultActions(), args.actions);
            this.controls = args.controls || {};
            this.expandTemplate = args.expandTemplate || this._getDefaultExpandTemplate();
        }

        /**
         * Returns an id for the row.
         * @param {Object} row - Row object.
         */
        _defaultGetRowId(row) {
            return row.index;
        }

        /**
         * Returns a set of default actions.
         * @return {Object}
         */
        _getDefaultActions() {
            return {
                handleDragAndDropChange: (oldIndex, newIndex) => {
                    this.collection.handleDragAndDropChange(oldIndex, newIndex);
                },

                /**
                 * Returns true if a specified searchTerm is found in the rule's match or action
                 * configuration. First checks the name for a match, then the match object, then
                 * the action object.
                 * @param {Object} row - Policy rule object.
                 * @param {string} searchTerm - Search string to match against.
                 * @return {boolean}
                 */
                search: (row, searchTerm) => {
                    if (stringService.contains(row.name, searchTerm)) {
                        return true;
                    }

                    let inMatch;

                    if (!_.isEmpty(row.match)) {
                        const matchJson = JSON.stringify(row.match);

                        inMatch = stringService.contains(matchJson, searchTerm);
                    } else {
                        inMatch = stringService.contains('any', searchTerm);
                    }

                    if (inMatch) {
                        return true;
                    }

                    return _.any(this.collection.actions, action => {
                        if (!_.isEmpty(row[action])) {
                            const { getActionString, getActionName } = PolicyGridService;
                            const actionString = getActionString(action, row[action], row);
                            const actionName = getActionName(action, row);

                            return stringService.contains(actionString, searchTerm) ||
                            stringService.contains(actionName, searchTerm);
                        } else {
                            return false;
                        }
                    });
                },
            };
        }

        /**
         * Returns a set of default fields.
         * @return {Object[]}
         */
        _getDefaultFields() {
            return [{
                title: l10nService.getMessage(l10nKeys.columnTitleIndex),
                name: 'index',
                template: '{{ row.index }}',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleEnable),
                name: 'enable',
                template: '<checkbox ng-model="row.enable"></checkbox>',
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleName),
                name: 'name',
                template: '<span class="hide-text-overflow">{{ row.name }}</span>',
                allowResize: true,
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleMatchingRules),
                name: 'match',
                template:
                    `<policy-grid-match-column
                        rule="row"
                        config="config">
                    </policy-grid-match-column>`,
                allowResize: true,
            }, {
                title: l10nService.getMessage(l10nKeys.columnTitleAction),
                name: 'action',
                template:
                    `<policy-grid-action-column
                        rule="row"
                        config="config">
                    </policy-grid-action-column>`,
            }];
        }

        /**
         * Returns a set of default singleactions. Action can also have a 'hidden' property,
         * which hides the singleaction icon if the return value is true.
         * @return {Object[]}
         */
        _getDefaultSingleactions() {
            return [{
                title: l10nService.getMessage(l10nKeys.actionEdit),
                class: 'icon-pencil-4',
                do: rule => rule.edit(),
            }];
        }

        /**
         * Returns a set of default multipleactions. The 'disabled' function receives the
         * row as the argument, and the action is disabled if the return value is true.
         * @return {Object[]}
         */
        _getDefaultMultipleactions() {
            return [{
                title: l10nService.getMessage(l10nKeys.actionEnable),
                disabled: rules => _.every(rules, rule => rule.enable),
                do: rules => this.collection.enable(rules),
            }, {
                title: l10nService.getMessage(l10nKeys.actionDeactivate),
                disabled: rules => _.every(rules, rule => !rule.enable),
                do: rules => this.collection.disable(rules),
            }, {
                title: l10nService.getMessage(l10nKeys.actionDelete),
                do: rules => this.collection.delete(rules),
            }];
        }

        _getDefaultExpandTemplate() {
            return (
                `<policy-grid-expander
                    row="row"
                    config="config">
                </policy-grid-expander>`
            );
        }
    };
};

policyGridConfigFactory.$inject = [
        'PolicyGridService',
        'stringService',
        'l10nService',
];

angular.module('aviApp').factory('PolicyGridConfig', policyGridConfigFactory);
