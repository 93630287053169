/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { ILinuxConfiguration } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { LinuxConfiguration } from 'object-types';

import * as l10n from './system-settings-banners-config.l10n';
import './system-settings-banners-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Banners Config System Settings Modal.
 * @author Kondiparthi Shanmukha Sarath
 */
@Component({
    selector: 'system-settings-banners-config',
    templateUrl: './system-settings-banners-config.component.html',
})
export class SystemSettingsBannersConfigComponent {
    /**
     * Linux configuration of the controller.
     */
    @Input()
    public linuxConfig: ILinuxConfiguration;

    /**
     * Object type for template usage.
     */
    public readonly objectType = LinuxConfiguration;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
