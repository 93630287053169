/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'email-controller';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleToAddress = `${prefix}.columnTitleToAddress`;
export const columnTitleCCAddress = `${prefix}.columnTitleCCAddress`;
export const columnTitleValidateSettings = `${prefix}.columnTitleValidateSettings`;
export const modalHeaderEdit = `${prefix}.modalHeaderEdit`;
export const modalHeaderNew = `${prefix}.modalHeaderNew`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const emailInformationHeader = `${prefix}.emailInformationHeader`;
export const toAddressInputLabel = `${prefix}.toAddressInputLabel`;
export const ccAddressInputLabel = `${prefix}.ccAddressInputLabel`;
export const emailExamplePlaceholder = `${prefix}.emailExamplePlaceholder`;
export const cancelButtonLabel = `${prefix}.cancelButtonLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleToAddress]: 'To Address',
    [columnTitleCCAddress]: 'CC Address',
    [columnTitleValidateSettings]: 'Validate Settings',
    [modalHeaderEdit]: 'Edit Email Notification: {0}',
    [modalHeaderNew]: 'New Email Notification: {0}',
    [nameInputLabel]: 'Name',
    [emailInformationHeader]: 'Email Information',
    [toAddressInputLabel]: 'To Address',
    [ccAddressInputLabel]: 'CC Address',
    [emailExamplePlaceholder]: 'ex. john@doe.com, jane@doe.com',
    [cancelButtonLabel]: 'Cancel',
    [saveButtonLabel]: 'Save',
};
