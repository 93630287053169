/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Hitesh Mandav
 */

export const ERROR_PAGE_BODY_COLLECTION_TOKEN = 'ErrorPageBodyCollection';

export const ERROR_PAGE_BODY_ITEM_TOKEN = 'ErrorPageBody';

export const ERROR_PAGE_PROFILE_COLLECTION_TOKEN = 'ErrorPageProfileCollection';

export const ERROR_PAGE_PROFILE_ITEM_TOKEN = 'ErrorPageProfile';

export const ERROR_PAGE_CONFIG_ITEM_TOKEN = 'ErrorPageConfigItem';
