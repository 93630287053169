/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/modal/cloud-create.less';
import { CREDENTIALS_TYPE } from 'ng/modules/cloud-connector-user';
import * as l10n from './CloudCreateController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const partialsPath = 'src/views/infrastructure/modal/partials';
const stateBasedDnsTemplateName = 'cloud-template-state-based-dns-registration.html';

angular.module('aviApp').controller('CloudCreateController', [
'$scope',
'$timeout',
'$q',
'Regex',
'Auth',
'SEGroupCollection',
'Cloud',
'cloudService',
'CloudConnectorUserCollection',
'IpamProfileCollection',
'CertificateCollection',
'OpenstackNetworkCollection',
'AviModal',
'secretStubStr',
'systemInfoService',
'dropDownUtils',
'naturalSort',
'schemaService',
'l10nService',
function(
    $scope,
    $timeout,
    $q,
    Regex,
    Auth,
    SEGroupCollection,
    Cloud,
    cloudService,
    CloudConnectorUserCollection,
    IpamProfileCollection,
    CertificateCollection,
    OpenstackNetworkCollection,
    AviModal,
    secretStubStr,
    systemInfo,
    dropDownUtils,
    naturalSort,
    schemaService,
    l10nService,
) {
    $scope.$parent.modalScope = $scope;//AviModal thing

    // Just reference, used in the template
    $scope.Regex = Regex;
    $scope.Auth = Auth;
    $scope.cloudService = cloudService;
    $scope.dropDownUtils = dropDownUtils;
    $scope.templateSeGroupFilePath = `${partialsPath}/cloud-template-se-group.html`;
    $scope.templateStateDnsRegistrationPath = `${partialsPath}/${stateBasedDnsTemplateName}`;
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.seInProviderContextTooltip =
        l10nService.getMessage(l10nKeys.seInProviderContextTooltip);

    // Wizard definition
    $scope.wizard = {
        current: 0,
        steps: [{
            title: l10nService.getMessage(l10nKeys.cloudSelectionTitle),
        }],
        beforeChange() {
            $scope.editable.errors = null;

            return false;
        },
    };

    $scope.forms = {};

    const wizardTabs = {
        CLOUD_OPENSTACK: [{
            title: l10nService.getMessage(l10nKeys.selectCloudTitle),
        }, {
            title: l10nService.getMessage(l10nKeys.infrastructureTitle),
        }, {
            title: l10nService.getMessage(l10nKeys.networkTitle),
        }, {
            title: l10nService.getMessage(l10nKeys.roleMappingTitle),
        }],
        CLOUD_VCA: [{
            title: l10nService.getMessage(l10nKeys.selectCloudTitle),
        }, {
            title: l10nService.getMessage(l10nKeys.infrastructureTitle),
        }, {
            title: l10nService.getMessage(l10nKeys.networkTitle),
        }],
    };

    $scope.wizardTabs = wizardTabs;

    /**
     * Handle cloud type change and update wizard accordingly.
     */
    $scope.onCloudTypeChange = function(cloudType) {
        const existingVType = $scope.editable.getVtype();

        $scope.editable.onCloudTypeChange(cloudType);

        const updatedVType = $scope.editable.getVtype();

        $scope.cloudTypeChanged = updatedVType !== existingVType;

        // resets the IPAM/DNS collection params whevenever changing the cloud type
        this.setIpamDnsCollectionParams_();
        $scope.updateWizard();
    };

    /**
     * Sets Ipam/Dns Profile collection during init and
     * if cloud type gets changed in the config window especially
     * when changing from GCP cloud type to some other type.
     * @protected
     */
    $scope.setIpamDnsCollectionParams_ = function() {
        const dnsProfileTypes = 'IPAMDNS_TYPE_INTERNAL_DNS,IPAMDNS_TYPE_INFOBLOX_DNS,' +
            'IPAMDNS_TYPE_AWS_DNS, IPAMDNS_TYPE_AZURE_DNS,IPAMDNS_TYPE_CUSTOM_DNS';

        $scope.ipamProfileCollection.setParams({
            type: dnsProfileTypes,
            exclude: 'type',
        });

        $scope.dnsIpamProfileCollection.setParams({
            type: dnsProfileTypes,
            exclude: undefined,
        });
    };

    $scope.updateWizard = function() {
        const config = $scope.editable.getConfig();

        if (config.vtype && wizardTabs[config.vtype]) {
            $scope.wizard.steps = wizardTabs[config.vtype];
        }

        //tabs will be used for edit mode
        if (config.url) {
            $scope.wizard.steps.forEach(step => {
                // For edit cloud scenario, changing cloud type is similar to creating new cloud.
                // Hence for those scenarios steps should be kept locked.
                step.unlocked = !$scope.cloudTypeChanged;
            });
        }
    };

    /**
     * Returns the name of the cloud defined by enums.
     * @param  {string} cloudType - type of Cloud.
     * @return {string}
     */
    $scope.getCloudTypeName = function(cloudType) {
        return Cloud.getCloudTypeName(cloudType);
    };

    // Collections used
    $scope.seGroupCollection = new SEGroupCollection({ isStatic: true });

    $scope.ipamProfileCollection = new IpamProfileCollection({
        defaults: {
            type: 'IPAMDNS_TYPE_INTERNAL',
        },
    });

    $scope.dnsIpamProfileCollection = new IpamProfileCollection({
        defaults: {
            type: 'IPAMDNS_TYPE_INTERNAL_DNS',
        },
    });

    $scope.setIpamDnsCollectionParams_();

    $scope.sshUsers = new CloudConnectorUserCollection({
        createParams: {
            credentialsType: CREDENTIALS_TYPE.SSH,
        },
    });

    const certTypeCAEnum = 'SSL_CERTIFICATE_TYPE_CA';

    /**
     * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
     */
    const certCollectionEnums =
        schemaService.getEnumKeys('SSLCertificateType')
            .filter(type => type !== certTypeCAEnum)
            .join();

    // Instantiate collections
    $scope.certCollection = new CertificateCollection({
        params: {
            type: certCollectionEnums,
        },
    });

    $scope.authCollection = new CertificateCollection({
        params: { type: certTypeCAEnum },
        defaults: { type: certTypeCAEnum },
    });

    // window initialization
    $scope.init = function() {
        $scope.updateWizard();

        /**
         * This property tracks cloud type change during cloud edit scenario.
         */
        $scope.cloudTypeChanged = false;

        const config = $scope.editable.getConfig();

        /**
         * Grabs the enums for certificate types !== 'SSL_CERTIFICATE_TYPE_CA'.
         */
        const certCollectionEnums =
            schemaService.getEnumKeys('SSLCertificateType')
                .filter(type => type !== 'SSL_CERTIFICATE_TYPE_CA').join();

        // Instantiate collections
        $scope.certCollection = new CertificateCollection({
            params: {
                type: certCollectionEnums,
            },
        });

        if ($scope.editable.id) { //edit mode
            $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': $scope.editable.id });

            $scope.seGroupCollection.setDefaultItemConfigProps({
                cloud_ref: $scope.editable.getRef(),
            });

            // Need to stay in cloud selection page if modal is opened from welcomeflow.
            if (!$scope.fromWelcomeModal) {
                $timeout(function() {
                    $scope.wizard.next();

                    //no way to switch type for existing cloud
                    if (config.vtype !== 'CLOUD_NONE') {
                        $scope.wizard.steps[0].unlocked = false;
                    }
                });
            }
        } else {
            $scope.seGroupCollection.setParams({ 'cloud_ref.uuid': undefined });
        }
    };

    /**
     * Handler for clicking next button in cloud selection wizard.
     * The relevant cloud init() is made sure to be called after the cloud selection.
     */
    $scope.saveSettings = function() {
        $scope.editable.errors = null;

        $scope.updateWizard();
        $scope.wizard.next();
    };

    /**
     * Navigates to complete screen and then to the system.
     */
    $scope.complete = function() {
        $scope.editable.submit();
    };

    $scope.isUnlocked = function(index) {
        const { steps } = $scope.wizard;

        return steps[index] && steps[index].unlocked;
    };

    /* ---- Modal methods, activated by save button and other UI elements ---- */

    $scope.isEditing = function() {
        return !!$scope.editable.data.config.url;
    };

    /*-------- OpenStack ---------*/
    $scope.openstack = {
        /**
         * Flag to check if Openstack login succeeded.
         */
        isLoggedIn: false,

        goTo(index) {
            if (index === 0) {
                $scope.wizard.current = 0;

                return;
            }

            const { current } = $scope.wizard;
            const cfg = $scope.editable.getCloudConfig();

            switch (current) {
                case 2: {
                    let credentialsCheckPromise = $q.when(true);

                    if (cfg._useContrail) {
                        credentialsCheckPromise = $scope.editable.checkContrailCreds();
                    } else if (cfg.nuage_config) {
                        credentialsCheckPromise = $scope.editable.checkNuageCreds();
                    }

                    credentialsCheckPromise
                        .then(() => {
                            $scope.wizard.steps[current].unlocked = true;
                            $scope.wizard.current = index;
                        });

                    break;
                }

                case 1:
                    this.login().then(() => {
                        this.getSeInProviderContext();
                        this.setTenantDropdownOptions();
                        $scope.wizard.steps[1].unlocked = true;
                        $scope.wizard.steps[2].unlocked = $scope.isEditing();
                        $scope.wizard.steps[3].unlocked = $scope.isEditing();

                        if (!$scope.isEditing()) {
                            delete cfg.nuage_config;
                            delete cfg.admin_tenant;
                            delete cfg.region;
                            delete cfg.mgmt_network_name;
                            delete cfg.contrail_endpoint;
                        }

                        $scope.wizard.current = index;
                    });
                    break;

                default:
                    if ($scope.isUnlocked(index)) {
                        $scope.wizard.steps[current].unlocked = true;
                        $scope.wizard.current = index;
                    }
            }
        },

        /**
         * Advances Openstack config wizard by 1 step.
         */
        nextStep() {
            this.goTo($scope.wizard.current + 1);
        },

        /**
         * Checks openstack credentials and sets tenants.
         */
        login() {
            return $scope.editable.openStackLogin()
                .then(() => {
                    this.setNetworkCollection();
                    this.regions = $scope.editable.getOpenstackRegions();
                    $scope.wizard.steps[0].unlocked = false;
                });
        },

        /**
         * Updates the payload params for openstack_tenant_networks API
         * based on the selected tenant.
         */
        setNetworkCollection() {
            const cloudConfig = $scope.editable.getCloudConfig();

            let tenantId;

            if (cloudConfig.admin_tenant) {
                const adminObj = $scope.editable.getTenantID();

                tenantId = adminObj.id;
            }

            $scope.openstackNetworkscollectionParams =
                $scope.editable.getOpenstackTenantNetworksPayload(tenantId);
        },

        /**
         * Handler for changing the Tenant dropdown.
         */
        onTenantChange() {
            const adminObj = $scope.editable.getTenantID();

            if (adminObj) {
                $scope.editable.onTenantChange(adminObj).then(() => {
                    this.setNetworkCollection();
                    this.regions = $scope.editable.getOpenstackRegions();
                });
            }
        },

        /**
         * Saves the Openstack configtation
         */
        saveConfiguration() {
            $scope.$broadcast('openStackBeforeSave');
            $scope.complete();
        },

        /**
         * Returns true to show Allow Self-signed Certificates checkbox.
         * @return {boolean}
         */
        showAllowSelfSignedCertCheckbox() {
            const config = $scope.editable.getCloudConfig();

            return !angular.isUndefined(config.auth_url) && config.auth_url.startsWith('https://');
        },

        /**
         * Returns SE in provider context flag.
         * @returns {boolean}
         */
        getSeInProviderContext() {
            systemInfo.load().then(() => {
                $scope.seInProviderContext = systemInfo.seInProviderContext;
            });
        },

        /**
         * Creates dropdown options for the tenants list.
         */
        setTenantDropdownOptions() {
            const { tenant_ids: tenants } = $scope.editable;

            this.tenants = tenants.map(({ id, name }) => dropDownUtils.createOption(id, name));
        },

        /**
         * Creates dropdown options for the regions list.
         */
        setRegionDropdownOptions(regions) {
            this.regions = regions.map(({ id, name }) => dropDownUtils.createOption(id, name));
        },
    };

    /*---- VCA ----*/
    $scope.vca = {
        VCALogin() {
            $scope.editable.VCALogin().then(function() {
                $scope.wizard.current = 2;
                $scope.wizard.steps[0].unlocked = false;
            });
        },
        saveVCAConfiguration() {
            $scope.editable.save().then(function() {
                $scope.complete();
            });
        },
    };

    $scope.linux = {

        /**
         * Runs /api/cloudconnectoruser task.
         * @param  {Object} host - Linuxserver config host object.
         * @param {string} task - "verify" or "cleanup".
         */
        runCloudConnectorTask(host, task = 'verify') {
            const { linuxserver_configuration: linux } = $scope.editable.getConfig();

            if (!linux || !linux.ssh_user_ref || !host.host_ip || !host.host_ip.addr) {
                return;
            }

            AviModal.open('verify-cloud-connector-user-host', {
                userName: linux.ssh_user_ref,
                host: host.host_ip.addr,
                task,
            });
        },
    };

    /**
     * @type {Object<string, Object>}
     */
    const cloudApi = {
        CLOUD_OPENSTACK: $scope.openstack,
        CLOUD_VCA: $scope.vca,
    };

    /**
     * Navigates cloud config tabs based on Cloud type and tab index.
     * @param {number} index - Tab index.
     * @param {string} cloudType - Cloud type.
     */
    $scope.goTo = function(index, cloudType) {
        $scope.editable.errors = null;

        const { wizard } = $scope;

        if (wizard.current === 0 && index !== 0) {
            $scope.updateWizard();
        }

        const cfg = cloudApi[cloudType];

        if (cfg && angular.isFunction(cfg.goTo)) {
            cfg.goTo(index);
        } else if ($scope.isUnlocked(index)) {
            wizard.current = index;
        }
    };

    /**
     * Handmade $scope.destroy() of the modal window.
     */
    $scope.$on('$destroy', function() {
        $scope.seGroupCollection.destroy();
        $scope.ipamProfileCollection.destroy();
        $scope.certCollection.destroy();
        $scope.authCollection.destroy();
        $scope.sshUsers.destroy();
    });
}]);
