/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'http-interceptor';
const prefix = `${moduleName}.${componentName}`;

export const unsuccessfulRequestsAlertMessage = `${prefix}.unsuccessfulRequestsAlertMessage`;
export const reloadButtonText = `${prefix}.reloadButtonText`;

export const ENGLISH = {
    [unsuccessfulRequestsAlertMessage]: 'Due to a network issue the page is unable to load properly. Please check your network settings and ad blocker software is turned off.',
    [reloadButtonText]: 'Reload page',
};
