/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'mapping-rule-attribute-match';
const prefix = `${moduleName}.${componentName}`;

export const attributeMatchLabel = `${prefix}.attributeMatchLabel`;
export const containsLabel = `${prefix}.containsLabel`;
export const doesNotContainLabel = `${prefix}.doesNotContainLabel`;
export const anyLabel = `${prefix}.anyLabel`;
export const regexLabel = `${prefix}.regexLabel`;
export const attributeNameLabel = `${prefix}.attributeNameLabel`;
export const attributeNamePlaceholder = `${prefix}.attributeNamePlaceholder`;
export const attributeValuesLabel = `${prefix}.attributeValuesLabel`;
export const attributeValuePlaceholder = `${prefix}.attributeValuePlaceholder`;

export const ENGLISH = {
    [attributeMatchLabel]: 'Attribute Match',
    [containsLabel]: 'Contains',
    [doesNotContainLabel]: 'Does not Contain',
    [anyLabel]: 'Any',
    [regexLabel]: 'Regex',
    [attributeNameLabel]: 'Attribute Name',
    [attributeNamePlaceholder]: 'Enter Attribute Name',
    [attributeValuesLabel]: 'Attribute Values',
    [attributeValuePlaceholder]: 'Enter Value',
};
