/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Injectable,
    Type,
} from '@angular/core';
import { ObservableList } from 'ng/shared/utils/observable-list.utils';

export interface IAviDialogProps {
    id: string;
    component: Type<Component>;
    componentProps?: Record<string, any>;
}

// Token used when registering as a downgraded service in AJS.
export const DIALOG_SERVICE_TOKEN = 'dialogService';

/**
 * Service for adding and removing dialogs.
 * @author alextsg
 */
@Injectable({
    providedIn: 'root',
})
export class DialogService extends ObservableList<IAviDialogProps, string> {
    protected getID(item: IAviDialogProps): string {
        return item.id;
    }
}
