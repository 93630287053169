/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *
 *   BGP Peer Message Item.
 *
 * @author Aravindh Nagarajan, Alex Klyuev
 */

import {
    Component,
    Type,
} from '@angular/core';
import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';
import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';
import { IBgpPeer } from 'generated-types';
import { BgpPeer } from 'object-types';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { L10nService } from '@vmw/ngx-vip';
import { BgpTypes } from './bgp-profile.config-item.factory';
import * as l10n from '../vrf-context.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

interface IBgpPeerConfig extends IBgpPeer {
    peer_ip: IpAddrConfigItem;
    subnet: IpAddrPrefixConfigItem;
    peer_ip6: IpAddrConfigItem;
    subnet6: IpAddrPrefixConfigItem;
}

interface IBgpPeerParams extends IItemParams {
    bgpProfileType: BgpTypes;
}

export class BgpPeerConfigItem extends
    withFullModalMixin(MessageItem)<IBgpPeerConfig> {
    public static ajsDependencies = ['l10nService'];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: BgpPeer,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method used to get Modal for lazy loaded component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            BgpPeerModalComponent,
        } = await import(
            /* webpackChunkName: "vrf-context-modal" */ /* eslint-disable-next-line max-len */
            'ng/lazy-loaded-components/modals/vrf-context-modal/bgp-peer-modal/bgp-peer-modal.component'
        );

        return BgpPeerModalComponent as Type<Component>;
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'peer_ip',
            'subnet',
            'peer_ip6',
            'subnet6',
        ];
    }

    /** @override */
    protected getModalBreadcrumbTitle(params: IItemParams): string {
        const { bgpProfileType } = params as IBgpPeerParams;

        return this.l10nService.getMessage(l10nKeys.bgpPeerLabel, [bgpProfileType]);
    }
}
