/**
 * @module DataGridModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { ClrDatagridStateInterface } from '@clr/angular';

import {
    isEqual,
    pick,
} from 'underscore';

import {
    IAviDataGridConfig,
    IAviDataGridRow,
} from '../avi-data-grid/avi-data-grid.types';

import {
    IAviDynamicDataGridSortingInfo,
    TAviDataGridPaginationInfo,
} from './avi-dynamic-data-grid.types';

import './avi-dynamic-data-grid.component.less';

/**
 * @description
 *
 *     This is a data grid component which makes use of avi-data-grid-base component to support
 *     displaying data that are dynamically loaded (typically by API calls) as the data grid state
 *     changes, eg. pagination, filters, sortings.
 *
 *     The component exposes some extra outputs, such as onPaginationChange, that the traditional
 *     avi-data-grid doesn't have for its parent component to react upon. This grid component
 *     doesn't allow users to do row order rearrangement comparing to avi-data-grid due to the fact
 *     that orders are determined by API call results.
 *
 *     Please refer to avi-dynamic-data-grid.stories.ts for an example.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-dynamic-data-grid',
    templateUrl: './avi-dynamic-data-grid.component.html',
})
export class AviDynamicDataGridComponent {
    /**
     * Grid configuration object containing getRowId, multipleactions, etc.
     */
    @Input()
    public config: IAviDataGridConfig;

    /**
     * Data to be displayed in the current page.
     */
    @Input()
    public rows: IAviDataGridRow[] = [];

    /**
     * Total number of data.
     */
    @Input()
    public rowsTotal: number;

    /**
     * Grid Title sent to the grid.
     */
    @Input()
    public gridTitle?: string;

    /**
     * Indicate whether the row data is being loaded.
     */
    @Input()
    public isLoading ? = false;

    /**
     * Fires on row selection change.
     */
    @Output()
    public onSelectionChange = new EventEmitter<IAviDataGridRow[]>();

    /**
     * Fires on pagination changes: either the current page or page size.
     */
    @Output()
    public onPaginationChange = new EventEmitter<TAviDataGridPaginationInfo>();

    @Output()
    public onSortingChange = new EventEmitter<IAviDynamicDataGridSortingInfo>();

    /**
     * Latest data grid state object containing info about pagination, sorting and filtering.
     */
    private dataGridState: ClrDatagridStateInterface;

    /**
     * Called on row selection change.
     * Help as a relay to bubble up the onSelectionChange output from avi-data-grid-base to the
     * parent using this avi-dynamic-data-grid component.
     */
    public onRowSelectionChange(rows: IAviDataGridRow[]): void {
        this.onSelectionChange.emit(rows);
    }

    /**
     * Update the local data grid states with the latest one bubbled up from avi-data-grid-base.
     * Emit pagination output only when pagination info updates.
     * Emit sorting ouput only when sorting info updates.
     */
    public handleDataGridStateChange(state: ClrDatagridStateInterface): void {
        const incomingPageInfo = pick(state.page, ['size', 'current']);

        if (!this.dataGridState) { // first time change
            this.onPaginationChange.emit(incomingPageInfo);
        } else {
            const currentPageInfo = pick(this.dataGridState.page, ['size', 'current']);

            if (!isEqual(currentPageInfo, incomingPageInfo)) {
                this.onPaginationChange.emit(incomingPageInfo);
            }

            const { sort: currentSort } = this.dataGridState;
            const { sort: incomingSort } = state;

            if (!isEqual(currentSort, incomingSort)) {
                this.hanldeSortingChange(incomingSort);
            }
        }

        this.dataGridState = state;
    }

    /**
     * "by" (sortBy) from the sorting info object can be either a string or a comparator according
     * to Clarity. For dynamic data grid, sorting is decided by a term (usually a property name.)
     * Thus only string type sortBy is emitted here.
     */
    private hanldeSortingChange = (sortingInfo: ClrDatagridStateInterface['sort']): void => {
        const { by: sortBy, reverse } = sortingInfo;

        if (typeof sortBy === 'string') {
            const sortingInfo: IAviDynamicDataGridSortingInfo = {
                sortBy,
                reverse,
            };

            this.onSortingChange.emit(sortingInfo);
        }
    };
}
