/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'http-header';
const prefix = `${moduleName}.${componentName}`;

export const methodLabel = `${prefix}.methodLabel`;
export const methodPlaceholder = `${prefix}.methodPlaceholder`;

export const ENGLISH = {
    [methodLabel]: 'Method',
    [methodPlaceholder]: 'Select Method',
};
