/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'network';
const componentName = 'sctp-proxy-config';
const prefix = `${moduleName}.${componentName}`;

export const sctpProxyProfileParamLabel = `${prefix}.sctpProxyProfileParamLabel`;
export const numberOfStreamsLabel = `${prefix}.numberOfStreamsLabel`;
export const numberOfStreamsPlaceholder = `${prefix}.numberOfStreamsPlaceholder`;
export const perSecondHelperText = `${prefix}.resetTimeoutHelperText`;
export const inKilobytesHelperText = `${prefix}.inKilobytesHelperText`;
export const resetTimeoutLabel = `${prefix}.resetTimeoutLabel`;
export const resetTimeoutPlaceholder = `${prefix}.resetTimeoutPlaceholder`;
export const receiveWindowLabel = `${prefix}.receiveWindowLabel`;
export const receiveWindowPlaceholder = `${prefix}.receiveWindowPlaceholder`;
export const cookieExpirationTimeoutLabel = `${prefix}.cookieExpirationTimeoutLabel`;
export const cookieExpirationTimeoutPlaceholder = `${prefix}.cookieExpirationTimeoutPlaceholder`;
export const heartbeatIntervalLabel = `${prefix}.heartbeatIntervalLabel`;
export const heartbeatIntervalPlaceholder = `${prefix}.heartbeatIntervalPlaceholder`;
export const idleTimeoutLabel = `${prefix}.idleTimeoutLabel`;
export const idleTimeoutPlaceholder = `${prefix}.idleTimeoutPlaceholder`;
export const maxRetransmissionsInitChunksLabel = `${prefix}.maxRetransmissionsInitChunksLabel`;
export const maxRetransmissionsInitChunksPlaceholder = `${prefix}.maxRetransmissionsInitChunksPlaceholder`;
export const maxRetransmissionsAssociationLabel = `${prefix}.maxRetransmissionsAssociationLabel`;
export const maxRetransmissionsAssociationPlaceholder = `${prefix}.maxRetransmissionsAssociationPlaceholder`;

export const ENGLISH = {
    [sctpProxyProfileParamLabel]: 'SCTP Proxy Profile Parameters',
    [numberOfStreamsLabel]: 'Number of Streams',
    [numberOfStreamsPlaceholder]: 'Enter Number of Streams',
    [perSecondHelperText]: 'Per Second',
    [inKilobytesHelperText]: 'in kilobytes',
    [resetTimeoutLabel]: 'Reset Timeout',
    [resetTimeoutPlaceholder]: 'Enter Reset Timeout',
    [receiveWindowLabel]: 'Receive Window',
    [receiveWindowPlaceholder]: 'Enter Receive Window',
    [cookieExpirationTimeoutLabel]: 'Cookie Expiration Timeout',
    [cookieExpirationTimeoutPlaceholder]: 'Enter Cookie Expiration Timeout',
    [heartbeatIntervalLabel]: 'Heartbeat Interval',
    [heartbeatIntervalPlaceholder]: 'Enter Heartbeat Interval',
    [idleTimeoutLabel]: 'Idle Timeout',
    [idleTimeoutPlaceholder]: 'Enter Idle Timeout',
    [maxRetransmissionsInitChunksLabel]: 'Max Retransmissions Init Chunks',
    [maxRetransmissionsInitChunksPlaceholder]: 'Max Retransmissions Init Chunks',
    [maxRetransmissionsAssociationLabel]: 'Max Retransmissions Association',
    [maxRetransmissionsAssociationPlaceholder]: 'Enter Max Retransmissions Association',
};
