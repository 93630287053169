/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import {
    SnmpTrapProfileModalComponent,
} from 'ng/modules/snmp-trap-profile/components/snmp-trap-profile-modal';
import { SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN } from '../../alert.tokens';

/**
 * @description
 *
 *   SnmpTrapProfile collection
 *
 * @author Guru Prasad H K
 */
export class SnmpTrapProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'snmptrapprofile',
            permissionName: AviPermissionResource.PERMISSION_SNMPTRAPPROFILE,
            windowElement: SnmpTrapProfileModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN);
    }
}

SnmpTrapProfileCollection.ajsDependencies = [
    SNMP_TRAP_PROFILE_CONFIG_ITEM_TOKEN,
];
