/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-profile-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const typeDropdownLabel = `${prefix}.typeDropdownLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;
export const sectionTitleInfoblox = `${prefix}.sectionTitleInfoblox`;
export const allocateIpInVrfCheckboxLabel = `${prefix}.allocateIpInVrfCheckboxLabel`;
export const aviVantageSectionHeader = `${prefix}.aviVantageSectionHeader`;
export const sectionTitleCustom = `${prefix}.sectionTitleCustom`;
export const sectionTitleOpenstack = `${prefix}.sectionTitleOpenstack`;
export const sectionTitleAzure = `${prefix}.sectionTitleAzure`;
export const sectionTitleAws = `${prefix}.sectionTitleAws`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit IPAM/DNS Profile',
    [modalTitleNew]: 'New IPAM/DNS Profile',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [typeDropdownLabel]: 'Type',
    [nameInputPlaceholder]: 'Enter Name',
    [typeDropdownPlaceholder]: 'Select Type',
    [sectionTitleInfoblox]: 'Infoblox',
    [aviVantageSectionHeader]: 'Avi Vantage',
    [allocateIpInVrfCheckboxLabel]: 'Allocate IP in VRF',
    [sectionTitleCustom]: 'Custom',
    [sectionTitleOpenstack]: 'OpenStack',
    [sectionTitleAzure]: 'Azure',
    [sectionTitleAws]: 'AWS',
};
