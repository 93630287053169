/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { take } from 'rxjs/operators';
import { L10nService } from '@vmw/ngx-vip';
import moment from 'moment';
import * as globalL10n from 'global-l10n';
import {
    HOVER_OVERLAY_BOTTOM_POSITION,
    HOVER_OVERLAY_TOP_POSITION,
} from 'ng/modules/tooltip/components/avi-hover-overlay/avi-hover-overlay.constants';
import { IApplicationLog } from 'generated-types';
import { VsLogsStore } from '../../services/vs-logs.store';
import { TVsLog, VsLogsType } from '../../vs-logs.types';

import * as l10n from './vs-log-duration-detail.l10n';
import './vs-log-duration-detail.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      Tooltip component that shows breakdown of log duration properties.
 * @author Alex Klyuev
 */
@Component({
    selector: '[vsLogDurationDetail]',
    templateUrl: './vs-log-duration-detail.component.html',
})
export class VsLogDurationDetailComponent implements OnInit {
    /**
     * Log object.
     */
    @Input()
    public log: TVsLog;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Enum for use in view.
     */
    public readonly VsLogsType = VsLogsType;

    /**
     * If any time is less than 1 ms, we display < 1ms.
     */
    public readonly minDisplayTime = 1;

    /**
     * Computed log duration properties.
     */
    public clientRtt: TVsLog['client_rtt'];

    public serverRtt: TVsLog['server_rtt'];

    public wafTime?: number;

    public appResponseTime?: IApplicationLog['app_response_time'];

    public dataTransferTime?: IApplicationLog['data_transfer_time'];

    public totalTime: TVsLog['total_time'];

    /**
     * Formatted log duration properties.
     */
    public startTime: string;

    public clientRttFormatted: string;

    public serverRttFormatted: string;

    public wafTimeFormatted: string;

    public appResponseTimeFormatted: string;

    public dataTransferTimeFormatted: string;

    public totalTimeFormatted: string;

    /**
     * Position for overlay.
     */
    public hoverOverlayPositionPriorities: ConnectedPosition[] = [
        HOVER_OVERLAY_BOTTOM_POSITION,
        HOVER_OVERLAY_TOP_POSITION,
    ];

    constructor(
        l10nService: L10nService,
        public readonly vsLogsStore: VsLogsStore,
        @Inject('$filter')
        private readonly timeFormatterService: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.clientRtt = this.log.client_rtt;
        this.serverRtt = this.log.server_rtt;
        this.totalTime = this.log.total_time;

        this.startTime = moment(this.log.report_timestamp)
            .subtract(this.totalTime, 'ms').toString();

        this.clientRttFormatted = this.timeFormatterService('ms2str')(this.clientRtt, true);
        this.serverRttFormatted = this.timeFormatterService('ms2str')(this.serverRtt, true);
        this.totalTimeFormatted = this.timeFormatterService('ms2str')(this.totalTime, true);

        this.vsLogsStore.vsLogsType$.pipe(take(1)).subscribe(type => {
            if (type === VsLogsType.APPLICATION) {
                const {
                    app_response_time: appResponseTime,
                    data_transfer_time: dataTransferTime,
                } = this.log as IApplicationLog;

                this.appResponseTime = appResponseTime;
                this.dataTransferTime = dataTransferTime;

                this.appResponseTimeFormatted =
                    this.timeFormatterService('ms2str')(this.appResponseTime, true);

                this.dataTransferTimeFormatted =
                    this.timeFormatterService('ms2str')(this.dataTransferTime, true);
            }
        });

        this.vsLogsStore.hasWafPolicy$.pipe(take(1)).subscribe(hasWafPolicy => {
            if (hasWafPolicy) {
                const { waf_log: wafLog } = this.log as IApplicationLog;

                const wafSum =
                    Number(wafLog.latency_request_header_phase) || 0 +
                    Number(wafLog.latency_request_body_phase) || 0 +
                    Number(wafLog.latency_response_header_phase) || 0 +
                    Number(wafLog.latency_response_body_phase) || 0;

                this.wafTime = Math.round(wafSum / 1000);
                this.wafTimeFormatted = this.timeFormatterService('ms2str')(this.wafTime, true);
            }
        });
    }
}
