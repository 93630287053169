/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-create-application-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const selfSignedButtonLabel = `${prefix}.selfSignedButtonLabel`;
export const importButtonLabel = `${prefix}.importButtonLabel`;
export const certificateInformationHeader = `${prefix}.certificateInformationHeader`;
export const commonNameInputLabel = `${prefix}.commonNameInputLabel`;
export const emailInputPlaceholder = `${prefix}.emailInputPlaceholder`;
export const organizationUnitInputLabel = `${prefix}.organizationUnitInputLabel`;
export const organizationUnitInputPlaceholder = `${prefix}.organizationUnitInputPlaceholder`;
export const organizationInputLabel = `${prefix}.organizationInputLabel`;
export const organizationInputPlaceholder = `${prefix}.organizationInputPlaceholder`;
export const localityInputLabel = `${prefix}.localityInputLabel`;
export const localityInputPlaceholder = `${prefix}.localityInputPlaceholder`;
export const stateNameInputLabel = `${prefix}.stateNameInputLabel`;
export const stateNameInputPlaceholder = `${prefix}.stateNameInputPlaceholder`;
export const countryInputLabel = `${prefix}.countryInputLabel`;
export const countryInputPlaceholder = `${prefix}.countryInputPlaceholder`;
export const countryInputHelperText = `${prefix}.countryInputHelperText`;
export const subjectAlternateNameInputLabel = `${prefix}.subjectAlternateNameInputLabel`;
export const algorithmInputLabel = `${prefix}.algorithmInputLabel`;
export const keySizeInputLabel = `${prefix}.keySizeInputLabel`;
export const daysUntilExpirationInputLabel = `${prefix}.daysUntilExpirationInputLabel`;
export const certificateManagementProfileInputLabel = `${prefix}.certificateManagementProfileInputLabel`;
export const certificateManagementProfileInputPlaceholder = `${prefix}.certificateManagementProfileInputPlaceholder`;
export const certificateInputLabel = `${prefix}.certificateInputLabel`;
export const pasteTextRadioOption = `${prefix}.pasteTextRadioOption`;
export const pasteCertificateInputPlaceholder = `${prefix}.pasteCertificateInputPlaceholder`;
export const keyPemInputLabel = `${prefix}.keyPemInputLabel`;
export const KeyInputPlaceholder = `${prefix}.KeyInputPlaceholder`;
export const keyPassphraseInputLabel = `${prefix}.keyPassphraseInputLabel`;
export const keyPassphraseInputPlaceholder = `${prefix}.keyPassphraseInputPlaceholder`;
export const hsmCertificateHeader = `${prefix}.hsmCertificateHeader`;
export const hsmGroupInputLabel = `${prefix}.hsmGroupInputLabel`;
export const hsmGroupInputPlaceholder = `${prefix}.hsmGroupInputPlaceholder`;
export const hsmGroupCreateLabel = `${prefix}.hsmGroupCreateLabel`;
export const encryptedPrivateKeyInputLabel = `${prefix}.encryptedPrivateKeyInputLabel`;
export const validateButtonLabel = `${prefix}.validateButtonLabel`;
export const typeSelectPlaceholder = `${prefix}.typeSelectPlaceholder`;
export const certificateSectionHeader = `${prefix}.certificateSectionHeader`;
export const commonNameInputPlaceholder = `${prefix}.commonNameInputPlaceholder`;
export const dynamicParamsLabel = `${prefix}.dynamicParamsLabel`;
export const certificateFileInputLabel = `${prefix}.certificateFileInputLabel`;
export const certificateFileInputPlaceholder = `${prefix}.certificateFileInputPlaceholder`;
export const sectionTitleOcspSampling = `${prefix}.sectionTitleOcspSampling`;
export const certificateCreateTypeSelfSignedLabel = `${prefix}.certificateCreateTypeSelfSignedLabel`;
export const certificateCreateTypeCsrLabel = `${prefix}.certificateCreateTypeCsrLabel`;
export const certificateCreateTypeImport = `${prefix}.certificateCreateTypeImport`;
export const keySizeSelectPlaceholder = `${prefix}.keySizeSelectPlaceholder`;
export const algorithmSelectPlaceholder = `${prefix}.algorithmSelectPlaceholder`;
export const subjectAltNamesGridPlaceholder = `${prefix}.subjectAltNamesGridPlaceholder`;
export const privateKeyHsmCheckboxLabel = `${prefix}.privateKeyHsmCheckboxLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Certificate (SSL/TLS)',
    [modalTitleNew]: 'New Certificate (SSL/TLS)',
    [typeSelectPlaceholder]: 'Select Type',
    [certificateInformationHeader]: 'Certificate Information',
    [commonNameInputLabel]: 'Common Name',
    [emailInputPlaceholder]: 'Enter Email',
    [organizationUnitInputLabel]: 'Organization Unit',
    [organizationUnitInputPlaceholder]: 'Enter Organization Unit',
    [organizationInputLabel]: 'Organization',
    [organizationInputPlaceholder]: 'Enter Organization',
    [localityInputLabel]: 'Locality or City',
    [localityInputPlaceholder]: 'Enter Locality or City',
    [stateNameInputLabel]: 'State Name or Province',
    [stateNameInputPlaceholder]: 'Enter State Name or Province',
    [countryInputLabel]: 'Country',
    [countryInputPlaceholder]: 'Enter Country',
    [countryInputHelperText]: 'Two letter country code',
    [subjectAlternateNameInputLabel]: 'Subject Alternate Name (SAN) ({0})',
    [algorithmInputLabel]: 'Algorithm',
    [keySizeInputLabel]: 'Key Size',
    [daysUntilExpirationInputLabel]: 'Days Until Expiration',
    [certificateManagementProfileInputLabel]: 'Certificate Management Profile',
    [certificateManagementProfileInputPlaceholder]: 'Select Certificate Management Profile',
    [certificateInputLabel]: 'Certificate',
    [pasteTextRadioOption]: 'Paste text',
    [pasteCertificateInputPlaceholder]: 'Paste Certificate',
    [keyPemInputLabel]: 'Upload or Paste Key (PEM) or PKCS12 File',
    [KeyInputPlaceholder]: 'Paste Key',
    [keyPassphraseInputLabel]: 'Key Passphrase',
    [keyPassphraseInputPlaceholder]: 'SSL/TLS Passphrase',
    [hsmCertificateHeader]: 'HSM Certificate',
    [hsmGroupInputLabel]: 'HSM Group',
    [hsmGroupInputPlaceholder]: 'Select HSM Group',
    [hsmGroupCreateLabel]: 'Create HSM Group',
    [encryptedPrivateKeyInputLabel]: 'Encrypted Private Key',
    [validateButtonLabel]: 'Validate',
    [certificateSectionHeader]: 'Certificate',
    [commonNameInputPlaceholder]: 'Enter Common Name',
    [dynamicParamsLabel]: 'Dynamic Parameters',
    [certificateFileInputLabel]: 'Upload or Paste Certificate File',
    [certificateFileInputPlaceholder]: 'Paste Certificate',
    [sectionTitleOcspSampling]: 'OCSP',
    [certificateCreateTypeSelfSignedLabel]: 'Self Signed',
    [certificateCreateTypeCsrLabel]: 'CSR',
    [certificateCreateTypeImport]: 'Import',
    [keySizeSelectPlaceholder]: 'Select Key Size',
    [algorithmSelectPlaceholder]: 'Select Algorithm',
    [subjectAltNamesGridPlaceholder]: 'Enter Subject Alternate Name',
    [privateKeyHsmCheckboxLabel]: 'Import Private Key to HSM',
};
