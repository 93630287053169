/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name ipAddrToInt
 * @desc
 *
 *     Returns an integer representation of IP address string. Used for IP comparison and ordering.
 *
 */
angular.module('aviApp').constant('ipAddrToInt', function(ipAddr) {
    let res;

    try {
        const parts = ipAddr.split('.');

        if (parts.length === 4) {
            res = parts.reduce((base, part, index) => {
                part = +part;

                if (_.isNaN(part) || !_.isFinite(part) || part < 0 || part > 255) {
                    throw new Error('format');
                }

                return base + part * 256 ** (3 - index);
            }, 0);
        } else {
            throw new Error('format');
        }
    } catch (e) {
        res = NaN;
        console.error(`IP address '${ipAddr}' has the wrong format`);
    }

    return res;
});
