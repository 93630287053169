/**
 * Module containing Licensing services and components.
 * @preferred
 * @module LicensingModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import {
    LicensingService,
} from '.';

const licensingModule = angular.module('avi/licensing');

const services = [
    {
        service: LicensingService,
        name: 'licensingService',
    },
];

services.forEach(({ name, service }) => licensingModule.service(name, service));
