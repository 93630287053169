/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IWafApplicationSignatures } from 'generated-types';

export class WafApplicationSignaturesConfigItem extends MessageItem<IWafApplicationSignatures> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafApplicationSignatures',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        if (!this.applications) {
            this.config.selected_applications = [];
        }
    }

    /**
     * Getter for Selected Applications.
     */
    public get applications(): IWafApplicationSignatures['selected_applications'] {
        return this.config.selected_applications;
    }

    /**
     * Setter for Selected Applications.
     */
    public set applications(selectedApplications) {
        this.config.selected_applications = selectedApplications;
    }

    /**
     * Method to remove application from list.
     */
    public removeApplication(removedApplication: string): void {
        this.applications = this.applications.filter(application => {
            return application !== removedApplication;
        });
    }

    /**
     * Method to add application to list.
     */
    public addApplication(application: string): void {
        this.applications = this.applications.concat(application);
    }

    /**
     * Returns Provider ref from config.
     */
    public get providerRef(): IWafApplicationSignatures['provider_ref'] {
        return this.config.provider_ref;
    }

    /**
     * Sets Provider ref to config.
     */
    public set providerRef(providerRef: IWafApplicationSignatures['provider_ref']) {
        this.config.provider_ref = providerRef;
    }
}
