/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { IpamProfileItem } from 'ajs/modules/ipam/factories/ipam-profile.item.factory';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import * as l10n from './ipam-dns-profile-modal.l10n';

const { ENGLISH, ...l10nKeys } = l10n;

const DNS_PROFILE_SUFFIX = '_DNS';

/**
 * @description IPAM/DNS Profile full-modal component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-profile-modal',
    templateUrl: './ipam-dns-profile-modal.component.html',
})
export class IpamDnsProfileModalComponent implements OnInit {
    /**
     * IpamProfileItem instance.
     */
    @Input()
    public editable: IpamProfileItem;

    /**
     * IpamProfileItem object type.
     */
    public objectType: string;

    /**
     * Ipam/Dns profile type options.
     */
    public ipamDnsTypeOptions: IAviDropdownOption[];

    /**
     * Layout for ICAP modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IpamDnsType enum to be used in template.
     */
    public readonly ipamDnsTypeEnum = ipamDnsProfileTypeHash;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(ENGLISH);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        let ipamDnsTypeOptions = this.schemaService.getEnumValues('IpamDnsType');

        // If type is DNS, Type dropdown should list only DNS Profiles.
        // Same for Ipam.
        if (this.editable.type.includes(DNS_PROFILE_SUFFIX)) {
            ipamDnsTypeOptions = ipamDnsTypeOptions
                .filter(({ value }) => value.endsWith(DNS_PROFILE_SUFFIX));
        } else {
            ipamDnsTypeOptions = ipamDnsTypeOptions
                .filter(({ value }) => !value.endsWith(DNS_PROFILE_SUFFIX));
        }

        this.ipamDnsTypeOptions = createOptionsFromEnumProps(ipamDnsTypeOptions);
    }
}
