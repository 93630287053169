/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function getLocationWoHashFactory(location) {
    /**
     * @alias module:services/getLocationWoHash.getLocationWoHash
     * @param {boolean=} withSearch - location will include query part if true is passed.
     * @return {string}
     **/
    function getLocationWoHash(withSearch) {
        const {
            origin,
            pathname,
            search,
        } = location;

        const url = `${origin}${pathname}`;

        if (withSearch && search) {
            return `${url}${search}`;
        }

        return url;
    }

    return getLocationWoHash;
}

getLocationWoHashFactory.$inject = [
    'windowLocation',
];

/**
 * @ngdoc service
 * @name getLocationWoHash
 * @module services/getLocationWoHash
 * @desc
 *
 *     Returns current URL wo the hash part. Can be used for the hard UI reload.
 */
//TODO might need more flexible approach of getting URL
// by passing list of chunk types we are interested in
angular.module('avi/deps')
    .factory('getLocationWoHash', getLocationWoHashFactory);
