/**
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IPReputationDBDisplayStatus } from '../../ip-reputation-db-list-page-container';
import * as l10n from './ip-reputation-db-list-page-header.l10n';
import './ip-reputation-db-list-page-header.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the IP Reputation DB List Page header.
 * @author alextsg
 */
@Component({
    selector: 'ip-reputation-db-list-page-header',
    templateUrl: './ip-reputation-db-list-page-header.component.html',
})
export class IPReputationDBListPageHeaderComponent {
    /**
     * Status of the IP Reputation DB. If UNREGISTERED, displays the banner to register the
     * controller. If DISABLED, displays the banner to edit pulse settings. If ENABLED or ERROR,
     * shows the green or red status icon.
     */
    @Input()
    public status: IPReputationDBDisplayStatus;

    /**
     * Error message to be displayed in the tooltip.
     */
    @Input()
    public error: string;

    /**
     * Called when the user clicks the Register/Edit Pulse settings link in the banner.
     */
    @Output()
    public onEditPulseSettings = new EventEmitter();

    /**
     * IPReputationDBDisplayStatus enum, set as a property to be compared in the template.
     */
    public readonly IPReputationDBDisplayStatus = IPReputationDBDisplayStatus;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the user clicks the Register/Edit Pulse settings link in the banner.
     */
    public redirectToCloudServices(): void {
        this.onEditPulseSettings.emit();
    }
}
