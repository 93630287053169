/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IGatewayMonitor } from 'generated-types';
import { GatewayMonitor } from 'object-types';
import {
    IpAddrConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';

type IGatewayMonitorConfigPartial = Omit<IGatewayMonitor, 'gateway_ip'>;

interface IGatewayMonitorConfig extends IGatewayMonitorConfigPartial {
    gateway_ip: IpAddrConfigItem;
}

/**
 * @description Gateway Monitor Message Item.
 * @author Alex Klyuev
 */
export class GatewayMonitorConfigItem extends MessageItem<IGatewayMonitorConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GatewayMonitor,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'gateway_ip',
        ];
    }
}
