/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'alert';
const componentName = 'alert-syslog-server-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalHeader = `${prefix}.modalHeader`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const ipAddressLabel = `${prefix}.ipAddressLabel`;
export const saveButtonLabel = `${prefix}.saveButtonLabel`;
export const generalLabel = `${prefix}.generalLabel`;
export const syslogServerLabel = `${prefix}.syslogServerLabel`;
export const portLabel = `${prefix}.portLabel`;
export const syslogServerPlaceholderLabel = `${prefix}.syslogServerPlaceholderLabel`;
export const portPlaceholderLabel = `${prefix}.portPlaceholderLabel`;

export const ENGLISH = {
    [modalHeader]: 'Syslog Server',
    [nameInputLabel]: 'Name',
    [ipAddressLabel]: 'IP Address',
    [saveButtonLabel]: 'Save',
    [generalLabel]: 'General',
    [syslogServerLabel]: 'Syslog Server',
    [portLabel]: 'Port',
    [syslogServerPlaceholderLabel]: 'Enter Hostname or IP Address',
    [portPlaceholderLabel]: 'Enter Port',
};
