/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './unit-tree.less';

import {
    svg as d3Svg,
    select as d3Select,
} from 'd3v3';

const componentPath = 'src/components/applications';
const componentName = 'unit-tree';

/**
 * @constructor
 * @memberOf module:avi/vs
 * @mixes module:avi/vs.unitTreeComponentBindings
 */
class UnitTreeController {
    constructor(PoolGroupCollection, GSLBService, $state, $timeout, $element, $scope) {
        /** @protected */
        this.PoolGroupCollection_ = PoolGroupCollection;
        this.GSLBService_ = GSLBService;
        this.$state_ = $state;
        this.$timeout_ = $timeout;
        this.$element_ = $element;
        this.$scope_ = $scope;

        /**
         * SVG Container.
         * @type {HTMLElement|null}
         */
        this.chart = null;

        /**
         * SVG group element <g> with connections class.
         * @type {HTMLElement|null}
         */
        this.chartContainer = null;

        /**
         * Line generator function.
         * @type {Function|null}
         */
        this.line = null;
    }

    /**
     * @override
     */
    $onInit() {
        const { unit } = this;

        this.previousPoolList = unit.pools;
    }

    /**
     * @override
     */
    $onChanges(changes) {
        if (_.sample(changes)
            .isFirstChange()) {
            return true;
        }

        if (changes.isTreeOpen) {
            const {
                currentValue: currentIsTreeOpenValue,
            } = changes.isTreeOpen;

            if (currentIsTreeOpenValue) {
                this.repaint_();
            }
        }
    }

    /**
     * @override
     */
    $postLink() {
        //onResize
        this.repaint_ = _.debounce(this.drawConnections_, 99);

        this.$scope_.$on('repaint', () => {
            this.repaint_();
        });
    }

    /**
     * @override
     */
    $doCheck() {
        const { unit, previousPoolList } = this;
        const { pools } = unit;

        this.setPoolIdsHash_();

        if (!_.isEqual(previousPoolList, pools)) {
            this.previousPoolList = pools;
            this.repaint_();
        }
    }

    /**
     * @override
     */
    $onDestroy() {
        const { chart, chartContainer } = this;

        if (chart) {
            if (chartContainer) {
                chartContainer.remove();
            }

            chart.remove();
        }
    }

    /**
     * Draws the connector lines between the designated entities such as VS, pool, server etc.
     * @protected
     */
    drawConnections_() {
        if (!this.isTreeOpen) {
            return;
        }

        if (this.chart) {
            this.chart.remove();
        }

        if (this.chartContainer && this.chartContainer.selectAll('path').size()) {
            this.chartContainer.selectAll('path').remove();
        }

        const serverSelector = '~servers-list > div.servers > ul:first-child ' +
            'unit-card[type="server"],~servers-list > ul > li >' +
            'unit-card[type="server"]:first-child';
        const { $element_ } = this;

        this.line = d3Svg.line()
            .x(d => d[0])
            .y(d => d[1])
            .interpolate(points => UnitTreeController.interpolation(points));

        this.chart = d3Select($element_.find('div.background')[0])
            .append('svg')
            .attr({
                class: 'chart sel-background-chart',
                width: '100%',
                height: '100%',
            });

        this.chartContainer = this.chart.append('g')
            .attr({ class: 'connections' });

        const vs = $element_.find('unit-card[type=vs]');
        const pools = $element_.find('unit-card[type=pool]');

        if (vs.length && pools.length) {
            const vsPos = this.countConnectionPoints_('right', vs);
            const vs2poolDraw = this.makeDrawFunc_(vsPos, 'vs', 'vs2pool_');

            pools.each((poolKey, pool) => {
                pool = $(pool);

                if (!pool.is('[pool-type^=ab], [pool-type=backup], [pool-type=poolgroup]')) {
                    vs2poolDraw(pool, poolKey);//draws connections between vs and pool
                } else if (
                    pool.is('[pool-type=poolgroup]') &&
                    pool.is('.poolgroup ul:first-child li:first-of-type [pool-type=poolgroup]')
                ) {
                    vs2poolDraw(pool, poolKey);
                } else if (
                    poolKey > 0 &&
                    pool.is('[pool-type=backup]')
                ) {
                    //line between parent and child pools
                    this.makeDrawFunc_(
                        this.countConnectionPoints_(
                            'bottom',
                            $(pools.get(poolKey - 1)),
                        ),
                        'pool2pool',
                        `pool2pool_${poolKey}`,
                        'bottom',
                    )(pool, poolKey);
                } else if (
                    poolKey > 0 &&
                    pool.is('[pool-type=ab-child]')
                ) {
                    //line from VS to the point between pools and than from that point to both
                    const parentPool = $(pools.get(poolKey - 1));
                    const parent = this.countConnectionPoints_('left', parentPool);
                    const child = this.countConnectionPoints_('left', pool);
                    const between = [
                            (parent[0] + child[0]) / 2,
                            (parent[1] + child[1]) / 2,
                    ];

                    this.makeDrawFunc_(
                        between, 'vs', 'vs2ab_pools', 'right',
                    )(vs);

                    this.makeDrawFunc_(
                        between,
                        'vs',
                        'ab_pools2ab_parent_pool_',
                        'bottom',
                    )(parentPool, poolKey - 1);

                    this.makeDrawFunc_(
                        between,
                        'vs',
                        'ab_pools2ab_child_pool_',
                        'top',
                    )(pool, poolKey);
                }

                let nets;

                if (pool.is('[pool-type=poolgroup]')) {
                    nets = pool.parent().find(
                        '~ul > li > div.net-wrapper > unit-card[type="net"]',
                    );
                } else {
                    nets = pool.find('~ul > li > div.net-wrapper > unit-card[type="net"]');
                }

                const poolPosR =
                    this.countConnectionPoints_('right', pool);

                if (nets.length) {
                    const pool2netDraw = this.makeDrawFunc_(
                        poolPosR, `pool_${poolKey}`, 'pool2net_',
                    );

                    nets.each((netKey, net) => {
                        net = $(net);

                        const netPosR =
                            this.countConnectionPoints_('right', net);

                        pool2netDraw(net, netKey);

                        //have two possible layouts dependent on servers quantity
                        const servers = net.parent().find(serverSelector);

                        const net2serverDraw = this.makeDrawFunc_(
                            netPosR, `pool_${poolKey}_net_${netKey}`, 'net2server_',
                        );

                        servers.each((serverKey, server) => {
                            net2serverDraw($(server), serverKey);
                        });
                    });
                } else {
                    let servers;

                    if (pool.is('[pool-type=poolgroup]')) {
                        servers = pool.parent().find(serverSelector);
                    } else {
                        servers = pool.find(serverSelector);
                    }

                    if (servers.length) {
                        const pool2serverDraw = this.makeDrawFunc_(
                            poolPosR, `pool_${poolKey}pool2server_`,
                        );

                        servers.each((serverKey, server) => {
                            pool2serverDraw($(server), serverKey);
                        });
                    }
                }
            });
        }
    }

    /**
     * Checks whether the given unit has SEs or not.
     * @return {boolean}
     */
    hasSes() {
        const { unit } = this;

        if (!unit || !unit.ses) {
            return false;
        }

        return !!Object.keys(unit.ses).length;
    }

    /**
     * Checks whether the given unit is GslbService or not.
     * @return {boolean}
     */
    isGslbService() {
        const { unit, GSLBService_ } = this;

        return unit instanceof GSLBService_;
    }

    /**
     * Redirects to GslbService details page.
     */
    // TODO(@logashoff): Once Health Score for GSLB Service is implemented, move to unitCard.js
    goToGslbService() {
        const { unit } = this;

        this.$state_.go(
            'authenticated.application.gslbservice-detail.members',
            { gslbServiceId: unit.id },
        );
    }

    /**
     * Toggles isTreeOpen flag.
     */
    toggleTree() {
        const { unit } = this;

        this.isTreeOpen = !this.isTreeOpen;
        unit._viewExpanded = this.isTreeOpen;
    }

    /**
     * Sets this.poolIdsHash to filter out pools already displayed in poolgroups.
     * @protected
     */
    setPoolIdsHash_() {
        const { unit, PoolGroupCollection_ } = this;
        const { poolgroups: items } = unit;

        if (items) {
            this.poolIdsHash = PoolGroupCollection_.getPoolIdsHash(items);
        }
    }

    /**
     *  Finds position in element to draw line from (to).
     *  @param {string} type
     *  @param {HTMLElement} element
     *  @returns {Object}
     *  @protected
     */
    countConnectionPoints_(type, element) {
        const point = [0, 0];
        const margin = [2, -1];
        const { $element_ } = this;

        const p = UnitTreeController.relativeOffset(
            element.get(0),
            $element_.find('.unit-tree-list').get(0),
        );
        const { x: left, y: top } = p;
        const width = element.outerWidth();
        const height = element.outerHeight();

        switch (type) {
            case 'right':
                point[0] = Math.floor(left + width) + margin[0];
                point[1] = Math.round(top + height / 2);
                break;
            case 'left':
                point[0] = Math.floor(left) - margin[0];
                point[1] = Math.round(top + height / 2);
                break;
            case 'top':
                point[0] = Math.round(left + width / 2);
                point[1] = Math.ceil(top) - margin[1];
                break;
            case 'bottom':
                point[0] = Math.round(left + width / 2);
                point[1] = Math.floor(top + height) + margin[1];
                break;
            default:
                console.warn('vsTree.drawConnections.countConnectionPoints_: "%s" is a' +
                    'wrong type of connection point. DOM Node: %o', type, element);
        }

        return point;
    }

    /**
     * Returns a function to draw the line in SVG path.
     * @param {Object } parentPos - Position in element to draw line from (to)
     * @param {string} gClassName - classname for SVG group element
     * @param {string} pathClassName - classname for SVG path element
     * @param {string} connPlacement - placement position of the connector.
     * @protected
     */
    makeDrawFunc_(parentPos, gClassName, pathClassName, connPlacement = 'left') {
        return (unit, key = '0') => {
            const pos = this.countConnectionPoints_(connPlacement, unit);
            const { chartContainer, line } = this;

            if (chartContainer &&
                !chartContainer.select(`g.${gClassName}`).size()
            ) {
                chartContainer.append('g').attr('class', gClassName);
            }

            chartContainer.select(`g.${gClassName}`)
                .selectAll(`path.${pathClassName}${key}`)
                .data([[parentPos, pos]])
                .enter()
                .append('path')
                .attr({
                    class: pathClassName + key,
                    d: line,
                    'stroke-linejoin': 'round',
                    'stroke-linecap': 'round',
                });
        };
    }

    /**
     * Makes Bezier curve between two points in different units
     * @param {Object} points
     * @returns {string}
     * @static
     */
    static interpolation(points) {
        const straightCurvature = 1 / 20;
        let path = '';

        const trueOrFalse = () => Math.random() >= 0.5;

        points.forEach((val, key, arr) => {
            //control points of Bezier curve
            let cpx1,
                cpx2,
                cpy1,
                cpy2;

            //only to add curvature to straight lines
            let dy,
                dx;

            if (key) {
                path += ' C';
                cpx2 = (val[0] + arr[key - 1][0]) / 2;
                cpx1 = (val[0] + arr[key - 1][0]) / 2;

                //would be straight horizontal line if don't do this
                if (Math.abs(val[1] - arr[key - 1][1]) < 9) {
                    dy = Math.abs(val[0] - arr[key - 1][0]) * straightCurvature;
                    cpy2 = val[1];
                    cpy1 = val[1];

                    if (trueOrFalse()) { //curvature direction
                        cpy1 -= dy;
                        cpy2 += dy;
                    } else {
                        cpy1 += dy;
                        cpy2 -= dy;
                    }
                    //would be straight vertical line if don't do this
                } else if (Math.abs(val[0] - arr[key - 1][0]) < 9) {
                    dx = Math.abs(val[1] - arr[key - 1][1]) * straightCurvature;
                    cpy1 = (val[1] + arr[key - 1][1]) / 2;
                    cpy2 = cpy1;
                    cpx1 = val[0];
                    cpx2 = cpx1;

                    if (trueOrFalse()) {
                        cpx1 -= dx;
                        cpx2 += dx;
                    } else {
                        cpx1 += dx;
                        cpx2 -= dx;
                    }
                } else {
                    cpy1 = arr[key - 1][1];
                    cpy2 = val[1];
                }

                path += `${cpx1.toFixed()},${cpy1.toFixed()} ${cpx2.toFixed()},${
                    cpy2.toFixed()} ${val[0].toFixed()},${val[1].toFixed()}`;
            } else {
                path += `${val[0].toFixed()},${val[1].toFixed()}`;
            }
        });

        return path;
    }

    /**
     * Calculates relative offset points.
     * @param {HTMLElement} childEl
     * @param {HTMLElement} parentEl
     * @return {Object}
     * @static
     */
    static relativeOffset(childEl, parentEl) {
        if (childEl && parentEl) {
            let currentEl = childEl.offsetParent;
            let x = childEl.offsetLeft;
            let y = childEl.offsetTop;

            while (currentEl && currentEl !== parentEl) {
                x += currentEl.offsetLeft;
                y += currentEl.offsetTop;
                currentEl = currentEl.offsetParent;
            }

            return {
                x,
                y,
            };
        }

        return {
            x: 0,
            y: 0,
        };
    }
}

UnitTreeController.$inject = [
    'PoolGroupCollection',
    'GSLBService',
    '$state',
    '$timeout',
    '$element',
    '$scope',
];

/**
 * @name unitTreeComponent
 * @property {module:avi/vs.UnitTreeController} controller
 * @property {module:avi/vs.unitTreeComponentBindings} bindings
 * @memberOf module:avi/vs
 * @description Component for VS/GSLB tree view.
 * @author Alex Malitsky, chitra
 */
angular.module('avi/vs').component('unitTree', {
    /**
     * @mixin unitTreeComponentBindings
     * @memberOf module:avi/vs
     * @property {module:avi/vs.VirtualService|GSLB} unit - VirtualService/GSLB item.
     * @property {boolean} isTreeOpen - flag to indicate whether the tree is open or not.
     */
    bindings: {
        unit: '<',
        isTreeOpen: '<',
    },
    controller: UnitTreeController,
    templateUrl: `${componentPath}/${componentName}/${componentName}.html`,
});
