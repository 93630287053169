/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import './match-container.less';

/**
 * @ngdoc component
 * @name  matchContainer
 * @module match/matchContainer
 * @description Container component used as a wrapper around MatchTarget Config Item configuration.
 * @param {string} header - Name to be used as the title.
 * @param {Function} onClose - Called when the close button is clicked.
 * @param {boolean} preventEdit - True if editing should be disallowed.
 * @author alextsg
 */
angular.module('avi/policies').component('matchContainer', {
    bindings: {
        header: '@',
        onClose: '&',
        preventEdit: '<',
    },
    transclude: true,
    templateUrl: 'src/components/forms/match-config/match-container/match-container.component.html',
});
