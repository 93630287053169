/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { BACKUP_ITEM_TOKEN } from '../system.tokens';

/**
 * @desc Collection for Backup items.
 * @author Alex Klyuev
 */
export class BackupCollection extends Collection {
    public static ajsDependencies = [
        BACKUP_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'backup',
            windowElement: 'backup-create',
            isStatic: false,
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(BACKUP_ITEM_TOKEN);
    }
}
