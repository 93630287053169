/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  HttpPolicySet
 * @description  HttpPolicySet item.
 */
function HttpPolicySetFactory(Item, HttpPolicy) {
    class HttpPolicySet extends Item {
        constructor(args) {
            super(args);

            const baseMatches = [
                'client_ip',
                'vs_port',
                'protocol',
                'method',
                'version',
                'path',
                'query',
                'hdrs',
                'cookie',
                'host_hdr',
                'source_ip',
            ];

            this.data['config'] = this.getDefaultConfig_() || {};

            this.policies = {
                http_request_policy: new HttpPolicy({
                    matches: baseMatches.concat([
                        'ip_reputation_type',
                    ]),
                    actions: [
                        'redirect_action',
                        'hdr_action',
                        'rewrite_url_action',
                        'switching_action',
                    ],
                }),
                http_response_policy: new HttpPolicy({
                    matches: baseMatches.concat([
                        'loc_hdr',
                        'status',
                        'rsp_hdrs',
                    ]),
                    actions: [
                        'hdr_action',
                        'loc_hdr_action',
                    ],
                }),
                http_security_policy: new HttpPolicy({
                    matches: baseMatches.concat([
                        'ip_reputation_type',
                        'bot_detection_result',
                    ]),
                    actions: [
                        'action',
                    ],
                }),
            };
        }

        /**
         * Override to populate HttpPolicy instances in this.policies with the new data.
         * @param {Object} data - HttpPolicySet data.
         * @override
         */
        updateItemData(data) {
            super.updateItemData(data);

            const config = this.getConfig();

            _.each(this.policies,
                (policy, property) => policy.updateData(config[property]));
        }

        /** @override */
        dataToSave() {
            const config = angular.copy(this.getConfig());

            config.index = config.index || 11;

            _.each(this.policies, (policyObj, policyName) => {
                config[policyName] = policyObj.dataToSave();
            });

            const { markers } = config;

            if (markers) {
                // filter out RBAC entries with an empty key
                config.markers = markers.filter(({ key }) => key);

                // delete empty RABC label list
                if (markers.length === 0) {
                    delete config.markers;
                }
            }

            return config;
        }

        /**
         * Returns true if any httpPolicySet policies contain any rules.
         */
        hasRules() {
            return _.any(this.policies, policy => policy.hasRules());
        }
    }

    Object.assign(HttpPolicySet.prototype, {
        objectName: 'httppolicyset',
    });

    return HttpPolicySet;
}

HttpPolicySetFactory.$inject = [
        'Item',
        'HttpPolicy',
];

angular.module('aviApp').factory('HttpPolicySet', HttpPolicySetFactory);
