/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, OnInit } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { take } from 'rxjs/operators';

import {
    IAviDiffGridConfig,
    IAviDiffGridRow,
} from 'ng/modules/data-grid/components/avi-diff-grid/avi-diff-grid.types';

import * as l10n from './vs-log-cinematic-section-datascript.l10n';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying the Datascript section in a VS log cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-datascript',
    templateUrl: './vs-log-cinematic-section-datascript.component.html',
})
export class VsLogCinematicSectionDatascriptComponent implements OnInit {
    public readonly l10nKeys = l10nKeys;

    public readonly datascriptInfo$ = this.vsLogCinematicStore.datascriptInfo$;

    public datascriptDiffGridConfig: IAviDiffGridConfig;

    public diffGridRows: IAviDiffGridRow[] = [];

    constructor(
        private readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.vsLogCinematicStore.datascriptInfo$
            .pipe(take(1))
            .subscribe(datascriptInfo => {
                if (datascriptInfo) {
                    const { error: right, stack_trace: left } = datascriptInfo;

                    this.diffGridRows = [{
                        left,
                        right,
                    }];
                }
            });

        this.datascriptDiffGridConfig = {
            leftField: {
                label: this.l10nService.getMessage(l10nKeys.datascriptErrorStackTraceLabel),
            },
            rightField: {
                label: this.l10nService.getMessage(l10nKeys.datascriptErrorLabel),
            },
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.emptyListPlaceholderLabel),
            },
            noDiff: true,
        };
    }
}
