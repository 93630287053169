/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { isString, keys } from 'underscore';
import {
    IVsLogsDefaultSignpostData,
    IVsLogsEndToEndTimingResponseData,
    IVsLogsEndToEndTimingSignpostData,
    IVsLogsGroupbyResponseData,
    IVsLogsGroupbyResponseResultData,
    IVsLogsGroupbySignificanceResultsData,
    IVsLogsGroupsSignpostData,
    IVsLogsPolicyGroupsResponseData,
    IVsLogsSignificanceSignpostInfo,
    IVsLogsSignpostRange,
    IVsLogsSignpostTransformedResult,
    IVsLogsSslGroupsResponseData,
    IVsLogsWafLatencyResponseData,
    IVsLogsWafLatencySignpostData,
    IVsLogsWafRuleGroupsRules,
    IVsLogsWafRuleGroupsSignpostData,
    TVsLogsSignpostApiResponseData,
    TVsLogsSignpostStoreData,
} from '../vs-logs.types';

import {
    customTemplates,
    e2eTimingGroupByKeys,
    e2eTimingL4KeysOrderedList,
    e2eTimingL7KeysOrderedList,
    signpostsWithRange,
    templatesConfiguration,
    wafLatencyPhases,
} from '../constants/vs-logs-signpost.constants';

const wafRuleGroupsIdKey = 'waf_log.rule_logs.rule_id';
const wafRuleGroupsNameKey = 'waf_log.rule_logs.rule_name';
const wafRuleGroupsGroupKey = 'waf_log.rule_logs.rule_group';
const nonSignificantLogKey = 'Non-Significant Log';

/**
 * Redirect to respective tranformation logic depending on type.
 */
export const transformSignpostData = (
    data: TVsLogsSignpostApiResponseData,
    configKey: string,
): TVsLogsSignpostStoreData => {
    switch (configKey) {
        case customTemplates.wafPhaseLatency:
            return transformWafLatencyResults(data as IVsLogsWafLatencyResponseData, configKey);

        case customTemplates.wafRuleGroups:
            return transformWafRuleGroupsResults(data as IVsLogsGroupbyResponseData);

        case customTemplates.policy:
            return transformPolicySignpostResults(data as IVsLogsPolicyGroupsResponseData);

        case customTemplates.ssl:
            return transformSslSignpostResults(data as IVsLogsSslGroupsResponseData);

        case customTemplates.significance:
            return transformSignificanceSignpostResults(
                data as IVsLogsGroupbyResponseData,
                configKey,
            );

        case customTemplates.e2eTiming:
            return transformEndToEndTimingSignpostResults(
                data as IVsLogsEndToEndTimingResponseData,
            );

        default:
            return transformDefaultSignpostData(data as IVsLogsGroupbyResponseData, configKey);
    }
};

/**
 * Transform received results for default template in the form usable for displaying.
 */
export const transformDefaultSignpostData = (
    data: IVsLogsGroupbyResponseData,
    configKey: string,
): TVsLogsSignpostStoreData => {
    if (signpostsWithRange.has(configKey)) {
        const groupby = templatesConfiguration[configKey].params;

        return transformResultsWithRange(data?.results, groupby);
    }

    return transformResultsForValue(data?.results, configKey);
};

/**
 * Transform results for signpost having range type.
 */
export const transformResultsWithRange = (
    results: IVsLogsGroupbyResponseResultData[] = [],
    groupby: string,
): IVsLogsDefaultSignpostData[] => {
    const transformedResult: IVsLogsDefaultSignpostData[] = [];

    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    results.forEach(res => {
        const value = res[groupby];

        if (value) {
            const { start, end } = value;

            res.value = {
                value,
                param: groupby,
                isValid: !(start === undefined || end === undefined || isNegativeRange(value)),
            };

            transformedResult.push({
                value: res.value,
                count: Number(res.count),
                percentage: Number(res.percentage?.toFixed(2)),
            });
        }
    });

    return transformedResult;
};

/**
 * Return true if time-range has negative range data.
 */
export const isNegativeRange = (range: IVsLogsSignpostRange): boolean => {
    const { start, end } = range;

    return start && end && Number(start) < 0 && Number(end) < 0;
};

/**
 *  Transform results, append attributes which are required for displaying default signpost.
 */
export const transformResultsForValue = (
    results: IVsLogsGroupbyResponseResultData[] = [],
    configKey: string,
): IVsLogsDefaultSignpostData[] => {
    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    const { params } = templatesConfiguration[configKey];
    const transformedResult: IVsLogsDefaultSignpostData[] = [];

    results.forEach((record: IVsLogsGroupbyResponseResultData) => {
        const transformedRecord: IVsLogsDefaultSignpostData = {
            count: Number(record.count),
            percentage: Number(record.percentage?.toFixed(2)),
        };

        if (Array.isArray(params)) {
            transformedRecord.values = params.map(param => {
                const recordValue = record[param];
                const resObj = {
                    value: recordValue,
                    param,
                    isValid: Boolean(recordValue),
                };

                return resObj;
            });
        } else if (isString(params)) {
            transformedRecord.value = selectValue(params, record);

            checkForInvalidValues(transformedRecord, configKey);
        }

        transformedResult.push(transformedRecord);
    });

    return transformedResult;
};

/**
 *  Transform results, append attributes which are required for displaying significance signpost.
 */
export const transformSignificanceSignpostResults = (
    results: IVsLogsGroupbyResponseData,
    configKey: string,
): IVsLogsGroupbySignificanceResultsData[] => {
    const { category_results: categoryResults = [] } = results;

    if (categoryResults.length > 10) {
        categoryResults.length = 10;
    }

    const { params: param } = templatesConfiguration[configKey];
    const transformedResult: IVsLogsGroupbySignificanceResultsData[] = [];

    categoryResults.forEach((parent: IVsLogsGroupbySignificanceResultsData) => {
        const nonSignificant = parent.primary_reason === nonSignificantLogKey;
        const value = nonSignificant ? '' : parent.primary_reason;
        const display = parent.primary_reason;

        parent.value = {
            display,
            value,
            contains: nonSignificant ? undefined : true,
            param,
            isValid: Boolean(display || value),
        };

        parent.children = parent.secondary_reasons?.map((val: IVsLogsSignificanceSignpostInfo) => {
            const value = val[param];
            const display = `${parent.value.value}: ${value}`;

            val.value = {
                value: display,
                contains: true,
                param,
                isValid: Boolean(display),
            };

            return val;
        }) || [];

        transformedResult.push(parent);
    });

    return transformedResult;
};

/**
 * Transform received results for WAF groups template in the form usable for displaying WAF rules
 * group signpost.
 */
export const transformWafRuleGroupsResults = (
    data: IVsLogsGroupbyResponseData,
): IVsLogsWafRuleGroupsSignpostData[] => {
    const wafRuleGroups = {};
    const groupCount = {};
    const { results = [] } = data;

    // We only show top 10 entries in signpost.
    if (results.length > 10) {
        results.length = 10;
    }

    results.forEach(item => {
        const { count, percentage } = item;
        const ruleId = item[wafRuleGroupsIdKey];
        const ruleName = item[wafRuleGroupsNameKey];
        const groupName = item[wafRuleGroupsGroupKey];
        const rules: IVsLogsWafRuleGroupsRules[] = wafRuleGroups[groupName] || [];
        let totalCount = groupCount[groupName] || 0;

        totalCount += count;
        groupCount[groupName] = totalCount;

        rules.push({
            ruleId: {
                value: ruleId,
                param: wafRuleGroupsIdKey,
            },
            ruleName: {
                value: ruleName,
                param: wafRuleGroupsNameKey,
            },
            count,
            percentage,
        });

        wafRuleGroups[groupName] = rules;
    });

    const groupKeys = Object.keys(wafRuleGroups);
    const result = groupKeys.map(groupName => {
        const rules = wafRuleGroups[groupName];
        const totalRuleCount = groupCount[groupName];

        return {
            rules,
            name: groupName,
            count: totalRuleCount,
            value: {
                value: groupName,
                param: wafRuleGroupsGroupKey,
            },
        };
    }) || [];

    return result;
};

/**
 * Transform received results for WAF latency template in the form usable for displaying
 * WAF latency signpost.
 */
export const transformWafLatencyResults = (
    results: IVsLogsWafLatencyResponseData,
    configKey: string,
): IVsLogsWafLatencySignpostData => {
    const { phases } = templatesConfiguration[configKey];
    const resObj: IVsLogsWafLatencySignpostData = {
        requestHeader: [],
        requestBody: [],
        responseHeader: [],
        responseBody: [],
    };

    if (results) {
        Object.values(results).forEach((res, index) => {
            const { name, groupby } = phases[index];

            resObj[wafLatencyPhases[index]] =
                res.results?.map((res: IVsLogsGroupbyResponseResultData) => {
                    const value = res[groupby];

                    return {
                        value,
                        param: groupby,
                        isValid: Boolean(value),
                        count: res.count,
                        percentage: res.percentage,
                        phase: name,
                    };
                });
        });
    }

    return resObj;
};

export const e2eAverageKeyGenerator = (key: string): string => {
    return `AVG(${key})`;
};

/**
 * Transform received results for e2e timing template in the form usable for displaying
 * e2e signpost.
 */
export const transformEndToEndTimingSignpostResults = (
    data: IVsLogsEndToEndTimingResponseData,
): IVsLogsEndToEndTimingSignpostData => {
    const [averageData] = data.average?.results;
    const e2eResult = data.e2eResult?.results;
    const transformedResultObj: IVsLogsEndToEndTimingSignpostData = {};

    /**
     * Transform average data from the API to usable values in signpost.
     * Backend does not return data in key value pairs with the key as the prop name,
     * rather it returns the data in an indexed format, where the index matches the
     * order of the requested params. Hence we determine what was asked for based on
     * count, and then match the values to the appropriate keys.
     */
    if (averageData) {
        /**
         * Determine the number of columns ('col[num]') returned by the API.
         * It may be much simpler/faster to use VirtualServiceStateService
         * to determine the present type of VS (L4/L7), however we want to maintain
         * stateless nature of our utils file.
         */
        const numberOfAverageDataCols =
            keys(averageData).reduce((acc, val) => {
                if (val.includes('col')) {
                    acc++;
                }

                return acc;
            }, 0);

        let orderedKeyList: string[] = [];

        /**
         * Determine the ordered key list (originally dictated by VS type)
         * based on the number of columns ('col[num]') returned by the API.
         */
        switch (numberOfAverageDataCols) {
            case e2eTimingL7KeysOrderedList.length:
                orderedKeyList = e2eTimingL7KeysOrderedList;
                break;
            case e2eTimingL4KeysOrderedList.length:
                orderedKeyList = e2eTimingL4KeysOrderedList;
                break;
        }

        transformedResultObj.average = {};

        /**
         * Backend returns the average e2e data without properly assigned keys,
         * but with the same indexing we provided in the request params. Hence we can use the
         * e2eTimingL7KeysOrderedList or e2eTimingL4KeysOrderedList to assign the
         * values from api response in the same order as e2eTimingL7KeysOrderedList maintains.
         */
        orderedKeyList.forEach((value: string, i: number) => {
            const apiVal = averageData[`col${i}`];

            if (apiVal !== undefined) {
                transformedResultObj.average[value] = apiVal.toFixed(0);
            }
        });
    }

    if (e2eResult) {
        const resultKeys = Object.keys(e2eResult[0]);
        const intersection = resultKeys.filter(
            key => Object.values(e2eTimingGroupByKeys).includes(key),
        );
        const groupby = intersection[0] || '';

        transformedResultObj.e2eResult = transformResultsWithRange(e2eResult, groupby);
    }

    return transformedResultObj;
};

/**
 * Transform received results for WAF latency template in the form usable for displaying
 * policy signpost.
 */
export const transformPolicySignpostResults = (
    data: IVsLogsPolicyGroupsResponseData,
): IVsLogsGroupsSignpostData[] => {
    const configKey = customTemplates.policy;
    const template = templatesConfiguration[configKey];
    const { query } = template;
    const results: IVsLogsGroupsSignpostData[] = [];

    Object.entries(query).forEach(([key, param]) => {
        const element = data[key];

        if (element) {
            const children = element.results.map((val: any) => {
                const currentValue = val[param as string];
                const value = {
                    value: currentValue,
                    param,
                    isValid: Boolean(currentValue),
                };

                val.value = value;

                return val;
            });

            results.push({
                value: template.subheader[key],
                children,
            });
        }
    });

    return results;
};

/**
 * Transform received results for ssl in the form usable for displaying ssl signpost.
 */
export const transformSslSignpostResults = (
    data: IVsLogsSslGroupsResponseData,
): IVsLogsGroupsSignpostData[] => {
    const configKey = customTemplates.ssl;
    const template = templatesConfiguration[configKey];
    const { query } = template;
    const res: IVsLogsGroupsSignpostData[] = [];

    // 2 types of data
    const { ssl, other } = data;

    // Ssl: 'ssl_version' section
    if (Array.isArray(ssl?.results)) {
        const { results } = ssl;

        const children = results.map((val: any) => {
            const param = query.ssl;
            const value = val[param];

            val.value = {
                value,
                param,
                isValid: Boolean(value),
            };

            return val;
        });

        res.push({
            value: template.subheader[customTemplates.ssl],
            children,
        });
    }

    // Other: 'ssl_cipher' section
    if (other) {
        Object.entries(template.key).forEach(([key, param]) => {
            const { category_results: categoryResults } = other;
            const data = categoryResults[template.key[key]];

            if (Array.isArray(data)) {
                const children = data.map(val => {
                    const { value } = val;

                    val.value = {
                        display: value,
                        value: `${param}:${value}`,
                        param: template.query.other,
                        isValid: Boolean(value),
                        contains: true,
                    };

                    return val;
                });

                res.push({
                    value: template.subheader[key],
                    children,
                });
            }
        });
    }

    return res;
};
/**
 * Check entries for which we have to display vsLogEmptyField for default signpost.
 */
export const checkForInvalidValues = (
    result: IVsLogsDefaultSignpostData,
    type: string,
): void => {
    const { value: record } = result;

    if (record) {
        if (type === 'referrer' || type === 'gslbservice_name' || type === 'uri_path') {
            if (record.value === '') {
                record.isValid = false;
            }
        } else if (type === 'http_version' || type === 'host' ||
            type === 'request-type' || type === 'dns_fqdn' ||
            type === 'response_content_type' || type === 'device' ||
            type === 'browser' || type === 'client_os' || type === 'user_id') {
            if (record.value === '') {
                record.isValid = false;
            }
        } else if (type === 'dns_qtype' || type === 'dns_etype' ||
            type === 'protocol') {
            if (!record.value) {
                record.isValid = false;
            }
        }
    }
};

/**
 * Update value attribute of entries which have single param, value is used for displaying
 * default signpost.
 */
export const selectValue = (
    param: string,
    record: IVsLogsGroupbyResponseResultData,
): IVsLogsSignpostTransformedResult => {
    let value = record[param];
    const result: IVsLogsSignpostTransformedResult = {
        value,
        param,
        isValid: true,
    };
    const either = [
        ['client_ip', 'client_ip6'],
        ['vs_ip', 'vs_ip6'],
        ['server_ip', 'server_ip6'],
    ];

    either.forEach(([param2, param1]) => {
        if (param1 in record && record[param1] !== '') {
            value = record[param1];
            result.param = param1;
        } else if (param2 in record) {
            value = record[param2];
            result.param = param2;
        }
    });

    if (value === '' || value === 0 || value === '0') {
        result.isValid = false;
    }

    result.value = value;

    return result;
};
