/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    FilterOperatorType,
    IVsLogsSignpostUpdatableParams,
} from 'ng/modules/vs-logs/vs-logs.types';

import {
    customTemplates,
    defaultSignpostParams,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import { defaultPageSize } from 'ng/modules/vs-logs/constants/default-values.constants';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDataGridConfig } from 'ng/modules/data-grid';
import * as globalL10n from 'global-l10n';
import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-custom-waf-rule-groups.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * @description Signpost component for WAF rule groups section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-waf-rule-groups',
    templateUrl: './vs-logs-signpost-custom-waf-rule-groups.component.html',
})
export class VsLogsSignpostCustomWafRuleGroupsComponent implements OnInit, AfterViewInit {
    /**
     * TemplateRef for Expanded content of the group rules.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('ruleGroupCountFieldTemplateRef')
    public ruleGroupCountFieldTemplateRef: TemplateRef<HTMLElement>;

    @ViewChild('nameFieldTemplateRef')
    public nameFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly FilterOperatorType = FilterOperatorType;

    public wafGroupsGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        private readonly l10nService: L10nService,
    ) {}

    /** @override */
    public ngOnInit(): void {
        const fullSignpostParams = this.getWafGroupsRequestParams();

        this.vsLogsSignpostStore.getVsLogSignpostData(fullSignpostParams);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;
        const configKey = customTemplates.wafRuleGroups;

        this.wafGroupsGridConfig = {
            getRowId: (index: number): number => index,
            layout: {
                hidePagination: true,
                hideCheckboxes: true,
                showFooter: false,
            },
            fields: [
                {
                    label: templatesConfiguration[configKey].name,
                    id: 'name',
                    templateRef: this.nameFieldTemplateRef,
                },
                {
                    label: l10nService.getMessage(globalL10nKeys.logsLabel),
                    id: 'logs',
                    templateRef: this.ruleGroupCountFieldTemplateRef,
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    public trackbyIndex(index: number): number {
        return index;
    }

    /**
     * Get request params for WAF rule groups signpost.
     */
    private getWafGroupsRequestParams(): IVsLogsSignpostUpdatableParams {
        const { wafRuleGroups: configKey } = customTemplates;
        const template = templatesConfiguration[configKey];
        const { queryParams, page_size: pageSize } = template;
        const groupby = queryParams.join(',');

        const fullSignpostParams: IVsLogsSignpostUpdatableParams = {
            ...defaultSignpostParams,
            groupby,
            page_size: pageSize || defaultPageSize,
        };

        return fullSignpostParams;
    }
}
