/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'local-backup-card';
const prefix = `${moduleName}.${componentName}`;

export const localBackupLabel = `${prefix}.localBackupLabel`;
export const enabledDataLabel = `${prefix}.enabledDataLabel`;
export const deactivatedDataLabel = `${prefix}.deactivatedDataLabel`;
export const directoryLabel = `${prefix}.directoryLabel`;

export const ENGLISH = {
    [localBackupLabel]: 'Local Backup',
    [enabledDataLabel]: 'Enabled',
    [deactivatedDataLabel]: 'Deactivated',
    [directoryLabel]: 'Directory',
};
