/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    getBrowserCultureLang,
    I18nContext,
    LocaleService,
    PatternCategories,
    VIPService,
} from '@vmw/ngx-vip';

/**
 * The name of 'product' and 'component' should be consistent with the release master.
 * 'version' is used to manage translation versions between different releases.
 * The source strings needs to be registered to this configuration, 'sourceBundles'
 * supports the form of array, so that each module is a separate object.
 */
const I18nConfig = {
    productID: 'nsxalb',
    component: 'ui',
    version: '22.1.1',
    i18nScope: [
        PatternCategories.NUMBER,
        PatternCategories.DATE,
    ],
    // Two modes are supported: online mode and bundle mode,
    // online mode is more convenient to update translations.
    // host: 'https://g11n-vip-stg-1.eng.vmware.com:8090/',
    i18nAssets: 'src/assets/i18n/',
    timeout: 5000,
};

// Turning this feature on as we are displaying language change dropdown by default.
const i18nEnabled = true;

/**
 * Initialize the configuration for the specific application.
 * Locale information can be fetched from browser or user profile.
 * @param vipService Connect and consume VIP service to get translations.
 * @param localeService Manage the locale setting globally.
 * @param i18nContext The global i18n environment for all components.
 */
export function initVIPConfig(
    vipService: VIPService,
    localeService: LocaleService,
    i18nContext: I18nContext,
): () => void {
    // Specify locale, either from browser language or user's profile.
    const browserLanguage: string = getBrowserCultureLang();

    // Initialize the current state of i18n feature.
    i18nContext.i18nEnabled = i18nContext.i18nEnabled || i18nEnabled;

    // Normalize the language code from browser to meet VMware i18n standard.
    // Such like: zh-CN, Chinese (Simplified) is considered to be neutral language.
    // It does not specify any region. Using iso 'zh-Hans' instead of 'zh-CN'.
    const currentLanguage = i18nContext.preferredLanguage ||
        localeService.normalizeLanguageCode(browserLanguage);

    localeService.init(currentLanguage);

    return () => vipService.initData(I18nConfig);
}
