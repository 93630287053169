/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export enum CREDENTIALS_TYPE {
    SSH = 'SSH',
    AZURE = 'AZURE',
    GCP = 'GCP',
    NSXT = 'NSXT',
    VCENTER = 'VCENTER',
}

export enum SSH_AUTHENTICATION_TYPE {
    SSH_KEY = 'SSH_KEY',
    USER_PASS = 'USER_PASS',
}

export enum AZURE_AUTHENTICATION_TYPE {
    USER_PASS = 'USER_PASS',
    SERVICE_PRINCIPAL = 'SERVICE_PRINCIPAL',
}
