/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    Input,
    OnInit,
    Renderer2,
} from '@angular/core';

import './vs-log-empty-field.directive.less';

/**
 * @description
 *     Direcitive used for empty or undefined field to set "N/A" text.
 *     Set with dash "-" if specified.
 * @author Zhiqian Liu
 */
@Directive({
    selector: '[vsLogEmptyField]',
})
export class VsLogEmptyFieldDirective implements OnInit {
    /**
     * Informs if an em dash to be used.
     */
    @Input()
    public useDash ?= false;

    /**
     * The native HTML element that the directive is bound to.
     */
    private domElement: HTMLElement;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
    ) {
        this.domElement = this.elementRef.nativeElement;
        this.renderer.addClass(this.domElement, 'vs-log-empty-field');
    }

    /** @override */
    public ngOnInit(): void {
        const textElement: HTMLElement = this.renderer.createText(this.useDash ? '—' : 'N/A');

        this.renderer.appendChild(this.domElement, textElement);
    }
}
