/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { HttpCacheConfig } from 'object-types';
import { IHttpCacheConfig } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { PathMatchConfigItem } from 'ajs/modules/match/factories/path-match.config-item.factory';

import {
    IRepeatedCollectionOrCustomStringModel,
} from 'ng/modules/avi-forms/components/avi-repeated-collection-or-custom-value';

type THttpCacheConfigPartial = Omit<IHttpCacheConfig, 'uri_non_cacheable'>;

interface IHttpCacheConfigConfig extends THttpCacheConfigPartial {
    uri_non_cacheable?: PathMatchConfigItem,
    cacheableMimeTypes?: IRepeatedCollectionOrCustomStringModel[]; // UI only field.
    nonCacheableMimeTypes?: IRepeatedCollectionOrCustomStringModel[]; // UI only field.
}

/**
 * @desc HttpCacheConfig MessageItem class.
 * @author Nisar Nadaf
 */
export class HttpCacheConfigConfigItem extends MessageItem<IHttpCacheConfigConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HttpCacheConfig,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns list of values required to render avi-repeated-collection-or-custom-string component.
     */
    private static createRepeatedCollectionOrCustomStringData(
        groupRefs: string[] = [],
        customStrings: string[] = [],
    ): IRepeatedCollectionOrCustomStringModel[] {
        const customValues = customStrings.reduce((
            values: IRepeatedCollectionOrCustomStringModel[],
            customValue: string,
        ): IRepeatedCollectionOrCustomStringModel[] => {
            values.push({ customValue });

            return values;
        }, []);

        const collectionRefs = groupRefs.reduce((
            values: IRepeatedCollectionOrCustomStringModel[],
            collectionRef: string,
        ): IRepeatedCollectionOrCustomStringModel[] => {
            values.push({ collectionRef });

            return values;
        }, []);

        const combinedData = collectionRefs.concat(customValues);

        // If no values present then add empty value to show input controls on UI.
        return combinedData.length ? combinedData : [{}];
    }

    /**
     * Returns object containing list of collectionRefs and list of customStrings.
     */
    private static getCollectionRefsAndCustomStrings(
        valueList: IRepeatedCollectionOrCustomStringModel[],
    ): Record<string, string[]> {
        return valueList.reduce((
            data: Record<string, string[]>,
            value: IRepeatedCollectionOrCustomStringModel,
        ): Record<string, string[]> => {
            if (!data.collectionRefs) {
                data.collectionRefs = [];
            }

            if (!data.customValues) {
                data.customValues = [];
            }

            if (value.collectionRef && value.collectionRef !== '') {
                data.collectionRefs.push(value.collectionRef);
            } else if (value.customValue && value.customValue !== '') {
                data.customValues.push(value.customValue);
            }

            return data;
        }, {});
    }

    /**
     * Method to add uri_non_cacheable Message Item.
     */
    public addCacheConfigPathMatch(): void {
        this.safeSetNewChildByField('uri_non_cacheable');
    }

    /**
     * Method to remove uri_non_cacheable Message Item.
     */
    public deleteCacheConfigPathMatch(): void {
        delete this.config.uri_non_cacheable;
    }

    /**
     * Returns true if uri_non_cacheable object present in config.
     */
    public get selectiveCachingEnabled(): boolean {
        return Boolean(this.config.uri_non_cacheable);
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const {
            uri_non_cacheable: uriNonCacheable,
        } = this.getConfig();

        // Remove uri_non_cacheable object if values are not set.
        // this helps to uncheck the non-cacheable-url checkbox on create flow.
        if (!uriNonCacheable?.config.string_group_refs?.length) {
            delete this.config.uri_non_cacheable;
        }

        this.config.cacheableMimeTypes = this.getCacheableMimeTypes();
        this.config.nonCacheableMimeTypes = this.getNonCacheableMimeTypes();
    }

    /**
     * @override
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        // Extract collectionRefs and customValues and update config.
        this.updateCacheableMimeTypesConfig();
        delete this.config.cacheableMimeTypes;

        this.updateNonCacheableMimeTypesConfig();
        delete this.config.nonCacheableMimeTypes;
    }

    /**
     * Returns list of cacheable MIME types to
     * render using avi-repeated-collection-or-custom-string component.
     */
    private getCacheableMimeTypes(): IRepeatedCollectionOrCustomStringModel[] {
        return HttpCacheConfigConfigItem.createRepeatedCollectionOrCustomStringData(
            this.config.mime_types_group_refs,
            this.config.mime_types_list,
        );
    }

    /**
     * Returns list of non cacheable MIME types to
     * render using avi-repeated-collection-or-custom-string component.
     */
    private getNonCacheableMimeTypes(): IRepeatedCollectionOrCustomStringModel[] {
        return HttpCacheConfigConfigItem.createRepeatedCollectionOrCustomStringData(
            this.config.mime_types_block_group_refs,
            this.config.mime_types_block_lists,
        );
    }

    /**
     * Updates cacheable MIME types from config.
     */
    private updateCacheableMimeTypesConfig(): void {
        const data = HttpCacheConfigConfigItem
            .getCollectionRefsAndCustomStrings(this.config.cacheableMimeTypes);

        this.config.mime_types_group_refs = data.collectionRefs;
        this.config.mime_types_list = data.customValues;
    }

    /**
     * Updates non cacheable MIME types from config.
     */
    private updateNonCacheableMimeTypesConfig(): void {
        const data = HttpCacheConfigConfigItem
            .getCollectionRefsAndCustomStrings(this.config.nonCacheableMimeTypes);

        this.config.mime_types_block_group_refs = data.collectionRefs;
        this.config.mime_types_block_lists = data.customValues;
    }
}
