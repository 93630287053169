/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function techSupportCaptureDataSourceFactory(DataSource) {
    /**
     * @alias module:services/TechSupportCaptureDataSource
     * @extends DataSource
     * @private
     */
    class TechSupportCaptureDataSource extends DataSource {
        constructor(args) {
            args = Object.assign(
                {
                    updateInterval: 15,
                    defaultFields: [
                        {
                            id: 'config',
                            preserved: true,
                            subscribers: ['__default_field'],
                        },
                    ],
                },
                args,
            );

            super(args);
        }

        /** @override */
        getRequestParams_() {
            const params = super.getRequestParams_();

            params.objectName_ = this.owner_.objectName_;

            return params;
        }

        /** @override */
        processResponse_({ data }) {
            this.owner_.setTechSupportCaptureStatus_(data);
            this.owner_.triggerUpdateEvent();
        }
    }

    return TechSupportCaptureDataSource;
}

techSupportCaptureDataSourceFactory.$inject = ['DataSource'];

/**
 * @ngdoc service
 * @name TechSupportCaptureDataSource
 * @module services/TechSupportCaptureDataSource
 * @author Akul Aggarwal
 * @description
 *
 *      Returns last tech support generation data for @TechSupportCapture.
 *
 */
angular.module('aviApp').factory('TechSupportCaptureDataSource',
    techSupportCaptureDataSourceFactory);
