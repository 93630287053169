/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';

import {
    IAviCollectionDataGridConfig,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import * as globalL10n from 'global-l10n';

import {
    PingAccessAgentProfile,
    PingAccessAgentProfileCollection,
} from 'ajs/modules/security/factories/ping-access-agent-profile';

import * as l10n from './ping-access-agent-profile-page.l10n';

type TPingAccessAgentProfileCollection = typeof PingAccessAgentProfileCollection;

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * @description Ping Access Agent Profile list page.
 *
 * @author Shahab Hashmi
 */
@Component({
    selector: 'ping-access-agent-profile-page',
    templateUrl: './ping-access-agent-profile-page.component.html',
})
export class PingAccessAgentProfilePageComponent implements OnInit, OnDestroy {
    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * * Configuration object to display Ping Access Agent Profile collection.
     */
    public pingAccessAgentProfilePageGridConfig: IAviCollectionDataGridConfig;

    /**
     * Collection object to store the Ping Access Agent Profile collection.
     */
    private readonly pingAccessAgentProfileCollection: PingAccessAgentProfileCollection;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject(PingAccessAgentProfileCollection)
        PingAccessAgentProfileCollection: TPingAccessAgentProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.pingAccessAgentProfileCollection = new PingAccessAgentProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.pingAccessAgentProfileCollection;

        this.pingAccessAgentProfilePageGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.pingAccessAgentProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }, {
                id: 'pool',
                label: this.l10nService.getMessage(globalL10nKeys.poolLabel),
                transform: row => this.stringService.name(
                    row.getConfig().pingaccess_pool_ref,
                ),
            }],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: PingAccessAgentProfile) => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.pingAccessAgentProfileCollection.destroy();
    }
}
