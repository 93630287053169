/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Type,
} from '@angular/core';

import {
    AuthAttributeMatchComponent,
    HostHdrMatchComponent,
    HttpMethodMatchComponent,
    IMatchOption,
    JwtMatchComponent,
    PathOrQueryMatchComponent,
} from 'ng/modules/match/components';

import {
    AuthorizationAction,
    AuthorizationMatch,
    AuthorizationRule,
} from 'object-types';

import { AuthorizationActionEnum, SSOPolicyType } from 'generated-types';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { AuthorizationRuleConfigItem }
    from 'ajs/modules/sso-policy/factories/authorization-rule.config-item.factory';

import * as l10n from './authorization-rule-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal Component for AuthorizationRule Configuration.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'authorization-rule-modal',
    templateUrl: './authorization-rule-modal.component.html',
})
export class AuthorizationRuleModalComponent implements OnInit {
    /**
     * AuthorizationRuleConfigItem instance.
     */
    @Input()
    public editable: AuthorizationRuleConfigItem;

    /**
     */
    @Input()
    public type: SSOPolicyType;

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<AuthorizationRuleConfigItem>();

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectTypes used in template.
     */
    public readonly objectTypes = {
        [AuthorizationRule]: AuthorizationRule,
        [AuthorizationAction]: AuthorizationAction,
    };

    /**
     * AuthorizationActionEnum Hash.
     */
    public readonly authorizationActionEnumHash = {
        [AuthorizationActionEnum.ALLOW_ACCESS]: AuthorizationActionEnum.ALLOW_ACCESS,
        [AuthorizationActionEnum.CLOSE_CONNECTION]: AuthorizationActionEnum.CLOSE_CONNECTION,
        [AuthorizationActionEnum.HTTP_LOCAL_RESPONSE]: AuthorizationActionEnum.HTTP_LOCAL_RESPONSE,
    };

    /**
     * List of match options the user is able to add.
     */
    public matchOptions: IMatchOption[];

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.matchOptions = [
            {
                component: AuthAttributeMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.attributeLabel),
                objectType: AuthorizationMatch,
                fieldName: 'attr_matches',
            },
            {
                component: HostHdrMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.hostHeaderLabel),
                objectType: AuthorizationMatch,
                fieldName: 'host_hdr',
            },
            {
                component: PathOrQueryMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.pathLabel),
                objectType: AuthorizationMatch,
                fieldName: 'path',
            },
            {
                component: HttpMethodMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.methodLabel),
                objectType: AuthorizationMatch,
                fieldName: 'method',
            },
        ];

        if (this.type === SSOPolicyType.SSO_TYPE_JWT) {
            this.matchOptions.push({
                component: JwtMatchComponent as Type<Component>,
                label: this.l10nService.getMessage(l10nKeys.jwtLabel),
                objectType: AuthorizationMatch,
                fieldName: 'access_token',
            });
        }
        // Backend accepts jwt match for other sso types too. Should we remove the condition?
    }

    /**
     * Fires on form submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Fires on action type change.
     * Clears status_code field.
     */
    public handleActionTypeChange(): void {
        this.editable.action.onTypeChange();
    }
}
