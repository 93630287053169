/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'alert';
const componentName = 'alert-actions-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleAlertLevel = `${prefix}.columnTitleAlertLevel`;
export const columnTitleSNMPTrap = `${prefix}.columnTitleSNMPTrap`;
export const columnTitleExternalOnly = `${prefix}.columnTitleExternalOnly`;

export const ENGLISH = {
    [headerLabel]: 'Alert Actions',
    [columnTitleAlertLevel]: 'Alert Level',
    [columnTitleSNMPTrap]: 'SNMP Trap',
    [columnTitleExternalOnly]: 'External Only',
};
