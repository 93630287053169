/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'network-security-policy';
const prefix = `${moduleName}.${componentName}`;

export const ruleNameDuplicatedMessage = `${prefix}.ruleNameDuplicatedMessage`;
export const ruleNameAlreadyInUseMessage = `${prefix}.ruleNameAlreadyInUseMessage`;
export const clientIpMatchLabel = `${prefix}.clientIpMatchLabel`;
export const servicePortMatchLabel = `${prefix}.servicePortMatchLabel`;
export const ipReputationMatchLabel = `${prefix}.ipReputationMatchLabel`;
export const createRuleActionLabel = `${prefix}.createRuleActionLabel`;
export const deleteRuleActionLabel = `${prefix}.deleteRuleActionLabel`;
export const menuActionLabel = `${prefix}.menuActionLabel`;
export const ipReputationDbHeader = `${prefix}.ipReputationDbHeader`;
export const ipReputationDbInputPlaceholder = `${prefix}.ipReputationDbInputPlaceholder`;
export const geoDbHeader = `${prefix}.geoDbHeader`;
export const geoDbInputPlaceholder = `${prefix}.geoDbInputPlaceholder`;
export const addNetworkSecurityRuleHeader = `${prefix}.addNetworkSecurityRuleHeader`;
export const ruleNameInputLabel = `${prefix}.ruleNameInputLabel`;
export const loggingCheckboxLabel = `${prefix}.loggingCheckboxLabel`;
export const matchingRulesHeader = `${prefix}.matchingRulesHeader`;
export const actionHeader = `${prefix}.actionHeader`;
export const burstSizeInputLabel = `${prefix}.burstSizeInputLabel`;
export const burstSizeInputPlaceholder = `${prefix}.burstSizeInputPlaceholder`;
export const maximumRateInputLabel = `${prefix}.maximumRateInputLabel`;
export const maximumRateInputPlaceholder = `${prefix}.maximumRateInputPlaceholder`;
export const rbacHeader = `${prefix}.rbacHeader`;

export const ENGLISH = {
    [ruleNameDuplicatedMessage]: '{0} - duplicated',
    [ruleNameAlreadyInUseMessage]: 'Rule name already in use',
    [clientIpMatchLabel]: 'Client IP',
    [servicePortMatchLabel]: 'Service Port',
    [ipReputationMatchLabel]: 'IP Reputation',
    [createRuleActionLabel]: 'Create rule',
    [deleteRuleActionLabel]: 'Delete',
    [menuActionLabel]: 'Menu',
    [ipReputationDbHeader]: 'IP Reputation DB',
    [ipReputationDbInputPlaceholder]: 'Select IP Reputation DB',
    [geoDbHeader]: 'Geo DB',
    [geoDbInputPlaceholder]: 'Select Geo DB',
    [addNetworkSecurityRuleHeader]: 'Add Network Security Rule',
    [ruleNameInputLabel]: 'Rule Name',
    [loggingCheckboxLabel]: 'Logging',
    [matchingRulesHeader]: 'Matching Rules',
    [actionHeader]: 'Action',
    [burstSizeInputLabel]: 'Burst Size',
    [burstSizeInputPlaceholder]: 'Connection Burst',
    [maximumRateInputLabel]: 'Maximum Rate',
    [maximumRateInputPlaceholder]: 'Connections per sec',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
};
