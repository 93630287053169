/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { defaultPageSize } from 'ng/modules/vs-logs/constants/default-values.constants';

import {
    defaultSignpostParams,
    signpostsWithRange,
    templatesConfiguration,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import {
    IVsLogsSignpostTemplateConfig,
    IVsLogsSignpostUpdatableParams,
} from 'ng/modules/vs-logs/vs-logs.types';

import { isString } from 'underscore';
import { VsLogsSignpostStore } from '../vs-logs-signpost.store';

/**
 * @description
 *     Component for displaying default singpost template.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-default',
    templateUrl: './vs-logs-signpost-default.component.html',
})
export class VsLogsSignpostDefaultComponent implements OnInit {
    /**
     * Selected signpost config key.
     */
    @Input()
    public configKey: string;

    /**
     * Selected signpost respective configuration in templatesConfiguration.
     */
    public activeTemplateConfig: IVsLogsSignpostTemplateConfig;

    constructor(public readonly vsLogsSignpostStore: VsLogsSignpostStore) {}

    /** @override */
    public ngOnInit(): void {
        this.activeTemplateConfig = templatesConfiguration[this.configKey];

        this.fetchSignpostData();
    }

    private fetchSignpostData(): void {
        let fullSignpostParams: IVsLogsSignpostUpdatableParams = this.getRequestParams();

        if (signpostsWithRange.has(this.configKey)) {
            fullSignpostParams = this.getRLengthRequestParams(fullSignpostParams);
        }

        this.vsLogsSignpostStore.getVsLogSignpostData(fullSignpostParams);
    }

    /**
     * Get request params for default signpost.
     */
    private getRequestParams(): IVsLogsSignpostUpdatableParams {
        const { queryParams, page_size: pageSize } = this.activeTemplateConfig;
        let groupby: string;

        if (Array.isArray(queryParams)) {
            groupby = queryParams.join(',');
        } else if (isString(queryParams)) {
            groupby = queryParams;
        }

        const fullSignpostParams: IVsLogsSignpostUpdatableParams = {
            ...defaultSignpostParams,
            groupby,
            page_size: pageSize || defaultPageSize,
        };

        return fullSignpostParams;
    }

    /**
     * Get request params for default signpost with range type.
     */
    private getRLengthRequestParams(
        requestParams: IVsLogsSignpostUpdatableParams,
    ): IVsLogsSignpostUpdatableParams {
        const { params } = this.activeTemplateConfig;

        requestParams = {
            ...requestParams,
            step: '512',
            expstep: true,
            orderby: isString(params) ? `${params}` : '-count',
        };

        if (this.configKey === 'icap_log.request_logs.latency') {
            requestParams.step = '100';
            requestParams.orderby = '-count';
        }

        return requestParams;
    }
}
