/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './vs-logs-signpost.l10n';
import { customTemplates } from '../../constants/vs-logs-signpost.constants';
import { IVsLogsSelectedSignpostConfig } from '../../vs-logs.types';
import { VsLogsSignpostStore } from './vs-logs-signpost.store';
import './vs-logs-signpost.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for deciding which signpost cutom or defualt to be displayed.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost',
    templateUrl: './vs-logs-signpost.component.html',
})
export class VsLogsSignpostComponent implements OnInit {
    /**
     * Contains selected signpost name and its respective config key.
     */
    @Input()
    public selectedSignpostConfig: IVsLogsSelectedSignpostConfig;

    public readonly l10nKeys = l10nKeys;

    /**
     * Flag indicating if its custom signpost or default.
     */
    public isCustomComponent = false;

    constructor(
        l10nService: L10nService,
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.isCustomComponent = Object.values(customTemplates).includes(
            this.selectedSignpostConfig.configKey,
        );

        this.vsLogsSignpostStore.setVsLogSignpostGroupby(this.selectedSignpostConfig);
    }

    /**
     * Reloads vs-logs page.
     */
    public reloadPage(): void {
        this.vsLogsSignpostStore.closeSignpost();
        window.location.reload();
    }
}
