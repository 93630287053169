/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    VHMatchRuleConfigItem,
} from 'ajs/modules/vs/factories/vh-match-rule.config-item.factory';

import {
    VHMatchConfigItem,
} from 'ajs/modules/vs/factories/vh-match.config-item.factory';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import * as l10n from './vh-match-rule-grid.l10n';
import './vh-match-rule-grid.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Virtual Hosting Rule Grid.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'vh-match-rule-grid',
    templateUrl: './vh-match-rule-grid.component.html',
})
export class VHMatchRuleGridComponent implements AfterViewInit {
    @Input()
    public editable : VHMatchConfigItem;

    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public rulesGridConfig: IAviDataGridConfig;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.rulesGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform: (rule: VHMatchRuleConfigItem) => rule.getName(),
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.matchLabel),
                    id: 'match',
                    transform: (rule: VHMatchRuleConfigItem) => rule.matchCount,
                },
            ],
            getRowId: (index: number): number => index,
            singleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (rule: VHMatchRuleConfigItem) => this.deleteVHMatchRule(rule),
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (rule: VHMatchRuleConfigItem) => this.editVHMatchRule(rule),
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (rules: VHMatchRuleConfigItem[]) => {
                        rules.forEach((rule: VHMatchRuleConfigItem) => {
                            this.deleteVHMatchRule(rule);
                        });
                    },
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
                withReordering: true,
            },
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }

    /**
     * Add VHMatchRule event handler.
     */
    public addVHMatchRule(): void {
        this.editable.addVHMatchRule();
    }

    /**
     * Edit VHMatchRule event handler.
     */
    public editVHMatchRule(rule: VHMatchRuleConfigItem): void {
        this.editable.editVHMatchRule(rule);
    }

    /**
     * Delete VHMatchRule event handler.
     */
    public deleteVHMatchRule(rule: VHMatchRuleConfigItem): void {
        this.editable.deleteVHMatchRule(rule);
    }
}
