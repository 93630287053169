/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import { NetworkProfile } from 'ajs/modules/network';

import {
    NetworkProfile as NetworkProfileObjectType,
    NetworkProfileUnion,
} from 'object-types';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { ClrFormLayout } from '@clr/angular';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { AviContinueConfirmationComponent } from 'ng/modules/dialog';

import {
    INetworkProfileConfigWithDsr,
    ProtocolTypes,
} from 'ajs/modules/network/factories/network-profile/network-profile.item.types';

import { DialogService } from 'ng/modules/core';
import './network-profile-modal.component.less';
import * as l10n from './network-profile-modal.l10n';

/**
 * Network profile type change confirmation dialog.
 */
const NETWORK_PROFILE_TYPE_CHANGE_DIALOG_ID = 'network-profile-type-change-confirmation';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Network profile full-modal component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'network-profile-modal',
    templateUrl: './network-profile-modal.component.html',
})
export class NetworkProfileModalComponent implements OnInit {
    /**
     * NetworkProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: NetworkProfile;

    /**
     * NetworkProfile object type enum.
     */
    public readonly ObjectType = {
        NetworkProfile: NetworkProfileObjectType,
        NetworkProfileUnion,
    };

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for network profile modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Protocol Types dropdown options.
     */
    public protocolTypesEnums: IAviDropdownOption[] = [];

    /**
     * Hash of all available network profile types.
     */
    public readonly ProtocolTypes = ProtocolTypes;

    /**
     * Check the status of DSR profile is dsr Enabled on not.
     */
    public dsrEnabled = false;

    /**
     * Variable will hold network profile union type.
     */
    public selectedProfileType: string;

    constructor(
        schemaService: SchemaService,
        private readonly l10nService: L10nService,
        private readonly dialogService: DialogService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const protocolType = schemaService.getEnumValues('ProtocolType');

        this.protocolTypesEnums = createOptionsFromEnumProps(protocolType);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.editable.supportsDsrProfile()) {
            const { config: profile } = this.editable.getProfileConfig();

            this.dsrEnabled = 'dsr_profile' in profile && Boolean(this.editable.id);

            if (!this.dsrEnabled) {
                this.onDsrChange();
            }
        }

        this.selectedProfileType = this.editable.config.profile.config.type;

        // To remove unwanted types from network profile union.
        if (!this.editable.id) {
            this.editable.onTypeChange();
        }
    }

    /**
     * On Dsr Profile checkbox change update  create or remove Dsr Profile..
     */
    public onDsrChange(): void {
        const profile = this.editable.getProfileConfig() as unknown as INetworkProfileConfigWithDsr;

        if (this.dsrEnabled) {
            profile.createDsrProfile();
        } else {
            profile.removeDsrProfile();
        }
    }

    /**
     * Method will check network profile type equal to selected ProtocolTypes.
     */
    public isProtocolType(type: ProtocolTypes): boolean {
        return this.editable.getType() === type;
    }

    /**
     * Method will be called on network profile type change.
     */
    public handleTypeChange(): void {
        this.networkProfileTypeChangeConfirmation();
    }

    /**
     * Displays network profile type Change Confirmation dialog.
     */
    public networkProfileTypeChangeConfirmation(): void {
        return this.dialogService.add({
            id: NETWORK_PROFILE_TYPE_CHANGE_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: this.l10nService.getMessage(l10nKeys.discardedCurrentValuesConfirmation),
                customHeader: this.l10nService.getMessage(l10nKeys.resetToDefaultValuesHeader),
                onConfirm: () => {
                    this.dialogService.remove(NETWORK_PROFILE_TYPE_CHANGE_DIALOG_ID);
                    this.editable.config.profile.config.type = this.selectedProfileType;
                    this.editable.onTypeChange();
                },
                onClose: () => {
                    this.dialogService.remove(NETWORK_PROFILE_TYPE_CHANGE_DIALOG_ID);
                    this.selectedProfileType = this.editable.config.profile.config.type;
                },
            },
        });
    }

    /**
     * Handle submit and set DSR profile status to the editable.
     */
    public handleSubmit(): void {
        this.editable.setDsrEnabled(this.dsrEnabled);
        this.editable.submit();
    }
}
