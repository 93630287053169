/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import './helper-text-tooltip-icon.less';
import * as l10n from './helper-text-tooltip-icon.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name HelperTextTooltipIconComponent
 * @description Component for an info icon that shows helpful text on hover.
 * @author alextsg, Zhiqian Liu
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'helper-text-tooltip-icon',
    templateUrl: './helper-text-tooltip-icon.component.html',
})
export class HelperTextTooltipIconComponent implements OnInit {
    /**
     * Custom text unrelated to Schema to be shown.
     */
    @Input()
    public tooltipText = '';

    /**
     * The Schema object type that the field belongs to.
     */
    @Input()
    public objectType = '';

    /**
     * The Schema field that contains the description text.
     */
    @Input()
    public fieldName = '';

    /**
     * The Schema enum type that the enum value belongs to.
     */
    @Input()
    public enumType = '';

    /**
     * The Schema enum value that maps to contains the description text.
     */
    @Input()
    public enumValue = '';

    /**
     * If true, hides the range text.
     */
    @Input()
    public hideRange = false;

    /**
     * If objectType is not available, we use range passed for showing value of range text.
     */
    @Input()
    public rangeText = '';

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Description for the field of an object, applicable when objectType and fieldName are set.
     */
    public fieldDescription = '';

    /**
     * Range of valid values, applicable when objectType and fieldName are set.
     */
    public fieldRange = '';

    /**
     * Special values for a field, ex. when 0 as a value means 'Infinite'. Applicable when
     * objectType and fieldName are set.
     */
    public fieldSpecialValues = '';

    /**
     * True if the field is immutable, i.e. cannot be changed when editing an object. Applicable
     * when objectType and fieldName are set.
     */
    public isImmutable = false;

    /**
     * True if the objectType and fieldName bindings are set.
     */
    private isObjectField = false;

    constructor(
        private readonly schemaService: SchemaService,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public ngOnInit(): void {
        // Called first as it's used to set the fields below.
        this.isObjectField = this.checkObjectField();

        this.fieldDescription = this.getFieldDescription();
        this.fieldRange = this.getFieldRange();
        this.fieldSpecialValues = this.getFieldSpecialValues();
        this.isImmutable = this.isImmutableField();
    }

    /**
     * Returns the description text for the field of an object, applicable when objectType and
     * fieldName are passed in.
     */
    private getFieldDescription(): string {
        if (this.isObjectField) {
            return this.schemaService.getFieldDescription(this.objectType, this.fieldName);
        }

        if (this.isEnum()) {
            const enumValueObj = this.schemaService.getEnumValue(this.enumType, this.enumValue);

            return enumValueObj.description;
        }

        return '';
    }

    /**
     * Returns text for the range of valid values, applicable when objectType and fieldName are
     * passed in.
     */
    private getFieldRange(): string {
        if (this.hideRange) {
            return '';
        }

        if (this.isObjectField) {
            return this.schemaService.getFieldRangeAsText(this.objectType, this.fieldName);
        }

        return this.rangeText;
    }

    /**
     * Returns text for special values.
     */
    private getFieldSpecialValues(): string {
        if (this.isObjectField) {
            return this.schemaService.getFieldSpecialValuesAsText(this.objectType, this.fieldName);
        }

        return '';
    }

    /**
     * Returns true/false for immutable fields.
     */
    private isImmutableField(): boolean {
        if (this.isObjectField) {
            return this.schemaService.isImmutableField(this.objectType, this.fieldName);
        }

        return false;
    }

    /**
     * Decide whether the data type to fetch text from is an object field or not.
     */
    private checkObjectField(): boolean {
        return Boolean(this.objectType && this.fieldName);
    }

    /**
     * Decide whether the data type to fetch text from is an enum or not.
     */
    private isEnum(): boolean {
        return Boolean(this.enumType && this.enumValue);
    }
}
