/**
 * @module AccountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    RoleCollection,
    RoleService,
    TUserCollection,
    UserCollection,
    UserProfileCollection,
} from 'ajs/modules/accounts';

import {
    ROLE_COLLECTION_TOKEN,
    ROLE_SERVICE_TOKEN,
    USER_COLLECTION_TOKEN,
    USER_PROFILE_COLLECTION_TOKEN,
} from 'ajs/modules/accounts/accounts.tokens';

const $injector = '$injector';

export const userProfileCollectionProvider = {
    deps: [$injector],
    provide: UserProfileCollection,
    useFactory(injector: Injector): typeof UserProfileCollection {
        return injector.get(USER_PROFILE_COLLECTION_TOKEN);
    },
};

export const systemConfigServiceProvider = {
    deps: [$injector],
    provide: 'systemConfigService',
    useFactory(injector: Injector): any {
        return injector.get('systemConfigService');
    },
};

export const roleServiceProvider = {
    deps: [$injector],
    provide: RoleService,
    useFactory(injector: Injector): typeof RoleService {
        return injector.get(ROLE_SERVICE_TOKEN);
    },
};

export const roleCollectionProvider = {
    deps: [$injector],
    provide: RoleCollection,
    useFactory(injector: Injector): typeof RoleCollection {
        return injector.get(ROLE_COLLECTION_TOKEN);
    },
};

export const userCollectionProvider = {
    deps: [$injector],
    provide: UserCollection,
    useFactory(injector: Injector): TUserCollection {
        return injector.get(USER_COLLECTION_TOKEN);
    },
};
