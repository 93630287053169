/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { IDnsServiceDomain } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { DnsServiceDomain } from 'object-types';
import * as l10n from './internal-dns-service-domains-grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Grid component for internal profile dns_service_domain configuration.
 * *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'internal-dns-service-domains-grid',
    templateUrl: './internal-dns-service-domains-grid.component.html',
})
export class InternalDnsServiceDomainsGrid implements AfterViewInit {
    /**
     * Binding for ipam#internal_profile#dns_service_domain.
     */
    @Input()
    public dnsServiceDomains: RepeatedMessageItem<MessageItem<IDnsServiceDomain>>;

    /**
     * Fires on Add Dns Service Domain.
     */
    @Output()
    public onAddDnsServiceDomain = new EventEmitter<void>();

    /**
     * Fires on Delete Dns Service Domain.
     */
    @Output()
    public onDeleteDnsServiceDomain = new EventEmitter<MessageItem<IDnsServiceDomain>>();

    /**
     * TemplateRef for domain name input field.
     */
    @ViewChild('domainNameTemplateRef')
    public domainNameTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for override TTL input field.
     */
    @ViewChild('recordTtlTemplateRef')
    public recordTtlTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Dns service domain grid config.
     */
    public internalDnsServiceDomainsGrid: IAviDataGridConfig;

    /**
     * Prefix for domain name input id & name.
     */
    public readonly internalDnsServiceDomainNamePrefix =
    'internal_dns_service_domain_name_';

    /**
     * Prefix for record TTL input id & name.
     */
    public readonly internalDnsServiceDomainRecordTtlPrefix =
    'internal_dns_service_domain_record_ttl_';

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ObjectType of IpamDnsInternalProfile.
     */
    public readonly objectType = DnsServiceDomain;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService, l10nKeys } = this;

        this.internalDnsServiceDomainsGrid = {
            getRowId(index: number): number {
                return index;
            },
            fields: [{
                label: l10nService.getMessage(l10nKeys.columnTitleDomainName),
                id: 'domain_name',
                templateRef: this.domainNameTemplateRef,
            }, {
                label: l10nService.getMessage(l10nKeys.columnTitleRecordTtl),
                id: 'record_ttl',
                templateRef: this.recordTtlTemplateRef,
            }],
            multipleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                onClick: (domains: Array<MessageItem<IDnsServiceDomain>>) => {
                    domains.forEach((domain: MessageItem<IDnsServiceDomain>) => {
                        this.deleteDnsServiceDomain(domain);
                    });
                },
            }],
            singleactions: [{
                label: l10nService.getMessage(l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (domain: MessageItem<IDnsServiceDomain>) => {
                    this.deleteDnsServiceDomain(domain);
                },
            }],
        };
    }

    /**
     * Emits add dns service domain.
     */
    public addDnsServiceDomain(): void {
        this.onAddDnsServiceDomain.emit();
    }

    /**
     * Deletes add dns service domain.
     */
    private deleteDnsServiceDomain(domain: MessageItem<IDnsServiceDomain>): void {
        this.onDeleteDnsServiceDomain.emit(domain);
    }
}
