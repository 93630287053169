/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name httpHeadersTextToHash
 *
 * @param {string} str - Full headers text.
 * @returns {{string: string}}
 *
 * @author Alex Malitsky
 * @description
 *
 *     Parses the headers text into hash of key & values where keys are header names.
 *
 */
angular.module('aviApp').factory('httpHeadersTextToHash', [
'httpHeaderNameDuplicatePostfix',
function(postfix) {
    return str => {
        const hash = {}; //to rename duplicated header names
        const duplicates = {};
        const lines = str.split('\n');

        let multilineKey = '';

        lines.forEach(line => {
            //skipping first colon case of pseudo HTTP2 headers
            const colonPos = line.indexOf(':', 1);

            if (colonPos !== -1) {
                let key = line.slice(0, colonPos).trim();

                //already had this key
                if (key in duplicates) {
                    // Second encounter - rename original key
                    if (duplicates[key] === 0) {
                        hash[`${key}${postfix}0`] = hash[key];
                        delete hash[key];
                    }

                    duplicates[key]++;

                    key += `${postfix}${duplicates[key]}`;
                } else {
                    duplicates[key] = 0;
                }

                multilineKey = key;
                hash[key] = line.slice(colonPos + 1).trim();
            } else if (line && multilineKey) { //multiline values are concatenated
                hash[multilineKey] += line;
            }
        });

        return hash;
    };
}]);
