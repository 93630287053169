/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'port-match';
const prefix = `${moduleName}.${componentName}`;

export const isLabel = `${prefix}.isLabel`;
export const isNotLabel = `${prefix}.isNotLabel`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const portInputPlaceholder = `${prefix}.portInputPlaceholder`;

export const ENGLISH = {
    [isLabel]: 'Is',
    [isNotLabel]: 'Is not',
    [portInputLabel]: 'Ports',
    [portInputPlaceholder]: 'Port',
};
