/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import {
    IDnsServiceDomain,
    IIpamUsableNetwork,
    IpamDnsType,
} from 'generated-types';

import { IpamDnsInternalProfileConfigItem }
    from 'ajs/modules/ipam/factories/ipam-dns-internal-profile.config-item.factory';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { CloudCollection } from 'ajs/modules/cloud/factories/cloud.collection.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ipam-dns-internal-profile-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCloudCollection = typeof CloudCollection;

/**
 * @description IpamDnsInternalProfileConfigItem configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-internal-profile-config',
    templateUrl: './ipam-dns-internal-profile-config.component.html',
})
export class IpamDnsInternalProfileConfigComponent implements OnInit, OnDestroy {
    /**
     * IpamDnsInternalProfile ConfigItem instance.
     */
    @Input()
    public editable: IpamDnsInternalProfileConfigItem;

    /**
     * Type of IpamDnsProviderProfile (IPAMDNS_TYPE_INTERNAL / IPAMDNS_TYPE_INTERNAL_DNS).
     */
    @Input()
    public type: IpamDnsType;

    /**
     * ObjectType of IpamDnsInternalProfile.
     */
    public objectType: string;

    /**
     * Model value for Cloud collection-dropdown.
     * Needed to load networks.
     */
    public cloudRef: string;

    /**
     * CloudCollection instance.
     */
    public readonly cloudCollection: CloudCollection;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * IpamDnsProfile type hash.
     */
    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    constructor(
        l10nService: L10nService,
        @Inject('CloudCollection')
        CloudCollection: TCloudCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.cloudCollection = new CloudCollection({
            isStatic: true,
            params: {
                fields: 'name,tenant_ref',
            },
        });
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.cloudCollection.destroy();
    }

    /**
     * Adds a usableNetwork row.
     */
    public addUsableNetwork(): void {
        this.editable.addUsableNetwork();
    }

    /**
     * Removes usableNetwork from editable.
     */
    public removeUsableNetwork(usableNetwork: MessageItem<IIpamUsableNetwork>): void {
        this.editable.removeUsableNetwork(usableNetwork);
    }

    /**
     * Adds a dns service domain.
     */
    public addDnsServiceDomain(): void {
        this.editable.addDnsRecord();
    }

    /**
     * Removes a dns service domain.
     */
    public removeDnsServiceDomain(domain: MessageItem<IDnsServiceDomain>): void {
        this.editable.removeDnsRecord(domain);
    }
}
