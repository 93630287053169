/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AviFormsModule
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const UNIQUE_VALUE_INPUT_VALIDATOR_KEY = 'uniqueValueInputValidation';

/**
 * Returns validatorFunction for Unique Value Input Validation Directive.
 *
 * @author Sarthak Kapoor
 */
export function uniqueValueInputValidator(inputList: string[], currentIndex: number): ValidatorFn {
    /**
     * Returns ValidationErrors Object if input list contains duplicate strings
     * and index of value being edited is not equal to currentIndex.
     * Returns null otherwise.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        const index = inputList.indexOf(value);

        const inputSet = new Set(inputList);

        if (inputSet.has(value) && index !== currentIndex) {
            return {
                [UNIQUE_VALUE_INPUT_VALIDATOR_KEY]: value,
            };
        } else {
            return null;
        }
    };
}
