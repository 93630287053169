/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import template from './license-selector.component.partial.html';
import * as l10n from './license-selector.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @constructor
 * @memberOf module:avi/licensing
 * @mixes module:avi/licensing.licenseSelectorBindings
 * @see {@link module:avi/licensing.LicenseSelectorComponent}
 */
class LicenseSelectorController {
    /**
     * Holds actual license types to display to user.
     * @type {DropDownOption[]}
     */
    licenseTypeOptions = [];

    /**
     * Represents objectType from which values are taken.
     * @type {string}
     */
    objectType = '';

    constructor(
        CloudLicenseTypes,
        dropDownUtils,
        schemaService,
        Cloud,
        defaultValues,
        l10nService,
    ) {
        this.CloudLicenseTypes = CloudLicenseTypes;
        this.dropDownUtils = dropDownUtils;
        this.schemaService = schemaService;
        this.Cloud = Cloud;
        this.defaultValues = defaultValues;
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    $onInit() {
        this.objectType = this.objectType || 'Cloud';
        this.setLicenseTypeOptions_();
        this.setDefaults_();
    }

    /**
     * Sets licenseTypeOptions based on allowed licenses by cloudType.
     * @protected
     */
    setLicenseTypeOptions_() {
        /**
         * List of all possible existing license types for Clouds which exist in protobuf.
         * Is used to get value, label, and description of each license type for dropdown.
         * @type {EnumValue[]}
         */
        const allLicenseTypes = this.schemaService.getEnumValues('LicenseType');

        const { cloudType } = this;
        const cloudSpecificLicenses = this.CloudLicenseTypes[cloudType];

        /**
         * List of Cloud license types belonging to current cloud being edited/created.
         * @type {EnumValue[]}
         */
        const allowedLicenses = _.filter(allLicenseTypes, licenseType => {
            return _.find(cloudSpecificLicenses, licenseVal => {
                return licenseType.value === licenseVal;
            });
        });

        this.licenseTypeOptions = allowedLicenses.map(license => {
            return this.dropDownUtils.createOption(
                license.value,
                license.label,
                license.description,
            );
        });
    }

    /**
     * Sets defaults to first in list.
     * @protected
     */
    setDefaults_() {
        if (!this.licenseType) {
            this.licenseType = this.licenseTypeOptions[0].value;
        }
    }
}

LicenseSelectorController.$inject = [
    'CloudLicenseTypes',
    'dropDownUtils',
    'schemaService',
    'Cloud',
    'defaultValues',
    'l10nService',
];

/**
 * @mixin licenseSelectorBindings
 * @memberOf module:avi/licensing
 * @see {@link module:avi/licensing.LicenseSelectorComponent}
 * @property {string} licenseType - links to SEG or Cloud enums' license_type
 * @property {string} cloudType - type of cloud
 * @property {?string} objectType - informs if being used for SEG.
 *     Only ServiceEngineGroup is supported and default value is Cloud.
 */
const licenseSelectorBindings = {
    licenseType: '=',
    cloudType: '<',
    objectType: '@?',
};

/**
 * @name  LicenseSelectorComponent
 * @ngdoc component
 * @memberOf module:avi/licensing
 * @property {module:avi/licensing.LicenseSelectorController} controller
 * @property {module:avi/licensing.licenseSelectorBindings} bindings
 * @description Component for selecting license type, for Cloud and SEG level.
 * Note: for 20.1.1, JIRA 86664, license_tier was removed. See git history to add back in quickly.
 * @author Akul Aggarwal
 */
angular.module('avi/licensing').component('licenseSelector', {
    bindings: licenseSelectorBindings,
    controller: LicenseSelectorController,
    template,
});
