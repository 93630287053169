/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import {
    ActionGroupConfigModalComponent,
} from 'ng/modules/alert/components/action-group-config/action-group-config-modal.component';

/**
 * @description
 *
 *   ActionGroupConfig collection
 *
 * @author Guru Prasad H K
 */
export class ActionGroupConfigCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'actiongroupconfig',
            permissionName: AviPermissionResource.PERMISSION_ACTIONGROUPCONFIG,
            windowElement: ActionGroupConfigModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('ActionGroupConfigItem');
    }
}

ActionGroupConfigCollection.ajsDependencies = [
    'ActionGroupConfigItem',
];
