/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name systemConfigService
 * @author Alex Malitsky
 * @description
 *
 *     Most often we need only one Item of systemConfig and this is it.
 */
angular.module('aviApp').service('systemConfigService', [
'$rootScope', 'SystemConfig',
function($rootScope, SystemConfig) {
    const systemConfig = new SystemConfig();

    //not every user has access to systemconfig, remove it to be on the safe side
    $rootScope.$on('setContext', systemConfig.emptyData.bind(systemConfig));

    return systemConfig;
}]);
