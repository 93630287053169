/** @module BotModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    BotDetectionPolicyItem,
} from
    'ajs/modules/bot-detection-policy/factories/bot-detection-policy.item.factory';

import {
    BotIpReputationTypeMappingCollection,
    TBotIpReputationTypeMappingCollection,
} from
    'ajs/modules/bot-detection-policy/factories/bot-ip-reputation-type-mapping.collection.factory';

import {
    GeoDbCollection,
    TGeoDbCollection,
} from 'ajs/modules/geo-db/factories/geo-db.collection.factory';

import {
    BotMappingCollection,
    TBotMappingCollection,
} from 'ajs/modules/bot-detection-policy/factories/bot-mapping.collection.factory';

import {
    IPReputationDBCollection,
    TIpReputationDbCollection,
} from 'ajs/modules/ip-reputation-db/factories/ip-reputation-db.collection.factory';

import './bot-management-policy-modal.component.less';

import {
    BotAllowListConfigItem,
    BotAllowRuleConfigItem,
    IBotAllowListConfig,
} from 'ajs/modules/bot-detection-policy';

import { ClrFormLayout } from '@clr/angular';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

import { IAviDataGridConfig }
    from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import * as l10n from './bot-management-policy-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

/**
 * Bot Management Policy modal.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-management-policy-modal',
    templateUrl: './bot-management-policy-modal.component.html',
})
export class BotManagementPolicyModalComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    public editable: BotDetectionPolicyItem;

    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Keys from global source bundles for template usage.
     */
    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * Is 'BotDetectionPolicy'.
     */
    public objectType: string;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Following collections needed for various collection-dropdown options.
     */
    public geoDbCollection: GeoDbCollection;
    public ipReputationDbCollection: IPReputationDBCollection;
    public botIpReputationTypeMappingCollection: BotIpReputationTypeMappingCollection;
    public botMappingCollection: BotMappingCollection;
    public stringGroupCollection: any;

    /**
     * Avi data grid config for BotAllowList.
     */
    public allowListGridConfig: IAviDataGridConfig;

    /**
     * Avi data grid rows for BotAllowList.
     */
    public allowListGridRows: BotAllowRuleConfigItem[];

    constructor(
        private readonly l10nService: L10nService,
        @Inject(GeoDbCollection)
        private readonly GeoDbCollection: TGeoDbCollection,
        @Inject(IPReputationDBCollection)
        private readonly IpReputationDbCollection: TIpReputationDbCollection,
        @Inject(BotIpReputationTypeMappingCollection)
        private readonly BotIpReputationTypeMappingCollection:
        TBotIpReputationTypeMappingCollection,
        @Inject(BotMappingCollection)
        private readonly BotMappingCollection: TBotMappingCollection,
        @Inject('StringGroupCollection')
        private readonly StringGroupCollection: any,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.instantiateCollections();
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
        this.allowListGridRows = this.allowListConfigRules.config;
    }

    /**
     * Needed because expandedContentTemplateRef avi-data-grid can only be set after init.
     * @override
     */
    public ngAfterViewInit(): void {
        this.setAllowListGridProps();
    }

    /** @override */
    public ngOnDestroy(): void {
        [
            this.ipReputationDbCollection,
            this.geoDbCollection,
            this.botIpReputationTypeMappingCollection,
            this.botMappingCollection,
            this.stringGroupCollection,
        ].forEach((collection: Collection) => collection.destroy());
    }

    /**
     * Trigger for user choosing to add BotAllowRule.
     */
    public addAllowListRule(): void {
        this.allowList.addRule();
    }

    /**
     * Deletes ipReputationDetectorConfig props if disabled, else repopulates defaults.
     */
    public onIpReputationDetectorConfigToggle(): void {
        const { ipReputationDetectorConfig } = this.editable;

        if (!ipReputationDetectorConfig.enabled) {
            delete ipReputationDetectorConfig.ip_reputation_db_ref;
            delete ipReputationDetectorConfig.system_ip_reputation_mapping_ref;
        } else {
            this.editable.resetIpReputationDetectorConfigToDefaultValues();
        }
    }

    /**
     * Deletes ipLocationDetectorConfig props if disabled, else repopulates defaults.
     */
    public onIpLocationDetectorConfigToggle(): void {
        const { ipLocationDetectorConfig } = this.editable;

        if (!ipLocationDetectorConfig.enabled) {
            delete ipLocationDetectorConfig.ip_location_db_ref;
            delete ipLocationDetectorConfig.system_cloud_providers_ref;
            delete ipLocationDetectorConfig.system_search_engines_ref;
        } else {
            this.editable.resetIpLocationDetectorConfigToDefaultValues();
        }
    }

    /**
     * Convenience method to get BotAllowList.
     */
    public get allowList(): BotAllowListConfigItem {
        return this.editable.config.allow_list;
    }

    /**
     * Convenience method to get rules from BotAllowList.
     */
    public get allowListConfigRules(): IBotAllowListConfig['rules'] {
        return this.allowList.config.rules;
    }

    /**
     * Checks if modal can be saved.
     */
    public hasMinimumRequiredFields(): boolean {
        const {
            user_bot_mapping_ref: userBotMappingRef,
            system_bot_mapping_ref: systemBotMappingRef,
        } = this.editable.config;

        return Boolean(userBotMappingRef || systemBotMappingRef);
    }

    /**
     * Instantiates each collection needed for dropdowns.
     */
    private instantiateCollections(): void {
        this.ipReputationDbCollection = new this.IpReputationDbCollection();
        this.geoDbCollection = new this.GeoDbCollection();
        this.botIpReputationTypeMappingCollection = new this.BotIpReputationTypeMappingCollection();
        this.botMappingCollection = new this.BotMappingCollection();
        this.stringGroupCollection = new this.StringGroupCollection();
    }

    /**
     * Deletes bot rule.
     */
    private removeRule(rule: BotAllowRuleConfigItem): void {
        this.allowListConfigRules.removeByMessageItem(rule);
    }

    /**
     * Sets config and rows for BotAllowList's grid.
     */
    private setAllowListGridProps(): void {
        this.allowListGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    id: 'name',
                    transform: (rule: BotAllowRuleConfigItem) => {
                        return rule.config.name;
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.numberOfMatchesLabel),
                    id: 'match',
                    transform: (rule: BotAllowRuleConfigItem) => {
                        return String(rule.matchCount);
                    },
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.actionLabel),
                    id: 'action',
                    transform: (rule: BotAllowRuleConfigItem) => {
                        return rule.botActionText;
                    },
                },
            ],
            layout: {
                hideCheckboxes: true,
            },
            multipleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    onClick: (rules: BotAllowRuleConfigItem[]) => {
                        rules.forEach(rule => {
                            this.removeRule(rule);
                        });
                    },
                },
            ],
            singleactions: [
                {
                    label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (rule: BotAllowRuleConfigItem) => {
                        this.allowList.editRule(rule);
                    },
                },
                {
                    label: this.l10nService.getMessage(globalL10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (rule: BotAllowRuleConfigItem) => {
                        this.removeRule(rule);
                    },
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
