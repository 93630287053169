/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { IIpamDnsOpenstackProfile } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './ipam-dns-openstack-profile-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description IpamDns OpenstackProfile configuration component.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ipam-dns-openstack-profile-config',
    templateUrl: './ipam-dns-openstack-profile-config.component.html',
})
export class IpamDnsOpenstackProfileConfigComponent implements OnInit {
    /**
     * IpamDnsOpenstackProfile ConfigItem instance.
     */
    @Input()
    public editable: MessageItem<IIpamDnsOpenstackProfile>;

    /**
     * ObjectType of IpamDnsOpenstackProfile.
     */
    public objectType: string;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
