/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import {
    tap,
    withLatestFrom,
} from 'rxjs/operators';

import {
    Subject,
    Subscription,
} from 'rxjs';

import { DevLoggerService } from 'ng/modules/core';
import { VsLogsEffectsService } from '../../services/vs-logs-effects.service';

import {
    IVsLogsCombinedRequestUpdatableParams,
    IVsLogsDefaultSignpostData,
    IVsLogsEndToEndTimingSignpostData,
    IVsLogsGroupbySignificanceResultsData,
    IVsLogsGroupsSignpostData,
    IVsLogsSelectedSignpostConfig,
    IVsLogsSignpostUpdatableParams,
    IVsLogsWafLatencySignpostData,
    IVsLogsWafRuleGroupsSignpostData,
    TVsLogsPageStateParams,
    TVsLogsSignpostApiResponseData,
    TVsLogsSignpostStoreData,
} from '../../vs-logs.types';

import { VsLogsStore } from '../../services/vs-logs.store';
import { transformSignpostData } from '../../utils/vs-logs-signpost.utils';

const VS_LOG_SIGNPOST_REQUEST_ID = 'VS_LOG_SIGNPOST_REQUEST';

/**
 * @param vsLogsSignpostData - Response from backend transfomed to be utilised on UI for displaying.
 * @param selectedSignpostConfig - Selected signpost name and its respective config key for mapping.
 * @param isLoading - Flag to indicate whether a request is in progress.
 */
interface IStateTypes {
    vsLogsSignpostData: TVsLogsSignpostStoreData;
    selectedSignpostConfig: IVsLogsSelectedSignpostConfig;
    isLoading: boolean;
    hasError: boolean;
}

const initialState: IStateTypes = {
    vsLogsSignpostData: null,
    selectedSignpostConfig: null,
    isLoading: false,
    hasError: false,
};

/**
 * @description State and Effect management for VsLogsSignpostComponent.
 * @author Suraj Kumar
 */
@Injectable()
export class VsLogsSignpostStore extends ComponentStore<IStateTypes> {
    // ********************************** Selectors **********************************

    public readonly vsLogsDefaultSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsDefaultSignpostData[],
    );

    public readonly vsLogsWafLatencySignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsWafLatencySignpostData,
    );

    public readonly vsLogsWafGroupsSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsWafRuleGroupsSignpostData[],
    );

    public readonly vsLogsGroupsSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsGroupsSignpostData[],
    );

    public readonly vsLogsSignificanceSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsGroupbySignificanceResultsData[],
    );

    public readonly vsLogsEndToEndTimingSignpostData$ = this.select(
        state => state.vsLogsSignpostData as IVsLogsEndToEndTimingSignpostData,
    );

    public readonly hasNoData$ = this.select(
        state => !state.vsLogsSignpostData && !state.isLoading,
    );

    public readonly isLoading$ = this.select(state => state.isLoading);

    public readonly hasError$ = this.select(state => state.hasError);

    public readonly vsLogSelectedSignpostConfigKey$ = this.select(
        state => state.selectedSignpostConfig?.configKey,
    );

    public readonly vsLogSelectedSignpost$ = this.select(state => state.selectedSignpostConfig);

    // ********************************** Effects **********************************

    /**
     * Request selected signpost data and update the state accordingly.
     */
    public readonly getVsLogSignpostData: (
        signpostParams: IVsLogsSignpostUpdatableParams,
    ) => Subscription;

    /**
     * Request selected signpost data which depends on response from multiple requests and update
     * state accordingly.
     */
    public readonly getVsLogSignpostCombinedData: (
        signpostParams: IVsLogsCombinedRequestUpdatableParams[],
    ) => Subscription;

    /**
     * Subject to close the signpost.
     */
    public readonly closeSignpost$ = new Subject<void>();

    // ********************************** Updaters **********************************

    public readonly setVsLogSignpostGroupby = this.updater(
        (state, selectedSignpostConfig: IVsLogsSelectedSignpostConfig) => ({
            ...state,
            selectedSignpostConfig,
        }),
    );

    private readonly setDataIsLoading = this.updater((state, isLoading: boolean) => ({
        ...state,
        isLoading,
    }));

    private readonly setHasError = this.updater((state, hasError: boolean) => ({
        ...state,
        hasError,
    }));

    // ********************************** Constructor **********************************

    constructor(
        private readonly vsLogsStore: VsLogsStore,
        vsLogsEffectsService: VsLogsEffectsService,
        private readonly devLoggerService: DevLoggerService,
    ) {
        super(initialState);

        // Initialize request effect
        const vsLogSignpostDataRequestEffect = this.effect<TVsLogsPageStateParams>(
            vsLogsEffectsService.createVsLogsRequestEffect(
                VS_LOG_SIGNPOST_REQUEST_ID,
                this.startLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiComplete,
            ),
        );

        // Initialize request effect
        const vsLogSignpostCombinedRequestEffect = this.effect(
            vsLogsEffectsService.createVsLogsCombinedRequestEffect(
                this.startLoading,
                this.handleApiData,
                this.handleApiError,
                this.handleApiComplete,
            ),
        );

        this.getVsLogSignpostData = this.effect<IVsLogsSignpostUpdatableParams>(
            signpostParams$ => signpostParams$.pipe(
                withLatestFrom(this.vsLogsStore.vsLogsPageParams$),
                tap(([signpostParams, params]:
                [IVsLogsSignpostUpdatableParams, TVsLogsPageStateParams]) => {
                    const fullParams: TVsLogsPageStateParams = {
                        ...params,
                        ...signpostParams,
                    };

                    vsLogSignpostDataRequestEffect(fullParams);
                }),
            ),
        );

        this.getVsLogSignpostCombinedData = this.effect<IVsLogsCombinedRequestUpdatableParams[]>(
            signpostParams$ => signpostParams$.pipe(
                withLatestFrom(this.vsLogsStore.vsLogsPageParams$),
                tap(([signpostParams, params]:
                [IVsLogsCombinedRequestUpdatableParams[], TVsLogsPageStateParams]) => {
                    const fullRequestParams = signpostParams.map(
                        (signpostParam: IVsLogsCombinedRequestUpdatableParams) => {
                            return {
                                requestId: signpostParam.requestId,
                                requestParams: {
                                    ...params,
                                    ...signpostParam.requestParams,
                                },
                            };
                        },
                    );

                    vsLogSignpostCombinedRequestEffect(fullRequestParams);
                }),
            ),
        );
    }

    // ********************************** Methods **********************************

    /**
     * Set signpost state to default state.
     */
    public setDefaultState(): void {
        this.setState(initialState);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeSignpost$.complete();
    }

    /**
     * Close the signpost.
     */
    public closeSignpost(): void {
        this.closeSignpost$.next();
    }

    /**
     * Set isLoading to true upon initiating an API request.
     */
    private startLoading = (): void => {
        this.setDataIsLoading(true);
        this.setHasError(false);
    };

    /**
     * Update state with results once data is returned from API.
     */
    private handleApiData = (data: TVsLogsSignpostApiResponseData): void => {
        this.setState(state => {
            const vsLogsSignpostData =
                transformSignpostData(data, state.selectedSignpostConfig?.configKey);

            return {
                ...state,
                vsLogsSignpostData,
            };
        });
    };

    // TODO 146748 figure out 'type' of 'err', and action to take upon err
    /**
     * Handle API errors.
     */
    private handleApiError = (err: any): void => {
        this.devLoggerService.error(err);
        this.setDataIsLoading(false);
        this.setHasError(true);
    };

    /**
     * Set isLoading to false upon API request completion.
     */
    private handleApiComplete = (): void => {
        this.setDataIsLoading(false);
    };
}
