/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './icap-log-list-expander.l10n';
import template from './icap-log-list-expander.component.html';
import './icap-log-list-expander.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @author akulaggarwal
 * @desc Component when ICAP logs present in VS for L7.
 */
class IcapLogListExpanderComponent {
    public readonly l10nKeys = l10nKeys;
    /** Parent function passed in to handle filter by new prop. */
    public updateSearch: (args: { [str: string]: string }) => void;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** Calls parent function to handle user click to filter by clicked prop. */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }
}

IcapLogListExpanderComponent.$inject = [
    'l10nService',
];

export const icapLogListExpanderComponentOptions = {
    bindings: {
        icapLog: '<',
        updateSearch: '&',
    },
    controller: IcapLogListExpanderComponent,
    template,
};
