/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module IpamModule
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';

import { StringService } from 'ajs/modules/core/services/string-service';

import {
    ICustomIpamSubnet,
    IpamDnsType,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { CustomIpamDnsCollection }
    from 'ajs/modules/ipam/factories/custom-ipam-dns-profile.collection.factory';

import { IpamDnsCustomProfileConfigItem }
    from 'ajs/modules/ipam/factories/ipam-dns-custom-profile.config-item.factory';

import './ipam-dns-custom-profile-config.component.less';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { ipamDnsProfileTypeHash } from 'ajs/modules/ipam/factories/ipam-dns-profile.types';
import { IAviDropdownButtonAction } from 'ng/shared/components/avi-dropdown-button';
import { CustomIpamSubnetConfigItem }
    from 'ajs/modules/ipam/factories/custom-ipam-subnet.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './ipam-dns-custom-profile-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

type TCustomIpamDnsCollection = typeof CustomIpamDnsCollection;

/**
 * @description IpamDnsCustomProfile configuration component.
 *
 * @author Aravindh Nagarajan, Hitesh Mandav
 */
@Component({
    selector: 'ipam-dns-custom-profile-config',
    templateUrl: './ipam-dns-custom-profile-config.component.html',
})
export class IpamDnsCustomProfileConfigComponent implements OnInit {
    @Input()
    public editable: IpamDnsCustomProfileConfigItem;

    @Input()
    public type: IpamDnsType;

    public objectType: string;

    public connected = false;

    public disableSave = false;

    public readonly isEditing: boolean;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly customIpamDnsCollection: CustomIpamDnsCollection;

    public readonly ipamDnsTypes = ipamDnsProfileTypeHash;

    public ipamDropdownActions: IAviDropdownButtonAction[] = [];

    public testLoginError: string;

    public availableSubnets: ICustomIpamSubnet[];

    constructor(
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        @Inject(CustomIpamDnsCollection)
        CustomIpamDnsCollection: TCustomIpamDnsCollection,
        @Inject(ITEM_ID_TOKEN)
        private readonly itemId: string,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.isEditing = Boolean(itemId);

        this.customIpamDnsCollection = new CustomIpamDnsCollection();
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        if (this.type === this.ipamDnsTypes.IPAMDNS_TYPE_CUSTOM) {
            this.onCustomIpamProfileChange(this.editable.config.custom_ipam_dns_profile_ref,
                false, this.isEditing);
        }

        this.setAvailableSubnets();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.customIpamDnsCollection.destroy();
    }

    /**
     * Add a usableSubnet row.
     */
    public addUsableSubnet(): void {
        this.editable.addUsableSubnet(this.availableSubnets);
    }

    /**
     * Remove usableSubnet from editable.
     */
    public removeUsableSubnet(usableSubnet: CustomIpamSubnetConfigItem): void {
        this.editable.removeUsableSubnet(usableSubnet);
    }

    /**
     * Edit usableSubnet in editable.
     */
    public editUsableAllocSubnet(usableSubnet: CustomIpamSubnetConfigItem): void {
        this.editable.editUsableSubnet(usableSubnet, this.availableSubnets);
    }

    /**
     * Set dropdown actions and connection status on page load
     * and on CustomIpamProfile change
     */
    public onCustomIpamProfileChange(
        customIpamProfileRef?: string,
        removeSubnets = true,
        skipDisconnect = false,
    ): void {
        if (!skipDisconnect) {
            this.disconnectLogin(removeSubnets);
        }

        this.setIpamDropdownActions(customIpamProfileRef);
    }

    /**
     * Set the login status and error message
     */
    public async testLogin(): Promise<void> {
        const { loginError, connectionStatus } =
        await this.editable.testLoginCustomIpamProfile();

        this.testLoginError = loginError;
        this.connected = connectionStatus;
        this.disableSave = !this.connected;
    }

    /**
     * Set dropdown actions on selecting custom IPAM profile and disconnects connection
     */
    private setIpamDropdownActions(itemRef?: string): void {
        const selectedItemId = itemRef ? this.stringService.slug(itemRef) : '';

        const selectedItem = this.customIpamDnsCollection.getItemById(selectedItemId);

        this.ipamDropdownActions = [
            {
                label: this.l10nService.getMessage(globalL10nKeys.createLabel),
                onClick: (): void => {
                    this.disconnectLogin(false);
                    this.customIpamDnsCollection.create();
                },
                disabled: (): boolean => !this.customIpamDnsCollection.isCreatable(),
            },
            {
                label: this.l10nService.getMessage(globalL10nKeys.editLabel),
                onClick: (): void => {
                    this.disconnectLogin();
                    selectedItem.edit();
                },
                disabled: (): boolean => !selectedItem || !selectedItem.isEditable(),
                hidden: (): boolean => !selectedItem,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.testLoginLabel),
                onClick: (): Promise<void> => this.testLogin(),
                disabled: (): boolean => !selectedItemId,
            },
        ];
    }

    /**
     * Set connection status to false and clear SubnetList
     */
    private disconnectLogin(removeSubnets = true): void {
        this.connected = false;
        this.disableSave = !this.connected;

        if (removeSubnets) {
            this.editable.clearUsableSubnetList();
        }
    }

    /**
     * Set available usable subnets.
     */
    private async setAvailableSubnets(): Promise<void> {
        try {
            this.availableSubnets = await
            this.editable.getAvailableSubnetsWithId(this.itemId);
        } catch (e: any) {
            Promise.reject(e);
        }
    }
}
