/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name aviFormItem
 * @restrict A
 * @param {*} ngLabel
 * @deprecated
 * This directive has been Deprecated.We have created new avi form item component.
 * @see {@link module:avi/component-kit.aviFormItem}
 */
angular.module('aviApp').directive('aviFormItem', ['$compile', '$timeout', '$parse',
function($compile, $timeout, $parse) {
    function link(scope, element, attrs, ctrl) {
        // wrap element in a new parent <div> container so we can apply the right styles.
        const
            className = attrs.className || '',
            wrapper = `<div class="control-group ${className}"><div class="controls"></div></div>`;

        element.wrap(wrapper);

        const scopik = scope.$new();

        scopik.$attrs = attrs;

        //otherwise plain text attribute value is passed which evaluates to true
        if (!_.isUndefined(attrs['ngRequired']) && attrs['ngRequired']) {
            scopik.$attrs['ngRequired'] = $parse(attrs['ngRequired']);
        }

        const labelTemplate = `<label for="${element[0].id}" ` +
            `avi-form-label="${attrs.aviFormItem}">` +
            `<span ng-class="{'label-required': $attrs.required || $attrs.ngRequired()}">${
                attrs.ngLabel || ''}</span></label>`;

        const compiledLabelTemplate = $compile(labelTemplate)(scopik);

        // Create a <label> and insert before the <input> field.
        element.parent().before(compiledLabelTemplate);

        if (attrs.ngLabel) {
            attrs.$set('name', attrs.name || attrs.ngLabel);
        }

        const render = function() {
            element.parent().parent()
                .find('.required')
                .removeClass('valid')
                .addClass(ctrl.$valid ? 'valid' : '');
        };

        /**
         * Watchers and binds.
         */
        element.on('focus blur keyup change', function() {
            $timeout(render);
        });

        /**
         * On initialization.
         */
        $timeout(render);
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        link,
    };
}]);
