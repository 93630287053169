/**
 * @module PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { IPolicyRuleConfig, PolicyRuleExtendableConfigItem } from 'ajs/modules/policies';
import { IExpanderAction } from 'ng/shared/components/expander-with-actions';
import './policy-rule.component.less';

@Component({
    selector: 'policy-rule',
    templateUrl: './policy-rule.component.html',
})
export class PolicyRuleComponent {
    /**
     * ConfigItem instance of policy rule.
     */
    @Input()
    public rule: PolicyRuleExtendableConfigItem<IPolicyRuleConfig>;

    /**
     * Fires on delete.
     */
    @Output()
    public onDelete: EventEmitter<void> = new EventEmitter();

    /**
     * Fires on edit.
     */
    @Output()
    public onEdit:
    EventEmitter<PolicyRuleExtendableConfigItem<IPolicyRuleConfig>> = new EventEmitter();

    /**
     * List of actions(delete/edit) for rule expander.
     */
    public expanderActions: IExpanderAction[] = [];

    /** @override */
    public ngOnInit(): void {
        this.expanderActions = [
            {
                confirmationButtonProps: {
                    actions: [
                        {
                            label: 'Delete',
                            onClick: () => this.handleDelete(),
                        },
                    ],
                    confirmationButtonClassName: 'avi-btn avi-btn-danger',
                },
                confirmationMessage: 'Are you sure you want to delete this rule?',
                iconClassName: 'sl-icon-trash',
                title: 'Delete',
            }, {
                iconClassName: 'sl-icon-note',
                onClick: () => this.handleEdit(),
                title: 'Edit',
            },
        ];
    }

    /**
     * Returns the state of the enable switch, 1 for enabled, 0 for disabled.
     */
    public getEnableState(): number {
        return this.rule.enabled ? 1 : 0;
    }

    /**
     * Click handler for the enable switch. Flips the current value.
     */
    public handeEnableClick(): void {
        this.rule.enabled = !this.rule.enabled;
    }

    /**
     * Click handler for editing a policy rule.
     */
    public handleEdit(): void {
        this.onEdit.emit(this.rule);
    }

    /**
     * Click handler for deleting a policy rule.
     */
    public handleDelete(): void {
        this.onDelete.emit();
    }
}
