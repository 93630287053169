/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isNaN, isUndefined } from 'underscore';
import {
    IWafPolicyAllowlist,
    IWafPolicyAllowlistRule,
} from 'generated-types';
import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { WafPolicyAllowlistRuleConfigItem } from './waf-policy-allowlist-rule.config-item.factory';

type TWafPolicyAllowlist = Omit<IWafPolicyAllowlist, 'rules'>;

interface IWafPolicyAllowlistConfig extends TWafPolicyAllowlist {
    rules?: RepeatedMessageItem<WafPolicyAllowlistRuleConfigItem>;
}

export const WAF_POLICY_ALLOWLIST_CONFIG_ITEM_TOKEN = 'WafPolicyAllowlistConfigItem';

export class WafPolicyAllowlistConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IWafPolicyAllowlistConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafPolicyAllowlist',
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public canFlatten(): boolean {
        return !this.config.rules.isEmpty();
    }

    /**
     * Returns the number of rules in the config.
     */
    public getRulesCount(): number {
        return this.config.rules.count;
    }

    /**
     * Returns a new WafPolicyAllowlistRule ConfigItem instance.
     */
    public createNewRule(config: IWafPolicyAllowlistRule = null): WafPolicyAllowlistRuleConfigItem {
        return this.createChildByField_('rules', config, true) as WafPolicyAllowlistRuleConfigItem;
    }

    /**
     * Adds a rule to the list of rules. If the rule has an index, it means that an existing
     * rule was modified so the existing rule should be replaced. If not, add the new rule to
     * the end of the list.
     * @param rule - Rule to add.
     */
    public addRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        const { rules } = this.config;
        const indexField = rule.getIndex();

        // New rule doesn't have an index.
        if (isUndefined(indexField)) {
            const maxIndex = rules.getMaxIndex();
            const newIndex = isNaN(+maxIndex) ? 0 : maxIndex + 1;

            rule.setIndex(newIndex);
            rules.add(rule);
        } else {
            const arrayIndex = rules.getArrayIndexWithIndexField(indexField);

            rules.config[arrayIndex] = rule;
        }
    }

    /**
     * Edits an allowlist rule.
     */
    public editRule(rule: WafPolicyAllowlistRuleConfigItem): void {
        this.editChildMessageItem({
            field: 'rules',
            messageItem: rule,
            modalBindings: {
                editMode: true,
            },
        });
    }

    /**
     * Opens Child odal to add an new allowlist rule.
     */
    public addNewRule(): void {
        this.addChildMessageItem({
            field: 'rules',
        });
    }
}
