/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { Scheduler } from 'ajs/modules/system/factories/scheduler.item.factory';
import { TItemConfig } from 'ajs/modules/data-model/factories/object-type-item.factory';
import * as l10n from './local-backup-card.l10n';
import './local-backup-card.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Card component containing an overview of the local backup settings.
 * @author Alex Klyuev
 */

@Component({
    selector: 'local-backup-card',
    templateUrl: './local-backup-card.component.html',
})
export class LocalBackupCardComponent {
    /**
     * Scheduler Item instance. Used to get backup configuration properties.
     */
    @Input()
    public scheduler: Scheduler;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for backup scheduler config object.
     */
    private get schedulerConfig(): TItemConfig {
        return this.scheduler.config;
    }

    /**
     * Getter for backup enabled property.
     */
    private get enabled(): boolean {
        return this.schedulerConfig.enabled;
    }

    /**
     * If backups are enabled, shows whether local backups are enabled.
     */
    public get localBackupEnabled(): boolean {
        return this.enabled && this.schedulerConfig.backup_config_ref_data.save_local;
    }
}
