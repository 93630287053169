/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ipam-dns-profiles-expander';
const prefix = `${moduleName}.${componentName}`;

export const usableNetworkHeader = `${prefix}.usableNetworkHeader`;
export const emptyDataLabel = `${prefix}.emptyDataLabel`;
export const usableSubnetHeader = `${prefix}.usableSubnetHeader`;
export const usableDomainHeader = `${prefix}.usableDomainHeader`;
export const fqdn = `${prefix}.fqdn`;

export const ENGLISH = {
    [usableNetworkHeader]: 'Usable Network',
    [emptyDataLabel]: 'None',
    [usableSubnetHeader]: 'Usable Subnet',
    [usableDomainHeader]: 'Usable Domain',
    [fqdn]: 'FQDN',
};
