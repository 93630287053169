/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auth-settings';
const componentName = 'user-role-mapping';
const prefix = `${moduleName}.${componentName}`;

export const userRoleLabel = `${prefix}.userRoleLabel`;
export const rolesLabel = `${prefix}.rolesLabel`;
export const rolesPlaceholderLabel = `${prefix}.rolesPlaceholderLabel`;
export const roleAttributeNameLabel = `${prefix}.roleAttributeNameLabel`;
export const roleAttributeNamePlaceholderLabel = `${prefix}.roleAttributeNamePlaceholderLabel`;
export const typeDropdownPlaceholder = `${prefix}.typeDropdownPlaceholder`;

export const ENGLISH = {
    [userRoleLabel]: 'User Role',
    [rolesLabel]: 'Roles',
    [rolesPlaceholderLabel]: 'Select Roles',
    [roleAttributeNameLabel]: 'Role Attribute Name',
    [roleAttributeNamePlaceholderLabel]: 'Enter Role Attribute Name',
    [typeDropdownPlaceholder]: 'Select Type',
};
