/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name PoolServerVMMetric
 * @description
 *
 *     When fetching for Server we want to use server VM id instead of regular server id.
 *     When fetching for Pool we want to pass a list of server VM ids and aggregate all the
 *     series into one. In both cases we get one Series per real series id.
 *
 */
angular.module('aviApp').service('PoolServerVMMetric', [
'$injector', 'CollMetric',
function($injector, CollMetric) {
    let Server;

    return class PoolServerVMMetric extends CollMetric {
        constructor(args) {
            super(args);

            // aggregated through all servers for Pool, not aggregated for a single Server
            // regular (non aggrgated) series are used
            // that's why we set this flag after running superconstructor
            if (args.item.getItemType() === 'pool') {
                this.aggregation = 'METRICS_DIMENSION_AGG_AVG';
            }

            this.stackedSeries_ = false;

            if (!Server) {
                Server = $injector.get('Server');
            }
        }

        /**
         * Series returned are
         * @override
         */
        getNewSeriesConfig_(seriesConfig) {
            const config = super.getNewSeriesConfig_(seriesConfig);

            //since Series would have used them for filtering and that won't work (vm id instead)
            delete config.itemId;
            delete config.itemRef;

            return config;
        }

        /** @override */
        requestConfig() {
            const
                { item } = this,
                config = super.requestConfig(),
                itemType = item.getItemType(),
                vmIdsList = [];

            let vmRef;

            switch (itemType) {
                case 'pool': {
                    const { servers } = item.getConfig();

                    config['aggregate_entity'] = true;

                    if (servers) {
                        servers.flattenConfig().forEach(serverConfig => {
                            vmRef = Server.getServerVMId(serverConfig);

                            if (vmRef) {
                                vmIdsList.push(vmRef);
                            }
                        });
                    }

                    break;
                }

                case 'server':
                    vmRef = Server.getServerVMId(item.getConfig());

                    if (vmRef) {
                        vmIdsList.push(vmRef);
                    }

                    break;
            }

            if (!vmIdsList.length) {
                console.warn(
                    'Can\'t make a request for PoolServerVMMetric since there are no server vm ids',
                );

                return null;
            }

            config['entity_uuid'] = _.unique(vmIdsList).join();

            delete config['pool_uuid'];
            delete config['obj_id'];

            return config;
        }
    };
}]);
