/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-application';
const prefix = `${moduleName}.${componentName}`;

export const http2StreamIDLabel = `${prefix}.http2StreamIDLabel`;
export const serverPushInitiatedLabel = `${prefix}.serverPushInitiatedLabel`;
export const serverPushedRequestLabel = `${prefix}.serverPushedRequestLabel`;
export const cacheHitLabel = `${prefix}.cacheHitLabel`;
export const compressionLabel = `${prefix}.compressionLabel`;
export const compressedPercentageLabel = `${prefix}.compressedPercentageLabel`;
export const numberOfServersTriedLabel = `${prefix}.numberOfServersTriedLabel`;

export const ENGLISH = {
    [http2StreamIDLabel]: 'HTTP2 Stream ID',
    [serverPushInitiatedLabel]: 'Server Push Initiated',
    [serverPushedRequestLabel]: 'Server Pushed Request',
    [cacheHitLabel]: 'Cache Hit',
    [compressionLabel]: 'Compression',
    [compressedPercentageLabel]: '{0}% Compressed',
    [numberOfServersTriedLabel]: 'Number of Servers Tried',
};
