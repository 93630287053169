/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './EmailValidationController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('EmailValidationController', [
'$scope', 'Regex', 'Base', 'l10nService',
function($scope, Regex, Base, l10nService) {
    const vm = this;
    const base = new Base();

    /**
    * Get keys from source bundles for template usage
    */
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    $scope.$parent.modalScope = $scope;

    this.Regex = Regex;

    /**
     * Returns the modal settings depending on the type of validation being done, passed into
     * AviModal.open when opening the modal. Modal can be opened from Administration -> Email/SMTP
     * or Operations -> Notifications.
     */
    const validationSettings = {
        smtp: {
            title: l10nService.getMessage(l10nKeys.smtpTextLabel),
            textLabel: l10nService.getMessage(l10nKeys.smtpTextLabel),
            showTextOnly: false,
            disableEditRecipients: false,
        },
        email: {
            title: l10nService.getMessage(l10nKeys.emailTitle),
            textLabel: l10nService.getMessage(l10nKeys.emailTextLabel),
            showTextOnly: false,
            disableEditRecipients: true,
        },
        snmp: {
            title: l10nService.getMessage(l10nKeys.snmpTitle),
            textLabel: l10nService.getMessage(l10nKeys.snmpTextLabel),
            showTextOnly: true,
            disableEditRecipients: true,
        },
        syslog: {
            title: l10nService.getMessage(l10nKeys.syslogTitle),
            textLabel: l10nService.getMessage(l10nKeys.syslogTextLabel),
            showTextOnly: true,
            disableEditRecipients: true,
        },
    };

    /**
     * Returns the API URL depending on the type.
     * @param  {string} type - Can be 'smtp', 'email', 'snmp', or 'syslog'.
     * @param  {string=} uuid - uuid of profile to be tested. Only applies to 'email', 'snmp', and
     * 'syslog'.
     * @return {string}
     */
    const getApi = function(type, uuid) {
        const apiHash = {
            smtp: '/api/testemail',
            email: `/api/alertemailconfig/${uuid}/testemail`,
            snmp: `/api/snmptrapprofile/${uuid}/testsnmptrap`,
            syslog: `/api/alertsyslogconfig/${uuid}/testsyslog`,
        };

        return apiHash[type];
    };

    $scope.init = function() {
        vm.errors = null;
        vm.busy = false;

        // If a config object has been passed in, that means this modal was opened from the
        // Operations -> Notifications -> Email page, in which case the to_emails and cc_emails
        // fields have already been populated.
        if (!_.isUndefined($scope.config)) {
            vm.config = $scope.config;
        }

        angular.extend(vm, validationSettings[$scope.type]);
    };

    /**
     * Make POST request to validate email settings.
     */
    this.submit = function() {
        const settings = validationSettings[$scope.type];
        const api = getApi($scope.type, $scope.uuid);
        const config = angular.copy(this.config);

        if (settings.disableEditRecipients) {
            delete config.to_emails;
            delete config.cc_emails;
        }

        this.busy = true;
        this.errors = null;

        base.request('POST', api, config, null, 'validateemail')
            .then(function() {
                vm.closeModal();
            }).catch(function(rsp) {
                vm.errors = rsp.data;
            }).finally(function() {
                vm.busy = false;
            });
    };

    this.closeModal = function() {
        $scope.closeModal();
    };

    $scope.$on('$destroy', function() {
        base.cancelRequests('validateemail');
    });
}]);
