/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'client-insights-controller';
const prefix = `${moduleName}.${componentName}`;

export const navigationTimingHeader = `${prefix}.navigationTimingHeader`;
export const clientDimensionsHeader = `${prefix}.clientDimensionsHeader`;
export const clearFiltersButtonLabel = `${prefix}.clearFiltersButtonLabel`;
export const hideTableButtonLabel = `${prefix}.hideTableButtonLabel`;
export const locationLabel = `${prefix}.locationLabel`;
export const deviceLabel = `${prefix}.deviceLabel`;
export const browserLabel = `${prefix}.browserLabel`;
export const operatingSystemLabel = `${prefix}.operatingSystemLabel`;
export const topUrlsLabel = `${prefix}.topUrlsLabel`;

export const ENGLISH = {
    [navigationTimingHeader]: 'Navigation Timing',
    [clientDimensionsHeader]: 'Client Dimensions',
    [clearFiltersButtonLabel]: 'Clear Filters',
    [hideTableButtonLabel]: 'Hide table',
    [locationLabel]: 'Location',
    [deviceLabel]: 'Device',
    [browserLabel]: 'Browser',
    [operatingSystemLabel]: 'Operating System',
    [topUrlsLabel]: 'Top URLs',
};
