/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'custom-usable-subnets-grid';
const prefix = `${moduleName}.${componentName}`;

export const usableSubnetsGridHeader = `${prefix}.usableSubnetsGridHeader`;
export const connectionAlertMessage = `${prefix}.connectionAlertMessage`;
export const testLoginLabel = `${prefix}.testLoginLabel`;

export const ENGLISH = {
    [usableSubnetsGridHeader]: 'Usable Subnets ({0})',
    [connectionAlertMessage]: 'Test Login Custom IPAM profile before proceeding to add subnets',
    [testLoginLabel]: 'Test Login',
};
