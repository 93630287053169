/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'time-frame-selector';
const prefix = `${moduleName}.${componentName}`;

export const displayingLabel = `${prefix}.displayingLabel`;

export const ENGLISH = {
    [displayingLabel]: 'Displaying',
};
