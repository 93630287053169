/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'pki-profile-certificate-revocation-list-grid';
const prefix = `${moduleName}.${componentName}`;

export const certRevocationListAddButtonLabel = `${prefix}.certRevocationListAddButtonLabel`;
export const certRevocationListRemoveButtonLabel = `${prefix}.certRevocationListRemoveButtonLabel`;
export const certRevocationListColTitleName = `${prefix}.certRevocationListColTitleName`;
export const certRevocationListColTitleExpDate = `${prefix}.certRevocationListColTitleExpDate`;
export const certRevocationListColTitleRefresh = `${prefix}.certRevocationListColTitleRefresh`;
export const crlMinutesLabel = `${prefix}.crlMinutesLabel`;
export const crlRefreshEmptyLabel = `${prefix}.crlRefreshEmptyLabel`;

export const ENGLISH = {
    [certRevocationListAddButtonLabel]: 'Add',
    [certRevocationListRemoveButtonLabel]: 'Remove',
    [certRevocationListColTitleName]: 'Name',
    [certRevocationListColTitleExpDate]: 'Expiration Date',
    [certRevocationListColTitleRefresh]: 'Refresh',
    [crlMinutesLabel]: '{0} Minutes',
    [crlRefreshEmptyLabel]: 'None',
};
