/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    ElementRef,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNull } from 'underscore';
import { AjsDependency } from 'ajs/js/utilities/ajsDependency';

/**
 * @description Directive that sets the input ngModel value to undefined if its value is empty
 *     string or null, which are the default empty values for input[type=text] and
 *     input[type=number] respectively.
 * @author alextsg
 */
@Directive({
    selector: 'input[type=text][ngModel], input[type=password][ngModel],' +
        'input[type=number][ngModel], input[type=email][ngModel], input[type=url][ngModel], ' +
        'input[type=tel][ngModel], input:not([type])[ngModel], textarea[ngModel]',
})
export class InputFieldDirective implements OnInit, OnDestroy {
    /**
     * Flag for identifying if element is number field
     */
    private isNumberField = false;

    constructor(private control: NgControl, private elementRef: ElementRef) {}

    /** @override */
    public ngOnInit(): void {
        this.elementRef.nativeElement.addEventListener('input', this.handleInput);

        if (this.elementRef.nativeElement.type === 'number') {
            this.isNumberField = true;
            this.elementRef.nativeElement.addEventListener(
                'wheel',
                this.disableScrollAndBlurElement,
            );
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('input', this.handleInput);

        if (this.isNumberField) {
            this.elementRef.nativeElement.removeEventListener(
                'wheel',
                this.disableScrollAndBlurElement,
            );
        }
    }

    /**
     * Called when the input element receives an 'input' event.
     */
    private handleInput = (event: InputEvent): void => {
        const { value } = event.target as HTMLInputElement;
        // We check instanceof AjsDependency for cases where the ngModel is a MessageItem instance.
        // If so, we don't want to set the ngModel to undefined since the config expects the
        // messageItem in its tree.

        if (isNull(value) || value === '' && !(this.control.value instanceof AjsDependency)) {
            event.stopImmediatePropagation();
            this.control.viewToModelUpdate(undefined);
            this.control.control.setValue(undefined, {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false,
            });
        }
    };

    /**
     * Remove scrolling behavior on input[type=number] fields if
     * it receives an 'scroll/mousewheel' event, when focussed
     */
    private disableScrollAndBlurElement = (event: MouseEvent): void => {
        if (event.type === 'wheel') {
            this.elementRef.nativeElement.blur();
        }
    };
}
