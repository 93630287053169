/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, Input } from '@angular/core';
import {} from '@clr/angular';

import './vs-log-cinematic-end-to-end-knot.component.less';

/**
 * @description
 *     Component for the end-to-end knot in log cinematic view. The template comprises a header and
 *     a fixed-size icon.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-end-to-end-knot',
    templateUrl: './vs-log-cinematic-end-to-end-knot.component.html',
})
export class VsLogCinematicEndToEndKnotComponent {
    @Input()
    public headerText: string;

    @Input()
    public iconShape: string;

    @Input()
    public iconStatus?: string;

    @Input()
    public iconBadge?: string;
}
