/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './TechSupportDataTransformer.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

function techSupportDataTransformerFactory(
    $filter, ListDataTransformer, TechSupportCaptureDataTransformer,
    techSupportStatus, l10nService,
) {
    /**
     * @alias module:services/TechSupportDataTransformer
     * @extends ListDataTransformer
     * @private
     */
    class TechSupportDataTransformer extends ListDataTransformer {
        /**
         * Returns filtered readable time, from when tarball was created.
         * @param {TechSupportApiConfig} config
         * @returns {AviMediumDate}
         */
        static getCreatedTime(config) {
            return $filter('aviDate')(config.modified);
        }

        /**
         * Returns time taken for generation.
         * @param {TechSupportApiStatus} status
         * @return {string} - time taken or empty string
         */
        static getDuration(status) {
            return status.duration || '';
        }

        /**
         * Returns linked case details
         * @param {TechSupportApiStatus} status
         * @return {?Object} caseDetails - return case details.
         */
        static getCaseDetails(status) {
            return status.case_details || null;
        }

        /**
         * Strips excess and returns fileName from provided url.
         * @param {TechSupportApiConfig} config
         * @returns {string}
         * Note: this method provides same as
         * @TechSupportCaptureDataTransformer.getFileNameFromStatus, but from url instead,
         * in situations where output isn't available in @TechSupportApiStatus object.
         */
        static getFileNameFromUrl(config) {
            const { url } = config,
                indexOfParam = 'tech_support/',
                indexOfParamLength = indexOfParam.length,
                i = url.indexOf(indexOfParam) + indexOfParamLength;

            return url.slice(i);
        }

        /**
         * Returns true if missing meta data status file.
         * @param {TechSupportApiStatus} status
         * @return {boolean}
         */
        static metaDataStatusFileIsNotFound(status) {
            return status.status_code ===
                techSupportStatus.SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND;
        }

        /**
         * Returns human readable status snippet.
         * @param {string} resultStatus
         * @return {string}
         */
        static getResultSnippet(resultStatus) {
            l10nService.registerSourceBundles(dictionary);

            switch (resultStatus) {
                case 'success':
                    return l10nService.getMessage(l10nKeys.completeSuccessfullyLabel);
                case 'warning':
                    return l10nService.getMessage(l10nKeys.completeErrorLabel);
            }
        }

        /**
         * Returns status of tech support icon.
         * @param {TechSupportApiStatus} status
         * @return {string} - 'warning' or 'success'
         */
        static getResultStatus(status) {
            const { status_code: statusCode } = status;
            const {
                SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS,
                SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS,
                SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND,
            } = techSupportStatus;

            switch (statusCode) {
                case SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS:
                    return 'success';
                case SYSERR_TECH_SUPPORT_COLLECTION_STATUS_FILE_NOT_FOUND:
                case SYSERR_TECH_SUPPORT_COLLECTION_SUCCESS_WITH_ERRORS:
                    /* Note:
                    These are technically errors, not warnings, but UI component
                    treats them as warnings.
                    Warnings are possible, but aren't returned here at present.
                     */
                    return 'warning';
            }
        }

        /**
         * Returns errors on actual status object.
         * @param {TechSupportApiStatus} status
         * @return {string[]} - strings of errors or empty array
         */
        static getStatusErrors(status) {
            return status.errors || [];
        }

        /**
         * Returns size formatted.
         * @param {TechSupportApiConfig} config
         * @returns {FormattedSize}
         */
        static getSize(config) {
            return $filter('formatBytes')(config.size);
        }

        /**
         * Does transformations on 'config' part of data, not 'config.status'.
         * @param {TechSupportApiConfig} config
         * @return {{size: TechSupportConfig.size, created: TechSupportConfig.created}}
         */
        static getTransformedItemConfigData(config) {
            const { getCreatedTime, getSize } = TechSupportDataTransformer;

            return {
                created: getCreatedTime(config),
                size: getSize(config),
            };
        }

        /**
         * Does additional transformations on tech support item, which are unique
         * to Collection only.
         * @param {TechSupportApiStatus} status
         * @return {{
         * duration: TechSupportConfig.duration,
         * resultStatus: TechSupportConfig.resultStatus,
         * errors: TechSupportConfig.errors
         * }}
         */
        static getTransformedItemStatusData(status) {
            const {
                getDuration,
                getStatusErrors,
                getResultStatus,
                metaDataStatusFileIsNotFound,
                getCaseDetails,
            } = TechSupportDataTransformer;

            const result = {
                duration: getDuration(status),
                errors: getStatusErrors(status),
                resultStatus: getResultStatus(status),
                caseDetails: getCaseDetails(status),
            };

            if (!status.errors && metaDataStatusFileIsNotFound(status) && status.status) {
                result.errors.push(status.status);
            }

            return result;
        }

        /**
         * Runs through single tech support instance and sets needed props.
         * @param {Object.<string, TechSupportApiConfig>} result
         */
        static getTransformedItemData(result) {
            const {
                getTransformedItemStatusData,
                getTransformedItemConfigData,
                getFileNameFromUrl,
                getResultSnippet,
            } = TechSupportDataTransformer;

            /**
             * @type {TechSupportApiConfig}
             */
            const { config } = result;
            /**
             * @type {TechSupportApiStatus}
             */
            const { status } = config;

            /**
             * @type {TechSupportConfig}
             */
            const finalConfig = TechSupportCaptureDataTransformer
                .getTransformedCaptureStatusData(status);

            Object.assign(
                finalConfig,
                getTransformedItemStatusData(status),
                getTransformedItemConfigData(config),
            );

            if (!finalConfig.fileName) {
                finalConfig.fileName = getFileNameFromUrl(config);
            }

            finalConfig.resultSnippet = getResultSnippet(finalConfig.resultStatus);
            finalConfig.url = config.url;

            return { config: finalConfig };
        }

        /**
         * @override
         */
        processResponse(rsp, resp) {
            const results = super.processResponse(rsp, resp);
            const finalResponse = {
                data: {
                    results: [],
                },
            };

            finalResponse.data.results =
                results.data.results.map(TechSupportDataTransformer.getTransformedItemData);

            return finalResponse;
        }
    }

    return TechSupportDataTransformer;
}

techSupportDataTransformerFactory.$inject = [
    '$filter',
    'ListDataTransformer',
    'TechSupportCaptureDataTransformer',
    'techSupportStatus',
    'l10nService',
];

/**
 * @ngdoc service
 * @name TechSupportDataTransformer
 * @module services/TechSupportDataTransformer
 * @author Akul Aggarwal
 * @description
 *
 *     Transforms data in @TechSupportCollection before consumed.
 *     First transformation happens using
 *     @TechSupportCaptureDataTransformer.getTransformedCaptureStatusData,
 *     then proceeds to do own transformation.
 *
 */
angular.module('aviApp').factory('TechSupportDataTransformer', techSupportDataTransformerFactory);
