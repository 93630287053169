/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    ElementRef,
    HostListener,
    Renderer2,
} from '@angular/core';

export const SECRET_STUB_STR = '<sensitive>';

/**
 * KeyboardEvent keyCode for the "Tab" key.
 */
const TAB_KEY_CODE = 9;

/**
 * @description Directive that sets the password to an empty string on focus if the value is equal
 *     to the SECRET_STUB_STR.
 * @author alextsg
 */
@Directive({ selector: 'input[type=password]' })
export class PasswordInputDirective {
    /**
     * True if the SECRET_STUB_STR should be restored after a focusout event.
     */
    private shouldResetSecretStubStr = false;

    constructor(private element: ElementRef, private renderer: Renderer2) {}

    /**
     * Listens for the focus event, and if the input[type=password] element is not readonly and
     * its value is equal to the SECRET_STUB_STR, set the value to empty string.
     */
    @HostListener('focus') public handleFocus = (): void => {
        const { nativeElement } = this.element;
        const { attributes, value } = nativeElement;

        if (!attributes.readonly && value === SECRET_STUB_STR) {
            this.shouldResetSecretStubStr = true;
            this.renderer.setProperty(nativeElement, 'value', '');
            nativeElement.dispatchEvent(new Event('input'));
        }
    };

    /**
     * Listens for the keyup event, and if the key pressed was not the "Tab" key (used for switching
     * between input elements) then we do not restore the SECRET_STUB_STR on focusout.
     */
    @HostListener('keyup', ['$event']) public handleKeyUp = ($event: KeyboardEvent): void => {
        if ($event.keyCode !== TAB_KEY_CODE) {
            this.shouldResetSecretStubStr = false;
        }
    };

    /**
     * Listens for the focusout event, and if the element is not readonly, its value is equal to
     * empty string (''), and the value was originally the SECRET_STUB_STR, then we set it back to
     * SECRET_STUB_STR.
     */
    @HostListener('focusout') public handleFocusOut = (): void => {
        const { nativeElement } = this.element;
        const { attributes, value } = nativeElement;

        if (!attributes.readonly && value === '' && this.shouldResetSecretStubStr) {
            this.renderer.setProperty(nativeElement, 'value', SECRET_STUB_STR);
            nativeElement.dispatchEvent(new Event('input'));
        }
    };
}
