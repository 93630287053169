/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

export const AVI_SLIDER_RANGE_VALIDATOR_KEY = 'aviSliderRangeValidation';

/**
 * Returns validatorFunction for AviSliderComponent.
 *
 * @author vgohil
 */
export function aviSliderRangeValidator(range: [number, number]): ValidatorFn {
    /**
     * Returns null if control's value is valid, i.e. within given range.
     * Return validationErros otherwise.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const [min = 0, max = 100] = range;
        const { value } = control;

        const errorObject = {
            [AVI_SLIDER_RANGE_VALIDATOR_KEY]: value,
        };

        if (value != null && (value < min || value > max)) {
            return errorObject;
        }

        return null;
    };
}
