/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'internal-dns-service-domains-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const columnTitleDomainName = `${prefix}.columnTitleDomainName`;
export const columnTitleRecordTtl = `${prefix}.columnTitleRecordTtl`;
export const domainNameInputPlaceholder = `${prefix}.domainNameInputPlaceholder`;
export const recordTtlInputPlaceholder = `${prefix}.recordTtlInputPlaceholder`;
export const dnsServiceDomainsGridHeader = `${prefix}.dnsServiceDomainsGridHeader`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [removeButtonLabel]: 'Remove',
    [columnTitleDomainName]: 'Domain Name',
    [columnTitleRecordTtl]: 'Override Record TTL',
    [domainNameInputPlaceholder]: 'Enter Domain Name',
    [recordTtlInputPlaceholder]: 'Enter Record TTL (Seconds)',
    [dnsServiceDomainsGridHeader]: 'DNS Service Domains ({0})',
};
