/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/
import { AviPermissionResource } from 'generated-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';

import { ControlScriptsModalComponent } from 'ng/modules/scripts';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../scripts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 *   Control Script item.
 *
 * @author Rachit Aggarwal , Ratan Kumar
 */
export class ControlScript extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'alertscriptconfig',
            objectType: 'AlertScriptConfig',
            windowElement: ControlScriptsModalComponent,
            permissionName: AviPermissionResource.PERMISSION_ALERTCONFIG,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.controlScriptModalBreadcrumbTitle);
    }
}
