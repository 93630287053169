/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/pie-chart-card.less';

//works with multiple series and provides st of current values to an actual pie chart
angular.module('aviApp').directive('pieChartCard', [
'myAccount', 'GraphSync', 'DisplayValue',
function(myAccount, GraphSync, DisplayValue) {
    function pieChartCardLink(scope, elm, attr) {
        let sum;//sum of all values shown on the chart

        scope.myAccount = myAccount;
        scope.GraphSync = GraphSync;

        scope.padding = {
            top: 5,
            bottom: 5,
            left: 5,
            right: 5,
        };

        scope.hovered = null;//updated by pieChart directive
        scope.selected = null;//massaged hovered to be used in a template

        function updateValues() {
            let maxSeries;

            sum = 0;

            const displayValueType = myAccount.getValuesDisplayType();

            const { metric } = scope.config.getConfig();

            scope.values = _.map(scope.seriesList, sName => {
                const series = metric.getSeries(sName);
                const res = DisplayValue.createCardValueUnit(
                    series, displayValueType,
                );

                res.value = res.value || 0;
                sum += res.value;
                res.className = series.getColorClassName();
                res.title = series.getTitle();

                if (!maxSeries || res.value > maxSeries.value) {
                    maxSeries = angular.copy(res);
                }

                return res;
            });

            if (maxSeries && maxSeries.value && (GraphSync.mouseOnGraph ||
                displayValueType !== 'max')) {
                maxSeries.unit = '%';
                maxSeries.value = +(maxSeries.value * 100 / sum).toFixed(2);
                scope.maxSeries = maxSeries;
            } else {
                scope.maxSeries = null;

                if (displayValueType === 'max') {
                    scope.values = null;
                }
            }
        }

        if (!scope.card) { //can provide just config if it has only one card inside
            scope.card = scope.config.getCard();
        }

        scope.seriesList = scope.card.series;

        if (!scope.title) {
            scope.title = scope.config.getCardTitle(scope.card.id);
        }

        scope.noValueLabel = attr.noValueLabel || scope.config.getNoValueLabel(scope.card.id);

        scope.$watchGroup([
            'card.metric.getMainSeries().getLatestPointTime()',
            () => `${GraphSync.mouseOnGraph}${GraphSync.stuck}`,
            'myAccount.getValuesDisplayType()',
        ], updateValues);

        scope.$watch('hovered', hovered => {
            if (hovered && sum) {
                scope.selected = angular.copy(hovered);
                scope.selected.value = +(100 * scope.selected.value / sum).toFixed(2);
                scope.selected.unit = '%';
            } else {
                scope.selected = null;
            }
        });
    }

    return {
        scope: {
            config: '=',
            card: '=',
            item: '=',
            title: '@',
        },
        restrict: 'EA',
        templateUrl: 'src/views/components/pie-chart-card.html',
        link: pieChartCardLink,
    };
}]);
