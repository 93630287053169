/**
 * Module containing core services.
 * @preferred
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'ng/shared/shared.module';
import { DialogModule } from 'ng/modules/dialog/dialog.module';
import { LoaderModule } from 'ng/modules/loader/loader.module';

import {
    AppLevelAlertsService,
    AviAlertComponent,
    AviAlertService,
    AviAppLevelAlertsPortalComponent,
    AviDatePipe,
    CentralLicenseAlertsService,
    DevLoggerService,
    FullModalService,
    I18nDatePipe,
    ObjectDiffService,
    StatePermissionTreeService,
    TenantService,
    TransitionProgressBarComponent,
} from '.';

const coreComponents = [
    AviAlertComponent,
    AviAppLevelAlertsPortalComponent,
    TransitionProgressBarComponent,
];

const coreServices = [
    FullModalService,
    StatePermissionTreeService,
    TenantService,
    DevLoggerService,
    AviAlertService,
    AppLevelAlertsService,
    CentralLicenseAlertsService,
    ObjectDiffService,
];

const corePipes = [
    AviDatePipe,
    I18nDatePipe,
];

@NgModule({
    declarations: [
        ...coreComponents,
        ...corePipes,
    ],
    providers: [
        ...coreServices,
        ...corePipes,
    ],
    imports: [
        SharedModule,
        CommonModule,
        DialogModule,
        FormsModule,
        LoaderModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [
        ...corePipes,
        TransitionProgressBarComponent,
    ],
})
export class CoreModule { }
