/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Directive,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { isNull } from 'underscore';
import { Subscription } from 'rxjs';

const INVALIDATE_FORM_ERROR = 'invalidateForm';

/**
 * @description
 *     This directive allows for invalidating a form based on a boolean. In Angular forms, disabled
 *     inputs are taken out of form validation checking, which might not be what we want. Ex. in
 *     cases where dropdown options are populated based on another field, the dropdown is
 *     disabled until that field has been filled in. Without this directive, the form would be valid
 *     even if the dropdown were required.
 * @example
 *     <form
 *         #myForm="ngForm"
 *         [invalidateForm]="connected"
 *     >
 *         <input
 *             type="text"
 *             [(ngModel)]="val"
 *             name="val"
 *         />
 *     </form>
 * @author alextsg
 */
@Directive({
    selector: '[invalidateForm]',
})
export class InvalidateFormDirective implements OnInit, OnChanges, OnDestroy {
    /**
     * Sets the invalidateForm boolean.
     */
    @Input('invalidateForm')
    public set setInvalidateForm(invalidate: boolean | '') {
        this.invalidateForm = invalidate === '' || invalidate;
    }

    /**
     * If true, the form should have an invalidateForm error.
     */
    private invalidateForm: boolean;

    /**
     * Subscription for Form status changes.
     */
    private formSubscription: Subscription;

    constructor(private ngForm: NgForm) {}

    /** @override */
    public ngOnInit(): void {
        this.setErrors(this.invalidateForm);

        const { form } = this.ngForm;

        this.formSubscription = form.statusChanges.subscribe((): void => {
            if (form.valid && this.invalidateForm) {
                this.setErrors(this.invalidateForm);
            }
        });
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { setInvalidateForm } = changes;

        if (setInvalidateForm && !setInvalidateForm.isFirstChange()) {
            this.setErrors(setInvalidateForm.currentValue);
        }
    }

    /** @override */
    public ngOnDestroy(): void {
        this.formSubscription.unsubscribe();
    }

    /**
     * Called to set errors on the form. Preserves existing errors.
     */
    private setErrors(invalidate = false): void {
        const { errors: existingErrors } = this.ngForm.form;
        let errors = null;

        if (invalidate) {
            if (isNull(existingErrors)) {
                errors = { [INVALIDATE_FORM_ERROR]: true };
            } else {
                errors = {
                    ...existingErrors,
                    [INVALIDATE_FORM_ERROR]: true,
                };
            }
        } else if (!isNull(existingErrors)) {
            const {
                [INVALIDATE_FORM_ERROR]: invalidateForm,
                ...otherExistingErrors
            } = existingErrors;

            if (Object.keys(otherExistingErrors).length > 0) {
                errors = { ...otherExistingErrors };
            }
        }

        this.ngForm.form.setErrors(errors);
    }
}
