/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @type {Function}
 * @name generateURL
 * @param {Object} urlObject
 * @returns {string}
 * @description
 *
 *      Generates a URL string using a URL object;
 *      this URL string may or may not contain the leading protocol.
 */
angular.module('aviApp').constant('generateURL', function(urlObject) {
    const { protocol, host, path, query, hash } = urlObject;
    // only host are required field in a urlObject, the others are optional
    const url = `${protocol ? `${protocol.toLowerCase()}://` : ''}` +
                `${host}${path || ''}${query || ''}${hash || ''}`;

    return url;
});
