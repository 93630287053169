/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name loadPoolServer
 * @description
 *
 *     Takes server config of pool instance and creates it's instance. Pool is supposed to be
 *     loaded through api/pool/poolId to have a list of servers. No API call is made.
 */
angular.module('avi/router').factory('loadPoolServer', [
'$q', 'Server', function($q, Server) {
    return args => {
        const
            { pool, serverId } = args,
            serverConfig = pool.getServerConfigById(serverId);

        if (!serverConfig) {
            const { poolId } = args;

            return $q.reject(`server ${serverId} is not found in pool ${poolId}`);
        }

        args.data = {
            config: serverConfig,
        };

        return new Server(args);
    };
}]);
