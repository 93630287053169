/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { JWTServerProfileCollection }
    from 'ajs/modules/security/factories/jwt-server-profile.collection.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './jwt-server-profile.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TJWTServerProfileCollection = typeof JWTServerProfileCollection;

/**
 * @description JWT Server Profiles list page component.
 *
 * @author Hitesh Mandav.
 */
@Component({
    selector: 'jwt-server-profile',
    templateUrl: './jwt-server-profile.component.html',
})
export class JwtServerProfileComponent implements OnInit, OnDestroy {
    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the jwt server profile collection.
     */
    public readonly jwtServerProfileCollection: JWTServerProfileCollection;

    /**
     * Configuration object to display jwt server profile collection.
     */
    public jwtServerProfileGridConfig: IAviCollectionDataGridConfig;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(JWTServerProfileCollection)
        JWTServerProfileCollection: TJWTServerProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.jwtServerProfileCollection = new JWTServerProfileCollection({ isStatic: false });
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.jwtServerProfileCollection;

        this.jwtServerProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.jwtServerProfileCollection,
            getRowId: (index: number): number => index,
            defaultSorting: 'name',
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                },
            ],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.jwtServerProfileCollection.destroy();
    }
}
