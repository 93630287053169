/**
 * @module ClusterModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Inject,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import { Cluster } from 'ajs/modules/cluster/factories';

import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid';

import {
    ClusterObjState,
    ClusterRole,
    ClusterStateValue,
    ClusterVipStatus,
} from 'generated-types';

import * as l10n from './nodes-page.l10n';
import './nodes-page.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TCluster = typeof Cluster;

/**
 * @description
 *
 * Nodes Page component.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'nodes-page',
    templateUrl: './nodes-page.component.html',
})

export class NodesPageComponent implements OnInit, AfterViewInit {
    /**
     * Instance of Cluster Item.
     */
    @Input('resolveClusterInfo')
    public cluster: Cluster = null;

    /**
     * TemplateRef for node state field.
     */
    @ViewChild('stateFieldTemplateRef')
    public stateFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * TemplateRef for node role field.
     */
    @ViewChild('roleFieldTemplateRef')
    public roleFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Collection object to store the cluster node collection.
     */
    public clusterNodeCollection: any;

    /**
     * Configuration object to display nodes collection.
     */
    public nodesGridConfig: IAviCollectionDataGridConfig;

    /**
     * Cluster status tooltip message.
     */
    public clusterTooltipStatusMessage : string;

    /**
     * Map of cluster state and label to display.
     */
    private clusterStateLabelMap = {
        CLUSTER_UP_HA_ACTIVE: l10nKeys.statusUpHaActiveLabel,
        CLUSTER_UP_HA_COMPROMISED: l10nKeys.statusUpHaCompromisedLabel,
        CLUSTER_UP_HA_NOT_READY: l10nKeys.statusUpHaNotReadyLabel,
        CLUSTER_UP_NO_HA: l10nKeys.statusUpNoHaLabel,
        CLUSTER_REBOOTING: l10nKeys.statusRebootingLabel,
        CLUSTER_PROCESSING: l10nKeys.statusProcessingLabel,
        CLUSTER_STARTING_UP: l10nKeys.statusStartingLabel,
    };

    /**
     * Map of node state and label to display.
     */
    private nodeStateLabelMap = {
        CLUSTER_STARTING: l10nKeys.stateStartingLabel,
        CLUSTER_ACTIVE: l10nKeys.stateActiveLabel,
        CLUSTER_INACTIVE: l10nKeys.stateInactiveLabel,
        CLUSTER_FAILURE: l10nKeys.stateFailureLabel,
        CLUSTER_COLD_REBOOT: l10nKeys.stateColdRebootLabel,
        CLUSTER_WARM_REBOOT: l10nKeys.stateWarmRebootLabel,
        CLUSTER_CLEAN_REBOOT: l10nKeys.stateCleanRebootLabel,
        CLUSTER_WAITING: l10nKeys.stateWaitingLabel,
        CLUSTER_CONFIG: l10nKeys.stateConfigLabel,
    };

    constructor(
        private readonly l10nService: L10nService,
        @Inject('ClusterNodeCollection')
        private readonly ClusterNodeCollection: any,
        @Inject('Cluster')
        private readonly Cluster: TCluster,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.clusterNodeCollection = new this.ClusterNodeCollection({
            defaultDataSources: ['list', 'runtime-list'],
        });

        this.cluster = new Cluster({
            id: 'default',
            bind: {
                itemSaveSuccess: () => {
                    this.cluster.load();
                    this.clusterNodeCollection.load();
                },
            },
        });
    }

    /**
     * Edit button click handler.
     */
    public async updateCluster(): Promise<void> {
        await this.cluster.edit();
    }

    /**
     * Return label according to cluster vip status.
     */
    public get clusterVipStatusLabel(): string {
        const statusLabelsHash = this.schemaService.getEnumValueLabelsHash('ClusterVipStatus');
        const status = this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_vip_status;

        return statusLabelsHash[status] || '';
    }

    /**
     * Return color code according to cluster state.
     */
    public get statusColorCode(): string {
        const clusterStatus =
            this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_state;

        switch (clusterStatus) {
            case ClusterStateValue.CLUSTER_UP_HA_ACTIVE:
            case ClusterStateValue.CLUSTER_UP_HA_NOT_READY:
            case ClusterStateValue.CLUSTER_UP_NO_HA:
                return 'success';
            case ClusterStateValue.CLUSTER_UP_HA_COMPROMISED:
                return 'warning';
            case ClusterStateValue.CLUSTER_REBOOTING:
            case ClusterStateValue.CLUSTER_PROCESSING:
            case ClusterStateValue.CLUSTER_STARTING_UP:
                return '';
        }
    }

    /**
     * Return color code according to cluster vip status.
     */
    public get clusterVipStatusColorCode(): string {
        const clusterVipStatus =
            this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_vip_status;

        switch (clusterVipStatus) {
            case ClusterVipStatus.CLUSTER_VIP_DECONFIG_FAILURE:
                return 'danger';

            case ClusterVipStatus.CLUSTER_VIP_FAILED:
                return 'danger';

            case ClusterVipStatus.CLUSTER_VIP_ACTIVE:
                return 'success';

            case ClusterVipStatus.CLUSTER_VIP_NOT_CONFIGURED:
            default:
                return '';
        }
    }

    /**
     * Returns true if cluster state is Active.
     */
    public isClusterStateActive(state: string): boolean {
        return state === ClusterObjState.CLUSTER_ACTIVE;
    }

    /**
     * Returns true if role is Leader.
     */
    public isClusterRoleLeader(role: string): boolean {
        return role === ClusterRole.CLUSTER_LEADER;
    }

    /**
     * Returns cluster State.
     */
    public get clusterState(): string {
        return this.clusterNodeCollection.itemList[0]?.getRuntimeData()?.cluster_state;
    }

    /**
     * Returns cluster state label as per state.
     */
    public get clusterStateLabel(): string {
        return this.l10nService.getMessage(
            this.clusterStateLabelMap[this.clusterState],
        );
    }

    /**
     * Returns node state label.
     */
    public nodeStateLabel(state: string): string {
        return this.l10nService.getMessage(this.nodeStateLabelMap[state]);
    }

    /** @override */
    public ngOnInit(): void {
        this.nodesGridConfig = {
            id: 'node-list',
            collection: this.clusterNodeCollection,
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(l10nKeys.placeholderMessage),
                hideDelete: true,
                hideEdit: true,
                hideCheckboxes: true,
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow) => row.id,
        };
    }

    /** @override */
    public ngAfterViewInit(): void {
        this.nodesGridConfig = {
            ...this.nodesGridConfig,
            fields: [{
                id: 'nodeip',
                label: this.l10nService.getMessage(l10nKeys.columnTitleNodeIp),
                transform: (row: IAviCollectionDataGridRow): string => {
                    return row.getConfig().ip;
                },
            }, {
                id: 'hostname',
                label: this.l10nService.getMessage(l10nKeys.columnTitleHostname),
                transform: (row: IAviCollectionDataGridRow): string => {
                    return row.getConfig().name;
                },
            }, {
                id: 'role',
                label: this.l10nService.getMessage(l10nKeys.columnTitleRole),
                templateRef: this.roleFieldTemplateRef,
            }, {
                id: 'state',
                label: this.l10nService.getMessage(l10nKeys.columnTitleState),
                templateRef: this.stateFieldTemplateRef,
            }],
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.clusterNodeCollection.destroy();
        this.cluster.destroy();
    }
}
