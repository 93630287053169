/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
const moduleName = 'security';
const componentName = 'geo-db-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleFiles = `${prefix}.columnTitleFiles`;
export const columnTitleMappings = `${prefix}.columnTitleMappings`;

export const ENGLISH = {
    [columnTitleFiles]: 'GeoDB Files',
    [columnTitleMappings]: 'GeoDB Mapping',
};
