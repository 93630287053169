/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { debounce } from 'underscore';
import { IKeyValue } from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import './avi-repeated-key-values-grid.component.less';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-repeated-key-values-grid.component.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Generic grid component for displaying key-value labels.
 * @example
 *      <avi-repeated-key-values-grid
 *          name="stringGroupTypeKeyValue"
 *          keyFieldPlaceholder="Enter Key"
 *          valueFieldPlaceholder="Enter Value"
 *          keyFieldcolumnLabel="Key"
 *          valueFieldcolumnLabel="Value"
 *          [keyValueRepeatedMessageItem]="this.editable.config.kv"
 *          [allowDuplicates]="false"
 *      ></avi-repeated-key-values-grid>
 * @author Suraj Kumar
 */
@Component({
    selector: 'avi-repeated-key-values-grid',
    templateUrl: './avi-repeated-key-values-grid.component.html',
})
export class AviRepeatedKeyValuesGridComponent implements AfterViewInit {
    /**
     * Placeholder for Key input control.
     */
    @Input()
    public keyFieldPlaceholder?: string;

    /**
     * Placeholder for Value input control.
     */
    @Input()
    public valueFieldPlaceholder?: string;

    /**
     * AviDataGrid Key column label.
     */
    @Input()
    public keyColumnLabel?: string;

    /**
     * AviDataGrid Value column label.
     */
    @Input()
    public valueColumnLabel?: string;

    /**
     * Check for allowing duplicate values.
     */
    @Input()
    public allowDuplicates = false;

    /**
     * Repeated key-value message item.
     */
    @Input()
    public keyValueRepeatedMessageItem: RepeatedMessageItem<MessageItem<IKeyValue>>;

    /**
     * Datagrid template for input field of the key in the label pair.
     */
    @ViewChild('keyFieldTemplateRef')
    public keyFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for input field of the value in the label pair.
     */
    @ViewChild('valueFieldTemplateRef')
    public valueFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Contains duplicate value of the AviDataGrid.
     */
    public duplicateValues: string[] = [];

    /**
     * DataGrid config for the object list.
     */
    public listDataGridConfig: IAviDataGridConfig;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
        this.keyFieldPlaceholder = this.l10nService.getMessage(l10nKeys.keyFieldPlaceholder);
        this.valueFieldPlaceholder = this.l10nService.getMessage(l10nKeys.valueFieldPlaceholder);
        this.keyColumnLabel = this.l10nService.getMessage(l10nKeys.keyColumnLabel);
        this.valueColumnLabel = this.l10nService.getMessage(l10nKeys.valueColumnLabel);
    }

    /** @override */
    public ngOnInit(): void {
        // To prevent getting called many times while entering values.
        this.findDuplicates = debounce(this.findDuplicates, 200);
    }

    /** @override */
    public ngAfterViewInit(): void {
        const { l10nService } = this;

        this.listDataGridConfig = {
            getRowId: (index: number): number => index,
            fields: [
                {
                    label: this.keyColumnLabel,
                    id: 'key',
                    templateRef: this.keyFieldTemplateRef,
                },
                {
                    label: this.valueColumnLabel,
                    id: 'value',
                    templateRef: this.valueFieldTemplateRef,
                },
            ],
            multipleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                onClick: (rows: Array<MessageItem<IKeyValue>>) => this.deleteEntries(rows),
            }],
            singleactions: [{
                label: l10nService.getMessage(this.l10nKeys.removeButtonLabel),
                shape: 'trash',
                onClick: (row: MessageItem<IKeyValue>) => this.deleteEntry(row),
            }],
        };
    }

    /**
     * Checks for duplicate error.
     */
    public get hasDuplicateError(): boolean {
        return this.duplicateValues.length > 0;
    }

    /**
     * Add a new entry.
     */
    public addEntry(): void {
        this.keyValueRepeatedMessageItem.add({
            key: undefined,
            value: undefined,
        });

        this.findDuplicates();
    }

    /**
     * To find duplicates values in grid.
     */
    public findDuplicates(): void {
        const uniqueElements = new Set();

        this.duplicateValues = [];

        if (!this.allowDuplicates) {
            if (this.keyValueRepeatedMessageItem.isEmpty()) {
                return;
            }

            this.keyValueRepeatedMessageItem.config.forEach(({ config }) => {
                if (uniqueElements.has(config.key?.trim())) {
                    this.duplicateValues.push(config.key?.trim());
                } else {
                    uniqueElements.add(config.key?.trim());
                }
            });
        }
    }

    /**
     * Delete a group of entries from the list.
     */
    private deleteEntries(entries: Array<MessageItem<IKeyValue>>): void {
        entries.forEach(entry => this.deleteEntry(entry));
    }

    /**
     * Delete a single entry from the list.
     */
    private deleteEntry(entry: MessageItem<IKeyValue>): void {
        this.keyValueRepeatedMessageItem.removeByMessageItem(entry);
        this.findDuplicates();
    }
}
