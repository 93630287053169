/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'accounts';
const componentName = 'role-filter-grid';
const prefix = `${moduleName}.${componentName}`;

export const roleFilterAddButtonLabel = `${prefix}.roleFilterAddButtonLabel`;
export const columnTitleCriteria = `${prefix}.columnTitleCriteria`;
export const columnTitleKey = `${prefix}.columnTitleKey`;
export const columnTitleValue = `${prefix}.columnTitleValue`;
export const columnTitleEnabled = `${prefix}.columnTitleEnabled`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const valueCountSuffix = `${prefix}.valueCountSuffix`;

export const ENGLISH = {
    [roleFilterAddButtonLabel]: 'Add',
    [columnTitleCriteria]: 'Criteria',
    [columnTitleKey]: 'Key',
    [columnTitleValue]: 'Value',
    [columnTitleEnabled]: 'Enabled',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
    [valueCountSuffix]: '(+{0} More)',
};
