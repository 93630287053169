/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name  HttpPolicy
 * @description
 *     HttpPolicy class. Contains a "rules" array containing configured rules for the HTTP policy
 *     and methods for modifying rules.
 */

/**
 * @typedef {Object} PolicyRule
 * @property {string} index
 * @property {string} name
 * @property {boolean} enable
 */
function HttpPolicyFactory(policyRuleSort) {
    return class HttpPolicy {
        constructor(args) {
            this.config = args && args.config || {};

            this.transformAfterLoad_();

            this.matches = args && args.matches || [];
            this.actions = args && args.actions || [];
            HttpPolicy.addOrRemoveCustomValuesFromRules(this.config, true);
        }

        /**
         * Updates data by received data. Manual angular.extend.
         * @param {Object} newData
         */
        updateData(newData) {
            if (angular.isObject(newData)) {
                _.each(newData, (val, key) => this.config[key] = val, this);
                HttpPolicy.addOrRemoveCustomValuesFromRules(this.config, true);
                this.transformAfterLoad_();
            }
        }

        /**
         * Adds or Removes "_CUSTOM_VALUE" from hdr.value.var in HTTP rules. "_CUSTOM_VALUE" is
         * not an ENUM so it must be added/removed from/to the backend.
         * @param {Object} config - Config object.
         * @param {boolean=} add - True to add "_CUSTOM_VALUE", false to remove.
         * @static
         */
        static addOrRemoveCustomValuesFromRules(config, add = false) {
            const { rules } = config;

            if (Array.isArray(rules)) {
                rules.forEach(rule => {
                    if (Array.isArray(rule['hdr_action'])) {
                        rule['hdr_action'].forEach(action => {
                            if (add) {
                                if (action.hdr.value &&
                                    angular.isUndefined(action.hdr.value.var)) {
                                    action.hdr.value.var = '_CUSTOM_VALUE';
                                }
                            } else if (action.hdr.value &&
                                    action.hdr.value.var === '_CUSTOM_VALUE') {
                                action.hdr.value.var = undefined;
                            }
                        });
                    }
                });
            }
        }

        /**
         * Returns true if rules have been configured in this HTTP policy.
         */
        hasRules() {
            const { rules } = this.config;

            return Array.isArray(rules) && rules.length > 0;
        }

        /**
         * Getter function used in ordered-grid component. Return a reference to config#rules.
         * @return {Object[]} rows of data..
         */
        get rows() {
            return this.config && this.config.rules;
        }

        /**
         * Enables a set of rules.
         * @param {PolicyRule[]} rules
         */
        enable(rules) {
            rules.forEach(rule => rule.enable = true);
        }

        /**
         * Disables a set of rules.
         * @param {PolicyRule[]} rules
         */
        disable(rules) {
            rules.forEach(rule => rule.enable = false);
        }

        /**
         * Deletes a set of rules.
         * @param {PolicyRule[]|PolicyRule} rules
         */
        delete(rules) {
            rules = Array.isArray(rules) ? rules : [rules];
            this.config.rules = this.rows.filter(rule => rules.indexOf(rule) === -1);
        }

        /**
         * Returns true if the indicies of the rules are valid for re-ordering.
         * @param {number} index1 - Index of rule within rules, not the property.
         * @param {number} index2 - Index of rule within rules, not the property.
         */
        indiciesAreValid(index1, index2) {
            const max = this.rows.length - 1;
            const min = 0;

            return index1 !== index2 &&
                Math.max(index1, index2) <= max && Math.min(index1, index2) >= min;
        }

        /**
         * Moves rule to a new index.
         * @param {PolicyRule} rule
         * @param {Object} data - Contains position and index properties.
         * @param {string} data.position - New position relative to the new index, 'above' or
         *     'below'.
         * @param {number} data.index - New index to be moved to.
         */
        moveRule(rule, data) {
            const oldIndex = this.rows.indexOf(rule);
            let newIndex = _.findIndex(this.rows, row => row.index === data.index);

            if (data.position === 'below') {
                newIndex++;
            }

            if (oldIndex < newIndex) {
                newIndex--;
            }

            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Moves rule to a new index. All rules in-between need to have their indices shifted.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        moveToIndex(oldIndex, newIndex) {
            if (!this.indiciesAreValid(oldIndex, newIndex)) {
                return;
            }

            // newIndex moves towards the direction of oldIndex
            const increment = oldIndex < newIndex ? -1 : 1;

            while (oldIndex !== newIndex) {
                this.swapRule(oldIndex, newIndex);
                newIndex += increment;
            }
        }

        /**
         * Handler for drag-and-drop event.
         * @param {number} oldIndex - Index of the original position of the rule.
         * @param {number} newIndex - Index of the new position.
         */
        handleDragAndDropChange(oldIndex, newIndex) {
            this.moveToIndex(oldIndex, newIndex);
        }

        /**
         * Given two indices of rules, swaps positions in the rules array along with the index
         * property in the rule.
         * @param {number} oldIndex
         * @param {number} newIndex
         */
        swapRule(oldIndex, newIndex) {
            const oldRule = this.rows[oldIndex];
            const newRule = this.rows[newIndex];

            this.rows[oldIndex] = this.rows[newIndex];
            this.rows[newIndex] = oldRule;
            [oldRule.index, newRule.index] = [newRule.index, oldRule.index];
        }

        /**
         * Returns the config data to be set in the HTTP policy.
         */
        dataToSave() {
            const config = angular.copy(this.config);

            HttpPolicy.addOrRemoveCustomValuesFromRules(config);

            return config;
        }

        /**
         * Sorts rules based on their indexes.
         * @protected
         */
        transformAfterLoad_() {
            const { config } = this;

            if (!('rules' in config)) {
                config['rules'] = [];
            }

            config.rules.sort(policyRuleSort);
        }
    };
}

HttpPolicyFactory.$inject = ['policyRuleSort'];

angular.module('aviApp').factory('HttpPolicy', HttpPolicyFactory);
