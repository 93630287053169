/** @module CportalModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IItemParams,
    withFullModalMixin,
} from 'ajs/js/utilities/mixins';

import {
    Component,
    Type,
} from '@angular/core';

import {
    IBaseRequestPromise,
    MessageItem,
    ObjectTypeItem,
} from 'ajs/modules/data-model/factories';

import { IFullModalLayout } from 'ng/modules/core/services/full-modal';

import {
    AviPermissionResource,
    IALBServicesConfig,
    ICaseConfig,
    IPortalFeatureOptIn,
    IWafCrsConfig,
} from 'generated-types';

import {
    CPortalInfoModalComponent,
    CPortalInfoPreviewComponent,
} from 'ng/modules/cportal/components/cportal-info-modal';

import { ALBServicesConfig } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './portal-info.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Interface for ALB Services Config Partial.
 */
type TALBServicesConfigPartial =
    Omit<IALBServicesConfig, 'feature_opt_in_status' | 'case_config' | 'waf_config'>;

/**
 * Interface for ALB Services Config.
 */
export interface IExtendedALBServicesConfig extends TALBServicesConfigPartial {
    feature_opt_in_status?: MessageItem<IPortalFeatureOptIn>;
    case_config?: MessageItem<ICaseConfig>;
    waf_config?: MessageItem<IWafCrsConfig>;
}

/**
 * Interface for  ALB Services Config Data.
 */
interface IExtendedALBServicesData {
    config: IExtendedALBServicesConfig;
}

/**
 * Ajs dependency token for PortalInfo.
 */
export const PORTAL_INFO_ITEM_TOKEN = 'PortalInfo';

/**
 * @description PortalInfo Item.
 * @author Ashish Verma, Rajawant Prajapati, Ratan Kumar
 */
export class PortalInfo extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Function to get ALBService config.
     */
    public getConfig: () => IExtendedALBServicesConfig;
    public data: IExtendedALBServicesData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'albservicesconfig',
            objectType: ALBServicesConfig,
            id: 'default', // fake Id
            windowElement: CPortalInfoModalComponent,
            permissionName: AviPermissionResource.PERMISSION_CONTROLLER,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter will return feature opt in status config.
     */
    public get featureOptInStatusConfig(): IPortalFeatureOptIn {
        return this.getConfig().feature_opt_in_status.config;
    }

    /**
     * Getter for case config.
     */
    public get caseConfig(): ICaseConfig {
        return this.getConfig().case_config.config;
    }

    /**
     * Getter for waf config.
     */
    public get wafConfig(): IWafCrsConfig {
        return this.getConfig().waf_config.config;
    }

    /**
     * Returns a API URI to save an object.
     * @override
     */
    public urlToSave(): string {
        return `/api/${this.objectName}`;
    }

    /**
     * Method to load side preview.
     * @override
     */
    protected async getFullModalProps(
        params: IItemParams,
        modalComponent?: Type<Component>,
    ): Promise<IFullModalLayout> {
        const props = await super.getFullModalProps(params, modalComponent);

        return {
            ...props,
            previewComponent: CPortalInfoPreviewComponent as Type<Component>,
        };
    }

    /**
     * Load portalInfo config
     * @override
     */
    protected loadConfig(): IBaseRequestPromise<IALBServicesConfig> {
        const url = `/api/${this.objectName}`;
        const requestConfig = {
            group: 'config',
            method: 'GET',
            url,
        };

        this.cancelRequests('config');

        return this.request<IALBServicesConfig>(requestConfig)
            .then(this.onConfigLoad_.bind(this));
    }

    /**
     * @override
     */
    // eslint-disable-next-line class-methods-use-this
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.albServiceConfigBreadcrumbTitle);
    }

    /** @override */
    // eslint-disable-next-line class-methods-use-this
    protected requiredFields(): string[] {
        return [
            'split_proxy_configuration',
        ];
    }
}
