/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'alert';

export const alertEmailConfigBreadcrumbTitle = `${prefix}.alertEmailConfigBreadcrumbTitle`;
export const alertActionBreadcrumbTitle = `${prefix}.alertActionBreadcrumbTitle`;
export const alertSyslogBreadcrumbTitle = `${prefix}.alertSyslogBreadcrumbTitle`;
export const alertSyslogServerBreadcrumbTitle = `${prefix}.alertSyslogServerBreadcrumbTitle`;
export const alertSnmpTrapProfileBreadcrumbTitle = `${prefix}.alertSnmpTrapProfileBreadcrumbTitle`;
export const alertSnmpTrapServereBreadcrumbTitle = `${prefix}.alertSnmpTrapServereBreadcrumbTitle`;

export const ENGLISH = {
    [alertEmailConfigBreadcrumbTitle]: 'Email',
    [alertActionBreadcrumbTitle]: 'Alert Action',
    [alertSyslogBreadcrumbTitle]: 'Syslog',
    [alertSyslogServerBreadcrumbTitle]: 'Syslog Server',
    [alertSnmpTrapProfileBreadcrumbTitle]: 'Trap Profile',
    [alertSnmpTrapServereBreadcrumbTitle]: 'Trap Server',
};
