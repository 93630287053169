/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name RangeParser
 * @description
 *
 *     IP addresses transformations.
 *
 */

/**
 * @typedef {Object} UriParamToken
 * @property {string} type - Token type, such as URI_TOKEN_TYPE_STRING.
 * @property {string} str_value - Token value for `string` type. Optional.
 * @property {number} start_index - Optional.
 * @property {number} end_index - Optional.
 */

angular.module('aviApp').service('RangeParser', [
'ipAddrToInt', 'Regex', 'getIpAddr',
function(ipAddrToInt, Regex, getIpAddr) {
    /**
     * Returns a type of passed object - can be ip or ip range. Empty string if none of these.
     * @param {Object} ipOrRangeObject
     * @returns {string}
     * @inner
     */
    const getIpObjectType = function(ipOrRangeObject) {
        if (angular.isObject(ipOrRangeObject)) {
            if ('begin' in ipOrRangeObject && 'end' in ipOrRangeObject) {
                return 'range';
            } else if ('addr' in ipOrRangeObject && 'type' in ipOrRangeObject) {
                return 'ip';
            }
        }

        return '';
    };

    /**
     * Parses IP range or IP and returns object if successful.
     * @param {string} ipOrRangeString - The string containing IP address or IP range.
     * @returns {IpAddr|IpAddrRange|IpAddrPrefix|null}
     * @public
     */
    this.ipRange2Json = function(ipOrRangeString) {
        let chunks;

        ipOrRangeString = ipOrRangeString.replace(/\s/, '');

        if (chunks = Regex.ipAddrRangeWG.exec(ipOrRangeString)) {
            return {
                begin: getIpAddr(chunks[1]),
                end: getIpAddr(chunks[2]),
            };
        } else if (chunks = Regex.ip.exec(ipOrRangeString)) {
            return getIpAddr(chunks[0]);
        } else if (chunks = Regex.subnetWG.exec(ipOrRangeString)) {
            return {
                ip_addr: getIpAddr(chunks[1]),
                mask: chunks[2],
            };
        } else if (chunks = Regex.ipv6.exec(ipOrRangeString)) {
            return getIpAddr(chunks[0], 'V6');
        } else if (chunks = Regex.ipv6Prefix.exec(ipOrRangeString)) {
            const ipAddr = getIpAddr(chunks[1], 'V6');

            return {
                ip_addr: ipAddr,
                mask: +chunks[chunks.length - 1],
            };
        } else if (chunks = Regex.ipv6Range.exec(ipOrRangeString)) {
            return {
                begin: getIpAddr(chunks[1], 'V6'),
                end: getIpAddr(chunks[2], 'V6'),
            };
        }

        return null;
    };

    /**
     * Parses HTTP Status range or HTTP Status and returns JSON if successful, otherwise Null.
     * @param statusOrRangeString - HTTP Status range or HTTP Status
     * @returns StatusNumber or Range object, or Null if unsuccessful.
     */
    this.httpStatus2Json = function(statusOrRangeString) {
        return this.strRange2Json(statusOrRangeString);
    };

    /**
     * Parses abstract number or ranges like 1234-2134, 1242.
     * @private
     * @param range
     * @returns Number or Range if successful, Null if fails.
     */
    this.strRange2Json = function(range) {
        let chunks;

        // Searching for range (XXXX - XXXX)
        chunks = /^\s*(\d+)\s*-\s*(\d+)\s*$/.exec(range.replace(/\s+/g, ''));

        if (chunks) {
            return {
                begin: chunks[1],
                end: chunks[2],
            };
        } else {
            // Searching for number
            chunks = /^\s*(\d+)\s*$/.exec(range);

            if (chunks) {
                return chunks[1];
            }
        }
    };

    /**
     * Turns string token representation into token object.
     * @param {string} str
     * @returns {UriParamToken}
     * @private
     */
    this.str2token = function(str) {
        const keywords = {
            P: 'PATH',
            PATH: 'PATH',
            H: 'HOST',
            HOST: 'HOST',
            SG_MAP: 'STRING_GROUP',
            STRINGGROUP_MAP: 'STRING_GROUP',
            SG_RE: 'REGEX',
            STRINGGROUP_REGEX: 'REGEX',
            SG_RE_Q: 'REGEX_QUERY',
            STRINGGROUP_REGEX_QUERY: 'REGEX_QUERY',
        };

        let token = {
            type: 'URI_TOKEN_TYPE_STRING',
            str_value: str,
        };

        const regExp = '^(HOST|PATH|H|P|SG_MAP|StringGroup_Map|SG_RE_Q|StringGroup_Regex_Query|' +
                'SG_RE|StringGroup_Regex)((\\[(\\d+):(\\d*)])|(\\[(\\d+)])|(\\[])?)$',
            chunks = new RegExp(regExp, 'i').exec(str);

        let type;

        if (chunks) {
            type = keywords[chunks[1].toUpperCase()];

            if (type === 'STRING_GROUP' || chunks[4] || chunks[7]) {
                token = { type: `URI_TOKEN_TYPE_${type}` };

                if (type !== 'STRING_GROUP') {
                    if (chunks[4]) {
                        token.start_index = parseInt(chunks[4], 10);
                        token.end_index = chunks[5] !== '' ? parseInt(chunks[5], 10) : 65535;
                    } else if (chunks[7]) {
                        token.start_index = parseInt(chunks[7], 10);
                        token.end_index = token.start_index;
                    }
                }
            }
        }

        return token;
    };

    /**
     * Returns UriParamToken string representation.
     * @param {UriParamToken} token
     * @returns {string|undefined}
     * @public
     */
    this.token2str = function(token) {
        const chunks = /URI_TOKEN_TYPE_(HOST|PATH|STRING_GROUP|STRING|REGEX_QUERY|REGEX)/
            .exec(token.type);
        let str;

        if (chunks) {
            switch (chunks[1]) {
                case 'STRING':
                    str = token.str_value;
                    break;

                case 'STRING_GROUP':
                    str = 'SG_MAP[]';
                    break;

                default:
                    if (chunks[1] === 'REGEX') {
                        str = 'SG_RE[';
                    } else if (chunks[1] === 'REGEX_QUERY') {
                        str = 'SG_RE_Q[';
                    } else {
                        str = `${chunks[1]}[`;
                    }

                    if (!_.isUndefined(token.start_index)) {
                        str += token.start_index;
                    }

                    if (!_.isUndefined(token.end_index) &&
                        token.start_index != token.end_index) {
                        str += `:${token.end_index == 65535 ? '' : token.end_index}`;
                    }

                    str += ']';
                    break;
            }
        }

        return str;
    };

    /**
     * Translates string presentation of multiple UriParamTokens into a list of objects. Squashes
     * adjacent strings separated by separator symbol.
     * @param {string} str
     * @param {string} separator - `/` for paths or `.` for domains.
     * @returns {UriParamToken[]} - Array of token objects.
     * @public
     */
    this.tokensStr2tokens = function(str, separator) {
        function squashStringTokens(token1, token2) {
            token1.str_value += separator + token2.str_value;
        }

        const
            res = [],
            arr = str.split(separator);

        // If the first token is empty, then there is leading slash which has
        // to be removed.
        if (arr[0] === '' && separator === '/') {
            arr.shift();
        }

        arr.map(this.str2token)
            .forEach(function(token, i) {
                if (i === 0 ||
                    token.type !== 'URI_TOKEN_TYPE_STRING' ||
                    res[res.length - 1].type !== 'URI_TOKEN_TYPE_STRING') {
                    res.push(token);
                } else {
                    squashStringTokens(res[res.length - 1], token);
                }
            });

        return res;
    };

    /**
     * Used to get the total number of IP addresses in a given range in order to check if the
     * number is greater than what is currently supported.
     * @param  {string|IpAddr|IpAddrRange} ipOrRange - IpRange or IP address objects or strings
     *     having one of these.
     * @return {number} Total number of IP addresses in range. NaN if faulty string/object was
     * passed.
     */
    this.getNumberOfIpsFromRange = function(ipOrRange) {
        let ipOrRangeObj;

        if (angular.isString(ipOrRange)) {
            ipOrRangeObj = this.ipRange2Json(ipOrRange);
        } else {
            ipOrRangeObj = ipOrRange;
        }

        switch (getIpObjectType(ipOrRangeObj)) {
            case 'ip':
                return 1;

            case 'range': {
                const { begin, end } = ipOrRangeObj;

                return Math.abs(ipAddrToInt(end.addr) - ipAddrToInt(begin.addr)) + 1;
            }

            default:
                return NaN;
        }
    };

    /**
     * Returns number of IPs by network prefix length.
     * @param {number} prefixLength - Network prefix length: from 0 to 32.
     * @returns {number}
     * @public
     */
    this.getNumberOfIpsFromPrefixLength = function(prefixLength) {
        return prefixLength >= 0 && prefixLength <= 32 ? 2 ** (32 - prefixLength) : NaN;
    };

    /**
     * Returns an array of IP addresses expanded from a passed range.
     * @param  {IpAddrRange|string} ipRange - Object containing begin and end objects defining
     *     range of IP addresses or string representing range of IP addresses.
     * @return {IpAddr[]} Array of all IP addresses from a given range.
     */
    this.getIpsFromRange = function(ipRange) {
        const ips = [];

        if (angular.isString(ipRange) && Regex.ipAddrRange.test(ipRange)) {
            ipRange = this.ipRange2Json(ipRange);
        }

        if (ipRange) {
            const
                { begin, end } = ipRange,
                fromAddr = begin.addr.split('.').map(Number),
                toAddr = end.addr.split('.').map(Number);

            for (let i0 = fromAddr[0]; i0 <= toAddr[0]; i0++) {
                for (let i1 = i0 == fromAddr[0] ? fromAddr[1] : 0;
                    i1 <= (i0 == toAddr[0] ? toAddr[1] : 255);
                    i1++) {
                    for (let i2 = i1 == fromAddr[1] ? fromAddr[2] : 0;
                        i2 <= (i1 == toAddr[1] ? toAddr[2] : 255);
                        i2++) {
                        for (let i3 = i2 == fromAddr[2] ? fromAddr[3] : 0;
                            i3 <= (i2 == toAddr[2] ? toAddr[3] : 255);
                            i3++) {
                            ips.push(getIpAddr([i0, i1, i2, i3].join('.')));
                        }
                    }
                }
            }
        }

        return ips;
    };
}]);
