/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/wizard.less';
import * as l10n from './Wizard.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Wizard directive
 * @ngdoc directive
 * @name wizard
 * @restrict element, class
 * @param {Object} config - Object containing steps properties for wizard.
 * @param {Object} ngDisabled - Flag to determine whether to reset wizard or unlock steps.
 * @param {string} unlock - Flag to determine whether to unlock all steps or partial steps.
 * @description Creates steps for application creation (ex. VS advanced create).
 *     Wraps a template and some logic.
 *     Styles for this directive should be in `less/components/wizard.less`.
 * @example
 * Example of config object
 *
 * $scope.wizard = {
 *
 *  // required: The list of steps
 *  steps: [{
 *      title: 'Settings',
 *  }, {
 *      title: 'Advanced'
 *  },{
 *      title: 'Summary'
 *  }],
 *
 *  // The index of currently active step
 *  current: 0,
 *
 * }
 * <wizard config="wizard" ng-disabled="forms.modalForm.$invalid" unlock="all"></wizard>
 */
angular.module('aviApp').directive('wizard', ['l10nService', function(l10nService) {
    return {
        scope: {
            config: '=',
            ngDisabled: '=',
            unlock: '@',
        },
        restrict: 'E',
        template: require('../../views/components/wizard.partial.html'),
        link(scope) {
            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            /**
             * Make sure current index initially corresponds to any step
             */
            if (!scope.config.current || !scope.config.steps[scope.config.current]) {
                scope.config.current = 0;

                if (scope.config.steps[scope.config.current]) {
                    scope.config.steps[scope.config.current].unlocked = true;
                }
            }

            let highestIndex = 0;

            /**
             * @description Unlocks only steps that have previously been unlocked.
             * @private
             */
            const unlockPartial = function() {
                for (let i = 0; i <= highestIndex; i++) {
                    scope.config.steps[i].unlocked = true;
                }
            };

            /**
             * @description Unlocks all steps.
             * @private
             */
            const unlockAll = function() {
                scope.config.steps.forEach(function(step) {
                    step.unlocked = true;
                });
            };

            /**
             * Going next 1 step
             * The function is created in config object to be able to call it outside this directive
             */
            scope.config.next = function() {
                if (scope.config.steps[scope.config.current + 1] &&
                    (!scope.config.beforeChange ||
                        !scope.config.beforeChange(scope.config.current))) {
                    scope.config.steps[scope.config.current].unlocked = true;
                    scope.config.current++;
                    scope.config.steps[scope.config.current].unlocked = true;
                    highestIndex = Math.max(scope.config.current, highestIndex);
                }

                if (!_.isUndefined(scope.config.afterChange)) {
                    scope.config.afterChange();
                }
            };

            /**
             * Going back 1 step
             * The function is created in config object to be able to call it outside this directive
             */
            scope.config.previous = function() {
                if (scope.config.current < 1) {
                    return;
                }

                if (!scope.config.beforeChange ||
                    !scope.config.beforeChange(scope.config.current)) {
                    scope.config.current--;
                }

                if (!_.isUndefined(scope.config.afterChange)) {
                    scope.config.afterChange();
                }
            };

            /**
             * Resets the state of wizard, makes all steps locked again
             * Should be called on modal window initialization
             */
            scope.config.reset = function() {
                scope.config.current = 0;
                _.each(scope.config.steps, function(step) {
                    delete step.unlocked;
                });
            };

            /**
             * Just lets to go to any unlocked step
             * @param index - Current step index (starts from 0)
             */
            scope.goto = function(index) {
                if (scope.config.steps[index] &&
                    scope.config.steps[index].unlocked &&
                    scope.config.current !== index &&
                    (!scope.config.beforeChange ||
                        !scope.config.beforeChange(scope.config.current))) {
                    scope.config.current = index;
                    highestIndex = Math.max(scope.config.current, highestIndex);
                }

                if (!_.isUndefined(scope.config.afterChange)) {
                    scope.config.afterChange();
                }
            };

            // Watches for changes to ngDisabled. Intended for watching form validity.
            scope.$watch('ngDisabled', function() {
                if (!_.isUndefined(scope.ngDisabled)) {
                    if (scope.ngDisabled) {
                        const { config } = scope;

                        for (let i = config.current + 1; i < config.steps.length; i++) {
                            config.steps[i].unlocked = false;
                        }
                    } else if (scope.unlock === 'all') {
                        unlockAll();
                    } else {
                        unlockPartial();
                    }
                }
            });
        },
    };
}]);
