/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as globalL10n from 'global-l10n';
import * as l10n from './ResponsePolicy.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

angular.module('aviApp').directive('responsePolicy', [
'Regex',
'RangeParser',
'$timeout',
'$templateCache',
'PolicyGridConfig',
'AviConfirmService',
'schemaService',
'l10nService',
function(
    Regex,
    RangeParser,
    $timeout,
    $templateCache,
    PolicyGridConfig,
    AviConfirmService,
    schemaService,
    l10nService,
) {
    l10nService.registerSourceBundles(dictionary);

    return {
        scope: {
            httpResponsePolicy: '=',
            current: '=',
            services: '=',
            readonly: '@',
        },
        restrict: 'A',
        templateUrl: 'src/views/components/response-policy.html',
        link(scope, elm) {
            /**
             * Get keys from source bundles for template usage
             */
            scope.l10nKeys = l10nKeys;
            scope.globalL10nKeys = globalL10nKeys;

            scope.Regex = Regex;
            scope.RangeParser = RangeParser;

            scope.httpProtocolEnumKeys = schemaService.getEnumKeys('HTTPProtocol');
            scope.httpVersionEnumValues = schemaService.getEnumValues('HTTPVersion');

            //for header set and remove actions, passed to eAutoComplete
            scope.headersSuggestions =
                schemaService.getEnumValues('HTTPPolicyVar')
                    .map(({ label, value }) => ({
                        value,
                        displayValue: label,
                    }));

            scope.rule2EditMode = function(rule) {
                // Convert matches
                rule.matchEdit = [];
                _.map(rule.match, function(match, id) {
                    if (match instanceof Array) {
                        _.each(match, function(m) {
                            if (scope.matches[id].init) {
                                scope.matches[id].init(m);
                            }

                            rule.matchEdit.push({
                                id,
                                value: m,
                            });
                        });
                    } else {
                        if (scope.matches[id].init) {
                            scope.matches[id].init(match);
                        }

                        rule.matchEdit.push({
                            id,
                            value: match,
                        });
                    }
                });
                // Convert actions
                rule.actionEdit = [];
                _.each(scope.actionKeys, function(actionId) {
                    if (rule[actionId]) {
                        if (rule[actionId] instanceof Array && !rule[actionId].length) {
                            return;
                        }

                        if (scope.actions[actionId].init) {
                            scope.actions[actionId].init(rule[actionId]);
                        }

                        rule.actionEdit.push({
                            id: actionId,
                            value: rule[actionId],
                        });
                    }
                });

                rule.save = function() {
                    scope.saveRule();
                };
            };

            scope.rule2ReadMode = function(rule) {
                // Convert matches back
                rule.match = {};
                _.map(rule.matchEdit, function(match) {
                    if (scope.matches[match.id].onSubmit) {
                        scope.matches[match.id].onSubmit(match.value);
                    }

                    if (match.id == 'hdrs' || match.id == 'rsp_hdrs') {
                        if (!rule.match[match.id] || !(rule.match[match.id] instanceof Array)) {
                            rule.match[match.id] = [];
                        }

                        rule.match[match.id].push(match.value);
                    } else {
                        rule.match[match.id] = match.value;
                    }
                });
                delete rule.matchEdit;

                // Convert actions back
                _.each(scope.actionKeys, function(actionId) {
                    delete rule[actionId];
                });
                _.map(rule.actionEdit, function(action) {
                    if (scope.actions[action.id].onSubmit) {
                        scope.actions[action.id].onSubmit(action.value);
                    }

                    rule[action.id] = action.value;
                });
                delete rule.actionEdit;
                delete rule.save;
            };

            /**
             * Creates a new rule.
             * @param {Object=} toPosition - Determines where to place the new rule.
             * @param {string} toPosition.position - 'above' or 'below' a specified index.
             * @param {number} toPosition.index - Existing index used as reference for position.
             */
            scope.addRule = function(toPosition) {
                const { config } = scope.httpResponsePolicy;

                scope.current = {
                    enable: true,
                    name: `Rule ${
                        config && config.rules ? config.rules.length + 1 : 1}`,
                    matchEdit: [],
                    _toPosition: toPosition,
                };
                scope.rule2EditMode(scope.current);
            };

            /**
             * Duplicates an existing rule and allows for editing.
             * @param {Object} rule - Rule config.
             * @param {Object} toData - Contains position and index properties.
             * @param {string} toData.position - New position relative to the new index, 'above' or
             *     'below'.
             * @param {number} toData.index - New index to be moved to.
             */
            scope.duplicateRule = function(rule, toPosition) {
                scope.current = angular.extend(angular.copy(rule), {
                    name: l10nService.getMessage(l10nKeys.ruleNameDuplicatedMessage, [rule.name]),
                    _toPosition: toPosition,
                });

                scope.current.index = undefined;

                /** Add UI only flag to allow first time editing of immutable
                 * is_sensitive field only in case it's a new action added by user.
                 * In edit scenarios, user should not be allowed to change value of this field.
                 */
                const { hdr_action: hdrAction } = scope.current;

                if (Array.isArray(hdrAction)) {
                    hdrAction.forEach(action => {
                        action.isNewAction = true;
                    });
                }

                scope.rule2EditMode(scope.current);
            };

            scope.editRule = function(rule) {
                scope.current = angular.copy(rule);
                scope.rule2EditMode(scope.current);
            };

            scope.saveRule = function() {
                if (!scope.current) {
                    return;
                }

                // Remove UI only field used to restrict immutable is_sensitive field changes.
                const { actionEdit } = scope.current;

                if (Array.isArray(actionEdit)) {
                    actionEdit.forEach(edit => {
                        if (Array.isArray(edit.value)) {
                            edit.value.forEach(action => {
                                delete action.isNewAction;
                            });
                        }
                    });
                }

                let { config } = scope.httpResponsePolicy;

                if (!config) {
                    config = {
                        rules: [],
                    };

                    scope.httpResponsePolicy.config = config;
                }

                if (!Array.isArray(config.rules)) {
                    config.rules = [];
                }

                // Make sure there is no rule with the same name
                scope.error = null;

                if (_.any(config.rules, function(rule) {
                    return rule.name == scope.current.name && rule.index != scope.current.index;
                })) {
                    scope.error = l10nService.getMessage(l10nKeys.ruleNameAlreadyInUseMessage);

                    return;
                }

                scope.rule2ReadMode(scope.current);

                if (!_.isUndefined(scope.current.index)) {
                    angular.copy(scope.current, _.find(config.rules, function(item) {
                        return item.index == scope.current.index;
                    }));
                } else {
                    scope.current.index = _.max(config.rules, function(i) {
                        return i.index;
                    }).index + 1 || 1;
                    config.rules.push(scope.current);
                }

                if (angular.isObject(scope.current._toPosition)) {
                    scope.httpResponsePolicy.moveRule(scope.current, scope.current._toPosition);
                }

                scope.current = null;
            };

            /**
             * Appends a match to the current rule
             * @param type - Match type
             */
            scope.addMatch = function(type) {
                if (!scope.current || !scope.matches[type]) {
                    return;
                }

                scope.current.matchEdit.push({
                    id: type,
                    index: scope.current.matchEdit.length,
                    value: angular.copy(scope.matches[type].default),
                });

                // Scroll down
                const curOffsetTop = $('.new-match-list').offset().top;

                $timeout(function() {
                    if (!$('.new-match-list').length) {
                        return;
                    }

                    const scrollable = $(elm).closest('.scrollable');

                    scrollable.animate({
                        scrollTop: $(scrollable).scrollTop() +
                            ($('.new-match-list').offset().top - curOffsetTop),
                    });
                });
            };

            /**
             * Appends a match to the current rule
             * @param type - Match type
             */
            scope.addAction = function(type) {
                if (!scope.current || !scope.actions[type]) {
                    return;
                }

                scope.current.actionEdit.push({
                    id: type,
                    value: angular.copy(scope.actions[type].default),
                });

                // Scroll down
                const curOffsetTop = $('.new-action-list').offset().top;

                $timeout(function() {
                    if (!$('.new-action-list').length) {
                        return;
                    }

                    const scrollable = $(elm).closest('.scrollable');

                    scrollable.animate({
                        scrollTop: $(scrollable).scrollTop() +
                            ($('.new-action-list').offset().top - curOffsetTop),
                    });
                });
            };

            /**
             * Deletes the match from the current rule
             * @param {Object} matchOrMatchValue - the type of the match
             */
            scope.deleteMatch = function(matchOrMatchValue) {
                const index = _.findIndex(scope.current.matchEdit,
                    item => item === matchOrMatchValue || item.value === matchOrMatchValue);

                if (index !== -1) {
                    scope.current.matchEdit.splice(index, 1);
                }
            };

            /**
             * Deletes the action from the current rule
             * @param type - the type of the match
             */
            scope.deleteAction = function(action) {
                scope.current.actionEdit = _.filter(scope.current.actionEdit, function(item) {
                    return item != action;
                });
            };

            /**
             * Uses to show/hide 'add new match' button
             * @returns {boolean}
             */
            scope.newMatchesAvailable = function() {
                if (!scope.current || !scope.current.matchEdit) {
                    return;
                }

                return scope.current.matchEdit.length != Object.keys(scope.matches).length;
            };

            // Used to filter out the rules that exist in detail.rules
            scope.matchNotUsed = function(match) {
                if (match == 'hdrs' || match == 'rsp_hdrs') {
                    return true;
                }

                if (scope.current && scope.current.matchEdit) {
                    const found = _.find(scope.current.matchEdit, function(item) {
                        return item.id == match;
                    });

                    if (found) {
                        return false;
                    }
                }

                return true;
            };

            scope.actionNotUsed = function(action) {
                if (scope.current && scope.current.actionEdit) {
                    return !_.find(scope.current.actionEdit, function(item) {
                        return item.id == action;
                    });
                }

                return true;
            };

            scope.object2Array = function(obj) {
                const arr = [];

                _.each(obj, function(item, key) {
                    arr.push({
                        id: key,
                        data: item,
                    });
                });

                return arr;
            };

            scope.removeHeaderMatch = function(match, index) {
                match.value.splice(index, 1);

                if (!match.value.length) {
                    scope.deleteMatch(match.id);
                }
            };

            /**
             * Return Boolean to show/hide Custom Value field for HTTP Header Action.
             * The field is shown in case of Add or Replace Header is selected.
             * @param {Object} hdr - HTTP Header Object.
             */
            scope.showCustomValueField = function(hdr) {
                return hdr.action !== 'HTTP_REMOVE_HDR' &&
                    (hdr.hdr.value.var === 'HTTP_POLICY_VAR_HTTP_HDR' ||
                        hdr.hdr.value.var === '_CUSTOM_VALUE');
            };

            scope.matches = {
                client_ip: {
                    name: l10nService.getMessage(globalL10nKeys.clientIpLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        _tmp: [{
                            type: 'address',
                            data: '',
                        }],
                        addrs: [],
                        ranges: [],
                    },
                    stringify(m) {
                        const val = [];

                        if (m.addrs) {
                            _.each(m.addrs, function(item) {
                                val.push(item.addr);
                            });
                        }

                        if (m.ranges) {
                            _.each(m.ranges, function(item) {
                                val.push(`${item.begin.addr}-${item.end.addr}`);
                            });
                        }

                        if (m.prefixes) {
                            _.each(m.prefixes, function(item) {
                                val.push(`${item.ip_addr.addr}/${item.mask}`);
                            });
                        }

                        if (m.group_refs) {
                            _.each(m.group_refs, function(item) {
                                val.push(`group ${item.name()}` || item.slug());
                            });
                        }

                        return `${
                            schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                        } (${val.join(', ')})`;
                    },
                },
                vs_port: {
                    name: l10nService.getMessage(l10nKeys.servicePortMatchLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        ports: [],
                    },
                    stringify(m) {
                        return m.ports.join(', ');
                    },
                },
                protocol: {
                    name: l10nService.getMessage(l10nKeys.protocolTypeMatchLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        protocols: 'HTTP',
                    },
                    stringify(m) {
                        return m.protocols;
                    },
                },
                method: {
                    name: l10nService.getMessage(globalL10nKeys.httpMethodLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        methods: [],
                    },
                    stringify(m) {
                        return `${
                            schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                        } (${_.map(m.methods, function(item) {
                            return item.replace(/^HTTP_METHOD_/g, '');
                        }).join(', ')})`;
                    },
                },
                version: {
                    name: l10nService.getMessage(globalL10nKeys.httpVersionLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        versions: [], // {version: 'ZERO_NINE'}
                        _tmp: {},
                    },
                    init(m) {
                        m._tmp = {};
                        _.each(m.versions, function(v) {
                            m._tmp[v] = true;
                        });
                    },
                    onSubmit(m) {
                        m.versions = [];
                        _.each(m._tmp, function(value, version) {
                            if (value) {
                                m.versions.push(
                                    version,
                                );
                            }
                        });
                    },
                    stringify(m) {
                        return _.map(m.versions, item => {
                            return schemaService.getEnumValue('HTTPVersion', item).label;
                        }).join(', ');
                    },
                },
                path: {
                    name: l10nService.getMessage(globalL10nKeys.pathLabel),
                    default: {
                        match_criteria: 'CONTAINS',
                        match_decoded_string: true,
                        match_str: [],
                        _tmp: [{
                            type: 'custom',
                            data: '',
                        }],
                    },
                    stringify(m) {
                        const val = [];

                        Array.prototype.push.apply(val, m.match_str);

                        _.each(m.string_group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });

                        return `${
                            schemaService.getEnumValue('StringOperation', m.match_criteria).label
                        } (${val.join(', ')})`;
                    },
                },
                query: {
                    name: l10nService.getMessage(globalL10nKeys.queryLabel),
                    default: {
                        match_criteria: 'QUERY_MATCH_CONTAINS',
                        match_decoded_string: true,
                        match_str: [],
                        _tmp: [{
                            type: 'custom',
                            data: '',
                        }],
                    },
                    stringify(m) {
                        const val = [];

                        _.each(m.match_str, function(str) {
                            val.push(`"${str}"`);
                        });

                        _.each(m.string_group_refs, function(item) {
                            val.push(`group ${item.name()}` || item.slug());
                        });

                        return `contains ${val.join(' or ')}`;
                    },
                },
                hdrs: {
                    name: l10nService.getMessage(globalL10nKeys.headersLabel),
                    default: {
                        match_criteria: 'HDR_EXISTS',
                        hdr: '',
                        value: [''],
                    },
                    stringify(hdrs) {
                        return _.map(hdrs, function(hdr) {
                            const { label } = schemaService
                                .getEnumValue('HdrMatchOperation', hdr.match_criteria);

                            return `${hdr.hdr} ${
                                label || hdr.match_criteria
                            }${hdr.match_criteria == 'HDR_CONTAINS' ? ` ${
                                hdr.value.join(' or ')}` : ''}`;
                        }).join(', ');
                    },
                },
                cookie: {
                    name: l10nService.getMessage(globalL10nKeys.cookieLabel),
                    default: {
                        match_criteria: 'HDR_EXISTS',
                        name: '',
                        value: '',
                    },
                    stringify(m) {
                        const { label } = schemaService
                            .getEnumValue('HdrMatchOperation', m.match_criteria);

                        return ` ${m.name} ${
                            (label || m.match_criteria).toLowerCase()
                        } ${m.value}`;
                    },
                },
                host_hdr: {
                    name: l10nService.getMessage(globalL10nKeys.hostHeaderLabel),
                    default: {
                        match_criteria: 'HDR_EQUALS',
                        value: [''],
                    },
                    init(m) {
                        if (m.match_criteria !== 'HDR_EXISTS' && m.match_criteria !==
                            'HDR_DOES_NOT_EXIST' && (!m.value || !m.value.length)) {
                            m.value = [''];
                        }
                    },
                    onSubmit(m) {
                        if (m.match_criteria === 'HDR_EXISTS' ||
                            m.match_criteria === 'HDR_DOES_NOT_EXIST') {
                            delete m.value;
                        }
                    },
                    stringify(m) {
                        return `${
                            schemaService
                                .getEnumValue('HdrMatchOperation', m.match_criteria).label
                        } '${m.value.join('\' or \'')}'`;
                    },
                },
                loc_hdr: {
                    name: l10nService.getMessage(l10nKeys.locationHeaderMatchLabel),
                    default: {
                        match_criteria: 'HDR_EQUALS',
                        value: [''],
                    },
                    init(m) {
                        if (m.match_criteria != 'HDR_EXISTS' &&
                            m.match_criteria != 'HDR_DOES_NOT_EXIST' &&
                            (!m.value || !m.value.length)) {
                            m.value = [''];
                        }
                    },
                    onSubmit(m) {
                        if (m.match_criteria == 'HDR_EXISTS' ||
                            m.match_criteria == 'HDR_DOES_NOT_EXIST') {
                            delete m.value;
                        }
                    },
                    stringify(m) {
                        return `${
                            schemaService
                                .getEnumValue('HdrMatchOperation', m.match_criteria).label
                        } '${m.value.join('\' or \'')}'`;
                    },
                },
                status: {
                    name: l10nService.getMessage(l10nKeys.httpStatusMatchLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        _tmp: '',
                        status_codes: [],
                        ranges: [],
                    },
                    init(m) {
                        m._tmp = m.status_codes.concat(
                            _.map(m.ranges, function(r) {
                                return `${r.begin}-${r.end}`;
                            }),
                        );
                    },
                    onSubmit(m) {
                        m.status_codes = [];
                        m.ranges = [];

                        _.each(`${m._tmp}`.split(','), function(item) {
                            const range = RangeParser.httpStatus2Json(item);

                            if (range) {
                                // assume scope is range
                                if (angular.isObject(range) && 'begin' in range) {
                                    m.ranges.push(range);
                                } else {
                                    m.status_codes.push(range);
                                }
                            }
                        });

                        delete m._tmp;
                    },
                    stringify(m) {
                        const statuses = m.status_codes.concat(
                            _.map(m.ranges, function(r) {
                                return `${r.begin}-${r.end}`;
                            }),
                        );

                        return `${
                            schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                        } (${statuses.join(', ')})`;
                    },
                },
                rsp_hdrs: {
                    name: l10nService.getMessage(l10nKeys.responseHeaderMatchLabel),
                    default: {
                        match_criteria: 'HDR_EXISTS',
                        hdr: '',
                        value: [],
                    },
                    stringify(hdrs) {
                        return _.map(hdrs, function(hdr) {
                            const { label } = schemaService
                                .getEnumValue('HdrMatchOperation', hdr.match_criteria);

                            return `${hdr.hdr} ${
                                label || hdr.match_criteria
                            }${hdr.match_criteria == 'HDR_CONTAINS' ? ` ${
                                hdr.value.join(' or ')}` : ''}`;
                        }).join(', ');
                    },
                },
                source_ip: {
                    name: l10nService.getMessage(l10nKeys.sourceIpMatchLabel),
                    default: {
                        match_criteria: 'IS_IN',
                        _tmp: [{
                            type: 'address',
                            data: '',
                        }],
                        addrs: [],
                        ranges: [],
                    },
                    stringify(m) {
                        const val = [];

                        if (m.addrs) {
                            _.each(m.addrs, function(item) {
                                val.push(item.addr);
                            });
                        }

                        if (m.ranges) {
                            _.each(m.ranges, function(item) {
                                val.push(`${item.begin.addr}-${item.end.addr}`);
                            });
                        }

                        if (m.prefixes) {
                            _.each(m.prefixes, function(item) {
                                val.push(`${item.ip_addr.addr}/${item.mask}`);
                            });
                        }

                        if (m.group_refs) {
                            _.each(m.group_refs, function(item) {
                                val.push(`group ${item.name()}` || item.slug());
                            });
                        }

                        return `${
                            schemaService.getEnumValue('MatchOperation', m.match_criteria).label
                        } (${val.join(', ')})`;
                    },
                },

            };

            /**
             * Definition of actions
             */
            scope.actions = {
                hdr_action: {
                    name: l10nService.getMessage(l10nKeys.modifyHeaderActionLabel),
                    default: [{
                        action: 'HTTP_REMOVE_HDR',
                        isNewAction: true,
                    }],
                    stringify(action) {
                        let output = '';

                        _.each(action, function(hdr) {
                            output += `${hdr.action.replace('HTTP_', '').replace('_', ' ')
                                .toLowerCase()}: `;

                            if (['HTTP_REMOVE_HDR', 'HTTP_REPLACE_HDR', 'HTTP_ADD_HDR'].includes(
                                hdr.action,
                            )) {
                                output += hdr.hdr ? `"${hdr.hdr.name}"` : '';

                                if (hdr.action !== 'HTTP_REMOVE_HDR' && hdr.hdr.value.val) {
                                    output += ` = "${hdr.hdr.value.val}"`;
                                }
                            } else {
                                output += hdr.cookie ? `"${hdr.cookie.name}"` : '';

                                if (hdr.action !== 'HTTP_REMOVE_COOKIE') {
                                    output += ` = "${hdr.cookie.value}"`;
                                }
                            }

                            output += '; ';
                        });

                        return output;
                    },
                    onActionChange(action, actionObj) {
                        actionObj = !_.isUndefined(actionObj) ? actionObj : {};

                        switch (action) {
                            case 'HTTP_REMOVE_HDR':
                                actionObj.value = undefined;
                                break;
                            default:
                                actionObj.value = {
                                    var: '_CUSTOM_VALUE',
                                    val: '',
                                };
                        }

                        return actionObj;
                    },
                },
                loc_hdr_action: {
                    name: l10nService.getMessage(l10nKeys.rewriteLocationHeaderActionLabel),
                    default: {
                        protocol: 'HTTP',
                        port: 80,
                        host: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        path: {
                            type: 'URI_PARAM_TYPE_TOKENIZED',
                            tokens: [],
                        },
                        keep_query: true,
                    },
                    init(action) {
                        let ii,
                            val;

                        if (action.host && action.host.tokens) {
                            val = [];

                            for (ii = 0; ii < action.host.tokens.length; ii++) {
                                val.push(RangeParser.token2str(action.host.tokens[ii]));
                            }

                            action.host.value = val.join('.');
                        }

                        if (action.path && action.path.tokens) {
                            val = [];

                            for (ii = 0; ii < action.path.tokens.length; ii++) {
                                val.push(RangeParser.token2str(action.path.tokens[ii]));
                            }

                            if (val[0] === '' && val.length > 1) {
                                val.splice(0, 1);
                                action.path.value = val.join('/');
                            } else if (val[0] === '' && val.length === 1) {
                                action.path.value = '/';
                            } else {
                                action.path.value = val.join('/');
                            }
                        }
                    },
                    onSubmit(action) {
                        // Parse host
                        if (action.host && action.host.value && action.host.value !== '') {
                            action.host = {
                                type: 'URI_PARAM_TYPE_TOKENIZED',
                                tokens: RangeParser.tokensStr2tokens(action.host.value, '.'),
                            };
                        } else {
                            delete action.host;
                        }

                        // Parse path
                        if (action.path && action.path.value && action.path.value !== '') {
                            action.path = {
                                type: 'URI_PARAM_TYPE_TOKENIZED',
                                tokens: RangeParser.tokensStr2tokens(action.path.value, '/'),
                            };
                        } else {
                            delete action.path;
                        }
                    },
                    stringify(a) {
                        // Stringify tokens
                        let host = '',
                            path = '';

                        if (a.host && a.host.tokens.length) {
                            host = _.map(a.host.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('.');
                        }

                        if (a.path && a.path.tokens.length) {
                            path = _.map(a.path.tokens, function(item) {
                                return RangeParser.token2str(item);
                            }).join('/');
                        }

                        return `${a.protocol}://${host !== '' ? host : '<ExistingHost>'
                        }${'port' in a ? `:${a.port}` : ''
                        }/${a.path && a.path.tokens.length ? path : '<ExistingPath>'
                        }?${a.keep_query ? '<KeepQueryString>' : '<RemoveQueryString>'}`;
                    },
                },
            };

            scope.onLogTypeChange = function(switchedTo) {
                const currentRule = scope.current;

                switch (switchedTo) {
                    case 'with-headers':
                        currentRule.log = true;
                        currentRule.all_headers = true;
                        break;

                    case 'on':
                        currentRule.log = true;
                        currentRule.all_headers = false;
                        break;

                    default:
                        currentRule.log = false;
                        currentRule.all_headers = false;
                }
            };

            scope.matchKeys = Object.keys(scope.matches);
            scope.actionKeys = Object.keys(scope.actions);

            scope.httpResponsePolicyGridConfig = new PolicyGridConfig({
                collection: scope.httpResponsePolicy,
                controls: {
                    create: {
                        title: l10nService.getMessage(globalL10nKeys.createRuleLabel),
                        do: () => {
                            const { rows } = scope.httpResponsePolicy;
                            const index = angular.isArray(rows) ?
                                rows.length && rows[rows.length - 1].index || 0 :
                                0;

                            scope.addRule({
                                index,
                                position: 'below',
                            });
                        },
                    },
                },
                actions: {
                    createAt: ({ index }, position) => scope.addRule({
                        index,
                        position,
                    }),
                },
                singleactions: [{
                    title: l10nService.getMessage(globalL10nKeys.deleteLabel),
                    class: 'icon icon-trash',
                    do: rule => scope.httpResponsePolicy.delete(rule),
                }, {
                    title: l10nService.getMessage(globalL10nKeys.menuLabel),
                    template: require(
                        '../../components/applications/virtualservice/policy/' +
                        'policy-grid/policy-grid-menu.tooltip.partial.html',
                    ),
                    edit: rule => scope.editRule(rule),
                    move: rule => {
                        const rules = scope.httpResponsePolicy.rows;

                        AviConfirmService
                            .prompt('policy-grid-prompt-index', {
                                rule,
                                rules,
                            })
                            .then(data => scope.httpResponsePolicy.moveRule(rule, data));
                    },
                    duplicate: rule => {
                        const rules = scope.httpResponsePolicy.rows;

                        AviConfirmService
                            .prompt('policy-grid-prompt-index', { rules })
                            .then(data => scope.duplicateRule(rule, data));
                    },
                }],
            });
        },
    };
}]);
