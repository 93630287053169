/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function eventListCollDataSourceFactory(ListCollDataSource) {
    class EventListCollDataSource extends ListCollDataSource {
        // only difference: 'orderby' instead of 'sort'
        /** @override */
        setSortParam(propertyName) {
            if (propertyName && typeof propertyName === 'string') {
                if (this.params_['orderby'] && this.params_['orderby'] === propertyName) {
                    if (this.params_['orderby'].indexOf('-') === 0) {
                        this.params_['orderby'] = this.params_['orderby'].slice(1);
                    } else {
                        this.params_['orderby'] = `-${this.params_['orderby']}`;
                    }
                } else {
                    this.params_['orderby'] = propertyName;
                }
            } else {
                delete this.params_['orderby'];
            }

            return true;
        }

        /** @override */
        getSortParam() {
            return this.params_['orderby'];
        }

        /** @override */
        processConfigResponse_(resp, requestParams) {
            const { groupby } = requestParams;

            //isAccurate_ flag is set to true if percent_remaining = 0, else set to false.
            this.owner_.isAccurate_ = resp.data.isAccurate;

            if (groupby) {
                const { results } = resp.data;

                results.forEach(res => {
                    const { config } = res;

                    config.value = config[groupby];
                    config.groupby = groupby;
                });
            }

            return super.processConfigResponse_(resp, requestParams);
        }
    }

    return EventListCollDataSource;
}

eventListCollDataSourceFactory.$inject = ['ListCollDataSource'];

/**
 * @ngdoc factory
 * @name EventListCollDataSource
 */
angular.module('aviApp').factory('EventListCollDataSource', eventListCollDataSourceFactory);
