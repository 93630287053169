/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { IpamDnsProfileModalComponent }
    from 'ng/modules/ipam/components/ipam-dns-profile-modal/ipam-dns-profile-modal.component';
import { IPAM_PROFILE_ITEM_TOKEN } from '../ipam.tokens';

/**
 * @description IpamProfile Collection class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'ipamdnsproviderprofile',
            windowElement: IpamDnsProfileModalComponent,
            restrictCreateOnEssentialLicense: false,
            ...args,
        };

        super(extendedArgs);

        this.serverDefaultsOverride_ = {
            type: 'IPAMDNS_TYPE_INTERNAL',
        };

        this.itemClass_ = this.getAjsDependency_(IPAM_PROFILE_ITEM_TOKEN);
    }
}

IpamProfileCollection.ajsDependencies = [
    IPAM_PROFILE_ITEM_TOKEN,
];
