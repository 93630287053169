/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-application-rules-list-header';
const prefix = `${moduleName}.${componentName}`;

export const applicationRulesTitle = `${prefix}.applicationRulesTitle`;
export const registerControllerMessage = `${prefix}.registerControllerMessage`;
export const registerControllerButtonLabel = `${prefix}.registerControllerButtonLabel`;
export const enableAppRulesOptInMessage = `${prefix}.enableAppRulesOptInMessage`;
export const editCloudServicesSettingsButtonLabel = `${prefix}.editCloudServicesSettingsButtonLabel`;

export const ENGLISH = {
    [applicationRulesTitle]: 'Application Rules',
    [registerControllerMessage]: 'Register your controller and easily stay current with Application Rules.',
    [registerControllerButtonLabel]: 'Register Controller',
    [enableAppRulesOptInMessage]: 'Keep Application Rules up-to-date by enabling Application Signature opt-in in Cloud Services.',
    [editCloudServicesSettingsButtonLabel]: 'Edit Cloud Services Settings',
};
