/**
 * @module PoolModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { IpAddrPrefixConfigItem } from 'ajs/modules/data-model/factories';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IPlacementNetwork } from 'generated-types';

type TPlacementNetwork = Omit<IPlacementNetwork, 'subnet'>;

interface IPlacementNetworkConfig extends TPlacementNetwork {
    subnet: IpAddrPrefixConfigItem;
}

/**
 * Ajs dependency token for PoolGroup.
 */
export const PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN = 'PlacementNetworkConfigItem';

/**
 * @description PlacementNetwork ConfigItem.
 *
 * @author Rachit Aggarwal
 */
export class PlacementNetworkConfigItem extends MessageItem<IPlacementNetworkConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'PlacementNetwork',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Reset subnet fields.
     */
    public resetSubnet(): void {
        this.setNewChildByField('subnet');
    }

    /**
     * @override
     */
    protected canFlatten(): boolean {
        const { subnet } = this.config;

        return subnet.canFlatten();
    }
}
