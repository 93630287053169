/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'custom-params-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const modalTitleNew = `${prefix}.modalTitleNew`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const valueInputLabel = `${prefix}.valueInputLabel`;
export const valueInputPlaceholder = `${prefix}.valueInputPlaceholder`;
export const sensitiveCheckboxLabel = `${prefix}.sensitiveCheckboxLabel`;
export const dynamicCheckboxLabel = `${prefix}.dynamicCheckboxLabel`;

export const ENGLISH = {
    [modalTitleEdit]: 'Edit Custom Param',
    [modalTitleNew]: 'New Custom Param',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [valueInputLabel]: 'Value',
    [valueInputPlaceholder]: 'Enter Value',
    [sensitiveCheckboxLabel]: 'Is Sensitive',
    [dynamicCheckboxLabel]: 'Is Dynamic',
};
