/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc factory
 * @name wafExceptionState
 * @description
 *     Service used to keep track of selected exceptions to be eventually added to an existing
 *     WafPolicy.
 */
const defaultSelectedExceptionsState = {
    group: {},
    rule: {},
};

const wafExceptionStateFactory = (Base, WafExcludeListEntryConfigItem) => {
    class WafExceptionState extends Base {
        constructor(args) {
            super(args);

            /**
             * Hash of selected exceptions. Structure is:
             * this._selectedExceptions = {
             *     group: {
             *         'group_name': [] //Array of exceptions
             *     },
             *     rule: {
             *         'rule_id': [] //Array of exceptions
             *     }
             * }
             */
            this._selectedExceptions = angular.copy(defaultSelectedExceptionsState);
        }

        /**
         * Adds an exception to the selected exceptions, to be shown in the preview.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Group name or Rule ID.
         * @param {Object} exception - Exception containing subnet, path, and match element.
         */
        addException(type, id, exception) {
            const idHash = this._selectedExceptions[type];

            idHash[id] = idHash[id] || [];
            idHash[id].push(exception);

            this.trigger('exceptionAdded');
            this.trigger('exceptionsChanged');
        }

        /**
         * Removes an exception from the selected exceptions.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Group name or Rule ID.
         * @param {Object} exceptionToRemove - Exception containing subnet, path, and match
         *     element.
         */
        removeException(type, id, exceptionToRemove) {
            const idHash = this._selectedExceptions[type];

            if (_.isEmpty(idHash) || _.isEmpty(idHash[id])) {
                return;
            }

            const exceptions = idHash[id];

            const indexToRemove = WafExcludeListEntryConfigItem.hasMatchingException(
                exceptionToRemove,
                exceptions,
            );

            if (indexToRemove > -1) {
                exceptions.splice(indexToRemove, 1);

                if (!exceptions.length) {
                    delete idHash[id];
                }

                this.trigger('exceptionRemoved');
                this.trigger('exceptionsChanged');
            }
        }

        /**
         * Removes all exceptions from the selected exceptions.
         */
        removeAllExceptions() {
            const hadExceptions = this.hasSelectedExceptions();

            this._selectedExceptions = angular.copy(defaultSelectedExceptionsState);

            if (hadExceptions) {
                this.trigger('exceptionRemoved');
                this.trigger('exceptionsChanged');
            }
        }

        /**
         * Returns the selected exceptions grouped by the type.
         * @return {Object}
         */
        getGroupedSelectedExceptions() {
            const output = {};

            Object.keys(this._selectedExceptions).forEach(type => {
                if (!_.isEmpty(this._selectedExceptions[type])) {
                    output[type] = [];

                    const idHash = this._selectedExceptions[type];

                    Object.keys(idHash).forEach(id => {
                        const exceptions = idHash[id];
                        const exceptionProps = {
                            id,
                            type,
                            exceptions: angular.copy(exceptions),
                        };

                        output[type].push(exceptionProps);
                    });
                }
            });

            return output;
        }

        /**
         * Returns true if the selected exceptions hash is populated.
         * @return {boolean}
         */
        hasSelectedExceptions() {
            return _.any(Object.keys(this._selectedExceptions), type => {
                return !_.isEmpty(this._selectedExceptions[type]);
            });
        }

        /**
         * Returns true if the exception already exists in the selected exceptions.
         * @param {string} type - Either 'group' or 'rule'.
         * @param {string} id - Group name or Rule ID.
         * @param {Object} exception - Exception containing subnet, path, and match
         * @return {boolean}
         */
        exceptionAlreadyExists(type, id, exception) {
            const idHash = this._selectedExceptions[type];
            const exceptions = idHash[id];

            if (angular.isUndefined(exceptions) || !exceptions.length) {
                return false;
            }

            return WafExcludeListEntryConfigItem.hasMatchingException(exception, exceptions) > -1;
        }
    }

    return new WafExceptionState();
};

wafExceptionStateFactory.$inject = [
    'Base',
    'WafExcludeListEntryConfigItem',
];

angular.module('aviApp').factory('wafExceptionState', wafExceptionStateFactory);
