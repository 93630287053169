/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { GeoDbFileConfigItem } from 'ajs/modules/geo-db';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { FileObjectCollection } from 'ajs/modules/system';
import { FileType } from 'generated-types';

import * as l10n from './geo-db-file-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TFileObjectCollection = typeof FileObjectCollection;

/**
 * @description Component for Geo Db file modal.
 *
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'geo-db-file-modal',
    templateUrl: './geo-db-file-modal.component.html',
})
export class GeoDbFileModalComponent implements OnInit {
    /**
     * GeoDbFile instance.
     */
    @Input()
    public editable: GeoDbFileConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<GeoDbFileConfigItem>();

    /**
     * GeoDbFile object type.
     */
    public objectType: string;

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for Geo Db file modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Vendor dropdown options.
     */
    public vendorOptions: IAviDropdownOption[] = [];

    /**
     * FileObjectCollection instance.
     */
    public fileObjectCollection: FileObjectCollection;

    constructor(
        schemaService: SchemaService,
        @Inject(FileObjectCollection)
        FileObjectCollection: TFileObjectCollection,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const vendorEnumValues = schemaService.getEnumValues('GeoDBFileVendor');

        this.vendorOptions = createOptionsFromEnumProps(vendorEnumValues);

        this.fileObjectCollection = new FileObjectCollection({
            params: {
                type: FileType.GEO_DB,
            },
        });
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.fileObjectCollection.destroy();
    }
}
