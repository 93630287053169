/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './chartValueController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

//Controller like a factory for two directives on server's page:
//serverPercentArcChart & serverPercentPileChart, cellSparkline and sparklineCard
//TODO remove with refactored chartConfig
angular.module('aviApp').factory('chartValueController', [
'myAccount', 'DisplayValue', 'GraphSync', 'seriesDataPointService', 'l10nService',
function(myAccount, DisplayValue, GraphSync, seriesDataPointService, l10nService) {
    return function chartValueController(scope) {
        scope.myAccount = myAccount;
        scope.GraphSync = GraphSync;
        scope.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);

        const updateValue = () => {
            const displayValueType = myAccount.getValuesDisplayType();
            const { value, unit } = DisplayValue.createCardValueUnit(scope.series,
                displayValueType);

            scope.value = value;
            scope.unit = unit;

            if (scope.errorSeries) {
                scope.errorValue = DisplayValue.createCardValueUnit(
                    scope.errorSeries, displayValueType,
                ).value;
            }
        };

        // Some charts (like sparklines) use a config to determine their values, others pass a
        // series and skip this.

        //TODO some of sparkline card doesn't update on timeframe switching
        //Don't really understand why card.metric !== item.getMetric(card.id)
        if (angular.isUndefined(scope.series)) {
            scope.$watchGroup(
                [
                    'card.metric',
                    'card.metric.getMainSeries()',
                ], ([metric, mainSeries]) => {
                    scope.series = mainSeries || null;

                    const errorTotal = metric && metric.getSeriesByType('errorTotal');

                    scope.errorSeries = errorTotal || null;
                },
            );
        }
        // otherwise series is passed explicitly

        scope.$watch(() => {
            const { series } = scope;
            const firstDataPoint = series && series.getFirstPoint() || null;
            const latestDataPoint = series && series.getLatestPoint(true) || null;

            const firstDataPointHash =
                seriesDataPointService.getDataPointHashString(firstDataPoint);

            const latestDataPointHash =
                seriesDataPointService.getDataPointHashString(latestDataPoint);

            return [
                firstDataPointHash,
                latestDataPointHash,
                myAccount.getValuesDisplayType(),
                GraphSync.mouseOnGraph,
                GraphSync.stuck,
            ].join('|');
        }, updateValue);
    };
}]);
