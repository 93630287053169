/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IServiceMatch } from 'generated-types';
import { ServiceMatch } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    L4RuleProtocolMatchConfigItem,
} from 'ajs/modules/l4-policy/factories/matches/l4-rule-protocol-match.config-item.factory';

import type { PortMatchConfigItem } from './port-match.config-item.factory';

/**
 * Type of Service Mtach Partial omitting source_port, destination_port, protocol.
 */
type TServiceMatchPartial = Omit<IServiceMatch, 'source_port' | 'destination_port' | 'protocol'>;

/**
 * Service Match Config interface.
 */
interface IServiceMatchConfig extends TServiceMatchPartial {
    source_port?: PortMatchConfigItem;
    destination_port?: PortMatchConfigItem;
    protocol?: L4RuleProtocolMatchConfigItem;
}

/**
 * Token for ProtocolMatchConfigItem class.
 */
export const SERVICE_MATCH_CONFIG_ITEM_TOKEN = 'ServiceMatchConfigItem';

/**
 * @description  Service match ConfigItem class.
 * @author Guru Prasad
 */
export class ServiceMatchConfigItem extends MessageItem<IServiceMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: ServiceMatch,
            ...args,
        };

        super(extendedArgs);
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();
    }

    /**
     * Return true if any values have been configured in the service match.
     */
    public hasAnyValue(): boolean {
        const {
            destination_port: destinationPort,
            source_port: sourcePort,
            protocol,
        } = this.config;

        return destinationPort.hasPorts() || sourcePort.hasPorts() || protocol.hasProtocol();
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'destination_port',
            'source_port',
            'protocol',
        ];
    }
}
