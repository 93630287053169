/**
 * @module PoolModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    HttpReselectRespCodeBlock,
    IHTTPReselectRespCode,
    IHTTPStatusRange,
} from 'generated-types';

import { HTTPReselectRespCode } from 'object-types';
import { HTTPStatusRangeConfigItem } from './http-status-range.config-item.factory';

type THTTPReselectRespCodeConfigPartial = Omit<IHTTPReselectRespCode, 'ranges'>;
interface IHTTPReselectRespCodeConfig extends THTTPReselectRespCodeConfigPartial {
    ranges?: RepeatedMessageItem<HTTPStatusRangeConfigItem>,
}

/**
 * @description
 *
 *   HTTPReselectRespCode config item.
 *
 * @author Rachit Aggarwal
 */
export class HTTPReselectRespCodeConfigItem extends MessageItem<IHTTPReselectRespCodeConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HTTPReselectRespCode,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Sets Codes property of server response code.
     */
    public set serverResponseCodes(codes: number[]) {
        this.config.codes = codes;
    }

    /**
     * Sets Ranges property of server response code.
     */
    public set serverResponseCodeRanges(ranges: IHTTPStatusRange[]) {
        this.config.ranges.updateConfig(ranges);
    }

    /**
     * Sets Code block property of server response code.
     */
    public set serverResponseCodeBlock(block: HttpReselectRespCodeBlock[]) {
        this.config.resp_code_block = block;
    }
}
