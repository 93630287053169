/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'data-grid';
const componentName = 'move-data-grid-row';
const prefix = `${moduleName}.${componentName}`;

export const moveLabel = `${prefix}.moveLabel`;
export const placementRadioButtonLabel = `${prefix}.placementRadioButtonLabel`;
export const indexDropdownPlaceholder = `${prefix}.indexDropdownPlaceholder`;
export const defaultItemType = `${prefix}.defaultItemType`;
export const aboveLabel = `${prefix}.aboveLabel`;
export const belowLabel = `${prefix}.belowLabel`;

export const ENGLISH = {
    [moveLabel]: 'Move',
    [placementRadioButtonLabel]: 'Placement',
    [indexDropdownPlaceholder]: 'Select Index',
    [defaultItemType]: 'item',
    [aboveLabel]: 'Above',
    [belowLabel]: 'Below',
};
