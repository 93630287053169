/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CloudType } from 'generated-types';

/**
 * Set of public clouds. Note: BGP Peer Labels are not supported for public clouds.
 */
export const PUBLIC_CLOUDS = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_AWS,
    CloudType.CLOUD_GCP,
    CloudType.CLOUD_OPENSTACK,
]);

/**
 * Hash of SE Usage path values.
 */
export const seUsagePaths = {
    BOTH: 'BOTH',
    SINGLE: 'SINGLE',
    AUTO: 'AUTO',
};

/* eslint-disable */
export const CLOUD_NONE = CloudType.CLOUD_NONE;
export const CLOUD_VCENTER = CloudType.CLOUD_VCENTER;
export const CLOUD_OPENSTACK = CloudType.CLOUD_OPENSTACK;
export const CLOUD_AWS = CloudType.CLOUD_AWS;
export const CLOUD_VCA = CloudType.CLOUD_VCA;
export const CLOUD_LINUXSERVER = CloudType.CLOUD_LINUXSERVER;
export const CLOUD_AZURE = CloudType.CLOUD_AZURE;
export const CLOUD_GCP = CloudType.CLOUD_GCP;
export const CLOUD_NSXT = CloudType.CLOUD_NSXT;
/* eslint-enable */
export const DNS_RESOLVERS = 'dns_resolvers';
export const CUSTOM_TAGS = 'custom_tags';
export const PROXY_CONFIGURATION = 'proxy_configuration';

/**
 * Strings used as field names in cloud item.
 */
export const IPAM_PROVIDER_REF_DATA = 'ipam_provider_ref_data';
export const TYPE = 'type';

/**
 * Set of Cloud types allowing network creation.
 */
export const cloudsAllowingNetworkCreation = new Set([
    CloudType.CLOUD_LINUXSERVER,
    CloudType.CLOUD_GCP,
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_NSXT,
]);

/**
 * Set of Cloud types having custom VRF Context.
 */
export const cloudsAllowingCustomVrfContext = new Set([
    CloudType.CLOUD_VCENTER,
    CloudType.CLOUD_LINUXSERVER,
    CloudType.CLOUD_NONE,
    CloudType.CLOUD_OPENSTACK,
    CloudType.CLOUD_NSXT,
]);

/**
 * Set of Cloud types that use macroStack for creation.
 */
export const macroStackTypes = new Set([
    CloudType.CLOUD_NSXT,
]);

/**
 * Hash of field names containing config items tied to cloud types.
 */
export const configItemFieldsHash = {
    [CloudType.CLOUD_NSXT]: 'nsxt_configuration',
    [CloudType.CLOUD_LINUXSERVER]: 'linuxserver_configuration',
    [CloudType.CLOUD_AZURE]: 'azure_configuration',
    [CloudType.CLOUD_AWS]: 'aws_configuration',
    [CloudType.CLOUD_VCENTER]: 'vcenter_configuration',
    [CloudType.CLOUD_GCP]: 'gcp_configuration',
    [CloudType.CLOUD_OPENSTACK]: 'openstack_configuration',
};

/**
 * Set of Clouds with Custom Tags.
 */
export const cloudsWithCustomTags = new Set([
    CloudType.CLOUD_AZURE,
    CloudType.CLOUD_AWS,
    CloudType.CLOUD_GCP,
    CloudType.CLOUD_VCENTER,
]);

/**
 * Hash of cloud vtypes to cloud configuration objects.
 * TODO: Remove this once confirmed CLOUD_VCA is no more applicable as all the other Cloud types
 * are already covered in configItemFieldHash.
 */
export const vtypeConfigHash = {
    [CloudType.CLOUD_VCA]: 'vca_configuration',
    ...configItemFieldsHash,
};
