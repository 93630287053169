/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { IAuthProfileConfig } from 'ajs/modules/security';
import { IHttpResponse } from 'angular';

import {
    IAuthProfileVerifyLdapApiResponse,
    IAuthProfileVerifyLdapConfig,
} from 'ng/modules/security/services/auth-profile-verify-ldap-modal.service.types';

import {
    AuthProfileVerifyLdapModalService,
} from 'ng/modules/security/services/auth-profile-verify-ldap-modal.service';

import './auth-profile-verify-ldap-modal.component.less';
import * as l10n from './auth-profile-verify-ldap-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

enum AdminSearchType {
    USER = 'user',
    GROUP = 'group',
    NONE = 'none',
}

enum BindType {
    ADMIN = 'admin',
    ANON = 'anon',
}

/**
 * @description Component for Auth Profile Verify LDAP modal.
 *
 * @author Suraj Kumar
 */
@Component({
    selector: 'auth-profile-verify-ldap-modal',
    templateUrl: './auth-profile-verify-ldap-modal.component.html',
    providers: [
        AuthProfileVerifyLdapModalService,
    ],
})
export class AuthProfileVerifyLdapModalComponent implements OnInit {
    /**
     * Auth profile's config.
     */
    @Input()
    public authProfileConfig: IAuthProfileConfig;

    /**
     * Config for verify LDAP API call request.
     */
    public config: IAuthProfileVerifyLdapConfig;

    /**
     * Stores mode selected.
     */
    public mode: string;

    /**
     * Used to show results if any.
     */
    public results: string;

    /**
     * Used to specify search type.
     */
    public search = AdminSearchType.USER;

    /**
     * Used to show error messages if any.
     */
    public errors: string;

    /**
     * Busy flag for rendering a spinner.
     */
    public busy = false;

    /**
     * Property holding AdminSearchType ENUM.
     */
    public readonly adminSearchType = AdminSearchType;

    /**
     * Property holding BindType ENUM.
     */
    public readonly bindType = BindType;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for verify LDAP modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
        private readonly authProfileVerifyLdapModalService: AuthProfileVerifyLdapModalService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.config = {
            profile: this.authProfileConfig.name,
        };
        this.mode = this.authProfileConfig.ldap.config.bind_as_administrator ?
            BindType.ADMIN : BindType.ANON;
    }

    /**
     * Function to close verify LDAP modal.
     */
    public dismissModal(): void {
        this.authProfileVerifyLdapModalService.closeModal();
    }

    /**
     * Make necesssary settings for API call for result.
     */
    public verifyLDAP(): void {
        const api = this.mode === BindType.ADMIN ? '/api/ldap-test-admin' : '/api/ldap-test-user';

        this.setSearchParams();

        this.busy = true;
        this.errors = undefined;
        this.results = undefined;
        this.config.uuid = this.authProfileConfig.uuid;

        this.fetchVerifyLdapCredentials(this.config, api);
    }

    /**
     * Getter for checking if the admin mode is selected for binding.
     */
    public get hasModeAdmin(): boolean {
        return this.mode === this.bindType.ADMIN;
    }

    /**
     * Getter for checking if the anonymous mode is selected for binding.
     */
    public get hasModeAnonymous(): boolean {
        return this.mode === this.bindType.ANON;
    }

    /**
     * Set's config payload properties based on search-type-related radio buttons.
     */
    private setSearchParams(): void {
        this.config.usersearch = false;
        this.config.groupsearch = false;

        switch (this.search) {
            case 'user':
                this.config.usersearch = true;
                break;
            case 'group':
                this.config.groupsearch = true;
                break;
        }
    }

    /**
     * Function to make API call for fetching verify LDAP result.
     */
    private fetchVerifyLdapCredentials(config: IAuthProfileVerifyLdapConfig, api: string): void {
        this.busy = true;
        this.authProfileVerifyLdapModalService.fetch(config, api)
            .then(({ data }: IHttpResponse<IAuthProfileVerifyLdapApiResponse>) => {
                this.results = data.output;
            }).catch(({ data }) => {
                this.errors = data;

                if (data.output) {
                    this.results = data.output;
                }
            }).finally(() => this.busy = false);
    }
}
