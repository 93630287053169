/**
 * Module containing L4 Policy components.
 * @preferred
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { MatchModule } from 'ng/modules/match/match.module';
import { l4policySetCollectionProvider } from './ajs-upgraded-providers';

import {
    L4PolicyComponent,
    L4PolicySetModalComponent,
    L4RuleModalComponent,
    L4RulePortMatchComponent,
    L4RulePortMatchReadonlyComponent,
    L4RuleProtocolMatchReadonlyComponent,
    L4RuleSelectPoolActionComponent,
} from '.';

const components = [
    L4PolicyComponent,
    L4PolicySetModalComponent,
    L4RuleModalComponent,
    L4RulePortMatchComponent,
    L4RulePortMatchReadonlyComponent,
    L4RuleProtocolMatchReadonlyComponent,
    L4RuleSelectPoolActionComponent,
];

@NgModule({
    declarations: [
        ...components,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        DataGridModule,
        SharedModule,
        MatchModule,
    ],
    providers: [
        l4policySetCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class L4PolicyModule {}
