/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RateLimiterAction } from 'object-types';

import {
    IHTTPLocalFile,
    IRateLimiterAction,
    RateLimiterActionType,
} from 'generated-types';

import { HTTPRedirectActionConfigItem } from './http-redirect-action.config-item.factory';

type TRateLimiterActionPartial = Omit<IRateLimiterAction, 'redirect' | 'file'>;

interface IRateLimiterActionConfig extends TRateLimiterActionPartial{
    redirect: HTTPRedirectActionConfigItem;
    file: MessageItem<IHTTPLocalFile>;
}

/**
 * @description Rate Limiter Action ConfigItem.
 *
 * @author Nisar Nadaf
 */
export class RateLimiterActionConfigItem extends MessageItem<IRateLimiterActionConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: RateLimiterAction,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * updated rate limiter action type and create/delete respective fields.
     */
    public changeActionType(type: RateLimiterActionType): void {
        this.config.type = type;

        delete this.config.redirect;
        delete this.config.status_code;
        delete this.config.file;

        if (type === RateLimiterActionType.RL_ACTION_LOCAL_RSP) {
            this.safeSetNewChildByField('file');
        } else if (type === RateLimiterActionType.RL_ACTION_REDIRECT) {
            this.safeSetNewChildByField('redirect');
        }
    }

    /**
     * @override
     * Handle scenario when profile is created using CLI and optional fields required for modal
     * weren't created.
     */
    protected modifyConfigDataAfterLoad(): void {
        const { type } = this.config;

        super.modifyConfigDataAfterLoad();

        if (type === RateLimiterActionType.RL_ACTION_LOCAL_RSP) {
            this.safeSetNewChildByField('file');
        } else if (type === RateLimiterActionType.RL_ACTION_REDIRECT) {
            this.safeSetNewChildByField('redirect');
        }
    }

    /**
     * @override
     * Remove configs which are not needed if none action is selected.
     * If user create new rate profile and does not change default action.
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { type } = this.config;

        if (type === RateLimiterActionType.RL_ACTION_NONE) {
            delete this.config.redirect;
            delete this.config.status_code;
            delete this.config.file;
        }
    }
}
