/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AviPermissionResource,
    IBotMapping,
} from 'generated-types';
import { withFullModalMixin } from 'ajs/utils/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withEditChildMessageItemMixin } from
    'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';
import { BotMappingModalComponent } from
    'ng/modules/bot/components/bot-mapping-modal/bot-mapping-modal.component';
import { BotMappingRuleConfigItem } from 'ajs/modules/bot-detection-policy';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { BotMapping } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bot-mapping.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TBotMappingItem = Omit<IBotMapping, 'mapping_rules'>;

interface IBotMappingItemConfig extends TBotMappingItem {
    mapping_rules: RepeatedMessageItem<BotMappingRuleConfigItem>,
}

interface IBotMappingItemData {
    config: IBotMappingItemConfig;
}

/**
 * @author Akul Aggarwal
 */
export class BotMappingItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * Needed due to legacy Item code.
     */
    public data: IBotMappingItemData;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botmapping',
            objectType: BotMapping,
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotMappingModalComponent,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get mapping rules.
     */
    public get botMappingRules(): RepeatedMessageItem<BotMappingRuleConfigItem> {
        return this.config.mapping_rules;
    }

    /**
     * Convenience method to get mapping rules config.
     */
    public get botMappingRulesConfig(): BotMappingRuleConfigItem[] {
        return this.botMappingRules.config;
    }

    /**
     * Returns the number of mapping_rules in the config.
     */
    public getRulesCount(): number {
        return this.botMappingRules.count;
    }

    /**
     * Triggers add BotAllowRule.
     */
    public addRule(): void {
        this.addChildMessageItem({
            field: 'mapping_rules',
            modalBindings: {
                isEditing: false,
            },
        });
    }

    /**
     * Triggers edit for BotAllowRule.
     */
    public editRule(rule: BotMappingRuleConfigItem): void {
        this.editChildMessageItem({
            field: 'mapping_rules',
            messageItem: rule,
            modalBindings: {
                isEditing: true,
            },
        });
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.botMappingBreadcrumbTitle);
    }
}
