/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'file';
const prefix = `${moduleName}.${componentName}`;

export const noFileInputPlaceholder = `${prefix}.noFileInputPlaceholder`;
export const uploadFileLabel = `${prefix}.uploadFileLabel`;

export const ENGLISH = {
    [noFileInputPlaceholder]: 'No File Selected',
    [uploadFileLabel]: 'Upload File',
};
