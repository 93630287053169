/**
 * Accounts module
 * @module AccountsModule
 * @preferred
 */
/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    RoleCollection,
    RoleFilterConfigItem,
    RoleItem,
    TenantCollection,
    TenantItem,
    UserCollection,
    UserItem,
    UserProfileCollection,
    UserProfileItem,
} from './factories';

import {
    authenticationTenantRoleMappingController,
    authenticationTypeController,
} from './controllers';

import {
    PasswordGeneratorService,
    RoleService,
    TenantSettingsService,
} from './services';

import {
    PASSWORD_GENERATOR_SERVICE_TOKEN,
    ROLE_COLLECTION_TOKEN,
    ROLE_FILTER_CONFIG_ITEM_TOKEN,
    ROLE_ITEM_TOKEN,
    ROLE_SERVICE_TOKEN,
    TENANT_COLLECTION_TOKEN,
    TENANT_ITEM_TOKEN,
    TENANT_SETTINGS_SERVICE_TOKEN,
    USER_COLLECTION_TOKEN,
    USER_ITEM_TOKEN,
    USER_PROFILE_COLLECTION_TOKEN,
    USER_PROFILE_ITEM_TOKEN,
} from './accounts.tokens';

const accountsModule = angular.module('avi/accounts');

const controllers = [
    {
        controller: authenticationTypeController,
        name: 'AuthenticationTypeController',
    },
    {
        controller: authenticationTenantRoleMappingController,
        name: 'AuthenticationTenantRoleMappingController',
    },
];

const services = [
    {
        service: RoleService,
        name: ROLE_SERVICE_TOKEN,
    },
    {
        service: TenantSettingsService,
        name: TENANT_SETTINGS_SERVICE_TOKEN,
    },
    {
        service: PasswordGeneratorService,
        name: PASSWORD_GENERATOR_SERVICE_TOKEN,
    },
];

const factories = [
    {
        factory: UserProfileItem,
        name: USER_PROFILE_ITEM_TOKEN,
    },
    {
        factory: UserProfileCollection,
        name: USER_PROFILE_COLLECTION_TOKEN,
    },
    {
        factory: TenantItem,
        name: TENANT_ITEM_TOKEN,
    },
    {
        factory: TenantCollection,
        name: TENANT_COLLECTION_TOKEN,
    },
    {
        factory: RoleItem,
        name: ROLE_ITEM_TOKEN,
    },
    {
        factory: RoleCollection,
        name: ROLE_COLLECTION_TOKEN,
    },
    {
        factory: RoleFilterConfigItem,
        name: ROLE_FILTER_CONFIG_ITEM_TOKEN,
    },
    {
        factory: UserItem,
        name: USER_ITEM_TOKEN,
    },
    {
        factory: UserCollection,
        name: USER_COLLECTION_TOKEN,
    },
];

controllers.forEach(({ name, controller }) => accountsModule.controller(name, controller));

services.forEach(({ name, service }) => {
    initAjsDependency(accountsModule, 'service', name, service);
});

factories.forEach(({ name, factory }) => {
    initAjsDependency(accountsModule, 'factory', name, factory);
});
