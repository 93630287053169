/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'notification';
const componentName = 'cloud-token';
const prefix = `${moduleName}.${componentName}`;

export const tokenGeneratedMessage = `${prefix}.tokenGeneratedMessage`;
export const clusterUuid = `${prefix}.clusterUuid`;

export const ENGLISH = {
    [tokenGeneratedMessage]: 'This is a unique token generated for use with one Service Engine.' +
        'Use this token in the <strong>AVICNTRL_AUTHTOKEN</strong> field of metadata when creating the Service Engine.' +
        'The Service Engine created using this token will be a member of tenant <strong>{0}</strong> in cloud <strong>{1}</strong>.' +
        'Token expires after an hour.',
    [clusterUuid]: 'Cluster UUID: <strong>{0}</strong>',
};
