/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'virtualservice-scale-in';
const prefix = `${moduleName}.${componentName}`;

export const scaleInHeader = `${prefix}.scaleInHeader`;
export const selectVipAddressToScaleInInputLabel = `${prefix}.selectVipAddressToScaleInInputLabel`;
export const selectVipAddressToScaleInInputPlaceholder = `${prefix}.selectVipAddressToScaleInInputPlaceholder`;
export const selectServiceEngineToScaleInInputHint = `${prefix}.selectServiceEngineToScaleInInputHint`;
export const selectServiceEngineToScaleInInputLabel = `${prefix}.selectServiceEngineToScaleInInputLabel`;
export const selectAutomaticallyInputPlaceholder = `${prefix}.selectAutomaticallyInputPlaceholder`;
export const primaryDataLabel = `${prefix}.primaryDataLabel`;
export const scaleInBtnLabel = `${prefix}.scaleInBtnLabel`;

export const ENGLISH = {
    [scaleInHeader]: 'Scale In: {0}',
    [selectVipAddressToScaleInInputLabel]: 'Select VIP Address to scale in',
    [selectVipAddressToScaleInInputPlaceholder]: 'Select VIP Address',
    [selectServiceEngineToScaleInInputHint]: 'This VIP address will be removed from the selected Service Engine. If Primary is selected, one of the existing Secondaries will become the new Primary.',
    [selectServiceEngineToScaleInInputLabel]: 'Please select a Service Engine to scale in',
    [selectAutomaticallyInputPlaceholder]: '(Select Automatically)',
    [primaryDataLabel]: '{0} (Primary)',
    [scaleInBtnLabel]: 'Scale In',
};
