/**
 * @module TooltipModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ConnectedPosition } from '@angular/cdk/overlay';

export enum SignpostPosition {
    LEFT_POSITION = 'LEFT_POSITION',
    RIGHT_POSITION = 'RIGHT_POSITION',
    BOTTOM_RIGHT_POSITION = 'BOTTOM_RIGHT_POSITION',
    BOTTOM_LEFT_POSITION = 'BOTTOM_LEFT_POSITION',
    TOP_RIGHT_POSITION = 'TOP_RIGHT_POSITION',
    TOP_LEFT_POSITION = 'TOP_LEFT_POSITION',
}

const CENTER = 'center';
const TOP = 'top';
const BOTTOM = 'bottom';
const START = 'start';
const END = 'end';

export const SIGNPOST_LEFT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: -15,
    originX: START,
    originY: CENTER,
    overlayX: END,
    overlayY: CENTER,
};

export const SIGNPOST_RIGHT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: 15,
    originX: END,
    originY: CENTER,
    overlayX: START,
    overlayY: CENTER,
};

export const SIGNPOST_TOP_LEFT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: -15,
    offsetY: 40,
    originX: START,
    originY: TOP,
    overlayX: END,
    overlayY: BOTTOM,
};

export const SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: -15,
    offsetY: -40,
    originX: START,
    originY: BOTTOM,
    overlayX: END,
    overlayY: TOP,
};

export const SIGNPOST_TOP_RIGHT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: 15,
    offsetY: 40,
    originX: END,
    originY: TOP,
    overlayX: START,
    overlayY: BOTTOM,
};

export const SIGNPOST_BOTTOM_RIGHT_CONNECTED_POSITION: ConnectedPosition = {
    offsetX: 15,
    offsetY: -40,
    originX: END,
    originY: BOTTOM,
    overlayX: START,
    overlayY: TOP,
};

// map from ConnectedPostion, which are usually used as position priorities passed to avi-tooltip,
// to SignpostPostions, which are passed to Signpost component to decide its real position
export const signpostPositionMap = new Map([
    [SIGNPOST_LEFT_CONNECTED_POSITION, SignpostPosition.LEFT_POSITION],
    [SIGNPOST_RIGHT_CONNECTED_POSITION, SignpostPosition.RIGHT_POSITION],
    [SIGNPOST_TOP_LEFT_CONNECTED_POSITION, SignpostPosition.TOP_LEFT_POSITION],
    [SIGNPOST_BOTTOM_LEFT_CONNECTED_POSITION, SignpostPosition.BOTTOM_LEFT_POSITION],
    [SIGNPOST_TOP_RIGHT_CONNECTED_POSITION, SignpostPosition.TOP_RIGHT_POSITION],
    [SIGNPOST_BOTTOM_RIGHT_CONNECTED_POSITION, SignpostPosition.BOTTOM_RIGHT_POSITION],
]);
