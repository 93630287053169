/**
 * Grid/CollectionGrid related components and services.
 * @module avi/component-kit/grid
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';

import {
    difference,
    partition,
} from 'underscore';

import {
    AjsDependency,
    initAjsDependency,
} from 'ajs/js/utilities/ajsDependency';

import { GridDataStorageService } from './gridDataStorageService';

import {
    ColumnWidthHash,
    IGridSettings,
} from './grid-types';

/**
 * Default relative width of new columns.
 */
const defaultNewColumnRelativeWidth = 10;

/**
 * @description
 *      Service to store width of columns of a grid in myAccount.uiProperty
 *      and applies it on grid load.
 * @author Aravindh Nagarajan
 */
export class GridColumnSizeManager extends AjsDependency {
    /**
     * Unique ID of Grid.
     * Constructor will throw if gridId is not passed.
     */
    private gridId: string;

    /**
     * Grid $element.
     */
    private gridElem: JQuery;

    /**
     * Grid settings - config and selectors.
     */
    private gridSettings: IGridSettings;

    /**
     * Service to store grid data in myAccount.
     */
    private gridDataStorageService: GridDataStorageService;

    public constructor(gridId: string, gridElem: JQuery, gridSettings: IGridSettings) {
        super();

        if (!gridId) {
            throw new Error('Grid Id is required to instantiate GridColumnSizeManager');
        }

        this.gridId = gridId;
        this.gridElem = gridElem;
        this.gridSettings = gridSettings;
        this.gridDataStorageService = this.getAjsDependency_('gridDataStorageService');
    }

    /**
     * Calculates column widths in percentage and stores it in myAccount.uiProperty
     */
    public storeColumnWidth(): void {
        const columnWidthHash: ColumnWidthHash = {};

        const columns = this.getAdjustableColumnElements();

        columns.forEach((column, index) => {
            const columnWidth = $(column).width();

            const columnName = this.getColumnName(index);

            const relativeColumnWidth = this.getRelativeWidth(columnWidth);

            columnWidthHash[columnName] = relativeColumnWidth;
        });

        this.columnWidthHash = columnWidthHash;
    }

    /**
     * Applies stored width to columns.
     */
    public applyStoredColumnWidth(): void {
        const { columnWidthHash } = this;

        if (!columnWidthHash) {
            return;
        }

        const columns = this.getAdjustableColumnElements();

        if (columns.length !== Object.keys(columnWidthHash).length) {
            console.warn('Widths are not applied as there is a variation in number of columns');

            this.columnWidthHash = null;

            return;
        }

        columns.forEach((col, index) => {
            const columnName = this.getColumnName(index);

            const columnWidth = this.getStoredColumnWidth(columnName);

            this.setColumnWidth(col, columnWidth);
        });
    }

    /**
     * Proportionally increases/decreases column width and updates the Hash
     * when new columns are added and/or existing columns are deleted.
     * Applicable only for collection-grids.
     */
    public adjustColumnWidth(currentVisibleColumns: string[]): void {
        const { columnWidthHash } = this;

        const columns = this.getAdjustableColumnElements();

        this.updateCurrentVisibleColumns(currentVisibleColumns);

        if (!columnWidthHash) {
            return;
        }

        const { minColWidth } = this.gridSettings;

        const minRelativeColWidth = this.getRelativeWidth(minColWidth);

        const presentColsWidthAdjustCoefficient = this.getPresentColsWidthAdjustCoefficient();

        // Partitioning the column list, so we can adjust percentage for
        // the existing ones and set default width(10%) for new ones.
        const [
            existingColumns,
            newColumns,
        ] = partition(columns, (col, index) => {
            const columnName = this.getColumnName(index);

            return columnName in columnWidthHash;
        });

        // Adjust width for Existing columns
        existingColumns.forEach((col, index) => {
            const columnName = this.getColumnName(index);

            const columnWidth = this.getStoredColumnWidth(columnName);

            let newColumnWidth =
                columnWidth - columnWidth * presentColsWidthAdjustCoefficient;

            newColumnWidth = Math.max(newColumnWidth, minRelativeColWidth);

            this.setColumnWidth(col, newColumnWidth);
        });

        // Set default relative width for new columns
        const newColumnWidth = Math.max(
            defaultNewColumnRelativeWidth,
            minRelativeColWidth,
        );

        newColumns.forEach(col => {
            this.setColumnWidth(col, newColumnWidth);
        });

        this.storeColumnWidth();
    }

    /**
     * Updates columnName list in gridSettings.
     * To keep gridSettings.columnNames identical with current visible columns
     * in collection-grid.
     */
    public updateCurrentVisibleColumns(columnNames: string[] = []): void {
        this.gridSettings.columnNames = columnNames;
    }

    /**
     * Returns columns of grid.
     */
    private getColumnElements(): HTMLElement[] {
        const {
            selectors: {
                columnHeaderSelector,
            },
        } = this.gridSettings;

        return this.gridElem.find(columnHeaderSelector).toArray();
    }

    /**
     * Returns column name for the position.
     * @throws when column name is undefined
     */
    private getColumnName(columnPosition: number): string {
        const { columnNames = [] } = this.gridSettings;

        const columnName = columnNames[columnPosition];

        if (!columnName) {
            throw new Error('Column name is required to store column sizes');
        }

        return columnName;
    }

    /**
     * Returns adjustable Columns collection. removes checkbox and row
     * action columns if they are present.
     */
    private getAdjustableColumnElements(): HTMLElement[] {
        const columns = this.getColumnElements();

        const {
            selectors: {
                checkboxSelector,
                rowActionsSelector,
            },
        } = this.gridSettings;

        return columns.filter((column: HTMLElement) => {
            const $column: JQuery<HTMLElement> = $(column);

            return !($column.is(checkboxSelector) || $column.is(rowActionsSelector));
        });
    }

    /**
     * Returns Relative width.
     */
    private getRelativeWidth(width: number): number {
        const {
            selectors: {
                gridHeaderRowSelector,
            },
        } = this.gridSettings;

        // For Grids, since we initiate from grid-header component,
        // width of the gridElem is the grid width.
        const gridWidth = gridHeaderRowSelector ?
            this.gridElem.find(gridHeaderRowSelector).width() :
            this.gridElem.width();

        return width / gridWidth * 100;
    }

    /**
     * Sets relative width for column element.
     */
    private setColumnWidth(column: HTMLElement, width: number): void {
        const $column = $(column);

        $column.css('width', `${width}%`);
    }

    /**
     * Returns column width from hash for given column name.
     */
    private getStoredColumnWidth(columnName: string): number {
        const { columnWidthHash } = this;

        return columnWidthHash[columnName];
    }

    /**
     * Returns width-adjust co-efficient for present columns.
     * This decimal value will be substracted from the stored column relative widths.
     * Will be a positive number when more number of columns are added and
     * will be a negative number if more columns are removed.
     * value always will be less than 1.
     */
    private getPresentColsWidthAdjustCoefficient(): number {
        const { columnWidthHash } = this;
        const { columnNames: currentColumnNames = [] } = this.gridSettings;

        const storedColumnNames = Object.keys(columnWidthHash);

        const removedColumnsWidth =
            this.getRemovedColumnsWidth(currentColumnNames, storedColumnNames);

        const newColumnsWidth = this.getAddedColumnsWidth(currentColumnNames, storedColumnNames);

        return (newColumnsWidth - removedColumnsWidth) / 100;
    }

    /**
     * Returns the relative width of the added columns.
     * @param currentColumnNames - Current visible column-names
     * @param storedColumnNames - Column-names from widthHash
     * @returns total width of added columns in %
     */
    private getAddedColumnsWidth(
        currentColumnNames: string[],
        storedColumnNames: string[],
    ): number {
        const addedColumns = difference(currentColumnNames, storedColumnNames);

        const { minColWidth } = this.gridSettings;

        const defaultNewColumnWidth = Math.max(
            defaultNewColumnRelativeWidth,
            this.getRelativeWidth(minColWidth),
        );

        return addedColumns.length * defaultNewColumnWidth;
    }

    /**
     * Returns the width of the removed columns
     * @param currentColumnNames
     * @param storedColumnNames
     * @returns total width of removed columns in %
     */
    private getRemovedColumnsWidth(
        currentColumnNames: string[],
        storedColumnNames: string[],
    ): number {
        const removedColumns = difference(storedColumnNames, currentColumnNames);

        return removedColumns.reduce((totalWidth, columnName) => {
            const currentColumnWidth = this.getStoredColumnWidth(columnName) || 0;

            return totalWidth + currentColumnWidth;
        }, 0);
    }

    /**
     * Setter to update column width hash for current collection grid.
     * @param columnWidthHash - Hash of column names and their width.
     */
    private set columnWidthHash(columnWidthHash: ColumnWidthHash) {
        const { gridId } = this;

        this.gridDataStorageService.setGridColumnWidthHash(gridId, columnWidthHash);
    }

    /**
     * Getter for column width hash for current collection grid.
     * Returns null if nothing is saved.
     */
    private get columnWidthHash(): ColumnWidthHash {
        const { gridId } = this;

        return this.gridDataStorageService.getGridColumnWidthHash(gridId);
    }
}

GridColumnSizeManager.ajsDependencies = [
    'gridDataStorageService',
];

initAjsDependency(
    angular.module('avi/component-kit/grid'),
    'factory',
    'GridColumnSizeManager',
    GridColumnSizeManager,
);
