/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import {
    ClrFormsModule,
    ClrInputModule,
} from '@clr/angular';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import {
    errorPageBodyCollectionProvider,
    errorPageProfileCollectionProvider,
} from './ajs-upgraded-providers';

import {
    ErrorPageBodyComponent,
    ErrorPageProfileComponent,
    ErrorPageProfileGridComponent,
    ErrorPageProfileListModalComponent,
    ErrorPageProfileModalComponent,
    ErrorPageProfilePreviewComponent,
} from '.';

const errorPageComponents = [
    ErrorPageBodyComponent,
    ErrorPageProfileComponent,
    ErrorPageProfileGridComponent,
    ErrorPageProfileModalComponent,
    ErrorPageProfileListModalComponent,
    ErrorPageProfilePreviewComponent,
];

/**
 * @description
 *
 *      Angular Module for Error Page related components.
 *
 * @author Satish Pednekar
 */
@NgModule({
    declarations: [
        ...errorPageComponents,
    ],
    imports: [
        AviFormsModule,
        DataGridModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrFormsModule,
    ],
    providers: [
        errorPageBodyCollectionProvider,
        errorPageProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ErrorPageModule {}
