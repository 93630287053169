/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/***************************************************************************
* ------------------------------------------------------------------------
* Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
* ------------------------------------------------------------------------
*
*/

const prefix = 'policies';

export const natPolicyBreadcrumbTitle = `${prefix}.natPolicyBreadcrumbTitle`;
export const natRuleBreadcrumbTitle = `${prefix}.natRuleBreadcrumbTitle`;

export const ENGLISH = {
    [natPolicyBreadcrumbTitle]: 'NAT Policy',
    [natRuleBreadcrumbTitle]: 'NAT Rule',
};
