/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * VRFContext related services and components.
 * @module VrfContextModule
 * @preferred
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    BgpPeerConfigItem,
    BgpProfileConfigItem,
    BgpRoutingOptionsConfigItem,
    GatewayMonitorConfigItem,
    StaticRouteConfigItem,
    VRFContext,
    VRFContextCollDataSource,
    VRFContextCollection,
} from './factories';

import {
    InfraCloudState,
} from './services';

import {
    BGP_PEER_CONFIG_ITEM_TOKEN,
    BGP_PROFILE_CONFIG_ITEM_TOKEN,
    BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN,
    GATEWAY_MONITOR_CONFIG_ITEM_TOKEN,
    INFRA_CLOUD_STATE_SERVICE_TOKEN,
    STATIC_ROUTE_CONFIG_ITEM_TOKEN,
    VRF_CONTEXT_COLLECTION_TOKEN,
    VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
    VRF_CONTEXT_TOKEN,
} from './vrf-context.tokens';

const VrfContextModule = angular.module('avi/vrf');

const factories = [
    {
        name: VRF_CONTEXT_TOKEN,
        factory: VRFContext,
    }, {
        name: VRF_CONTEXT_COLLECTION_TOKEN,
        factory: VRFContextCollection,
    }, {
        name: VRF_CONTEXT_COLL_DATASOURCE_TOKEN,
        factory: VRFContextCollDataSource,
    }, {
        name: BGP_PROFILE_CONFIG_ITEM_TOKEN,
        factory: BgpProfileConfigItem,
    }, {
        name: BGP_PEER_CONFIG_ITEM_TOKEN,
        factory: BgpPeerConfigItem,
    }, {
        name: BGP_ROUTING_OPTIONS_CONFIG_ITEM_TOKEN,
        factory: BgpRoutingOptionsConfigItem,
    }, {
        name: STATIC_ROUTE_CONFIG_ITEM_TOKEN,
        factory: StaticRouteConfigItem,
    }, {
        name: GATEWAY_MONITOR_CONFIG_ITEM_TOKEN,
        factory: GatewayMonitorConfigItem,
    },
];

const services = [
    {
        name: INFRA_CLOUD_STATE_SERVICE_TOKEN,
        service: InfraCloudState,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(VrfContextModule, 'factory', name, factory);
});

services.forEach(({ name, service }) => {
    initAjsDependency(VrfContextModule, 'service', name, service);
});
