/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-authentication';
const prefix = `${moduleName}.${componentName}`;

export const samlLabel = `${prefix}.samlLabel`;
export const oAuthLabel = `${prefix}.oAuthLabel`;
export const paaLabel = `${prefix}.paaLabel`;

export const ENGLISH = {
    [samlLabel]: 'SAML',
    [oAuthLabel]: 'OAuth',
    [paaLabel]: 'PAA',
};
