/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module PoolModule */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    FailActionConfigItem,
    FailActionHttpLocalResponseConfigItem,
    FailActionHttpRedirectConfigItem,
    HTTPReselectRespCodeConfigItem,
    HTTPServerReselectConfigItem,
    HTTPStatusRangeConfigItem,
    PlacementNetworkConfigItem,
    PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN,
    Pool,
    PoolCollection,
    PoolGroup,
    PoolGroupCollection,
    PoolGroupDeploymentPolicy,
    PoolGroupDeploymentPolicyCollection,
    PoolGroupMemberConfigItem,
    ServerConfigItem,
} from '.';

import {
    FAIL_ACTION_CONFIG_ITEM_TOKEN,
    FAIL_ACTION_HTTP_LOCAL_RESPONSE_CONFIG_ITEM_TOKEN,
    FAIL_ACTION_HTTP_REDIRECT_CONFIG_ITEM_TOKEN,
    HTTP_RESELECT_RESP_CODE_CONFIG_ITEM_TOKEN,
    HTTP_SERVER_RESELECT_CONFIG_ITEM_TOKEN,
    HTTP_STATUS_RANGE_CONFIG_ITEM_TOKEN,
    POOL_COLLECTION_TOKEN,
    POOL_GROUP_COLLECTION_TOKEN,
    POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN,
    POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
    POOL_GROUP_ITEM_TOKEN,
    POOL_GROUP_MEMBER_CONFIG_ITEM_TOKEN,
    POOL_ITEM_TOKEN,
    SERVER_CONFIG_ITEM_TOKEN,
} from './pool.tokens';

const poolModule = angular.module('avi/pool');

const factories = [
    {
        factory: PlacementNetworkConfigItem,
        name: PLACEMENT_NETWORK_CONFIG_ITEM_TOKEN,
    },
    {
        factory: Pool,
        name: POOL_ITEM_TOKEN,
    },
    {
        factory: PoolCollection,
        name: POOL_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroup,
        name: POOL_GROUP_ITEM_TOKEN,
    },
    {
        factory: PoolGroupCollection,
        name: POOL_GROUP_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroupDeploymentPolicyCollection,
        name: POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN,
    },
    {
        factory: PoolGroupDeploymentPolicy,
        name: POOL_GROUP_DEPLOYMENT_POLICY_ITEM_TOKEN,
    },
    {
        factory: PoolGroupMemberConfigItem,
        name: POOL_GROUP_MEMBER_CONFIG_ITEM_TOKEN,
    },
    {
        factory: FailActionConfigItem,
        name: FAIL_ACTION_CONFIG_ITEM_TOKEN,
    },
    {
        factory: FailActionHttpRedirectConfigItem,
        name: FAIL_ACTION_HTTP_REDIRECT_CONFIG_ITEM_TOKEN,
    },
    {
        factory: FailActionHttpLocalResponseConfigItem,
        name: FAIL_ACTION_HTTP_LOCAL_RESPONSE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: ServerConfigItem,
        name: SERVER_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HTTPServerReselectConfigItem,
        name: HTTP_SERVER_RESELECT_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HTTPReselectRespCodeConfigItem,
        name: HTTP_RESELECT_RESP_CODE_CONFIG_ITEM_TOKEN,
    },
    {
        factory: HTTPStatusRangeConfigItem,
        name: HTTP_STATUS_RANGE_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(poolModule, 'factory', name, factory);
});
