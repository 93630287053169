/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('avi/logs').directive('searchPill', ['logSearchMediator',
function(logSearchMediator) {
    function searchPillLink(scope, elem) {
        const
            input = elem.find('>input'),
            { ta } = logSearchMediator;

        let passFocusOut = false;//flag to pass focus out on keydown event

        scope.editMode = false;
        scope.max = Math.max;

        input
            .on('keydown', function(e) {
                if (e.which === 13) { /* enter */
                    if (ta.suggClicked) {
                        return;
                    }

                    //console.log('pill enter event');
                    passFocusOut = true;
                    scope.editMode = false;

                    if (!scope.value) {
                        scope.remove({ index: scope.index });
                        scope.$apply();
                    } else {
                        scope.onChange({ index: scope.index });
                    }
                } else if (e.which === 8 && !scope.value.length) { /* backspace in empty field */
                    scope.editMode = false;
                    e.stopPropagation();
                    //input.unbind('focusout');
                    passFocusOut = true;
                    scope.remove({
                        index: scope.index,
                        event: e,
                        type: true,
                    });
                    scope.$apply();
                } else if (e.which === 27) { /* esc */
                    if (!ta.escClicked) {
                        scope.editMode = false;

                        if (!scope.value) {
                            scope.remove({ index: scope.index });
                            scope.$apply();
                        } else {
                            scope.onChange({ index: scope.index });
                        }
                    }
                }
            })
            .on('focusout', function() {
                if (!scope.editMode || ta.suggClicked || passFocusOut) {
                    if (passFocusOut) {
                        passFocusOut = false;
                    }

                    return;
                }

                //console.log('pill focusout');
                scope.editMode = false;

                if (!scope.value) {
                    scope.remove({ index: scope.index });
                    scope.$apply();
                } else {
                    scope.onChange({ index: scope.index });
                }
            });

        scope.edit = function() {
            scope.editMode = true;
            setTimeout(() => input.trigger('focus'));
        };
    }

    return {
        link: searchPillLink,
        restrict: 'E',
        scope: {
            value: '=',
            requestUri: '&', //for suggestions (typeahead)
            index: '@',
            editMode: '=',
            remove: '&',
            onChange: '&',
        },
        template:
            `<span
                class="sel-selected-value"
                ng-hide="editMode"
                ng-click="edit()">{{value}}</span>
            <input
                log-suggestions
                ng-show="editMode"
                type="text"
                ng-model="value"
                size="{{ max(4, value.length + 3) }}"/>
            <i ng-show="!editMode"
                class="sel-remove-item icon-cancel"
                ng-click="remove({index: index, event: $event})"></i>`,
    };
}]);
