/** @module SharedModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Component } from '@angular/core';
import './preview-field.component.less';

/* tslint:disable:jsdoc-format */
/**
 * Displays passed in fields, meant for use in full-modal preview (sidebar).
 * Standardizes layout for a label-val pair.
 *
 * Usage:
   // parent component using preview-field:
   <preview-field>
       <preview-field_label class="parent-comp__preview-field-content">
           {{ label1 }}
       </preview-field_label>
       <preview-field_val class="parent-comp__preview-field-content">
           {{ val1 }}
       </preview-field_val>
   </preview-field>
 *
 * Above can be wrapped in an *ngFor.
 *
 * @see item-preview-defaults.component and item-preview-wrapper.component for more on usage
 * @author Akul Aggarwal
 */
/* tslint:enable:jsdoc-format */
@Component({
    selector: 'preview-field',
    templateUrl: './preview-field.component.html',
})
export class PreviewFieldComponent {}
