/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'cloud-connector-user-item';
const prefix = `${moduleName}.${componentName}`;

export const userCredentialsLabel = `${prefix}.userCredentialsLabel`;

export const ENGLISH = {
    [userCredentialsLabel]: 'User Credentials',
};
