/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    copy,
    IPromise,
    IQService,
} from 'angular';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { WAF_PROFILE_ITEM_TOKEN } from 'ajs/modules/waf/waf.tokens';
import { WafProfile } from 'object-types';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';

/**
 * @description WafProfile Collection Class.
 *
 * @author Kondiparthi Shanmukha Sarath
 */
export class WafProfileCollection extends Collection {
    public static ajsDependencies = [
        WAF_PROFILE_ITEM_TOKEN,
        'stringService',
        '$q',
        'Collection',
        'defaultValues',
    ];

    /**
     * Default Values for Waf Profile.
     */
    public readonly defaultValues: DefaultValues;

    /**
     * StringService instance, for recieving slug part of URL.
     */
    protected readonly stringService: StringService;

    /**
     * $q Dependency used for overriding server Default values.
     */
    protected readonly $q: IQService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'wafprofile',
            windowElement: 'lazy-load',
            objectType: WafProfile,
            permissionName: AviPermissionResource.PERMISSION_WAFPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(WAF_PROFILE_ITEM_TOKEN);
        this.stringService = this.getAjsDependency_('stringService');
        this.$q = this.getAjsDependency_('$q');
        this.defaultValues = this.getAjsDependency_('defaultValues');
    }

    /**
     * Used to set serverDefaultsOverride_ with the system default Waf Policy.
     */
    public setServerDefaultsOverride(): IPromise<void> {
        this.serverDefaultsOverride_ = this.serverDefaultsOverride_ || {};

        if ('files' in this.serverDefaultsOverride_) {
            return this.$q.when();
        } else {
            const defaultWafPolicyRef = this.defaultValues.getSystemObjectRefByName(
                'wafprofile',
                'System-WAF-Profile',
            );

            const WafProfileItem = this.itemClass_;

            const defaultWafPolicy = new WafProfileItem({
                id: this.stringService.slug(defaultWafPolicyRef),
            });

            return defaultWafPolicy.load()
                .then(() => {
                    const { files: defaultFiles } = defaultWafPolicy.getConfig();
                    const files = defaultFiles.getDataToSave();

                    this.serverDefaultsOverride_.files = copy(files);
                });
        }
    }

    /**
     * Used for opening create modal in waf profile.
     */
    public create(windowElement: string, params: object): IPromise<any> {
        return this.setServerDefaultsOverride().then(() => super.create(windowElement, params));
    }
}

export type TWafProfileCollection = typeof WafProfileCollection;
