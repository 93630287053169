/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-detection-policy-item';
const prefix = `${moduleName}.${componentName}`;

export const botManagementPolicyBreadcrumbTitle = `${prefix}.botManagementPagePolicyTitle`;

export const ENGLISH = {
    [botManagementPolicyBreadcrumbTitle]: 'Bot Management Policy',
};
