/**
 * @module CloudModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { INsxtTier1SegmentAutomaticMode } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

export class NsxtTier1SegmentAutomaticModeConfigItem
    extends MessageItem<INsxtTier1SegmentAutomaticMode> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'NsxtTier1SegmentAutomaticMode',
            ...args,
        };

        super(extendedArgs);
    }
}
