/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { PortMatchConfigItem } from 'ajs/modules/match';
import * as l10n from './port-match.component.l10n';
import './port-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Match component for Port Match message.
 * @author Sarthak Kapoor
 */
@Component({
    selector: 'port-match',
    templateUrl: './port-match.component.html',
})
export class PortMatchComponent {
    /**
     *  Port Match message item instance.
     */
    @Input()
    public editable: PortMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * ObjectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * FieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * Object Type to be used in template for tooltip.
     */
    @Input()
    public matchObjectType: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
