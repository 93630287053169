/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import { Injectable } from '@angular/core';

/**
 * @description Service used to provide the same instance of a VirtualService item across modules.
 * @author Alex Klyuev
 */
@Injectable()
export class VirtualServiceStateService {
    /**
     * Given instance of a Virtual Service item.
     * TODO: change "any" to the VirtualService class once it's been converted to Typescript.
     * (and eventually this service should be obsoleted)
     */
    public virtualService: any;
}
