/** @module LogsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { BotDecisionComponent } from 'generated-types';
import { IGridConfig } from 'ajs/components/common/grid/grid-data-model';
import { L10nService } from '@vmw/ngx-vip';

import {
    IBotDetectionLogClassification,
    IBotDetectionLogIdentification,
    IBotDetectionLogRow,
} from './bot-detection-policy-logs.types';

import * as l10n from './bot-detection-policy-logs.l10n';
import template from './bot-detection-policy-logs.component.html';
import './bot-detection-policy-logs.component.less';

export const BOT_DETECTION_POLICY_LOG_TOKEN = 'botDetectionPolicyLog';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component when Bot Detection Policy logs are present in VS for L7.
 * @author Sarthak Kapoor
 */
class BotDetectionPolicyLogComponent {
    /**
     * Config data to be passed as param to grid directive from template.
     */
    public gridConfig: IGridConfig;

    /**
     * Row Data to be passed as parameter to grid in template.
     */
    public gridRows: IBotDetectionLogRow[];

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Data to be shown in Bot Detection Policy logs table.
     */
    public readonly botDetectionData: {
        classification: IBotDetectionLogClassification,
        results: IBotDetectionLogRow[],
    };

    /**
     * Parent function passed in to handle filter by new prop.
     */
    public updateSearch: (args: { [str: string]: string }) => void;

    /**
     * Flag that controls visibility of the components grid.
     */
    public showComponentsGrid = false;

    /**
     * Data of the consolidation component.
     */
    private consolidationComponentData: IBotDetectionLogRow;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Sets initial grid row data and config to be passed on to grid directive.
     */
    public $onInit(): void {
        this.consolidationComponentData = this.botDetectionData.results.find(({ component }) => {
            return component.val === BotDecisionComponent.BOT_DECIDER_CONSOLIDATION;
        });

        this.gridRows = this.botDetectionData.results;

        this.gridConfig = this.createBotDetectionLogsGrid();
    }

    /**
     * Calls parent function to handle user click to filter by clicked prop.
     */
    public handleUpdate(str: string): void {
        this.updateSearch({ str });
    }

    /**
     * Called to toggle visibility of the components grid.
     */
    public toggleShowComponentsGrid(): void {
        this.showComponentsGrid = !this.showComponentsGrid;
    }

    /**
     * Returns the bot classification.
     */
    public get classification(): IBotDetectionLogClassification['type'] {
        return this.botDetectionData.classification.type;
    }

    /**
     * Returns the consolidated identification type.
     */
    public get consolidatedType(): IBotDetectionLogIdentification['type'] {
        return this.consolidationComponentData.identification.type;
    }

    /**
     * Returns the consolidated identifier type.
     */
    public get consolidatedIdentifier(): IBotDetectionLogIdentification['identifier'] {
        return this.consolidationComponentData.identification.identifier;
    }

    /**
     * Creates and returns grid config.
     */
    private createBotDetectionLogsGrid(): IGridConfig {
        return {
            expandedContainerTemplate:
                `<bot-detection-log-list-expander
                     bot-log="::row"
                     update-search="config.props.updateSearch(str)"
                 ></bot-detection-log-list-expander>`,
            props: {
                updateSearch: (str: string) => this.updateSearch({ str }),
            },
            fields: this.getGridConfigFields(),
            id: 'bot-detection-policy-log',
            layout: {
                hideDisplaying: true,
                hideSearch: true,
            },
        };
    }

    /**
     * Returns vendor specific grid config fields.
     */
    private getGridConfigFields(): IGridConfig['fields'] {
        const gridConfigFields = [
            {
                name: this.l10nService.getMessage(l10nKeys.gridColumnHeaderComponentLabel),
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderComponentLabel),
                template: `<div
                                class="bot-logs__clickable-filter"
                                log-filter-click
                                key="bot_management_log.results.component"
                                display-value="{{ ::row.component.display }}"
                                value="::row.component.val"
                                on-update="config.props.updateSearch(str)"
                            ></div>`,
            },
            {
                name: this.l10nService.getMessage(l10nKeys.gridColumnHeaderClassLabel),
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderClassLabel),
                template: `<div
                                class="bot-logs__clickable-filter"
                                log-filter-click
                                key="bot_management_log.results.identification.class"
                                display-value="{{ ::row.identification.class.display }}"
                                value="::row.identification.class.val"
                                on-update="config.props.updateSearch(str)"
                            ></div>`,
            },
            {
                name: this.l10nService.getMessage(l10nKeys.gridColumnHeaderTypeLabel),
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderTypeLabel),
                template: `<div
                                class="bot-logs__clickable-filter"
                                log-filter-click
                                key="bot_management_log.results.identification.type"
                                display-value="{{ ::row.identification.type.display }}"
                                value="::row.identification.type.val"
                                on-update="config.props.updateSearch(str)"
                            ></div>`,
            },
            {
                name: this.l10nService.getMessage(l10nKeys.gridColumnHeaderIdentifierLabel),
                title: this.l10nService.getMessage(l10nKeys.gridColumnHeaderIdentifierLabel),
                template: `<div
                                class="bot-logs__clickable-filter"
                                log-filter-click
                                key="bot_management_log.results.identification.identifier"
                                display-value="{{ ::row.identification.identifier.display }}"
                                value="::row.identification.identifier.val"
                                on-update="config.props.updateSearch(str)"
                            ></div>`,
            },
        ];

        return gridConfigFields;
    }
}

BotDetectionPolicyLogComponent.$inject = [
    'l10nService',
];

export const botDetectionPolicyLogsComponentOptions = {
    bindings: {
        botDetectionData: '<',
        updateSearch: '&',
    },
    controller: BotDetectionPolicyLogComponent,
    template,
};
