/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name moment
 * @description Injectable wrapper for moment.js library.
 */
angular.module('aviApp').factory('moment', ['$window', function($window) {
    return $window.moment;
}]);
