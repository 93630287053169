/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

/**
 * Filter fields stored in respective sets for quick lookup.
 */
export const l4Fields = new Set([
    'client_dest_port',
    'client_ip',
    'client_ip6',
    'client_location',
    'client_src_port',
    'connection_ended',
    'dns_etype',
    'dns_fqdn',
    'dns_qtype',
    'dns_request.authentic_data',
    'dns_request.checking_disabled',
    'dns_request.client_location.name',
    'dns_request.identifier',
    'dns_request.opcode',
    'dns_request.opt_record.dnssec_ok',
    'dns_request.opt_record.options.code',
    'dns_request.opt_record.options.scope_prefix_len',
    'dns_request.opt_record.options.source_prefix_len',
    'dns_request.opt_record.options.subnet_ip',
    'dns_request.opt_record.options.subnet_ip6',
    'dns_request.opt_record.udp_payload_size',
    'dns_request.opt_record.version',
    'dns_request.question_count',
    'dns_request.recursion_desired',
    'dns_response.additional_records_count',
    'dns_response.answer_records_count',
    'dns_response.authoritative_answer',
    'dns_response.fallback_algorithm_used',
    'dns_response.is_wildcard',
    'dns_response.nameserver_records_count',
    'dns_response.opcode',
    'dns_response.opt_record.dnssec_ok',
    'dns_response.opt_record.options.code',
    'dns_response.opt_record.options.scope_prefix_len',
    'dns_response.opt_record.options.source_prefix_len',
    'dns_response.opt_record.udp_payload_size',
    'dns_response.opt_record.version',
    'dns_response.question_count',
    'dns_response.records.addr6_ip_str',
    'dns_response.records.addr_ip',
    'dns_response.records.cname',
    'dns_response.records.location.name',
    'dns_response.records.mail_server',
    'dns_response.records.name',
    'dns_response.records.nsname',
    'dns_response.records.port',
    'dns_response.records.priority',
    'dns_response.records.text_rdata',
    'dns_response.records.ttl',
    'dns_response.records.type',
    'dns_response.records.vs_name',
    'dns_response.recursion_available',
    'dns_response.recursion_desired',
    'dns_response.response_code',
    'dns_response.truncation',
    'gslbpool_name',
    'gslbservice',
    'gslbservice_name',
    'microservice',
    'microservice_name',
    'mss',
    'network_security_policy_rule_name',
    'ocsp_status_resp_sent',
    'out_of_orders',
    'pool_name',
    'protocol',
    'retransmits',
    'rtt',
    'rx_bytes',
    'rx_pkts',
    'server_conn_src_ip',
    'server_conn_src_ip6',
    'server_dest_port',
    'server_ip',
    'server_ip6',
    'server_name',
    'server_out_of_orders',
    'server_retransmits',
    'server_rtt',
    'server_rx_bytes',
    'server_rx_pkts',
    'server_src_port',
    'server_timeouts',
    'server_total_bytes',
    'server_total_pkts',
    'server_tx_bytes',
    'server_tx_pkts',
    'server_zero_window_size_events',
    'service_engine',
    'significance',
    'sni_hostname',
    'ssl_cipher',
    'ssl_session_id',
    'ssl_version',
    'timeouts',
    'total_bytes',
    'total_pkts',
    'total_time',
    'tx_bytes',
    'tx_pkts',
    'vcpu_id',
    'vs_ip',
    'vs_ip6',
    'zero_window_size_events',
]);

export const l7Fields = new Set([
    'app_response_time',
    'bot_management_log.classification.type',
    'bot_management_log.results.component',
    'bot_management_log.results.confidence',
    'bot_management_log.results.identification.class',
    'bot_management_log.results.identification.identifier',
    'bot_management_log.results.identification.type',
    'cache_hit',
    'client_browser',
    'client_dest_port',
    'client_device',
    'client_fingerprints.filtered_tls_fingerprint',
    'client_fingerprints.full_tls_fingerprint',
    'client_ip',
    'client_ip6',
    'client_location',
    'client_os',
    'client_rtt',
    'client_src_port',
    'compression',
    'compression_percentage',
    'data_transfer_time',
    'etag',
    'grpc_method_name',
    'grpc_service_name',
    'grpc_status',
    'grpc_status_label',
    'grpc_status_reason_phrase',
    'host',
    'http2_stream_id',
    'http_request_policy_rule_name',
    'http_response_policy_rule_name',
    'http_security_policy_rule_name',
    'http_version',
    'icap_log.action',
    'icap_log.request_logs.action',
    'icap_log.request_logs.complete_body_sent',
    'icap_log.request_logs.http_method',
    'icap_log.request_logs.http_response_code',
    'icap_log.request_logs.icap_absolute_uri',
    'icap_log.request_logs.icap_method',
    'icap_log.request_logs.icap_response_code',
    'icap_log.request_logs.icap_server_ip',
    'icap_log.request_logs.latency',
    'icap_log.request_logs.modified_content_length',
    'icap_log.request_logs.nsx_defender_log.score',
    'icap_log.request_logs.nsx_defender_log.status_url',
    'icap_log.request_logs.nsx_defender_log.task_uuid',
    'icap_log.request_logs.opswat_log.reason',
    'icap_log.request_logs.opswat_log.threat_id',
    'icap_log.request_logs.opswat_log.violations.file_name',
    'icap_log.request_logs.opswat_log.violations.resolution',
    'icap_log.request_logs.opswat_log.violations.threat_name',
    'icap_log.request_logs.pool_name',
    'icap_log.request_logs.server_port',
    'icap_log.request_logs.source_port',
    'icap_log.request_logs.vendor',
    'method',
    'microservice',
    'microservice_name',
    'network_security_policy_rule_name',
    'ntlm_log.ntlm_detected',
    'ntlm_log.ntlm_status',
    'oauth_log.authn_rule_match.rule_action',
    'oauth_log.authn_rule_match.rule_name',
    'oauth_log.authz_rule_match.rule_action',
    'oauth_log.authz_rule_match.rule_name',
    'oauth_log.is_session_cookie_expired',
    'oauth_log.jwks_subrequest.error_code',
    'oauth_log.jwks_subrequest.sub_request_log.http_response_code',
    'oauth_log.jwks_subrequest.sub_request_log.pool_name',
    'oauth_log.jwks_subrequest.sub_request_log.server_ip',
    'oauth_log.jwks_subrequest.sub_request_log.server_name',
    'oauth_log.jwks_subrequest.sub_request_log.server_port',
    'oauth_log.jwks_subrequest.sub_request_log.source_port',
    'oauth_log.jwks_subrequest.sub_request_log.total_time',
    'oauth_log.state',
    'oauth_log.token_exchange_subrequest.error_code',
    'oauth_log.token_exchange_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_exchange_subrequest.sub_request_log.pool_name',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_ip',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_name',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_port',
    'oauth_log.token_exchange_subrequest.sub_request_log.source_port',
    'oauth_log.token_exchange_subrequest.sub_request_log.total_time',
    'oauth_log.token_introspection_subrequest.error_code',
    'oauth_log.token_introspection_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_introspection_subrequest.sub_request_log.pool_name',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_ip',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_name',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_port',
    'oauth_log.token_introspection_subrequest.sub_request_log.source_port',
    'oauth_log.token_introspection_subrequest.sub_request_log.total_time',
    'oauth_log.token_refresh_subrequest.error_code',
    'oauth_log.token_refresh_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_refresh_subrequest.sub_request_log.pool_name',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_ip',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_name',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_port',
    'oauth_log.token_refresh_subrequest.sub_request_log.source_port',
    'oauth_log.token_refresh_subrequest.sub_request_log.total_time',
    'oauth_log.userinfo_subrequest.error_code',
    'oauth_log.userinfo_subrequest.sub_request_log.http_response_code',
    'oauth_log.userinfo_subrequest.sub_request_log.pool_name',
    'oauth_log.userinfo_subrequest.sub_request_log.server_ip',
    'oauth_log.userinfo_subrequest.sub_request_log.server_name',
    'oauth_log.userinfo_subrequest.sub_request_log.server_port',
    'oauth_log.userinfo_subrequest.sub_request_log.source_port',
    'oauth_log.userinfo_subrequest.sub_request_log.total_time',
    'ocsp_status_resp_sent',
    'oob_log.ds_req_logs.ds_name',
    'oob_log.ds_req_logs.event',
    'oob_log.ds_req_logs.headers_received_from_server',
    'oob_log.ds_req_logs.headers_sent_to_server',
    'oob_log.ds_req_logs.http_response_code',
    'oob_log.ds_req_logs.http_version',
    'oob_log.ds_req_logs.method',
    'oob_log.ds_req_logs.pool_name',
    'oob_log.ds_req_logs.pool_uuid',
    'oob_log.ds_req_logs.request_length',
    'oob_log.ds_req_logs.response_length',
    'oob_log.ds_req_logs.server_ip',
    'oob_log.ds_req_logs.server_name',
    'oob_log.ds_req_logs.server_port',
    'oob_log.ds_req_logs.servers_tried',
    'oob_log.ds_req_logs.source_port',
    'oob_log.ds_req_logs.total_time',
    'oob_log.ds_req_logs.uri_path',
    'oob_log.ds_req_logs.uri_query',
    'paa_log.cache_hit',
    'paa_log.client_request_body_sent',
    'paa_log.request_logs.headers_received_from_server',
    'paa_log.request_logs.headers_sent_to_server',
    'paa_log.request_logs.http_version',
    'paa_log.request_logs.method',
    'paa_log.request_logs.pool_name',
    'paa_log.request_logs.response_code',
    'paa_log.request_logs.server_ip',
    'paa_log.request_logs.server_name',
    'paa_log.request_logs.servers_tried',
    'paa_log.request_logs.uri_path',
    'persistence_used',
    'persistent_cookie',
    'persistent_session_id',
    'pool_name',
    'redirected_uri',
    'referer',
    'request_content_type',
    'request_id',
    'request_length',
    'response_code',
    'response_content_type',
    'response_length',
    'rewritten_uri_path',
    'rewritten_uri_query',
    'saml_log.saml_attribute_lists.attr_name',
    'saml_log.saml_auth_status',
    'saml_log.saml_authn_rule_match.saml_authn_matched_rule_action',
    'saml_log.saml_authn_rule_match.saml_authn_matched_rule_name',
    'saml_log.saml_authz_rule_match.saml_authz_matched_rule_action',
    'saml_log.saml_authz_rule_match.saml_authz_matched_rule_name',
    'saml_log.saml_session_cookie_expired',
    'saml_log.userid',
    'server_conn_src_ip',
    'server_conn_src_ip6',
    'server_dest_port',
    'server_ip',
    'server_ip6',
    'server_name',
    'server_push_initiated',
    'server_pushed_request',
    'server_response_code',
    'server_response_length',
    'server_rtt',
    'server_side_redirect_uri',
    'server_src_port',
    'servers_tried',
    'service_engine',
    'significance',
    'sni_hostname',
    'source_ip',
    'source_ip6',
    'spdy_version',
    'ssl_cipher',
    'ssl_session_id',
    'ssl_version',
    'total_time',
    'uri_path',
    'uri_query',
    'user_agent',
    'user_id',
    'vcpu_id',
    'vs_ip',
    'vs_ip6',
    'waf_log.allowlist_logs.actions',
    'waf_log.allowlist_logs.rule_name',
    'waf_log.latency_request_body_phase',
    'waf_log.latency_request_header_phase',
    'waf_log.latency_response_body_phase',
    'waf_log.latency_response_header_phase',
    'waf_log.memory_allocated',
    'waf_log.psm_logs.actions',
    'waf_log.psm_logs.group_name',
    'waf_log.psm_logs.location',
    'waf_log.psm_logs.matches.match_element',
    'waf_log.psm_logs.matches.match_value',
    'waf_log.psm_logs.rule_name',
    'waf_log.rule_logs.matches.match_element',
    'waf_log.rule_logs.matches.match_value',
    'waf_log.rule_logs.msg',
    'waf_log.rule_logs.phase',
    'waf_log.rule_logs.rule_group',
    'waf_log.rule_logs.rule_id',
    'waf_log.rule_logs.rule_name',
    'waf_log.rule_logs.tags',
    'waf_log.status',
    'xff',
]);

export const numberFields = new Set([
    'app_response_time',
    'client_dest_port',
    'client_rtt',
    'client_src_port',
    'compression_percentage',
    'data_transfer_time',
    'dns_request.identifier',
    'dns_request.opt_record.options.scope_prefix_len',
    'dns_request.opt_record.options.source_prefix_len',
    'dns_request.opt_record.udp_payload_size',
    'dns_request.opt_record.version',
    'dns_request.question_count',
    'dns_response.additional_records_count',
    'dns_response.answer_records_count',
    'dns_response.nameserver_records_count',
    'dns_response.opt_record.options.scope_prefix_len',
    'dns_response.opt_record.options.source_prefix_len',
    'dns_response.opt_record.udp_payload_size',
    'dns_response.opt_record.version',
    'dns_response.question_count',
    'dns_response.records.port',
    'dns_response.records.priority',
    'dns_response.records.ttl',
    'grpc_status',
    'http2_stream_id',
    'icap_log.request_logs.http_response_code',
    'icap_log.request_logs.icap_response_code',
    'icap_log.request_logs.latency',
    'icap_log.request_logs.modified_content_length',
    'icap_log.request_logs.nsx_defender_log.score',
    'icap_log.request_logs.server_port',
    'icap_log.request_logs.source_port',
    'mss',
    'oauth_log.jwks_subrequest.sub_request_log.http_response_code',
    'oauth_log.jwks_subrequest.sub_request_log.server_port',
    'oauth_log.jwks_subrequest.sub_request_log.source_port',
    'oauth_log.jwks_subrequest.sub_request_log.total_time',
    'oauth_log.token_exchange_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_port',
    'oauth_log.token_exchange_subrequest.sub_request_log.source_port',
    'oauth_log.token_exchange_subrequest.sub_request_log.total_time',
    'oauth_log.token_introspection_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_port',
    'oauth_log.token_introspection_subrequest.sub_request_log.source_port',
    'oauth_log.token_introspection_subrequest.sub_request_log.total_time',
    'oauth_log.token_refresh_subrequest.sub_request_log.http_response_code',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_port',
    'oauth_log.token_refresh_subrequest.sub_request_log.source_port',
    'oauth_log.token_refresh_subrequest.sub_request_log.total_time',
    'oauth_log.userinfo_subrequest.sub_request_log.http_response_code',
    'oauth_log.userinfo_subrequest.sub_request_log.server_port',
    'oauth_log.userinfo_subrequest.sub_request_log.source_port',
    'oauth_log.userinfo_subrequest.sub_request_log.total_time',
    'oob_log.ds_req_logs.http_response_code',
    'oob_log.ds_req_logs.request_length',
    'oob_log.ds_req_logs.response_length',
    'oob_log.ds_req_logs.server_port',
    'oob_log.ds_req_logs.servers_tried',
    'oob_log.ds_req_logs.source_port',
    'oob_log.ds_req_logs.total_time',
    'out_of_orders',
    'paa_log.request_logs.response_code',
    'paa_log.request_logs.servers_tried',
    'persistent_session_id',
    'request_length',
    'response_code',
    'response_length',
    'retransmits',
    'rtt',
    'rx_bytes',
    'rx_pkts',
    'server_dest_port',
    'server_out_of_orders',
    'server_response_code',
    'server_response_length',
    'server_retransmits',
    'server_rtt',
    'server_rx_bytes',
    'server_rx_pkts',
    'server_src_port',
    'server_timeouts',
    'server_total_bytes',
    'server_total_pkts',
    'server_tx_bytes',
    'server_tx_pkts',
    'server_zero_window_size_events',
    'servers_tried',
    'timeouts',
    'total_bytes',
    'total_pkts',
    'total_time',
    'tx_bytes',
    'tx_pkts',
    'vcpu_id',
    'waf_log.latency_request_body_phase',
    'waf_log.latency_request_header_phase',
    'waf_log.latency_response_body_phase',
    'waf_log.latency_response_header_phase',
    'waf_log.memory_allocated',
    'waf_log.rule_logs.rule_id',
    'zero_window_size_events',
]);

export const stringFields = new Set([
    'bot_management_log.classification.type',
    'bot_management_log.results.component',
    'bot_management_log.results.confidence',
    'bot_management_log.results.identification.class',
    'bot_management_log.results.identification.identifier',
    'bot_management_log.results.identification.type',
    'client_browser',
    'client_device',
    'client_fingerprints.filtered_tls_fingerprint',
    'client_fingerprints.full_tls_fingerprint',
    'client_ip',
    'client_ip6',
    'client_location',
    'client_os',
    'compression',
    'dns_etype',
    'dns_fqdn',
    'dns_qtype',
    'dns_request.client_location.name',
    'dns_request.opcode',
    'dns_request.opt_record.options.code',
    'dns_request.opt_record.options.subnet_ip',
    'dns_request.opt_record.options.subnet_ip6',
    'dns_response.opcode',
    'dns_response.opt_record.options.code',
    'dns_response.records.addr6_ip_str',
    'dns_response.records.addr_ip',
    'dns_response.records.cname',
    'dns_response.records.location.name',
    'dns_response.records.mail_server',
    'dns_response.records.name',
    'dns_response.records.nsname',
    'dns_response.records.text_rdata',
    'dns_response.records.type',
    'dns_response.records.vs_name',
    'dns_response.response_code',
    'etag',
    'grpc_method_name',
    'grpc_service_name',
    'grpc_status_label',
    'grpc_status_reason_phrase',
    'gslbpool_name',
    'gslbservice',
    'gslbservice_name',
    'host',
    'http_request_policy_rule_name',
    'http_response_policy_rule_name',
    'http_security_policy_rule_name',
    'http_version',
    'icap_log.action',
    'icap_log.request_logs.action',
    'icap_log.request_logs.http_method',
    'icap_log.request_logs.icap_absolute_uri',
    'icap_log.request_logs.icap_method',
    'icap_log.request_logs.icap_server_ip',
    'icap_log.request_logs.nsx_defender_log.status_url',
    'icap_log.request_logs.nsx_defender_log.task_uuid',
    'icap_log.request_logs.opswat_log.reason',
    'icap_log.request_logs.opswat_log.threat_id',
    'icap_log.request_logs.opswat_log.violations.file_name',
    'icap_log.request_logs.opswat_log.violations.resolution',
    'icap_log.request_logs.opswat_log.violations.threat_name',
    'icap_log.request_logs.pool_name',
    'icap_log.request_logs.vendor',
    'method',
    'microservice',
    'microservice_name',
    'network_security_policy_rule_name',
    'ntlm_log.ntlm_status',
    'oauth_log.authn_rule_match.rule_action',
    'oauth_log.authn_rule_match.rule_name',
    'oauth_log.authz_rule_match.rule_action',
    'oauth_log.authz_rule_match.rule_name',
    'oauth_log.jwks_subrequest.error_code',
    'oauth_log.jwks_subrequest.sub_request_log.pool_name',
    'oauth_log.jwks_subrequest.sub_request_log.server_ip',
    'oauth_log.jwks_subrequest.sub_request_log.server_name',
    'oauth_log.state',
    'oauth_log.token_exchange_subrequest.error_code',
    'oauth_log.token_exchange_subrequest.sub_request_log.pool_name',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_ip',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_name',
    'oauth_log.token_introspection_subrequest.error_code',
    'oauth_log.token_introspection_subrequest.sub_request_log.pool_name',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_ip',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_name',
    'oauth_log.token_refresh_subrequest.error_code',
    'oauth_log.token_refresh_subrequest.sub_request_log.pool_name',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_ip',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_name',
    'oauth_log.userinfo_subrequest.error_code',
    'oauth_log.userinfo_subrequest.sub_request_log.pool_name',
    'oauth_log.userinfo_subrequest.sub_request_log.server_ip',
    'oauth_log.userinfo_subrequest.sub_request_log.server_name',
    'oob_log.ds_req_logs.ds_name',
    'oob_log.ds_req_logs.event',
    'oob_log.ds_req_logs.headers_received_from_server',
    'oob_log.ds_req_logs.headers_sent_to_server',
    'oob_log.ds_req_logs.http_version',
    'oob_log.ds_req_logs.method',
    'oob_log.ds_req_logs.pool_name',
    'oob_log.ds_req_logs.pool_uuid',
    'oob_log.ds_req_logs.server_ip',
    'oob_log.ds_req_logs.server_name',
    'oob_log.ds_req_logs.uri_path',
    'oob_log.ds_req_logs.uri_query',
    'paa_log.request_logs.headers_received_from_server',
    'paa_log.request_logs.headers_sent_to_server',
    'paa_log.request_logs.http_version',
    'paa_log.request_logs.method',
    'paa_log.request_logs.pool_name',
    'paa_log.request_logs.server_ip',
    'paa_log.request_logs.server_name',
    'paa_log.request_logs.uri_path',
    'persistent_cookie',
    'pool_name',
    'protocol',
    'redirected_uri',
    'referer',
    'request_content_type',
    'request_id',
    'response_content_type',
    'rewritten_uri_path',
    'rewritten_uri_query',
    'saml_log.saml_attribute_lists.attr_name',
    'saml_log.saml_auth_status',
    'saml_log.saml_authn_rule_match.saml_authn_matched_rule_action',
    'saml_log.saml_authn_rule_match.saml_authn_matched_rule_name',
    'saml_log.saml_authz_rule_match.saml_authz_matched_rule_action',
    'saml_log.saml_authz_rule_match.saml_authz_matched_rule_name',
    'saml_log.userid',
    'server_conn_src_ip',
    'server_conn_src_ip6',
    'server_ip',
    'server_ip6',
    'server_name',
    'server_side_redirect_uri',
    'service_engine',
    'significance',
    'sni_hostname',
    'source_ip',
    'source_ip6',
    'spdy_version',
    'ssl_cipher',
    'ssl_session_id',
    'ssl_version',
    'uri_path',
    'uri_query',
    'user_agent',
    'user_id',
    'vs_ip',
    'vs_ip6',
    'waf_log.allowlist_logs.actions',
    'waf_log.allowlist_logs.rule_name',
    'waf_log.psm_logs.actions',
    'waf_log.psm_logs.group_name',
    'waf_log.psm_logs.location',
    'waf_log.psm_logs.matches.match_element',
    'waf_log.psm_logs.matches.match_value',
    'waf_log.psm_logs.rule_name',
    'waf_log.rule_logs.matches.match_element',
    'waf_log.rule_logs.matches.match_value',
    'waf_log.rule_logs.msg',
    'waf_log.rule_logs.phase',
    'waf_log.rule_logs.rule_group',
    'waf_log.rule_logs.rule_name',
    'waf_log.rule_logs.tags',
    'waf_log.status',
    'xff',
]);

export const boolFields = new Set([
    'cache_hit',
    'connection_ended',
    'dns_request.authentic_data',
    'dns_request.checking_disabled',
    'dns_request.opt_record.dnssec_ok',
    'dns_request.recursion_desired',
    'dns_response.authoritative_answer',
    'dns_response.fallback_algorithm_used',
    'dns_response.is_wildcard',
    'dns_response.opt_record.dnssec_ok',
    'dns_response.recursion_available',
    'dns_response.recursion_desired',
    'dns_response.truncation',
    'icap_log.request_logs.complete_body_sent',
    'ntlm_log.ntlm_detected',
    'oauth_log.is_session_cookie_expired',
    'ocsp_status_resp_sent',
    'paa_log.cache_hit',
    'paa_log.client_request_body_sent',
    'persistence_used',
    'saml_log.saml_session_cookie_expired',
    'server_push_initiated',
    'server_pushed_request',
]);

/**
 * Fields that support OR logic (e.g., method=["GET","POST"])
 */
export const fieldsWithOrLogic = new Set([
    'significance',
    'virtualservice',
    'service_engine',
    'client_location',
    'ssl_session_id',
    'ssl_cipher',
    'ssl_version',
    'http_version',
    'method',
    'uri_path',
    'uri_query',
    'referer',
    'user_agent',
    'client_device',
    'client_browser',
    'client_os',
    'xff',
    'persistent_cookie',
    'host',
    'etag',
    'http_security_policy_rule_name',
    'network_security_policy_rule_name',
    'http_request_policy_rule_name',
    'http_response_policy_rule_name',
    'pool_name',
    'server_name',
    'connection_error_info',
    'client_ip',
    'client_ip6',
    'server_ip',
    'server_ip6',
    'server_conn_src_ip',
    'response_content_type',
    'request_content_type',
    'rewritten_uri_path',
    'rewritten_uri_query',
    'redirected_uri',
    'server_side_redirect_uri',
    'microservice',
    'microservice_name',
    'dns_qtype',
    'gslbservice',
    'server_conn_src_ip6',
    'gslbservice_name',
    'gslbpool_name',
    'protocol',
    'dns_etype',
    'vs_ip',
    'vs_ip6',
    'paa_log.request_logs.uri_path',
    'paa_log.request_logs.server_ip',
    'dns_request.opt_record.options.subnet_ip',
    'dns_request.opt_record.options.subnet_ip6',
    'dns_response.records.addr6_ip_str',
    'dns_response.records.addr_ip',
    'oauth_log.userinfo_subrequest.sub_request_log.server_ip',
    'oauth_log.token_refresh_subrequest.sub_request_log.server_ip',
    'oauth_log.token_introspection_subrequest.sub_request_log.server_ip',
    'oauth_log.token_exchange_subrequest.sub_request_log.server_ip',
    'oauth_log.jwks_subrequest.sub_request_log.server_ip',
]);

export interface IVsLogsOperatorDesc {
    symbol: string;
    desc: string;
}

const equalTo: IVsLogsOperatorDesc = {
    symbol: '=',
    desc: 'equal to',
};

const notEqualTo: IVsLogsOperatorDesc = {
    symbol: '!=',
    desc: 'not equal to',
};

const greaterThan: IVsLogsOperatorDesc = {
    symbol: '>',
    desc: 'greater than',
};

const greaterThanOrEqualTo: IVsLogsOperatorDesc = {
    symbol: '>=',
    desc: 'greater than or equal to',
};

const lessThan: IVsLogsOperatorDesc = {
    symbol: '<',
    desc: 'less than',
};

const lessThanOrEqualTo: IVsLogsOperatorDesc = {
    symbol: '<=',
    desc: 'less than or equal to',
};

const contains: IVsLogsOperatorDesc = {
    symbol: '~=',
    desc: 'contains',
};

const doesNotContain: IVsLogsOperatorDesc = {
    symbol: '!~=',
    desc: 'does not contain',
};

const startsWith: IVsLogsOperatorDesc = {
    symbol: '^=',
    desc: 'starts with',
};

export type TPropertyTypeKeys = 'number' | 'string' | 'boolean';

export const typeToOperatorMapping: Record<TPropertyTypeKeys, IVsLogsOperatorDesc[]> = {
    number: [equalTo, notEqualTo, greaterThan, greaterThanOrEqualTo, lessThan, lessThanOrEqualTo],
    string: [equalTo, notEqualTo, startsWith, contains, doesNotContain],
    boolean: [equalTo, notEqualTo],
};
