/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CinematicModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import './cinematic-header.component.less';

/**
 * @description
 *     Component to display a header in the cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'cinematic-header',
    templateUrl: './cinematic-header.component.html',
})
export class CinematicHeaderComponent {
    /**
     * Text to show in the header, ex. the title of the view.
     * Hidden when a custom header is used.
     */
    @Input()
    public headerText ?= '';

    /**
     * Called when the user clicks the close button.
     */
    @Output()
    public onClose = new EventEmitter();

    /**
     * Handler for clicking the close button.
     */
    public handleClose(): void {
        this.onClose.emit();
    }
}
