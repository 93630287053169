/** @module BotDetectionPolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';
import { BotMappingModalComponent } from 'ng/modules/bot';
import { BotMapping } from 'object-types';
import { BOT_MAPPING_ITEM_TOKEN } from '../bot-detection-policy.tokens';

/**
 * @author Akul Aggarwal
 */
export class BotMappingCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'botmapping',
            objectType: BotMapping,
            permissionName: AviPermissionResource.PERMISSION_BOTDETECTIONPOLICY,
            windowElement: BotMappingModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(BOT_MAPPING_ITEM_TOKEN);
    }
}

BotMappingCollection.ajsDependencies = [
    BOT_MAPPING_ITEM_TOKEN,
];

export type TBotMappingCollection = typeof BotMappingCollection;
