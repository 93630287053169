/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';

/**
 * InterceptorHandler calls the intercept method of the
 * HttpInterceptor in the chain and returns the result.
 */
export class AlbInterceptorHandler implements HttpHandler {
    constructor(
        private readonly next: HttpHandler,
        private readonly interceptor: HttpInterceptor,
    ) {}

    /**
     * Executes interceptor's intercept method and
     * passes next HttpHandler (for next interceptor).
     * @override
     */
    public handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req, this.next);
    }
}
