/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { isUndefined } from 'underscore';

/**
 * @ngdoc component
 * @name  subheader
 * @param {Item?} - Item might be passed to figure out which tabs to show or hide.
 * @description
 *
 *     Subheader component for the subheader navbar below the main application navbar.
 */
//TODO rename
class SubheaderController {
    constructor(
        $state,
        $transitions,
        $stateParams,
        systemInfoService,
        infraCloudState,
        appStateTree,
        stateManagerService,
    ) {
        this.$state = $state;
        this.$transitions_ = $transitions;
        this.$stateParams = $stateParams;
        this.systemInfoService_ = systemInfoService;
        this._infraCloudState = infraCloudState;
        this.appStateTree = appStateTree;
        this.stateManagerService = stateManagerService;

        /**
         * Set of parent states for which metric controls are not shown in the main header.
         */
        this.parentStatesWithoutMetricControl = new Set([
            'authenticated.application.virtualservice-detail',
            'authenticated.infrastructure.cloud.serviceengine-detail',
            'authenticated.application.pool-detail',
            'authenticated.application.gslbservice-detail',
        ]);
    }

    $onInit() {
        this.setProperties_();
        this.showTimeframe = this.getTimeframeVisibility_();
        this.showMetricSelector = this.getMetricSelectorVisibility_();

        this.destroyTransitionListener = this.$transitions_.onSuccess({}, () => {
            if (this.parent_.name !== this.getParent_().name) {
                this.setProperties_();
            }

            this.showTimeframe = this.getTimeframeVisibility_();
            this.showMetricSelector = this.getMetricSelectorVisibility_();
        });
    }

    /**
     * Returns true if the header should show the Metric selector dropdown (display value type).
     * @return {boolean}
     * @protected
     */
    getMetricSelectorVisibility_() {
        return this.$state.current.data?.tfGroup !== 'logs' || false;
    }

    /**
     * Sets properties used by the template along with the parent state name. States get reset
     * only if the parent name changes.
     */
    setProperties_() {
        this.parent_ = this.getParent_();
        this.states = this.getStates_();
    }

    /**
     * Returns the parent state.
     * @return {Object}
     */
    getParent_() {
        return this.$state.get(this.$state.current.data.parentState);
    }

    /**
     * Returns true if the timeframe should be shown in the subheader.
     * @return {Boolean}
     */
    getTimeframeVisibility_() {
        return this.$state.current.data?.showTimeframe || this.parent_?.data?.showTimeframe;
    }

    /**
     * Returns true if tab should be hidden due to special circumstances (ex. only show if GSLB
     * config exists), unrelated to permissions.
     * @param  {Object} state - Name of the state defined in state tree.
     * @return {Boolean}
     */
    //TODO use some custom state property to put checks in along with
    // resolvable Item shared by all the nested states
    isHidden(state) {
        const { name: stateName } = state;

        switch (stateName) {
            case 'authenticated.profile.profiles.gslb-healthmonitor-list':
                return !this.haveGSLBConfig();

            case 'authenticated.infrastructure.gslb.upload-geo-files':
            case 'authenticated.infrastructure.gslb.geo-profile':
            case 'authenticated.infrastructure.gslb.federation-checkpoints':
                return !this.systemInfoService_.localSiteIsGSLBLeader;

            case 'authenticated.application.virtualservice-detail.clientinsight':
                return !this.item || !this.item.isHTTP();

            case 'authenticated.application.virtualservice-detail.dns-records':
                return !this.item || !this.item.isDNS();

            case 'authenticated.application.virtualservice-detail.WAF':
                return !this.item.hasWAFPolicy();

            default:
                return false;
        }
    }

    /**
     * Sets the array of states to be shown in the template.
     * For App level header, it's the parent level objects.
     */
    getStates_() {
        if (this.isAppLevel) {
            return this.appStateTree;
        } else {
            return this.parent_.children.filter(child => !isUndefined(child.title));
        }
    }

    /**
     * Returns true if the GSLB Health Monitors tab should be shown in the subheader.
     * @return {boolean}
     */
    haveGSLBConfig() {
        return this.systemInfoService_.haveGSLBConfig();
    }

    /**
     * Returns true if metric value and timeframe selectors should be shown in the subheader.
     * @return {boolean}
     */
    isMetricControlsShown() {
        const { current: { data } } = this.$state;
        const { parentState } = data;

        return this.showTimeframe &&
            !(this.isAppLevel && this.parentStatesWithoutMetricControl.has(parentState));
    }

    $onDestroy() {
        this.destroyTransitionListener();
    }
}

SubheaderController.$inject = [
    '$state',
    '$transitions',
    '$stateParams',
    'systemInfoService',
    'infraCloudState',
    'appStateTree',
    'stateManagerService',
];

angular.module('aviApp').component('subheader', {
    controller: SubheaderController,
    templateUrl: 'src/components/common/subheader/subheader.html',
    bindings: {
        item: '<?',
        isAppLevel: '<?',
    },
});
