/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { JWTClaimType } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { JwtClaimMatchConfigItem } from
    'ajs/modules/match/factories/jwt-claim-match.config-item.factory';
import { StringMatchConfigItem } from
    'ajs/modules/match/factories/string-match.config-item.factory';
import * as l10n from './jwt-claim-match-config.l10n';
import './jwt-claim-match-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *      JwtClaimMatch Configuration component.
 *      (cannot be used as individual match ie doesnt have match wrapper).
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'jwt-claim-match-config',
    templateUrl: './jwt-claim-match-config.component.html',
})
export class JwtClaimMatchConfigComponent implements OnInit {
    /**
     * JwtClaimMatchConfigItem instance.
     */
    @Input()
    public editable: JwtClaimMatchConfigItem;

    /**
     * Unique Index to generate ID.
     */
    @Input()
    public id: string | number;

    /**
     * Unique Index to generate ID.
     */
    @Input()
    public showDeleteIcon = false;

    /**
     * EventEmitter for Claim delete.
     */
    @Output()
    public onDeleteClaim = new EventEmitter<JwtClaimMatchConfigItem>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object type to be used in template.
     */
    public objectType: string;

    /**
     * JWTClaimType enum hash.
     */
    public readonly jwtClaimTypeHash = {
        [JWTClaimType.JWT_CLAIM_TYPE_STRING]: JWTClaimType.JWT_CLAIM_TYPE_STRING,
        [JWTClaimType.JWT_CLAIM_TYPE_BOOL]: JWTClaimType.JWT_CLAIM_TYPE_BOOL,
        [JWTClaimType.JWT_CLAIM_TYPE_INT]: JWTClaimType.JWT_CLAIM_TYPE_INT,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on JwtClaimType change.
     */
    public onTypeChange(): void {
        const { type } = this.editable.config;

        delete this.editable.config.bool_match;
        delete this.editable.config.int_match;
        delete this.editable.config.string_match;

        if (type === JWTClaimType.JWT_CLAIM_TYPE_STRING) {
            this.editable.config.string_match =
                this.editable.createChildByField('string_match') as StringMatchConfigItem;
        }
    }

    /**
     * Handler for JWT claim delete.
     */
    public deleteClaim(): void {
        this.onDeleteClaim.emit(this.editable);
    }
}
