/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud';
const componentName = 'verify-cloud-connector-user-host-modal-service';
const prefix = `${moduleName}.${componentName}`;

export const modalName = `${prefix}.modalName`;
export const verifyHostBreadcrumbTitle = `${prefix}.verifyHostBreadcrumbTitle`;
export const cleanUpBreadcrumbTitle = `${prefix}.cleanUpBreadcrumbTitle`;

export const ENGLISH = {
    [verifyHostBreadcrumbTitle]: 'Verify Host',
    [cleanUpBreadcrumbTitle]: 'Cleanup',
};
