/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { HealthMonitorHttp } from 'object-types';
import { IHealthMonitorSSLAttributes } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IHealthMonitorHttpConfig } from '../healthmonitor.types';

/**
 * @description Health Monitor Http Config Item.
 * @author Rajawant Prajapati
 */
export class HealthMonitorHttpConfigItem extends MessageItem<IHealthMonitorHttpConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HealthMonitorHttp,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Returns ssl_attributes Message Item.
     */
    public get sslAttributes(): MessageItem<IHealthMonitorSSLAttributes> {
        const { ssl_attributes: sslAttributes } = this.config;

        return sslAttributes;
    }

    /**
     * Returns true if any field is present in https_monitor#ssl_attributes, either an
     * server_name, 'ssl_profile_ref', 'pki_profile_ref', or 'ssl_key_and_certificate_ref'.
     */
    public hasSslAttributes(): boolean {
        const { ssl_attributes: sslAttributes } = this.config;

        return sslAttributes &&
            Object.values(sslAttributes.config).some(value => Boolean(value));
    }

    /**
     * Method to add ssl_attributes Message Item.
     */
    public addSslAttributes(): void {
        this.safeSetNewChildByField('ssl_attributes');
    }

    /**
     * Method to remove ssl_attributes.
     */
    public clearSslAttributes(): void {
        delete this.config.ssl_attributes;
    }
}
