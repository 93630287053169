/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsWrapperModule
 * @preferred
 */

import angular from 'angular';
import { vsLogsWrapperPageOptions } from './components/vs-logs-wrapper-page.component';

const module = angular.module('avi/vs-logs-wrapper');

const components = [
    {
        name: 'vsLogsWrapper',
        options: vsLogsWrapperPageOptions,
    },
];

components.forEach(({ name, options }) => module.component(name, options));
