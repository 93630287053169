/**
 * @module SsoPolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IAuthenticationAction,
    IAuthenticationRule,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { AuthenticationRule } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { DefaultValues } from 'ajs/modules/core/services/default-values.service';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { AuthenticationRuleModalComponent }
    from 'ng/modules/sso-policy/components/authentication-rule-modal';
import { SchemaService } from 'ajs/modules/core/services/schema-service/schema.service';
import { AuthenticationMatchConfigItem } from './authentication-match.config-item.factory';
import * as l10n from '../sso-policy.l10n';

type TAuthenticationRulePartial = Omit<IAuthenticationRule, 'action' | 'match'>;

interface IAuthenticationRuleConfig extends TAuthenticationRulePartial {
    action?: MessageItem<IAuthenticationAction>;
    match?: AuthenticationMatchConfigItem;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description AuthenticationRule ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class AuthenticationRuleConfigItem extends
    withFullModalMixin(MessageItem)<IAuthenticationRuleConfig> {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
        'schemaService',
    ];

    private readonly l10nService: L10nService;

    private readonly schemaService: SchemaService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthenticationRule,
            windowElement: AuthenticationRuleModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
        this.schemaService = this.getAjsDependency_('schemaService');
    }

    /**
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IAuthenticationRuleConfig> {
        const defaultValues: DefaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /**
     * Getter for AuthenticationMatchConfigItem.
     */
    public get match(): AuthenticationMatchConfigItem {
        return this.config.match;
    }

    /**
     * Getter for action configItem.
     */
    public get action(): MessageItem<IAuthenticationAction> {
        return this.config.action;
    }

    /**
     * Clears flatProps so that original config will not be over-written during
     * IpAddrMatchConfigItem's modifyConfigDataBeforeSave call.
     * @override
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { match } = this.config;

        if (match) {
            const { client_ip: clientIp } = match.config;

            if (clientIp) {
                clientIp.clearFlatProps();
            }
        }
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['match'];
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.authenticationRuleModalBreadcrumbTitle);
    }

    /**
     * Returns AuthenticationAction type in human readable format.
     */
    public get authenticationActionType(): string {
        if (!this.action.config.type) {
            return '';
        }

        return this.schemaService.getEnumValueDescription(
            'AuthenticationActionEnum',
            this.action.config.type,
        );
    }
}
