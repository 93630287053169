/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-save-search-modal';
const prefix = `${moduleName}.${componentName}`;

export const saveSearchLabel = `${prefix}.saveSearchLabel`;
export const nameAlreadyExistsErrorLabel = `${prefix}.nameAlreadyExistsErrorLabel`;

export const ENGLISH = {
    [saveSearchLabel]: 'Save Search',
    [nameAlreadyExistsErrorLabel]: 'This search name already exists.',
};
