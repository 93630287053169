/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('avi/serviceengine').factory('SEGroupFlavor', [
'Item', function(Item) {
    /**
     * @constructor
     * @extends module:avi/dataModel.Item
     * @memberOf module:avi/serviceengine
     * @description Flavor {@link module:avi/dataModel.Item} for
     *     {@link module:avi/serviceengine.SEGroup}.
     * @author Akul, Alex Malitsky
     */
    class SEGroupFlavor extends Item {
        /** @override */
        isEditable() {
            return false;
        }

        /**
         * For historical reasons backend is treating name field as id.
         * @override
         **/
        getIdFromData_(data) {
            return data && data.config && data.config.name;
        }

        /** @override */
        getName() {
            const config = this.getConfig();

            if (!config) {
                return '';
            }

            const addNotRecommended = 'is_recommended' in config && !config.is_recommended;

            if (addNotRecommended) {
                return `${config.name} (not recommended)`;
            }

            return config.name;
        }
    }

    //not used for API calls
    SEGroupFlavor.prototype.objectName = 'segroup-flavor';

    return SEGroupFlavor;
}]);
