/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'gslb';
const componentName = 'gslb-federation-checkpoints-list';

const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const timestampLabel = `${prefix}.timestampLabel`;
export const setToActiveLabel = `${prefix}.setToActiveLabel`;
export const activeCheckpointLabel = `${prefix}.activeCheckpointLabel`;
export const confirmMessage = `${prefix}.confirmMessage`;

export const ENGLISH = {
    [headerLabel]: 'Federation Checkpoints',
    [timestampLabel]: 'Timestamp',
    [setToActiveLabel]: 'Set To Active',
    [activeCheckpointLabel]: 'Active Checkpoint',
    [confirmMessage]: 'Are you sure you want to make {0} the active checkpoint?',
};
