/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'security';

export const authProfileModalBreadcrumbTitle = `${prefix}.authProfileModalBreadcrumbTitle`;

export const ENGLISH = {
    [authProfileModalBreadcrumbTitle]: 'Auth Profile',
};
