/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import './credentials-verification-dialog.component.less';
import * as l10n from './credentials-verification-dialog.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const BACKDROP_CLICK_EVENT = 'backdrop-click';

type TLoadingState = 'default' | 'loading';

/**
 * @description Credentials verification dialog component.
 * @author alextsg
 */
@Component({
    selector: 'credentials-verification-dialog',
    templateUrl: './credentials-verification-dialog.component.html',
})
export class CredentialsVerificationDialogComponent {
    /**
     * If true, the Connect button is disabled.
     */
    @Input()
    public disableSubmit = false;

    /**
     * Size of the modal dialog. Defaults to 'default' which is basically "medium".
     */
    @Input()
    public size = 'default';

    /**
     * Errors to be shown on the top of the dialog.
     */
    @Input()
    public errors: string;

    /**
     * Busy flag. If true, disables the submit button and shows a spinner.
     */
    @Input('busy')
    private set setLoadingState(busy: boolean) {
        this.loadingState = busy ? 'loading' : 'default';
    }

    /**
     * Label for submit button.
     */
    @Input()
    public submitButtonLabel: string;

    /**
     * Called when the user cancels the dialog.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Called when the user clicks the submit button.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Layout for clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Loading state of the submit button. If set to 'loading', shows a spinner.
     */
    public loadingState: TLoadingState = 'default';

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Listens for keydown events. This is set here to stop propagating the escape key event to
     * other event listeners like FullModalConfig.
     */
    @HostListener('keydown.escape', ['$event'])
    private onKeyDown(event: KeyboardEvent): void {
        event.stopPropagation();
        this.handleCancel();
    }

    /**
     * Called when the cds-modal detects a close event. Closing by clicking the backdrop is
     * disabled.
     */
    public handleClose($event: Event): void {
        const { detail } = $event as CustomEvent;

        if (detail !== BACKDROP_CLICK_EVENT) {
            this.handleCancel();
        }
    }

    /**
     * Called to emit the onCancel event.
     */
    public handleCancel(): void {
        this.onCancel.emit();
    }

    /**
     * Called to emit the onSubmit event. Called when the submit button is clicked.
     */
    public handleSubmit(): void {
        this.onSubmit.emit();
    }

    /**
     * Returns Label for submit button.
     */
    public getSubmitButtonLabel(): string {
        return this.submitButtonLabel ||
            this.l10nService.getMessage(this.l10nKeys.connectButtonLabel);
    }
}
