/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'ping-access-policy-settings';
const prefix = `${moduleName}.${componentName}`;

export const ssoPolicyInputLabel = `${prefix}.ssoPolicyInputLabel`;
export const ssoPolicyInputPlaceholder = `${prefix}.ssoPolicyInputPlaceholder`;
export const createSsoPolicyButtonLabel = `${prefix}.createSsoPolicyButtonLabel`;

export const ENGLISH = {
    [ssoPolicyInputLabel]: 'SSO Policy',
    [ssoPolicyInputPlaceholder]: 'Select SSO Policy',
    [createSsoPolicyButtonLabel]: 'Create SSO Policy',
};
