/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorRadius,
} from 'object-types';

import { IHealthMonitorRadius } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as l10n from './radius-health-monitor-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Config Component for Radius Health Monitor Type.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'radius-health-monitor-config',
    templateUrl: './radius-health-monitor-config.component.html',
})
export class RadiusHealthMonitorConfigComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorRadius,
    };

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for Radius Health Monitor message item.
     */
    public get radiusMonitor(): MessageItem<IHealthMonitorRadius> {
        const { radiusMonitor } = this.editable;

        return radiusMonitor;
    }
}
