/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

const imageInventoryDataSources = {
    list: {
        source: 'ListCollDataSource',
        transformer: 'ListDataTransformer',
        transport: 'ListDataTransport',
        fields: [
            'config',
        ],
    },
};

interface IImageCollectionArgs {
    permissionName?: string;
    objectName?: string;
    allDataSources?: {};
}

/**
 * @alias ImageCollection
 * @description
 *     Collection of {@link Image} items.
 * @author Zhiqian Liu
 */
export class ImageCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs: IImageCollectionArgs = {
            ...args,
            permissionName: 'PERMISSION_IMAGE',
        };

        if (extendedArgs.objectName === 'image-raw-inventory') {
            extendedArgs.allDataSources = imageInventoryDataSources;
        } else {
            extendedArgs.objectName = 'image';
        }

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('Image');
    }
}

ImageCollection.ajsDependencies = [
    'Image',
];
