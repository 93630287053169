/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

class WafRulesCollapsibleListButtonController {
    constructor() {
        /** @type {boolean} */
        this.expanded = false;

        /** @type {CollapsibleListController} */
        this.parent = null;

        /** @type {Function|null} */
        this.onToggle = null;
    }

    /**
     * Expands or collapses toggle button based on current state.
     */
    toggle() {
        this.expanded = !this.expanded;

        if (this.expanded) {
            this.parent.expand();
        } else {
            this.parent.collapse();
        }

        if (angular.isFunction(this.onToggle)) {
            this.onToggle.call(this);
        }
    }
}

/**
 * @ngdoc component
 * @requires collapsibleList
 * @param {Function=} onToggle - Callback when button is clicked.
 * @description
 *      Toggle button used for collapsibleList directive.
 */
angular.module('aviApp').component('wafRulesCollapsibleListButton', {
    controller: WafRulesCollapsibleListButtonController,
    bindings: {
        onToggle: '&?',
    },
    require: {
        parent: '^wafRulesCollapsibleList',
    },
    templateUrl: 'src/components/common/collapsible-list/waf-rule-groups-collapsible-list/' +
            'waf-rule-groups-collapsible-list-button.html',
});
