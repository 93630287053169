/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SystemModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { SystemConfig } from 'ajs/modules/system/factories/system-config.item.factory';

@Component({
    selector: 'system-settings-preview',
    templateUrl: './system-settings-preview.component.html',
})
export class SystemSettingsPreviewComponent {
    /**
     * System config instance.
     */
    @Input()
    public config: SystemConfig;
}
