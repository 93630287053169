/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AviPermissionResource,
    HealthMonitorType,
} from 'generated-types';

import { HealthMonitor } from 'object-types';
import { HealthMonitorModalComponent }
    from 'ng/modules/health-monitor/components/health-monitor-modal/health-monitor-modal.component';
import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { HEALTH_MONITOR_ITEM_TOKEN } from '../healthmonitor.tokens';

/**
 * @description
 *     Regular collection besides the fact we always need to hide few `internal` health monitor
 *     types such as HEALTH_MONITOR_GSLB and HEALTH_MONITOR_GSLB_LOCAL.
 *
 * @author Rajawant Prajapati
 */
export class HealthMonitorCollection extends Collection {
    public static ajsDependencies = [
        HEALTH_MONITOR_ITEM_TOKEN,
    ];

    constructor(args: any = {}) {
        const { params = {}, ...restArgs } = args;

        const extendedArgs = {
            objectName: 'healthmonitor',
            objectType: HealthMonitor,
            windowElement: HealthMonitorModalComponent,
            permissionName: AviPermissionResource.PERMISSION_HEALTHMONITOR,
            ...restArgs,
            params: {
                is_federated: false,
                type: `${HealthMonitorType.HEALTH_MONITOR_GSLB},HEALTH_MONITOR_GSLB_LOCAL`,
                exclude: 'type',
                ...params,
            },
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(HEALTH_MONITOR_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            type: HealthMonitorType.HEALTH_MONITOR_PING,
            ftp_monitor: undefined,
            ftps_monitor: undefined,
        };
    }
}
