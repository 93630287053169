/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'l4-policy';
const componentName = 'l4-rule-port-match-readonly';
const prefix = `${moduleName}.${componentName}`;

export const portMatchLabel = `${prefix}.portMatchLabel`;

export const ENGLISH = {
    [portMatchLabel]: 'Service Port',
};
