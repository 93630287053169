/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description Component for creating radio buttons from a Schema enum.
 * @example
 *     <enum-radio-buttons
 *         enum="MatchOperation"
 *         [(ngModel)]="value"
 *     >
 *         <label
 *             aviLabelWithTooltip
 *             objectType="SomeObject"
 *             fieldName="some_field"
 *         >
 *             Match Operation
 *         </label>
 *     </enum-radio-buttons>
 * @author alextsg
 */

import {
    Component,
    forwardRef,
    Input,
    OnInit,
    Provider,
} from '@angular/core';

import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';

import {
    IEnumValue,
    SchemaService,
} from 'ajs/modules/core/services/schema-service';

import './enum-radio-buttons.component.less';

const ENUM_RADIO_BUTTONS_COMPONENT_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EnumRadioButtonsComponent),
};

@Component({
    selector: 'enum-radio-buttons',
    templateUrl: './enum-radio-buttons.component.html',
    providers: [
        ENUM_RADIO_BUTTONS_COMPONENT_ACCESSOR,
    ],
})
export class EnumRadioButtonsComponent implements ControlValueAccessor, OnInit {
    /**
     * Schema enum used to create radio button options.
     */
    @Input()
    public enum: string;

    /**
     * List of enum values to be hidden.
     */
    @Input()
    public hiddenEnumValues: string[] = [];

    /**
     * Usually needed as addition when using ngModel attr, but used here for unique id as well.
     */
    @Input()
    public name: string;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /*
     * Sets the noMarginTop property.
     */
    @Input('noMarginTop')
    private set setNoMarginTop(noMarginTop: boolean | '') {
        this.noMarginTop = noMarginTop === '' || noMarginTop;
    }

    /**
     * If true, removes the margin-top from clr-radio-container.
     */
    public noMarginTop = false;

    /**
     * Enum properties retrieved from schemaService. Used for rendering radio button options.
     */
    public enumValues: IEnumValue[];

    /**
     * Used as the ngModel value for the radio buttons.
     */
    public modelValue: IEnumValue['value'];

    constructor(private readonly schemaService: SchemaService) {}

    /** @override */
    public ngOnInit(): void {
        this.enumValues = this.schemaService.getEnumValues(this.enum);

        // Filter out enum values to be hidden.
        if (this.hiddenEnumValues.length) {
            this.enumValues = this.enumValues
                .filter(enumValue => !this.hiddenEnumValues.includes(enumValue.value));
        }
    }

    /**
     * Trackby function for enumValues.
     */
    public trackByEnumValue(index: number, { value }: IEnumValue): IEnumValue['value'] {
        return value;
    }

    /**
     * Change handler when user toggles radio button selection.
     */
    public handleChange(): void {
        this.onChange(this.modelValue);
    }

    /**
     * Writes the input display value with formatted ipAddr string.
     */
    public writeValue(value: IEnumValue['value']): void {
        this.modelValue = value;
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange = (value: IEnumValue['value']): void => {};

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch = (): void => {};
}
