/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { StringService } from 'ajs/modules/core/services/string-service/string.service';

import {
    IAzureConfiguration,
    IAzureNetworkInfo,
} from 'generated-types';

import { AzureConfiguration } from 'object-types';

const LOCATIONS_API = '/api/azure-get-locations';
const RESOURCE_GROUPS_API = '/api/azure-get-resource-groups';
const VIRTUAL_NETWORKS_API = '/api/azure-get-virtual-networks';
const DISK_ENCRYPTION_SETS_API = '/api/azure-list-des';
const SUBNETS_API = '/api/azure-get-subnets';

type TAzureConfigurationPartial = Omit<IAzureConfiguration, 'network_info'>;

interface IAzureConfig extends TAzureConfigurationPartial {
    'network_info': RepeatedMessageItem<MessageItem<IAzureNetworkInfo>>;
}

/**
 * Type of promise that will be returned by fetchResourceGroup method.
 */
interface IResourceGroup {
    name: string;
}

/**
 * Type of promise that will be returned by fetchLocations method.
 */
interface ILocation {
    name: string;
    display_name: string;
}

/**
 * Type of promise that will be returned by fetchVirtualNetworks method.
 */
interface IVirtualNetwork {
    cidr: string[];
    id: string;
    location: string;
    name: string;
}

/**
 * Type of promise that will be returned by fetchSubNets method.
 */
interface ISubNets {
    cidr: string;
    id: string;
    name: string;
}

/**
 * Type of promise that will be returned by fetchDiskEncryptionSets method.
 */
interface IDiskEncyptionSet {
    id: string;
    name: string;
    resource_group: string;
}

/**
 * Type of payload data sent to backend.
 */
interface IPayload {
    subscription_id: string;
    cloud_credentials_uuid?: string;
    location?: string;
    vnet_id?: string;
}

/**
 * Type of request config.
 */
interface IRequestConfig {
    url: string;
    method: HttpMethod;
    data: IPayload;
    params?: IPayload;
}

/**
 * Holds the constant string values for DNS Type radio buttons.
 */
export enum DefaultDnsTypeValues {
    NONE = 'NONE',
    AZURE = 'AZURE',
    OTHER = 'OTHER',
}

/**
 * @description
 *
 *   AzureConfiguration MessageItem.
 *
 * @author Sarthak kapoor
 */

export type TAzureLoginCredentials =
 Pick<IAzureConfiguration, 'subscription_id' | 'cloud_credentials_ref'>;

export class AzureConfigurationConfigItem extends MessageItem<IAzureConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'stringService',
    ];

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * StringService instance to work with string values.
     */
    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: AzureConfiguration,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.stringService = this.getAjsDependency_('stringService');
    }

    /**
     * Sets the cloud_credentials_ref and subscription_id properties on the config.
     */
    public setAzureCredentials(azureCredentials: TAzureLoginCredentials): void {
        const config = this.getConfig();

        const {
            cloud_credentials_ref: cloudCredentialsRef,
            subscription_id: subscriptionId,
        } = azureCredentials;

        config.subscription_id = subscriptionId;

        // cloudCredentialsRef will be undefined if user selects MSI Authentication option.
        if (cloudCredentialsRef) {
            config.cloud_credentials_ref = cloudCredentialsRef;
        } else if (config.cloud_credentials_ref) {
            delete config.cloud_credentials_ref;
        }
    }

    /**
     * Sets the default value for management_network_id field or,
     * deletes the property if Use Dedicated Management Interface option is not selected.
     */
    public setManagementNetworkId(useDedicatedManagementInterface: boolean): void {
        const { networkInfoConfig } = this;

        if (useDedicatedManagementInterface) {
            networkInfoConfig.management_network_id = networkInfoConfig.se_network_id;
        } else {
            delete networkInfoConfig.management_network_id;
        }
    }

    /**
     * Fetches list of locations to be displayed as dropdown options.
     */
    public async fetchLocations(): Promise<ILocation[]> {
        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(LOCATIONS_API);

            const response = await this.httpWrapper.request(requestConfig);
            const { locations = [] } = response.data;

            return locations;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of resource groups to be displayed as dropdown options.
     */
    public async fetchResourceGroups(): Promise<IResourceGroup[]> {
        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(RESOURCE_GROUPS_API);

            const response = await this.httpWrapper.request(requestConfig);
            const { resource_groups: resourceGroups = [] } = response.data;

            return resourceGroups;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of virtual networks.
     */
    public async fetchVirtualNetworks(): Promise<IVirtualNetwork[]> {
        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(VIRTUAL_NETWORKS_API);

            const response = await this.httpWrapper.request(requestConfig);
            const { vnets = [] } = response.data;

            return vnets;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of subnets.
     */
    public async fetchSubNets(): Promise<ISubNets[]> {
        this.busy = true;
        this.errors = null;

        try {
            const requestConfig = this.getRequestConfig(SUBNETS_API);

            const response = await this.httpWrapper.request(requestConfig);
            const { subnets = [] } = response.data;

            return subnets;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Fetches list of Disk Encryption Sets.
     */
    public async fetchDiskEcnryptionSets(): Promise<IDiskEncyptionSet[]> {
        this.busy = true;
        this.errors = null;

        try {
            const {
                data,
                ...requestConfig
            } = this.getRequestConfig(DISK_ENCRYPTION_SETS_API);

            requestConfig.params = { ...data };

            const response = await this.httpWrapper.request(requestConfig);
            const diskEncryptionSets = response.data['disk-encryption-sets'] || [];

            return diskEncryptionSets;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            this.busy = false;
        }
    }

    /**
     * Removes location and resource group fields when credentials are reset.
     */
    public removeLocationAndResourceGroupFields(): void {
        const config = this.getConfig();

        delete config.location;
        delete config.resource_group;

        this.removeLocationBasedFields();
    }

    /**
     * Removes location based fields on change of credentials.
     */
    public removeLocationBasedFields(): void {
        const config = this.getConfig();

        const { networkInfoConfig } = this;

        delete config.des_id;
        delete networkInfoConfig.virtual_network_id;
        delete networkInfoConfig.se_network_id;
    }

    /**
     * Removes Virtual Networks based fields on change of Vnet selection.
     */
    public removeVnetBasedFields(): void {
        const { networkInfoConfig } = this;

        delete networkInfoConfig.se_network_id;
        delete networkInfoConfig.management_network_id;
    }

    /**
     * Removes availability_zones field from config if Use Enhanced HA option is not selected.
     */
    public removeAvailabilityZones(): void {
        const config = this.getConfig();

        delete config.availability_zones;
    }

    /**
     * @override
     * Sets the networkInfo config for create flow.
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { network_info: networkInfo } = this.getConfig();

        if (!networkInfo.count) {
            networkInfo.add();
        }
    }

    /**
     * Returns requestConfig for fetching data from backend depending upon url type passed.
     */
    private getRequestConfig(requestUrl: string): IRequestConfig {
        const {
            cloud_credentials_ref: cloudCredentialsRef,
            subscription_id: subscriptionId,
            location,
        } = this.config;

        const payload: IPayload = {
            subscription_id: subscriptionId,
        };

        if (cloudCredentialsRef) {
            payload.cloud_credentials_uuid = this.stringService.slug(cloudCredentialsRef);
        }

        switch (requestUrl) {
            case VIRTUAL_NETWORKS_API:
                payload.location = location;
                break;

            case DISK_ENCRYPTION_SETS_API:
                payload.location = location;
                break;

            case SUBNETS_API: {
                const { networkInfoConfig } = this;

                payload.vnet_id = networkInfoConfig.virtual_network_id;
                payload.location = location;
            }
        }

        return {
            url: requestUrl,
            method: requestUrl === DISK_ENCRYPTION_SETS_API ? HttpMethod.GET : HttpMethod.POST,
            data: payload,
        };
    }

    private get networkInfoConfig(): IAzureNetworkInfo {
        const { network_info: networkInfo } = this.getConfig();

        return networkInfo.at(0).config;
    }
}
