/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as statusTooltipTemplate from './partials/cloud-status-icon-tooltip-template.partial.html';
import { CloudService } from '../../../../js/services/cloud-service/cloudService';

/**
 * @memberOf module:avi/cloud
 * @constructor
 * @mixes module:avi/cloud.cloudStatusIconBindings
 * @author Akul Aggarwal
 */
class CloudStatusIconController {
    constructor(cloudService) {
        this.cloudService_ = cloudService;

        /**
         * Actual template instantiating component, passed into attribute: 'tooltip2-template'.
         * @type {string}
         */
        this.statusTooltipTemplate = statusTooltipTemplate;
    }

    /**
     * Returns class which determines status/color of icon-circle.
     * @return {string}
     */
    getStatusClass() {
        return CloudService.getStatusIconClass(this.cloud.getRuntimeData().state);
    }

    /**
     * Handles cloud status icon click.
     * @param {Event} $event - Mouse event.
     */
    handleClick($event) {
        $event.stopPropagation();

        if (this.cloud.getVtype() === 'CLOUD_VCENTER') {
            this.cloudService_.openCloudStatusDetails(this.cloud.id);
        }
    }
}

CloudStatusIconController.$inject = [
    'cloudService',
];

const componentTag = 'cloud-status-icon';
const templateUrl =
    `src/components/infrastructure/clouds/${componentTag}/${componentTag}.component.html`;

/**
 * @mixin cloudStatusIconBindings
 * @memberOf module:avi/cloud
 * @property {module:avi/cloud.Cloud} cloud - cloud instance
 */

/**
 * @name cloudStatusIconComponent
 * @ngdoc component
 * @memberOf module:avi/cloud
 * @author Akul Aggarwal
 * @property {module:avi/cloud.cloudStatusIconBindings} bindings
 * @property {module:avi/cloud.CloudStatusIconController} controller
 * @description Colored status icon for each Cloud row in table.
 */
angular.module('avi/cloud').component('cloudStatusIcon', {
    bindings: {
        cloud: '<',
    },
    controller: CloudStatusIconController,
    templateUrl,
});
