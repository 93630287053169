/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './bot-detection-result-match.component.l10n';

import {
    IBotDetectionMatch,
    IClassification,
    IMatchRule,
} from './bot-detection-result-match.types';

import template from './bot-detection-result-match.html';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Constant for Controller name.
 */
export const BOT_DETECTION_RESULT_MATCH_CONTROLLER_TOKEN = 'botDetectionResultMatch';

/**
 * Constant to hold bot detection match id.
 */
const BOT_DETECTION_RESULT = 'bot_detection_result';

/**
 * @ngdoc controller
 * @name BotDetectionResultMatchController
 * @description BotDetectionResultMatchController, adds new Bot Management Rule Match.
 * @author Sarthak Kapoor
 */

class BotDetectionResultMatchController {
    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Current object bound from parent controller.
     */
    public current: IMatchRule;

    /**
     * Called when delete is clicked.
     */
    public onDelete: (match: IBotDetectionMatch) => void;

    /**
     * Bot Detection match filtered out from current object.
     */
    private botDetectionMatch: IBotDetectionMatch;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public $onInit(): void {
        this.setBotDetectionMatch();
    }

    /**
     * Calls Parent function to handle deletion of Match.
     */
    public handleDelete(match: IBotDetectionMatch): void {
        this.onDelete(match);
    }

    /**
     * Adds new classification to the classifications array of current object.
     */
    public addClassification(classification: IClassification): void {
        this.botDetectionMatch.value.classifications.push(classification);
    }

    /**
     *
     */
    public removeClassification(index: number): void {
        this.botDetectionMatch.value.classifications.splice(index, 1);
    }

    /**
     * Filters out Bot Detection Match from a list of matches from Current Object.
     */
    private setBotDetectionMatch(): void {
        this.botDetectionMatch = this.current.matchEdit.find(
            match => match.id === BOT_DETECTION_RESULT,
        );
    }
}

BotDetectionResultMatchController.$inject = [
    'l10nService',
];

export const botDetectionResultMatchComponentOptions = {
    bindings: {
        current: '=',
        match: '=',
        onDelete: '&',
    },
    controller: BotDetectionResultMatchController,
    template,
};
