/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { extend } from 'angular';
import { isObject } from 'underscore';
import { getResolvablePromise, IResolvablePromise } from 'ng/shared/utils';
import { AviModalService, AviModalType } from './avi-modal';

export interface IAviAjsConfirmBindings {
    message: string;
    headerText: string;
    isWarning?: boolean;
    confirmationText?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
}

/**
 * @description
 *     Used to create a confirmation window as an alternative to $window.confirm. Returns a promise
 *     that is resolved if the user confirms or rejected if the user cancels.
 *
 * @author Aravindh Nagarajan, Zhiqian Liu
 */
export class AviConfirmService {
    constructor(private aviModal: AviModalService) {}

    /**
     * Opens the confirm modal for the user to Confirm or Cancel based on the message parameter.
     */
    public confirm(
        message: IAviAjsConfirmBindings | string,
        isWarning = false,
        headerText = '',
    ): IResolvablePromise<boolean> {
        const promise = getResolvablePromise<boolean>();

        let aviConfirmationComponentBindings = {
            onConfirm: () => promise.resolve(true),
            onCancel: () => promise.reject(false),
        } as any as IAviAjsConfirmBindings;

        if (isObject(message)) {
            aviConfirmationComponentBindings = {
                ...aviConfirmationComponentBindings,
                ...message,
            };
        } else {
            aviConfirmationComponentBindings = {
                ...aviConfirmationComponentBindings,
                message,
                isWarning,
                headerText,
            };
        }

        return this.prompt('avi-ajs-confirm', aviConfirmationComponentBindings, promise);
    }

    /**
     * Compiles a component prompt to the user, used in cases where a user needs to proceed by
     * filling out a small form. Returns a promise so that an action can be chained with the
     * data.
     * @param component - Name of the component to compile.
     * @param data - Optional data to be used in bindings.
     * @param promise - promise object to be returned (optional).
     */
    public prompt(
        component: string,
        data?: Record<string, any>,
        promise: IResolvablePromise<any> = getResolvablePromise<any>(),
    ): IResolvablePromise<any> {
        const bindings = {
            onSubmit: ['data', (data: any) => promise.resolve(data)],
            onCancel: () => promise.reject(false),
        };

        if (isObject(data)) {
            extend(bindings, data);
        }

        this.aviModal.open(
            component,
            bindings,
            'avi-prompt',
            AviModalType.AVI_ALERT_OR_CONFIRM,
        );

        return promise;
    }
}

AviConfirmService.$inject = [
    'AviModal',
];
