/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import './avi-app-level-alert.component.less';
import * as l10n from './avi-app-level-alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Enum for possible status values of the alert bar.
 */
export enum Status {
    NEUTRAL = 'neutral',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    DANGER = 'danger',
    ALT = 'alt',
    LOADING = 'loading',
}

/**
 * @description
 *  App level alert component which makes use of cds-alert.
 *
 * @example
 *  <avi-app-level-alert
 *      messages="['message1', 'message2']"
 *      showAction="true"
 *      actionLabel="Activate"
 *      (onClose)="onAlertClose()"
 *  ></avi-app-level-alert>
 *
 * @author Nisar Nadaf
 */
@Component({
    selector: 'avi-app-level-alert',
    templateUrl: './avi-app-level-alert.component.html',
})
export class AviAppLevelAlertComponent {
    /**
     * Status of the alert bar.
     */
    @Input()
    public status: Status = Status.INFO;

    /**
     * List of messages to be shown.
     */
    @Input()
    public messages? : string[];

    /**
     * Label for action button.
     */
    @Input()
    public actionLabel ?: string;

    /**
     * Flag to allow user to close/dismiss alert. False to hide close icon.
     */
    @Input()
    public closable ?= true;

    /**
     * Called when the user has clicked on action button.
     */
    @Output()
    public onClick = new EventEmitter<void>();

    /**
     * Called when the user has clicked on close button of alert.
     */
    @Output()
    public onClose = new EventEmitter<void>();

    public constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
        this.actionLabel = l10nService.getMessage(l10nKeys.defaultActionBtnLabel);
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Returns true if a onClick handler has been passed in, so we display Action button on alert.
     */
    public get hasActionButton(): boolean {
        return Boolean(this.onClick.observers.length);
    }

    /**
     * Handler for close button click. Emits onClose event.
     */
    public closeAlert(): void {
        this.onClose.emit();
    }

    /**
     * Handler for Action button click. Emits onClick event.
     */
    public handleAction(): void {
        this.onClick.emit();
    }
}
