/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-infoblox-credentials-dialog';
const prefix = `${moduleName}.${componentName}`;

export const setInfobloxCredentialsHeader = `${prefix}.setInfobloxCredentialsHeader`;
export const ipAddressInputLabel = `${prefix}.ipAddressInputLabel`;
export const ipAddressInputPlaceholder = `${prefix}.ipAddressInputPlaceholder`;
export const networkViewInputLabel = `${prefix}.networkViewInputLabel`;
export const networkViewInputPlaceholder = `${prefix}.networkViewInputPlaceholder`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernameInputPlaceholder = `${prefix}.usernameInputPlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordInputPlaceholder = `${prefix}.passwordInputPlaceholder`;
export const dnsViewInputLabel = `${prefix}.dnsViewInputLabel`;
export const dnsViewInputPlaceholder = `${prefix}.dnsViewInputPlaceholder`;

export const ENGLISH = {
    [setInfobloxCredentialsHeader]: 'Set Infoblox Credentials',
    [ipAddressInputLabel]: 'IP Address or FQDN',
    [ipAddressInputPlaceholder]: 'Enter IP Address or FQDN',
    [networkViewInputLabel]: 'Network View',
    [dnsViewInputLabel]: 'DNS View',
    [dnsViewInputPlaceholder]: 'Enter DNS View',
    [networkViewInputPlaceholder]: 'Enter Network View',
    [usernameInputLabel]: 'Username',
    [usernameInputPlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordInputPlaceholder]: 'Enter Password',
};
