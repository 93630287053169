/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable } from '@angular/core';
import { isEmpty } from 'underscore';

import { TVsLogHttpHeadersHash } from '../vs-logs.types';

/**
 * Postfix to make repeated header names unique for the sake of comparison and presenting diff.
 * Used characters are not allowed to be used as a header name in accordance with HTTP 1.1.
 */
export const HTTP_HEADER_NAME_DUPLICATE_POSTFIX = ':://';

/**
 * @description
 *     Service for processing HTTP headers strings from VS logs.
 * @author Zhiqian Liu
 */
@Injectable()
export class VsLogsHttpHeadersStringService {
    /**
     * Split HTTP headers string to individual headers and put them into a hash with header names
     * and header contents. Duplicated header names are added with a postfix of the duplicate
     * counter for diff purpose.
     */
    public static splitHttpHeadersStrToHash(headersStr = ''): TVsLogHttpHeadersHash | undefined {
        const hash = {}; // to rename duplicated header names
        const duplicates = {};
        const lines = headersStr.split('\n');

        let multilineKey = '';

        lines.forEach(line => {
            // skip first colon in the case of pseudo HTTP2 headers
            const colonPos = line.indexOf(':', 1);

            if (colonPos !== -1) {
                let key = line.slice(0, colonPos).trim();

                // this key has already been processed
                if (key in duplicates) {
                    // second encounter - rename original key
                    if (duplicates[key] === 0) {
                        hash[`${key}${HTTP_HEADER_NAME_DUPLICATE_POSTFIX}0`] = hash[key];
                        delete hash[key];
                    }

                    duplicates[key]++;

                    key += `${HTTP_HEADER_NAME_DUPLICATE_POSTFIX}${duplicates[key]}`;
                } else {
                    duplicates[key] = 0;
                }

                multilineKey = key;
                hash[key] = line.slice(colonPos + 1).trim();
            } else if (line && multilineKey) { // multiline values are concatenated
                hash[multilineKey] += line;
            }
        });

        return isEmpty(hash) ? undefined : hash;
    }

    /**
     * Check if the headers string has truncation.
     */
    public static headersStringHasTruncation(headersString: string): boolean {
        if (headersString) {
            const ellipsisPos = headersString.lastIndexOf('...');

            return ellipsisPos !== -1 && ellipsisPos + 3 === headersString.length;
        }

        return false;
    }
}
