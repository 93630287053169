/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vsLogs';
const componentName = 'vs-logs-sidebar';
const prefix = `${moduleName}.${componentName}`;

export const savedSearchesLabel = `${prefix}.savedSearchesLabel`;
export const wafAnalyticsLabel = `${prefix}.wafAnalyticsLabel`;
export const wafTagsLabel = `${prefix}.wafTagsLabel`;
export const wafRulesLabel = `${prefix}.wafRulesLabel`;
export const wafGroupsLabel = `${prefix}.wafGroupsLabel`;
export const wafLatencyLabel = `${prefix}.wafLatencyLabel`;
export const clientAnalyticsLabel = `${prefix}.clientAnalyticsLabel`;
export const browserLabel = `${prefix}.browserLabel`;
export const clientOsLabel = `${prefix}.clientOsLabel`;
export const deviceLabel = `${prefix}.deviceLabel`;
export const locationLabel = `${prefix}.locationLabel`;
export const endToEndLabel = `${prefix}.endToEndLabel`;
export const userIdLabel = `${prefix}.userIdLabel`;
export const loadBalancerAnalyticsLabel = `${prefix}.loadBalancerAnalyticsLabel`;
export const policiesLabel = `${prefix}.policiesLabel`;
export const vsIpAddressLabel = `${prefix}.vsIpAddressLabel`;
export const serviceIpAddressLabel = `${prefix}.serviceIpAddressLabel`;
export const requestAnalyticsLabel = `${prefix}.requestAnalyticsLabel`;
export const sslLabel = `${prefix}.sslLabel`;
export const refererLabel = `${prefix}.refererLabel`;
export const urlPathLabel = `${prefix}.urlPathLabel`;
export const requestTypeLabel = `${prefix}.requestTypeLabel`;
export const responseContentTypeLabel = `${prefix}.responseContentTypeLabel`;
export const significanceAnalyticsLabel = `${prefix}.significanceAnalyticsLabel`;
export const significanceLabel = `${prefix}.significanceLabel`;
export const botAnalyticsLabel = `${prefix}.botAnalyticsLabel`;
export const classificationsLabel = `${prefix}.classificationsLabel`;
export const icapAnalyticsLabel = `${prefix}.icapAnalyticsLabel`;
export const latencyLabel = `${prefix}.latencyLabel`;
export const icapResponseCodeLabel = `${prefix}.icapResponseCodeLabel`;
export const httpResponseCodeLabel = `${prefix}.httpResponseCodeLabel`;
export const violationResolutionsLabel = `${prefix}.violationResolutionsLabel`;
export const violationThreatNamesLabel = `${prefix}.violationThreatNamesLabel`;
export const tcpEndToEndLabel = `${prefix}.tcpEndToEndLabel`;
export const udpEndToEndLabel = `${prefix}.udpEndToEndLabel`;
export const fqdnDomainNameLabel = `${prefix}.fqdnDomainNameLabel`;
export const serverIpAddressLabel = `${prefix}.serverIpAddressLabel`;
export const queryTypeLabel = `${prefix}.queryTypeLabel`;
export const recordSourceLabel = `${prefix}.recordSourceLabel`;
export const gslbServiceNameLabel = `${prefix}.gslbServiceNameLabel`;

export const ENGLISH = {
    [savedSearchesLabel]: 'Saved Searches',
    [wafAnalyticsLabel]: 'WAF Analytics',
    [wafTagsLabel]: 'WAF Tags',
    [wafRulesLabel]: 'WAF Rules',
    [wafGroupsLabel]: 'WAF Groups',
    [wafLatencyLabel]: 'WAF Latency',
    [clientAnalyticsLabel]: 'Client Analytics',
    [browserLabel]: 'Browser',
    [clientOsLabel]: 'Client OS',
    [deviceLabel]: 'Device',
    [locationLabel]: 'Location',
    [endToEndLabel]: 'End to End',
    [userIdLabel]: 'User ID',
    [loadBalancerAnalyticsLabel]: 'Load Balancer Analytics',
    [policiesLabel]: 'Policies',
    [vsIpAddressLabel]: 'VS IP Address',
    [serviceIpAddressLabel]: 'Service IP Address',
    [requestAnalyticsLabel]: 'Request Analytics',
    [sslLabel]: 'SSL',
    [refererLabel]: 'Referer',
    [urlPathLabel]: 'URL Path',
    [requestTypeLabel]: 'Request Type',
    [responseContentTypeLabel]: 'Response Content Type',
    [significanceAnalyticsLabel]: 'Significance Analytics',
    [significanceLabel]: 'Significance',
    [botAnalyticsLabel]: 'Bot Analytics',
    [classificationsLabel]: 'Classifications',
    [icapAnalyticsLabel]: 'ICAP Analytics',
    [latencyLabel]: 'Latency',
    [icapResponseCodeLabel]: 'ICAP Response Code',
    [httpResponseCodeLabel]: 'HTTP Response Code',
    [violationResolutionsLabel]: 'Violation Resolutions',
    [violationThreatNamesLabel]: 'Violation Threat Names',
    [tcpEndToEndLabel]: 'End to End (TCP)',
    [udpEndToEndLabel]: 'End to End (UDP)',
    [fqdnDomainNameLabel]: 'FQDN/Domain Name',
    [serverIpAddressLabel]: 'Server IP Address',
    [queryTypeLabel]: 'Query Type',
    [recordSourceLabel]: 'Record Source',
    [gslbServiceNameLabel]: 'GSLB Service Name',
};
