/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IpamDnsType } from 'generated-types';

/**
 * Hash of IpamDnsProfile Types.
 */
export const ipamDnsProfileTypeHash = {
    [IpamDnsType.IPAMDNS_TYPE_AWS]: IpamDnsType.IPAMDNS_TYPE_AWS,
    [IpamDnsType.IPAMDNS_TYPE_AWS_DNS]: IpamDnsType.IPAMDNS_TYPE_AWS_DNS,
    [IpamDnsType.IPAMDNS_TYPE_AZURE]: IpamDnsType.IPAMDNS_TYPE_AZURE,
    [IpamDnsType.IPAMDNS_TYPE_AZURE_DNS]: IpamDnsType.IPAMDNS_TYPE_AZURE_DNS,
    [IpamDnsType.IPAMDNS_TYPE_CUSTOM]: IpamDnsType.IPAMDNS_TYPE_CUSTOM,
    [IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS]: IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS,
    [IpamDnsType.IPAMDNS_TYPE_GCP]: IpamDnsType.IPAMDNS_TYPE_GCP,
    [IpamDnsType.IPAMDNS_TYPE_INFOBLOX]: IpamDnsType.IPAMDNS_TYPE_INFOBLOX,
    [IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS]: IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS,
    [IpamDnsType.IPAMDNS_TYPE_INTERNAL]: IpamDnsType.IPAMDNS_TYPE_INTERNAL,
    [IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS]: IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
    [IpamDnsType.IPAMDNS_TYPE_OCI]: IpamDnsType.IPAMDNS_TYPE_OCI,
    [IpamDnsType.IPAMDNS_TYPE_OPENSTACK]: IpamDnsType.IPAMDNS_TYPE_OPENSTACK,
    [IpamDnsType.IPAMDNS_TYPE_TENCENT]: IpamDnsType.IPAMDNS_TYPE_TENCENT,
};

export const typesWithoutExpander = new Set([
    IpamDnsType.IPAMDNS_TYPE_AZURE,
    IpamDnsType.IPAMDNS_TYPE_CUSTOM,
    IpamDnsType.IPAMDNS_TYPE_CUSTOM_DNS,
    IpamDnsType.IPAMDNS_TYPE_GCP,
    IpamDnsType.IPAMDNS_TYPE_INFOBLOX,
    IpamDnsType.IPAMDNS_TYPE_INFOBLOX_DNS,
    IpamDnsType.IPAMDNS_TYPE_INTERNAL,
    IpamDnsType.IPAMDNS_TYPE_INTERNAL_DNS,
    IpamDnsType.IPAMDNS_TYPE_TENCENT,
]);
