/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import { HealthMonitorHttp } from 'object-types';
import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorHttpConfigItem }
    from 'ajs/modules/healthmonitor/factories/health-monitor-http.config.item.factory';
import * as l10n from './server-maintenance-mode.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Server Maintenance Mode section for HTTP/HTTPS Health Monitor Types.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'server-maintenance-mode',
    templateUrl: './server-maintenance-mode.component.html',
})
export class ServerMaintenanceModeComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public healthMonitorTypeConfig: HealthMonitorHttpConfigItem;

    /**
     * Http Health monitor object type.
     */
    public readonly objectType = HealthMonitorHttp;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
