/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'waf-crs-list';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleReleaseDate = `${prefix}.columnTitleReleaseDate`;
export const actionTitleDelete = `${prefix}.actionTitleDelete`;
export const newReleaseLabel = `${prefix}.newReleaseLabel`;
export const downloadButtonLabel = `${prefix}.downloadButtonLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleReleaseDate]: 'Release Date',
    [actionTitleDelete]: 'Delete',
    [newReleaseLabel]: 'New Release! {0} Available.',
    [downloadButtonLabel]: 'Download Now',
};
