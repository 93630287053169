/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module NetworkModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    NetworkProfileUnion,
    SCTPProxyProfile,
} from 'object-types';

import { L10nService } from '@vmw/ngx-vip';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ISCTPProxyProfile } from 'generated-types';
import * as l10n from './sctp-proxy-config.l10n';
import './sctp-proxy-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Sctp proxy config component.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'sctp-proxy-config',
    templateUrl: './sctp-proxy-config.component.html',
})
export class SctpProxyConfigComponent {
    @Input()
    public editable: MessageItem<ISCTPProxyProfile>;

    public readonly objectType = {
        SCTPProxyProfile,
        NetworkProfileUnion,
    };

    public readonly l10nKeys = l10nKeys;

    /**
     * Enables Sctp proxy profile parameters.
     */
    public enableSctpProxyProfileParams = false;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
