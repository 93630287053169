/**
 * @module L4PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IL4RuleMatchTarget } from 'generated-types';
import { IpAddrMatchConfigItem } from 'ajs/modules/match';
import {
    PolicyMatchConfigItem,
} from 'ajs/modules/policies/factories/config-items/policy-match.config-item.factory';
import {
    L4RulePortMatchConfigItem,
    L4RuleProtocolMatchConfigItem,
} from '.';

interface IL4RuleMatchTargetConfig
    extends Omit<IL4RuleMatchTarget, 'client_ip' | 'port' | 'protocol'>
{
    client_ip?: IpAddrMatchConfigItem;
    port?: L4RulePortMatchConfigItem;
    protocol?: L4RuleProtocolMatchConfigItem;
}

/**
 * @desc L4RuleMatchTarget MessageItem class.
 * @author Zhiqian Liu
 */
export class L4RuleMatchTargetConfigItem extends PolicyMatchConfigItem<IL4RuleMatchTargetConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'L4RuleMatchTarget',
            ...args,
        };

        super(extendedArgs);
    }
}
