/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for Support components.
 * @preferred
 * @module SupportModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClrIconModule } from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { CaseListPageComponent } from './components/case-list-page/case-list-page.component';
import { CoreModule } from '../core/core.module';
import { caseListProvider } from './ajs-upgraded-providers';

/**
 * @description Angular Module for Support components.
 * @author: Shahab Hashmi
 */
@NgModule({
    declarations: [CaseListPageComponent],
    imports: [
        AviFormsModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrIconModule,
        CommonModule,
        DataGridModule,
        CoreModule,
    ],
    exports: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA,
    ],
    providers: [caseListProvider],
})
export class SupportModule {}
