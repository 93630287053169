/**
 * @module LaunchConfigModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { LaunchConfigModalComponent } from '../../../angular/modules/launch-config';
import { AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN } from '../launch-config.tokens';

/**
 * @description AutoScale Launch Config collection
 *
 * @author Harmeet Kaur
 */
export class AutoScaleLaunchConfigCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'autoscalelaunchconfig',
            windowElement: LaunchConfigModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN);
    }
}

AutoScaleLaunchConfigCollection.ajsDependencies = [
    AUTO_SCALE_LAUNCH_CONFIG_ITEM_TOKEN,
];
