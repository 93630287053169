/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VirtualServiceModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';
import { VHMatch } from 'object-types';

import {
    VHMatchConfigItem,
} from 'ajs/modules/vs/factories/vh-match.config-item.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './vh-match-modal.l10n';
import './vh-match-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for Virtual Hosting Match.
 *
 * @author Abhinesh Gour
 */
@Component({
    selector: 'vh-match-modal',
    templateUrl: './vh-match-modal.component.html',
})
export class VHMatchModalComponent {
    @Input()
    public editable: VHMatchConfigItem;

    /**
     * Indicate if current item is new or already exists.
     */
    @Input()
    public isEditing: boolean;

    /**
     * Existing host names used in current match config.
     * Pass host names to the directive which detects duplicate string values.
     */
    @Input()
    public hostNames: string[] = [];

    /**
     * Current host index in current match config
     * Pass host index to the directive which detects duplicate string values.
     */
    @Input()
    public hostIndex: number;

    /**
     * Fires on modal cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on modal submit.
     */
    @Output()
    public onSubmit = new EventEmitter<VHMatchConfigItem>();

    public readonly objectType = VHMatch;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(private readonly l10nService: L10nService) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit(this.editable);
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }
}
