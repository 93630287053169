/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-snmp-config';
const prefix = `${moduleName}.${componentName}`;

export const snmpLabel = `${prefix}.snmpLabel`;
export const snmpV2RadioLabel = `${prefix}.snmpV2RadioLabel`;
export const snmpV3RadioLabel = `${prefix}.snmpV3RadioLabel`;
export const snmpCommunityInputLabel = `${prefix}.snmpCommunityInputLabel`;
export const snmpCommunityInputPlaceholder = `${prefix}.snmpCommunityInputPlaceholder`;
export const engineIdInputLabel = `${prefix}.engineIdInputLabel`;
export const engineIdInputPlaceholder = `${prefix}.engineIdInputPlaceholder`;
export const authTypeDropdownLabel = `${prefix}.authTypeDropdownLabel`;
export const authTypeDropdownPlaceholder = `${prefix}.authTypeDropdownPlaceholder`;
export const authPassphraseInputLabel = `${prefix}.authPassphraseInputLabel`;
export const authPassphraseInputPlaceholder = `${prefix}.authPassphraseInputPlaceholder`;
export const privacySettingsDropdownLabel = `${prefix}.privacySettingsDropdownLabel`;
export const privacySettingsDropdownPlaceholder = `${prefix}.privacySettingsDropdownPlaceholder`;
export const privacyPassphraseInputLabel = `${prefix}.privacyPassphraseInputLabel`;
export const privacyPassphraseInputPlaceholder = `${prefix}.privacyPassphraseInputPlaceholder`;

export const ENGLISH = {
    [snmpLabel]: 'SNMP',
    [snmpV2RadioLabel]: 'SNMP V2',
    [snmpV3RadioLabel]: 'SNMP V3',
    [snmpCommunityInputLabel]: 'SNMP Community',
    [snmpCommunityInputPlaceholder]: 'Enter SNMP Community',
    [engineIdInputLabel]: 'Engine ID',
    [engineIdInputPlaceholder]: 'Enter Engine ID',
    [authTypeDropdownLabel]: 'Auth Type',
    [authTypeDropdownPlaceholder]: 'Select Auth Type',
    [authPassphraseInputLabel]: 'Auth Passphrase',
    [authPassphraseInputPlaceholder]: 'Enter Auth Passphrase',
    [privacySettingsDropdownLabel]: 'Privacy Settings',
    [privacySettingsDropdownPlaceholder]: 'Select Privacy Settings',
    [privacyPassphraseInputLabel]: 'Privacy Passphrase',
    [privacyPassphraseInputPlaceholder]: 'Select Privacy Passphrase',
};
