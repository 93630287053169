/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../../less/pages/application/virtualservice-security.less';
import * as l10n from './VirtualServiceSecurityController.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').controller('VirtualServiceSecurityController', ['$scope', 'ChartConfig',
'myAccount', 'l10nService',
function($scope, ChartConfig, myAccount, l10nService) {
    $scope.myAccount = myAccount;
    $scope.l10nKeys = l10nKeys;

    l10nService.registerSourceBundles(dictionary);

    // Dashboard
    const sslMetrics = [{
        // Security Summary
        name: 'avg_attack_count',
        series: [
            'l4_client.avg_network_dos_attacks',
            'l4_client.avg_application_dos_attacks',
        ],
    }, {
        // TLS Version
        name: 'vs_ssl_tls_version',
        series: [
            'l7_client.avg_ssl_ver_tls10',
            'l7_client.avg_ssl_ver_tls11',
            'l7_client.avg_ssl_ver_tls12',
            'l7_client.avg_ssl_ver_tls13',
        ],
    }, {
        // Transactions
        name: 'avg_ssl_connections',
        series: 'l7_client.avg_ssl_connections',
        errorsTotal: 'l7_client.pct_ssl_failed_connections',
        errorsSeries: [
            'l7_client.avg_ssl_handshakes_new',
            'l7_client.avg_ssl_handshakes_reused',
            'l7_client.avg_ssl_handshake_protocol_errors',
            'l7_client.avg_ssl_handshake_network_errors',
            'l7_client.avg_ssl_handshakes_timedout',
        ],
    }, {
        // Key Exchange
        name: 'vs_key_exchange',
        series: [
            'l7_client.avg_ssl_kx_ecdh',
            'l7_client.avg_ssl_kx_dh',
            'l7_client.avg_ssl_kx_rsa',
        ],
    }, {
        // Certificate
        name: 'vs_certificate_key_exchange',
        series: [
            'l7_client.avg_ssl_auth_ecdsa',
            'l7_client.avg_ssl_auth_rsa',
        ],
    }, {
        // PFS
        name: 'avg_ssl_pfs',
        series: [
            'l7_client.avg_ssl_handshakes_pfs',
            'l7_client.avg_ssl_handshakes_non_pfs',
        ],
    }, {
        // Attack Count
        name: 'avg_dos_attacks',
        series: 'l4_client.avg_dos_attacks',
    }];

    sslMetrics.forEach(function(metric) {
        metric.subscriber = 'vsSecurityController';
    });

    $scope.dashboard = ['avg_attack_count'];
    $scope.ssl = ['vs_ssl_tls_version', 'avg_ssl_connections', 'vs_key_exchange',
                  'vs_certificate_key_exchange', 'avg_ssl_pfs'];
    $scope.ddos = ['avg_dos_attacks'];

    //need this ugly part to avoid exceptions on initial load TODO remove @am
    $scope.config = new ChartConfig([{
        id: '',
        series: [],
    }]);

    /**
     * Init function.
     * @return {promise} collMetricsSubscribe promise.
     */
    function init() {
        const localMetrics = angular.copy(sslMetrics);

        $scope.ui = {
            error: '',
        };

        return $scope.VirtualService.collMetricsSubscribe(localMetrics)
            .then(function() {
                const metricsNames = sslMetrics.map(function(obj) {
                    return obj.name;
                });
                const config = $scope.VirtualService.createChartsConfig(metricsNames);

                $scope.config = new ChartConfig(config);

                return $scope.VirtualService.startCollMetricsAsync();
            });
    }

    $scope.VirtualService.addLoad(['config_events', 'system_events', 'alerts']);

    init();

    //event listeners
    function repaint() {
        $scope.VirtualService.async.stop(true);
        $scope.VirtualService.collMetricsUnSubscribe(sslMetrics);

        init().then(function() {
            //to redraw the charts
            $scope.$broadcast('repaint');
        });
    }

    //reload page after item edit through modal
    $scope.VirtualService.on('itemSaveSuccess', repaint);

    $scope.$on('$destroy', function() {
        $scope.VirtualService.async.stop(true);
        $scope.VirtualService.removeLoad('config_events', 'system_events', 'alerts');
        $scope.VirtualService.unbind('itemSaveSuccess', repaint);
    });
}]);
