/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'welcome-system-settings';
const prefix = `${moduleName}.${componentName}`;

export const dnsResolverLabel = `${prefix}.dnsResolverLabel`;
export const dnsSearchDomainLabel = `${prefix}.dnsSearchDomainLabel`;
export const dnsSearchDomainFieldPlaceholder = `${prefix}.dnsSearchDomainFieldPlaceholder`;

export const ENGLISH = {
    [dnsResolverLabel]: 'DNS Resolver(s)',
    [dnsSearchDomainLabel]: 'DNS Search Domain',
    [dnsSearchDomainFieldPlaceholder]: 'Enter Domain (ex abc.com)',
};
