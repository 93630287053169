/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as globalL10n from 'global-l10n';
import * as l10n from './snmp-v3-user.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

class SnmpV3UserController {
    constructor(l10nService) {
        /**
         * Get keys from source bundles for template usage.
         */
        this.l10nKeys = l10nKeys;
        this.globalL10nKeys = globalL10nKeys;
        this.l10nService_ = l10nService;

        l10nService.registerSourceBundles(dictionary);
    }
}

SnmpV3UserController.$inject = [
    'l10nService',
];

/**
 * @ngdoc component
 * @name snmpV3User
 * @param {Object} config - Config object where SnmpV3UserParams user is configured.
 * @description
 *      Configures SnmpV3UserParams object inside "config" object "user" property.
 */
angular.module('aviApp').component('snmpV3User', {
    bindings: {
        config: '<',
    },
    controller: SnmpV3UserController,
    templateUrl: 'src/components/administration/snmp-v3-user/snmp-v3-user.html',
});
