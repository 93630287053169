/**
 * @module WafModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { L10nService } from '@vmw/ngx-vip';
import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import {
    WafApplicationSignatureProvider,
} from 'ajs/modules/waf';
import {
    IWafApplicationSignatureAppVersion,
} from 'generated-types';
import * as l10n from './waf-application-rules-list.l10n';
import './waf-application-rules-list.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the Waf Application rules List Page.
 * @author Rachit Aggarwal
 */
@Component({
    selector: 'waf-application-rules-list',
    templateUrl: './waf-application-rules-list.component.html',
})
export class WafApplicationRulesListComponent implements OnInit {
    /**
     * WafApplicationSignatureProviderCollection resolved by UI router.
     */
    @Input('resolveApplicationSignatureProvider')
    public applicationSignatureProvider: WafApplicationSignatureProvider;

    /**
     * List of Applications in application rules DB.
     */
    public applications: IWafApplicationSignatureAppVersion[] = [];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Grid config for the datagrid containing a list of applications.
     */
    public readonly applicationsGridConfig: IAviDataGridConfig;

    constructor(
        l10nService: L10nService,
        @Inject('$state')
        private readonly $state: StateService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.applicationsGridConfig = {
            fields: [
                {
                    label: l10nService.getMessage(l10nKeys.applicationNameLabel),
                    id: 'application-name',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.application;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.lastUpdateVersionLabel),
                    id: 'last-updated-version',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.last_changed_ruleset_version;
                    },
                },
                {
                    label: l10nService.getMessage(l10nKeys.numberOfRulesLabel),
                    id: 'number-of-rules',
                    transform(app: IWafApplicationSignatureAppVersion) {
                        return app.number_of_rules.toString();
                    },
                },
            ],
            getRowId: (index, app) => app.application,
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * Redirects to the Cloud services settings page.
     */
    public redirectToCloudServices(): void {
        this.$state.go('authenticated.administration.cloud-services');
    }

    /** @override */
    public ngOnInit(): void {
        this.applications = this.applicationSignatureProvider.availableApplications || [];
    }
}
