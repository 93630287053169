/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'hsm-group-download-certificate-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const clientIpAddressesHeader = `${prefix}.clientIpAddressesHeader`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const downloadButtonLabel = `${prefix}.downloadButtonLabel`;
export const submitButtonLabel = `${prefix}.submitButtonLabel`;

export const ENGLISH = {
    [modalTitle]: 'HSM Group: {0}',
    [clientIpAddressesHeader]: 'Client IP Addresses',
    [columnTitleIpAddress]: 'IP Address',
    [downloadButtonLabel]: 'Download Certificate',
    [submitButtonLabel]: 'Done',
};
