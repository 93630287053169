/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'match-config';
const prefix = `${moduleName}.${componentName}`;

export const matchTypeInputLabel = `${prefix}.matchTypeInputLabel`;
export const matchTypeInputPlaceholder = `${prefix}.matchTypeInputPlaceholder`;

export const ENGLISH = {
    [matchTypeInputLabel]: 'Add Match Type',
    [matchTypeInputPlaceholder]: 'Select Match',
};
