/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 *     Angular forms don't work the same way as AngularJS forms, and validation from the input
 *     elements of nested forms or child component inputs no longer propagate to the parent form.
 *     Furthermore, reactive-forms are now the recommended approach, meaning that support for
 *     template-driven forms (the traditional AngularJS approach where forms are controlled through
 *     directives) will be minimal and improvements will be sluggish.
 *
 *     The purpose of this directive is to allow ngModels from child components within a parent form
 *     to be able to register themselves to that parent ngForm. Without it, there would be no link
 *     between the parent ngForm and the child ngModel.
 * @example
 *     Parent component template:
 *         <form #parentForm="ngForm">
 *             <child-component></child-component>
 *         </form>
 *
 *     Child component template:
 *         <div provideParentForm>
 *             <input
 *                 type="text"
 *                 [(ngModel)]="childVal"
 *                 name="childVal"
 *                 required
 *             />
 *         </div>
 * @author alextsg
 */
import { Directive } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

@Directive({
    providers: [
        {
            provide: ControlContainer,
            useExisting: NgForm,
        },
    ],
    selector: '[provideParentForm]',
})
export class ProvideParentFormDirective {}
