/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ProtocolParser } from 'ajs/modules/scripts';
import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './protocol-parser-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for ProtocolParser ObjectTypeItem instance.
 *
 * @author Rajawant Prajapati
 */

@Component({
    selector: 'protocol-parser-modal',
    templateUrl: './protocol-parser-modal.component.html',
})

export class ProtocolParserModalComponent implements OnInit {
    /**
     * ProtocolParser ObjectTypeItem instance which is being edited.
     */
    @Input()
    public editable: ProtocolParser;

    /**
     * ProtocolParser objectType
     */
    public objectType: string;

    /**
     * Layout for modal.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
