/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs';
const componentName = 'vs-enhanced-vh-child-hosts';
const prefix = `${moduleName}.${componentName}`;

export const domainLabel = `${prefix}.domainLabel`;
export const hostInputLabel = `${prefix}.hostInputLabel`;
export const addMatchButtonLabel = `${prefix}.addMatchButtonLabel`;
export const addDomainButtonLabel = `${prefix}.addDomainButtonLabel`;
export const removeDomainTitle = `${prefix}.removeDomainTitle`;
export const hostInputPlaceholder = `${prefix}.hostInputPlaceholder`;

export const ENGLISH = {
    [domainLabel]: 'Domain',
    [hostInputLabel]: 'Host',
    [addMatchButtonLabel]: 'Add Match',
    [addDomainButtonLabel]: 'Add Domain',
    [removeDomainTitle]: 'Remove Domain',
    [hostInputPlaceholder]: 'Host',
};
