/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';
import {
    AppLearningParamsConfigItem,
    LatestWafCrsVersionService,
    WafApplicationSignatureProvider,
    WafApplicationSignatureProviderCollection,
    WafApplicationSignaturesConfigItem,
    WafCrs,
    WafCrsCollection,
    WafDataFileConfigItem,
    WafExcludeListEntryConfigItem,
    WafExclusionTypeConfigItem,
    wafLearningDataModalOptions,
    WafPolicyAllowlistConfigItem,
    WafPolicyAllowlistRuleConfigItem,
    WafPolicyCollection,
    WafPolicyItem,
    WafPolicyPsmGroup,
    WafPolicyPsmGroupCollection,
    WafPolicyPsmGroupMetric,
    WafPositiveSecurityModelConfigItem,
    WafProfileCollection,
    WafProfileItem,
    WafPSMLocationConfigItem,
    WafPSMLocationMatchConfigItem,
    WafPSMMatchElementConfigItem,
    WafPSMRuleConfigItem,
    WafRuleConfigItem,
    WafRuleGroupConfigItem,
    WafRuleGroupOverridesConfigItem,
    WafRuleOverridesConfigItem,
    WAF_CRS_COLLECTION_TOKEN,
    WAF_CRS_ITEM_TOKEN,
    WAF_EXCLUDE_LIST_ENTRY_CONFIG_ITEM_TOKEN,
    WAF_EXCLUSION_TYPE_CONFIG_ITEM_TOKEN,
    WAF_POLICY_ALLOWLIST_CONFIG_ITEM_TOKEN,
    WAF_POLICY_ALLOWLIST_RULE_CONFIG_ITEM_TOKEN,
    WAF_RULE_CONFIG_ITEM_TOKEN,
    WAF_RULE_GROUP_CONFIG_ITEM_TOKEN,
    WAF_RULE_GROUP_OVERRIDES_CONFIG_ITEM_TOKEN,
    WAF_RULE_OVERRIDES_CONFIG_ITEM_TOKEN,
} from '.';

import {
    LATEST_WAF_CRS_VERSION_SERVICE_TOKEN,
    WAF_DATA_FILE_CONFIG_ITEM_TOKEN,
    WAF_POLICY_COLLECTION_TOKEN,
    WAF_POLICY_ITEM_TOKEN,
    WAF_POLICY_PSM_GROUP_ITEM_TOKEN,
    WAF_PROFILE_COLLECTION_TOKEN,
    WAF_PROFILE_ITEM_TOKEN,
} from './waf.tokens';

const wafModule = angular.module('avi/waf');

const components = [
    {
        name: 'wafLearningDataModal',
        options: wafLearningDataModalOptions,
    },
];

const factories = [
    {
        dependency: AppLearningParamsConfigItem,
        name: 'AppLearningParamsConfigItem',
    },
    {
        dependency: WafPolicyItem,
        name: WAF_POLICY_ITEM_TOKEN,
    },
    {
        dependency: WafPolicyPsmGroup,
        name: WAF_POLICY_PSM_GROUP_ITEM_TOKEN,
    },
    {
        dependency: WafPSMLocationConfigItem,
        name: 'WafPSMLocationConfigItem',
    },
    {
        dependency: WafPSMLocationMatchConfigItem,
        name: 'WafPSMLocationMatchConfigItem',
    },
    {
        dependency: WafPSMMatchElementConfigItem,
        name: 'WafPSMMatchElementConfigItem',
    },
    {
        dependency: WafPSMRuleConfigItem,
        name: 'WafPSMRuleConfigItem',
    },
    {
        dependency: WafPolicyPsmGroupCollection,
        name: 'WafPolicyPsmGroupCollection',
    },
    {
        dependency: WafPositiveSecurityModelConfigItem,
        name: 'WafPositiveSecurityModelConfigItem',
    },
    {
        dependency: WafApplicationSignaturesConfigItem,
        name: 'WafApplicationSignaturesConfigItem',
    },
    {
        dependency: WafPolicyPsmGroupMetric,
        name: 'WafPolicyPsmGroupMetric',
    },
    {
        dependency: WafPolicyAllowlistRuleConfigItem,
        name: WAF_POLICY_ALLOWLIST_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafApplicationSignatureProviderCollection,
        name: 'WafApplicationSignatureProviderCollection',
    },
    {
        dependency: WafApplicationSignatureProvider,
        name: 'WafApplicationSignatureProvider',
    },
    {
        dependency: WafCrs,
        name: WAF_CRS_ITEM_TOKEN,
    },
    {
        dependency: WafCrsCollection,
        name: WAF_CRS_COLLECTION_TOKEN,
    },
    {
        dependency: WafRuleGroupConfigItem,
        name: WAF_RULE_GROUP_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafRuleConfigItem,
        name: WAF_RULE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafExcludeListEntryConfigItem,
        name: WAF_EXCLUDE_LIST_ENTRY_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafExclusionTypeConfigItem,
        name: WAF_EXCLUSION_TYPE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafRuleGroupOverridesConfigItem,
        name: WAF_RULE_GROUP_OVERRIDES_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafRuleOverridesConfigItem,
        name: WAF_RULE_OVERRIDES_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafPolicyAllowlistConfigItem,
        name: WAF_POLICY_ALLOWLIST_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafProfileItem,
        name: WAF_PROFILE_ITEM_TOKEN,
    },
    {
        dependency: WafProfileCollection,
        name: WAF_PROFILE_COLLECTION_TOKEN,
    },
    {
        dependency: WafDataFileConfigItem,
        name: WAF_DATA_FILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: WafPolicyCollection,
        name: WAF_POLICY_COLLECTION_TOKEN,
    },
];

const services = [
    {
        dependency: LatestWafCrsVersionService,
        name: LATEST_WAF_CRS_VERSION_SERVICE_TOKEN,
    },
];

components.forEach(({ name, options }) => wafModule.component(name, options));
services.forEach(({ name, dependency }) => wafModule.service(name, dependency));

factories.forEach(({ name, dependency }) => {
    initAjsDependency(wafModule, 'factory', name, dependency);
});
