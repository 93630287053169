/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('headerCollapseButton', function() {
    return {
        scope: {
            expanded: '=',
        },
        link(scope, element) {
            element.addClass('header-collapse-button');
            scope.$watch(function() {
                return scope.expanded;
            }, function(newValue) {
                if (newValue) {
                    element.addClass('expanded');
                } else {
                    element.removeClass('expanded');
                }
            });
        },
        restrict: 'AE',
        template: '<i class = "icon icon-minus"></i><i class = "icon icon-plus"></i>',
    };
});
