/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { DosRateLimitProfile } from 'object-types';
import { IDosRateLimitProfile } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RateLimiterProfileConfigItem } from '.';

type TDosRateLimitProfilePartial = Omit<IDosRateLimitProfile, 'rl_profile'>;

interface IDosRateLimitProfileConfig extends TDosRateLimitProfilePartial {
    rl_profile: RateLimiterProfileConfigItem,
}

/**
 * @desc DosRateLimitProfile MessageItem class.
 * @author Nisar Nadaf
 */
export class DosRateLimitProfileConfigItem extends MessageItem<IDosRateLimitProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: DosRateLimitProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter function for rl profile value.
     */
    public get rlProfile(): RateLimiterProfileConfigItem {
        const { rl_profile: rlProfile } = this.config;

        return rlProfile;
    }
}
