/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, Input } from '@angular/core';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';

import './vs-log-cinematic-ip-field.component.less';

export type TIpVersion = 'ipv4' | 'ipv6';

/**
 * @description
 *     Component used for showing IPs for the VS log cinematic view.
 *     This component handles partial selection filter for IPs and determines behaviors upon
 *     clicking.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-ip-field',
    templateUrl: 'vs-log-cinematic-ip-field.component.html',
})
export class VsLogCinematicIpFieldComponent {
    /**
     * Property name of the field.
     */
    @Input()
    public property: string;

    /**
     * An IP is supposed to be a string, regardless ipv4 or ipv6. But in protobuf, ipv4 is asserted
     * as number type, thereby in generated-types. Hence here the type "number" is added for
     * consistency.
     */
    @Input()
    public ip: string | number;

    /**
     * Version of the IP address deciding the layout variations, with the default as IP version 4.
     */
    @Input()
    public version: TIpVersion = 'ipv4';

    /**
     * Property name of the port field.
     */
    @Input()
    public portProperty?: string;

    @Input()
    public port?: number;

    @Input()
    public noIpSearchIcon = false;

    @Input()
    public noPortSearchIcon = false;

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {}
}
