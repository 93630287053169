/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Function to convert SQL-styled offset and limit to page and page_size in a possible
 * efficient way.
 * As our API doesn't support them yet. Throw away after implementation. @am
 */
angular.module('aviApp').factory('limitAndOffset2pageSizeAndPage', function() {
    return function(limit, offset = 0) {
        if (!limit) {
            console.warn(
                "limitAndOffset2pageSizeAndPage can't work without provided limit value",
            );

            return [0, 0];
        }

        let
            pageSize = limit,
            page = Math.floor(offset / pageSize) + 1;

        while (pageSize < limit * 2 && (offset < (page - 1) * pageSize ||
            page * pageSize < offset + limit)) {
            pageSize++;
            page = Math.floor(offset / pageSize) + 1;
        }

        return [pageSize, page];
    };
});
