/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './dosRateLimiterService.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @fileoverview Limiter types are different for HTTP and L4 profiles. And set of limiter actions
 * depends on Profile type as well. First limiter is always present and is working as L4 type
 * despite the selected profile (or VS) type.
 *
 * @class dosRateLimiterService
 */
angular.module('aviApp').service('dosRateLimiterService', ['l10nService', function(l10nService) {
    l10nService.registerSourceBundles(dictionary);

    /**
     @typedef {Object} LimiterType
     @property {string} id - Type of rate limiter and key to be used on save.
     @property {string} label - Label (description) for the dropdowns.
     @property {number} type - Profile type supported by this limiter - 1 for L4 and 10
     for HTTP. 11 when applicable to both.
     /
     /**
     * Static Dictionary of limiter types. Local Schema.
     * @type {Array.<LimiterType>}
     * @private
     */
    const limiterTypes = [{
        id: 'client_ip_connections_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitConnectionsFromClientOption),
        type: 11, //01 for layer 4(L4) and + 10 for layer 7(HTTP)
    }, {
        id: 'client_ip_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitRequestsFromClientToAllUrlsOption),
        type: 10, // layer 7 only(HTTP)
    }, {
        id: 'uri_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitRequestsFromAllClientsToUrlOption),
        type: 10, //layer 7 only(HTTP)
    }, {
        id: 'client_ip_to_uri_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitRequestsFromClientsToUrlOption),
        type: 10,
    }, {
        id: 'client_ip_failed_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitFailedRequestsFromClientsToAllUrlsOption),
        type: 10,
    }, {
        id: 'uri_failed_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitFailedRequestsFromAllClientsToUrlOption),
        type: 10,
    }, {
        id: 'custom_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitHttpRequestsToAllUrlsOfVsOption),
        type: 10,
    }, {
        id: 'client_ip_to_uri_failed_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitFailedRequestsFormClientToUrlOption),
        type: 10,
    }, {
        id: 'client_ip_scanners_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitScansFromClientToAllUrlsOption),
        type: 10,
    }, {
        id: 'uri_scanners_requests_rate_limit',
        label: l10nService.getMessage(l10nKeys.rateLimitScansFromAllClientsToAllUrlsOption),
        type: 10,
    }, {
        id: 'http_header_rate_limits',
        label: l10nService.getMessage(l10nKeys.rateLimitHttpHeaderOrCookieOption),
        type: 10,
    }];
    /**
     * Static Hash to get the name having the limiter's key(type).
     * @type {Object.<LimiterType>}
     */
    const limiterTypesDict = {};

    _.each(limiterTypes, function(limiterType) {
        limiterTypesDict[limiterType.id] = limiterType;
    });
    /**
     * @typedef {Object} LimiterActionType
     * @property {string} id - Type of limiter action and key to be used on save.
     * @property {string} label - Label (description) for the dropdowns.
     * @property {number} type - Profile type supported by this action - 1 for L4 and 10
     for HTTP. 11 when applicable to both.
     */

    /**
     * Static Dictionary of limiter action types. Local Schema.
     * @type {Array.<LimiterActionType>}
     * @private
     */
    const limiterActions = [{
        id: 'RL_ACTION_NONE',
        label: l10nService.getMessage(l10nKeys.reportOnlyOption),
        type: 11,
    }, {
        id: 'RL_ACTION_DROP_CONN',
        label: l10nService.getMessage(l10nKeys.dropSynPacketsOption),
        type: 1,
    }, {
        id: 'RL_ACTION_RESET_CONN',
        label: l10nService.getMessage(l10nKeys.sendTcpRstOption),
        type: 1,
    }, {
        id: 'RL_ACTION_CLOSE_CONN',
        label: l10nService.getMessage(l10nKeys.closeTcpConnectionOption),
        type: 10,
    }, {
        id: 'RL_ACTION_LOCAL_RSP',
        label: l10nService.getMessage(l10nKeys.sendHttpLocalResponseOption),
        type: 10,
        detailsSet: 'local',
    }, {
        id: 'RL_ACTION_REDIRECT',
        label: l10nService.getMessage(l10nKeys.sendHttpRedirectOption),
        type: 10,
        detailsSet: 'redirect',
    }];

    /**
     * Full and static hash of the Limiter Actions. Used by template.
     * @type {Object.<LimiterActionType>}
     */
    const limiterActionsDict = {};

    _.each(limiterActions, function(action) {
        limiterActionsDict[action.id] = action;
    });

    /**
     * For limiterType client_ip_requests_rate_limit (first and default) we always show only
     *    l4 actions.
     * @type {Array.<LimiterActionType>}
     */
    const l4limiterActions = _.filter(limiterActions, function(action) {
        return action.type === 1 || action.type === 11;
    });

    this.types = limiterTypes;

    this.typesDict = limiterTypesDict;

    this.actions = limiterActions;

    this.l4Actions = l4limiterActions;

    this.actionsDict = limiterActionsDict;

    /**
     * Removes all properties but type from the action object and sets some default values for
     * certain actions.
     * @param {Object<type, file, status_code, redirect>} limiter - Limiter Object of the
     * editable's rate limiter.
     * @param {Object<value, label>} action - Selected option object from the Dropdown.
     */
    this.emptyLimiterActionObject = function(limiter, action) {
        delete limiter.action.file;
        delete limiter.action.status_code;
        delete limiter.action.redirect;

        if (action.value === 'RL_ACTION_REDIRECT') {
            limiter.action.redirect = {
                protocol: 'HTTP',
                port: 80,
                keep_query: true,
                status_code: 'HTTP_REDIRECT_STATUS_CODE_302',
            };
        }
    };
}]);
