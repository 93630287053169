/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'sso-policy';
const componentName = 'authentication-rule-grid';
const prefix = `${moduleName}.${componentName}`;

export const addButtonLabel = `${prefix}.addButtonLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const removeButtonLabel = `${prefix}.removeButtonLabel`;
export const editButtonLabel = `${prefix}.editButtonLabel`;
export const authenticationRulesLabel = `${prefix}.authenticationRulesLabel`;

export const ENGLISH = {
    [addButtonLabel]: 'Add',
    [columnTitleName]: 'Name',
    [removeButtonLabel]: 'Remove',
    [editButtonLabel]: 'Edit',
    [authenticationRulesLabel]: 'Authentication Rules ({0})',
};
