/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from '../ajsDependency';
import { withFullModalMixin } from './with-full-modal.mixin';
import { withIndexMixin } from './with-index.mixin';

const mixins = [
    {
        mixin: withFullModalMixin,
        name: 'withFullModalMixin',
    },
    {
        mixin: withIndexMixin,
        name: 'withIndexMixin',
    },
];

mixins.forEach(({ mixin, name }) => {
    initAjsDependency(angular.module('avi/dataModel'), 'factory', name, mixin);
});
