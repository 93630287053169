/** @module L4PolicyModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import {
    L4ConnectionPolicyConfigItem,
    L4PolicySet,
    L4RuleConfigItem,
} from 'ajs/modules/l4-policy/factories';
import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import {
    IAviDataGridConfig,
    IRowReorder,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from './l4-policy-set-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for L4 Policy Set.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'l4-policy-set-modal',
    templateUrl: './l4-policy-set-modal.component.html',
})
export class L4PolicySetModalComponent implements OnInit, AfterViewInit {
    /**
     * Passed-in L4 Policy Set as the modal editable.
     */
    @Input()
    public editable: L4PolicySet;

    /**
     * Expander template for displaying Enabled field.
     */
    @ViewChild('enabledColumnTemplateRef')
    public enabledColumnTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Expander template for displaying match and action details.
     */
    @ViewChild('expandedContentTemplateRef')
    public expandedContentTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Avi data grid config for list of L4 Rules.
     */
    public l4RuleDataGridConfig: IAviDataGridConfig;

    /**
     * Rows passed to the L4 Rule data grid.
     */
    public l4RuleDataGridRows: L4RuleConfigItem[] = [];

    /**
     * Message type token of L4 Policy Set.
     */
    public l4PolicySetObjectType: string;

    /**
     * l10n keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Layout for modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * Getter for the L4 Rule list under L4 Connection Policy.
     */
    public get l4Rules(): RepeatedMessageItem<L4RuleConfigItem> {
        return this.l4ConnectionPolicy.config.rules;
    }

    /**
     * Getter for the L4 Connection Policy in this L4 policy set.
     */
    private get l4ConnectionPolicy(): L4ConnectionPolicyConfigItem {
        return this.editable.l4ConnectionPolicy;
    }

    constructor(private l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.l4PolicySetObjectType = this.editable.messageType;
    }

    /**
     * Needed because expandedContentTemplateRef avi-data-grid can only be set after init.
     * @override
     */
    public ngAfterViewInit(): void {
        this.setL4RuleGridConfig();
    }

    /**
     * Handler for moving rules around when the ordre of one row changes.
     */
    public handleL4RuleGridRowReorder({ previousIndex, currentIndex }: IRowReorder): void {
        this.l4Rules.moveByIndex(previousIndex, currentIndex);
    }

    /**
     * Add a new L4 Rule to L4 Connection Policy.
     */
    public addL4Rule(): void {
        this.l4ConnectionPolicy.addRule();
    }

    /**
     * Set the config for L4 Rule data grid.
     */
    private setL4RuleGridConfig(): void {
        this.l4RuleDataGridRows = this.l4Rules.config;

        this.l4RuleDataGridConfig = {
            fields: [
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleEnabled),
                    id: 'enabled',
                    templateRef: this.enabledColumnTemplateRef,
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.columnTitleName),
                    id: 'name',
                    transform: (l4RuleGridRow: L4RuleConfigItem) => {
                        return l4RuleGridRow.config.name;
                    },
                },
            ],
            getRowId(index: number): number {
                return index;
            },
            layout: {
                withReordering: true,
            },
            singleactions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.editLabel),
                    shape: 'pencil',
                    onClick: (rule: L4RuleConfigItem) => {
                        this.l4ConnectionPolicy.editRule(rule);
                    },
                },
                {
                    label: this.l10nService.getMessage(l10nKeys.deleteLabel),
                    shape: 'trash',
                    onClick: (rule: L4RuleConfigItem) => {
                        this.l4Rules.removeByMessageItem(rule);
                    },
                },
            ],
            multipleactions: [
                {
                    label: this.l10nService.getMessage(l10nKeys.deleteLabel),
                    onClick: (rules: L4RuleConfigItem[]) => {
                        rules.forEach(rule => this.l4Rules.removeByMessageItem(rule));
                    },
                },
            ],
            expandedContentTemplateRef: this.expandedContentTemplateRef,
        };
    }
}
