/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-certificate-grid-name-column';
const prefix = `${moduleName}.${componentName}`;

export const sslCertChainTooltipLabel = `${prefix}.sslCertChainTooltipLabel`;

export const ENGLISH = {
    [sslCertChainTooltipLabel]: 'SSL Certificate Chain',
};
