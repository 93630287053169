/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/resource-timing-cell.less';

angular.module('aviApp').directive('resourceTimingCell', ['$timeout', function($timeout) {
    return {
        scope: {
            data: '=',
            totalTime: '=',
            overallData: '=',
        },
        restrict: 'AE',
        templateUrl: 'src/views/components/resource-timing-cell.html',
        link(scope, elm) {
        },
    };
}]);
