/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IIpAddrPrefix,
    IpAddrType,
} from 'generated-types';

import {
    ipv6Prefix as ipv6PrefixPrefix,
    subnetWG as ipv4PrefixRegex,
} from './regex.utils';

import { getIpAddr } from './ip-parser.utils';

/**
 * Returns readable IP-Subnet string from IpAddrPrefix  type object.
 */
export function getSubnetString(subnetObj: IIpAddrPrefix): string {
    const {
        ip_addr: { addr = '' } = {},
        mask = '',
    } = subnetObj;

    return `${addr}/${mask}`;
}

/**
 * Parses IP-Subnet string and returns IpAddrPrefix  type object.
 * throws error for invalid cases.
 */
export function parseIpPrefix(ipPrefixString: string): IIpAddrPrefix {
    if (ipv4PrefixRegex.test(ipPrefixString)) {
        const [, ipAddr, mask] = ipv4PrefixRegex.exec(ipPrefixString);

        return {
            ip_addr: getIpAddr(ipAddr),
            mask: +mask,
        };
    }

    if (ipv6PrefixPrefix.test(ipPrefixString)) {
        const ipv6PrefixChunks = ipv6PrefixPrefix.exec(ipPrefixString);
        const ipAddr = ipv6PrefixChunks[1];
        const mask = ipv6PrefixChunks[ipv6PrefixChunks.length - 1];

        return {
            ip_addr: getIpAddr(ipAddr, IpAddrType.V6),
            mask: +mask,
        };
    }

    throw new Error(`Invalid IP Prefix: ${ipPrefixString}`);
}

/**
 * Returns IpAddrPrefix object from IP-Subnet string.
 * returns null for invalid cases.
 */
export function parseIpPrefixString(ipPrefixString: string): IIpAddrPrefix | null {
    try {
        ipPrefixString = ipPrefixString.trim();

        return parseIpPrefix(ipPrefixString);
    } catch (e) {
        return null;
    }
}

/**
 * Returns IP-subnet string from IpAddrPrefix object.
 */
export function formatIpAddrPrefix(ipAddrPrefix: IIpAddrPrefix): string {
    return ipAddrPrefix ? getSubnetString(ipAddrPrefix) : '';
}
