/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './PerformanceChartLegend.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

angular.module('aviApp').directive('performanceChartLegend', ['l10nService',
    function(l10nService) {
        function link(scope, element) {
            element.addClass('chart-legend');
            scope.hideLegend = true;
            scope.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);
        }

        return {
            restrict: 'AE',
            link,
            templateUrl: 'src/views/components/performance-chart-legend.html',
            scope: {
                legend: '=',
            },
        };
    }]);
