/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { any } from 'underscore';

import {
    IBuildInfo,
    IImage,
    IImageInventoryInfo,
    ImageType,
} from 'generated-types';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { ImageCategory, UpgradeNodeType } from '../upgrade.constants';

const { NODE_CONTROLLER_CLUSTER, NODE_SE_GROUP } = UpgradeNodeType;

/**
 * @alias Image
 * @desc
 *
 *      Image item representing file for system operations, maps to Image in protobuf.
 *
 * @author Zhiqian Liu
 */
export class Image extends Item<IImage> {
    /**
     * Override runtime data type.
     * @override
     */
    public getRuntimeData: () => IImageInventoryInfo;

    /**
     * Getter for type of this image. 'IMAGE_TYPE_SYSTEM' or 'IMAGE_TYPE_PATCH'.
     */
    public get type(): ImageType {
        return this.getConfig().type;
    }

    /**
     * Getter for version of this image.
     */
    public get version(): string {
        const buildInfo = this.getBuildInfo();
        const {
            version,
            patch_version: patchVersion,
            build_no: buildNumber,
        } = buildInfo;

        switch (this.type) {
            case ImageType.IMAGE_TYPE_SYSTEM:
                return `${version}-${buildNumber}`;

            case ImageType.IMAGE_TYPE_PATCH:
                return `${version}-${patchVersion}-${buildNumber}`;
        }

        return '';
    }

    /**
     * Getter for major version of this image. Major version is the part of version string that
     * exludes patch version and build number.
     */
    public get majorVersion(): string {
        const buildInfo = this.getBuildInfo();
        const { version } = buildInfo;

        return version;
    }

    /**
     * Getter for patch version of this image. Empty when it's not applicable.
     */
    public get patchVersion(): string {
        const buildInfo = this.getBuildInfo();
        const { patch_version: patchVersion } = buildInfo;

        return patchVersion || '';
    }

    /**
     * Getter for build number string of this image.
     */
    public get build(): number {
        const buildInfo = this.getBuildInfo();
        const { build_no: buildNumString } = buildInfo;

        return buildNumString;
    }

    /**
     * Getter to decide is the image a controller image, SE image or both.
     */
    public get category(): ImageCategory {
        const {
            controller_info: controllerInfo,
            se_info: seInfo,
        } = this.getConfig();

        if (controllerInfo && seInfo) {
            return ImageCategory.IMAGE_CATEGORY_HYBRID;
        } else if (controllerInfo) {
            return ImageCategory.IMAGE_CATEGORY_CONTROLLER;
        } else {
            return ImageCategory.IMAGE_CATEGORY_SE;
        }
    }

    /** @override */
    public getName(): string {
        return this.version;
    }

    /**
     * Decide if this image is of a specific type.
     * @param type - Type to be decide.
     */
    public isType(type: ImageType): boolean {
        return type === this.type;
    }

    /**
     * Decide if this image is of a specific category.
     * @param category - Category to be decide.
     */
    public isCategory(category: ImageCategory): boolean {
        return category === this.category;
    }

    /**
     * Decide if this image is in use.
     */
    public isInUse(): boolean {
        return this.getRuntimeData().in_use;
    }

    /**
     * Decide if this image is being used by controller.
     */
    public isUsedByController(): boolean {
        if (!this.isInUse()) {
            return false;
        }

        const { in_use_info: inUseInfoList } = this.getRuntimeData();

        return any(
            inUseInfoList,
            ({ node_type: usedByNodeType }) => usedByNodeType === NODE_CONTROLLER_CLUSTER,
        );
    }

    /**
     * Decide if this image is being used by any SEG.
     */
    public isUsedBySeg(): boolean {
        if (!this.isInUse()) {
            return false;
        }

        const { in_use_info: inUseInfoList } = this.getRuntimeData();

        return any(
            inUseInfoList,
            ({ node_type: usedByNodeType }) => usedByNodeType === NODE_SE_GROUP,
        );
    }

    /**
     * Get object containing necessary build info.
     */
    private getBuildInfo(): IBuildInfo {
        const {
            controller_info: controllerInfo,
            se_info: seInfo,
        } = this.getConfig();

        const { build: buildInfo } = controllerInfo || seInfo;

        return buildInfo;
    }
}

Image.prototype.objectName = 'image';
