/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-datascript';
const prefix = `${moduleName}.${componentName}`;

export const datascriptErrorStackTraceLabel = `${prefix}.datascriptErrorStackTraceLabel`;
export const datascriptErrorLabel = `${prefix}.datascriptErrorLabel`;
export const emptyListPlaceholderLabel = `${prefix}.emptyListPlaceholderLabel`;

export const ENGLISH = {
    [datascriptErrorStackTraceLabel]: 'Datascript Error Stack Trace',
    [datascriptErrorLabel]: 'Datascript Error',
    [emptyListPlaceholderLabel]: 'No datascript data to show!',
};
