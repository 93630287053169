/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    AfterViewInit,
    Component,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import {
    IAviDataGridConfig,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';
import { VsLogCinematicStore } from '../vs-log-cinematic.store';
import { FilterOperatorType } from '../../../vs-logs.types';

import './vs-log-cinematic-section-ssl.component.less';

import * as l10n from './vs-log-cinematic-section-ssl.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Component for displaying SSL section in a VS log cinematic view.
 * @author Kondiparthi Shanmukha Sarath, Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-section-ssl',
    templateUrl: './vs-log-cinematic-section-ssl.component.html',
})
export class VsLogCinematicSectionSslComponent implements OnInit, AfterViewInit {
    /**
     * Datagrid template for the SSL version field.
     */
    @ViewChild('sslVersionFieldTemplateRef')
    public readonly sslVersionFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the SSL certtificate type field.
     */
    @ViewChild('sslCertTypeFieldTemplateRef')
    public readonly sslCertTypeFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the SNI hostname field.
     */
    @ViewChild('sniHostnameFieldTemplateRef')
    public readonly sniHostnameFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the SSL Perfect Forward Secracy field.
     */
    @ViewChild('sslPfsFieldTemplateRef')
    public readonly sslPfsFieldTemplateRef: TemplateRef<HTMLElement>;

    /**
     * Datagrid template for the OCSP status field.
     */
    @ViewChild('oscpStatusFieldTemplateRef')
    public readonly oscpStatusFieldTemplateRef: TemplateRef<HTMLElement>;

    public readonly ssl$ = this.vsLogCinematicStore.ssl$;

    public readonly FilterOperatorType = FilterOperatorType;

    public sslGridConfig: IAviDataGridConfig;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
        public readonly vsLogCinematicStore: VsLogCinematicStore,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     * Setting up SSL grid config for avi-data-grid usage.
     */
    public ngOnInit(): void {
        this.sslGridConfig = {
            getRowId(index: number): number {
                return index;
            },
            fields: [],
            layout: {
                hideCheckboxes: true,
            },
        };
    }

    /**
     * @override
     * Setting fields for ssl grid config.
     */
    public ngAfterViewInit(): void {
        this.sslGridConfig.fields = [
            {
                label: this.l10nService.getMessage(l10nKeys.sslVersionLabel),
                id: 'ssl-version',
                templateRef: this.sslVersionFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(globalL10nKeys.typeLabel),
                id: 'ssl-cert-type',
                templateRef: this.sslCertTypeFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.sniHostnameLabel),
                id: 'sni-hostname',
                templateRef: this.sniHostnameFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.perfectForwardSecracyLabel),
                id: 'ssl-pfs',
                templateRef: this.sslPfsFieldTemplateRef,
            },
            {
                label: this.l10nService.getMessage(l10nKeys.oscpHandlingLabel),
                id: 'ocsp',
                templateRef: this.oscpStatusFieldTemplateRef,
            },
        ];
    }
}
