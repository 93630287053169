/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IBotClassMatcher } from 'generated-types';
import { BotClassMatcher } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * @description  BotClassMatcher ConfigItem class.
 * @author Akul Aggarwal
 */
export class BotClassMatcherConfigItem extends MessageItem<IBotClassMatcher> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: BotClassMatcher,
            ...args,
        };

        super(extendedArgs);
    }
}
