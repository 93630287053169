/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'bot-detection-policy';
const componentName = 'bot-allow-rule-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleCreate = `${prefix}.modalTitleCreate`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const noNameLabel = `${prefix}.noNameLabel`;
export const matchSectionTitle = `${prefix}.matchSectionTitle`;

export const botActionLabel = `${prefix}.botActionLabel`;
export const selectBotActionLabel = `${prefix}.selectBotActionLabel`;

export const ENGLISH = {
    [modalTitleCreate]: 'Create Allow Rule',
    [modalTitleEdit]: 'Edit Allow Rule',
    [noNameLabel]: 'No Name',
    [matchSectionTitle]: 'Match',

    [botActionLabel]: 'Bot Action',
    [selectBotActionLabel]: 'Select Bot Action',
};
