/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

angular.module('aviApp').directive('aviLoader', function() {
    return {
        restrict: 'A',
        template: '<div><i class="icon-loader sel-loader"></i></div>',
    };
});
