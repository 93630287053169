/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for components and services related to Grid & collectionGrid.
 * @module avi/component-kit/grid
 */

import './grid.less';
import * as l10n from './grid.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

//TODO defaultSorting - confirm it's a fieldname rather en path
function gridDirective(
        $timeout,
        gridConfigTools,
        propLookup,
        gridCtrlRowExpanderMixin,
        gridCtrlCssRulesMixin,
        gridCtrlRowSelectionMixin,
        gridCtrlRowsSortingMixin,
) {
    /**
     * Searches through searchable fields of grid row object.
     * @param {Object} row
     * @param {string=} keyword - If not set or empty value is provided function returns true.
     * @param {string[]} searchableFields
     * @returns {boolean} - True when keyword was found in a row.
     * @inne
     */
    //TODO rendered content might differ from data in object
    function rowSearch(row, keyword, searchableFields) {
        if (!keyword) {
            return true;
        }

        keyword = keyword.toString().toLowerCase();

        if (angular.isObject(row) && Array.isArray(searchableFields) &&
                searchableFields.length) {
            return _.some(searchableFields, searchFieldName => {
                const rowValue = propLookup(searchFieldName, row);

                if (rowValue && angular.isNumber(rowValue) || angular.isString(rowValue)) {
                    return rowValue
                        .toString()
                        .toLowerCase()
                        .indexOf(keyword) !== -1;
                }

                return false;
            });
        } else if (angular.isString(row) || angular.isNumber(row)) {
            return row
                .toString()
                .toLowerCase()
                .indexOf(keyword) !== -1;
        }

        return false;
    }

    const
        componentName = 'c-grid',
        tableRowClassName = `${componentName}__table-body-row`,
        tableCellClassName = `${componentName}__table-cell`;

    /**
     * @mixes gridCtrlCssRulesMixin
     * @mixes gridCtrlRowExpanderMixin
     * @mixes gridCtrlRowSelectionMixin
     * @mixes gridCtrlRowsSortingMixin
     */
    class GridCtrl {
        constructor($element, $scope, l10nService) {
            this.$element = $element;
            this.$scope = $scope;
            this.isDestroyed_ = false;

            this.l10nService_ = l10nService;
            this.l10nKeys = l10nKeys;

            l10nService.registerSourceBundles(dictionary);

            this.vsRepeatOptions = {};
            this.vsRepeatRenderAllTimeout_ = NaN;

            /**
             * Custom class name to create scoped CSS rules in the runtime.
             * @type {string}
             **/
            this.classNamePrefix_ = `${componentName}_i_${_.random(0, 999999)}`;

            /**
             * Search string provided by user.
             * @type {string}
             */
            this.searchString = '';

            /**
             * List of table rows filtered by search string.
             * @type {Object[]}
             */
            this.filteredRows = [];

            /**
             * Map of filteredRows object for faster lookup.
             * This is used to provide unique row ids if it's not present in gridConfig object
             * @private
             * @type {Map<any, number>}
             */
            this.filteredRowsMap_ = new Map();

            /**
             * Needs to be redefined in constructor to avoid data sharing through the prototype.
             * @type {{string: boolean}}
             * @see gridCtrlRowSelectionMixin
             */
            this.selectedRowsHash = {};

            this.isRowSelected = this.isRowSelected.bind(this);
            this.onRowsListChange_ = this.onRowsListChange_.bind(this);
        }

        $onInit() {
            this.$element.addClass(`${componentName} ${this.classNamePrefix_}`);

            this.cssRulesOnInit_(componentName);

            const {
                $scope,
                config,
                l10nService_: l10nService,
            } = this;

            /**
             * Text label for 'no items found' message.
             * @type {string}
             */
            this.noRowsLabel =
                this.labelEmpty || l10nService.getMessage(l10nKeys.emptyListMessage);

            /**
             * Text label for 'no items found' message.
             * @type {string}
             */
            this.noRowsOnSearchLabel =
                this.labelSearchEmpty || l10nService.getMessage(l10nKeys.noItemsMatchMsg);

            // we need to render all rows for reordering to work
            // vs-repeat doesn't really work with non-equal row height
            if (config.renderAll || config.dragAndDropReordering) {
                this.vsRepeatOptions.latch = true;//stashing ones at the top
                this.vsRepeatRenderAllTimeout_ = $timeout(
                    () => $scope.$broadcast('vsRenderAll'),
                );
            }

            const { defaultSorting: sortBy } = config;

            if (sortBy) {
                if (angular.isString(sortBy) && sortBy[0] === '-') {
                    this.setSorting_(sortBy.slice(1), true);
                } else {
                    this.setSorting_(sortBy, false);
                }

                this.sortRows_();
            }

            $scope.$watchCollection(
                () => this.rows,
                this.onRowsListChange_.bind(this),
            );
        }

        $onDestroy() {
            this.isDestroyed_ = true;
            this.cssRulesOnDestroy_();
            clearTimeout(this.vsRepeatRenderAllTimeout_);
        }

        /**
         * Updates filtered rows list and sorts em (when required).
         * @protected
         */
        onRowsListChange_() {
            this.updateFilteredRows_();
            this.sortRows_();
        }

        /**
         * Returns row id. For simple type it is row passed itself.
         * @param {*} row
         * @returns {string}
         */
        rowId(row) {
            const { config } = this;

            if (angular.isObject(row)) {
                switch (typeof config.rowId) {
                    //TODO add try...catch
                    case 'function':
                        return config.rowId.call(undefined, row);

                    case 'string':
                        return propLookup(config.rowId, row);

                    default:
                        return this.getUniqueRowID_(row);
                }
            } else { //for simple types we use value itself
                return row.toString();
            }
        }

        /**
         * Returns true if non empty list of singleactions is passed.
         * @returns {boolean}
         */
        singleActionsAvailable() {
            const { singleactions } = this.config;

            return Array.isArray(singleactions) && singleactions.length > 0;
        }

        /**
         * Returns true if config is asking for expander, drag and drop reordering or single
         * actions.
         * @returns {boolean}
         */
        withSingleActionsColumn() {
            return this.rowExpanderAvailable() ||
                    this.singleActionsAvailable() ||
                    this.reorderingAvailable();
        }

        /**
         * Checks whether grid should render a top panel with multiple actions and search.
         * @returns {boolean}
         */
        withTopPanel() {
            const { layout } = this.config;

            return this.withMultipleActions() || !layout || !layout.hideSearch || false;
        }

        /**
         * Returns true if grid config has multiple actions defined.
         * @returns {boolean}
         */
        withMultipleActions() {
            const { config } = this;

            return Array.isArray(config.multipleactions) && config.multipleactions.length > 0;
        }

        /**
         * Returns max number of single actions available per every row. This includes not just
         * explicitly set singleactions but also drag-and-drop reordering and row expander
         * feature.
         * @returns {number}
         */
        getTotalSingleActionsLength() {
            let length = 0;

            if (this.singleActionsAvailable()) {
                length += this.config.singleactions.length;
            }

            if (this.reorderingAvailable()) {
                length += this.dragAndDropAvailable() ? 3 : 2;
            }

            if (this.rowExpanderAvailable()) {
                length++;
            }

            return length;
        }

        /**
         * Returns true if drag-and-drop feature is requested for the grid.
         * @returns {boolean}
         */
        dragAndDropAvailable() {
            return !!this.config.dragAndDropReordering;
        }

        /**
         * Returns true if reordering feature is requested for the grid. Reordering is supported
         * through drag-and-drop and(or) by special single action.
         * @returns {boolean}
         */
        reorderingAvailable() {
            const { config } = this;

            return !!(config.withReordering || config.dragAndDropReordering);
        }

        /**
         * Returns custom class name for the row (if applicable).
         * @param {*} row
         * @returns {string}
         */
        getCustomRowClassName(row) {
            const { rowClass } = this.config;

            return rowClass && angular.isFunction(rowClass) && rowClass(row) || '';
        }

        /**
         * Returns class name for the row expander button.
         * @param {string} rowId
         * @returns {string}
         */
        getRowExpanderIconClassName(rowId) {
            const stateIcon = this.rowIsExpanded(rowId) ? 'minus' : 'plus';

            return `icon-${stateIcon}`;
        }

        /**
         * Returns a list of space separated class name for the row.
         * @param {*} row
         * @param {number }index
         * @returns {string}
         */
        getRowClassNames(row, index) {
            const
                rowId = this.rowId(row),
                classNames = [
                        `sel-row-${index}`,
                ];

            if (this.isRowSelected(rowId)) {
                classNames.push(`${tableRowClassName}--selected`);
            }

            if (this.rowExpanderAvailable()) {
                if (this.rowExpanderAvailable(row)) {
                    if (this.rowIsExpanded(rowId)) {
                        classNames.push(`${tableRowClassName}--expanded`);
                    }
                }
            }

            const customClassName = this.getCustomRowClassName(row);

            if (customClassName) {
                classNames.push(customClassName);
            }

            return classNames.join(' ');
        }

        /**
         * Event handler for up and down single action icons.
         * @param {string} rowId
         * @param {string} direction - "up" or "down"
         */
        moveRow(rowId, direction) {
            const
                { filteredRows } = this,
                from = _.findIndex(filteredRows, row => this.rowId(row) === rowId);

            let to = from;

            switch (direction) {
                case 'up':
                    to--;
                    break;

                case 'down':
                    to++;
                    break;
            }

            this.onRowMove(from, to);
        }

        /**
         * Changes filteredRows AND rows lists order.
         * @param {number} from - Row index to move from
         * @param {number} to - Row index to move to
         * @param {boolean=} triggerDigest - Will trigger angular digest loop when truethy
         *     value is passed.
         */
        onRowMove(from, to, triggerDigest = false) {
            if (!this.reorderingAvailable() ||
                    angular.isUndefined(to) ||
                    angular.isUndefined(from) ||
                    from === -1 ||
                    to === -1 ||
                    from === to) {
                return;
            }

            const
                { $scope, rows, filteredRows } = this,
                row = filteredRows[from],
                rowToBeSwappedWith = filteredRows[to];

            //filteredRows will pick up new order through watchCollection on the rows list

            const
                rowsFrom = rows.indexOf(row),
                rowsTo = rows.indexOf(rowToBeSwappedWith);

            //if event handler is set we expect it to take care of reordering
            if (this.rowMoveEventHandler) {
                this.rowMoveEventHandler({
                    from: rowsFrom,
                    to: rowsTo,
                });
            } else {
                //otherwise we will do so
                rows[rowsTo] = row;
                rows[rowsFrom] = rowToBeSwappedWith;

                if (triggerDigest) {
                    $scope.$digest();
                }
            }
        }

        /**
         * Used in template.
         * helps to disable the action button
         * @param {Object} action
         * @return {boolean}
         */
        multipleActionDisabled(action) {
            return this.isDisabled || this.noRowsSelected ||
                    gridConfigTools.isMultipleActionDisabled(action, this.getSelectedRows());
        }

        /**
         * Calls action on selected rows, after that clears selection if the action returned
         * true
         * @param {Object} action - The action object (defined in config).
         */
        runMultipleAction(action) {
            const
                rows = this.getSelectedRows(),
                done = gridConfigTools.execMultipleAction(action, rows);

            if (done) {
                this.clearSelection();
            }
        }

        /**
         * Filters array of rows by searchString (when set) or just copies whole array.
         * @protected
         */
        //TODO stop drag and drop process if active
        updateFilteredRows_() {
            const {
                rows,
                config,
                searchString,
            } = this;

            //filteredRows has to be copied for correct drag and drop support
            if (searchString) {
                this.filteredRows = _.filter(rows,
                    row => rowSearch(row, searchString, config.searchFields));
            } else if (Array.isArray(rows)) {
                this.filteredRows = rows.concat();
            } else {
                this.filteredRows = [];
            }

            this.filteredRowsMap_ = new Map(
                this.filteredRows.map((row, index) => [row, index]),
            );

            this.updateSelectedHash_();
        }

        /**
         * Filters rows based on updated search string.
         */
        onSearchUpdate() {
            this.updateFilteredRows_();
        }

        /**
         * Get index of row from filteredRowsMap. This serves as unique row id
         * @private
         * @param {*} row
         * @returns {number}
         */
        getUniqueRowID_(row) {
            return this.filteredRowsMap_.get(row);
        }

        /**
         * Returns true if row is first in the original list.
         * @param {*} row
         * @returns {boolean}
         */
        isFirstRow(row) {
            const { rows } = this;

            return rows[0] === row;
        }

        /**
         * Returns true if row is last in the original list.
         * @param {*} row
         * @returns {boolean}
         */
        isLastRow(row) {
            const
                { rows } = this,
                { length } = rows;

            return rows[length - 1] === row;
        }

        /**
         * Returns table cell class name containing fieldConfig.name for further customization
         * in CSS.
         * @param {gridFieldConfig} fieldConfig
         * @return {string}
         */
        getTableCellClassName(fieldConfig) {
            const fieldName = gridConfigTools.getFieldClassName(fieldConfig);

            return `${tableCellClassName}--field-name--${fieldName}`;
        }
    }

    GridCtrl.$inject = [
        '$element',
        '$scope',
        'l10nService',
    ];

    gridCtrlCssRulesMixin(GridCtrl);
    gridCtrlRowExpanderMixin(GridCtrl);
    gridCtrlRowSelectionMixin(GridCtrl);
    gridCtrlRowsSortingMixin(GridCtrl);

    return {
        bindToController: {
            rows: '<',
            config: '<',
            isDisabled: '<',
            rowMoveEventHandler: '&?onRowMove',
            labelEmpty: '@',
            labelSearchEmpty: '@',
        },
        controller: GridCtrl,
        template: require('./grid.partial.html'),
        controllerAs: '$ctrl',
        scope: {}, //wanna have isolated scope
        restrict: 'E',
    };
}

gridDirective.$inject = [
    '$timeout',
    'gridConfigTools',
    'propLookup',
    'gridCtrlRowExpanderMixin',
    'gridCtrlCssRulesMixin',
    'gridCtrlRowSelectionMixin',
    'gridCtrlRowsSortingMixin',
];

/**
 * @ngdoc directive
 * @restrict E
 * @name grid
 * @memberOf module:avi/component-kit/grid
 * @param {gridConfig} config
 * @param {Array<*>} rows
 * @param {boolean=} isDisabled - Disables most of grid actions when true is passed.
 * @param {string=} labelEmpty - Text label presented by grid when now rows were passed.
 * @param {string=} labelSearchEmpty - Text label presented by grid when not a single row is
 *     matching a search string provided by the user.
 *
 * @desc
 *
 *     Grid (table) to render passed list of rows (of arbitrary type).
 *     Search, selection, sorting, "single" and "multiple" actions are supported.
 *     Columns are resizable, but list of columns is fixed.
 *     Renders all rows within available viewport, doesn't support pagination.
 *
 * @example
 *
 * <grid
 *     config="::$ctrl.gridConfig"
 *     rows="$ctrl.rows"
 *     ng-disabled="$ctrl.isDisabled"
 *     label-empty="No products avaiable"
 *     label-search-empty="No products were found"></grid>
 *
 * //Where rows are
 * this.rows = [
 *     {name: 'Pepsi', price: 1.3},
 *     {name: 'Coke', price: 1.4},
 *     {name: 'Green Tea', price: 0.99}
 * ];
 *
 * //and gridConfig is an object with certain properties
 * //changes to config object won't be honored by the grid
 * this.gridConfig = {
 *     fields: [{//required
 *         name: 'name',//required
 *         title: 'Product',
 *         sortBy: 'name'
 *             //field name (or path) of the row object to be used for sorting or function
 *             //template and transform optional properties are supported: {@link Cell}
 *     }, {
 *         name: 'price',
 *         title: 'Price',
 *         transform: row => row.price,
 *         sortBy: (a, b) => a.price - b.price
 *     }],
 *
 *     // will be used to track table rows, must return unique value per each row
 *     rowId: row => row.name,//or "name" as a field name, required
 *
 *     // Multiple actions is a list of action objects with:
 *     // 'do' function is a function that perform action on selected items
 *     // when 'do' returns true after an action, grid clears the selection of the rows
 *     multipleactions: [{
 *         title: 'Remove',//required
 *         do: rows => //filter this.rows
 *     }],
 *
 *     // Single actions is a list of action objects defining actions, rendered as icons in the
 *     // rightmost column of the grid.
 *     // don't forget to determine class (usually fontawesome or bootstrap class)
 *     // 'do' method is supposed to perform the desired action
 *     singleactions: [{
 *         title: 'Add',//required
 *         class: 'icon-plus',//required
 *         hidden: row => row.added,
 *         disabled: row => row.name === 'Coke',
 *         do: row => row.added = true//required
 *     }, {
 *         title: 'Remove',
 *         class: 'icon-plus',
 *         hidden: row => !row.added,
 *         do: row => row.added = false
 *     }],
 *
 *     // pass function to compute and add special class based on the table row object
 *     rowClass: row => row.added ? 'green-background' : '';
 *
 *     layout: {
 *        hideDisplaying: true, //pass true to hide number of table rows
 *        hideSearch: true //pass true to hide search input
 *     }
 *
 *     defaultSorting: '-name',
 *         // default sorting (field name has to be passed) to be used by default.
 *         // If starts with '-' symbol order will get reversed.
 *
 *     searchFields: ['name', 'price']
 *         // Array of strings specifying the properties (names or paths)
 *         // that grid search should search through.
 * };
 *
 * @author Alex Malitsky
 */
angular.module('avi/component-kit/grid')
    .directive('grid', gridDirective);
