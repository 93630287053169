/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

/**
 * @description Search component which can be used to search data.
 * @example
 *     <avi-search
 *         [placeholder]="Search by name"
 *         (onSearch)="handleSearch($event)"
 *     ></avi-search>
 *
 * @author Nisar Nadaf
 */

import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './avi-search.l10n';
import './avi-search.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

@Component({
    selector: 'avi-search',
    templateUrl: './avi-search.component.html',
})
export class AviSearchComponent {
    /**
     * Optional placeholder for search input field.
     */
    @Input()
    public placeholder?: string;

    /**
     * Fires on search is confirmed by 'Enter' or emptied by the clear icon.
     */
    @Output()
    public onSearch = new EventEmitter();

    /**
     * Ref for search input. Used to set focus on search icon click.
     */
    @ViewChild('searchInput')
    public searchInputRef: ElementRef;

    /**
     * Token used for search.
     */
    public searchValue: string;

    /**
     * If true, show the search bar, otherwise show search icon only.
     */
    public showSearchBar = false;

    /**
     * Get keys from source bundles for template usage
     */
    private readonly l10nKeys = l10nKeys;

    constructor(
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Handler for search form submit.
     */
    public search(value: string): void {
        this.onSearch.emit(value);
    }

    /**
     * Clear the search input field.
     */
    public clearSearch(): void {
        this.searchValue = undefined;
        this.showSearchBar = false;

        this.search(undefined);
    }

    /**
     * Expand the search section with a search bar and a clear icon.
     */
    public expandSearchBar(): void {
        this.showSearchBar = true;

        setTimeout(() => {
            this.searchInputRef.nativeElement.focus();
        });
    }

    /**
     * Returns placeholder for input field.
     */
    public get InputPlaceholder(): string {
        return this.placeholder ||
            this.l10nService.getMessage(this.l10nKeys.searchInputPlaceholder);
    }
}
