/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module SharedModule
 */

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { isEmpty } from 'underscore';
import { L10nService } from '@vmw/ngx-vip';
import {
    containsDropdownOptionByValue,
    getDisplayLabel,
    getValue,
} from '../avi-dropdown.utils';
import { DropdownModelSingleValue, IAviDropdownOption } from '../avi-dropdown.types';
import { OPTION_HEIGHT } from '../avi-dropdown.constants';
import './avi-dropdown-options.component.less';
import * as l10n from './avi-dropdown-options.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component for the dropdown options.
 * @author alextsg, Alex Klyuev
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'avi-dropdown-options',
    templateUrl: './avi-dropdown-options.component.html',
})
export class AviDropdownOptionsComponent<
    TDropdownOptionValue = DropdownModelSingleValue
> implements OnInit, OnChanges {
    /**
     * List of dropdown options to show in the menu.
     */
    @Input() public options: Array<IAviDropdownOption<TDropdownOptionValue>> = [];

    /**
     * List of dropdown options that have been selected.
     */
    @Input() public selectedOptions: IAviDropdownOption[] = [];

    /**
     * Width used for the viewport dimensions.
     */
    @Input() public width: number;

    /**
     * Height used for the viewport dimensions.
     */
    @Input() public height: number;

    /**
     * If true, shows a spinner under the options.
     */
    @Input() public busy = false;

    /**
     * Hides dropdown when there are no options.
     */
    @Input()
    public hideIfNoOptions = false;

    /**
     * Template ref for custom option templates.
     */
    @Input()
    public optionTemplateRef?: TemplateRef<HTMLElement>;

    /**
     * Message to display if there are no options.
     */
    @Input()
    public noEntriesMessage?: string;

    /**
     * Error message to display.
     */
    @Input()
    public errorMessage?: string;

    /**
     * Event emitter for selecting an option.
     */
    @Output() public onOptionSelect = new EventEmitter<IAviDropdownOption<TDropdownOptionValue>>();

    /**
     * Event emitter for user submission without selecting an option.
     */
    @Output() public onSubmit = new EventEmitter<void>();

    /**
     * Event emitter for scrolling to the end of the list.
     */
    @Output() public onScrollEnd = new EventEmitter<number>();

    @ViewChild(CdkVirtualScrollViewport) private viewport: CdkVirtualScrollViewport;

    /**
     * Height of each dropdown option. Passed to the virtual scrolling CDK.
     */
    public optionHeight = OPTION_HEIGHT;

    /**
     * Index of the option at the top of the viewport.
     */
    public scrollIndex = 0;

    /**
     * Index of the currently highlighted option.
     */
    public highlightedIndex: number | null = null;

    /**
     * Don't highlight any fields when true.
     */
    public ignoreHighlight = true;

    /**
     * Ignore mouse enter events when true.
     */
    public ignoreMouseEnter = false;

    /**
     * When true, set highlighted index to null on mouse leave.
     */
    public resetHighlightIndexOnMouseLeave = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Listen for and handle various keyboard events.
     */
    @HostListener('document:keydown', ['$event.key'])
    private handleKeyPress(key: string): void {
        switch (key) {
            //  Increment the highlighted index and scroll index for up and down keyboard presses.
            case 'ArrowDown':
                this.ignoreMouseEnter = true;
                this.ignoreHighlight = false;

                if (this.highlightedIndex === null) {
                    this.highlightedIndex = 0;

                    return;
                }

                if (this.highlightedIndex === this.options.length - 1) {
                    return;
                }

                this.highlightedIndex++;

                if (this.highlightedIndex - this.scrollIndex > 5) {
                    this.viewport.scrollToIndex(this.scrollIndex + 1, 'auto');
                }

                break;

            case 'ArrowUp':
                this.ignoreMouseEnter = true;
                this.ignoreHighlight = false;

                if (this.highlightedIndex === null) {
                    return;
                }

                if (this.highlightedIndex === 0) {
                    this.highlightedIndex = null;

                    return;
                }

                this.highlightedIndex--;

                if (this.highlightedIndex < this.scrollIndex) {
                    this.viewport.scrollToIndex(this.scrollIndex - 1, 'auto');
                }

                break;

            // Select the currently highlighted item for tab and enter presses.
            // If no option is highlighted, emit submission event.
            case 'Tab':
            case 'Enter':
                if (this.ignoreHighlight) {
                    this.onSubmit.emit();

                    return;
                }

                this.onOptionSelect.emit(this.options[this.highlightedIndex]);

                break;
        }
    }

    /**
     * Remove highlights when mouse leaves dropdown.
     */
    @HostListener('mouseleave')
    private handleMouseLeave(): void {
        if (this.resetHighlightIndexOnMouseLeave) {
            this.highlightedIndex = null;
        }

        this.ignoreHighlight = true;
    }

    /** @override */
    public ngOnInit(): void {
        // Avoid condition when top choice is highlighted then unhighlighted while
        // dropdown is being constructed.
        this.resetHighlightIndexOnMouseLeave = true;
        setTimeout(() => this.resetHighlightIndexOnMouseLeave = false, 200);
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { height } = changes;

        if (!isEmpty(height)) {
            const { firstChange, previousValue, currentValue } = height;

            if (firstChange) {
                return;
            }

            if (previousValue !== currentValue) {
                setTimeout(() => this.viewport.checkViewportSize());
            }
        }

        const { options } = changes;

        // if no options, remove the highlight so that no option can be selected
        // by keyboard actions
        if (options?.currentValue?.length === 0) {
            this.ignoreHighlight = true;
        }
    }

    /**
     * Update the highlighted option index when the mouse hovers over it.
     */
    public handleMouseEnter(index: number): void {
        if (this.ignoreMouseEnter) {
            return;
        }

        this.ignoreHighlight = false;
        this.highlightedIndex = index;
    }

    /**
     * Update the highlighted index from mouse movements when mouseenter is ignored.
     */
    public handleMouseMove(index: number): void {
        if (this.ignoreMouseEnter) {
            this.highlightedIndex = index;
            this.ignoreMouseEnter = false;
        }
    }

    /**
     * Handler for selecting a dropdown option.
     */
    public handleSelect(dropdownOption: IAviDropdownOption<TDropdownOptionValue>): void {
        this.onOptionSelect.emit(dropdownOption);
    }

    /**
     * Returns the text to be shown based on the DropdownOption.
     */
    public getOptionLabel(dropdownOption: IAviDropdownOption): string {
        return getDisplayLabel(dropdownOption);
    }

    /**
     * Returns true if the dropdownOption is selected.
     */
    public isSelected(dropdownOption: IAviDropdownOption): boolean {
        return containsDropdownOptionByValue(dropdownOption, this.selectedOptions);
    }

    /**
     * Returns true if the dropdownOption is highlighted.
     */
    public isHighlighted(index: number): boolean {
        if (this.ignoreHighlight) {
            return false;
        }

        return index === this.highlightedIndex;
    }

    /**
     * Returns the dropdown value from the dropdownOption object. Used as the trackBy function.
     */
    // eslint-disable-next-line class-methods-use-this
    public trackByValue(
        index: number,
        dropdownOption: IAviDropdownOption<TDropdownOptionValue>,
    ): IAviDropdownOption<TDropdownOptionValue>['value'] {
        return getValue(dropdownOption);
    }

    /**
     * Called when the scrolling changes. The onScrollEnd event is emitted when the user scrolls to
     * the bottom of the options list.
     */
    public handleScrollIndexChange(index: number): void {
        this.scrollIndex = index;

        const { end } = this.viewport.getRenderedRange();
        const total = this.viewport.getDataLength();

        if (end >= total) {
            this.onScrollEnd.emit(total);
        }
    }
}
