/**
 * @module IpamModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IAwsZoneNetwork,
    IIpamDnsAwsProfile,
    IProxyConfiguration,
} from 'generated-types';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import {
    extend,
    isEmpty,
    isObject,
} from 'underscore';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { IpamDnsAwsProfile } from 'object-types';

type TIIpamDnsAwsProfilePartial = Omit<IIpamDnsAwsProfile, 'zones'>;

interface IIpamDnsAwsProfileConfig extends TIIpamDnsAwsProfilePartial {
    zones?: RepeatedMessageItem<MessageItem<IAwsZoneNetwork>>
}

export interface IAwsRegion {
    name: string;
    description: string;
}

export interface IAwsVpc {
    id: string;
    name: string;
    cidr: string;
    default: boolean;
}

export interface IAwsAvailabilityZone {
    availability_zone: string;
    subnets: IAwsVpc[];
}

export interface IAwsAssumeRole {
    account: string;
    role: string;
}

const AWS_IAM_ASSUME_ROLES_FETCH_URL = '/api/aws-get-assume-roles';
const AWS_AVAILABILITY_ZONES_FETCH_URL = '/api/aws-get-networks';
const AWS_DOMAIN_LIST_URL = '/api/ipamdnsproviderprofiledomainlist';
const AWS_REGIONS_FETCH_URL = '/api/aws-get-regions';
const AWS_CREDENTIALS_VERIFICATION_URL = '/api/aws-verify-credentials';

/**
 * Verify AWS credentials request ID.
 */
const VERIFY_AWS_LOGIN_CREDENTIALS = 'verify-aws-login';

export interface IAwsCredentialsConfig {
    password: string;
    useProxy: boolean;
    proxy?: {
        host?: string;
        password?: string;
        port?: number;
        username?: string;
    },
    region: string;
    useIamRoles: boolean;
    username: string;
}
/**
 * @description IpamDnsAwsProfile ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class IpamDnsAwsProfileConfigItem extends MessageItem<IIpamDnsAwsProfileConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'defaultValues',
        'secretStubStr',
    ];

    private readonly httpWrapper: HttpWrapper;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: IpamDnsAwsProfile,
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Called when user changes VPC.
     * Removes all usable networks from AWS profile.
     */
    public clearUsableNetworks(): void {
        const { zones } = this.config;

        zones.removeAll();
    }

    /**
     * Called when user changes VPC. Removes all usable domains from AWS profile.
     */
    public clearUsableDomains(): void {
        const { usable_domains: usableDomains } = this.config;

        if (Array.isArray(usableDomains)) {
            usableDomains.length = 0;
        }
    }

    /**
     * Adds empty usable_network_refs entry to AWS profiles.
     */
    public addUsableNetwork(): void {
        this.config.zones.add({
            availability_zone: undefined,
            usable_network_uuids: [],
        });
    }

    /**
     * Removes Network ref from AWS network list.
     */
    public removeUsableNetwork(index = 0): void {
        this.config.zones.remove(index);
    }

    /**
     * Getter for usable domain objects.
     */
    public get usableDomains(): string[] {
        return this.config.usable_domains;
    }

    /**
     * Adds empty usable domain entry to AWS profiles.
     */
    public addUsableDomain(): void {
        if (this.config.usable_domains) {
            this.config.usable_domains = [];
        }

        this.config.usable_domains.push(undefined);
    }

    /**
     * Removes usable domain from AWS domain list.
     */
    public removeUsableDomain(index = 0): void {
        this.config.usable_domains.splice(index, 1);
    }

    /**
     * Requests AWS AssumeRole list.
     */
    public getIamAssumeRoles(
        proxyConfig: IProxyConfiguration,
        ipamProfileId?: string,
    ): Promise<IAwsAssumeRole[]> {
        const params = this.getParams(proxyConfig, ipamProfileId);

        this.errors = null;

        return this.httpWrapper.request({
            method: HttpMethod.POST,
            url: AWS_IAM_ASSUME_ROLES_FETCH_URL,
            data: params,
        })
            .then(({ data }) => data.assume_roles)
            .catch(({ data }) => this.errors = data);
    }

    /**
     * Requests the list of availability zones to be used for usable networks.
     */
    public getAvailabilityZones(
        proxyConfig: IProxyConfiguration,
        ipamProfileId?: string,
    ): Promise<IAwsAvailabilityZone[]> {
        const params = this.getParams(proxyConfig, ipamProfileId);

        this.errors = null;

        return this.httpWrapper.request({
            method: HttpMethod.POST,
            url: AWS_AVAILABILITY_ZONES_FETCH_URL,
            data: params,
        })
            .then(({ data }) => data.networks)
            .catch(({ data }) => {
                this.errors = data;

                return Promise.reject();
            });
    }

    /**
     * Returns a list of AWS domains.
     */
    public getDomains(
        proxyConfig: IProxyConfiguration,
        ipamProfileId?: string,
    ): Promise<string[]> {
        this.errors = null;

        const params = this.getParams(proxyConfig, ipamProfileId);
        const { ipamdnsprovider_uuid: uuid } = params;

        if (uuid) {
            return this.httpWrapper.request({
                method: HttpMethod.GET,
                url: `${AWS_DOMAIN_LIST_URL}?ipamdnsprovider_uuid=${uuid}`,
                data: params,
            })
                .then(({ data }) => data.domains || [])
                .catch(({ data }) => {
                    this.errors = data;

                    return Promise.reject(data);
                });
        }

        return Promise.resolve([]);
    }

    /**
     * Gets a list of AWS regions.
     */
    public getRegions(): Promise<IAwsRegion[]> {
        this.errors = null;

        return this.httpWrapper.request({
            method: HttpMethod.GET,
            url: AWS_REGIONS_FETCH_URL,
        })
            .then(({ data }) => data.regions)
            .catch(rsp => this.errors = rsp.data);
    }

    /**
     * Verifies AWS Credentials.
     */
    public verifyAwsCredentials(
        credentials: IAwsCredentialsConfig,
        ipamProfileId?: string,
    ): Promise<IAwsVpc[]> {
        const {
            password,
            useProxy,
            proxy,
            region,
            useIamRoles,
            username,
        } = credentials;

        const secretStubStr = this.getAjsDependency_('secretStubStr');

        const params: Record<string, string | boolean> = {
            region,
        };

        if (!useIamRoles) {
            params.username = username;
            params.password = password;
        } else {
            params.use_iam_roles = useIamRoles;
        }

        if (useProxy) {
            extend(params, {
                proxy_host: proxy.host,
                proxy_port: proxy.port,
                proxy_user: proxy.username,
                proxy_pass: proxy.password,
            });
        }

        if (ipamProfileId && (params.password === secretStubStr ||
            isObject(proxy) && proxy.password === secretStubStr)) {
            params.ipamdnsprovider_uuid = ipamProfileId;
            delete params.username;
            delete params.password;
            delete params.proxy_pass;
        }

        return this.httpWrapper.request({
            method: HttpMethod.POST,
            url: AWS_CREDENTIALS_VERIFICATION_URL,
            data: params,
            requestId: VERIFY_AWS_LOGIN_CREDENTIALS,
        })
            .then(({ data }) => data.vpcs)
            .catch(({ data }) => Promise.reject(data.error));
    }

    /**
     * Cancels verify crdentials request.
     */
    public cancelAwsVerifyCredentials(): void {
        this.httpWrapper.cancelRequest(VERIFY_AWS_LOGIN_CREDENTIALS);
    }

    /**
     * Sets verified crdentials in config.
     */
    public setAwsLoginCredentials(credentials: IAwsCredentialsConfig): void {
        const {
            password,
            region,
            useIamRoles,
            username,
        } = credentials;

        this.config.region = region;
        this.config.use_iam_roles = useIamRoles;
        this.config.access_key_id = username;
        this.config.secret_access_key = password;
    }

    /**
     * Gets list of AWS VPCs.
     * @param proxyConfig - Proxy configuration, alternative params used for AWS
     *     request.
     */
    public getVpcs(
        proxyConfig: IProxyConfiguration,
        ipamProfileId?: string,
    ): Promise<IAwsVpc[]> {
        const params = this.getParams(proxyConfig, ipamProfileId);

        this.errors = null;

        return this.httpWrapper.request({
            method: HttpMethod.POST,
            url: AWS_CREDENTIALS_VERIFICATION_URL,
            data: params,
        })
            .then(({ data }) => data.vpcs)
            .catch(({ data }) => {
                this.errors = data;

                return Promise.reject();
            });
    }

    /**
     * Sets iam_assume_role in aws_profile to undefined.
     */
    public removeIamAssumeRole(): void {
        const { config } = this;

        config.iam_assume_role = undefined;
    }

    /**
     * Getter for usable_domains count.
     */
    public get usableDomainsCount(): number {
        return Array.isArray(this.config.usable_domains) ? this.config.usable_domains.length : 0;
    }

    /**
     * @override
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        config.usable_domains = config.usable_domains || [];
    }

    /**
     * @override
     */
    protected modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        if (config.use_iam_roles) {
            delete config.access_key_id;
            delete config.secret_access_key;
        }

        if (isEmpty(config.usable_domains)) {
            delete config.usable_domains;
        }

        if (isEmpty(config.vpc_id)) {
            delete config.vpc_id;
        }

        if (isEmpty(config.usable_network_uuids)) {
            delete config.usable_network_uuids;
        }
    }

    /**
     * Returns params used for AWS requests. If sensitive fields are found, includes the
     * ipamProfileId (IPAM profile uuid).
     * @param proxyConfig - Proxy configuration, alternative params used for AWS
     *     requests.
     */
    private getParams(
        proxyConfig: IProxyConfiguration,
        ipamProfileId?: string,
    ): Record<string, string | boolean> {
        const {
            access_key_id: accessKeyId,
            secret_access_key: secretAccessKey,
            vpc_id: vpcId,
            region,
            use_iam_roles: useIamRoles,
            iam_assume_role: iamAssumeRole,
        } = this.config;

        const secretStubStr = this.getAjsDependency_('secretStubStr');

        const params: Record<string, string | boolean> = {
            username: accessKeyId,
            password: secretAccessKey,
            vpc: vpcId,
            region,
            use_iam_roles: useIamRoles,
            iam_assume_role: iamAssumeRole,
        };

        if (!isEmpty(proxyConfig)) {
            extend(params, {
                proxy_host: proxyConfig.host,
                proxy_port: proxyConfig.port,
                proxy_user: proxyConfig.username,
                proxy_pass: proxyConfig.password,
            });
        }

        if (ipamProfileId && (params.password === secretStubStr ||
            isObject(proxyConfig) && proxyConfig.password === secretStubStr)) {
            params.ipamdnsprovider_uuid = ipamProfileId;
            delete params.username;
            delete params.password;
            delete params.proxy_pass;
        }

        return params;
    }
}
