/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc constant
 * @name deleteUndefinedValues
 * @author Alex Malitsky
 * @description
 *
 *     Sometimes we want to use undefined values for property deletions (usually after using)
 *     angular.extend. This function will do it.
 */
angular.module('aviApp').constant('deleteUndefinedValues', function(hash) {
    _.each(hash, (value, key, hash) => angular.isUndefined(value) && delete hash[key]);

    return hash;
});
