/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @fileoverview Contains Ajs dependency tokens for auto-scale-policy related tokens.
 * @author Harmeet Kaur
 */

/**
 * Ajs dependency token for AutoScalePolicy collection.
 */
export const AUTO_SCALE_POLICY_COLLECTION_TOKEN = 'AutoScalePolicyCollection';

/**
 * Ajs dependency token for AutoScalePolicy item.
 */
export const AUTO_SCALE_POLICY_ITEM_TOKEN = 'AutoScalePolicy';

/**
 * Ajs dependency token for ScheduledScaling item.
 */
export const SCHEDULED_SCALING_ITEM_TOKEN = 'ScheduledScalingConfigItem';

/**
 * Ajs dependency token for Webhook collection.
 */
export const WEBHOOK_COLLECTION_TOKEN = 'WebhookCollection';

/**
 * Ajs dependency token for Webhook item.
 */
export const WEBHOOK_ITEM_TOKEN = 'WebhookItem';
