/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import '../../less/components/popover.less';

/*
* Unified service for popovers @am
* TODO support margins between popover and parent
* TODO carat placement dependant on the border of popover
* TODO show on timeout
* TODO calculate z-index of the parent by traversing DOM graph and use it for popover
* TODO extend EventEmitter
* */

angular.module('aviApp').factory('popoverFactory', [
'$timeout', '$rootScope', '$document', '$window',
function($timeout, $rootScope, $document, $window) {
    const popoverFactory = function(config) {
        /* config example, got redefined in a place of use */
        const self = this;

        this.$body_ = $($document.get(0).body);

        this.config = {
            parent: [[50, 50], [100, 100]],
            className: '',
            position: 'bottom',
            repositioning: false, //if we pass html and don't know size of popover in advance
            width: 100, //for initial positioning only
            height: 100,
            //margin: 5,//from window borders
            carat: false,
            //init will be called by show and popover will be removed from DOM on hide
            removeAfterHide: false,
            alternativePositions: 'strict', //all for all four, strict for left<>right and top<>btm
            hide: {
                outClick: true,
                //mouseOut: true,
                //timeout: false,
                //innerClick: false,
                //parentMouseOut,
                onEscape: true,
                onWinResize: true,
            },
        };

        if (config) {
            angular.extend(this.config, config);
        }

        if (config.hide && config.hide.parentMouseOut) {
            config.hide.mouseOut = true;
        }

        //if parent was hidden by scroll event, let's hide popover
        this._onScrollListener = _.throttle(function(event) {
            if (!self.isParentVisible($(event.target))) {
                self.hide();
            } else {
                self.reposition();
            }
        }, 10);

        this.scrollableParents = [];

        this.popover = false;
        this.carat = false;
    };

    /* to distinguish clicks inside popover or outside */
    popoverFactory.prototype.elemIsInside = function(elem) {
        const carat = this.carat && (elem === this.carat[0] || this.carat.has(elem).length);

        return this.popover && (elem === this.popover[0] || this.popover.has(elem).length) ||
            carat;
    };

    popoverFactory.prototype.hideOnBodyClick = function(e) {
        if (!this.elemIsInside(e.target) && (!this.parent || e.target !== this.parent[0])) {
            this.hide();
        }

        /* click on the element, which evoked popover */
        if (this.parent && e.target === this.parent[0]) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    popoverFactory.prototype.hideOnEscapeKeyDown = function(e) {
        if (e.which === 27) {
            e.stopPropagation();
        }
    };

    popoverFactory.prototype.hideOnEscapeKeyUp = function(e) {
        if (e.which === 27) {
            this.hide();
            e.stopPropagation();
        }
    };

    popoverFactory.prototype.hideOnWindowResize = function() {
        this.hide();
    };

    //need this properties for event  listeners removal. The list here is for the reference only.
    popoverFactory.prototype._onBodyClickListener = undefined;
    popoverFactory.prototype._onEscapeListenerKeyDown = undefined;
    popoverFactory.prototype._onEscapeListenerKeyUp = undefined;
    popoverFactory.prototype._onScrollListener = undefined;
    popoverFactory.prototype._onWindowResizeListener = undefined;
    popoverFactory.prototype._onParentMouseOutListener = undefined;
    popoverFactory.prototype._onMouseOutListener = undefined;

    /**
     * Checks if parent of popover is fully visible, otherwise we won't show or will hide Popover.
     * When we use it having onScroll event we don't need to check all scrollableParent, but one
     * from which we've got an event itself.
     *
     * @param target {Object} - jQuery object of event.target when use on scroll event
     * @returns {Boolean}
     * */
    popoverFactory.prototype.isParentVisible = function(target) {
        //if box of the element is out of the view even for one pixel
        function isNotVisible(elm, parent) {
            if (parent && parent[0] === $window) {
                return null;
            }

            const
                elmOffset = elm.offset(),
                //for document element
                parentOffset = parent.offset() || { top: $(window).scrollTop() },
                elmHeight = elm.outerHeight(),
                parentHeight = parent.outerHeight();

            return parentOffset.top + parentHeight < elmOffset.top + elmHeight ||
                elmOffset.top < parentOffset.top;
        }

        const self = this;

        let res;

        if (target) { //check only scrolled parent
            res = isNotVisible(this.parent, target);
        } else { //check all scrollable parents
            res = _.any(this.scrollableParents, function(parent) {
                return isNotVisible(self.parent, parent);
            });
        }

        return !res;
    };

    //need to know where to put scroll event listeners
    popoverFactory.prototype._findScrollableParents = function() {
        function isScrollable(node) {
            return node.css('overflow') === 'scroll' || node.css('overflow') === 'auto' ||
                node.css('overflowY') === 'scroll' || node.css('overflowY') === 'auto';
        }

        let
            node = this.parent,
            weAreDone;

        const scrollableParents = [];

        if (this.parent) {
            while (!weAreDone && node && node[0] && node[0].tagName &&
                !(node[0].tagName === 'HTML' || node[0].tagName === 'BODY')) {
                if (isScrollable(node)) {
                    scrollableParents.push(node);
                }

                //no point in going deeper as scroll events on parents won't work for the fixed
                //children
                if (node.css('position') === 'fixed') {
                    weAreDone = true;
                    //in case of absolutely positioned element should care only about offsetParent
                } else if (node.css('position') === 'absolute') {
                    node = node.offsetParent();
                } else {
                    node = node.parent();
                }
            }
        }

        if (!weAreDone) { //if we are not inside fixed, should care about generic scroll
            scrollableParents.push($(window));
        }

        return scrollableParents;
    };

    //TODO unitTests!
    popoverFactory.prototype._calcPosition = function(useActualSize) {
        /**
         * Counts offset objects for popover and carat depending on:
         * @param type {string} top/bottom/left/right
         * @param popover {object} properties: width, height
         * @param parent {object} coordinates of it's four corners
         * @param withCarat {boolean} do we need to calculate the carat position
         * @param windowSize {object} properties: width, height
         * @returns {{result: Object[], fitsWell: number}} fitsWell is a ration of visible popover
         *     area
         * (inside viewport) to expected area. Used to choose the best positioning type.
         */
        function countThisPosition(type, popover, parent, withCarat, windowSize) {
            /**
             * Counts coordinates of the popover starting from top left corner of the window.
             * Needed to calculate the visible popover's area.
             * @popPos {object} having top or bottom and left or right for following popover
             *     positioning
             * @popover {object} properties: height and width of popover
             * @window {object} properties: height and width of the window
             * */
            function coordinatesFromOffset(popPos, popover, windowSize) {
                const
                    coordinates = { //top left, top right, bottom right, bottom left
                        tl: [0, 0],
                        tr: [0, 0],
                        br: [0, 0],
                        bl: [0, 0], //x,y
                    };

                if (popPos.left) { // X
                    coordinates.bl[0] = popPos.left;
                    coordinates.tl[0] = coordinates.bl[0];
                    coordinates.br[0] = coordinates.bl[0] + popover.width;
                    coordinates.tr[0] = coordinates.br[0];
                } else { //right
                    coordinates.br[0] = windowSize.width - popPos.right;
                    coordinates.tr[0] = coordinates.br[0];
                    coordinates.bl[0] = coordinates.br[0] - popover.width;
                    coordinates.tl[0] = coordinates.bl[0];
                }

                if (popPos.top) { // Y
                    coordinates.tl[1] = popPos.top;
                    coordinates.tr[1] = coordinates.tl[1];
                    coordinates.bl[1] = coordinates.tl[1] + popover.height;
                    coordinates.br[1] = coordinates.bl[1];
                } else { //bottom
                    coordinates.bl[1] = windowSize.height - popPos.bottom;
                    coordinates.br[1] = coordinates.bl[1];
                    coordinates.tl[1] = coordinates.bl[1] - popover.height;
                    coordinates.tr[1] = coordinates.tl[1];
                }

                return coordinates;
            }

            const square = popover.width * popover.height;

            const carat = {
                height: 10,
                width: 10,
            };

            const popPos = {};
            let carPos = {};

            switch (type) {
                case 'left':
                    popPos.right = windowSize.width - parent[0][0];
                    popPos.top = (parent[0][1] + parent[1][1]) / 2 - popover.height / 2;

                    if (withCarat) {
                        popPos.right += carat.width;
                        carPos.right = popPos.right + 1;
                        carPos.top = (parent[0][1] + parent[1][1]) / 2 - carat.height + 2;
                    }

                    break;

                case 'right':
                    popPos.left = parent[1][0];
                    popPos.top = (parent[0][1] + parent[1][1]) / 2 - popover.height / 2;

                    if (withCarat) {
                        popPos.left += carat.width;
                        carPos.left = popPos.left - 2 * carat.width + 1;
                        carPos.top = (parent[0][1] + parent[1][1]) / 2 - carat.height + 2;
                    }

                    break;

                case 'bottom':
                    popPos.top = parent[1][1];
                    popPos.left = (parent[0][0] + parent[1][0]) / 2 - popover.width / 2;

                    if (withCarat) {
                        popPos.top += carat.height;
                        carPos.left = (parent[0][0] + parent[1][0]) / 2 - carat.width;
                        carPos.top = popPos.top - 2 * carat.height + 2;
                    }

                    break;

                case 'top':
                    popPos.bottom = windowSize.height - parent[0][1];
                    popPos.left = (parent[0][0] + parent[1][0]) / 2 - popover.width / 2;

                    if (withCarat) {
                        popPos.bottom += carat.height;
                        carPos.left = (parent[0][0] + parent[1][0]) / 2 - carat.width;
                        carPos.bottom = popPos.bottom + 2;
                    }

                    break;
            }

            // tl, tr, br, bl coordinates
            const coordinates = coordinatesFromOffset(popPos, popover, windowSize);

            //clip them by the window size with respect to the margins
            _.each(coordinates, function(point) {
                point[0] = Math.clipValue(point[0], margin, windowSize.width - margin);
                point[1] = Math.clipValue(point[1], margin, windowSize.height - margin);
            });

            const visibleSquare = Math.abs(coordinates.tr[0] -
                coordinates.tl[0]) * Math.abs(coordinates.bl[1] - coordinates.tl[1]);

            // ratio of the visible popover area to the whole popover's area
            const fitsWell = square ? visibleSquare / square : 0;

            // clip offset parameters to fit window with respect to the margins
            // we can tweak only axis different from positioning direction, i.e. for left-right
            // we can tweak only top&bottom and vice versa. Otherwise we might cover (hide) the
            // parent.
            _.each(popPos, function(val, key) {
                //check position to be inside inside viewport with set margins
                if (key === 'left' || key === 'right' && !(type === 'left' || type === 'right')) {
                    val = Math.clipValue(val, margin, windowSize.width - popover.width - margin);
                } else if (key === 'bottom' || key === 'top' &&
                    !(type === 'top' || type === 'bottom')) {
                    val = Math.clipValue(val, margin, windowSize.height - popover.height - margin);
                }

                popPos[key] = val;
            });

            let carMargin;

            if (withCarat) {
                if (type === 'left' || type === 'right') {
                    carMargin = {
                        vertical: margin,
                        horizontal: -2 * carat.width,
                    };
                } else {
                    carMargin = {
                        vertical: -2 * carat.width,
                        horizontal: margin,
                    };
                }

                if (carPos.left) {
                    carPos.left = Math.clipValue(carPos.left, popPos.left + carMargin.horizontal,
                        popPos.left + popover.width - carMargin.horizontal);
                } else {
                    carPos.right = Math.clipValue(carPos.right, popPos.right + carMargin.horizontal,
                        popPos.right + popover.width - carMargin.horizontal);
                }

                if (carPos.top) {
                    carPos.top = Math.clipValue(carPos.top, popPos.top + carMargin.vertical,
                        popPos.top + popover.height - carMargin.vertical);
                } else {
                    carPos.bottom = Math.clipValue(carPos.bottom, popPos.bottom +
                        carMargin.vertical, popPos.bottom + popover.height - carMargin.vertical);
                }
            } else { //say no to environmental pollution
                carPos = undefined;
            }

            return {
                fitsWell,
                result: [popPos, carPos],
            };
        }

        const alternativeDirectons = {
            left: ['right', 'top', 'bottom'],
            right: ['left', 'top', 'bottom'],
            top: ['bottom', 'right', 'left'],
            bottom: ['top', 'right', 'left'],
        };

        const res = {}; //{result: [popPos, carPos], position: ''}

        let
            parent = [[], []], //[[0, 0],[50, 50]] top+left & bottom+right positions
            parentPos,
            margin, //margin from window borders
            //to flip popover if there is not enough room for the desired position
            pos2;//we count up to two possible positions: desired and best alternative

        const windowSize = { //width and height of popover and window
            height: $(window).height(),
            width: $(window).width(),
        };

        const popover = {
            height: useActualSize && this.popover.outerHeight() || this.config.height,
            width: useActualSize && this.popover.outerWidth() || this.config.width,
        };

        margin = typeof this.config.margin === 'number' ? this.config.margin : 0;

        if (this.parent && this.parent[0] !== $window) {
            parentPos = this.parent.offset();
            parent = [
                [parentPos.left, parentPos.top - $(window).scrollTop()],
                [parentPos.left + this.parent.outerWidth(), parentPos.top -
                    $(window).scrollTop() + this.parent.outerHeight()],
            ];
        } else {
            parent = this.config.parent;
        }

        // we have an option to use opposite positioning if there is no room for desired one
        const pos1 = countThisPosition(this.config.position, popover, parent, this.config.carat,
            windowSize);

        if (pos1.fitsWell < 1) {
            let bestFit = pos1.fitsWell,
                bestPosition = this.config.position,
                alternatives;

            if (this.config.alternativePositions === 'all') {
                alternatives = alternativeDirectons[this.config.position];
            } else { //previous default behaviour, 'restricted' - only opposite
                alternatives = alternativeDirectons[this.config.position].slice(0, 1);
            }

            _.each(alternatives, function(direction) {
                const pos = countThisPosition(direction, popover, parent,
                    this.config.carat, windowSize);

                if (pos.fitsWell > bestFit) {
                    bestPosition = direction;
                    bestFit = pos.fitsWell;
                    pos2 = pos;
                }
            }, this);

            res.position = bestPosition;
            res.result = bestPosition !== this.config.position ? pos2.result : pos1.result;
        } else {
            res.result = pos1.result;
            res.position = this.config.position;
        }

        return res;
    };

    popoverFactory.prototype.setOnScrollListeners = function() {
        const self = this;

        this.scrollableParents.forEach(function(parent) {
            parent.on('scroll', self._onScrollListener);
        });
    };

    popoverFactory.prototype.removeScrollListeners = function() {
        const self = this;

        this.scrollableParents.forEach(function(parent) {
            parent.off('scroll', self._onScrollListener);
        });
    };

    /* parent - element which evoked popover, optional */
    popoverFactory.prototype.init = function(parent) {
        const self = this;

        if (this.removeTimeout) {
            $timeout.cancel(this.removeTimeout);
            delete this.removeTimeout;
        }

        if (parent) {
            this.parent = $(parent);
        }

        if (this.popover || this.carat) {
            this.remove(true);
        }

        this.scrollableParents = this._findScrollableParents();

        const pos = this._calcPosition();

        this.popover = $('<div/>')
            .addClass(`aviPopover ${this.config.className}`)
            .css('display', 'none')
            .css(pos.result[0])
            .on('mouseleave', self.remove);

        if (this.config.html) {
            this.popover
                .html(this.config.html)
                .appendTo(this.$body_);
        }

        if (this.config.carat) {
            this.carat = $('<div/>')
                .addClass(`aviPopoverCarat ${this.config.className} ${pos.position}`)
                .css('display', 'none')
                .css(pos.result[1]);

            this.carat.appendTo(this.$body_);
        }
    };

    popoverFactory.prototype.setHideListeners = function() {
        const
            self = this;

        if (this.config.hide) {
            if (this.config.hide['outClick']) {
                //hook to be able to delete function after click
                this._onBodyClickListener = this.hideOnBodyClick.bind(this);
                this.$body_.get(0).addEventListener('click', this._onBodyClickListener, true);
            }

            if (this.config.hide['onEscape']) {
                this._onEscapeListenerKeyUp = this.hideOnEscapeKeyUp.bind(this);
                this._onEscapeListenerKeyDown = this.hideOnEscapeKeyDown.bind(this);

                this.$body_.get(0).addEventListener('keyup', this._onEscapeListenerKeyUp, true);
                this.$body_.get(0).addEventListener('keydown', this._onEscapeListenerKeyDown, true);
            }

            if (this.config.hide['innerClick']) {
                this.popover.on('click', function(e) {
                    $timeout(self.hide.bind(self));
                });
            }

            if (this.config.hide['mouseOut']) {
                this._onMouseOutListener = function(event) {
                    const target = event.toElement || event.relatedTarget;

                    if (!target ||
                        !(self.elemIsInside(target) ||
                        target === self.parent ||
                        self.parent.has(target).length)) {
                        self.hide();
                    }
                };

                this.popover.on('mouseout', this._onMouseOutListener);

                if (this.config.carat) {
                    this.carat.on('mouseout', this._onMouseOutListener);
                }
            }

            if (this.config.hide['parentMouseOut']) {
                this._onParentMouseOutListener = function(event) {
                    const target = event.toElement || event.relatedTarget;

                    if (!target ||
                        !(self.elemIsInside(target) ||
                        target === self.parent ||
                        self.parent.has(target).length)) {
                        self.hide();
                    }
                };

                this.parent.on('mouseout', this._onParentMouseOutListener);
            }

            if (this.config.hide['onWinResize']) {
                this._onWindowResizeListener =
                    $rootScope.$on('repaint', this.hideOnWindowResize.bind(this));
            }
        }
    };

    popoverFactory.prototype.show = function(parent) {
        const self = this;

        if (this.config.removeAfterHide && parent) {
            this.init(parent);
        }

        if (this.removeTimeout) {
            $timeout.cancel(this.removeTimeout);
            delete this.removeTimeout;
        }

        if (this.config.repositioning) {
            $timeout(function() {
                self.reposition();
                self.popover.css('opacity', 1);

                if (self.config.carat) {
                    self.carat.css('opacity', 1);
                }
            });
        }

        if (this.popover && this.popover.is(':hidden') && this.isParentVisible()) {
            if (this.config.repositioning) {
                this.popover.css('opacity', 0);
            }

            this.popover.show();

            this.setOnScrollListeners();
            this.setHideListeners();

            if (this.config.carat) {
                if (this.config.repositioning) {
                    this.carat.css('opacity', 0);
                }

                this.carat.show();
            }
        }
    };

    //when we don't know popover size in advance have to check and update position after appending
    /**
     * Repositions popover on the page. Most often used immediately after element has been put
     * into DOM and got it's initial width and size values.
     * @param {boolean=} useConfigSize - When set to true method will use width and height values
     *     from the config object rather then actual size values. Needed when size of popover is
     *     not fixed and can be changed later.
     * @public
     */
    popoverFactory.prototype.reposition = function(useConfigSize) {
        let pos;

        if (this.popover) {
            pos = this._calcPosition(!useConfigSize);

            this.popover.css({
                top: '',
                bottom: '',
                left: '',
                right: '',
            });

            this.popover.css(pos.result[0]);//offset takes only top and left

            if (this.config.carat) {
                this.carat.removeClass('top bottom left right');
                this.carat.addClass(pos.position);
                this.carat.css(pos.result[1]);
            }
        }
    };

    popoverFactory.prototype.removeHideListeners = function() {
        const self = this;

        if (this.config.hide) {
            if (this.config.hide['outClick']) {
                this.$body_.get(0).removeEventListener('click', this._onBodyClickListener, true);
                delete this._onBodyClickListener;
            }

            if (this.config.hide['onEscape']) {
                this.$body_.get(0).removeEventListener('keydown',
                    self._onEscapeListenerKeyDown, true);
                this.$body_.get(0).removeEventListener('keyup',
                    self._onEscapeListenerKeyUp, true);
                delete self._onEscapeListenerKeyDown;
                delete self._onEscapeListenerKeyUp;
            }

            if (this.config.hide['mouseOut']) {
                this.popover.off('mouseout', this._onMouseOutListener);

                if (this.config.carat) {
                    this.carat.off('mouseout', this._onMouseOutListener);
                }

                delete this._onMouseOutListener;
            }

            if (this.config.hide['parentMouseOut']) {
                this.parent.off('mouseout', this._onParentMouseOutListener);
                delete this._onParentMouseOutListener;
            }

            if (this.config.hide['onWinResize']) {
                this._onWindowResizeListener();//angularJS $on listener, other way to remove
                delete this._onWindowResizeListener;
            }
        }
    };

    popoverFactory.prototype.hide = function() {
        const self = this;
        let removeAfter;

        if (this.popover && this.popover.is(':visible')) {
            this.popover.hide();
            this.removeScrollListeners();
            this.removeHideListeners();
        }

        if (this.carat) {
            this.carat.hide();
        }

        if (this.config.removeAfterHide && !this.removeTimeout) {
            removeAfter = typeof this.config.removeAfterHide === 'number' ?
                this.config.removeAfterHide : 199;

            this.removeTimeout = $timeout(function() {
                self.remove(true);
                delete self.removeTimeout;
            }, removeAfter);
        }
    };

    popoverFactory.prototype.remove = function(detach = false) {
        if (this.popover) {
            this.hide();

            if (detach) {
                this.popover.detach();
            } else {
                this.popover.remove();
            }

            this.popover = false;
        }

        if (this.carat) {
            this.carat.remove();
            this.carat = false;
        }
    };

    /**
     * Checks whether popover is visible.
     * @returns {boolean}
     */
    popoverFactory.prototype.isVisible = function() {
        return !!this.popover && this.popover.is(':visible');
    };

    /**
     * Sets popovers template.
     * @param {string|HTMLElement|jQuery} html
     * @public
     */
    popoverFactory.prototype.setTemplate = function(html) {
        this.config.html = html;
    };

    return popoverFactory;
}]);
