/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'bot-detection-policy-logs';
const prefix = `${moduleName}.${componentName}`;

export const botManagementSectionHeaderLabel = `${prefix}.botManagementSectionHeaderLabel`;
export const botSectionClassificationLabel = `${prefix}.botSectionClassificationLabel`;
export const gridColumnHeaderComponentLabel = `${prefix}.gridColumnHeaderComponentLabel`;
export const gridColumnHeaderClassLabel = `${prefix}.gridColumnHeaderClassLabel`;
export const gridColumnHeaderTypeLabel = `${prefix}.gridColumnHeaderTypeLabel`;
export const gridColumnHeaderIdentifierLabel = `${prefix}.gridColumnHeaderIdentifierLabel`;
export const botSectionTypeLabel = `${prefix}.botSectionTypeLabel`;
export const botSectionIdentifierLabel = `${prefix}.botSectionIdentifierLabel`;
export const showComponentsButtonLabel = `${prefix}.showComponentsButtonLabel`;
export const hideComponentsButtonLabel = `${prefix}.hideComponentsButtonLabel`;

export const ENGLISH = {
    [botManagementSectionHeaderLabel]: 'Bot Management',
    [botSectionClassificationLabel]: 'Classification',
    [gridColumnHeaderComponentLabel]: 'Component',
    [gridColumnHeaderClassLabel]: 'Bot Client Class',
    [gridColumnHeaderTypeLabel]: 'Bot Client Type',
    [gridColumnHeaderIdentifierLabel]: 'Identifier',
    [botSectionTypeLabel]: 'Bot Client Type',
    [botSectionIdentifierLabel]: 'Identifier',
    [showComponentsButtonLabel]: 'Show Components',
    [hideComponentsButtonLabel]: 'Hide Components',
};
