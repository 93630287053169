/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Scales values and units to the largest possible size
 */
angular.module('aviApp').factory('Convert', function() {
    const oneForOne = {
        METRIC_COUNT: '',
        PER_SECOND: '/sec',
        SECONDS: 'sec',
        MILLISECONDS: 'ms',
        PERCENT: '%',
    };

    const scales = [
        'KILO',
        'MEGA',
        'GIGA',
        'TERA',
        'PETA',
    ];

    const byteOrBit = {
        BYTES: 'B',
        BITS: 'b',
    };

    const secondHalf = {
        _PER_SECOND: 'ps',
    };

    function convertUp(unit) {
        let parts;

        if (unit.indexOf('BITS') === 0 || unit.indexOf('BYTES') === 0) {
            return `${scales[0]}_${unit}`;
        } else if (unit.indexOf('BITS') > 0) {
            parts = unit.split('_BITS');

            return `${scales[scales.indexOf(parts[0]) + 1]}_BITS${parts[1]}`;
        } else if (unit.indexOf('BYTES') > 0) {
            parts = unit.split('_BYTES');

            return `${scales[scales.indexOf(parts[0]) + 1]}_BYTES${parts[1]}`;
        } else {
            console.log(unit);
            throw new Error('Unit must have BITS or BYTES');
        }
    }

    function createPrettyUnit(unit) {
        const
            type = unit.indexOf('BYTES') === -1 ? 'BITS' : 'BYTES',
            parts = unit.split(type);

        if (!type || parts.length !== 2) {
            throw new Error('Unit must have BITS or BYTES and only once');
        }

        if (parts[0] === '') {
            if (parts[1] === '') {
                return byteOrBit[type];
            } else {
                return byteOrBit[type] + secondHalf[parts[1]];
            }
        } else if (parts[1] === '') {
            // Get the first part, and the first character, KILO = K
            return parts[0][0] + byteOrBit[type];
        } else {
            return parts[0][0] + byteOrBit[type] + secondHalf[parts[1]];
        }
    }

    return {
        /**
         * Scale the value and get the pretty version of the units
         * @param  {number} value   The value to scale
         * @param  {string} unit    The unit to scale and/or convert
         * @param  {number} scaleIfBigger   The largest the value can be before scaling
         *                                  defaults to 10,000
         * @param  {number} divisor     How much to divide the value to,
         *                              defaults to 1024
         * @return {obj}    hash with new value and unit, ex: {value: 123, unit: 'Kbps'}
         */
        getVal(value, unit, scaleIfBigger, divisor) {
            unit = unit.toUpperCase();

            // Set defaults
            if (scaleIfBigger === undefined) {
                scaleIfBigger = 1000;
            }

            if (divisor === undefined) {
                divisor = 1024;
            }

            // Determine if it is a simple unit conversion (never scales)
            if (oneForOne[unit] !== undefined) {
                return {
                    value,
                    unit: oneForOne[unit],
                };
            }

            // Divide number while converting the unit until value < scaleIfBigger
            while (value >= scaleIfBigger) {
                if (value >= scaleIfBigger) {
                    value /= divisor;
                    unit = convertUp(unit);
                }
            }

            // return new value and pretty units
            return {
                value,
                unit: createPrettyUnit(unit),
            };
        },
        toSum(unit) {
            if (unit.slice(-2) === 'ps') {
                return unit.slice(0, -2);
            } else if (unit === '/sec') {
                return '';
            } else if (unit === '%') {
                return unit;
            }
        },
    };
});
