/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineGroup */

import {
    Component,
    Type,
} from '@angular/core';

import {
    IClusterHAConfig,
    IClusterVMGroups,
    INsxtCluster,
    INsxtClusters,
    INsxtDatastores,
    INsxtHosts,
    INsxtTransportnode,
    IPlacementScopeConfig,
    IVcenterFolder,
    IVcenterSharedDatastore,
} from 'generated-types';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { PlacementScopeConfig } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './se-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TChildMessageFields = 'nsxt_hosts' |
'nsxt_clusters' |
'nsxt_datastores' |
'clusters';

type TPlacementScopeConfigPartial = Omit<IPlacementScopeConfig, TChildMessageFields>;

interface IPlacementScopeConfigConfig extends TPlacementScopeConfigPartial {
    nsxt_hosts?: MessageItem<INsxtHosts>;
    nsxt_clusters?: MessageItem<INsxtClusters>;
    nsxt_datastores?: MessageItem<INsxtDatastores>;
    clusters?: RepeatedMessageItem<MessageItem<IClusterHAConfig>>;
}

// UI only enums used in modal component and config item.
export const enum HostClusterScope {
    ANY = 'Any',
    HOST = 'Host',
    CLUSTER = 'Cluster',
}
export const enum DatastoreScope {
    ANY = 'Any',
    SHARED = 'Shared',
}

/**
 * Constants used in the class.
 */
const VCENTER_FOLDERS_URL = '/api/vcenter/folders';
const NSXT_TRANSPORTNODES_URL = '/api/nsxt/transportnodes';
const NSXT_CLUSTERS_URL = '/api/nsxt/clusters';
const VCENTER_DATASTORES_URL = '/api/vcenter/datastores';
const VCENTER_CLUSTER_VMGROUPS_URL = '/api/vcenter/cluster/vmgroups';

/**
 * @description PlacementScopeConfig Message Item under SE Group Item.
 * @author vgohil
 */
export class PlacementScopeConfigConfigItem extends
    withFullModalMixin(MessageItem)<IPlacementScopeConfigConfig> {
    public static ajsDependencies = [
        'l10nService',
        'naturalSort',
        HTTP_WRAPPER_TOKEN,
    ];

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private httpWrapper: HttpWrapper;

    /**
     * L10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: PlacementScopeConfig,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Method used to import lazy-loaded child modal component.
     */
    /* eslint-disable-next-line */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { SEGroupNsxtVcenterModalComponent } = await import(
            /* webpackChunkName: "se-group-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/se-group-modal/se-group-scope/se-group-nsxt-scope/se-group-nsxt-vcenter-modal/se-group-nsxt-vcenter-modal.component'
        );

        return SEGroupNsxtVcenterModalComponent as Type<Component>;
    }

    /**
     * Returns the scope of Host/Cluster.
     */
    public get hostClusterScope(): HostClusterScope {
        const { hostsInfo, clustersInfo } = this;

        if (hostsInfo.config.host_ids) {
            return HostClusterScope.HOST;
        } else if (clustersInfo.config.cluster_ids) {
            return HostClusterScope.CLUSTER;
        }

        return HostClusterScope.ANY;
    }

    /**
     * Returns the scope of Datastore.
     */
    public get datastoreScope(): DatastoreScope {
        const { datastoresInfo } = this;

        if (datastoresInfo.config.ds_ids) {
            return DatastoreScope.SHARED;
        }

        return DatastoreScope.ANY;
    }

    /**
     * Returns the flag about Datastore scope is Shared.
     */
    public get isSharedDatastoreScope(): boolean {
        return this.datastoreScope === DatastoreScope.SHARED;
    }

    /**
     * Returns the configured Cluster HA config repeated message item.
     */
    public get clusterHaConfig(): RepeatedMessageItem<MessageItem<IClusterHAConfig>> {
        return this.config.clusters;
    }

    /**
     * @override
     * Set keys with its default value if not set.
     */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.nsxt_hosts) {
            this.safeSetNewChildByField('nsxt_hosts');
        }

        if (!config.nsxt_clusters) {
            this.safeSetNewChildByField('nsxt_clusters');
        }

        if (!config.nsxt_datastores) {
            this.safeSetNewChildByField('nsxt_datastores');
        }

        if (!config.clusters) {
            this.safeSetNewChildByField('clusters');
        }
    }

    /**
     * Method to reset the vcenter_folder.
     */
    public resetVCenterFolder(): void {
        this.config.vcenter_folder = undefined;
    }

    /**
     * Method to reset the nsxt_hosts info.
     */
    public resetHostsInfo(): void {
        const { hostsInfo, defaultHostsConfig } = this;

        hostsInfo.updateConfig(defaultHostsConfig);
    }

    /**
     * Method to reset the nsxt_clusters info.
     */
    public resetClustersInfo(): void {
        const { clustersInfo, defaultClustersConfig } = this;

        clustersInfo.updateConfig(defaultClustersConfig);
    }

    /**
     * Method to reset the nsxt_datastores info.
     */
    public resetDatastoresInfo(): void {
        const { datastoresInfo, defaultDatastoresConfig } = this;

        datastoresInfo.updateConfig(defaultDatastoresConfig);
    }

    /**
     * Method to reset the Cluster HA config.
     */
    public resetClusterHaConfig(): void {
        this.clusterHaConfig.updateConfig([]);
    }

    /**
     * @override
     * Delete keys which are not set from modal before sending API request.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config, hostsInfo, clustersInfo, datastoresInfo } = this;

        if (!hostsInfo.config.host_ids) {
            delete config.nsxt_hosts;
        }

        if (!clustersInfo.config.cluster_ids) {
            delete config.nsxt_clusters;
        }

        if (!datastoresInfo.config.ds_ids) {
            delete config.nsxt_datastores;
        }

        if (!config.clusters.count) {
            delete config.clusters;
        } else {
            // Keep only those ClusterHA configurations where cluster is selected/set.
            const { clusterHaConfig } = this;

            const clusterHaList = clusterHaConfig.config.filter(
                (clusterHa: MessageItem<IClusterHAConfig>) => !clusterHa.config.cluster_id,
            );

            clusterHaList.forEach((clusterHa: MessageItem<IClusterHAConfig>) => {
                clusterHaConfig.removeByMessageItem(clusterHa);
            });
        }
    }

    /**
     * Method to add new empty row/item to list/grid of Cluster HA configuration.
     */
    public addClusterHaConfig(): void {
        this.clusterHaConfig.add();
    }

    /**
     * Method to remove the given Clusters HA config(s) from the list.
     */
    public removeClusterHaConfig(clusterHaList: Array<MessageItem<IClusterHAConfig>>): void {
        clusterHaList.forEach((clusterHa: MessageItem<IClusterHAConfig>) => {
            this.clusterHaConfig.removeByMessageItem(clusterHa);
        });
    }

    /**
     * Fetches a list of vCenter folders to be used as Service Engine folder options.
     */
    public async getVCenterFolders(
        vCenterServerId: string,
        cloudId: string,
    ): Promise<IVcenterFolder[]> {
        const naturalSort = this.getAjsDependency_('naturalSort');
        const payload = {
            cloud_uuid: cloudId,
            vcenter_uuid: vCenterServerId,
        };

        this.errors = null;

        try {
            const requestConfig = {
                url: VCENTER_FOLDERS_URL,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { vcenter_folders: vcenterFolders = [] } = resource;

            return vcenterFolders.sort(
                ({ name: a }: IVcenterFolder, { name: b }: IVcenterFolder) => {
                    return naturalSort(a, b);
                },
            );
        } catch (errors) {
            this.errors = errors.data;

            return [];
        }
    }

    /**
     * Fetches a list of vCenter/Nsxt transport nodes/hosts to be used in Host options.
     */
    public async getNsxtTransportNodes(
        vCenterServerId: string,
        cloudId: string,
    ): Promise<INsxtTransportnode[]> {
        const payload = {
            cloud_uuid: cloudId,
            vcenter_uuid: vCenterServerId,
        };

        this.errors = null;

        try {
            const requestConfig = {
                url: NSXT_TRANSPORTNODES_URL,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_transportnodes: nsxtTransportnodes = [] } = resource;

            return nsxtTransportnodes;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        }
    }

    /**
     * Fetches a list of vCenter/Nsxt clusters to be used in Clusters options.
     */
    public async getNsxtClusters(
        vCenterServerId: string,
        cloudId: string,
    ): Promise<INsxtCluster[]> {
        const payload = {
            cloud_uuid: cloudId,
            vcenter_uuid: vCenterServerId,
        };

        this.errors = null;

        try {
            const requestConfig = {
                url: NSXT_CLUSTERS_URL,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { nsxt_clusters: nsxtClusters = [] } = resource;

            return nsxtClusters;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        }
    }

    /**
     * Fetches a list of vCenter/Nsxt datastores to be used in Datastores options.
     */
    public async getVCenterDatastores(
        vCenterServerId: string,
        cloudId: string,
    ): Promise<IVcenterSharedDatastore[]> {
        const payload = {
            cloud_uuid: cloudId,
            vcenter_uuid: vCenterServerId,
        };

        this.errors = null;

        try {
            const requestConfig = {
                url: VCENTER_DATASTORES_URL,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { vcenter_datastores: vcenterDatastores = [] } = resource;

            return vcenterDatastores;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        }
    }

    /**
     * Fetches a list of vCenter/Nsxt datastores to be used in Datastores options.
     */
    public async getVMGroupInfo(
        vCenterServerId: string,
        clusterId: string,
    ): Promise<IClusterVMGroups[]> {
        const payload = {
            cluster_id: clusterId,
            vcenter_uuid: vCenterServerId,
        };

        this.errors = null;

        try {
            const requestConfig = {
                url: VCENTER_CLUSTER_VMGROUPS_URL,
                method: HttpMethod.POST,
                data: payload,
            };

            const response = await this.httpWrapper.request(requestConfig);
            const { resource = {} } = response.data;
            const { cluster_vmgs: clusterVmgs = [] } = resource;

            return clusterVmgs;
        } catch (errors) {
            this.errors = errors.data;

            return [];
        }
    }

    /**
     * @override
     * Returns the Breadcrumb title for Nsxt vCenter (PlacementScopeConfig) Modal.
     */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.vCenterModalBreadcrumbTitle);
    }

    /**
     * Returns the configured Hosts info message item.
     */
    private get hostsInfo(): MessageItem<INsxtHosts> {
        return this.config.nsxt_hosts;
    }

    /**
     * Returns the configured Clusters info message item.
     */
    private get clustersInfo(): MessageItem<INsxtClusters> {
        return this.config.nsxt_clusters;
    }

    /**
     * Returns the configured Datastores info message item.
     */
    private get datastoresInfo(): MessageItem<INsxtDatastores> {
        return this.config.nsxt_datastores;
    }

    /**
     * Returns the default config of nsxt_hosts field.
     */
    private get defaultHostsConfig(): INsxtHosts {
        return this.getDefaultConfig().nsxt_clusters as INsxtHosts;
    }

    /**
     * Returns the default config of nsxt_clusters field.
     */
    private get defaultClustersConfig(): INsxtClusters {
        return this.getDefaultConfig().nsxt_clusters as INsxtClusters;
    }

    /**
     * Returns the default config of nsxt_datastores field.
     */
    private get defaultDatastoresConfig(): INsxtDatastores {
        return this.getDefaultConfig().nsxt_datastores as INsxtDatastores;
    }
}
