/** @module GslbModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { L10nService } from '@vmw/ngx-vip';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { IFederationCheckpoint } from 'generated-types';
import { GslbCheckpointsModalComponent } from 'ng/modules/gslb';
import * as l10n from '../gslb.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export type TFederationCheckpoint = typeof FederationCheckpoint;

export class FederationCheckpoint extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: {
        config: IFederationCheckpoint;
        is_active?: boolean;
    };

    public getConfig: () => IFederationCheckpoint;

    private readonly l10nService: L10nService;

    public get isActive(): boolean {
        return this.data.is_active;
    }

    public get date(): IFederationCheckpoint['date'] {
        return this.getConfig().date;
    }

    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'federationcheckpoint',
            objectType: 'FederationCheckpoint',
            permissionName: 'PERMISSION_CONTROLLER',
            windowElement: GslbCheckpointsModalComponent,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public updateItemData(newData: FederationCheckpoint['data']): boolean {
        this.clearAllButConfig();

        return super.updateItemData(newData);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.federationCheckpointModalBreadcrumbTitle);
    }

    /**
     * Clears data object of all but config. Needed, to remove stale inventory data before
     * update.
     */
    private clearAllButConfig(): void {
        const { config } = this.data;

        this.data = { config };
    }
}
