/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import { RepeatedMessageItem } from
    'ajs/modules/data-model/factories/repeated-message-item.factory';

import { withEditChildMessageItemMixin }
    from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    IOpenStackConfiguration,
    IOpenStackRoleMapping,
    IOpenStackVipNetwork,
    Privilege,
} from 'generated-types';

import { OpenStackConfiguration } from 'object-types';
import { SystemInfoService } from 'ajs/modules/core/services/system-info/system-info.service';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';

import { findWhere } from 'underscore';

import {
    HttpMethod,
    HttpWrapper,
    HTTP_WRAPPER_TOKEN,
} from 'ajs/modules/core/factories/http-wrapper/http-wrapper.service';

import { OpenStackVipNetworkConfigItem } from './openstack-vip-network.config-item.factory';

const OPENSTACK_VERIFY_CREDENTIALS_API = '/api/openstack-verify-credentials';
const OPENSTACK_GET_TENANT_NETWORKS_API = '/api/openstack-get-tenant-networks';
const VERIFY_CONTRAIL_CREDENTIALS_API = '/api/contrail-verify-credentials';

const OPENSTACK_VERIFY_CREDENTIALS_REQUEST_ID = 'openstack-verify-credentials';
const OPENSTACK_GET_TENANT_NETWORKS_REQUEST_ID = 'openstack-get-tenant-networks';
const VERIFY_CONTRAIL_CREDENTIALS_REQUEST_ID = 'contrail-verify-credentials';
const SYSTEM_INFO_LOAD_ID = 'system-info-load';

/**
 * Type of request params used for login api call.
 */
export interface ILoginCredentials {
    username?: string;
    password?: string;
    auth_url: string;
    uuid?: string;
}

/**
 * Type of request params used for getOpenstackTenantNetworks api call.
 */
export interface IGetTenantNetworksPayload {
    username?: string;
    password?: string;
    auth_url: string;
    tenant_uuid: string;
    use_internal_endpoints: boolean;
    uuid?: string;
}

/**
 * Type of tenants received from backend.
 */
export interface ITenants {
    id: string;
    name: string;
}

/**
 * Type of network contained by getOpenstackTenantNetworks api response.
 */
export interface INetwork {
    cidr: string;
    external: boolean;
    id: string;
    name: string;
    owner: string;
}

/**
 * Type of list of networks by region.
 */
export interface INetworks {
    networks: INetwork[],
    region: string;
}

/**
 * Type of request params used for contrailVerifyCredentials api call.
 */
interface IContrailVerifyCredentialsPayload {
    auth_url: string;
    contrail_endpoint: string;
    tenant_name: string;
    password?: string;
    username?: string;
    uuid?: string;
}

type TOpenStackConfigurationPartial = Omit<
IOpenStackConfiguration, 'provider_vip_networks' | 'role_mapping'
>;

export type TOpenStackLoginConfiguration = Pick<
IOpenStackConfiguration,
'username' | 'password' | 'auth_url' | 'use_keystone_auth' | 'use_internal_endpoints' | 'insecure'
>;

export interface IOpenStackConfig extends TOpenStackConfigurationPartial {
    role_mapping?: RepeatedMessageItem<MessageItem<IOpenStackRoleMapping>>;
    provider_vip_networks?: RepeatedMessageItem<OpenStackVipNetworkConfigItem>;
}

/**
 * @description
 *
 *   OpenStack Configuration MessageItem.
 *
 * @author Sarthak kapoor
 */
export class OpenStackConfigurationConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<IOpenStackConfig> {
    public static ajsDependencies = [
        HTTP_WRAPPER_TOKEN,
        'secretStubStr',
        'systemInfoService',
        'stringService',
    ];

    /**
     * List of regions received from backend.
     */
    public regions: string[];

    /**
     * List of tenants received from backend.
     */
    public tenants: ITenants[];

    /**
     * List of networks by region.
     */
    public networksByRegion: INetworks[];

    /**
     * List of networks to be shown as dropdown options for Management Network.
     */
    public managementNetworksList: INetwork[];

    /**
     * Flag to check if contrail credentials have been verified.
     */
    public contrailCredentialsVerified: boolean;

    /**
     * Stores the updated value of se_in_provider_context.
     */
    public seInProviderContext: boolean;

    private readonly systemInfo: SystemInfoService;

    private httpWrapper: HttpWrapper;

    /**
     * Tenant selected in the UI.
     */
    private selectedTenant: string;

    /**
     * Region selected in the UI.
     */
    private selectedRegion: string;

    /**
     * Object for storing in progress API Call Ids.
     */
    private apiCallsInProgress: Record<string, boolean>;

    private readonly stringService: StringService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: OpenStackConfiguration,
            whitelistedFields: [
                'role_mapping',
                'provider_vip_networks',
            ],
            ...args,
        };

        super(extendedArgs);

        const HttpWrapper = this.getAjsDependency_(HTTP_WRAPPER_TOKEN);

        this.httpWrapper = new HttpWrapper();

        this.systemInfo = this.getAjsDependency_('systemInfoService');

        this.stringService = this.getAjsDependency_('stringService');

        this.apiCallsInProgress = {};
    }

    /**
     * Set the data entered in the credentials pop up on cloud config.
     */
    public setOpenstackLoginCredentials(loginCredentials: TOpenStackLoginConfiguration): void {
        const { config } = this;

        config.auth_url = loginCredentials.auth_url;
        config.insecure = loginCredentials.insecure;
        config.password = loginCredentials.password;
        config.username = loginCredentials.username;
        config.use_internal_endpoints = loginCredentials.use_internal_endpoints;
        config.use_keystone_auth = loginCredentials.use_keystone_auth;
    }

    /**
     * Remove login credentials based fields on connecting if not in edit mode.
     */
    public removeLoginCredentialsBasedFields(): void {
        const { config } = this;

        delete config.admin_tenant;
        delete config.region;
        delete config.mgmt_network_name;
        delete config.contrail_endpoint;
    }

    /**
     * @override
     */
    public modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (!config.provider_vip_networks) {
            this.safeSetNewChildByField('provider_vip_networks');
        }

        if (!config.role_mapping) {
            this.safeSetNewChildByField('role_mapping');
        }
    }

    /**
     * @override
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        const { config } = this;

        if (!config.provider_vip_networks.count) {
            delete config.provider_vip_networks;
        } else {
            // Drop refs since backend expects only the network ids.
            config.provider_vip_networks.config.forEach(
                (providerVipNetwork: MessageItem<IOpenStackVipNetwork>) => {
                    const { config: providerVipNetworkConfig } = providerVipNetwork;

                    providerVipNetworkConfig.os_network_uuid = this.stringService.slug(
                        providerVipNetworkConfig.os_network_uuid,
                    ) || providerVipNetworkConfig.os_network_uuid;
                },
            );
        }

        if (!config.privilege) {
            config.privilege = Privilege.WRITE_ACCESS;
        }

        if (!config.role_mapping.count) {
            delete config.role_mapping;
        } else {
            // Drop refs since keystone is expecting names only.
            config.role_mapping.config.forEach(
                (roleMap: MessageItem<IOpenStackRoleMapping>) => {
                    const { config: roleMapConfig } = roleMap;

                    roleMapConfig.avi_role = this.stringService.name(
                        roleMapConfig.avi_role,
                    ) || roleMapConfig.avi_role;
                },
            );
        }
    }

    /**
     * Open Modal to add a VIP Provider Network for OpenStack Cloud.
     */
    public addProviderVipNetwork(cloudId = ''): void {
        const selectedTenant = { ...this.getSelectedTenantObject() };

        this.addChildMessageItem({
            field: 'provider_vip_networks',
            modalBindings: {
                isEditMode: false,
                tenants: [...this.tenants],
                openStackNetworkCollectionParams: {
                    ...this.getOpenstackTenantNeworksPayload(selectedTenant.id, cloudId),
                },
            },
        });
    }

    /**
     * Open modal to edit an existing VIP Provider Network.
     */
    public editProviderVipNetwork(
        providerVipNetwork: OpenStackVipNetworkConfigItem,
        cloudId = '',
    ): void {
        const selectedTenant = { ...this.getSelectedTenantObject() };

        this.editChildMessageItem({
            field: 'provider_vip_networks',
            messageItem: providerVipNetwork,
            modalBindings: {
                isEditMode: true,
                tenants: [...this.tenants],
                openStackNetworkCollectionParams: {
                    ...this.getOpenstackTenantNeworksPayload(selectedTenant.id, cloudId),
                },
            },
        });
    }

    /**
     * Remove the selected VIP Provider Network from config.
     */
    public removeVipProviderNetwork(providerVipNetwork: OpenStackVipNetworkConfigItem): void {
        const { provider_vip_networks: providerVipNetworks } = this.config;

        providerVipNetworks.removeByMessageItem(providerVipNetwork);
    }

    /**
     * Verify openstack credentials and fetch, list of tenants and regions.
     */
    public async verifyOpenstackCredentials(
        loginCredentials: ILoginCredentials,
    ): Promise<void> {
        const { config } = this;

        this.errors = null;
        this.busy = true;

        const payload = { ...this.getOpenstackVerifyCredentialsPayload(loginCredentials) };

        const requestConfig = {
            url: OPENSTACK_VERIFY_CREDENTIALS_API,
            method: HttpMethod.POST,
            data: payload,
        };

        try {
            this.apiCallsInProgress[OPENSTACK_VERIFY_CREDENTIALS_REQUEST_ID] = true;

            const response = await this.httpWrapper.request(requestConfig);
            const { regions = [], tenants = [] } = response.data;

            this.regions = regions;
            this.tenants = tenants;
            this.selectedTenant = config.admin_tenant;
            this.selectedRegion = config.region;
        } catch (errors) {
            return Promise.reject(errors);
        } finally {
            delete this.apiCallsInProgress[OPENSTACK_VERIFY_CREDENTIALS_REQUEST_ID];
            this.busy = this.isBusy;
        }
    }

    /**
     * Invoke systemInfo service load method and set seInProviderContext property on config.
     */
    public async setSeInProviderContext(): Promise<void> {
        const {
            config,
            systemInfo,
        } = this;

        this.errors = null;
        this.busy = true;

        try {
            this.apiCallsInProgress[SYSTEM_INFO_LOAD_ID] = true;
            await systemInfo.load();

            this.seInProviderContext = systemInfo.seInProviderContext;
            systemInfo.isSeInProviderContext.next(systemInfo.seInProviderContext);

            /**
             * If seInProviderContext is true, Service Engines will be deployed only in admin
             * context, and user won't be allowed to change the selection. As of now the default
             * value from backend for tenant_se is true hence, overriding it to false here.
             */
            if (this.seInProviderContext) {
                config.tenant_se = false;
            }
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            delete this.apiCallsInProgress[SYSTEM_INFO_LOAD_ID];
            this.busy = this.isBusy;
        }
    }

    /**
     * Fetch list of openstack networks based on selected tenant.
     */
    public async fetchOpenstackTenantNetworks(uuid = ''): Promise<void> {
        const selectedTenant = { ...this.getSelectedTenantObject() };
        const { config } = this;

        if (!selectedTenant) {
            return;
        }

        this.busy = true;
        this.errors = null;
        this.managementNetworksList = [];

        if (selectedTenant.name !== this.selectedTenant) {
            delete config.region;
            delete config.mgmt_network_name;
            config.provider_vip_networks.removeAll();
            this.selectedTenant = selectedTenant.name;
        }

        const payload = { ...this.getOpenstackTenantNeworksPayload(selectedTenant.id, uuid) };

        const requestConfig = {
            url: OPENSTACK_GET_TENANT_NETWORKS_API,
            method: HttpMethod.POST,
            data: payload,
        };

        try {
            this.apiCallsInProgress[OPENSTACK_GET_TENANT_NETWORKS_REQUEST_ID] = true;

            const response = await this.httpWrapper.request(requestConfig);

            const { networks = [] } = response.data;

            this.networksByRegion = networks;
            this.mapOpenstackNetworksByRegion();
            this.mapProviderNetworksToOpenstackNetworks();
        } catch (errors) {
            delete config.region;
            this.errors = errors.data;
        } finally {
            delete this.apiCallsInProgress[OPENSTACK_GET_TENANT_NETWORKS_REQUEST_ID];
            this.busy = this.isBusy;
        }
    }

    /**
     * Invoke contrailCredentialsVerify api to verify the api endpoint url entered by the user.
     */
    public async verifyContrailCredentials(cloudId = ''): Promise<void> {
        this.busy = true;
        this.errors = null;
        this.contrailCredentialsVerified = false;

        const payload = { ...this.getContrailVerifyCredentialsPayload(cloudId) };

        const requestConfig = {
            url: VERIFY_CONTRAIL_CREDENTIALS_API,
            method: HttpMethod.POST,
            data: payload,
        };

        try {
            this.apiCallsInProgress[VERIFY_CONTRAIL_CREDENTIALS_REQUEST_ID] = true;

            await this.httpWrapper.request(requestConfig);
            this.contrailCredentialsVerified = true;
        } catch (errors) {
            this.errors = errors.data;
        } finally {
            delete this.apiCallsInProgress[VERIFY_CONTRAIL_CREDENTIALS_REQUEST_ID];
            this.busy = this.isBusy;
        }
    }

    /**
     * Set the management network list.
     */
    public onRegionChange(): void {
        const {
            config,
            networksByRegion,
        } = this;
        const { region } = config;

        if (this.selectedRegion !== region) {
            delete config.mgmt_network_name;
            config.external_networks = false;
            this.selectedRegion = region;
        }

        if (region) {
            const networks = findWhere(networksByRegion, { region });

            this.managementNetworksList = networks ? networks.networks : [];
        }
    }

    /**
     * Set external_networks propert on config depending upon the selected management network.
     */
    public onManagementNetworkChange(): void {
        const { config } = this;
        const { mgmt_network_name: mgmtNetworkName } = config;

        const selectedManagementNetwork = this.managementNetworksList.find(
            managementNetwork => managementNetwork.name === mgmtNetworkName,
        );

        if (selectedManagementNetwork?.external) {
            config.external_networks = true;
        }
    }

    /**
     * Remove contrail_endpoint property from config.
     */
    public removeContrailEndpoint(): void {
        const { config } = this;

        delete config.contrail_endpoint;
    }

    /**
     * Return the tenant name for the selected tenant id.
     */
    public getSelectedTenantName(tenantId: string): string {
        const { tenants } = this;

        return tenants.find(
            tenant => tenant.id === tenantId,
        )?.name;
    }

    /**
     * Add Keystone Role Mapping to Avi Role.
     */
    public addKeystoneRoleMappingToAviRole(): void {
        const { config } = this;
        const { role_mapping: roleMapping } = config;

        roleMapping.add();
    }

    /**
     * Remove Keystone Role Mapping to Avi Role.
     */
    public removeKeystoneMappingToAviRole(roleMap: MessageItem<IOpenStackRoleMapping>): void {
        const { config } = this;
        const { role_mapping: roleMapping } = config;

        roleMapping.removeByMessageItem(roleMap);
    }

    /**
     * Return the list of regions fetched as part of getOpenstackTenantNetworks api response.
     */
    private getOpenStackRegions(): string[] {
        const { networksByRegion } = this;

        return networksByRegion.map(
            (network: INetworks) => network.region,
        );
    }

    /**
     * Return the tenant object whose name is selected in the UI.
     */
    private getSelectedTenantObject(): ITenants {
        const { config } = this;

        return this.tenants.find(tenant => tenant.name === config.admin_tenant);
    }

    /**
     * Return the request params for openstack-verify-credentials api call.
     */
    private getOpenstackVerifyCredentialsPayload(
        loginCredentials: ILoginCredentials,
    ): ILoginCredentials {
        const secretStubStr = this.getAjsDependency_('secretStubStr');

        const {
            username,
            password,
            auth_url: authUrl,
            uuid,
        } = loginCredentials;

        return {
            username: password === secretStubStr ? undefined : username,
            password: password === secretStubStr ? undefined : password,
            auth_url: authUrl,
            uuid: password === secretStubStr && uuid ? uuid : undefined,
        };
    }

    /**
     * Return the payload for openstackTenants Network Api call.
     */
    private getOpenstackTenantNeworksPayload(
        tenantId: string,
        uuid = '',
    ): IGetTenantNetworksPayload {
        const secretStubStr = this.getAjsDependency_('secretStubStr');
        const { config } = this;

        return {
            username: config.password === secretStubStr ? undefined : config.username,
            password: config.password === secretStubStr ? undefined : config.password,
            auth_url: config.auth_url,
            tenant_uuid: tenantId,
            use_internal_endpoints: config.use_internal_endpoints,
            uuid: config.password === secretStubStr && uuid ? uuid : undefined,
        };
    }

    /**
     * Return the payload for contrailVerifyCredentials Api call.
     */
    private getContrailVerifyCredentialsPayload(cloudId = ''): IContrailVerifyCredentialsPayload {
        const secretStubStr = this.getAjsDependency_('secretStubStr');
        const { config } = this;
        const {
            username,
            password,
            auth_url: authUrl,
            contrail_endpoint: contrailEndpoint,
            admin_tenant: adminTenant,
        } = config;

        return {
            username: password === secretStubStr ? undefined : username,
            password: password === secretStubStr ? undefined : password,
            auth_url: authUrl,
            contrail_endpoint: contrailEndpoint,
            tenant_name: adminTenant,
            uuid: password === secretStubStr ? cloudId : undefined,
        };
    }

    /**
     * Map the networks based on their regions.
     * If there is no region selected, the first region in the list gets assigned
     * to the region property on config.
     */
    private mapOpenstackNetworksByRegion(): void {
        const { config } = this;
        const regionsList = this.getOpenStackRegions();

        if (!config.region && regionsList.length) {
            config.region = regionsList[0];
        }

        if (config.region) {
            this.onRegionChange();
        }
    }

    /**
     * Map each existing provider network to the relevant Openstack network
     * based on the given network uuid and creates ref by appending name along with its uuid.
     */
    private mapProviderNetworksToOpenstackNetworks(): void {
        const {
            config,
            networksByRegion,
        } = this;
        const {
            provider_vip_networks: providerVipNetworks,
            region,
        } = config;

        const networks = findWhere(networksByRegion, { region });
        const { networks: regionBasedNetworks } = networks;

        providerVipNetworks?.config?.forEach(
            (providerVipNetwork: MessageItem<IOpenStackVipNetwork>) => {
                if (
                    providerVipNetwork?.config?.os_network_uuid &&
                    providerVipNetwork?.config?.os_tenant_uuids
                ) {
                    const { os_network_uuid: osNetworkUuId } = providerVipNetwork?.config;
                    const network = findWhere(regionBasedNetworks, { id: osNetworkUuId });

                    if (network) {
                        const url = `${network.id}#${network.name}`;

                        providerVipNetwork.config.os_network_uuid = url;
                    }
                }
            },
        );
    }

    /**
     * Return if the busy flag can be set to false.
     */
    private get isBusy(): boolean {
        const { apiCallsInProgress } = this;

        return !(!apiCallsInProgress || !Object.prototype.hasOwnProperty.call(apiCallsInProgress));
    }
}
