/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'ssl-ciphers-grid';
const prefix = `${moduleName}.${componentName}`;

export const enabledLabel = `${prefix}.enabledLabel`;
export const cipherLabel = `${prefix}.cipherLabel`;
export const securityScoreLabel = `${prefix}.securityScoreLabel`;
export const pfsLabel = `${prefix}.pfsLabel`;
export const performanceLabel = `${prefix}.performanceLabel`;
export const compatibilityLabel = `${prefix}.compatibilityLabel`;

export const ENGLISH = {
    [enabledLabel]: 'Enabled',
    [cipherLabel]: 'Cipher',
    [securityScoreLabel]: 'Security Score',
    [pfsLabel]: 'PFS',
    [performanceLabel]: 'Performance',
    [compatibilityLabel]: 'Compatibility',
};
