/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-aws-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const credentialSectionHeader = `${prefix}.credentialSectionHeader`;
export const valueNotSetPlaceholder = `${prefix}.valueNotSetPlaceholder`;
export const accessKeyIdInputLabel = `${prefix}.accessKeyIdInputLabel`;
export const secretAccessKeyInputLabel = `${prefix}.secretAccessKeyInputLabel`;
export const regionInputLabel = `${prefix}.regionInputLabel`;
export const awsHeaderLabel = `${prefix}.awsHeaderLabel`;
export const accessLabel = `${prefix}.accessLabel`;
export const iamRolesLabel = `${prefix}.iamRolesLabel`;
export const proxyLabel = `${prefix}.proxyLabel`;
export const hostIpAddressInputLabel = `${prefix}.hostIpAddressInputLabel`;
export const portInputLabel = `${prefix}.portInputLabel`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const useIamAssumeRoleLabel = `${prefix}.useIamAssumeRoleLabel`;
export const iamAssumeRoleDropdownLabel = `${prefix}.iamAssumeRoleDropdownLabel`;
export const iamAssumeRoleDropdownPlaceholder = `${prefix}.iamAssumeRoleDropdownPlaceholder`;
export const vpcDropdownLabel = `${prefix}.vpcDropdownLabel`;
export const vpcDropdownPlaceholder = `${prefix}.vpcDropdownPlaceholder`;
export const usableDomainLabel = `${prefix}.usableDomainLabel`;
export const usableDomainsGridLabel = `${prefix}.usableDomainsGridLabel`;
export const domainInputPlaceholder = `${prefix}.domainInputPlaceholder`;

export const ENGLISH = {
    [credentialSectionHeader]: 'Credentials',
    [valueNotSetPlaceholder]: 'Not Set',
    [accessKeyIdInputLabel]: 'Access Key ID',
    [secretAccessKeyInputLabel]: 'Secret Access Key',
    [regionInputLabel]: 'Region',
    [awsHeaderLabel]: 'AWS',
    [accessLabel]: 'Access',
    [iamRolesLabel]: 'IAM Roles',
    [proxyLabel]: 'Proxy',
    [hostIpAddressInputLabel]: 'Host IP Address',
    [portInputLabel]: 'Port',
    [usernameInputLabel]: 'Username',
    [passwordInputLabel]: 'Password',
    [useIamAssumeRoleLabel]: 'Use Cross-Account IAM AssumeRole',
    [iamAssumeRoleDropdownLabel]: 'IAM AssumeRole',
    [iamAssumeRoleDropdownPlaceholder]: 'Select IAM AssumeRole',
    [vpcDropdownLabel]: 'VPC',
    [vpcDropdownPlaceholder]: 'Select VPC',
    [usableDomainLabel]: 'Usable Domain',
    [usableDomainsGridLabel]: 'Usable Domains ({0})',
    [domainInputPlaceholder]: 'Enter Domain',
};
