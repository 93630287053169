/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module CoreModule
 */

import { Injectable } from '@angular/core';

/**
 * @description Service to generate the password.
 *
 * @author Nitesh Kesarkar
 */
@Injectable()
export class PasswordGeneratorService {
    /**
     * Pattern array which includes lower and upper alphabets, digits and special characters.
     */
    private readonly patterns = ['a-z', 'A-Z', '\\d', '_\\-\\+=%$@'];

    /**
     * RegExp expression array for each of the above mentioned pattern.
     */
    private readonly regExpPatterns = this.patterns.map((exp: string) => new RegExp(`[${exp}]`));

    /**
     * RegExp general pattern to include different characters mentioned above.
     */
    private readonly generalPattern = new RegExp(`[${this.patterns.join('')}]`);

    /**
     * Method to get random byte.
     */
    private static getRandomByte(): number {
        let result;

        if (window.crypto && window.crypto.getRandomValues) {
            result = new Uint8Array(1);
            window.crypto.getRandomValues(result);
            [result] = result;

            return result;
        }

        const msCrypto = 'msCrypto';

        if (window[msCrypto] && window[msCrypto].getRandomValues) {
            result = new Uint8Array(1);

            window[msCrypto].getRandomValues(result);
            [result] = result;

            return result;
        }

        result = 33 + Math.floor(Math.random() * (256 - 33));

        return result;
    }

    /**
     * Method to generate random password string.
     */
    private static generateRandomPassword(length: number, patternToMatch: RegExp): string {
        let symbol = '';
        let str = '';

        for (let i = 0; i < length; i++) {
            while (!symbol || !patternToMatch.test(symbol)) {
                symbol = String.fromCharCode(PasswordGeneratorService.getRandomByte());
            }

            str += symbol;
            symbol = '';
        }

        return str;
    }

    /**
     * Method to generate password.
     */
    public generate(length: number): string {
        const patternLength = this.patterns.length;

        length = Math.max(patternLength, length);

        let str = PasswordGeneratorService
            .generateRandomPassword(length - patternLength + 1, this.generalPattern);

        this.regExpPatterns.forEach(pattern => {
            if (!pattern.test(str)) {
                str += PasswordGeneratorService
                    .generateRandomPassword(1, pattern);
            }
        });

        if (str.length < length) {
            str += PasswordGeneratorService
                .generateRandomPassword(length - str.length, this.generalPattern);
        }

        return str;
    }
}
