/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    EventEmitter,
    Inject,
    Input,
    Output,
} from '@angular/core';

import {
    DataScriptSet, // eslint-disable-line no-unused-vars
    TDataScriptSet,
} from 'ajs/modules/scripts';

import {
    IVSDataScript,
    VSDataScriptEvent,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import * as l10n from './data-script-config.l10n';
import './data-script-config.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * VSDataScriptEvent enum name.
 */
const VSDataScriptEventEnum = 'VSDataScriptEvent';

/**
 * @description Component to list and edit/upload datascripts.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'data-script-config',
    templateUrl: './data-script-config.component.html',
})
export class DataScriptConfigComponent {
    /**
     * DataScripts list to be edited.
     */
    @Input()
    public dataScripts: IVSDataScript[];

    /**
     * Set of added events.
     */
    @Input()
    public dataScriptEvents: Set<VSDataScriptEvent>;

    /**
     * Called when the user removes the event.
     */
    @Output()
    public onEventRemove = new EventEmitter<VSDataScriptEvent>();

    /**
     * Rows value for ile-upload-textarea component.
     */
    public readonly textAreaRows = 4;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        @Inject(SchemaService)
        private readonly schemaService: SchemaService,
        @Inject(DataScriptSet)
        private readonly DataScriptSet: TDataScriptSet,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns human readable string from VSDataScriptEvent enum.
     */
    public getDSEventLabel(event: VSDataScriptEvent): string {
        return this.DataScriptSet.getDSEventLabel(event);
    }

    /**
     * Returns description from VSDataScriptEvent enum.
     */
    public getDSEventDescription(event: VSDataScriptEvent): string {
        return this.schemaService.getEnumValueDescription(VSDataScriptEventEnum, event);
    }

    /**
     * Callback to ngFor-trackBy.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
