/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc directive
 * @name gslbServiceUnitGroup
 * @description
 *      GSLB Group for GSLB tree in application dashboard.
 */
angular.module('aviApp').directive('gslbServiceUnitGroup', function() {
    return {
        restrict: 'E',
        scope: {
            config: '<',
        },
        templateUrl: 'src/views/components/gslb-service-unit-group.html',
    };
});
