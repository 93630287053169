/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Inject,
    Injectable,
} from '@angular/core';

import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

import {
    HttpMethod,
    HttpWrapper,
    IHttpWrapperRequestConfig,
    THttpWrapper,
} from 'ajs/modules/core/factories';

import { switchMap } from 'rxjs/operators';
import { DevLoggerService } from 'ng/modules/core/services/dev-logger.service';
import { IFileServiceAttachment } from 'ng/modules/cportal/components/add-attachments-grid';
import { IHttpResponse } from 'angular';

export interface ICrashReportState {
    count: number,
    results: IFileServiceAttachment[],
}

const defaultState: ICrashReportState = {
    count: 0,
    results: [],
};

const url = '/api/fileservice?uri=controller://archive';

/**
 * @description Store to handle crash reports state.
 *
 * @author Suraj Kumar
 */
@Injectable()
export class CrashReportsStore extends ComponentStore<ICrashReportState> {
    /**
     * Row NgRx/selector for rendering rows in the grid.
     */
    public rows$ = this.select(({ results }) => results);

    /**
     * Indicate whether the data is being loaded.
     */
    public isLoading = false;

    /**
     * NgRx/Effect for fetching list and each new call of fetchList(config)
     * pushed that config into config$ stream.
     */
    public fetchList = this.effect((config$: Observable<void>) => {
        const requestConfig = {
            url,
            method: HttpMethod.GET,
        };

        return config$.pipe(
            switchMap(async() => {
                this.isLoading = true;

                try {
                    const { data } = await this.sendRequest(requestConfig);

                    this.dataHandler(data);
                } catch ({ data }) {
                    this.devLoggerService.error(data);
                } finally {
                    this.isLoading = false;
                }
            }),
        );
    });

    /**
     * NgRx/Effect for deleting entries and fetching fresh list.
     */
    public deleteEntries = this.effect((fileNames$: Observable<string[]>) => {
        return fileNames$.pipe(
            switchMap(async fileNames => {
                await Promise.all(fileNames.map(fileName => this.delete(fileName)));
                this.fetchList();
            }),
        );
    });

    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    /**
     * NgRx/updater for updating store state.
     */
    private dataHandler = this.updater((state, data: ICrashReportState) => ({
        ...state,
        ...data,
    }));

    constructor(
        private readonly devLoggerService: DevLoggerService,
        @Inject(HttpWrapper)
        HttpWrapper: THttpWrapper,
    ) {
        super(defaultState);
        this.httpWrapper = new HttpWrapper();
    }

    /**
     *  Method for sending http Requests.
     */
    private sendRequest(
        requestConfig: IHttpWrapperRequestConfig,
    ): Promise<IHttpResponse<ICrashReportState>> {
        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Method for deleting entry from list.
     */
    private async delete(fileName: string): Promise<void> {
        const requestConfig = {
            url: `${url}/${fileName}`,
            method: HttpMethod.DELETE,
        };

        try {
            await this.sendRequest(requestConfig);
        } catch ({ data }) {
            this.devLoggerService.error(data);
        }
    }
}
