/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name SingleSeriesPerEveryDimensionIdMetric
 * @description
 *
 *     Metric containing one or more Series of a single metric_id but different dimensionIds.
 */
angular.module('avi/metrics').service('SingleSeriesPerEveryDimensionIdMetric', [
'CollMetric', function(CollMetric) {
    return class SingleSeriesPerEveryDimensionIdMetric extends CollMetric {
        constructor(args) {
            args.stackedSeries = true;
            args.staticSeriesList = false;

            super(args);
        }

        /**
         * Dimension id is used as a series name.
         * @override
         */
        seriesIdToConfig_(dimensionId) {
            return {
                dimensionId,
                seriesId: this.seriesId_,
            };
        }

        /** @override */
        getSeriesConfigBySeriesHeader_(header) {
            const config = super.getSeriesConfigBySeriesHeader_(header);

            config['title'] = config['dimensionId'];

            return config;
        }
    };
}]);
