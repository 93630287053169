/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-modal';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const sectionTitleDnsServices = `${prefix}.sectionTitleDnsServices`;
export const sectionTitleTenancyMode = `${prefix}.sectionTitleTenancyMode`;
export const sectionTitleAuthentication = `${prefix}.sectionTitleAuthentication`;
export const sectionTitleEmailSmtp = `${prefix}.sectionTitleEmailSmtp`;
export const sectionTitleDnsNtp = `${prefix}.sectionTitleDnsNtp`;
export const sectionTitleAccess = `${prefix}.sectionTitleAccess`;

export const ENGLISH = {
    [headerLabel]: 'Edit System Settings',
    [sectionTitleDnsServices]: 'DNS Services',
    [sectionTitleTenancyMode]: 'Tenancy Mode',
    [sectionTitleAuthentication]: 'Authentication',
    [sectionTitleEmailSmtp]: 'Email/SMTP',
    [sectionTitleDnsNtp]: 'DNS/NTP',
    [sectionTitleAccess]: 'Access',
};
