/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AviPermissionResource } from 'generated-types';

import {
    PingAccessAgentModalComponent,
} from 'ng/modules/security/components/ping-access-agent-modal/ping-access-agent-modal.component';

import { PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN } from '../../security.tokens';

/**
 * Type for PingAccessAgentProfile collection.
 */
export type TPingAccessAgentProfileCollection = typeof PingAccessAgentProfileCollection;

/**
 * @description Ping access agent profile collection
 *
 * @author Rohit Gaikwad
 */
export class PingAccessAgentProfileCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'pingaccessagent',
            objectType: PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
            permissionName: AviPermissionResource.PERMISSION_PINGACCESSAGENT,
            windowElement: PingAccessAgentModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN);
    }
}

PingAccessAgentProfileCollection.ajsDependencies = [
    PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN,
];
