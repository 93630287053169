/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    AuthProfileType,
    AviPermissionResource,
} from 'generated-types';

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';
import { AUTH_PROFILE_ITEM_TOKEN } from '../../security.tokens';

/**
 * Ajs dependency token for AuthProfileCollection.
 */
export const AUTH_PROFILE_COLLECTION_TOKEN = 'AuthProfileCollection';

/**
 * @description Collection of AuthProfile items.
 *
 * @author Rajawant Prajapati, Suraj Kumar
 */
export class AuthProfileCollection extends Collection {
    public static ajsDependencies = [
        AUTH_PROFILE_ITEM_TOKEN,
    ];

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'authprofile',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_AUTHPROFILE,
            ...args,
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_(AUTH_PROFILE_ITEM_TOKEN);

        this.serverDefaultsOverride_ = {
            type: AuthProfileType.AUTH_PROFILE_LDAP,
        };
    }
}
