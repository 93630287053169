/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'vs-security-ddos-top-lists';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleDuration = `${prefix}.columnTitleDuration`;
export const columnTitleRate = `${prefix}.columnTitleRate`;
export const columnTitleIpAddress = `${prefix}.columnTitleIpAddress`;
export const columnTitleAsName = `${prefix}.columnTitleAsName`;
export const actionTitleBlock = `${prefix}.actionTitleBlock`;
export const columnTitleAsNumber = `${prefix}.columnTitleAsNumber`;
export const actionTitleUnblock = `${prefix}.actionTitleUnblock`;
export const attackTypesTitle = `${prefix}.attackTypesTitle`;
export const attackingClientIpsTitle = `${prefix}.attackingClientIpsTitle`;
export const attackingUrlsTitle = `${prefix}.attackingUrlsTitle`;
export const attackingAsnsTitle = `${prefix}.attackingAsnsTitle`;
export const activeClientsBlockedTitle = `${prefix}.activeClientsBlockedTitle`;
export const attackTypeHeader = `${prefix}.attackTypeHeader`;
export const clientIpHeader = `${prefix}.clientIpHeader`;
export const activeClientsBlockedHeader = `${prefix}.activeClientsBlockedHeader`;
export const viewFullListLabel = `${prefix}.viewFullListLabel`;
export const emptyAttacksDisplayMessage = `${prefix}.emptyAttacksDisplayMessage`;
export const emptyClientIpAddressesMessage = `${prefix}.emptyClientIpAddressesMessage`;
export const emptyUrlsDisplayMessage = `${prefix}.emptyUrlsDisplayMessage`;
export const emptyAsnsDisplayMessage = `${prefix}.emptyAsnsDisplayMessage`;
export const emptyBlockedAddressesMessage = `${prefix}.emptyBlockedAddressesMessage`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleDuration]: 'Duration (sec)',
    [columnTitleRate]: 'Rate (/sec)',
    [columnTitleIpAddress]: 'IP Address',
    [columnTitleAsName]: 'AS Name',
    [actionTitleBlock]: 'Block',
    [columnTitleAsNumber]: 'AS Number',
    [actionTitleUnblock]: 'Unblock',
    [attackTypesTitle]: 'Attack Types',
    [attackingClientIpsTitle]: 'Attacking Client IPs',
    [attackingUrlsTitle]: 'Attacking URLs',
    [attackingAsnsTitle]: 'Attacking ASNs',
    [activeClientsBlockedTitle]: 'Active Clients Blocked',
    [attackTypeHeader]: 'Attack Type',
    [clientIpHeader]: 'Client IP',
    [activeClientsBlockedHeader]: 'Active Clients Blocked',
    [viewFullListLabel]: 'View Full List',
    [emptyAttacksDisplayMessage]: 'No attacks to display.',
    [emptyClientIpAddressesMessage]: 'No client IP addresses to display.',
    [emptyUrlsDisplayMessage]: 'No URLs to display.',
    [emptyAsnsDisplayMessage]: 'No ASNs to display.',
    [emptyBlockedAddressesMessage]: 'No blocked addresses to display.',
};
