/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Injector } from '@angular/core';

import {
    NetworkProfileCollection,
} from 'ajs/modules/network/factories/network-profile/network-profile.collection.factory';

import {
    ConfiguredNetworkCollection,
} from 'ajs/modules/network/factories/configured-network.collection.factory';

import {
    ConfiguredNetwork,
} from 'ajs/modules/network/factories/configured-network.item.factory';

import {
    CONFIGURED_NETWORK_COLLECTION_TOKEN,
    CONFIGURED_NETWORK_ITEM_TOKEN,
} from 'ajs/modules/network/network.tokens';

import { NETWORK_PROFILE_COLLECTION_TOKEN }
    from 'ajs/modules/network/factories/network-profile/network-profile.tokens';

const $injector = '$injector';

type TNetworkProfileCollection = typeof NetworkProfileCollection;

export const networkProfileCollectionProvider = {
    deps: [$injector],
    provide: NetworkProfileCollection,
    useFactory(injector: Injector): TNetworkProfileCollection {
        return injector.get(NETWORK_PROFILE_COLLECTION_TOKEN);
    },
};

export const configuredNetworkCollectionProvider = {
    deps: [$injector],
    provide: ConfiguredNetworkCollection,
    useFactory(injector: Injector): typeof ConfiguredNetworkCollection {
        return injector.get(CONFIGURED_NETWORK_COLLECTION_TOKEN);
    },
};

export const configuredNetworkProvider = {
    deps: [$injector],
    provide: ConfiguredNetwork,
    useFactory(injector: Injector): typeof ConfiguredNetwork {
        return injector.get(CONFIGURED_NETWORK_ITEM_TOKEN);
    },
};
