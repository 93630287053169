/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'policies';
const componentName = 'dns-policy-action';
const prefix = `${moduleName}.${componentName}`;

export const allowQueryActionHeader = `${prefix}.allowQueryActionHeader`;
export const allowDnsQueryLabel = `${prefix}.allowDnsQueryLabel`;
export const resetTcpConnLabel = `${prefix}.resetTcpConnLabel`;
export const groupSelectionActionHeader = `${prefix}.groupSelectionActionHeader`;
export const groupNameLabel = `${prefix}.groupNameLabel`;
export const gslbSiteActionHeader = `${prefix}.gslbSiteActionHeader`;
export const poolSwitchingActionHeader = `${prefix}.poolSwitchingActionHeader`;
export const selectPoolPlaceholder = `${prefix}.selectPoolPlaceholder`;
export const createPoolGroupLabel = `${prefix}.createPoolGroupLabel`;
export const selectPoolGroupPlaceholder = `${prefix}.selectPoolGroupPlaceholder`;
export const rateLimitActionHeader = `${prefix}.rateLimitActionHeader`;
export const burstSizeLabel = `${prefix}.burstSizeLabel`;
export const countLabel = `${prefix}.countLabel`;
export const periodLabel = `${prefix}.periodLabel`;
export const maxInnerText = `${prefix}.maxInnerText`;
export const queryResponseActionHeader = `${prefix}.queryResponseActionHeader`;
export const dnsResponseCodeLabel = `${prefix}.dnsResponseCodeLabel`;
export const dnsResponseCodePlaceholder = `${prefix}.dnsResponseCodePlaceholder`;
export const truncationLabel = `${prefix}.truncationLabel`;
export const authoritativeLabel = `${prefix}.authoritativeLabel`;

export const ENGLISH = {
    [allowQueryActionHeader]: 'Allow/Drop Query',
    [allowDnsQueryLabel]: 'Allow DNS Query',
    [resetTcpConnLabel]: 'Reset TCP Connection',
    [groupSelectionActionHeader]: 'GSLB Service Group Selection',
    [groupNameLabel]: 'Group Name',
    [gslbSiteActionHeader]: 'GSLB Site',
    [poolSwitchingActionHeader]: 'Pool Switching',
    [selectPoolPlaceholder]: 'Select a Pool',
    [createPoolGroupLabel]: 'Create Pool Group',
    [selectPoolGroupPlaceholder]: 'Select a Pool Group',
    [rateLimitActionHeader]: 'Rate Limit',
    [burstSizeLabel]: 'Burst Size',
    [countLabel]: 'Count',
    [periodLabel]: 'Period',
    [maxInnerText]: 'max',
    [queryResponseActionHeader]: 'Query Response',
    [dnsResponseCodeLabel]: 'DNS Response Code',
    [dnsResponseCodePlaceholder]: 'Select DNS Response Code',
    [truncationLabel]: 'Truncation',
    [authoritativeLabel]: 'Authoritative',
};
