/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module RightRailModule
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import './avi-right-rail.component.less';

/**
 * @description
 *
 *     Component for the right-side view of displaying hierachical texts or transcluded contents.
 *
 * @author Zhiqian Liu
 */
@Component({
    selector: 'avi-right-rail',
    templateUrl: './avi-right-rail.component.html',
})
export class AviRightRailComponent {
    /**
     * Indicator of whether the rail has been collapsed.
     * Two-way bound.
     */
    @Input()
    public collapsed ?= false;

    /**
     * Two-way bound emitter working with this.collapsed.
     */
    @Output()
    public collapsedChange = new EventEmitter<boolean>();

    public toggleCollapse(): void {
        this.collapsed = !this.collapsed;
        this.collapsedChange.emit(this.collapsed);
    }
}
