/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

export interface IResolvablePromise<T> extends Promise<T> {
    resolve?: (value?: T) => void;
    reject?: (value?: T) => void;
}

/**
 * @description
 * Returns a promise which can be resolved/rejected from outside.
 * Similar to $Q.defer.
 *
 * @example
 *      const promise = getResolvablePromise();
 *
 *      promise.resolve() // will resolve the promise
 *      promise.reject() // will reject it
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export function getResolvablePromise<T>(): IResolvablePromise<T> {
    let resolvable: (value?: T) => void;
    let rejectable: (value?: T) => void;

    const promise: IResolvablePromise<T> = new Promise((resolve, reject) => {
        resolvable = resolve;
        rejectable = reject;
    });

    // handler to resolve promise from outside
    promise.resolve = function(value?: T) {
        resolvable(value);
    };

    // handler to reject promise from outside
    promise.reject = function(value?: T) {
        rejectable(value);
    };

    return promise;
}
