/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name GSLBSiteFingerprint
 * @author Alex Malitsky
 * @description
 *
 *     Provides an ability to save a "fingerprint" of {@link GslbSite} and compare it with
 *     an updated one. Used to know when "significant" change had happened and we need to ask user
 *     for credentials.
 *
 */
angular.module('aviApp').factory('GSLBSiteFingerprint', function() {
    return class GSLBSiteFingerprint {
        /** @constructor */
        constructor() {
            /**
             * Stored fingerprint object of original GslbSite.
             * @type {string[]}
             * @private
             */
            this.fingerprint_ = [];
        }

        /**
         * Gets a "fingerprint" of GslbSiteConfig.
         * @param {GslbSiteConfig} site
         * @returns {string[]}
         * @private
         */
        static getFingerprint_(site) {
            const res = [];

            res.push(site['port']);

            Array.prototype.push.apply(res,
                _.pluck(site['ip_addresses'], 'addr'));

            return res;
        }

        /**
         * Saves the fingerprint of GslbSite to be used for comparison down the road.
         * @param {GslbSiteConfig} site
         * @public
         */
        save(site) {
            this.fingerprint_ = GSLBSiteFingerprint.getFingerprint_(site);
        }

        /**
         * Compares a passed GslbSite fingerprint the with stored one.
         * @param {GslbSiteConfig} site
         * @returns {boolean} - Returns true if config has been "significantly" changed.
         * @public
         */
        hasChanged(site) {
            const current = GSLBSiteFingerprint.getFingerprint_(site);

            return current.length !== this.fingerprint_.length ||
                _.intersection(current, this.fingerprint_).length !==
                this.fingerprint_.length;
        }
    };
});
