/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
    OnInit,
    Type,
} from '@angular/core';

import {
    SchemaService,
    StringService,
} from 'ajs/modules/core/services';

import {
    CipherType,
    SSLProfile,
} from 'ajs/modules/security';

import {
    createDropdownOption,
    createOptionsFromEnumProps,
} from 'ng/shared/utils';

import {
    debounce,
    isUndefined,
} from 'underscore';

import { L10nService } from '@vmw/ngx-vip';
import { AviContinueConfirmationComponent } from 'ng/modules/dialog';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';
import { IAviDropdownOption } from 'ng/shared/components';
import { ClrFormLayout } from '@clr/angular';
import { DialogService } from 'ng/modules/core';

import * as globalL10n from 'global-l10n';
import * as l10n from './ssl-tls-profile-modal.l10n';

import { SslProfileModalService } from '../../services';
import './ssl-tls-profile-modal.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * ID for SSL CIpher change confirmation dialog.
 */
const SSL_CIPHER_CHANGE_DIALOG_ID = 'ssl-cipher-change-confirmation';

/**
 * @description Modal component for SSL/TLS Profile ObjectTypeItem.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'ssl-tls-profile-modal',
    templateUrl: './ssl-tls-profile-modal.component.html',
    providers: [
        SslProfileModalService,
    ],
})
export class SslTlsProfileModalComponent implements OnInit {
    /**
     * SSLProfile ObjectTypeItem instance.
     */
    @Input()
    public editable: SSLProfile;

    /**
     * SSLProfile modal clr-form layout.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    /**
     * SSLProfile objectType.
     */
    public objectType: string;

    /**
     * SSLProfile type (application/system) dropdown options.
     */
    public readonly typeDropdownOptions: IAviDropdownOption[];

    /**
     * SSLProfile version(tls1.2, tls1.3, ...) dropdown options.
     */
    public readonly versionDropdownOptions: IAviDropdownOption[];

    /**
     * Selected CipherType (list / string) of SSL Profile.
     */
    public cipherType = CipherType.LIST_VIEW;

    /**
     * Reference for CipherType enum - to use in template.
     */
    public readonly CipherTypeEnum = CipherType;

    /**
     * True if the SSLProfile is being edited, not created.
     */
    public readonly isEditing: boolean;

    constructor(
        schemaService: SchemaService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
        private readonly dialogService: DialogService,
        private readonly sslProfileModalService: SslProfileModalService,
        stringService: StringService,
        private readonly l10nService: L10nService,
    ) {
        this.typeDropdownOptions = schemaService.getEnumValues('SSLProfileType')
            .map(({ value, label: description }) => {
                const optionLabel = stringService.enumeration(value, 'SSL_PROFILE_TYPE_');

                return createDropdownOption(value, optionLabel, description);
            });

        this.versionDropdownOptions = createOptionsFromEnumProps(
            schemaService.getEnumValues('SSLVersionType'),
        );

        this.isEditing = Boolean(itemId);

        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;

        this.initCipherTab();

        // Just to stop it from making too many HTTP Requests for SSLRating.
        this.emitSslProfileUpdate = debounce(this.emitSslProfileUpdate, 699);

        // If this.isEditing is true, rating from editable will be displayed.
        // else sslprofilecheck call will be made to get the sslRating.
        this.sslProfileModalService.fetchSSLRating(this.editable, this.isEditing);
    }

    /**
     * Returns true if CipherType - list is chosen by user.
     */
    public get isListSelected(): boolean {
        return this.cipherType === this.CipherTypeEnum.LIST_VIEW;
    }

    /**
     * Returns true if TLSV1.3 is selected in accepted_versions.
     */
    public get hasTlsv1_3(): boolean {
        return this.editable.hasTlsv1_3;
    }

    /**
     * Handler for CipherType change.
     * Shows confirm dialog if SSLProfile is being edited/modified.
     */
    public onCipherTypeChange(): void {
        const isModified = this.editable.modified();

        if (this.isEditing || isModified) {
            this.showCipherTypeChangeConfirmation();
        } else {
            this.changeCipherType();
        }
    }

    /**
     * Handler for SSL Profile Type change.
     * clears selected accepted_versions and emit sslProfile change event.
     */
    public onSslProfileTypeChange = (): void => {
        this.editable.clearAcceptedVersionsList();
        this.emitSslProfileUpdate();
    };

    /**
     * Emits SSLProfile update event.
     * This is for preview component to load SSL Rating.
     */
    public emitSslProfileUpdate = (): void => {
        this.sslProfileModalService.fetchSSLRating(this.editable);
    };

    /**
     * Handler for modal submitting.
     */
    public submit(): void {
        this.editable.submit();
    }

    /**
     * Make changes on SSLProfile based on the selected cipher type.
     */
    private changeCipherType = (): void => {
        this.editable.onCipherTypeChange(this.cipherType);
        this.emitSslProfileUpdate();
    };

    /**
     * Revert user's cipherType selection.
     *
     * Will be called when user clicks NO in confirmation dialog.
     */
    private resetCipherType = (): void => {
        const { LIST_VIEW, STRING_VIEW } = this.CipherTypeEnum;

        this.cipherType = this.cipherType === LIST_VIEW ? STRING_VIEW : LIST_VIEW;
    };

    /**
     * Displays CipherTypeChangeConfirmatio dialog.
     */
    private showCipherTypeChangeConfirmation(): void {
        this.dialogService.add({
            id: SSL_CIPHER_CHANGE_DIALOG_ID,
            component: AviContinueConfirmationComponent as Type<Component>,
            componentProps: {
                warning: this.l10nService.getMessage(l10nKeys.changingCiphersMessage),
                customHeader: this.l10nService.getMessage(l10n.resetToDefaultValuesHeader),
                onConfirm: () => {
                    this.dialogService.remove(SSL_CIPHER_CHANGE_DIALOG_ID);
                    this.changeCipherType();
                },
                onClose: () => {
                    this.dialogService.remove(SSL_CIPHER_CHANGE_DIALOG_ID);
                    this.resetCipherType();
                },
            },
        });
    }

    /**
     * Initializes cipher tab - called on modal initialization phase.
     */
    private initCipherTab(): void {
        const { cipher_enums: cipherEnums } = this.editable.getConfig();

        if (this.isEditing && isUndefined(cipherEnums)) {
            this.cipherType = this.CipherTypeEnum.STRING_VIEW;
        }
    }
}
