/**
 * @module accountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { AviPermissionResource, ITenantConfiguration } from 'generated-types';
import { copy } from 'angular';
import { TenantModalComponent } from 'ng/modules/accounts';
import { MessageItem, ObjectTypeItem } from 'ajs/modules/data-model/factories';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import {
    OpenstackKeystoneService,
    OPENSTACK_KEYSTONE_SERVICE_TOKEN,
} from 'ajs/modules/core/services/openstack-keystone/openstack-keystone.service';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from '../../accounts.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Tenant Item.
 * @author Suraj Kumar
 */
export class TenantItem extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        OPENSTACK_KEYSTONE_SERVICE_TOKEN,
        'l10nService',
    ];

    private readonly openstackKeystoneService: OpenstackKeystoneService;

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'tenant',
            objectType: 'Tenant',
            windowElement: TenantModalComponent,
            permissionName: AviPermissionResource.PERMISSION_TENANT,
            ...args,
        };

        super(extendedArgs);

        this.openstackKeystoneService = this.getAjsDependency_(OPENSTACK_KEYSTONE_SERVICE_TOKEN);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * @override
     */
    public beforeEdit(): void {
        this.backup = copy(this.data.users);
    }

    /**
     * @override
     */
    public isEditable(): boolean {
        // Enforce to disable editing remote users if keystone auth is enabled.
        if (this.openstackKeystoneService.keystoneAuthEnabled) {
            return false;
        }

        return super.isEditable();
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.tenantModalBreadcrumbTitle);
    }

    /**
     * Getter for config_settings.
     */
    public get configSettings(): MessageItem<ITenantConfiguration> {
        return this.config.config_settings;
    }
}
