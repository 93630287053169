/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'match';
const componentName = 'auth-attribute-match';
const prefix = `${moduleName}.${componentName}`;

export const attributeNameLabel = `${prefix}.attributeNameLabel`;
export const attributeNamePlaceholder = `${prefix}.attributeNamePlaceholder`;

export const ENGLISH = {
    [attributeNameLabel]: 'Attribute Name',
    [attributeNamePlaceholder]: 'Enter Attribute Name',
};
