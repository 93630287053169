/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'auth-settings';

export const authMappingProfileModalBreadcrumbTitle = `${prefix}.authMappingProfileModalBreadcrumbTitle`;
export const authMappingRuleModalBreadcrumbTitle = `${prefix}.authMappingRuleModalBreadcrumbTitle`;

export const ENGLISH = {
    [authMappingProfileModalBreadcrumbTitle]: 'Mapping Profile',
    [authMappingRuleModalBreadcrumbTitle]: 'Mapping Rule',
};
