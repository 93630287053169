/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @module WafModule
 */

import {
    Component,
    Type,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IWafPolicyAllowlistRule } from 'generated-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { MatchTargetConfigItem, TMatchField } from 'ajs/modules/policies';
import { withFullModalMixin } from 'ajs/utils/mixins';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import * as l10n from '../waf.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const supportedMatches = ['client_ip', 'method', 'path', 'host_hdr'];

type TWafPolicyAllowlistRule = Omit<IWafPolicyAllowlistRule, 'match'>;

interface IWafPolicyAllowlistRuleConfig extends TWafPolicyAllowlistRule {
    match?: MatchTargetConfigItem;
}

export const WAF_POLICY_ALLOWLIST_RULE_CONFIG_ITEM_TOKEN = 'WafPolicyAllowlistRuleConfigItem';

export class WafPolicyAllowlistRuleConfigItem extends
    withFullModalMixin(MessageItem)<IWafPolicyAllowlistRuleConfig> {
    public static ajsDependencies = [
        'defaultValues',
        'l10nService',
    ];

    private readonly l10nService: L10nService;

    constructor(args = {}) {
        super({
            objectType: 'WafPolicyAllowlistRule',
            windowElement: 'lazy-load',
            ...args,
        });

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * We want to remove 'match' from the default configuration so that the user can add matches
     * manually.
     * @override
     */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IWafPolicyAllowlistRuleConfig> {
        const defaultValues = this.getAjsDependency_('defaultValues');
        const type = this.objectType.toLowerCase();
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};
        const { match, ...defaults } = defaultConfig;

        return {
            ...defaults,
            match: null,
        };
    }

    /**
     * Returns the list of supported matches. TODO: refactor to use Schema when
     * "match_target_options" is available.
     */
    public get supportedMatches(): string[] {
        return supportedMatches;
    }

    /**
     * Returns the number of matches configured.
     */
    public get matchCount(): number {
        return this.supportedMatches.filter(this.hasMatchByField).length;
    }

    /**
     * Returns the enable state.
     */
    public get enable(): boolean {
        return this.config.enable;
    }

    /**
     * Sets the enable state.
     */
    public set enable(enabled) {
        this.config.enable = enabled;
    }

    /**
     * Returns true if the match exists in this.config.match.
     */
    public hasMatchByField = (matchFieldName: TMatchField): boolean => {
        return this.config.match ? this.config.match.hasMatchByField(matchFieldName) : false;
    };

    /**
     * @override
     * We clear out flatProps so that the repeatedMessageItems don't get overwritten.
     */
    public modifyConfigDataBeforeSave(): void {
        super.modifyConfigDataBeforeSave();

        this.config.match?.config.client_ip?.clearFlatProps();
    }

    /**
     * Import lazy-loaded modal component.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-unused-vars
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const { WafPolicyAllowlistRuleModalComponent } = await import(
            /* webpackChunkName: "waf-policy-modal" */
            // eslint-disable-next-line max-len
            'ng/lazy-loaded-components/modals/waf-policy-modal/waf-policy-allowlist-rule-modal/waf-policy-allowlist-rule-modal.component'
        );

        return WafPolicyAllowlistRuleModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.allowListRuleModalBreadcrumbTitle);
    }
}
