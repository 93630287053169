/** @module SecurityModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { ICustomParams } from 'generated-types';
import { CustomParams } from 'object-types';
import { CustomParamsModalComponent }
    from 'ng/modules/security/components/custom-params-modal/custom-params-modal.component';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { L10nService } from '@vmw/ngx-vip';
import * as l10n from './certificate-management.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *   Custom Params Config Item.
 *
 * @author Rajawant Prajapati
 */
export class CustomParamsConfigItem extends withFullModalMixin(MessageItem)<ICustomParams> {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * L10nService instance for translation.
     */
    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: CustomParams,
            windowElement: CustomParamsModalComponent as Type<Component>,
            ...args,
        };

        super(extendedArgs);
        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.customParamsModalBreadcrumbTitle);
    }

    /**
     * Getter for is_sensitive field.
     */
    public get isSensitive(): boolean {
        const { is_sensitive: isSensitive } = this.config;

        return isSensitive;
    }

    /**
     * Getter for is_dynamic field.
     */
    public get isDynamic(): boolean {
        const { is_dynamic: isDynamic } = this.config;

        return isDynamic;
    }
}
