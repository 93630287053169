/**
 * @module SystemModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { IBackupConfiguration } from 'generated-types';

/**
 * @desc Item for backup configuration.
 * @author Zhiqian Liu
 */
export class BackupConfiguration extends Item<IBackupConfiguration> {
    /**
     * Getter for config#backup_passphrase.
     */
    public get backupPassphrase(): string {
        // there is no backup_passphrase data at the initial set-up stage (the Welcome flow)
        return this.getConfig().backup_passphrase || '';
    }

    /**
     * Setter for config#backup_passphrase.
     */
    public set backupPassphrase(passphrase: string) {
        this.getConfig().backup_passphrase = passphrase;
    }

    /**
     * Add backup passphrase data by making patch call.
     */
    public addBackupPassphrase(): ng.IPromise<any> {
        const { backupPassphrase } = this;

        return this.patch({ add: { backup_passphrase: backupPassphrase } });
    }
}

BackupConfiguration.prototype.objectName = 'backupconfiguration';
