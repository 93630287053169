/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import {
    Component,
    Input,
    OnDestroy,
} from '@angular/core';

import {
    Observable,
    Subject,
} from 'rxjs';

import { ConnectedPosition } from '@angular/cdk/overlay';

import {
    IExtendedVipRuntimeDetail,
    OperStateType,
} from 'ajs/modules/vs/vs.types';

import { VsVip } from 'ajs/modules/vs/factories/vs-vip.item.factory';
import * as globalL10n from 'global-l10n';
import './vs-vip-address-grid-cell.component.less';

const { ...globalL10nKeys } = globalL10n;

/**
 * class name prefix for state icon
 */
const STATE_ICON_CLASSNAME_PREFIX = 'vs-vip-address-grid-cell__state-icon--';

/**
 * @description Component for the vs vip address grid cell.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'vs-vip-address-grid-cell',
    templateUrl: './vs-vip-address-grid-cell.component.html',
})
export class VsVipAddressGridCellComponent implements OnDestroy {
    @Input()
    public vsVip: VsVip;

    public currentOperStateType: OperStateType;

    public readonly operStateType = OperStateType;

    public popoverControl$: Observable<boolean>;

    public vips: IExtendedVipRuntimeDetail[];

    public popoverPositions: ConnectedPosition[] = [
        {
            originX: 'center',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetY: -8,
            panelClass: 'right',
        },
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center',
            offsetY: -8,
            panelClass: 'right',
        },
        {
            originX: 'start',
            originY: 'center',
            overlayX: 'end',
            overlayY: 'center',
            offsetY: -8,
            panelClass: 'left',
        },
    ];

    public readonly globalL10nKeys = globalL10nKeys;

    private readonly popoverControlSubject = new Subject<boolean>();

    constructor() {
        this.popoverControl$ = this.popoverControlSubject.asObservable();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.closeVipListPopover();
    }

    /**
     * Returns class for circle arrow icon based on vip state.
     */
    public getVipStateIconClass(operStateType: OperStateType): string {
        return STATE_ICON_CLASSNAME_PREFIX + operStateType;
    }

    /**
     * Getter function which returns list of vipRuntime.
     */
    public get vipRuntimeList(): IExtendedVipRuntimeDetail[] {
        return this.vsVip.getRuntimeData() as IExtendedVipRuntimeDetail[] ?? [];
    }

    /**
     * Returns icon shape based on operStateType.
     */
    public getIconShape(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
            case OperStateType.OPER_DOWN:
                return 'circle-arrow';

            case OperStateType.OPER_PENDING:
                return 'minus-circle';

            default:
                return '';
        }
    }

    /**
     * Returns icon direction based on operStateType.
     */
    public getIconDirection(operStateType: OperStateType): string {
        switch (operStateType) {
            case OperStateType.OPER_UP:
                return 'up';

            case OperStateType.OPER_DOWN:
                return 'down';

            default:
                return '';
        }
    }

    /**
     * Returns a string of comma-separated VIP addresses.
     */
    public get vipListAsString(): string {
        const vipAddresses = this.vsVip.getVipAddresses();

        return vipAddresses?.length ? vipAddresses.join(', ') : '';
    }

    /**
     * Returns runtime object having operStateType as 'Up'.
     */
    public get vipsWithUpState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_UP);
    }

    /**
     * Returns runtime object having operStateType as 'Down'.
     */
    public get vipsWithDownState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_DOWN);
    }

    /**
     * Returns runtime object having operStateType as 'Pending'.
     */
    public get vipsWithPendingState(): IExtendedVipRuntimeDetail[] {
        return this.getVipsByState(OperStateType.OPER_PENDING);
    }

    /**
     * Opens popover with details like vips and OperStateType('Up', 'Down', 'Pending').
     */
    public openVipListPopover(operStateType: OperStateType, $event: MouseEvent): void {
        $event.stopPropagation();
        this.currentOperStateType = operStateType;
        this.vips = this.getVipsByState(operStateType);
    }

    /**
     * For vips having no runtime it shows popover with viplist and OperStateType as 'Pending'.
     */
    public openVipListPopoverWithNoRuntime($event: MouseEvent): void {
        $event.stopPropagation();
        this.currentOperStateType = OperStateType.OPER_PENDING;
        this.vips = this.vsVip.getVipAddressHashes() as IExtendedVipRuntimeDetail[];
    }

    /**
     * function to close existig vip summary popover.
     */
    public closeVipListPopover(): void {
        this.popoverControlSubject.next(false);
        this.vips = [];
        this.currentOperStateType = null;
    }

    /**
     * Returns runtime object based on OperStateType ('UP', 'DOWN', 'PENDING');
     */
    private getVipsByState(operStateType: OperStateType): IExtendedVipRuntimeDetail[] {
        return this.vipRuntimeList
            .filter((runtimeItem: IExtendedVipRuntimeDetail) => {
                return runtimeItem.operStateType === operStateType;
            });
    }
}
