/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  virtualserviceSecurityDdosTableGridDuration
 * @param {MetricsDimension} row - MetricsDimension item.
 * @description
 *     Displays the duration for the MetricsDimension item. If the "valuesToDisplay" is not "sum",
 *     display "N/A".
 */
class VsSecurityDdosTopListGridCellDurationController {
    constructor(myAccount, GraphSync) {
        this.myAccount_ = myAccount;
        this.GraphSync_ = GraphSync;
    }

    /**
     * Returns SUM value of attack duration series.
     * @returns {number|string}
     * @public
     */
    getDuration() {
        const displayValueType = this.myAccount_.getValuesDisplayType();

        if (displayValueType === 'sum' && !this.GraphSync_.mouseIsOnGraph()) {
            const series = this.row.getSeriesBySeriesId('dos.sum_attack_duration');

            return series && series.getValue(displayValueType) || 'N/A';
        }

        return 'N/A';
    }
}

VsSecurityDdosTopListGridCellDurationController.$inject = [
    'myAccount',
    'GraphSync',
];

angular.module('avi/vs/security')
    .component('vsSecurityDdosTopListGridCellDuration', {
        bindings: {
            row: '<',
        },
        controller: VsSecurityDdosTopListGridCellDurationController,
        template: '<span>{{ $ctrl.getDuration() }}</span>',
    });
