/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name  virtualserviceGridServiceEngine
 * @description  Displays a list of Service Engines in the Virtual Service grid.
 * @param {VirtualService} row - Virtual Service object containing runtime for Service Engine info.
 */
class VirtualserviceGridServiceEngineController {
    constructor(Auth) {
        this.Auth = Auth;
    }

    $onInit() {
        this.cloudSlug = this.row.data.config.cloud_ref.slug();
    }

    /**
     * Returns a string of comma-separated Service Engine names.
     * @return {string}
     */
    getServiceEngineNames() {
        const runtime = this.row.getRuntimeData();

        if (angular.isUndefined(runtime) || !angular.isArray(runtime.vip_summary)) {
            return;
        }

        const names = runtime.vip_summary.reduce((acc, vip) => {
            if (angular.isArray(vip.service_engine)) {
                acc.push(...vip.service_engine.map(se => this.getServiceEngineLabel(se)));

                return acc;
            } else {
                return acc;
            }
        }, []);

        return names.join(', ');
    }

    /**
     * Returns a string to be shown for the Service Engine, adding '(Primary)' if the SE is a
     * primary SE.
     * @param {object} serviceengine - Service Engine object.
     * @return {string}
     */
    getServiceEngineLabel(serviceengine) {
        let output = serviceengine.url.name();

        if (serviceengine.primary) {
            output += ' (primary)';
        }

        return output;
    }
}

VirtualserviceGridServiceEngineController.$inject = [
    'Auth',
];

angular.module('aviApp').component('virtualserviceGridServiceEngine', {
    bindings: {
        row: '<',
    },
    controller: VirtualserviceGridServiceEngineController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-list/' +
        'virtualservice-grid-service-engine/virtualservice-grid-service-engine.html',
});
