/**
 * Module containing IPReputationDB components.
 * @preferred
 * @module IPReputationDBModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    ClrInputModule,
    ClrSpinnerModule,
    ClrTextareaModule,
} from '@clr/angular';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';

import {
    IpReputationChangeRequestCardComponent,
    IPReputationDBListPageCardComponent,
    IPReputationDBListPageComponent,
    IPReputationDBListPageContainerComponent,
    IPReputationDBListPageContainerService,
    IPReputationDBListPageHeaderComponent,
    IPReputationSearchRecordCardComponent,
} from '.';

const ipReputationDBComponents = [
    IpReputationChangeRequestCardComponent,
    IPReputationDBListPageCardComponent,
    IPReputationDBListPageComponent,
    IPReputationDBListPageContainerComponent,
    IPReputationDBListPageHeaderComponent,
    IPReputationSearchRecordCardComponent,
];

const ipReputationDBServices = [
    IPReputationDBListPageContainerService,
];

@NgModule({
    declarations: [
        ...ipReputationDBComponents,
    ],
    providers: [
        ...ipReputationDBServices,
    ],
    imports: [
        AviFormsModule,
        FormsModule,
        DataGridModule,
        SharedModule,
        BrowserAnimationsModule,
        ClrInputModule,
        ClrSpinnerModule,
        ClrTextareaModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class IPReputationDBModule {}
