/**
 * @module NotificationModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import classnames from 'classnames';
import './avi-notification.less';

const aviNotificationClassPrefix = 'avi-notification';
const slIconClassPrefix = 'sl-icon';

export enum AviNotificationTypeEnum {
    SUCCESS = 'success',
    ERROR = 'error',
    WARNING = 'warning',
    INFO = 'info',
}

type hashByAviNotificationType = {
    [type in AviNotificationType]: string;
};

type AviNotificationType = 'success' | 'error' | 'warning' | 'info';

/**
 * Class names associated with each notification type.
 */
const notificationTypeClasses: hashByAviNotificationType = {
    error: `${aviNotificationClassPrefix}--error`,
    info: `${aviNotificationClassPrefix}--info`,
    success: `${aviNotificationClassPrefix}--success`,
    warning: `${aviNotificationClassPrefix}--warning`,
};

/**
 * Icons associated with each notification type.
 */
const notificationTypeIcons: hashByAviNotificationType = {
    error: `${slIconClassPrefix}-minus`,
    info: `${slIconClassPrefix}-info`,
    success: `${slIconClassPrefix}-check`,
    warning: `${slIconClassPrefix}-exclamation`,
};

/**
 * @param {AviNotificationType} type - type of the notification which is to be shown
 * @param {Function} onClose - function to be called when notification gets closed
 * @description
 *     Component displaying a message in 4 different styles, as a success, error, warning,
 *     and info notification.
 * @example
 *      <avi-notification
 *          [type]="AviNotificationType.SUCCESS"
 *          (onClose)="callbackFunction"
 *      >
 *          <p>Notification Message</p>
 *      </avi-notification>
 * @author alext, chitra
 */
@Component({
    selector: 'avi-notification',
    templateUrl: './avi-notification.component.html',
})
export class AviNotificationComponent {
    /**
     * Type of notification to show.
     */
    @Input() public type: AviNotificationType = 'success';

    /**
     * Called when the user clicks the close button.
     */
    @Output() public onClose = new EventEmitter();

    /**
     * Click handler for closing the notification.
     */
    public handleClose(): void {
        this.onClose.emit();
    }

    /**
     * Returns true to show the close button on the notification.
     */
    public get showClose(): boolean {
        return this.onClose.observers.length > 0;
    }

    /**
     * Returns avi notification type class name.
     */
    public get aviNotificationTypeClass(): string {
        return classnames(aviNotificationClassPrefix, notificationTypeClasses[this.type]);
    }

    /**
     * Returns avi notification type icon class.
     */
    public get aviNotificationTypeIconClass(): string {
        return classnames(`${aviNotificationClassPrefix}__icon`, notificationTypeIcons[this.type]);
    }
}
