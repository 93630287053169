/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsWrapperModule
 */

import {
    MyAccount,
    SHOW_NEW_VS_LOGS_PAGE,
} from 'ajs/modules/core/services';
import { IRootScopeService } from 'angular';
import template from './vs-logs-wrapper-page.component.html';

/**
 * Wrapper component to allow alternating between old/new logs page versions.
 * @author Akul Aggarwal
 */
class VsLogsWrapperPageController {
    /**
     * Decide which version of page to show.
     */
    public showNewVersion: boolean;

    constructor(
        private readonly $scope: any,
        private readonly myAccount: MyAccount,
        private readonly $rootScope: IRootScopeService,
    ) {
        this.$scope = $scope;
    }

    /**
     * @override
     */
    public $onInit(): void {
        this.$scope.vs = this.$scope.$parent.vs;
        this.setToggleWatcher();
    }

    /**
     * Update page upon myAccount API updating showNewVsLogsPage prop.
     */
    private setToggleWatcher(): void {
        this.$rootScope.$watch(
            () => this.myAccount.uiProperty[SHOW_NEW_VS_LOGS_PAGE],
            () => this.showNewVersion = this.myAccount.uiProperty[SHOW_NEW_VS_LOGS_PAGE],
        );
    }
}

VsLogsWrapperPageController.$inject = [
    '$scope',
    'myAccount',
    '$rootScope',
];

export const vsLogsWrapperPageOptions = {
    controller: VsLogsWrapperPageController,
    template,
};
