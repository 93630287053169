/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author vgohil
 */

/**
 * Ajs dependency token for HSMGroup Collection.
 */
export const HSM_GROUP_COLLECTION_TOKEN = 'HSMGroupCollection';

/**
 * Ajs dependency token for HSMGroup Item.
 */
export const HSM_GROUP_ITEM_TOKEN = 'HSMGroup';

/**
 * Ajs dependency token for HardwareSecurityModule Config Item.
 */
export const HARDWARE_SECURITY_MODULE_CONFIG_ITEM_TOKEN = 'HardwareSecurityModuleConfigItem';

/**
 * Ajs dependency token for HSMSafenetLuna Config Item.
 */
export const HSM_SAFENET_LUNA_CONFIG_ITEM_TOKEN = 'HSMSafenetLunaConfigItem';

/**
 * Ajs dependency token for HSMSafenetLunaServer Config Item.
 */
export const HSM_SAFENET_LUNA_SERVER_CONFIG_ITEM_TOKEN = 'HSMSafenetLunaServerConfigItem';

/**
 * Ajs dependency token for HSMSafenetClientInfo Config Item.
 */
export const HSM_SAFENET_CLIENT_INFO_CONFIG_ITEM_TOKEN = 'HSMSafenetClientInfoConfigItem';

/**
 * Ajs dependency token for HSMAwsCloudHsm Config Item.
 */
export const HSM_AWS_CLOUD_HSM_CONFIG_ITEM_TOKEN = 'HSMAwsCloudHsmConfigItem';

/**
 * Ajs dependency token for Custom Params Config Item.
 */
export const CUSTOM_PARAMS_CONFIG_ITEM_TOKEN = 'CustomParamsConfigItem';

/**
 * Ajs dependency token for JWT Collection.
 */
export const JWT_SERVER_PROFILE_COLLECTION_TOKEN = 'JWTServerProfileCollection';

/**
 * Ajs dependency token for PingAccessAgentProfile collection.
 */
export const PING_ACCESS_AGENT_PROFILE_COLLECTION_TOKEN = 'PingAccessAgentProfileCollection';

/**
 * Ajs dependency token for SAML(SP) Config Item.
 */
export const SAML_SERVICE_PROVIDER_SETTINGS_CONFIG_ITEM_TOKEN =
    'SamlServiceProviderSettingsConfigItem';

/**
 * Ajs dependency token for SAML Config Item.
 */
export const SAML_SETTINGS_CONFIG_ITEM_TOKEN = 'SamlSettingsConfigItem';

/**
 * Ajs dependency token for AuthProfile Item.
 */
export const AUTH_PROFILE_ITEM_TOKEN = 'AuthProfileItem';

/**
 * Ajs dependency token for PingAccessAgent item.
 */
export const PING_ACCESS_AGENT_PROFILE_ITEM_TOKEN = 'PingAccessAgentProfile';

/**
 * Ajs dependency token for SSLProfileCollection.
 */
export const SSL_PROFILE_COLLECTION_TOKEN = 'SSLProfileCollection';

/**
 * Ajs dependency token for CertificateCollection.
 */
export const CERTIFICATE_COLLECTION_TOKEN = 'CertificateCollection';

export const LDAP_AUTH_SETTINGS_CONFIG_ITEM_TOKEN = 'LdapAuthSettingsConfigItem';
