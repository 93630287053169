/** @module WafModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { AviPermissionResource } from 'generated-types';

export const WAF_CRS_ITEM_TOKEN = 'WafCrs';

/**
 * @description WafCrs ObjectTypeItem
 * @author alextsg
 */
export class WafCrs extends ObjectTypeItem {
    public static ajsDependencies = [
        'Auth',
    ];

    constructor(args = {}) {
        const extendedArgs = {
            permissionName: AviPermissionResource.PERMISSION_WAFCRS,
            objectName: 'wafcrs',
            objectType: 'WafCRS',
            ...args,
        };

        super(extendedArgs);
    }
}
