/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Pipe } from '@angular/core';

import {
    IAviDiffGridSourcePair,
    IAviDiffGridSourcePairTransform,
} from 'ng/modules/data-grid/components/avi-diff-grid';

import {
    TVsLogHttpRequestHeaders,
    TVsLogHttpResponseHeaders,
} from '../components/vs-log-cinematic/vs-log-cinematic.types';

import {
    HTTP_HEADER_NAME_DUPLICATE_POSTFIX,
    VsLogsHttpHeadersStringService,
} from '../services/vs-log-http-headers.service';

type THttpHeaderType = 'request' | 'response';

const headersHashKeys = {
    request: {
        left: 'all_request_headers',
        right: 'headers_sent_to_server',
    },
    response: {
        left: 'headers_received_from_server',
        right: 'all_response_headers',
    },
};

/**
 * @description
 *     Pipe for transforming a pair of HTTP headers strings with delimiters '\n' to an object of
 *     diff source pair with two sets of headers extracted from the headers strings, respectively,
 *     and saved in the left source object and right source object.
 *
 * @example
 *     Raw data:
 *     headersData: {
 *         all_request_headers: 'Host: 100.66.184.80:9000\r\nUser-Agent: python-requests/2.22.0\r\n
 *             Accept-Encoding: gzip, deflate\r\nAccept: **\r\nConnection: keep-alive\r\n',
 *         headers_sent_to_server: 'X-Forwarded-For: 100.66.183.76\r\nHost: 100.66.184.80:9000\r\n
 *             User-Agent: python-requests/2.22.0\r\nAccept-Encoding: gzip, deflate\r\nAccept: **
 *             \r\n',
 *     }
 *
 *     Resulted diff source pair object:
 *     {
 *       leftSource: {
 *           'Host': '100.66.184.80:9000',
 *           'User-Agent': 'python-requests/2.22.0',
 *           'Accept-Encoding': 'gzip, deflate',
 *           'Accept': '**',
 *           'Connection': 'keep-alive'
 *       },
 *       rightSource: {
 *           'X-Forwarded-For': '100.66.183.76',
 *           'Host': '100.66.184.80:9000',
 *           'User-Agent': 'python-requests/2.22.0',
 *           'Accept-Encoding': 'gzip, deflate',
 *           'Accept': '**'
 *       },
 *       diffPostfix: ':://',
 *       performNoDiff: false,
 *     }
 * @author Zhiqian Liu
 */
@Pipe({
    name: 'logHttpHeadersDiffSourcePair',
})
export class LogHttpHeadersDiffSourcePairPipe implements IAviDiffGridSourcePairTransform {
    /** @override */
    // eslint-disable-next-line class-methods-use-this
    public transform(
        headersData: TVsLogHttpRequestHeaders | TVsLogHttpResponseHeaders,
        type: THttpHeaderType,
    ): IAviDiffGridSourcePair {
        const {
            left: leftKey,
            right: rightKey,
        } = headersHashKeys[type];

        const leftHeadersString = headersData[leftKey] as string;
        const rightHeadersString = headersData[rightKey] as string;

        // turn raw headers string data to a list of headers and save them as a hash
        const leftSource =
            VsLogsHttpHeadersStringService.splitHttpHeadersStrToHash(leftHeadersString);
        const leftTruncated =
            VsLogsHttpHeadersStringService.headersStringHasTruncation(leftHeadersString);

        // turn raw headers string data to a list of headers and save them as a hash
        const rightSource =
            VsLogsHttpHeadersStringService.splitHttpHeadersStrToHash(rightHeadersString);
        const rightTruncated =
            VsLogsHttpHeadersStringService.headersStringHasTruncation(rightHeadersString);

        const diffSourcePair: IAviDiffGridSourcePair = {
            leftSource,
            rightSource,
            diffPostfix: HTTP_HEADER_NAME_DUPLICATE_POSTFIX,
            // no need to perform diff if one of the sources is undefined or one of the headers
            // strings is truncated
            performNoDiff: !leftSource || !rightSource || leftTruncated || rightTruncated,
        };

        return diffSourcePair;
    }
}
