/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name orderedGridResizer
 * @description
 *     Resizer component for the orderedGrid component. Resizes the column to the left, which is the
 *     column the resizer is in, and right of the resizer, and leaves all other columns untouched.
 *     Increasing the size of column to the left decreases the size of the column to the right.
 * @param {string} columnClassName - className selector of the column containing the resizer.
 * @param {string} neighborColumnClassName - className Selector of the adjacent column affected by
 *     resizing, aka the column to the right.
 */
class OrderedGridResizer {
    constructor($element, $document) {
        this._$document = $document;
        this._$element = $element;

        /**
         * startX represents the pixel value at the beginning of the th element. The minimum
         * value for the width is the width of the field label plus startX to avoid cutting off
         * the field label.
         */
        this.startX = 0;

        /**
         * maxX represents the maximum x-coordinate allowed for dragging. It is calculated to
         * include the size of the field label of the column to the right along with the current
         * total size of the th elements of the rest of the siblings to the right. We disable
         * further dragging when the cursor is greater than the maxX value.
         */
        this.maxX = 0;

        /**
         * minWidth is the minimum width allowed to be dragged when dragging to the left. Since
         * we don't want to cut off the field label, the minWidth is equal to startX plus the
         * width of the field label and padding.
         */
        this.minWidth = 0;
    }

    $onInit() {
        this.selector = `.${this.columnClassName}`;
        this.neighborSelector = `.${this.neighborColumnClassName}`;

        this.handleMousemove = this.handleMousemove.bind(this);
        this.handleMouseup = this.handleMouseup.bind(this);

        this._$element.on('mousedown', event => {
            event.preventDefault();

            /**
             * Sets each th element width to the default width to prevent unwanted automatic
             * width changes during dragging.
             */
            this.orderedGrid.setColumnWidths();

            /**
             * this.minWidth doesn't change on resize since it's based on the field string and
             * padding.
             */
            this.minWidth = this.minWidth || this.getMinWidth();

            /**
             * this.startX and this.maxX may change depending on if sibling columns have been
             * resized.
             */
            this.startX = this.getStartX();
            this.maxX = this.getMaxX();

            this._$document.on('mousemove', this.handleMousemove);
            this._$document.on('mouseup', this.handleMouseup);
        });
    }

    /**
     * Returns the minimum width as a number in pixels. The minimum width is calculated as the
     * smallest size the column can be, which is the label width plus its padding.
     * @return {number}
     */
    getMinWidth() {
        return this.orderedGrid.getMinimumHeaderWidth(this.selector);
    }

    /**
     * Returns the startX value to be used to calculate the width. The width is equal to the
     * cursor x-coordinate minus the startX.
     * @return {number}
     */
    getStartX() {
        return this.orderedGrid.getColumnHeaderLeftOffset(this.selector);
    }

    /**
     * Returns the maxX value, or the maximum x-coordinate value that the element can be
     * dragged.
     * @return {number}
     */
    getMaxX() {
        return this.orderedGrid.getMaxX(this.selector);
    }

    /**
     * Event handler for the 'mousemove' event. The resizer is situated between two columns, and
     * this handler sets the widths of both of the columns as the resizer is moved. All other
     * columns' widths are unchanged. Percentages are used instead of pixels in order to account
     * for the possibility of the user changing the window size after the grid has rendered.
     * @param {MouseEvent} event - 'mousemove' event.
     */
    handleMousemove(event) {
        const { pageX } = event;
        const width = pageX - this.startX;

        if (width < this.minWidth || pageX > this.maxX) {
            return;
        }

        const totalWidth = this.orderedGrid.getTableWidth();
        const oldLeftWidth = this.orderedGrid.getColumnHeaderWidth(this.selector);
        const oldRightWidth = this.orderedGrid.getColumnHeaderWidth(this.neighborSelector);
        const newRightWidth = oldLeftWidth + oldRightWidth - width;

        const widthPercent = width / totalWidth * 100;
        const newRightWidthPercent = newRightWidth / totalWidth * 100;

        this.orderedGrid.setWidth(this.selector, widthPercent);
        this.orderedGrid.setWidth(this.neighborSelector, newRightWidthPercent);
    }

    /**
     * Event handler for the 'mouseup' event. Removes event listeners from the $document.
     */
    handleMouseup() {
        this._$document.off('mousemove', this.handleMousemove);
        this._$document.off('mouseup', this.handleMouseup);
    }
}

OrderedGridResizer.$inject = [
    '$element',
    '$document',
];

angular.module('aviApp').component('orderedGridResizer', {
    controller: OrderedGridResizer,
    bindings: {
        columnClassName: '@',
        neighborColumnClassName: '@',
    },
    require: {
        orderedGrid: '^^orderedGrid',
    },
    template: '<div class="ordered-grid__resizer"></div>',
});
