/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'icap-profile';
const componentName = 'icap-profile-list-page';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleServiceUri = `${prefix}.columnTitleServiceUri`;
export const columnTitlePoolGroup = `${prefix}.columnTitlePoolGroup`;
export const columnTitleFailAction = `${prefix}.columnTitleFailAction`;
export const columnTitleVendor = `${prefix}.columnTitleVendor`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleServiceUri]: 'Service URI',
    [columnTitlePoolGroup]: 'Pool Group',
    [columnTitleFailAction]: 'Fail Action',
    [columnTitleVendor]: 'Vendor',
    [headerLabel]: 'ICAP Profile',
};
