/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { L10nService } from '@vmw/ngx-vip';

import {
    AviPermissionResource,
    IAlertSyslogServer,
} from 'generated-types';

import {
    AlertSyslogConfigModalComponent,
} from 'ng/modules/alert/components/alert-syslog-modal/alert-syslog-modal.component';

import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { AlertSyslogConfig } from 'object-types';

import {
    withEditChildMessageItemMixin,
} from 'ajs/modules/data-model/mixins/with-edit-child-message-item.mixin';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { AlertSyslogServerConfigItem } from './alert-syslog-server.config-item.factory';
import * as l10n from '../../alert.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const SYSLOG_SERVERS = 'syslog_servers';

type AlertSyslogConfigPartial =
    Omit<IAlertSyslogServer, 'syslog_servers'>;

/**
 * AlertSyslogConfig interface extending IAlertSyslogServer type.
 */
interface IAlertSyslogConfig extends AlertSyslogConfigPartial {
    syslog_servers?: RepeatedMessageItem<AlertSyslogServerConfigItem>
}

/**
 * IAlertSyslogData interface for over-riding data.
 */
interface IAlertSyslogData {
    config: IAlertSyslogConfig;
}

/**
 * @description
 *
 *   AlertEmailConfig item.
 *
 * @author Guru Prasad H K
 */
export class AlertSyslogConfigItem extends
    withEditChildMessageItemMixin(withFullModalMixin(ObjectTypeItem)) {
    public static ajsDependencies = [
        'l10nService',
    ];

    /**
     * alert syslog data.
     */
    public data: IAlertSyslogData;

    /**
     * l10nService instance to register source bundles and get keys from source bundles.
     */
    private readonly l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'alertsyslogconfig',
            objectType: AlertSyslogConfig,
            permissionName: AviPermissionResource.PERMISSION_ALERTSYSLOGCONFIG,
            windowElement: AlertSyslogConfigModalComponent,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');

        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * To get Syslog Servers from Syslog Server config.
     */
    public get syslogServers(): RepeatedMessageItem<AlertSyslogServerConfigItem> {
        const { syslog_servers: syslogServers } = this.config;

        return syslogServers;
    }
    /**
     * Method to add Syslog server item.
     */
    public addSyslogServer(): void {
        this.addChildMessageItem({
            field: SYSLOG_SERVERS,
        });
    }

    /**
     * Method to edit Syslog server server item.
     */
    public editSyslogServer(syslogServer: AlertSyslogServerConfigItem): void {
        this.addChildMessageItem({
            field: SYSLOG_SERVERS,
            messageItem: syslogServer,
        });
    }

    /** @override */
    public getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.alertSyslogBreadcrumbTitle);
    }
}
