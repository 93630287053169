/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Module for commonly used components.
 * @module avi/component-kit
 */

import classnames from 'classnames';
import './progress-bar.component.less';

const componentClassName = 'new-progress-bar';
const typeToClassName = {
    transition: `${componentClassName}--transition`,
    message: `${componentClassName}--message`,
    default: `${componentClassName}--default`,
};

/**
 * @constructor
 * @memberOf module:avi/component-kit
 */
class ProgressBarController {
    /**
     * Getter for width percentage of the progress bar.
     */
    get widthPercentage() {
        // this.percentage can be NaN
        return this.percentage || 0;
    }

    /**
     * Returns the classNames associated with the type.
     * @returns {string}
     */
    getClassNames() {
        const {
            type = 'default',
            transitionFinished,
            title,
            className,
        } = this;

        const classNameByType = typeToClassName[type];

        return classnames(
            className,
            componentClassName,
            classNameByType,
            title ? `${componentClassName}--with-title` : '',
            type === 'transition' &&
                transitionFinished && `${classNameByType}--fast` || '',
        );
    }
}

/**
 * @mixin progressBarBindings
 * @memberOf module:avi/component-kit
 * @property {string=} className - class passed in from parent
 * @property {string=} message - Message to be shown.
 * @property {number} percentage - Completion percentage, 0 - 100 or NaN.
 * @property {string=} title - Title above progress bar.
 * @property {boolean=} transitionFinished - Indicate progress bar that transition has finished.
 *      based on this property transition duration in css is reset from 1s to 500ms.
 * @property {string=} type - Type of the progress bar to be shown. We have only three types:
 *     'transition' - red bar with no text.
 *     'message' - green bar in a container with a message and percentage text.
 *     'default' - green bar with no text. In the absence of type 'default' is used.
 */

/**
 * @memberOf module:avi/component-kit
 * @name progressBarComponent
 * @property {module:avi/component-kit.progressBarBindings} bindings
 * @property {module:avi/component-kit.ProgressBarController} controller
 * @author Alex Tseung, Akul Aggarwal, Zhiqian Liu
 * @desc A progress bar for displaying the progress of something, ex. a file upload.
 */
angular.module('avi/component-kit').component('progressBar', {
    bindings: {
        className: '@?',
        message: '@?',
        percentage: '<',
        title: '@?',
        transitionFinished: '<?',
        type: '@?',
    },
    controller: ProgressBarController,
    template: require('./progress-bar.partial.html'), //must require due to race-condition
});
