/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    AbstractControl,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';

import inSubnet from 'insubnet';
import { isString } from 'angular';
import { find } from 'underscore';
import { getSubnetString } from 'ng/shared/utils/ip-prefix-parser.utils';

export const IP_RANGE_VALIDATOR_KEY = 'ipRangeInvalid';

/**
 * Validator function to check if ip or ip range are within subnet.
 */
export function validateIpInSubnet(subnetValue: string): ValidatorFn {
    /**
     * Returns null if IP is in subnet else,
     * returns an error object.
     */
    return (control: AbstractControl): ValidationErrors | null => {
        const { value: modelValue } = control;

        const errorObject = {
            [IP_RANGE_VALIDATOR_KEY]: modelValue,
        };

        if (!subnetValue) {
            return errorObject;
        }

        const subnet = isString(subnetValue) ? subnetValue : getSubnetString(subnetValue);

        const isIpValid = !find(modelValue.split(/[,-]/), (ip: string) => {
            return !inSubnet.Auto(ip.trim(), subnet);
        });

        return isIpValid ? null : errorObject;
    };
}
