/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { pick } from 'underscore';
import {
    IIpAddr,
    IIpAddrMatch,
    IIpAddrPrefix,
    IIpAddrRange,
    MatchOperation,
} from 'generated-types';
import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    IpAddrRangeConfigItem,
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';
import {
    StringService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core/services';
import { L10nService } from '@vmw/ngx-vip';

import * as l10n from '../match.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

export const IP_ADDR_MATCH_CONFIG_ITEM_TOKEN = 'IpAddrMatchConfigItem';

type TIpAddrMatchPartial = Omit<IIpAddrMatch, 'addrs' | 'ranges' | 'prefixes'>;

interface IIpAddrMatchFlatProps {
    addrs?: IIpAddr[];
    ranges?: IIpAddrRange[];
    prefixes?: IIpAddrPrefix[];
}

interface IIpAddrMatchConfig extends TIpAddrMatchPartial {
    addrs?: RepeatedMessageItem<IpAddrConfigItem>;
    ranges?: RepeatedMessageItem<IpAddrRangeConfigItem>;
    prefixes?: RepeatedMessageItem<IpAddrPrefixConfigItem>;
    _flatProps?: IIpAddrMatchFlatProps;
}

/**
 * @description  IpAddrMatch ConfigItem class.
 * @author alextsg
 */
export class IpAddrMatchConfigItem extends MessageItem<IIpAddrMatchConfig> {
    public static ajsDependencies = [
        'schemaService',
        'getSubnetString',
        'stringService',
        'l10nService',
    ];

    private matchOperationLabelsHash: TEnumValueLabelsHash;

    private l10nService: L10nService;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'IpAddrMatch',
            ...args,
        };

        super(extendedArgs);

        const schemaService = this.getAjsDependency_('schemaService');

        this.matchOperationLabelsHash = schemaService.getEnumValueLabelsHash('MatchOperation');
        this.l10nService = this.getAjsDependency_('l10nService');
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IIpAddrMatchConfig> {
        return {
            match_criteria: MatchOperation.IS_IN,
            group_refs: [],
        };
    }

    /**
     * Returns the default values for this.config._flatProps.
     */
    private get defaultFlatProps(): Record<string, []> {
        return {
            ranges: [],
            prefixes: [],
            addrs: [],
        };
    }

    /**
     * Returns the label for this.config.match_criteria.
     * @returns {string}
     */
    private get matchCriteriaLabel(): string {
        const { match_criteria: matchCriteria } = this.config;

        return this.matchOperationLabelsHash[matchCriteria];
    }

    /**
     * Returns an array of all addresses in this.config.
     */
    public get addresses(): string[] {
        const { addrs, ranges, prefixes } = this.config;
        const repeatedConfigItems = [addrs, ranges, prefixes];

        return repeatedConfigItems.reduce((acc, base: RepeatedMessageItem<any>) => {
            const labels = base.config.map(configItem => configItem.toString()) || [];

            return acc.concat(labels);
        }, []);
    }

    /**
     * Returns an array of all addresses in this.config._flatProps.
     */
    private get flatPropsAddresses(): string[] {
        const getSubnetString = this.getAjsDependency_('getSubnetString');

        // eslint-disable-next-line no-underscore-dangle
        const { addrs = [], ranges = [], prefixes = [] } = this.config._flatProps;
        const addrStrings = addrs.map(addr => addr.addr);
        const rangeStrings = ranges.map(range => `${range.begin.addr} - ${range.end.addr}`);
        const prefixStrings = prefixes.map(prefix => getSubnetString(prefix));

        return addrStrings.concat(rangeStrings).concat(prefixStrings);
    }

    /**
     * Returns an array of names of the group_refs.
     */
    public get groupNames(): string[] {
        const stringService: StringService = this.getAjsDependency_('stringService');
        const { group_refs: groupRefs = [] } = this.config;

        return groupRefs.map(ref => stringService.name(ref));
    }

    /**
     * Label of match. Commonly used as part of expander info.
     */
    public get matchLabel(): string {
        return this.l10nService.getMessage(l10nKeys.clientIpLabel);
    }

    /**
     * Value of match to represent the data. Commonly used as part of expander info.
     */
    public get matchValue(): string {
        return this.flatPropsAddresses.concat(this.groupNames).join(', ');
    }

    /**
     * Returns a string representation of the _flatProps config data.
     */
    public flatDataToString(): string {
        return `${this.matchCriteriaLabel} ${this.matchValue}`;
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        const values = this.addresses.concat(this.groupNames).join(', ');

        return `${this.matchCriteriaLabel} ${values}`;
    }

    /** @override */
    public modifyConfigDataAfterLoad(): void {
        // eslint-disable-next-line no-underscore-dangle
        this.config._flatProps = this.config._flatProps || this.defaultFlatProps;

        // Needed to use with ipOrGroupList directive, as it expects flat data.
        // Rewrite ipOrGroupList to work with a ConfigItem.
        const repeatedConfigItems = pick(this.config, 'ranges', 'prefixes', 'addrs');

        Object.keys(repeatedConfigItems).forEach(field => {
            const repeatedConfigItem = repeatedConfigItems[field];

            // eslint-disable-next-line no-underscore-dangle
            this.config._flatProps[field] = repeatedConfigItem.getDataToSave() || [];
        });
    }

    /** @override */
    public modifyConfigDataBeforeSave(): void {
        const { _flatProps } = this.config;

        Object.keys(_flatProps).forEach(field => {
            const repeatedConfigItem = this.config[field];

            repeatedConfigItem.removeAll();
            _flatProps[field].forEach((config: Record<string, any>) => {
                repeatedConfigItem.add(config);
            });
        });

        // eslint-disable-next-line no-underscore-dangle
        delete this.config._flatProps;
    }

    /**
     * Called to remove flatProps, so that they don't overwrite the repeatedMessageItem data in
     * modifyConfigDataBeforeSave.
     */
    public clearFlatProps(): void {
        // eslint-disable-next-line no-underscore-dangle
        this.config._flatProps = {};
    }

    public removeAll(): void {
        const {
            addrs,
            prefixes,
            ranges,
        } = this.config;

        const repeatedMessageItems = [addrs, prefixes, ranges];

        repeatedMessageItems.forEach(repeatedMessageItem => repeatedMessageItem.removeAll());

        delete this.config.group_refs;
    }

    public addGroupRef(ref: string): void {
        this.config.group_refs = this.config.group_refs || [];

        this.config.group_refs.push(ref);
    }
}
