/**
 * @module accountsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for factories & services.
 *
 * @author Nisar Nadaf
 */

/**
 * Ajs dependency token for Role Service.
 */
export const ROLE_SERVICE_TOKEN = 'roleService';

/**
 * Ajs dependency token for Tenant Settings Service.
 */
export const TENANT_SETTINGS_SERVICE_TOKEN = 'TenantSettingsService';

/**
 * Ajs dependency token for Role Item.
 */
export const ROLE_ITEM_TOKEN = 'RoleItem';

/**
 * Ajs dependency token for RoleCollection.
 */
export const ROLE_COLLECTION_TOKEN = 'RoleCollection';

/**
 * Ajs dependency token for RoleFilterConfigItem.
 */
export const ROLE_FILTER_CONFIG_ITEM_TOKEN = 'RoleFilterConfigItem';

/**
 * Ajs dependency token for User Item.
 */
export const USER_ITEM_TOKEN = 'UserItem';

/**
 * Ajs dependency token for UserCollection.
 */
export const USER_COLLECTION_TOKEN = 'UserCollection';

/**
 * Ajs Dependency token for PasswordGeneratorService.
 */
export const PASSWORD_GENERATOR_SERVICE_TOKEN = 'PasswordGeneratorService';

/**
 * Ajs dependency token for Tenant Item.
 */
export const TENANT_ITEM_TOKEN = 'TenantItem';

/**
 * Ajs dependency token for TenantCollection.
 */
export const TENANT_COLLECTION_TOKEN = 'TenantCollection';

/**
 * Ajs dependency token for User Profile Collection.
 */
export const USER_PROFILE_COLLECTION_TOKEN = 'UserProfileCollection';

/**
 * Ajs dependency token for UserProfile Item.
 */
export const USER_PROFILE_ITEM_TOKEN = 'UserProfileItem';
