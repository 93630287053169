/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc component
 * @name policyGridActionColumn
 * @description Component for displaying the action configuration in a grid field.
 * @param {LegacyPolicyRuleConfigItem} rule
 * @param {PolicyGridConfig} config
 */
class PolicyGridActionColumnController {
    constructor(PolicyGridService, LegacyPolicyRuleConfigItem) {
        this.PolicyGridService_ = PolicyGridService;
        this.LegacyPolicyRuleConfigItem_ = LegacyPolicyRuleConfigItem;
    }

    $onInit() {
        this.isLegacyPolicyRuleConfigItem = this.rule instanceof this.LegacyPolicyRuleConfigItem_;

        if (!this.isLegacyPolicyRuleConfigItem) {
            this.actions = this.config.collection.actions;
        }
    }

    /**
     * Returns all actions in the rule.
     * @return {ActionConfigItem[]}
     */
    getActions() {
        return this.rule.getActions();
    }

    /**
     * Returns the name of the action property to be displayed.
     * @param {string} property - Action property.
     */
    getActionName(property) {
        return this.PolicyGridService_.getActionName(property, this.rule);
    }

    /**
     * Returns a string representation of the action configuration.
     * @param {string} property - Action property.
     * @param {Object} action - Action object containing the config.
     */
    getActionString(property, action) {
        return this.PolicyGridService_.getActionString(property, action, this.rule);
    }
}

PolicyGridActionColumnController.$inject = [
    'PolicyGridService',
    'LegacyPolicyRuleConfigItem',
];

angular.module('aviApp').component('policyGridActionColumn', {
    bindings: {
        rule: '<',
        config: '<',
    },
    controller: PolicyGridActionColumnController,
    templateUrl: 'src/components/applications/virtualservice/policy/policy-grid/' +
            'policy-grid-action-column/policy-grid-action-column.html',
});
