/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf';
const componentName = 'waf-profile-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const gridPlaceholder = `${prefix}.gridPlaceholder`;
export const columnTitleName = `${prefix}.columnTitleName`;

export const ENGLISH = {
    [headerLabel]: 'WAF Profile',
    [gridPlaceholder]: 'No Items Found',
    [columnTitleName]: 'Name',
};
