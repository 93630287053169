/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-logs-search-bar-dropdown';
const prefix = `${moduleName}.${componentName}`;

export const noOptionsMessage = `${prefix}.noOptionsMessage`;
export const errorMessage = `${prefix}.errorMessage`;

export const ENGLISH = {
    [noOptionsMessage]: 'no logs with this property',
    [errorMessage]: 'unable to load suggestions',
};
