/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ControllerInitialDataStoreModule } from
    './controller-initial-data-store/controller-initial-data-store.module';

import { ControllerSiteStoreModule } from './controller-site-store/controller-site-store.module';
import { IInfraCloudStateModule } from './infra-cloud-store/infra-cloud-store.module';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forRoot({}),
        ControllerInitialDataStoreModule,
        IInfraCloudStateModule,
        ControllerSiteStoreModule,
    ],
})
export class RootStoreModule {}
