/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'dns-services-grid';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const gridTitleDnsServices = `${prefix}.gridTitleDnsServices`;
export const columnTitleVirtualService = `${prefix}.columnTitleVirtualService`;

export const ENGLISH = {
    [headerLabel]: 'Edit System Settings',
    [gridTitleDnsServices]: 'DNS Services',
    [columnTitleVirtualService]: 'Virtual Service',
};
