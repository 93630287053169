/**
 * @module VrfContextModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IStaticRoute } from 'generated-types';
import { StaticRoute } from 'object-types';
import {
    IpAddrConfigItem,
    IpAddrPrefixConfigItem,
    MessageItem,
} from 'ajs/modules/data-model/factories';

type IStaticRouteConfigPartial = Omit<IStaticRoute, 'prefix' | 'next_hop'>;

interface IStaticRouteConfig extends IStaticRouteConfigPartial {
    prefix: IpAddrPrefixConfigItem;
    next_hop: IpAddrConfigItem;
}

/**
 * @description Static Route Message Item.
 * @author Alex Klyuev
 */
export class StaticRouteConfigItem extends MessageItem<IStaticRouteConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: StaticRoute,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for route_id property.
     */
    public get routeId(): string {
        return this.config.route_id;
    }

    /**
     * Setter for route_id property.
     */
    public set routeId(id: string) {
        this.config.route_id = id;
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return [
            'prefix',
            'next_hop',
        ];
    }
}
