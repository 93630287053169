/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'system';
const componentName = 'system-settings-system-access-config';
const prefix = `${moduleName}.${componentName}`;

export const systemAccessHeader = `${prefix}.systemAccessHeader`;
export const enableHttpAccessLabel = `${prefix}.enableHttpAccessLabel`;
export const httpPortLabel = `${prefix}.httpPortLabel`;
export const httpPortPlaceholder = `${prefix}.httpPortPlaceholder`;
export const httpPortHint = `${prefix}.httpPortHint`;
export const httpsPortHint = `${prefix}.httpsPortHint`;
export const enableHttpsAccessLabel = `${prefix}.enableHttpsAccessLabel`;
export const httpsPortLabel = `${prefix}.httpsPortLabel`;
export const httpsPortPlaceholder = `${prefix}.httpsPortPlaceholder`;
export const redirectPortLabel = `${prefix}.redirectPortLabel`;
export const sslTlsHeader = `${prefix}.sslTlsHeader`;
export const sslProfileLabel = `${prefix}.sslProfileLabel`;
export const sslTlsCertificatesLabel = `${prefix}.sslTlsCertificatesLabel`;
export const secureChannelSslTlsCertsLabel = `${prefix}.secureChannelSslTlsCertsLabel`;
export const sslTlsCertificatesPlaceholder = `${prefix}.sslTlsCertificatesPlaceholder`;
export const secureChannelSslTlsCertsPlaceholder = `${prefix}.secureChannelSslTlsCertsPlaceholder`;
export const allowBasicAuthLabel = `${prefix}.allowBasicAuthLabel`;
export const allowedCiphersLabel = `${prefix}.allowedCiphersLabel`;
export const allowedCiphersPlaceholder = `${prefix}.allowedCiphersPlaceholder`;
export const allowedHmacsLabel = `${prefix}.allowedHmacsLabel`;
export const allowedHmacsPlaceholder = `${prefix}.allowedHmacsPlaceholder`;

export const ENGLISH = {
    [systemAccessHeader]: 'System Access',
    [enableHttpAccessLabel]: 'Enable HTTP Access to System',
    [httpPortLabel]: 'HTTP Port',
    [httpPortPlaceholder]: 'Enter HTTP Port',
    [httpPortHint]: 'Default Port: 80',
    [httpsPortHint]: 'Default Port: 443',
    [enableHttpsAccessLabel]: 'Enable HTTPS Access to System',
    [httpsPortLabel]: 'HTTPS Port',
    [httpsPortPlaceholder]: 'Enter HTTPS Port',
    [redirectPortLabel]: 'Redirect HTTP to HTTPS',
    [sslTlsHeader]: 'SSL/TLS',
    [sslProfileLabel]: 'SSL Profile',
    [sslTlsCertificatesLabel]: 'SSL/TLS Certificate',
    [sslTlsCertificatesPlaceholder]: 'Select SSL/TLS Certificate',
    [secureChannelSslTlsCertsLabel]: 'Secure Channel SSL/TLS Certificate',
    [secureChannelSslTlsCertsPlaceholder]: 'Select Secure Channel SSL/TLS Certificate',
    [allowBasicAuthLabel]: 'Allow Basic Authentication',
    [allowedCiphersLabel]: 'Allowed Ciphers',
    [allowedCiphersPlaceholder]: 'Enter Allowed Ciphers',
    [allowedHmacsLabel]: 'Allowed HMACs',
    [allowedHmacsPlaceholder]: 'Enter Allowed HMACs',
};
