/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IHostHdrMatch } from 'generated-types';
import { HostHdrMatch } from 'object-types';

import {
    SchemaService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core/services';

import { GenericHdrMatchBaseConfigItem } from './generic-hdr-match-base.config-item';

/**
 * @description  HostHdrMatch ConfigItem class.
 * @author Akul Aggarwal, Abhinesh Gour
 */
export class HostHdrMatchConfigItem extends GenericHdrMatchBaseConfigItem<IHostHdrMatch> {
    public static ajsDependencies = ['schemaService'];

    /**
     * SchemaService instance to get data model exported from protobuf.
     */
    private readonly schemaService: SchemaService;

    /**
     * Hash of match case values to their labels.
     */
    private readonly matchCaseLabelsHash: TEnumValueLabelsHash;

    /**
     * Hash of operation values to their labels.
     */
    private readonly operationLabelsHash: TEnumValueLabelsHash;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: HostHdrMatch,
            ...args,
        };

        super(extendedArgs);

        this.schemaService = this.getAjsDependency_('schemaService');
        this.matchCaseLabelsHash = this.schemaService.getEnumValueLabelsHash('MatchCase');
        this.operationLabelsHash = this.schemaService.getEnumValueLabelsHash('HdrMatchOperation');
    }

    /**
     * Returns a list of values that should be searchable.
     */
    public get searchableValues(): string[] {
        const { value = [] } = this.config;

        return [
            ...value,
        ];
    }

    /**
     * Returns the label for the match_case value.
     */
    public get matchCaseLabel(): string {
        const { match_case: matchCase } = this.config;

        return this.matchCaseLabelsHash[matchCase];
    }

    /**
     * Returns the label for the match_criteria value.
     */
    public get matchCriteriaLabel(): string {
        const { match_criteria: matchCriteria } = this.config;

        return this.operationLabelsHash[matchCriteria];
    }

    /**
     * Returns the label for the 'value' config property.
     */
    public get valueLabels(): string {
        const { value = [] } = this.config;

        return value.join(', ');
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        return `${this.matchCaseLabel}, ${this.matchCriteriaLabel} ${this.valueLabels}`;
    }
}
