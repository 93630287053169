/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Component, OnInit } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';
import { SchemaService, StringService } from 'ajs/modules/core/services';

import * as l10n from './vs-log-cinematic-sub-section-o-auth.l10n';
import { VsLogCinematicStore } from '../../vs-log-cinematic.store';

import './vs-log-cinematic-sub-section-o-auth.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const { ...globalL10nKeys } = globalL10n;

const oauthSubRequestLogNames = ['jwks_subrequest', 'token_exchange_subrequest',
    'token_introspection_subrequest', 'token_refresh_subrequest', 'userinfo_subrequest'];

const oauthSubRequestLogFilterableProperties =
    ['pool_name', 'source_port', 'total_time', 'error_code', 'server_name', 'server_ip',
    'server_port', 'http_response_code'];

/** Field info for each sub-reqeust log under an oAuth Log */
interface IOAuthSubRequestLogField {
    fieldName: string;
    fieldLabel: string;
}

type TSubRequestLogFilterPropertyHash = Record<string, Record<string, string>>;

/**
 * @description
 *     Component for displaying the OAuth sub-section in the Authentication section under VS Log
 *     cinematic view.
 * @author Zhiqian Liu
 */
@Component({
    selector: 'vs-log-cinematic-sub-section-o-auth',
    templateUrl: './vs-log-cinematic-sub-section-o-auth.component.html',
})
export class VsLogCinematicSubSectionOAuthComponent implements OnInit {
    public readonly oAuthLog$ = this.vsLogCinematicStore.oAuthLog$;

    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    /**
     * List of info containing field names and field labels for oAuth sub-request logs.
     */
    public oAuthSubRequestFields: IOAuthSubRequestLogField[];

    /**
     * Map from filterable property names of OAuth SubRequestLogs to the full string of chained
     * filter property names from the top level.
     * Eg. subRequestLogFilterPropertyHash['jwks_subrequest']['server_port'] yields the resulted
     * string as 'oauth_log.jwks_subrequest.sub_request_log.server_port'.
     */
    public subRequestLogFilterPropertyHash: TSubRequestLogFilterPropertyHash = {};

    constructor(
        public readonly vsLogCinematicStore: VsLogCinematicStore,
        private readonly l10nService: L10nService,
        private readonly stringService: StringService,
        public readonly schemaService: SchemaService,
    ) {
        this.l10nService.registerSourceBundles(dictionary);
    }

    public trackByIndex(index: number): number {
        return index;
    }

    /** @override */
    public ngOnInit(): void {
        this.setOAuthSubRequestFields();
        this.setSubRequestLogFilterPropertyHash();
    }

    /**
     * Set a list of the named sub-request logs under OAuth log with their names and labels.
     */
    private setOAuthSubRequestFields(): void {
        this.oAuthSubRequestFields = oauthSubRequestLogNames.map(
            fieldName => {
                const fieldCamelCaseName = this.stringService.snakeToCamelCase(fieldName);
                const l10nKey = `${fieldCamelCaseName}Label`;
                const fieldLabel = this.l10nService.getMessage(l10nKeys[l10nKey]);

                return {
                    fieldName,
                    fieldLabel,
                };
            },
        );
    }

    /**
     * Set a map from filterable property names of OAuth SubRequestLogs to the full string of
     * chained filter property names from the top level. This hash is nested with the sub request
     * log key to first access the filter property string hash of that subReqLog and the property
     * key to get the actualy property string.
     * Eg. subRequestLogFilterPropertyHash['jwks_subrequest']['server_port'] yields the resulted
     * string as 'oauth_log.jwks_subrequest.sub_request_log.server_port'.
     */
    private setSubRequestLogFilterPropertyHash(): void {
        oauthSubRequestLogNames.forEach(subReqLogName => {
            const subReqLogPropertyHash = {};

            oauthSubRequestLogFilterableProperties.forEach(propertyName => {
                let propertyString: string;

                if (propertyName !== 'error_code') {
                    propertyString = `oauth_log.${subReqLogName}.sub_request_log.${propertyName}`;
                } else {
                    propertyString = `oauth_log.${subReqLogName}.${propertyName}`;
                }

                subReqLogPropertyHash[propertyName] = propertyString;
            });

            this.subRequestLogFilterPropertyHash[subReqLogName] = subReqLogPropertyHash;
        });
    }
}
