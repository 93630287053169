/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'ng/shared/shared.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';

import { AnalyticsProfileListPageComponent } from './components';

import { analyticsProfileCollectionProvider } from './ajs-upgraded-providers';

const analyticsProfileComponents = [AnalyticsProfileListPageComponent];

/**
 * @description
 *      Angular Module for Analytics Profile related components.
 * @author Anjali Tanpure
 */
@NgModule({
    declarations: [
        ...analyticsProfileComponents,
    ],
    imports: [
        DataGridModule,
        FormsModule,
        SharedModule,
        BrowserAnimationsModule,
    ],
    providers: [
        analyticsProfileCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AnalyticsModule {}
