/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'sso-policy';
const componentName = 'sso-policy-list';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleAuthProfile = `${prefix}.columnTitleAuthProfile`;

export const ENGLISH = {
    [headerLabel]: 'SSO Policy',
    [columnTitleAuthProfile]: 'Auth Profile',
};

