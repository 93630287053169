/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * Used for downgrading collections used in Cloud Module
 * @module CloudModule
 */

import { Injector } from '@angular/core';

import {
    SEGroupCollection,
    SE_GROUP_COLLECTION_TOKEN,
} from 'ajs/modules/service-engine-group';

import { OpenstackNetworkCollection } from
    'ajs/modules/cloud/factories/openstack-network.collection.factory';

import { OPEN_STACK_NETWORK_COLLECTION_TOKEN } from
    'ajs/modules/cloud/cloud.tokens';

import { LicenseBasedFeaturesService } from 'ajs/modules/core/services';
import { LICENSE_BASED_FEATURES_SERVICE_TOKEN } from 'ajs/modules/core/core.tokens';
import { RoleCollection } from 'ajs/modules/accounts/factories/role/role.collection.factory';
import { ROLE_COLLECTION_TOKEN } from 'ajs/modules/accounts/accounts.tokens';

const $injector = '$injector';

export const seGroupCollectionProvider = {
    deps: [$injector],
    provide: SEGroupCollection,
    useFactory(injector: Injector): typeof SEGroupCollection {
        return injector.get(SE_GROUP_COLLECTION_TOKEN);
    },
};

export const licenseBasedFeaturesServiceProvider = {
    deps: [$injector],
    provide: LicenseBasedFeaturesService,
    useFactory(injector: Injector): LicenseBasedFeaturesService {
        return injector.get(LICENSE_BASED_FEATURES_SERVICE_TOKEN);
    },
};

export const openStackNetworkCollectionProvider = {
    deps: [$injector],
    provide: OpenstackNetworkCollection,
    useFactory(injector: Injector): typeof OpenstackNetworkCollection {
        return injector.get(OPEN_STACK_NETWORK_COLLECTION_TOKEN);
    },
};

export const roleCollectionProvider = {
    deps: [$injector],
    provide: RoleCollection,
    useFactory(injector: Injector): typeof RoleCollection {
        return injector.get(ROLE_COLLECTION_TOKEN);
    },
};
