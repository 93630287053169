/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Item } from 'ajs/modules/data-model/factories/item.factory';
import { IUpgradeStatusInfo } from 'generated-types';
import {
    IUpgradeStatusEssential,
    withUpgradeStatusMixin,
} from '../mixins';

interface IRespData {
    config: IRespDataConfig;
}

interface IRespDataConfig {
    uuid: string;
    url?: string;
}

/**
 * @desc
 *
 *      UpgradeNode item holding all the relevant upgrade information related to a node in the
 *      system; mapping to UpgradeStatusInfo in protobuf.
 *
 *      Node types include:
 *      1. NODE_CONTROLLER_CLUSTER (controller node)
 *      2. NODE_SE_GROUP (service engine group node)
 *      2. NODE_SE_TYPE (service engine node)
 *
 * @author Zhiqian Liu
 */
export class UpgradeNodeBase extends Item implements IUpgradeStatusEssential {
    /**
     * @override
     * Make uuid a fallback for fetching id if url is not present. This is due to that url will not
     * be present in the resp of upgradestatusinfo api for tiny-portal, uuid is there whenever
     * there's upgradestatusinfo response despite tiny-portal.
     */
    // eslint-disable-next-line no-underscore-dangle
    public getIdFromData_(data: IRespData): string | null {
        if (data.config) {
            const { uuid, url } = data.config;

            if (url) {
                return url.slug();
            } else {
                return uuid || null;
            }
        }

        return null;
    }

    /**
     * @override
     */
    public getUpgradeStatusConfig(): IUpgradeStatusInfo {
        return this.getConfig();
    }

    /**
     * @override
     */
    public getCloudRef(): string {
        const { obj_cloud_ref: cloudRef } = this.getUpgradeStatusConfig();

        return cloudRef;
    }
}

// semantic use only, can't be used as a top level object type to fetch data
UpgradeNodeBase.prototype.objectName = 'upgradenode';

export const UpgradeNode = withUpgradeStatusMixin(UpgradeNodeBase);

// Export instance type ITUpgradeNode for type annotation use only due to the fact that UpgradeNode
// class can't be used that way since it's a generated intermidiate class and it works as a value.
export type ITUpgradeNode = InstanceType<typeof UpgradeNode>;
