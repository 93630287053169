/**
 * @module AutoScaleModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Type,
} from '@angular/core';

import { Webhook } from 'object-types';
import { AviPermissionResource } from 'generated-types';
import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';
import { withFullModalMixin } from 'ajs/js/utilities/mixins/with-full-modal.mixin';

/**
 * @description Webhook Item.
 *
 * @author Nitesh Kesarkar
 */
export class WebhookItem extends withFullModalMixin(ObjectTypeItem) {
    constructor(args = {}) {
        const extendedArgs = {
            objectName: 'webhook',
            objectType: Webhook,
            permissionName: AviPermissionResource.PERMISSION_POOL,
            windowElement: 'lazy-load',
            ...args,
        };

        super(extendedArgs);
    }

    /* eslint-disable-next-line class-methods-use-this */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            WebhookModalComponent,
        } = await import(
            /* webpackChunkName: "webhook-modal" */
            'ng/lazy-loaded-components/modals/webhook-modal/webhook-modal.component'
        );

        return WebhookModalComponent as Type<Component>;
    }
}
