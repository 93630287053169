/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'datascript-policy';
const prefix = `${moduleName}.${componentName}`;

export const scriptToExecuteInputLabel = `${prefix}.scriptToExecuteInputLabel`;
export const emptyInputPlaceholder = `${prefix}.emptyInputPlaceholder`;
export const createDataScriptButtonLabel = `${prefix}.createDataScriptButtonLabel`;
export const dataScriptsHeader = `${prefix}.dataScriptsHeader`;
export const addDataScriptButtonLabel = `${prefix}.addDataScriptButtonLabel`;
export const editActionLabel = `${prefix}.editActionLabel`;
export const deleteActionLabel = `${prefix}.deleteActionLabel`;
export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleIndex = `${prefix}.columnTitleIndex`;

export const ENGLISH = {
    [scriptToExecuteInputLabel]: 'Script To Execute',
    [emptyInputPlaceholder]: 'None',
    [createDataScriptButtonLabel]: 'Create DataScript',
    [dataScriptsHeader]: 'DataScripts',
    [addDataScriptButtonLabel]: 'Add DataScript',
    [editActionLabel]: 'Edit',
    [deleteActionLabel]: 'Delete',
    [columnTitleName]: 'Name',
    [columnTitleIndex]: 'Index',
};
