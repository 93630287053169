/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module FullModalModule
 */

import {
    Inject,
    Injectable,
    OnDestroy,
} from '@angular/core';

import { IHttpPromise } from 'angular';

import {
    HttpMethod,
    HttpWrapper,
} from 'ajs/modules/core/factories/http-wrapper';

import { IObjectGraphApiResponse } from './referenced-objects-graph.types';

/**
 * Type of Http wrapper.
 */
type THttpWrapper = typeof HttpWrapper;

/**
 * Fetch objects graph request id.
 */
const FETCH_OBJECTS_GRAPH_REQUEST_ID = 'fetch-objects-graph-request-id';

/**
 * Base url for object graph refers to api.
 */
const OBJECT_GRAPH_BASE_URL = '/api/object-graph?refers_to=';

/**
 * @description
 *      Referenced object graph service.
 *
 * @author Rajawant Prajapati
 */
@Injectable()
export class ReferencedObjectsGraphService implements OnDestroy {
    /**
     * HttpWrapper instance to make HTTP Requests.
     */
    private readonly httpWrapper: HttpWrapper;

    constructor(
    @Inject(HttpWrapper)
    HttpWrapper: THttpWrapper,
    ) {
        this.httpWrapper = new HttpWrapper();
    }

    /**
     * Method to fetch the list of objects in which the current item is being referenced.
     */
    public fetchObjectGraphs(
        objectName: string,
        uuid: string,
    ): IHttpPromise<IObjectGraphApiResponse> {
        const requestConfig = {
            url: `${OBJECT_GRAPH_BASE_URL + objectName}:${uuid}&fields=name`,
            method: HttpMethod.GET,
            requestId: FETCH_OBJECTS_GRAPH_REQUEST_ID,
        };

        return this.httpWrapper.request(requestConfig);
    }

    /**
     * Cancel the pending request.
     * @override
     */
    public ngOnDestroy(): void {
        this.httpWrapper.cancelRequest(FETCH_OBJECTS_GRAPH_REQUEST_ID);
    }
}
