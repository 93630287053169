/**
 * @module IpamModule
 * @preferred
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    CustomIpamDnsCollection,
    CustomIpamDnsProfile,
    CustomIpamSubnetConfigItem,
    InfobloxSubnetConfigItem,
    IpamDnsAwsProfileConfigItem,
    IpamDnsAzureProfileConfigItem,
    IpamDnsCustomProfileConfigItem,
    IpamDnsInfobloxProfileConfigItem,
    IpamDnsInternalProfileConfigItem,
    IpamProfileCollection,
    IpamProfileItem,
} from '.';

import {
    CUSTOM_IPAM_PROFILE_COLLECTION_TOKEN,
    CUSTOM_IPAM_PROFILE_ITEM_TOKEN,
    CUSTOM_IPAM_SUBNET_CONFIG_ITEM_TOKEN,
    INFOBLOX_SUBNET_CONFIG_ITEM_TOKEN,
    IPAM_DNS_AWS_PROFILE_CONFIG_ITEM_TOKEN,
    IPAM_DNS_AZURE_PROFILE_CONFIG_ITEM_TOKEN,
    IPAM_DNS_CUSTOM_PROFILE_CONFIG_ITEM_TOKEN,
    IPAM_DNS_INFOBLOX_PROFILE_CONFIG_ITEM_TOKEN,
    IPAM_DNS_INTERNAL_PROFILE_CONFIG_ITEM_TOKEN,
    IPAM_PROFILE_COLLECTION_TOKEN,
    IPAM_PROFILE_ITEM_TOKEN,
} from './ipam.tokens';

const ipamModule = angular.module('avi/ipam');

const factories = [
    {
        dependency: IpamProfileCollection,
        name: IPAM_PROFILE_COLLECTION_TOKEN,
    },
    {
        dependency: CustomIpamDnsProfile,
        name: CUSTOM_IPAM_PROFILE_ITEM_TOKEN,
    },
    {
        dependency: CustomIpamDnsCollection,
        name: CUSTOM_IPAM_PROFILE_COLLECTION_TOKEN,
    },
    {
        dependency: IpamProfileItem,
        name: IPAM_PROFILE_ITEM_TOKEN,
    },
    {
        dependency: IpamDnsInfobloxProfileConfigItem,
        name: IPAM_DNS_INFOBLOX_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: InfobloxSubnetConfigItem,
        name: INFOBLOX_SUBNET_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: IpamDnsInternalProfileConfigItem,
        name: IPAM_DNS_INTERNAL_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: IpamDnsAwsProfileConfigItem,
        name: IPAM_DNS_AWS_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: IpamDnsAzureProfileConfigItem,
        name: IPAM_DNS_AZURE_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: IpamDnsCustomProfileConfigItem,
        name: IPAM_DNS_CUSTOM_PROFILE_CONFIG_ITEM_TOKEN,
    },
    {
        dependency: CustomIpamSubnetConfigItem,
        name: CUSTOM_IPAM_SUBNET_CONFIG_ITEM_TOKEN,
    },
];

factories.forEach(({ name, dependency }) => {
    initAjsDependency(ipamModule, 'factory', name, dependency);
});
