/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Component,
    OnDestroy,
    OnInit,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import * as globalL10n from 'global-l10n';

import { Subscription } from 'rxjs';
import { ILabelData } from 'ng/modules/diagram/components';
import { VsLogsGraphStore } from './vs-logs-graph.store';
import { VsLogsStore } from '../../services/vs-logs.store';
import { VsLogsReloadService } from '../../services/vs-logs-reload.service';
import { TVsLogsGraphStateParams } from '../../vs-logs.types';
import * as l10n from './vs-logs-graph.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 * Main graph for vs logs page.
 * This component houses the bar-graph at top of vs-logs page, gotten from /logs/analytics
 * api when request param 'step' is provided. Each bar represents number of logs (y-axis)
 * for a given time period (x-axis), for a given duration (entire x-axis).
 * Graph is made by child component avi-bar-graph, this component basically
 * just handles api calls and passing in data to avi-bar-graph.
 * @author Akul Aggarwal, Alex Klyuev
 */
@Component({
    selector: 'vs-logs-graph',
    templateUrl: './vs-logs-graph.component.html',
    providers: [VsLogsGraphStore],
})
export class VsLogsGraphComponent implements OnInit, OnDestroy {
    public readonly globalL10nKeys = globalL10nKeys;

    public readonly l10nKeys = l10nKeys;

    public readonly vsLogsGraphColors = this.vsLogsGraphStore.vsLogsGraphColors;

    public readonly legendList: ILabelData[] = [
        {
            hasLeftColor: true,
            color: this.vsLogsGraphColors.nonSignificant.color,
            label: this.vsLogsGraphColors.nonSignificant.label,
        }, {
            hasLeftColor: true,
            color: this.vsLogsGraphColors.significant.color,
            label: this.vsLogsGraphColors.significant.label,
        },
    ];

    /** Unique label addendum to show for summary row inside tooltip. */
    public tooltipLogsLabel: string;

    /**
     * Sets subscription to begin API calls.
     */
    private readonly logsGraphApiParamsSubscription: Subscription =
    this.vsLogsStore.vsLogsGraphApiParams$.subscribe((apiParams: TVsLogsGraphStateParams) => {
        this.vsLogsGraphStore.getVsLogsGraphData(apiParams);
    });

    constructor(
        public readonly vsLogsGraphStore: VsLogsGraphStore,
        public readonly vsLogsStore: VsLogsStore,
        public readonly l10nService: L10nService,
        vsLogsReloadService: VsLogsReloadService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        // Subscribe to Reload service for refresh graph action.
        vsLogsReloadService.reload$.subscribe(() => vsLogsGraphStore.reloadGraph());
    }

    /** @override */
    public ngOnInit(): void {
        this.tooltipLogsLabel = this.l10nService.getMessage(globalL10nKeys.logsLabel);
    }

    /** @override */
    public ngOnDestroy(): void {
        this.logsGraphApiParamsSubscription.unsubscribe();
    }
}
