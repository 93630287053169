/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-section-security';
const prefix = `${moduleName}.${componentName}`;

export const wafLabel = `${prefix}.wafLabel`;
export const icapLabel = `${prefix}.icapLabel`;
export const oneActionLabel = `${prefix}.oneActionLabel`;
export const actionsLabel = `${prefix}.actionsLabel`;
export const oneViolationLabel = `${prefix}.oneViolationLabel`;
export const violationsLabel = `${prefix}.violationsLabel`;

export const ENGLISH = {
    [wafLabel]: 'WAF',
    [icapLabel]: 'ICAP ({0}, {1})',
    [oneActionLabel]: '1 Action',
    [actionsLabel]: '{0} Actions',
    [oneViolationLabel]: '1 Violation',
    [violationsLabel]: '{0} Violations',
};
