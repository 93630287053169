/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { IAuthAttributeMatch } from 'generated-types';
import { AuthAttributeMatch } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { StringMatchConfigItem } from './string-match.config-item.factory';

type TAuthAttributeMatchPartial = Omit<IAuthAttributeMatch, 'attribute_value_list'>;

interface IAuthAttributeMatchConfig extends TAuthAttributeMatchPartial {
    attribute_value_list?: StringMatchConfigItem;
}

/**
 * @description AuthAttributeMatch ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class AuthAttributeMatchConfigItem extends MessageItem<IAuthAttributeMatchConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: AuthAttributeMatch,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter function for the config to override the message default.
     */
    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IAuthAttributeMatchConfig> {
        return {
            attribute_value_list: null,
        };
    }

    /**
     * Getter for attribute_value_list.
     */
    public get attributeValueList(): StringMatchConfigItem {
        return this.config.attribute_value_list;
    }
}
