/**
 * Module containing CloudConnectorUser components.
 * @preferred
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ClrFormsModule,
    ClrInputModule,
    ClrRadioModule,
    ClrTextareaModule,
} from '@clr/angular';

import { SharedModule } from 'ng/shared/shared.module';
import { AviFormsModule } from 'ng/modules/avi-forms/avi-forms.module';
import { DataGridModule } from 'ng/modules/data-grid/data-grid.module';
import { cloudConnectorUserCollectionProvider } from './ajs-upgraded-providers';

import {
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsListPageComponent,
    UserCredentialsModalComponent,
    VCenterCredentialsComponent,
} from '.';

const cloudConnectorUserComponents = [
    GcpCredentialsComponent,
    NsxtCredentialsComponent,
    UserCredentialsModalComponent,
    UserCredentialsListPageComponent,
    VCenterCredentialsComponent,
];

@NgModule({
    declarations: [
        ...cloudConnectorUserComponents,
    ],
    imports: [
        AviFormsModule,
        CommonModule,
        FormsModule,
        SharedModule,
        ClrInputModule,
        ClrRadioModule,
        ClrTextareaModule,
        ClrFormsModule,
        DataGridModule,
    ],
    providers: [
        cloudConnectorUserCollectionProvider,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class CloudConnectorUserModule {}
