/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'analytics';
const componentName = 'analytics-profile-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [headerLabel]: 'Analytics Profile',
};
