/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
} from '@angular/core';
import { VsLogsStore } from '../../services/vs-logs.store';
import { FilterOperatorType } from '../../vs-logs.types';

import './vs-log-filter.directive.less';

/**
 * @description
 *      Directive to style a clickable filter and add it to store upon click.
 * @author Zhiqian Liu, Alex Klyuev
 */
@Directive({
    selector: '[vsLogFilter]',
})
export class VsLogFilterDirective {
    /**
     * Property, operator, and value for a filter object.
     * See TFilterObj for reference.
     */
    @Input()
    public property: string;

    @Input()
    public operator = FilterOperatorType.EQUAL_TO;

    @Input()
    public value: string | number | boolean;

    /**
     * Event emitter for when filter is added.
     */
    @Output()
    public onFilterAdd = new EventEmitter<void>();

    constructor(
        elementRef: ElementRef,
        renderer: Renderer2,
        private readonly vsLogsStore: VsLogsStore,
    ) {
        renderer.addClass(elementRef.nativeElement, 'vs-log-filter');
    }

    /**
     * Add filter to store upon click.
     */
    @HostListener('click')
    public addFilter(): void {
        this.vsLogsStore.addFilter({
            property: this.property,
            operator: this.operator,
            value: this.value,
        });

        this.onFilterAdd.emit();
    }
}
