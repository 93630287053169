/**
 * Module containing Policy components.
 * @preferred
 * @module PolicyModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SharedModule } from 'ng/shared/shared.module';

import {
    PolicyRuleComponent,
    PolicyRuleListComponent,
    PolicyRuleSummaryComponent,
} from './components';

const policyComponents = [
    PolicyRuleComponent,
    PolicyRuleListComponent,
    PolicyRuleSummaryComponent,
];

@NgModule({
    declarations: [
        ...policyComponents,
    ],
    exports: [
        PolicyRuleListComponent,
    ],
    imports: [
        CommonModule,
        DragDropModule,
        SharedModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class PolicyModule { }
