/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'service-port-controller';
const prefix = `${moduleName}.${componentName}`;

export const servicePortLabel = `${prefix}.servicePortLabel`;
export const isRadioLabel = `${prefix}.isRadioLabel`;
export const isNotRadioLabel = `${prefix}.isNotRadioLabel`;
export const portsLabel = `${prefix}.portsLabel`;
export const removeMatchTitle = `${prefix}.removeMatchTitle`;

export const ENGLISH = {
    [servicePortLabel]: 'Service Port',
    [isRadioLabel]: 'Is',
    [isNotRadioLabel]: 'Is not',
    [portsLabel]: 'Ports',
    [removeMatchTitle]: 'Remove Match',
};
