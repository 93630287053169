/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    INetworkProfile,
    IRoleFilterMatchLabel,
    ITCPProxyProfile,
    IUDPProxyProfile,
    ProtocolType,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    NetworkProfileUnionConfigItem,
    TCPFastPathProfileConfigItem,
    UDPFastPathProfileConfigItem,
} from '..';

/**
 * Interface for Network Profile Partial.
 */
type TNetworkProfilePartial =
    Omit<INetworkProfile, 'profile' | 'markers'>;

/**
 * Interface for Network Profile Config.
 */
export interface INetworkProfileConfig extends TNetworkProfilePartial {
    profile?: NetworkProfileUnionConfigItem;
    markers?: MessageItem<IRoleFilterMatchLabel>;
}

/**
 * Interface for  Network Profile Data.
 */
export interface INetworkProfileData {
    config: INetworkProfileConfig;
}

/**
 * Interface for all types of Network Profile config.
 */
export type TNetworkProfile = TCPFastPathProfileConfigItem
| MessageItem<ITCPProxyProfile>
| UDPFastPathProfileConfigItem
| MessageItem<IUDPProxyProfile>;

/**
 * Interface for Network Profile with Dsr config.
 */
export type INetworkProfileConfigWithDsr = TCPFastPathProfileConfigItem
| UDPFastPathProfileConfigItem;

/**
 * Enum of all available network profile types.
 */
export enum ProtocolTypes {
    PROTOCOL_TYPE_TCP_FAST_PATH = ProtocolType.PROTOCOL_TYPE_TCP_FAST_PATH,
    PROTOCOL_TYPE_TCP_PROXY = ProtocolType.PROTOCOL_TYPE_TCP_PROXY,
    PROTOCOL_TYPE_UDP_FAST_PATH = ProtocolType.PROTOCOL_TYPE_UDP_FAST_PATH,
    PROTOCOL_TYPE_UDP_PROXY = ProtocolType.PROTOCOL_TYPE_UDP_PROXY,
    PROTOCOL_TYPE_SCTP_FAST_PATH = ProtocolType.PROTOCOL_TYPE_SCTP_FAST_PATH,
    PROTOCOL_TYPE_SCTP_PROXY = ProtocolType.PROTOCOL_TYPE_SCTP_PROXY,
}
