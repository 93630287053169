/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Inject,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { DsrProfile } from 'object-types';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { ITEM_ID_TOKEN } from 'ng/shared/shared.constants';

import {
    DsrType,
    EncapType,
    IDsrProfile,
} from 'generated-types';

import './direct-server-return-settings.component.less';
import * as l10n from './direct-server-return-settings.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Direct server return component.
 *
 * @author Ratan Kumar
 */
@Component({
    selector: 'direct-server-return-settings',
    templateUrl: './direct-server-return-settings.component.html',
})
export class DirectServerReturnSettingsComponent {
    /**
     * Profile config type TNetworkProfile.
     */
    @Input()
    public editable: MessageItem<IDsrProfile>;

    /**
     * Object type for dsr profile.
     */
    public readonly objectType = DsrProfile;

    /**
     * Keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Will Hold Dsr type l2/l3. Enum options - DSR_TYPE_L2, DSR_TYPE_L3.
     */
    public dsrTypes: IAviDropdownOption[] = [];

    /**
     * Will Hold Encapsulation type to use when dsr is not l2. Enum options - ENCAP_IPINIP.
     */
    public dsrEncapsulationTypes: IAviDropdownOption[] = [];

    /**
     * True if the Network Profile Item is being edited, not created.
     */
    public readonly isEditing: boolean;

    /**
     * dsrTypeL2 will hold DSR_TYPE_L2's value from Dsrtype config .
     */
    private readonly dsrTypeL2 = DsrType.DSR_TYPE_L2;

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
        @Inject(ITEM_ID_TOKEN)
        itemId: string,
    ) {
        l10nService.registerSourceBundles(dictionary);

        const protocolDsrTypes = schemaService.getEnumValues('DsrType');
        const protocolDsrEncapTypes = schemaService.getEnumValues('EncapType');

        this.dsrTypes = createOptionsFromEnumProps(protocolDsrTypes);
        this.dsrEncapsulationTypes = createOptionsFromEnumProps(protocolDsrEncapTypes);

        this.isEditing = Boolean(itemId);
    }

    /**
     * Set DSR encapsulation to undefined for DSR type L2,
     * else set it to ENCAP_IPINIP.
     */
    public onDsrTypeSelect(type: DsrType): void {
        const { config } = this.editable;

        if (type === DsrType.DSR_TYPE_L2) {
            delete config.dsr_encap_type;
        } else {
            config.dsr_encap_type = EncapType.ENCAP_IPINIP;
        }
    }

    /**
     * Method will check if dsr Profile type is equal to
     * dsrTypeL2 option in dsrEncapTypes dropdown.
     */
    public get isDsrTypeL2(): boolean {
        const { dsr_type: dsrType } = this.editable.config;

        return dsrType === this.dsrTypeL2;
    }
}
