/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name LogRecord
 * @description
 *
 *     Log record Item.
 */
angular.module('aviApp').factory('LogRecord', ['Item',
function(Item) {
    class LogRecord extends Item {
        /** override */
        getIdFromData_({ config: record }) {
            return [
                record['service_engine'],
                record['vcpu_id'],
                LogRecord.getLogType_(record),
                record['log_id'],
                record['report_timestamp'],
            ].join('/');
        }

        /**
         * Returns the log record type.
         * @param {Object} record
         * @returns {string} - 'adf'/'udf'/'ndf'
         * @static
         * @inner
         */
        static getLogType_(record) {
            return 'adf' in record && 'adf' || 'udf' in record && 'udf' || 'ndf';
        }
    }

    LogRecord.prototype.objectName = 'analytics/logs';

    return LogRecord;
}]);
