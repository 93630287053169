/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

// Collection-grid constants
const COLL_GRID_MIN_COLUMN_WIDTH = 70;
const COLL_GRID_TABLE_HEADER_SELECTOR = '.header-table-cell';
const COLL_GRID_CHECKBOX_SELECTOR = '.selectable';
const COLL_ROWACTION_SELECTOR = '.rowactions';
const COLL_GRID_HEADER_SELECTOR = '.header-table-row';

export {
    COLL_GRID_MIN_COLUMN_WIDTH,
    COLL_GRID_TABLE_HEADER_SELECTOR,
    COLL_GRID_CHECKBOX_SELECTOR,
    COLL_GRID_HEADER_SELECTOR,
    COLL_ROWACTION_SELECTOR,
};
