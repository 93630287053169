/**
 * Module containing scripts related components.
 * @preferred
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import angular from 'angular';
import { initAjsDependency } from 'ajs/js/utilities/ajsDependency';

import {
    ControlScript,
    ControlScriptCollection,
    DataScriptSet,
    DataScriptSetCollection,
    ProtocolParser,
    ProtocolParserCollection,
    PROTOCOL_PARSER_COLLECTION_TOKEN,
    PROTOCOL_PARSER_ITEM_TOKEN,
    RateLimiterConfigItem,
} from '.';

const scriptsModule = angular.module('avi/scripts');

const factories = [
    {
        factory: ControlScript,
        name: 'ControlScript',
    },
    {
        factory: ControlScriptCollection,
        name: 'ControlScriptCollection',
    },
    {
        factory: DataScriptSet,
        name: 'DataScriptSet',
    },
    {
        factory: DataScriptSetCollection,
        name: 'DataScriptSetCollection',
    },
    {
        factory: RateLimiterConfigItem,
        name: 'RateLimiterConfigItem',
    },
    {
        factory: ProtocolParser,
        name: PROTOCOL_PARSER_ITEM_TOKEN,
    },
    {
        factory: ProtocolParserCollection,
        name: PROTOCOL_PARSER_COLLECTION_TOKEN,
    },
];

factories.forEach(({ name, factory }) => {
    initAjsDependency(
        scriptsModule,
        'factory',
        name,
        factory,
    );
});
