/**
 * @module ErrorPageModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { ObjectTypeItem } from 'ajs/modules/data-model/factories/object-type-item.factory';
import { withFullModalMixin } from 'ajs/js/utilities/mixins';
import { AviPermissionResource, IErrorPageBody } from 'generated-types';
import { L10nService } from '@vmw/ngx-vip';
import { TWindowElement } from 'ajs/modules/data-model/data-model.types';

import {
    Component,
    Type,
} from '@angular/core';

import * as l10n from '../error-page.l10n';

interface IErrorPageBodyData {
    config: IErrorPageBody;
}

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description ErrorPageBody Item class.
 * @author Satish Pednekar
 */
export class ErrorPageBody extends withFullModalMixin(ObjectTypeItem) {
    public static ajsDependencies = [
        'l10nService',
    ];

    public data: IErrorPageBodyData;
    public getConfig: () => IErrorPageBody;

    private l10nService: L10nService;

    constructor(args: {}) {
        const extendedArgs = {
            objectName: 'errorpagebody',
            objectType: 'ErrorPageBody',
            windowElement: 'lazy-load',
            permissionName: AviPermissionResource.PERMISSION_ERRORPAGEBODY,
            ...args,
        };

        super(extendedArgs);

        this.l10nService = this.getAjsDependency_('l10nService');
        this.l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Method used to import lazy-loaded modal component.
     */
    public async getModalComponent(windowElement: TWindowElement): Promise<Type<Component>> {
        const {
            ErrorPageBodyModalComponent,
        } = await import(
            /* webpackChunkName: "error-page-body-modal" */
            'ng/lazy-loaded-components/modals/error-page-body-modal/error-page-body-modal.component'
        );

        return ErrorPageBodyModalComponent as Type<Component>;
    }

    /** @override */
    protected getModalBreadcrumbTitle(): string {
        return this.l10nService.getMessage(l10nKeys.errorPageBodyModalBreadcrumbTitle);
    }
}
