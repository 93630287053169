/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import {
    Component,
    Input,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IItemGenericConfig } from 'ajs/modules/data-model/factories/item.factory';
import * as l10n from './waf-policy-psm-group-modal-preview.l10n';
import './waf-policy-psm-group-modal-preview.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Waf policy psm group modal preview component.
 * @author Nitesh Kesarkar
 */
@Component({
    selector: 'waf-policy-psm-group-modal-preview',
    templateUrl: './waf-policy-psm-group-modal-preview.component.html',
})
export class WafPolicyPsmGroupModalPreviewComponent {
    @Input()
    public readonly config: IItemGenericConfig;

    public readonly l10nKeys = l10nKeys;

    constructor(private readonly l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }
}
