/**
 * @module CoreModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IQService,
} from 'angular';

import { AjsDependency } from 'ajs/js/utilities/ajsDependency';

interface IAsyncFactoryTimeoutConfig {
    maxSkipped: number;
    callback: (...args: any[]) => void;
}

/**
 * Token to be registered in Ajs.
 */
export const ASYNC_FACTORY_TOKEN = 'AsyncFactory';

/**
 * @description Factory allows for easy setup of polling.
 *
 * @author Alex Malitsky, Aravindh Nagarajan
 */
export class AsyncFactory extends AjsDependency {
    /**
     * Ajs $QService.
     * TODO: Replace $q with native promise.
     */
    private readonly $q: IQService;
    /**
     * Function which performs the polling.
     *
     * Must return a promise when the operation is complete.
     * Intervals will be skipped until this promise is resolved / rejected.
     */
    private readonly pollingFunction: (...args: any) => Promise<void>;
    /**
     * Max number of times polling is allowed to be skipped because
     * the pollingFunction's last promise hasnt yet resolved/rejected.
     *
     * When `this.skipped (an internal counter)` reaches (>=) `maxSkipped`,
     * we call timeoutCallback method..
     */
    private readonly maxSkipped: number;
    /**
     * Callback that is called when maxSkipped is reached.
     * Use this to cancel unresponsive async calls so polling can try again.
     */
    private readonly timeoutCallback: (...args: any) => void;
    /**
     * If a request is pending, it would be set to true.
     * Its used to make sure only one request can be made at a time.
     */
    private outstanding = false;
    /**
     * Stores the result of setInterval.
     * Used to clear the interval.
     */
    private intervalId: number = null;
    /**
     * Internal counter to track the number of times we skip the polling
     * because we have one pending promise.
     *
     * When it reaches maxSkipped value, we will call the timeout callback,
     * which would hopefully flush the pending requests.
     */
    private skipped = 0;

    constructor(
        pollingFunction: (...args: any) => Promise<void>,
        timeoutConfig = {} as unknown as IAsyncFactoryTimeoutConfig,
    ) {
        super();

        if (typeof pollingFunction !== 'function') {
            throw new Error('pollingFunction must be a function');
        }

        this.$q = this.getAjsDependency_('$q');
        this.pollingFunction = pollingFunction;
        this.maxSkipped = timeoutConfig.maxSkipped;
        this.timeoutCallback = timeoutConfig.callback;

        if (this.maxSkipped && !this.timeoutCallback || !this.maxSkipped && this.timeoutCallback) {
            throw new
            Error('Must define both timeoutCallback and maxSkipped, not one or the other');
        }

        if (this.timeoutCallback && typeof this.timeoutCallback !== 'function') {
            throw new Error('timeoutCallback must be a function');
        }
    }

    /**
     * Starts the polling, first pollingFunction is called immediately.
     * @param ms - Time between intervals in milliseconds, 0 makes request only once
     */
    public start(ms: number): void {
        if (!Number.isInteger(ms)) {
            throw new Error('start(ms) - ms must be an integer');
        }

        if (this.intervalId) {
            throw new Error('Must call stop before starting again');
        }

        if (ms !== 0) {
            this.intervalId = setInterval(() => {
                this.repeated();
            }, ms);
        }

        this.repeated(this.intervalId);
    }

    /**
     * Stops the polling, any pending callbacks must be canceled by the
     * caller.
     */
    public stop(): void {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.skipped = 0;
        this.outstanding = false;
    }

    /**
     * Returns true when asyncFactory is running.
     */
    public isActive(): boolean {
        return !!this.intervalId;
    }

    /**
     * Checks if given object looks like a promise
     * @return Throws if not promise like, otherwise just exits
     */
    private promiseLike(promise: any): void {
        if (!(promise && promise.finally && typeof promise.finally === 'function')) {
            throw new Error('Function must return a promise');
        }
    }

    /**
     * Private function that checks if the previous pollingFunction has
     * completed before calling it again. If maxSkipped has been reached,
     * timeoutCallback is called
     * @param intervalId - intervalId or null when there is no intervalId set.
     */
    private repeated = (intervalId?: number | null): void => {
        intervalId = intervalId || this.intervalId;

        if (this.outstanding) {
            if (this.maxSkipped && this.maxSkipped <= this.skipped) {
                // Call callback passing empty object as context (instead of this object)
                this.timeoutCallback.apply(undefined, []);
            } else {
                this.skipped++;
            }

            // Not going to make another request until outstanding is false
            // It is the timeoutCallback's job to cancel the pending requests
            return;
        }

        // Call callback passing empty object as context (instead of this object)
        let promise: Promise<void> | Array<Promise<void>> =
            this.pollingFunction.apply(undefined, []);

        if (!Array.isArray(promise)) {
            promise = [promise];
        }

        // Check each object is a promise
        promise.forEach((p: Promise<void>) => this.promiseLike(p));

        this.$q.all(promise)
            .finally(() => {
                // If we've already started a new interval it doesn't make sense to update
                // parameters by rejected or resolved promise of the previous interval
                // polling function.
                if (!this.intervalId || intervalId === this.intervalId) {
                    this.skipped = 0;
                    this.outstanding = false;
                }
            });

        this.outstanding = true;
    };
}

AsyncFactory.ajsDependencies = [
    '$q',
];
