/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

function vsInventoryDataTransformerFactory(
    InventoryDataTransformer,
    itemAlertDataTransform,
    FaultService,
    OperStateToTypeMap,
) {
    /**
     * @class VSInventoryDataTransformer
     * @extends module:avi/dataModel.RevisedDataTransformer
     * @desc
     *
     *     Parses response faults, alerts and modifies search param value to search through
     *     multiple fields.
     *
     * @author Alex Malitsky
     */
    class VSInventoryDataTransformer extends InventoryDataTransformer {
        /** @override */
        processResponse(resp, request) {
            resp = this.responseListOffsetControl_(resp, request);

            const { data: { results } } = resp;

            if (Array.isArray(results)) {
                results.forEach(result => {
                    if ('faults' in result) {
                        result.faults = FaultService.parseFaults(result.faults);
                    }

                    if ('alert' in result) {
                        itemAlertDataTransform(result.alert);
                    }

                    const { runtime } = result;

                    if (runtime) {
                        const { vip_summary: vipSummary } = runtime;

                        if (Array.isArray(vipSummary)) {
                            vipSummary.forEach(vipSummaryItem => {
                                const { oper_status: { state } } = vipSummaryItem;

                                vipSummaryItem.operStateType = OperStateToTypeMap.get(state);
                            });
                        }
                    }
                });
            }

            return resp;
        }
    }

    return VSInventoryDataTransformer;
}

vsInventoryDataTransformerFactory.$inject = [
    'InventoryDataTransformer',
    'itemAlertDataTransform',
    'FaultService',
    'OperStateToTypeMap',
];

angular.module('aviApp')
    .factory('VSInventoryDataTransformer', vsInventoryDataTransformerFactory);
