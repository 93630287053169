/**
 * @module HealthMonitorModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    HealthMonitor,
    HealthMonitorDNS,
} from 'object-types';

import {
    DnsRecordType,
    IHealthMonitorDNS,
} from 'generated-types';

import { L10nService } from '@vmw/ngx-vip';
import { HealthMonitorItem } from 'ajs/modules/healthmonitor/factories/health-monitor-item.factory';
import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import * as globalL10n from 'global-l10n';
import * as l10n from './dns-health-monitor-config.l10n';
import './dns-health-monitor-config.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Health Monitor Config Component for DNS Health Monitor Type.
 *
 * @author Rajawant Prajapati
 */
@Component({
    selector: 'dns-health-monitor-config',
    templateUrl: './dns-health-monitor-config.component.html',
})
export class DnsHealthMonitorConfigComponent {
    /**
     * Health Monitor ObjectTypeItem instance.
     */
    @Input()
    public editable: HealthMonitorItem;

    /**
     * Object types used in template.
     */
    public readonly objectTypes = {
        HealthMonitor,
        HealthMonitorDNS,
    };

    /**
     * List of hidden DNS record types.
     */
    public readonly hiddenDnsRecordTypes = [
        DnsRecordType.DNS_RECORD_ANY,
        DnsRecordType.DNS_RECORD_AXFR,
        DnsRecordType.DNS_RECORD_CNAME,
        DnsRecordType.DNS_RECORD_DNSKEY,
        DnsRecordType.DNS_RECORD_HINFO,
        DnsRecordType.DNS_RECORD_MX,
        DnsRecordType.DNS_RECORD_NS,
        DnsRecordType.DNS_RECORD_OPT,
        DnsRecordType.DNS_RECORD_OTHER,
        DnsRecordType.DNS_RECORD_PTR,
        DnsRecordType.DNS_RECORD_RP,
        DnsRecordType.DNS_RECORD_RRSIG,
        DnsRecordType.DNS_RECORD_SOA,
        DnsRecordType.DNS_RECORD_SRV,
        DnsRecordType.DNS_RECORD_TXT,
    ];

    /**
     * For template usage.
     */
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Getter for DNS Health Monitor message item.
     */
    public get dnsMonitor(): MessageItem<IHealthMonitorDNS> {
        const { dnsMonitor } = this.editable;

        return dnsMonitor;
    }
}
