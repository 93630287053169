/**
 * @module HttpModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';

import { Auth } from 'ajs/modules/core/services/auth';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Constant for 'reload-permissions' header.
 */
const RELOAD_PERMISSIONS = 'reload-permissions';

/**
 * @description
 *      Interceptor to look for `reload-permissions` in response headers,
 *      if found reloads user permissions.
 *
 * @author Aravindh Nagrajan
 */
@Injectable()
export class ProcessReloadPermissionHeaderInterceptor implements HttpInterceptor {
    constructor(private readonly authService: Auth) {}

    /** @override */
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req)
            .pipe(
                tap((event: HttpEvent<any>): void => {
                    if (event instanceof HttpResponse) {
                        const { headers } = event;

                        if (headers.has(RELOAD_PERMISSIONS)) {
                            const reloadPermissionsKey = headers.get(RELOAD_PERMISSIONS);

                            this.processReloadPermissionsHeader(reloadPermissionsKey);
                        }
                    }
                }),
            );
    }

    /**
     * Checks whether current reloadPermissionsKey is unaddressed, if its unaddressed,
     * triggers Auth#updateReloadPermissionsRequestHash call.
     */
    private processReloadPermissionsHeader(reloadPermissionsKey: string): void {
        // Should reload context if true.
        const unaddressedReloadRequest =
            Auth.isNewReloadPermissionsRequest(reloadPermissionsKey);

        if (unaddressedReloadRequest) {
            // we mark key as processed right away so that other HTTP calls
            // won't cause multiple reloadUserProfile calls simultaneously for the same value
            Auth.updateReloadPermissionsRequestHash(reloadPermissionsKey, true);

            this.authService.reloadUserProfileAndPermissions()
                .catch(() => {
                    Auth.updateReloadPermissionsRequestHash(reloadPermissionsKey, false);
                });
        }
    }
}
