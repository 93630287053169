/** @module AviFormsModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @desc Parser for IpAddrPrefix message items as the ngModel.
 * @author alextsg
 */

import {
    isNull,
    isUndefined,
} from 'underscore';

import {
    Directive,
    ElementRef,
    forwardRef,
    HostListener,
    Input,
    Provider,
    Renderer2,
} from '@angular/core';

import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import {
    IpAddrPrefixConfigItem,
} from 'ajs/modules/data-model/factories/ip-addr-prefix.config-item.factory';
import { regexPatternValidator } from '../../validators/regex-pattern/regex-pattern.validator';

const PARSE_IP_ADDR_PREFIX_MESSAGE_ITEM_DIRECTIVE_ACCESSOR: Provider = {
    multi: true,
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ParseIpAddrPrefixMessageItemDirective),
};

const PARSE_IP_ADDR_PREFIX_MESSAGE_ITEM_DIRECTIVE_VALIDATOR: Provider = {
    multi: true,
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => ParseIpAddrPrefixMessageItemDirective),
};

@Directive({
    selector: '[parseIpAddrPrefixMessageItem]',
    providers: [
        PARSE_IP_ADDR_PREFIX_MESSAGE_ITEM_DIRECTIVE_ACCESSOR,
        PARSE_IP_ADDR_PREFIX_MESSAGE_ITEM_DIRECTIVE_VALIDATOR,
    ],
})
export class ParseIpAddrPrefixMessageItemDirective implements ControlValueAccessor, Validator {
    /**
     * Setter for required attribute.
     */
    @Input('required')
    private set setRequired(required: boolean | '') {
        this.required = required === '' || Boolean(required);
    }

    /**
     * Reference of the IpAddrPrefixConfigItem instance. This should be set as the ngModel by the
     * consumer and is required - we cannot simply create a new instance here because the instance
     * contains properties needed by the messageMap to properly create the save payload for the
     * ObjectTypeItem.
     */
    private ipAddrPrefixMessageItem: IpAddrPrefixConfigItem;

    /**
     * Required flag. This is needed for this directive since control.value is always populated with
     * the IpAddrPrefixConfigItem message item so the typical required attribute won't be able to
     * validate the input.
     */
    private required = false;

    constructor(
        private readonly renderer: Renderer2,
        private readonly elementRef: ElementRef,
    ) {}

    /**
     * Listener for the input change event.
     * Sets the subnet value and calls onChange to proceed with the new model value.
     */
    @HostListener('input', ['$event.target.value'])
    private onInputChange(value: string): void {
        this.ipAddrPrefixMessageItem.subnet = value;
        this.onChange(this.ipAddrPrefixMessageItem);
    }

    /**
     * Writes the input field display value with ipAddrPrefixConfigItem subnet string.
     */
    public writeValue(ipAddrPrefixConfigItem: IpAddrPrefixConfigItem): void {
        // Initial value is null.
        if (isNull(ipAddrPrefixConfigItem)) {
            return;
        }

        this.ipAddrPrefixMessageItem = ipAddrPrefixConfigItem;

        const { nativeElement: hostElement } = this.elementRef;
        const { subnet } = this.ipAddrPrefixMessageItem;
        const viewValue = isUndefined(subnet) ? '' : subnet;

        this.renderer.setProperty(hostElement, 'value', viewValue);
    }

    /**
     * Sets the onChange function.
     */
    public registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    /**
     * Sets the onTouched function.
     */
    public registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    /***************************************************************************
     * IMPLEMENTING Validator INTERFACE
    */

    /**
     * To validate the subnet input value. We want to validate the viewValue since the modelValue
     * (IpAddrPrefixMessageItem.subnet) can be undefined if invalid.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        const { nativeElement: hostElement } = this.elementRef;
        const { value: viewValue } = hostElement;

        // If the value doesn't exist, need to check if the required attribute is set.
        if (viewValue === '') {
            return this.required ? { required: { value: true } } : null;
        }

        return regexPatternValidator('anySubnet')(control);
    }

    /*************************************************************************/

    /**
     * Method to be overridden by the ControlValueAccessor interface.
     *
     * This method will be used to update ngModel value when user
     * changes input value.
     */
    private onChange = (value: IpAddrPrefixConfigItem): void => {};

    /**
     *  Method to be overridden by the ControlValueAccessor interface.
     */
    private onTouch: () => void = () => {};
}
