/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsVipModule
 */

import {
    AfterViewInit,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';

import {
    IAviCollectionDataGridConfig,
    IAviCollectionDataGridRow,
} from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';

import { StateService } from '@uirouter/core';
import { StringService } from 'ajs/modules/core/services/string-service/string.service';
import { IAviDropdownOption } from 'ng/shared/components/avi-dropdown/avi-dropdown.types';
import { VsVipCollection } from 'ajs/modules/vs/factories/vs-vip.collection.factory';

import * as globalL10n from 'global-l10n';
import * as l10n from './vs-vip-list-page.l10n';
import './vs-vip-list-page.component.less';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TVsVipCollection = typeof VsVipCollection;

/**
 * Enum for vs vip filter dropdown options.
 */
const enum vsvipFilterValues {
    ALL = 'all_vips',
    UNUSED = 'unused_vips',
    USED = 'used_vips',
}

/**
 * param value for vs vip collection filter: in use
 */
const USED_VS_FILTER = 'virtualservice:any';

/**
 * param value for vs vip collection filter: un used
 */
const UNUSED_VS_FILTER = 'virtualservice:none';

/**
 * @description Component for the vs vip list page.
 * @author Hitesh Mandav
 */
@Component({
    selector: 'vs-vip-list-page',
    templateUrl: './vs-vip-list-page.component.html',
})
export class VsVipListPageComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('vsVipListExpanderTemplate')
    public readonly vsVipListExpanderTemplate: TemplateRef<HTMLElement>;

    @ViewChild('vsAddressGridCellTemplate')
    public readonly vsAddressGridCellTemplate: TemplateRef<HTMLElement>;

    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public vsVipGridConfig: IAviCollectionDataGridConfig;

    public vsvipFilter: vsvipFilterValues = vsvipFilterValues.ALL;

    public readonly vsvipFilterOptions: IAviDropdownOption[];

    private readonly vsVipCollection: VsVipCollection;

    constructor(
        private readonly l10nService: L10nService,
        private readonly $state: StateService,
        private readonly stringService: StringService,
        @Inject(VsVipCollection)
        VsVipCollection: TVsVipCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.vsVipCollection = new VsVipCollection();
        this.vsvipFilterOptions = this.getVsVipFilterOptions();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.vsVipCollection;

        this.vsVipGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.vsVipCollection,
            getRowId: (index: number): number => index,
            defaultSorting: 'name',
            fields: [],
            layout: {
                placeholderMessage: this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
        };
    }

    /**
     * @override
     * Template Refs must be set after the view is initialized.
     */
    public ngAfterViewInit(): void {
        this.vsVipGridConfig = {
            ...this.vsVipGridConfig,
            fields: [
                {
                    id: 'name',
                    label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                    transform: (row: IAviCollectionDataGridRow) => row.getName(),
                    sortBy: 'name',
                },
                {
                    id: 'vip',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleAddress),
                    templateRef: this.vsAddressGridCellTemplate,
                }, {
                    id: 'vs_refs',
                    label: this.l10nService.getMessage(l10nKeys.columnTitleVirtualServicesCount),
                    transform: (row: IAviCollectionDataGridRow) =>
                        row.getConfig().vs_refs?.length || 0,
                }],
            expandedContentTemplateRef: this.vsVipListExpanderTemplate,
            expanderDisabled: (row: IAviCollectionDataGridRow) =>
                !row.getConfig() || !('vs_refs' in row.getConfig()),
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.vsVipCollection.destroy();
    }

    /**
     * Sets vsvip grid collection params on filter change.
     */
    public onFilterChange(): void {
        const { vsvipFilter } = this;

        // Default params to load all vsvips.
        const params: Record<string, string | undefined> = {
            referred_by: undefined,
        };

        switch (vsvipFilter) {
            case vsvipFilterValues.USED:
                params.referred_by = USED_VS_FILTER;
                break;

            case vsvipFilterValues.UNUSED:
                params.referred_by = UNUSED_VS_FILTER;
                break;
        }

        this.vsVipGridConfig.collection.setParams(params);
        this.vsVipGridConfig.collection.load();
    }

    /**
     * used to navigate to virtual service details page for given vsRef.
     */
    public navigate(vsRef: string): void {
        this.$state.go(
            'authenticated.application.virtualservice-detail.analytics',
            { vsId: this.stringService.slug(vsRef) },
        );
    }

    /**
     * trackBy Index Method.
     */
    public trackByIndex(index: number): number {
        return index;
    }

    /**
     * Returns vsVip filter options.
     */
    private getVsVipFilterOptions(): IAviDropdownOption[] {
        const { l10nService } = this;

        return [
            {
                label: l10nService.getMessage(l10nKeys.allVipsOptionLabel),
                value: vsvipFilterValues.ALL,
            }, {
                label: l10nService.getMessage(l10nKeys.unusedVipsOptionLabel),
                value: vsvipFilterValues.UNUSED,
            }, {
                label: l10nService.getMessage(l10nKeys.inUseVipsOptionLabel),
                value: vsvipFilterValues.USED,
            },
        ];
    }
}
