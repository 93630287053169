/**
 * @module CloudConnectorUserModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Collection } from 'ajs/modules/data-model/factories/collection.factory';

/**
 * Ajs dependency token for Cloud Connector User Collection
 */
export const CLOUD_CONNECTOR_USER_COLLECTION = 'CloudConnectorUserCollection';

export class CloudConnectorUserCollection extends Collection {
    constructor(args = {}) {
        const extendedArgs = {
            ...args,
            objectName: 'cloudconnectoruser',
            permissionName: 'PERMISSION_USER_CREDENTIAL',
            windowElement: 'user-credentials-modal',
        };

        super(extendedArgs);

        this.itemClass_ = this.getAjsDependency_('CloudConnectorUser');
    }
}

CloudConnectorUserCollection.ajsDependencies = [
    'CloudConnectorUser',
];
