/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AutoScaleModule
 */

import { Injector } from '@angular/core';
import { POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN } from 'ajs/modules/pool/pool.tokens';

import {
    WebhookCollection,
} from 'ajs/modules/auto-scale/factories/webhook/webhook.collection.factory';

import {
    AutoScalePolicyCollection,
} from 'ajs/modules/auto-scale/factories/auto-scale-policy.collection.factory';

import {
    AutoScaleLaunchConfigCollection,
} from 'ajs/modules/launch-config/factories/launch-config.collection.factory';

import {
    PoolGroupDeploymentPolicyCollection,
} from 'ajs/modules/pool/factories/pool-group-deployment-policy.collection.factory';

import {
    AUTO_SCALE_LAUNCH_CONFIG_COLLECTION_TOKEN,
} from 'ajs/modules/launch-config/launch-config.tokens';

import {
    AUTO_SCALE_POLICY_COLLECTION_TOKEN,
    WEBHOOK_COLLECTION_TOKEN,
} from 'ajs/modules/auto-scale/auto-scale.tokens';

const $injector = '$injector';

export const WebhookCollectionProvider = {
    deps: [$injector],
    provide: WebhookCollection,
    useFactory(injector: Injector): typeof WebhookCollection {
        return injector.get(WEBHOOK_COLLECTION_TOKEN);
    },
};

export const AutoScalePolicyCollectionProvider = {
    deps: [$injector],
    provide: AutoScalePolicyCollection,
    useFactory(injector: Injector): typeof AutoScalePolicyCollection {
        return injector.get(AUTO_SCALE_POLICY_COLLECTION_TOKEN);
    },
};

export const AutoScaleLaunchConfigCollectionProvider = {
    deps: [$injector],
    provide: AutoScaleLaunchConfigCollection,
    useFactory(injector: Injector): typeof AutoScaleLaunchConfigCollection {
        return injector.get(AUTO_SCALE_LAUNCH_CONFIG_COLLECTION_TOKEN);
    },
};

export const PoolGroupDeploymentPolicyCollectionProvider = {
    deps: [$injector],
    provide: PoolGroupDeploymentPolicyCollection,
    useFactory(injector: Injector): typeof PoolGroupDeploymentPolicyCollection {
        return injector.get(POOL_GROUP_DEPLOYMENT_POLICY_COLLECTION_TOKEN);
    },
};
