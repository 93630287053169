/**
 * @module avi/core
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    IEventMap,
    ISeUpgradeEvents,
    IUpgradeStatusInfo,
    NodeType,
    UpgradeFsmState,
} from 'generated-types';
import { Item } from 'ajs/modules/data-model/factories/item.factory';
import {
    IUpgradeNodeVersionHash,
    UpgradeNodeType,
    UpgradeState,
    UpgradeStateGroup,
} from 'ng/modules/update/upgrade.types';
import { UpgradeService } from '../services/upgrade.service';
import { Constructor } from '../../../declarations/globals.d';

export interface IUpgradeStatusEssential {
    getUpgradeStatusConfig(): IUpgradeStatusInfo;
    getCloudRef(): string;
}

/**
 * @description
 *     Mixin to extend a BaseClass with methods for feteching upgrade status.
 * @author Zhiqian Liu
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, require-jsdoc
export function withUpgradeStatusMixin<T extends Constructor<Item<any>>>(BaseClass: T) {
    return class UpgradeStatusItem extends BaseClass implements IUpgradeStatusEssential {
        public static ajsDependencies = [
            'upgradeService',
        ];
        public upgradeStateToStateGroupMap_: Map<UpgradeState, UpgradeStateGroup>;
        public getUpgradeStatusConfig: () => IUpgradeStatusInfo;
        public getCloudRef: () => string;

        /**
         * @override
         */
        constructor(...args: any[]) {
            super(...args);

            const upgradeService: UpgradeService = this.getAjsDependency_('upgradeService');

            this.upgradeStateToStateGroupMap_ = upgradeService.upgradeStateToStateGroupMap;
        }

        /**
         * Getter for the complete hash of all version info from the node.
         */
        protected get versionAsHash(): IUpgradeNodeVersionHash {
            const {
                version,
                patch_version: patchVersion,
            } = this.getUpgradeStatusConfig();

            return UpgradeService.getVersionAsHash(version, patchVersion);
        }

        /**
         * Getter for the complete hash of all previous version info from the node.
         */
        protected get previousVersionAsHash(): IUpgradeNodeVersionHash {
            const {
                previous_version: previousVersion,
                previous_patch_version: previousPatchVersion,
            } = this.getUpgradeStatusConfig();

            return UpgradeService.getVersionAsHash(previousVersion, previousPatchVersion);
        }

        /**
         * Getter for the major version of this node.
         * Example formats of major versions: '18.2.8', '20.1.1'.
         * @return {string}
         */
        public get majorVersion(): string {
            const { version } = this.versionAsHash;

            return version;
        }

        /**
         * Getter for the patch version of this node. Consisting of major patch version and minor
         * patch version.
         * Example format of patch versions (<major>p<minor>): '2p1', '3p2'.
         */
        public get patchVersion(): string {
            const { patchVersion } = this.versionAsHash;

            return patchVersion || '';
        }

        /**
         * Getter for the major patch version of this node.
         * If there exists patch version '2p1', then the '2' before the 'p' will be returned.
         */
        public get majorPatchVersion(): string {
            const { majorPatchVersion } = this.versionAsHash;

            return majorPatchVersion || '';
        }

        /**
         * Getter for the minor patch version of this node.
         * If there exists patch version '2p1', then the '1' after the 'p' will be returned.
         */
        public get minorPatchVersion(): string {
            const { minorPatchVersion } = this.versionAsHash;

            return minorPatchVersion || '';
        }

        /**
         * Getter for the previous major version of this node. This version won't exist if the
         * current version is not upgraded from an old version.
         * Expample formats of major versions: '18.2.8', '20.1.1'.
         */
        public get previousMajorVersion(): string {
            const { version } = this.previousVersionAsHash;

            return version || '';
        }

        /**
         * Getter for the previous patch version of this node. This version won't exist if there was
         * no patch applied.
         * Example formats of patch versions will be as '2p1', '3p2'.
         */
        public get previousPatchVersion(): string {
            const { patchVersion } = this.previousVersionAsHash;

            return patchVersion || '';
        }

        /**
         * Getter for type of the node.
         */
        public get nodeType(): NodeType {
            return this.getUpgradeStatusConfig().node_type;
        }

        /**
         * Getter for the latest upgrade starting time of the node.
         */
        public get upgradeStartTime(): string {
            const { start_time: startTime } = this.getUpgradeStatusConfig();

            // Tells interpreter that time is in GMT/UTC, remove if/when API returns timezone:
            // https://avinetworks.atlassian.net/browse/AV-80832
            return startTime ? `${startTime}+00:00` : '';
        }

        /**
         * Getter for the upgrade operation.
         */
        public get upgradeOps(): string {
            const { upgrade_ops: upgradeOps }: { upgrade_ops?: string } =
                this.getUpgradeStatusConfig();

            return upgradeOps && this.stringService.enumeration(upgradeOps) || '';
        }

        /**
         * Getter for cloud name of the node; applies to non-controller node only.
         */
        public get cloudName(): string {
            if (this.isNodeType(UpgradeNodeType.CONTROLLER)) {
                throw new Error(
                    `Can't fetch cloud name for node of type ${UpgradeNodeType.CONTROLLER}`,
                );
            }

            const cloudRef = this.getCloudRef();

            return this.stringService.name(cloudRef);
        }

        /**
         * Getter for the latest upgrade end time of the node.
         */
        public get upgradeEndTime(): string {
            const { end_time: endTime } = this.getUpgradeStatusConfig();

            return endTime || '';
        }

        /**
         * Getter for upgrade progress percentage.
         */
        public get progressPercentage(): number {
            const { progress } = this.getUpgradeStatusConfig();

            return typeof progress === 'number' ? progress : NaN;
        }

        /**
         * Getter for controller/se-group upgrade events list.
         */
        public get upgradeEvents(): IEventMap[] | ISeUpgradeEvents[] {
            const isControllerNode = this.isNodeType(UpgradeNodeType.CONTROLLER);

            const {
                upgrade_events: upgradeEvents,
                se_upgrade_events: seUpgradeEvents,
            } = this.getUpgradeStatusConfig();

            return isControllerNode ? upgradeEvents : seUpgradeEvents;
        }

        /**
         * Getter for upgrade state of this node.
         */
        public get state(): UpgradeFsmState {
            const { state: { state: upgradeState } } = this.getUpgradeStatusConfig();

            return upgradeState;
        }

        /**
         * Getter for upgrade state group of this node.
         */
        public get stateGroup(): UpgradeStateGroup {
            return this.upgradeStateToStateGroupMap_[this.state];
        }

        /**
         * Get current version of this node.
         * Example returned string format: (<majorVersion>-<patchVersion>): '20.1.1-2p1'.
         * When withBuild is true, expected returned string will be as format
         * <majorVersion>-<patchVersion>-<build>, ie. '20.1.1-2p1-5038'.
         * @param [withBuild=false] Whether to include build number.
         */
        public getVersion(withBuild = false): string {
            return UpgradeService.generateCompleteVersionFromHash(this.versionAsHash, withBuild);
        }

        /**
         * Get previous version of this node. Unlike major version and patch version, previous major
         * version and previous patch version are independent. There can exist previous patch
         * version but not previous major version. Therefore the latter must be there for the whole
         * string.
         * Example returned version string (<majorVersion>-<patchVersion>): '18.2.8-3p1'.
         * When withBuild is true (<majorVersion>-<patchVersion>-<build>): '18.2.8-3p1-2046'.
         * @param [withBuild=false] - Whether to include build number.
         */
        public getPreviousVersion(withBuild = false): string {
            const { version } = this.previousVersionAsHash;

            if (version) {
                return UpgradeService.generateCompleteVersionFromHash(
                    this.previousVersionAsHash,
                    withBuild,
                );
            }

            return '';
        }

        /**
         * Decide is this node of a specific type.
         */
        public isNodeType(type: string): boolean {
            return this.nodeType === type;
        }

        /**
         * Decide is this node in initial state and hasn't gone through any upgrade operations.
         */
        public isInInitState(): boolean {
            const { stateGroup } = this;

            return stateGroup === UpgradeStateGroup.GROUP_INIT;
        }

        /**
         * Decide is this node in upgrade progress.
         */
        public isUpgradeInProgress(): boolean {
            const { stateGroup } = this;

            return stateGroup === UpgradeStateGroup.GROUP_IN_PROGRESS;
        }
    };
}
