/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
    Type,
} from '@angular/core';

import { DialogService } from 'ng/modules/core/services/dialog.service';
import { L10nService } from '@vmw/ngx-vip';
import { AviDropdownButtonPosition }
    from 'ng/shared/components/avi-dropdown-button/avi-dropdown-button.constants';
import { JsonViewerComponent }
    from 'ng/modules/dialog/components/avi-config-viewer/json-viewer/json-viewer.component';
import { Item } from 'ajs/modules/data-model/factories/item.factory';

import './full-modal-config-viewer.component.less';
import * as l10n from '../../full-modal-config.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
const VIEW_CONFIG_AS_JSON = 'VIEW_CONFIG_AS_JSON';
/**
 * @description
 * FullModalConfigViewer component, allows user to view config as
 * Json and Yaml(Ansible) format.
 *
 * @author Abhinesh Gour
 */
@Component({
    selector: 'full-modal-config-viewer',
    templateUrl: './full-modal-config-viewer.component.html',
})

export class FullModalConfigViewerComponent {
    /**
     * Editable item of Host Modal Component.
     */
    @Input()
    public item: Item;

    /**
     * Position of the config viewer dropdown.
     */
    public position = AviDropdownButtonPosition.BOTTOM_LEFT;

    /**
     * Actions for the config viewer dropdown.
     */
    public actions = [
        {
            label: this.l10nService.getMessage(l10nKeys.viewAsJson),
            onClick: () => this.openJsonViewerModal(),
        },
    ];

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    constructor(
        private readonly dialogService: DialogService,
        private readonly l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called when the connect button is clicked. Opens up the dialog component. Provides the
     * closeDialog method to the dialog component to close itself.
     */
    public openJsonViewerModal(): void {
        this.dialogService.add({
            id: VIEW_CONFIG_AS_JSON,
            component: JsonViewerComponent as Type<Component>,
            componentProps: {
                closeDialog: () => this.dialogService.remove(VIEW_CONFIG_AS_JSON),
                config: this.item.dataToSave(),
                modified: this.item.modified(),
                defaultConfig: this.item.getDefaultConfig(),
            },
        });
    }
}
