/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'health-monitor';
const componentName = 'health-monitor-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitleNew = `${prefix}.modalTitleNew`;
export const modalTitleEdit = `${prefix}.modalTitleEdit`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const descriptionInputLabel = `${prefix}.descriptionInputLabel`;
export const descriptionInputPlaceholder = `${prefix}.descriptionInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const isFederatedInputLabel = `${prefix}.isFederatedInputLabel`;
export const sendIntervalInputLabel = `${prefix}.sendIntervalInputLabel`;
export const sendIntervalInputPlaceholder = `${prefix}.sendIntervalInputPlaceholder`;
export const receiveTimeoutInputLabel = `${prefix}.receiveTimeoutInputLabel`;
export const receiveTimeoutInputPlaceholder = `${prefix}.receiveTimeoutInputPlaceholder`;
export const secondsInnerText = `${prefix}.secondsInnerText`;
export const successfulChecksInputLabel = `${prefix}.successfulChecksInputLabel`;
export const successfulChecksInputPlaceholder = `${prefix}.successfulChecksInputPlaceholder`;
export const failedChecksInputLabel = `${prefix}.failedChecksInputLabel`;
export const failedChecksInputPlaceholder = `${prefix}.failedChecksInputPlaceholder`;
export const sectionTitleRbac = `${prefix}.sectionTitleRbac`;
export const rbacHeader = `${prefix}.rbacHeader`;
export const sectionTitleDns = `${prefix}.sectionTitleDns`;
export const sectionTitleExternal = `${prefix}.sectionTitleExternal`;
export const sectionTitleRadius = `${prefix}.sectionTitleRadius`;
export const sectionTitleSip = `${prefix}.sectionTitleSip`;
export const sectionTitleHttp = `${prefix}.sectionTitleHttp`;
export const sectionTitleHttps = `${prefix}.sectionTitleHttps`;
export const sectionTitleTcp = `${prefix}.sectionTitleTcp`;
export const sectionTitleUdp = `${prefix}.sectionTitleUdp`;
export const sectionTitleServerMaintenanceMode = `${prefix}.sectionTitleServerMaintenanceMode`;
export const maintenanceResponseDataInputLabel = `${prefix}.maintenanceResponseDataInputLabel`;
export const maintenanceResponseDataInputPlaceholder = `${prefix}.maintenanceResponseDataInputPlaceholder`;
export const sectionTitleSctp = `${prefix}.sectionTitleSctp`;

export const ENGLISH = {
    [modalTitleNew]: 'Create Health Monitor',
    [modalTitleEdit]: 'Edit Health Monitor',
    [sectionTitleGeneral]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [descriptionInputLabel]: 'Description',
    [descriptionInputPlaceholder]: 'Enter Description',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'Select Type',
    [isFederatedInputLabel]: 'Is Federated',
    [sendIntervalInputLabel]: 'Send Interval',
    [sendIntervalInputPlaceholder]: 'Enter Send Interval',
    [receiveTimeoutInputLabel]: 'Receive Timeout',
    [receiveTimeoutInputPlaceholder]: 'Enter Receive Timeout',
    [successfulChecksInputLabel]: 'Successful Checks',
    [successfulChecksInputPlaceholder]: 'Enter Successful Checks',
    [failedChecksInputLabel]: 'Failed Checks',
    [failedChecksInputPlaceholder]: 'Enter Failed Checks',
    [secondsInnerText]: 'Seconds',
    [sectionTitleRbac]: 'RBAC',
    [rbacHeader]: 'Role-Based Access Control (RBAC)',
    [sectionTitleDns]: 'DNS',
    [sectionTitleExternal]: 'External',
    [sectionTitleRadius]: 'Radius',
    [sectionTitleSip]: 'SIP',
    [sectionTitleHttp]: 'HTTP',
    [sectionTitleHttps]: 'HTTPS',
    [sectionTitleTcp]: 'TCP',
    [sectionTitleUdp]: 'UDP',
    [sectionTitleServerMaintenanceMode]: 'Server Maintenance Mode',
    [maintenanceResponseDataInputLabel]: 'Maintenance Server Response Data',
    [maintenanceResponseDataInputPlaceholder]: 'Enter Response Data',
    [sectionTitleSctp]: 'SCTP',
};
