/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ApplicationProfileModule
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { RateLimiterProfile } from 'object-types';
import { IRateLimiterProfile } from 'generated-types';

import {
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories/repeated-message-item.factory';

import { RateProfileConfigItem } from './rate-profile.config-item.factory';

const HTTP_HEADER_RATE_LIMITS = 'http_header_rate_limits';

type TRateLimiterProfilePartial = Omit<IRateLimiterProfile,
'client_ip_connections_rate_limit' |
'http_header_rate_limits'
>;

interface IRateLimiterProfileConfig extends TRateLimiterProfilePartial {
    client_ip_connections_rate_limit?: RateProfileConfigItem;
    http_header_rate_limits?: RepeatedMessageItem<RateProfileConfigItem>;
}

/**
 * @description RateLimiter Profile ConfigItem.
 *
 * @author Nisar Nadaf
 */
export class RateLimiterProfileConfigItem extends MessageItem<IRateLimiterProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: RateLimiterProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Create Rate Profile config object based on given rate profile name.
     */
    public setProfileByName(profileName: string): void {
        // http_header_rate_limits is repeated message item.
        if (profileName === HTTP_HEADER_RATE_LIMITS) {
            this.config.http_header_rate_limits.add();

            return;
        }

        this.safeSetNewChildByField(profileName);
    }

    /**
     * Remove Rate Profile config object based by given rate profile name.
     */
    public removeProfileByName(profileName: string): void {
        delete this.config[profileName];
    }

    /**
     * Remove HttpHeader Rate Profile config object by array index.
     */
    public removeHttpHeaderProfile(index: number): void {
        this.config.http_header_rate_limits.remove(index);
    }
}
