/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    ISSLVersion,
    SSLVersionType,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

/**
 * Ajs dependency token for SSLVersion configItem.
 */
export const SSL_VERSION_CONFIG_ITEM_TOKEN = 'SSLVersionConfigItem';

/**
 * @description SSLVersion ConfigItem class.
 *
 * @author Aravindh Nagarajan
 */
export class SSLVersionConfigItem extends MessageItem<ISSLVersion> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'SSLVersion',
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Getter for Version type.
     */
    public get type(): SSLVersionType {
        const { config } = this;

        return config.type;
    }
}
