/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const prefix = 'auto-scale';

export const autoScalePolicyModalBreadcrumbTitle = `${prefix}.autoScalePolicyModalBreadcrumbTitle`;
export const scheduledScalingModalBreadcrumbTitle = `${prefix}.scheduledScalingModalBreadcrumbTitle`;

export const ENGLISH = {
    [autoScalePolicyModalBreadcrumbTitle]: 'Server AutoScale Policy',
    [scheduledScalingModalBreadcrumbTitle]: 'Scheduler',
};
