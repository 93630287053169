/**
 * @module ScriptModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2020 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { IRateLimiter } from 'generated-types';

/**
 * @description RateLimiter ConfigItem.
 *
 * @author Aravindh Nagarajan
 */
export class RateLimiterConfigItem extends MessageItem<IRateLimiter> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'RateLimiter',
            ...args,
        };

        super(extendedArgs);
    }
}
