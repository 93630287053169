/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'certificate-management-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const customParamsLabel = `${prefix}.customParamsLabel`;

export const ENGLISH = {
    [headerLabel]: 'Certificate Management',
    [customParamsLabel]: 'Custom Params',
};
