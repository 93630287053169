/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';

import {
    ActiveUserProfileService,
    IActiveUserData,
    StringService,
} from 'ajs/modules/core/services';

import { L10nService } from '@vmw/ngx-vip';
import * as moment from 'moment';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ControllerInitialDataSelectors } from 'ng/root-store/controller-initial-data-store';
import { MyAccountModalService } from 'ng/modules/accounts';
import './user-card.component.less';
import * as l10n from './user-card.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @desc Component to display user information in user dropdown menu.
 *
 * @author Rohit Gaikwad, Alex Klyuev
 */
@Component({
    selector: 'user-card',
    templateUrl: './user-card.component.html',
})
export class UserCardComponent implements OnDestroy, OnChanges {
    /**
     * User's last login information.
     */
    @Input()
    public lastLoginTimestamp: string;

    /**
     * Event emitted to close the tooltip/user dropdown menu.
     */
    @Output()
    public onTooltipClose = new EventEmitter<boolean>();

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * User's last login detail.
     */
    public lastLogin: string;

    /**
     * Logged in user's information.
     */
    public readonly userData: IActiveUserData;

    /**
     * Controller time difference data computed from the initial-data API.
     */
    private readonly controllerTimeDifference$ = this.store.select(
        ControllerInitialDataSelectors.selectControllerTimeDifference,
    );

    constructor(
        l10nService: L10nService,
        activeUserProfileService: ActiveUserProfileService,
        private readonly stringService: StringService,
        private readonly myAccountModalService: MyAccountModalService,
        private readonly store: Store,
    ) {
        this.userData = activeUserProfileService.userData;

        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnChanges(changes: SimpleChanges): void {
        const { lastLoginTimestamp: { currentValue } } = changes;

        if (currentValue) {
            this.setLastLogin(currentValue);
        }
    }

    /**
     * Opens the Account modal popup.
     */
    public openMyAccountModal(): void {
        this.onTooltipClose.emit();
        this.myAccountModalService.openModal();
    }

    /** @override */
    public ngOnDestroy(): void {
        const { myAccountModalService } = this;

        if (myAccountModalService.isOpen()) {
            myAccountModalService.closeModal();
        }
    }

    /**
     * Sets last login of user in readable format.
     */
    protected setLastLogin(lastLoginTimestamp: string): void {
        this.controllerTimeDifference$
            .pipe(take(1))
            .subscribe(controllerTimeDifference => {
                let lastLogin = '';

                if (lastLoginTimestamp) {
                    lastLogin += moment
                        .utc(lastLoginTimestamp)
                        .add(controllerTimeDifference, 'seconds')
                        .fromNow();
                }

                this.lastLogin = this.stringService.capitalize(lastLogin);
            });
    }
}
