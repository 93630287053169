/**
 * @module SharedModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';

/**
 * @description Pipe to transform objects to prettify JSON string format.
 *
 * @author Abhinesh Gour
 */

@Pipe({
    name: 'formatJson',
})
export class FormatJsonPipe implements PipeTransform {
    /**
     * Transforms objects to prettify JSON string.
     */
    public transform(val: Record<string, string>): string {
        return this.formatJson(val);
    }

    /**
     * Returns prettify JSON string template.
     */
    private formatJson(rawJsonObject: Record<string, string>): string {
        const indentationSpace = 4;

        return JSON.stringify(rawJsonObject, undefined, indentationSpace)
            .replace(/ /g, '&nbsp;')
            .replace(/\n/g, '<br/>');
    }
}
