/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import d3 from 'd3v3';
import * as l10n from './LogBarchart.l10n';
import '../../less/components/log-barchart.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

//TODO combine logBarChart and eventsChart, moving resuable components to services and factories
angular.module('aviApp').directive('logBarChart', [
    '$timeout', 'durationDates2str', 'logChartBrush', 'logBarChartTooltip', 'l10nService', 'NgZone',
function($timeout, durationDates2str, logChartBrush, tooltipClass, l10nService, ngZone) {
    function link(scope, elem) {
        l10nService.registerSourceBundles(dictionary);

        const LogChartBrush = logChartBrush,
            TooltipClass = tooltipClass,
            padding = {
                top: 10,
                bottom: 20,
                right: 20,
                left: 30,
                icon: 14,
                legend: 20,
            },
            zoomInIconSize = 24;

        let w,
            h,
            xMin,
            xMax,
            yMax,
            x,
            y,
            xAxis,
            yAxis;

        //svg elements
        let chart,
            highlight,
            barchartContainer,
            record;

        let data,
            step,
            repaintTimeout,
            target, //'parent' for tooltip
            tooltip,
            brush;

        //to check inside watcher if boundaries were updated
        let prevStart,
            prevEnd,
            prevIfUTC;

        let showZoomInIconOnBrush;

        //main event driven function of the chart
        let repaint,
            clear,
            resize;

        //chart type (log/events) specific functions:
        let convertChartData,
            paintBars,
            tooltipContent,
            hoverTargetsSelector,
            combineSelectionData,
            barWidth,
            wafContainer;

        /* Counts portion of bar in selection from 0 to 1.
        Returns undefined if bar is completely out of selection.
        * start, end - selected time range */
        const selectedBarPortion = function(start, end, barStart, barEnd) {
            let portion;

            if (barEnd > start && barStart < end) {
                //bar is completely inside the selection
                if (barStart >= start && barEnd <= end) {
                    portion = 1; //boundaries conditions, estimate linear distribution
                } else if (barStart < start && barEnd > start) {
                    portion = (Math.min(end, barEnd) - start) / step;
                } else if (barEnd > end && barStart < end) {
                    portion = (end - Math.max(start, barStart)) / step;
                }
            }

            return portion;
        };

        const highlightResize = function(time) {
            //console.log('logBarchart highlighter, time:%O', time);
            if (time && x) { //can be fired before x has been set in
                highlight
                    .attr('x', x(d3.time.format.iso.parse(moment(time))))
                    .style('display', 'inherit');
            } else {
                highlight.style('display', 'none');
            }
        };

        /* finds parent to show tooltip and defines if it is time to show or hide one */
        const onMouseMove = _.throttle(function(e, px) {
            let parent,
                parentWidth,
                newTarget;

            const pos = {
                center: 0,
                bottom: 0,
            };
            const pointerTime = +x.invert(px);

            [newTarget] = _.filter(elem.find(hoverTargetsSelector), function(n) {
                return pointerTime < n.__data__.end && pointerTime >= n.__data__.start;
            });

            //don't show for bars with zeroes inside (artificial when no data)
            if (newTarget && !newTarget.__data__.total) {
                newTarget = undefined;
            }

            // if target changed, change tooltip
            if (newTarget && (target !== newTarget || !tooltip.isVisible()) && !brush.active) {
                target = newTarget;
                parent = $(newTarget);

                parentWidth = barWidth(parent) || 16;

                pos.bottom =
                    $(window).height() - elem.offset().top - padding.top - padding.legend - 5;
                pos.center = parent.offset().left + parentWidth / 2;

                tooltip.show(pos, tooltipContent(target.__data__));
            } else if (!newTarget) {
                target = undefined;
                tooltip.hide();
            }
        }, 50, { trailing: false });

        /* On-fly definition of specific functions(!) & properties depending on a chart type
            (log/event). Should be rebuilt using JS inheritance @am */
        if (scope.type === 'log') {
            hoverTargetsSelector = 'g.record';

            convertChartData = function(p) {
                let val = 0,
                    missing,
                    adf = 0, //totals for significant and all logs
                    nf = 0; //totals for significant and all logs

                const bars = [],
                    start = d3.time.format.iso.parse(p.start),
                    end = d3.time.format.iso.parse(p.end);

                if (typeof p.adf !== 'undefined' && p.adf > 0) {
                    adf = p.adf;
                    bars.push({
                        type: 'adf',
                        value: +p.adf,
                        top: +p.value,
                        start,
                        end,
                    });
                }

                /* add user defined filters to all logs */
                if (typeof p.nf !== 'undefined' && p.nf > 0 ||
                    typeof p.udf !== 'undefined' && p.udf > 0) {
                    if (p.nf) { val += p.nf; }

                    if (p.udf) { val += p.udf; }

                    nf = val;
                    bars.push({
                        type: 'nf',
                        value: val,
                        top: p.value - (p.adf || 0),
                        start,
                        end,
                    });
                }

                if (typeof p.missing_count !== 'undefined') {
                    if (p.missing_count > 0) {
                        missing = +p.missing_count;
                    } else if (p.missing_count < 0) {
                        missing = 'NaN';
                    }
                    /*if(missing) {
                        scope.haveMissingLogs = true;
                    }*/
                }

                return {
                    start,
                    end,
                    bars,
                    adf, //total for significant
                    nf, //total for all
                    total: +p.value,
                    missing,
                };
            };

            paintBars = function() {
                const record = barchartContainer
                    .selectAll('.record')
                    .data(data)
                    .enter()
                    .append('g')
                    .attr('class', 'record')
                    .attr('transform', function(d) {
                        return `translate(${x(d.start)})`;
                    });

                record.selectAll('rect')
                    .data(function(d) { return d.bars; })
                    .enter().append('rect')
                    .attr('class', function(d) {
                        return `${d.type} barchart sel-barchart`;
                    })
                    .attr('y', function(d) {
                        return Math.min(h - 1, y(d.top));
                    })
                    .attr('width', function(d) {
                        //TODO figure out why it can rarely be a negative value
                        return Math.max(0, x(d.end) - x(d.start));
                    })
                    .attr('height', function(d) {
                        return d.value === 0 ? 0 : h - Math.min(h - 1, y(d.value));
                    });

                //extra work on chart can't be done in main thread
                record.each(function(d) {
                    let
                        first;
                    const bars = $(this).find('rect');

                    //move first bar for one pixel to the top to be seen if both of
                    // them have width = 1
                    if (bars.length > 1) {
                        first = $(bars[0]);

                        if (first.attr('y') === $(bars[1]).attr('y')) {
                            first.attr('y', first.attr('y') - 1);
                        }
                    }
                });

                const { waf } = scope.data;

                if (waf) {
                    const wafIcons = wafContainer
                        .selectAll('div')
                        .data(waf);

                    wafIcons
                        .enter()
                        .append('xhtml:div')
                        .attr('class', 'waf-icon-container');

                    wafIcons.exit().remove();

                    const { left } = padding;

                    const containers = wafContainer
                        .selectAll('div.waf-icon-container')
                        .style('left', d => `${x(new Date(d.timestamp).getTime()) + left}px`)
                        .style('top', `${h + padding.top + padding.legend}px`);

                    containers.html('');

                    containers
                        .append('xhtml:div')
                        .attr('class', 'waf-icon')
                        .html('<i class="icon-shield"></i>');
                }
            };

            /* need to know 'bar'(target) width for tooltip positioning */
            barWidth = function(elem) {
                return elem.children().length && $(elem.children()[0]).attr('width');
            };

            tooltipContent = function(data) {
                let
                    label,
                    html;

                const dates = durationDates2str(data.start, data.end);

                html = `<div class="tooltip-date">${dates[0]} - ${dates[1]}</div>`;

                const tabWidth = (100 / (+!!data.adf + !!data.nf + !!data.missing)).toFixed(2);

                if (data.nf) {
                    html += `<div><span class="tooltip-legend" style="width:${tabWidth}%">` +
                        '<div class="chart-color tooltip-legend-color"></div>' +
                        '<span class="legend-value"><div>Non-significant</div>' +
                        `<div class="value">${Math.num2abbrStr(data.nf, 1)
                        } <span class="units"> log${data.nf > 1 ? 's' : ''
                        }</span></div></span></span>`;
                }

                if (data.adf) {
                    html += `<span class="tooltip-legend" style="width:${tabWidth}%">` +
                        '<div class="significant tooltip-legend-color"></div>' +
                        `<span class="legend-value"><div>Significant</div><div class="value">${
                            Math.num2abbrStr(data.adf, 1)
                        } <span class="units"> log${data.adf > 1 ? 's' : ''
                        }</span></div></span></span>`;
                }

                if (data.missing) {
                    label = data.missing === 'NaN' ? '' : Math.num2abbrStr(data.missing, 1);
                    html += `<span class="tooltip-legend" style="width:${tabWidth}%">` +
                        '<div class="missing tooltip-legend-color"></div>' +
                        `<span class="legend-value"><div>Missing</div><div class="value">${label
                        } <span class="units"> log${
                            data.missing === 'NaN' || data.missing > 1 ? 's' : ''
                        }</span></div></span></span>`;
                }

                html += '</div>';

                return html;
            };

            /* Counts values of bars inside selection to present summed data like one bar to
            generate selection tooltip content.
             * Uses data from common scope. */
            combineSelectionData = function(start, end) {
                const elem = {
                    start,
                    end,
                    adf: 0,
                    nf: 0,
                    total: 0,
                    missing: 0,
                };

                start = +start;//dates to number of seconds
                end = +end;

                if (data && data.length && start && end) {
                    data.forEach(function(val) {
                        const portion = selectedBarPortion(start, end, +val.start, +val.end);

                        if (typeof portion !== 'undefined') {
                            //undefined if bar is completely outside selection
                            elem.total += Math.floor(val.total * portion);
                            elem.adf += Math.floor(val.adf * portion);
                            elem.nf += Math.floor(val.nf * portion);

                            if (typeof val.missing === 'number' &&
                                typeof elem.missing === 'number') {
                                elem.missing += Math.floor(val.missing * portion);
                            } else if (val.missing === 'NaN') {
                                elem.missing = val.missing;
                            }
                        }
                    });
                }

                return elem;
            };
        } else if (scope.type === 'event') {
            padding.legend = 0;
            padding.icon = 0;
            hoverTargetsSelector = 'rect.barchart';

            convertChartData = function(p) {
                return {
                    start: d3.time.format.iso.parse(p.start),
                    end: d3.time.format.iso.parse(p.end),
                    total: p.value,
                };
            };

            paintBars = function() {
                barchartContainer
                    .selectAll('rect.barchart')
                    .data(data)
                    .enter()
                    .append('rect')
                    .attr('class', 'barchart sel-barchart')
                    .attr('x', function(d) {
                        return x(d.start);
                    })
                    .attr('y', function(d) {
                        return Math.min(h - 1, y(d.total));
                    })
                    .attr('width', function(d) {
                        //TODO figure out why it can rarely be a negative value
                        return Math.max(0, x(d.end) - x(d.start));
                    })
                    .attr('height', function(d) {
                        return d.total === 0 ? 0 : h - Math.min(h - 1, y(d.total));
                    });
            };

            barWidth = function(elem) {
                return elem.attr('width');
            };

            tooltipContent = function(data) {
                const dates = durationDates2str(data.start, data.end);
                const html = `<div class="tooltip-date">${dates[0]} - ${dates[1]
                }</div><span class="tooltip-legend" style="width:100%">` +
                    '<div class="chart-color tooltip-legend-color">' +
                    `</div><span class="legend-value"><div class="value">
                    ${l10nService.getMessage(l10nKeys.eventsPluralLabel, [data.total])}
                    </div></span></span>`;

                return html;
            };

            combineSelectionData = function(start, end) {
                const elem = {
                    start,
                    end,
                    total: 0,
                };

                if (data && data.length && start && end) {
                    data.forEach(function(val) {
                        const portion = selectedBarPortion(+start, +end, +val.start, +val.end);

                        if (typeof portion !== 'undefined') {
                            //undefined if bar is completely outside selection
                            elem.total += Math.floor(val.total * portion);
                        }
                    });
                }

                return elem;
            };
        }

        brush = (function() {
            let
                zoomInClicked = false;
            const brush = new LogChartBrush();

            brush.onBrushStart = function() {
                zoomInClicked = d3.event.sourceEvent && d3.event.sourceEvent.target &&
                    typeof d3.event.sourceEvent.target.className === 'string' &&
                    d3.event.sourceEvent.target.className.indexOf('zoom-in');
            };

            /* defining main brush methods using closure to current function scope */
            brush.onBrushUpdate = function(range) {
                //console.log('onBrushUpdate');
                let
                    combined,
                    center = d3.event.sourceEvent && d3.event.sourceEvent.x;
                const bottom =
                        $(window).height() - elem.offset().top - padding.top - padding.legend - 5;

                scope.brush.start = moment(range[0]);
                scope.brush.end = moment(range[1]);

                if (center) { //'real' event (not evoked by d3.call)
                    //should be inside chart area
                    center = Math.max(elem.offset().left + padding.left,
                        Math.min(elem.offset().left + elem.width() - padding.right, center));
                    combined = combineSelectionData(scope.brush.start, scope.brush.end);
                    tooltip.show(
                        {
                            bottom,
                            center,
                        },
                        tooltipContent(combined),
                    );
                    scope.selected = combined.total;
                    chart.select('g.brush > .zoom-in').attr('style', 'display:none');
                    scope.$apply();
                }
            };

            /* fires before onBrushEnd to check and modify current brush selection */
            brush.checkBrushSelection = function(range) {
                if (moment(range[1]).diff(moment(range[0]), 's') < 1) {
                    range[1] = moment(range[0]).add(1, 's');

                    if (range[1].isAfter(moment(xMax))) {
                        range[1] = moment(xMax);
                        range[0] = range[1].clone().subtract(1, 's');
                    }

                    this.extent(range);
                    chart.select('.brush').call(this.obj);
                }

                return range;
            };

            brush.onBrushEnd = function(range) {
                const
                    pos = {
                        start: 0,
                        end: 0,
                    };

                tooltip.hide();

                if (range) {
                    scope.brush.start = moment(range[0]);
                    scope.brush.end = moment(range[1]);

                    pos.start = x(scope.brush.start);
                    pos.end = x(scope.brush.end);

                    showZoomInIconOnBrush(scope.brush.start, scope.brush.end);
                } else {
                    chart.select('g.brush > .zoom-in').attr('style', 'display:none');
                    scope.selected = 0;

                    if (zoomInClicked) {
                        scope.$apply('zoomIn()');

                        return;
                    } else {
                        scope.brush.end = false;
                        scope.brush.start = false;
                    }
                }

                scope.$apply('onUpdate()');
            };

            /* Click event when no active (or previous) brush on a chart.
            Used to select clicked bar on a chart. */
            brush.onClickNoBrush = function() {
                let extent;

                const container = elem.find('g.barchart-container');
                let mousePos;

                try {
                    mousePos = d3.mouse(container.get(0));
                } catch (e) {
                    mousePos = [0, 0];
                }

                const pointerTime = +x.invert(mousePos[0]);

                const bar = _.filter(elem.find(hoverTargetsSelector), function(n) {
                    return pointerTime < n.__data__.end && pointerTime >= n.__data__.start;
                })[0];

                if (bar && bar.__data__.total) {
                    extent = this.checkBrushSelection([bar.__data__.start, bar.__data__.end]);
                    this.extent(extent);

                    chart.select('.brush').call(this.obj);
                    scope.selected = bar.__data__.total;
                    this.onBrushEnd(extent);
                }
            };

            return brush;
        })();

        showZoomInIconOnBrush = function(start, end) {
            const pos = {};

            pos.start = x(start);
            pos.end = x(end);

            if (scope.selected && pos.end - pos.start > zoomInIconSize * 1.2 &&
                scope.zoomInAvail()) {
                chart.select('g.brush > .zoom-in')
                    .attr({
                        x: Math.round(pos.start + (pos.end - pos.start - zoomInIconSize) / 2),
                        style: 'display:inherit',
                    });
            }
        };

        tooltip = new TooltipClass();

        /* creates main svg elements on initial drawing and after resize event */
        const init = function() {
            w = elem.width() - padding.right - padding.left;
            //sometimes onReload elem is not ready to provide size even through css
            h = (elem.height() || 125) - padding.top - padding.bottom - padding.legend;

            target = undefined;

            if (barchartContainer) {
                barchartContainer.remove();
            }

            if (chart) {
                chart.remove();
            }

            if (record) {
                record.remove();
            }

            if (highlight) {
                highlight.remove();
            }

            const svg = d3.select(elem[0]).append('svg');

            chart = svg
                .attr({
                    class: 'chart sel-bar-chart',
                    width: '100%',
                    height: '100%',
                })
                .append('g')
                .attr('transform',
                    `translate(${padding.left},${padding.top + padding.legend})`);

            const gradient = chart.append('linearGradient')
                .attr({
                    id: 'brushGrad',
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: 1,
                });

            gradient.append('stop')
                .attr({
                    id: 'stop1',
                    'stop-color': '#67b0cb', //aviBlue3
                    'stop-opacity': 0.3,
                    offset: 0,
                });

            gradient.append('stop')
                .attr({
                    id: 'stop2',
                    'stop-color': 'white',
                    'stop-opacity': 0,
                    offset: 0.6,
                });

            barchartContainer = chart.append('g')
                .attr('class', 'barchart-container sel-barchart-container');

            chart.append('g')
                .attr('class', 'x brush')
                .call(brush.obj)
                .call(brush.obj.event)
                .selectAll('rect')
                .attr({
                    y: 1,
                    height: h - 1,
                    fill: 'url(#brushGrad)',
                });

            /* zoomIn icon in the middle of a brush */
            chart.select('g.x.brush')
                .append('svg:foreignObject')
                .attr({
                    class: 'zoom-in',
                    x: '0',
                    y: Math.round((h - zoomInIconSize) / 2 + 2),
                    height: zoomInIconSize,
                    width: zoomInIconSize,
                    style: 'display:none',
                })
                .append('xhtml:div')
                .attr('class', 'in-svg')
                .html('<i class="icon icon-zoom-in"></i>');

            highlight = barchartContainer
                .append('rect')
                .attr({
                    class: 'highlight',
                    x: 0,
                    y: 0,
                    width: 1,
                    height: h,
                })
                .style('display', 'none');

            // These events trigger unnecessary change detection cycles in angular
            // environments. So running them outside of zone.
            ngZone.runOutsideAngular(() => {
                chart.on('mousemove', function() {
                    const px = d3.mouse(elem.find('g.barchart-container')[0])[0];

                    //console.log(px);
                    //if(d3.event.which){ return; }
                    //TODO replace with variable
                    onMouseMove(d3.event, px);
                });
            });

            wafContainer = svg
                .append('g')
                .attr('class', 'waf-container')
                .append('svg:foreignObject');
        };

        /* on initial load only, after timeout */
        const firstInit = function() {
            init();

            scope.$watch('data.results', function(newVal) {
                //watch init, disabled because sometimes it
                // fires only once with 'newVal === prevVal'
                if (repaintTimeout) {
                    $timeout.cancel(repaintTimeout);
                    repaintTimeout = false;
                }

                if (newVal && newVal.length || !scope.loading) {
                    repaint();
                } else { //to skip updates with empty array when loading just have started
                    if (chart) { clear(); }

                    repaintTimeout = $timeout(function() {
                        repaint();
                        repaintTimeout = false;
                    }, 9999);
                }
            });
            //user settings were modified
            scope.$watch('ifUTC', function(ifUTC, oldVal) {
                if (ifUTC === oldVal) { return; }

                repaint();
            });
            //add a tiny highlight bar
            elem.on('$highlightByRow', function(event, time) {
                highlightResize(time);
            });
            scope.$on('$repaintViewport', resize);

            scope.$on('$destroy', function() {
                tooltip.remove();
            });

            scope.$on('userLoggedOut', function() {
                tooltip.remove();
            });

            scope.$on('repaint', resize);
        };

        repaint = function(type) {
            let selected;

            const startDate = scope.data.start || scope.start.toISOString(),
                endDate = scope.data.end || scope.end.toISOString(),
                reloadBrush = function() {
                    x.domain([xMin, xMax]).rangeRound([0, w]);
                    brush.obj.x(x);
                    brush.clear();
                    chart.select('.brush').call(brush.obj);

                    if (scope.brush.start && scope.brush.end) {
                        brush.extent([
                            d3.time.format.iso.parse(moment(scope.brush.start)),
                            d3.time.format.iso.parse(moment(scope.brush.end)),
                        ]);
                        selected = combineSelectionData(scope.brush.start, scope.brush.end);
                        scope.selected = selected.total;
                        showZoomInIconOnBrush(scope.brush.start, scope.brush.end);
                    }
                };

            data = scope.data.results;
            step = scope.step * 1000;//step in seconds

            const boundariesUpdated = prevStart !== startDate || prevEnd !== endDate ||
                type === 'afterResize';
            const ifUTCupdated = prevIfUTC !== scope.ifUTC;

            if (!data || !data.length) {
                data = [{ //fake data to show axises
                    start: startDate,
                    end: startDate + step,
                    value: 0,
                }, {
                    start: endDate - step,
                    end: endDate,
                    value: 0,
                }];
            }
            //scope.haveMissingLogs = false;

            data = _.map(data, convertChartData);

            //console.log('%O',data);

            //TODO search filter added for same timeframe - have to recalculate brush
            if (boundariesUpdated || ifUTCupdated) {
                if (ifUTCupdated) {
                    //console.log('logBarchart repaint: UTC updated');
                    prevIfUTC = scope.ifUTC;
                }

                if (boundariesUpdated) {
                    //console.log('logBarchart repaint: boundaries updated');
                    //scope.brush.start = scope.brush.end = false; (can't refresh with brush
                    // if I leave it here)
                    xMin = +new Date(startDate);
                    xMax = +new Date(endDate);
                    prevStart = startDate;
                    prevEnd = endDate;
                }

                x = scope.ifUTC ? d3.time.scale.utc() : d3.time.scale();
                reloadBrush();
            } else if (brush.obj.empty() && scope.brush.start && scope.brush.end) {
                reloadBrush();
            } else if (!brush.obj.empty() && scope.brush.start && scope.brush.end) {
                //have selection and graph keeps loading - increase only
                selected = combineSelectionData(scope.brush.start, scope.brush.end);

                if (selected.total > scope.selected) { scope.selected = selected.total; }

                showZoomInIconOnBrush(scope.brush.start, scope.brush.end);
            }

            chart.selectAll('.axis, .barchart, .record').remove();

            xAxis = d3.svg.axis()
                .scale(x)
                .tickSize(-h)
                .tickPadding(5)
                .orient('bottom');

            chart.append('g')
                .attr('class', 'x axis')
                .attr('transform', `translate(0,${h})`)
                .call(xAxis)
                .append('line')
                .attr({
                    class: 'topline',
                    x1: 0,
                    x2: w,
                    y1: -h,
                    y2: -h,
                });

            yMax = d3.max(data, function(p) { return p.total; }) * 1.1;
            y = d3.scale.linear().domain([0, yMax]).rangeRound([h, padding.icon]);

            chart.select('.brush').call(brush.obj);

            yAxis = d3.svg.axis()
                .scale(y)
                .tickSize(0)
                .ticks(4)
                .tickFormat(d3.format('s'), 3)
                .orient('left');

            //when there is no place for four whole numbers on axes, use predefined values only
            if (Math.floor(yMax) < 3) {
                yAxis.tickValues([0, 1, 2]);
            }

            chart.append('g')
                .attr('class', 'y axis')
                .call(yAxis);

            paintBars();
        };

        clear = function() {
            data = [];
            //scope.haveMissingLogs = false;
            chart.select('.brush').call(brush.clear());
            chart.select('g.brush > .zoom-in').attr('style', 'display:none');
            chart.selectAll('.axis, .record, .barchart').remove();
        };

        resize = function() {
            //console.log('resize');
            if (repaintTimeout) {
                $timeout.cancel(repaintTimeout);
                repaintTimeout = false;
            }

            tooltip.hide();
            target = undefined;

            elem.find('svg').remove();

            init();
            repaint('afterResize');
        };

        //need timeout for DOM ready to set appropriate width of charts SVG
        $timeout(firstInit);

        elem.on('mouseleave', function(e) {
            //allow mouseleave event to tooltip descenders
            if (!tooltip.has(e.relatedTarget)) {
                tooltip.hide();
            }
            //else{ console.log('elem mouseleave event, target: %O', e.relatedTarget); }
        });

        //no brush events for buttons other then left
        elem[0].addEventListener('mousedown', function(event) {
            if (event.which !== 1) {
                event.stopPropagation();
            }
        }, true);
    }

    return {
        restrict: 'E',
        scope: {
            onUpdate: '&', //call (for ex. fetch smth) when brush were used for selection
            //type of chart (log/event), used to define important private functions of directive
            type: '@',
            //{results:[{start: string, end: string, value: number}], start:string, end:string}
            data: '=',
            start: '=', //used only with empty data array
            end: '=', //--/--
            selected: '=', //number of items inside brush selection
            brush: '=', // {start:, end:} from controller's scope
            step: '=', //step in seconds from API call
            loading: '=',
            zoomInAvail: '&', //function which can check if we need to show zoomIn icon in a brush
            zoomIn: '&', //controller function to zoom in at the chart
            ifUTC: '=ifUtc',
        },
        link,
        template: '<div avi-loader ng-show="loading"></div>',
    };
}]);
