/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { IPathMatch } from 'generated-types';
import { PathMatch } from 'object-types';
import { PathOrQueryMatchBaseConfigItem } from './path-or-query-match-base.config-item';

/**
 * @description  PathMatch ConfigItem class.
 * @author alextsg
 */
export class PathMatchConfigItem extends PathOrQueryMatchBaseConfigItem<IPathMatch> {
    public static ajsDependencies = ['schemaService'];

    constructor(args = {}) {
        const extendedArgs = {
            objectType: PathMatch,
            ...args,
        };

        super(extendedArgs);

        const schemaService = this.getAjsDependency_('schemaService');

        this.operationLabels = schemaService.getEnumValueLabelsHash('StringOperation');
    }
}
