/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module DiagramModule
 */

import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
} from '@angular/core';

import './double-arrow.component.less';
import { TDoubleArrowColor } from './double-arrow.types';

/**
 * @description
 *     Component showing a stretchable, lined double arrow with selective colors.
 * @author Zhiqian Liu
 */
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'double-arrow',
    templateUrl: './double-arrow.component.html',
})
export class DoubleArrowComponent implements OnInit {
    /**
     * Color to be set for the double-arrow. Default to black.
     */
    @Input()
    public color ?: TDoubleArrowColor = 'black';

    /**
     * CSS class to set color for left and right arrow heads.
     */
    public headColorClass = '';

    /**
     * CSS class to set color for the connecting line.
     */
    public lineColorClass = '';

    /** @override */
    public ngOnInit(): void {
        this.headColorClass = `double-arrow__head--${this.color}`;
        this.lineColorClass = `double-arrow__line--${this.color}`;
    }
}
