/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';

import {
    GCPVIPAllocationMode,
    IGCPVIPAllocation,
    IGCPVIPILB,
    IGCPVIPRoutes,
} from 'generated-types';

import { GCPVIPAllocation } from 'object-types';

type TGcpVipAllocationPartial = Omit<IGCPVIPAllocation, 'routes' | 'ilb'>;

interface IGcpVipAllocation extends TGcpVipAllocationPartial {
    routes?: MessageItem<IGCPVIPRoutes>;
    ilb?: MessageItem<IGCPVIPILB>;
}

/**
 * @description
 *
 *   GCP VipAllocation MessageItem.
 *
 * @author Sarthak kapoor
 */
export class GCPVipAllocationConfigItem extends MessageItem<IGcpVipAllocation> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: GCPVIPAllocation,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Invoked when user changes the VIPAllocation mode from UI.
     */
    public onAllocationModeChange(): void {
        const { config } = this;

        if (config.mode === GCPVIPAllocationMode.ILB) {
            delete config.routes;

            this.safeSetNewChildByField('ilb');
        } else {
            delete config.ilb;

            this.safeSetNewChildByField('routes');
        }
    }

    /**
     * @override
     * 'mode' inside 'vip_allocation_strategy' is a mandatory field but the other two fields aren't.
     * We safeSet 'vip_allocation_strategy' if it isn't present in the config but,
     * while creating the cloud from CLI user can just set the mode which'll add
     * 'vip_allocation_strategy' field to the config but routes/ilb field won't be present which
     * results in GCP cloud modal not loading completely.
     * To avoid this we need to safeSet these two fields, depending upon the mode configured.
     */
    protected modifyConfigDataAfterLoad(): void {
        super.modifyConfigDataAfterLoad();

        const { config } = this;

        if (config.mode === GCPVIPAllocationMode.ILB && !config.ilb) {
            this.safeSetNewChildByField('ilb');
        } else if (config.mode === GCPVIPAllocationMode.ROUTES && !config.routes) {
            this.safeSetNewChildByField('routes');
        }
    }
}
