/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module MatchModule */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { Protocol } from 'generated-types';
import {
    ServiceMatchConfigItem,
} from 'ajs/modules/match/factories/service-match.config-item.factory';

import * as l10n from './service-match.component.l10n';
import './service-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * Type of Protocol enum type.
 */
type TProtocolEnumType = string;

/**
 * @description
 *     Match component for Service Match.
 * @author Guru Prasad H K
 */
@Component({
    selector: 'service-match',
    templateUrl: './service-match.component.html',
})
export class ServiceMatchComponent implements OnInit {
    /**
     *  Service Match message item instance.
     */
    @Input()
    public editable: ServiceMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * ObjectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * FieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * Object Type to be used in template for tooltip.
     */
    @Input()
    public matchObjectType: string;

    /**
     * If true, remove SCTP from protocol options.
     */
    @Input()
    public disallowSctpProtocol = false;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Max limit of a port number.
     */
    public readonly portNumberMax = 65535;

    /**
     * Min limit of a port number.
     */
    public readonly portNumberMin = 1;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Enum type for protocol match.
     */
    public readonly enumType: TProtocolEnumType = 'Protocol';

    /**
     * List of hidden Protocol enum values.
     */
    public hiddenProtocolValues: Protocol[] = [];

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        if (this.disallowSctpProtocol) {
            this.hiddenProtocolValues = [Protocol.PROTOCOL_SCTP];
        }
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch() : void {
        this.onRemoveMatch.emit();
    }
}
