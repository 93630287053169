/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'hsm-group-modal';
const prefix = `${moduleName}.${componentName}`;

export const editModalTitle = `${prefix}.editModalTitle`;
export const newModalTitle = `${prefix}.newModalTitle`;
export const generalSectionTitle = `${prefix}.generalSectionTitle`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const typeInputLabel = `${prefix}.typeInputLabel`;
export const typeInputPlaceholder = `${prefix}.typeInputPlaceholder`;
export const safenetLunaSectionTitle = `${prefix}.safenetLunaSectionTitle`;
export const safenetLunaServersLabel = `${prefix}.safenetLunaServersLabel`;
export const enableHaCheckboxLabel = `${prefix}.enableHaCheckboxLabel`;
export const dedicatedInterfaceCheckboxLabel = `${prefix}.dedicatedInterfaceCheckboxLabel`;
export const hsmCommunicationWithInputLabel = `${prefix}.hsmCommunicationWithInputLabel`;
export const dedicatedNetworkRadioLabel = `${prefix}.dedicatedNetworkRadioLabel`;
export const managementNetworkRadioLabel = `${prefix}.managementNetworkRadioLabel`;
export const clientIpInputLabel = `${prefix}.clientIpInputLabel`;
export const awsCloudHsmSectionTitle = `${prefix}.awsCloudHsmSectionTitle`;
export const usernameInputLabel = `${prefix}.usernameInputLabel`;
export const usernamePlaceholder = `${prefix}.usernamePlaceholder`;
export const passwordInputLabel = `${prefix}.passwordInputLabel`;
export const passwordPlaceholder = `${prefix}.passwordPlaceholder`;
export const hsmClusterCertificateInputLabel = `${prefix}.hsmClusterCertificateInputLabel`;
export const hsmClusterCertificatePlaceholder = `${prefix}.hsmClusterCertificatePlaceholder`;
export const hsmIpAddressesInputLabel = `${prefix}.hsmIpAddressesInputLabel`;

export const ENGLISH = {
    [editModalTitle]: 'Edit HSM Group',
    [newModalTitle]: 'New HSM Group',
    [generalSectionTitle]: 'General',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [typeInputLabel]: 'Type',
    [typeInputPlaceholder]: 'Select Type',
    [safenetLunaSectionTitle]: 'Thales Luna HSM',
    [safenetLunaServersLabel]: 'Thales Luna HSM Servers ({0})',
    [enableHaCheckboxLabel]: 'Enable HA',
    [dedicatedInterfaceCheckboxLabel]: 'Dedicated Interface',
    [hsmCommunicationWithInputLabel]: 'HSM Communication with',
    [dedicatedNetworkRadioLabel]: 'Dedicated Network',
    [managementNetworkRadioLabel]: 'Management Network',
    [clientIpInputLabel]: 'Client IP ({0})',
    [awsCloudHsmSectionTitle]: 'AWS CloudHSM',
    [usernameInputLabel]: 'Username',
    [usernamePlaceholder]: 'Enter Username',
    [passwordInputLabel]: 'Password',
    [passwordPlaceholder]: 'Enter Password',
    [hsmClusterCertificateInputLabel]: 'Import or Paste HSM Cluster Certificate',
    [hsmClusterCertificatePlaceholder]: 'Paste HSM Cluster Certificate',
    [hsmIpAddressesInputLabel]: 'HSM IP Addresses',
};
