/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'auto-scale';
const componentName = 'webhook-list-page';
const prefix = `${moduleName}.${componentName}`;

export const headerLabel = `${prefix}.headerLabel`;
export const columnTitleCallbackUrl = `${prefix}.columnTitleCallbackUrl`;
export const columnTitleVerificationToken = `${prefix}.columnTitleVerificationToken`;

export const ENGLISH = {
    [headerLabel]: 'Webhook',
    [columnTitleCallbackUrl]: 'Callback URL',
    [columnTitleVerificationToken]: 'Verification Token',
};
