/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module ErrorPageModule
 */

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IAviCollectionDataGridRow } from 'ng/modules/data-grid';
import { ErrorPageProfileCollection }
    from 'ajs/modules/error-page/factories/error-page-profile.collection.factory';
import { IAviCollectionDataGridConfig }
    from 'ng/modules/data-grid/components/avi-collection-data-grid/avi-collection-data-grid.types';
import * as globalL10n from 'global-l10n';
import * as l10n from './error-page-profile-list.l10n';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TErrorPageProfileCollection = typeof ErrorPageProfileCollection;

/**
 * @description Error Page Profile list page.
 *
 * @author Hitesh Mandav
 */
@Component({
    selector: 'error-page-profile-list',
    templateUrl: './error-page-profile-list.component.html',
})
export class ErrorPageProfileComponent implements OnInit, OnDestroy {
    public readonly l10nKeys = l10nKeys;

    public readonly globalL10nKeys = globalL10nKeys;

    public errorPageProfileGridConfig: IAviCollectionDataGridConfig;

    private readonly errorPageProfileCollection: ErrorPageProfileCollection;

    constructor(
        private readonly l10nService: L10nService,
        @Inject(ErrorPageProfileCollection)
        ErrorPageProfileCollection: TErrorPageProfileCollection,
    ) {
        l10nService.registerSourceBundles(dictionary);
        this.errorPageProfileCollection = new ErrorPageProfileCollection();
    }

    /** @override */
    public ngOnInit(): void {
        const { objectName } = this.errorPageProfileCollection;

        this.errorPageProfileGridConfig = {
            id: `${objectName}-list-page`,
            collection: this.errorPageProfileCollection,
            fields: [{
                id: 'name',
                label: this.l10nService.getMessage(globalL10nKeys.nameLabel),
                transform: row => row.getName(),
            }],
            layout: {
                placeholderMessage:
                this.l10nService.getMessage(globalL10nKeys.noItemsFoundLabel),
            },
            getRowId: (index: number, row: IAviCollectionDataGridRow) => row.id,
        };
    }

    /** @override */
    public ngOnDestroy(): void {
        this.errorPageProfileCollection.destroy();
    }
}
