/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'license-tier-switch-modal';
const prefix = `${moduleName}.${componentName}`;

export const modalTitle = `${prefix}.modalTitle`;
export const switchLicenseHeader = `${prefix}.switchLicenseHeader`;
export const controllerDeregistrationMessage = `${prefix}.controllerDeregistrationMessage`;
export const insufficientLicensesMessage = `${prefix}.insufficientLicensesMessage`;
export const unlockMoreFeaturesMessage = `${prefix}.unlockMoreFeaturesMessage`;
export const someFeaturesOnlyAvailableMessage = `${prefix}.someFeaturesOnlyAvailableMessage`;
export const reserveServiceUnitsLabel = `${prefix}.reserveServiceUnitsLabel`;
export const reserveServiceUnitsPlaceholder = `${prefix}.reserveServiceUnitsPlaceholder`;
export const maxServiceUnitsLabel = `${prefix}.maxServiceUnitsLabel`;
export const maxServiceUnitsPlaceholder = `${prefix}.maxServiceUnitsPlaceholder`;

export const ENGLISH = {
    [modalTitle]: 'Licensing',
    [switchLicenseHeader]: 'Tier Switching',
    [controllerDeregistrationMessage]: 'In order to switch to {0} the controller will be deregistered from Cloud Services.',
    [insufficientLicensesMessage]: 'Insufficient Service Core Licenses to switch to {0} License',
    [unlockMoreFeaturesMessage]: 'Unlock more features and possibilities with {0} License',
    [someFeaturesOnlyAvailableMessage]: 'Some features are only available in {0}, switching to {1} will deactivate these features',
    [reserveServiceUnitsLabel]: 'Number of Reserved Licenses',
    [reserveServiceUnitsPlaceholder]: 'Enter Number of Reserved Licenses',
    [maxServiceUnitsLabel]: 'Maximum Allowed Licenses',
    [maxServiceUnitsPlaceholder]: 'Enter Maximum Allowed Licenses',
};
