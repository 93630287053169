/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @description
 * @author alextsg
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { IAviDropdownOption } from 'ng/shared/components';
import { createDropdownOption } from 'ng/shared/utils';
import { HdrMatchOperation } from 'generated-types';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { HdrMatchConfigItem } from 'ajs/modules/match';
import * as l10n from './hdr-match.l10n';
import './hdr-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const preventConfigureValueSet = new Set([
    HdrMatchOperation.HDR_EXISTS,
    HdrMatchOperation.HDR_DOES_NOT_EXIST,
]);

/**
 * @description
 *     Match component for the HdrMatch message. The matchIndex is a binding so that the ngModel
 *     has unique names, since this can be a repeated message.
 * @author alextsg
 */
@Component({
    selector: 'hdr-match',
    templateUrl: './hdr-match.component.html',
})
export class HdrMatchComponent {
    /**
     * HdrMatch message item instance.
     */
    @Input()
    public editable: HdrMatchConfigItem;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    public readonly l10nKeys = l10nKeys;

    /**
     * Dropdown options for the match operation.
     */
    public readonly matchOperationOptions: IAviDropdownOption[];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.matchOperationOptions = schemaService
            .getEnumValues('HdrMatchOperation')
            .map(({ label, value }) => createDropdownOption(value, label));
    }

    /**
     * Returns true if the value input field should be shown.
     */
    public showValueField(): boolean {
        return this.editable.config.match_criteria && !this.preventConfigureValue();
    }

    /**
     * True if the value field should not be configurable.
     */
    public preventConfigureValue(): boolean {
        return preventConfigureValueSet.has(this.editable.config.match_criteria);
    }

    /**
     * Called when the match criteria changes.
     */
    public handleCriteriaChange(): void {
        if (this.preventConfigureValue()) {
            delete this.editable.config.value;
        }
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
