/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @ngdoc service
 * @name logDataTransform
 * @author Alex Malitsky
 * @description
 *
 *    For each log entry, gotten from API request same transformations should be done in filters
 *    and logCallout controller. Not used by logCallOuts.
 *
 */
angular.module('aviApp').service('logDataTransform', [
'noValueLogLabel', 'schemaService', 'stringService',
function(noValueLogLabel, schemaService, stringService) {
    /** @type {string} */
    const noValueLabel = noValueLogLabel;

    function getDnsRequestResponseRecordLabel(record) {
        let label = '';

        const { type } = record;

        switch (type) {
            case 'DNS_RECORD_CNAME':
                label = `Type: CNAME,  Name: ${record['cname']}`;
                break;

            case 'DNS_RECORD_NS':
                label = `Type: NS, Name: ${record['nsname']}`;
                break;

            case 'DNS_RECORD_A':
            case 'DNS_RECORD_AAAA': {
                const ip = record.addr_ip || record.addr6_ip_str;

                label = `Type: ${type.replace('DNS_RECORD_', '')}, IP Address: ${ip}`;

                if ('site_name' in record) {
                    label += ` (${record['site_name']}:${record['vs_name']})`;
                }

                break;
            }

            default:
                label = `Type: ${record['type']}`;
        }

        if ('ttl' in record) {
            label += ` TTL: ${record['ttl']}`;
        }

        return label;
    }

    function modifyRespCode(code) {
        const res = { val: code };

        if (code) {
            const { label } =
                schemaService.getEnumValue('HTTPResponseCodes', `HTTP_RESPONSE_CODE_${code}`);

            if (label) {
                res.descr = label;
            }
        }

        return res;
    }

    const noValueDisplay = function(value) {
        return {
            display: value === '' ? noValueLabel : value,
            val: value,
        };
    };

    const parsers = {
        server_ip(str) {
            if (str === 0) {
                str = '';
            }

            return noValueDisplay(str);
        },
        vs_ip(str) {
            if (str === 0) {
                str = '';
            }

            return noValueDisplay(str);
        },
        server_ip6(str) {
            return noValueDisplay(str);
        },
        http_version(str) {
            const res = { val: str };

            if (str || str === 0) {
                res.display = `HTTP/${str}`;
            }

            return res;
        },
        response_code: modifyRespCode,
        server_response_code: modifyRespCode,
        ssl_cipher(str) {
            const
                res = { val: str },
                sslRegExp = /^.*?auth:([^\s]+)?.*?enc:([^\s]+)?.*?PFS:([^\s]+)?.*$/i;

            const ssl = sslRegExp.exec(str);

            if (ssl) {
                res.ssl_cert_type = ssl[1];
                res.ssl_enc = ssl[2];
                res.ssl_pfs = ssl[3];
            }

            return res;
        },
        significance(str) {
            const
                res = { val: str },
                enums = schemaService.getEnumValues('AviDefinedFilters'),
                signReasons = str.split(', ');

            if (str) {
                res.display = signReasons.map(function(val) {
                    const res = {};

                    res.val = val;

                    const enumValue = _.find(enums, function(elem) {
                        return elem.label === val;
                    });

                    if (enumValue) {
                        res.descr = enumValue.description;
                    }

                    return res;
                });
            }

            return res;
        },
        ocsp_status_resp_sent(str) {
            let res;

            if (str) {
                res = { val: str };
                res.display = stringService.capitalize(str.toString());
            }

            return res;
        },
        spdy_version(str) {
            let res;

            if (str) {
                res = { val: str };
                res.display = str.slug();
            }

            return res;
        },
        grpc_status(str) {
            let res;

            if (str !== undefined) {
                res = { val: str };
                res.display = str === -1 ? 'N/A' : str;
            }

            return res;
        },
        grpc_status_label(str) {
            let res;

            if (str) {
                res = { val: str };
                res.display = schemaService.getEnumValue('GRPCStatus', str).label ||
                    stringService.enumeration(str, 'GRPC_STATUS_CODE_');
            }

            return res;
        },
        ntlm_log(ntlm_config) {
            let res;

            if (ntlm_config) {
                res = { ...ntlm_config };
                res.display =
                    schemaService.getEnumValue('NtlmStatus', ntlm_config.ntlm_status).label;
            }

            return res;
        },
        microservice_name(str) {
            return {
                display: str || 'External-Client',
                val: str,
            };
        },
        protocol(str) {
            return {
                display: stringService.enumeration(str, 'PROTOCOL_'),
                val: str,
            };
        },
        dns_request(dnsRequest) {
            const { opt_record: optRecord } = dnsRequest;
            let options;

            if (optRecord && (options = optRecord['options']) &&
                Array.isArray(options) && options.length) {
                const [option] = options;

                if (option['subnet_ip']) {
                    option['subnet_ip'] = {
                        display: option['subnet_ip'],
                        val: option['subnet_ip'],
                    };
                }
            }

            return dnsRequest;
        },
        dns_response(responseObj, row) {
            const getDnsRequestResponseRecordShortLabel = record =>
                record.cname || record.nsname ||
                    'addr_ip' in record && record['addr_ip'] ||
                    'dns_ips' in record && record['dns_ips'].join(', ') || '';

            let label = '';

            if ('records' in responseObj) {
                //dns_qtype should be set and we might have it processed
                const qType = row['dns_qtype'] && angular.isString(row['dns_qtype']) ?
                    row['dns_qtype'] : row['dns_qtype'].val;

                responseObj['records'].forEach(function(record) {
                    record.display = getDnsRequestResponseRecordLabel(record);

                    if (!label && (!qType || record['type'] === qType)) {
                        label = getDnsRequestResponseRecordShortLabel(record);
                    }
                });

                //no label found by type
                if (!label) {
                    const suitableRecord =
                        _.find(responseObj['records'], getDnsRequestResponseRecordShortLabel);

                    if (suitableRecord) {
                        label = getDnsRequestResponseRecordShortLabel(suitableRecord);
                    }
                }
            }

            return {
                display: label,
                val: responseObj,
            };
        },
        dns_qtype(str) {
            return {
                display: stringService.enumeration(str, 'DNS_RECORD_').toUpperCase(),
                val: str,
            };
        },
        dns_etype(str) {
            return {
                display: stringService.enumeration(str, 'DNS_ENTRY_'),
                val: str,
            };
        },
        icap_log({ action, request_logs: logs = [] }) {
            return {
                // this top level action represents most severe action out of entire list
                action: {
                    display: action ? stringService.enumeration(action, 'ICAP_') : '-',
                    val: action,
                },
                logs: logs.map(log => transformIcapLogProps(log)),
            };
        },
        oob_log({ ds_req_logs: dsRequestLogs = [] }) {
            return {
                eventCount: dsRequestLogs.length,
                logs: dsRequestLogs.map(dsRequestLog => transformDSRequestLog(dsRequestLog)),
            };
        },
        bot_management_log({ classification, results: logs = [] }) {
            return {
                classification: {
                    type: {
                        display: classification.type ?
                            stringService.enumeration(classification.type) : '-',
                        val: classification.type,
                    },
                    user_defined_type: {
                        display: classification.user_defined_type ?
                            stringService.enumeration(classification.user_defined_type) : '-',
                        val: classification.user_defined_type,
                    },
                },
                results: logs.map(result => transformBotDetectionPolicyLogs(result)),
            };
        },
        bot_management_log_classification_type(str) {
            let result;

            if (str) {
                result = stringService.enumeration(str);
            }

            return result;
        },
    };

    /**
     * Transforms violations into usable list.
     * @param {IIcapViolation[]} violations
     * @returns {IIcapLogViolation[]}
     */
    function transformIcapViolations(violations) {
        return violations.map(
            ({ file_name: fileName,
                threat_name: threatName,
                resolution }) => {
                return {
                    file_name: {
                        val: fileName,
                        display: fileName || '-',
                    },
                    threat_name: {
                        val: threatName,
                        display: threatName || '-',
                    },
                    resolution: {
                        val: resolution,
                        display: resolution ?
                            `ICAP ${stringService.enumeration(resolution, 'ICAP_')}` : '-',
                    },
                };
            },
        );
    }

    /**
     * Transforms ICAP OPSWAT Logs into usable list.
     * @param {IIcapOPSWATLog} opswatLogs
     * @returns {IIcapOPSWATLogsData}
     */
    function transformOpswatLogs(opswatLogs) {
        return {
            reason: {
                val: opswatLogs.reason,
                display: opswatLogs.reason || '-',
            },
            threat_id: {
                val: opswatLogs.threat_id,
                display: opswatLogs.threat_id || '-',
            },
            violations: opswatLogs.violations ?
                transformIcapViolations(opswatLogs.violations) : [],
        };
    }

    /**
     * Transforms ICAP NSX Defender Logs into usable list.
     * @param {IIcapNSXDefenderLog} nsxDefenderLogs
     * @returns {IIcapNSXDefenderLogsData}
     */
    function transformNsxDefenderLogs(nsxDefenderLogs) {
        return {
            score: {
                val: nsxDefenderLogs.score,
                display: nsxDefenderLogs.score || '-',
            },
            status_url: {
                val: nsxDefenderLogs.status_url,
                display: nsxDefenderLogs.status_url || '-',
            },
            task_uuid: {
                val: nsxDefenderLogs.task_uuid,
                display: nsxDefenderLogs.task_uuid || '-',
            },
        };
    }

    /**
     * Outputs values needed for single icapLog entry.
     * @param {IIcapRequestLog} requestLog - single icapLog entry from protobuf
     * @returns {IIcapLogDisplayRow}
     */
    function transformIcapLogProps(requestLog) {
        return {
            action: {
                val: requestLog.action,
                display: requestLog.action ?
                    stringService.enumeration(requestLog.action, 'ICAP_') : '-',
            },
            source_port: {
                val: requestLog.source_port,
                display: requestLog.source_port || '-',
            },
            pool_name: {
                val: requestLog.pool_name?.val,
                display: requestLog.pool_name?.val || '-',
            },
            icap_server_ip: {
                val: requestLog.icap_server_ip,
                display: requestLog.icap_server_ip || '-',
            },
            icap_headers_sent_to_server: {
                val: requestLog.icap_headers_sent_to_server,
                display: requestLog.icap_headers_sent_to_server || '-',
            },
            icap_headers_received_from_server: {
                val: requestLog.icap_headers_received_from_server,
                display: requestLog.icap_headers_received_from_server || '-',
            },
            icap_server_port: {
                val: requestLog.icap_server_port,
                display: requestLog.icap_server_port?.toString() || '-',
            },
            latency: {
                val: requestLog.latency,
                display: requestLog.latency ? `${requestLog.latency} ms` : '-',
            },
            http_method: {
                val: requestLog.http_method,
                display: requestLog.http_method ?
                    stringService.enumeration(requestLog.http_method, 'HTTP_METHOD_') : '-',
            },
            complete_body_sent: {
                val: requestLog.complete_body_sent,
                display: typeof requestLog.complete_body_sent === 'boolean' ?
                    stringService.capitalize(requestLog.complete_body_sent.toString()) : '-',
            },
            icap_absolute_uri: {
                val: requestLog.icap_absolute_uri,
                display: requestLog.icap_absolute_uri || '-',
            },
            icap_method: {
                val: requestLog.icap_method,
                display: requestLog.icap_method ?
                    stringService.enumeration(requestLog.icap_method, 'ICAP_METHOD_') : '-',
            },
            http_response_code: {
                val: requestLog.http_response_code,
                display: requestLog.http_response_code || '-',
            },
            modified_content_length: {
                val: requestLog.modified_content_length,
                display: requestLog.modified_content_length || '-',
            },
            /* http_response_code will be absent when icap_response_code is absent,
               except when icap_response_code is 204 */
            icap_response_code: {
                val: requestLog.icap_response_code,
                display: requestLog.icap_response_code || '-',
            },
            vendor: {
                val: requestLog.vendor,
                display: schemaService.getEnumValue('ICAPVendor', requestLog.vendor).label || '-',
            },
            opswat_log: requestLog.opswat_log ?
                transformOpswatLogs(requestLog.opswat_log) : null,

            nsx_defender_log: requestLog.nsx_defender_log ?
                transformNsxDefenderLogs(requestLog.nsx_defender_log) : null,
        };
    }

    /**
     * Outputs values needed for single Out of Band DSRequestLog entry.
     * @param {DSRequestLog} dsRequestLog - single Out of Band DSrequestLog entry from protobuf.
     * @returns {IDSRequestLogRow}
     */
    function transformDSRequestLog(dsRequestLog) {
        return {
            ds_name: {
                val: dsRequestLog.ds_name,
                display: dsRequestLog.ds_name || '-',
            },
            event: {
                val: dsRequestLog.event,
                display: schemaService
                    .getEnumValue('VSDataScriptEvent', dsRequestLog.event)?.label || '-',
            },
            pool_name: {
                val: dsRequestLog.pool_name?.val,
                display: dsRequestLog.pool_name?.val || '-',
            },
            pool_uuid: {
                val: dsRequestLog.pool_uuid,
                display: dsRequestLog.pool_uuid || '-',
            },
            source_port: {
                val: dsRequestLog.source_port,
                display: dsRequestLog.source_port || '-',
            },
            total_time: {
                val: +dsRequestLog.total_time,
                display: dsRequestLog.total_time ? `${dsRequestLog.total_time} ms` : '-',
            },
            server_name: {
                val: dsRequestLog.server_name?.val,
                display: dsRequestLog.server_name?.val || '-',
            },
            server_ip: {
                val: dsRequestLog.server_ip,
                display: dsRequestLog.server_ip || '-',
            },
            server_port: {
                val: dsRequestLog.server_port,
                display: dsRequestLog.server_port || '-',
            },
            servers_tried: {
                val: dsRequestLog.servers_tried,
                display: dsRequestLog.servers_tried || '-',
            },
            method: {
                val: dsRequestLog.method,
                display: dsRequestLog.method || '-',
            },
            http_version: {
                val: dsRequestLog.http_version?.val,
                display: dsRequestLog.http_version?.val || '-',
            },
            uri_path: {
                val: dsRequestLog.uri_path,
                display: dsRequestLog.uri_path || '-',
            },
            uri_query: {
                val: dsRequestLog.uri_query,
                display: dsRequestLog.uri_query || '-',
            },
            request_length: {
                val: dsRequestLog.request_length,
                display: dsRequestLog.request_length,
            },
            http_response_code: {
                val: dsRequestLog.http_response_code,
                display: dsRequestLog.http_response_code || '-',
            },
            response_length: {
                val: dsRequestLog.response_length,
                display: dsRequestLog.response_length,
            },
            headers_sent_to_server: {
                val: dsRequestLog.headers_sent_to_server,
                display: dsRequestLog.headers_sent_to_server || '-',
            },
            headers_received_from_server: {
                val: dsRequestLog.headers_received_from_server,
                display: dsRequestLog.headers_received_from_server || '-',
            },
        };
    }

    /**
     * Outputs values needed for single botDetectionPolicyLog entry.
     * @param {IBotManagementLog} resultLog - single botDetectionPolicyLog entry from protobuf
     * @returns {IBotDetectionLogRow}
     */
    function transformBotDetectionPolicyLogs(resultLog) {
        return {
            component: {
                val: resultLog.component,
                display: resultLog.component ? stringService.enumeration(resultLog.component) : '-',
            },
            confidence: {
                val: resultLog.confidence,
                display: resultLog.component ?
                    stringService.enumeration(resultLog.confidence) : '-',
            },
            identification: {
                class: {
                    val: resultLog.identification.class,
                    display: resultLog.identification.class ?
                        stringService.enumeration(resultLog.identification.class) : '-',
                },
                identifier: {
                    val: resultLog.identification.identifier,
                    display: resultLog.identification.identifier ?
                        resultLog.identification.identifier : '-',
                },
                type: {
                    val: resultLog.identification.type,
                    display: resultLog.identification.type ?
                        stringService.enumeration(resultLog.identification.type) : '-',
                },
            },
            notes: resultLog.notes ? resultLog.notes.join(' ') : '-',
        };
    }

    /**
     * Transformer for log entry property.
     * @param {string} name - Log property name.
     * @param {*} val - Particular property value.
     * @param {Object=} row - Log entry.
     * @returns {*} Transformed property.
     * @public
     */
    this.propOnLoad = function(name, val, row) {
        if (name in parsers) {
            return parsers[name](val, row);
        } else {
            return val;
        }
    };

    /**
     * Modifies log entry on load using all defined property parsers.
     * @param {Object} row
     * @returns {*} Transformed log entry object.
     * @public
     */
    this.rowOnLoad = function(row) {
        function logType(row) {
            return row['adf'] && 'adf' || row['udf'] && 'udf' || 'ndf';
        }

        row.id = [
            row.service_engine,
            row.vcpu_id,
            logType(row),
            row.log_id,
            row.report_timestamp,
        ].join('/');

        row.dt_start = moment(row.report_timestamp).subtract(row.total_time, 'ms');

        if (typeof row.data_transfer_time === 'undefined') { //pseudo, have no d_t_t for layer 4
            row.data_transfer_time = row.total_time - row.server_rtt - row.client_rtt;
        }

        if ('waf_log' in row) {
            const wafLogTime = [
                'latency_request_header_phase',
                'latency_request_body_phase',
                'latency_response_header_phase',
                'latency_response_body_phase',
            ].reduce(
                (acc, fieldName) => acc + Number(row['waf_log'][fieldName]),
                0,
            );

            row['waf_log_time'] = Math.round(wafLogTime / 1000);
        }

        _.each(parsers, (parser, fieldName) => {
            if (fieldName in row) {
                row[fieldName] = parser(row[fieldName], row);
            }
        });

        return row;
    };
}]);
