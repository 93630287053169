/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'logs';
const componentName = 'l4-datascript-log';
const prefix = `${moduleName}.${componentName}`;

export const datascriptHeader = `${prefix}.datascriptHeader`;
export const datascriptLogGridTitle = `${prefix}.datascriptLogGridTitle`;

export const ENGLISH = {
    [datascriptHeader]: 'Datascript',
    [datascriptLogGridTitle]: 'Datascript Log',
};
