/**
 * @module AviFormsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { Directive } from '@angular/core';

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';

import { isNull, isUndefined } from 'underscore';

import { cronExpressionValidator }
    from 'ng/modules/avi-forms/validators/cron-expression-validation';

/**
 * @description
 *      This directive validates the cron expression input field.
 *      Invalidates the form when the value is either invalid or not supported yet.
 *
 * @example
 *      <avi-input-container>
 *          <input
 *              clrInput
 *              [(ngModel)]="value"
 *              name="value"
 *              cronExpressionValidation
 *         />
 *    </avi-input-container>
 *
 * @author Harmeet Kaur
 */

@Directive({
    providers: [{
        multi: true,
        provide: NG_VALIDATORS,
        useExisting: CronExpressionValidationDirective,
    }],
    selector: `[cronExpressionValidation][formControlName],
        [cronExpressionValidation][formControl],
        [cronExpressionValidation][ngModel]`,
})
export class CronExpressionValidationDirective implements Validator {
    /**
     * @override
     * Checks the cron expression if the value is defined,
     * since we don't want to invalidate a form with
     * empty input fields that are optional.
     */
    public validate(control: AbstractControl): ValidationErrors | null {
        return !isUndefined(control.value) && !isNull(control.value) ?
            cronExpressionValidator()(control) :
            null;
    }
}
