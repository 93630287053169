/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'custom-ipam';
const prefix = `${moduleName}.${componentName}`;

export const columnTitleName = `${prefix}.columnTitleName`;
export const columnTitleScript = `${prefix}.columnTitleScript`;
export const headerLabel = `${prefix}.headerLabel`;

export const ENGLISH = {
    [columnTitleName]: 'Name',
    [columnTitleScript]: 'Script',
    [headerLabel]: 'Custom IPAM/DNS Profiles',
};
