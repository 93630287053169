/**
 * @module NetworkModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';

import {
    IIpAddrRange,
    StaticIpType,
} from 'generated-types';

import {
    ConfiguredNetwork,
    IConfiguredNetworkSubnet,
    IConfiguredNetworkWithRuntime,
} from 'ajs/modules/network';

import { L10nService } from '@vmw/ngx-vip';

import './configured-network-subnet-expander.component.less';
import * as l10n from './configured-network-subnet-expander.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

const STATIC_IPS_FOR_SE_LABEL = 'SE';
const STATIC_IPS_FOR_VIP_LABEL = 'VIP';
const STATIC_IPS_FOR_VIP_AND_SE_LABEL = 'SE, VIP';

/**
 * @description Component to display subnet prefix and list its staticIpPools.
 *
 * @author Aravindh Nagarajan
 */
@Component({
    selector: 'configured-network-subnet-expander',
    templateUrl: './configured-network-subnet-expander.component.html',
})
export class ConfiguredNetworkSubnetExpanderComponent {
    /**
     * Configured Subnet object.
     *
     * Can be invoked from `network-list-expander component` with IConfiguredNetworkWithRuntime
     * or from `modalIpAddrList directive` with IConfiguredNetworkSubnet.
     */
    @Input()
    public subnet: IConfiguredNetworkWithRuntime | IConfiguredNetworkSubnet;

    /**
     * Will be set to true if component is invoked from modalIpAddrList directive.
     *
     * In that case, component should display subnet and type from static_ipaddr_temp rather
     * than static_ip_ranges, since thats the primary datastructure for the modal -
     * ie, changes will be reflected only in static_ipaddr_temp.
     */
    @Input()
    public fromNetworkEdit = false;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Hash of Static Ip Pool Types and its labels.
     */
    public readonly staticIpPoolTypeLabelHash = {
        [StaticIpType.STATIC_IPS_FOR_SE]: STATIC_IPS_FOR_SE_LABEL,
        [StaticIpType.STATIC_IPS_FOR_VIP]: STATIC_IPS_FOR_VIP_LABEL,
        [StaticIpType.STATIC_IPS_FOR_VIP_AND_SE]: STATIC_IPS_FOR_VIP_AND_SE_LABEL,
    };

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Returns IpAddrRange object as readable string.
     */
    public getFormattedStaticIpRange(range: IIpAddrRange): string {
        const {
            begin: { addr: rangeStart },
            end: { addr: rangeEnd },
        } = range;

        return `${rangeStart} - ${rangeEnd}`;
    }

    /**
     * Returns a readable subnet string with runtime if exists.
     */
    public getFormattedSubnet(
        subnet: IConfiguredNetworkWithRuntime | IConfiguredNetworkSubnet,
    ): string {
        return ConfiguredNetwork.formatSubnetRuntimeData(subnet);
    }

    /**
     * Returns subnet type label.
     */
    public getSubnetTypeLabel(type = StaticIpType.STATIC_IPS_FOR_VIP_AND_SE): string {
        return this.staticIpPoolTypeLabelHash[type];
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }
}
