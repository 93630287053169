/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'cloud-connector-user';
const componentName = 'nsxt-credentials';
const prefix = `${moduleName}.${componentName}`;

export const usernameFieldLabel = `${prefix}.usernameFieldLabel`;
export const passwordFieldLabel = `${prefix}.passwordFieldLabel`;
export const usernameFieldPlaceholder = `${prefix}.usernameFieldPlaceholder`;
export const passwordFieldPlaceholder = `${prefix}.passwordFieldPlaceholder`;

export const ENGLISH = {
    [usernameFieldLabel]: 'Username',
    [passwordFieldLabel]: 'Password',
    [usernameFieldPlaceholder]: 'Enter Username',
    [passwordFieldPlaceholder]: 'Enter Password',
};
