/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module AnalyticsModule
 */

import { withEditChildMessageItemMixin } from 'ajs/modules/data-model/mixins';

import {
    MessageItem,
    RepeatedMessageItem,
} from 'ajs/modules/data-model/factories';

import { SensitiveLogProfile } from 'object-types';
import { ISensitiveLogProfile } from 'generated-types';
import { SensitiveFieldRuleConfigItem } from './sensitive-field-rule.config-item.factory';

type TSensitiveLogProfilePartial = Omit<
ISensitiveLogProfile, 'header_field_rules' | 'uri_query_field_rules' | 'waf_field_rules'
>;

interface ISensitiveLogProfileConfig extends TSensitiveLogProfilePartial {
    header_field_rules?: RepeatedMessageItem<SensitiveFieldRuleConfigItem>,
    uri_query_field_rules?: RepeatedMessageItem<SensitiveFieldRuleConfigItem>,
    waf_field_rules?: RepeatedMessageItem<SensitiveFieldRuleConfigItem>,
}

/**
 * @description Sensitive Log Profile Message Item.
 *
 * @author sghare
 */
export class SensitiveLogProfileConfigItem extends
    withEditChildMessageItemMixin(MessageItem)<ISensitiveLogProfileConfig> {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: SensitiveLogProfile,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * Opens modal to add Sensitive Information Rule.
     */
    public addSensitiveInformationRule(fieldName: string): void {
        this.addChildMessageItem({
            field: fieldName,
        });
    }

    /**
     * Opens modal to edit Sensitive Information Rule.
     */
    public editSensitiveInformationRule(sensitiveFieldRule: SensitiveFieldRuleConfigItem): void {
        this.editChildMessageItem({
            field: sensitiveFieldRule.fieldName,
            messageItem: sensitiveFieldRule,
            modalBindings: {
                editMode: true,
            },
        });
    }

    /**
     * Removes Sensitive Information Rule.
     */
    public removeSensitiveInformationRule(sensitiveFieldRule: SensitiveFieldRuleConfigItem): void {
        const { [sensitiveFieldRule.fieldName]: RulesList } = this.config;

        RulesList.removeByMessageItem(sensitiveFieldRule);
    }

    /** @override */
    protected requiredFields(): string[] {
        return [
            'header_field_rules',
            'uri_query_field_rules',
            'waf_field_rules',
        ];
    }
}
