/**
 * @module PoliciesModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { PolicyActionConfigItem } from './policy-action.config-item.factory';
import { PolicyMatchConfigItem } from './policy-match.config-item.factory';
import { IPolicyActionInfo, IPolicyMatchInfo } from '../../policies.types';

export interface IPolicyRuleConfig {
    match: PolicyMatchConfigItem;
    action: PolicyActionConfigItem;
}

export const POLICY_RULE_EXTENDABLE_CONFIG_ITEM_TOKEN = 'PolicyRuleExtendableConfigItem';

/**
 * @description
 *
 *     Policy Rule ExtendableConfigItem class. Used for protobuf messages that map to rules in a
 *     policy. ie. L4Rule in L4ConnectionPolicy. This class works as an abstraction which contains
 *     routine operations (fetching matches/actions, enabling a rule, etc.) of a policy rule.
 *
 * @author Zhiqian Liu
 */
export abstract class PolicyRuleExtendableConfigItem<
    T extends IPolicyRuleConfig
> extends MessageItem<T> {
    /**
     * Abstract getter to check if the rule is enabled.
     * Rules can have different names for the field representing "enabled."
     * This getter is to be overriden to return that specific enabled field.
     */
    public abstract get enabled(): boolean;

    /**
     * Abstract setter to set the rule as enabled / disabled.
     * To be overriden to have the specific field set.
     */
    public abstract set enabled(isEnabled: boolean);

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<T> {
        const { objectType: type } = this;
        const defaultValues = this.getAjsDependency_('defaultValues');
        const defaultConfig = defaultValues.getDefaultItemConfigByType(type) || {};

        return {
            ...defaultConfig,
            match: null,
        };
    }

    /**
     * Getter for the number of matches in a rule.
     */
    public get matchCount(): number {
        return this.config.match?.matchCount || 0;
    }

    /**
     * Getter for the number of actions in a rule.
     */
    public get actionCount(): number {
        return this.config.action?.actionCount || 0;
    }

    /**
     * Getter for match configItem of a rule.
     */
    public get match(): PolicyMatchConfigItem {
        return this.config.match || null;
    }

    /**
     * Getter for action configItem of a rule.
     */
    public get action(): PolicyActionConfigItem {
        return this.config.action || null;
    }

    /**
     * Getter for the list of configured matches with label/value pairs.
     */
    public get configuredMatchInfoList(): IPolicyMatchInfo[] {
        return this.match?.getConfiguredMatchInfoList() || [];
    }

    /**
     * Getter for the list of configured actions with label/value pairs.
     */
    public get configuredActionInfoList(): IPolicyActionInfo[] {
        return this.action?.getConfiguredActionInfoList() || [];
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    protected requiredFields(): string[] {
        return ['match', 'action'];
    }
}

PolicyRuleExtendableConfigItem.ajsDependencies = [
    'defaultValues',
];
