/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    Input,
} from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { BotClassMatcherConfigItem } from 'ajs/modules/match/factories';
import * as l10n from './bot-class-matcher-readonly.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Component to show readonly information for bot-class-matcher.
 * @author Akul Aggarwal
 */
@Component({
    selector: 'bot-class-matcher-readonly',
    templateUrl: './bot-class-matcher-readonly.component.html',
})
export class BotClassMatcherReadonlyComponent {
    @Input()
    public editable: BotClassMatcherConfigItem;

    public readonly l10nKeys = l10nKeys;

    constructor(
        l10nService: L10nService,
        private readonly schemaService: SchemaService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Convenience method to get text label for selected match criteria.
     */
    public get matchCriteria(): string {
        return this.schemaService.getEnumValueLabel(
            'MatchOperation',
            this.editable.config.op,
        );
    }

    /**
     * Returns text for list of classes, if any, corresponding to criteria.
     */
    public get classTexts(): string[] {
        return this.editable.config.client_classes.map(clientClass => {
            return this.schemaService.getEnumValueLabel(
                'BotClientClass',
                clientClass,
            );
        });
    }

    /**
     * Used in *ngFor.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
