/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import * as l10n from './virtualservice-delete.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @ngdoc component
 * @name virtualserviceDelete
 * Modal that allows the user to delete just the Virtual Service or the Virtual Service along with
 * all its children, ie. pools and pool groups.
 * @param {VirtualService[]} rows
 * @param {VirtualServiceCollection} collection
 * @param {Function} closeModal - closes this modal.
 */
class VirtualserviceDeleteController {
    constructor(l10nService) {
        this.l10nKeys = l10nKeys;

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Deletes either the Virtual Service or the Virtual Service and its children depending on
     * the force param.
     * @param  {Boolean} force - If true, deletes all associated chidlren.
     */
    delete(force = false) {
        if (angular.isArray(this.rows) && !angular.isUndefined(this.collection)) {
            this.collection.dropByPriority(this.rows, force);
        }

        this.closeModal();
    }
}

VirtualserviceDeleteController.$inject = [
    'l10nService',
];

angular.module('aviApp').component('virtualserviceDelete', {
    bindings: {
        rows: '<',
        collection: '<',
        closeModal: '&',
    },
    controller: VirtualserviceDeleteController,
    templateUrl: 'src/components/applications/virtualservice/virtualservice-list/' +
            'virtualservice-delete/virtualservice-delete.html',
});
