/**
 * @module PoolModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
*/

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import {
    HttpReselectRespCodeBlock,
    IHTTPServerReselect,
    IHTTPStatusRange,
} from 'generated-types';
import { HTTPServerReselect } from 'object-types';
import { HTTPReselectRespCodeConfigItem } from './http-reselect-resp-code.config-item.factory';

type THttpServerReselectConfigPartial = Omit<IHTTPServerReselect, 'svr_resp_code'>;
interface IHTTPServerReselectConfig extends THttpServerReselectConfigPartial {
    svr_resp_code: HTTPReselectRespCodeConfigItem
}

/**
 * @description
 *
 *   HTTPServerReselect config item.
 *
 * @author Rachit Aggarwal
 */
export class HTTPServerReselectConfigItem extends MessageItem<IHTTPServerReselectConfig> {
    /**
     * @constructor
     */
    constructor(args = {}) {
        const extendedArgs = {
            objectType: HTTPServerReselect,
            ...args,
        };

        super(extendedArgs);
    }

    /**
     * @override
     */
    protected requiredFields(): string[] {
        return ['svr_resp_code'];
    }

    /**
     * Sets Codes property of server response code.
     */
    public set serverResponseCodes(codes: number[]) {
        this.config.svr_resp_code.serverResponseCodes = codes;
    }

    /**
     * Sets Ranges property of server response code.
     */
    public set serverResponseCodeRanges(ranges: IHTTPStatusRange[]) {
        this.config.svr_resp_code.serverResponseCodeRanges = ranges;
    }

    /**
     * Sets Code block property of server response code.
     */
    public set serverResponseCodeBlock(block: HttpReselectRespCodeBlock[]) {
        this.config.svr_resp_code.serverResponseCodeBlock = block;
    }

    /**
     * Getter for enabled property.
     */
    public get enabled(): boolean {
        return this.config.enabled;
    }

    /**
     * setter for enabled property.
     */
    public set enabled(enabled: boolean) {
        this.config.enabled = enabled;
    }
}
