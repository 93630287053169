/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'licensing';
const componentName = 'licensing-page-header';
const prefix = `${moduleName}.${componentName}`;

export const unlockMoreFeaturesMessage = `${prefix}.unlockMoreFeaturesMessage`;
export const licensingLabel = `${prefix}.licensingLabel`;
export const learnMoreBtnLabel = `${prefix}.learnMoreBtnLabel`;

export const ENGLISH = {
    [unlockMoreFeaturesMessage]: 'Unlock more features and possibilities with Enterprise edition.',
    [licensingLabel]: 'Licensing',
    [learnMoreBtnLabel]: 'Learn More',
};
