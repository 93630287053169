/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import { VIRTUAL_SERVICE_STATE_SERVICE_TOKEN } from '../../../../downgrade-services.tokens';
import './virtualservice-detail.less';

/**
 * @ngdoc component
 * @name  virtualserviceDetail
 * @param  {VirtualService} loadedVirtualService
 * @description
 *
 *     VirtualService detail page containing the VirtualService child pages.
 */
class VirtualserviceDetailController {
    constructor(
        $scope,
        $rootScope,
        myAccount,
        virtualServiceStateService,
    ) {
        this.$scope_ = $scope;
        this.myAccount = myAccount;

        // TODO: this service is needed to share this instance of VS item
        // across modules. To be removed when item infrastructure updated to Store.
        this.virtualServiceStateService = virtualServiceStateService;

        // Emulating what is provided on $rootScope from app.js for child pages, since this is
        // now using an isolated scope. TODO: remove when possible.
        Object.assign(
            this.$scope_,
            _.pick(
                $rootScope,
                '$location',
                '$state',
                '$stateParams',
                'timeouts',
                'allowed',
            ),
        );
    }

    $onInit() {
        const { loadedVirtualService: vs } = this;

        this.$scope_.vs = vs;
        this.virtualServiceStateService.virtualService = vs;

        /** @deprecated */
        this.$scope_.VirtualService = vs;
    }

    $onDestroy() {
        this.loadedVirtualService.destroy();
    }
}

VirtualserviceDetailController.$inject = [
    '$scope',
    '$rootScope',
    'myAccount',
    VIRTUAL_SERVICE_STATE_SERVICE_TOKEN,
];

angular.module('aviApp').component('virtualserviceDetail', {
    bindings: {
        loadedVirtualService: '<resolveVS',
    },
    controller: VirtualserviceDetailController,
    templateUrl: 'src/components/pages/item-detail/virtualservice-detail/' +
        'virtualservice-detail.html',
});
