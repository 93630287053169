/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'vs-logs';
const componentName = 'vs-log-cinematic-dns-opt-record';
const prefix = `${moduleName}.${componentName}`;

export const dnsOptRecordLabel = `${prefix}.dnsOptRecordLabel`;
export const udpPayloadSizeLabel = `${prefix}.udpPayloadSizeLabel`;
export const dnssecLabel = `${prefix}.dnssecLabel`;
export const ednsRecordsLabel = `${prefix}.ednsRecordsLabel`;
export const ednsOptionCodeLabel = `${prefix}.ednsOptionCodeLabel`;
export const sourcePrefixLengthLabel = `${prefix}.sourcePrefixLengthLabel`;
export const scopePrefixLengthLabel = `${prefix}.scopePrefixLengthLabel`;

export const ENGLISH = {
    [dnsOptRecordLabel]: 'DNS Opt Record',
    [udpPayloadSizeLabel]: 'UDP Payload Size',
    [dnssecLabel]: 'DNSSEC',
    [ednsRecordsLabel]: 'EDNS Records ({0})',
    [ednsOptionCodeLabel]: 'EDNS Option Code',
    [sourcePrefixLengthLabel]: 'Source Prefix Length',
    [scopePrefixLengthLabel]: 'Scope Prefix Length',
};
