/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'waf-policy';
const componentName = 'vs-waf-top-hits-data-transformer';
const prefix = `${moduleName}.${componentName}`;

export const allOtherValuesLabel = `${prefix}.allOtherValuesLabel`;

export const ENGLISH = {
    [allOtherValuesLabel]: 'All Other Values',
};
