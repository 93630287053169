/**
 * @module AlertModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { ClrFormLayout } from '@clr/angular';

import {
    SnmpTrapServerConfigItem,
} from 'ajs/modules/alert/factories/snmp-trap-profile/snmp-trap-server.config-item.factory';

import * as l10n from './snmp-trap-server-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *
 * SnmpTrapProfileList modal component.
 *
 * @author Guru Prasad
 */
@Component({
    selector: 'snmp-trap-server-modal',
    templateUrl: './snmp-trap-server-modal.component.html',
})
export class SnmpTrapProfileListModalComponent implements OnInit {
    /**
     * SnmpTrapServerConfigItem instance.
     */
    @Input()
    public editable: SnmpTrapServerConfigItem;

    /**
     * Fires on cancel.
     */
    @Output()
    public onCancel = new EventEmitter<void>();

    /**
     * Fires on submit.
     */
    @Output()
    public onSubmit = new EventEmitter<void>();

    /**
     * Keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * SnmpTrapProfileList object type.
     */
    public objectType: string;

    /**
     * Layout for SnmpTrapProfileList modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }

    /**
     * Fires on submit.
     */
    public submit(): void {
        this.onSubmit.emit();
    }

    /**
     * Fires on cancel.
     */
    public cancel(): void {
        this.onCancel.emit();
    }

    /**
     * Trackby function for the *ngFor of trapServers.
     */
    public trackByIndex(index: number): number {
        return index;
    }
}
