/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module ServiceEngineModule */

import {
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { L10nService } from '@vmw/ngx-vip';
import { IDebugServiceEngine } from 'generated-types';
import { Subscription } from 'rxjs';
import { AviDatePipe } from 'ng/modules/core/pipes';
import { AsyncFactory } from 'ajs/modules/core/factories';

import {
    IAviDataGridConfig,
    IAviDataGridRow,
} from 'ng/modules/data-grid/components/avi-data-grid/avi-data-grid.types';

import * as globalL10n from 'global-l10n';
import './traffic-capture-page.component.less';
import * as l10n from './traffic-capture-page.l10n';

import {
    ICaptureFiles,
    IStopCaptureConfig,
    ITrafficCaptureCard,
} from '../../types/service-engine.types';

import { ServiceEngineTrafficCaptureStore }
    from '../../component-store/service-engine-traffic-capture.store';

import { TrafficCaptureModalService } from '../../services/traffic-capture-modal.service';

const { ...globalL10nKeys } = globalL10n;
const { ENGLISH: dictionary, ...l10nKeys } = l10n;

type TAsyncFactory = typeof AsyncFactory;

/**
 * @description Parent component holding Traffic capture related component(s).
 * @author Suraj Kumar
 */
@Component({
    selector: 'traffic-capture-page',
    templateUrl: './traffic-capture-page.component.html',
})
export class TrafficCapturePageComponent implements OnInit, OnDestroy {
    /**
     * Instance of serviceEngine Item.
     */
    @Input('resolveSE')
    private serviceEngine: any; // To do need to define type, once serviceEngine is migrated to ts.

    /**
     * Capture Files Grid config.
     */
    public capturesGridConfig: IAviDataGridConfig;

    /**
     * Traffic capture card configuration.
     */
    public trafficCaptureCardConfiguration: ITrafficCaptureCard;

    /**
     * Indicate whether to show success message or not.
     */
    public showSuccessMessage = false;

    /**
     * Get keys from source bundles for template usage.
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Get keys from global source bundles for template usage.
     */
    private readonly globalL10nKeys = globalL10nKeys;

    /**
     * Instance of debugServiceEngine, which is used as traffic capture configuration.
     */
    private debugServiceEngine: IDebugServiceEngine;

    /**
     * Subscription for debugServiceEngine changes.
     */
    private debugServiceEngineSubscription: Subscription;

    /**
     * Instance of AsyncFactory when we have an ongoing polling.
     */
    private pollGetAllCaptureFiles: AsyncFactory = null;

    constructor(
        public readonly serviceEngineTrafficCaptureStore: ServiceEngineTrafficCaptureStore,
        private readonly trafficCaptureModalService: TrafficCaptureModalService,
        private readonly aviDatePipe: AviDatePipe,
        @Inject('AsyncFactory')
        private readonly AsyncFactory: TAsyncFactory,
        public l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);

        this.pollGetAllCaptureFiles = new this.AsyncFactory(
            async() => {
                await this.serviceEngineTrafficCaptureStore.getAllCaptureFiles();
            },
        );
    }

    /** @override */
    public ngOnInit(): void {
        const { l10nService, l10nKeys } = this;

        this.serviceEngineTrafficCaptureStore.setServiceEngine(this.serviceEngine.getConfig());
        this.setTrafficCaptureCardConfiguration();
        this.serviceEngineTrafficCaptureStore.getCurrentCapture();
        this.serviceEngineTrafficCaptureStore.getAllCaptureFiles();
        this.startGetAllCaptureFilesPolling();

        this.debugServiceEngineSubscription =
            this.serviceEngineTrafficCaptureStore.debugServiceEngine$.subscribe(
                (debugServiceEngine: IDebugServiceEngine) => {
                    this.debugServiceEngine = debugServiceEngine;
                    this.setTrafficCaptureCardConfiguration();
                },
            );

        this.capturesGridConfig = {
            getRowId: (index, row: IAviDataGridRow) => row.modified,
            fields: [{
                id: 'date',
                label: l10nService.getMessage(globalL10nKeys.dateLabel),
                transform: row => this.aviDatePipe.transform(row.modified),
            },
            {
                id: 'service-engine-name',
                label: l10nService.getMessage(l10nKeys.serviceEngineColumnTitle),
                transform: row => row.name,
            },
            {
                id: 'size',
                label: l10nService.getMessage(l10nKeys.sizeColumnTitle),
                transform: row => row.size,
            }],
            singleactions: [{
                label: this.l10nService.getMessage(globalL10nKeys.downloadLabel),
                shape: 'download',
                onClick: row => window.open(row.url, '_blank'),
            },
            {
                label: l10nService.getMessage(globalL10nKeys.removeLabel),
                shape: 'trash',
                onClick: row => this.deleteEntry(row),
            }],
            multipleactions: [{
                label: l10nService.getMessage(globalL10nKeys.deleteLabel),
                onClick: rows => this.deleteEntries(rows),
            }],
        };
    }

    /**
     * Invoked when we stop capture.
     */
    public onStopCapture(): void {
        const stopCaptureConfig: IStopCaptureConfig = {
            config: this.debugServiceEngine,
            whenStopped: () => {
                this.showSuccessMessage = true;
            },
        };

        this.serviceEngineTrafficCaptureStore.stopCapture(stopCaptureConfig);
    }

    /**
     * Invoked when we start capture.
     */
    public onStartCapture(): void {
        const serviceEngineName = this.serviceEngine.getConfig()?.name || '';

        this.clearSuccessAlert();
        this.trafficCaptureModalService.openModal(serviceEngineName);
    }

    /**
     *  Method for clearing error message alert.
     */
    public clearErrorAlert(): void {
        this.serviceEngineTrafficCaptureStore.setError(undefined);
    }

    /**
     *  Method for clearing success message alert.
     */
    public clearSuccessAlert(): void {
        this.showSuccessMessage = false;
    }

    /** @override */
    public ngOnDestroy(): void {
        this.debugServiceEngineSubscription.unsubscribe();
        this.stopGetAllCaptureFilesPolling();
        this.serviceEngineTrafficCaptureStore.cancelAllRequests();
        this.clearSuccessAlert();
    }

    /**
     * Setter for card configuration.
     */
    private setTrafficCaptureCardConfiguration(): void {
        const trafficCaptureCardConfiguration: ITrafficCaptureCard = {
            typesToCapture: this.debugServiceEngine?.flags,
            pktSize: this.debugServiceEngine?.capture_params?.pkt_size,
            srcPort: this.debugServiceEngine?.capture_filters?.src_port,
        };

        this.trafficCaptureCardConfiguration = trafficCaptureCardConfiguration;
    }

    /**
     * Delete group of capture files from the list.
     */
    private deleteEntries(entries: ICaptureFiles[]): void {
        entries.forEach(entry => this.deleteEntry(entry));
    }

    /**
     * Delete single capture file from the list.
     */
    private deleteEntry(entry: ICaptureFiles): void {
        let files: ICaptureFiles[] = [];

        this.serviceEngineTrafficCaptureStore.captureFiles$.subscribe(
            (captureFiles: ICaptureFiles[]) => files = captureFiles,
        );

        const index = files.indexOf(entry);

        if (index !== -1) {
            const { url } = files[index];
            const filename = url.substring(url.lastIndexOf('/') + 1);

            this.serviceEngineTrafficCaptureStore.deleteFile(filename);
        }
    }

    /**
     * Start capture files polling.
     */
    private startGetAllCaptureFilesPolling(): void {
        this.pollGetAllCaptureFiles.start(20000);
    }

    /**
     *  Stop capture files polling.
     */
    private stopGetAllCaptureFilesPolling(): void {
        this.pollGetAllCaptureFiles.stop();
    }
}
