/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'welcome';
const componentName = 'repeated-inputs';
const prefix = `${moduleName}.${componentName}`;

export const removeItemBtnLabel = `${prefix}.removeItemBtnLabel`;
export const addItemBtnLabel = `${prefix}.addItemBtnLabel`;
export const itemLabel = `${prefix}.itemLabel`;

export const ENGLISH = {
    [removeItemBtnLabel]: 'Remove {0}',
    [addItemBtnLabel]: '+ Add {0}',
    [itemLabel]: 'Item',
};
