/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'auth-profile-verify-saml-modal';
const prefix = `${moduleName}.${componentName}`;

export const serviceProviderSettingsHeader = `${prefix}.serviceProviderSettingsHeader`;
export const serviceProviderNodeHeader = `${prefix}.serviceProviderNodeHeader`;
export const entityIdInputLabel = `${prefix}.entityIdInputLabel`;
export const entityIdInputPlaceholder = `${prefix}.entityIdInputPlaceholder`;
export const singleSignonUrlInputLabel = `${prefix}.singleSignonUrlInputLabel`;
export const singleSignonUrlInputPlaceholder = `${prefix}.singleSignonUrlInputPlaceholder`;

export const ENGLISH = {
    [serviceProviderSettingsHeader]: 'Verify Service Provider Settings',
    [serviceProviderNodeHeader]: 'Service Provider Node',
    [entityIdInputLabel]: 'Entity ID',
    [entityIdInputPlaceholder]: 'Enter Entity ID',
    [singleSignonUrlInputLabel]: 'Single Sign on URL',
    [singleSignonUrlInputPlaceholder]: 'Enter Single Sign on URL',
};
