/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module AviFormsModule */

import { Injector } from '@angular/core';

const $injector = '$injector';

export const stringGroupCollectionProvider = {
    deps: [$injector],
    provide: 'StringGroupCollection',
    useFactory(injector: Injector): any {
        return injector.get('StringGroupCollection');
    },
};
