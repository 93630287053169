/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module WafModule
 */

import { PolicyMatchConfigItem } from 'ajs/modules/policies';

export class WafPSMLocationMatchConfigItem extends PolicyMatchConfigItem {
    constructor(args = {}) {
        const extendedArgs = {
            objectType: 'WafPSMLocationMatch',
            ...args,
        };

        super(extendedArgs);
    }
}

WafPSMLocationMatchConfigItem.ajsDependencies = [
    'PolicyMatchConfigItem',
];
