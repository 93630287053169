/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'collection-grid';
const prefix = `${moduleName}.${componentName}`;

export const searchInputPlaceholder = `${prefix}.searchInputPlaceholder`;
export const createAction = `${prefix}.createAction`;
export const rowSelectionSelectAllAction = `${prefix}.rowSelectionSelectAllAction`;
export const rowSelectionClearAllAction = `${prefix}.rowSelectionClearAllAction`;
export const rowSelectionShowSelectedAction = `${prefix}.rowSelectionShowSelectedAction`;
export const rowSelectionShowAllAction = `${prefix}.rowSelectionShowAllAction`;
export const emptyListMessage = `${prefix}.emptyListMessage`;
export const emptyItemsMatchSearchCriteria = `${prefix}.emptyItemsMatchSearchCriteria`;
export const currentPageSelectLabel = `${prefix}.currentPageSelectLabel`;
export const rowsPerPageLabel = `${prefix}.rowsPerPageLabel`;
export const showingRangeOfTotalNumber = `${prefix}.showingRangeOfTotalNumber`;
export const showingRangeOfAtLeast = `${prefix}.showingRangeOfAtLeast`;
export const currentPageNumberLabel = `${prefix}.currentPageNumberLabel`;
export const settingsModalHeader = `${prefix}.settingsModalHeader`;
export const settingsModalColumnConfigHeader = `${prefix}.settingsModalColumnConfigHeader`;
export const settingsModalColumnConfigResetLabel = `${prefix}.settingsModalColumnConfigResetLabel`;
export const settingsModalColumnConfigAvailableHeader = `${prefix}.settingsModalColumnConfigAvailableHeader`;
export const settingsModalColumnConfigDisplayedHeader = `${prefix}.settingsModalColumnConfigDisplayedHeader`;
export const settingsModalColumnConfigRequiredColumnTag = `${prefix}.settingsModalColumnConfigRequiredColumnTag`;
export const settingsModalCancelLabel = `${prefix}.settingsModalCancelLabel`;
export const settingsModalSaveLabel = `${prefix}.settingsModalSaveLabel`;
export const nothingIsSelectedMessage = `${prefix}.nothingIsSelectedMessage`;
export const columnTitleTenant = `${prefix}.columnTitleTenant`;

export const ENGLISH = {
    [searchInputPlaceholder]: 'Search',
    [createAction]: 'Create',
    [rowSelectionSelectAllAction]: 'Select All',
    [rowSelectionClearAllAction]: 'Clear All',
    [rowSelectionShowSelectedAction]: 'Show Selected',
    [rowSelectionShowAllAction]: 'Show All',
    [emptyListMessage]: 'No items found.',
    [emptyItemsMatchSearchCriteria]: 'No items match your search criteria.',
    [currentPageSelectLabel]: 'Page:',
    [rowsPerPageLabel]: 'Rows per page:',
    [showingRangeOfTotalNumber]: '{0} of {1}',
    [showingRangeOfAtLeast]: '{0} of at least {1}',
    [currentPageNumberLabel]: 'Page {0}',
    [settingsModalHeader]: 'Table Customization',
    [settingsModalColumnConfigHeader]: 'Column Configuration',
    [settingsModalColumnConfigResetLabel]: 'Reset to Default',
    [settingsModalColumnConfigAvailableHeader]: 'Available Columns',
    [settingsModalColumnConfigDisplayedHeader]: 'Displayed Columns',
    [settingsModalColumnConfigRequiredColumnTag]: '(Required)',
    [settingsModalCancelLabel]: 'Cancel',
    [settingsModalSaveLabel]: 'Save',
    [nothingIsSelectedMessage]: 'Nothing is selected, click ##0here##1, to show all',
    [columnTitleTenant]: 'Tenant',
};
