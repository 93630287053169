/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ipam';
const componentName = 'ipam-dns-internal-profile-config';
const prefix = `${moduleName}.${componentName}`;

export const cloudInputLabel = `${prefix}.cloudInputLabel`;
export const cloudInputPlaceholder = `${prefix}.cloudInputPlaceholder`;
export const cloudFieldDescription = `${prefix}.cloudFieldDescription`;
export const defaultTtlInputLabel = `${prefix}.defaultTtlInputLabel`;
export const defaultTtlInputPlaceholder = `${prefix}.defaultTtlInputPlaceholder`;
export const secondsInnerText = `${prefix}.secondsInnerText`;

export const ENGLISH = {
    [cloudInputLabel]: 'Cloud',
    [cloudInputPlaceholder]: 'Select Cloud',
    [cloudFieldDescription]: 'Select Cloud to use for Usable Network',
    [defaultTtlInputLabel]: 'Default Record TTL for all domains',
    [defaultTtlInputPlaceholder]: 'Enter Default Record TTL for all domains',
    [secondsInnerText]: 'Seconds',
};
