/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';

import { IAviDropdownOption } from 'ng/shared/components';
import { createOptionsFromEnumProps } from 'ng/shared/utils/dropdown.utils';
import { L10nService } from '@vmw/ngx-vip';
import { SchemaService } from 'ajs/modules/core/services/schema-service';
import { MethodMatchConfigItem } from 'ajs/modules/match';
import * as l10n from './http-method-match.component.l10n';
import './http-method-match.component.less';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description
 *     Match component for MethodMatch message.
 * @author Abhinesh Gour
 */
@Component({
    selector: 'http-method-match',
    templateUrl: './http-method-match.component.html',
})
export class HttpMethodMatchComponent {
    /**
     * Method Match message item instance.
     */
    @Input()
    public editable: MethodMatchConfigItem;

    /**
     * Match label passed to the MatchWrapperComponent.
     */
    @Input()
    public label: string;

    /**
     * Index of the match within the MatchAdder component.
     */
    @Input()
    public matchIndex = 0;

    /**
     * objectType passed to the MatchWrapperComponent.
     */
    @Input()
    public objectType: string;

    /**
     * fieldName passed to the MatchWrapperComponent.
     */
    @Input()
    public fieldName: string;

    /**
     * Disable any modifications to the fields.
     */
    @Input()
    public disabled = false;

    /**
     * EventEmitter for removing a match, passed to the MatchWrapperComponent.
     */
    @Output()
    public onRemoveMatch = new EventEmitter<void>();

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * Object Type to be used in template for tooltip.
     */
    public readonly httpMethodMatchObjectType = 'MethodMatch';

    /**
     * HTTPMethodMatch dropdown options.
     */
    public readonly httpMethodOptions: IAviDropdownOption[] = [];

    constructor(
        l10nService: L10nService,
        schemaService: SchemaService,
    ) {
        const httpMethods = schemaService.getEnumValues('HTTPMethod');

        this.httpMethodOptions = createOptionsFromEnumProps(httpMethods);

        l10nService.registerSourceBundles(dictionary);
    }

    /**
     * Called to remove this match. Passed to the MatchWrapperComponent.
     */
    public removeMatch(): void {
        this.onRemoveMatch.emit();
    }
}
