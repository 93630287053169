/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'CportalModule';
const componentName = 'cportal-page';
const prefix = `${moduleName}.${componentName}`;

export const cloudServicesLabel = `${prefix}.cloudServicesLabel`;
export const editCloudServicesSettingsTooltip = `${prefix}.editCloudServicesSettingsTooltip`;
export const reconnectNowLabel = `${prefix}.reconnectNowLabel`;
export const connectedLabel = `${prefix}.connectedLabel`;
export const disconnectedLabel = `${prefix}.disconnectedLabel`;
export const controllerLabel = `${prefix}.controllerLabel`;
export const editControllerSettingsTooltip = `${prefix}.editControllerSettingsTooltip`;
export const registeredLabel = `${prefix}.registeredLabel`;
export const unregisteredLabel = `${prefix}.unregisteredLabel`;
export const registerControllerBtnLabel = `${prefix}.registerControllerBtnLabel`;
export const deregisterControllerBtnLabel = `${prefix}.deregisterControllerBtnLabel`;
export const connectionErrorMessage = `${prefix}.connectionErrorMessage`;
export const isDeregisterControllerMessage = `${prefix}.isDeregisterControllerMessage`;
export const cloudServicesRegisterControllerMessage = `${prefix}.cloudServicesRegisterControllerMessage`;
export const controllerRegisteredControllerBtnLabel = `${prefix}.controllerRegisteredControllerBtnLabel`;
export const notConnectedErrorMessage = `${prefix}.notConnectedErrorMessage`;
export const notRegisteredAndConnectedErrorMessage = `${prefix}.notRegisteredAndConnectedErrorMessage`;
export const attemptingToConnectMessage = `${prefix}.attemptingToConnectMessage`;
export const learnMoreLink = `${prefix}.learnMoreLink`;
export const organizationIdLabel = `${prefix}.organizationIdLabel`;
export const portalUrlLabel = `${prefix}.portalUrlLabel`;

export const ENGLISH = {
    [cloudServicesLabel]: 'Cloud Services',
    [editCloudServicesSettingsTooltip]: 'Edit Cloud Services settings',
    [reconnectNowLabel]: 'Reconnect now.',
    [connectedLabel]: 'Connected',
    [disconnectedLabel]: 'Disconnected',
    [controllerLabel]: 'CONTROLLER',
    [editControllerSettingsTooltip]: 'Edit controller settings',
    [registeredLabel]: 'Registered',
    [unregisteredLabel]: 'Not Registered',
    [controllerRegisteredControllerBtnLabel]: 'Complete Registration',
    [registerControllerBtnLabel]: 'Register Controller',
    [deregisterControllerBtnLabel]: 'Deregister Controller',
    [connectionErrorMessage]: 'A connection error has occurred, controller will auto-retry in 10 seconds.',
    [notConnectedErrorMessage]: 'We successfully made a connection to Cloud Services but was unable to complete registration, Please try again.',
    [notRegisteredAndConnectedErrorMessage]: 'Register your Controller with Cloud Services to take advantage of auto-updates in controller support and more.',
    [attemptingToConnectMessage]: 'Controller attempting to connect to Cloud Services, Please wait to complete registration. This may take several minutes.',
    [isDeregisterControllerMessage]: 'Are you sure you want to Deregister this controller?',
    [cloudServicesRegisterControllerMessage]: 'Cloud Services must be connected for registering controller.',
    [learnMoreLink]: 'Learn More',
    [organizationIdLabel]: 'Organization ID',
    [portalUrlLabel]: 'Portal URL',
};
