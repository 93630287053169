/** @module MatchModule */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
*/

import {
    IMethodMatch,
    MatchOperation,
} from 'generated-types';

import { MessageItem } from 'ajs/modules/data-model/factories/message-item.factory';
import { MethodMatch } from 'object-types';

import {
    SchemaService,
    TEnumValueLabelsHash,
} from 'ajs/modules/core/services';

/**
 * @description  MethodMatch ConfigItem class.
 * @author Abhinesh Gour
 */
export class MethodMatchConfigItem extends MessageItem<IMethodMatch> {
    public static ajsDependencies = ['schemaService'];

    /**
     * SchemaService instance to get data model exported from protobuf.
     */
    private readonly schemaService: SchemaService;

    /**
     * Hash of match operation values to their labels.
     */
    private readonly matchOperationLabelsHash: TEnumValueLabelsHash;

    /**
     * Hash of method values to their labels.
     */
    private readonly methodLabelsHash: TEnumValueLabelsHash;

    constructor(args = {}) {
        const extendedArgs = {
            objectType: MethodMatch,
            ...args,
        };

        super(extendedArgs);

        this.schemaService = this.getAjsDependency_('schemaService');
        this.matchOperationLabelsHash = this.schemaService.getEnumValueLabelsHash('MatchOperation');
        this.methodLabelsHash = this.schemaService.getEnumValueLabelsHash('HTTPMethod');
    }

    /**
     * Returns a list of values that should be searchable.
     */
    public get searchableValues(): string[] {
        return [
            ...this.methodLabels,
        ];
    }

    /**
     * Returns the label to display for this.config.methods.
     */
    public get methodLabels(): string[] {
        const { methods = [] } = this.config;

        return methods.map(method => this.methodLabelsHash[method]);
    }

    /**
     * Returns the label to display for this.config.match_criteria.
     */
    public get matchCriteriaLabel(): string {
        const { match_criteria: matchCriteria } = this.config;

        return this.matchOperationLabelsHash[matchCriteria];
    }

    /**
     * Returns a string representation of the config data.
     */
    public toString(): string {
        return `${this.matchCriteriaLabel} ${this.methodLabels.join(', ')}`;
    }

    /** @override */
    // eslint-disable-next-line no-underscore-dangle
    public get defaultConfigOverride_(): Partial<IMethodMatch> {
        return {
            match_criteria: MatchOperation.IS_IN,
        };
    }
}
