/**
 * @module HeaderModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

import {
    Pipe,
    PipeTransform,
} from '@angular/core';
import { IAppState } from 'ajs/js/services/appStates.types';
import { L10nService } from '@vmw/ngx-vip';
import { StringService } from 'ajs/modules/core/services/string-service';
import * as l10n from './state-group.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;
/**
 * Pipe to get state title from state name.
 *
 * @author Aravindh Nagarajan
 */
@Pipe({
    name: 'stateGroup',
})

export class StateGroupPipe implements PipeTransform {
    constructor(
        private l10nService: L10nService,
        private readonly stringService: StringService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    public transform(state: IAppState): string {
        // Ignore leaf states (summary, config, performance)
        // and get to abstract state (application, vs, pool)
        const [, stateName] = state.name.split('.') as any;

        const name: string = this.stringService.capitalize(stateName);

        const { l10nService } = this;

        switch (name) {
            case 'Application':
                return l10nService.getMessage(l10nKeys.application);

            case 'Profile':
                return l10nService.getMessage(l10nKeys.templates);

            case 'Infrastructure':
                return l10nService.getMessage(l10nKeys.infrastructureOption);

            case 'Administration':
                return l10nService.getMessage(l10nKeys.administrationOption);

            case 'Operations':
                return l10nService.getMessage(l10nKeys.operationsOption);

            default:
                return name;
        }
    }
}
