/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @module VsLogsModule
 */

import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description Connects Reload button to subscribing components that will reload upon button press.
 * @author Alex Klyuev
 */
@Injectable()
export class VsLogsReloadService implements OnDestroy {
    /**
     * Observable for when a reload should take place.
     */
    public readonly reload$ = new Subject<void>();

    /**
     * Emit the reload Subject to indicate that a reload should take place.
     */
    public reload(): void {
        this.reload$.next();
    }

    /** @override */
    public ngOnDestroy(): void {
        this.reload$.complete();
    }
}
