/**
 * @module SecurityModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'jwt-vs-validation-config';
const prefix = `${moduleName}.${componentName}`;

export const ssoPolicyLabel = `${prefix}.ssoPolicyLabel`;
export const ssoPolicyInputPlaceholder = `${prefix}.ssoPolicyInputPlaceholder`;
export const createSsoPolicyButtonLabel = `${prefix}.createSsoPolicyButtonLabel`;
export const audienceInputLabel = `${prefix}.audienceInputLabel`;
export const audienceInputPlaceholder = `${prefix}.audienceInputPlaceholder`;
export const tokenLocationLabel = `${prefix}.tokenLocationLabel`;
export const authorizationHeaderLabel = `${prefix}.authorizationHeaderLabel`;
export const urlQueryLabel = `${prefix}.urlQueryLabel`;
export const tokenNameLabel = `${prefix}.tokenNameLabel`;

export const ENGLISH = {
    [ssoPolicyLabel]: 'SSO Policy',
    [ssoPolicyInputPlaceholder]: 'Select SSO Policy',
    [createSsoPolicyButtonLabel]: 'Create SSO Policy',
    [audienceInputLabel]: 'Audience',
    [audienceInputPlaceholder]: 'Enter Audience',
    [tokenLocationLabel]: 'Token Location',
    [authorizationHeaderLabel]: 'Authorization Header',
    [urlQueryLabel]: 'URL Query',
    [tokenNameLabel]: 'Token name',
};
