/**
 * @module AuthSettingsModule
 */

/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/**
 * @fileoverview Contains Ajs dependency tokens for auth-settings related things.
 * @author Aravindh Nagarajan
 */

/**
 * Ajs dependency token for AuthMappingProfile objectTypeItem.
 */
export const AUTH_MAPPING_PROFILE_ITEM_TOKEN = 'AuthMappingProfileItem';

/**
 * Ajs dependency token for AuthMappingProfile collection.
 */
export const AUTH_MAPPING_PROFILE_COLLECTION_TOKEN = 'AuthMappingProfileCollection';

/**
 * Ajs dependency token for AuthMappingRule ConfigItem.
 */
export const AUTH_MAPPING_RULE_CONFIG_ITEM_TOKEN = 'AuthMappingRuleConfigItem';

/**
 * Ajs dependency token for AuthMatchAttribute collection.
 */
export const AUTH_MATCH_ATTRIBUTE_CONFIG_ITEM_TOKEN = 'AuthMatchAttributeConfigItem';

/**
 * Ajs dependency token for AuthMatchGroupMembership ConfigItem.
 */
export const AUTH_MATCH_GROUP_MEMBERSHIP_CONFIG_ITEM_TOKEN =
    'AuthMatchGroupMembershipConfigItem';
