/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

/** @module VsLogsModule */

import { Component, OnInit } from '@angular/core';
import { L10nService } from '@vmw/ngx-vip';

import {
    defaultSignpostParams,
    templatesConfiguration,
    wafLatencyPhases,
} from 'ng/modules/vs-logs/constants/vs-logs-signpost.constants';

import {
    IVsLogsCombinedRequestUpdatableParams,
    IVsLogsSignpostPhasesConfig,
} from 'ng/modules/vs-logs/vs-logs.types';
import { defaultPageSize } from '../../../../constants/default-values.constants';

import { VsLogsSignpostStore } from '../../vs-logs-signpost.store';
import './vs-logs-signpost-custom-waf-latency.component.less';
import * as l10n from './vs-logs-signpost-custom-waf-latency.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Signpost component for WAF Latency section.
 * @author Suraj Kumar
 */
@Component({
    selector: 'vs-logs-signpost-custom-waf-latency',
    templateUrl: './vs-logs-signpost-custom-waf-latency.component.html',
})
export class VsLogsSignpostCustomWafLatencyComponent implements OnInit {
    public readonly wafLatencyPhases = wafLatencyPhases;

    public readonly l10nKeys = l10nKeys;

    constructor(
        public readonly vsLogsSignpostStore: VsLogsSignpostStore,
        l10nService: L10nService,
    ) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        const fullSignpostParams = this.getWafLatencyRequestParams();

        this.vsLogsSignpostStore.getVsLogSignpostCombinedData(fullSignpostParams);
    }

    /**
     * Callback to ngFor-track by.
     */
    public trackbyIndex(index: number): number {
        return index;
    }

    /**
     * Get request params for WAF latency signpost.
     */
    private getWafLatencyRequestParams(): IVsLogsCombinedRequestUpdatableParams[] {
        const template = templatesConfiguration['waf-phase-latency'];
        const { phases, page_size: pageSize } = template;

        const fullRequestsParams: IVsLogsCombinedRequestUpdatableParams[] = phases.map(
            ({ groupby }: IVsLogsSignpostPhasesConfig, index) => {
                return {
                    requestId: wafLatencyPhases[index],
                    requestParams: {
                        ...defaultSignpostParams,
                        groupby,
                        step: '100',
                        page_size: pageSize || defaultPageSize,
                        expstep: true,
                    },
                };
            },
        );

        return fullRequestsParams;
    }
}
