/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'security';
const componentName = 'geo-db-file-modal';
const prefix = `${moduleName}.${componentName}`;

export const geoDbFileModalTitle = `${prefix}.geoDbFileModalTitle`;
export const enabledFileLabel = `${prefix}.enabledFileLabel`;
export const vendorLabel = `${prefix}.vendorLabel`;
export const nameInputLabel = `${prefix}.nameInputLabel`;
export const nameInputPlaceholder = `${prefix}.nameInputPlaceholder`;
export const sectionTitleGeneral = `${prefix}.sectionTitleGeneral`;
export const geoDbFileLabel = `${prefix}.geoDbFileLabel`;
export const geoDbFilePlaceholder = `${prefix}.geoDbFilePlaceholder`;

export const ENGLISH = {
    [geoDbFileModalTitle]: 'Add GeoDB File',
    [enabledFileLabel]: 'Enabled File',
    [geoDbFileLabel]: 'GeoDb File',
    [geoDbFilePlaceholder]: 'Select File',
    [vendorLabel]: 'Vendor',
    [nameInputLabel]: 'Name',
    [nameInputPlaceholder]: 'Enter Name',
    [sectionTitleGeneral]: 'General',
};
