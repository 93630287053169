/***************************************************************************
 * ========================================================================
 * Copyright 2023 VMware, Inc. All rights reserved. VMware Confidential
 * ========================================================================
 */

const moduleName = 'ajs-legacy';
const componentName = 'infrastructure-detail';
const prefix = `${moduleName}.${componentName}`;

export const defaultCloudHeader = `${prefix}.defaultCloudHeader`;
export const selectCloudDropdownLabel = `${prefix}.selectCloudDropdownLabel`;
export const selectCloudDropdownPlaceholder = `${prefix}.selectCloudDropdownPlaceholder`;

export const ENGLISH = {
    [defaultCloudHeader]: 'Default-Cloud',
    [selectCloudDropdownLabel]: 'Select Cloud:',
    [selectCloudDropdownPlaceholder]: 'Select Cloud',
};
