/**
 * @module ScriptsModule
 */

/***************************************************************************
 * ------------------------------------------------------------------------
 * Copyright 2021 VMware, Inc.  All rights reserved. VMware Confidential
 * ------------------------------------------------------------------------
 */

import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { ClrFormLayout } from '@clr/angular';
import { L10nService } from '@vmw/ngx-vip';
import { ControlScript } from 'ajs/modules/scripts';

import * as l10n from './control-scripts-modal.l10n';

const { ENGLISH: dictionary, ...l10nKeys } = l10n;

/**
 * @description Modal component for controlScript.
 * @author Ratan Kumar
 */
@Component({
    selector: 'control-scripts-modal',
    templateUrl: './control-scripts-modal.component.html',
})
export class ControlScriptsModalComponent implements OnInit {
    /**
     * ControlScript ObjectTypeItem instance.
     */
    @Input()
    public editable: ControlScript;

    /**
     * Get keys from source bundles for template usage
     */
    public readonly l10nKeys = l10nKeys;

    /**
     * ControlScript objectType.
     */
    public objectType: string;

    /**
     * Layout for control script modal clrForm.
     */
    public readonly verticalLayout = ClrFormLayout.VERTICAL;

    constructor(l10nService: L10nService) {
        l10nService.registerSourceBundles(dictionary);
    }

    /** @override */
    public ngOnInit(): void {
        this.objectType = this.editable.messageType;
    }
}
